<template>
  <div v-if="predictions" class="prediction-container">
    <div class="prediction-header">
      <Icon name="magic-wand-1" medium />
      <label>Vision Predictions</label>
    </div>
    <div class="predictions">
      <Prediction
        v-for="prediction_type in availablePredictionTypes"
        :key="prediction_type.field"
        :rating="
          getPredictionRating(predictions, {
            currentModel: prediction_type,
          })
        "
        :label="prediction_type.label"
        :full_width="singlePrediction"
        :primary="prediction_type.field == predictionModel.field"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Icon from '@/components/foundation/Icon.vue';
import { getPredictionRating } from '@/utils/vision';
import { PREDICTION_MODEL } from '@/config';
import Prediction from '@/app/library/components/MediaPopup/Prediction.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Predictions',
  components: {
    Prediction,
    Icon,
  },
  props: {
    predictions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useAuthStore),
    predictionModel() {
      return PREDICTION_MODEL.ENGAGEMENT;
    },
    singlePrediction() {
      return Object.keys(this.predictions).length === 1;
    },
    hasAdPredictionsAccess() {
      return this.authStore.user_can('ads', 'can_access_ad_predictions');
    },
    availablePredictionTypes() {
      const permissiblePredictions = this.hasAdPredictionsAccess
        ? PREDICTION_MODEL
        : { ENGAGEMENT: PREDICTION_MODEL.ENGAGEMENT };

      return Object.keys(permissiblePredictions)
        .filter((key) => Object.keys(this.predictions).includes(permissiblePredictions[key].field))
        .map((key) => [key, PREDICTION_MODEL[key]])
        .reduce((createdObj, pair) => ({ ...createdObj, [pair[0]]: pair[1] }), {});
    },
  },
  created() {},
  methods: {
    getPredictionRating,
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.prediction-container {
  width: 100%;
  margin-bottom: var(--space-32);

  .icon {
    margin-right: var(--space-8);
    float: left;
  }

  label {
    font-size: var(--x18);
    float: left;
  }
}

.prediction-header {
  float: left;
  width: 100%;
}

.predictions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
</style>
