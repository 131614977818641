<template>
  <Chip
    v-tooltip="disabled ? disabledTooltipText : tooltip"
    light
    small
    :prepend-icon="prependIcon"
    :prepend-icon-color="iconColor"
    :remove="showClearButton && !readOnly"
    :hover-mode="hoverMode"
    :force-hover="active"
    :border="border"
    class="selection-display-chip cursor-pointer"
    :class="{ active: active, disabled: disabled, 'read-only': readOnly }"
    @remove="onRemove"
  >
    <div class="flex items-center gap-1">
      <div v-if="hasLabel" class="text-small">
        <slot name="label">
          {{ label }}
          <template v-if="labelOperator">
            <span class="text-secondary">{{ labelOperator }}</span>
          </template>
        </slot>
      </div>
      <div
        class="selection-display-chip-button text-link text-small flex items-center gap-2"
        data-cy="SelectionDisplayButton"
      >
        <slot />
      </div>
      <div v-if="!hideDropdownIcon && !readOnly && !showClearButton" class="flex items-center">
        <Icon name="chevronDown" :dir="active ? 'down' : 'up'" xsmall />
      </div>
    </div>
  </Chip>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import Chip from '@/components/foundation/Chip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Icon,
    Chip,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    labelOperator: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    disabledTooltipText: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
    hoverMode: {
      type: Boolean,
      default: true,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'var(--icon-primary)',
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
    hideDropdownIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clear'],
  computed: {
    hasLabel() {
      return this.label || this.$slots.label;
    },
  },
  methods: {
    onRemove(event) {
      this.$emit('clear', event);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.selection-display-chip {
  &.read-only {
    .selection-display-chip-button {
      color: var(--text-primary);
    }
  }

  &.disabled {
    .selection-display-chip-button {
      color: var(--text-primary);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
