import { computed, unref } from 'vue';
import { REPORTS, isContentReport } from '@/app/dashboards/utils/reports.enum';
import {
  TOTAL_METRIC_GROUPS,
  DATA_TYPES,
  IMAGE_DOWNLOAD_FILE_FORMATS,
  MAX_ADS_TABLE_REPORT_ROWS,
} from '@/app/dashboards/constants';
import {
  extractBrandIdsFromReport,
  uniqueSourceAccountIdsFromReport,
  uniqueBrandIdsFromTags,
  extractBrandTagObjectsFromReport,
} from '@/app/dashboards/utils/tagging.utils';
import { useReportType } from '@/app/dashboards/composables/useReportType';
import { useReportMetaAggregateBy } from '@/app/dashboards/composables/useReportMetaAggregateBy';
import {
  getBrandIdToReportAdCampaign,
  getFormattedMetrics,
} from '@/app/dashboards/utils/report.utils';
import { ALL_CHANNELS_KEY, CHANNELS } from '@/models/dashboards/channels.enum';
import { useSearchStore } from '@/stores/search';
import { useAuthStore } from '@/stores/auth';
import { useMetricDetails } from '@/app/dashboards/composables/useMetricDetails';
import { getAdsPlatformController } from '@/app/dashboards/utils/getAdsPlatformController';
import { getMetricMediaType } from '@/app/dashboards/utils/media-types.utils';
import { useRoute } from 'vue-router';
import { formatGroupedByReportData } from '@/app/dashboards/utils/grouped-by-metrics.utils';
import { getMetricDetails } from '@/utils/metrics';
import { downloadImage } from '@/components/VisionAi/utils/tile-download';
import { toolTips } from '@/config';
import {
  getReportBrandsMetrics,
  getReportChannelMetrics,
  getReportCompetitorAverageMetrics,
  getReportCompetitorsMetrics,
  getReportMediaTypeMetrics,
} from '@/app/dashboards/utils/report-metric.utils';

/**
 * Responsible for extracting data from dashboard report object.
 */
export function useReport({
  report,
  reportTypeForMetricDetails = null,
  loading = false,
  preview = false,
} = {}) {
  const authStore = useAuthStore();
  const searchStore = useSearchStore();
  const route = useRoute();

  const dashboardId = computed(() => unref(report)?.dashboard_id);
  const reportId = computed(() => unref(report)?.id);
  const reportType = computed(() => unref(report)?.type);
  const reportMeta = computed(() => unref(report)?.meta);
  const reportData = computed(() => unref(report)?.data);
  const visionAiReport = computed(() => unref(report)?.visionAiReport);
  const isExpanded = computed(() => unref(report)?.isExpanded);
  const reportLoading = computed(() => unref(loading));
  const reportPreview = computed(() => unref(preview));

  const metricTypeReportKey = computed(() => {
    const reportTypeForMetric = reportTypeForMetricDetails ?? reportType;

    return (
      REPORTS[reportTypeForMetric]?.metricTypeReportKey ?? REPORTS.SINGLE_METRIC.metricTypeReportKey
    );
  });

  const isReport = computed(() => route?.meta?.report);

  const identityBrands = computed(() => {
    const brands = authStore.identity?.brands || {};
    return Object.keys(brands).map((key) => brands[key]);
  });

  const singleChannel = computed(() => reportMeta.value?.channel);

  const channels = computed(
    () => reportMeta.value?.channels || [].concat(reportMeta.value?.channel || []),
  );

  const firstChannel = computed(() => {
    return channels.value.length > 0 ? channels.value[0] : null;
  });

  const metrics = computed(
    () => reportMeta.value?.metrics || [].concat(reportMeta.value?.metric || []),
  );

  const aggregateBy = computed(() => reportMeta.value?.aggregate_by);
  const brandIds = computed(() => extractBrandIdsFromReport(unref(report)));
  const brandTags = computed(() => reportMeta.value?.brand_tags || []);
  const reportCompetitorIds = computed(() => uniqueSourceAccountIdsFromReport(unref(report)));
  const competitorTagsByBrand = computed(() => reportMeta.value?.competitor_tags ?? {});
  const campaignIds = computed(() => reportMeta.value?.source_campaign_ids ?? []);
  const contentTagIds = computed(() => reportMeta.value?.content_tag_ids || []);
  const brandTagIds = computed(() => reportMeta.value?.brand_tags ?? []);
  const competitorTagIdsByBrand = computed(() => reportMeta.value?.competitor_tags ?? {});
  const sourceCampaignIds = computed(() => reportMeta.value?.source_campaign_ids ?? []);

  const isMultiBrand = computed(() => brandIds.value.length > 1);
  const isMultiChannel = computed(() => channels.value.length > 1);
  const isUGCMetric = computed(() => metrics.value?.[0]?.startsWith('UGC_'));
  const selectedBrandTags = computed(() => extractBrandTagObjectsFromReport(unref(report)));
  const brandIdsOfSelectedTags = computed(() => uniqueBrandIdsFromTags(selectedBrandTags.value));

  const {
    isAdsTotalMetric,
    isMultiChannelReport,
    isMultiMediaTypeReport,
    isBenchmarkReport,
    isTotalGroupedMetricReport,
    isAdsTotalGroupedMetricReport,
    isCompetitiveReport,
  } = useReportType({ reportType });

  const { reportIsGroupedByChannel, reportIsGroupedByBrand, reportIsGroupedByGender } =
    useReportMetaAggregateBy({
      aggregateBy,
    });

  const reportAdCampaigns = computed(() => {
    return brandIds.value.map((id) => {
      let hasAccess = false;
      const campaigns = campaignIds.value.map((campaignId) => {
        const reportDataCampaign = reportData?.[campaignId];
        hasAccess ||= reportDataCampaign?.user_has_access ?? false;
        return {
          campaignId,
          avatar: reportDataCampaign?.avatar,
          name: reportDataCampaign?.name,
          user_has_access: reportDataCampaign?.user_has_access,
        };
      });
      return {
        id,
        campaigns,
        hasAccess,
      };
    });
  });

  const reportBrands = computed(() => {
    const formattedReportBrands =
      brandIds.value?.map((id) => {
        const reportBrandData = reportData.value?.[id];
        const identityBrand = identityBrands.value?.find((brand) => brand.id === id);
        if (!identityBrand) {
          // user no longer has access to this brand
          return {
            id,
            user_has_access: false,
          };
        }
        let hasAccess = reportBrandData?.user_has_access;
        if (isAdsTotalMetric.value) {
          const brandIdToReportAdCampaign = getBrandIdToReportAdCampaign(reportAdCampaigns.value);
          hasAccess = brandIdToReportAdCampaign[id].hasAccess;
        }
        return {
          id,
          avatar: reportBrandData?.avatar || identityBrand?.avatarUrl,
          name: reportBrandData?.name || identityBrand?.name,
          label: reportBrandData?.label || identityBrand?.label,
          user_has_access: hasAccess,
          permissions: identityBrand?.permissions,
        };
      }) || [];
    return formattedReportBrands.filter((brand) => Object.keys(brand).length > 0);
  });

  const reportChannel = computed(() => {
    if (!isContentReport(reportType.value) && isUGCMetric.value) {
      return CHANNELS.INSTAGRAM_UGC.value;
    }
    return singleChannel.value || firstChannel.value;
  });

  const reportChannels = computed(() => {
    if (!isContentReport(reportType.value) && isUGCMetric.value) {
      return [CHANNELS.INSTAGRAM_UGC.value];
    }
    return channels.value;
  });

  const reportCompetitors = computed(() => {
    const source = CHANNELS[reportChannel.value]?.competitiveChannelType;

    const competitors = searchStore.filterCompetitors({
      sourceAccountIds: reportCompetitorIds.value,
      source,
    });
    const formattedReportCompetitors =
      competitors
        .map((competitor) => {
          const sourceAccountId = competitor.sourceAccountId;
          const reportCompetitorData = reportData.value?.[sourceAccountId];
          if (competitor.missing) {
            // user no longer has access to this competitor
            const explicitlyAddedCompetitor =
              reportMeta.value?.competitorSourceAccountIds?.includes(sourceAccountId);
            return {
              id: sourceAccountId,
              user_has_access: false,
              source: explicitlyAddedCompetitor ? source : null,
            };
          }
          return {
            id: sourceAccountId,
            avatar: reportCompetitorData?.avatar || competitor?.avatarUrl,
            name: reportCompetitorData?.name || competitor?.handle,
            label: reportCompetitorData?.name || competitor?.handle,
            source: reportCompetitorData?.source || competitor?.source,
            brandDetails: competitor?.brand_details || competitor?.brandDetails,
            user_has_access: reportCompetitorData?.user_has_access,
            isCompetitor: true,
          };
        })
        ?.filter((competitor) => competitor.source === source) || [];
    return formattedReportCompetitors.filter((competitor) => Object.keys(competitor).length > 0);
  });

  const reportBrandId = computed(() => {
    return brandIds.value[0] || brandIdsOfSelectedTags.value[0];
  });

  const reportBenchmarksData = computed(() => {
    const reportDataIds = Object.keys(reportData.value);
    return reportDataIds
      .filter((itemId) => reportData.value[itemId]?.data_type === DATA_TYPES.BENCHMARK)
      .map((itemId) => {
        const benchmarkData = reportData.value[itemId] || {};
        const benchmarkDataId = itemId || '';
        return { ...benchmarkData, benchmarkDataId };
      });
  });

  const reportMetricDetails = computed(() => {
    return getMetricDetails(metrics.value[0], reportChannels.value, metricTypeReportKey.value);
  });

  function metricValueDictHasData(valueDict) {
    const hasValue = valueDict?.value !== null && valueDict?.value !== undefined;
    const hasContext = valueDict?.context !== null && valueDict?.context !== undefined;
    return hasValue && hasContext;
  }

  const lineGraphReportHasData = computed(() => {
    const validReportTypes = [REPORTS.GRAPH.value, REPORTS.COMPETITIVE_GRAPH.value];
    if (!validReportTypes.includes(reportType.value)) return false;

    return Object.values(reportData.value).some((accountData) => {
      return reportChannels.value.some((channel) => {
        return metrics.value.some((metric) => {
          const valueDict = accountData?.[channel]?.[metric] || {};
          return Object.values(valueDict)?.length > 0;
        });
      });
    });
  });

  function metricsObjectHasData(metricsObject) {
    const channelsToCheck = [...reportChannels.value, ALL_CHANNELS_KEY];
    return metrics.value.some((metric) => {
      return channelsToCheck.some((channel) => {
        const valueDict = metricsObject?.[metric]?.[channel] || {};
        return metricValueDictHasData(valueDict);
      });
    });
  }

  const brandAggregateMetricReportHasData = computed(() => {
    const brandAggregateReportTypes = [
      REPORTS.COMPETITIVE_MULTI_BRAND_METRIC.value,
      REPORTS.MULTI_METRIC_MEDIA_TYPE.value,
      REPORTS.SINGLE_METRIC.value,
      REPORTS.MULTI_BRAND_METRIC.value,
    ];
    if (
      aggregateBy.value !== TOTAL_METRIC_GROUPS.BRAND &&
      !brandAggregateReportTypes.includes(reportType.value)
    )
      return false;

    return Object.values(reportData.value).some((accountData) => {
      const metricObj = accountData?.metrics || {};
      return metricsObjectHasData(metricObj);
    });
  });

  const otherAggregateMetricReportHasData = computed(() => {
    const rootLevelAggregates = [TOTAL_METRIC_GROUPS.TOTAL, TOTAL_METRIC_GROUPS.CHANNEL];
    if (!rootLevelAggregates.includes(aggregateBy.value)) return false;

    const metricObj = reportData.value?.metrics || {};
    return metricsObjectHasData(metricObj);
  });

  const reportHasData = computed(() => {
    return (
      lineGraphReportHasData.value ||
      brandAggregateMetricReportHasData.value ||
      otherAggregateMetricReportHasData.value
    );
  });

  const isMultiBrandOrCompetitiveReport = computed(() => {
    const hasMultipleBrandsOrCompetitors =
      reportBrands.value.length + reportCompetitors.value.length > 1;
    const hasBrandTags = brandTags.value.length;
    const hasCompetitorTags = Object.keys(competitorTagsByBrand.value).length;

    return Boolean(hasMultipleBrandsOrCompetitors || hasBrandTags || hasCompetitorTags);
  });

  const isInstagramReport = computed(() => {
    return (
      reportChannels.value.length === 1 && reportChannels.value[0] === CHANNELS.INSTAGRAM.value
    );
  });

  const isFacebookReport = computed(() => {
    return reportChannels.value.length === 1 && reportChannels.value[0] === CHANNELS.FACEBOOK.value;
  });

  const isFacebookCompetitiveReport = computed(() => {
    return (
      reportChannels.value.length === 1 &&
      reportChannels.value[0] === CHANNELS.FACEBOOK_COMPETITIVE.value
    );
  });

  const isInstagramCompetitiveReport = computed(() => {
    return (
      reportChannels.value.length === 1 &&
      reportChannels.value[0] === CHANNELS.INSTAGRAM_COMPETITIVE.value
    );
  });

  const hasReportMetrics = computed(() => metrics.value.length > 0);

  const hasMediaSubtypes = computed(() => {
    return (
      hasReportMetrics.value &&
      metrics.value.every(
        (reportMetric) =>
          !!getMetricMediaType(reportChannel.value, reportMetric, metricTypeReportKey.value),
      )
    );
  });

  const hasUGCMetrics = computed(() => {
    return metrics.value.every((reportMetric) => reportMetric.startsWith('UGC_'));
  });

  const isMonetaryMetric = computed(() => {
    const metric = metrics.value?.[0];
    if (!metric) return false;

    const metricDetails = useMetricDetails({
      metric,
      channels: reportChannels.value,
      metricReportTypeKey: metricTypeReportKey.value,
    });

    return metricDetails.isMonetaryMetric.value;
  });

  const currency = computed(() => {
    const adsPlatformController = getAdsPlatformController(reportChannel.value);
    if (!isMonetaryMetric.value || !adsPlatformController) return '';

    // Find the first accountId that exists in the account -> currency map
    const accountToCurrency = adsPlatformController.adAccountToCurrency.value;
    const accountId = reportMeta.value?.source_ad_account_ids?.find((id) => accountToCurrency[id]);
    return accountToCurrency[accountId] ?? '';
  });

  const reportMediaTypeMetrics = computed(() => {
    return getReportMediaTypeMetrics(
      metrics.value,
      reportData.value,
      reportBrands.value,
      reportBrandId.value,
      reportChannel.value,
      metricTypeReportKey.value,
    );
  });

  const reportBrandsMetrics = computed(() => {
    return getReportBrandsMetrics(
      metrics.value,
      reportData.value,
      reportBrands.value,
      campaignIds.value,
      reportChannel.value,
      isAdsTotalMetric.value,
    );
  });

  const reportCompetitorsMetrics = computed(() => {
    return getReportCompetitorsMetrics(
      metrics.value,
      reportData.value,
      reportCompetitors.value,
      reportChannel.value,
    );
  });

  const reportCompetitorAverageMetrics = computed(() => {
    return getReportCompetitorAverageMetrics(metrics.value, reportData.value, reportChannel.value);
  });

  const reportBenchmarksMetrics = computed(() => {
    return reportBenchmarksData.value.map((benchmark) => {
      const benchmarkMetrics = benchmark?.metrics || {};
      const { formattedMetrics, metric } = getFormattedMetrics(
        benchmarkMetrics,
        metrics.value[0],
        reportChannel.value,
      );

      return {
        ...benchmark,
        metric,
        formattedMetrics,
      };
    });
  });

  const reportChannelsMetrics = computed(() => {
    return getReportChannelMetrics(metrics.value, reportData.value, reportChannels.value);
  });

  const reportBrandAggregated = computed(() => {
    return reportBrands.value?.map((brand) => {
      const reportDataBrand = reportData.value?.[brand.id];

      if (!reportDataBrand) {
        return {
          ...brand,
        };
      }

      const brandMetrics = reportDataBrand?.metrics || {};
      const { formattedMetrics, metric } = getFormattedMetrics(brandMetrics, metrics.value[0]);

      return {
        ...brand,
        isBrand: true,
        metric,
        formattedMetrics,
      };
    });
  });

  const reportGroupedByMetrics = computed(() => {
    return formatGroupedByReportData(
      reportChannel.value,
      reportData.value,
      metrics.value[0],
      aggregateBy.value,
    );
  });

  const reportMetricNumbers = computed(() => {
    return reportBrandsMetrics.value?.[0]?.metric;
  });

  const reportSortOrder = computed(() => {
    const defaultSortOrder = reportMetricDetails.value?.isNegativeMetric ? 'asc' : 'desc';
    return reportMeta.value?.sort_order ?? defaultSortOrder ?? 'desc';
  });

  const showDownloadReportImageButton = computed(() => visionAiReport.value);

  const thumbnailMode = computed(() => {
    if (visionAiReport.value) {
      return !isExpanded.value;
    }

    return false;
  });

  const visionAiImageDownloadButtonTooltip = computed(() => {
    return toolTips.visionAi.downloadButton;
  });

  const filteredBrands = computed(() => {
    return reportBrandsMetrics.value.filter(
      (brandMetrics) => reportPreview.value || brandMetrics.metric,
    );
  });

  const filteredCompetitors = computed(() => {
    return reportCompetitorsMetrics.value.filter(
      (competitorMetrics) => reportPreview.value || competitorMetrics.metric,
    );
  });

  const metricReportCombinedData = computed(() => {
    if (isMultiMediaTypeReport.value) {
      return [...reportMediaTypeMetrics.value];
    }
    if (isCompetitiveReport.value) {
      const combinedCompetitiveAndBrandItems = [
        ...filteredBrands.value,
        ...filteredCompetitors.value,
      ];
      if (
        reportCompetitorAverageMetrics.value &&
        filteredCompetitors.value.filter((competitor) => competitor.user_has_access).length > 1
      ) {
        combinedCompetitiveAndBrandItems.push(reportCompetitorAverageMetrics.value);
      }
      return combinedCompetitiveAndBrandItems;
    }
    if (isBenchmarkReport.value) {
      return [...filteredBrands.value, ...reportBenchmarksMetrics.value];
    }
    if (isTotalGroupedMetricReport.value) {
      if (reportIsGroupedByChannel.value) {
        return [...reportChannelsMetrics.value];
      }
      if (reportIsGroupedByBrand.value) {
        return [...reportBrandAggregated.value];
      }
    }
    if (isAdsTotalGroupedMetricReport.value) {
      if (reportIsGroupedByBrand.value) {
        return [...reportBrandAggregated.value];
      }
      return [...reportGroupedByMetrics.value];
    }
    return [...filteredBrands.value];
  });

  const sortedCombinedItems = computed(() => {
    const newCombinedItems = metricReportCombinedData.value;
    // gender reports should not have their sort order modified
    const sortOrder = reportIsGroupedByGender.value ? 'desc' : reportSortOrder.value;
    // if we are sorting ascending, then we want undefined values sorted last
    const defaultNumber =
      sortOrder === 'desc' ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;

    newCombinedItems.sort((a, b) => {
      const aMetric = a?.metric?.value ?? defaultNumber;
      const bMetric = b?.metric?.value ?? defaultNumber;
      if (aMetric < bMetric) {
        return 1;
      }
      if (aMetric > bMetric) {
        return -1;
      }
      if (a?.name && b?.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

    return sortOrder.toLowerCase() === 'asc' ? newCombinedItems.reverse() : newCombinedItems;
  });

  const loadingRows = computed(() => {
    return Array.from({ length: 4 }, () => {
      return {
        id: null,
        metric: {},
        metrics: [],
      };
    });
  });

  const metricReportRows = computed(() => {
    if (reportLoading.value) {
      return loadingRows.value;
    }
    // Performance issue when loading table report with hundreds or thousands of rows
    if (isAdsTotalGroupedMetricReport.value) {
      return sortedCombinedItems.value.slice(0, MAX_ADS_TABLE_REPORT_ROWS);
    }

    return sortedCombinedItems.value;
  });

  function canAccessData(id) {
    return reportData.value?.[id]?.user_has_access === true;
  }

  function handleDownloadReportImage(element, callBack) {
    if (element) {
      callBack();
    }
    try {
      downloadImage(element, IMAGE_DOWNLOAD_FILE_FORMATS.PNG.extension);
    } catch (error) {
      this.notificationStore.setToast({
        message: 'Failed to download widget image',
        type: 'error',
      });
    }
  }

  return {
    aggregateBy,
    isMultiBrand,
    isMultiChannel,
    isReport,
    dashboardId,
    reportHasData,
    reportId,
    reportType,
    reportMeta,
    reportData,
    channels,
    metrics,
    metricTypeReportKey,
    brandIds,
    brandTagIds,
    competitorTagIdsByBrand,
    contentTagIds,
    sourceCampaignIds,
    reportBrands,
    brandTags,
    reportCompetitors,
    competitorTagsByBrand,
    reportChannel,
    reportChannels,
    isMultiBrandOrCompetitiveReport,
    isMultiChannelReport,
    isInstagramReport,
    isFacebookCompetitiveReport,
    isInstagramCompetitiveReport,
    isFacebookReport,
    isMonetaryMetric,
    currency,
    hasMediaSubtypes,
    hasUGCMetrics,
    reportMediaTypeMetrics,
    reportBrandsMetrics,
    reportCompetitorsMetrics,
    reportCompetitorAverageMetrics,
    reportBenchmarksMetrics,
    reportChannelsMetrics,
    reportMetricNumbers,
    reportGroupedByMetrics,
    reportBrandAggregated,
    reportSortOrder,
    showDownloadReportImageButton,
    thumbnailMode,
    visionAiImageDownloadButtonTooltip,
    metricReportRows,
    filteredCompetitors,
    canAccessData,
    handleDownloadReportImage,
    visionAiReport,
  };
}
