import { computed, ref } from 'vue';
import { USER } from '@/models/auth/permissions.enum';
import { useAuthStore } from '@/stores/auth';
import { usePlatformStore } from '@/stores/platform';
import { useIdentityStore } from '@/stores/identity';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useFlagStore } from '@/stores/flag';

export function useTikTokCreatorMarketplace(brand) {
  const platformType = PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE;

  const authStore = useAuthStore();
  const platformStore = usePlatformStore();
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const brandToConnect = ref(brand.value ?? authStore.currentBrand);
  const brandId = ref(brandToConnect.value.id);

  const platformConnection = computed(() =>
    platformStore.getPlatformConnection(platformType, {
      brandId: brandId.value,
    }),
  );
  // computed
  const hasTTCMConnectionFlag = computed(
    () => flagStore.ready && flagStore.flags.tiktokCreatorMarketplaceConnection,
  );
  const hasValidPlatformConnection = computed(() =>
    platformStore.isPlatformConnected(platformType.value, { brandId: brandToConnect.value.id }),
  );
  const canManageTTCM = computed(() =>
    authStore.guard(USER.RELATIONSHIPS.CAN_MANAGE_TIKTOK_CREATOR_MARKETPLACE, brandToConnect.value),
  );
  const canEnableAccounts = computed(() => {
    const userIdThatConnectedTTCM = platformConnection.value?.connected_by ?? null;
    const currentUserConnectedTTCM =
      !userIdThatConnectedTTCM || identityStore.identity.id === userIdThatConnectedTTCM;
    return canManageTTCM.value && currentUserConnectedTTCM;
  });
  const canConnect = computed(() => {
    return canManageTTCM.value || hasValidPlatformConnection.value;
  });
  const hasMonitoredTTCMAccount = computed(() => {
    const accounts = platformStore.accounts[platformType.value]?.[brandId.value] || [];
    return accounts.some((account) => account.isMonitored);
  });

  return {
    brandToConnect,
    brandId,
    platformConnection,
    hasMonitoredTTCMAccount,
    hasTTCMConnectionFlag,
    hasValidPlatformConnection,
    canManageTTCM,
    canEnableAccounts,
    canConnect,
  };
}
