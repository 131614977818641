/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'googleAnalyticsBlackAndWhite': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g transform="translate(5.667 4.667)" _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M9.167 1.75v11c0 1.23.849 1.917 1.75 1.917.833 0 1.75-.584 1.75-1.917V1.833C12.667.705 11.833 0 10.917 0 10 0 9.167.777 9.167 1.75zM4.583 7.333v5.417c0 1.23.85 1.917 1.75 1.917.834 0 1.75-.584 1.75-1.917V7.417c0-1.129-.833-1.834-1.75-1.834-.916 0-1.75.778-1.75 1.75z"/><circle pid="1" cx="1.75" cy="12.917" r="1.75"/></g>'
  }
})
