<template>
  <div class="post-status-container">
    <Banner v-if="showInstagramAutoPublishScopeError" alert-type="danger" class="info-alert mb-8">
      This content did not go live because auto publish was disabled. To enable auto publishing for
      Instagram, please
      <Button link @click="onReconnect">reconnect</Button>
      your Facebook account.
    </Banner>
    <Banner
      v-else-if="postStatus"
      :alert-type="postStatus.type"
      class="info-alert mb-8"
      :action-text="postStatus.actionText"
      :action-to="postStatus.actionTo"
    >
      <span id="msgBox" v-sanitize-html="postStatus.message" />
      <a
        v-if="post.status === 'MISSING_APPROVALS'"
        target="_blank"
        @click="
          schedulerStore.toggleSidePanel({
            router: $router,
            platform: post?.platform,
            post: post,
          })
        "
      >
        <br />
        <br />
        Add Reviewers
      </a>
    </Banner>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { autoPublishErrors } from '@/app/scheduler/constants';
import Banner from '@/components/foundation/feedback/Banner.vue';
import Button from '@/components/foundation/Button.vue';
import { getPostStatusMessage, isOverdue, isFailed } from '@/app/scheduler/utils/post-status';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useSchedulerStore } from '@/stores/scheduler';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PostStatusBox',
  components: {
    Button,
    Banner,
  },
  props: {
    post: { type: Object, default: null },
  },
  computed: {
    ...mapStores(useAuthStore, usePlatformStore, useSchedulerStore),
    hasInstagramAutoPublishScope() {
      return this.platformStore.canAccessScope(PLATFORM_CONNECTION.FACEBOOK, [
        PLATFORM_CONNECTION.FACEBOOK.SCOPES.INSTAGRAM_CONTENT_PUBLISH,
      ]);
    },
    showInstagramAutoPublishScopeError() {
      return (
        this.post &&
        (isOverdue(this.post, { asThread: false }) || isFailed(this.post, { asThread: false })) &&
        this.post.platform === 'instagram' &&
        this.post?.auto_publish_error === autoPublishErrors.INVALID_AUTH &&
        !this.hasInstagramAutoPublishScope
      );
    },
    postStatus() {
      // We are not interested in the status of replies when displaying post
      // status within editors
      return this.post && getPostStatusMessage(this.post, { asThread: false, inEditor: true });
    },
  },
  methods: {
    onReconnect() {
      this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK, {
        title: 'Enable Auto Publishing',
        message: 'To enable auto publishing for Instagram, please reconnect your Facebook account.',
      });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.post-status-container {
  width: 100%;

  .info-alert {
    :deep(a) {
      color: var(--action-500);
      font-weight: var(--font-medium);
    }

    a.below-message {
      display: inline-block;
      margin-top: var(--space-8);
      color: var(--action-500);
      font-weight: var(--font-normal);
    }
  }

  :deep(.information-box) {
    margin-bottom: var(--space-16);
  }
}
</style>
