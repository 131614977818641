<script setup>
import Icon from '@/components/foundation/Icon.vue';
import { useCommentingStore } from '@/stores/commenting';
import { useNotificationStore } from '@/stores/notification';
import { computed } from 'vue';
import {
  discardCommentConfirmMessage,
  discardCommentConfirmTitle,
  discardConfirmButton,
} from '@/config';

import { useSchedulerStore } from '@/stores/scheduler';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const schedulerStore = useSchedulerStore();
const commentingStore = useCommentingStore();
const notificationStore = useNotificationStore();

// Check if there are approvers added to the post
const hasApprovers = computed(() => {
  return schedulerStore.approvalRequests?.length > 0;
});

// Determine the title based on the presence of approvers and flag status
const title = computed(() => {
  if (hasApprovers.value) {
    return 'Review & Discussion';
  }
  return 'Request Approval';
});

async function closePanel() {
  if (commentingStore?.hasUnsavedComments) {
    if (
      await notificationStore.confirm(discardCommentConfirmTitle, discardCommentConfirmMessage, {
        confirmAlias: discardConfirmButton,
      })
    ) {
      commentingStore.clearUnsavedComments();
      props.onClose();
    }
  } else {
    commentingStore.clearUnsavedComments();
    props.onClose();
  }
}
</script>

<template>
  <div class="text-primary flex items-center py-5 pl-6 pr-0 text-base leading-5">
    <Icon name="caret" dir="down" class="mr-2 cursor-pointer" @click="closePanel" />
    <span class="header">{{ title }}</span>
  </div>
</template>

<style lang="postcss" scoped>
.header {
  font-size: var(--x18);
}
</style>
