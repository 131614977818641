export const enumTypes = {
  // types
  ALL: 'ALL',
  UGC: 'UGC',
  OWN: 'OWN',
  // media
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  // sorts
  PUBLISHED_DATE: '-PUBLISHED_DATE',
  TOTAL_VISITS: '-TOTAL_VISITS',
  TOTAL_SAVES: '-TOTAL_SAVES',
  TOTAL_CLICKS: '-TOTAL_CLICKS',
  TOTAL_CLOSEUPS: '-TOTAL_CLOSEUPS',
  TOTAL_IMPRESSIONS: '-TOTAL_IMPRESSIONS',
  TOTAL_ENGAGEMENT: '-TOTAL_ENGAGEMENT',
  TOTAL_COMMENTS: '-TOTAL_COMMENTS',
  TOTAL_TRAFFIC: '-PINTEREST_TRAFFIC',
  TOTAL_VIDEO_VIEWS: '-TOTAL_VIDEO_VIEWS',
  AVERAGE_VIDEO_WATCH_TIME: '-AVERAGE_VIDEO_WATCH_TIME',
  MOST_RECENT: '-CREATED',
  // metrics
  VISITS: 'VISITS',
  TRAFFIC: 'TRAFFIC',
  CLICKS: 'CLICKS',
  LINK_CLICKS: 'LINK_CLICKS',
  SAVES: 'SAVES',
  IMPRESSIONS: 'IMPRESSIONS',
  ENGAGEMENTS: 'ENGAGEMENTS',
  ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
  ENGAGEMENT: 'ENGAGEMENT',
  CLOSEUPS: 'CLOSEUPS',
  COMMENTS: 'COMMENTS',
  VIDEO_VIEWS: 'VIDEO_VIEWS',
  // scales
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  // Pin Privacy
  PUBLIC: 'PUBLIC',
  PROTECTED: 'PROTECTED',
};

export const tooltips = {
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  connectCallToAction:
    "Dash Hudson brings you all of the insights you love, including Pinterest! Ask your Dash Hudson administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
};

export const enumLabels = {
  VISITS: 'Visits',
  TRAFFIC: 'Traffic',
  LINK_CLICKS: 'Outbound Clicks',
  SAVES: 'Saves',
  IMPRESSIONS: 'Impressions',
  ENGAGEMENT: 'Engagement Rate', // old engagement label
  ENGAGEMENT_RATE: 'Engagement Rate', // new engagement label
  ENGAGEMENTS: 'Engagements',
  CLOSEUPS: 'Pin Clicks',
  COMMENTS: 'Comments',
  VIDEO_VIEWS: 'Views',
};

export const pinUnavailableMessage = 'Pinterest failed to return any pins for this time period.';
export const pinMetricsUnavailableMessage =
  'Pinterest failed to return any pin metrics for this time period.';

export const pinterestMediaCardDefaults = {
  labels: {
    totalCloseups: 'Pin Clicks',
    engagementRate: 'Engagement Rate',
    totalImpressions: 'Impressions',
    totalClicks: 'Outbound Clicks',
    totalSaves: 'Saves',
    videoViews: 'Video Views',
    averageWatchTime: 'Average Watch Time',
  },
  metrics: ['engagementRate', 'totalSaves', 'totalImpressions', 'totalCloseups', 'videoViews'],
};

export const pinterestMetricsToCardDetails = {
  engagementRate: 'engagementRate',
  totalSaves: 'saves',
  totalImpressions: 'impressions',
  totalCloseups: 'closeups',
  totalClicks: 'linkClicks',
  videoViews: 'videoViews',
  averageWatchTime: 'averageWatchTime',
};

export const pinterestMediaCardSortToMetric = {
  '-TOTAL_SAVES': 'totalSaves',
  '-TOTAL_CLICKS': 'totalClicks',
  '-TOTAL_CLOSEUPS': 'totalCloseups',
  '-TOTAL_IMPRESSIONS': 'totalImpressions',
  '-TOTAL_ENGAGEMENT': 'engagementRate',
  '-TOTAL_VIDEO_VIEWS': 'videoViews',
  '-AVERAGE_VIDEO_WATCH_TIME': 'averageWatchTime',
};

export const pinterestConnectionMessages = {
  title: `Connect your Pinterest Account`,
  message: `To see account insights you are required to connect your Pinterest account.`,
};

export const routeNames = {
  YOUR_PINS: 'pinterest.insights.pins',
};

export const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

export const galleriesSortOptions = [
  { label: 'Recently Added', value: 'CREATED' },
  { label: 'Engagement Rate', value: 'TOTAL_ENGAGEMENT' },
  { label: 'Outbound Clicks', value: 'TOTAL_CLICKS' },
  { label: 'Saves', value: 'TOTAL_SAVES' },
];

export const pinterestGalleriesDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-DATE',
  },
  {
    label: 'Engagement Rate',
    value: '-PINTEREST_ENGAGEMENT',
    field: 'engagement',
  },
  {
    label: 'Outbound Clicks',
    value: '-PINTEREST_CLICKS',
    field: 'totalClicks',
  },
  {
    label: 'Saves',
    value: '-PINTEREST_SAVES',
    field: 'totalSaves',
  },
  {
    label: 'Impressions',
    value: '-PINTEREST_IMPRESSIONS',
    field: 'totalImpressions',
  },
  {
    label: 'Pin Clicks',
    value: '-PINTEREST_CLOSEUPS',
    field: 'totalCloseups',
  },
  {
    label: 'Video Views',
    value: '-PINTEREST_VIDEO_VIEWS',
    field: 'totalVideoViews',
  },
  {
    label: 'Average Watch Time',
    value: '-PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
    field: 'averageVideoWatchTime',
  },
];

export const pinterestGalleryCardDefaults = {
  metrics: ['pinterestAvgEngagementRate', 'pinterestTotalClicks', 'pinterestTotalSaves'],
  labels: {
    pinterestAvgEngagementRate: 'Avg. Engagement Rate',
    pinterestTotalClicks: 'Outbound Clicks',
    pinterestTotalSaves: 'Saves',
  },
};

export const pinterestMetricFormats = {
  pinterestAvgEngagementRate: 'percent',
};
