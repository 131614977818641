/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus-icon': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M5.33 9.333h1.334V6.666h2.667V5.333H6.664V2.666H5.331v2.667H2.664v1.333h2.667v2.667z" _fill="#4990E2"/><path pid="1" d="M10.667 0H1.333C.597 0 0 .597 0 1.333v9.334C0 11.403.597 12 1.333 12h9.334c.736 0 1.333-.597 1.333-1.333V1.333C12 .597 11.403 0 10.667 0zm-.002 10.667H1.333V1.333h9.334l-.002 9.334z" _fill="#4990E2"/>'
  }
})
