<template>
  <div class="filter-group">
    <div class="sort-select">
      <Select
        dropdown-label="Date Published"
        :date-range-value="dateRange"
        :options="dateOptions"
        :active="!dateValue"
        placeholder="All Time"
        type="calendar"
        :filter-group="!widgetMediaFilter"
        class="date-filter"
        data-cy="TimeFilterGroup"
        :options-style="dateRangeOptionStyle"
        @selected="dateChanged"
      />
    </div>

    <div v-if="!widgetMediaFilter" class="sort-select">
      <Select
        dropdown-label="Sort By"
        :model-value="sort"
        :disabled="sortDisabled"
        :options="sortOptions"
        @selected="sortChanged"
      />
    </div>
    <div v-if="showSortOrder">
      <SortOrderToggle :value="lowToHigh" @input="sortLowToHigh" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import Select from '@/components/Select.vue';
import { useSearchStore } from '@/stores/search';
import SortOrderToggle from '@/components/core/SortOrderToggle.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'TimeFilterGroup',
  components: {
    SortOrderToggle,
    Select,
  },
  inject: {
    widgetMediaFilter: { from: 'widgetMediaFilter', default: false },
  },
  props: {
    dateValue: { type: Object, default: null },
    filterValue: { type: Object, default: null },
    sortDisabled: { type: Boolean, default: false },
    sortOptions: { type: Array, default: null },
    page: { type: String, default: null },
    sort: { type: String, default: null },
    lowToHigh: { type: Boolean, default: false },
    showSortOrder: { type: Boolean, default: false },
    sendMixpanel: { type: Boolean, default: true },
  },
  emits: ['updateSort', 'updateResult', 'updateGallery', 'dateFilterSelected', 'updateLowToHigh'],
  data() {
    return {
      dateOptions: [
        { label: 'All Time', value: null },
        { label: 'Last 24 Hours', value: 1 },
        { label: 'Last 3 Days', value: 3 },
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 4 Weeks', value: 28 },
      ],
      dateRange: null,
    };
  },
  computed: {
    ...mapStores(useSearchStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'identity']),
    dateRangeOptionStyle() {
      if (this.widgetMediaFilter) {
        return ['right-align'];
      }
      return [];
    },
  },
  watch: {
    dateValue(to) {
      if (to && to.startDate && to.endDate) {
        this.dateRange = [to.startDate, to.endDate];
      } else {
        this.dateRange = null;
      }
    },
  },
  methods: {
    sortLowToHigh(sortOrder) {
      this.$emit('updateLowToHigh', sortOrder);
    },
    sortChanged(option) {
      this.$emit('updateSort', option);
      if (this.page === 'FindMedia') {
        this.$emit('updateResult');
      } else {
        this.searchStore.clearMediaSearchList();
        this.$emit('updateGallery');
      }
    },
    dateChanged(option) {
      this.$emit('dateFilterSelected', option);
      if (!this.sendMixpanel) return;
      if (option) {
        this.trackingStore.track('Search Filter Changed', {
          filter: 'Date',
          filterValue: option,
        });
      } else {
        this.trackingStore.track('Search Filter Changed', {
          filter: 'Date',
          filterValue: 'All time',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.filter-group {
  display: flex;

  .sort-select {
    margin-right: 10px;

    .date-published {
      padding-left: var(--space-12);
      margin-bottom: 0;
      color: var(--text-secondary);
      font-size: var(--x12);
      transform: translateY(10px);
    }

    .sort-by {
      padding-left: var(--space-16);
      margin-bottom: 0;
      color: var(--text-secondary);
      font-size: var(--x12);
      transform: translateY(10px);
    }
  }

  .filter-dropdown {
    position: relative;

    button {
      height: 2.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--background-0);
      font-size: var(--x14);
      color: var(--text-primary);
      border-radius: var(--button-border-radius);
      border: 1px solid var(--border);
      margin-right: var(--space-8);
      padding: 0 var(--space-16);
      text-transform: capitalize;
      cursor: pointer;
      transition: var(--transition-all);

      &:hover {
        color: var(--action-500);
      }
    }

    &.open {
      button {
        color: var(--action-500);
        border-color: var(--action-500);
      }
    }
  }
}
</style>
