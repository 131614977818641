/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'list-view': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M4.833.667c.461 0 .834.373.834.833v3.333c0 .46-.373.834-.834.834H1.5a.834.834 0 01-.833-.834V1.5c0-.46.373-.833.833-.833h3.333zM4 4V2.333H2.333V4H4zm.833 2.5c.461 0 .834.373.834.833v3.334c0 .46-.373.833-.834.833H1.5a.834.834 0 01-.833-.833V7.333c0-.46.373-.833.833-.833h3.333zM4 9.833V8.167H2.333v1.666H4zm.833 2.5c.461 0 .834.374.834.834V16.5c0 .46-.373.833-.834.833H1.5a.834.834 0 01-.833-.833v-3.333c0-.46.373-.834.833-.834h3.333zM4 15.667V14H2.333v1.667H4zM7.333 2.333h10V4h-10V2.333zm0 5.834h10v1.666h-10V8.167zm0 5.833h10v1.667h-10V14z" _fill="#666" fill-rule="nonzero"/>'
  }
})
