/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'benchmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><clipPath id="svgicon_benchmark_a"><path pid="0" d="M188 0v154H0V0h188z"/></clipPath><clipPath id="svgicon_benchmark_b"><path pid="1" d="M12 0c1.104 0 2 .897 2 2v17a1 1 0 01-1.555.832L7 16.202l-5.446 3.63A1 1 0 010 19V2C0 .897.896 0 2 0zm0 2H2v15.132l4.446-2.964a1 1 0 011.109 0L12 17.132V2z"/></clipPath><clipPath id="svgicon_benchmark_c"><path pid="2" d="M4 0l1 3h3L6 5l1 3-3-2-3 2 1-3-2-2h3l1-3z"/></clipPath></defs><g clip-path="url(#svgicon_benchmark_a)" transform="translate(-40 -84)"><g clip-path="url(#svgicon_benchmark_b)" transform="translate(45 86)"><path pid="3" _fill="#686a7a" d="M0 0h14v20H0V0z"/></g><g clip-path="url(#svgicon_benchmark_c)" transform="translate(48 90)"><path pid="4" _fill="#686a7a" d="M0 0h8v8H0V0z"/></g></g>'
  }
})
