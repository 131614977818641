<template>
  <div
    :class="[loaderSizeClass, { white: white, 'input-circular-loader': isInSearchInput }]"
    class="loader"
    data-cy="circular-loader"
  >
    <svg :class="{ 'circular-s': small }" class="circular" viewBox="25 25 50 50">
      <circle
        cx="50"
        cy="50"
        r="10"
        class="path"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { BRAND } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CircularLoader',
  props: {
    isInSearchInput: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    color: { type: String, default: BRAND.BRAND_ACCENT },
  },
  computed: {
    loaderSizeClass() {
      if (this.large) {
        return 'w-24';
      }
      if (this.small) {
        return 'w-12';
      }
      return '';
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.loader {
  position: relative;
  margin: var(--space-64) auto;
  width: 4rem;
}

.loader::before {
  content: '';
  display: block;
  padding-top: 150%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  inset: 0;
  margin: auto;

  &.input-circular-loader {
    position: unset;
  }
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation:
    dash 1.5s ease-in-out infinite,
    color 3s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: v-bind(color);
  }

  40% {
    stroke: v-bind(color);
  }

  66% {
    stroke: v-bind(color);
  }

  80%,
  90% {
    stroke: v-bind(color);
  }
}

@keyframes grey-color {
  100%,
  0% {
    stroke: var(--text-secondary);
  }

  40% {
    stroke: var(--text-secondary);
  }

  66% {
    stroke: var(--text-secondary);
  }

  80%,
  90% {
    stroke: var(--text-secondary);
  }
}

@keyframes color-w {
  0%,
  100% {
    stroke: rgb(255 255 255 / 80%);
  }
}

.white {
  .path {
    animation:
      dash 1.5s ease-in-out infinite,
      color-w 6s ease-in-out infinite;
  }
}

.input-circular-loader {
  &.loader {
    position: absolute;
    width: 2.25rem;
    z-index: 99;
    margin: 0 0 0 9.5rem !important;

    .circular {
      position: unset;
      margin-top: -1px;

      .path {
        animation:
          dash 1.5s ease-in-out infinite,
          grey-color 3s ease-in-out infinite;
      }
    }
  }

  &.loader::before {
    padding-top: 0;
  }
}
</style>
