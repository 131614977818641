<template>
  <div ref="dropzoneElement" data-cy="dropzone-element">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { EXIF } from 'exif-js';

import { mapState as mapPiniaState, mapStores } from 'pinia';
import Dropzone from 'dropzone';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { axios } from '@/apis/library';
import { UPLOAD_STATUS } from '@/config';
import enumTypes from '@/app/library/constants';
import SocketsMixin from '@/mixins/socketsMixin';
import { useSocketStore } from '@/stores/socket';
import { logger } from '@/utils/logger';

window.EXIF = EXIF;

Dropzone.autoDiscover = false;

const imageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/tiff',
  'image/heic',
  'image/heif',
];
const videoTypes = [
  'video/mp4',
  'video/avi',
  'video/quicktime',
  'video/mov',
  'video/webm',
  'video/m4v',
  'video/MP4V-ES',
];

const fileTypes = {
  ...imageTypes.reduce((o, key) => ({ ...o, [key]: 'img' }), {}),
  ...videoTypes.reduce((o, key) => ({ ...o, [key]: 'video' }), {}),
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Dropzone',
  mixins: [SocketsMixin],
  props: {
    thumbnail: { type: Boolean, default: true },
    disableClick: { type: Boolean, default: false },
    itemsChanged: { type: Function, required: true },
    skipPrediction: { type: Boolean, default: false },
    acceptVideo: { type: Boolean, default: true },
    maxFiles: { type: Number, default: null },
    deferUpload: { type: Boolean, default: false, required: false },
    deferredUploadInitiated: { type: Boolean, default: false, required: false },
    brandMediaMeta: { type: Object, default: () => {} },
    mediaUploadMeta: { type: Object, default: () => {} },
    /**
     * Shape of object passed to itemsChanged callback:
     *
     * [{
     *   id: Integer
     *   width: Integer
     *   height: Integer
     *   uploadProgress: Integer (0-100)
     *   uploadStatus: String (init|pending|success|failed)
     *   url: String (base64-encoded image)
     * }, ...]
     */
    brandIds: { type: Array, default: () => [] },
  },
  emits: ['mediaAddedToBrand', 'brandMediaCreated'],
  data() {
    return {
      items: [],
      fileIndex: {},
    };
  },
  computed: {
    ...mapStores(useSocketStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'identity']),
    isUploadingToCurrentBrandOnly() {
      return this.brandIds.length === 1 && this.brandIds[0] === this.currentBrand.id;
    },
  },
  watch: {
    items: {
      handler(val) {
        this.itemsChanged(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setupDropzone();
    this.selectedFiles = [];
  },
  methods: {
    findItemById(media) {
      return this.items.find((_item) => _item.id === media.id);
    },
    openFileDialog() {
      this.dropzone.hiddenFileInput.click();
    },
    reset() {
      this.dropzone.removeAllFiles(true);
      this.items = [];
    },
    remove(ids) {
      this.items = this.items.filter((item) => !ids.includes(item.id));
    },
    setupDropzone() {
      const options = {
        // The URL will be changed for each new file being processing
        url: '/',

        // Since we're going to do a `PUT` upload to S3 directly
        method: 'put',

        // Hijack the xhr.send since Dropzone always upload file by using formData
        // ref: https://github.com/danialfarid/ng-file-upload/issues/743
        sending: (file, xhr) => {
          const originalSend = xhr.send;
          xhr.send = () => {
            originalSend.call(xhr, file);
          };
        },

        // Maximum upload file size in mb
        maxFilesize: 1024,

        // Maximum number of files to allow uploaded at once
        maxFiles: this.maxFiles,

        // When the filesize exceeds this limit, the thumbnail will not be generated (in mb)
        maxThumbnailFilesize: 30,

        clickable: !this.disableClick,

        // Upload one file at a time since we're using the S3 pre-signed URL scenario
        parallelUploads: 1,
        uploadMultiple: false,
        timeout: 3600000, // 60 minutes
        // Content-Type should be included, otherwise you'll get a signature
        // mismatch error from S3. We're going to update this for each file.
        header: '',

        // Customize the wording
        dictDefaultMessage: '',

        // We're going to process each file manually (see `accept` below)
        autoProcessQueue: false,

        // We're using our own 'preview'
        previewsContainer: false,

        acceptedFiles: this.acceptVideo
          ? imageTypes.concat(videoTypes).join(', ')
          : imageTypes.join(', '),
        // Append hidden input to dropzone element (default is 'body') so that click-outside handlers
        // aren't accidentally triggered when input is clicked in openFileDialog() method below.
        hiddenInputContainer: this.$refs.dropzoneElement,

        thumbnailWidth: this.thumbnail ? 200 : null,
        thumbnailHeight: this.thumbnail ? 200 : null,
        thumbnailMethod: 'contain',

        accept: this.dropzoneAccept,
        uploadprogress: this.dropzoneUploadProcess,
        complete: this.dropzoneComplete,
        success: this.dropzoneSuccess,
      };

      // Instantiate Dropzone
      this.dropzone = new Dropzone(this.$refs.dropzoneElement, options);
      this.dropzone.on('thumbnail', this.onDropzoneThumbnail);
      this.dropzone.on('processing', this.onDropzoneProcessing);
      this.dropzone.on('success', this.onDropzoneSuccess);
    },
    dropzoneAccept(file, done) {
      if (this.deferUpload && this.deferredUploadInitiated) {
        return;
      }
      // Here we request a signed upload URL when a file being accepted
      const mediaItem = {
        uploadStatus: UPLOAD_STATUS.INIT,
        uploadProgress: 0,
        url: '',
        width: 500,
        height: 500,
        filename: file.name,
      };
      this.fileIndex[file.upload.uuid] = this.items.length;
      this.items.push(mediaItem);

      if (fileTypes[file.type] === 'video') {
        // Dropzone won't automatically emit for non-images
        this.dropzone.emit('thumbnail', file, window.URL.createObjectURL(file));
      }

      if (this.deferUpload) {
        this.selectedFiles.push({ file, done, mediaItem });
      } else {
        this.uploadFile(file, done, mediaItem);
      }
    },
    uploadAllFiles() {
      this.selectedFiles.forEach((item) => {
        this.items[this.fileIndex[item.file.upload.uuid]].uploadStatus = UPLOAD_STATUS.PENDING;
        this.uploadFile(item.file, item.done, item.mediaItem);
      });
    },
    uploadFile(file, done, mediaItem) {
      const params = { filename: file.name, content_type: file.type };
      axios
        .post(`/media_upload_url`, params)
        .then((res) => {
          const newFile = file;
          newFile.uploadURL = res.data;
          done();
          setTimeout(() => this.dropzone.processFile(newFile));
        })
        .catch((err) => {
          done('Failed to get an S3 signed upload URL', err);
          mediaItem.uploadStatus = UPLOAD_STATUS.FAILED;
        });
    },
    dropzoneUploadProcess(file, progress) {
      /**
       * Overriding default UI Behaviour to allow the progress bar to show during analysis
       */
      const item = this.items[this.fileIndex[file.upload.uuid]];
      item.uploadProgress = progress;
      item.uploadStatus = UPLOAD_STATUS.UPLOADING;
    },
    dropzoneComplete(file) {
      // Overriding default Behaviour
      // see: https://gitlab.com/meno/dropzone/blob/master/src/dropzone.js for reference
      if (file._removeLink) {
        file._removeLink.innerHTML = this.options.dictRemoveFile;
      }
      if (file.status === 'error') {
        const targetFile = this.items[this.fileIndex[file.upload.uuid]];
        if (targetFile) {
          this.items[this.fileIndex[file.upload.uuid]].uploadStatus = UPLOAD_STATUS.FAILED;
        }
      }
    },
    dropzoneSuccess(file) {
      // Overriding default Behaviour
      // see: https://gitlab.com/meno/dropzone/blob/master/src/dropzone.js for reference
      const item = this.items[this.fileIndex[file.upload.uuid]];
      if (this.skipPrediction) {
        item.uploadStatus = UPLOAD_STATUS.SUCCESS;
      } else {
        item.uploadStatus = UPLOAD_STATUS.PROCESSING;
      }
      this.trackingStore.track('Media Uploaded', {
        brandIdsIncluded: this.mediaUploadMeta?.brandIdsIncluded,
        brandNamesIncluded: this.mediaUploadMeta?.brandNamesIncluded,
        brandTagIdsIncluded: this.mediaUploadMeta?.brandTagIdsIncluded,
        brandTagNamesIncluded: this.mediaUploadMeta?.brandTagNamesIncluded,
        brandTagNamesAmount: this.mediaUploadMeta?.brandTagNamesAmount,
        numberOfBrands: this.mediaUploadMeta?.numberOfBrands,
        approvedPublishDateIncluded:
          !!this.brandMediaMeta?.canPublishWithin?.start &&
          !!this.brandMediaMeta?.canPublishWithin?.end,
        approvedPublishDateTimeZone: this.mediaUploadMeta?.timezone ?? null,
        publishStartDate: this.brandMediaMeta?.canPublishWithin?.start ?? null,
        publishEndDate: this.brandMediaMeta?.canPublishWithin?.end ?? null,
      });
    },
    onDropzoneThumbnail(file, dataURL) {
      if (this.deferUpload && this.deferredUploadInitiated) {
        return;
      }
      // Sometimes dataURL is returns from dropzone as an event, we don't want those
      if (!(dataURL instanceof Event)) {
        const index = this.fileIndex[file.upload.uuid];
        const item = this.items[index];
        item.width = file.width ?? item.width;
        item.height = file.height ?? item.height;
        item.url = dataURL;
        item.fileTag = fileTypes[file.type];
        this.items.splice(index, 1, { ...item }); // trigger update
        if (item.uploadStatus !== UPLOAD_STATUS.SUCCESS && !this.deferUpload) {
          item.uploadStatus = UPLOAD_STATUS.PENDING;
        }
      }
    },
    onDropzoneProcessing(file) {
      // Set signed upload URL for each file
      this.dropzone.options.url = file.uploadURL;
    },
    onDropzoneSuccess(data) {
      const metadata = {
        filename: data.name,
        uploaded_by: this.identity.id,
        last_modified_at: data.lastModifiedDate,
      };
      if (data.fullPath) {
        metadata.fullPath = data.fullPath;
      }
      axios
        .post('/media', {
          url: data.uploadURL.split('?')[0],
          source: 'UPLOAD',
          source_id: data.upload.uuid,
          type: 'UPLOADED',
          source_created_at: new Date(),
          brand_media_meta: this.brandMediaMeta,
          meta: metadata,
          socket_id: this.socketStore?.id,
          brand_ids: this.brandIds,
        })
        .then((res) => {
          this.$emit('brandMediaCreated', res.data);

          const item = this.items[this.fileIndex[data.upload.uuid]];
          const resData = res.data[0];
          item.id = resData.mediaId;
          if (resData.imageSizes) {
            const { imageSizes: sizeMap } = resData;
            item.size = sizeMap.originalConverted && sizeMap.originalConverted.size;
          }
          if (resData.mediaType === 'VIDEO') {
            item.url = resData.urls.thumbs;
            item.mediaType = 'VIDEO';
            item.fileTag = 'img';
          }
          // If dropzone is unable to generate a thumbnail, we assign the one created in Library
          if (resData.mediaType === 'IMAGE' && !item.url) {
            item.url = resData.urls.thumbs;
            item.mediaType = 'IMAGE';
            item.fileTag = 'img';
          }
          // need to do this in order to trigger the watcher.
          this.items.splice(this.fileIndex[data.upload.uuid], 1, { ...item });
          this.trackingStore.track('Library upload content', { mediaId: item.id });

          if (!this.isUploadingToCurrentBrandOnly) {
            this.items[this.fileIndex[data.upload.uuid]].uploadStatus = UPLOAD_STATUS.SUCCESS;
          }
          this.$emit('mediaAddedToBrand', item.id);
          setTimeout(() => {
            if (this.items[this.fileIndex[data.upload.uuid]]) {
              this.items[this.fileIndex[data.upload.uuid]].uploadStatus = UPLOAD_STATUS.SUCCESS;
            }
          }, 300000); // 5 Minutes
        })
        .catch(() => {
          this.items[this.fileIndex[data.upload.uuid]].uploadStatus = UPLOAD_STATUS.FAILED;
        });
    },
    onConvertVideoCallbackSocket(videoData) {
      if (videoData.source !== enumTypes.UPLOAD) {
        return;
      }
      axios.get(`/brands/${this.brandIds[0]}/media/${videoData.id}`).then((file) => {
        const item = this.findItemById(videoData);
        if (item) {
          Object.assign(
            (item.fullMediaObject = {}),
            { urls: file.data.urls },
            { video_sizes: file.data.videoSizes },
          );
          Object.assign(item, {
            uploadStatus: UPLOAD_STATUS.SUCCESS,
            duration: videoData.duration,
          });
          if (this.isUploadingToCurrentBrandOnly) {
            Object.assign(item, { predictions: 'processing' });
          }
        }
      });
    },
    onMediaProcessedSocket(data) {
      const item = this.findItemById(data);
      // if the data id doesn't match any of the item ids, there is nothing relevant to be updated
      if (!item) {
        return;
      }
      // there was an error uploading the media
      if (data.status !== 'success') {
        Object.assign(item, { uploadStatus: UPLOAD_STATUS.FAILED, predictions: null });
        return;
      }
      if (this.isUploadingToCurrentBrandOnly) {
        // Only include predictions if media solely for current brand
        // HACK: Setting it back to processing before switching to success
        // to make sure that the watch gets triggered.
        Object.assign(item, {
          uploadStatus: UPLOAD_STATUS.PROCESSING,
          predictions: data.predictions,
        });
      }
      Object.assign(item, { uploadStatus: UPLOAD_STATUS.SUCCESS });
    },
  },
  sockets: {
    convert_video_callback(...args) {
      logger.info('convert_video_callback websocket received.');
      this.onConvertVideoCallbackSocket(...args);
    },
    media_processed(...args) {
      this.onMediaProcessedSocket(...args);
    },
  },
});
export default comp;
</script>
