<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import AddInputItem from '@/components/foundation/form/AddInputItem.vue';
import { useNotificationStore } from '@/stores/notification';
import { maxCollaborators, maxUserTags, tooManyUserTagsMessage } from '@/app/scheduler/constants';

const emit = defineEmits(['tagAdded']);

const props = defineProps({
  placeholderText: {
    type: String,
    default: 'Type Instagram Handle',
  },
  mediaType: { type: String, default: 'IMAGE' },
});

const instagramUserTaggerStore = useInstagramUserTaggerStore();
const notificationStore = useNotificationStore();

const tag = ref('');
const addTagInput = ref(null);
function checkExistingTags() {
  if (instagramUserTaggerStore.editingUserTag) {
    if (instagramUserTaggerStore.activeMediaTaggedUsers.some((u) => u.username === tag.value)) {
      return `You've already tagged ${tag.value}. Please enter another user.`;
    }
  } else if (instagramUserTaggerStore.collaborators.some((u) => u.username === tag.value)) {
    return `You've already invited ${tag.value}. Please enter another user.`;
  }
  return null;
}

const tagValidationError = computed(() => {
  const userHasValidCharacters = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/.test(tag.value);
  if (tag.value.length > 0 && !userHasValidCharacters) {
    return 'This username contains invalid characters.';
  }
  return checkExistingTags(tag);
});

const disabled = computed(() => {
  /*
   * For media user tags, it allows clicking on the media to attempt adding a new one after 20
   * maximum is hit, whereas for collaborators, it should disable the input directly once 20 maximum
   * is hit. Therefore, the situations for the input disabled states are different
   *
   */
  const { editingUserTag, collaborators, totalUserTags } = instagramUserTaggerStore;
  const maxUserTagsReached = totalUserTags >= maxUserTags;
  const maxCollaboratorsReached = !editingUserTag && collaborators.length >= maxCollaborators;

  if (props.mediaType === 'VIDEO') {
    return maxCollaboratorsReached || maxUserTagsReached;
  }

  if (props.mediaType === 'IMAGE') {
    return (
      maxCollaboratorsReached ||
      (editingUserTag && totalUserTags > maxUserTags) ||
      (!editingUserTag && maxUserTagsReached)
    );
  }
  return false;
});

async function tagSaved() {
  if (!tagValidationError.value && tag.value !== '') {
    await nextTick(() => emit('tagAdded', tag.value));
    tag.value = '';
  }
}

const addTagTooltip = computed(() => {
  return disabled.value && notificationStore.toasts.length === 0 ? tooManyUserTagsMessage : null;
});

onMounted(() => {
  addTagInput.value.focus();
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div class="people-tag-item">
    <input
      ref="addTagInput"
      v-model="tag"
      v-tooltip="addTagTooltip"
      :disabled="disabled"
      class="m-auto h-10 w-full rounded border border-solid bg-[length:16px_16px] py-3 pl-10 pr-6 text-sm leading-[18px]"
      :class="{
        'border-[#E36042]': tagValidationError,
        'border-[#ddd]': !tagValidationError,
        'focus:border-[#4990e2]': !tagValidationError,
      }"
      :maxLength="30"
      :placeholder="props.placeholderText"
      @keydown.enter.prevent="tagSaved"
      @blur="tagSaved"
    />
    <AddInputItem
      :show="tag !== '' && !tagValidationError"
      container=".people-tag-item"
      @add="tagSaved"
    />
    <p v-if="tagValidationError" class="mt-2 text-left text-xs text-[#E36042]">
      {{ tagValidationError }}
    </p>
  </div>
</template>

<style lang="postcss" scoped>
input {
  background: url('@/assets/icons/atSymbol.svg') var(--background-0) no-repeat 1rem center;
}
</style>
