/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'graphBarStatus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 21.999v-13a1 1 0 00-1-1h-4a1 1 0 00-1 1v13h-1v-7a1 1 0 00-1-1h-4a1 1 0 00-1 1v7H8v-10a1 1 0 00-1-1H3a1 1 0 00-1 1v10H0v2h24v-2h-2zm-18 0v-9h2v9H4zm7 0v-6h2v6h-2zm7 0v-12h2v12h-2z"/>'
  }
})
