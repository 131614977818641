/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'videosBottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20 14v5.586l2.293-2.293 1.414 1.414L19 23.414l-4.707-4.707 1.414-1.414L18 19.586V14h2zM13.414 0L18 4.586v7.42h-2V6h-4V2H2v18h10v2H2a2.002 2.002 0 01-1.995-1.85L0 20V2C0 .948.817.082 1.85.006L2 0h11.414zM6 9l7 4-7 4V9z" id="svgicon_videosBottom_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_videosBottom_b" _fill="#fff"><use xlink:href="#svgicon_videosBottom_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_videosBottom_a"/><g mask="url(#svgicon_videosBottom_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
