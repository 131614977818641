import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import uniq from 'lodash/uniq';

const MEDIA_SELECT_MODES = {
  DISABLED: 'disabled',
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

export const useMediaSelectStore = defineStore('mediaSelect', () => {
  const mediaSelectMode = ref(MEDIA_SELECT_MODES.MULTIPLE);
  const multiSelectBarEnabled = ref(true);
  const selectedItems = ref([]);
  const targetBrands = ref([]);
  const associatedBrandMedia = ref([]);

  const isSingleMode = computed(() => mediaSelectMode.value === MEDIA_SELECT_MODES.SINGLE);
  const isMultipleMode = computed(() => mediaSelectMode.value === MEDIA_SELECT_MODES.MULTIPLE);
  const isDisabledMode = computed(() => mediaSelectMode.value === MEDIA_SELECT_MODES.DISABLED);
  const multiSelectSelectedItems = computed(() =>
    selectedItems.value.filter((item) => {
      return !item.empty;
    }),
  );
  const isTargetingBrands = computed(() => {
    return targetBrands.value.length > 0;
  });

  function setSingleMode() {
    mediaSelectMode.value = MEDIA_SELECT_MODES.SINGLE;
  }

  function setMultipleMode() {
    mediaSelectMode.value = MEDIA_SELECT_MODES.MULTIPLE;
  }

  function setDisabledMode() {
    mediaSelectMode.value = MEDIA_SELECT_MODES.DISABLED;
  }

  function enableMultiSelectBar() {
    multiSelectBarEnabled.value = true;
  }

  function disableMultiSelectBar() {
    multiSelectBarEnabled.value = false;
  }

  function setMultiSelectSelectedItems(items) {
    selectedItems.value = [...items];
  }

  function addItemToMultiSelect({ item, items }) {
    let newList = [...selectedItems.value];
    if (item) {
      newList = [...newList, item];
    }
    if (Array.isArray(items)) {
      newList = [...newList, ...items];
    }
    selectedItems.value = uniq(newList);
  }

  function dragSelectableSelectedSetter({ els, additive }) {
    const items = els.map((el) => el.item);
    if (!additive) {
      setMultiSelectSelectedItems(items);
    } else {
      selectedItems.value = uniq([...selectedItems.value, ...items]);
    }
  }

  function removeItemFromMultiSelect({ item }) {
    selectedItems.value = selectedItems.value.filter((selectedItem) => item.id !== selectedItem.id);
  }

  function clearItemsFromMultiSelect() {
    selectedItems.value = [];
  }

  return {
    mediaSelectMode,
    isSingleMode,
    isMultipleMode,
    isDisabledMode,
    multiSelectBarEnabled,
    multiSelectSelectedItems,
    setSingleMode,
    setMultipleMode,
    setDisabledMode,
    enableMultiSelectBar,
    disableMultiSelectBar,
    dragSelectableSelectedSetter,
    setMultiSelectSelectedItems,
    addItemToMultiSelect,
    removeItemFromMultiSelect,
    clearItemsFromMultiSelect,
    targetBrands,
    isTargetingBrands,
    associatedBrandMedia,
  };
});
