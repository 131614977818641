/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'twitterBadge': {
    width: 256,
    height: 256,
    viewBox: '0 0 256 256',
    data: '<g stroke-width="1" stroke-miterlimit="10" _fill="#000"><path pid="0" d="M71.347 66.065l92.45 123.583h20.934L92.282 66.065z"/><path pid="1" d="M127.857 1.407c-69.837 0-126.45 56.613-126.45 126.45s56.613 126.45 126.45 126.45 126.45-56.613 126.45-126.45-56.613-126.45-126.45-126.45zm31 198.116l-42.089-56.276-52.091 56.276h-13.39l59.499-64.276L51.66 56.19h45.57l38.76 51.828 47.973-51.828h13.39l-55.38 59.83 62.452 83.503h-45.57z"/></g>'
  }
})
