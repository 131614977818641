/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chatCheck': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M11.998 18h-10a.998.998 0 01-.732-1.68l2.306-2.484C2.539 12.392 1.998 10.735 1.998 9c0-4.963 4.486-9 10-9s10 4.037 10 9-4.486 9-10 9zm-7.707-2h7.707c4.411 0 8-3.14 8-7 0-3.859-3.589-7-8-7s-8 3.141-8 7c0 1.537.568 3 1.642 4.232a1 1 0 01-.021 1.338L4.291 16zm7.021-2.289L7.305 11.04l1.386-2.08 1.994 1.329 4.296-6.016 2.034 1.454-5.703 7.984z" id="svgicon_chatCheck_a"/></defs><g transform="translate(0 3)" _fill="none" fill-rule="evenodd"><mask id="svgicon_chatCheck_b" _fill="#fff"><use xlink:href="#svgicon_chatCheck_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_chatCheck_a"/><g mask="url(#svgicon_chatCheck_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0-2h24v24H0z"/></g></g>'
  }
})
