/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar-hourglass': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 8H2v10h7v1.8c0 .069.007.135.02.2H2c-1.103 0-2-.897-2-2V5c0-1.102.897-2 2-2h2v2h1V0h2v3h7v1.8h-4a1 1 0 00-1 1V8zm10.99-3.2A2.003 2.003 0 0018 3h-1V0h-2v4.8h4.99z" _fill="#686a7a"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.995 6.194V7.78c0 .578-.234 1.143-.643 1.552l-3.441 3.442 3.441 3.442c.415.414.643.965.643 1.552v1.587a.731.731 0 01-.731.731h-8.775a.732.732 0 01-.731-.731v-1.588c0-.586.228-1.137.643-1.55l3.441-3.443-3.441-3.442a2.206 2.206 0 01-.643-1.551V6.194c0-.404.327-.732.731-.732h8.775c.404 0 .731.328.731.732zm-1.463 12.43v-.855a.724.724 0 00-.214-.517l-3.442-3.443-3.442 3.442a.727.727 0 00-.214.518v.856h7.312zm0-10.842a.737.737 0 01-.214.517l-3.442 3.442-3.442-3.442a.737.737 0 01-.214-.517v-.857h7.312v.857zM14.876 15.7l-2.194 2.193h4.387L14.876 15.7z" _fill="#686a7a"/>'
  }
})
