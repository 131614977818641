import { downloadFileFromUrl } from '@/utils';

export default {
  computed: {},
  methods: {
    getDownloadableMediaUrl(media) {
      // When video/image cropping was built, for some reason the original url saved for images
      // is the cropped version, and for videos it is the original, uncropped. In order for users
      // to download their cropped video, we need the original_converted url.
      if (media.type === 'VIDEO' && media.source === 'EDITOR') {
        return (
          media.sizes?.originalConverted.url || media.fullMediaObject.sizes.originalConverted.url
        );
      }
      return media.sizes?.original.url || media.fullMediaObject.sizes.original.url;
    },
    async downloadMedia(mediaUrl) {
      const ext = mediaUrl.split('.').pop().toLowerCase();
      if (/(gif|jpe?g|png|mp4|webp)$/.test(ext)) {
        // If media extension is supported
        downloadFileFromUrl(mediaUrl, `download.${ext}`);
        return true;
      }
      return false;
    },
  },
};
