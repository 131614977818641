<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ButtonToggle',
  provide() {
    return {
      register: this.register,
      unregister: this.unregister,
    };
  },
  props: {
    value: {
      type: [Number, String, Object],
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      internalValue: null,
      items: [],
    };
  },
  computed: {
    selectedIndex() {
      return this.selectedItem ? this.items.indexOf(this.selectedItem) : -1;
    },
    selectedItem() {
      return this.selectedItems?.length > 0 ? this.selectedItems[0] : null;
    },
    selectedItems() {
      return this.items.filter((item, index) => {
        return this.toggleMethod(this.getValue(item, index));
      });
    },
    selectedValues() {
      if (this.internalValue == null) return [];
      return Array.isArray(this.internalValue) ? this.internalValue : [this.internalValue];
    },
    toggleMethod() {
      return (v) => this.internalValue === v;
    },
  },
  watch: {
    value: {
      handler() {
        this.internalValue = this.value;
      },
      immediate: true,
    },
    internalValue(newValue) {
      this.updateItemsState();
      this.$emit('input', newValue);
    },
    items() {
      this.updateItemsState();
    },
  },
  methods: {
    register(item) {
      item.$el.addEventListener('click', () => this.onClick(item));
      this.items.push(item);
      this.items.sort((a, b) => {
        return this.$slots.default().indexOf(a.$vnode) - this.$slots.default().indexOf(b.$vnode);
      });

      if (this.mandatory && !this.selectedValues.length) {
        this.updateMandatory();
      }
      const index = this.items.indexOf(item);
      this.updateItem(item, index);
    },
    unregister(item) {
      const index = this.items.indexOf(item);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    },
    onClick(item) {
      const value = this.getValue(item, this.items.indexOf(item));
      this.updateInternalValue(value);
    },
    getValue(item, index) {
      return item?.value ?? index;
    },
    updateMandatory(last) {
      if (!this.items.length) return;

      const items = this.items.slice();

      if (last) items.reverse();

      const item = items.find((i) => !i.disabled);

      if (!item) return;

      const index = this.items.indexOf(item);

      this.updateInternalValue(this.getValue(item, index));
    },
    updateInternalValue(value) {
      const isSame = value === this.internalValue;

      if (this.mandatory && isSame) return;

      this.internalValue = isSame ? undefined : value;
    },
    updateItem(item, index) {
      const value = this.getValue(item, index);

      item.isGroupItemActive = this.toggleMethod(value);
    },
    updateItemsState() {
      nextTick(() => {
        if (this.mandatory && !this.selectedItems.length) {
          this.updateMandatory();
        } else {
          this.items.forEach(this.updateItem);
        }
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.button-group {
  display: flex;

  :deep(.button) {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    &:not(:first-child, :last-child) {
      border-radius: 0;
    }
  }
}
</style>
