import dayjs from 'dayjs';
import { TOPICS_QUERY_PARAM_MAP } from '@/app/socialListening/constants';
import { useAuthStore } from '@/stores/auth';

export function formatCustomSelectDropdownLabel(start, end, format) {
  const startLabel = dayjs(start).format(format);
  const endLabel = dayjs(end).format(format);
  return `${startLabel} - ${endLabel}`;
}

export function formatTopicQueryParamsFromSourceLink(query) {
  const authStore = useAuthStore();
  const tempFilters = {
    visualData: {
      includes: [],
      doesNotInclude: [],
    },
  };
  const sentiment = {};

  // format the values from query param to be used in the filters pinia store object
  [TOPICS_QUERY_PARAM_MAP.SOURCES, TOPICS_QUERY_PARAM_MAP.MEDIA_TYPES].forEach((key) => {
    if (query[key]) {
      tempFilters[key] = query[key];
    }
  });
  [
    TOPICS_QUERY_PARAM_MAP.POSITIVE_SENTIMENT,
    TOPICS_QUERY_PARAM_MAP.NEUTRAL_SENTIMENT,
    TOPICS_QUERY_PARAM_MAP.NEGATIVE_SENTIMENT,
  ].forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      sentiment[key] = query[key] === true;
    }
  });
  [TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_INCLUDE, TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_EXCLUDE].forEach(
    (key) => {
      if (query[key]) {
        if (key.includes('include') || key.includes('exclude')) {
          const visualData = query[key];
          const targetArray = key.includes('include')
            ? tempFilters.visualData.includes
            : tempFilters.visualData.doesNotInclude;

          visualData.forEach((e) => {
            const keyName = e.split(':')[0].includes(authStore.currentBrand?.id.toString())
              ? 'libraryMediaDetails'
              : 'sourceAndId';
            targetArray.push({ [keyName]: e });
          });
        }
      }
    },
  );

  if (Object.keys(sentiment).length > 0) {
    Object.keys(sentiment).forEach((key) => {
      if (sentiment[key] === true) {
        tempFilters.sentiment = sentiment;
      }
    });
  }
  return tempFilters;
}

export function formatTopicQueryParamsVisualDataForSourceLink(queryParams) {
  const formattedVisualDataQueryParams = {
    includeVisualDataSourceAndId: [],
    excludeVisualDataSourceAndId: [],
  };

  [TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_INCLUDE, TOPICS_QUERY_PARAM_MAP.VISUAL_DATA_EXCLUDE].forEach(
    (key) => {
      if (queryParams[key]) {
        if (key.includes('include') || key.includes('exclude')) {
          const sourceAndIds = queryParams[key];
          const targetArray = key.includes('include')
            ? formattedVisualDataQueryParams.includeVisualDataSourceAndId
            : formattedVisualDataQueryParams.excludeVisualDataSourceAndId;

          sourceAndIds.forEach((e) => {
            const keyName = Object.keys(e)[0];
            targetArray.push(e[keyName]);
          });
        }
      }
    },
  );
  return formattedVisualDataQueryParams;
}
