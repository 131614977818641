/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'facebookWhite': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><path pid="0" d="M29.49 16.079c0-7.408-6.004-13.412-13.411-13.412-7.408 0-13.412 6.004-13.412 13.412 0 6.694 4.904 12.242 11.316 13.249v-9.373h-3.405V16.08h3.405v-2.955c0-3.362 2.002-5.218 5.066-5.218 1.467 0 3.002.262 3.002.262v3.3H20.36c-1.666 0-2.186 1.034-2.186 2.095v2.516h3.72l-.595 3.876h-3.125v9.373c6.412-1.007 11.317-6.555 11.317-13.25" id="svgicon_facebookWhite_a"/></defs><g transform="translate(-2 -2)" _fill="none" fill-rule="evenodd" opacity=".7"><mask id="svgicon_facebookWhite_b" _fill="#fff"><use xlink:href="#svgicon_facebookWhite_a"/></mask><use _fill="#FFFFFE" xlink:href="#svgicon_facebookWhite_a"/><g mask="url(#svgicon_facebookWhite_b)" _fill="#FFF" fill-rule="nonzero"><path pid="1" d="M0 0h32v32H0z"/></g></g>'
  }
})
