/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sync': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 4a7.939 7.939 0 00-5.649 2.351L9 9H3V3l1.938 1.938A9.928 9.928 0 0112 2c5.514 0 10 4.485 10 10h-2c0-4.411-3.589-8-8-8zm0 16a7.937 7.937 0 005.649-2.351L15 15h6v6l-1.937-1.938A9.93 9.93 0 0112 22C6.486 22 2 17.515 2 12h2c0 4.411 3.589 8 8 8z" _fill="#686a7a"/><mask id="svgicon_sync_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12 4a7.939 7.939 0 00-5.649 2.351L9 9H3V3l1.938 1.938A9.928 9.928 0 0112 2c5.514 0 10 4.485 10 10h-2c0-4.411-3.589-8-8-8zm0 16a7.937 7.937 0 005.649-2.351L15 15h6v6l-1.937-1.938A9.93 9.93 0 0112 22C6.486 22 2 17.515 2 12h2c0 4.411 3.589 8 8 8z" _fill="#fff"/></mask><g mask="url(#svgicon_sync_a)"><path pid="2" _fill="#686a7a" d="M0 0h24v24H0z"/></g>'
  }
})
