/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'campaigns': {
    width: 21,
    height: 18,
    viewBox: '0 0 21 18',
    data: '<defs><path pid="0" d="M17.615.212a1 1 0 00-.857-.182L4.877 3H2a2 2 0 00-2 2v4c0 1.103.896 2 2 2h2v2a1 1 0 00.2.6l3 4 1.6-1.2L6 12.668v-1.385l10.758 2.689a.996.996 0 00.857-.183A1 1 0 0018 13V1c0-.31-.143-.6-.385-.79zM2 5h2v4H2V5zm14 6.719L6 9.22V4.782l10-2.5v9.438zm3-7.535v5.63a2.994 2.994 0 002-2.813 2.994 2.994 0 00-2-2.817z" id="svgicon_campaigns_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_campaigns_a"/>'
  }
})
