/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'eyeOpen': {
    width: 26,
    height: 17,
    viewBox: '0 0 26 17',
    data: '<defs><path pid="0" d="M13 0c5.306 0 10.007 3.193 11.928 7.96l.15.373-.15.374c-1.921 4.766-6.622 7.96-11.928 7.96-5.306 0-10.007-3.194-11.928-7.96l-.15-.374.15-.373C2.993 3.193 7.694 0 13 0zm0 14.667c4.347 0 8.206-2.53 9.913-6.334C21.206 4.53 17.347 2 13 2S4.794 4.53 3.087 8.333c1.707 3.805 5.566 6.334 9.913 6.334zm0-1.445c-2.76 0-5-2.19-5-4.889 0-2.698 2.24-4.889 5-4.889s5 2.19 5 4.89c0 2.698-2.24 4.888-5 4.888zm0-6.889c-1.107 0-2 .894-2 2 0 1.107.893 2 2 2s2-.893 2-2c0-1.106-.893-2-2-2z" id="svgicon_eyeOpen_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_eyeOpen_a"/>'
  }
})
