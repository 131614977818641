import { reactive, toRefs } from 'vue';

const state = reactive({
  expanded: window.innerWidth > 1280,
  mounted: false,
  hidden: false,
});

export function useSecondaryNavigationPanel() {
  function expandNavigationPanel() {
    state.expanded = true;
  }

  function collapseNavigationPanel() {
    state.expanded = false;
  }

  function toggleNavigationPanel() {
    if (state.expanded) {
      collapseNavigationPanel();
    } else {
      expandNavigationPanel();
    }
  }

  function setMounted(value) {
    state.mounted = value;
  }

  function setSecondaryNavHidden() {
    state.hidden = true;
  }

  function setSecondaryNavVisible() {
    state.hidden = false;
  }

  function toggleSecondaryNavHidden(val = null) {
    // Can pass in a value to determine or use inner state
    const boolValue = val === null ? state.hidden : val;
    if (boolValue) {
      setSecondaryNavVisible();
    } else {
      setSecondaryNavHidden();
    }
  }

  return {
    ...toRefs(state),
    toggleNavigationPanel,
    collapseNavigationPanel,
    expandNavigationPanel,
    setMounted,
    setSecondaryNavHidden,
    setSecondaryNavVisible,
    toggleSecondaryNavHidden,
  };
}
