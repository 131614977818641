<template>
  <Card class="chart-container">
    <CardTitle :tooltip="cardTitleTooltip">
      <template v-if="multiMetric">
        <Select
          :options="metricOptions"
          :model-value="activeMetric"
          class="metric-select"
          data-cy="metrics-chart-metric-select"
          @selected="handleSelection"
        />
      </template>
      <template v-else>
        {{ title }}
      </template>
      <template #right>
        <slot name="topRight">
          <SkeletonLoader :loading="loading" :type="skeletonTypes.title">
            <div class="chart-stats">
              <ChartStatistic
                v-for="(stat, index) in chartStatistics"
                :key="index"
                :insert-divider="index > 0"
                :title="chartStatistics[index]['title']"
                :value="chartStatistics[index]['value']"
                :tooltip-value="chartStatistics[index]['tooltipValue']"
              />
            </div>
          </SkeletonLoader>
        </slot>
      </template>
    </CardTitle>
    <CardContent>
      <SkeletonLoader :loading="loading" :type="skeletonTypes.chart" class="chart-loader">
        <slot />
      </SkeletonLoader>
    </CardContent>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';
import ChartStatistic from '@/components/Metrics/ChartStatistic.vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import Select from '@/components/Select.vue';
import { SKELETON_TYPES } from '@/config';
import { colours } from '@/ux/colours';
import Card from '@/components/foundation/Card.vue';
import CardContent from '@/components/foundation/CardContent.vue';
import CardTitle from '@/components/foundation/CardTitle.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MetricsChart',
  components: {
    Card,
    CardContent,
    CardTitle,
    ChartStatistic,
    SkeletonLoader,
    Select,
  },
  props: {
    chartStatistics: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    includeTooltip: { type: Boolean, default: true },
    title: { type: String, required: true },
    tooltip: { type: String, default: '' },
    metricOptions: { type: Array, default: () => [] },
    selectedMetric: { type: String, default: null },
  },
  emits: ['dropdown-changed'],
  data() {
    return {
      iconColor: colours.ICON.ICON_SECONDARY,
      internalMetric: null,
    };
  },
  computed: {
    cardTitleTooltip() {
      return this.includeTooltip ? this.tooltip : null;
    },
    skeletonTypes() {
      return SKELETON_TYPES;
    },
    multiMetric() {
      return this.metricOptions.length > 1;
    },
    activeMetric() {
      return this.selectedMetric || this.metricOptions[0]?.value;
    },
  },
  methods: {
    handleSelection(newIndex) {
      this.$emit('dropdown-changed', this.metricOptions[newIndex].value);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.chart-container {
  margin: var(--space-32) 0;
  margin-bottom: 0;
  min-height: 322px;

  .chart-stats {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
    margin-bottom: var(--space-28);
  }

  .metric-select {
    margin-left: 0;

    :deep(.picker button) {
      line-height: normal;
      font-size: var(--x18);
      padding-left: 0;
      padding-right: 1.375rem;
      height: auto;
      white-space: nowrap;
      overflow: hidden;
      background-position: right 0 center;
    }
  }

  .metrics-tooltip {
    margin-left: var(--space-12);
    margin-right: var(--space-4);
  }
}
</style>
