const SCOPE = Object.freeze({
  USER: {
    value: 'user',
  },
  BRAND: {
    value: 'brand',
  },
  ORGANIZATION: {
    value: 'organization',
  },
  ORGANIZATION_USER: {
    value: 'organization_user',
  },
});

const MODULE = Object.freeze({
  GENERAL: {
    value: 'general',
  },
  ADS: {
    value: 'ads',
  },
  FACEBOOK: {
    value: 'facebook',
  },
  INSTAGRAM: {
    value: 'instagram',
  },
  COMMUNITY: {
    value: 'community',
  },
  LINKEDIN: {
    value: 'linkedin',
  },
  LIBRARY: {
    value: 'library',
  },
  PINTEREST: {
    value: 'pinterest',
  },
  SCHEDULER: {
    value: 'scheduler',
  },
  TIKTOK: {
    value: 'tiktok',
  },
  TWITTER: {
    value: 'twitter',
  },
  VISION: {
    value: 'vision',
  },
  YOUTUBE: {
    value: 'youtube',
  },
  CAMPAIGNS: {
    value: 'campaigns',
  },
  RELATIONSHIPS: {
    value: 'relationships',
  },
  SETTINGS: {
    value: 'settings',
  },
  COMPETITIVE: {
    value: 'competitive',
  },
  GOOGLE_ANALYTICS: {
    value: 'google_analytics',
  },
  SOCIAL_LISTENING: {
    value: 'social_listening',
  },
  DASHBOARD: {
    value: 'dashboard',
  },
  TAGS: {
    value: 'tags',
  },
});

export const USER = Object.freeze({
  GENERAL: {
    CAN_ACCESS_CSV: {
      scope: SCOPE.USER,
      module: MODULE.GENERAL,
      value: 'can_access_csv',
    },
    CAN_ACCESS_UTM_SETTINGS: {
      scope: SCOPE.USER,
      module: MODULE.GENERAL,
      value: 'can_access_utm_settings',
    },
  },
  ADS: {
    CAN_ACCESS_AD_BOARDS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_access_ad_boards',
    },
    CAN_ACCESS_AD_PREDICTIONS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_access_ad_predictions',
    },
    CAN_ACCESS_ADS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_access_ads',
    },
    CAN_ACCESS_TIKTOK_ADS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_access_tiktok_ads',
    },
    CAN_DISCONNECT_FB_ADS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_disconnect_fb_ads',
    },
    CAN_ACCESS_FB_AD_ACCOUNTS_MANAGER: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_access_fb_ad_accounts_manager',
    },
    CAN_MANAGE_TIKTOK_ADS: {
      scope: SCOPE.USER,
      module: MODULE.ADS,
      value: 'can_manage_tiktok_ads',
    },
  },
  LINKEDIN: {
    CAN_ACCESS_LINKEDIN_INSIGHTS: {
      scope: SCOPE.USER,
      module: MODULE.LINKEDIN,
      value: 'can_access_linkedin_insights',
    },
  },
  FACEBOOK: {
    CAN_DISCONNECT_FB: {
      scope: SCOPE.USER,
      module: MODULE.FACEBOOK,
      value: 'can_disconnect_fb',
    },
    CAN_ACCESS_FB_INSIGHTS: {
      scope: SCOPE.USER,
      module: MODULE.FACEBOOK,
      value: 'can_access_fb_insights',
    },
  },
  INSTAGRAM: {
    CAN_ACCESS_ACCOUNT_OVERVIEW: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_account_overview',
    },
    CAN_ACCESS_BOARDS: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_boards',
    },
    CAN_ACCESS_COMMUNITY_UGC: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_community_ugc',
    },
    CAN_ACCESS_EMV: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_emv',
    },
    CAN_ACCESS_INSTAGRAM: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_instagram',
    },
    CAN_ACCESS_LIKESHOP: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_likeshop',
    },
    CAN_ACCESS_LIKESHOP_EMAIL_CAPTURE: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_likeshop_email_capture',
    },
    CAN_ACCESS_RELATIONSHIPS: {
      scope: SCOPE.USER,
      module: MODULE.RELATIONSHIPS,
      value: 'can_access_instagram_relationships',
    },
    CAN_ACCESS_RIGHTS_REQUESTS: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_rights_requests',
    },
    CAN_ACCESS_RIQ_NEW_FOLLOWERS: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_riq_new_followers',
    },
    CAN_ACCESS_SEARCH: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_search',
    },
    CAN_ACCESS_STORIES: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_stories',
    },
    CAN_ACCESS_STORIES_SWIPEUP: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_stories_swipeup',
    },
    CAN_ACCESS_UGC_MORE: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_ugc_more',
    },
    CAN_ACCESS_YOUR_FOLLOWERS: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_your_followers',
    },
    CAN_ACCESS_YOUR_POSTS: {
      scope: SCOPE.USER,
      module: MODULE.INSTAGRAM,
      value: 'can_access_your_posts',
    },
  },
  COMMUNITY: {
    CAN_ACCESS_COMMUNITY: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_community',
    },
    CAN_ACCESS_COMMUNITY_OVERVIEW: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_community_overview',
    },
    CAN_ACCESS_INSTAGRAM_DIRECT: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_instagram_direct',
    },
    CAN_ACCESS_INSTAGRAM_COMMENTS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_instagram_comments',
    },
    CAN_ACCESS_TIKTOK_COMMENTS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_tiktok_comments',
    },
    CAN_ACCESS_FACEBOOK_MESSENGER: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_facebook_messenger',
    },
    CAN_ACCESS_FACEBOOK_COMMENTS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_facebook_comments',
    },
    CAN_ACCESS_TWITTER_MENTIONS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_twitter_mentions',
    },
    CAN_ACCESS_TWITTER_DIRECT: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_twitter_direct',
    },
    CAN_ACCESS_TEXT_ANALYSIS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_text_analysis',
    },
    CAN_ACCESS_YOUTUBE_COMMENTS: {
      scope: SCOPE.USER,
      module: MODULE.COMMUNITY,
      value: 'can_access_youtube_comments',
    },
  },
  LIBRARY: {
    CAN_ACCESS_PRODUCT_WIDGET: {
      scope: SCOPE.USER,
      module: MODULE.LIBRARY,
      value: 'can_access_product_widget',
    },
    CAN_ACCESS_SHOPPABLE_GALLERIES: {
      scope: SCOPE.USER,
      module: MODULE.LIBRARY,
      value: 'can_access_shoppable_galleries',
    },
    CAN_EDIT_GALLERIES: {
      scope: SCOPE.USER,
      module: MODULE.LIBRARY,
      value: 'can_edit_galleries',
    },
    CAN_EDIT_PUBLISH_DATES: {
      scope: SCOPE.USER,
      module: MODULE.LIBRARY,
      value: 'can_edit_publish_dates',
    },
  },
  PINTEREST: {
    CAN_ACCESS_PINT: {
      scope: SCOPE.USER,
      module: MODULE.PINTEREST,
      value: 'can_access_pint',
    },
    CAN_ACCESS_PINT_BOARDS: {
      scope: SCOPE.USER,
      module: MODULE.PINTEREST,
      value: 'can_access_pint_boards',
    },
    CAN_DISCONNECT_PINT: {
      scope: SCOPE.USER,
      module: MODULE.PINTEREST,
      value: 'can_disconnect_pint',
    },
  },
  SCHEDULER: {
    CAN_ACCESS_SCHEDULER: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler',
    },
    CAN_ACCESS_SCHEDULER_TIKTOK: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_tiktok',
    },
    CAN_ACCESS_SCHEDULER_FB: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_fb',
    },
    CAN_ACCESS_SCHEDULER_IG: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_ig',
    },
    CAN_ACCESS_SCHEDULER_PINT: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_pint',
    },
    CAN_ACCESS_SCHEDULER_TWIT: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_twit',
    },
    CAN_AUTO_PUBLISH_TIKTOK: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_tiktok',
    },
    CAN_AUTO_PUBLISH_FACEBOOK: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_facebook',
    },
    CAN_AUTO_PUBLISH_INSTAGRAM: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_instagram',
    },
    CAN_AUTO_PUBLISH_PINTEREST: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_pinterest',
    },
    CAN_AUTO_PUBLISH_TWIT: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_twit',
    },
    CAN_REVIEW_SCHEDULED_POST: {
      scope: SCOPE.USER,
      module: MODULE.SCHEDULER,
      value: 'can_review_scheduled_post',
    },
  },
  TIKTOK: {
    CAN_ACCESS_TRENDING_SOUNDS: {
      scope: SCOPE.USER,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_trending_sounds',
    },
    CAN_ACCESS_TIKTOK_INSIGHTS: {
      scope: SCOPE.USER,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_insights',
    },
    CAN_ACCESS_TIKTOK_LIKESHOP: {
      scope: SCOPE.USER,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_likeshop',
    },
    CAN_DISCONNECT_TIKTOK: {
      scope: SCOPE.USER,
      module: MODULE.TIKTOK,
      value: 'can_disconnect_tiktok',
    },
  },
  TWITTER: {
    CAN_ACCESS_TWITTER_INSIGHTS: {
      scope: SCOPE.USER,
      module: MODULE.TWITTER,
      value: 'can_access_twitter_insights',
    },
    CAN_DISCONNECT_TWITTER: {
      scope: SCOPE.USER,
      module: MODULE.TWITTER,
      value: 'can_disconnect_twitter',
    },
  },
  VISION: {
    CAN_ACCESS_VIQ: {
      scope: SCOPE.USER,
      module: MODULE.VISION,
      value: 'can_access_viq',
    },
    CAN_ACCESS_VISION: {
      scope: SCOPE.USER,
      module: MODULE.VISION,
      value: 'can_access_vision',
    },
    CAN_ACCESS_VISION_AI: {
      scope: SCOPE.USER,
      module: MODULE.VISION,
      value: 'can_access_vision_ai',
    },
    CAN_ACCESS_VISION_AI_CONVERSATIONAL: {
      scope: SCOPE.USER,
      module: MODULE.VISION,
      value: 'can_access_vision_ai_conversational',
    },
  },
  YOUTUBE: {
    CAN_ACCESS_YOUTUBE_INSIGHTS: {
      scope: SCOPE.USER,
      module: MODULE.YOUTUBE,
      value: 'can_access_youtube_insights',
    },
  },
  CAMPAIGNS: {
    CAN_ACCESS_CAMPAIGNS: {
      scope: SCOPE.USER,
      module: MODULE.CAMPAIGNS,
      value: 'can_access_campaigns',
    },
    CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS: {
      scope: SCOPE.USER,
      module: MODULE.CAMPAIGNS,
      value: 'can_access_campaigns_google_analytics',
    },
  },
  COMPETITIVE: {
    CAN_ACCESS_COMPETITORS: {
      scope: SCOPE.USER,
      module: MODULE.COMPETITIVE,
      value: 'can_access_competitors',
    },
  },
  GOOGLE_ANALYTICS: {
    CAN_DISCONNECT_GOOGLE_ANALYTICS: {
      scope: SCOPE.USER,
      module: MODULE.GOOGLE_ANALYTICS,
      value: 'can_disconnect_google_analytics',
    },
  },
  LISTENING: {
    CAN_ACCESS_SOCIAL_LISTENING: {
      scope: SCOPE.USER,
      module: MODULE.SOCIAL_LISTENING,
      value: 'can_access_social_listening',
    },
  },
  DASHBOARD: {
    CAN_ACCESS_DASHBOARDS: {
      scope: SCOPE.USER,
      module: MODULE.DASHBOARD,
      value: 'can_access_dashboards',
    },
  },
  RELATIONSHIPS: {
    CAN_MANAGE_TIKTOK_CREATOR_MARKETPLACE: {
      scope: SCOPE.USER,
      module: MODULE.RELATIONSHIPS,
      value: 'can_manage_tiktok_creator_marketplace',
    },
  },
});

export const BRAND = Object.freeze({
  ADS: {
    CAN_ACCESS_AD_BOARDS: {
      scope: SCOPE.BRAND,
      module: MODULE.ADS,
      value: 'can_access_ad_boards',
    },
    CAN_ACCESS_ADS: {
      scope: SCOPE.BRAND,
      module: MODULE.ADS,
      value: 'can_access_ads',
    },
    CAN_ACCESS_TIKTOK_ADS: {
      scope: SCOPE.BRAND,
      module: MODULE.ADS,
      value: 'can_access_tiktok_ads',
    },
  },
  COMMUNITY: {
    CAN_ACCESS_COMMUNITY: {
      scope: SCOPE.BRAND,
      module: MODULE.COMMUNITY,
      value: 'can_access_community',
    },
    CAN_ACCESS_COMMUNITY_OVERVIEW: {
      scope: SCOPE.BRAND,
      module: MODULE.COMMUNITY,
      value: 'can_access_community_overview',
    },
    CAN_ACCESS_COMMUNITY_COLLABORATION: {
      scope: SCOPE.BRAND,
      module: MODULE.COMMUNITY,
      value: 'can_access_community_collaboration',
    },
    CAN_ACCESS_TEXT_ANALYSIS: {
      scope: SCOPE.BRAND,
      module: MODULE.COMMUNITY,
      value: 'can_access_text_analysis',
    },
    CAN_ACCESS_INBOX_AUTOMATION: {
      scope: SCOPE.BRAND,
      module: MODULE.COMMUNITY,
      value: 'can_access_inbox_automation',
    },
  },
  LINKEDIN: {
    CAN_ACCESS_LINKEDIN_INSIGHTS: {
      scope: SCOPE.BRAND,
      module: MODULE.LINKEDIN,
      value: 'can_access_linkedin_insights',
    },
  },
  FACEBOOK: {
    CAN_ACCESS_FB_INSIGHTS: {
      scope: SCOPE.BRAND,
      module: MODULE.FACEBOOK,
      value: 'can_access_fb_insights',
    },
  },
  GENERAL: {
    CAN_ACCESS_CSV: {
      scope: SCOPE.BRAND,
      module: MODULE.GENERAL,
      value: 'can_access_csv',
    },
    CAN_ACCESS_SENTIMENT: {
      scope: SCOPE.BRAND,
      module: MODULE.GENERAL,
      value: 'can_access_sentiment',
    },
  },
  INSTAGRAM: {
    BOARDS_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'boards_limit',
    },
    CAN_ACCESS_ACCOUNT_OVERVIEW: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_account_overview',
    },
    CAN_ACCESS_BOOSTED_POSTS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_boosted_posts',
    },
    CAN_ACCESS_COMMUNITY_UGC: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_community_ugc',
    },
    CAN_ACCESS_EMV: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_emv',
    },
    CAN_ACCESS_INSTAGRAM: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_instagram',
    },
    CAN_ACCESS_LIKESHOP_EMAIL_CAPTURE: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_likeshop_email_capture',
    },
    CAN_ACCESS_NON_BUSINESS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_non_business',
    },
    CAN_ACCESS_RELATIONSHIPS: {
      scope: SCOPE.BRAND,
      module: MODULE.RELATIONSHIPS,
      value: 'can_access_instagram_relationships',
    },
    CAN_ACCESS_RIGHTS_REQUESTS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_rights_requests',
    },
    CAN_ACCESS_RIQ_NEW_FOLLOWERS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_riq_new_followers',
    },
    CAN_ACCESS_STORIES_SWIPEUP: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_stories_swipeup',
    },
    CAN_ACCESS_UGC_MORE: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_ugc_more',
    },
    CAN_ACCESS_VIQ_TRENDS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_viq_trends',
    },
    CAN_ACCESS_YOUR_FOLLOWERS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_your_followers',
    },
    CAN_ACCESS_YOUR_POSTS: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'can_access_your_posts',
    },
    HASHTAGS_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'hashtags_limit',
    },
    LOCATIONS_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'locations_limit',
    },
    STORY_BOARDS_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'story_boards_limit',
    },
    VIQ_ACCOUNTS_PER_TREND_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'viq_accounts_per_trend_limit',
    },
    VIQ_TREND_GROUP_LIMIT: {
      scope: SCOPE.BRAND,
      module: MODULE.INSTAGRAM,
      value: 'viq_trend_group_limit',
    },
  },
  LIBRARY: {
    CAN_ACCESS_PRODUCT_WIDGET: {
      scope: SCOPE.BRAND,
      module: MODULE.LIBRARY,
      value: 'can_access_product_widget',
    },
    CAN_ACCESS_SHOPPABLE_GALLERIES: {
      scope: SCOPE.BRAND,
      module: MODULE.LIBRARY,
      value: 'can_access_shoppable_galleries',
    },
    CAN_ACCESS_LIBRARY: {
      scope: SCOPE.BRAND,
      module: MODULE.LIBRARY,
      value: 'can_access_library',
    },
    CAN_ACCESS_PUBLISHING_DATES_AND_BULK_UPLOADING: {
      scope: SCOPE.BRAND,
      module: MODULE.LIBRARY,
      value: 'can_access_publishing_dates_and_bulk_uploading',
    },
  },
  PINTEREST: {
    CAN_ACCESS_PINT: {
      scope: SCOPE.BRAND,
      module: MODULE.PINTEREST,
      value: 'can_access_pint',
    },
  },
  SCHEDULER: {
    CAN_ACCESS_SCHEDULER_TIKTOK: {
      scope: SCOPE.BRAND,
      module: MODULE.SCHEDULER,
      value: 'can_access_scheduler_tiktok',
    },
    CAN_AUTO_PUBLISH_FACEBOOK: {
      scope: SCOPE.BRAND,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_facebook',
    },
    CAN_AUTO_PUBLISH_INSTAGRAM: {
      scope: SCOPE.BRAND,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_instagram',
    },
    CAN_AUTO_PUBLISH_TWIT: {
      scope: SCOPE.BRAND,
      module: MODULE.SCHEDULER,
      value: 'can_auto_publish_twit',
    },
  },
  SETTINGS: {
    CAN_ACCESS_TEAMS_AND_PERMISSIONS: {
      scope: SCOPE.BRAND,
      module: MODULE.SETTINGS,
      value: 'can_access_teams_and_permissions',
    },
    CAN_ACCESS_API_KEYS: {
      scope: SCOPE.BRAND,
      module: MODULE.SETTINGS,
      value: 'can_access_api_keys',
    },
    CAN_ACCESS_INTEGRATIONS: {
      scope: SCOPE.BRAND,
      module: MODULE.SETTINGS,
      value: 'can_access_integrations',
    },
    CAN_ACCESS_CONTENT_AUTOMATION: {
      scope: SCOPE.BRAND,
      module: MODULE.SETTINGS,
      value: 'can_access_content_automations',
    },
    CAN_ACCESS_CUSTOM_METRICS: {
      scope: SCOPE.BRAND,
      module: MODULE.SETTINGS,
      value: 'can_access_custom_metrics',
    },
  },
  TIKTOK: {
    CAN_ACCESS_TRENDING_SOUNDS: {
      scope: SCOPE.BRAND,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_trending_sounds',
    },
    CAN_ACCESS_TIKTOK_INSIGHTS: {
      scope: SCOPE.BRAND,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_insights',
    },
    CAN_ACCESS_TIKTOK_LIKESHOP: {
      scope: SCOPE.BRAND,
      module: MODULE.TIKTOK,
      value: 'can_access_tiktok_likeshop',
    },
  },
  TWITTER: {
    CAN_ACCESS_TWITTER_INSIGHTS: {
      scope: SCOPE.BRAND,
      module: MODULE.TWITTER,
      value: 'can_access_twitter_insights',
    },
  },
  VISION: {
    CAN_ACCESS_VIQ: {
      scope: SCOPE.BRAND,
      module: MODULE.VISION,
      value: 'can_access_viq',
    },
    CAN_ACCESS_VISION: {
      scope: SCOPE.BRAND,
      module: MODULE.VISION,
      value: 'can_access_vision',
    },
    CAN_ACCESS_VISION_AI_COMMUNITY: {
      scope: SCOPE.BRAND,
      module: MODULE.VISION,
      value: 'can_access_vision_ai_community',
    },
    CAN_ACCESS_VISION_AI_DASHBOARDS: {
      scope: SCOPE.BRAND,
      module: MODULE.VISION,
      value: 'can_access_vision_ai_dashboards',
    },
  },
  YOUTUBE: {
    CAN_ACCESS_YOUTUBE_INSIGHTS: {
      scope: SCOPE.BRAND,
      module: MODULE.YOUTUBE,
      value: 'can_access_youtube_insights',
    },
  },
  CAMPAIGNS: {
    CAN_ACCESS_CAMPAIGNS: {
      scope: SCOPE.BRAND,
      module: MODULE.CAMPAIGNS,
      value: 'can_access_campaigns',
    },
    CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS: {
      scope: SCOPE.BRAND,
      module: MODULE.CAMPAIGNS,
      value: 'can_access_campaigns_google_analytics',
    },
  },
  COMPETITIVE: {
    CAN_ACCESS_COMPETITORS: {
      scope: SCOPE.BRAND,
      module: MODULE.COMPETITIVE,
      value: 'can_access_competitors',
    },
  },
  LISTENING: {
    CAN_ACCESS_SOCIAL_LISTENING: {
      scope: SCOPE.BRAND,
      module: MODULE.SOCIAL_LISTENING,
      value: 'can_access_social_listening',
    },
  },
  DASHBOARD: {
    CAN_ACCESS_DASHBOARDS: {
      scope: SCOPE.BRAND,
      module: MODULE.DASHBOARD,
      value: 'can_access_dashboards',
    },
    CAN_ACCESS_INDUSTRY_BENCHMARKS: {
      scope: SCOPE.BRAND,
      module: MODULE.DASHBOARD,
      value: 'can_access_industry_benchmarks',
    },
  },
  TAGS: {
    CAN_ACCESS_BRAND_TAGS: {
      scope: SCOPE.BRAND,
      module: MODULE.TAGS,
      value: 'can_access_brand_tags',
    },
    CAN_ACCESS_COMPETITOR_TAGS: {
      scope: SCOPE.BRAND,
      module: MODULE.TAGS,
      value: 'can_access_competitor_tags',
    },
    CAN_ACCESS_CONTENT_TAGS: {
      scope: SCOPE.BRAND,
      module: MODULE.TAGS,
      value: 'can_access_content_tags',
    },
  },
});

export const ORGANIZATION = Object.freeze({
  LISTENING: {
    CAN_ACCESS_SOCIAL_LISTENING: {
      scope: SCOPE.ORGANIZATION,
      module: MODULE.SOCIAL_LISTENING,
      value: 'can_access_social_listening',
    },
    CAN_ACCESS_VISION_AI_SOCIAL_LISTENING: {
      scope: SCOPE.ORGANIZATION,
      module: MODULE.SOCIAL_LISTENING,
      value: 'can_access_vision_ai_social_listening',
    },
  },
});

export const ORGANIZATION_USER = Object.freeze({
  LISTENING: {
    CAN_ACCESS_SOCIAL_LISTENING: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.SOCIAL_LISTENING,
      value: 'can_access_social_listening',
    },
  },
  TAGS: {
    CAN_MANAGE_BRAND_TAGS: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.TAGS,
      value: 'can_manage_brand_tags',
    },
    CAN_MANAGE_COMPETITOR_TAGS: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.TAGS,
      value: 'can_manage_competitor_tags',
    },
    CAN_MANAGE_CONTENT_TAGS: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.TAGS,
      value: 'can_manage_content_tags',
    },
  },
  SETTINGS: {
    CAN_MANAGE_CUSTOM_METRICS: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.SETTINGS,
      value: 'can_manage_custom_metrics',
    },
    CAN_MANAGE_CONTENT_AUTOMATIONS: {
      scope: SCOPE.ORGANIZATION_USER,
      module: MODULE.SETTINGS,
      value: 'can_manage_content_automations',
    },
  },
});

export const BOARD_TYPES = Object.freeze({
  INSTAGRAM: 'instagram',
  INSTAGRAM_STORY: 'instagram_story',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TIKTOK: 'tiktok',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
});

export const BOARD_LIMIT_PERMISSIONS = Object.freeze({
  [BOARD_TYPES.INSTAGRAM]: {
    module: MODULE.INSTAGRAM.value,
    permission: 'boards_limit',
  },
  [BOARD_TYPES.INSTAGRAM_STORY]: {
    module: MODULE.INSTAGRAM.value,
    permission: 'story_boards_limit',
  },
  [BOARD_TYPES.FACEBOOK]: {
    module: MODULE.FACEBOOK.value,
    permission: 'fb_boards_limit',
  },
  [BOARD_TYPES.PINTEREST]: {
    module: MODULE.PINTEREST.value,
    permission: 'pinterest_boards_limit',
  },
  [BOARD_TYPES.TIKTOK]: {
    module: MODULE.TIKTOK.value,
    permission: 'tiktok_boards_limit',
  },
  [BOARD_TYPES.TWITTER]: {
    module: MODULE.TWITTER.value,
    permission: 'twitter_boards_limit',
  },
  [BOARD_TYPES.YOUTUBE]: {
    module: MODULE.YOUTUBE.value,
    permission: 'youtube_boards_limit',
  },
});

function permissionsForRoute(route) {
  return route.matched
    .filter((matchedRoute) => Array.isArray(matchedRoute?.meta?.permissions))
    .map((matchedRoute) => matchedRoute.meta.permissions)
    .reduce((acc, permissions) => [...acc, ...permissions], []);
}

export function guard(identity, brand, permissionObject) {
  const brandLabel = brand?.label;
  const module = permissionObject?.module?.value?.toLowerCase();
  const permission = permissionObject?.value?.toLowerCase();

  if (permissionObject?.scope?.value === SCOPE.USER.value) {
    const doesSameBrandPermissionExist = !!BRAND[module.toUpperCase()]?.[permission.toUpperCase()];
    const hasBrandPermission = !!brand?.permissions?.[module]?.[permission];
    const hasUserPermission = !!identity?.permissions?.[module]?.[brandLabel]?.[permission];

    return hasUserPermission && (!doesSameBrandPermissionExist || hasBrandPermission);
  }

  if (permissionObject?.scope?.value === SCOPE.BRAND.value) {
    return !!brand?.permissions?.[module]?.[permission];
  }

  if (permissionObject?.scope?.value === SCOPE.ORGANIZATION.value) {
    const brandOrganizationId = brand.organizationId;
    const hasOrganizationPermission =
      !!identity?.organization_permissions?.[brandOrganizationId]?.[module]?.[permission];

    return hasOrganizationPermission;
  }

  if (permissionObject?.scope?.value === SCOPE.ORGANIZATION_USER.value) {
    const brandOrganizationId = brand.organizationId;
    const hasOrganizationUserPermission =
      !!identity?.organization_user_permissions?.[brandOrganizationId]?.[module]?.[permission];

    return hasOrganizationUserPermission;
  }

  return false;
}

export function guardAll(identity, brand, permissionObjects = []) {
  return permissionObjects.every((permissionObject) => guard(identity, brand, permissionObject));
}

export function guardRoute(identity, brand, route) {
  return guardAll(identity, brand, permissionsForRoute(route));
}

export const requiredMetaAdsPermission = Object.freeze({
  value: {
    permission: BRAND.ADS.CAN_ACCESS_ADS,
    tooltip: {
      brand: "This brand doesn't have access to ad analytics",
      user: "You don't have access to ad analytics for this brand",
      tag: 'Brands in this tag don’t have access to ad analytics',
    },
  },
});
export const requiredTikTokAdsPermission = Object.freeze({
  value: {
    permission: BRAND.ADS.CAN_ACCESS_TIKTOK_ADS,
    tooltip: {
      brand: "This brand doesn't have access to ad analytics",
      user: "You don't have access to ad analytics for this brand",
      tag: 'Brands in this tag don’t have access to ad analytics',
    },
  },
});
