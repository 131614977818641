<script setup>
import Chip from '@/components/foundation/Chip.vue';

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <Chip v-if="show" class="text-extra-extra-small-medium print:hidden" xxsmall primary> NEW! </Chip>
</template>
