/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'thumbs-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#686a7a"><path pid="0" d="M19 14.001h4a1 1 0 001-1v-10a1 1 0 00-1-1h-4a1 1 0 00-1 1v10a1 1 0 001 1zM1.232 14.642c.19.228.471.359.768.359h6v4a1 1 0 001 1h3c.4 0 .762-.238.919-.606L16 13.001V3.523l-3.629-1.451A.999.999 0 0012 2.001H4a1 1 0 00-.984.821l-2 11c-.053.292.027.592.216.82z"/></g>'
  }
})
