/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vote-flag-3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 6.001h-7v-3a1 1 0 00-1-1H4a1 1 0 00-1 1v19h2v-8h2v3a1 1 0 001 1h12a1 1 0 001-1v-10a1 1 0 00-1-1zm-15-2h6v8H5v-8zm14 12h-8.586l2.293-2.293a.993.993 0 00.293-.707v-5h6v8z"/>'
  }
})
