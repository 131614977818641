import { LibraryAPI } from '@/apis';
import { logger } from '@/utils/logger';
import { URL_REGEX, utmChannel } from '@/app/settings/components/Utm/const';
import { getInstagramCustomUtmSettings as getUtmSettingsForGalleries } from '@/apis/instagram';

export const decodeDynamicValuesInUrl = (url) => {
  // %3C is <, %3E is > . Angle brackets denote dynamic values, we want to decode those
  return url.replace(/(%3C.*%3E)/g, (_, content) => decodeURIComponent(content));
};

export function getKeyNameForGalleryChannel(channel) {
  if (channel === utmChannel.PRODUCT_PAGE_GALLERIES) {
    return 'product_widget';
  }
  if (channel === utmChannel.SHOPPABLE_GALLERIES) {
    return 'gallery';
  }
  throw new Error(`Unrecognized IG key ${channel}`);
}

export function sortSettings(settings) {
  const utmOrder = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_keyword',
  ];
  let orderedSettings = {};
  utmOrder.forEach((utmKey) => {
    if (Object.prototype.hasOwnProperty.call(settings, utmKey)) {
      orderedSettings[utmKey] = settings[utmKey];
    }
  });
  orderedSettings = Object.keys(settings)
    .sort()
    .reduce((acc, key) => {
      if (utmOrder.includes(key)) {
        return acc;
      }
      return {
        ...acc,
        [key]: settings[key],
      };
    }, orderedSettings);
  return orderedSettings;
}

function formatAndSortSettings(settings, editablePerPost = []) {
  const formatted = Object.keys(settings).reduce((acc, key) => {
    const isEditable = editablePerPost.some((item) => item === key);
    acc[key] = { value: settings[key], edit_per_post: isEditable };
    return acc;
  }, {});
  return sortSettings(formatted);
}

async function fetchItemsForGalleries({ brandId, channel }) {
  try {
    const result = await getUtmSettingsForGalleries({
      brandId,
    });
    const keyName = getKeyNameForGalleryChannel(channel);
    const settings = result.data[keyName];
    return formatAndSortSettings(settings);
  } catch (e) {
    logger.warn(`Request failed ${e}`);
    return {};
  }
}

async function fetchSettingsFromLibrary({ channel, brandId }, onError = () => {}) {
  try {
    const result = await LibraryAPI.getUtmSettings({
      brandId,
      channel,
    });
    return sortSettings(result.data);
  } catch (error) {
    // brand settings return 404 when they don't exist yet, that is expected
    // if the code is something other than 404, we will log the error to investigate
    if (error.response?.status !== 404) {
      logger.warn(`Request failed: ${error}`);
      onError();
    }
    return {};
  }
}

export async function fetchUtmSettings({ channel, brandId }, onError = () => {}) {
  if ([utmChannel.PRODUCT_PAGE_GALLERIES, utmChannel.SHOPPABLE_GALLERIES].includes(channel)) {
    return fetchItemsForGalleries({ brandId, channel });
  }
  return fetchSettingsFromLibrary({ channel, brandId }, onError);
}

export function extractAllUrlsFromString(string) {
  return string.match(URL_REGEX) ?? [];
}
