/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'darkGreySearch': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.707 18.293L14.314 12.9A7.928 7.928 0 0016 8a7.945 7.945 0 00-2.344-5.656A7.94 7.94 0 008 0a7.94 7.94 0 00-5.656 2.344A7.945 7.945 0 000 8c0 2.137.833 4.146 2.344 5.656A7.94 7.94 0 008 16a7.922 7.922 0 004.9-1.686l5.393 5.392 1.414-1.413zM8 14a5.959 5.959 0 01-4.242-1.757A5.958 5.958 0 012 8c0-1.602.624-3.109 1.758-4.242A5.956 5.956 0 018 2c1.603 0 3.109.624 4.242 1.758A5.957 5.957 0 0114 8c0 1.603-.624 3.11-1.758 4.243A5.959 5.959 0 018 14z" _fill="#666" fill-rule="evenodd"/>'
  }
})
