/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clock': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><path pid="0" d="M10 0c5.513 0 10 4.487 10 10s-4.487 10-10 10S0 15.513 0 10 4.487 0 10 0zm0 2.222c-4.289 0-7.778 3.49-7.778 7.778 0 4.289 3.49 7.778 7.778 7.778 4.288 0 7.778-3.49 7.778-7.778 0-4.289-3.49-7.778-7.778-7.778zM11.195 4v5.13L14 11.089 12.782 13 9 10.36V4h2.195z" id="svgicon_clock_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_clock_b" _fill="#fff"><use xlink:href="#svgicon_clock_a"/></mask><use _fill="#A4C7F0" fill-rule="nonzero" xlink:href="#svgicon_clock_a"/><g mask="url(#svgicon_clock_b)"><path pid="1" _fill="#686a7a" fill-rule="nonzero" d="M-.91-.91h29.092v29.092H-.91z"/></g></g>'
  }
})
