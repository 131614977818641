<template>
  <div class="search-select">
    <TextInput
      ref="textInput"
      v-model="value"
      v-tooltip="tooltip"
      class="search-select-input"
      :placeholder="placeholder"
      :loading="loading"
      :disabled="disabled"
      :round="round"
      tall
      hide-details
      prepend-inner-icon="search"
      @update:model-value="handleInput"
      @focusin="handleFocus"
      @blur="handleBlur"
    />
    <transition name="fade">
      <div v-if="focusWithin" :class="{ 'search-select-options-wrapper': options.length > 0 }">
        <ul
          v-if="!showNoResults"
          ref="searchResults"
          v-infinite-scroll="{ loading }"
          class="search-select-options webkit-scrollbar--dynamically-visible"
          :style="{ maxHeight: maxDropdownHeight }"
          @infinite-scroll-down="$emit('infinite-scroll-down')"
        >
          <li
            v-for="(option, index) in options"
            :key="option[optionKey] || index"
            class="search-select-option-wrapper"
            @mousedown="(e) => handleMouseDown(e, option)"
          >
            <div class="search-select-option" :class="{ disabled: option[optionDisabled] }">
              <slot name="option" :option="option">
                <p class="text-main">{{ option[optionText] }}</p>
              </slot>
            </div>
          </li>
        </ul>
        <div v-if="showNoResults" class="no-results-container">
          <slot name="noResults"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import TextInput from '@/components/foundation/form/TextInput.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SearchSelect',
  components: {
    TextInput,
  },
  props: {
    /**
     * Options to be displayed in the list select.
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Text to display when no value is being searched.
     */
    placeholder: { type: String, default: '' },
    /**
     * Property to be used when looking for the display text in the list of options.
     */
    optionKey: { type: String, default: 'key' },
    /**
     * Property to be used when looking for the display text in the list of options.
     */
    optionValue: { type: String, default: 'value' },
    /**
     * Property to be used when looking for the image url in the list of options.
     */
    optionText: { type: String, default: 'text' },
    /**
     * Property to be used when determining if the option should be disabled in the list of options.
     */
    optionDisabled: { type: String, default: 'disabled' },
    /**
     * Determines if the option object should be returned when selected instead of the value.
     */
    returnObject: { type: Boolean, default: false },
    /**
     * Set the max height of the options dropdown
     */
    maxDropdownHeight: { type: String, default: '20rem' },
    /**
     * Show a loading spinner on the right side of the text input while requesting results
     */
    loading: { type: Boolean, default: false },
    /**
     * Flag to disable the text input
     */
    disabled: { type: Boolean, default: false },
    /**
     * Tooltip message to display when text input is disabled
     */
    disabledTooltip: { type: String, default: null },
    // Styling
    round: { type: Boolean, default: false },
  },
  emits: ['infinite-scroll-down', 'blur', 'focus', 'input', 'option-click'],
  data() {
    return {
      value: null,
      focusWithin: false,
      showNoResults: false,
    };
  },
  computed: {
    tooltip() {
      if (this.disabled) {
        return this.disabledTooltip;
      }
      return null;
    },
  },
  watch: {
    options(options) {
      this.showNoResults = this.value !== null && this.value.length > 0 && options.length === 0;
    },
  },
  methods: {
    handleBlur() {
      this.value = null;
      this.focusWithin = false;
      this.$emit('blur');
    },
    handleFocus() {
      this.focusWithin = true;
      this.$emit('focus');
    },
    async handleInput() {
      await nextTick();
      this.$emit('input', this.value);
    },
    handleMouseDown(e, option) {
      if (option.disabled) {
        e.preventDefault();
      } else {
        this.value = null;
        this.focusWithin = false;
        this.$emit('option-click', this.getValue(option));
      }
    },
    getValue(option) {
      return this.returnObject ? option : option[this.optionValue];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.search-select {
  position: relative;

  .search-select-options-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--background-0);
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-4);
    width: 100%;
    z-index: var(--z-index-dropdown);
    text-align: left;
    margin-top: var(--space-8);

    .search-select-options {
      cursor: pointer;
      overflow-y: scroll;

      .search-select-option-wrapper {
        &:not(:last-child) {
          border-bottom: 1px solid var(--border);
        }

        .search-select-option {
          padding: var(--space-16);

          &:not(.disabled):hover {
            background-color: var(--background-300);
          }

          .disabled {
            opacity: 0.3;
            pointer-events: none;
          }
        }
      }
    }
  }

  .no-results-container {
    position: absolute;
    font-size: var(--x16);
    color: var(--text-primary);
    height: 155px;
    width: 100%;
    z-index: var(--z-index-dropdown);
    top: var(--space-48);
    border-radius: 6px;
    background-color: var(--background-0);
    box-shadow: var(--shadow-4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
