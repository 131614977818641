/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'live': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.667 10c0 .917-.75 1.667-1.667 1.667S8.333 10.917 8.333 10 9.083 8.333 10 8.333s1.667.75 1.667 1.667zm-4.6-2.958l-.592-.584A4.98 4.98 0 005 10a4.98 4.98 0 001.475 3.542l.592-.592A4.135 4.135 0 015.833 10c0-1.158.475-2.2 1.234-2.958zm6.458-.584l-.592.592A4.135 4.135 0 0114.167 10a4.17 4.17 0 01-1.234 2.958l.592.592A5.015 5.015 0 0015 10a4.98 4.98 0 00-1.475-3.542zM4.708 4.692L4.125 4.1a8.307 8.307 0 000 11.8l.592-.592A7.461 7.461 0 012.5 10c0-2.075.85-3.95 2.208-5.308zM15.875 4.1l-.592.592A7.462 7.462 0 0117.5 10c0 2.075-.85 3.95-2.208 5.308l.591.592a8.334 8.334 0 002.45-5.9c0-2.3-.941-4.392-2.458-5.9z" _fill="#fff" _stroke="#fff" stroke-width=".5"/>'
  }
})
