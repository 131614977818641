/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cursorFinger': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<defs><path pid="0" d="M15.316 10.053L10 8.281v-6.28a1 1 0 00-.758-.97l-4-1A1.003 1.003 0 004 1.001v7.382L.553 10.107a1 1 0 00-.553.894v4c0 .155.036.309.105.447l2 4c.17.339.516.553.895.553h10c.459 0 .859-.313.971-.757l2-8a1 1 0 00-.655-1.191zm-3.097 7.948H3.618L2 14.765V11.62l2-1v2.381h2V2.282l2 .5v6.219a1 1 0 00.684.949l5.121 1.708-1.586 6.343z" id="svgicon_cursorFinger_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_cursorFinger_a"/>'
  }
})
