import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import isObject from 'lodash/isObject';
import { transform } from 'lodash/object';
import { isArray } from 'lodash/lang';
import isEqual from 'lodash/isEqual';
import {
  SENTIMENT_OPTIONS,
  SOCIAL_LISTENING_DATE_OPTIONS,
  SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS,
  SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
} from '@/app/socialListening/constants';

export function getStartAndEndDate(dateRange, dateOptions = SOCIAL_LISTENING_DATE_OPTIONS) {
  let startDate = null;
  let endDate = null;
  const subtractUnit = dateRange && dateOptions ? dateOptions[dateRange]?.subtractUnit : null;
  const subtractValue = dateRange && dateOptions ? dateOptions[dateRange]?.subtractValue : null;
  if (subtractUnit && subtractValue) {
    if (subtractUnit === 'day' && subtractValue === 1) {
      endDate = dayjs().set('minute', 0).set('second', 0);
      startDate = endDate.subtract(subtractValue, subtractUnit);
    } else {
      endDate = dayjs().endOf('day');
      startDate = endDate.subtract(subtractValue, subtractUnit).startOf('day');
    }
    endDate = endDate.format('YYYY-MM-DD HH:mm:ss');
    startDate = startDate.format('YYYY-MM-DD HH:mm:ss');
  }
  return { startDate, endDate };
}

export function sortObjectKeys(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

export function getBackendFilterPayload(
  dateRange,
  sentimentList,
  channelList,
  mediaTypeList,
  keywords,
  dateOptions,
  startDate = null,
  endDate = null,
  visualData = null,
  industryIds = null,
  competitorIds = null,
) {
  const filterPayload = {
    sources: channelList,
    media_types: {
      includes: mediaTypeList,
    },
  };

  const sentimentSet = new Set(sentimentList);
  const isAllSentiments = isEqual(sentimentSet, SENTIMENT_OPTIONS);

  if (!isAllSentiments) {
    const includePositive = (sentimentList ?? []).includes(
      SOCIAL_LISTENING_SENTIMENTS_OPTIONS.POSITIVE.value,
    );
    const includeNeutral = (sentimentList ?? []).includes(
      SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEUTRAL.value,
    );
    const includeNegative = (sentimentList ?? []).includes(
      SOCIAL_LISTENING_SENTIMENTS_OPTIONS.NEGATIVE.value,
    );

    filterPayload.sentiment = {
      include_positive: includePositive,
      include_neutral: includeNeutral,
      include_negative: includeNegative,
    };
  }
  let rangeStart;
  let rangeEnd;
  // if fixed dates not provided, use date option selected
  if (!startDate) {
    const rangeDates = getStartAndEndDate(dateRange, dateOptions);
    rangeStart = rangeDates.startDate;
    rangeEnd = rangeDates.endDate;
  } else {
    rangeStart = startDate;
    rangeEnd = endDate;
  }
  if (rangeStart) {
    filterPayload.source_created = {
      on_or_after: rangeStart,
    };
  }
  if (rangeEnd) {
    filterPayload.source_created.on_or_before = rangeEnd;
  }

  if (keywords && keywords.length > 0) {
    filterPayload.keywords_and_hashtags = keywords;
  }

  if (visualData) {
    filterPayload.visual_data = visualData;
  }

  if (industryIds) {
    filterPayload.industry_ids = industryIds;
  }

  if (competitorIds) {
    filterPayload.source_and_creator_ids = competitorIds;
  }

  return sortObjectKeys(filterPayload);
}

export function getKeywordORString(filters, topKeywords) {
  if (filters.keyword) {
    return filters.keyword;
  }
  const keywordList = topKeywords.map((keywordData) => keywordData.keyword);
  return keywordList.join(' OR ');
}

export function camelCaseObjDeep(snakeObject) {
  return transform(snakeObject, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelCaseObjDeep(value) : value;
  });
}

export function getKeywordTypePayload(keywordTypes) {
  const { KEYWORD, HASHTAG } = SOCIAL_LISTENING_KEYWORD_TYPE_OPTIONS;
  return {
    includeKeywords: keywordTypes.includes(KEYWORD.value),
    includeHashtags: keywordTypes.includes(HASHTAG.value),
  };
}
