/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'expand': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#686a7a"><path pid="0" d="M10 7.999v-2H4c-1.103 0-2 .897-2 2v12c0 1.104.897 2 2 2h12a2 2 0 002-2v-6h-2v6H4v-12h6z"/><path pid="1" d="M21 2.999h-7v2h3.586L6.293 16.292l1.414 1.414L19 6.413v3.586h2z"/></g>'
  }
})
