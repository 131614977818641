<template>
  <section class="media-detail-filter">
    <header>
      <Icon name="magic-wand-2" small />
      Predicted Performance
    </header>
    <main>
      <div class="filter" @click.prevent="handleClickAny">
        <span>Any</span>
        <Icon v-if="!currentFilter" :color="bgBlue" name="check" small />
      </div>
      <div class="filter" @click.prevent="handleClick(filterOptions.top)">
        <div class="pop-wrapper">
          <div :style="{ 'margin-right': '0.5em' }" class="performance-badge">
            <Icon :name="filterOptions.top.icon" :color="filterOptions.top.color" xxsmall />
          </div>

          <div class="performance-badge">
            <Icon :name="filterOptions.better.icon" :color="filterOptions.better.color" xxsmall />
          </div>
        </div>

        <Icon v-if="currentFilter === filterOptions.top.name" :color="bgBlue" name="check" small />
      </div>
      <div class="filter" @click.prevent="handleClick(filterOptions.low)">
        <div class="pop-wrapper">
          <div class="performance-badge">
            <Icon :name="filterOptions.low.icon" :color="filterOptions.low.color" xxsmall />
          </div>
        </div>

        <Icon v-if="currentFilter === filterOptions.low.name" :color="bgBlue" name="check" small />
      </div>
    </main>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { PREDICTION_MODEL } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PerformanceFilter',
  components: {
    Icon,
  },
  emits: ['filterSelected'],
  data() {
    return {
      filterOptions: {
        top: { name: 'top', icon: 'excellent', color: colours.ALERT.ALERT_500 },
        better: { name: 'better', icon: 'good', color: colours.SUCCESS.SUCCESS_500 },
        low: { name: 'low', icon: 'bad', color: '#B1C1D2' },
      },
      currentFilter: null,
      bgBlue: colours.ACTION.ACTION_500,
      bgGrey: colours.BACKGROUND.BACKGROUND_400,
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
    predictionModel() {
      return PREDICTION_MODEL.ENGAGEMENT;
    },
  },
  methods: {
    updateFilter(name) {
      if (name === null) {
        this.handleClickAny();
      } else if (Object.keys(this.filterOptions).includes(name)) {
        this.currentFilter = name;
      }
    },
    handleClick(filter) {
      if (this.currentFilter !== filter.name) {
        this.currentFilter = filter.name;
        this.$emit('filterSelected', filter.name);
        this.trackingStore.track('Performance Filter Changed', {
          filter: 'Performance',
          filterValue: filter.name,
          modelLabel: this.predictionModel.label,
          modelField: this.predictionModel.field,
        });
      } else {
        this.currentFilter = null;
        this.$emit('filterSelected', null);
      }
    },
    handleClickAny() {
      this.currentFilter = null;
      this.$emit('filterSelected', null);
      this.trackingStore.track('Performance Filter Changed', {
        filter: 'Performance',
        filterValue: 'any',
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-detail-filter {
  padding: var(--space-24) var(--space-24) 0;
}

header {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  text-transform: capitalize;

  .svg-icon {
    margin-right: var(--space-24);
  }
}

main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 var(--space-40);
  margin-left: var(--space-4);
}

.filter {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  padding: var(--space-8) 0 0 0;
  cursor: pointer;
  text-transform: capitalize;
  transition: var(--transition-all);

  .svg-icon {
    margin-left: var(--space-16);
  }
}

.performance-badge {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--background-300);
  transition: var(--transition-all);
  transform: translateY(0);

  .svg-icon {
    margin-left: 0;
    margin-right: 0;
  }

  &.border-color {
    border: 1px solid var(--border);
  }
}

.pop-wrapper {
  display: flex;

  &:hover {
    transform: var(--hover-move);
  }
}
</style>
