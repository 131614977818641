// input: { instagram: { can_access_instagram: true ... }, pinterest: {...} }.
// output: { can_access_instagram: true, can_access_pinterest: true }.
export const flattenBrandUserPermissions = (permissions) => {
  const perms = {};
  Object.entries(permissions)
    .filter(([module]) => module !== 'brand_roles')
    .forEach(([, userModulePermissions]) => {
      Object.entries(userModulePermissions).forEach(([permission, value]) => {
        perms[permission] = value;
      });
    });
  return perms;
};

export const flattenIdentityPermissions = (permissions, brand) => {
  const brandUserPermissions = Object.fromEntries(
    Object.entries(permissions)
      .filter(([module]) => module !== 'brand_roles')
      .map(([module, userBrandModulePermissions]) => {
        return [module, userBrandModulePermissions[brand]];
      }),
  );
  return flattenBrandUserPermissions(brandUserPermissions);
};

export const formatUtmDateString = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day}${month}${year}`;
};
