<template>
  <span class="dot" :style="{ backgroundColor: color }"></span>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Dot',
  props: {
    color: { type: String, required: true },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dot {
  display: inline-block;
  width: var(--space-10);
  height: var(--space-10);
  border-radius: 50%;
  margin-right: var(--space-6);
}
</style>
