/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'igtv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M8.704 1.142l.095.083 3.292 3.292 3.293-3.292a1 1 0 011.32-.083l.095.083a1 1 0 01.083 1.32l-.083.094-3.294 3.293H15a6 6 0 015.996 5.775l.004.225v5a6 6 0 01-6 6H9a6 6 0 01-6-6v-5a6 6 0 016-6h1.677L7.384 2.64a1 1 0 011.32-1.497zM15 7.932H9a4 4 0 00-4 4v5a4 4 0 004 4h6a4 4 0 004-4v-5a4 4 0 00-4-4zm-2.178 4.953l.005.116v1.655l3.1-1.113c.41-.148.84.1 1.004.56l.034.11c.122.491-.085 1.007-.468 1.205l-.091.04-4.173 1.5c-.503.18-1.009-.235-1.067-.842L11.161 16v-1.524l-3.018 1.446c-.397.19-.844-.011-1.042-.451l-.041-.106c-.159-.476.01-1.012.376-1.25l.088-.05 4.161-1.993c.517-.247 1.076.17 1.137.813z" id="svgicon_igtv_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_igtv_b" _fill="#fff"><use xlink:href="#svgicon_igtv_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_igtv_a"/><g mask="url(#svgicon_igtv_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
