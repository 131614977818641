<template>
  <div class="comment-author-details">
    <UserAvatar :user="avatarDetails" class="avatar" xsmall />
    <div>
      <span class="author-name">{{ commentAuthor.firstName }} {{ commentAuthor.lastName }}</span>
      <span v-tooltip="formattedTimeTooltip" class="comment-time">
        {{ formattedCommentTime }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import { mapState as mapPiniaState } from 'pinia';
import humps from 'humps';
import { useAuthStore } from '@/stores/auth';
import UserAvatar from '@/components/core/UserAvatar.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CommentAuthorDetails',
  components: {
    UserAvatar,
  },
  props: {
    commentAuthor: { type: Object, required: true },
    commentCreatedAt: { type: String, required: true },
  },
  data() {
    return {
      commentTime: dayjs.utc(this.commentCreatedAt),
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['identity']),
    avatarDetails() {
      return humps.camelizeKeys(this.commentAuthor);
    },
    formattedCommentTime() {
      const oneMinuteAgo = dayjs.utc().subtract(1, 'minutes');
      if (this.commentTime.isAfter(oneMinuteAgo)) {
        return 'Just now';
      }
      return this.commentTime.fromNow();
    },
    formattedTimeTooltip() {
      // If the user has a preferred timezone, use that. Otherwise display local time.
      const timeZoneName = this.identity.time_zone_name;
      return timeZoneName
        ? dayjs.utc(this.commentCreatedAt).tz(timeZoneName).format('MMM D, YYYY h:mmA')
        : dayjs.utc(this.commentCreatedAt).local().format('MMM D, YYYY h:mmA');
    },
  },
  created() {
    // refresh commentTime periodically
    setInterval(() => {
      this.commentTime = dayjs.utc(this.commentCreatedAt);
    }, 30000);
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.comment-author-details {
  display: flex;
  align-items: center;

  .avatar {
    padding-right: var(--space-6);
  }

  .author-name {
    word-break: break-word;
    font-weight: var(--font-medium);
    margin-right: var(--space-8);
  }

  .comment-time {
    font-size: var(--x10);
    color: var(--text-secondary);
    white-space: nowrap;
  }
}
</style>
