<template>
  <div ref="videoTrimmerContainer" class="video-trimmer-container">
    <div class="background">
      <VideoPreview :media="media" />
    </div>
    <div class="timeline-container">
      <div ref="leftTimelineOverlay" class="timeline-overlay left-timeline-overlay" />
      <div ref="rightTimelineOverlay" class="timeline-overlay right-timeline-overlay" />
      <div ref="timeline" :class="{ disabled }" class="timeline">
        <div class="time-duration">
          <span ref="duration">
            {{ trimmedDuration }}
          </span>
        </div>
        <div
          ref="leftToggle"
          :class="{ disabled }"
          class="toggle left-toggle"
          @mousedown.stop="mouseDownHandler('left')"
          @mousemove.stop="mouseMoveHandler"
        >
          <div class="dots" />
          <div class="time">
            <span ref="startTime">
              {{ formattedStartTime }}
            </span>
          </div>
        </div>
        <div
          ref="rightToggle"
          :class="{ disabled }"
          class="toggle right-toggle"
          @mousedown.stop="mouseDownHandler('right')"
          @mousemove.stop="mouseMoveHandler"
        >
          <div class="dots" />
          <div class="time">
            <span ref="endTime">
              {{ formattedEndTime }}
            </span>
          </div>
        </div>
      </div>
      <div
        ref="playhead"
        :class="['playhead', { disabled }]"
        @mousedown.stop="mouseDownPlayheadHandler"
        @mousemove.stop="mouseMoveHandler"
      >
        <div :class="['playhead-time', { disabled }]">{{ formattedPlayheadPosition }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useCropperStore } from '@/stores/cropper';
import VideoPreview from './VideoPreview/VideoPreview.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'VideoTrimmer',
  components: {
    VideoPreview,
  },
  props: {
    endTime: { type: Number, default: 0 },
    hideVideoDuration: { type: Boolean, default: false },
    media: { type: Object, default: null },
    onEndTimeChanged: { type: Function, default: () => {} },
    onPlayheadDragged: { type: Function, default: () => {} },
    onPlayheadPositionChanged: { type: Function, default: () => {} },
    onStartTimeChanged: { type: Function, default: () => {} },
    onTrimChanged: { type: Function, default: () => {} },
    playheadPosition: { type: Number, default: 0 },
    savingVideo: { type: Boolean, default: false },
    startTime: { type: Number, default: 0 },
    videoIsLoaded: { type: Boolean, default: false },
    videoPlaying: { type: Boolean, default: false },
  },
  data() {
    return {
      dragging: false,
      draggingPlayhead: false,
      duration: null,
      trimmerContainerWidth: null,
      videoWasPlaying: this.videoPlaying,
    };
  },
  computed: {
    ...mapStores(useCropperStore),
    disabled() {
      return this.savingVideo || !this.videoIsLoaded;
    },
    trimmedDuration() {
      // Since the times shown on the trimmer are rounded before displaying they need to be
      // independently rounded here before calculating duration to ensure the duration time
      // displayed matched the start and end times displayed.
      const roundedStartTime = Math.round(this.startTime * 10) / 10;
      const roundedEndTime = Math.round(this.endTime * 10) / 10;
      return this.formatTime(roundedEndTime - roundedStartTime);
    },
    formattedStartTime() {
      return this.formatTime(this.startTime);
    },
    formattedEndTime() {
      return this.formatTime(this.endTime);
    },
    formattedPlayheadPosition() {
      return this.formatTime(this.playheadPosition);
    },
  },
  watch: {
    endTime(newVal, oldVal) {
      // Make end trimmer respond to changes (like when the video duration has been computed and
      // the endTime value is set).
      if (oldVal === 0) {
        this.setTimelinePositions();
        this.setPlayheadPositionFromTime(this.startTime);
      }
    },
    hideVideoDuration(val) {
      this.$refs.duration.style.display = val ? 'none' : 'block';
    },
    'cropperStore.videoDuration': {
      handler(seconds) {
        // adding the time values on the trimmer once the duration is accessible
        this.duration = seconds;
        this.setTimelinePositions();
        this.setPlayheadPositionFromTime(this.startTime);

        if (!this.endTime) {
          this.onEndTimeChanged(seconds);
        }
      },
    },
    playheadPosition(seconds) {
      // only setting the playhead time once the video is loaded
      if (this.videoIsLoaded) {
        this.setPlayheadPositionFromTime(seconds);
      }
    },
    draggingPlayhead(dragged) {
      // NOTE: videoWasPlaying is used to track if the video was playing without actually changing
      // values in the cropper popup.
      this.videoWasPlaying = true;
      this.onPlayheadDragged(this.videoPlaying, dragged);
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.mouseUpHandler);
    window.addEventListener('mousemove', this.mouseMoveHandler);
    window.addEventListener('resize', this.onWindowResize);
    if (this.$refs && this.$refs.videoTrimmerContainer) {
      this.trimmerContainerWidth = this.$refs.videoTrimmerContainer.clientWidth;
    }
  },
  unmounted() {
    this.duration = null;
    window.removeEventListener('mouseup', this.mouseUpHandler);
    window.removeEventListener('mousemove', this.mouseMoveHandler);
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    mouseDownHandler(side) {
      if (!this.disabled) {
        this.dragging = side;
        if (side === 'left') {
          // Set opacity
          this.$refs.startTime.style.opacity = 1;
          this.$refs.endTime.style.opacity = this.calculateOpacity(this.$refs.timeline.clientWidth);
        } else if (side === 'right') {
          // Set opacity
          this.$refs.endTime.style.opacity = 1;
          this.$refs.startTime.style.opacity = this.calculateOpacity(
            this.$refs.timeline.clientWidth,
          );
        }
      }
    },
    mouseUpHandler() {
      this.dragging = false;
      this.draggingPlayhead = false;
      if (this.videoWasPlaying) {
        this.videoWasPlaying = false;
        this.onPlayheadDragged(this.videoWasPlaying, this.draggingPlayhead);
      }
    },
    mouseMoveHandler(e) {
      if (!this.disabled && (this.dragging || this.draggingPlayhead)) {
        const { trimmerContainerWidth } = this;
        const mouseX = e.clientX;
        const boundingBox = this.$refs.videoTrimmerContainer.getBoundingClientRect();
        const timelineBox = this.$refs.timeline.getBoundingClientRect();
        let x;
        if (this.dragging) {
          if (this.dragging === 'left') {
            x = mouseX - boundingBox.left;
            // Check to keep toggle inside bounds
            if (mouseX < boundingBox.left) {
              x = 0;
              // Check to ensure left and right toggles aren't overlapping
            } else if (mouseX > timelineBox.right - this.$refs.leftToggle.clientWidth - 2) {
              x = timelineBox.right - boundingBox.left - this.$refs.leftToggle.clientWidth - 2;
            }
          } else if (this.dragging === 'right') {
            x = boundingBox.right - mouseX;
            // Check to keep toggle inside bounds
            if (mouseX > boundingBox.right) {
              x = 0;
              // Check to ensure left and right toggles aren't overlapping
            } else if (mouseX < timelineBox.left + this.$refs.rightToggle.clientWidth + 2) {
              x = boundingBox.right - timelineBox.left - this.$refs.rightToggle.clientWidth - 2;
            }
          }
          this.move(x);
          this.onTrimChanged(true);
        } else if (this.draggingPlayhead) {
          if (mouseX < timelineBox.left) {
            x = timelineBox.left - boundingBox.left;
          } else if (mouseX > timelineBox.right) {
            x = timelineBox.right - boundingBox.left;
          } else {
            x = mouseX - boundingBox.left;
          }
          this.$refs.playhead.style.left = `${
            ((x - this.$refs.playhead.clientWidth / 2) / trimmerContainerWidth) * 100
          }%`;
          const ratio = x / trimmerContainerWidth;
          const seconds = Math.round(ratio * this.duration * 1000) / 1000;
          this.onPlayheadPositionChanged(seconds);
        }
      }
    },
    move(x) {
      if (this.dragging === 'left') {
        this.handleLeftMove(x);
      } else if (this.dragging === 'right') {
        this.handleRightMove(x);
      }
    },
    handleLeftMove(x) {
      const { trimmerContainerWidth } = this;
      // Set position
      this.$refs.timeline.style.left = `${(x / trimmerContainerWidth) * 100}%`;
      // Set playhead position
      this.setPlayheadPosition(x);
      // Set overlay
      this.$refs.leftTimelineOverlay.style.right = `${
        ((trimmerContainerWidth - x) / trimmerContainerWidth) * 100
      }%`;
      // Set start time
      const ratio = x / trimmerContainerWidth;
      const seconds = Math.round(ratio * this.duration * 1000) / 1000;
      this.onStartTimeChanged(seconds);
      // Set opacity
      this.$refs.startTime.style.opacity = 1;
      this.$refs.endTime.style.opacity = this.calculateOpacity(this.$refs.timeline.clientWidth);
      this.setDurationOpacity();
    },
    handleRightMove(x) {
      const { trimmerContainerWidth } = this;
      // Set position
      this.$refs.timeline.style.right = `${(x / trimmerContainerWidth) * 100}%`;
      // Set playhead position
      this.setPlayheadPosition(trimmerContainerWidth - x);
      // Set overlay
      this.$refs.rightTimelineOverlay.style.left = `${
        ((trimmerContainerWidth - x) / trimmerContainerWidth) * 100
      }%`;
      // Set end time
      const ratio = (trimmerContainerWidth - x) / trimmerContainerWidth;
      const seconds = Math.round(ratio * this.duration * 1000) / 1000;
      this.onEndTimeChanged(seconds);
      // Set opacity
      this.$refs.endTime.style.opacity = 1;
      this.$refs.startTime.style.opacity = this.calculateOpacity(this.$refs.timeline.clientWidth);
      this.setDurationOpacity();
    },
    setDurationOpacity() {
      // de-structuring tags to avoid rewriting nesting
      const { duration, startTime } = this.$refs;

      const toggleDurationDiff =
        duration.offsetLeft + duration.clientWidth / 2 - startTime.clientWidth / 2;
      duration.style.opacity = this.calculateOpacity(toggleDurationDiff * 0.75);
    },
    calculateOpacity(width) {
      if (width <= 25) {
        return 0;
      }
      if (width <= 45) {
        return (width - 25) / 20;
      }
      return 1;
    },
    formatTime(totalSeconds) {
      const minutes = Math.floor(Math.round(totalSeconds * 10) / 10 / 60);
      // Pad with leading 0 (if needed) and fix to one decimal place
      const seconds = `0${((Math.round(totalSeconds * 10) / 10) % 60).toFixed(1)}`.slice(-4);

      return `${minutes}:${seconds}`;
    },
    mouseDownPlayheadHandler() {
      this.draggingPlayhead = true;
    },
    setTimelinePositions() {
      const { trimmerContainerWidth } = this;
      // Left side of slider
      const startRatio = this.startTime / this.duration;
      const x = startRatio * trimmerContainerWidth;
      this.handleLeftMove(x);

      // Right side of slider
      const endRatio = (this.duration - this.endTime) / this.duration;
      const y = endRatio * trimmerContainerWidth;
      this.handleRightMove(y);
    },
    setPlayheadPosition(x) {
      const playheadWidth = this.$refs.playhead.clientWidth;
      const playheadX = Math.round(x - playheadWidth / 2);
      this.$refs.playhead.style.left = `${
        (playheadX / this.$refs.videoTrimmerContainer.clientWidth) * 100
      }%`;
    },
    setPlayheadPositionFromTime(seconds) {
      const { trimmerContainerWidth } = this;
      if (trimmerContainerWidth) {
        const ratio = seconds / this.duration;
        const x = ratio * trimmerContainerWidth;
        this.setPlayheadPosition(x);
      }
    },
    onWindowResize() {
      if (this.$refs && this.$refs.videoTrimmerContainer) {
        this.trimmerContainerWidth = this.$refs.videoTrimmerContainer.clientWidth;
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.video-trimmer-container {
  height: 40px;
  margin: var(--space-24) 0;
  display: block;
  border-radius: var(--round-corner-small);
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  width: 28.25rem;
  user-select: none;

  .loader-wrapper {
    position: absolute;
    inset: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-0);
  }

  .background {
    background-color: var(--background-500);
    display: block;
    border-radius: var(--round-corner-small);
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .frame-image {
    position: absolute;
  }

  .time-duration {
    color: var(--text-secondary);
    display: flex;
    font-size: var(--x10);
    font-weight: var(--font-medium);
    justify-content: center;
    top: 36px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .timeline-container {
    position: absolute;
    inset: 0;

    .timeline-overlay {
      background-color: rgb(0 0 0 / 50%);
      bottom: 0;
      position: absolute;
      top: 0;
    }

    .left-timeline-overlay {
      border-radius: var(--round-corner-small) 0 0 var(--round-corner-small);
      left: 0;
    }

    .right-timeline-overlay {
      border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
      right: 0;
    }

    .disabled {
      opacity: 0.6;
    }

    .timeline {
      background-color: rgb(255 255 255 / 0%);
      border: 2px solid var(--action-500);
      box-sizing: border-box;
      display: block;
      position: absolute;
      inset: 0;
      margin-bottom: 0;

      .toggle {
        background-color: var(--action-500);
        border-radius: var(--round-corner);
        cursor: grab;
        height: 28px;
        position: absolute;
        top: 4px;
        width: 10px;
        z-index: var(--z-index-raised);
      }

      .disabled {
        cursor: auto;
      }

      .left-toggle {
        left: -6px;
      }

      .right-toggle {
        right: -6px;
      }

      .dots {
        background-color: var(--background-0);
        border-radius: var(--round-corner);
        height: 2px;
        left: 4px;
        position: absolute;
        width: 2px;
        top: 13px;

        &::before {
          content: '';
          background-color: var(--background-0);
          border-radius: var(--round-corner);
          height: 2px;
          position: absolute;
          width: 2px;
          top: -4px;
        }

        &::after {
          content: '';
          background-color: var(--background-0);
          border-radius: var(--round-corner);
          height: 2px;
          position: absolute;
          width: 2px;
          top: 4px;
        }
      }

      .time {
        color: var(--action-500);
        font-size: var(--x10);
        font-weight: var(--font-medium);
        height: 13px;
        left: -10px;
        line-height: 13px;
        position: absolute;
        top: 36px;
        width: 100%;
      }
    }

    .playhead {
      background-color: var(--background-0);
      border-radius: var(--round-corner);
      color: var(--text-primary);
      font-weight: var(--font-medium);
      height: 14px;
      position: absolute;
      left: -20px;
      top: -18px;
      width: 43px;
      right: -21px;
      filter: drop-shadow(0 0 8px rgb(0 0 0 / 10%)) drop-shadow(0 0 1px rgb(0 0 0 / 20%));

      &::before {
        border-left: 2.5px solid transparent;
        border-right: 2.5px solid transparent;
        border-top: 3px solid var(--white);
        position: absolute;
        content: '';
        top: 99%;
        left: 19px;
      }

      &::after {
        content: '';
        background-color: var(--background-0);
        height: 45px;
        position: absolute;
        width: 1px;
        top: 99%;
        left: 21px;
      }

      .playhead-time {
        width: 100%;
        font-size: var(--x10);
        font-weight: var(--font-medium);
        line-height: 14px;
        text-align: center;
        font-family: sans-serif;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}
</style>
