/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'replay-2': {
    width: 21,
    height: 18,
    viewBox: '0 0 21 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.96 8.292a1 1 0 011.414 0l2.043 2.043 2.043-2.043a1 1 0 111.414 1.414l-2.397 2.397a1.5 1.5 0 01-2.121 0L12.96 9.706a1 1 0 010-1.414z" _fill="#4990E2"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9.083 2.666a6.333 6.333 0 000 12.667 1 1 0 110 2 8.333 8.333 0 118.334-8.334v1.834a1 1 0 11-2 0V8.999a6.333 6.333 0 00-6.334-6.333z" _fill="#4990E2"/>'
  }
})
