/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'good': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" d="M5.074 0v2.147h2.418L0 9.609 1.674 11 9.01 3.665v2.418h2.147V0z" _fill="#27C449" fill-rule="evenodd"/>'
  }
})
