import { defineStore } from 'pinia';
import { ref } from 'vue';
import { DashboardAPI } from '@/apis';
import { useFlagStore } from './flag';

// TODO: Move metric types and comparable metrics into this store
const metricDetailsReady = ref(false);

export const useMetricsStore = defineStore('metrics', () => {
  const metricDetails = ref({});

  async function fetchMetricDetails(channel) {
    const response = await DashboardAPI.getMetricDetails({
      channel,
    });
    metricDetails.value = response?.data;
    metricDetailsReady.value = true;
  }

  function getMetricKeysByParent(channel, metricReportTypeKey, parentMetric) {
    const flagStore = useFlagStore();
    const allChannelTypeMetrics = metricDetails.value?.[channel]?.[metricReportTypeKey];
    return Object.keys(allChannelTypeMetrics).filter((metricKey) => {
      const {
        parent_metric: currentParentMetric,
        hide_on_dashboards: hideOnDashboards,
        required_feature_flag: requiredFeatureFlag,
      } = allChannelTypeMetrics[metricKey];
      const parentMetricMatch = metricKey === parentMetric || currentParentMetric === parentMetric;
      const hasRequiredFlag = Boolean(
        !requiredFeatureFlag || (flagStore.ready && flagStore.flags?.[requiredFeatureFlag]),
      );

      return !hideOnDashboards && parentMetricMatch && hasRequiredFlag;
    }, {});
  }

  function getTargetMetricDetails(channel, metricReportTypeKey, metric) {
    return metricDetails.value?.[channel]?.[metricReportTypeKey]?.[metric];
  }

  return {
    metricDetailsReady,
    metricDetails,
    fetchMetricDetails,
    getMetricKeysByParent,
    getTargetMetricDetails,
  };
});
