import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useNotificationStore } from '@/stores/notification';

export const useUtmStore = defineStore('utm', () => {
  const notificationStore = useNotificationStore();

  const isManagementSettingsDirty = ref(null);
  const discardChanges = ref(false);

  async function confirmLeaving(onLeave = () => {}) {
    await notificationStore.confirm(
      'Discard Changes?',
      'If you exit now, your unsaved changes will be lost.',
      {
        confirmAlias: 'Discard',
        onConfirm: () => {
          discardChanges.value = true;
          onLeave();
        },
      },
    );
  }

  return {
    isManagementSettingsDirty,
    discardChanges,
    confirmLeaving,
  };
});
