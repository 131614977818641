<script setup>
import Avatar from '@/components/foundation/Avatar.vue';
import MessageChips from '@/app/community/components/MessageChips.vue';
import { useAuthStore } from '@/stores/auth';
import { computed, ref } from 'vue';
import { USER, BRAND } from '@/models/auth/permissions.enum';
import { SENTIMENT_LABEL_BY_TYPE } from '@/app/community/constants';
import dayjs from 'dayjs';
import { localize } from '@/utils/formatters';
import Button from '@/components/foundation/Button.vue';
import { downloadImage } from '@/components/VisionAi/utils/tile-download';
import { IMAGE_DOWNLOAD_FILE_FORMATS } from '@/app/dashboards/constants';
import { useNotificationStore } from '@/stores/notification';

const props = defineProps({
  postThumbnailUrl: {
    type: String,
    default: '',
  },
  displayText: {
    type: String,
    default: '',
  },
  userHandle: {
    type: String,
    default: '',
  },
  brandAvatarUrl: {
    type: String,
    default: '',
  },
  timestamp: {
    type: String,
    default: '',
  },
  mediaCommentSentimentDetail: {
    type: Object,
    default: null,
  },
  interactionType: {
    type: String,
    default: null,
  },
  likeCount: {
    type: Number,
    default: 0,
  },
  commentCount: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['downloadButtonClicked']);

const authStore = useAuthStore();
const notificationStore = useNotificationStore();

const thumbnailLoaded = ref(false);
const thumbnailError = ref(false);

const commentWidgetContainer = ref(null);

const download = () => {
  if (commentWidgetContainer.value) {
    emit('downloadButtonClicked');
    try {
      downloadImage(commentWidgetContainer.value, IMAGE_DOWNLOAD_FILE_FORMATS.PNG.extension);
    } catch (err) {
      notificationStore.setToast({
        message: 'Failed to download widget image',
        type: 'error',
      });
    }
  }
};

function formatTimestamp(timestamp) {
  return dayjs(timestamp).format('MMM DD, h:mm A');
}

function calculateAspectRatio(url) {
  const w = url?.match(/w=([^&]*)/)?.[1];
  const h = url?.match(/h=([^&]*)/)?.[1];
  if (!w || !h) {
    return '1.0';
  }
  return `${w} / ${h}`;
}

function formatLargeCount(count) {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(count);
}

function formatLikes(count, singularSuffix = 'Like', pluralSuffix = 'Likes', threshold = 99999) {
  const suffix = count === 1 ? singularSuffix : pluralSuffix;
  if (count > threshold) {
    return [formatLargeCount(count), suffix];
  }
  return [localize(count, false), suffix];
}

// Sentiment render helpers

function mapSentimentDetails(detail) {
  return Object.keys(detail ?? {}).map((sentiment) => ({
    ...SENTIMENT_LABEL_BY_TYPE[sentiment],
    value: detail?.[sentiment],
  }));
}

function processSentimentStats(sentimentStats) {
  const sentimentMap = mapSentimentDetails(sentimentStats);
  let totalPercentage = 0;
  const processedStats = sentimentMap.map((stat, index, array) => {
    const percentage =
      index === array.length - 1 ? 100 - totalPercentage : Math.round(stat.value * 100);
    totalPercentage += percentage;
    return { ...stat, value: percentage, suffix: '%' };
  });
  return processedStats.filter((stat) => stat.value > 0);
}

function formatLikeStat(likeCount) {
  const formattedLikes = formatLikes(likeCount); // Assuming the formatLikes function is defined elsewhere
  return {
    value: formattedLikes[0],
    title: formattedLikes[1],
    iconName: 'heart-solid',
  };
}

// Computed properties
const postThumbnailStyles = computed(() => ({
  maxHeight: '6.75rem',
  maxWidth: '6.75rem',
  height: 'unset',
  width: 'unset',
  position: 'relative',
  aspectRatio: calculateAspectRatio(props.postThumbnailUrl),
  objectFit: 'cover',
}));

// Sentiment rendering computed properties
const sentimentAnalysisAccessible = computed(
  () =>
    authStore.guard(USER.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS) &&
    authStore.guard(BRAND.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS),
);

const processedSentimentStats = computed(() => {
  if (!sentimentAnalysisAccessible.value) return [];
  return processSentimentStats(props.mediaCommentSentimentDetail);
});

const stats = computed(() => [formatLikeStat(props.likeCount), ...processedSentimentStats.value]);

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        sentimentAnalysisAccessible,
      }
    : {},
);
</script>

<template>
  <div ref="commentWidgetContainer" class="comment-widget-container">
    <Button
      v-tooltip="'Download as .png'"
      class="image-download absolute right-2 top-3 z-dropdown"
      icon-name="download"
      @click="download"
    />
    <div class="comment-widget">
      <div class="comment-widget-body">
        <div class="comment-widget-thumbnail">
          <Avatar class="thumb-nail-avatar" :style="postThumbnailStyles" xlarge>
            <img
              :src="props.postThumbnailUrl"
              alt="post thumbnail"
              @load="thumbnailLoaded = true"
              @error="thumbnailError = true"
            />
          </Avatar>
        </div>
        <div class="media-info-container">
          <div class="media-info">
            <div class="media-brand-info">
              <Avatar :img-src="props.brandAvatarUrl" xsmall class="brand-avatar" />
              <h4 class="line-clamp-1 text-ellipsis">{{ props.userHandle }}</h4>
            </div>
          </div>
          <div class="media-brand-tool">
            <div class="timestamp">{{ formatTimestamp(props.timestamp) }}</div>
          </div>
          <div class="interaction-info line-clamp-6 text-ellipsis">
            <p>{{ displayText }}</p>
          </div>
        </div>
      </div>
      <div class="comment-widget-footer">
        <div class="flex flex-col">
          <div class="flex items-end justify-between">
            <div class="align-center items-center" :class="['comment-widget-chips']">
              <MessageChips :stats="stats" :light="false" />
            </div>
          </div>
          <div class="footer-comment-count">{{ props.commentCount }} comments on this post</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.comment-widget-container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-0);
  max-width: 100%;
  width: 100%;
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-1);
  position: relative;

  .comment-widget {
    display: flex;
    flex-direction: column;
    padding: var(--space-28) var(--space-28) var(--space-14) var(--space-28);
    border-radius: var(--round-corner-small);
    flex: 1;

    .thumb-nail-avatar {
      display: flex;
      margin: 0;
      border-radius: var(--round-corner-small);
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }

      .badge-icon {
        position: absolute;
        top: var(--space-6);
        right: var(--space-6);
        z-index: 2;
      }
    }
  }

  :deep(.comment-widget-body) {
    display: flex;
    flex-direction: row;

    .media-info-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 0;
      margin-left: var(--space-16);

      .media-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .media-brand-info {
          display: flex;
          flex-direction: row;
          min-width: 0;
          align-items: center;

          .brand-avatar {
            flex-shrink: 0;
            margin-right: var(--space-8);
          }

          h4 {
            font-size: var(--x14);
            font-weight: 500;
            letter-spacing: 0;
            text-transform: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .media-brand-tool {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;

        :deep(.round.disabled) {
          opacity: 0.3;
        }

        .timestamp {
          font-size: var(--x11);
          letter-spacing: 0;
          color: var(--text-secondary);
          flex-shrink: 0;
        }
      }
    }

    .interaction-info {
      p {
        font-size: var(--x13);
      }
    }
  }

  .comment-widget-footer {
    margin: var(--space-12) 0 var(--space-24) 0;
    width: 100%;

    .comment-widget-chips {
      display: flex;
      flex: 0 auto;
      justify-content: flex-start;
      flex-wrap: wrap;

      :deep(.tweet-action-buttons) {
        display: flex;
        align-items: center;
        margin-bottom: calc(-1 * var(--space-4));
      }
    }

    .footer-comment-count {
      margin-top: var(--space-12);
      font-size: var(--x13);
    }
  }
}
</style>
