/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sorting': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M10 16h4v3h-4zM7 11h10v3H7zM5 6h14v3H5z"/></g>'
  }
})
