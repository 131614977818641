class DragMove {
  /**
   * Directive for dragging elements around within a container.
   *
   * DEPRECATED - Use the drag-move-2 directive. It works with elements of any shape, not just
   * circles as with this implementation.
   *
   * @param {object} el - Element dragging is bounded by.
   * @param {string} targetClass - Class for child items that should be draggable.
   * @param {string} targetRadius - ?
   * @param {string} scrollContainerId - ID of parent element that is scrollable. Used for
   * incrementally scrolling when dragging happens near the edge of the window.
   * @param {number} scrollOffset - ?
   * @param {Function} dragCallBack - A function called when item is dragged. selected. Will be
   * supplied three parameters:
   *   - target {object} - Element that is being dragged
   *   - x {number} - X position of element in container
   *   - y {number} - Y position of element in container
   */
  constructor({ el, targetClass, targetRadius, scrollContainerId, scrollOffset, dragCallBack }) {
    this.el = el;
    this.targetClass = targetClass;
    this.targetRadius = targetRadius;
    this.scrollContainerId = scrollContainerId;
    this.dragCallBack = dragCallBack;
    this.scrollOffset = scrollOffset;
    this.target = null;
    this.isDragging = false;
    this.dragTimer = null;
    this.lastMouseX = null;
    this.lastMouseY = null;
    this.endX = null;
    this.endY = null;
    this.scrollTick = 8;
    this.scrollDistance = 0;
    this.scrollTimer = null;
  }

  mouseDownHandler = (e) => {
    // ignore right click event
    const isNotTarget = this.targetClass.indexOf(e.target.classList) === -1;
    if (e.which === 3 || isNotTarget) {
      return;
    }
    const box = DragMove.getBoundingBox(this.el);
    const isMouseInBox = this.isMouseInBox(box, e.pageX, e.pageY);
    if (isMouseInBox) {
      this.target = e.target;
      e.preventDefault();
      const x = Math.min(Math.max(box.left, e.pageX), box.width + box.left);
      const y = Math.min(Math.max(box.top, e.pageY), box.height + box.top);
      this.endX = x;
      this.endY = y;
      this.scrollDistance = 0;
      this.dragTimer = setTimeout(() => {
        this.isDragging = true;
      }, 250);
    }
  };

  mouseMoveHandler = (e) => {
    this.scrollDistance = 0;
    if (this.isDragging) {
      e.preventDefault();
      const box = DragMove.getBoundingBox(this.el);
      const scrollableElement = document.getElementById(this.scrollContainerId);
      const scrollableBox = DragMove.getBoundingBox(scrollableElement);
      const isMouseInBox = this.isMouseInBox(box, e.pageX, e.pageY);

      if (isMouseInBox && window.innerHeight - e.clientY < 16) {
        if (this.scrollTimer === null) {
          this.incrementalScroll(this.scrollTick);
        }
      } else if (isMouseInBox && e.clientY - scrollableBox.top < 16) {
        if (this.scrollTimer === null) {
          this.incrementalScroll(this.scrollTick * -1);
        }
      } else {
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
      }
      this.lastMouseX = e.pageX;
      this.lastMouseY = e.pageY;

      const x = Math.min(Math.max(box.left, this.lastMouseX), box.width + box.left);
      const y = Math.min(Math.max(box.top, this.lastMouseY), box.height + box.top);
      this.endX = x;
      this.endY = y;
      this.renderMove();
    }
  };

  mouseUpHandler = (e) => {
    this.scrollDistance = 0;
    if (this.isDragging) {
      const box = DragMove.getBoundingBox(this.el);
      const x = Math.min(Math.max(box.left, e.pageX), box.width + box.left);
      const y = Math.min(Math.max(box.top, e.pageY), box.height + box.top);
      this.endX = x;
      this.endY = y;
      this.isDragging = false;
    }
    if (this.dragTimer) {
      clearTimeout(this.dragTimer);
    }
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
  };

  scrollHandler = () => {
    if (this.isDragging) {
      const box = DragMove.getBoundingBox(this.el);
      const x = Math.min(Math.max(box.left, this.lastMouseX), box.width + box.left);
      const y = Math.min(
        Math.max(box.top, this.lastMouseY + this.scrollDistance),
        box.height + box.top,
      );
      this.endX = x;
      this.endY = y;
      this.renderMove();
    }
  };

  init() {
    document.addEventListener('mousedown', this.mouseDownHandler);
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
    document.addEventListener('scroll', this.scrollHandler);
  }

  deinit() {
    document.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    document.removeEventListener('scroll', this.scrollHandler);
  }

  isMouseInBox(box, eventPageX, eventPageY) {
    return (
      box.top - this.targetRadius <= eventPageY &&
      eventPageY <= box.top + box.height + this.targetRadius &&
      box.left - this.targetRadius <= eventPageX &&
      eventPageX <= box.left + box.width + this.targetRadius
    );
  }

  incrementalScroll(scrollTick) {
    this.scrollTimer = setTimeout(() => {
      const scrollEl = document.getElementById(this.scrollContainerId);
      scrollEl.scrollTo(0, scrollEl.scrollTop + scrollTick);
      this.scrollDistance += scrollTick;
      this.incrementalScroll(scrollTick);
    }, 50);
  }

  getBadgePosition() {
    const box = this.el.getBoundingClientRect();
    return {
      x: this.endX - box.left,
      y: this.endY - box.top - this.scrollOffset,
      left: this.endX - box.left - this.targetRadius,
      top: this.endY - box.top - this.targetRadius - this.scrollOffset,
    };
  }

  renderMove() {
    const targetEl = this.target;
    const { style } = targetEl;
    const s = this.getBadgePosition();
    style.left = `${s.left}px`;
    style.top = `${s.top}px`;
    if (this.dragCallBack) {
      this.dragCallBack(this.target, s.x, s.y);
    }
  }

  static getBoundingBox(element) {
    const box = element.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      left: box.left + window.pageXOffset,
      width: box.width,
      height: box.height,
    };
  }
}

export default {
  beforeMount: (el, bind) => {
    const { targetClass, scrollContainerId, targetRadius, dragCallBack, scrollOffset } = bind.value;
    new DragMove({
      el,
      targetClass,
      scrollContainerId,
      targetRadius,
      dragCallBack,
      scrollOffset,
    }).init();
  },
  unmounted: (el) => {
    new DragMove({ el }).deinit();
  },
};
