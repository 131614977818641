import { computed } from 'vue';
import {
  COMMUNITY_ADS_INTERACTION_TYPES,
  COMMUNITY_INTERACTION_TYPES,
  COMMUNITY_PLATFORM_FILTER_WIDGET,
} from '@/app/community/constants';
import { useCommunityPermissions } from '@/app/community/composables/useCommunityPermissions';
import { useFlagStore } from '@/stores/flag';

export function useEnabledTypes() {
  // stores and composables
  const {
    canAccessInstagramComments,
    canAccessInstagramDirect,
    canAccessTikTokComments,
    canAccessFacebookComments,
    canAccessFacebookMessenger,
    canAccessTwitterMentions,
    canAccessTwitterDirect,
    canAccessYouTubeComments,
  } = useCommunityPermissions();

  const flagStore = useFlagStore();

  const enabledTypes = computed(() => {
    const types = [];

    if (canAccessInstagramComments.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT);
      types.push(COMMUNITY_ADS_INTERACTION_TYPES.INSTAGRAM_ADS_COMMENT);
    }
    if (canAccessInstagramDirect.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION);
    }
    if (canAccessTikTokComments.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT);
      if (flagStore.flags?.communityTiktokMention) {
        types.push(COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT_MENTION);
      }
    }
    if (canAccessFacebookComments.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT);
      types.push(COMMUNITY_ADS_INTERACTION_TYPES.FACEBOOK_ADS_COMMENT);
    }
    if (canAccessFacebookMessenger.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION);
    }
    if (canAccessTwitterMentions.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION);
      types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET);
      types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE);
    }
    if (canAccessTwitterDirect.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION);
    }
    if (canAccessYouTubeComments.value) {
      types.push(COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT);
    }

    return types;
  });

  const enabledPlatforms = computed(() => {
    const platforms = [];
    if (canAccessFacebookComments.value || canAccessFacebookMessenger.value) {
      platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.FACEBOOK.value);
    }
    if (canAccessInstagramComments.value || canAccessInstagramDirect.value) {
      platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.INSTAGRAM.value);
    }
    if (canAccessTikTokComments.value) {
      platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.TIKTOK.value);
    }
    if (canAccessTwitterMentions.value || canAccessTwitterDirect.value) {
      platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.TWITTER.value);
    }
    if (canAccessYouTubeComments.value) {
      platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.YOUTUBE.value);
    }
    return platforms;
  });

  return {
    enabledTypes,
    enabledPlatforms,
  };
}
