const ORIGINAL_VALUES = Symbol('ORIGINAL_VALUES');

export const applyReversibleUpdate = (collection, id, update) => {
  const record = collection.find((item) => item.id === id);
  if (record) {
    if (!record[ORIGINAL_VALUES]) {
      record[ORIGINAL_VALUES] = {};
    }
    const originalKeys = Object.keys(record[ORIGINAL_VALUES]);
    Object.entries(update).forEach(([key, value]) => {
      if (!originalKeys.includes(key)) {
        record[ORIGINAL_VALUES][key] = record[key];
      }
      record[key] = value;
    });
  }
};

export const reverseUpdate = (collection, id) => {
  const record = collection.find((item) => item.id === id);
  if (record) {
    Object.entries(record[ORIGINAL_VALUES] ?? {}).forEach(([key, value]) => {
      record[key] = value;
    });
    delete record[ORIGINAL_VALUES];
  }
};
