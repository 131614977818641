<template>
  <section class="media-popup-sentiment-container">
    <Icon :name="iconName" medium />
    <span class="text-main">Sentiment - Caption</span>
    <InfoTooltip :tooltip="sentimentTooltip" />
    <span class="sentiment-text-container">
      <Dot :color="sentimentColor" class="dot" />
      <span class="sentiment-value-text" :style="{ color: textColor }">
        {{ sentimentValue }}
      </span>
    </span>
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import Dot from '@/components/foundation/Dot.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { useMediaDetailStore } from '@/stores/media-detail';
import { useNotificationStore } from '@/stores/notification';
import { SENTIMENTS } from '@/models/sentiment.enum';

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaPopupSentiment',
  components: { InfoTooltip, Dot, Icon },
  props: {
    sentiment: { type: Object, required: true },
  },
  computed: {
    ...mapStores(useMediaDetailStore, useNotificationStore),

    sentimentValue() {
      if (this.sentiment?.isNegative) {
        return SENTIMENTS.NEGATIVE.text;
      }
      if (this.sentiment?.isPositive) {
        return SENTIMENTS.POSITIVE.text;
      }
      if (this.sentiment?.isNeutral) {
        return SENTIMENTS.NEUTRAL.text;
      }
      return '-';
    },
    sentimentColor() {
      if (this.sentiment?.isNegative) {
        return SENTIMENTS.NEGATIVE.iconColour;
      }
      if (this.sentiment?.isPositive) {
        return SENTIMENTS.POSITIVE.iconColour;
      }
      if (this.sentiment?.isNeutral) {
        return SENTIMENTS.NEUTRAL.iconColour;
      }
      return '';
    },
    iconName() {
      return SENTIMENTS.POSITIVE.icon;
    },
    textColor() {
      return colours.ACTION.ACTION_500;
    },
    sentimentTooltip() {
      return toolTips.sentiment.publicDescription;
    },
  },
};
</script>

<style lang="postcss" scoped>
.media-popup-sentiment-container {
  min-height: 4.4rem;
  background: var(--background-300);
  border-radius: var(--round-corner);
  width: 100%;
  padding: var(--space-24);
  margin: var(--space-8) auto var(--space-8) auto;
  color: var(--text-primary);
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: var(--space-8);
  }

  .sentiment-value-text {
    color: var(--action-500);
  }

  .sentiment-text-container {
    text-align: right;
    margin-left: auto;
  }

  .dot {
    margin-right: var(--space-4);
  }
}
</style>
