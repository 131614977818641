import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import {
  DEFAULT_PERCENTAGE_GRAPH_PRECISION,
  METRIC_FORMATS,
  QUERY_TIMEFRAME,
} from '@/models/dashboards/metrics.constants';
import { toolTips } from '@/config';
import {
  formatMediaSubtypes,
  INSTAGRAM_MEDIA_TYPE_LIST as instagramAllMediaTypes,
} from '@/models/dashboards/media-types.enum';
import { GRAPH_SCALES } from './graph-scales.enum';

export const INSTAGRAM_COMPETITIVE_METRICS = Object.freeze({
  ENGAGEMENT_RATE: {
    value: 'ENGAGEMENT_RATE',
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    mediaV2MetricName: 'engagement',
    graphStats: [GRAPH_STATS.MEAN_BY_DAY.value],
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    allowContentTags: true,
  },
  ORGANIC_COMMENTS: {
    value: 'ORGANIC_COMMENTS',
    text: 'Comments',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    mediaV2MetricName: 'comments_count',
    allowContentTags: true,
  },
  ORGANIC_LIKES: {
    value: 'ORGANIC_LIKES',
    text: 'Likes',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    mediaV2MetricName: 'like_count',
    allowContentTags: true,
  },
  REACH: {
    value: 'REACH',
    text: 'Estimated Reach',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    mediaV2MetricName: 'reach',
    allowContentTags: true,
  },
  TOTAL_FOLLOWERS: {
    value: 'TOTAL_FOLLOWERS',
    text: 'Followers - Total',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    graphStats: [GRAPH_STATS.CURRENT.value, GRAPH_STATS.MEAN_BY_DAY.value],
    chart: {
      beginAtZero: false,
      min: 0,
    },
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
  },
  NET_NEW_FOLLOWERS: {
    value: 'NET_NEW_FOLLOWERS',
    text: 'Followers - Net New',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
  },
  FOLLOWER_GROWTH_RATE: {
    value: 'FOLLOWER_GROWTH_RATE',
    text: 'Followers - Growth Rate',
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl_WITH_COMMA,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG_WITH_COMMA,
    },
    graphStats: [GRAPH_STATS.MEAN_BY_DAY.value],
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
  },
  NUMBER_OF_POSTS: {
    value: 'NUMBER_OF_POSTS',
    text: 'Number of Posts',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    allowContentTags: true,
    mediaSubtypes: formatMediaSubtypes('NUMBER_OF_POSTS', instagramAllMediaTypes),
  },
  AVG_REACH: {
    value: 'AVG_REACH',
    text: 'Avg. Estimated Reach',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    graphScales: [GRAPH_SCALES.DAILY.value],
    graphStats: [],
    tooltip: toolTips.instagramDashboard.avgEstimatedReach,
    allowContentTags: true,
  },
  AVG_ENGAGEMENT_RATE: {
    value: 'AVG_ENGAGEMENT_RATE',
    text: 'Avg. Engagement Rate',
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    graphStats: [],
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    allowContentTags: true,
  },
});
