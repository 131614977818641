<template>
  <section class="tags-container">
    <header>
      <Icon small name="magic-wand-1-filled" />
      <p>Visual Tags</p>
      <InfoTooltip v-if="systemTags.length > 0" :tooltip="tooltip" xsmall />
    </header>
    <main>
      <Tags :custom-tags="customTags" :system-tags="systemTags" />
    </main>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Tags from '@/app/library/components/MediaPopup/Tags.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'VisualTags',
  components: {
    Icon,
    InfoTooltip,
    Tags,
  },
  props: {
    customTags: { type: Array, default: () => [] },
    systemTags: { type: Array, default: () => [] },
  },
  emits: ['tagsChanged'],
  data() {
    return {
      tooltip:
        'Visual tags added by Vision AI appear in light grey. Custom tags added by your brand can be added via (+) and appear in darker grey.',
    };
  },
  watch: {
    customTags(to, from) {
      if (to !== from) {
        const actionType = to < from ? 'Removed' : 'Added';
        this.$emit('tagsChanged', 'Tags', `${actionType} custom tag`);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.tags-container {
  min-height: 4.4rem;
  background: var(--background-300);
  border-radius: var(--round-corner);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--space-24);
  margin: var(--space-8) auto;
  color: var(--text-primary);

  header {
    display: flex;
    align-items: center;
    font-size: var(--x16);
    color: var(--text-primary);
  }

  .svg-icon {
    margin-right: var(--space-12);
  }

  main {
    margin-top: var(--space-12);
  }
}
</style>
