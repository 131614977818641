<template>
  <div>
    <transition name="fade">
      <div v-if="showCampaigns && !showAddNewCampaign" class="campaign-search-dropdown">
        <MultiSelectList
          :items="formattedCampaigns"
          search-placeholder="Search Campaigns"
          :loading="loading"
          :disabled="disabled"
          class="campaign-search-list"
        >
          <template #topNav>
            <div :class="['back', { disabled }]" @click="handleBackClick">
              <Icon name="caret" dir="down" xxsmall />
              <span>All</span>
            </div>
          </template>
          <template #emptyListAddOn>
            <div class="empty-campaign-view">
              <div>
                <p>No Campaigns</p>
                <Button class="add-new-button" small primary @click="handleAddNewClicked">
                  Add New
                </Button>
              </div>
            </div>
          </template>
          <template #footer>
            <footer v-if="campaignsStore.summaryCampaigns.length > 0" :class="{ disabled }">
              <a class="add-new" @click="handleAddNewClicked">Add New</a>
              <a
                v-if="!saveLoading"
                class="blue"
                data-cy="add-to-dropdown-save"
                @click="handleSaveClicked"
              >
                Save
              </a>
              <CircularLoader v-else :color="spinnerColour" small />
            </footer>
          </template>
        </MultiSelectList>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showAddNewCampaign && !showCampaigns" class="new-campaign-dropdown">
        <input
          ref="newCampaignInput"
          v-model="campaignName"
          placeholder="Campaign name"
          autofocus
          maxlength="80"
          class="new-campaign-input"
          @click="inputFocus"
        />

        <footer>
          <a @click="handleCancelClicked">Cancel</a>
          <a :class="{ disabled: !campaignName }" class="blue" @click="addNewCampaign">Add</a>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Icon from '@/components/foundation/Icon.vue';
import MultiSelectList from '@/components/MultiSelectList.vue';
import Button from '@/components/foundation/Button.vue';
import { useCampaignsStore } from '@/stores/campaigns';
import { useNotificationStore } from '@/stores/notification';
import { useMediaSelectStore } from '@/stores/media-select';
import { useSharedStore } from '@/stores/shared';
import CircularLoader from '@/components/CircularLoader.vue';
import { ACTION } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AddToCampaigns',
  components: {
    Icon,
    MultiSelectList,
    Button,
    CircularLoader,
  },
  props: {
    selectOnly: { type: Boolean, default: false },
    media: { type: [Object, Array], required: true },
    hideDropdown: { type: Function, default: () => {} },
  },
  emits: ['handleBackClick', 'handleSaveCampaigns'],
  data() {
    return {
      saveLoading: false,
      showCampaigns: true,
      showAddNewCampaign: false,
      campaignName: null,
      spinnerColour: ACTION.ACTION_500,
    };
  },
  computed: {
    ...mapStores(useCampaignsStore, useNotificationStore, useMediaSelectStore, useSharedStore),
    loading() {
      return this.campaignsStore.pending.summaryCampaigns;
    },
    disabled() {
      return this.saveLoading;
    },
    formattedCampaigns() {
      return this.campaignsStore.summaryCampaigns.map((campaign) => {
        return {
          campaign: true,
          id: campaign.id,
          name: campaign.name,
        };
      });
    },
    isMediaList() {
      return Array.isArray(this.media);
    },
    mediaIds() {
      return this.isMediaList ? this.media.map((item) => item.id) : [this.media.id];
    },
  },
  watch: {},
  created() {
    this.campaignsStore.clearSummaryCampaigns();
    this.campaignsStore.getSummaryCampaigns();
  },
  methods: {
    handleBackClick() {
      if (this.saveLoading) {
        return;
      }
      this.$emit('handleBackClick');
    },
    async handleSaveClicked() {
      this.saveLoading = true;
      if (this.selectOnly) {
        this.$emit('handleSaveCampaigns');
        this.saveLoading = false;
        return;
      }
      const promiseArray = [];
      this.sharedStore.selectedMultiSelectListItems.forEach((item) => {
        if (item?.campaign) {
          promiseArray.push(
            this.campaignsStore.addMediaToCampaign({
              campaign: item,
              media: this.isMediaList ? this.media : [this.media],
              page: this.$route.name,
            }),
          );
        }
      });
      await Promise.all(promiseArray);
      if (!this.isMediaList) {
        const campaignsToRemoveMediaFrom = this.media.campaigns.filter(
          (campaign) =>
            !this.sharedStore.selectedMultiSelectListItems.find(
              (selected) => selected.id === campaign.id,
            ),
        );
        campaignsToRemoveMediaFrom.forEach((campaign) => {
          this.campaignsStore.removeMediaFromCampaign({
            campaign,
            media: this.isMediaList ? this.media : [this.media],
          });
        });
      }

      this.$emit('handleSaveCampaigns');
      this.saveLoading = false;
    },
    handleAddNewClicked() {
      this.showCampaigns = false;
      this.showAddNewCampaign = true;
    },
    handleCancelClicked() {
      this.showAddNewCampaign = false;
      this.showCampaigns = true;
    },
    async addNewCampaign() {
      try {
        const response = await this.campaignsStore.createCampaign({ name: this.campaignName });
        this.notificationStore.setToast({ message: 'Campaign created' });
        try {
          await this.campaignsStore.addMediaToCampaign({
            campaign: response,
            media: this.isMediaList ? this.media : [this.media],
            page: this.$route.name,
          });
          this.mediaSelectStore.clearItemsFromMultiSelect();
        } catch (e) {
          if (this.notificationStore.toasts?.length === 0)
            this.notificationStore.setToast({
              message: 'An error has occurred.',
              type: 'error',
            });
        }
      } catch (e) {
        if (e?.response?.status === 409 && this.notificationStore.toasts?.length === 0) {
          this.notificationStore.setToast({
            message: 'A campaign with that name already exists',
            type: 'error',
          });
        } else if (this.notificationStore.toasts?.length === 0)
          this.notificationStore.setToast({
            message: 'An error has occurred.',
            type: 'error',
          });
      }
      this.hideDropdown();
    },
    inputFocus(e) {
      e.target.focus();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.campaign-search-dropdown,
.new-campaign-dropdown {
  position: absolute;
  z-index: var(--z-index-dropdown);
}

.campaign-search-list .back {
  margin-left: var(--space-16);
  height: var(--space-16);
  text-align: left;
  font-size: var(--x14);
  color: var(--text-secondary);
  cursor: pointer;
  transition: var(--transition-all);

  &:hover {
    color: var(--action-500);
  }

  svg {
    margin-right: var(--space-8);
  }

  &.disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.campaign-search-list {
  padding-top: var(--space-8);
}

.empty-campaign-view div {
  text-align: center;
  padding: var(--space-48) 0 var(--space-72);

  p {
    text-align: center;
    font-size: var(--x14);
    margin-bottom: var(--space-16);
  }

  .add-new-button {
    width: 8.3rem;
    margin: auto;
  }
}

.new-campaign-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 19.3rem;
  width: 16.5rem;
  background: var(--background-0);
  box-shadow: var(--shadow-4);
  border-radius: var(--round-corner-small);

  input {
    width: 13.5rem;
    height: var(--space-32);
    margin: var(--space-24) auto var(--space-8);
    padding: 0 var(--space-24);
    border-radius: var(--space-32);
    font-size: var(--x14);
    background-color: var(--background-0);
    color: var(--text-primary);
    border: 1px solid var(--border);

    &:focus,
    &:active {
      border-color: var(--action-500);
    }
  }
}

footer {
  height: var(--space-48);
  background: var(--background-300);
  font-size: var(--x14);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);

  a {
    &:hover {
      color: var(--action-500);
    }
  }

  a.disabled,
  a.disabled:hover {
    cursor: auto;
    opacity: 0.3;
  }

  .blue {
    color: var(--action-500);
  }

  .loader {
    margin: 0;
  }
}
</style>
