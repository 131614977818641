import { code, flag } from 'country-emoji';

import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';

const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });

export function getCountryName(countryCode) {
  let countryName;
  try {
    countryName = regionNamesInEnglish.of(countryCode);
  } catch {
    countryName = countryCode;
  }
  return countryName;
}

export function getCountryFlag(countryNameOrCode) {
  const countryFlag = flag(countryNameOrCode);
  return countryFlag || '-';
}

export function getCountryCode(countryNameOrFlag) {
  const countryCode = code(countryNameOrFlag);
  return countryCode || '-';
}

export function getLanguageName(languageCode) {
  let languageName;
  try {
    languageName = languageNamesInEnglish.of(languageCode);
  } catch {
    languageName = languageCode;
  }
  return languageName;
}
