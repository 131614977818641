/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3h-3V1h-2v6h2V5h3v15H3V5h2V3H3a2 2 0 00-2 2v15a2 2 0 002 2h18a2 2 0 002-2V5a2 2 0 00-2-2z"/><path pid="1" d="M8 5h7V3H8V1H6v6h2zM5 9h4v4H5zM10 9h4v4h-4zM15 9h4v4h-4zM5 14h4v4H5zM10 14h4v4h-4z"/>'
  }
})
