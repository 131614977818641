<template>
  <Popup :close="hideMessagePopup" type="medium">
    <div class="center">
      <span>
        <h4>{{ title }}</h4>
        <p v-sanitize-html="message" />
      </span>
      <a v-if="mediaPostUrl" :href="mediaPostUrl" target="_blank" rel="noopener">
        <Button large primary wide>{{ buttonText }}</Button>
      </a>
      <Button v-else large primary wide @click="clickHandle">{{ buttonText }}</Button>
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MessagePopup',
  components: {
    Button,
    Popup,
  },
  props: {
    hideMessagePopup: { type: Function, required: true },
    mediaPostUrl: { type: String, default: '' },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    clickHandle: { type: Function, default: () => {} },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.center {
  text-align: center;

  h4 {
    font-weight: 500;
  }

  h4,
  p {
    margin: var(--space-4) auto var(--space-24) auto;
  }

  button {
    margin: var(--space-48) auto 0;
  }
}
</style>
