/**
 * An extension for Tiptap that restricts the content to a single paragraph. This prevents users
 * from pasting in content with <p> tags. When used in conjunction with the hard-break-only
 * extension, this ensures that we only have <br> in our document denoting new paragraphs.
 */
import { Node } from '@tiptap/core';

const Document = Node.create({
  name: 'document',
  topNode: true,
  content: 'block',
});

export default Document;
