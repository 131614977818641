<template>
  <section>
    <div class="user-section">
      <div class="left">
        <div>
          <Avatar
            v-if="showAvatar"
            v-bind="$attrs"
            class="avatar"
            :img-src="avatarUrl"
            :tooltip="tooltipContent"
          />
          <LetterAvatar v-if="!showAvatar" class="avatar" :name="userName" :account-id="numberId" />
        </div>
        <dl>
          <dt v-if="showUserName" data-cy="media-popup-user-name" class="username">
            <a v-if="userNameExists" :href="getProfilePageUrl" target="_blank" rel="noopener">
              {{ validUserName }}
            </a>
            <p v-else>
              {{ validUserName }}
            </p>
          </dt>
          <dt v-else-if="name">
            {{ name }}
          </dt>
          <dd v-if="datePosted">{{ timeDisplayed }}</dd>
        </dl>
      </div>
    </div>
    <div v-if="showPostInfo">
      <div class="text-section">
        <p v-if="title" class="post-title">{{ title }}</p>
        <p
          ref="message"
          v-sanitize-html="messageHTML"
          :class="['post-text', { truncated: !isMessageExpanded && isLongMessage }]"
        />
        <p v-if="showSeeMore">
          <span class="see-more-toggle" @click="toggleTextTruncate">
            {{ isMessageExpanded ? 'See Less' : 'See More' }}
          </span>
        </p>
      </div>
      <a :href="permalink" target="_blank" rel="noopener">
        <Button light class="post-url">
          {{ seeOnButtonName }}
        </Button>
      </a>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import dayjs from 'dayjs';
import {
  encodeForTiptapFromInstagramFeed,
  encodeForTiptapFromTweetStatus,
} from '@/app/scheduler/utils/tiptap';
import enumTypes from '@/app/library/constants';
import Button from '@/components/foundation/Button.vue';
import { getFormattedUserName, getNumbersFromString, validateRealUser } from '@/utils/user';
import Avatar from '@/components/foundation/Avatar.vue';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import LetterAvatar from '@/components/LetterAvatar.vue';
import loadImage from '@/utils/image';
import { getUserTimezone } from '@/utils/timezone';
import { NULL_USERNAME } from '@/app/socialListening/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'UserSection',
  components: {
    LetterAvatar,
    Button,
    Avatar,
  },
  props: {
    allowExpandingMessage: { type: Boolean, default: true },
    userName: { type: String, default: null },
    name: { type: String, default: null },
    platformHomePageUrl: { type: String, default: null },
    postType: { type: String, default: null },
    postUrl: { type: String, default: null },
    datePosted: { type: String, default: null },
    postTitle: { type: String, default: null },
    message: { type: String, default: null },
    iconName: { type: String, default: null },
    avatarUrl: { type: String, default: null },
    invitationStatus: { type: String, default: null },
    email: { type: String, default: null },
    tooltip: { type: String, default: null },
    sourceId: { type: String, default: null },
    permalink: { type: String, default: null },
    sourceCreatorId: { type: String, default: null },
  },
  data() {
    return {
      enumTypes,
      isMessageExpanded: false,
      showAvatar: false,
      nullUsername: NULL_USERNAME,
    };
  },
  computed: {
    placeholderUserName() {
      const platform = this.postType.toUpperCase();
      const platformText = CHANNELS[platform]?.text;
      return `${platformText} ${NULL_USERNAME}`;
    },
    validUserName() {
      if (this.userName && validateRealUser(this.userName)) return this.userName;
      return this.placeholderUserName;
    },
    userNameExists() {
      return this.validUserName !== this.placeholderUserName;
    },
    numberId() {
      return getNumbersFromString(this.sourceCreatorId);
    },
    tooltipContent() {
      if (isFunction(this.tooltip)) {
        return this.tooltip(this.userName);
      }
      if (isString(this.tooltip)) {
        return this.tooltip;
      }
      if (this.tooltip === true) {
        return getFormattedUserName(this.userName) || this.email || '';
      }
      return '';
    },
    isLongMessage() {
      return this.message.length > 175;
    },
    userShouldDisplayAsPending() {
      const invitationStatus = this.invitationStatus;
      return !!invitationStatus && invitationStatus !== 'completed';
    },
    showPostInfo() {
      return ![enumTypes.INSTAGRAM_STORY].includes(this.postType);
    },
    getProfilePageUrl() {
      if (this.userNameExists) {
        const platform = this.postType?.toLowerCase();
        const userName = this.userName?.toLowerCase().replace(/\s/g, '');
        if (platform.includes('instagram')) {
          return `https://www.instagram.com/${userName}`;
        }
        if (platform === 'youtube') {
          return `https://www.youtube.com/@${userName}`;
        }
        if (platform === 'twitter') {
          return `https://www.twitter.com/${userName}`;
        }
      }
      return null;
    },

    showSeeMore() {
      if (!this.allowExpandingMessage) {
        return true;
      }
      return this.isLongMessage;
    },
    seeOnButtonName() {
      const platform = this.postType.toUpperCase();
      const platformText = CHANNELS[platform]?.text || '';
      return `See on ${platformText}`;
    },
    showUserName() {
      return (
        this.postType === enumTypes.INSTAGRAM ||
        this.postType === enumTypes.TWITTER ||
        this.postType === enumTypes.YOUTUBE
      );
    },
    timeDisplayed() {
      return dayjs.utc(this.datePosted).tz(getUserTimezone()).format('MMMM D, YYYY h:mm A');
    },
    title() {
      return this.postTitle;
    },
    messageHTML() {
      switch (this.postType) {
        case enumTypes.TWITTER:
          return encodeForTiptapFromTweetStatus(this.message);
        case enumTypes.INSTAGRAM:
          return encodeForTiptapFromInstagramFeed(this.message);
        default:
          return this.message;
      }
    },
  },
  async mounted() {
    try {
      const imageDecoded = await loadImage(this.avatarUrl);
      if (imageDecoded) {
        this.showAvatar = true;
      }
    } catch (error) {
      this.showAvatar = false;
    }
  },
  methods: {
    toggleTextTruncate() {
      this.isMessageExpanded = !this.isMessageExpanded;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.user-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-8);

  .left {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: var(--space-8);
    }

    dl {
      text-align: left;

      dd {
        font-size: var(--x12);
        color: var(--text-secondary);
        line-height: normal;
      }

      .username a:hover {
        color: var(--action-500);
        cursor: pointer;
      }
    }
  }

  .riq-added {
    background: transparent;
    border-radius: var(--button-border-radius);
    border: 1px solid var(--border);
    padding: var(--space-4) var(--space-16);
    font-size: var(--x13);
    font-weight: var(--font-medium);
    color: var(--text-secondary);
  }
}

.text-section {
  display: block;
  margin: var(--space-8);
  text-align: left;
  font-size: var(--x14);
  line-height: 18px;

  .post-title {
    font-weight: var(--font-medium);
  }

  .post-text {
    color: var(--text-primary);
    word-wrap: normal;
    user-select: text;
    margin-top: var(--space-14);
    overflow-wrap: break-word;

    :deep(a.twitter-mention) {
      font-weight: normal !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .truncated {
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: var(--space-2);
  }

  .see-more-toggle {
    color: var(--action-500);
    cursor: pointer;
    display: block;
    margin-top: var(--space-4);
  }
}

.post-url {
  display: inline;
  margin: var(--space-12) auto;
}

.pin-text {
  display: block;
}
</style>
