/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'instagram-feed': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><path pid="0" d="M15 1.5H3c-.825 0-1.5.675-1.5 1.5v12c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5V3c0-.825-.675-1.5-1.5-1.5zM6 15H3v-3h3v3zm0-4.5H3v-3h3v3zM6 6H3V3h3v3zm4.5 9h-3v-3h3v3zm0-4.5h-3v-3h3v3zm0-4.5h-3V3h3v3zm4.5 9h-3v-3h3v3zm0-4.5h-3v-3h3v3zM15 6h-3V3h3v3z" id="svgicon_instagram-feed_a"/></defs><g transform="translate(-1 -1)" _fill="none" fill-rule="evenodd"><mask id="svgicon_instagram-feed_b" _fill="#fff"><use xlink:href="#svgicon_instagram-feed_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_instagram-feed_a"/><g mask="url(#svgicon_instagram-feed_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h18v18H0z"/></g></g>'
  }
})
