import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { CHANNELS, compareChannels } from '@/models/dashboards/channels.enum';
import {
  getMetricMediaType,
  removeMediaTypeFromMetric,
} from '@/app/dashboards/utils/media-types.utils';
import { getMetricText } from '@/models/dashboards/metrics';
import { REPORTS } from '@/app/dashboards/utils/reports.enum';
import { formatToIndividualCompetitorTags } from '@/app/dashboards/utils/tagging.utils';
import { extractReportType } from '@/app/dashboards/utils/dashboard-general.util';
import { extractBenchmarksObjectFromIndustries } from '@/app/dashboards/utils/benchmarks.utils';
import { getMetricDetails } from '@/utils/metrics';
import { useMetricsStore } from '@/stores/metrics';
import { CHANNELS_WITH_MEDIA_TYPE_BREAKDOWN_SUPPORT } from '@/app/dashboards/constants';

export function prefixObjectProperties(value, prefix) {
  return Object.keys(value || {}).reduce((results, prop) => {
    const newProp = camelCase(`${prefix}_${prop}`);
    if (newProp === 'reportChannels') {
      const newPropAlt = 'reportChannel';
      const sortedChannelList = [...value[prop]].sort(compareChannels);
      results[newPropAlt] = sortedChannelList.toString();
    } else {
      results[newProp] = value[prop];
    }
    return results;
  }, {});
}

export function objectChanges(object, base) {
  return Object.keys(object || {}).reduce((acc, key) => {
    if (!isEqual(object?.[key], base?.[key])) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
}

export function extractBrandNames(brands, ids) {
  return brands?.filter((brand) => ids?.includes(brand.id))?.map((brand) => brand?.name);
}

export function extractCompetitorNames(competitors, ids) {
  return competitors
    ?.filter((competitor) => ids?.includes(competitor.sourceAccountId))
    ?.map((competitor) => competitor?.handle);
}

export function contentCreator(channel) {
  return channel === CHANNELS.INSTAGRAM_UGC.value ? 'UGC' : 'Owned';
}

export function extractBrandAndCompetitorTagProperties({ props }) {
  let brandSelectionType = null;
  let competitorSelectionType = null;

  const reportBrandTagUsed = props?.reportBrandTags?.length || 0;
  const reportCompetitorTagsUsed =
    formatToIndividualCompetitorTags(props?.reportCompetitorTags).map(
      (competitorTag) => competitorTag.tagId,
    ).length || 0;

  if (props?.reportBrandIds) {
    brandSelectionType = 'brands';
  } else if (reportBrandTagUsed) {
    brandSelectionType = 'tags';
  }

  if (props?.reportCompetitorSourceAccountIds) {
    competitorSelectionType = 'competitors';
  }

  if (reportCompetitorTagsUsed) {
    competitorSelectionType = 'tags';
  }

  return {
    brandSelectionType,
    competitorSelectionType,
    reportBrandTagUsed,
    reportCompetitorTagsUsed,
  };
}

export function extractAdsProperties({
  reportSourceAdAccountIds = [],
  reportSourceCampaignIds = [],
} = {}) {
  return {
    adAccountUsed: reportSourceAdAccountIds?.length ?? 0,
    adCampaignUsed: reportSourceCampaignIds?.length ?? 0,
  };
}

function extractContentTagNames(contentTags, ids) {
  return contentTags
    ?.filter((contentTag) => ids?.includes(contentTag.id))
    ?.map((contentTag) => contentTag?.name);
}

export function getBaseReportProperties(
  reportId,
  reportType,
  reportMeta,
  competitors,
  contentTags,
  dashboardsStore,
  previousReport = false,
) {
  const brands = reportMeta?.brand_ids || [];
  const channels = reportMeta?.channels || [reportMeta?.channel] || [];
  const reportMetrics = reportMeta?.metrics || [reportMeta?.metric] || [];
  const baseMetric = removeMediaTypeFromMetric(reportMetrics?.[0]);
  const reportChannel = channels?.length === 1 ? channels[0] : null;
  const benchmarks = reportMeta?.benchmarks || [];
  const numberOfReportCompetitors = reportMeta?.competitor_source_account_ids?.length || [];
  const metricDetails = getMetricDetails(
    baseMetric,
    channels,
    REPORTS[reportType].metricTypeReportKey,
  );

  const metricsStore = useMetricsStore();
  const metricsStoreMetricDetails = metricsStore.getTargetMetricDetails(
    reportChannel,
    REPORTS[reportType]?.metricTypeReportKey,
    baseMetric,
  );

  const userSelectedMediaTypes = Boolean(
    CHANNELS_WITH_MEDIA_TYPE_BREAKDOWN_SUPPORT.includes(reportChannel) &&
      metricsStoreMetricDetails?.supports_media_breakdown &&
      !metricsStoreMetricDetails?.locked_media_breakdown,
  );

  const mediaTypes = reportMetrics.reduce((result, metric) => {
    if (userSelectedMediaTypes) {
      const mediaTypeObj = getMetricMediaType(
        reportChannel,
        metric,
        REPORTS[reportType].metricTypeReportKey,
      );
      result.push(mediaTypeObj.text);
    }
    return result;
  }, []);

  const reportBenchmarksNames = extractBenchmarksObjectFromIndustries(
    benchmarks,
    dashboardsStore.industries,
  ).map((benchmark) => benchmark.name.replace(':', ' -'));

  if (!previousReport) {
    return {
      dashboardId: dashboardsStore.currentDashboardId,
      dashboardName: dashboardsStore.currentDashboardName,
      reportId,
      reportType: extractReportType(reportType, brands, reportMetrics, channels),
      ...prefixObjectProperties(reportMeta, 'report'),
      reportMetric: baseMetric,
      reportMetricText:
        metricDetails?.displayName ?? getMetricText(reportMeta?.channel, baseMetric, channels),
      reportMetricsMediaType: userSelectedMediaTypes ? reportMetrics : [],
      mediaType: mediaTypes,
      reportBrandNames: extractBrandNames(dashboardsStore.identityBrands, reportMeta?.brand_ids),
      reportCompetitorNames: extractCompetitorNames(
        competitors[CHANNELS.INSTAGRAM.value],
        reportMeta?.competitor_source_account_ids,
      ),
      reportBenchmarksNames,
      numberOfReportBenchmarks: benchmarks?.length,
      numberOfReportChannels: channels.length,
      numberOfReportContentTags: reportMeta?.content_tag_ids?.length ?? 0,
      numberOfReportCompetitors,
      reportOnAllContent: !reportMeta?.content_tag_ids,
      chartType: REPORTS?.[reportType]?.text || null,
      contentTagsUsed: extractContentTagNames(contentTags, reportMeta.content_tag_ids),
      ...extractBrandAndCompetitorTagProperties({
        props: { ...prefixObjectProperties(reportMeta, 'report') },
      }),
      ...extractAdsProperties(prefixObjectProperties(reportMeta, 'report')),
      numberOfReportBrands: brands.length,
    };
  }

  return {
    previousReportMetric: baseMetric,
    previousReportMetricText:
      metricDetails?.displayName ?? getMetricText(reportMeta?.channel, baseMetric, channels),
    previousReportMetricsMediaType: userSelectedMediaTypes ? reportMetrics : [],
    previousReportMediaType: mediaTypes,
    ...prefixObjectProperties(reportMeta, 'previousReport'),
  };
}
