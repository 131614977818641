<template>
  <main class="insights">
    <header data-cy="audience-section-header" @click.prevent="toggleDropdown">
      <span class="title-section">
        <Icon name="camera-live-view-on" />
        Audience
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <CollapsibleContainer class="tabs" :open="dropdownOpen">
      <div :class="['tab-headers', { 'tabs-extra-space': tabs.length > 2 }]">
        <a v-for="tab in tabs" :key="tab.name" class="tab" @click="tabChanged(tab.value)">
          <span class="tab-header">
            <span :class="['tab-text', { 'is-active': tab.value === value }]">
              {{ tab.name }}
            </span>
            <InfoTooltip v-if="tab.tooltip" :tooltip="tab.tooltip" />
          </span>
        </a>
      </div>
      <div v-if="isEmpty">
        <div class="empty-state">
          {{ emptyStateCopy }}
        </div>
      </div>
      <div v-else>
        <div v-if="selectedAudienceStatistics.subHeader" class="statistic-sub-header">
          {{ selectedAudienceStatistics.subHeader }}
        </div>
        <div v-for="(statistic, index) in selectedAudienceStatistics.statistics" :key="index">
          <AudienceStatSection
            :statistic="statistic"
            :selected-audience-tab-name="selectedAudienceTabName"
            :format="selectedAudienceStatistics.format"
          />
        </div>
      </div>
    </CollapsibleContainer>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import { audienceSectionTabs, audienceSectionTabLabels } from '@/app/library/constants';
import AudienceStatSection from '@/app/library/components/MediaPopup/AudienceStatSection.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AudienceSection',
  components: { AudienceStatSection, Icon, InfoTooltip, CollapsibleContainer },
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return value in audienceSectionTabs;
      },
    },
    /**
     * audienceStatistics object should be in the following format
     * {
     *   <audience_section_tab_value>: {
     *     tooltip: String,
     *     format: String,
     *     statistics: [
     *       {
     *          label: String,
     *          value: Number,
     *          statisticBreakdown: [
     *            {
     *              label: String,
     *              value: String,
     *              barWidthRatio: Number
     *            }
     *          ],
     *        },
     *     ]
     *   }
     * }
     */
    audienceStatistics: {
      type: Object,
      required: true,
      validator(value) {
        return Object.keys(value).every((key) => key in audienceSectionTabs);
      },
    },
    emptyStateCopy: { type: String, required: true },
  },
  emits: ['input', 'tabChanged'],
  data() {
    return {
      dropdownOpen: true,
    };
  },

  computed: {
    tabs() {
      return Object.keys(this.audienceStatistics).map((key) => {
        return {
          name: audienceSectionTabLabels[key],
          tooltip: this.audienceStatistics[key].tooltip,
          value: key,
        };
      });
    },
    selectedAudienceStatistics() {
      return this.audienceStatistics[this.value];
    },
    isEmpty() {
      return (
        !this.selectedAudienceStatistics.statistics ||
        this.selectedAudienceStatistics.statistics.length === 0
      );
    },
    selectedAudienceTabName() {
      return audienceSectionTabLabels[this.value];
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    tabChanged(option) {
      this.$emit('input', option);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .title-section {
    display: flex;
    align-items: center;
    gap: var(--space-12);
  }
}

.tabs {
  .tab {
    display: inline-block;
    font-weight: var(--font-normal);
    margin-top: 0;
    margin-right: var(--space-16);
    text-align: center;
    text-transform: capitalize;
    float: left;

    .tab-header {
      display: flex;
      align-items: center;
      font-size: var(--x14);
      float: left;
    }
  }

  .tab:last-child {
    margin-right: 0;
  }

  .is-active {
    color: var(--brand-accent);
    border-bottom: var(--space-2) solid var(--brand-accent);
    font-weight: var(--font-medium);
  }
}

.tab-headers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: var(--space-24) 0 var(--space-24) var(--space-36);

  &.tabs-extra-space {
    margin: var(--space-24) 0 var(--space-24) 0;
    justify-content: center;
  }
}

.empty-state {
  color: var(--text-secondary);
  text-align: center;
  padding: var(--space-16) var(--space-96) var(--space-32) var(--space-96);
}

.statistic-sub-header {
  margin: 0 0 var(--space-8) var(--space-36);
  text-align: left;
  color: var(--text-secondary);
}
</style>
