/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'percentCircle': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M7 5.25c0-.965-.785-1.75-1.75-1.75S3.5 4.286 3.5 5.25C3.5 6.215 4.285 7 5.25 7S7 6.215 7 5.25zm-2.333 0a.584.584 0 011.166 0 .584.584 0 01-1.166 0zM8.75 7C7.785 7 7 7.785 7 8.75s.785 1.75 1.75 1.75 1.75-.785 1.75-1.75C10.5 7.786 9.715 7 8.75 7zm0 2.333a.584.584 0 11.001-1.168.584.584 0 01-.001 1.168z"/><path pid="1" d="M4.254 8.922l4.667-4.667.825.825L5.08 9.747z"/><path pid="2" d="M7 1.167A5.84 5.84 0 001.167 7 5.84 5.84 0 007 12.833 5.84 5.84 0 0012.833 7 5.84 5.84 0 007 1.167zm0 10.5A4.672 4.672 0 012.333 7 4.672 4.672 0 017 2.333 4.672 4.672 0 0111.667 7 4.672 4.672 0 017 11.667z"/></g>'
  }
})
