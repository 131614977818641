<template>
  <div data-cy="user-list">
    <List :empty-list="{ title: noUsersMessage, image: 'empty-beach.png' }" :items="brandUsers">
      <template #empty-status>
        <slot name="empty-status" />
      </template>
      <template #main-content="{ item }">
        <UserListItem
          :key="item.id || item.user_id"
          :user="item"
          :display-role-tags="displayRoleTags"
          :display-time-zone="displayTimeZone"
          :display-user-avatar="displayUserAvatar"
        />
      </template>

      <template #action-content="{ item }">
        <div
          :key="item.id || item.user_id"
          :data-cy="`ActionItems-${item.id || item.user_id}`"
          class="action-items"
        >
          <slot name="actions" :user="item" />
        </div>
      </template>
    </List>
    <InfiniteScroll :on-infinite="onInfinite" :prevent-request="loading" get-on-created />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import List from '@/components/foundation/List.vue';
import UserListItem from '@/components/core/UserListItem.vue';

/**
 * Displays a list of brand users.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'UserList',
  components: {
    InfiniteScroll,
    UserListItem,
    List,
  },
  props: {
    /**
     * List of users with camalized property keys.
     */
    brandUsers: {
      type: Array,
      default: () => [],
    },
    /**
     * Set to true if currently loading more user data.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Function to call when infinite scroll is triggered.
     */
    onInfinite: { type: Function, default: () => {} },
    /**
     * Set to `true` to show the role 'Admin' tag next to the user name
     */
    displayRoleTags: { type: Boolean, default: null },
    /**
     * Set to `false` to not show the user time zone in list
     */
    displayTimeZone: { type: Boolean, default: true },
    /**
     * Set to `false` to not show the user avatar in list
     */
    displayUserAvatar: { type: Boolean, default: true },
  },
  computed: {
    noUsersMessage() {
      return 'No Users Found';
    },
    listEmpty() {
      return !this.loading && this.brandUsers?.length === 0;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.action-items {
  display: flex;
}
</style>
