/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pencil': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.821 3.179A3.997 3.997 0 0017.974 2a3.997 3.997 0 00-2.846 1.179L3.719 14.587l-.003.004a1.274 1.274 0 00-.261.461L2.03 20.756a.997.997 0 00.263.949l.001.001.001.001a1 1 0 00.949.264l5.704-1.427a1.266 1.266 0 00.465-.264L20.822 8.873A3.996 3.996 0 0022 6.026a4.002 4.002 0 00-1.178-2.847h-.001zm-6.41 3.549l2.863 2.863-8.568 8.568-2.863-2.863 8.568-8.568zM4.375 19.626l.595-2.374 1.78 1.781-2.375.593zM19.409 4.594c.764.765.764 2.099 0 2.864l-.719.72-2.866-2.865.721-.719c.765-.764 2.099-.765 2.864 0z"/>'
  }
})
