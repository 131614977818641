/**
 * An extension for Tiptap that generates hard breaks (<br>) instead of paragraphs whenever enter
 * is pressed. This creates simpler output when getHTML() is used, and makes it easier to convert
 * to our server text format with a regex.
 */
import { Node } from '@tiptap/core';

const HardBreakOnly = Node.create({
  name: 'hardBreakOnly',
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { schema, tr } = editor.view.state;
        const transaction = tr
          .replaceSelectionWith(schema.nodes.hardBreak.create())
          .scrollIntoView();
        editor.view.dispatch(transaction);
        return true;
      },
    };
  },
});

export default HardBreakOnly;
