<script setup>
const emit = defineEmits(['userButtonClicked']);

const props = defineProps({
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isTextOnlyButton: {
    type: Boolean,
    default: false,
  },
  summaryReady: {
    type: Boolean,
    default: false,
  },
});

function handleClick(e) {
  if (!props.isDisabled) {
    emit('userButtonClicked', e);
  }
}
</script>

<template>
  <div v-if="isTextOnlyButton" class="prompt-button-style prompt-button-font-style">
    <slot />
  </div>
  <button
    v-else
    class="prompt-button-style prompt-button-font-style"
    :class="[
      { 'cursor-pointer': !props.isDisabled },
      { 'cursor-not-allowed opacity-50': props.isDisabled },
    ]"
    :data-cy="summaryReady ? 'vision-ai-summary-prompt-button' : null"
    @click="handleClick"
  >
    <slot />
  </button>
</template>
