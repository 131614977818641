import { useIdentityStore } from '@/stores/identity';

function extractNextUrl(router) {
  const { next } = router?.currentRoute?.value?.query ?? {};
  return next ? decodeURI(next) : null;
}

export function loginRedirect(router) {
  if (!router) {
    return;
  }

  const url = extractNextUrl(router);
  router.push(url ?? { name: 'landingPage' });
}

export function loginPageRedirect(router) {
  if (!router) {
    return;
  }

  const identityStore = useIdentityStore();
  if (identityStore.identity?.invitation_status === 'accepted') {
    const setupRoute = { name: 'account.setup' };
    const next = extractNextUrl(router);
    if (next) {
      setupRoute.query = { next: encodeURI(next) };
    }
    router.push(setupRoute);
  } else {
    loginRedirect(router);
  }
}
