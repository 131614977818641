import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.libraryApiUrl);

export default {
  async createProductCatalog({ brandId, source, sourceId }, axiosConfig = {}) {
    const path = `/brands/${brandId}/product_catalog`;
    return axios.post(path, { source, source_id: sourceId }, axiosConfig);
  },

  async createProductFeed({ brandId, url = null, productCatalogId = null }, axiosConfig = {}) {
    const path = `/brands/${brandId}/product_feeds`;
    return axios.post(path, { url, product_catalog_id: productCatalogId }, axiosConfig);
  },
};
