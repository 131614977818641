import { defineAsyncComponent } from 'vue';
import { COMMUNITY_ROUTE_NAMES } from '@/app/community/constants';

const Overview = () => import('@/app/community/components/overview/Overview.vue');
const TopPosts = () => import('@/app/community/components/overview/TopPosts.vue');
const App = () => import('@/app/community/App.vue');
const InstagramCommentPopup = defineAsyncComponent(
  () => import('@/app/community/components/InstagramCommentPopup.vue'),
);
const FacebookCommentPopup = defineAsyncComponent(
  () => import('@/app/community/components/FacebookCommentPopup.vue'),
);
const TiktokCommentPopup = defineAsyncComponent(
  () => import('@/app/community/components/TiktokCommentPopup.vue'),
);
const TwitterRepliesPopup = defineAsyncComponent(
  () => import('@/app/community/components/twitter/TwitterRepliesPopup.vue'),
);
const YouTubeCommentPopup = defineAsyncComponent(
  () => import('@/app/community/components/youtube/YouTubeCommentPopup.vue'),
);
const CommunityInteractionPanel = () =>
  import('@/app/community/components/CommunityInteractionPanel.vue');
const PAGE_LEVEL_TOP = 'Community';

export const communityRouteName = 'community';
export const getCommentPopupChildren = (parentRoute) => {
  return [
    {
      name: `${parentRoute}.instagram_comments`,
      path: 'instagram-comments/:interactionId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Instagram comment`,
        modal: {
          component: InstagramCommentPopup,
        },
      },
    },
    {
      name: `${parentRoute}.tiktok_comments`,
      path: 'tiktok-comments/:interactionId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - TikTok comment`,
        modal: {
          component: TiktokCommentPopup,
        },
      },
    },
    {
      name: `${parentRoute}.facebook_comments`,
      path: 'facebook-comments/:interactionId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Facebook comment`,
        modal: {
          component: FacebookCommentPopup,
        },
      },
    },
    {
      path: 'tweets:pathMatch(.*)*',
      redirect: (to) => ({ ...to, path: to.path.replace('/tweets', '/posts') }),
    },
    {
      name: `${parentRoute}.tweets`,
      path: 'posts/:interactionId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Tweets`,
        modal: {
          component: TwitterRepliesPopup,
        },
      },
    },
    {
      name: `${parentRoute}.youtube_comments`,
      path: 'youtube-comments/:interactionId',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - YouTube comment`,
        modal: {
          component: YouTubeCommentPopup,
        },
      },
    },
  ];
};

export default {
  path: 'community',
  name: communityRouteName,
  component: App,
  meta: {
    requiresAuth: true,
    analytics: `${PAGE_LEVEL_TOP}`,
  },
  children: [
    {
      path: 'insights',
      children: [
        {
          name: COMMUNITY_ROUTE_NAMES.INSIGHTS,
          path: '',
          component: Overview,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Insights`,
            overviewRoute: true,
          },
        },
        {
          name: COMMUNITY_ROUTE_NAMES.TOP_POSTS,
          path: 'top-posts',
          component: TopPosts,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Top Posts`,
            overviewRoute: true,
          },
        },
      ],
    },
    {
      name: COMMUNITY_ROUTE_NAMES.INBOX,
      path: 'inbox',
      component: CommunityInteractionPanel,
      meta: {
        title: 'Inbox',
        analytics: `${PAGE_LEVEL_TOP} - Inbox`,
      },
      children: getCommentPopupChildren(COMMUNITY_ROUTE_NAMES.INBOX),
    },
    {
      name: COMMUNITY_ROUTE_NAMES.ASSIGNED_TO_YOU,
      path: 'assigned-to-you',
      component: CommunityInteractionPanel,
      meta: {
        title: 'Assigned to You',
        analytics: `${PAGE_LEVEL_TOP} - Assigned to You`,
      },
      children: getCommentPopupChildren(COMMUNITY_ROUTE_NAMES.ASSIGNED_TO_YOU),
    },
    {
      name: COMMUNITY_ROUTE_NAMES.ARCHIVE,
      path: 'archive',
      component: CommunityInteractionPanel,
      meta: {
        title: 'Archive',
        analytics: `${PAGE_LEVEL_TOP} - Archive`,
      },
      children: getCommentPopupChildren(COMMUNITY_ROUTE_NAMES.ARCHIVE),
    },
    {
      name: COMMUNITY_ROUTE_NAMES.ALL_MESSAGES,
      path: 'all-messages',
      component: CommunityInteractionPanel,
      meta: {
        title: 'All Messages',
        analytics: `${PAGE_LEVEL_TOP} - All Messages`,
      },
      children: getCommentPopupChildren(COMMUNITY_ROUTE_NAMES.ALL_MESSAGES),
    },
    {
      name: COMMUNITY_ROUTE_NAMES.INTERNAL_NOTES,
      path: 'internal-notes',
      component: CommunityInteractionPanel,
      meta: {
        title: 'Internal Notes',
        analytics: `${PAGE_LEVEL_TOP} - Internal Notes`,
        collaborationRoute: true,
      },
      children: getCommentPopupChildren(COMMUNITY_ROUTE_NAMES.INTERNAL_NOTES),
    },
    ...getCommentPopupChildren('community'),
  ],
};
