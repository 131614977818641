import { defineStore } from 'pinia';
import { ref } from 'vue';
import * as FullStory from '@fullstory/browser';
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';
import { useIdentityStore } from '@/stores/identity';

const EVENTS_TO_INIT = ['click', 'keydown'];

export const useFullstoryStore = defineStore('fullstory', () => {
  const identityStore = useIdentityStore();

  const isFullStoryEnabled = env.fullstoryEnabled && !isDatadogSyntheticsBrowser;
  const ready = ref(false);

  function excludeIdentityFromFullstory() {
    return identityStore?.identity?.email?.endsWith('@dashhudson.com');
  }

  function initializeFullStory() {
    if (ready.value === false) {
      FullStory.init({
        orgId: env.fullstoryOrg,
      });
      ready.value = true;
      EVENTS_TO_INIT.forEach((type) => window.removeEventListener(type, initializeFullStory));
    }
  }

  function init() {
    if (isFullStoryEnabled && !excludeIdentityFromFullstory()) {
      EVENTS_TO_INIT.forEach((type) => window.addEventListener(type, initializeFullStory));
    }
  }

  function getFullStoryUrl() {
    if (ready.value === false) {
      return '';
    }

    try {
      return FullStory.getCurrentSessionURL(true) || 'Current session URL API not ready';
    } catch (e) {
      const reason = e instanceof Error ? e.message : String(e);
      return `Unable to get url: ${reason}`;
    }
  }

  function sendFullstoryEvent(type, payload = {}) {
    if (ready.value) {
      FullStory.event(type, payload);
    }
  }

  function setIdentity(properties) {
    if (properties) {
      FullStory.identify(properties.id, properties);
    }
  }

  return {
    init,
    ready,
    isFullStoryEnabled,
    sendFullstoryEvent,
    getFullStoryUrl,
    setIdentity,
  };
});
