/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'staticBottom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20 14v5.586l2.293-2.293 1.414 1.414L19 23.414l-4.707-4.707 1.414-1.414L18 19.586V14h2zM13.414 0L18 4.586v7.416h-2V6h-4V2H2v18h10v2H2a2.002 2.002 0 01-1.995-1.85L0 20V2C0 .948.817.082 1.85.006L2 0h11.414zM11 11.001l1 2v4H4l3-4 2 2 2-4zm-4-3a2 2 0 110 4 2 2 0 010-4z" id="svgicon_staticBottom_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_staticBottom_b" _fill="#fff"><use xlink:href="#svgicon_staticBottom_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_staticBottom_a"/><g mask="url(#svgicon_staticBottom_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
