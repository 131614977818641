import { useDashboardsVisionAi } from '@/app/dashboards/composables/useDashboardsVisionAi';
import { INTENT_TYPES } from '@/components/VisionAi/constants';
import { useCommunityVisionAi } from '@/app/community/composables/useCommunityVisionAi';
import { useListeningVisionAi } from '@/app/socialListening/composables/useListeningVisionAi';
import { useIdentityStore } from '@/stores/identity';

export function getIntentQuery(intentType) {
  switch (intentType) {
    case INTENT_TYPES.DASHBOARD_REPORTS: {
      const { dashboardIntentQuery } = useDashboardsVisionAi();
      return dashboardIntentQuery.value;
    }
    case INTENT_TYPES.COMMENTS:
    case INTENT_TYPES.DMS: {
      const { communityIntentQuery } = useCommunityVisionAi();
      return communityIntentQuery.value;
    }
    case INTENT_TYPES.TOPICS: {
      const { topicIntentQuery } = useListeningVisionAi();
      return topicIntentQuery.value;
    }
    case INTENT_TYPES.TRENDS: {
      const { trendsIntentQuery } = useListeningVisionAi();
      return trendsIntentQuery.value;
    }
    case INTENT_TYPES.METRIC_ANALYTICS: {
      const { currentBrand } = useIdentityStore();
      const currentBrandId = currentBrand.id;
      return { currentBrandId };
    }

    default:
      return {};
  }
}
