/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'interface-question-mark': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g opacity=".7" clip-path="url(#clip0_183_43333)" _fill="#686a7a"><path pid="0" d="M10 18.335c-4.595 0-8.333-3.738-8.333-8.334 0-4.594 3.738-8.333 8.333-8.333 4.595 0 8.333 3.738 8.333 8.333 0 4.596-3.738 8.334-8.333 8.334zm0-15A6.674 6.674 0 003.333 10 6.674 6.674 0 0010 16.668a6.675 6.675 0 006.667-6.667A6.674 6.674 0 0010 3.335z"/><path pid="1" d="M10.833 12.5H9.167V10H10a1.668 1.668 0 000-3.333c-.92 0-1.667.746-1.667 1.666H6.667A3.338 3.338 0 0110 5a3.338 3.338 0 013.333 3.333 3.343 3.343 0 01-2.5 3.23v.937zM10 15.21a1.042 1.042 0 100-2.083 1.042 1.042 0 000 2.083z"/></g><defs><clipPath id="clip0_183_43333"><path pid="2" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
