/**
 * Non-blocking image loader
 *
 * Example usage:
 * const { naturalWidth: w, naturalHeight: h } = await loadImage(someImgSrc);
 * const aspectRatio = w / h;
 *
 * @param src The image source to load
 * @returns {Promise<HTMLImageElement>}
 */
export default async function loadImage(src) {
  const img = new Image();

  // https://html.spec.whatwg.org/multipage/embedded-content.html#dom-img-decode
  // Waiting on Image.decode vs an onload event avoids blocking the main thread while
  // performing load / decode steps.
  img.src = src;
  await img.decode();

  return img;
}
