import { ADS_REPORT_AGGREGATE_BY_TYPES } from '@/models/dashboards/aggregate-type.enum';
import { getCountryFlag } from '@/utils/countries';
import { getFormattedMetrics } from '@/app/dashboards/utils/report.utils';

const getId = (id, aggregateBy) => {
  if (aggregateBy === ADS_REPORT_AGGREGATE_BY_TYPES.PUBLISHER_PLATFORM.value) {
    return id.toUpperCase();
  }
  return id;
};

const getDisplayName = (key, name, aggregateBy) => {
  if (aggregateBy === ADS_REPORT_AGGREGATE_BY_TYPES.COUNTRY.value) {
    const countryFlag = getCountryFlag(key);
    return countryFlag === '-' ? name : `${countryFlag} ${name}`;
  }
  return name;
};

export const getMetricTableHeader = (aggregateBy) => {
  return ADS_REPORT_AGGREGATE_BY_TYPES[aggregateBy].formattedValue;
};

export const formatGroupedByReportData = (reportChannel, reportData, reportMetric, aggregateBy) => {
  if (aggregateBy === ADS_REPORT_AGGREGATE_BY_TYPES.AGE_GENDER.value) {
    const data = {};

    Object.entries(reportData).forEach(([key, value]) => {
      const ageGenderName = value?.name;
      const subStrings = ageGenderName.split(' ');
      const ageRange = subStrings[subStrings.length - 1];
      const formattedGender = ageGenderName?.substring(0, ageGenderName?.lastIndexOf(' '));

      if (!data[ageRange]) {
        data[ageRange] = {};
      }

      data[ageRange][formattedGender] = {
        ...getFormattedMetrics(reportData[key].metrics, reportMetric, reportChannel),
      };
    });

    return Object.entries(data).map(([key, value]) => {
      return {
        id: key,
        name: getDisplayName(null, key, aggregateBy),
        channel: reportChannel,
        audiences: {
          ...value,
        },
      };
    });
  }

  return Object.entries(reportData).map(([key, value]) => {
    return {
      id: getId(key, aggregateBy),
      name: getDisplayName(key, value.name, aggregateBy),
      channel: reportChannel,
      ...getFormattedMetrics(value.metrics, reportMetric, reportChannel),
    };
  });
};
