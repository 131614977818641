import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.shortURLServiceApiUrl, {
  camelizeKeys: true,
});

export function createShortURL({ url, type, brandId }, axiosConfig = {}) {
  return axios.post(
    `/api/urls`,
    {
      url,
      type,
      brandId,
    },
    axiosConfig,
  );
}

export function getShortURL({ code }, axiosConfig = {}) {
  return axios.get(`/api/urls/${code}`, axiosConfig);
}
