<template>
  <div v-if="toast" :class="toastType" class="notification flex gap-2" data-cy="notification">
    <div>
      <Icon :name="iconName" class="left-icon" />
    </div>
    <div class="flex w-full items-center gap-4">
      <div class="body gap-2" :class="{ 'same-line': toast.sameLine }">
        <div v-if="toast.htmlMessage" v-sanitize-html="toast.htmlMessage" class="main-text"></div>
        <div v-else class="main-text">{{ toast.message }}</div>
        <div v-if="toast.subtext" class="subtext text-small">{{ toast.subtext }}</div>
        <div v-if="toast.actionText && toast.actionTo" class="action">
          <a v-if="isFunction(toast.actionTo)" @click="clickAction(toast)">
            {{ toast.actionText }}
          </a>
          <a
            v-else-if="isUrlExternal(toast.actionTo)"
            :href="toast.actionTo"
            target="_blank"
            rel="noopener"
          >
            {{ toast.actionText }}
          </a>
          <router-link v-else :to="toast.actionTo">
            {{ toast.actionText }}
          </router-link>
        </div>
      </div>
      <div v-if="toast.buttonText">
        <Button small @click="clickButton(toast)">
          {{ toast.buttonText }}
        </Button>
      </div>
      <div>
        <a
          data-prevent-click-outside
          class="close"
          data-cy="close-notification"
          @click="close(toast)"
        >
          <Icon name="close" xsmall />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';

export const TOAST_TYPES = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Toast',
  components: { Icon, Button },
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  emits: ['clickAction', 'clickButton', 'close'],
  computed: {
    iconName() {
      switch (this.toastType) {
        case TOAST_TYPES.WARNING:
          return 'alertCircle';
        case TOAST_TYPES.ERROR:
          return 'alertTriangle';
        default:
          return 'checkCircle';
      }
    },
    toastType() {
      return this.toast?.type ?? TOAST_TYPES.SUCCESS;
    },
  },
  methods: {
    isUrlExternal(url) {
      return typeof url === 'string' && url.startsWith('http');
    },
    isFunction(actionTo) {
      return actionTo instanceof Function;
    },
    clickButton(toast) {
      this.$emit('clickButton', toast);
    },
    clickAction(toast) {
      this.$emit('clickAction', toast);
    },
    close(toast) {
      this.$emit('close', toast);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.notification {
  width: 36.25rem;
  padding: var(--space-24);
  box-shadow: var(--shadow-4);
  border-radius: var(--round-corner-small);
  border-left: var(--space-4) solid var(--border);
  background-color: var(--background-300);
  position: unset;
  transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  align-items: flex-start;

  .body {
    flex: 1;
    overflow: visible;
    text-overflow: ellipsis;

    .main-text,
    .subtext {
      color: var(--text-primary);
    }

    .main-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action {
      > * {
        display: inline;
        color: var(--action-500);
        font-weight: var(--font-normal);
        text-decoration: none;
      }
    }

    &.same-line {
      .main-text,
      .action,
      .subtext {
        display: inline;
      }
    }

    .subtext {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .close {
    opacity: 0.7;

    svg {
      fill: var(--icon-primary);
    }
  }

  .close:hover {
    opacity: 1;
  }

  &.success {
    background-color: var(--success-100);
    border-left-color: var(--success-500);

    .left-icon {
      fill: var(--success-500);
    }

    .body {
      .main-text,
      .subtext {
        color: var(--success-700);
      }
    }

    .close {
      svg {
        fill: var(--success-700);
      }
    }
  }

  &.error {
    background-color: var(--error-100);
    border-left-color: var(--error-500);

    .left-icon {
      fill: var(--error-500);
    }

    .body {
      .main-text,
      .subtext {
        color: var(--error-700);
      }
    }

    .close {
      svg {
        fill: var(--error-700);
      }
    }
  }

  &.warning {
    background-color: var(--alert-100);
    border-left-color: var(--alert-500);

    .left-icon {
      fill: var(--alert-500);
    }

    .body {
      .main-text,
      .subtext {
        color: var(--alert-700);
      }
    }

    .close {
      svg {
        fill: var(--alert-700);
      }
    }
  }

  &.primary {
    background-color: var(--action-100);
    border-left-color: var(--action-500);

    .left-icon {
      fill: var(--action-500);
    }

    .body {
      .main-text,
      .subtext {
        color: var(--text-primary);
      }
    }
  }
}
</style>
