import { Chart } from 'chart.js';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export function resizeAllCharts() {
  if (Chart.instances) {
    Object.keys(Chart.instances).forEach((id) => {
      Chart.instances[id].resize();
    });
  }
}

export function customTooltip(contentCallback, leftPositionOffset = 0, topPositionOffset = 15) {
  return (context) => {
    const tooltipModel = context.tooltip;
    // Create tooltip element if required
    let tooltipEl = document.getElementById(`chartjs-tooltip-${context.chart.id}`);
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = `chartjs-tooltip-${context.chart.id}`;
      tooltipEl.classList = ['chartjs-tooltip pointer-events-auto'];
      tooltipEl.innerHTML = '<div class="chartToolTip"></div>';
      tooltipEl.mouseIsOver = false;

      document.body.appendChild(tooltipEl);
      tooltipEl.addEventListener('mouseenter', (event) => {
        tooltipEl.mouseIsOver = true;
        const position = context.chart.canvas.getBoundingClientRect();
        // only show tooltip if cursor is over tooltip and within bounds of chart
        if (
          event.clientX >= position.left &&
          event.clientX <= position.right &&
          event.clientY <= position.bottom &&
          event.clientY >= position.top
        ) {
          tooltipEl.style.opacity = 1;
        }
      });
      tooltipEl.addEventListener('mouseleave', (event) => {
        tooltipEl.mouseIsOver = false;
        tooltipEl.style.opacity = 0;
        const position = context.chart.canvas.getBoundingClientRect();
        // remove the tooltip from the DOM if the cursor is outside the bounds of chart to prevent invisible tooltip blocking chart hover actions
        if (
          event.clientX < position.left ||
          event.clientX > position.right ||
          event.clientY > position.bottom ||
          event.clientY < position.top
        ) {
          tooltipEl.remove();
        }
      });
    }

    // Hide if cursor isn't over tooltip or there is no tooltip
    if (!tooltipEl.mouseIsOver && tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    const contentHtml = contentCallback(context);
    tooltipEl.querySelector('div.chartToolTip').innerHTML = contentHtml;

    // Set tooltip display and position
    const position = context.chart.canvas.getBoundingClientRect();
    let leftPx =
      position.left +
      tooltipModel.caretX +
      window.scrollX -
      tooltipEl.clientWidth / 2 +
      leftPositionOffset;
    const topPx =
      position.top +
      tooltipModel.caretY +
      window.scrollY -
      tooltipEl.clientHeight -
      topPositionOffset;

    if (leftPx < 0) {
      leftPx = 0;
    }
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.display = 'block';
    tooltipEl.style.left = `${leftPx}px`;
    tooltipEl.style.top = `${topPx}px`;
  };
}

export function cleanupChartElements() {
  [
    document.getElementById('hover-point-element'),
    ...document.querySelectorAll('[id^="chartjs-tooltip"]'),
  ]
    .filter((elem) => !!elem)
    .forEach((elem) => elem.remove());
}

export function sortBarChartData(chartData, sort) {
  const sortedChartData = cloneDeep(chartData);

  if (sort) {
    const counts = {};
    chartData.labels.forEach((label, idx) => {
      chartData.datasets.forEach((dataset) => {
        const dataForLabel = dataset.data[idx];
        const labelKey = `${label.type}:${label.id}`;
        // we want to keep the counts[labelKey] undefined if all values are undefined
        if (isNumber(dataForLabel)) {
          if (counts[labelKey] === undefined) {
            counts[labelKey] = 0;
          }
          counts[labelKey] += dataForLabel;
        }
      });
    });

    sortedChartData.labels.sort((a, b) => {
      const aLabelKey = `${a.type}:${a.id}`;
      const bLabelKey = `${b.type}:${b.id}`;
      if (sort.toLowerCase() === 'asc') {
        const aMetric = counts[aLabelKey] ?? Number.POSITIVE_INFINITY;
        const bMetric = counts[bLabelKey] ?? Number.POSITIVE_INFINITY;
        return aMetric - bMetric;
      }
      if (sort.toLowerCase() === 'desc') {
        const aMetric = counts[aLabelKey] ?? Number.NEGATIVE_INFINITY;
        const bMetric = counts[bLabelKey] ?? Number.NEGATIVE_INFINITY;
        return bMetric - aMetric;
      }
      return 0;
    });

    sortedChartData.datasets.forEach((dataset) => {
      dataset.data = [];
      if (Array.isArray(dataset.dhDataMeta)) {
        dataset.dhDataMeta = [];
      }
    });

    sortedChartData.labels.forEach((newLabel) => {
      const oldIndex = chartData.labels.findIndex((oldLabel) => isEqual(oldLabel, newLabel));
      sortedChartData.datasets.forEach((dataset, datasetIndex) => {
        const oldDataset = chartData.datasets[datasetIndex];
        const value = oldDataset.data[oldIndex];
        dataset.data.push(value);

        if (Array.isArray(oldDataset.dhDataMeta)) {
          const dhDataMeta = oldDataset.dhDataMeta[oldIndex];
          dataset.dhDataMeta.push(dhDataMeta);
        }
      });
    });
  }

  return sortedChartData;
}

export function sortLineTimeChartData(chartData, sort) {
  const clonedChartData = cloneDeep(chartData);
  if (sort) {
    clonedChartData.datasets.sort((a, b) => {
      const aTotal = a?.data?.reduce((acc, value) => acc + value.y, 0) ?? 0;
      const bTotal = b?.data?.reduce((acc, value) => acc + value.y, 0) ?? 0;
      if (sort.toLowerCase() === 'asc') {
        return aTotal - bTotal;
      }
      if (sort.toLowerCase() === 'desc') {
        return bTotal - aTotal;
      }
      return 0;
    });
  }
  return clonedChartData;
}

export function limitBarChartData(chartData, limit) {
  const limitedChartData = cloneDeep(chartData);
  let excludedDatasetLabels;
  let excludedDhDataMeta;

  if (isNumber(limit) && limit > -1) {
    if (limitedChartData.labels.length > limit) {
      limitedChartData.labels.length = limit;
    }
    limitedChartData.datasets.forEach((dataset) => {
      if (dataset.data.length > limit) {
        dataset.data.length = limit;
      }
      if (dataset.dhDataMeta?.length > limit) {
        dataset.dhDataMeta.length = limit;
      }
    });

    excludedDatasetLabels = cloneDeep(chartData).labels.filter(
      (label) => !limitedChartData.labels.some((limitedLabel) => isEqual(limitedLabel, label)),
    );
    excludedDhDataMeta = chartData.datasets?.map((dataset) => {
      return dataset?.dhDataMeta?.slice(limit) ?? [];
    });
  }

  return {
    chartData: limitedChartData,
    excludedDatasetLabels,
    excludedDhDataMeta,
  };
}

export function limitLineTimeChartData(chartData, limit, mustIncludeDatasetLabels = []) {
  const limitedChartData = cloneDeep(chartData);
  let excludedDatasetLabels;

  if (isNumber(limit) && limit > -1) {
    if (limitedChartData?.datasets?.length > limit) {
      const mustIncludeDatasets = cloneDeep(chartData).datasets?.filter(
        (dataset, index) => mustIncludeDatasetLabels.includes(dataset?.label) && index >= limit,
      );
      limitedChartData.datasets.length = limit - (mustIncludeDatasets?.length ?? 0);
      limitedChartData.datasets = limitedChartData.datasets.concat(mustIncludeDatasets);
      excludedDatasetLabels = cloneDeep(chartData)
        .datasets.filter(
          (dataset) =>
            !limitedChartData.datasets.some(
              (limitedDataset) => limitedDataset.label === dataset.label,
            ),
        )
        .map((dataset) => dataset.label);
    }
  }

  return {
    chartData: limitedChartData,
    excludedDatasetLabels,
  };
}

export default {
  customTooltip,
  resizeAllCharts,
};
