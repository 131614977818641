/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'handleLines': {
    width: 6,
    height: 11,
    viewBox: '0 0 6 11',
    data: '<path pid="0" d="M.5.5v10.05V.5zm5 0v10.05V.5z" _stroke="#DDD" _fill="none" fill-rule="evenodd" stroke-linecap="square"/>'
  }
})
