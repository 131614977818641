import { mapStores } from 'pinia';
import { useSocketStore } from '@/stores/socket';

export default {
  computed: {
    ...mapStores(useSocketStore),
  },
  mounted() {
    if (this.$options.sockets) {
      Object.keys(this.$options.sockets).forEach((event) => {
        this.socketStore.addListener(event, this.$options.sockets[event], this);
      });
    }
  },
  beforeUnmount() {
    if (this.$options.sockets) {
      Object.keys(this.$options.sockets).forEach((event) => {
        this.socketStore.removeListener(event, this.$options.sockets[event], this);
      });
    }
  },
};
