import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { BRAND, ORGANIZATION, USER } from '@/models/auth/permissions.enum';
import { communityRouteName } from '@/app/community/routes';
import { INTENT_TYPES, INTENT_TYPES_BY_ROUTE_NAME } from '@/components/VisionAi/constants';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';

export function useVisionAiPermissions() {
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();
  const route = useRoute();

  const visionAiDashboardFeatureFlagSet = computed(() => {
    return flagStore.ready && flagStore.flags.visionAiDashboards;
  });

  const visionAiCommunityFeatureFlagSet = computed(() => {
    return flagStore.ready && flagStore.flags.visionAiSummarizationCommunity;
  });

  const visionAiSocialListeningFeatureFlagSet = computed(() => {
    return flagStore.ready && flagStore.flags.visionAiSummarizationListening;
  });

  const userCanAccessVisionAiForSomeBrand = computed(() => {
    return identityStore.allUserBrands.some((brand) => {
      const brandAccess = identityStore.guardSome(
        [BRAND.VISION.CAN_ACCESS_VISION_AI_COMMUNITY, BRAND.VISION.CAN_ACCESS_VISION_AI_DASHBOARDS],
        brand,
      );
      const userAccess = identityStore.guard(USER.VISION.CAN_ACCESS_VISION_AI, brand);
      return brandAccess && userAccess;
    });
  });

  const userCanAccessVisionAiForSomeOrg = computed(() => {
    return identityStore.allUserBrands.some((brand) => {
      const orgAccess = identityStore.guard(
        ORGANIZATION.LISTENING.CAN_ACCESS_VISION_AI_SOCIAL_LISTENING,
        brand,
      );
      const userAccess = identityStore.guard(USER.VISION.CAN_ACCESS_VISION_AI, brand);
      return orgAccess && userAccess;
    });
  });

  const userCanAccessVisionAiForSomeBrandOrOrg = computed(() => {
    return userCanAccessVisionAiForSomeBrand.value || userCanAccessVisionAiForSomeOrg.value;
  });

  const someBrandCanAccessDashboardsAndVisionAi = computed(() => {
    if (!visionAiDashboardFeatureFlagSet.value) return false;
    return identityStore.allUserBrands.some((brand) => {
      const brandCanAccessDashboards = identityStore.guard(
        BRAND.VISION.CAN_ACCESS_VISION_AI_DASHBOARDS,
        brand,
      );
      const userCanAccessVisionAi = identityStore.guard(USER.VISION.CAN_ACCESS_VISION_AI, brand);
      return brandCanAccessDashboards && userCanAccessVisionAi;
    });
  });
  const someUserBrandCanAccessVisionAiConversational = computed(() => {
    return identityStore.allUserBrands.some((brand) => {
      const userBrandCanAccessConversational = identityStore.guard(
        USER.VISION.CAN_ACCESS_VISION_AI_CONVERSATIONAL,
        brand,
      );
      return userBrandCanAccessConversational;
    });
  });
  const userCanAccessCommunityVisionAiForCurrentBrand = computed(() => {
    if (!visionAiCommunityFeatureFlagSet.value) return false;
    return (
      identityStore.guard(BRAND.VISION.CAN_ACCESS_VISION_AI_COMMUNITY) &&
      identityStore.guard(USER.VISION.CAN_ACCESS_VISION_AI)
    );
  });

  const userCanAccessSocialListeningVisionAiForCurrentBrand = computed(() => {
    if (!visionAiSocialListeningFeatureFlagSet.value) return false;
    return (
      identityStore.guard(ORGANIZATION.LISTENING.CAN_ACCESS_VISION_AI_SOCIAL_LISTENING) &&
      identityStore.guard(USER.VISION.CAN_ACCESS_VISION_AI)
    );
  });

  const userHasVisionAiPermissionForCurrentRoute = computed(() => {
    if (route && route.name?.startsWith(communityRouteName)) {
      return userCanAccessCommunityVisionAiForCurrentBrand.value;
    }

    if (route && route.name === SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW) {
      return userCanAccessSocialListeningVisionAiForCurrentBrand.value;
    }

    const intent = INTENT_TYPES_BY_ROUTE_NAME?.[route?.name];
    switch (intent) {
      case INTENT_TYPES.TRENDS:
      case INTENT_TYPES.TOPICS: {
        return userCanAccessSocialListeningVisionAiForCurrentBrand.value;
      }
      case INTENT_TYPES.DASHBOARD_REPORTS: {
        return someBrandCanAccessDashboardsAndVisionAi.value;
      }
      default:
        return false;
    }
  });

  return {
    userCanAccessVisionAiForSomeBrandOrOrg,
    userHasVisionAiPermissionForCurrentRoute,
    someBrandCanAccessDashboardsAndVisionAi,
    someUserBrandCanAccessVisionAiConversational,
  };
}
