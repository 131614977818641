export const LINKEDIN_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'PAGE_VIEWS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['PAGE_VIEWS_ALL_POSTS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 9,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'COMMENTS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 9,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'REACTIONS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICK_THROUGH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICKS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'SHARES_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 21,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'IMPRESSIONS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'UNIQUE_IMPRESSIONS_ALL_POSTS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: {
      name: 'All Published Posts',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 29,
    meta: {
      name: 'Posts Published During Reporting Period',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 30,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'WATCH_TIME_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 30,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'ENGAGEMENTS_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'COMMENTS_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICKS_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_CLICK_THROUGH_RATE_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 42,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'IMPRESSIONS_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 42,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'SHARES_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 46,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 46,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'REACTIONS_BY_POST',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 50,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'UNIQUE_VISITORS_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 50,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_UNIQUE_IMPRESSIONS_BY_POST',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 54,
    meta: {
      name: 'Post Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 55,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metrics: ['ENGAGEMENT_RATE'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 59,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metrics: ['CLICK_THROUGH_RATE_BY_POST'],
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 63,
    meta: {
      name: 'Follower Activity',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 64,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 68,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 68,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 64,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 17,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_ALL_POSTS',
    },
  },
];
