<template>
  <div class="mention-tooltip">
    <component :is="component" v-bind="mention" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MentionTooltip',
  props: {
    mention: { type: Object, required: true },
    component: { type: Object, required: true },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.mention-tooltip {
  background-color: var(--background-0);
  box-shadow: var(--shadow-4);
  border-radius: var(--round-corner);
  padding: var(--space-8) 0;
}
</style>
