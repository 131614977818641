<template>
  <Select
    :date-range-value="dateRange"
    :options="dateOptions"
    :columns="columns"
    type="calendar"
    :dropdown-position-top="topOffset"
    :disabled="disabled"
    :select-relative-position="!showAsEditButton"
    :is-updating="isUpdating"
    :calendar-always-custom="calendarAlwaysCustom"
    :reset-on-open="resetOnOpen"
    :calendar-max-date="calendarMaxDate"
    :calendar-min-date="calendarMinDate"
    :event-tracker="eventTracker"
    @selected="dateRange = $event"
  >
    <Icon
      v-if="showAsEditButton"
      v-tooltip="tooltip"
      class="edit-button"
      :class="{ 'edit-button-disabled': disabled }"
      name="pencil"
      xsmall
      :color="editButtonColor"
    />

    <div v-else class="selector-button" :class="{ disabled, border }">
      <Icon :name="iconName" :color="iconColor" small class="selector-icon" />
      <div class="selector-label">{{ label }}</div>
      <div v-if="preposition" class="preposition">{{ preposition }}</div>
      <div v-if="description" class="description">{{ description }}</div>
      <div v-if="description && !disabled" class="reset-button" @click.stop="reset">
        <Icon class="close-icon" name="close" xxxsmall />
      </div>
    </div>

    <template #below-calendar="{ startDate, endDate }">
      <slot name="below-calendar" :start-date="startDate" :end-date="endDate" />
    </template>
  </Select>
</template>

<script>
import dayjs from 'dayjs';
import { mapStores } from 'pinia';
import Select from '@/components/Select.vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { formatDateRangeLabel } from '@/utils/formatters';
import constants from '@/app/relationships/constants';
import { useAuthStore } from '@/stores/auth';

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'DateRangeSelector',
  components: {
    Select,
    Icon,
  },
  props: {
    value: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    showAsEditButton: { type: Boolean, default: false },
    topOffset: { type: String, default: 'var(--space-40)' },
    resetOnOpen: { type: Boolean, default: false },
    isUpdating: { type: Boolean, default: false },
    start: { type: String, default: null },
    end: { type: String, default: null },
    labelSameDate: { type: String, default: '' },
    labelDifferentDate: { type: String, default: '' },
    timezone: { type: String, default: null },
    iconName: { type: String, default: 'calendar' },
    enableDateOptions: { type: Boolean, default: false },
    calendarAlwaysCustom: { type: Boolean, default: false },
    columns: { type: [Number, String], default: null },
    border: { type: Boolean, default: false },
    calendarMinDate: { type: Date, default: null },
    calendarMaxDate: { type: Date, default: () => new Date() },
    tooltip: { type: String, default: null },
    eventTracker: { type: Object, default: null },
  },
  emits: ['update:end', 'update:start'],
  computed: {
    ...mapStores(useAuthStore),
    dateOptions() {
      return this.enableDateOptions ? constants.dateOptionsArray : [];
    },
    iconColor() {
      return colours.TEXT.TEXT_SECONDARY;
    },
    hasDates() {
      return this.start && this.end;
    },
    description() {
      return (
        this.hasDates &&
        (this.timezone
          ? formatDateRangeLabel(
              dayjs(this.start).tz(this.timezone),
              dayjs(this.end).subtract(1, 'day').tz(this.timezone),
            )
          : formatDateRangeLabel(this.start, this.end))
      );
    },
    isSameDate() {
      return (
        this.hasDates &&
        (this.timezone
          ? dayjs(this.end).subtract(1, 'day').isSame(dayjs(this.start), 'day')
          : this.start !== null && this.start === this.end)
      );
    },
    label() {
      return (!this.timezone && this.description) || (this.timezone && this.isSameDate)
        ? this.labelSameDate
        : this.labelDifferentDate;
    },
    preposition() {
      if (this.isSameDate) {
        return 'on';
      }
      if (this.description) {
        return 'from';
      }
      return null;
    },
    dateRange: {
      get() {
        if (this.hasDates) {
          return this.timezone
            ? [
                dayjs(this.start).tz(this.timezone).format('YYYY-MM-DD'),
                dayjs(this.end).subtract(1, 'day').tz(this.timezone).format('YYYY-MM-DD'),
              ]
            : [this.start, this.end];
        }
        return null;
      },
      set(to) {
        if (to) {
          const [start, end] = to;
          this.$emit(
            'update:start',
            this.timezone ? dayjs.tz(start, this.timezone).toISOString() : start,
          );
          this.$emit(
            'update:end',
            this.timezone ? dayjs.tz(end, this.timezone).add(1, 'day').toISOString() : end,
          );
        } else {
          this.$emit('update:start', null);
          this.$emit('update:end', null);
        }
      },
    },
    editButtonColor() {
      return this.disabled ? '#d9d7d7' : null;
    },
  },
  methods: {
    reset() {
      this.$emit('update:start', null);
      this.$emit('update:end', null);
    },
    formatDateRangeForInfoMessage(startDate, endDate) {
      return formatDateRangeLabel(startDate, dayjs(endDate).add(1, 'day').toDate(), {
        withTime: true,
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.selector-button {
  color: var(--text-primary);
  font-size: var(--space-14);
  background-color: white;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 0 var(--space-12);
  height: var(--space-32);
  border-radius: var(--space-16);
  transition: var(--transition-all);

  &.disabled {
    cursor: default;
  }

  &.border {
    border: 1px solid var(--border);
  }

  &:not(.disabled):hover {
    box-shadow: var(--shadow-1);
    transform: var(--hover-move);
  }

  .selector-icon {
    margin-right: var(--space-8);
  }

  .selector-label {
    margin-right: var(--space-2);
  }

  .preposition {
    color: var(--text-secondary);
    margin: 0 var(--space-2);
  }

  .description {
    color: var(--action-500);
    margin: 0 var(--space-2);
  }

  .reset-button {
    margin-left: var(--space-8);
  }
}

.edit-button {
  margin-left: var(--space-10);
  cursor: pointer;
  display: flex;

  &.edit-button-disabled {
    cursor: default;
  }
}
</style>
