<template>
  <div class="galleries-popup">
    <section>
      <Tags
        v-if="formattedTags.length > 0"
        :tags="formattedTags"
        :show-border="false"
        :on-tag-clicked="handleTagClicked"
        :update-tags-filter="updateTagsFilter"
        :filter-type="tagsFilterType"
        has-toggle
      />
    </section>

    <section class="filter-options">
      <form @submit.prevent="setSearchTerm">
        <ExpandableSearch
          id="galleryListSearchBar"
          v-model="searchTerm"
          large
          :expanded-width="16"
          :placeholder="searchTerm ? searchTerm : 'Search'"
        />
      </form>
      <Select
        :model-value="sort"
        :options="sortOptions"
        class="select-control"
        @selected="sortChanged"
      />
    </section>

    <CircularLoader v-if="popupGalleryStore.pending.galleries" />

    <section
      v-if="
        !popupGalleryStore.pending.galleries &&
        popupGalleryStore.galleries.data &&
        popupGalleryStore.galleries.data.length > 0
      "
      class="gallery-cards"
    >
      <a
        v-for="gallery in popupGalleryStore.galleries.data"
        :key="gallery.id"
        @click.prevent="selectGallery(gallery)"
      >
        <GalleryCard :gallery="gallery" />
      </a>
    </section>

    <div
      v-if="
        !popupGalleryStore.pending.galleries &&
        popupGalleryStore.galleries.data &&
        popupGalleryStore.galleries.data.length === 0
      "
    >
      <EmptyStatus :title="noGalleriesText" image="empty-catcouch.png" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { constants } from '@/config';
import CircularLoader from '@/components/CircularLoader.vue';
import Select from '@/components/Select.vue';
import Tags from '@/components/Tags.vue';
import EmptyStatus from '@/components/EmptyStatus.vue';
import ExpandableSearch from '@/components/foundation/form/ExpandableSearch.vue';
import { usePopupGalleryStore } from '@/stores/popup-gallery';
import { useGalleryTagStore } from '@/stores/gallery-tag';
import GalleryCard from './GalleryCard.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'GalleriesPopup',
  components: {
    EmptyStatus,
    ExpandableSearch,
    CircularLoader,
    GalleryCard,
    Select,
    Tags,
  },
  emits: ['gallerySelected'],
  data() {
    return {
      searchTerm: null,
      selectedTags: [],
      tagsFilterType: 'OR',
      sortOptions: [
        // TODO sort by media count when available
        { label: 'Recently Added', value: '-CREATED' },
        { label: 'Total Traffic', value: '-LIBRARY_TRAFFIC' },
      ],
      sort: '-CREATED',
    };
  },
  computed: {
    ...mapStores(usePopupGalleryStore, useGalleryTagStore, useAuthStore),
    queryData() {
      const params = {
        type: 'LIBRARY',
        brandId: this.authStore.currentBrand.id,
        sort: this.sort,
        tagsFilterType: this.tagsFilterType,
      };
      if (this.searchTerm) {
        params.search = this.searchTerm;
      }
      if (this.selectedTags.length > 0) {
        params.tags = this.selectedTags.toString();
      }
      return params;
    },
    formattedTags() {
      if (this.galleryTagStore.fullGalleryTagList) {
        const tags = this.galleryTagStore.fullGalleryTagList.map((tag) => {
          if (this.selectedTags.includes(tag.id)) {
            return { ...tag, showBorder: true };
          }
          return { ...tag, showBorder: false };
        });
        return tags;
      }
      return null;
    },
    noGalleriesText() {
      if (!this.searchTerm && !this.selectedTags) {
        return "You haven't created any galleries yet!";
      }
      return 'No galleries match your search.';
    },
  },
  mounted() {
    this.galleryTagStore.getFullGalleryTagList({
      activeOnly: true,
      galleryType: constants.LIBRARY,
    });
    if (!this.popupGalleryStore.galleries.data) {
      this.fetchGalleries();
    }
    if (this.popupGalleryStore.gallerySearchTerm) {
      this.searchTerm = this.popupGalleryStore.gallerySearchTerm;
    }
    if (this.galleryTagStore.galleryTagFilters) {
      this.selectedTags = this.galleryTagStore.galleryTagFilters;
    }
  },
  methods: {
    sortChanged(option) {
      this.sort = this.sortOptions[option].value;
      this.fetchGalleries();
    },
    handleTagClicked(tag) {
      if (this.selectedTags.includes(tag.id)) {
        const tagIndex = this.selectedTags.findIndex((selectedTagId) => selectedTagId === tag.id);
        this.selectedTags.splice(tagIndex, 1);
        this.galleryTagStore.setGalleryTagFilters(this.selectedTags);
      } else {
        this.selectedTags.push(tag.id);
        this.galleryTagStore.setGalleryTagFilters(this.selectedTags);
      }
      this.fetchGalleries();
    },
    setSearchTerm() {
      this.popupGalleryStore.setGallerySearchTerm(this.searchTerm);
      this.fetchGalleries();
    },
    selectGallery(selection) {
      this.$emit('gallerySelected', selection);
    },
    fetchGalleries() {
      const { type, brandId, sort, tagsFilterType, search, tags } = this.queryData;
      this.popupGalleryStore.getGalleries({
        type,
        brandId,
        sort,
        tagsFilterType,
        search,
        tags,
      });
    },
    addGalleryClicked() {
      this.$router.push({ name: 'library.galleries.new' });
    },
    updateTagsFilter(option) {
      this.tagsFilterType = option;
      this.fetchGalleries();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.galleries-popup {
  background: var(--background-300);
  padding: var(--space-40);
  border-radius: 0 0 var(--round-corner) var(--round-corner);
}

.filter-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
  grid-gap: var(--space-16);
  margin-top: var(--space-32);
}
</style>
