/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'google': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M21.56 11.25c0-.78-.07-1.53-.2-2.25H11v4.255h5.92a5.06 5.06 0 01-2.195 3.32v2.76h3.555c2.08-1.915 3.28-4.735 3.28-8.085z" _fill="#4285F4"/><path pid="1" d="M11 22c2.97 0 5.46-.985 7.28-2.665l-3.555-2.76c-.985.66-2.245 1.05-3.725 1.05-2.865 0-5.29-1.935-6.155-4.535H1.17v2.85A10.996 10.996 0 0011 22z" _fill="#34A853"/><path pid="2" d="M4.845 13.09A6.612 6.612 0 014.5 11c0-.725.125-1.43.345-2.09V6.06H1.17A10.996 10.996 0 000 11c0 1.775.425 3.455 1.17 4.94l3.675-2.85z" _fill="#FBBC05"/><path pid="3" d="M11 4.375c1.615 0 3.065.555 4.205 1.645l3.155-3.155C16.455 1.09 13.965 0 11 0 6.7 0 2.98 2.465 1.17 6.06l3.675 2.85C5.71 6.31 8.135 4.375 11 4.375z" _fill="#EA4335"/></g>'
  }
})
