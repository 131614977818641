import {
  DEFAULT_OPERATOR_BY_GROUP_TYPE,
  KEYWORD_GROUP_TYPE,
  KEYWORD_TEXT_EDITOR_ERRORS,
  KEYWORD_VALIDATION_REGEX,
  keywordDuplicateError,
  MAX_TOPIC_KEYWORD_LENGTH,
  OPERATOR_MAP,
} from '@/app/socialListening/constants';
import uniq from 'lodash/uniq';
import { useKeywordExpressionValidators } from '@/app/socialListening/composables/useKeywordExpressionValidators';

export function addKeywordToGroup(keywordGroups, idx, word) {
  const updatedGroup = {
    ...keywordGroups[idx],
    keywords: [...keywordGroups[idx].keywords, word],
  };
  keywordGroups.splice(idx, 1, updatedGroup);
}

export function removeKeywordFromGroup(keywordGroups, idx, word) {
  const updatedGroup = {
    ...keywordGroups[idx],
    keywords: keywordGroups[idx].keywords.filter((kw) => kw !== word),
  };
  // splice & replace with updated group
  keywordGroups.splice(idx, 1, updatedGroup);
}

export function addGroup(keywordGroups, groupType, defaultOperator, newGroup = {}) {
  const includeGroups = keywordGroups.filter((group) => !group.operators.includes('not'));
  let excludeGroup = keywordGroups.find((group) => group.operators.includes('not'));
  const groupLength = keywordGroups?.length;
  if (groupLength) {
    // if there are existing groups
    if (groupType === KEYWORD_GROUP_TYPE.INCLUDES) {
      const previousGroupIndex = includeGroups.length - 1;
      const previousGroup = includeGroups[previousGroupIndex];
      let newIncludeGroup;
      if (excludeGroup && previousGroupIndex < 0) {
        newIncludeGroup = {
          operators: ['or'],
          level: 0,
          keywords: [],
          ...newGroup,
        };
        excludeGroup.operators = [OPERATOR_MAP.AND.toLowerCase(), OPERATOR_MAP.NOT.toLowerCase()];
      } else {
        if (previousGroup && defaultOperator === OPERATOR_MAP.AND) {
          includeGroups[previousGroupIndex].level = 1;
        }
        newIncludeGroup = {
          operators: [defaultOperator.toLowerCase()],
          level: defaultOperator === OPERATOR_MAP.AND ? 1 : 0,
          keywords: [],
          ...newGroup,
        };
      }
      keywordGroups.splice(includeGroups.length, 0, newIncludeGroup);
    } else {
      excludeGroup = {
        keywords: newGroup.keywords ?? [],
        operators: !includeGroups.length ? ['not'] : ['and', 'not'],
        level: 0,
      };
      keywordGroups.push(excludeGroup);
    }
  } else {
    // no existing groups
    const operators = groupType === KEYWORD_GROUP_TYPE.EXCLUDES ? ['not'] : ['or'];
    keywordGroups.push({
      level: 0,
      keywords: [],
      operators,
      ...newGroup,
    });
  }
  return keywordGroups;
}

export function updateGroup(keywordGroups, index, params) {
  const group = keywordGroups[index];
  const includeGroups = keywordGroups.filter((g) => !g.operators.includes('not'));
  const { operators } = params;
  let updatedGroup = { operators };
  let previousGroup = null;
  let nextGroup = null;

  if (index > 0) {
    previousGroup = keywordGroups[index - 1];
  }
  if (index < includeGroups.length - 1) {
    nextGroup = keywordGroups[index + 1];
  }
  if (operators.includes('and') && !operators.includes('not')) {
    // if we change a group from or -> and
    if (previousGroup && !previousGroup.operators.includes('not')) {
      // if the previous group is not an excludes group then change to an and group
      updatedGroup = {
        ...updatedGroup,
        level: 1,
        operators: [OPERATOR_MAP.AND.toLowerCase()],
      };
      // if the previous group is an or level 0
      keywordGroups.splice(index - 1, 1, {
        ...previousGroup,
        level: 1,
      });
    }
  } else if (operators.includes('or')) {
    // if we change from and -> or
    if (previousGroup && previousGroup.operators.includes('or')) {
      // if the previous group was an includes or we should un-nest that group
      keywordGroups.splice(index - 1, 1, {
        ...previousGroup,
        level: 0,
      });
    }
    if (nextGroup && nextGroup.operators.includes('and') && !nextGroup.operators.includes('not')) {
      // we need to check if the next includes group is an "and"
      // if it is, we need to change the level of the current group to 1
      updatedGroup = {
        ...updatedGroup,
        level: 1,
      };
    } else {
      // just put it on level 0
      updatedGroup = {
        ...updatedGroup,
        level: 0,
        operators: ['or'],
      };
    }
  }

  if (operators.includes('not')) {
    // remove and add to avoid index clashes since "exclude" groups have their index changed to the last in the list
    keywordGroups.splice(index, 1);
    addGroup(
      keywordGroups,
      KEYWORD_GROUP_TYPE.EXCLUDES,
      DEFAULT_OPERATOR_BY_GROUP_TYPE[KEYWORD_GROUP_TYPE.EXCLUDES],
      { ...group, ...updatedGroup },
    );
  } else {
    keywordGroups.splice(index, 1, { ...group, ...updatedGroup });
  }
}

export function removeGroup(keywordGroups, index) {
  keywordGroups.splice(index, 1);
  // if a "parent" "includes" statement has its immediate child group removed
  if (keywordGroups?.length && keywordGroups?.[index - 1]?.operators?.includes('or')) {
    updateGroup(keywordGroups, index - 1, { operators: ['or'] });
  } else if (keywordGroups.length === 1 && keywordGroups[0]?.operators.includes('not')) {
    // if the last group remaining is an "excludes" group change and not -> not
    updateGroup(keywordGroups, 0, { operators: ['not'] });
  }
}

export function createOrUpdateExcludeGroup(keywordGroups, groupType, { keywords }) {
  // if an excludes group exists already just append the keywords
  const excludeGroup = keywordGroups.find((group) => group.operators.includes('not'));
  const index = keywordGroups.findIndex((group) => group.operators.includes('not'));
  if (index >= 0) {
    keywordGroups.splice(index, 1, {
      ...excludeGroup,
      keywords: [...excludeGroup.keywords, ...keywords],
    });
  } else {
    addGroup(keywordGroups, groupType, DEFAULT_OPERATOR_BY_GROUP_TYPE[groupType], {
      keywords,
    });
  }
  return keywordGroups;
}

export function escapeBeforeRegex(snippet) {
  if (snippet === null) {
    return '';
  }
  return snippet.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function orGroupedRegex(t) {
  return RegExp(`(?<=\\bOR\\s|\\()${escapeBeforeRegex(t)}(?=\\sOR\\b|\\)|\\()`, 'g');
}

export function captureLongWordRegex(str) {
  const flexibleWhitespaceStr = str.replace(/\s+/g, '\\s*');
  return RegExp(`(\\s*${flexibleWhitespaceStr}\\s*)`, 'g');
}

export function genericUnexpectedError(unexpected) {
  return unexpected ? `Unexpected "${unexpected}".` : '';
}

export function genericExpectedError(expected) {
  const verbage = expected?.length > 1 ? 'one of' : '';
  return expected ? `Expected ${verbage} "${expected.join(', ')}".` : '';
}

export function buildMessageForParseTreeError(unexpected, expected) {
  if (expected.length === 1 && expected[0] === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.MISSING_CLOSING_BRACKET;
  }
  if (expected.length === 1 && expected[0] === 'END' && unexpected === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.MISSING_STARTING_BRACKET;
  }
  if (expected.length > 1 && unexpected === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.UNEXPECTED_CLOSING_BRACKET;
  }
  if (['AND', 'OR', 'NOT'].includes(unexpected)) {
    return KEYWORD_TEXT_EDITOR_ERRORS.MULTIPLE_OPERATORS;
  }
  if (unexpected) {
    return genericUnexpectedError(unexpected);
  }
  if (expected) {
    return genericExpectedError(expected);
  }
  return `${unexpected} ${expected}`;
}

export function buildErrorForSyntaxError(unexpected, expected, n) {
  const nth = Math.max(0, n - 1);
  let regex;
  if (expected?.length && !unexpected) {
    regex = null;
  } else {
    regex = {
      pattern: RegExp(
        `(?:.*?${escapeBeforeRegex(unexpected)}){${nth}}(?:.*?)(${escapeBeforeRegex(unexpected)})`,
      ),
      inside: {
        'error-inside': RegExp(`${escapeBeforeRegex(unexpected)}$`),
      },
    };
  }
  return {
    error: buildMessageForParseTreeError(unexpected, expected),
    regex,
  };
}

export function handleTextEditorErrorsUsingKeywordGroups(keywordGroups) {
  const keywordList = [];
  const duplicateKeywords = [];
  keywordGroups.forEach((group) => {
    const keywords = group.keywords;
    if (keywords?.length > 0) {
      keywordList.push(...keywords);
      duplicateKeywords.push(...keywords.filter((k, i) => keywords.indexOf(k) < i));
    }
  });
  const maxReached = keywordList.filter((k) => k.length > MAX_TOPIC_KEYWORD_LENGTH);
  const unsupportedChar = keywordList.filter((k) => KEYWORD_VALIDATION_REGEX.test(k));

  const errors = [];
  if (duplicateKeywords.length) {
    errors.push(
      ...uniq(duplicateKeywords).map((k) => ({
        error: keywordDuplicateError(k),
        regex: {
          pattern: orGroupedRegex(k),
        },
      })),
    );
  }
  if (maxReached.length) {
    maxReached.forEach((word) => {
      errors.push({
        error: KEYWORD_TEXT_EDITOR_ERRORS.MAX_LENGTH_EXCEEDED,
        regex: {
          pattern: captureLongWordRegex(word),
        },
      });
    });
  }
  if (unsupportedChar.length) {
    unsupportedChar.forEach((char) => {
      errors.push({
        error: KEYWORD_TEXT_EDITOR_ERRORS.UNSUPPORTED_CHARACTER,
        regex: {
          pattern: RegExp(`\\s*(${escapeBeforeRegex(char)})\\s*`, 'g'),
        },
      });
    });
  }

  return errors;
}

export function handleTextEditorErrorsUsingParseTree(tree) {
  const { notClauseContainsAndNear, andDepthExceededNear, parenthesisDepthExceededNear } =
    useKeywordExpressionValidators();

  const errors = [];
  // not clause containing and operators
  let nearSnippet = notClauseContainsAndNear(tree);
  if (nearSnippet) {
    const keyword = nearSnippet.split(/\s/).slice(0)?.[0];
    const operator = nearSnippet.split(/\s/).slice(-1)?.[0];
    errors.push({
      error: `${KEYWORD_TEXT_EDITOR_ERRORS.NOT_CLAUSE_CONTAINS_ILLEGAL_OPERATOR} Found near '${nearSnippet}'.`,
      regex: {
        pattern: RegExp(`(?:\\s*NOT\\s+\\(\\s*${keyword}\\s+)(\\b${operator}\\b)`, 'g'),
        inside: {
          'error-inside': RegExp(`(\\b${operator}\\b)`),
        },
      },
    });
  }
  // handle the 2 types of depth errors separately
  nearSnippet = andDepthExceededNear(tree);
  if (nearSnippet) {
    const snippetList = nearSnippet
      .split(/\s/)
      .slice(-2)
      .map((snippet) => escapeBeforeRegex(snippet));
    errors.push({
      error: `${KEYWORD_TEXT_EDITOR_ERRORS.NESTING_DEPTH_EXCEEDED} Found near '${nearSnippet}'.`,
      regex: {
        pattern: RegExp(`(?:\\s*${snippetList?.[0]}\\s+)(${snippetList?.[1]})`, 'g'),
        inside: {
          'error-inside': RegExp(`(\\b${snippetList.slice(-1)?.[0]}\\b)`, 'g'),
        },
      },
    });
  } else {
    nearSnippet = parenthesisDepthExceededNear(tree);
    if (nearSnippet) {
      const snippetList = nearSnippet
        .split(/\s/)
        .slice(-2)
        .map((snippet) => escapeBeforeRegex(snippet));
      errors.push({
        error: `${KEYWORD_TEXT_EDITOR_ERRORS.NESTING_DEPTH_EXCEEDED} Found near '${snippetList.slice(-1).join('')}'.`,
        regex: {
          pattern: RegExp(`(?:(\\s*${snippetList?.[0]}\\s+)*\\(()${snippetList?.[1]})`, 'g'),
          inside: {
            'error-inside': /(\()/g,
          },
        },
      });
    }
  }

  return errors;
}

export function handleTextEditorErrorsUsingExpression(expression) {
  const { hasMoreThanOneNotClause } = useKeywordExpressionValidators();

  return hasMoreThanOneNotClause(expression)
    ? [
        {
          error: KEYWORD_TEXT_EDITOR_ERRORS.MULTIPLE_NOT_CLAUSES,
          regex: {
            pattern: /(?<=\b)NOT(?=\b)/g,
          },
        },
      ]
    : [];
}
