import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.facebookApiUrl, {
  camelizeKeys: true,
});

export const oldAxios = createAxiosInstance(env.facebookApiUrl);

// Facebook often returns an after cursor item when it should not
const correctPagingCursor = (response) => {
  const { data } = response.data;
  if (data?.length === 0) {
    response.data.paging.cursors.after = null;
  }
  return response;
};

export async function getFacebookPage({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/page`, axiosConfig);
}

export async function getPages({ ids, q, limit }, axiosConfig = {}) {
  return axios.get(
    `/pages`,
    merge(
      {
        params: {
          ids,
          q,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getReport360Posts({ brandId, month }, axiosConfig = {}) {
  return oldAxios.get(
    `/brands/${brandId}/facebook/reports/360/posts`,
    merge({ params: { month } }, axiosConfig),
  );
}

export default {
  async getBusinesses({ brandId, params }, axiosConfig = {}) {
    const url = `/brands/${brandId}/fb_businesses`;
    return oldAxios.get(url, { ...axiosConfig, params });
  },
  async getBusinessCatalogs({ brandId, params, paging = {} }, axiosConfig = {}) {
    const localParams = { ...params };
    const after = paging?.cursors?.after;
    if (after) {
      localParams.after = after;
    }

    const url = `/brands/${brandId}/fb_business_catalogs`;
    const res = await oldAxios.get(url, { ...axiosConfig, params: localParams });
    return correctPagingCursor(res);
  },
  async getPageMetrics({ brandId, startDate, endDate, scale }) {
    return oldAxios.get(`/brands/${brandId}/page/metrics`, {
      params: {
        startDate,
        endDate,
        scale,
      },
    });
  },
  async getCampaignList({ brandId }) {
    return oldAxios.get(`/brands/${brandId}/campaigns`);
  },
  async getAccountsFromApi(
    { brandId, fetchApi, connectFacebookAdsToken, isMonitored },
    axiosConfig = {},
  ) {
    return oldAxios.get(
      `/brands/${brandId}/ad_accounts`,
      merge(
        {
          params: {
            fetchApi,
            connectFacebookAdsToken,
            isMonitored,
          },
        },
        axiosConfig,
      ),
    );
  },
  async getAccountsMultiBrandsFromApi({ brandIds }, axiosConfig = {}) {
    return oldAxios.put('/brands/ad_accounts', { brand_ids: brandIds }, axiosConfig);
  },
  async getAllFacebookPages({ brandIds }, axiosConfig = {}) {
    return axios.put('/brands/pages', { brand_ids: brandIds }, axiosConfig);
  },
  async updateAccount({ brandId, fbAccountId, isMonitored }) {
    return oldAxios.patch(`/brands/${brandId}/ad_accounts/${fbAccountId}`, {
      is_monitored: isMonitored,
    });
  },
  async deleteAccount({ brandId, fbAccountId }) {
    return oldAxios.delete(`/brands/${brandId}/ad_accounts/${fbAccountId}`);
  },
  async getPageFansCSV({ brandId, startDate, endDate, filename, scale }) {
    return oldAxios.get(`/brands/${brandId}/exports/page_fans`, {
      params: {
        startDate,
        endDate,
        filename,
        scale,
      },
    });
  },
  async getTopPostsCSV({ brandId, startDate, endDate, sortOrder, filename, scale }) {
    return oldAxios.get(`/brands/${brandId}/exports/top_posts`, {
      params: {
        startDate,
        endDate,
        sortOrder,
        filename,
        scale,
      },
    });
  },
  async getAllPostsCSV({ brandId, startDate, endDate, socketId, filename }) {
    return oldAxios.get(`/brands/${brandId}/exports/posts`, {
      params: {
        startDate,
        endDate,
        socketId,
        filename,
      },
    });
  },
  instagramProductTags: {
    async searchProducts(
      { brandId, params: { instagramUserId, query, catalogId, after } = {} },
      axiosConfig = {},
    ) {
      const url = `/brands/${brandId}/instagram_product_tags/search_products`;
      return axios.get(url, {
        ...axiosConfig,
        params: { instagramUserId, query, catalogId, after },
      });
    },
    async searchProductsByIds({ brandId, params: { productIds } = {} }, axiosConfig = {}) {
      const url = `/brands/${brandId}/instagram_product_tags/products`;
      return axios.get(url, {
        ...axiosConfig,
        params: { productIds: productIds.join(',') },
      });
    },
  },
  facebookProductTags: {
    async searchProducts({ brandId, params: { fbPageId, catalogId, query } }) {
      const url = `/brands/${brandId}/facebook_product_tags/search_products`;
      return axios.get(url, { params: { fbPageId, catalogId, query } });
    },
    async searchProductsByIds({ brandId, params: { productIds } = {} }) {
      const url = `/brands/${brandId}/facebook_product_tags/products`;
      return axios.get(url, { params: { productIds: productIds.join(',') } });
    },
  },
  async getAccessToken({ brandId, requiredType = 'user', requiredScopes = [] }, axiosConfig = {}) {
    return axios.get('/access_tokens', {
      ...axiosConfig,
      params: { brandId, requiredType, requiredScopes: requiredScopes.join(',') },
    });
  },
};

export async function getInstagramProductTagsEligibility({ brandId }, axiosConfig = {}) {
  return oldAxios.get(`/brands/${brandId}/instagram_product_tags/eligibility`, axiosConfig);
}

export async function getInstagramProductTagsCatalogs(
  { brandId, instagramUserId },
  axiosConfig = {},
) {
  return oldAxios.get(
    `/brands/${brandId}/instagram_product_tags/catalogs`,
    merge({ params: { instagramUserId } }, axiosConfig),
  );
}

export const collateData = (currentValue, newValue, { uniqueOn, faultyNext = false } = {}) => {
  if ((currentValue ?? []).length === 0) {
    return newValue;
  }

  // Some facebook endpoints return a next when the enxt request returns an empty array
  if (!faultyNext && (newValue ?? []).length === 0) {
    return [];
  }

  // Facebook paging cannot be trusted not to have duplicates
  const combined = [...currentValue, ...newValue];
  if (!uniqueOn) {
    return combined;
  }

  const uniqueIdentifiers = new Set(combined.map((obj) => obj[uniqueOn]));
  return Array.from(uniqueIdentifiers).map((id) => combined.find((obj) => obj[uniqueOn] === id));
};

export async function getLocations(
  { brandId, query, locationType, locationFields, limit },
  axiosConfig = {},
) {
  return oldAxios.get(
    `/locations`,
    merge(
      {
        params: {
          brandId,
          query,
          locationType,
          locationFields,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getProductTagsEligibility({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/facebook_product_tags/eligibility`, axiosConfig);
}

export async function getProductTagsCatalogs({ brandId, fbPageId }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/facebook_product_tags/catalogs`,
    merge(
      {
        params: { fbPageId },
      },
      axiosConfig,
    ),
  );
}

export async function getMarketingV2Campaigns(
  { adAccountIds, brandIds, sourceCampaignIds },
  axiosConfig = {},
) {
  return axios.put(
    `/brands/marketing_v2/campaigns`,
    {
      adAccountIds,
      brandIds,
      sourceCampaignIds,
    },
    merge({}, axiosConfig),
  );
}
