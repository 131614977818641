<template>
  <div class="product-nav-wrapper">
    <div class="product-nav">
      <ul>
        <li v-for="platform in availableVisiblePlatformItems" :key="platform.name">
          <template v-if="platform.children">
            <ProductNavSubMenu :platform="platform" />
          </template>
          <template v-else>
            <router-link
              :to="platform.to"
              :class="[platform.name, { active: currentPlatformName === platform.name }]"
              :data-cy="`ProductNav-${platform.name}`"
            >
              {{ platform.formattedLinkText }}
            </router-link>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import ProductNavSubMenu from '@/components/layout/ProductNavSubMenu.vue';
import { useFlagStore } from '@/stores/flag';
import { competitiveRouteNames } from '@/app/competitive/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ProductNav',
  components: {
    ProductNavSubMenu,
  },
  emits: ['navChanged'],
  data() {
    return {
      productDropdownToggled: false,
      currentProduct: '',
      platforms: ['pinterest', 'scheduler', 'library'],
    };
  },
  computed: {
    ...mapStores(useFlagStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['user_can', 'currentBrand']),
    ...mapPiniaState(useCustomerJourneyStore, ['hasPlanGrow']),

    canAccessCommunity() {
      return this.user_can('community', 'can_access_community');
    },
    currentPlatformName() {
      const { name } = this.$route;
      if (name) {
        return name.split('.')[0];
      }
      return null;
    },
    currentBrandLabel() {
      return this.currentBrand?.label;
    },

    availablePlatforms() {
      return [
        {
          name: 'dashboards',
          visible: true,
          to: {
            name: 'dashboards',
          },
        },
        {
          name: 'analytics',
          visible: true,
          children: [
            {
              label: 'TikTok',
              iconName: 'tiktok',
              visible: true,
              to: {
                name: 'tiktok',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'Instagram',
              iconName: 'instagram',
              visible: true,
              to: {
                name: 'instagram',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'Facebook',
              iconName: 'facebook',
              visible: true,
              to: {
                name: 'facebook',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'Pinterest',
              iconName: 'pinterest',
              visible: true,
              to: {
                name: 'pinterest',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'X',
              iconName: 'twitter',
              visible: true,
              to: {
                name: 'twitter',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'YouTube',
              iconName: 'youtube',
              visible: true,
              to: {
                name: 'youtube',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'LinkedIn',
              iconName: 'linkedin',
              visible: true,
              to: {
                name: 'linkedin',
                params: { brandLabel: this.currentBrandLabel },
              },
            },
          ],
        },
        {
          name: 'relationships',
          visible: true,
        },
        {
          name: 'creators',
          visible: this.flagStore.ready && this.flagStore?.flags?.saveJackie,
        },
        {
          name: 'campaigns',
          visible: true,
        },
        {
          name: 'community',
          link: 'community',
          visible: !this.flagStore?.flags?.disableCommunity,
        },
        {
          name: 'Competitors',
          link: competitiveRouteNames.instagram.main,
          visible:
            !this.flagStore?.flags?.twitterCompetitive &&
            !this.flagStore?.flags?.youTubeCompetitive,
        },
        {
          name: 'Competitors',
          visible:
            this.flagStore?.flags?.twitterCompetitive || this.flagStore?.flags?.youTubeCompetitive,
          children: [
            {
              label: 'Instagram',
              iconName: 'instagram',
              visible: true,
              to: {
                name: competitiveRouteNames.instagram.main,
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'X',
              iconName: 'twitter',
              visible: this.flagStore?.flags?.twitterCompetitive,
              to: {
                name: competitiveRouteNames.twitter.main,
                params: { brandLabel: this.currentBrandLabel },
              },
            },
            {
              label: 'YouTube',
              iconName: 'youtube',
              visible: this.flagStore?.flags?.youTubeCompetitive,
              to: {
                name: competitiveRouteNames.youtube.main,
                params: { brandLabel: this.currentBrandLabel },
              },
            },
          ],
        },
        {
          name: 'scheduler',
          visible: true,
        },
        {
          name: 'library',
          visible: true,
        },
        {
          name: 'listening',
          link: 'socialListening',
          visible: true,
        },
      ];
    },
    availableVisiblePlatformItems() {
      return this.availablePlatforms
        .filter((platform) => platform.visible)
        .map((platform) => {
          return {
            to: {
              name: platform.link || platform.name,
              params: { brandLabel: this.currentBrandLabel },
            },
            formattedLinkText: this.formatLinkText(platform.name),
            ...platform,
          };
        });
    },
  },
  watch: {
    $route() {
      this.getCurrentProduct();
    },
    availableVisiblePlatformItems() {
      this.$emit('navChanged');
    },
  },
  mounted() {
    this.getCurrentProduct();
  },
  methods: {
    toggleProductDropdown() {
      this.productDropdownToggled = !this.productDropdownToggled;
    },
    closeProductDropdown(e) {
      if (e.target !== this.$refs.dropdownToggle) {
        this.productDropdownToggled = false;
      }
    },
    getCurrentProduct() {
      if (this.$route.name) {
        const [route] = this.$route.name.split('.');
        this.currentProduct = route;
      }
    },
    mixpanelEvent(platform) {
      this.trackingStore.track('Navigation Heading Selected', {
        itemSelected: platform.formattedLinkText,
        secondary_item_selected: null,
        page: this.$route.path,
      });
    },
    formatLinkText(name) {
      if (name === 'tiktok') {
        return 'TikTok';
      }
      if (name === 'youtube') {
        return 'YouTube';
      }
      if (name === 'linkedin') {
        return 'LinkedIn';
      }
      return name;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.product-nav-wrapper {
  display: flex;
}

.product-nav {
  display: flex;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 0 var(--space-16) var(--space-4);

      a {
        cursor: pointer;
        text-transform: capitalize;
        text-decoration: none;
        color: var(--text-secondary);
        font-size: var(--x14);
        font-weight: var(--font-normal);

        &.active {
          color: var(--brand-accent);
          font-weight: var(--font-medium);
        }
      }
    }
  }
}
</style>
