/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alertBellFill': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<mask id="svgicon_alertBellFill_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40"><path pid="0" d="M.5 20C.5 9.23 9.23.5 20 .5S39.5 9.23 39.5 20 30.77 39.5 20 39.5.5 30.77.5 20z" _fill="#fff" _stroke="#fff"/></mask><g clip-path="url(#clip0_1614_102564)"><path pid="1" d="M20 30c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V12c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-4.5 3.24-4.5 6.32v5l-2 2v1h16v-1l-2-2z" _fill="#666"/></g><defs><clipPath id="clip0_1614_102564"><path pid="2" _fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
