import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';
import { getUrlPathByPlatform } from '@/app/community/utils/comments';

export const axios = createAxiosInstance(env.communityApiUrl, {
  camelizeKeys: true,
});

export function saveCommunityInteractionTicket(
  {
    communityInteractionId,
    source,
    sourceId,
    sourceCreatedAt,
    sourceUpdatedAt,
    label,
    status,
    messageId,
  },
  axiosConfig = {},
) {
  return axios.put(
    `/community_interactions/${communityInteractionId}/tickets/${sourceId}`,
    {
      source,
      sourceId,
      sourceCreatedAt,
      sourceUpdatedAt,
      label,
      status,
      messageId,
    },
    axiosConfig,
  );
}

export function getCommentInteractionList(
  { platform, brandId, mediaId, limit = 12 },
  axiosConfig = {},
) {
  const params = {
    brandId,
    mediaId,
    limit,
  };
  const url = `/${getUrlPathByPlatform(platform)}`;
  return axios.get(
    url,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getCommentInteractionDetail(
  { platform, communityInteractionId, returnParent },
  axiosConfig = {},
) {
  const params = {
    returnParent,
  };
  return axios.get(
    `/${getUrlPathByPlatform(platform)}/${communityInteractionId}`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getFacebookComment({ id }, axiosConfig = {}) {
  return axios.get(`/facebook_comments/${id}`, axiosConfig);
}

export function getInstagramComment({ id }, axiosConfig = {}) {
  return axios.get(`/instagram_comments/${id}`, axiosConfig);
}

export function getTikTokComment({ id }, axiosConfig = {}) {
  return axios.get(`/tiktok_comments/${id}`, axiosConfig);
}

export function getTwitterComment({ id }, axiosConfig = {}) {
  return axios.get(`/tweets/${id}`, axiosConfig);
}

export function getYoutubeComment({ id }, axiosConfig = {}) {
  return axios.get(`/youtube_comments/${id}`, axiosConfig);
}

export function getFacebookCommentsCsv({ socketId, mediaId, brandId }, axiosConfig = {}) {
  return axios.post(`/facebook_comments/csv`, { socketId, mediaId, brandId }, axiosConfig);
}

export function getInstagramCommentsCsv({ socketId, mediaId, brandId }, axiosConfig = {}) {
  return axios.post(`/instagram_comments/csv`, { socketId, mediaId, brandId }, axiosConfig);
}

export function getTikTokCommentsCsv({ socketId, mediaId, brandId }, axiosConfig = {}) {
  return axios.post(`/tiktok_comments/csv`, { socketId, mediaId, brandId }, axiosConfig);
}

export function getTwitterCommentsCsv({ socketId, mediaId, brandId }, axiosConfig = {}) {
  return axios.post(`/tweets/csv`, { socketId, mediaId, brandId }, axiosConfig);
}

export function getYoutubeCommentsCsv({ socketId, mediaId, brandId }, axiosConfig = {}) {
  return axios.post(`/youtube_comments/csv`, { socketId, mediaId, brandId }, axiosConfig);
}

/*
   Interaction rules endpoints
*/
export async function getInteractionRules(brandId, axiosConfig = {}) {
  const url = `/brands/${brandId}/interaction_rules`;
  return axios.get(url, axiosConfig);
}
export async function createInteractionRule(brandId, payload, axiosConfig = {}) {
  const url = `/brands/${brandId}/interaction_rules`;
  return axios.post(url, payload, axiosConfig);
}
export async function updateInteractionRule(brandId, ruleId, payload, axiosConfig = {}) {
  const url = `/brands/${brandId}/interaction_rules/${ruleId}`;

  return axios.patch(url, payload, axiosConfig);
}
export async function deleteInteractionRule(brandId, ruleId, axiosConfig = {}) {
  const url = `/brands/${brandId}/interaction_rules/${ruleId}`;
  return axios.delete(url, axiosConfig);
}
export async function deleteInteractionRulesHistory(
  brandId,
  ruleExecutionHistoryId,
  axiosConfig = {},
) {
  const url = `/brands/${brandId}/interaction_rules_history/${ruleExecutionHistoryId}`;
  return axios.delete(url, axiosConfig);
}

export async function getRuleFilterMatchCount(brandId, ruleFilters, axiosConfig = {}) {
  const url = `/brands/${brandId}/interaction_rules/match_count`;
  return axios.post(url, { ruleFilters }, axiosConfig);
}

export default {
  async bulkUpdateCommunityInteractionsByMedia(
    { params: { brandId, mediaIds, types }, data },
    axiosConfig = {},
  ) {
    let url;
    if (types) {
      url = `/community_interactions?brand_id=${brandId}&media_ids=${mediaIds}&types=${types}`;
    } else {
      url = `/community_interactions?brand_id=${brandId}&media_ids=${mediaIds}`;
    }
    return axios.patch(url, data, axiosConfig);
  },
  async updatePostEnabled({ brandId, mediaId, commentDisabled }, axiosConfig = {}) {
    const url = `/posts/${brandId}/${mediaId}`;
    return axios.patch(url, { commentDisabled }, axiosConfig);
  },
  async createTweet({ brandId, parentTweetId, text, isQuote }, axiosConfig = {}) {
    const url = `/tweets`;
    return axios.post(url, { brandId, parentTweetId, text, isQuote }, axiosConfig);
  },
  async patchTweet(
    { communityInteractionId, isHidden = null, isLiked = null, isRetweeted = null },
    axiosConfig,
  ) {
    const url = `/tweets/${communityInteractionId}`;
    const payload = { isHidden, isRetweeted, isLiked };
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([, v]) => v !== null),
    );
    return axios.patch(url, filteredPayload, axiosConfig);
  },
  async createDM({ conversationId, platform, text, uuid }, axiosConfig = {}) {
    const url = `/conversations/${platform}/${conversationId}/messages`;
    const payload = { text, uuid };
    return axios.post(url, payload, axiosConfig);
  },
  async getPlatformAccount({ platform, brandId }, axiosConfig) {
    const url = `/conversations/${platform}/accounts/${brandId}`;
    return axios.get(url, axiosConfig);
  },
  async getPlatformMessages(
    { platform, id, limit, createdOnOrAfter, createdOnOrBefore },
    axiosConfig = {},
  ) {
    const url = `/conversations/${platform}/${id}/messages`;
    return axios.get(
      url,
      merge(
        {
          params: {
            limit,
            createdOnOrAfter,
            createdOnOrBefore,
          },
        },
        axiosConfig,
      ),
    );
  },
  async updateDM({ conversationId, platform, messageId, payload }, axiosConfig = {}) {
    const url = `/conversations/${platform}/${conversationId}/messages/${messageId}`;
    return axios.patch(url, payload, axiosConfig);
  },

  //
  // PRESET REPLY API
  //
  async getPresetReplyList({ brandId, type }, axiosConfig) {
    const url = `/preset_replies?brand_id=${brandId}&type=${type}`;
    return axios.get(url, axiosConfig);
  },
  async createPresetReply({ payload }, axiosConfig) {
    const url = `/preset_replies`;
    return axios.post(url, payload, axiosConfig);
  },
  async updatePresetReply({ replyId, payload }, axiosConfig) {
    const url = `/preset_replies/${replyId}`;
    return axios.patch(url, payload, axiosConfig);
  },
  async deletePresetReply(replyId, axiosConfig) {
    const url = `preset_replies/${replyId}`;
    return axios.delete(url, axiosConfig);
  },

  async getUnreadCommunityInteractionCounts(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/community_interactions/unread_counts`;
    return axios.get(url, { ...axiosConfig, params, camelizeKeys: false });
  },
  async getUnreadCommunityInteractionCountsV2(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/unread_interaction_counts`;
    return axios.get(url, { ...axiosConfig, params, camelizeKeys: false });
  },

  //
  // COMMENT API
  //
  async getCommentList({ next, brandId, mediaId, limit, platform }, axiosConfig = {}) {
    const url =
      next ||
      `/${getUrlPathByPlatform(platform)}?brand_id=${brandId}&media_id=${mediaId}&limit=${limit}`;
    return axios.get(url, axiosConfig);
  },
  async getCommentDetail(id, returnParent, platform, axiosConfig = {}) {
    const url = returnParent
      ? `/${getUrlPathByPlatform(platform)}/${id}?return_parent=${returnParent}`
      : `/${getUrlPathByPlatform(platform)}/${id}`;
    return axios.get(url, axiosConfig);
  },
  async updateCommentRequest({ commentId, platform, payload }, axiosConfig = {}) {
    const url = `/${getUrlPathByPlatform(platform)}/${commentId}`;
    return axios.patch(url, payload, axiosConfig);
  },
  async deleteComment(commentId, platform, softDelete, axiosConfig = {}) {
    const url = `/${getUrlPathByPlatform(platform)}/${commentId}${
      softDelete ? '?soft_delete_only=true' : ''
    }`;
    return axios.delete(url, axiosConfig);
  },
  async createComment(platform, payload, axiosConfig = {}) {
    const url = `/${getUrlPathByPlatform(platform)}`;
    return axios.post(url, payload, axiosConfig);
  },

  /* COMMUNITY OVERVIEW APIS */
  async getOverviewStats(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/overview_stats`;
    return axios.get(url, { ...axiosConfig, params });
  },
  async getAggregateSentimentByPlatform(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/sentiment_distribution_stats`;
    return axios.get(url, { ...axiosConfig, params });
  },
  async getVolumeTimeSeriesStats(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/volume_time_series`;
    return axios.get(url, { ...axiosConfig, params });
  },
  async getSentimentTimeSeries(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/sentiment_time_series`;
    return axios.get(url, { ...axiosConfig, params });
  },

  async getWordCloudTopKeywords(brandId, params, axiosConfig) {
    const url = `/brands/${brandId}/top_keywords`;
    return axios.get(url, { ...axiosConfig, params });
  },

  //
  // COMMUNITY INTERACTIONS
  //
  async getCommunityInteractions(params, axiosConfig = {}) {
    const url = params?.next ?? '/community_interactions';
    return axios.get(url, { ...axiosConfig, params: { ...params, next: undefined } });
  },
  async updateCommunityInteraction(id, payload, axiosConfig = {}) {
    return axios.patch(`/community_interactions/${id}`, payload, axiosConfig);
  },
  async bulkUpdateCommunityInteractions(params, payload, axiosConfig = {}) {
    return axios.patch('/community_interactions', payload, { ...axiosConfig, params });
  },
  async getTags(params, axiosConfig = {}) {
    return axios.get('/tags', { ...axiosConfig, params });
  },
  async createTag(payload, axiosConfig = {}) {
    return axios.post('/tags', payload, axiosConfig);
  },
  async deleteTag(tagId, axiosConfig = {}) {
    return axios.delete(`/tags/${tagId}`, axiosConfig);
  },

  async createAssignment(brandId, interactionId, payload, axiosConfig = {}) {
    const url = `/brands/${brandId}/community_interactions/${interactionId}/assignments`;
    return axios.post(url, payload, axiosConfig);
  },
};
