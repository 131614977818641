/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chatBubbleSquare': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.998 22v-4h-2c-1.104 0-2-.897-2-2V4a2 2 0 012-2h16a2 2 0 012 2v12c0 1.103-.896 2-2 2h-8.666l-5.334 4zm-2-18v12h4v2l2.666-2h9.334V4h-16z"/><path pid="1" d="M9.998 7h8v2h-8zM9.998 11h8v2h-8z"/><circle pid="2" cx="6.998" cy="8" r="1.25"/><circle pid="3" cx="6.998" cy="12" r="1.25"/>'
  }
})
