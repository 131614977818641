/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'action-show': {
    width: 26,
    height: 24,
    viewBox: '0 0 26 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.927 11.96C23.008 7.192 18.307 4 13 4 7.694 4 2.993 7.193 1.072 11.96l-.15.373.15.374c1.921 4.766 6.622 7.96 11.928 7.96 5.306 0 10.007-3.194 11.927-7.96l.151-.374-.15-.373zm-2.014.373c-1.707 3.805-5.566 6.334-9.913 6.334s-8.206-2.53-9.913-6.334C4.794 8.53 8.653 6 13 6s8.206 2.53 9.913 6.333zM13 17.223c-2.76 0-5-2.191-5-4.89 0-2.698 2.24-4.889 5-4.889s5 2.19 5 4.89c0 2.698-2.24 4.888-5 4.888zm-2-4.89c0-1.106.893-2 2-2s2 .894 2 2c0 1.107-.893 2-2 2s-2-.893-2-2z" _fill="#686a7a"/><mask id="svgicon_action-show_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="4" width="26" height="17"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.927 11.96C23.008 7.192 18.307 4 13 4 7.694 4 2.993 7.193 1.072 11.96l-.15.373.15.374c1.921 4.766 6.622 7.96 11.928 7.96 5.306 0 10.007-3.194 11.927-7.96l.151-.374-.15-.373zm-2.014.373c-1.707 3.805-5.566 6.334-9.913 6.334s-8.206-2.53-9.913-6.334C4.794 8.53 8.653 6 13 6s8.206 2.53 9.913 6.333zM13 17.223c-2.76 0-5-2.191-5-4.89 0-2.698 2.24-4.889 5-4.889s5 2.19 5 4.89c0 2.698-2.24 4.888-5 4.888zm-2-4.89c0-1.106.893-2 2-2s2 .894 2 2c0 1.107-.893 2-2 2s-2-.893-2-2z" _fill="#fff"/></mask><g mask="url(#svgicon_action-show_a)"><path pid="2" _fill="#686a7a" d="M1 0h24v24H1z"/></g>'
  }
})
