import CommentMediaWidget from '@/components/VisionAi/SummaryMediaWidget/CommentMediaWidget.vue';
import DashboardReportContent from '@/app/dashboards/components/DashboardReportContent.vue';
import { COMMUNITY_INTERACTION_TYPES } from '@/app/community/constants';
import { ERROR_TYPES, INTENT_TYPES } from '@/components/VisionAi/constants';
import DmMediaWidget from '@/components/VisionAi/SummaryMediaWidget/DmMediaWidget.vue';
import PublicMediaCard from '@/components/PublicMediaCard.vue';
import { getDefaultMediaCardMetrics } from '@/app/socialListening/utils/metric-utils';
import { VISION_AI_SENDER } from '@/config';
import { useDashboardsVisionAi } from '@/app/dashboards/composables/useDashboardsVisionAi';
import { REPORTS } from '@/app/dashboards/utils/reports.enum';
import VisionAIDashboardReportContent from '@/components/VisionAi/SummaryMediaWidget/VisionAIDashboardReportContent.vue';
import isEmpty from 'lodash/isEmpty';

export function stripHtmlFromMessage(htmlMessage) {
  const div = document.createElement('div');
  div.innerHTML = htmlMessage;
  const text = div.textContent || div.innerText || '';
  return text;
}

export const MESSAGE_WIDGET_MAP = {
  [INTENT_TYPES.COMMENTS]: CommentMediaWidget,
  [INTENT_TYPES.DMS]: DmMediaWidget,
  [INTENT_TYPES.TRENDS]: PublicMediaCard,
  [INTENT_TYPES.TOPICS]: PublicMediaCard,
  [INTENT_TYPES.DASHBOARD_REPORTS]: DashboardReportContent,
  [INTENT_TYPES.METRIC_ANALYTICS]: VisionAIDashboardReportContent,
};

function getCommentsProps(additionalContent) {
  const { positive = 0, negative = 0, neutral = 0 } = additionalContent.mediaCommentSentiment || {};
  return {
    postThumbnailUrl: additionalContent.media?.postThumbnailUrl,
    displayText: additionalContent.media?.caption,
    userHandle: additionalContent.media?.userHandle,
    brandAvatarUrl: additionalContent.brandAvatarUrl,
    timestamp: additionalContent.media?.sourceCreatedAt,
    mediaCommentSentimentDetail: {
      positivePercentage: positive,
      negativePercentage: negative,
      neutralPercentage: neutral,
    },
    likeCount: additionalContent.media?.likes,
    commentCount: additionalContent.commentCount,
    interactionType: COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT,
  };
}

function getDashboardReportsProps(
  additionalContent,
  intentQuery,
  additionalProps,
  isSummary = true,
) {
  let reportOverride = { ...additionalContent, ...additionalProps };
  reportOverride.visionAiReport = true;
  reportOverride.isSummaryReport = isSummary;

  if (reportOverride?.type === REPORTS.DASHBOARD_SUMMARY.value) {
    const { formatDateRangeFromIntentQuery } = useDashboardsVisionAi();
    const dashboardSummaryDateRange = formatDateRangeFromIntentQuery(intentQuery);
    reportOverride = { ...reportOverride, dashboardSummaryDateRange };
  }
  const shouldHideConversation = reportOverride.visionAiReport && !reportOverride.isSummaryReport;
  return {
    reportOverride,
    type: additionalContent?.type,
    shouldHideConversation,
  };
}

export function getMetricAnalyticsProps(additionalContent, intentQuery, additionalProps) {
  const reports = [];
  const additionalContentValues = Object.values(additionalContent ?? {});
  additionalContentValues.forEach((reportData) => {
    if (!isEmpty(reportData)) {
      reports.push(getDashboardReportsProps(reportData, intentQuery, additionalProps, false));
    }
  });
  return {
    reports,
  };
}

function getPublicMediaProps(additionalContent) {
  return {
    media: additionalContent.media,
    metrics: getDefaultMediaCardMetrics(additionalContent.media),
    hoverEffect: false,
    isDownloadable: true,
  };
}

function getDmProps(additionalContent) {
  return {
    userHandle: additionalContent.followerParticipant?.userHandle,
    profilePictureUrl: additionalContent.followerParticipant?.profilePictureUrl,
  };
}

export const INTENT_PROPS_FACTORY = {
  [INTENT_TYPES.COMMENTS]: getCommentsProps,
  [INTENT_TYPES.DMS]: getDmProps,
  [INTENT_TYPES.TRENDS]: getPublicMediaProps,
  [INTENT_TYPES.TOPICS]: getPublicMediaProps,
  [INTENT_TYPES.DASHBOARD_REPORTS]: getDashboardReportsProps,
  [INTENT_TYPES.METRIC_ANALYTICS]: getMetricAnalyticsProps,
  default: null,
};

export function getPropsForIntentType(
  intentType,
  additionalContent,
  intentQuery,
  additionalProps = null,
) {
  const generator = INTENT_PROPS_FACTORY[intentType] || INTENT_PROPS_FACTORY.default;
  if (generator) {
    return generator(additionalContent, intentQuery, additionalProps);
  }
  return {};
}

const INTENT_ERROR_MESSAGE_MAP = {
  [INTENT_TYPES.DASHBOARD_REPORTS]: {
    [ERROR_TYPES.UNAUTHORIZED_ERROR.statusCode]:
      'You no longer have access to the dashboard that was used to generate this response. It may have been deleted or your permission to access it changed.',
  },
};

export function getMessageTextForIntentType(
  message,
  sender,
  intentType,
  errorCode,
  isWarningMessage,
) {
  /**
   * custom error messages are displayed if an unexpected or validation error occurred while
   * building the AI assistant message.
   */
  if (sender === VISION_AI_SENDER.USER || !errorCode) {
    return message;
  }

  if (isWarningMessage) {
    return message;
  }

  if (INTENT_ERROR_MESSAGE_MAP[intentType]?.[errorCode]) {
    return INTENT_ERROR_MESSAGE_MAP[intentType]?.[errorCode];
  }

  return ERROR_TYPES.RUNTIME_ERROR.errorMessage;
}
