<template>
  <div
    v-tooltip="{ content: buttonTooltip, boundary: 'viewport' }"
    :class="{ relativeMenu: !menuRelativeToParentElement }"
    class="dropdown-button"
    :data-cy="dataCy"
  >
    <!-- TODO:  integrate different spinner style -->
    <VDropdown
      :placement="popoverPlacement"
      :container="popoverContainer"
      :shown="dropdownVisible"
      theme="dh-dropdown-full"
      boundary="viewport"
      :triggers="[]"
      @auto-hide="handleAutoHide"
    >
      <Button
        ref="picker"
        :class="[buttonClasses, { loading: loading, 'no-border': noBorder }]"
        :icon-name="buttonIcon && !loading ? buttonIcon : null"
        :icon-color="buttonIconColor ? buttonIconColor : null"
        :icon-hover-color="buttonIconHoverColor ? buttonIconHoverColor : null"
        :icon-size="buttonIconSize"
        :is-width-of-icon="buttonIsWidthOfIcon"
        :is-height-of-icon="buttonIsHeightOfIcon"
        :disabled="disabled"
        :small="smallButton"
        :round-small="roundSmall"
        :rectangular="rectangular"
        @click.stop="openDropdownList"
      >
        <slot name="buttonContent" :open="dropdownVisible" />
      </Button>

      <template #popper>
        <div
          :class="['dropdown-list', ...dropdownListClasses]"
          :style="allDropdownListStyles"
          data-cy="popover-dropdown-button"
        >
          <dropdown-button-item
            v-for="(item, index) in mappedDropdownList"
            :key="index"
            :scrollable="scrollableList"
            :item="item"
            :data-cy="`${dataCy}-Item`"
            @click="handleClick"
            @action="handleAction"
          />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isFunction from 'lodash/isFunction';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import DropdownButtonItem from '@/components/foundation/DropdownButtonItem.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'DropdownButton',
  components: {
    DropdownButtonItem,
    Button,
  },
  props: {
    /**
     * List of items for the dropdown.  Properties available for each item:
     *
     * text - the text to display in the menu item option (string)
     * text: 'Download CSV'
     *
     * action - PREFERRED, pass in a function to be called when item is clicked
     * action: functionToCallWhenClicked
     *
     * action - LEGACY, pass in a string and dropdownActionClicked event will be emitted when item is clicked
     * action: 'someString'
     *
     * actionParams - pass object with these parameters to the specified function or emit with event
     * actionParams: { a: 1, b: 2 }
     *
     * textColor - string, pass in string hex code for the colour of this action's text
     * textColor: '#E36042'
     *
     * route - navigate to the specified route (cannot be used with "action")
     * route: 'routeToNavigateTo'
     *
     * disabled - pass boolean that determines if the item be disabled (un-clickable and greyed out)
     * disabled: true
     *
     * header - pass true if item should be formatted like a header, this makes item unclickable
     * header: true
     *
     * divider - pass true if the item should apply a top border
     *
     * tooltip - STRING, tooltip to display on hover over dropdown item
     * tooltip: 'This is a tooltip'
     *
     * tooltip - OBJECT, content field will be text displayed, content/classes fields are used to
     *           format tooltip
     * tooltip: {
                  content: 'Test for content + classes + placement as an object.',
                  classes: 'a',
                  placement: 'top',
                 }
     *
     * subList - Array, contains a sub-menu of options with the same parameters as above
     * subList: [
                  {
                    title: 'A sub-menu item',
                  },
                  ...
                ]
     *
     * subListPosition - String, indicates the position of the sub list.
     * subListPosition: `left` or `right`
     *
     * <code>
     * <pre>
     * {
     *  text: required,
     *  action: optional,
     *  actionParams: optional,
     *  textColor: optional,
     *  route: optional,
     *  disabled: optional
     *  header: optional,
     *  divider: optional,
     *  tooltip: optional,
     *  sublist: optional,
     *  subListPosition: optional,
     * }
     * </pre>
     * </code>
     */
    dropdownList: { type: Array, default: null },
    // Unsure on what these two do
    value: { type: Boolean, default: false },
    /**
     * Tooltip that will be shown when hovering over button
     */
    buttonTooltip: { type: String, default: null },
    /**
     * Disables button, makes it un-clickable and greyed out
     */
    disabled: { type: Boolean, default: false },
    /**
     * Aligns right edge of popover dropdown with right edge of button
     */
    alignRight: { type: Boolean, default: false },
    /**
     * Aligns right edge of popover dropdown with center of button
     */
    alignCenter: { type: Boolean, default: false },
    /**
     * Adds spinner to button to show loading process, makes button un-clickable
     */
    loading: { type: Boolean, default: false },
    /**
     * When false, dropdown button IS relative to parent element
     */
    menuRelativeToParentElement: { type: Boolean, default: false },
    /**
     * Configure data-cy attribute for testing tag.
     */
    dataCy: { type: String, default: 'DropdownButton' },
    /**
     * CSS classes that will passed to the Button component
     */
    buttonClasses: { type: [String, Array], default: null },
    // Further button styling
    buttonIcon: { type: String, default: null },
    buttonIconColor: { type: String, default: colours.ICON.ICON_SECONDARY },
    buttonIconHoverColor: { type: String, default: colours.ICON.ICON_SECONDARY },
    buttonIconSize: { type: String, default: null },
    buttonIsWidthOfIcon: { type: Boolean, default: false },
    buttonIsHeightOfIcon: { type: Boolean, default: false },
    scrollableList: { type: Boolean, default: false },
    dropdownListStyles: { type: Object, default: () => {} },
    dropdownListClasses: { type: Array, default: () => [] },
    smallButton: { type: Boolean, default: false },
    /**
     * Small size round dropdown button
     */
    roundSmall: { type: Boolean, default: false },
    rectangular: { type: Boolean, default: false },
    popoverContainer: { type: String, default: 'body' },
    hideOnScroll: { type: Boolean, default: false },
    onHide: { type: Function, default: () => {} },
    /**
     * Remove border styling from button
     */
    noBorder: { type: Boolean, default: false },
  },
  emits: ['input', 'dropdownActionClicked', 'dropdownMenuClicked'],
  data() {
    return {
      dropdownVisible: false,
      scrollableParent: null,
      pickerWidth: null,
    };
  },
  computed: {
    mappedDropdownList() {
      if (this.dropdownList) {
        return this.dropdownList.map((item) => {
          const { tooltip: givenTooltip } = item;

          if (!givenTooltip) {
            return item;
          }

          const tooltip =
            typeof givenTooltip === 'string' ? { content: givenTooltip } : { ...givenTooltip };

          if (!tooltip.content) {
            return item;
          }
          tooltip.classes = this.getTooltipClasses(givenTooltip);

          return {
            ...item,
            tooltip,
          };
        });
      }
      return this.dropdownList;
    },
    popoverPlacement() {
      if (this.alignRight) {
        return 'bottom-end';
      }
      if (this.alignCenter) {
        return 'bottom';
      }
      return 'bottom-start';
    },
    dropdownDisplay() {
      return this.hideOnScroll && !this.dropdownVisible ? 'none' : 'block';
    },
    allDropdownListStyles() {
      return {
        position: 'static',
        display: this.dropdownDisplay,
        minWidth: this.pickerWidth ? `${this.pickerWidth}px` : undefined,
        ...this.dropdownListStyles,
      };
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.dropdownVisible = newValue;
      },
      immediate: true,
    },
    dropdownVisible(newValue) {
      this.$emit('input', newValue);
    },
  },
  mounted() {
    if (this.hideOnScroll) {
      this.scrollableParent = this.getScrollableParent();
      this.scrollableParent.addEventListener('scroll', this.closeDropdownList);
      this.scrollableParent.addEventListener('resize', this.closeDropdownList);
    }
  },
  unmounted() {
    if (this.hideOnScroll) {
      this.scrollableParent.removeEventListener('scroll', this.closeDropdownList);
      this.scrollableParent.removeEventListener('resize', this.closeDropdownList);
    }
  },
  methods: {
    getTooltipClasses(givenTooltip) {
      const defaultClass = '';

      if (typeof givenTooltip === 'string') {
        return defaultClass;
      }

      const { classes } = givenTooltip;
      if (typeof classes === 'string') {
        return classes.concat(' ', defaultClass);
      }
      if (Array.isArray(classes)) {
        return classes.join(' ').concat(' ', defaultClass);
      }

      return defaultClass;
    },
    openDropdownList() {
      if (!this.loading && !this.disabled) {
        if (this.$refs.picker) {
          this.pickerWidth = this.$refs.picker.$el.offsetWidth;
        }
        this.dropdownVisible = true;
        this.$emit('dropdownMenuClicked');
      }
    },
    closeDropdownList() {
      this.dropdownVisible = false;
      this.onHide();
    },
    handleClick() {
      this.closeDropdownList();
    },
    handleAction(action, params) {
      setTimeout(() => {
        if (isFunction(action)) {
          action(params);
        } else {
          this.$emit('dropdownActionClicked', action, params);
        }
      }, 100);
    },
    getScrollableParent() {
      const style = getComputedStyle(this.$el);
      const excludeStaticParent = style.position === 'absolute';
      const overflowRegex = /(auto|scroll)/;

      let parent = this.$el.parentElement;
      while (parent) {
        const parentStyle = getComputedStyle(parent);
        if (
          !(excludeStaticParent && parentStyle.position === 'static') &&
          overflowRegex.test(parentStyle.overflow + parentStyle.overflowY + parentStyle.overflowX)
        ) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return window;
    },
    handleAutoHide() {
      this.dropdownVisible = false;
      this.onHide();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-popover {
  padding: 0;
}

.dropdown-button {
  display: inline-block;

  .relativeMenu {
    position: relative;
  }
}

.dropdown-list {
  border-radius: var(--round-corner);
  left: 0;
  z-index: var(--z-index-dropdown);
  position: absolute;
  background: var(--background-0);
  width: max-content;
  padding: var(--space-8) 0;

  a {
    font-weight: var(--font-normal);
  }

  .list-text-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text-primary);
    font-weight: var(--font-medium);
    font-size: var(--x14);

    .svg-icon {
      margin-right: var(--space-8);
    }

    .svg-icon:last-child {
      margin: 0 0 0 var(--space-16);
    }

    .flex-spacer {
      flex: 1;
    }
  }

  .disabled .list-text-wrapper {
    cursor: auto;
  }
}

.loading {
  color: transparent !important;
  position: relative;

  & > svg {
    display: none;
  }
}

.loading::after {
  content: '';
  display: block;
  border: 2px solid var(--border);
  border-left-color: rgb(255 255 255 / 20%);
  border-right-color: rgb(255 255 255 / 20%);
  border-bottom-color: rgb(255 255 255 / 20%);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  margin-left: -0.75em;
  margin-top: -0.75em;
  animation: rotate 1s linear infinite;
}

.dropdown-button button.semi-circle {
  position: relative;
  height: 2.5rem;
  border: none;
  border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
  padding: 0 var(--space-16) 0 var(--space-12);
  background: var(--action-500);

  :deep(svg) {
    opacity: 0.7 !important;
  }

  &:hover {
    transform: none;
    box-shadow: none;

    :deep(svg) {
      opacity: 1 !important;
    }
  }

  &::after {
    content: '';
    height: 1.62rem;
    width: 0.062rem;
    position: absolute;
    left: 0;
    top: 0.44rem;
    background-color: var(--background-0);
    opacity: 0.7;
  }
}

.dropdown-button button.semi-circle-white {
  position: relative;
  border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
  border-left: none;
  padding: 0 var(--space-12) 0 var(--space-8);
  background: var(--background-0);
  height: var(--space-32);
  font-size: var(--x16);
  line-height: 0;

  &:hover {
    transform: none;
    box-shadow: none;

    :deep(svg) {
      opacity: 1 !important;
    }
  }

  &::after {
    content: '';
    height: 1.2rem;
    width: 0.062rem;
    position: absolute;
    left: 0;
    background-color: var(--border);
    opacity: 0.7;
  }
}

.dropdown-button button.timeline {
  border-radius: 0;
  border-color: white;
  padding: 0;
  background: transparent;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.dropdown-button button.corner {
  border-radius: 0;
  border-color: transparent;
  padding: 0;
  background: transparent;
  height: 100%;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.dropdown-button button.no-border {
  border-radius: 0;
  border-color: transparent;
  background: transparent;
  height: 100%;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.dropdown-button button.pad-top {
  padding-top: 10px;
}

.dropdown-button button.primary {
  background: var(--action-500);
  color: var(--white);
  border: none;

  &:hover {
    color: var(--white);
    box-shadow:
      0 1px 2px 0 rgb(255 255 255 / 10%),
      0 5px 15px 0 rgb(73 144 226 / 50%);
  }
}

.visual-bridge {
  :deep(.list-text-wrapper) {
    color: var(--black);
    font-size: var(--x12);
  }
}
</style>

<style lang="postcss">
.rectangular.button {
  &:hover {
    transform: none;
    box-shadow: none;
  }
}

.rectangular.button.loading {
  transition: none;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  cursor: not-allowed;
}

.dropdown-tooltip {
  display: inline-block;
}
</style>
