import { constants } from '@/config';

const App = () => import('@/app/twitter/App.vue');
const Gallery = () => import('@/components/Galleries/Gallery.vue');
const Overview = () => import('@/app/twitter/pages/Overview.vue');
const TwitterGalleries = () => import('@/app/twitter/pages/Galleries.vue');
const TwitterGalleryEdit = () => import('@/app/twitter/pages/TwitterGalleryEdit.vue');
const YourTweets = () => import('@/app/twitter/pages/YourTweets.vue');

const PAGE_LEVEL_TOP = `Twitter`;

export default {
  path: 'x',
  component: App,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      name: 'twitter',
      path: '',
      redirect: { name: 'twitter.overview' },
    },
    {
      name: 'twitter.dashboard',
      path: 'dashboard',
      component: Overview,
      redirect: { name: 'twitter.overview' },
    },
    {
      name: 'twitter.dashboard.report',
      path: 'dashboard/report',
      component: Overview,
      meta: {
        report: true,
      },
      redirect: { name: 'twitter.overview.report' },
    },
    {
      name: 'twitter.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'twitter.overview.report',
      path: 'overview/report',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview Report`,
        report: true,
      },
    },
    {
      path: 'your-tweets',
      redirect: {
        name: 'twitter.yourTweets',
      },
    },
    {
      name: 'twitter.yourTweets',
      path: 'your-posts',
      component: YourTweets,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Posts`,
      },
    },
    {
      path: 'boards',
      children: [
        {
          name: 'twitter.galleries',
          path: '',
          component: TwitterGalleries,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Boards`,
          },
          children: [
            {
              name: 'twitter.galleries.new',
              path: 'new',
              component: TwitterGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - New`,
              },
            },
          ],
        },
        {
          name: 'twitter.gallery.detail',
          path: ':id',
          component: Gallery,
          props: {
            galleryType: constants.TWITTER,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Board Detail`,
          },
          children: [
            {
              name: 'twitter.galleries.edit',
              path: 'edit',
              component: TwitterGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - Edit`,
              },
            },
          ],
        },
      ],
    },
  ],
};
