/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bookmark-simple': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M13 0a1 1 0 011 1v18a1 1 0 01-1.707.707L7 14.414l-5.293 5.293A1 1 0 010 19V1a1 1 0 011-1zM8 12.586l4 4V2H2v14.586l4-4v-.005l1-.988 1 .988v.005z" id="svgicon_bookmark-simple_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_bookmark-simple_a" transform="translate(5 2)"/>'
  }
})
