/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alertBellFillActive': {
    width: 40,
    height: 45,
    viewBox: '0 2 40 45',
    data: '<mask id="svgicon_alertBellFillActive_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="5" width="40" height="40"><path pid="0" d="M.5 25C.5 14.23 9.23 5.5 20 5.5S39.5 14.23 39.5 25 30.77 44.5 20 44.5.5 35.77.5 25z" _fill="#fff" _stroke="#fff"/></mask><g clip-path="url(#clip0_1630_116162)"><path pid="1" d="M20 35c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V17c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-4.5 3.24-4.5 6.32v5l-2 2v1h16v-1l-2-2z" _fill="#666"/></g><circle pid="2" cx="20" cy="4.75" r="6" _fill="#27C449"/><defs><clipPath id="clip0_1630_116162"><path pid="3" _fill="#fff" transform="translate(8 13)" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
