/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'coins': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 22c-3.309 0-6-2.691-6-6a6.009 6.009 0 014.266-5.742 9.885 9.885 0 01-.252-1.997C2.562 9.147 0 12.276 0 16c0 4.411 3.589 8 8 8 3.725 0 6.854-2.562 7.74-6.013a10.095 10.095 0 01-1.998-.252A6.008 6.008 0 018 22zM17 3h-2v1.05c-1.14.232-2 1.242-2 2.45C13 7.878 14.122 9 15.5 9h1a.5.5 0 010 1H13v2h2v1h2v-1.05c1.14-.232 2-1.242 2-2.45C19 8.122 17.879 7 16.5 7h-1a.5.5 0 010-1H19V4h-2V3z"/><path pid="1" d="M16 0c-4.411 0-8 3.59-8 8 0 4.411 3.589 8 8 8s8-3.589 8-8c0-4.41-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6 0-3.308 2.691-6 6-6s6 2.692 6 6c0 3.309-2.691 6-6 6zM7.5 14h.514a9.997 9.997 0 01-1.135-1.914A2.5 2.5 0 005 14.5C5 15.878 6.122 17 7.5 17h1a.5.5 0 010 1H5v2h2v1h2v-1.05a2.504 2.504 0 001.805-3.417A10.1 10.1 0 018.9 15.041 2.378 2.378 0 008.5 15h-1a.5.5 0 010-1z"/>'
  }
})
