/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'expandHorizontal': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 19.585l-5.293-5.293-1.414 1.414L12 22.413l6.707-6.707-1.414-1.414zM5.293 8.292l1.414 1.414L12 4.413l5.293 5.293 1.414-1.414L12 1.585z"/>'
  }
})
