export const TIKTOK_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 27,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_TIME_WATCHED',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_RETENTION_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENTERTAINMENT_SCORE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 7,
    meta: { name: 'Posts Published During Reporting Period' },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: { name: 'All Published Posts' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENGAGEMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ORGANIC_LIKES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ORGANIC_COMMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'SHARES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_LIKES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_COMMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_SHARES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 24,
    meta: { name: 'Follower Activity' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'PROFILE_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENTERTAINMENT_SCORE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
];
