<template>
  <PlatformOutage v-if="notificationStore.platformIsDown" />
  <LoginAnimation
    v-else-if="authStore.isLoggedIn && showAnimation"
    @animationend="showAnimation = false"
  />
  <CircularLoader v-else-if="isFetchingIdentity && !showAnimation" />
  <PageNotFound v-else-if="!brandLabelInUrlIsValid" />
  <div
    v-else
    class="app-view"
    :class="{
      'nav-view': showTopNav && !hasVisualBridgeFlag,
      'report-view': routeMetaReport,
      'visual-bridge-view min-h-screen': hasVisualBridgeFlag,
      'pl-[var(--primary-navigation-width)]':
        hasVisualBridgeFlag && !routeMetaHidePrimaryNav && !routeMetaReport,
    }"
  >
    <TopNav v-if="showTopNav && !hasVisualBridgeFlag" />

    <VisionChatPopup v-if="showVisionAi" />

    <template v-if="useDashingLayout">
      <DashingLayout :show-nav="showTopNav">
        <router-view />
      </DashingLayout>
    </template>
    <template v-else>
      <router-view />
    </template>

    <component
      v-bind="modal.props"
      :is="modal.component"
      v-for="(modal, index) in globalModals"
      :key="index"
    />
    <MediaLinks v-if="showLinkPopupAlone" :link-limit="mediaLinksStore.linkLimit" />
    <MultiSelectTopBar
      v-if="mediaSelectStore.multiSelectBarEnabled"
      :show-add-to-gallery-control="showAddToGalleryOption"
    />
    <ShoppableGallerySettings
      v-if="galleryStore.showShoppableSettingsPopup && !mediaSelectStore.isTargetingBrands"
    />
    <NotificationPopups />
    <PlatformPopups />
    <CustomerJourneyPopups />
    <DrawerProvider />
    <ConfirmationDialog />
    <Toast position="top-right" />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import isEqual from 'lodash/isEqual';
import { mapStores } from 'pinia';
import {
  browserStorageSetItem,
  browserStorageGetItem,
  browserStorageNamespacedKey,
} from '@/utils/browserStorage';
import { sessionInterval, trackSessionKey } from '@/config';
import LoginAnimation from '@/app/auth/components/LoginAnimation.vue';
import { guessTimezone } from '@/utils/timezone';
import { toggleVisualBridgeBodyClass } from '@/utils';

import PageNotFound from '@/components/PageNotFound.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import MultiSelectTopBar from '@/components/MultiSelectTopBar.vue';
import TopNav from '@/components/layout/TopNav.vue';
import NotificationPopups from '@/components/NotificationPopups.vue';
import MediaLinks from '@/app/library/components/MediaLinks.vue';
import PlatformOutage from '@/components/PlatformOutage.vue';
import ShoppableGallerySettings from '@/app/library/pages/ShoppableGallerySettings.vue';
import CustomerJourneyPopups from '@/components/InAppTrials/CustomerJourneyPopups.vue';
import '@/styles/main.css';
import { useMetricsStore } from '@/stores/metrics';

// Use trailing underscores to avoid collisions with globals injected by these tools
import globalModalsMixin from '@/mixins/globalModalsMixin';
import { useAuthStore, TOKEN_KEY } from '@/stores/auth';
import { useSearchStore } from '@/stores/search';
import { useDashboardsStore } from '@/stores/dashboards';
import { useNotificationStore } from '@/stores/notification';
import { usePlatformStore } from '@/stores/platform';
import { useSettingsStore } from '@/stores/settings';
import PlatformPopups from '@/components/common/connections/PlatformPopups.vue';
import { useMediaLinksStore } from '@/stores/media-links';
import { useMediaSelectStore } from '@/stores/media-select';
import { useLayoutStore } from '@/stores/layout';
import { useTwitterStore } from '@/stores/twitter';
import { useIntercomStore } from '@/stores/intercom';
import { useHubspotStore } from '@/stores/hubspot';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { useSharedStore } from '@/stores/shared';
import { useFullstoryStore } from '@/stores/fullstory';
import { useDatadogStore } from '@/stores/datadog';
import { setCssPageMedia } from '@/utils/dom';
import { logger } from '@/utils/logger';
import { useGalleryStore } from '@/stores/gallery';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import { useSchedulerStore } from '@/stores/scheduler';
import { useTrackingStore } from '@/stores/tracking';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useSurvicateStore } from '@/stores/survicate';
import { useVisionAiStore } from '@/stores/vision-ai';
import { useIdentityStore } from '@/stores/identity';
import VisionChatPopup from '@/components/VisionAi/VisionChatPopup.vue';
import { useVisionAiPermissions } from '@/components/VisionAi/composables/useVisionAiPermissions';
import DrawerProvider from '@/components/dashingUI/DrawerProvider.vue';
import { ConfirmationDialog, Toast } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import DashingLayout from '@/components/dashingUI/DashingLayout.vue';
import { CHANNELS, filterNonCompetitiveChannels } from '@/models/dashboards/channels.enum';

const comp = defineComponent({
  name: 'App',
  components: {
    VisionChatPopup,
    PageNotFound,
    CircularLoader,
    LoginAnimation,
    MediaLinks,
    MultiSelectTopBar,
    NotificationPopups,
    PlatformOutage,
    PlatformPopups,
    ShoppableGallerySettings,
    TopNav,
    CustomerJourneyPopups,
    DrawerProvider,
    ConfirmationDialog,
    Toast,
    DashingLayout,
  },
  mixins: [globalModalsMixin],
  setup() {
    const viewportScale = window?.visualViewport?.scale ?? 1;
    const route = useRoute();
    const metaContent = computed(() =>
      route?.meta?.setResponsiveViewportMetaTag
        ? 'width=device-width, initial-scale=1'
        : `width=device-width, initial-scale=${viewportScale}`,
    );
    useHead({
      meta: () => {
        return [
          {
            name: 'viewport',
            content: metaContent.value,
          },
        ];
      },
    });

    return {
      metaContent,
    };
  },
  data() {
    return {
      currentCursorPos: [0, 0],
      savedCursorPos: [0, 0],
      showAnimation: false,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useNotificationStore,
      usePlatformStore,
      useMediaSelectStore,
      useMediaLinksStore,
      useMetricsStore,
      useSettingsStore,
      useLayoutStore,
      useTwitterStore,
      useIntercomStore,
      useHubspotStore,
      useChiliPiperStore,
      useSharedStore,
      useFullstoryStore,
      useDatadogStore,
      useInstagramAccountStore,
      useGalleryStore,
      useSchedulerStore,
      useTrackingStore,
      useImpersonatorStore,
      useSurvicateStore,
      useVisionAiStore,
      useIdentityStore,
      useFlagStore,
      useSearchStore,
      useDashboardsStore,
    ),
    brandLabelInUrlIsValid() {
      const brandLabel = this.$route?.params?.brandLabel;
      return (
        !this.authStore.identity ||
        !brandLabel ||
        Object.keys(this.authStore.identity.brands).includes(brandLabel) ||
        Object.keys(this.authStore.identity?.inactive_brands || {}).includes(brandLabel)
      );
    },
    showLinkPopupAlone() {
      return (
        !this.schedulerStore.popupType &&
        this.mediaLinksStore.showLinkPopup &&
        !this.globalModals.length
      );
    },
    isFetchingIdentity() {
      return !this.authStore.hasAttemptedIdentification || this.authStore.pending.identity;
    },
    hasOpenPopup() {
      return (
        this.globalModals.length > 0 ||
        this.mediaLinksStore.showLinkPopup ||
        this.schedulerStore.popupType ||
        this.showLinkPopupAlone ||
        this.galleryStore.showShoppableSettingsPopup
      );
    },
    showAddToGalleryOption() {
      return (
        this.routeName !== 'instagram.ugc.hashtag' &&
        !this.routeName?.startsWith('scheduler') &&
        !this.mediaSelectStore.isTargetingBrands
      );
    },
    routeMetaReport() {
      return this.$route?.meta?.report;
    },
    routeMetaHideNav() {
      return this.$route?.meta?.hideNav;
    },
    routeMetaHideVisionAi() {
      return this.$route?.meta?.hideVisionAi;
    },
    routeMetaHidePrimaryNav() {
      return this.$route?.meta?.hidePrimaryNav;
    },
    showTopNav() {
      return !this.routeMetaReport && !this.routeMetaHideNav && !!this.authStore.identity?.id;
    },
    showVisionAi() {
      if (this.visionAiStore.forceHideVisionAi) return false;
      if (!this.authStore.identity?.id) return false;

      const { userCanAccessVisionAiForSomeBrandOrOrg } = useVisionAiPermissions();
      return (
        !this.routeMetaReport &&
        !this.routeMetaHideVisionAi &&
        userCanAccessVisionAiForSomeBrandOrOrg.value
      );
    },
    routeMetaLayout() {
      return this.$route?.meta?.layout;
    },
    routeName() {
      return this.$route?.name;
    },
    hasVisualBridgeFlag() {
      return this.flagStore.ready && this.flagStore.flags?.visualBridge;
    },
    hasSaveJackieFlag() {
      return this.flagStore.ready && this.flagStore.flags?.saveJackie;
    },
    hasVisualBridgeQueryParam() {
      return this.$route?.query?.visualBridge;
    },
    useDashingLayout() {
      return this.hasSaveJackieFlag || this.hasVisualBridgeFlag;
    },
    canAccessVisualBridgeFontChange() {
      return this.useDashingLayout || this.hasVisualBridgeQueryParam;
    },
  },
  watch: {
    canAccessVisualBridgeFontChange: {
      handler(from, to) {
        if (!isEqual(from, to)) {
          toggleVisualBridgeBodyClass();
        }
      },
      immediate: true,
    },
    'authStore.currentBrand': {
      handler() {
        this.sharedStore.resetBrandState();
        this.handleBrandChange();
      },
    },
    'authStore.identity': {
      handler(to) {
        this.handleBrandChange();
        this.loadTwitterAccounts();
        if (to && !this.impersonatorStore.isImpersonating) {
          this.setUserTimezone();
        }
      },
    },
    $route: {
      handler(to) {
        if (to && to.meta && to.meta.report) {
          // move it from beforeMount to here as beforeMount and even a little while after mounted,
          // $route is empty.
          this.loadReportStyle();
        }
      },
      immediate: true,
    },
    isFetchingIdentity(newVal) {
      if (newVal) {
        this.showAnimation = true;
      }
    },
  },
  created() {
    // Track start of session in Mixpanel
    window.addEventListener('mousemove', (e) => {
      this.currentCursorPos = [e.pageX, e.pageY];
    });
    this.trackSession();
    setInterval(this.trackSession, sessionInterval);

    if (!this.routeMetaReport) {
      this.fullstoryStore.init();
      this.datadogStore.init();
    }

    // Prevent users from continuing if they haven't completed their account setup
    if (this.accountSetupNotComplete()) {
      this.$router.replace({ name: 'account.setup' });
    } else if (this.$route.name !== 'account.setup') {
      const unwatchIdentity = this.$watch(
        () => this.authStore.identity,
        () => {
          if (this.accountSetupNotComplete()) {
            this.$router.replace({ name: 'account.setup' });
          }
          unwatchIdentity();
        },
      );
    }

    // Listen for when the token is removed from local storage (logout on a different tab)
    window.addEventListener('storage', async (e) => {
      if (browserStorageNamespacedKey && e.key === browserStorageNamespacedKey('token')) {
        const currentToken = browserStorageGetItem(TOKEN_KEY, true);
        if (!currentToken) {
          await this.authStore.logout();
          this.$router.push({ name: 'auth.login' });
        }
      }
    });
  },
  mounted() {
    this.handleBrandChange();
    this.loadTwitterAccounts();
    this.setUserTimezone();
    this.setupAppEventListeners();
    this.platformStore.init();
    this.fetchMetricDetails();
    this.getCompetitorList();
  },
  methods: {
    async setUserTimezone() {
      const guessedTimezone = guessTimezone();
      const identityLoaded = !!this.authStore.identity?.id;
      const noIdentityTimezone = !this.authStore.identity?.time_zone_name;
      if (
        !this.impersonatorStore.isImpersonating &&
        identityLoaded &&
        noIdentityTimezone &&
        guessedTimezone
      ) {
        const data = { time_zone_name: guessedTimezone };
        try {
          await this.settingsStore.updateUser({
            userId: this.authStore.identity.id,
            data,
          });
        } catch (error) {
          let errorMessage = 'Unknown Error';
          if (error?.response?.data?.description) {
            errorMessage = error.response.data.description;
          }
          logger.warn(`Unable to update user timezone ${errorMessage}`);
          return;
        }
        await this.authStore.updateIdentity({ data });
        if (this.authStore.identity.id) {
          const id = await this.notificationStore.setToast({
            message: `Your timezone was set to ${guessedTimezone} based on your current location.
              If this is incorrect, please update your timezone in Profile Settings.`,
            type: 'primary',
            buttonText: 'Go to Profile Settings',
            buttonAction: async () => {
              await this.$router.push({
                name: 'settings.profile',
                params: { brandLabel: this.authStore?.currentBrandLabel },
              });
              this.notificationStore.clearToasts({ id });
            },
            autoClear: false,
          });
        }
      }
    },
    fetchMetricDetails() {
      if (this.authStore.isLoggedIn) {
        this.metricsStore.fetchMetricDetails();
      }
    },
    getCompetitorList() {
      // load competitors into store. Used for dashboards UI and global Vision AI chat
      const competitiveChannelTypes = Object.values(CHANNELS)
        .filter(filterNonCompetitiveChannels())
        .map((channel) => channel.competitiveChannelType);

      return Promise.all(
        competitiveChannelTypes.map((channelType) =>
          this.searchStore.getCompetitors(
            channelType,
            this.dashboardsStore.dashboardIdentityBrandsByIds,
          ),
        ),
      );
    },
    handleBrandChange() {
      if (
        this.authStore.identity &&
        this.authStore.currentBrand &&
        this.authStore.currentBrand.label
      ) {
        this.authStore.clearBrandAccessibleUsers();
        if (this.authStore.isLoggedIn) {
          this.schedulerStore.getFacebookSchedulingToken();
        }
        if (this.authStore.currentBrand.permissions?.instagram?.can_access_instagram) {
          this.instagramAccountStore.getBrandInstagramAccount();
        }

        if (this.routeMetaReport || this.routeName === null) {
          return;
        }
        this.chiliPiperStore.init();
        this.intercomStore.boot();
        this.hubspotStore.boot();
        this.survicateStore.init();
        this.datadogStore.setupUser();
        if (this.authStore.isLoggedIn) {
          this.authStore.getBrandAccessibleUsers({ brandId: this.authStore.currentBrand?.id });
        }
        this.loadTwitterAccounts();
        this.getCompetitorList();
      }
    },
    trackSession() {
      let sessionExpired = true;

      const lastCheck = browserStorageGetItem(trackSessionKey);
      const timestamp = Date.now();

      if (lastCheck && timestamp - lastCheck < sessionInterval) {
        sessionExpired = false;
      } else {
        browserStorageSetItem(trackSessionKey, timestamp.toString());
      }

      if (sessionExpired && !isEqual(this.currentCursorPos, this.savedCursorPos)) {
        this.savedCursorPos = this.currentCursorPos;
        this.trackingStore.track(`Session`);
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.addEventListener('complete', () => {
        this.showAnimation = false;
      });
      this.anim.play();
    },
    loadReportStyle() {
      document.body.style.background = 'white';
      if (this.routeMetaLayout === 'horizontal') {
        setCssPageMedia(`
          size: 1150px 735px;
          margin: 0;
        `);
      }
    },
    handleDragEnter(event) {
      // make whole app responsive to drag and drop upload
      event.preventDefault();
      // ignore other draggable elements.
      if (event.dataTransfer.items.length === 0 || event.dataTransfer.items[0].kind !== 'file') {
        return;
      }
      // disable drag & drop when popup is open.
      if (this.hasOpenPopup) {
        return;
      }
      if (this.$route.name !== 'auth.login') {
        // TODO: open upload popup here...
      }
    },
    handleDragLeave(event) {
      // dragleave event fires whenever drag leaves current DOM node.
      // (i.e, hovering on different Node, or moved out of window)
      // we only resume page when mouse moved out of the window.
      event.preventDefault();
      if (
        event.clientX === 0 &&
        event.clientY === 0 &&
        this.$route.name === 'library.media.upload'
      ) {
        const existingFileLength = this.$refs.uploadPopup.mediaItems.length;
        if (!existingFileLength) {
          this.$router.back();
        }
      }
    },
    setupAppEventListeners() {
      window.addEventListener('dragenter', this.handleDragEnter.bind(this));
      window.addEventListener('dragleave', this.handleDragLeave.bind(this));

      // This will disable default browser drag/drop functionality
      // (prevents drag/drop outside dropzone)
      document.addEventListener('dragover', (e) => e.preventDefault());
      document.addEventListener('drop', (e) => e.preventDefault());
    },
    accountSetupNotComplete() {
      return !!(
        this.authStore.identity &&
        // Brands are added to identity for anonymous users as part of the platform reconnection process
        this.authStore.identity.id &&
        this.authStore.identity.invitation_status !== 'completed' &&
        this.$route.name !== 'account.setup'
      );
    },
    loadTwitterAccounts() {
      if (this.authStore.isLoggedIn) {
        const identityBrandIds = Object.keys(this.authStore.identity.brands).map(
          (brandLabel) => this.authStore.identity.brands[brandLabel].id,
        );
        const brandIds = identityBrandIds;
        this.twitterStore.getTwitterAccounts({ brandIds });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.app-view {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* iOS Safari */

  &.nav-view {
    padding-top: var(--space-48);
    min-width: 1024px;
  }

  &.report-view {
    background: var(--background-0);
  }
}

@media print {
  body {
    background: var(--background-0) !important;
  }
}
</style>
