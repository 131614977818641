/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowRightCircle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><path pid="0" id="svgicon_arrowRightCircle_a" d="M15.675 9.999l-5.648-5.648-5.649 5.648 1.697 1.697 2.752-2.752v6.703h2.4V8.944l2.751 2.752z"/></defs><g _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"/><use transform="scale(1 -1) rotate(90 20.026 0)" xlink:href="#svgicon_arrowRightCircle_a"/></g>'
  }
})
