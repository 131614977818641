import { watch, ref, computed } from 'vue';
import { defineStore } from 'pinia';
import merge from 'lodash/merge';
import { env } from '@/env';
import { useAuthStore } from '@/stores/auth';
import * as AuthAPI from '@/apis/auth';
import { logger } from '@/utils/logger';
import { BRAND } from '@/models/auth/permissions.enum';
import { useNotificationStore } from '@/stores/notification';
import salesforceIcon from '@/assets/img/integrations/salesforce.svg';
import zendeskIcon from '@/assets/img/integrations/zendesk.svg';
import bigqueryIcon from '@/assets/img/integrations/bigquery.svg';
import klaviyoIcon from '@/assets/img/integrations/klaviyo.svg';
import mailchimpIcon from '@/assets/img/integrations/mailchimp.svg';
import googleSheetsIcon from '@/assets/img/integrations/googleSheets.svg';
import googleDriveIcon from '@/assets/img/integrations/googleDrive.svg';
import s3Icon from '@/assets/img/integrations/s3.svg';
import rssFeedIcon from '@/assets/img/integrations/rssFeed.svg';
import airtableIcon from '@/assets/img/integrations/airtable.svg';
import { CUSTOMER_PLAN } from '@/models/auth/customer-plan.enum';
import { useTrackingStore } from '@/stores/tracking';
import { paragon } from '@useparagon/connect';
import { useFlagStore } from './flag';

const INTEGRATION_API_KEY_LABEL = 'dh-integrations';
const DH_INTEGRATIONS_OAUTH_URL = 'https://integrations.dashhudson.com/oauth';

export const INTEGRATIONS = {
  SALESFORCE: {
    name: 'Salesforce',
    value: 'salesforce',
    icon: salesforceIcon,
    description: 'Connect to Salesforce to create cases.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
  },
  ZENDESK: {
    name: 'Zendesk',
    value: 'zendesk',
    icon: zendeskIcon,
    description: 'Connect to Zendesk to create tickets.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
  },
  BIGQUERY: {
    name: 'BigQuery',
    value: 'bigquery',
    icon: bigqueryIcon,
    description: 'Access your most important social metrics from any channel in your BigQuery.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    connectOptions: {
      overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
    },
    flag: 'integrationBigquery',
  },
  KLAVIYO: {
    name: 'Klaviyo',
    value: 'klaviyo',
    icon: klaviyoIcon,
    description: 'Send your LikeShop email submissions directly to your lists in Klaviyo.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    flag: 'integrationKlaviyo',
  },
  MAILCHIMP: {
    name: 'Mailchimp',
    value: 'mailchimp',
    icon: mailchimpIcon,
    description: 'Send your LikeShop email submissions directly to your lists in Mailchimp.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    flag: 'integrationMailchimp',
  },
  GOOGLE_SHEETS: {
    name: 'Google Sheets',
    value: 'googlesheets',
    icon: googleSheetsIcon,
    description:
      'Automatically sync your social media metrics directly from Dash Hudson to Google Sheets.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    connectOptions: {
      overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
    },
    flag: 'integrationGoogleSheets',
  },
  GOOGLE_DRIVE: {
    name: 'Google Drive',
    value: 'googledrive',
    icon: googleDriveIcon,
    description:
      'Sync your approved assets live directly from Google Drive to Dash Hudson’s Library.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    connectOptions: {
      overrideRedirectUrl: DH_INTEGRATIONS_OAUTH_URL,
    },
    flag: 'integrationGoogleDrive',
  },
  AMAZON_S3: {
    name: 'Amazon S3',
    value: 'amazons3',
    icon: s3Icon,
    description: 'Access your most important social metrics from any channel in your AWS s3.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    flag: 'integrationS3',
  },
  AIRTABLE: {
    name: 'Airtable',
    value: 'airtable',
    icon: airtableIcon,
    description: 'Access your most important social metrics from any channel in your Airtable.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    flag: 'integrationAirtable',
  },
  RSSFEED: {
    name: 'RSS Feed',
    value: 'custom.rssfeed',
    icon: rssFeedIcon,
    description: 'Automates content transfer from RSS feeds to social media.',
    plans: [
      CUSTOMER_PLAN.ADVANCE,
      CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ADVANCE_PREMIUM,
      CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE,
      CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM,
      CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING,
    ],
    flag: 'integrationRssFeedV1',
  },
};

export const useIntegrationStore = defineStore('integration', () => {
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const flagStore = useFlagStore();
  const trackingStore = useTrackingStore();

  const initialized = ref(false);
  const paragonIntegrations = ref(null);
  const paragonIntegrationMetadata = ref([]);
  const authenticating = ref(false);
  const authenticated = ref(false);

  function extractIntegrationType(type) {
    return (type?.value ?? type)?.toLowerCase();
  }

  const hasAccessToIntegrations = computed(() => {
    return authStore.guard(BRAND.SETTINGS.CAN_ACCESS_INTEGRATIONS);
  });

  const integrations = computed(() => {
    return Object.keys(paragonIntegrations.value ?? {}).reduce((acc, integrationType) => {
      const paragonMetadata = paragonIntegrationMetadata.value?.find(
        (metadata) => metadata.type === integrationType,
      );
      const dhMetadata = Object.keys(INTEGRATIONS)
        .map((integrationEnum) => INTEGRATIONS[integrationEnum])
        .find((integrationEnumObject) => integrationEnumObject.value === integrationType);

      const isIntegrationActive = !!paragonMetadata;
      const hasFlag = dhMetadata && (!dhMetadata.flag || flagStore.flags[dhMetadata.flag]);
      const hasPlan = authStore.hasPlan(dhMetadata?.plans);

      if (isIntegrationActive && hasFlag && hasPlan) {
        acc[integrationType] = paragonIntegrations.value[integrationType] ?? {};
        acc[integrationType].metadata = paragonMetadata ?? {};
        acc[integrationType].metadata.description = dhMetadata?.description;
      }

      return acc;
    }, {});
  });
  const integrationList = computed(() => {
    return Object.values(integrations.value);
  });
  const integrationIcon = computed(() => {
    return (type) => {
      const value = extractIntegrationType(type);
      return Object.values(INTEGRATIONS).find((i) => i.value === value)?.icon;
    };
  });
  const isConnected = computed(() => {
    return (integrationValue) => {
      const value = extractIntegrationType(integrationValue);
      return !!paragonIntegrations.value?.[value]?.enabled;
    };
  });
  const getWorkflow = computed(() => {
    return (integrationValue, workflowId) => {
      const value = extractIntegrationType(integrationValue);
      return paragonIntegrations.value?.[value]?.configuredWorkflows?.[workflowId];
    };
  });
  const hasWorkflow = computed(() => {
    return (integrationValue, workflowId) => {
      return !!getWorkflow.value(integrationValue, workflowId);
    };
  });
  const isWorkflowEnabled = computed(() => {
    return (integrationValue, workflowId) => {
      return !!getWorkflow.value(integrationValue, workflowId)?.enabled;
    };
  });

  function integrationName(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    return Object.values(INTEGRATIONS).find((i) => i.value === value)?.name;
  }

  function integrationConnectOptions(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    return Object.values(INTEGRATIONS).find((i) => i.value === value)?.connectOptions ?? {};
  }

  function integrationMixpanelProps(integration) {
    const type = extractIntegrationType(integration);
    const name = integrationName(integration);
    return {
      integrationType: type,
      integrationName: name,
    };
  }

  function loadBrandIntegrations() {
    const user = paragon.getUser();
    paragonIntegrations.value = user?.integrations;
    paragonIntegrationMetadata.value = paragon.getIntegrationMetadata();
  }

  async function deleteOldIntegrationApiKeys() {
    try {
      const response = await authStore.getApiKeysForBrand({
        brandId: authStore.currentBrand.id,
      });
      const payload = response?.data ?? [];
      if (Array.isArray(payload)) {
        const deleteRequests = payload
          .filter((apiKey) => apiKey.label === INTEGRATION_API_KEY_LABEL)
          .map((apiKey) =>
            AuthAPI.deleteApiKeyForBrand({
              brandId: authStore.currentBrand?.id,
              apiKeyId: apiKey?.apiKeyId,
            }),
          );
        await Promise.all(deleteRequests);
      }
    } catch (error) {
      logger.error('Failed to delete old integration api key', {}, error);
      throw error;
    }
  }

  async function updateDHApiKey() {
    try {
      await deleteOldIntegrationApiKeys();
    } catch (error) {
      // ignoring errors here so we still create new api key
    }
    const response = await authStore.generateApiKey({
      label: INTEGRATION_API_KEY_LABEL,
      brandId: authStore.currentBrand?.id,
    });
    const payload = response?.data;
    const token = payload?.token;
    await paragon.setUserMetadata({
      api_key: token,
    });
  }

  async function hasValidApiKey(user) {
    const apiKey = user?.meta?.api_key;
    if (!apiKey) {
      return false;
    }
    try {
      await AuthAPI.validateApiKey({ token: apiKey });
    } catch (error) {
      return false;
    }
    return true;
  }

  async function updateDHApiKeyIfRequired() {
    const user = paragon.getUser();
    if (user?.meta) {
      if (!(await hasValidApiKey(user))) {
        await updateDHApiKey();
      }
    }
  }

  async function authenticate() {
    if (!env.paragonProjectId) {
      logger.error(`Paragon Project ID is missing.`);
      return;
    }
    const brand = authStore.currentBrand;
    authenticating.value = true;
    try {
      if (brand) {
        const response = await AuthAPI.getBrandIntegrations({ brandId: brand.id });
        const paragonJwtToken = response?.data?.paragonJwtToken;
        if (paragonJwtToken) {
          await paragon.authenticate(env.paragonProjectId, paragonJwtToken);

          await paragon.setUserMetadata({
            name: brand.name,
            brand_id: brand.id,
          });
          await updateDHApiKeyIfRequired();
          loadBrandIntegrations();
        } else {
          logger.error(`Paragon token is missing, unable to authenticate.`);
        }
      } else {
        paragonIntegrations.value = null;
      }
    } catch (error) {
      logger.error('Failed paragon authentication', {}, error);
      throw error;
    } finally {
      authenticating.value = false;
      authenticated.value = paragon?.getUser()?.authenticated;
      initialized.value = true;
    }
  }

  function logout() {
    localStorage.removeItem('paragon-connect-user-state');
    authenticated.value = false;
  }

  function connect(integrationValue) {
    const value = extractIntegrationType(integrationValue);
    const name = integrationName(integrationValue);
    const connectOptions = integrationConnectOptions(integrationValue);
    const mixpanelProps = integrationMixpanelProps(integrationValue);
    trackingStore.track('Started Integration Connection', mixpanelProps);
    paragon.connect(
      value,
      merge(
        {
          onSuccess: () => {
            trackingStore.track('Integration Connected', {
              integrationType: value,
              integrationName: name,
            });
            notificationStore.setToast({
              message: `Successfully connected to ${name}`,
            });
            loadBrandIntegrations();
          },
          onUninstall: () => {
            trackingStore.track('Integration Disconnected', mixpanelProps);
            loadBrandIntegrations();
          },
          onWorkflowChange: () => {
            trackingStore.track('Integration Workflow Change', mixpanelProps);
            loadBrandIntegrations();
          },
          onError: (error) => {
            trackingStore.track('Integration Connection Failure', {
              ...mixpanelProps,
              errorType: error,
            });
            notificationStore.setToast({
              message: `There was a problem connecting your ${name} account.  Please try again.`,
              type: 'error',
            });
            loadBrandIntegrations();
          },
        },
        connectOptions,
      ),
    );
  }

  async function runWorkflow(workflowId, payload) {
    return paragon?.workflow(workflowId, payload);
  }

  function instanceUrl(integration) {
    const integrationType = extractIntegrationType(integration);
    const user = paragon.getUser();
    const providerData = user?.integrations?.[integrationType]?.providerData;
    return providerData?.instanceUrl ?? '';
  }

  watch(
    () => authStore.currentBrand,
    async () => {
      await authenticate();
    },
    { immediate: true },
  );

  watch(
    () => authStore.isLoggedIn,
    async (newIsLoggedIn, oldIsLoggedIn) => {
      if (newIsLoggedIn === oldIsLoggedIn) {
        return;
      }
      if (!newIsLoggedIn) {
        logout();
      }
    },
    { immediate: true },
  );

  return {
    hasAccessToIntegrations,
    initialized,
    paragonIntegrations,
    paragonIntegrationMetadata,
    authenticating,
    integrationList,
    integrations,
    integrationIcon,
    authenticate,
    authenticated,
    loadBrandIntegrations,
    connect,
    isConnected,
    getWorkflow,
    hasWorkflow,
    isWorkflowEnabled,
    integrationName,
    runWorkflow,
    instanceUrl,
    extractIntegrationType,
    integrationMixpanelProps,
    logout,
  };
});
