<template>
  <main>
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon name="cloudUpload" />
        <p>Uploaded Media</p>
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <section :class="['content-list', { open: dropdownOpen }]">
      <div v-for="(item, i) in informationList" :key="item.label" class="list-row-container">
        <div ref="row" class="list-row" :class="{ 'list-row-overflow': listValueOverflows[i] }">
          <span ref="label" class="list-label">{{ item.label }} </span>
          <dl ref="value">
            <template v-if="item.avatar">
              <UserAvatar :user="uploader" class="avatar-placeholder" xxsmall />
            </template>
            {{ item.value }}
            <template v-if="item.label === 'Approved Publishing Dates'">
              <DateRangeSelector
                v-model:start="dateRangeStart"
                v-model:end="dateRangeEnd"
                :timezone="timezone"
                calendar-always-custom
                reset-on-open
                show-as-edit-button
                :calendar-max-date="null"
                :calendar-min-date="new Date()"
                top-offset="var(--space-24)"
                :is-updating="isUpdating"
                :disabled="!userCanEditPublishDates"
                :tooltip="publishDatesNoPermissionTooltip"
              >
                <template #below-calendar="{ startDate, endDate }">
                  <div v-if="startDate && endDate" class="info-message">
                    <Icon
                      v-tooltip="'Time zone is based on your current browser settings'"
                      name="info"
                      xxsmall
                    />
                    <div class="info-message-text">
                      <p>Date range this media will be approved for publishing:</p>
                      <p>
                        {{ formatDateRangeForInfoMessage(startDate, endDate) }}<br />
                        ({{ timezone }})
                      </p>
                    </div>
                  </div>
                </template>
              </DateRangeSelector>
              <div
                v-if="mediaDetailStore.mediaDetail.meta.canPublishWithin"
                class="reset-dates"
                @click.stop="clearPublishingDates"
              >
                <Icon
                  v-tooltip="userCanEditPublishDates ? null : publishDatesNoPermissionTooltip"
                  :class="{ disabled: !userCanEditPublishDates }"
                  name="close"
                  xxsmall
                />
              </div>
            </template>
          </dl>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { useMediaDetailStore } from '@/stores/media-detail';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
import { USER, BRAND } from '@/models/auth/permissions.enum';
import { formatDateRangeLabel } from '@/utils/formatters';
import { LibraryAPI } from '@/apis';
import { useSchedulerStore } from '@/stores/scheduler';
import DateRangeSelector from '@/components/DateRangeSelector.vue';
import { guessTimezone } from '@/utils/timezone';
import { toolTips } from '@/config';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'UploadInformation',
  components: { DateRangeSelector, Icon, UserAvatar },
  data() {
    return {
      rowResizeObserver: new ResizeObserver(() => {
        this.rowWidth = this.$refs.row.map((row) => row.offsetWidth);
      }),
      labelResizeObserver: new ResizeObserver(() => {
        this.labelWidth = this.$refs.label.map((label) => label.offsetWidth);
      }),
      valueResizeObserver: new ResizeObserver(() => {
        this.valueWidth = this.$refs.value.map((value) => value.offsetWidth);
      }),
      dropdownOpen: true,
      iconColor: colours.ACTION.ACTION_500,
      rowWidth: [],
      labelWidth: [],
      valueWidth: [],
      isUpdating: false,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  },
  computed: {
    ...mapStores(
      useMediaDetailStore,
      useFlagStore,
      useIdentityStore,
      useNotificationStore,
      useSchedulerStore,
    ),
    uploader() {
      if (
        !this.mediaDetailStore.mediaDetail &&
        this.mediaDetailStore.mediaDetail.uploadSourceData
      ) {
        return null;
      }
      return {
        firstName: this.mediaDetailStore.mediaDetail.uploadSourceData.firstName,
        lastName: this.mediaDetailStore.mediaDetail.uploadSourceData.lastName,
        avatarUrl: this.mediaDetailStore.mediaDetail.uploadSourceData.avatarUrl,
      };
    },
    informationList() {
      if (this.mediaDetailStore.mediaDetail && this.mediaDetailStore.mediaDetail.uploadSourceData) {
        const { uploadSourceData } = this.mediaDetailStore.mediaDetail;

        let canViewPublishWithin =
          this.flagStore.ready &&
          this.flagStore.flags.assetManagementGA &&
          this.identityStore.guard(BRAND.LIBRARY.CAN_ACCESS_PUBLISHING_DATES_AND_BULK_UPLOADING);
        let dateDisplay = 'Any Time';
        if (this.mediaDetailStore.mediaDetail?.meta?.canPublishWithin) {
          canViewPublishWithin = true;
          dateDisplay = formatDateRangeLabel(
            this.mediaDetailStore.mediaDetail.meta.canPublishWithin.start,
            this.mediaDetailStore.mediaDetail.meta.canPublishWithin.end,
            {
              withTime: true,
            },
          );
        }

        return [
          { label: 'File Name', value: this.formatFileName(uploadSourceData.filename) },
          ...(canViewPublishWithin
            ? [
                {
                  label: 'Approved Publishing Dates',
                  value: dateDisplay,
                },
              ]
            : []),
          {
            label: 'Uploaded Time',
            value: dayjs(uploadSourceData.uploadTime).format('MMMM D, YYYY h:mm A'),
          },
          {
            label: 'Uploaded By',
            avatar: true,
            image: uploadSourceData.avatarUrl,
            value: `${uploadSourceData.firstName} ${uploadSourceData.lastName}`,
          },
        ];
      }
      return [];
    },
    listValueOverflows() {
      return this.informationList.map((_, i) => {
        return this.valueWidth[i] > this.rowWidth[i] - this.labelWidth[i];
      });
    },
    userCanEditPublishDates() {
      return this.identityStore.guard(USER.LIBRARY.CAN_EDIT_PUBLISH_DATES);
    },
    timezone() {
      return guessTimezone();
    },
    publishDatesNoPermissionTooltip() {
      return this.userCanEditPublishDates
        ? null
        : toolTips.mediaPopupPublishDatesNoPermissionTooltip;
    },
  },
  mounted() {
    this.$refs.row?.forEach((row) => {
      this.rowResizeObserver.observe(row);
    });
    this.$refs.label?.forEach((label) => {
      this.labelResizeObserver.observe(label);
    });
    this.$refs.value?.forEach((value) => {
      this.valueResizeObserver.observe(value);
    });
    this.$watch(
      () => [this.dateRangeStart, this.dateRangeEnd],
      async ([dateRangeStart, dateRangeEnd]) => {
        await this.updatePublishingDate({ start: dateRangeStart, end: dateRangeEnd });
      },
      { immediate: false, deep: true },
    );
  },
  beforeUnmount() {
    this.rowResizeObserver.disconnect();
    this.labelResizeObserver.disconnect();
    this.valueResizeObserver.disconnect();
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    formatFileName(name) {
      return name || '-';
    },
    async clearPublishingDates() {
      if (this.userCanEditPublishDates) {
        await this.notificationStore.confirm(
          'Remove Approved Publishing Dates?',
          'It will be possible to post the media on any date.',
          {
            confirmAlias: 'Discard',
            onConfirm: async () => {
              await this.updatePublishingDate(null);
            },
          },
        );
      }
    },
    formatDateRangeForInfoMessage(startDate, endDate) {
      return formatDateRangeLabel(startDate, dayjs(endDate).add(1, 'day').toDate(), {
        withTime: true,
      });
    },
    async updatePublishingDate(range) {
      try {
        this.isUpdating = !!range;
        const newMeta = {
          ...this.mediaDetailStore.mediaDetail.meta,
          canPublishWithin: range,
        };
        await LibraryAPI.updateMediaMeta({
          brandId: this.mediaDetailStore.mediaDetail.brandId,
          mediaId: this.mediaDetailStore.mediaDetail.id,
          meta: newMeta,
        });
        this.mediaDetailStore.mediaDetail.meta = newMeta;
        this.schedulerStore.$reset();
      } catch (e) {
        this.notificationStore.setToast({
          message: 'Your approved publishing dates could not be modified. Please try again later.',
          type: 'error',
        });
      } finally {
        this.isUpdating = false;
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--background-300);
  margin: var(--space-12) auto;
  padding: 0 var(--space-16);
  border-radius: var(--round-corner-small);

  header {
    min-width: 100%;
    height: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-16);
      }

      p {
        transform: translateY(-1px);
      }
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .content-list {
    width: 100%;
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.3s;
    padding-left: var(--space-40);

    .list-row-container {
      position: relative;
      padding-bottom: var(--space-8);
      font-size: var(--x14);

      &:last-child {
        border: none;
        padding-bottom: var(--space-24);
      }
    }

    .list-row:not(.list-row-overflow)::before {
      float: left;
      width: 0;
      white-space: nowrap;
      content: '--------------------------------------------------------------------------------';
      color: var(--border);
    }

    .list-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;

      &.list-row-overflow {
        span {
          position: unset;
        }

        dl {
          display: flex;
          align-items: center;
          text-align: left;
          float: left;
          padding-left: 0;
          overflow-wrap: anywhere;
        }
      }

      span {
        color: var(--text-secondary);
        font-weight: var(--font-medium);
      }

      .list-label {
        position: absolute;
        background: var(--background-300);
        padding-right: var(--space-8);
      }

      dl {
        display: flex;
        align-items: center;
        text-align: right;
        float: right;
        padding-left: var(--space-8);
        background: var(--background-300);

        .avatar-placeholder {
          margin-right: var(--space-8);
        }
      }
    }

    &.open {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }
}

.info-message {
  display: flex;
  gap: var(--space-12);
  padding: var(--space-12);
  margin: 0 var(--space-16) var(--space-16) var(--space-16);
  border: 1px dashed var(--border);
  border-radius: var(--round-corner);
  text-align: left;

  .info-message-text {
    flex: 1;
    font-size: var(--x12);
    line-height: var(--x14);
  }
}

.publish-dates-no-permission-tooltip {
  margin-left: var(--space-4);
}

.reset-dates {
  align-items: center !important;
  display: flex;

  .icon {
    cursor: pointer;
    margin-left: var(--space-10);

    &.disabled {
      cursor: unset;
    }
  }
}
</style>
