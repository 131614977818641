<template>
  <div class="media-badges">
    <!-- Media type Badges-->
    <MediaPopupBadge
      v-if="showMediaPopupBadge"
      :source-type="mediaItem.sourceType"
      :media-type="mediaItem.mediaType"
      :carousel="carousel"
    />

    <Icon
      v-if="showMultiMediaBadge"
      v-tooltip="'This is a carousel'"
      name="multipleMedia"
      xlarge
      :color="iconColor"
    />

    <Icon
      v-if="showLinkBadge"
      v-tooltip="'This is a Facebook link post'"
      name="link"
      xlarge
      color="white"
    />
    <!-- Media Source Badges-->
    <Icon
      v-if="mediaSourceBadge"
      v-tooltip="mediaSourceBadge.tooltip"
      :name="mediaSourceBadge.name"
      xlarge
      :color="iconColor"
    />
    <!-- Extra Badges-->
    <Icon
      v-if="isBoosted"
      v-tooltip="'This is a promoted post'"
      name="boosted"
      large
      :color="iconColor"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import enumTypes from '@/app/library/constants';
import MediaPopupBadge from '@/components/MediaBadges/MediaPopupBadge.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaPopupBadges',
  components: { Icon, MediaPopupBadge },
  props: {
    carousel: { type: Boolean, default: false },
    igtv: { type: Boolean, default: false },
    mediaItem: { type: Object, required: true },
  },
  data() {
    return {};
  },
  computed: {
    mediaSourceBadge() {
      const mediaSourceBadge = {};
      if (this.showUGCBadge) {
        mediaSourceBadge.name = 'file-heart';
        mediaSourceBadge.tooltip = 'This is a UGC post';
      } else if (this.showInstagramBadge || this.showIgtvBadge) {
        mediaSourceBadge.name = this.instagramIcon;
        mediaSourceBadge.tooltip = 'This media is published on your Instagram';
      } else if (this.showPinterestBadge) {
        mediaSourceBadge.name = 'pinterest';
        mediaSourceBadge.tooltip = 'This media is published on your Pinterest';
      } else if (this.showInstagramStoryBadge) {
        mediaSourceBadge.name = 'stories';
        mediaSourceBadge.tooltip = 'This media is published on your Instagram Story';
      } else if (this.showAdBadge) {
        mediaSourceBadge.name = 'megaphone';
        mediaSourceBadge.tooltip = 'This is an Ad';
      } else if (this.isDynamicAd) {
        mediaSourceBadge.name = 'megaphoneDynamic';
        mediaSourceBadge.tooltip = 'This is a Dynamic Ad';
      } else if (this.showFacebookBadge) {
        mediaSourceBadge.name = this.facebookIcon;
        mediaSourceBadge.tooltip = 'This media is published on your Facebook';
      } else if (this.showTwitterBadge) {
        mediaSourceBadge.name = 'twitter';
        mediaSourceBadge.tooltip = 'This media is published on your X';
      }
      return Object.keys(mediaSourceBadge).length > 0 ? mediaSourceBadge : null;
    },
    iconColor() {
      return colours.BASIC.WHITE;
    },
    isBoosted() {
      if (this.carousel) {
        return (
          this.mediaItem.type === enumTypes.FACEBOOK_ADS && this.mediaItem.sourceData[0].isBoosted
        );
      }
      return (
        this.mediaItem.sourceType === enumTypes.FACEBOOK_ADS && this.mediaItem.adsInfo.adIsBoosted
      );
    },
    isDynamicAd() {
      if (this.carousel) {
        return (
          this.mediaItem.type === enumTypes.FACEBOOK_ADS &&
          this.mediaItem.sourceData[0].creativeIsDynamic
        );
      }
      return (
        this.mediaItem.sourceType === enumTypes.FACEBOOK_ADS && this.mediaItem.adsInfo.adIsDynamic
      );
    },
    showLinkBadge() {
      return enumTypes.FACEBOOK_LINK_LIST.includes(this.mediaItem.sourceType);
    },
    showMultiMediaBadge() {
      return this.carousel && this.mediaItem.sourceType !== enumTypes.LINKEDIN_OWNED;
    },
    showUGCBadge() {
      return enumTypes.UGC_LIST.includes(this.mediaItem.sourceType);
    },
    showIgtvBadge() {
      return this.igtv;
    },
    showInstagramBadge() {
      return (
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_OWNED ||
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_UGC ||
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_OTHER
      );
    },
    showPinterestBadge() {
      return this.mediaItem.sourceType === enumTypes.PINTEREST_OWNED;
    },
    showInstagramStoryBadge() {
      return this.mediaItem.sourceType === enumTypes.INSTAGRAM_STORY;
    },
    showAdBadge() {
      const type = this.carousel ? this.mediaItem.type : this.mediaItem.sourceType;
      return type === enumTypes.FACEBOOK_ADS;
    },
    showFacebookBadge() {
      return enumTypes.FACEBOOK_SOURCE_LIST.includes(this.mediaItem.sourceType);
    },
    showTwitterBadge() {
      return this.mediaItem.sourceType === enumTypes.TWITTER_OWNED;
    },
    instagramIcon() {
      return this.mediaItem.isReel ? 'instagram-reels' : 'instagram';
    },
    facebookIcon() {
      return this.mediaItem.isReel ? 'instagram-reels' : 'facebook';
    },
    showMediaPopupBadge() {
      return this.mediaItem.sourceType === enumTypes.LINKEDIN_OWNED;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-badges {
  position: absolute;
  right: var(--space-16);
  top: var(--space-16);
  display: flex;
  justify-content: flex-end;
  z-index: 3;

  & > * {
    margin: 0 var(--space-8);
    flex: 0 0 var(--space-32);
    cursor: pointer;
    outline: none;
  }

  & > *:last-child {
    margin-right: 0;
  }

  .video-badge {
    border-radius: 50%;
    background: linear-gradient(rgb(0 0 0 / 20%), rgb(0 0 0 / 0%));
  }
}
</style>
