/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'viewSubtract': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#767676"><path pid="0" d="M22.817 14.425C22.633 14.162 18.222 8 12 8S1.367 14.162 1.183 14.425a.996.996 0 000 1.151C1.367 15.838 5.778 22 12 22s10.633-6.162 10.817-6.424a.996.996 0 000-1.151zM12 20c-4.166 0-7.549-3.575-8.731-5.001C4.448 13.573 7.82 10 12 10c4.166 0 7.549 3.576 8.731 5.001C19.552 16.428 16.18 20 12 20zM15 4h8v2h-8z"/><path pid="1" d="M12 11c-2.206 0-4 1.795-4 4 0 2.206 1.794 4 4 4s4-1.794 4-4c0-2.205-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2 0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2z"/></g>'
  }
})
