<script setup>
import Icon from '@/components/foundation/Icon.vue';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  grey: {
    type: Boolean,
    default: false,
  },
  prependIcon: {
    type: String,
    default: null,
  },
  prependIconColor: {
    type: String,
    default: null,
  },
  prependIconBindings: {
    type: Object,
    default: () => {},
  },
  prependImg: {
    type: String,
    default: null,
  },
  inline: {
    type: Boolean,
    default: false,
  },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div
    class="selection-display-button text-small-medium flex cursor-pointer items-center py-2 pl-6 pr-4"
    :class="{
      open: props.open,
      grey: props.grey,
      flex: !props.inline,
      'inline-flex': props.inline,
    }"
  >
    <slot name="prependIcon">
      <template v-if="props.prependIcon">
        <div class="flex items-center pr-2">
          <Icon
            v-bind="props.prependIconBindings"
            :name="props.prependIcon"
            :color="props.prependIconColor"
          />
        </div>
      </template>
    </slot>
    <template v-if="props.prependImg || $slots.prependImg">
      <div class="flex items-center pr-2">
        <slot name="prependImg">
          <img :src="props.prependImg" class="h-6 w-6" />
        </slot>
      </div>
    </template>
    <div class="grow">
      <slot />
    </div>
    <div class="text-small ml-2 flex h-6 w-6 items-center justify-center">
      <Icon name="chevronDown" :dir="props.open ? 'down' : 'up'" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.selection-display-button {
  background: var(--background-0);
  border: 1px solid var(--border);
  border-radius: var(--button-border-radius);
  transition: var(--transition-all);

  &.grey {
    background: var(--background-300);
  }

  &.open,
  &:hover {
    box-shadow: var(--shadow-1);
  }
}
</style>
