/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stackedGraph': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_1998_184797)" _fill="#686a7a"><path pid="0" d="M5 19V3.001H3V21h18v-2H5z"/><path pid="1" d="M20 6.001h-4V18h4V6.001zM15 12h-4v6h4v-6zM10 10H6v8h4v-8z"/></g><defs><clipPath id="clip0_1998_184797"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
