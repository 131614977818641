/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera-live-view-on': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 19.999h-4v2h4c1.103 0 2-.897 2-2v-4h-2v4zM20 1.999h-4v2h4v4h2v-4c0-1.103-.897-2-2-2zM4 3.999h4v-2H4c-1.103 0-2 .897-2 2v4h2v-4zM4 15.999H2v4c0 1.103.897 2 2 2h4v-2H4v-4zM20 11.999s-3.381-5-8-5-8 5-8 5 3.381 5 8 5 8-5 8-5zm-8 3a3 3 0 110-6 3 3 0 110 6z"/><circle pid="1" cx="12" cy="11.999" r="1"/>'
  }
})
