<script setup>
import { useSlots, computed, inject } from 'vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import Icon from '@/components/foundation/Icon.vue';

const props = defineProps({
  option: { type: Object, default: null },
  optionLabel: { type: String, default: 'label' },
  optionValue: { type: String, default: 'value' },
  linesPerLabel: { type: [String, Number], default: 1 },
  level: { type: Number, default: 1 },
});

const slots = useSlots();

const isOptionSelected = inject('isOptionSelected', () => false);
const isOptionDisabled = inject('isOptionDisabled', () => false);
const canOptionExpand = inject('canOptionExpand', () => false);
const canOptionCollapse = inject('canOptionCollapse', () => false);
const isOptionExpanded = inject('isOptionExpanded', () => false);
const getOptionTooltip = inject('getOptionTooltip', () => false);
const onOptionClick = inject('onOptionClick', () => false);
const onOptionExpandClick = inject('onOptionExpandClick', () => false);
const isOptionPartiallySelected = inject('isOptionPartiallySelected', () => false);
const hasDividerBefore = inject('hasDividerBefore', () => false);

const selected = computed(() => isOptionSelected(props.option));
const partiallySelected = computed(() => isOptionPartiallySelected(props.option));
const showDividerBefore = computed(() => hasDividerBefore(props.option));

const linesPerLabel = computed(() => {
  const maxLines = props.linesPerLabel > 0 ? props.linesPerLabel : 1;
  if (maxLines > 6) {
    return 6;
  }
  return maxLines;
});

const optionLabelClasses = computed(() => {
  const lineClampClass = `line-clamp-${linesPerLabel.value}`;
  return {
    [lineClampClass]: true,
  };
});

const optionCheckboxAndLabelClasses = computed(() => {
  const classes = {
    'cursor-pointer': !isOptionDisabled(props.option),
    'cursor-not-allowed': isOptionDisabled(props.option),
  };

  const level = props.level;
  if (level > 1) {
    classes[`pl-${(level - 1) * 6}`] = true;
  }

  return classes;
});

defineExpose(
  process.env.NODE_ENV === 'test' && {
    optionLabelClasses,
  },
);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div v-if="props.option">
    <template v-if="showDividerBefore">
      <hr class="mx-4 my-0.5" />
    </template>
    <div
      class="text-small-medium flex items-center justify-between gap-2 py-2 pl-6 pr-4 hover:bg-grey-300"
      :class="{
        'cursor-pointer': !isOptionDisabled(props.option) && canOptionExpand(props.option),
        'cursor-not-allowed': isOptionDisabled(props.option) && canOptionExpand(props.option),
      }"
      @click.stop.prevent="onOptionExpandClick(option)"
    >
      <div
        class="flex min-w-0 items-center"
        :class="optionCheckboxAndLabelClasses"
        @click.capture.stop.prevent="onOptionClick(option)"
      >
        <CheckBox
          :id="props.option[props.optionValue]"
          :disabled="isOptionDisabled(props.option)"
          :value="selected || partiallySelected"
          :use-indeterminate-style="partiallySelected"
        />
        <div
          v-tooltip="getOptionTooltip(props.option)"
          class="break-words"
          :class="{
            'text-secondary': isOptionDisabled(props.option),
            ...optionLabelClasses,
          }"
          :title="option[props.optionLabel]"
        >
          {{ option[props.optionLabel] }}
        </div>
      </div>
      <div v-if="!!slots.optionRight">
        <slot name="optionRight" :option="option" />
      </div>
      <div v-if="canOptionExpand(props.option) && canOptionCollapse(props.option)">
        <div class="text-small ml-2 flex h-6 w-6 items-center justify-center">
          <Icon name="chevronDown" :dir="isOptionExpanded(props.option) ? 'down' : 'up'" xsmall />
        </div>
      </div>
    </div>

    <template v-if="canOptionExpand(props.option)">
      <transition name="slide">
        <div v-if="isOptionExpanded(props.option)">
          <template v-for="childOption in option.children" :key="childOption[props.optionValue]">
            <MultiSelectDropdownOption
              :option="childOption"
              :option-label="props.optionLabel"
              :option-value="props.optionValue"
              :lines-per-label="props.linesPerLabel"
              :level="props.level + 1"
            >
              <template v-if="!!slots.optionRight" #optionRight="optionRightProps">
                <slot name="optionRight" v-bind="optionRightProps" />
              </template>
            </MultiSelectDropdownOption>
          </template>
        </div>
      </transition>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.divider-before {
  border-top: 1px solid var(--border);
}
</style>
