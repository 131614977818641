<template>
  <div
    v-if="showCommentPanelToggle"
    v-tooltip="tooltip"
    :class="['comment-panel-toggle', { active: isActive, 'has-comments': hasComments }]"
    @click="confirmCommentDiscard"
  >
    <Icon
      :color="isActive ? colours.BRAND.BRAND_ACCENT : colours.ICON.ICON_SECONDARY"
      name="chatBubbleSquare"
    />
    <span
      v-if="hasComments || hasUnreadComments"
      :class="['notification-circle', { unread: hasUnreadComments }]"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import {
  commentPanelToggleTooltip,
  discardConfirmButton,
  discardCommentConfirmMessage,
  discardCommentConfirmTitle,
} from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import { useCommentingStore } from '@/stores/commenting';
import { useNotificationStore } from '@/stores/notification';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { useSchedulerStore } from '@/stores/scheduler';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CommentPanelToggle',
  components: {
    Icon,
  },
  props: {
    isActive: { type: Boolean, required: true },
    platform: { type: String, default: '' },
    postId: { type: [String, Number], default: null },
  },
  data() {
    return {
      isFirstTimeUsingCommentPanel: false,
      hasUsedCommentPanel: browserStorageGetItem('hasUsedCommentPanel'),
    };
  },
  computed: {
    ...mapStores(useCommentingStore, useNotificationStore, useSchedulerStore),
    colours() {
      return colours;
    },
    tooltip() {
      if (this.isActive) {
        return 'Collapse Comment Panel';
      }
      if (this.showNewUserTooltip) {
        return commentPanelToggleTooltip;
      }
      return 'Expand Comment Panel';
    },
    unreadCommentIds() {
      return this.commentingStore?.notifications
        .filter((notification) => !notification.readAt)
        .map((notification) => notification.comment.id);
    },
    hasComments() {
      return this.commentingStore?.comments?.length > 0;
    },
    hasUnreadComments() {
      return this.commentingStore?.comments?.some((comment) => {
        return (
          this.unreadCommentIds.includes(comment.id) ||
          comment.replies?.some((reply) => this.unreadCommentIds.includes(reply.id))
        );
      });
    },
    showNewUserTooltip() {
      return !this.hasUsedCommentPanel;
    },
    showCommentPanelToggle() {
      return !!this.postId;
    },
  },
  watch: {
    isActive: {
      handler(to) {
        if (to) {
          this.hasUsedCommentPanel = true;
          browserStorageSetItem('hasUsedCommentPanel', this.hasUsedCommentPanel);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async confirmCommentDiscard() {
      if (this.isActive && this.commentingStore?.hasUnsavedComments) {
        if (
          await this.notificationStore.confirm(
            discardCommentConfirmTitle,
            discardCommentConfirmMessage,
            {
              confirmAlias: discardConfirmButton,
            },
          )
        ) {
          this.toggleCommentPanel();
        }
      } else {
        this.toggleCommentPanel();
      }
    },
    toggleCommentPanel() {
      const post = this.schedulerStore.getPost({ platform: this.platform, id: this.postId });
      this.schedulerStore.toggleSidePanel({ router: this.$router, platform: this.platform, post });
    },
  },
});
export default comp;
</script>
<style lang="postcss">
@keyframes shake {
  0%,
  30%,
  40%,
  53.3%,
  66.667%,
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }

  35% {
    transform: translate3d(0, 25px, 0);
    animation-timing-function: ease-in;
  }

  46.667% {
    transform: translate3d(0, 12.5px, 0);
    animation-timing-function: ease-in;
  }

  60% {
    transform: translate3d(0, 6.25px, 0);
    animation-timing-function: ease-in;
  }
}
</style>

<style lang="postcss" scoped>
.comment-panel-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: var(--space-40);
  height: var(--space-40);
  background: var(--background-300);
  border: 1px solid #ddd;
  border-radius: var(--round-corner-small);
  cursor: pointer;

  &:hover .svg-icon {
    fill: var(--brand-accent) !important;
  }

  &.active {
    border-color: var(--brand-accent);
  }

  .notification-circle {
    position: absolute;
    width: var(--space-8);
    height: var(--space-8);
    top: 0.375rem;
    right: 0.375rem;
    border-radius: 50%;
    background-color: var(--brand-accent);

    &.unread {
      background-color: var(--error-500);
    }
  }
}
</style>
