/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'thumbs-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#686a7a"><path pid="0" d="M5 8H1a1 1 0 00-1 1v10a1 1 0 001 1h4a1 1 0 001-1V9a1 1 0 00-1-1zM22.768 7.359A1.002 1.002 0 0022 7h-6V3a1 1 0 00-1-1h-3a1 1 0 00-.919.605L8 9v9.478l3.629 1.451A.999.999 0 0012 20h8c.483 0 .897-.346.984-.821l2-11a1.004 1.004 0 00-.216-.82z"/></g>'
  }
})
