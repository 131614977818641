<script setup>
import { VISIT_RESOURCE_CENTER } from '@/components/VisionAi/constants';
import AssistantStyleWrapper from '@/components/VisionAi/AssistantStyleWrapper.vue';
import { useVisionAiStore } from '@/stores/vision-ai';

const visionAiStore = useVisionAiStore();

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  hideResourceLink: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <AssistantStyleWrapper>
    <span v-sanitize-html="props.text" class="sanitize-html-string" />
    <span v-if="!hideResourceLink"
      >{{ VISIT_RESOURCE_CENTER
      }}<a class="colours.TEXT.TEXT_LINK" @click="visionAiStore.sendToResourceCenter"
        >Resource Center</a
      ></span
    >
  </AssistantStyleWrapper>
</template>

<style lang="postcss" scoped>
.sanitize-html-string {
  :deep() {
    ul {
      list-style-type: disc;
      padding-left: var(--space-20);
    }

    .list-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.indented-list {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

a {
  color: var(--text-link);
}
</style>
