<script setup>
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import ConversationsSettingsDropdown from '@/components/VisionAi/ConversationsSettingsDropdown.vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import {
  SUMMARY_INTENT_TYPES,
  NEW_CHAT_TOOLTIP,
  VISION_AI_WIDGET_INTERACTIONS,
} from '@/components/VisionAi/constants';
import { aiWidgetInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';

const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiStore = useVisionAiStore();

const isSummariesHovered = ref(false);
const hoveredConversationId = ref(null);
const conversationContainerHeight = ref(0);
const showScrollbar = ref(false);

const showConversationHistory = computed(() => {
  return visionAiStore.showConversationHistorySidebar;
});

const groupedConversations = computed(() => {
  return visionAiConversationsStore.groupedConversations;
});

const selectedConversationId = computed(() => {
  return visionAiConversationsStore.currentConversationId;
});

const disableNewChat = computed(() => {
  return (
    visionAiConversationsStore.isCurrentConversationEmpty &&
    !visionAiConversationsStore.summariesSelected
  );
});

const disableNewChatTooltip = computed(() => {
  return disableNewChat.value ? NEW_CHAT_TOOLTIP.DISABLED : null;
});

async function handleConversationClicked(conversationId) {
  visionAiConversationsStore.setSummariesSelectedValue(false);
  visionAiConversationsStore.setCurrentConversationId(conversationId);

  await visionAiConversationsStore.getConversationMessages({
    conversationId,
  });
}

function handleMouseEnter(conversationId) {
  hoveredConversationId.value = conversationId;
  const conversationElement = document.getElementById(`conversation-button-id-${conversationId}`);
  if (hoveredConversationId.value === conversationId) {
    conversationContainerHeight.value = conversationElement?.offsetHeight;
  }
}

async function handleSummariesClicked() {
  visionAiConversationsStore.selectSummaries();
  visionAiStore.clearLocalChatHistory();
  await visionAiStore.getMessages({ intentTypes: SUMMARY_INTENT_TYPES });
}

async function startNewChat() {
  visionAiStore.startNewChat();
  aiWidgetInteractionEvent({
    interaction: VISION_AI_WIDGET_INTERACTIONS.NEW_CHAT,
  });
}

function scrollToConversation(conversationId) {
  nextTick(() => {
    const scrollElement = document.getElementById('conversation-sidebar');
    const targetElement = scrollElement.querySelectorAll(
      `#conversation-button-id-${conversationId}`,
    )[0];
    const containerRect = scrollElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const isHidden = targetRect.bottom > containerRect.bottom - 5;
    if (isHidden) {
      targetElement.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  });
}

watch(
  () => visionAiStore.popupIsExpanded,
  (to) => {
    if (to && visionAiConversationsStore.currentConversationId) {
      scrollToConversation(visionAiConversationsStore.currentConversationId);
    }
  },
);
onMounted(() => {
  if (visionAiConversationsStore.currentConversationId) {
    setTimeout(() => {
      scrollToConversation(visionAiConversationsStore.currentConversationId);
    }, 200);
  }
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        groupedConversations,
        handleConversationClicked,
        startNewChat,
        handleSummariesClicked,
        disableNewChat,
        disableNewChatTooltip,
        showConversationHistory,
      }
    : {},
);
</script>

<template>
  <div
    v-if="showConversationHistory"
    class="mt-[--space-16] flex h-[98%] flex-col rounded-2xl bg-white bg-opacity-60 pl-3 pr-1 pt-3"
    :class="{
      'w-[--width-320] max-w-[260px]': showConversationHistory,
      'w-0': !showConversationHistory,
    }"
  >
    <Button
      v-tooltip="disableNewChatTooltip"
      dismiss
      data-cy="start-new-chat"
      class="new-chat-button mr-5 p-1 py-2 text-[--text-link] has-[:hover]:rounded-lg has-[:hover]:bg-white/75"
      :disabled="disableNewChat"
      @click="startNewChat"
    >
      <div class="flex w-fit items-center gap-2 px-2 py-1">
        <Icon class="add" name="newChat" color="var(--text-link)" medium />
        <span class="text-small-medium">New Chat</span>
      </div>
    </Button>
    <div
      id="conversation-sidebar"
      class="conversation-sidebar"
      :class="{ 'scrollbar-background-color': showScrollbar }"
      @mouseenter="(e) => (showScrollbar = true)"
      @mouseleave="(e) => (showScrollbar = false)"
    >
      <div class="conversation-container mr-2 mt-4 flex flex-col gap-2 pb-8">
        <button
          class="conversation-background relative px-3 py-2 text-left text-sm hover:cursor-default hover:rounded-lg hover:bg-white hover:font-bold"
          :class="{
            'conversation-background cursor-pointer rounded-lg bg-white font-bold':
              visionAiConversationsStore.summariesSelected,
            'hover:cursor-pointer': !visionAiConversationsStore.summariesSelected,
          }"
          data-cy="summaries-sidebar-button"
          :disabled="visionAiConversationsStore.summariesSelected"
          @click="handleSummariesClicked"
          @mouseover="isSummariesHovered = true"
          @mouseleave="isSummariesHovered = false"
        >
          <span> Summaries </span>
          <ConversationsSettingsDropdown
            data-cy="summaries-settings-dropdown"
            :show="isSummariesHovered"
            :conversation-container-height="conversationContainerHeight"
            is-summary
          />
        </button>
      </div>
      <div class="pb-8">
        <span class="text-small-bold text-primary mt-4 pb-6 pl-3">Chat History</span>
        <div
          v-for="groupedConversation in groupedConversations"
          :key="groupedConversation.date"
          class="conversation-container mr-2 flex flex-col gap-2 pt-4"
        >
          <span class="text-extra-extra-small-bold text-primary pl-3">{{
            groupedConversation.date
          }}</span>
          <button
            v-for="conversation in groupedConversation.conversations"
            :id="`conversation-button-id-${conversation.id}`"
            :key="conversation.id"
            class="conversation-name conversations-font-style relative px-3 py-2"
            :class="{
              'conversation-background rounded-lg bg-white font-bold hover:cursor-default':
                conversation.id === selectedConversationId &&
                !visionAiConversationsStore.summariesSelected,
              'hover:cursor-pointer': conversation.id !== selectedConversationId,
            }"
            data-cy="conversation-sidebar-button"
            :disabled="conversation.id === selectedConversationId"
            @click="handleConversationClicked(conversation.id)"
            @mouseover="handleMouseEnter(conversation.id)"
            @mouseleave="hoveredConversationId = null"
          >
            <span
              class="truncated hover:text-[--text-link]"
              :class="{
                'hover:text-[--text-primary]': conversation.id === selectedConversationId,
              }"
              >{{ conversation.name }}</span
            >
            <ConversationsSettingsDropdown
              class="conversation-settings-background"
              data-cy="conversation-settings-dropdown"
              :show="hoveredConversationId === conversation.id"
              :conversation-id="conversation.id"
              :selected-conversation-id="selectedConversationId"
              :conversation-container-height="conversationContainerHeight"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.conversation-sidebar {
  position: relative;
  box-sizing: border-box;
  overflow: hidden scroll;
  transition: width linear 0.2s;
  mask-image: linear-gradient(to top, black 97%, transparent 100%),
    linear-gradient(to bottom, black 93%, transparent 100%);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  mask-composite: intersect;

  &::-webkit-scrollbar {
    width: var(--space-10);
    height: var(--space-8);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--round-corner);
    background-clip: content-box;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
  }

  &.scrollbar-background-color {
    &::-webkit-scrollbar-thumb {
      background-color: rgb(var(--scrollbar-background) / 100%);
    }
  }

  &:not(.scrollbar-background-color) {
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    margin-bottom: var(--space-36);
    margin-top: var(--space-12);
  }

  .truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0.063rem;
  }

  .conversation-background {
    &:hover {
      background: var(--conversation-gradient);
    }
  }

  .conversation-settings-background {
    background: var(--conversation-settings-gradient);
  }
}
</style>
