<template>
  <header class="top-bar">
    <div class="top-bar-left">
      <div
        v-if="!hideSideMenuToggle"
        class="top-bar-toggle side-menu-toggle"
        data-cy="SideMenuToggle"
        @click.prevent="layoutStore.toggleSideMenu"
      >
        <button>
          <Icon v-if="layoutStore.isSideMenuVisible" name="caret" xsmall class="side-menu-open" />
          <Icon v-else name="menu" xsmall class="side-menu-closed" />
        </button>
      </div>
      <router-link :to="landingPageRoute" class="dh-logo-link">
        <img class="dh-logo" :src="IMG.logoDhDark" alt="Dash Hudson" />
      </router-link>
      <ProductNav ref="productNav" class="product-tabs" @nav-changed="checkProductNavScrolling" />
    </div>

    <div class="top-bar-right" :class="{ scrolling: isProductNavScrolling }">
      <div v-if="impersonatorStore.isImpersonating" class="impersonating-label">
        <span>DH User</span>
      </div>
      <VisionIcon v-if="canAccessVision" />
      <div class="top-bar-toggle right">
        <div class="right-menu-buttons-container">
          <NotificationsPanel />
          <ResourceCenterLink />
          <UserMenu />
          <BrandSwitcher class="top-nav-brand-switcher" />
        </div>
        <div>
          <transition name="slide">
            <ul
              v-if="userDropdownToggled"
              v-on-click-outside="closeUserDropdown"
              class="user-dropdown"
              role="menu"
              @click.capture="closeUserDropdown"
            >
              <li ref="email" class="email">{{ identity.email }}</li>
              <template v-for="item in userDropdownItems" :key="item.class">
                <li
                  v-tooltip="item.tooltip"
                  :class="[item.class, { disabled: item.disabled }]"
                  :data-cy="item['data-cy']"
                  @click="mixpanelEvent(item)"
                >
                  <template v-if="item.disabled">
                    {{ item.text }}
                  </template>
                  <a v-else-if="item.externalTo" :href="item.externalTo" target="_blank">
                    {{ item.text }}
                  </a>
                  <router-link v-else :to="item.to">
                    {{ item.text }}
                  </router-link>
                </li>
              </template>
            </ul>
          </transition>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { env } from '@/env';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import ProductNav from '@/components/layout/ProductNav.vue';
import NotificationsPanel from '@/components/layout/NotificationsPanel.vue';
import BrandSwitcher from '@/components/layout/BrandSwitcher.vue';
import UserMenu from '@/components/layout/UserMenu.vue';
import { useFlagStore } from '@/stores/flag';
import { useLayoutStore } from '@/stores/layout';
import VisionIcon from '@/components/layout/VisionIcon.vue';
import ResourceCenterLink from '@/components/layout/ResourceCenterLink.vue';
import { BRAND } from '@/models/auth/permissions.enum';
import { useImpersonatorStore } from '@/stores/impersonator';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Nav',
  components: {
    BrandSwitcher,
    Icon,
    ProductNav,
    NotificationsPanel,
    UserMenu,
    VisionIcon,
    ResourceCenterLink,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  data() {
    return {
      flagsReady: false,
      userDropdownToggled: false,
      iconColor: colours.ICON.ICON_PRIMARY,
      accountPanelVisible: false,
      isProductNavScrolling: false,
    };
  },
  computed: {
    ...mapStores(useFlagStore, useLayoutStore, useTrackingStore, useImpersonatorStore),
    ...mapPiniaState(useAuthStore, ['identity', 'guard', 'currentBrandLabel']),
    IMG() {
      return IMG;
    },
    canAccessVision() {
      return this.guard(BRAND.VISION.CAN_ACCESS_VISION);
    },
    hideSideMenuToggle() {
      const alwaysShow = ['/settings/', '/dashboards/'];
      return (
        this.$route?.path === '/' ||
        alwaysShow.some((pathPrefix) => this.$route.path.includes(pathPrefix))
      );
    },
    zendeskResourceCenterTooltip() {
      if (env.zendeskResourceCenterUrl) {
        return null;
      }
      return 'Resource Center is not available in this environment.';
    },
    userDropdownItems() {
      const enabled = Boolean(env.zendeskResourceCenterUrl);
      return [
        {
          class: 'resource-center',
          'data-cy': 'Nav-resource-center',
          disabled: !enabled,
          text: 'Resource Center',
          externalTo: env.zendeskResourceCenterUrl,
          tooltip: this.zendeskResourceCenterTooltip,
        },
        {
          class: 'settings',
          'data-cy': 'Nav-settings',
          text: 'Settings',
          to: {
            name: 'settings.profile',
            params: { brandLabel: this.currentBrandLabel },
          },
        },
        {
          class: 'logout',
          'data-cy': 'Nav-logout',
          text: 'Logout',
          to: { name: 'auth.logout' },
        },
      ];
    },
    landingPageRoute() {
      return {
        name: 'landingPage',
      };
    },
  },
  mounted() {
    this.checkProductNavScrolling();
    window.addEventListener('resize', this.checkProductNavScrolling);
  },
  unmounted() {
    window.addEventListener('resize', this.checkProductNavScrolling);
  },
  methods: {
    mixpanelEvent(userDropdownItem) {
      this.trackingStore.track('Navigation Heading Selected', {
        itemSelected: 'Person_Icon',
        secondary_item_selected: userDropdownItem?.text ?? null,
        page: this.$route.path,
      });
    },
    checkProductNavScrolling() {
      nextTick(() => {
        const elem = this.$refs?.productNav?.$el;
        if (elem) {
          this.isProductNavScrolling = elem.scrollWidth > elem.clientWidth;
        }
      });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
header.top-bar {
  background: var(--background-0);
  height: 3.125rem;
  width: 100%;
  box-shadow: var(--shadow-1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-nav);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 0.4rem;
  font-weight: var(--font-medium);

  .top-bar-left {
    display: flex;
    width: auto;
    overflow: hidden;
  }

  .right-menu-buttons-container {
    display: flex;
    height: 100%;
  }

  .dh-logo-link {
    display: flex;
  }

  .dh-logo {
    height: 0.8rem;
    margin: auto var(--space-32) auto var(--space-16);
    justify-content: center;
  }

  .product-tabs {
    overflow-x: auto;
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  .top-bar-toggle {
    position: relative;
    width: 2.75rem;
    height: 100%;

    &.right {
      width: auto;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      line-height: 2.75rem;
      cursor: pointer;
      background: transparent;
      border: none;
      opacity: 0.6;
      transition: opacity 0.3s;
    }

    button:hover {
      opacity: 1;
    }
  }

  .side-menu-toggle {
    display: none;

    .svg-icon {
      transition: var(--transition-all);

      &.side-menu-open {
        transform: rotate(180deg);
      }

      &.side-menu-closed {
        transform: rotate(0deg);
      }
    }
  }

  .top-bar-right {
    display: flex;
    justify-content: center;

    &.scrolling {
      box-shadow: -5px 0 15px 0 rgb(0 0 0 / 6%);
    }

    .top-nav-brand-switcher {
      padding-left: var(--space-24);
    }

    .impersonating-label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--x14);
    }
  }
}

@media (max-width: 75rem) {
  header.top-bar .side-menu-toggle {
    display: inline-block;
  }
}
</style>

<style lang="postcss">
header.top-bar .top-bar-right {
  .scrollbar-padding & {
    margin: 0 var(--space-10) 0 0;
  }
}
</style>
