import { useFacebookStore } from '@/stores/facebook';
import { computed, unref } from 'vue';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useIdentityStore } from '@/stores/identity';

export function useMetaAdsAccountDetails() {
  const facebookStore = useFacebookStore();
  const identityStore = useIdentityStore();

  const adAccountsList = computed(() => {
    return facebookStore.adAccountsList?.map((adAccount) => {
      return {
        sourceAdAccountId: adAccount.accountId,
        name: adAccount.accountName,
        ...adAccount,
      };
    });
  });

  const adCampaigns = computed(() => facebookStore.marketingV2Campaigns);

  const adCampaignsList = computed(() => {
    if (!facebookStore.marketingV2Campaigns) return [];
    return Object.values(facebookStore.marketingV2Campaigns);
  });

  const adAccountToCurrency = computed(() => {
    return facebookStore.metaAdsAccountToCurrency;
  });

  const adAccountsLoading = computed(() => facebookStore.adAccountsLoading);
  const adCampaignsLoading = computed(() => facebookStore.pending.marketingV2Campaigns);

  async function getAdAccounts({ brandIds }) {
    await facebookStore.getAdAccounts({ brandIds: unref(brandIds) });
  }

  async function getAdCampaigns({ brandIds, adAccountIds, sourceCampaignIds }) {
    await facebookStore.getMarketingV2Campaigns({
      adAccountIds: unref(adAccountIds),
      brandIds: unref(brandIds),
      sourceCampaignIds: unref(sourceCampaignIds),
    });
  }

  function brandHasPermission(brand) {
    return identityStore.guard(BRAND.ADS.CAN_ACCESS_ADS, brand);
  }

  function userHasPermission(brand) {
    return identityStore.guard(USER.ADS.CAN_ACCESS_ADS, brand);
  }

  function userAndBrandHavePermission(brand) {
    return brandHasPermission(brand) && userHasPermission(brand);
  }

  return {
    adAccountsList,
    adAccountsLoading,
    adCampaigns,
    adCampaignsList,
    adCampaignsLoading,
    adAccountToCurrency,
    getAdAccounts,
    getAdCampaigns,
    brandHasPermission,
    userHasPermission,
    userAndBrandHavePermission,
  };
}
