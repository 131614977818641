<template>
  <main class="insights">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon :name="platformIcon" />
        {{ headerTitle }}
        <InfoTooltip v-if="headerTooltip" :tooltip="headerTooltip" class="insights-tooltip" />
        <Icon
          v-if="hasPaidMetrics"
          class="icon-boosted"
          name="boosted"
          :color="colours.BRAND.BRAND_ACCENT"
        />
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <CollapsibleContainer class="insights-container" :open="dropdownOpen">
      <slot name="dropdownContents" />
    </CollapsibleContainer>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Icon from '@/components/foundation/Icon.vue';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'InsightsDropdown',
  components: { Icon, InfoTooltip, CollapsibleContainer },
  props: {
    hasInsightsAccess: { type: Boolean, required: true },
    headerTitle: { type: String, required: true },
    headerTooltip: { type: String, default: null },
    platformIcon: { type: String, required: true },
    hasPaidMetrics: { type: Boolean, default: false },
    hasNegativeOrganicMetric: { type: Boolean, default: false },
  },
  emits: ['toggleInsights'],
  data() {
    return {
      dropdownOpen: true,
    };
  },
  computed: {
    colours() {
      return colours;
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.$emit('toggleInsights', this.dropdownOpen);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
    }

    .svg-icon {
      margin-right: var(--space-8);
    }

    .insights-tooltip {
      margin: 0;
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .disable-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: inline-block;
    width: 20rem;
    background-color: rgb(255 255 255 / 70%);
    box-shadow: var(--shadow-1);
    border-radius: var(--round-corner-small);
    padding: var(--space-16);

    .svg-icon {
      margin-bottom: var(--space-4);
    }

    p {
      font-size: var(--x14);
      line-height: var(--space-16);
    }

    a {
      color: var(--action-500);
      font-weight: var(--font-medium);
      cursor: pointer;
    }
  }

  :deep(.insights-list) {
    .alert-message {
      margin: var(--space-16) 0 0 var(--space-32);
      background: var(--background-400);
      border-radius: var(--round-corner-small);
      padding: var(--space-16);
      font-size: var(--x14);
      text-align: left;
    }

    .message {
      text-align: left;
      margin: var(--space-8) 0 0 var(--space-32);
      padding-left: var(--space-4);
    }

    ul {
      margin-left: var(--space-32);
      padding-left: var(--space-4);

      li {
        display: flex;
        justify-content: space-between;
        margin: var(--space-8) 0;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: flex;
          align-items: center;

          svg {
            margin-left: var(--space-8);
          }
        }

        .stat {
          color: var(--action-500);
        }

        .url {
          text-decoration: underline;
        }

        .link-wrapper {
          color: var(--action-500);
          display: block;
          margin-top: var(--space-8);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .link-wrapper a {
          color: var(--action-500);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .insights-desc li {
      display: flex;
      margin: var(--space-12) 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      span {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        color: var(--text-secondary);
        white-space: normal;
        margin-top: var(--space-8);
      }
    }

    .insights-stats {
      padding-top: var(--space-16);

      li::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: '--------------------------------------------------------------------------------';
        color: var(--border);
      }

      .stat-name {
        padding-right: var(--space-8);
      }

      li {
        overflow: hidden;

        span + span {
          float: right;
          padding-left: var(--space-8);
          background: var(--background-300);
        }

        span:first-child {
          position: absolute;
          background: var(--background-300);
        }
      }
    }
  }

  :deep(.hide) {
    display: none;
  }
}
</style>
