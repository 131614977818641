/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'photo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.414 1.001H5c-1.103 0-2 .897-2 2v18c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5.587l-4.586-4.586zM5 21.001v-18h10v4h4l.001 14H5z"/><path pid="1" d="M12 16.002l-2-2-3 4h10l-3-6z"/><circle pid="2" cx="10" cy="11.002" r="2"/>'
  }
})
