/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'radioButtonOn': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="10" _fill="#4990E2"/><circle pid="1" cx="10" cy="10" r="3.333" _fill="#fff"/>'
  }
})
