<template>
  <VDropdown
    placement="bottom-end"
    :triggers="[]"
    theme="dh-dropdown-full"
    distance="2"
    :shown="userDropdownToggled"
    class="user-menu"
    @auto-hide="userDropdownToggled = false"
  >
    <div class="user-icon" data-cy="Nav-profile" @click="toggleUserDropdown">
      <Icon name="user" xsmall :color="colours.ICON.ICON_PRIMARY" />
    </div>
    <template #popper>
      <ul class="user-dropdown" role="menu" @click.capture="closeUserDropdown">
        <li ref="email" class="email">
          <div class="user-row">
            <div class="user-row-avatar">
              <UserAvatar :user="authStore.identity" xsmall />
            </div>
            <div>
              <div class="text-small">{{ fullName }}</div>
              <div v-tooltip="userEmailTooltip" class="text-extra-small text-secondary">
                {{ formattedUserEmail }}
              </div>
            </div>
          </div>
        </li>
        <li v-if="showAdditionalTrialsInfo" class="trial-info text-xs text-[--text-primary]">
          <TrialInfoPanel />
        </li>
        <li v-else-if="showTrialDaysLeft && isPLGUserOnTrial" class="trial-info">
          <div class="trial-info-box">
            <div v-if="trialDaysLeft > 0">
              <p class="trial-days-info" :class="{ 'mb-2': isAdmin }">
                {{ trialDaysLeftCopy }}
              </p>
              <p
                v-if="isAdmin"
                ref="purchaseLink"
                data-cy="PurchaseLink"
                class="link"
                @click="planSelected"
              >
                Subscribe Now
                <InlineLoader
                  :color="colours.ACTION.ACTION_500"
                  :loading="customerJourneyStore.pending.purchaseSubscription"
                />
              </p>
            </div>
            <div v-else>
              <p class="trial-expired-info">
                Your Grow free trial has ended.
                <span
                  v-if="isAdmin"
                  ref="purchaseLink"
                  data-cy="PurchaseLink"
                  class="link"
                  @click="planSelected"
                  >Subscribe Now
                </span>
                <span v-if="isAdmin">or </span>
                <span class="link" @click="contactUsClicked">Contact Us</span>
                to reactivate your account.
                <InlineLoader
                  :color="colours.ACTION.ACTION_500"
                  :loading="customerJourneyStore.pending.purchaseSubscription"
                />
              </p>
            </div>
          </div>
        </li>
        <template v-for="item in userDropdownItems" :key="item.class">
          <li
            v-tooltip="item.tooltip"
            :class="[item.class, { disabled: item.disabled }]"
            :data-cy="item['data-cy']"
            @click="onMenuItemClick(item)"
          >
            <div class="flex flex-row items-center">
              <div class="menu-icon">
                <Icon v-if="item.iconName" xsmall :name="item.iconName" />
              </div>
              <div>
                {{ item.text }}
              </div>
            </div>
          </li>
        </template>
      </ul>
    </template>
  </VDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import dayjs from 'dayjs';
import { useTrackingStore } from '@/stores/tracking';
import InlineLoader from '@/components/Loaders/InlineLoader.vue';
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { getPricePlan, PRICE_PLANS } from '@/utils/stripeProduct';
import { useSupportChatStore } from '@/stores/support-chat';
import { trackSubscribeNowButtonAction } from '@/app/settings/mixpanel';
import TrialInfoPanel from '@/components/layout/TrialInfoPanel.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Icon,
    InlineLoader,
    UserAvatar,
    TrialInfoPanel,
  },
  data() {
    return {
      userDropdownToggled: false,
      maxUserEmailLength: 24,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useCustomerJourneyStore,
      useSupportChatStore,
      useTrackingStore,
      useFlagStore,
      useChiliPiperStore,
    ),
    hasInAppTrialsFlag() {
      return this.flagStore.ready && this.flagStore.flags.inAppTrials;
    },
    showPlanTrial() {
      return (
        this.customerJourneyStore.additionalPlanTrial?.trialEndDate &&
        this.trialActiveOrExpiredForTwoDays(this.customerJourneyStore.additionalPlanTrial)
      );
    },
    showPremiumAnalyticsTrial() {
      return (
        this.customerJourneyStore.additionalPremiumAnalyticsTrial?.trialEndDate &&
        this.trialActiveOrExpiredForTwoDays(
          this.customerJourneyStore.additionalPremiumAnalyticsTrial,
        )
      );
    },
    showSocialListeningTrial() {
      return (
        this.customerJourneyStore.socialListeningTrial?.trialEndDate &&
        this.trialActiveOrExpiredForTwoDays(this.customerJourneyStore.socialListeningTrial)
      );
    },
    showAdditionalTrialsInfo() {
      return (
        this.hasInAppTrialsFlag &&
        (this.showPlanTrial || this.showPremiumAnalyticsTrial || this.showSocialListeningTrial)
      );
    },
    colours() {
      return colours;
    },
    userDropdownItems() {
      const dropDownItems = [
        {
          iconName: 'settings',
          class: 'settings',
          'data-cy': 'Nav-settings',
          text: 'Settings',
          to: {
            name: 'settings.profile',
            params: { brandLabel: this.authStore.currentBrandLabel },
          },
        },
        {
          iconName: 'logout',
          class: 'logout',
          'data-cy': 'Nav-logout',
          text: 'Log Out',
          to: { name: 'auth.logout' },
        },
      ];
      if (this.isAdmin) {
        return [
          {
            iconName: 'payment-card',
            class: 'payment-card',
            'data-cy': 'Nav-payment-card',
            text: 'Billing and Plan',
            to: {
              name: 'settings.billing',
              params: { brandLabel: this.authStore.currentBrandLabel },
            },
          },
          ...dropDownItems,
        ];
      }
      return dropDownItems;
    },
    fullName() {
      let name = '';
      if (this.authStore.identity?.first_name) {
        name += this.authStore.identity.first_name;
      }
      if (this.authStore.identity?.last_name) {
        if (name.length > 0) {
          name += ' ';
        }
        name += this.authStore.identity.last_name;
      }
      return name;
    },
    userEmail() {
      return this.authStore.identity?.email ?? '';
    },
    formattedUserEmail() {
      if (this.userEmail.length <= this.maxUserEmailLength) {
        return this.userEmail;
      }
      return `${this.userEmail.slice(0, this.maxUserEmailLength)}...`;
    },
    userEmailTooltip() {
      if (this.userEmail.length > this.maxUserEmailLength) {
        return this.userEmail;
      }
      return null;
    },
    isPLGUserOnTrial() {
      return this.customerJourneyStore.isProductLedGrowTrial;
    },
    showTrialDaysLeft() {
      return !!this.authStore.currentBrand?.plan?.trial_end_date;
    },
    trialDaysLeft() {
      const trialEndDate = dayjs(this.authStore.currentBrand.plan.trial_end_date);
      const today = dayjs();
      return Math.ceil(trialEndDate.diff(today, 'days', true));
    },
    trialDaysLeftCopy() {
      return this.trialDaysLeft > 1
        ? `You have ${this.trialDaysLeft} days remaining in your Grow free trial.`
        : 'This is the last day of your Grow free trial.';
    },
    isAdmin() {
      return this.authStore.isCurrentBrandRoleAdmin;
    },
  },
  methods: {
    trialActiveOrExpiredForTwoDays(trial) {
      const endDate = dayjs(trial.trialEndDate).add(2, 'days');
      const today = dayjs();
      return endDate >= today;
    },
    getTrialDaysLeft(trial) {
      const trialEndDate = dayjs(trial?.trialEndDate);
      const today = dayjs();
      return Math.ceil(trialEndDate.diff(today, 'days', true));
    },
    toggleUserDropdown() {
      this.userDropdownToggled = !this.userDropdownToggled;
      this.mixpanelEvent();
    },
    closeUserDropdown(e) {
      if (Array.from(e.target.classList).includes('disabled')) {
        e.stopPropagation();
        return;
      }
      if (e.target === this.$refs.email) {
        return;
      }
      if (e.target === this.$refs.purchaseLink) {
        return;
      }
      this.userDropdownToggled = false;
    },
    mixpanelEvent(userDropdownItem) {
      this.trackingStore.track('Navigation Heading Selected', {
        itemSelected: 'Person_Icon',
        secondary_item_selected: userDropdownItem?.text ?? null,
        page: this.$route.path,
      });
    },
    getUseDropdownItemBinds(item) {
      return {
        class: { disabled: item.disabled },
        'data-cy': item['data-cy'],
        href: item.to,
      };
    },
    onMenuItemClick(item) {
      if (item) {
        if (item.disabled) {
          return;
        }
        if (item.externalTo) {
          window.open(item.externalTo, '_blank');
        } else if (item.to) {
          this.$router.push(item.to);
        }
        this.mixpanelEvent(item);
      }
    },
    planSelected() {
      const plan = getPricePlan(PRICE_PLANS.GROW_YEARLY);
      trackSubscribeNowButtonAction({
        lookupKey: plan.lookupKey,
        buttonName: 'Subscribe Now',
      });
      this.customerJourneyStore.purchaseSubscription(plan.lookupKey);
    },
    contactUsClicked() {
      this.supportChatStore.show();
    },
  },
});
export default comp;
</script>
<style lang="postcss" scoped>
.user-menu {
  display: flex;
  align-items: center;
}

.user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  width: 36px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.user-dropdown {
  padding: var(--space-12) var(--space-24);

  .user-row {
    display: flex;

    .user-row-avatar {
      display: flex;
      align-items: center;
      margin-right: var(--space-8);
    }
  }

  .menu-icon {
    min-width: var(--space-24);
    margin-right: var(--space-8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > li {
    padding: var(--space-8) var(--space-12);
    line-height: 1.5em;
    cursor: pointer;

    &.disabled {
      color: var(--text-secondary);
    }

    &.email {
      border-bottom: 1px solid var(--border);
      padding: var(--space-16) 0;
      cursor: auto;
    }

    &.trial-info {
      cursor: auto;
      padding: var(--space-16) 0 var(--space-8) 0;

      .trial-info-box {
        background: var(--action-100);
        width: var(--space-214);
        padding: var(--space-16) var(--space-16) var(--space-12) var(--space-16);

        p {
          font-size: var(--x12);
          line-height: var(--x15);
        }

        .trial-days-info {
          color: var(--text-primary);
        }

        .trial-expired-info {
          color: var(--text-primary);
          margin-bottom: var(--space-4);
        }

        .link {
          color: var(--action-500);
          cursor: pointer;
        }
      }
    }

    &:nth-child(2) {
      padding-top: var(--space-16);
    }

    &:last-child {
      padding-bottom: var(--space-16);
    }
  }
}
</style>
