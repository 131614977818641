export const URL_REGEX =
  /(?<!@)(?:https?:\/\/|www.)[-a-zA-Z0-9()@:%_+.~#&/=<>]+(?:\?[-a-zA-Z0-9()@:%_+.~#?&/=<>,]+)?[-a-zA-Z0-9()@:%_+.~#&/=<>]+/gi;

// values mirror content of `library-backend`:
//   `library_backend/models/utm_settings.py`
export const utmChannel = Object.freeze({
  TIKTOK_LIKESHOP: 'likeshop_tiktok',
  INSTAGRAM_LIKESHOP: 'likeshop_instagram',
  INSTAGRAM_STORY: 'instagram_story',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TWITTER: 'twitter',
  SHOPPABLE_GALLERIES: 'shoppable_gallery',
  PRODUCT_PAGE_GALLERIES: 'product_page_gallery',
});

export const utmScheduleEditTrackingTitle = 'Edit UTM Tracking';
export const utmScheduleOpenEditTrackingButton = 'Edit UTM Tracking';

export const utmManagementNoBrandParameters = Object.freeze({
  message: 'Add a UTM parameter below to start tracking.',
});

export const utmValueCategoryTooltips = Object.freeze({
  preset:
    'A custom list of set parameter values to customize UTMs when publishing within Scheduler.',
  dynamic:
    'Dynamic values will automatically update to match the specifics of the content being published.',
});

const postDateDynamicValue = Object.freeze({
  value: '<post_date:%d%m%y>',
  text: 'Post Date (d/m/y)',
});

const mediaUrlDynamicValue = Object.freeze({
  value: '<media_url>',
  text: 'Media URL',
});

export const baseUtmDynamicValues = Object.freeze([
  {
    value: '<product_name>',
    text: 'Product Name',
  },
  {
    value: '<product_url>',
    text: 'Product URL',
  },
  {
    value: '<product_sku>',
    text: 'Product SKU',
  },
  mediaUrlDynamicValue,
  postDateDynamicValue,
]);

export const twitterUtmDynamicValues = Object.freeze([mediaUrlDynamicValue, postDateDynamicValue]);

export const facebookUtmDynamicValues = Object.freeze([mediaUrlDynamicValue, postDateDynamicValue]);

export const ppgUtmDynamicValues = Object.freeze([
  ...baseUtmDynamicValues,
  {
    value: '<product_id>',
    text: 'Product ID',
  },
]);

export const sgUtmDynamicValues = Object.freeze([
  ...baseUtmDynamicValues,
  {
    value: '<gallery_name>',
    text: 'Gallery Name',
  },
  {
    value: '<gallery_id>',
    text: 'Gallery ID',
  },
]);

// if channel is missing take base values
export const channelToUtmDynamicValues = Object.freeze({
  [utmChannel.TWITTER]: twitterUtmDynamicValues,
  [utmChannel.FACEBOOK]: facebookUtmDynamicValues,
  [utmChannel.PRODUCT_PAGE_GALLERIES]: ppgUtmDynamicValues,
  [utmChannel.SHOPPABLE_GALLERIES]: sgUtmDynamicValues,
});

export const utmOptions = Object.freeze([
  {
    value: utmChannel.TIKTOK_LIKESHOP,
    text: 'TikTok LikeShop',
  },
  {
    value: utmChannel.INSTAGRAM_LIKESHOP,
    text: 'Instagram LikeShop',
  },
  {
    value: utmChannel.INSTAGRAM_STORY,
    text: 'Instagram Stories',
  },
  {
    value: utmChannel.FACEBOOK,
    text: 'Facebook',
  },
  {
    value: utmChannel.PINTEREST,
    text: 'Pinterest',
  },
  {
    value: utmChannel.TWITTER,
    text: 'X',
  },
  {
    value: utmChannel.SHOPPABLE_GALLERIES,
    text: 'Shoppable Galleries',
  },
  {
    value: utmChannel.PRODUCT_PAGE_GALLERIES,
    text: 'Product Page Galleries',
  },
]);

export const utmEditPresetValueInfo =
  'Preset values are static options for UTM tracking when scheduling posts.';

export const utmEditPresetValueError = 'Unable to save changes, please try again later.';

export const iconNames = Object.freeze({
  [utmChannel.FACEBOOK]: 'facebook',
  [utmChannel.INSTAGRAM_LIKESHOP]: 'instagram',
  [utmChannel.TIKTOK_LIKESHOP]: 'tiktok',
  [utmChannel.TWITTER]: 'twitter',
  [utmChannel.PINTEREST]: 'pinterest',
  [utmChannel.INSTAGRAM_STORY]: 'instagramStories',
  [utmChannel.SHOPPABLE_GALLERIES]: '',
  [utmChannel.PRODUCT_PAGE_GALLERIES]: '',
});

export const decodeUtmErrorMessage = Object.freeze({
  title: 'Error Fetching Utm Settings',
  message:
    'Please retry loading this data. If you’re seeing this screen repeatedly, try again in a few minutes or contact your Account Manager.',
});

export const noUtmEmptyStateMessage = Object.freeze({
  message: 'No UTM parameters added. This can be updated in Settings.',
});

export const keyTooltips = Object.freeze({
  utm_source:
    'Identify the site, feature, etc. sending traffic to your property. This value should stay constant. (example: instagram, facebook, dash_hudson)',
  utm_medium:
    'The advertising or marketing medium. This value should stay constant. (example: social, organic_social, paid)',
  utm_campaign:
    'The individual campaign name, slogan, promo code, etc. (example: spring_campaign, new_product_launch)',
  utm_content:
    'Used to differentiate the content used or between links within the same post. (example: &lt;media_url&gt;, &lt;product_name&gt;)',
});

export const customValidatorNames = Object.freeze({
  VALIDATE_EXISTING_PARAMETER: 'validateExistingParameter',
  ALPHA_NUMERIC_WITH_SPECIAL: 'alphaNumericWithSpecial',
});

export const encodeUtmErrorMessage =
  'Something went wrong when saving the UTM values on this post. Please try again.';

export const utmSettingsNoAccessTooltip = 'Only admins can make changes to this page';

export const utmNotEditableTooltip = 'UTM editing can be enabled by an Admin in Settings.';
