<template>
  <div class="dh-card-content" :style="measurableStyles">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MeasurableMixin from '@/components/foundation/mixins/measurable.mixin';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  mixins: [MeasurableMixin],
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-card-content {
  padding: 0 var(--space-24);
}
</style>
