import enumTypes from '@/app/library/constants';
import { useFlagStore } from '@/stores/flag';
import { useAuthStore } from '@/stores/auth';
import { BRAND } from '@/models/auth/permissions.enum';

export const MEDIA_SUPPORT_CONTENT_TAG_TOOLTIP = `Content tagging is not supported for uploaded media.`;
export const NO_ACCESS_TO_CONTENT_TAG_TOOLTIP = `Content tags are only available to brands in the Enterprise plan`;

export function mediaSupportsContentTags(mediaItem) {
  const flagStore = useFlagStore();
  if (flagStore.ready && flagStore.flags.uploadedMediaContentTags) return true;
  return [].concat(mediaItem ?? [])?.every((media) => {
    if (media?.uploadStatus) return false;
    const mediaIsUploaded = (media?.source ?? media?.postType) === enumTypes.UPLOAD;
    return !mediaIsUploaded;
  });
}

export function canAccessContentTags() {
  const authStore = useAuthStore();
  return authStore.guard(BRAND.TAGS.CAN_ACCESS_CONTENT_TAGS);
}
