/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'action-hide': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.827 11.456c-.096-.148-1.522-2.282-4.1-3.979l3.97-3.971-1.415-1.414-19.47 19.472 1.413 1.414 5.146-5.146c1.093.341 2.298.554 3.618.554 6.804 0 10.667-5.585 10.828-5.824l.373-.548-.363-.558zm-10.839 4.93a9.873 9.873 0 01-1.968-.204l1.907-1.907c.252.069.515.111.79.111a3 3 0 003-3c0-.274-.042-.538-.11-.79l1.66-1.661a13.848 13.848 0 013.464 3.052c-1.087 1.29-4.182 4.399-8.743 4.399zm-5.93-1.897l-1.454 1.453c-2.164-1.535-3.356-3.251-3.442-3.38l-.373-.548.362-.558c.16-.248 4.022-6.07 10.838-6.07 1.007 0 1.941.137 2.815.356l-1.716 1.716a9.138 9.138 0 00-1.099-.072c-4.59 0-7.674 3.264-8.744 4.6a13.823 13.823 0 002.812 2.503z" _fill="#686a7a"/><mask id="svgicon_action-hide_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="2" width="24" height="21"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.827 11.456c-.096-.148-1.522-2.282-4.1-3.979l3.97-3.971-1.415-1.414-19.47 19.472 1.413 1.414 5.146-5.146c1.093.341 2.298.554 3.618.554 6.804 0 10.667-5.585 10.828-5.824l.373-.548-.363-.558zm-10.839 4.93a9.873 9.873 0 01-1.968-.204l1.907-1.907c.252.069.515.111.79.111a3 3 0 003-3c0-.274-.042-.538-.11-.79l1.66-1.661a13.848 13.848 0 013.464 3.052c-1.087 1.29-4.182 4.399-8.743 4.399zm-5.93-1.897l-1.454 1.453c-2.164-1.535-3.356-3.251-3.442-3.38l-.373-.548.362-.558c.16-.248 4.022-6.07 10.838-6.07 1.007 0 1.941.137 2.815.356l-1.716 1.716a9.138 9.138 0 00-1.099-.072c-4.59 0-7.674 3.264-8.744 4.6a13.823 13.823 0 002.812 2.503z" _fill="#fff"/></mask><g mask="url(#svgicon_action-hide_a)"><path pid="2" _fill="#686a7a" d="M0 0h26v26H0z"/></g>'
  }
})
