import humps from 'humps';
import { productFeedTypeEnum } from '@/app/library/constants';

export function productFeedType(product) {
  const normalizedProduct = humps.camelizeKeys(product);
  if (normalizedProduct?.productFeed?.feedType === productFeedTypeEnum.PRODUCT_CATALOG) {
    return 'Facebook';
  }
  if (normalizedProduct?.productFeed) {
    return 'Legacy Feed';
  }
  return null;
}
