import upperFirst from 'lodash/upperFirst';
import { contentRightsPostTypes } from '@/app/library/constants';
import { colours } from '@/ux/colours';

const ContentRightsMixins = {
  props: {
    contentRightsItem: {
      default: null,
    },
  },
  data() {
    return {
      selectedType: contentRightsPostTypes.ORIGINAL_TYPE,
      contentRightsTypes: {},
    };
  },
  methods: {
    getDropdownList(selectedType, withCheckIcon) {
      const approvedDropdownItem = {
        textColor: colours.SUCCESS.SUCCESS_500,
        text: upperFirst(contentRightsPostTypes.APPROVED.toLowerCase()),
        action: 'selectApproved',
      };
      const lapsedDropdownItem = {
        textColor: colours.TEXT.TEXT_SECONDARY,
        text: upperFirst(contentRightsPostTypes.LAPSED.toLowerCase()),
        action: 'selectLapsed',
      };
      const resetDropdownItem = {
        textColor: colours.TEXT.TEXT_PRIMARY,
        text: upperFirst(contentRightsPostTypes.RESET.toLowerCase()),
        action: 'selectReset',
      };
      const requestedDropdownItem = {
        textColor: colours.ALERT.ALERT_400,
        text: upperFirst(contentRightsPostTypes.REQUESTED.toLowerCase()),
        action: '',
      };
      const selectedItem = { check: true };
      if (selectedType === contentRightsPostTypes.ORIGINAL_TYPE) {
        this.contentRightsTypes = [approvedDropdownItem, lapsedDropdownItem];
      } else if (selectedType === contentRightsPostTypes.RESET) {
        Object.assign(selectedItem, resetDropdownItem);
        this.contentRightsTypes = [approvedDropdownItem, lapsedDropdownItem];
      } else if (selectedType === contentRightsPostTypes.APPROVED) {
        Object.assign(selectedItem, approvedDropdownItem);
        this.contentRightsTypes = [lapsedDropdownItem, resetDropdownItem];
      } else if (selectedType === contentRightsPostTypes.LAPSED) {
        Object.assign(selectedItem, lapsedDropdownItem);
        this.contentRightsTypes = [approvedDropdownItem, resetDropdownItem];
      } else if (selectedType === contentRightsPostTypes.REQUESTED) {
        Object.assign(selectedItem, requestedDropdownItem);
        this.contentRightsTypes = [approvedDropdownItem, lapsedDropdownItem, resetDropdownItem];
      }
      if (withCheckIcon) {
        this.contentRightsTypes.unshift(selectedItem);
      }
      return this.contentRightsTypes;
    },
  },
  computed: {
    contentRightsDropdownWithCheckIcon() {
      const type = (this.contentRightsItem && this.contentRightsItem.type) || this.selectedType;
      return this.getDropdownList(type, true);
    },
    contentRightsDropdown() {
      const type = (this.contentRightsItem && this.contentRightsItem.type) || this.selectedType;
      return this.getDropdownList(type);
    },
  },
};

export default ContentRightsMixins;
