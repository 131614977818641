import humps from 'humps';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

export function prepareBrandParamsForPagination(oldUrl, brandId) {
  if (!oldUrl) {
    return { url: null, brandId };
  }
  try {
    const urlObject = new URL(oldUrl);
    const params = new URLSearchParams(urlObject.search);

    // we need to remove url param, otherwise we ll create chain of long urls &url=someurl&url=oldUrl
    // and at some page we'll exceed limit of URL length
    params.delete('url');

    const url = `${urlObject.origin}${urlObject.pathname}?${params.toString()}`;
    // to not duplicate brandId param we need to delete if it exists in newUrl
    return params.has('brandId') ? { url } : { url, brandId };
  } catch (error) {
    return {
      url: oldUrl,
      brandId,
    };
  }
}

function filterNullAndUndefined(objectValue = {}) {
  return Object.fromEntries(
    Object.entries(objectValue).filter(([, value]) => value !== null && value !== undefined),
  );
}

/**
 * Takes a url string and converts the query parameters into an object.
 *
 * @param {*} value A url string to parse the query parameters from.
 * @returns An object representation of the query parameters.
 */
export function createQueryObject(value) {
  if (isString(value)) {
    const start = value.includes('?') ? value.indexOf('?') : 0;
    const end = value.includes('#') ? value.indexOf('#') : value.length;
    const queryString = value.substring(start, end);
    const urlSearchParams = new URLSearchParams(queryString);
    if (urlSearchParams) {
      return Object.fromEntries(urlSearchParams.entries());
    }
  }
  return {};
}

/**
 * Takes a URL string and creates a camelCase object of the query parameters.
 *
 * @param {String} url String representation of the url.
 * @param {Object} [queryDefaults={}] Object containing any query parameter defaults you'd like returned if not found in the url.
 * @param {Boolean} [excludeCamelCaseQueryObject=false] Will exclude the camelCase query object if true
 * @returns Object An object of query parameters, where props are converted to camelCase.
 */
export function parseQuery(url, queryDefaults = {}, excludeCamelCaseQueryObject = false) {
  // For now, we are keeping the camelCase and snake_case keys
  const snakeCaseQueryObject = createQueryObject(url);
  const queryObject = {
    ...queryDefaults,
    ...snakeCaseQueryObject,
  };
  if (excludeCamelCaseQueryObject) return queryObject;

  const camelCaseQueryObject = humps.camelizeKeys(snakeCaseQueryObject);
  return {
    ...queryObject,
    ...camelCaseQueryObject,
  };
}

/**
 * Removes null/undefined values and converts property keys to snake_case.
 *
 * @param  {...any} queryObjects Any camelCase query parameter objects.
 * @returns A snake_case version of the query parameters object with null/undefined values removed.
 */
export function prepareQueryObject(...queryObjects) {
  return filterNullAndUndefined(humps.decamelizeKeys(Object.assign({}, ...queryObjects)));
}

/**
 * Takes a query object and returns a query string representation.
 *
 * @param {Object} [query={}] query a formatted, or snakeCake query parameter object (pass camelCase object if formatQueryObject is true)
 * @param {Boolean} [formatQueryObject=true] decides if prepareQueryObject gets called on query
 * @returns {String} query string representation of the query object.
 */
export function stringifyQuery(query = {}, formatQueryObject = true) {
  const queryObject = formatQueryObject ? prepareQueryObject(query) : query;
  return Object.entries(queryObject)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
}

/**
 * Used by VueRouter.stringifyQuery for converting query objects into strings.
 *
 * @param {*} query camelCase query parameters object.
 * @returns query string to be prepended to url
 */
export function routerStringifyQuery(query) {
  if (isObject(query)) {
    const queryString = stringifyQuery(query);
    return queryString ? `${queryString}` : '';
  }
  if (isString(query)) {
    return query;
  }
  return '';
}

export function stringifyObjectValues(query) {
  return Object.keys(filterNullAndUndefined(query)).reduce((obj, key) => {
    return {
      ...obj,
      [key]: String(query[key]),
    };
  }, {});
}

/**
 * Take an existing URL and append some query params, overwriting any conflicting values.
 * @param {string} url URL to modify
 * @param {Object} params Keys & values to set as query params
 * @returns {string} The modified URL
 */
export function appendQueryParamsToUrl(url, params) {
  const parsedUrl = new URL(url);
  Object.keys(params).forEach((key) => {
    parsedUrl.searchParams.set(key, params[key]);
  });
  return parsedUrl.toString();
}
