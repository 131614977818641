/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'override-lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_2291_18033)" _fill="#686a7a"><path pid="0" d="M12.001 16a7 7 0 017-7 7 7 0 01.999.08V5.001c0-1.102-.897-2-2-2h-2v-3h-2v3H6v-3H4v3H2c-1.103 0-2 .898-2 2v13c0 1.103.897 2 2 2h10L12.001 16zM2 5.001h16v3H2v-3z"/><ellipse pid="1" cx="19" cy="20.425" rx="1.071" ry=".975"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M22.571 16.85v-2.6C22.571 12.458 20.97 11 19 11c-1.97 0-3.571 1.458-3.571 3.25v2.6c-.789 0-1.429.582-1.429 1.3v4.55c0 .717.64 1.3 1.429 1.3h7.142C23.36 24 24 23.417 24 22.7v-4.55c0-.718-.64-1.3-1.429-1.3zm-5.714-2.6c0-1.075.962-1.95 2.143-1.95 1.182 0 2.143.875 2.143 1.95v2.6h-4.286v-2.6zm-1.428 3.9v4.55h7.144l-.001-4.55h-7.143z"/></g><defs><clipPath id="clip0_2291_18033"><path pid="3" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
