<template>
  <div class="grid-view-container">
    <div class="top-container">
      <draggable
        v-model="mediaList"
        item-key="id"
        :options="{
          ghostClass: 'ghost',
          dragClass: 'drag',
        }"
        class="draggable-container"
        @start="draggingInProgress = true"
        @end="draggingInProgress = false"
      >
        <template #item="{ element, index }">
          <div
            :class="{
              half: mediaList.length >= 2 && mediaList.length <= 5,
              third: mediaList.length > 5,
            }"
            class="cell"
          >
            <Media
              v-model:playing="element.playing"
              :media="element.media"
              :class="borderClass(element)"
              draggable
              cover
              small
              show-media-only
              :validation-error="
                mediaWarningIds.includes(element.media.id) ? validationError : null
              "
            />
            <div :class="{ 'dragging-in-progress': draggingInProgress }" class="mask"></div>
            <div :class="{ 'dragging-in-progress': draggingInProgress }" class="media-buttons">
              <Button
                v-if="element.media.type === 'VIDEO'"
                v-tooltip="element.playing ? 'Pause Video' : 'Play Video'"
                :icon-color="buttonIconColor"
                :icon-hover-color="buttonIconHoverColor"
                :icon-name="element.playing ? 'videoControlPause' : 'controlPlay'"
                class="media-button"
                rectangular
                small
                @click.stop="element.playing = !element.playing"
              />
              <Button
                v-if="showCropperButton(element.media)"
                v-tooltip="'Edit Media'"
                :icon-color="buttonIconColor"
                :icon-hover-color="buttonIconHoverColor"
                icon-name="crop"
                rectangular
                data-cy="open-cropper"
                class="media-button"
                small
                @click.stop="cropClicked(element.media, index)"
              />
              <Button
                v-if="!disableEditing"
                ref="remove-media-button"
                v-tooltip="'Remove Media'"
                :icon-color="buttonIconColor"
                :icon-hover-color="buttonIconHoverColor"
                icon-name="bin"
                class="media-button remove-media-button"
                rectangular
                small
                @click.stop="removeItem(element.media)"
              />
              <Button
                v-if="showDownloadButton(element.media)"
                v-tooltip="'Download Media'"
                :icon-color="buttonIconColor"
                :icon-hover-color="buttonIconHoverColor"
                icon-name="download"
                class="media-button"
                rectangular
                small
                data-cy="download-media"
                :loading="!!downloadingMediaList.includes(element.media.id)"
                @click.stop="handleDownloadMedia(element.media)"
              />
            </div>
          </div>
        </template>
      </draggable>
      <ValidationBanner
        v-if="validationError"
        :level="validationError.level"
        :custom-icon="validationError.icon"
        class="validation-banner"
      >
        {{ validationError.message }}
      </ValidationBanner>
    </div>
    <div class="bottom-container">
      <DropdownButton
        v-if="!disableEditing && !instagramUserTaggerStore.enabled"
        v-tooltip="allowAdd ? 'Add Media' : 'You have reached the media limit.'"
        :dropdown-list="addDropdownList"
        :button-icon-color="buttonIconColor"
        :button-icon-hover-color="buttonIconHoverColor"
        :disabled="!allowAdd"
        align-right
        button-classes="rectangular"
        button-icon="add"
        data-cy="add-media-button"
        class="add-button"
        @dropdown-action-clicked="addActionClicked"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import isEqual from 'lodash/isEqual';
import { mapStores } from 'pinia';
import downloadMediaMixin from '@/mixins/downloadMediaMixin';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { mediaSizeIsCroppable } from '@/utils/media';
import ValidationBanner from '@/components/ValidationBanner.vue';
import { useFlagStore } from '@/stores/flag';
import { useCropperStore } from '@/stores/cropper';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import Media from './Media.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'GridViewer',
  components: {
    Button,
    DropdownButton,
    Media,
    draggable,
    ValidationBanner,
  },
  mixins: [downloadMediaMixin],
  props: {
    allowAdd: { type: Boolean, default: true },
    disableEditing: { type: Boolean, default: false },
    downloadMediaClicked: { type: Function, default: () => {} },
    value: { type: Array, required: true },
    enableCropping: { type: Boolean, default: false },
    cropClicked: { type: Function, default: () => {} },
    uploadClicked: { type: Function, required: false, default: null },
    libraryClicked: { type: Function, required: false, default: null },
    removeClicked: { type: Function, required: false, default: null },
    mediaWarningIds: { type: Array, default: () => [] },
    platform: { type: String, required: false, default: null },
    validationError: { type: Object, default: null },
  },
  emits: ['input'],
  data() {
    return {
      draggingInProgress: false,
      mediaList: [],
      paginationColor: colours.BACKGROUND.BACKGROUND_400,
      paginationActiveColor: colours.TEXT.TEXT_SECONDARY,
      buttonIconColor: colours.ICON.ICON_PRIMARY,
      buttonIconHoverColor: colours.ICON.ICON_LINK,
      mediaExplorer: DropdownButton,
      addDropdownList: [
        {
          text: 'From Library',
          action: 'libraryClicked',
        },
        {
          text: 'From Your Computer',
          action: 'uploadClicked',
        },
      ],
      showNewCropFeatureBadge: false,
      showNewCropFeatureBadgeVideos: false,
      downloadingMediaList: [],
    };
  },
  computed: {
    ...mapStores(useCropperStore, useFlagStore, useSchedulerStore, useInstagramUserTaggerStore),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        const playingById = {};
        this.mediaList.forEach((item) => {
          playingById[item.media.id] = item.playing;
        });
        const newMediaList = val.map((media) => ({
          media,
          playing: !!playingById[media.id],
        }));
        if (!isEqual(newMediaList, this.mediaList)) {
          this.mediaList = newMediaList;
        }
      },
    },
    mediaList: {
      deep: true,
      handler(val) {
        const newValue = val.map((item) => item.media);
        if (!isEqual(newValue, this.value)) {
          this.$emit('input', newValue);
        }
      },
    },
  },
  methods: {
    showCropperButton(media) {
      return (
        !this.disableEditing &&
        !this.isMediaUploading(media) &&
        this.schedulerStore.activeSubScreen !== 'tagPeople' &&
        mediaSizeIsCroppable(media) &&
        (!media.uploadStatus || media.uploadStatus === UPLOAD_STATUS.SUCCESS)
      );
    },
    showDownloadButton(media) {
      return (
        !this.isMediaUploading(media) &&
        (media.uploadStatus === UPLOAD_STATUS.SUCCESS || !media.uploadStatus)
      );
    },
    isMediaUploading(media) {
      return !!media.uploadStatus && !media.fullMediaObject;
    },
    addActionClicked(option) {
      this[option]();
    },
    removeItem(media) {
      this.cropperStore.removeAdjustLinksPrompt();
      this.cropperStore.removeAdjustTagsPrompt();
      this.removeClicked(media);
    },
    borderClass(item) {
      const level = this.validationError?.level || 'warning';
      return { [level]: this.mediaWarningIds.includes(item.media.id) };
    },
    async handleDownloadMedia(media) {
      if (this.downloadingMediaList.includes(media.id)) return;
      this.downloadingMediaList.push(media.id);
      this.downloadMediaClicked(media);
      const status = await this.downloadMedia(this.getDownloadableMediaUrl(media));
      if (status) {
        const index = this.downloadingMediaList.indexOf(media.id);
        this.downloadingMediaList.splice(index, 1);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.grid-view-container {
  margin: calc(-1 * var(--space-4));
  height: calc(100% + var(--space-8));
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .draggable-container {
    display: flex;
    flex-flow: row wrap;

    .cell {
      position: relative;
      width: 100%;
      padding: var(--space-4);
      border-radius: var(--round-corner-small);
      overflow: hidden;
      transform: translate(0, 0);

      .warning {
        border: 1.5px solid var(--alert-500);
      }

      .error {
        border: 1.5px solid var(--error-500);
      }

      &.half {
        width: 50%;
      }

      &.third {
        width: 33%;
      }

      .mask {
        position: absolute;
        inset: var(--space-4);
        cursor: pointer;
        border-radius: var(--round-corner-small);
        background-color: transparent;
        transition: background-color 0.2s ease;

        &.dragging-in-progress {
          display: none;
        }
      }

      &:hover .mask {
        background-color: rgb(0 0 0 / 50%);
      }

      &.drag .mask {
        display: none;
      }

      &.ghost {
        opacity: 0;
      }

      .media-buttons {
        position: absolute;
        opacity: 0;
        z-index: 10;
        top: var(--space-4);
        left: var(--space-4);
        display: flex;
        flex-wrap: wrap;
        padding: var(--space-3);
        transition: all 0.2s ease;

        .media-button {
          margin: var(--space-3);
          width: var(--space-32);
          height: var(--space-32);
        }

        &.dragging-in-progress {
          display: none;
        }
      }

      &:hover .media-buttons {
        opacity: 1;
      }

      &:active {
        .media-buttons {
          display: none;

          &:active {
            display: flex;
          }
        }
      }
    }
  }

  .validation-banner {
    margin: var(--space-16) 0;
  }

  .bottom-container {
    padding: var(--space-4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: sticky;
    bottom: var(--space-28);
  }
}
</style>
