/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'twitterDarkGrey': {
    width: 23,
    height: 20,
    viewBox: '0 0 23 20',
    data: '<path pid="0" d="M23 2.368a8.923 8.923 0 01-2.71.794A5.013 5.013 0 0022.366.37a9.069 9.069 0 01-2.998 1.223C18.51.613 17.281 0 15.923 0c-2.604 0-4.717 2.26-4.717 5.049 0 .396.04.78.122 1.15C7.406 5.988 3.93 3.979 1.6.922a5.315 5.315 0 00-.638 2.54c0 1.751.833 3.297 2.099 4.203a4.489 4.489 0 01-2.139-.63v.062c0 2.447 1.627 4.488 3.787 4.95a4.324 4.324 0 01-1.243.178c-.304 0-.6-.03-.888-.09.6 2.006 2.342 3.466 4.407 3.505-1.614 1.355-3.65 2.163-5.86 2.163-.38 0-.757-.023-1.126-.07C2.088 19.163 4.57 20 7.233 20c8.68 0 13.426-7.692 13.426-14.365 0-.22-.004-.439-.012-.654A9.945 9.945 0 0023 2.368" _fill="#B4B4B4" fill-rule="nonzero"/>'
  }
})
