/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'portrait': {
    width: 20,
    height: 30,
    viewBox: '0 0 20 30',
    data: '<path pid="0" d="M3 2a1 1 0 00-1 1v24a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1H3zm0-2h14a3 3 0 013 3v24a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3z" fill-rule="nonzero" _fill="#D3DDE6"/>'
  }
})
