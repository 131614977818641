import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.twitterApiUrl);
export const axiosCamelCase = createAxiosInstance(env.twitterApiUrl, {
  camelizeKeys: true,
});

export const fetchUsers = ({ handles, brandId }, axiosConfig = {}) =>
  axiosCamelCase.get(`/users`, {
    ...axiosConfig,
    params: { handles, brandId },
  });

export async function getTwitterAccount({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/account`, axiosConfig);
}

export async function getTwitterAccounts({ brandIds }, axiosConfig = {}) {
  return axios.get(
    `/brands/accounts`,
    merge(
      {
        params: {
          brandIds: brandIds.join(','),
        },
      },
      axiosConfig,
    ),
  );
}

export async function refreshTwitterAdsAccount({ brandId }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/account/refresh/ads_account`, axiosConfig);
}

export async function getFollowersCSV(
  { brandId, filename, startDate, endDate, scale },
  axiosConfig,
) {
  return axios.get(
    `/brands/${brandId}/exports/followers`,
    merge(
      {
        params: {
          filename,
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getMetricsCSV({ brandId, filename, startDate, endDate, scale }, axiosConfig) {
  return axios.get(
    `/brands/${brandId}/exports/metrics`,
    merge(
      {
        params: {
          filename,
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getAllTweetsCSV(
  { brandId, filename, startDate, endDate, scale },
  axiosConfig,
) {
  return axios.get(
    `/brands/${brandId}/exports/all_tweets`,
    merge(
      {
        params: {
          filename,
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getTopTweetsCSV(
  { brandId, filename, startDate, endDate, scale, sortOrder },
  axiosConfig,
) {
  return axios.get(
    `/brands/${brandId}/exports/top_tweets`,
    merge(
      {
        params: {
          filename,
          startDate,
          endDate,
          scale,
          sortOrder,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getAccountMetrics({ brandId, startDate, endDate, scale }, axiosConfig) {
  return axios.get(
    `/brands/${brandId}/metrics`,
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function addAccount({ handle }, axiosConfig = {}) {
  return axios.post(`/public_accounts/${handle}`, axiosConfig);
}

export async function getPublicAccountsByIds({ twitterUserIds }, axiosConfig = {}) {
  return axiosCamelCase.post('/public_accounts', { twitterUserIds }, axiosConfig);
}

export async function getLocations({ brandId, query }, axiosConfig = {}) {
  return axios.get(
    `/locations`,
    merge(
      {
        params: {
          brandId,
          query,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getTwitterSearchCounts(
  { topicQuery, bucket, fromDate, toDate },
  axiosConfig = {},
) {
  return axios.get(
    `/twitter_search/counts`,
    merge(
      {
        params: {
          topicQuery,
          bucket,
          fromDate,
          toDate,
        },
      },
      axiosConfig,
    ),
  );
}
