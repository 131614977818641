<script setup>
import { ref, toRefs } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import Chip from '@/components/foundation/Chip.vue';
import { useLazyList } from '@/components/foundation/composables/useLazyList';

const hoveredIndex = ref(-1);

const props = defineProps({
  /**
   * Text to display on the Chip
   */
  text: { type: String, required: true },
  /**
   * items to display on hover
   * format:
   * {
   *   text: <string>, // text to display on the item
   *   iconName: <string>, // name of the icon to display next to the text. If null, won't add icon
   *   action: <function>, // a function that will be called when the item is clicked, if null, won't be clickable
   * }
   */
  dropdownItems: { type: Array, required: true },
  /**
   * Props that will be passed into the Chip.vue component
   */
  chipProps: {
    type: Object,
    default: () => {
      return { xsmall: true, fontWeightNormal: true, small: false };
    },
  },
  /**
   * Where the dropdown should be positioned.
   * Possible values: https://floating-vue.starpad.dev/guide/component.html#placements
   */
  placement: { type: String, default: 'top-start' },
  /**
   * Flag to control lazy rendering of the list.
   * Helpful when list will contain hundreds/thousands of items
   */
  enableLazyList: { type: Boolean, default: false },
});
const propRefs = toRefs(props);

const { lazyItems, resetLimit, scrollableContainerProps } = useLazyList({
  list: propRefs.dropdownItems,
  disableLazyList: !propRefs.enableLazyList.value,
});

const getIconColor = (index) => {
  if (hoveredIndex.value === index) return colours.ACTION.ACTION_500;
  return null;
};

const shown = ref();
function mouseEnter() {
  shown.value = true;
}
function mouseLeave() {
  resetLimit();
  shown.value = false;
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <VMenu
    boundary="viewport"
    class="pill-container"
    theme="dh-hover-menu"
    :placement="props.placement"
    :hide-triggers="[]"
    :shown="shown"
  >
    <Chip
      v-bind="props.chipProps"
      data-cy="ChipText"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
      >{{ props.text }}</Chip
    >
    <template #popper>
      <ul
        v-bind="scrollableContainerProps"
        class="dropdown-list"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
      >
        <li
          v-for="(item, index) in lazyItems"
          :key="index"
          :class="['dropdown-item', { selectable: Boolean(item.action) }]"
          @click="item.action?.()"
          @mouseenter="hoveredIndex = index"
          @mouseleave="hoveredIndex = -1"
        >
          <slot name="item" :item="item" :dropdown-item="item">
            <span class="item-text">{{ item.text }}</span>
            <Icon
              v-if="item.iconName"
              class="item-icon"
              :name="item.iconName"
              xxsmall
              :color="getIconColor(index)"
            />
          </slot>
        </li>
      </ul>
    </template>
  </VMenu>
</template>

<style lang="postcss" scoped>
.dropdown-list {
  min-width: 8.75rem;
  max-height: 14.5rem;
  overflow-y: scroll;

  .dropdown-item {
    line-height: var(--x16);
    margin-bottom: var(--space-8);

    .item-text {
      color: var(--text-primary);
      font-size: var(--x12);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-icon {
      margin: 0 var(--space-20) 0 var(--space-8);
    }
  }

  .dropdown-item:last-child {
    margin-bottom: 0;
  }

  .selectable {
    cursor: pointer;
    font-weight: var(--font-medium);

    &:hover .item-text {
      color: var(--action-500);
    }
  }
}
</style>
