export function getEnv() {
  return import.meta.env;
}

export const env = {
  get mode() {
    return getEnv().MODE;
  },
  get isCI() {
    return getEnv().IS_CI === 'true';
  },
  get isProduction() {
    return getEnv().MODE === 'production';
  },
  get dashwebUrl() {
    return getEnv().VITE_DASHWEB_URL;
  },
  get likeShopUrl() {
    return getEnv().VITE_LIKESHOP_URL;
  },
  get tiktokLikeShopUrl() {
    return getEnv().VITE_TIKTOK_LIKESHOP_URL;
  },
  get reportOriginOverride() {
    return getEnv().VITE_REPORT_ORIGIN_OVERRIDE;
  },
  get authApiUrl() {
    return getEnv().VITE_AUTH_API_URL;
  },
  get instagramApiUrl() {
    return getEnv().VITE_INSTAGRAM_API_URL;
  },
  get pinterestApiUrl() {
    return getEnv().VITE_PINTEREST_API_URL;
  },
  get facebookApiUrl() {
    return getEnv().VITE_FACEBOOK_API_URL;
  },
  get dashboardApiUrl() {
    return getEnv().VITE_DASHBOARD_API_URL;
  },
  get tiktokApiUrl() {
    return getEnv().VITE_TIKTOK_API_URL;
  },
  get tikTokTrendingSoundsWebflowPageUrl() {
    return getEnv().VITE_TIKTOK_TRENDING_SOUNDS_WEBFLOW_PAGE_URL;
  },
  get twitterApiUrl() {
    return getEnv().VITE_TWITTER_API_URL;
  },
  get youtubeApiUrl() {
    return getEnv().VITE_YOUTUBE_API_URL;
  },
  get libraryApiUrl() {
    return getEnv().VITE_LIBRARY_API_URL;
  },
  get libraryIntelligenceApiUrl() {
    return getEnv().VITE_LIBRARY_INTELLIGENCE_API_URL;
  },
  get linkedinApiUrl() {
    return getEnv().VITE_LINKEDIN_API_URL;
  },
  get schedulerApiUrl() {
    return getEnv().VITE_SCHEDULER_API_URL;
  },
  get notificationServiceUrl() {
    return getEnv().VITE_NOTIFICATION_SERVICE_URL;
  },
  get shortURLServiceApiUrl() {
    return getEnv().VITE_SHORTURL_SERVICE_API_URL;
  },
  get shortURLDomains() {
    return getEnv().VITE_SHORTURL_DOMAINS.split(',');
  },
  get devImageApiUrl() {
    return getEnv().VITE_DEV_IMAGE_API_URL;
  },
  get oldDevImageApiUrl() {
    return getEnv().VITE_OLD_DEV_IMAGE_API_URL;
  },
  get prodImageApiUrl() {
    return getEnv().VITE_PROD_IMAGE_API_URL;
  },
  get devS3Url() {
    return getEnv().VITE_DEV_S3_URL;
  },
  get oldDevS3Url() {
    return getEnv().VITE_OLD_DEV_S3_URL;
  },
  get prodS3Url() {
    return getEnv().VITE_PROD_S3_URL;
  },
  get devCdnUrl() {
    return getEnv().VITE_DEV_CDN_URL;
  },
  get oldDevCdnUrl() {
    return getEnv().VITE_OLD_DEV_CDN_URL;
  },
  get prodCdnUrl() {
    return getEnv().VITE_PROD_CDN_URL;
  },
  get devCloudFrontUrl() {
    return getEnv().VITE_DEV_CLOUDFRONT_URL;
  },
  get oldDevCloudFrontUrl() {
    return getEnv().VITE_OLD_CLOUDFRONT_URL;
  },
  get prodCloudFrontUrl() {
    return getEnv().VITE_PROD_CLOUDFRONT_URL;
  },
  get pdfApiUrl() {
    return getEnv().VITE_PDF_API_URL;
  },
  get collaborationApiUrl() {
    return getEnv().VITE_COLLABORATION_API_URL;
  },
  get communityApiUrl() {
    return getEnv().VITE_COMMUNITY_API_URL;
  },
  get socialListeningApiUrl() {
    return getEnv().VITE_SOCIAL_LISTENING_API_URL;
  },
  get creatorsApiUrl() {
    return getEnv().VITE_CREATORS_API_URL;
  },
  get browserStorageNamespace() {
    return getEnv().VITE_LOCAL_STORAGE_NAMESPACE;
  },
  get uploadedMediaStorageUrl() {
    return getEnv().VITE_UPLOADED_MEDIA_STORAGE_URL;
  },
  get datadogEnabled() {
    return getEnv().VITE_DATADOG_ENABLED === 'true';
  },
  get datadogApplicationId() {
    return getEnv().VITE_DATADOG_APPLICATION_ID;
  },
  get datadogClientToken() {
    return getEnv().VITE_DATADOG_CLIENT_TOKEN;
  },
  get fullstoryOrg() {
    return getEnv().VITE_FULL_STORY_ORG;
  },
  get intercomAppId() {
    return getEnv().VITE_INTERCOM_APP_ID;
  },
  get mixpanelToken() {
    return getEnv().VITE_MIXPANEL_TOKEN;
  },
  get fullstoryEnabled() {
    return getEnv().VITE_FULL_STORY_ENABLED === 'true';
  },
  get intercomEnabled() {
    return getEnv().VITE_INTERCOM_ENABLED === 'true';
  },
  get hubspotEnabled() {
    return getEnv().VITE_HUBSPOT_ENABLED === 'true';
  },
  get hubspotTrackingCode() {
    return getEnv().VITE_HUBSPOT_TRACKING_CODE;
  },
  get chiliPiperEnabled() {
    return getEnv().VITE_CHILIPIPER_ENABLED === 'true';
  },
  get revision() {
    return getEnv().VITE_REVISION;
  },
  get mixpanelEnabled() {
    return getEnv().VITE_MIXPANEL_ENABLED === 'true';
  },
  get mixpanelApiHost() {
    return getEnv().VITE_MIXPANEL_API_HOST;
  },
  get dhInstagramDev() {
    return getEnv().VITE_DASHWEB_INSTAGRAM_DEV === 'true';
  },
  get pinterestDestLinkDenyList() {
    return (getEnv().VITE_PINTEREST_DEST_LINK_DENY_LIST || '')
      .split(',')
      .map((value) => {
        return value?.trim()?.toLowerCase();
      })
      .filter((value) => !!value);
  },
  get allowedExternalPictureSources() {
    return (getEnv().VITE_ALLOWED_EXTERNAL_PICTURE_SOURCES || '').split(',');
  },
  get zendeskResourceCenterUrl() {
    return getEnv().VITE_ZENDESK_RESOURCE_CENTER_URL;
  },
  get paragonProjectId() {
    return getEnv().VITE_PARAGON_PROJECT_ID;
  },
  get isLocalDev() {
    return getEnv().IS_LOCAL_DEV;
  },
  get launchdarklyToken() {
    return getEnv().VITE_LAUNCHDARKLY_TOKEN;
  },
  get galleryEmbedUrl() {
    return getEnv().VITE_GALLERY_EMBED_URL;
  },
  get carouselEmbedUrl() {
    return getEnv().VITE_CAROUSEL_EMBED_URL;
  },
  get cypressLaunchdarklyUnstubbedLabels() {
    return getEnv().VITE_CYPRESS_LAUNCHDARKLY_UNSTUBBED_LABELS;
  },
  get survicateEnabled() {
    return getEnv().VITE_SURVICATE_ENABLED === 'true';
  },
  get survicateWorkspaceKey() {
    return getEnv().VITE_SURVICATE_WORKSPACE_KEY;
  },
  get visionAiApiUrl() {
    return getEnv().VITE_VISION_AI_API_URL;
  },
  get gtmId() {
    return getEnv().VITE_GTM_ID;
  },
  get gtmEnabled() {
    return getEnv().VITE_GTM_ENABLED;
  },
};
