import { CHANNELS } from '@/models/dashboards/channels.enum';
import { useMetaAdsAccountDetails } from '@/app/dashboards/composables/useMetaAdsAccountDetails';
import { useTikTokAdsAccountDetails } from '@/app/dashboards/composables/useTikTokAdsAccountDetails';
import { unref } from 'vue';

const CHANNEL_TO_ADS_PLATFORM_CONTROLLER = {
  [CHANNELS.META_ADS.value]: useMetaAdsAccountDetails,
  [CHANNELS.TIKTOK_ADS.value]: useTikTokAdsAccountDetails,
};

export function getAdsPlatformController(channel) {
  const composable = CHANNEL_TO_ADS_PLATFORM_CONTROLLER[unref(channel)];
  return composable ? composable() : null;
}
