<template>
  <section>
    <div class="media-popup">
      <MediaPopupV2
        v-if="media"
        :media-type="media.mediaType"
        :media-url="media.urls.original"
        :thumbnail-url="media.urls.ratio"
        :source-type="media.source"
        :carousel="media.carousel"
        :insights="insightsObject"
        :platform-icon="media.source"
        :name="media.sourceCreatorHandle"
        :user-name="media.sourceCreatorHandle"
        :message="media.caption"
        :date-posted="media.sourceCreatedAt"
        :icon-name="media.source"
        :avatar-url="media.sourceCreatorAvatar"
        :email="media.email"
        :tooltip="media.tooltip"
        :duration="media.videoDuration"
        :source-id="media.sourceId"
        :permalink="media?.permalink"
        :sentiment="media?.sentiment"
        :source-creator-id="media.sourceCreatorId"
        :carousel-media="getCarouselMedia(media?.extraUrls)"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import MediaPopupV2 from '@/components/common/MediaPopupV2/MediaPopupV2.vue';
import { formatNumber, formatPercent } from '@/utils/formatters';
import {
  metricFormats,
  metricsMap,
  SOCIAL_LISTENING_ROUTE_NAMES,
} from '@/app/socialListening/constants';
import { useSocialListeningStore } from '@/stores/social-listening';
import { useMediaPopupStore } from '@/stores/media-popup';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PublicMediaPopup',
  components: {
    MediaPopupV2,
  },
  props: {
    mediaItem: { type: Object, default: null },
    t: { type: Boolean, default: false },
  },
  data() {
    return {
      media: this.mediaItem,
    };
  },
  computed: {
    ...mapStores(useSocialListeningStore, useMediaPopupStore),
    insightsObject() {
      return Object.values(metricsMap[this.media.source]).map((fieldData) => {
        if (fieldData.format === metricFormats.NUMBER)
          fieldData.value = formatNumber(this.media[fieldData.field]);
        else if (fieldData.format === metricFormats.PERCENT)
          fieldData.value = formatPercent(this.media[fieldData.field]);
        return {
          value: fieldData.value,
          label: fieldData.label,
          tooltip: fieldData.tooltip,
        };
      });
    },
  },
  watch: {
    mediaItem() {
      this.media = this.mediaItem;
    },
  },
  async mounted() {
    if (this.media === null) {
      let id = null;
      let channel = '';
      if (this.$route.name === SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA) {
        // this flow only works when enter comp directly from url
        id = this.$route.params.id;
        channel = this.$route.params.channel.toUpperCase();
      } else if (window.location.pathname.indexOf('public-media')) {
        // when enter this comp through router push,
        // $route is still the previous object before this popup is rendered,
        // and this popup is not rendered until media is defined (dead loop)
        // manually parse window url for as a workaround.
        // url is expected to always structured as
        // <brand-label>/listening/public-media/<channel>/<source_media_id>
        const url = window.location.pathname;
        id = url.split('/').splice(-1, 1)[0];
        channel = url.split('/').splice(-2, 1)[0].toUpperCase();
      } else {
        return;
      }
      const media = await this.socialListeningStore.getPostById(id, channel, this.t);
      this.mediaPopupStore.setMediaContextList({ mediaContextList: [media] });
      this.media = media;
    }
  },
  beforeUnmount() {
    this.mediaPopupStore.clearMediaContextList();
  },
  methods: {
    getCarouselMedia(mediaList) {
      const mediaItems = [];

      if (mediaList) {
        mediaList.forEach((media) => {
          mediaItems.push({
            mediaType: media.mediaType,
            mediaUrl: media.urls.full,
            sourceType: this.media.source,
          });
        });
      }

      return mediaItems;
    },
  },
});

export default comp;
</script>
