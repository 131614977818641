import { defineStore } from 'pinia';
import { formatMediaListData } from '@/utils';
import { LibraryAPI } from '@/apis';

export const usePopupSearchStore = defineStore('popupSearch', {
  resetOnBrandChange: true,
  state: () => ({
    pending: {
      mediaSearchList: false,
    },
    searchPage: 1,
    searchLimit: 100,
    sectionMedia: [],
    cropParams: null,
    mediaSearchList: [],
    searchListFullyLoaded: false,
    uniqueImageGroupIds: {},
    nextToken: null,
  }),
  getters: {
    formattedMediaSearchList() {
      return formatMediaListData(this.mediaSearchList);
    },
  },
  actions: {
    async getMediaSearchList({
      brandId,
      sort,
      query,
      aspectRatio,
      size,
      type,
      color,
      rightsReqd,
      dateFrom,
      dateTo,
      imageDataIncluded,
      mediaId,
      predictionModel,
      performanceFrom,
      performanceTo,
      page,
      limit,
      sources,
      nextToken,
      imageData,
      clear,
      cancelToken,
      contentTags,
    }) {
      this.pending.mediaSearchList = true;
      try {
        const response = await LibraryAPI.getMediaSearchList({
          brandId,
          sort,
          query,
          aspectRatio,
          size,
          type,
          color,
          rightsReqd,
          dateFrom,
          dateTo,
          imageDataIncluded,
          mediaId,
          predictionModel,
          performanceFrom,
          performanceTo,
          page,
          limit,
          sources,
          nextToken,
          imageData,
          clear,
          cancelToken,
          contentTags,
        });
        this.searchPage += 1;
        if (clear) {
          this.mediaSearchList = [];
          this.uniqueImageGroupIds = {};
        }

        const nextTokenFromResponse = response.data.slice(-1)[0]?.sort;

        if (nextTokenFromResponse) {
          this.nextToken = nextTokenFromResponse;
        }

        const uniqueMediaList = response.data.reduce((mediaList, media) => {
          // Media that belong to an ads carousel (but are not the first item) have --00x appended to the source_id.
          // Remove ads carousel items except the first one
          if (media.source === 'FACEBOOK' && media.sourceId.includes('--')) {
            return mediaList;
          }

          if (!Object.prototype.hasOwnProperty.call(this.uniqueImageGroupIds, media.brandType)) {
            this.uniqueImageGroupIds[media.brandType] = [];
          }
          if (this.uniqueImageGroupIds[media.brandType].includes(media.mediaGroup)) {
            return mediaList;
          }
          this.uniqueImageGroupIds[media.brandType].push(media.mediaGroup);

          mediaList.push(media);
          return mediaList;
        }, []);

        this.mediaSearchList = [...this.mediaSearchList, ...uniqueMediaList];

        if (response.data.length < this.searchLimit) {
          this.searchListFullyLoaded = true;
        }
      } finally {
        this.pending.mediaSearchList = false;
      }
    },
    clearSectionMedia() {
      this.sectionMedia = [];
    },
    setCropParams({ cropParams }) {
      this.setCropParamsValue({ cropParams });
    },
    resetCropParams() {
      this.setCropParamsValue({ cropParams: null });
    },
    clearMediaSearchList() {
      this.searchListFullyLoaded = false;
      this.searchPage = 1;
      this.mediaSearchList = [];
      this.uniqueImageGroupIds = {};
    },
    setCropParamsValue({ cropParams }) {
      this.cropParams = cropParams;
    },
  },
});
