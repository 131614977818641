<template>
  <Icon
    v-tooltip="tooltip"
    :tiny="tiny"
    :xsmall="small ? false : true"
    :small="small"
    :color="iconColor"
    :class="['info-tooltip', { 'add-margin': margin }]"
    name="info"
  />
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

/**
 * Used to display a info icon with a tooltip.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'InfoTooltip',
  components: { Icon },
  props: {
    /**
     * smallest version of the component.
     */
    tiny: { type: Boolean, default: false },
    /**
     * Default size for the component
     */
    xsmall: { type: Boolean, default: true },
    /**
     * Larger version of the default size of the component.
     */
    small: { type: Boolean, default: false },
    /**
     * Set the icon colour.
     */
    iconColor: { type: String, default: null },
    /**
     * Passthrough property for v-tooltip
     */
    tooltip: {
      type: [String, Object],
      default: null,
    },
    /**
     * Toggle margin style
     */
    margin: {
      type: Boolean,
      default: true,
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.svg-icon {
  cursor: default;

  &:focus {
    outline: none;
  }

  &.add-margin {
    margin: 0 var(--space-8);
  }
}

@media print {
  .svg-icon {
    display: none;
  }
}
</style>
