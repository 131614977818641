/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'meta': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.572 5c-1.722 0-3.068 1.301-4.287 2.954C10.61 5.815 9.21 5 7.535 5 4.117 5 1.5 9.46 1.5 14.182 1.5 17.137 2.925 19 5.311 19c1.718 0 2.953-.812 5.15-4.663l1.544-2.74c.221.358.453.743.698 1.156l1.03 1.738C15.737 17.859 16.855 19 18.88 19c2.325 0 3.619-1.889 3.619-4.905C22.5 9.152 19.823 5 16.572 5zm-7.786 8.294c-1.781 2.8-2.397 3.427-3.388 3.427-1.02 0-1.627-.898-1.627-2.5 0-3.428 1.704-6.933 3.734-6.933 1.1 0 2.02.637 3.427 2.66a279.147 279.147 0 00-2.146 3.346zm6.72-.353l-1.232-2.06a42.83 42.83 0 00-.96-1.501c1.11-1.718 2.024-2.574 3.113-2.574 2.262 0 4.071 3.34 4.071 7.444 0 1.564-.51 2.471-1.569 2.471-1.014 0-1.498-.672-3.424-3.78z" _fill="#686a7a"/>'
  }
})
