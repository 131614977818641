/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magic-wand-1-filled': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.384 1.414L16.088 6.19l3.71 3.709-5.063-.822-2.522 5.001-.887-5.604-5.606-.888 5.002-2.522L9.899 0l3.71 3.71 4.775-2.296zM.002 18.385L1.415 19.8 9.9 11.315 8.485 9.901.001 18.385z" _fill="#686a7a"/><mask id="svgicon_magic-wand-1-filled_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.384 1.414L16.088 6.19l3.71 3.709-5.063-.822-2.522 5.001-.887-5.604-5.606-.888 5.002-2.522L9.899 0l3.71 3.71 4.775-2.296zM.002 18.385L1.415 19.8 9.9 11.315 8.485 9.901.001 18.385z" _fill="#fff"/></mask><g mask="url(#svgicon_magic-wand-1-filled_a)"><path pid="2" _fill="#686a7a" d="M-3-2h24v24H-3z"/></g>'
  }
})
