import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';
import { parseQuery } from '@/utils/query';

export const axios = createAxiosInstance(env.libraryApiUrl, {
  camelizeKeys: true,
});
// instance for utm data, some keys and value comes from user input
export const utmAxios = createAxiosInstance(env.libraryApiUrl, {
  camelizeKeys: false,
  decamelizeRequests: false,
});

export function getMediaV2({ brandId, mediaId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/media/v2/${mediaId}`, axiosConfig);
}

export function getGalleriesCSV({ brandId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getStoryMetricsCSV({ brandId, ...params }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/galleries/timeseries_metrics/csv`,
    {},
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getBoardsMediaCSV({ brandId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/media/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getBoardMediaCSV({ brandId, galleryId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/media/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getGalleryMediaProductsCSV({ brandId, galleryId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/gallery_media_products/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getGalleryStatsCSV({ brandId, galleryId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/stats/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getGalleryUgcAsAltImageCSV({ brandId, galleryId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/ugc_as_alt_image/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getLikeShopEmailSubmissionsCSV({ brandId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/likeshop/email_submissions/csv`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getGallery({ brandId, galleryId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/galleries/${galleryId}`, axiosConfig);
}

export async function createGallery(
  { brandId, name, description, tags, galleryType, duplicate, duplicateFilter },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/galleries`,
    { name, description, tags, galleryType, duplicate, duplicateFilter },
    axiosConfig,
  );
}

export async function updateGallery(
  { brandId, galleryId, name, description, tags, spiritGalleryEnabled, settings },
  axiosConfig = {},
) {
  return axios.patch(
    `/brands/${brandId}/galleries/${galleryId}`,
    { name, description, tags, spirit_gallery_enabled: spiritGalleryEnabled, settings },
    axiosConfig,
  );
}

export async function deleteGallery({ brandId, galleryId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/galleries/${galleryId}`, axiosConfig);
}

export function getGalleries(
  {
    brandId,
    url,
    type,
    startDate,
    endDate,
    tags,
    tagsFilterType,
    search,
    sort,
    limit,
    summary,
    types,
    minSize,
    live,
  },
  axiosConfig = {},
) {
  const params = {
    type,
    startDate,
    endDate,
    tags,
    tagsFilterType,
    search,
    sort,
    limit,
    summary,
    types,
    minSize,
    live,
  };
  return axios.get(
    url ?? `/brands/${brandId}/galleries`,
    merge(
      {
        params: url ? null : params,
      },
      axiosConfig,
    ),
  );
}

export function getFullGalleryTagList({ brandId, activeOnly, galleryType }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/gallery_tags`,
    merge(
      {
        params: {
          activeOnly,
          galleryType,
        },
      },
      axiosConfig,
    ),
  );
}

export async function removeTagsFromGallery({ brandId, galleryId, tags }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/galleries/${galleryId}/remove_tags`, { tags }, axiosConfig);
}

export function getGalleryMedia({ brandId, galleryId, url, ...other }, axiosConfig = {}) {
  const params = merge({}, other);
  if (url) {
    merge(params, parseQuery(url));
  }
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/media`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function addGalleryMedia({ brandId, galleryId, mediaIds }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/galleries/${galleryId}/media`, { mediaIds }, axiosConfig);
}

export function removeGalleryMediaBulk({ brandId, galleryId, mediaIds }, axiosConfig = {}) {
  return axios.delete(
    `/brands/${brandId}/galleries/${galleryId}/media`,

    merge(
      {
        params: {
          mediaIds: mediaIds?.join(',') ?? [],
        },
      },
      axiosConfig,
    ),
  );
}

export function removeGalleryMedia({ brandId, galleryId, mediaId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/galleries/${galleryId}/media/${mediaId}`, axiosConfig);
}

export function getTimeSeriesMetricsInstagramStory(
  { brandId, scale, startDate, endDate },
  axiosConfig,
) {
  return axios.get(
    `/brands/${brandId}/timeseries_metrics/instagram_story`,
    merge(
      {
        params: {
          scale,
          startDate,
          endDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function deleteGalleryTag({ brandId, tagId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/gallery_tags/${tagId}`, axiosConfig);
}

export function getGalleryCSV({ brandId, galleryId, sort, socketId, type }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/galleries/${galleryId}/csv`,
    { sort, socketId, type },
    axiosConfig,
  );
}

export function getGalleryStats({ brandId, galleryId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/${galleryId}/stats`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function getLiveGalleriesStats({ brandId, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/galleries/stats`,
    merge(
      {
        params,
      },
      axiosConfig,
    ),
  );
}

export function updateLikeshopCustomContentSettings(
  { brandId, bannerImage, pinnedContent, emailCapture },
  axiosConfig = {},
) {
  return axios.patch(
    `/brands/${brandId}/galleries/likeshop/custom_content_settings`,
    {
      bannerImage,
      pinnedContent,
      emailCapture,
    },
    axiosConfig,
  );
}

export async function updateGalleryOrder({ brandId, galleryId, changeList }, axiosConfig = {}) {
  return axios.patch(`/brands/${brandId}/galleries/${galleryId}/order`, changeList, axiosConfig);
}

export function getCompetitorTotalsByBrand({ brandId }, axiosConfig = {}) {
  return utmAxios.get(`/brands/${brandId}/competitors/count`, axiosConfig);
}

export async function getReport360LikeshopPosts({ brandId, month }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/reports/360/likeshop`,
    merge({ params: { month } }, axiosConfig),
  );
}

export async function getTrendGroups({ brandId, source }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/trend_groups`,
    merge(
      {
        params: { source },
      },
      axiosConfig,
    ),
  );
}

export async function getTrendGroup({ brandId, trendGroupId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/trend_groups/${trendGroupId}`, axiosConfig);
}

export async function createTrendGroup(
  { brandId, name, description, tags, targetSource },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/trend_groups`,
    { name, description, tags, targetSource },
    axiosConfig,
  );
}

export async function updateTrendGroup(
  { brandId, trendGroupId, name, description, tags },
  axiosConfig = {},
) {
  return axios.patch(
    `/brands/${brandId}/trend_groups/${trendGroupId}`,
    { name, description, tags },
    axiosConfig,
  );
}

export async function deleteTrendGroup({ brandId, trendGroupId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/trend_groups/${trendGroupId}`, axiosConfig);
}

export async function addTrendGroupAccount({ brandId, trendGroupId, accountId }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/trend_groups/${trendGroupId}/accounts`,
    { accountId },
    axiosConfig,
  );
}

export async function removeTrendGroupAccount(
  { brandId, trendGroupId, accountId },
  axiosConfig = {},
) {
  return axios.delete(
    `/brands/${brandId}/trend_groups/${trendGroupId}/accounts/${accountId}`,
    axiosConfig,
  );
}

export async function getTrendGroupStats(
  { brandId, trendId, period, resolution, sourceId },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/trend_groups/${trendId}/stats`,
    merge({ params: { period, resolution, sourceId } }, axiosConfig),
  );
}

export async function removeTrendGroupTag({ brandId, trendGroupId, tagId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/trend_groups/${trendGroupId}/tags/${tagId}`, axiosConfig);
}

export async function getTrendGroupTags({ brandId, activeOnly }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/trend_group_tags`,
    merge(
      {
        params: {
          activeOnly,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getSegmentCluster(
  { brandId, segmentClusterId, isUgc, period, sort, resolution },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/segment_clusters/${segmentClusterId}`,
    merge(
      {
        params: {
          isUgc,
          period,
          sort,
          resolution,
        },
        camelizeKeys: true,
      },
      axiosConfig,
    ),
  );
}

export async function updateSegmentCluster({ brandId, segmentClusterId, tags }, axiosConfig = {}) {
  return axios.patch(
    `/brands/${brandId}/segment_clusters/${segmentClusterId}`,
    { tags },
    axiosConfig,
  );
}

export async function removeSegmentClusterTag({ brandId, clusterId, tagId }, axiosConfig = {}) {
  return axios.delete(
    `/brands/${brandId}/segment_clusters/${clusterId}/tags/${tagId}`,
    axiosConfig,
  );
}

export async function deleteSegmentCluster({ brandId, clusterId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/segment_clusters/${clusterId}`, axiosConfig);
}

export async function getSegmentClusterTags({ brandId, activeOnly }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/segment_cluster_tags`,
    merge(
      {
        params: { activeOnly },
      },
      axiosConfig,
    ),
  );
}

export async function removeTagFromAllSegmentClusters({ brandId, tagId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/segment_cluster_tags/${tagId}`, axiosConfig);
}

export async function createSegmentClusterTag({ brandId, name, color }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/segment_cluster_tags`, { name, color }, axiosConfig);
}

export async function createSegmentClusterTagAssociations(
  { brandId, segmentClusterIds, segmentClusterTagIds },
  axiosConfig = {},
) {
  await axios.post(
    `/brands/${brandId}/segment_cluster_tag_associations`,
    { segmentClusterIds, segmentClusterTagIds },
    axiosConfig,
  );
}

export async function getSegmentClusterStats(
  { brandId, clusterId, period, resolution, sourceId },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/segment_clusters/${clusterId}/stats`,
    merge(
      {
        params: {
          period,
          resolution,
          sourceId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getSegments(
  { brandId, source, sourceId, sort, period, resolution, mediaCount, isUgc, brand, token },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/segments`,
    merge(
      {
        params: {
          source,
          sourceId,
          sort,
          period,
          resolution,
          mediaCount,
          isUgc,
          brand,
          token,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getSegment({ brandId, segmentId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/segments/${segmentId}`, axiosConfig);
}

export async function getSegmentStats({ brandId, source, sourceId, isUgc }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/segments/stats`,
    merge(
      {
        params: {
          source,
          sourceId,
          isUgc,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getContentTags({ organizationId, contentTagIds }, axiosConfig = {}) {
  const params = {};
  if (contentTagIds?.length > 0) {
    params.ids = contentTagIds.join(',');
  }
  return axios.get(`/organizations/${organizationId}/content_tags`, merge({ params }, axiosConfig));
}

export async function createContentTag({ organizationId, name, brandIds }, axiosConfig = {}) {
  const data = {
    name,
  };
  if (brandIds?.length > 0) {
    data.brandIds = brandIds;
  }
  return axios.post(`/organizations/${organizationId}/content_tags`, data, axiosConfig);
}

export async function updateContentTag(
  { organizationId, contentTagId, name, brandIds },
  axiosConfig = {},
) {
  const data = {
    name,
    brandIds,
  };

  return axios.patch(
    `/organizations/${organizationId}/content_tags/${contentTagId}`,
    data,
    axiosConfig,
  );
}

export async function deleteContentTag({ organizationId, contentTagId }, axiosConfig = {}) {
  return axios.delete(`/organizations/${organizationId}/content_tags/${contentTagId}`, axiosConfig);
}

export async function deleteContentTagBrand(
  { organizationId, contentTagId, brandId },
  axiosConfig = {},
) {
  return axios.delete(
    `/organizations/${organizationId}/content_tags/${contentTagId}/brands/${brandId}`,
    axiosConfig,
  );
}

export async function associateContentTagsToBrandMedia(
  { organizationId, contentTagIds, brandMediaIds, createMissingAssociations },
  axiosConfig = {},
) {
  return axios.post(
    `/organizations/${organizationId}/brand_media_content_tags`,
    {
      contentTagIds,
      brandMediaIds,
      createMissingAssociations,
    },
    axiosConfig,
  );
}

export async function removeContentTagFromBrandMedia(
  { organizationId, contentTagId, brandMediaId },
  axiosConfig = {},
) {
  return axios.delete(
    `/organizations/${organizationId}/content_tags/${contentTagId}/brand_media/${brandMediaId}`,
    axiosConfig,
  );
}

export async function getPredictions({ brandId, files }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/media_predictions`, files, axiosConfig);
}

export async function searchContentAutomations({ organizationIds, brandIds }, axiosConfig = {}) {
  return axios.post('/content_automations/search', { organizationIds, brandIds }, axiosConfig);
}

const api = {
  async getGalleryMediaV2(
    {
      brandId,
      galleryId,
      offset = 0,
      limit = 100,
      sort,
      withProductsOnly = false,
      startDate,
      endDate,
      excludeInstagramOwnedWithoutProducts,
      include,
      url,
    },
    axiosConfig = {},
  ) {
    const params = {
      offset,
      limit,
      withProductsOnly,
    };
    if (include) {
      params.include = include.join(',');
    }
    if (sort) {
      params.sort = sort;
    }
    if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }
    if (excludeInstagramOwnedWithoutProducts) {
      params.excludeInstagramOwnedWithoutProducts = excludeInstagramOwnedWithoutProducts;
    }

    if (url) {
      merge(params, parseQuery(url));
    }

    return axios.get(
      `/brands/${brandId}/galleries/${galleryId}/media/v2`,
      merge(
        {
          params,
        },
        axiosConfig,
      ),
    );
  },
  async getInteractionMediaById({ brandId, mediaId }, axiosConfig = {}, params = {}) {
    const url = `/brands/${brandId}/media/${mediaId}`;
    return axios.get(url, merge({ params }, axiosConfig));
  },
  async getMediaListV2(
    { brandId, limit, offset, sortFields, filters, url, includeCreator },
    axiosConfig,
  ) {
    const params = { limit, offset };
    const data = { sortFields, filters, includeCreator };

    if (url) {
      merge(params, parseQuery(url));
    }

    return axios.put(`/brands/${brandId}/media/v2`, data, merge({ params }, axiosConfig));
  },
  async updateTikTokLikeShopCustomContent({ brandId, data }) {
    return axios.patch(
      `/brands/${brandId}/galleries/tiktok_likeshop/custom_content_settings`,
      data,
    );
  },
  async getUtmSettingsForTikTokLikeShop({ brandId, data }) {
    return utmAxios.get(`/brands/${brandId}/galleries/tiktok_likeshop`, data);
  },

  async saveUtmSettingsForTikTokLikeShop({ brandId, data, galleryId }) {
    return utmAxios.patch(`/brands/${brandId}/galleries/${galleryId}`, data);
  },
  async getGoogleAnalyticsAccounts({ brandId }) {
    return axios.get(`/brands/${brandId}/google_analytics/accounts`);
  },
  async createAnalyticsAccount({ brandId, data }) {
    const path = `/brands/${brandId}/google_analytics/account`;
    return axios.post(path, { ...data });
  },
  async getGoogleAnalyticAccountsByBrandId({ brandId }, payload) {
    return axios.get(`/brands/${brandId}/google_analytics/list_accounts`, payload);
  },
  async getBrandsGoogleAnalyticsAccount({ brandId }) {
    return axios.get(`/brands/${brandId}/google_analytics/account`);
  },
  async getSegmentClusters(
    {
      brandId,
      tagId,
      sources,
      isUgc,
      period,
      mediaCount,
      search,
      tags,
      tagsFilterType,
      sort,
      limit,
      offset,
      summary = true,
      url,
    },
    axiosConfig = {},
  ) {
    const params = {
      tagId,
      sources,
      isUgc,
      period,
      mediaCount,
      search,
      tags,
      tagsFilterType,
      sort,
      limit,
      offset,
      summary,
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });
    return axios.get(url || `/brands/${brandId}/segment_clusters`, {
      ...axiosConfig,
      params,
    });
  },

  async getMediaSearchList({
    brandId,
    sort,
    query,
    aspectRatio,
    size,
    type,
    color,
    rightsReqd,
    dateFrom,
    dateTo,
    imageDataIncluded,
    mediaId,
    predictionModel,
    performanceFrom,
    performanceTo,
    page,
    limit,
    sources,
    nextToken,
    imageData,
    clear,
    cancelToken,
    contentTags,
  }) {
    const url = `/brands/${brandId}/media_search`;
    const data = { image_data: imageData, clear, contentTags };
    const params = {
      image_data: imageDataIncluded,
      brand_id: brandId,
      sort,
      query,
      aspect_ratio: aspectRatio,
      size,
      type,
      color,
      rights_reqd: rightsReqd,
      date_from: dateFrom,
      date_to: dateTo,
      media_id: mediaId,
      prediction_model: predictionModel,
      performance_from: performanceFrom,
      performance_to: performanceTo,
      page,
      limit,
      sources,
      next_token: nextToken,
    };
    return axios.put(url, data, { params, cancelToken });
  },

  async getImageData(mediaId) {
    const libraryAxios = createAxiosInstance(env.libraryApiUrl);
    return libraryAxios.get(`/media/${mediaId}/image_data`);
  },

  async fetchMediaProducts({ brandId, mediaId }, axiosConfig = {}) {
    return axios.get(`/brands/${brandId}/media/${mediaId}/media_products`, { ...axiosConfig });
  },

  async getMediaV2({ brandId, mediaId }, axiosConfig = {}) {
    return axios.get(`/brands/${brandId}/media/v2/${mediaId}`, { ...axiosConfig });
  },

  async createMedia({ brandId }, data, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/media`, data, { ...axiosConfig });
  },

  async createMediaV2({ brandId }, data, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/media/v2`, data, { ...axiosConfig });
  },

  async getMediaList(
    { brandId, url, sortFields, sort, filters, include, ids, limit, includeInstagramUsers = false },
    axiosConfig = {},
  ) {
    const params = {
      include,
    };
    const data = {
      sortFields,
      sort,
      filters,
      ids,
      limit,
      includeInstagramUsers,
    };

    if (url) {
      merge(params, parseQuery(url));
    }

    return axios.put(
      `/brands/${brandId}/media/v2`,
      data,
      merge(
        {
          params,
        },
        axiosConfig,
      ),
    );
  },

  async getBrandMediaListBySource({ brandId, source, sourceIds }, axiosConfig = {}) {
    return axios.get(`/brands/${brandId}/media`, {
      ...axiosConfig,
      params: { source, sourceIds: sourceIds.join(',') },
    });
  },
  async getTopPostMediaList({ brandId, limit, filters, sortFields, url }, axiosConfig = {}) {
    const params = {
      limit,
    };
    const data = {
      sortFields,
      filters,
    };

    if (url) {
      merge(params, parseQuery(url));
    }

    return axios.put(
      `/brands/${brandId}/media/v2`,
      data,
      merge(
        {
          params,
        },
        axiosConfig,
      ),
    );
  },

  async getMediaListCSV(
    { brandId, source, filters, sortFields, sort, socketId },
    axiosConfig = {},
  ) {
    const params = { brandId };
    const data = {
      source,
      filters,
      sortFields,
      sort,
      socketId,
    };
    return axios.put(`/brands/${brandId}/media/v2/csv`, data, {
      ...axiosConfig,
      params,
    });
  },
  async updateMediaLinks({ mediaId, brandId, data }) {
    return axios.patch(`/brands/${brandId}/media/${mediaId}/media_products`, data);
  },
  async getListMediaLinks({ brandId, mediaId }) {
    return axios.get(`/brands/${brandId}/media/${mediaId}/media_products`);
  },
  async getSimilarMediaList({ brandId, mediaId, groupId, sources, limit }) {
    const url = `/brands/${brandId}/media_search`;
    const params = { brandId, mediaId, groupId, sources, limit };
    return axios.get(url, { params });
  },
  async getMediaSourceList({ brandId, groupId, globalIds }) {
    const url = `/brands/${brandId}/media`;
    const params = { brandId, group_id: groupId, global_ids: globalIds };
    return axios.get(url, { params });
  },
  async apiGetCompetitors({ payload }, axiosConfig = {}) {
    const url = '/competitors/search';
    return axios.post(url, payload, axiosConfig);
  },
  async deleteGoogleAnalyticsAccount(brandId) {
    return axios.delete(`/brands/${brandId}/google_analytics/account`);
  },
  async getAutocomplete({ brandId, source, handle }) {
    const updatedSource = source === 'X' ? 'TWITTER' : source;
    const url = `/brands/${brandId}/competitors/autocomplete`;
    const params = { brandId, source: updatedSource, handle };
    return axios.get(url, { params });
  },

  async createSegmentCluster({ brandId, targetSource, targetSourceId }, axiosConfig = {}) {
    return axios.post(
      `/brands/${brandId}/segment_clusters`,
      {
        targetSource,
        targetSourceId,
      },
      axiosConfig,
    );
  },

  async addMediaTag({ brandId, mediaId, name }, axiosConfig = {}) {
    const params = { brandId, mediaId };
    const data = { name };

    return axios.post(`/brands/${brandId}/media/${mediaId}/tags`, data, { ...axiosConfig, params });
  },

  async addMultipleMediaTags({ brandId, mediaIds, tags }, axiosConfig = {}) {
    const params = { brandId };
    const data = { media_ids: mediaIds, tags };

    return axios.post(`/brands/${brandId}/media_tags`, data, { ...axiosConfig, params });
  },

  async removeMediaTag({ brandId, mediaId, tagId }, axiosConfig = {}) {
    const params = { brandId, mediaId, tagId };
    return axios.delete(`/brands/${brandId}/media/${mediaId}/tags/${tagId}`, {
      ...axiosConfig,
      params,
    });
  },

  async updateMediaMeta({ brandId, mediaId, meta }, axiosConfig = {}) {
    const params = { brandId, mediaId };
    const data = { meta };

    return axios.patch(`/brands/${brandId}/media/${mediaId}`, data, {
      ...axiosConfig,
      params,
    });
  },

  async deleteMediaItem({ brandId, mediaId }, axiosConfig = {}) {
    const params = { brandId, mediaId };

    return axios.delete(`/brands/${brandId}/media/${mediaId}`, { ...axiosConfig, params });
  },

  async bulkDeleteMedia({ brandIds, mediaIds }, axiosConfig = {}) {
    return axios.delete('/media/bulk', {
      ...axiosConfig,
      params: { brandIds: brandIds.join(','), mediaIds: mediaIds.join(',') },
    });
  },

  async getImageDataByMediaId({ mediaId }, axiosConfig = {}) {
    const params = { mediaId };

    return axios.get(`/media/${mediaId}/image_data`, { ...axiosConfig, params });
  },

  /**
   * Deprecated can be removed if fetch (imageToBase64) solution will work
   */
  async getImageDataByUrl({ url }, axiosConfig = {}) {
    const params = { url };

    return axios.get('/image_data', { ...axiosConfig, params });
  },

  async updateProduct({ brandId, productId, data }, axiosConfig = {}) {
    const url = `/brands/${brandId}/products/${productId}`;
    return axios.patch(url, data, { ...axiosConfig });
  },

  async getProducts({ brandId, url, ...other }, axiosConfig = {}) {
    let pathName = `/brands/${brandId}/products`;
    let params = merge({}, other);

    if (url) {
      pathName = new URL(url).pathname;
      params = merge(params, parseQuery(url));
    }

    return axios.get(
      pathName,
      merge(
        {
          params,
        },
        axiosConfig,
      ),
    );
  },

  async getProduct({ brandId, productId }, axiosConfig = {}) {
    return axios.get(`/brands/${brandId}/products/${productId}`, { ...axiosConfig });
  },

  // Returns a product record with url that matches exactly
  // Also searches product override URLs, returning the associated product record if found
  async findProductByUrl({ brandId, url }, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/products/find_by_url`, { url }, axiosConfig);
  },

  async addProduct({ brandId }, data, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/products`, data, { ...axiosConfig });
  },

  async updateProductArchivedStatusByIds({ brandId, productIds, archived }, axiosConfig = {}) {
    const data = { product_ids: productIds, archived };
    return axios.patch(`/brands/${brandId}/products/archive`, data, { ...axiosConfig });
  },

  async getProductFeeds({ brandId, sort }, axiosConfig = {}) {
    const params = { sort };
    return axios.get(`/brands/${brandId}/product_feeds`, { ...axiosConfig, params });
  },

  async addProductFeed({ brandId }, data, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/product_feeds`, data, { ...axiosConfig });
  },

  async updateProductFeed({ brandId, feedId }, data, axiosConfig = {}) {
    return axios.post(`/brands/${brandId}/product_feeds/${feedId}`, data, { ...axiosConfig });
  },

  async archiveProductFeedProducts(
    { brandId, feedId, notificationSocketId, archived },
    axiosConfig = {},
  ) {
    const data = {
      notification_socket_id: notificationSocketId,
      archived,
    };
    return axios.patch(`/brands/${brandId}/product_feeds/${feedId}/products/archive`, data, {
      ...axiosConfig,
    });
  },

  async deleteProductFeed({ brandId, feedId }, axiosConfig = {}) {
    return axios.delete(`/brands/${brandId}/product_feeds/${feedId}`, { ...axiosConfig });
  },

  async getUrlMetadata({ url, id, linkNumber }, axiosConfig = {}) {
    const params = { url, id, linkNumber };
    return axios.get(`/url_metadata`, { ...axiosConfig, params });
  },

  async getProductsAnalytics({ brandId, scale, startDate, endDate }, axiosConfig = {}) {
    const params = {
      brandId,
      scale,
      start_date: startDate,
      end_date: endDate,
    };
    return axios.get(`/brands/${brandId}/products/click_stats`, { ...axiosConfig, params });
  },

  async getProductMedia({ brandId, productId }, axiosConfig = {}) {
    return axios.get(`/brands/${brandId}/products/${productId}/media`, { ...axiosConfig });
  },

  async getProductClicks(
    { brandId, productId, startDate, endDate, scale, source },
    axiosConfig = {},
  ) {
    const params = {
      startDate,
      endDate,
      scale,
      source,
    };
    return axios.get(`/brands/${brandId}/products/${productId}/click_stats`, {
      ...axiosConfig,
      params,
    });
  },

  async setProductMediaHiddenStatus(
    { brandId, productId, mediaId, hideInWidget },
    axiosConfig = {},
  ) {
    const data = { hide_in_widget: hideInWidget };
    return axios.patch(`/brands/${brandId}/products/${productId}/media/${mediaId}`, data, {
      ...axiosConfig,
    });
  },

  async getMediaBySourceId({ brandId, sourceId, sourceType }, axiosConfig = {}) {
    const url = `/brands/${brandId}/media/source/${sourceId}`;
    const params = { source_type: sourceType };
    return axios.get(url, { ...axiosConfig, params });
  },

  async getCompetitorTagsByBrand({ brandIds, includeCompetitors = false }, axiosConfig = {}) {
    const url = '/competitors/tags';
    const data = { brand_ids: brandIds, include_competitors: includeCompetitors };
    return axios.put(url, data, axiosConfig);
  },

  async getUtmSettings({ brandId, channel }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings`;
    return utmAxios.get(url, { params: { channel } }, axiosConfig);
  },

  async getUtmPresetValues({ brandId, channel, trackingKey }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings/preset_values`;
    return axios.get(url, { params: { channel, trackingKey } }, axiosConfig);
  },

  async createUtmPresetValue({ brandId, channel, trackingKey, value }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings/preset_values`;
    return axios.post(url, { channel, trackingKey, value }, axiosConfig);
  },

  async updateUtmPresetValue({ brandId, id, value }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings/preset_values/${id}`;
    return axios.put(url, { value }, axiosConfig);
  },

  async deleteUtmPresetValue({ brandId, id }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings/preset_values/${id}`;
    return axios.delete(url, axiosConfig);
  },

  async saveUtmSettings({ brandId, channel, items }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings`;
    const data = {
      [channel]: items,
    };
    return utmAxios.put(url, data, axiosConfig);
  },

  async decodeUtmSettings({ brandId, channel, content, urls }, axiosConfig = {}) {
    const url = `/brands/${brandId}/utm_settings/decode_utms`;
    return utmAxios.post(url, { channel, content, urls }, axiosConfig);
  },
  async encodeUtmSettings(
    {
      brandId,
      channel,
      shortenUrl,
      customUtms = {},
      updateShortUrl = false,
      mediaId,
      productId,
      content,
      urls,
    },
    axiosConfig = {},
  ) {
    return axios.post(
      `/brands/${brandId}/utm_settings/encode_utms`,
      {
        channel,
        content,
        mediaId,
        customUtms,
        updateShortUrl,
        productId,
        shortenUrl,
        urls,
      },
      axiosConfig,
    );
  },
};

export default api;
