/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clicks': {
    width: 17,
    height: 22,
    viewBox: '0 0 17 22',
    data: '<g _fill="#F2F2F2" fill-rule="nonzero"><path pid="0" d="M15.223 21.994h-7.68c-.416 0-.796-.235-.982-.606l-3.292-6.583a1.098 1.098 0 01.982-1.588h2.195V6.634a1.098 1.098 0 011.444-1.04L11.18 6.69c.449.15.75.57.75 1.041v4.63l3.557.89c.49.122.832.56.832 1.063v6.583c0 .607-.49 1.097-1.097 1.097zM8.22 19.8h5.905v-4.629l-3.557-.889a1.1 1.1 0 01-.832-1.065V8.522L8.64 8.157v6.157c0 .606-.49 1.097-1.097 1.097H6.027L8.22 19.8zM.96 6.634h3.291v2.194H.96zM3.985 1.526l2.328 2.327L4.76 5.405 2.434 3.077zM7.543.051h2.194v3.291H7.543zM14.846 3.078l-2.328 2.327-1.551-1.552 2.327-2.327zM13.029 6.634h3.291v2.194h-3.291z"/></g>'
  }
})
