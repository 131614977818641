import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import constants from '@/app/dashboards/constants';
import { getReportConfigProp } from '@/app/dashboards/utils/reports.enum';

function intersects(a, b) {
  return a.x < b.x + b.w && b.x < a.x + a.w && a.y < b.y + b.h && b.y < a.y + a.h;
}

function intersectsLayout(layout, newPosition) {
  return layout.some((report) => {
    return intersects(report, newPosition);
  });
}

function numberOfRows(layout) {
  return layout.reduce((existingMax, report) => {
    const possibleNewMax = report.y + report.h;
    return possibleNewMax > existingMax ? possibleNewMax : existingMax;
  }, 0);
}

function numberOfCards(layout, paddingRows = 0, cols = constants.NUMBER_OF_DASHBOARD_COLS) {
  return numberOfRows(layout) * cols + paddingRows * cols;
}

function findPosition(
  layout,
  w,
  h,
  rows = numberOfRows(layout),
  cols = constants.NUMBER_OF_DASHBOARD_COLS,
) {
  for (let y = 0; y < rows + 1; y += 1) {
    for (let x = 0; x < cols - w + 1; x += 1) {
      const newPosition = {
        w,
        h,
        x,
        y,
      };
      if (!intersectsLayout(layout, newPosition)) {
        return newPosition;
      }
    }
  }
  return null;
}

export function sortLayout(layoutItemA, layoutItemB) {
  if (layoutItemA.y === layoutItemB.y) {
    return layoutItemA.x - layoutItemB.x;
  }
  return layoutItemA.y - layoutItemB.y;
}

/*
 * Attempts to find where page breaks (or a print row) can be inserted nicely.
 * `maxRowsForPrintRow` is the maximum number of rows that can fit on a page.
 * This means that there must be a print row at most every `maxRowsForPrintRow` rows.
 */
function getPrintRowBreaks(layout = []) {
  const maxRows = numberOfRows(layout);
  const printRowBreaks = [0];
  let lastPrintRowIndex = 0;
  for (let rowIndex = 1; rowIndex <= maxRows; rowIndex += 1) {
    const doAnyLayoutItemsCrossBoundary = layout.some((layoutItem) => {
      const top = layoutItem.y;
      const bottom = layoutItem.y + layoutItem.h;
      return top < rowIndex && rowIndex < bottom;
    });
    const boundaryPrintRowMax = 6;
    const normalPrintRowMax = 8;

    const maxRowsForPrintRow = doAnyLayoutItemsCrossBoundary
      ? boundaryPrintRowMax
      : normalPrintRowMax;
    const rowsSinceLastPrintRow = rowIndex - lastPrintRowIndex;
    const maxRowsForPrintRowReached = rowsSinceLastPrintRow === maxRowsForPrintRow;
    if (!doAnyLayoutItemsCrossBoundary || maxRowsForPrintRowReached) {
      lastPrintRowIndex = rowIndex;
      printRowBreaks.push(rowIndex);
    }
  }
  return printRowBreaks;
}

function calculatePrintRows(layout = []) {
  const sortedLayout = cloneDeep(layout).sort(sortLayout);
  const printRowBreaks = getPrintRowBreaks(sortedLayout);
  const printRows = printRowBreaks
    .filter((_element, index) => index < printRowBreaks.length - 1) // remove last break index
    .map((breakIndex, index) => {
      const nextBreakIndex = printRowBreaks[index + 1];
      return sortedLayout.filter(
        (layoutItem) => layoutItem.y >= breakIndex && layoutItem.y < nextBreakIndex,
      );
    });

  return printRows.map((printRow) => {
    const groupByY = groupBy(printRow, 'y');
    return Object.values(groupByY);
  });
}

export function overrideLayoutItemHeightFromConstant(layout) {
  return [].concat(layout).map((layoutItem) => {
    const h = getReportConfigProp(layoutItem.type, 'height');
    return {
      ...layoutItem,
      h,
    };
  });
}

export default {
  findPosition,
  numberOfRows,
  numberOfCards,
  sortLayout,
  getPrintRowBreaks,
  calculatePrintRows,
};
