/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file-heart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 2h10v4h4v5.002h2V4.586L13.414 0H2C.897 0 0 .898 0 2v18c0 1.103.897 2 2 2h8v-2H2V2zm5 10.001a2 2 0 100-4 2 2 0 000 4zm-3 5l3-4 2 2 1-2v4H4zM20 13c-.735 0-1.452.332-2 .846-.548-.514-1.265-.846-2-.846-2.393 0-4 1.607-4 4 0 3.467 4.924 6.521 5.485 6.858a1.002 1.002 0 001.03 0C19.076 23.521 24 20.467 24 17c0-2.393-1.607-4-4-4zm-2 8.811c-1.422-.965-4-3.072-4-4.811 0-.602.195-2 2-2 .449 0 1 .551 1 1a1 1 0 002 0c0-.449.551-1 1-1 1.805 0 2 1.398 2 2 0 1.738-2.578 3.846-4 4.811z" _fill="#2A2A2A"/>'
  }
})
