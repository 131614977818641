<template>
  <!-- NB: Extra whitespace in the wrapper content causes problems. -->
  <NodeViewWrapper ref="mention" as="span" class="mention">{{ node.attrs.label }}</NodeViewWrapper>
</template>

<script>
import { createApp, defineComponent, markRaw } from 'vue';
import tippy from 'tippy.js';
import { NodeViewWrapper } from '@tiptap/vue-3';

/* eslint-disable vue/one-component-per-file */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    NodeViewWrapper,
  },
  props: {
    node: { type: Object, required: true },
    extension: { type: Object, required: true },
  },
  mounted() {
    const { mentionDetailsComponent, onMentionLookup } = this.extension.options.mentionHover;
    const mentionId = this.node.attrs.id;

    // Using a nested component and VPopover to show hover details breaks Tiptap's layout, so we
    // render the mention details off-document and append to the page with tippy.
    this.tooltip = tippy(this.$refs.mention.$el, {
      appendTo: () => document.body,
      placement: 'bottom-start',
      onTrigger(instance) {
        // When the tooltip is hovered, lookup the mention details and pass them as props to
        // the mention details component
        const mention = onMentionLookup(mentionId);
        if (mention) {
          const mountEl = document.createElement('div');
          document.body.appendChild(mountEl);

          const component = createApp(mentionDetailsComponent, { ...mention });
          instance.mentionDetails = markRaw(component);
          instance.mentionDetails.mount(mountEl);

          instance.setContent(mountEl);
        }
      },
      onUntrigger(instance) {
        instance.mentionDetails?.unmount();
      },
      onDestroy(instance) {
        instance.mentionDetails?.unmount();
      },
    });
  },
  beforeUnmount() {
    this.tooltip.destroy();
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.mention {
  color: var(--action-500);
}
</style>
