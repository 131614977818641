<template>
  <div ref="wrapper" class="rich-textarea">
    <div
      ref="textArea"
      :class="[
        'editor',
        {
          disabled: disabled,
          'is-focused': isFocused,
          resizable,
          'height-override': editorHeight,
          'border-rose-600': Boolean(errorMessage),
        },
      ]"
      data-cy="rich-text-area"
      :style="cssVars"
      @click="clickContainer"
      @keyup="onEditorKeyUp"
    >
      <BubbleMenu
        v-if="editor && enableTwitterMentions"
        :editor="editor"
        :tippy-options="{ placement: 'bottom', popperOptions: { strategy: 'fixed' } }"
        :should-show="() => true"
      >
        <MentionSearch
          v-if="mentionSearchQuery && hasInteractedWithEditor"
          ref="mentionSearch"
          :on-mention-search="onMentionSearch"
          :on-mention-add="handleMentionAdd"
          :mention-details-component="mentionDetailsComponent"
          :insert-suggestion="() => {}"
          :query="mentionSearchQuery"
        />
      </BubbleMenu>
      <EditorContent
        ref="editorContent"
        :editor="editor"
        class="editor-content"
        @keyup="EditorContentAdjust"
        @mouseover="updateHoveredMentionElement"
        @mouseout="updateHoveredMentionElement"
      />
    </div>

    <div v-if="errorMessage" class="text-sm text-[color:var(--error-500)]">{{ errorMessage }}</div>

    <div
      :class="[
        'left-button-wrapper',
        { 'buttons-top': !buttonsBottom, 'buttons-bottom': buttonsBottom },
      ]"
    >
      <slot name="left-buttons"></slot>
    </div>
    <div
      :class="[
        'right-button-wrapper',
        { 'buttons-top': !buttonsBottom, 'buttons-bottom': buttonsBottom },
      ]"
    >
      <slot name="right-buttons">
        <EmojiPicker
          class="emoji-picker"
          :disabled="disabled"
          :size="emojiPickerSize"
          @emoji-selected="insertEmoji"
          @opened="focus"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import tippy from 'tippy.js';
import { Editor, EditorContent, VueRenderer, BubbleMenu } from '@tiptap/vue-3';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import Text from '@tiptap/extension-text';

import CaptureKeys from './CaptureKeys';
import Document from './Document';
import HardBreakOnly from './HardBreakOnly';
import HashTag from './HashTag';
import TwitterMention from './TwitterMention';
import Mention from './Mention';
import MentionSearch from './MentionSearch.vue';
import MentionTooltip from './MentionTooltip.vue';
import EmojiPicker from './EmojiPicker.vue';

const flattenElements = (element) => [element, ...[...element.children].flatMap(flattenElements)];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'RichTextarea',
  components: {
    EmojiPicker,
    EditorContent,
    BubbleMenu,
    MentionSearch,
  },
  props: {
    autofocus: { type: String, default: null }, // See https://tiptap.dev/api/editor#autofocus
    maxlength: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    emojiPickerSize: { type: String, default: '1.75rem' },
    enableMentions: { type: Boolean, default: false },
    enableTwitterMentions: { type: Boolean, default: false },
    enableHashTagHighlighting: { type: Boolean, default: false },
    mentionDetailsComponent: { type: Object, default: null },
    mentionSearchPosition: { type: String, default: 'text' }, // Accepts 'text' or 'editor'
    placeholder: { type: String, default: '' },
    resizable: { type: Boolean, default: false },
    value: { type: String, default: '' },
    captureEnter: { type: Boolean, default: false },
    editorHeight: { type: String, default: '6.25rem' },
    resizeCharLimit: { type: Number, default: null },
    autoAdjustMaxHeight: { type: String, default: '18.75rem' },

    // Mention object required shape: { id: 123, text: 'some text', ...otherPropsUnused }

    // Function called when a mention is added to the textarea. Passed a mention object.
    onMentionAdd: { type: Function, default: () => {} },

    // Function called when a mention is hovered and we need the details for it. Passed a mention
    // ID and must return a mention object.
    onMentionLookup: { type: Function, default: () => {} },

    // Function called when a user types "@<sometext>". Passed a query string and must return an
    // array of mention objects.
    onMentionSearch: { type: Function, default: () => [] },

    // Debounce wait time for onMentionSearch in milliseconds. If 0 or null, the search function
    // won't be debounced at all.
    debounceMentionSearch: { type: Number, default: 250 },

    buttonsBottom: { type: Boolean, default: false },
    errorMessage: { type: String, default: null },
  },
  emits: ['blur', 'focus', 'input', 'enter-pressed', 'text-pasted'],
  data() {
    return {
      overLimit: false,
      editor: null,
      isFocused: false,
      isSearchingMentions: false,
      mentionSearchQuery: null,
      cursorPosition: 0,
      hasInteractedWithEditor: false,
      hoveredMentionElement: null,
      closeMentionHoverTooltip: () => {},
      textAreaStyles: {},
    };
  },
  computed: {
    mentionSearchTargetElement() {
      return this.mentionSearchPosition === 'editor' ? this.$refs.wrapper : null;
    },
    cssVars() {
      if (!(this.resizeCharLimit != null && this.resizeCharLimit > 0))
        return {
          '--editor-height': this.editorHeight,
          ...this.textAreaStyles,
        };
      return { height: this.editorHeight, ...this.textAreaStyles };
    },
  },
  watch: {
    placeholder(to, from) {
      if (to !== from) {
        // Make the placeholder update reactive
        this.editor.chain().run();
      }
    },
    mentionSearchQuery(to) {
      if (to) {
        this.closeMentionHoverTooltip();
      }
    },
    hoveredMentionElement(to) {
      this.closeMentionHoverTooltip();
      if (to && (!this.mentionSearchQuery || !this.isFocused)) {
        const username = to.innerText.replace(/^@/, '');
        const user = this.onMentionLookup(username);
        if (user) {
          const component = new VueRenderer(MentionTooltip, {
            props: {
              mention: user,
              component: this.mentionDetailsComponent,
            },
            editor: this.editor,
          });
          const popup = tippy(to, {
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
            maxWidth: '',
          });
          this.closeMentionHoverTooltip = () => {
            popup.destroy();
            component.destroy();
            this.closeMentionHoverTooltip = () => {};
          };
        }
      }
    },
    value(newVal) {
      if (this.editor && newVal !== this.editor.getHTML()) {
        this.editor
          .chain()
          // We set "preserveWhitespace" so that when HTML content is updated via the value prop,
          // we don't strip out meaningful whitespace.
          .setContent(newVal, false, { preserveWhitespace: 'full' })
          // Restore cursor position after new text has been injected
          .setTextSelection(this.cursorPosition)
          .run();
      }
    },
    disabled(newVal) {
      this.editor.setEditable(!newVal);
    },
  },
  unmounted() {
    this.editor.destroy();
    this.closeMentionHoverTooltip();
  },
  mounted() {
    const extensions = [
      Document,
      HardBreak,
      HardBreakOnly,
      History,
      Paragraph,
      Placeholder.configure({
        placeholder: () => this.placeholder,
        showOnlyWhenEditable: false,
      }),
      Text,
    ];
    if (this.maxlength) {
      extensions.push(CharacterCount.configure({ limit: this.maxlength }));
    }
    if (this.captureEnter) {
      extensions.push(
        CaptureKeys.configure({
          handlers: {
            Enter: () => {
              this.$emit('enter-pressed');
              return true;
            },
          },
        }),
      );
    }
    if (this.enableHashTagHighlighting) {
      extensions.push(HashTag);
    }
    if (this.enableTwitterMentions) {
      extensions.push(TwitterMention);
      extensions.push(
        CaptureKeys.configure({
          handlers: {
            ArrowUp: () => {
              this.$refs.mentionSearch?.upHandler();
              return !!this.mentionSearchQuery;
            },
            ArrowDown: () => {
              this.$refs.mentionSearch?.downHandler();
              return !!this.mentionSearchQuery;
            },
            Enter: () => {
              const hadQuery = !!this.mentionSearchQuery;
              this.$refs.mentionSearch?.enterHandler();
              return hadQuery;
            },
          },
        }),
      );
    }
    if (this.enableMentions) {
      extensions.push(
        Mention.configure({
          mentionHover: {
            // Settings for our mention hover functionality which is not native to the base
            // @tiptap/extension-mention.
            onMentionLookup: this.onMentionLookup,
            mentionDetailsComponent: this.mentionDetailsComponent,
          },
          suggestion: {
            // Settings for our customized version of @tiptap/extension-mention. Based on
            // https://github.com/ueberdosis/tiptap/blob/main/demos/src/Nodes/Mention/Vue/suggestion.js
            render: () => {
              let component;
              let popup;

              return {
                // Called when the @ symbol is typed in a proper position to trigger mentions
                onStart: (props) => {
                  this.isSearchingMentions = true;
                  this.cancelMention = props.cancelSuggestion;
                  component = new VueRenderer(MentionSearch, {
                    props: {
                      ...props,
                      mentionDetailsComponent: this.mentionDetailsComponent,
                      onMentionAdd: this.onMentionAdd,
                      onMentionSearch: this.onMentionSearch,
                      debounceMentionSearch: this.debounceMentionSearch,
                    },
                    editor: props.editor,
                  });
                  popup = tippy('body', {
                    getReferenceClientRect: this.getMentionSearchPositioning(props),
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  });
                },
                onUpdate: (props) => {
                  component.updateProps({ ...props });
                  popup[0].setProps({
                    getReferenceClientRect: this.getMentionSearchPositioning(props),
                  });
                },
                onKeyDown: (props) => {
                  return component.ref.onKeyDown(props);
                },
                onExit: () => {
                  this.isSearchingMentions = false;
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
        }),
      );
    }

    const editorOptions = {
      content: this.value,
      editable: !this.disabled,
      editorProps: {
        handlePaste: this.pasteHandler,
      },
      parseOptions: {
        preserveWhitespace: 'full',
      },
      extensions,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onUpdate: this.onUpdate,
      onTransaction: this.onTransaction,
    };
    if (this.autofocus) {
      // Despite what the Tiptap docs say, passing in autofocus = null doesn't actually disable
      // autofocus. So we conditionally add the autofocus option here if it has a real value.
      editorOptions.autofocus = this.autofocus;
    }

    this.editor = new Editor(editorOptions);
  },
  methods: {
    clickContainer(event) {
      const isInsideEditor = event
        .composedPath()
        .some((element) => element?.matches?.('.rich-textarea'));
      const position = isInsideEditor ? null : 'end';
      this.focus(position);
    },
    EditorContentAdjust(o) {
      if (this.resizeCharLimit != null && this.resizeCharLimit > 0) {
        let pasted = false; // Checking if the key pressed is ctrl or command
        if ((o.which === 17 || o.which === 91) && !this.overLimit) pasted = true;

        const insideElement = o.target; // rootFont is used to convert from px to rem and vive versa
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        this.overLimit = insideElement.querySelector('p').innerText.length > this.resizeCharLimit;
        const newHeight = insideElement.scrollHeight + 2 * rootFontSize;
        if (!this.overLimit || pasted) {
          if (newHeight / rootFontSize > parseFloat(this.autoAdjustMaxHeight)) {
            // If the text length exceeds size limit
            this.textAreaStyles.overflowY = 'auto';
            this.textAreaStyles.height = `${parseFloat(this.autoAdjustMaxHeight) * rootFontSize}px`;
          } else {
            // Expand height and hide the scroll if text not exceeding the char limit or size limit
            this.textAreaStyles.overflowY = 'hidden';
            this.textAreaStyles.height = `${newHeight}px`;
          }
        } else {
          // If the text length exceeds char limit
          this.textAreaStyles.overflowY = 'auto';
          if (parseFloat(this.textAreaStyles.height) > newHeight) {
            // exceeds cher limit but the text size is getting smaller, still auto reduce
            this.textAreaStyles.overflowY = 'hidden';
            this.textAreaStyles.height = `${newHeight}px`;
          }
        }
        if (newHeight / rootFontSize < parseFloat(this.editorHeight)) {
          // If size has been reduced past minimum height, make it the minimum
          this.textAreaStyles.height = this.editorHeight;
        }
      }
    },
    handleMentionAdd(mention) {
      this.focus(null);
      this.onMentionAdd(mention);
    },
    updateHoveredMentionElement() {
      this.hoveredMentionElement =
        flattenElements(this.$refs.editorContent.$el).filter((element) =>
          element.matches('.mention:hover'),
        )[0] ?? null;
    },
    onBlur() {
      this.isFocused = false;
      this.$emit('blur');
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onUpdate() {
      this.$emit('input', this.editor.getHTML());
    },
    onTransaction({ transaction }) {
      const { $anchor } = transaction.curSelection;

      // Keep a copy of the cursor position so that it can be restored when we inject new
      // content in the `value` watcher.
      this.cursorPosition = $anchor.pos;

      // Find any active twitterMention mark, and use it to set the search query
      if (!this.disabled) {
        const mentionMark = $anchor.marks().find((mark) => mark.type.name === 'twitterMention');
        this.mentionSearchQuery = mentionMark?.attrs?.handle;
      }
    },
    setCursorPosition(newPosition) {
      this.editor.commands.setTextSelection(newPosition);
      this.cursorPosition = newPosition;
    },
    onEditorKeyUp(event) {
      this.hasInteractedWithEditor = true;
      if (this.isSearchingMentions) {
        if (event.key === 'Escape') {
          event.stopPropagation();
          this.cancelMention();
        }
      }
    },
    focus(position = null) {
      this.hasInteractedWithEditor = true;
      // Position options documented here: https://tiptap.dev/api/commands/focus#parameters
      if (!this.disabled) {
        this.editor.commands.focus(position);
      }
    },
    insertEmoji(emoji) {
      this.injectEditorText(emoji.native);
      this.focus();
    },
    injectEditorText(text) {
      const transaction = this.editor.state.tr.insertText(text);
      this.editor.view.dispatch(transaction);
    },
    pasteHandler(view, e) {
      // By default, if you paste content copied from a webpage, Tiptap will grab 'text/html'
      // when fetching the data from this clipboard. Tiptap ends up stripping out a bunch of content
      // when this occurs because we provide it a stripped back schema that doesn't include some
      // common HTML elements. Therefore, we override paste to pull the content from the clipboard
      // as 'text' (i.e. plaintext) and inject it into the editor manually.
      const text = e.clipboardData.getData('text').trim();
      this.injectEditorText(text);
      this.$emit('text-pasted', e);
      return true;
    },
    getMentionSearchPositioning(props) {
      // Anchor the mention search popup to an element if one is provided, otherwise anchor it to
      // the active mention.
      return this.mentionSearchTargetElement
        ? () => this.mentionSearchTargetElement.getBoundingClientRect()
        : props.clientRect;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.rich-textarea {
  position: relative;
  height: fit-content;

  &:hover {
    cursor: text;
  }

  .editor {
    position: relative;
    display: block;
    width: 100%;
    height: var(--editor-height);
    color: var(--text-primary);
    background-color: var(--background-0);
    border-radius: var(--round-corner-small);
    font-size: var(--x14);
    line-height: 1.4em;
    border: 1px solid var(--border);
    padding: var(--space-8) var(--space-48) var(--space-8) var(--space-12);
    transition: var(--transition-all);

    &.height-override:not(.resizable) {
      overflow-y: auto;
    }

    :deep(.ProseMirror-focused) {
      outline: none;
    }

    .editor-content {
      :deep(.hashtag),
      :deep(.mention) {
        color: var(--action-500);
      }
    }

    /* Custom style the placeholder text */
    :deep(p.is-empty:first-child::before) {
      content: attr(data-placeholder);
      font-size: var(--x14);
      float: left;
      pointer-events: none;
      height: 0;
      font-weight: var(--font-normal);
      color: var(--text-secondary);
    }

    :deep(.suggestion) {
      color: var(--action-500);
    }

    &.is-focused {
      border-color: var(--action-500);
    }

    &.resizable {
      resize: vertical;
      overflow: auto;
    }

    &.disabled {
      border-color: var(--border);
      background: var(--background-300);
      resize: none;
      opacity: 1;
    }
  }

  .left-button-wrapper {
    position: absolute;
    left: var(--space-16);
    cursor: auto;
  }

  .right-button-wrapper {
    position: absolute;
    right: var(--space-16);
    cursor: auto;
  }

  .emoji-picker {
    cursor: pointer;
  }

  .buttons-top {
    top: var(--space-8);
  }

  .buttons-bottom {
    bottom: var(--space-8);
  }
}
</style>
