<template>
  <router-link
    v-if="to"
    :class="classes"
    :to="to"
    :title="title"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <Icon
      v-if="iconName"
      v-bind="{ [iconSize]: true }"
      :name="iconName"
      :color="internalIconColor"
    />
    <slot />
  </router-link>
  <a
    v-else-if="href"
    :class="[classes, { 'disabled-btn': disabledState }]"
    :href="href"
    :disabled="disabledState"
    target="_blank"
    rel="noopener"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <Icon
      v-if="iconName"
      v-bind="{ [computedIconSize]: true }"
      :name="iconName"
      :color="internalIconColor"
    />
    <slot />
  </a>
  <button
    v-else
    :class="[classes, { 'disabled-btn': disabledState }]"
    :disabled="disabledState"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <Icon
      v-if="iconName"
      v-bind="{ [computedIconSize]: true }"
      :name="iconName && !loading ? iconName : null"
      :color="internalIconColor"
      :class="iconClass"
    />
    <slot />
  </button>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import GroupableMixin from '@/mixins/groupableMixin';

/**
 * Generic button component.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Button',
  components: { Icon },
  mixins: [GroupableMixin],
  props: {
    // Button styles
    primary: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
    blue: { type: Boolean, default: false },
    remove: { type: Boolean, default: false },
    back: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    rectangular: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
    dismiss: { type: Boolean, default: false },
    noHover: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    darkBlue: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    useHover: { type: Boolean, default: false },

    // Icon styles
    iconColor: { type: String, default: colours.TEXT.TEXT_PRIMARY },
    iconHoverColor: { type: String, default: colours.ACTION.ACTION_500 },
    iconName: { type: String, default: null },
    iconSize: { type: String, default: null },
    isWidthOfIcon: { type: Boolean, default: false },
    isHeightOfIcon: { type: Boolean, default: false },
    iconClass: { type: String, default: null },

    // Sizes
    roundSmall: { type: Boolean, default: false },
    closeSmall: { type: Boolean, default: false },
    xwide: { type: Boolean, default: false },
    wide: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },

    // Attributes
    color: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    title: { type: String, default: '' },
    to: { type: Object, default: null },
    /**
     * Designates component as anchor tag and sets href
     */
    href: { type: String, default: null },
  },
  data() {
    return {
      showLoading: false,
      mouseOver: false,
    };
  },
  computed: {
    classes() {
      return {
        back: this.back,
        button: !(this.link || this.dismiss),
        blue: this.blue,
        danger: this.danger,
        disabled: this.disabledState,
        dismiss: this.dismiss,
        'has-icon': !this.primary && this.iconName && !this.useHover,
        'has-icon-with-hover': !this.primary && this.iconName && this.useHover,
        light: this.light,
        white: this.white,
        link: this.link,
        loading: this.loading || this.showLoading,
        primary: this.primary || this.isGroupItemActive,
        'primary-with-icon': this.iconName && this.primary,
        rectangular: this.rectangular,
        remove: this.remove,
        round: this.round,
        'round-small': this.roundSmall,
        small: this.small,
        xsmall: this.xsmall,
        'close-small': this.closeSmall,
        wide: this.wide,
        xwide: this.xwide,
        noHover: this.noHover || this.loading || this.showLoading,
        noBorder: this.noBorder,
        darkBlue: this.darkBlue,
        'is-width-of-icon': this.isWidthOfIcon,
        'is-height-of-icon': this.isHeightOfIcon,
        bold: this.bold,
      };
    },
    computedIconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      // There should probably be additional mapping in here between button size and icon size
      // but I didn't want to break anything, so limiting scope to just "tiny" size.
      return this.xsmall ? 'tiny' : 'small';
    },
    disabledState() {
      return this.loading || this.disabled || this.showLoading;
    },
    internalIconColor() {
      return this.mouseOver && !this.disabled ? this.iconHoverColor : this.iconColor;
    },
  },
  methods: {
    spin() {
      this.showLoading = true;
    },
    stop() {
      this.showLoading = false;
    },
    click() {
      if (!this.showLoading && this.action) {
        this.spin();
        this.action();
      }
    },
    onMouseOver() {
      this.mouseOver = true;
    },
    onMouseLeave() {
      this.mouseOver = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 var(--space-24);
  margin: 0;
  border: 1px solid var(--border);
  border-radius: var(--button-border-radius);
  font-size: var(--x14);
  font-weight: var(--font-medium);
  line-height: 2rem;
  color: var(--text-primary);
  background-color: var(--background-300);
  cursor: pointer;
  transition: var(--transition-all);
  white-space: nowrap;

  --blue-dark: #162b43;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    box-shadow: var(--shadow-2);
    transform: var(--hover-move);
    color: var(--action-500);
  }

  &:active {
    transform: translateY(0);
  }

  &.noHover {
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &.noBorder {
    border: none;
  }

  &.darkBlue {
    background: var(--blue-dark);
    color: var(--white);
    border: var(--blue-dark) 1px solid;

    &:disabled:hover {
      background: var(--blue-dark);
      color: var(--white);
      border: var(--blue-dark) 1px solid;
    }

    &:hover {
      border: var(--action-500) 1px solid;
      background: var(--action-500);
    }
  }

  &.white {
    background: var(--background-0);
    color: var(--text-primary);
    border: 1px solid var(--border);

    &:hover {
      background: var(--background-500);
      border: var(--border) 1px solid;
    }
  }

  &.bold {
    font-weight: var(--font-bold);
  }
}

.link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: var(--action-500);
  font-weight: var(--font-medium);

  &:hover {
    text-decoration: underline;
  }
}

.dismiss {
  background: none !important;
  border: none;
  padding: 0 var(--space-4);
  font: inherit;
  color: var(--text-secondary);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.primary {
  background: var(--action-500);
  color: var(--white);
  border: none;

  &:hover {
    color: var(--white);
    box-shadow:
      0 1px 2px 0 rgb(255 255 255 / 10%),
      0 5px 15px 0 rgb(73 144 226 / 50%);
  }
}

.round {
  background: var(--background-0);
  color: var(--text-primary);
  border: 1px solid var(--border);
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 var(--space-4);
  border-radius: 100%;
  padding: 0;

  &:hover {
    color: var(--action-500);
  }
}

.danger {
  color: var(--error-500);

  &:hover,
  &.loading {
    background: var(--error-400);
    border: none;
    color: var(--white);
  }
}

.remove {
  background: var(--background-0);
  color: var(--text-primary);
  border: 1px solid var(--border);

  &:hover {
    color: var(--error-500);
  }
}

.light {
  background: var(--background-0);
  color: var(--text-primary);
  border: 1px solid var(--border);

  &:disabled:hover {
    color: var(--text-primary);
  }

  &:hover {
    color: var(--action-500);
  }
}

.blue {
  background: var(--blue-dark);
  color: var(--white);
  cursor: pointer;

  &:hover {
    background: var(--action-500);
    color: var(--white);
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.close:hover {
  background: var(--shadow-dark);
}

.loading {
  color: transparent !important;
  position: relative;
  transition: none;
}

.loading::after {
  content: '';
  display: block;
  border: 1px solid var(--border);
  border-left-color: rgb(255 255 255 / 20%);
  border-right-color: rgb(255 255 255 / 20%);
  border-bottom-color: rgb(255 255 255 / 20%);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  margin-left: -0.75em;
  margin-top: -0.75em;
  animation: rotate 1s linear infinite;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &.focus,
  &:hover,
  &:active {
    text-decoration: none;
    transform: none !important;
    box-shadow: none;
  }

  &.loading {
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.rectangular {
  border-radius: var(--round-corner-small);
}

.has-icon,
.has-icon-with-hover {
  background: var(--background-0);
  width: var(--space-40);
  height: var(--space-40);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.back {
    .svg-icon {
      transform: rotate(180deg);
    }

    cursor: pointer;
  }

  &.is-width-of-icon {
    width: unset;
  }

  &.is-height-of-icon {
    height: unset;
  }
}

.has-icon {
  transition: color 0.3s;

  &:hover,
  &:hover:not(:disabled),
  &:active {
    transform: none;
    box-shadow: none;
  }
}

.primary-with-icon {
  background: var(--action-500);
  color: white;

  &:hover {
    color: white;
  }

  span {
    margin: calc(-1 * var(--space-4)) var(--space-8) 0;
  }
}

.xsmall {
  height: var(--space-28);
  padding: 0 var(--space-8);
  font-size: var(--x12);
  line-height: 0;
}

.small {
  height: var(--space-32);
  font-size: var(--x13);
  line-height: 0;
}

.round-small {
  width: var(--space-32);
  height: var(--space-32);
}

.close-small {
  width: var(--space-12);
  height: var(--space-12);
}

.wide {
  min-width: 10rem;
}

.xwide {
  min-width: 12rem;
}

.sentiment {
  padding-right: var(--space-12);
}
</style>
