<template>
  <div>
    <form v-if="tab === 'On-Site'" class="style-options">
      <label
        :class="[
          'gallery',
          'js-gallery-option',
          { act: shoppableSettingsForm.viewType === 'gallery' },
        ]"
      >
        <input
          v-model="shoppableSettingsForm.viewType"
          type="radio"
          name="style"
          checked
          value="gallery"
          @change="sendInteractionMixpanelEvent('Widget Type')"
        />
        <span>Gallery</span>
      </label>

      <label
        :class="[
          'carousel',
          'js-gallery-option',
          { act: shoppableSettingsForm.viewType === 'carousel' },
        ]"
      >
        <input
          v-model="shoppableSettingsForm.viewType"
          type="radio"
          name="style"
          value="carousel"
          @change="sendInteractionMixpanelEvent('Widget Type')"
        />
        <span>Carousel</span>
      </label>
    </form>

    <div v-if="tab === 'URL'" class="form-group">
      <label>
        <span class="title-span">Gallery Title</span>
        <input
          v-model="shoppableSettingsForm.galleryTitle"
          type="text"
          name="title"
          class="title-box"
          @focus="galleryTitleBeforeModification = shoppableSettingsForm.galleryTitle"
          @blur="handleBlurGalleryTitleInput"
        />
      </label>
    </div>

    <div class="form-group">
      <label>
        <span>Column</span>
        <Select
          v-model="shoppableSettingsForm.column"
          :options="columnOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Column')"
        />
      </label>

      <label>
        <span>Gap</span>
        <Select
          v-model="shoppableSettingsForm.gap"
          :options="gapOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Gap')"
        />
      </label>

      <label v-show="shoppableSettingsForm.viewType === 'gallery'">
        <span>Row</span>
        <Select
          v-model="shoppableSettingsForm.row"
          :options="rowOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Row')"
        />
      </label>

      <label v-show="shoppableSettingsForm.viewType === 'carousel'">
        <span>Scroll Buttons</span>
        <Select
          v-model="shoppableSettingsForm.scroll"
          :options="scrollOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Scroll Buttons')"
        />
      </label>

      <label v-show="shoppableSettingsForm.viewType === 'carousel'">
        <span>Autoplay</span>
        <Select
          v-model="shoppableSettingsForm.autoplay"
          :options="autoplayOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Autoplay')"
        />
      </label>
    </div>

    <div v-if="tab !== 'Email'" class="form-group">
      <label>
        <span>
          Mobile Column
          <InfoTooltip :tooltip="tooltips.mobileColumn" />
        </span>
        <Select
          v-model="shoppableSettingsForm.mobileColumn"
          :options="mobileColumnOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Mobile Column')"
        />
      </label>

      <label>
        <span>
          Mobile Gap
          <InfoTooltip :tooltip="tooltips.mobileGap" />
        </span>
        <Select
          v-model="shoppableSettingsForm.mobileGap"
          :options="mobileGapOptions"
          border
          full-width
          @update:model-value="sendInteractionMixpanelEvent('Mobile Gap')"
        />
      </label>
    </div>

    <div class="form-group link-options">
      <label class="link-setting">
        <CheckBox
          v-model="shoppableSettingsForm.customLinks"
          :tooltip="tooltips.customLinks"
          label="Enable custom links"
          @update:model-value="sendInteractionMixpanelEvent('Custom Links')"
        />
      </label>

      <label v-if="tab !== 'Email'">
        <span>Call to Action</span>
        <Select
          v-model="shoppableSettingsForm.callToAction"
          :disabled="!shoppableSettingsForm.customLinks"
          :options="callToActionOptions"
          border
          @update:model-value="sendInteractionMixpanelEvent('Call To Action')"
        />
      </label>

      <div v-else class="form-group guide-link">
        <a
          href="https://developer.dashhudson.com/docs/shoppable-galleries-doc"
          target="_blank"
          rel="noopener"
          @click="sendInteractionMixpanelEvent('Customization Guide')"
        >
          Customization Guide
        </a>
      </div>
    </div>

    <div v-if="tab !== 'Email'" class="form-group guide-link">
      <a
        href="https://developer.dashhudson.com/docs/shoppable-galleries-doc"
        target="_blank"
        rel="noopener"
        @click="sendInteractionMixpanelEvent('Customization Guide')"
      >
        Customization Guide
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { toolTips } from '@/config';
import CheckBox from '@/components/foundation/CheckBox.vue';
import Select from '@/components/Select.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SettingGroup',
  components: {
    CheckBox,
    InfoTooltip,
    Select,
  },
  props: {
    tab: { type: String, default: 'On-Site' },
    value: { type: Object, default: null },
  },
  emits: ['input'],
  data() {
    return {
      shoppableSettingsForm: {
        standAloneURLEnabled: false,
        viewType: 'gallery',
        column: '4',
        gap: '4',
        row: 'auto',
        scroll: 'show',
        autoplay: 'on',
        mobileColumn: '4',
        mobileGap: '2',
        customLinks: true, // CheckBox value prop is expecting boolean instead of number
        callToAction: '-',
      },
      tooltips: {
        mobileColumn: toolTips.shoppableSettings.mobileColumn,
        mobileGap: toolTips.shoppableSettings.mobileGap,
        customLinks: toolTips.shoppableSettings.customLinks,
      },
      galleryTitleBeforeModification: null,
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
    columnOptions() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
        label: i.toString(),
        value: i.toString(),
      }));
    },
    gapOptions() {
      return [1, 2, 4, 6, 8, 10].map((i) => ({ label: i.toString(), value: i.toString() }));
    },
    rowOptions() {
      const fullOptions = [
        { label: 'no limit', value: 'auto' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
      ];
      if (this.tab === 'Email') {
        return fullOptions.filter((option) => option.value !== 'auto');
      }
      return fullOptions;
    },
    scrollOptions() {
      return ['show', 'hide'].map((i) => ({ label: i, value: i }));
    },
    autoplayOptions() {
      return ['on', 'off'].map((i) => ({ label: i, value: i }));
    },
    mobileColumnOptions() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
        label: i.toString(),
        value: i.toString(),
      }));
    },
    mobileGapOptions() {
      return [1, 2, 4, 6, 8, 10].map((i) => ({ label: i.toString(), value: i.toString() }));
    },
    callToActionOptions() {
      return [
        { label: '-', value: '-' },
        { label: 'learn more', value: 'learn_more' },
        { label: 'shop now', value: 'shop_now' },
        { label: 'book now', value: 'book_now' },
      ];
    },
  },
  watch: {
    shoppableSettingsForm: {
      handler(to) {
        this.$emit('input', to);
      },
      deep: true,
    },
  },
  mounted() {
    this.shoppableSettingsForm = this.value;
  },
  methods: {
    sendInteractionMixpanelEvent(settingChanged) {
      this.trackingStore.track('Shoppable Gallery Settings Interaction', {
        action: settingChanged,
        ...this.shoppableSettingsForm,
      });
    },
    handleBlurGalleryTitleInput() {
      if (this.galleryTitleBeforeModification !== this.shoppableSettingsForm.galleryTitle) {
        this.sendInteractionMixpanelEvent('Gallery Title');
      }
      this.galleryTitleBeforeModification = this.shoppableSettingsForm.galleryTitle;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.form-group {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  label {
    flex: 1 1 auto;
    padding: 0 var(--space-8);
  }

  label > span {
    line-height: var(--space-24);
  }

  label button {
    height: 40px;
  }

  .icon {
    transform: translateY(2px);
  }

  label.shoppable-link {
    padding-top: var(--space-32);
    cursor: pointer;
  }

  .title-span {
    width: 50%;
    margin: auto auto var(--space-4);
  }

  .title-box {
    width: 50%;
    margin: auto;
  }
}

.style-options {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: left;

  label {
    flex: 1 1;
    padding: 24px 30px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: var(--x16);
    font-weight: var(--font-medium);
    margin-bottom: 0;

    span {
      display: inline;
    }

    input[type='radio'] {
      margin-right: var(--space-8);
    }
  }

  label:first-child {
    margin-right: var(--space-16);
  }

  label.gallery {
    background: url('@/assets/img/gallery-view.svg') var(--background-300) 95% center no-repeat;
  }

  label.carousel {
    background: url('@/assets/img/carousel-view.svg') var(--background-300) 95% center no-repeat;
  }

  label.act {
    border-color: var(--action-500);
  }
}

.link-setting {
  display: flex;
  align-items: center;
  margin-top: var(--space-16);
  margin-left: calc(-1 * var(--space-4));
}

.guide-link {
  justify-content: flex-end;
  align-items: center;
  margin-right: var(--space-8);

  a {
    color: var(--action-500);
    float: right;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 30rem) {
  .pilltab-container {
    width: 100%;
  }

  .style-options {
    label {
      height: 10rem;
    }

    label.gallery {
      background: url('@/assets/img/gallery-view.svg') var(--background-300) 50% bottom no-repeat;
      background-position-y: 85%;
    }

    label.carousel {
      background: url('@/assets/img/carousel-view.svg') var(--background-300) 50% center no-repeat;
      background-position-y: 80%;
    }
  }

  .form-group {
    flex-direction: column;
  }
}
</style>
