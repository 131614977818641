import { exhaustiveMap } from '@/utils';

export const PLATFORMS = {
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
};

export const ALL_PLATFORMS = Object.values(PLATFORMS);

export const DIALOG_TYPES = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  PINTEREST: 'pinterest',
  PINTEREST_BULK: 'pinterestBulk',
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  LINKEDIN: 'linkedin',
};

export const DIALOG_TYPE_PLATFORMS = exhaustiveMap(DIALOG_TYPES, {
  [DIALOG_TYPES.FACEBOOK]: PLATFORMS.FACEBOOK,
  [DIALOG_TYPES.INSTAGRAM]: PLATFORMS.INSTAGRAM,
  [DIALOG_TYPES.PINTEREST]: PLATFORMS.PINTEREST,
  [DIALOG_TYPES.PINTEREST_BULK]: PLATFORMS.PINTEREST,
  [DIALOG_TYPES.TWITTER]: PLATFORMS.TWITTER,
  [DIALOG_TYPES.TIKTOK]: PLATFORMS.TIKTOK,
  [DIALOG_TYPES.LINKEDIN]: PLATFORMS.LINKEDIN,
});

export const MULTI_CHANNEL_POST_TYPES = {
  TIKTOK_POST: 'TIKTOK_POST',
  INSTAGRAM_POST: 'INSTAGRAM_POST',
  INSTAGRAM_STORY_FRAME: 'INSTAGRAM_STORY_FRAME',
  FACEBOOK_POST: 'FACEBOOK_POST',
  PINTEREST_PIN: 'PINTEREST_PIN',
  TWITTER_TWEET: 'TWITTER_TWEET',
  LINKEDIN_POST: 'LINKEDIN_POST',
  APPROVAL_PENDING_POST: 'APPROVAL_PENDING_POST',
};

export const MULTI_CHANNEL_POST_DESCRIPTIONS = exhaustiveMap(MULTI_CHANNEL_POST_TYPES, {
  TIKTOK_POST: 'post',
  INSTAGRAM_POST: 'post',
  INSTAGRAM_STORY_FRAME: 'story frame',
  FACEBOOK_POST: 'post',
  PINTEREST_PIN: 'pin',
  TWITTER_TWEET: 'post',
  LINKEDIN_POST: 'post',
  APPROVAL_PENDING_POST: 'post',
});

export const PLATFORMS_TO_MULTI_CHANNEL_POST_TYPES = exhaustiveMap(PLATFORMS, {
  tiktok: 'TIKTOK_POST',
  instagram: 'INSTAGRAM_POST',
  facebook: 'FACEBOOK_POST',
  pinterest: 'PINTEREST_PIN',
  twitter: 'TWITTER_TWEET',
  linkedin: 'LINKEDIN_POST',
});

export const textProps = exhaustiveMap(PLATFORMS, {
  facebook: 'message',
  instagram: 'caption',
  pinterest: 'note',
  twitter: 'tweet_status',
  tiktok: 'text',
  linkedin: 'text',
});

export const platformLabels = exhaustiveMap(PLATFORMS, {
  facebook: 'Facebook',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
  twitter: 'X',
  tiktok: 'TikTok',
  linkedin: 'LinkedIn',
});

export const shopProps = {
  facebook: 'productTags',
  instagram: 'shoppingTags',
};

export const postDescriptions = exhaustiveMap(PLATFORMS, {
  twitter: 'X Post',
  pinterest: 'Pinterest pin',
  instagram: 'Instagram post',
  facebook: 'Facebook post',
  tiktok: 'TikTok post',
  linkedin: 'LinkedIn post',
});

export const postTypes = {
  FEED: 'FEED',
  STORY: 'STORY',
  REEL: 'REEL',
};

export const fbPostTypes = {
  POST: 'POST',
  REEL: 'REEL',
};

export const tabContexts = {
  FEED_POST: 'Feed Post',
  STORY: 'Story',
  REEL: 'Reel',
};

export const postStatus = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  USERS_NOTIFIED: 'USERS_NOTIFIED',
  POSTED: 'POSTED',
  IMPORTED: 'IMPORTED',
  EXPIRED: 'EXPIRED',
  AUTOPUBLISHING: 'AUTOPUBLISHING',
  FAILED: 'FAILED',
  SKIPPED: 'SKIPPED',
  PARTIALLY_FAILED: 'PARTIALLY_FAILED',
  MISSING_APPROVALS: 'MISSING_APPROVALS',
};

export const autoPublishErrors = {
  AUTO_PUBLISH_LIMIT_EXCEEDED: 'AUTO_PUBLISH_LIMIT_EXCEEDED',
  ACCOUNT_RESTRICTED: 'ACCOUNT_RESTRICTED',
  DUPLICATE_POST: 'DUPLICATE_POST',
  MISSING_AUTH: 'MISSING_AUTH',
  INVALID_AUTH: 'INVALID_AUTH',
  INVALID_IMAGE: 'INVALID_IMAGE',
  INTERNAL: 'INTERNAL',
  INVALID_PIN_BOARD: 'INVALID_PIN_BOARD',
  PAGE_PUBLISHING_AUTH_REQUIRED: 'PAGE_PUBLISHING_AUTH_REQUIRED',
  REJECTED: 'REJECTED',
  TEXT_LENGTH_EXCEEDED: 'TEXT_LENGTH_EXCEEDED',
  AGAINST_COMMUNITY_STANDARDS: 'AGAINST_COMMUNITY_STANDARDS',
  INVALID_ACCOUNT_TYPE: 'INVALID_ACCOUNT_TYPE',
  FLAGGED_AS_SPAM: 'FLAGGED_AS_SPAM',
  OUTSIDE_APPROVED_PUBLISHING_DATES: 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES',
  APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP: 'APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP',
  INVALID_TARGETING: 'INVALID_TARGETING',
};

export const NoAccessToPage = (platformName) => {
  return {
    PLAN_TITLE: "This feature isn't included in your plan",
    PLAN_MESSAGE: 'Contact your Customer Success Representative to try it out!',
    USER_MESSAGE: `You don't have the proper permissions enabled to schedule ${platformName} posts. Ask your Dash Hudson administrator to provide you access.`,
  };
};

export const firstCommentStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const videoStatus = {
  PROCESSING: 'PROCESSING',
  PROCESSING_FAILED: 'PROCESSING_FAILED',
  REVIEWING: 'REVIEWING',
  REVIEWING_FAILED: 'REVIEWING_FAILED',
  SUCCESS: 'SUCCESS',
};

export const popUpMessages = {
  removeFromScheduler: {
    title: 'Remove scheduled post?',
    message:
      'This will remove your post from your scheduled content. It will still remain in your library!',
  },
};

export const removeApprovalRequestMessage = (name) => {
  return {
    title: 'Remove Approval Request?',
    message: `This will remove ${name} as an approver of this post. Are you sure you want to proceed?`,
  };
};

export const thumbnailSourceType = {
  VISION: 'VISION',
  SLIDER: 'SLIDER',
  CUSTOM: 'CUSTOM',
};

export const mixpanelMentionWarningType = {
  POST: 'post', // user tried adding more than allowed @mentions to a single tweet
  DAY: 'day', // user tried scheduling a tweet with @mentions and exceeded their 24h limit
};

export const mixpanelActions = {
  DUPLICATE_POST_CLICKED: 'Duplicate Post Clicked',
  LINK_UPDATE_CLICKED: 'Link Update Clicked',
  SCHEDULER_CALENDAR_OPENED: 'Scheduler Calendar Opened',
  INVITE_COLLABORATORS_CLICKED: 'Invite Collaborators Clicked',
};

export const adGeolocationFields = {
  COUNTRY: 'country',
  COUNTRY_GROUP: 'country_group',
};

// Detailed Slab doc about validations:
// https://dashhudson.slab.com/posts/scheduler-media-validations-wr26a2si
export const minRecommendedImageWidth = 1080;
export const minRecommendedVideoWidth = 600;

export const MAX_ALT_TEXT_LENGTH = 1000;

export const mediaValidationMap = {
  // Instagram validations from https://developers.facebook.com/docs/instagram-api/reference/ig-user/media#image-specifications
  instagramFeedPost: {
    aspectRatio: {
      image: {
        min: '4:5',
        max: '1.91:1',
        // Actual IG min is slightly less than 4:5
        minNumeric: 0.792,
        // Actual IG max is slightly more than 1.91:1
        maxNumeric: 1.92,
        platformName: 'Instagram',
      },
      video: {
        min: '4:5',
        max: '16:9',
        minNumeric: 0.79,
        maxNumeric: 1.78,
        platformName: 'Instagram',
      },
    },
    dimensions: {
      video: {
        maxWidth: '1920 pixels',
        maxWidthNumeric: 1920,
        maxHeight: '1920 pixels',
        maxHeightNumeric: 1920,
        platformName: 'Instagram',
      },
    },
    duration: {
      video: {
        min: '3 sec',
        max: '60 sec',
        minNumeric: 3,
        maxNumeric: 60,
        platformName: 'Instagram',
      },
    },
    fileSize: {
      image: {
        max: '8 MB',
        maxNumeric: 8 * 1024 * 1024,
        platformName: 'Instagram',
      },
      video: {
        max: '100 MB',
        maxNumeric: 100 * 1024 * 1024,
        platformName: 'Instagram',
      },
    },
  },
  instagramReel: {
    aspectRatio: {
      video: {
        min: '9:16',
        max: '16:9',
        minNumeric: 0.5625,
        maxNumeric: 1.78,
        platformName: 'Instagram',
      },
    },
    dimensions: {
      video: {
        maxWidth: '1920 pixels',
        maxWidthNumeric: 1920,
        platformName: 'Instagram',
      },
    },
    duration: {
      video: {
        min: '3 sec',
        max: '15 min',
        minNumeric: 3,
        maxNumeric: 900,
        platformName: 'Instagram',
      },
    },
    fileSize: {
      video: {
        max: '100 MB',
        maxNumeric: 100 * 1024 * 1024,
        platformName: 'Instagram',
      },
      coverImage: {
        max: '8 MB',
        maxNumeric: 8 * 1024 * 1024,
        platformName: 'Instagram',
      },
    },
  },
  instagramStory: {
    aspectRatio: {
      video: {
        min: '9:16',
        max: '16:9',
        minNumeric: 0.5625,
        maxNumeric: 1.78,
        platformName: 'Instagram',
      },
      image: {
        min: '9:16',
        max: '16:9',
        minNumeric: 0.5625,
        maxNumeric: 1.78,
        platformName: 'Instagram',
      },
    },
    dimensions: {
      video: {
        maxWidth: '1920 pixels',
        maxWidthNumeric: 1920,
        platformName: 'Instagram',
      },
    },
    duration: {
      video: {
        // Max is 60 seconds if feature flag for auto-publishing enabled
        // As validation only for auto-publish, changed here.
        // Limit not announced by Meta
        min: '3 sec',
        max: '60 sec',
        minNumeric: 3,
        maxNumeric: 60,
        platformName: 'Instagram',
      },
    },
    fileSize: {
      image: {
        max: '8 MB',
        maxNumeric: 8 * 1024 * 1024,
        platformName: 'Instagram',
      },
      video: {
        max: '50 MB',
        maxNumeric: 50 * 1024 * 1024,
        platformName: 'Instagram',
      },
    },
  },
  twitter: {
    // Limits from https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/uploading-media/media-best-practices
    aspectRatio: {
      video: {
        min: '1:3',
        max: '3:1',
        minNumeric: 0.3,
        maxNumeric: 3,
        platformName: 'X',
      },
    },
    dimensions: {
      image: {
        min: '4 pixels',
        max: '8192 pixels',
        minNumeric: 4,
        maxNumeric: 8192,
        platformName: 'X',
      },
    },
    duration: {
      video: {
        // The max length is edited in utils/index.js
        // depending on whether the brand has the allowLongTwitterVideos featureflag
        min: '0.5 sec',
        minNumeric: 0.5,
        max: '140 sec',
        maxNumeric: 140,
        platformName: 'X',
      },
    },
    fileSize: {
      image: {
        max: '5 MB',
        maxNumeric: 5 * 1024 * 1024,
        platformName: 'X',
      },
      video: {
        max: '512 MB',
        maxNumeric: 512 * 1024 * 1024,
        platformName: 'X',
      },
    },
  },
  tiktok: {
    // Limits from https://bytedance.us.feishu.cn/docs/doccnq3SeHrZkncd0AdxL69MCEe#DMh0RO
    dimensions: {
      video: {
        min: '480 pixels',
        minNumeric: 480,
        platformName: 'TikTok',
      },
    },
    duration: {
      video: {
        // Allowance for 10 min videos was released on May 16th, though this is not reflected in the
        // TikTok API docs
        min: '3 sec',
        minNumeric: 3,
        max: '10 min',
        maxNumeric: 600,
        platformName: 'TikTok',
      },
    },
    fileSize: {
      video: {
        max: '1 GB',
        maxNumeric: 1024 * 1024 * 1024,
        platformName: 'TikTok',
      },
    },
  },
  pinterest: {
    // Limits from https://help.pinterest.com/en/business/article/pinterest-product-specs
    aspectRatio: {
      video: {
        min: '1:2',
        max: '1.91:1',
        minNumeric: 0.5,
        maxNumeric: 1.91,
        platformName: 'Pinterest',
      },
    },
    duration: {
      video: {
        min: '4 sec',
        minNumeric: 4,
        max: '15 min',
        maxNumeric: 900,
        platformName: 'Pinterest',
      },
    },
    fileSize: {
      image: {
        max: '32 MB',
        maxNumeric: 32 * 1024 * 1024,
        platformName: 'Pinterest',
      },
      video: {
        max: '2 GB',
        maxNumeric: 2 * 1024 * 1024 * 1024,
        platformName: 'Pinterest',
      },
    },
    dimensions: {
      image: {
        min: '100 pixels',
        minNumeric: 100,
        maxArea: '89.4 megapixels',
        maxAreaNumeric: 89478485, // (w * h)
        platformName: 'Pinterest',
      },
    },
  },
  facebook: {
    // Dimensions and aspect ratios to be permissive
    dimensions: {
      video: {
        min: '120 pixels',
        minNumeric: 120,
        platformName: 'Facebook',
      },
      image: {
        maxArea: '64,000,000 pixels',
        maxAreaNumeric: 8000 * 8000,
        platformName: 'Facebook',
      },
    },
    aspectRatio: {
      video: {
        min: '1:4',
        max: '4:1',
        minNumeric: 0.25,
        maxNumeric: 4,
        platformName: 'Facebook',
      },
    },
    duration: {
      video: {
        // Limits half of FB non-resumable limits (to avoid overwhelming DH)
        // https://developers.facebook.com/docs/video-api/guides/publishing#limitations-2
        min: '1 sec',
        minNumeric: 1,
        max: '10 min',
        maxNumeric: 600, // sec
        trimmerMax: '15 min',
        trimmerMaxNumeric: 900, // sec
        platformName: 'Facebook',
      },
    },
    fileSize: {
      image: {
        max: '4 MB',
        maxNumeric: 4 * 1024 * 1024, // In bytes
        platformName: 'Facebook',
      },
      video: {
        max: '500 MB',
        maxNumeric: 500 * 1024 * 1024, // In bytes
        platformName: 'Facebook',
      },
    },
  },
  linkedin: {
    dimensions: {
      image: {
        maxArea: '36152320 pixels',
        maxAreaNumeric: 36152320,
        platformName: PLATFORMS.LINKEDIN,
      },
    },
  },
};

export const pinterestBoardEmptyMessage = 'Pin Board is required for auto-publishing.';
export const pinterestNoImageMessage = 'Please add one image to auto-publish.';
export const pinterestBadMediaRequirementsMessage =
  'For publishing these Pins, please make sure all media meet the requirement of content formats on Pinterest.';

// Limits from https://help.pinterest.com/en/business/article/pinterest-product-specs
export const pinterestCaptionLimit = {
  title: 100,
  description: 500,
};
export const pinterestMediaCountLimit = 10;
export const pinterestMediaCountLimitMessage = `You can only select up to ${pinterestMediaCountLimit} images at one time for bulk publishing.`;
export const pinterestVideoProcessInfoMessage =
  'Videos require manual review & approval from Pinterest. This process may take up to 48 hours from the publishing time in Dash Hudson.';
export const pinterestCarouselPublishing = `Pinterest carousel publishing is not supported at this time. Please select one piece of media and try again.`;

export const somethingWentWrongTitle = 'Something Went Wrong';
export const somethingWentWrongMessage = `Sorry, it looks like something went wrong while saving your post.  Please try again.`;

export const shoppingTagsSubScreenTitle = 'Add Product Tags';
export const shoppingTagsSubScreenEmptyStateMessage = 'Click on media to tag products';

export const schedulerPostsSortTypes = {
  INDEX_DESC: '-SORT_INDEX',
  TIMESTAMP_ASC: 'TIMESTAMP',
  TIMESTAMP_DESC: '-TIMESTAMP',
};

export const tagPeoplePromptMessage = 'Click on the photo to tag people';
export const tagPeopleVideoErrorMessage = 'You cannot tag people on videos';

export const maxUserTags = 20;
export const maxShoppingTagsPerMedia = 5;

export const maxCollaborators = 3;
export const maxShoppingTagsPerPost = 20;
export const maxTagsPerMedia = Math.min(maxUserTags, maxShoppingTagsPerMedia);
export const maxTagsPerPost = Math.min(maxUserTags, maxShoppingTagsPerPost);

export const tooManyUserTagsMessage = `This post can have a maximum of ${maxUserTags} tags, which include both user tags and collaborators. Please remove tags to continue.`;
export const tooManyShoppingTagsPerMediaMessage = `Only ${maxShoppingTagsPerMedia} product tags can be added per piece of media`;
export const tooManyShoppingTagsPerPostMessage = `Only ${maxShoppingTagsPerPost} total product tags can be added to a post`;
export const tooManyTagsPerMediaMessage = `Only ${maxTagsPerMedia} total people and product tags can be added per piece of media`;
export const tooManyTagsPerPostMessage = `Only ${maxTagsPerPost} total people and product tags can be added to a post`;
export const linkPromptMessage =
  'Cropping your media may have repositioned your people, product tags, and links. Please confirm their position before saving.';
export const mediaCropLinkTagsMessage =
  'People, product tags, and links may change position after cropping. They can be repositioned later.';
export const mediaCropLinkMessage =
  'Cropping your media may have repositioned your links. Please confirm their position before saving.';
export const mediaCropLinkTagsTitle = 'Cropping media may reposition tags and links';
export const schedulerPostBadge = {
  FAILED: 'failed',
  OVERDUE: 'overdue',
  PENDING: 'pending',
  APPROVED: 'approved',
};

export const facebookTagsCropConfirmTitle = 'Cropping media may reposition tags';
export const facebookTagsCropConfirmMessage =
  'Product tags may change position after cropping. They can be repositioned later.';
export const facebookTagsCropWarningMessage =
  'Cropping your media may have repositioned your product tags. Please confirm their position before saving.';

export const eligibilityCallFailureNotificationMessage = 'Something Went Wrong';
export const eligibilityCallFailureNotificationSubtext =
  'This feature is currently unavailable due to an API error. Please try again later or if the issue persists, contact your Customer Success Representative.';

export const calendarDragReschedulingFailureNotificationMessage = 'Reschedule Failed';
export const calendarDragReschedulingFailureNotificationSubtext =
  'This post could not be rescheduled. Please try again. If the issue persists contact your Customer Success Representative.';

export const notEligibleMessageTitle = "You aren't eligible for Instagram Shopping";
export const notEligibleMessageContent =
  "You don't meet Facebook's Commerce Eligibility Requirements. This may be because you don't reside in a supported market or aren't compliant with Facebook's policies.";
export const instagramCommerceEligibilityRequirementsLink =
  'https://www.facebook.com/help/instagram/1627591223954487?helpref=faq_content';
export const instagramCarouselAspectRatioWarning =
  'Instagram will crop carousel cards to match the aspect ratio of the first card in the series. Please ensure all media has the same aspect ratio to avoid undesirable cropping and changed tag positions.';

export const notEligibleFacebookMessageTitle = "You aren't eligible for Facebook Shopping";
export const facebookCommerceEligibilityRequirementsLink =
  'https://www.facebook.com/business/help/2347002662267537';

export const enableFacebookShoppingAccessTitle = 'Enable Access to Facebook Shopping';
export const enableFacebookShoppingAccessMessage =
  'To add product tags to media you must authorize Dash Hudson to access Facebook Shopping.';
export const enableFacebookShoppingAccessButtonText = 'Connect Facebook Shopping';

export const enableInstagramShoppingAccessTitle = 'Enable Access to Instagram Shopping';
export const enableInstagramShoppingAccessMessage =
  'To add product tags to media you must authorize Dash Hudson to access Instagram Shopping.';
export const enableInstagramShoppingAccessButtonText = 'Connect Instagram Shopping';

export const tweetIsBeingSaved = 'Your post is currently being saved.';
export const tweetRemovedFromTimeline = 'Post removed from your timeline.';
export const tweetRemovedFromUnscheduled = 'Post removed from your unscheduled posts.';
export const tweetUpdatedAndAddedToTimeline = 'Post updated & added to your timeline.';
export const tweetAddedToTimeline = 'Post added to your timeline.';

export const twitterDailyMentionLimit = 100;

export const twitterMentionLimitExceededText = `You've already reached your limit of ${twitterDailyMentionLimit} Posts including @mentions within a 24-hour period. Please remove the @mention from your Tweet or schedule this for another time.`;
export const mediaLimitExceededText = 'You can only select up to 4 photos or a single video.';
export const tweetTooLongText = 'Your Post is too long.';
export const emptyTweetInThreadText = 'One or more of the Posts in your thread are empty.';
export const emptyTweetText = 'Your Post is empty.';
export const twitterNotConnectedText = 'To edit a post, you need to connect your X account.';

export const errorAltTextTooLong = 'Your alt text must be 1,000 characters or less.';
export const altTextErrorNotificationText =
  'Alt Text can not exceed 1,000 characters. Please shorten it and try again.';
export const altTextRemovedNotification = 'Alt text has been successfully removed.';
export const altTextSavedNotification = 'Alt text has been saved successfully.';

export const invalidShoppingTagsWarningMessage =
  'There is an issue with one or more of your product tags, please remove the tags to save your post.';
export const invalidCollaboratorsWarningMessage =
  "We were unable to include the following collaborators because they're invalid or private accounts";
export const invalidUserTagsWarningMessage =
  "We were unable to include the following user tags because they're invalid or private accounts";
export const publishDateRangeError = 'Outside of Approved Publishing Date';
export const publishDateOutOfRangeSubtext = 'Asset(s) can only be published between ';
export const noOverlapPublishDateSubtext = 'Asset(s) selected do not have an overlapping date.';

export const unknownApiErrorMessage =
  'Something went wrong saving your post. Please try again later.';

export const noDuplicationForTwitterThreads = 'Duplication is not supported for Post threads.';

export const linkedinNotConnectedText =
  'To edit a post, you need to connect your LinkedIn account.';

export const draftOverdueBannerText =
  'This post could not be published because it is currently a Draft. Choose a new publication time and disable the Draft option.';

export const postSources = {
  SCHEDULED: 'Scheduled Post',
  UNSCHEDULED: 'Unscheduled Post',
  NEW: 'New Post',
};

export const mixpanelCRUDAction = {
  CREATED: 'Created',
  DELETED: 'Deleted',
  UPDATED: 'Updated',
};

export const postPublishType = {
  AUTO_PUBLISH: 'Auto Publish',
  SEND_NOTIFICATION: 'Send Notification',
};

export const UUID = Symbol('UUID');

export const ORIGINAL = Symbol('ORIGINAL');

export const inlineBannerInfo = Object.freeze({
  QR_CODE_LINK: 'https://qrco.de/dashhudsonapp',
  LINK: {
    text: 'Learn More',
    url: 'https://help.dashhudson.com/hc/en-us/articles/14920389737357',
  },
  STORAGE_KEY: 'inlineSchedulerAppBanner',
  TEXT: 'The Dash Hudson mobile app is needed to <br> publish via Send Notification.',
});

export const postPublishInfoTooltip = {
  AUTO_PUBLISH:
    'Dash Hudson takes care of publishing your post automatically at the date and time you have selected.',
  SEND_NOTIFICATION:
    'The Dash Hudson mobile app will send a push notification when it’s time to post. You can proceed to publish the post manually by tapping the notification.',
  TIKTOK_CAPTION:
    'Your caption will copy to your mobile device’s clipboard when you tap “Open in TikTok” at the scheduled time to post.',
  IG_REEL_CAPTION:
    'Your caption will copy to your mobile device’s clipboard when you tap “Publish Now” at the scheduled time to post.',
  IG_FEED_CAPTION:
    'Your caption will copy to your mobile device’s clipboard when you tap “Open in Instagram” at the scheduled time to post.',
  FIRST_COMMENT:
    'Use the first comment on your post to add additional hashtags or extend the post’s caption.',
  ADD_NOTE:
    'Use this space to draft copy for your Story. You can manually copy and paste the text when it’s time for your post to go live.',
  LINK_STICKER:
    'Your link will automatically copy to your clipboard when you tap “Publish Now” at the scheduled time to post.',
};

export const schedulerFAQLink = 'https://help.dashhudson.com/hc/en-us/articles/14919531348749';

export const SCHEDULER_FILTERS = {
  FILTER_PLATFORM: 'scheduler_platform',
};

export const SCHEDULER_PLATFORM_FILTER_WIDGET = {
  TIKTOK: {
    title: 'TikTok',
    iconName: 'tiktok',
    value: 'TIKTOK',
  },
  INSTAGRAM: {
    title: 'Instagram',
    iconName: 'instagram',
    value: 'INSTAGRAM',
  },
  INSTAGRAM_STORY: {
    title: 'Instagram Stories',
    iconName: 'instagramStories',
    value: 'INSTAGRAM_STORY',
  },
  FACEBOOK: {
    title: 'Facebook',
    iconName: 'facebook',
    value: 'FACEBOOK',
  },
  PINTEREST: {
    title: 'Pinterest',
    iconName: 'pinterest',
    value: 'PINTEREST',
  },
  TWITTER: {
    title: 'X',
    iconName: 'twitter',
    value: 'TWITTER',
  },
  LINKEDIN: {
    title: 'LinkedIn',
    iconName: 'linkedin',
    value: 'LINKEDIN',
  },
};

export const FILTER_TYPE = {
  PLATFORM: 'Platform',
};

export const SCHEDULER_FILTER_WIDGETS = {
  scheduler_platform: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Platform',
    noDuplicate: true,
    options: {
      filterOptions: Object.values(SCHEDULER_PLATFORM_FILTER_WIDGET).map((platform) => {
        return {
          label: platform.title,
          formattedLabel: platform.title,
          icon: {
            channelIcon: true,
            name: platform.iconName,
          },
          value: platform.value,
        };
      }),
    },
    type: FILTER_TYPE.PLATFORM,
  },
};

export const SCHEDULER_ICON_NAMES = {
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  INSTAGRAM_STORY: 'instagramStories',
  FACEBOOK: 'facebook',
  PINTEREST: 'pinterest',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
};

export const USER_TAG_TYPE = {
  MEDIA_TAG: 'mediaTag',
  COLLABORATOR: 'collaborator',
};

export const SCHEDULER_FILTER_PLATFORM = [
  {
    label: '',
    field: SCHEDULER_FILTERS.FILTER_PLATFORM,
    imgFilter: true,
    emptyOperator: true,
    valueToIconMap: SCHEDULER_ICON_NAMES,
    value: Object.keys(SCHEDULER_ICON_NAMES),
    emptyStates: false,
    type: FILTER_TYPE.PLATFORM,
  },
];

export const SCHEDULER_ADD_POST_DROPDOWN_OPTIONS = [
  {
    text: 'TikTok',
    iconName: 'tiktok',
    action: 'tiktok',
  },
  {
    text: 'Instagram Post',
    iconName: 'instagram',
    action: 'instagram',
  },
  {
    text: 'Facebook Post',
    iconName: 'facebook',
    action: 'facebook',
  },
  {
    text: 'Pinterest Single Pin',
    iconName: 'pinterest',
    action: 'pinterest',
  },
  {
    text: 'Pinterest Multiple Pins',
    iconName: 'pinterest',
    action: 'pinterestBulk',
  },
  {
    text: 'X Post',
    iconName: 'twitter',
    action: 'twitter',
  },
  {
    text: 'LinkedIn Post',
    iconName: 'linkedin',
    action: 'linkedin',
  },
];

export const DATE_RESTRICTION_ERRORS = {
  APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP: 'APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP',
  OUTSIDE_OF_APPROVED_PUBLISHING_DATES: 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES',
};

export const EXPIRED_PUBLISHING_DATES_SCHEDULED_TIME_ERROR =
  'The approved publishing dates for media have expired';

export const INCOMPATIBLE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR =
  'Media selected has incompatible approved date ranges';

export const OUTSIDE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR =
  'Scheduled media is not approved to publish on this date';

export const CROSS_DUPLICATE_POST_TO_BRAND_KEYS = [
  'altTextMediaMap',
  'autoPublish',
  'location',
  'locationId',
  'media',
  'mediaIds',
  'message',
  'platform',
  'tweetStatus',
  'text',
  'video_title',
  'thumb_offset',
  'targeting',
];

export const CROSS_BRAND_DUPLICATE_ERROR =
  "Your post didn't duplicate successfully. Please return to the original post and try again.";

export const FACEBOOK_SUCCESSFUL_PUBLISH_WITH_TARGETING_MESSAGE =
  'Facebook post successfully published to selected location(s). Only Facebook page admins can view this post outside of the chosen location(s).';

export const INSTAGRAM_COLLABORATOR_INFORMATION_BANNER_TEXT =
  "Invite up to three users to collaborate on this post. Once accepted, this post will appear on each collaborator's Feed or Reels, sharing metrics like comments and likes.";

export const APPROVAL_POLICY = {
  APPROVAL_REQUIRED: 'APPROVAL_REQUIRED',
  APPROVAL_NOT_REQUIRED: 'APPROVAL_NOT_REQUIRED',
};

export const APPROVAL_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  UNASSIGNED: 'UNASSIGNED',
};

export const PUBLISHING_APPROVALS_OVERRIDE_BANNER_LOCK =
  'This post is currently unlocked. Lock this post to require approval before it goes live.';
export const PUBLISHING_APPROVALS_OVERRIDE_BANNER_UNLOCK =
  'This post is currently locked. Unlock to enable this post to go live without approvals.';

export const APPROVALS_BANNER = {
  APPROVAL_NOT_REQUIRED:
    'This post will be published at the scheduled time as its approval policy is currently unlocked.',
  APPROVAL_REQUIRED:
    'This post needs approval before publishing. If not approved by the scheduled time, it will not publish and must be rescheduled.',
};

export const APPROVAL_REQUIRED_BANNER_MESSAGE =
  'These posts require approval before publishing. Please add reviewers in the post editor. Without approval, these posts will not go live and must be rescheduled.';

export const SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE = {
  INSTAGRAM: 'SCHEDULER_INSTAGRAM_APPROVAL_REQUEST',
  PINTEREST: 'SCHEDULER_PINTEREST_APPROVAL_REQUEST',
  FACEBOOK: 'SCHEDULER_FACEBOOK_APPROVAL_REQUEST',
  TIKTOK: 'SCHEDULER_TIKTOK_APPROVAL_REQUEST',
  TWITTER: 'SCHEDULER_TWITTER_APPROVAL_REQUEST',
  LINKEDIN: 'SCHEDULER_LINKEDIN_APPROVAL_REQUEST',
};

export const SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE = {
  INSTAGRAM: 'SCHEDULER_INSTAGRAM_POST_APPROVED',
  PINTEREST: 'SCHEDULER_PINTEREST_POST_APPROVED',
  FACEBOOK: 'SCHEDULER_FACEBOOK_POST_APPROVED',
  TIKTOK: 'SCHEDULER_TIKTOK_POST_APPROVED',
  TWITTER: 'SCHEDULER_TWITTER_POST_APPROVED',
  LINKEDIN: 'SCHEDULER_LINKEDIN_POST_APPROVED',
};

export const SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES = {
  APPROVAL_REQUEST: 'requested your approval on a post',
  REQUEST_APPROVED: 'approved your post',
};

export const SCHEDULER_ACTION_NAMES = {
  CREATE_POST: 'createPost',
  UPDATE_POST: 'updatePost',
  DELETE_POST: 'deletePost',
  TOGGLE_SIDE_PANEL: 'toggleSidePanel',
};

export const draftToggleDisabledToolTip = 'Select a date and time to schedule your post.';
