/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" d="M0 12h18v3H0v-3zm0-6h18v3H0V6zm0-6h18v3H0V0z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
