/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'media': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19 16.001H5a1 1 0 01-1-1v-14a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zm-13-2h12v-12H6v12zm10 6H1a1 1 0 01-1-1v-15h2v14h14v2zm-7-13a2 2 0 110-4 2 2 0 010 4zm5 0l3 6H7l3-4 1.412 1.882L14 7.001z" _fill="#adadad" fill-rule="evenodd"/>'
  }
})
