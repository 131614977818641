/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hourglass': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 5.171V3a1 1 0 00-1-1H6a1 1 0 00-1 1v2.172c0 .79.32 1.563.879 2.121L10.586 12l-4.707 4.708A2.976 2.976 0 005 18.829V21a1 1 0 001 1h12a1 1 0 001-1v-2.171a2.98 2.98 0 00-.879-2.122L13.414 12l4.707-4.707A3.02 3.02 0 0019 5.171zm-2 13.658V20H7v-1.171c0-.268.104-.519.293-.708L12 13.414l4.707 4.708a.99.99 0 01.293.707zm0-13.658c0 .263-.107.521-.293.707L12 10.585 7.293 5.878A1.008 1.008 0 017 5.171V4h10v1.171z"/><path pid="1" d="M9 19l3-3 3 3z"/>'
  }
})
