import { computed } from 'vue';
import { env } from '@/env';
import { useRoute } from 'vue-router';

import { useIdentityStore } from '@/stores/identity';
import { MIXPANEL_VALUES } from '@/components/layout/navigation/constants';

export function handleZendesk() {
  window.open(env.zendeskResourceCenterUrl, '_blank');
}

export function useUtilityNavigationMenu() {
  const identityStore = useIdentityStore();

  const route = useRoute();

  const canAccessResourceCenter = computed(() => !!env.zendeskResourceCenterUrl);

  const firstName = computed(() => {
    return identityStore.identity.first_name;
  });

  const utilityNavigationMenuItems = computed(() => {
    return [
      {
        icon: 'legacy-settings',
        iconHoverClassList:
          'dash-fill-icon-subtle group-hover:!dash-fill-icon-primary group-focus:!dash-fill-icon-primary group-active:!dash-fill-icon-primary',
        label: 'Settings',
        route: {
          name: 'settings',
          params: { brandLabel: identityStore.currentBrandLabel },
        },
        tracking: MIXPANEL_VALUES.UTILITY_MENU.SETTINGS,
        visible: true,
        inactive: route.name === 'settings.billing',
      },
      {
        icon: 'legacy-billing',
        iconHoverClassList:
          'dash-fill-icon-subtle group-hover:!dash-fill-icon-primary group-focus:!dash-fill-icon-primary group-active:!dash-fill-icon-primary',
        label: 'Billing and Plan',
        route: {
          name: 'settings.billing',
          params: { brandLabel: identityStore.currentBrandLabel },
        },
        tracking: MIXPANEL_VALUES.UTILITY_MENU.BILLING,
        visible: identityStore.isCurrentBrandRoleAdmin,
      },
      {
        icon: 'legacy-question',
        iconHoverClassList:
          'dash-fill-icon-subtle group-hover:!dash-fill-icon-primary group-focus:!dash-fill-icon-primary group-active:!dash-fill-icon-primary',
        label: 'Resource Center',
        command: handleZendesk,
        tracking: MIXPANEL_VALUES.UTILITY_MENU.RESOURCE_CENTER,
        visible: canAccessResourceCenter.value,
      },
      {
        icon: 'legacy-logout',
        iconHoverClassList:
          'dash-fill-icon-subtle group-hover:!dash-fill-icon-primary group-focus:!dash-fill-icon-primary group-active:!dash-fill-icon-primary',
        label: 'Log Out',
        route: { name: 'auth.logout' },
        tracking: MIXPANEL_VALUES.UTILITY_MENU.LOGOUT,
        visible: true,
      },
    ];
  });

  const visibleUtilityNavigationMenuItems = computed(() => {
    return utilityNavigationMenuItems.value.filter((item) => item.visible);
  });

  return {
    firstName,
    visibleUtilityNavigationMenuItems,
  };
}
