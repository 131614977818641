/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'multipleMedia': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M20.824 17.648H6a1.059 1.059 0 01-1.059-1.059V1.766c0-.585.473-1.06 1.059-1.06h14.824c.585 0 1.058.475 1.058 1.06v14.823c0 .585-.473 1.06-1.058 1.06zM7.059 15.53h12.706V2.825H7.059V15.53z"/><path pid="1" d="M17.647 21.883H1.765a1.059 1.059 0 01-1.06-1.058V4.942h2.119v14.824h14.823v2.117z"/></g>'
  }
})
