<script setup>
import Picture from '@/components/foundation/Picture.vue';

const props = defineProps({
  mediaId: { type: String, default: null },
  /**
   * Aspect ratio for the media slot, square by default. Valid when default content slot is used.
   */
  mediaAspectRatio: {
    type: String,
    default: 'square',
    validator: (prop) => ['square', 'landscape', 'portrait'].includes(prop),
  },
  /**
   * Media image or video url to pass into the default Picture component
   */
  pictureUrl: { type: String, default: null },
  /**
   * Media image or video size to pass into the default Picture component
   */
  pictureSize: { type: Number, default: null },
  /**
   *  String provided by parent for display purposes in the details slot
   */
  title: { type: String, default: null },
  /**
   *  String provided by parent for display purposes in the details slot
   */
  timeStamp: { type: String, default: null },
  /**
   * Metrics to be shown by default in the stats slot, should be in the following format:
   * <code>
   * {
   *   key: optional,
   *   label: required,
   *   rawValue: optional,
   *   value: required,
   *   tooltip: optional,
   *    iconColor: optional
   *   highlighted: optional
   * }
   * </code>
   */
  metrics: { type: Array, default: null },
  /**
   * Reduces the line height on the default metrics
   */
  compactMetrics: { type: Boolean, default: false },
  /**
   * Disabled the always on select functionality
   */
  disableMultiSelect: { type: Boolean, default: false },
  /**
   * Additional class to be added to the card
   */
  additionalClass: { type: String, default: '' },
  /**
   * Hide stats section
   */
  hideStats: { type: Boolean, default: false },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div :class="['card', additionalClass]" :data-id="mediaId">
    <slot name="content">
      <header class="header" :class="mediaAspectRatio">
        <slot name="status" />
        <div class="top-badges">
          <slot name="top-badges" />
        </div>
        <div class="media-container">
          <div class="media">
            <slot name="visual">
              <Picture :url="props.pictureUrl" :width="props.pictureSize" />
            </slot>
          </div>
        </div>
        <div class="bottom-badges">
          <slot name="bottom-badges" />
        </div>
        <div class="badges-bg" />
        <slot name="avatar" />
        <slot name="overlay" />
      </header>
    </slot>
    <section v-if="!hideStats" class="stats-container">
      <dl>
        <slot name="details">
          <dt v-if="title" class="title">
            {{ props.title }}
          </dt>
          <dt v-if="timeStamp" class="timeStamp">
            {{ props.timeStamp }}
          </dt>
        </slot>
        <slot name="stats">
          <dd
            v-for="(metric, index) in props.metrics"
            :key="index"
            :class="{ 'reduced-line-height': compactMetrics }"
          >
            <span class="metric-label" :class="[metric.highlighted ? 'highlight' : 'grey-text']">
              {{ metric.label }}
            </span>
            <div class="dot-container">
              <div :style="{ backgroundColor: metric.iconColor }" class="dot"></div>
              <span v-tooltip="metric.tooltip" class="metric-stat">
                {{ metric.value }}
                <slot name="stat-icon" :metric="metric"></slot>
              </span>
            </div>
          </dd>
        </slot>
      </dl>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
.card {
  background: var(--background-0);
  width: 100%;
  border-radius: var(--round-corner);
  box-shadow: var(--shadow-1);
  cursor: pointer;
  transition: var(--transition-all);
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 240px;

  .selection {
    position: absolute;
    width: 100%;
    height: 100%;

    .select-outline {
      position: absolute;
      width: 100%;
      height: 100%;
      border: var(--space-4) solid var(--action-500);
      border-radius: var(--round-corner);
      opacity: 0;
      visibility: hidden;
      z-index: 3;
    }

    .select-checkbox {
      border: var(--space-4) solid transparent;
      transition: var(--transition-all);
      opacity: 0;
      visibility: hidden;
      z-index: 5;
    }

    &.selected {
      .select-outline,
      .select-checkbox {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow-2);
    transform: var(--hover-move);

    .selection .select-checkbox {
      opacity: 1;
      visibility: visible;
    }
  }

  .header {
    position: relative;
    line-height: 0;
    height: 0;
    background-color: var(--background-300);

    .top-badges {
      position: absolute;
      width: 100%;
      z-index: 4;
      top: 0;
      right: 0;
      padding: var(--space-8);
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;

      & > * {
        margin: 0 0 var(--space-8) var(--space-8);
        flex: 0 0 var(--space-20);
      }
    }

    .bottom-badges {
      position: absolute;
      bottom: var(--space-10);
      right: var(--space-12);
      z-index: 4;
    }

    .badges-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--tile-bg-gradient);
      border-top-left-radius: var(--round-corner);
      border-top-right-radius: var(--round-corner);
    }

    .media-container {
      background: var(--background-400);

      .media {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-top-left-radius: var(--round-corner);
        border-top-right-radius: var(--round-corner);

        :deep(img) {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .square {
    padding-bottom: 100%;
  }

  .landscape {
    padding-bottom: 56.25%;
  }

  .portrait {
    height: 100%;
  }

  .stats-container {
    padding-bottom: var(--space-16);
    z-index: 4;

    dl {
      margin: var(--space-16) var(--space-16) 0 var(--space-16);
      max-width: 100%;
      text-transform: capitalize;
      transition: var(--transition-all);

      dt {
        letter-spacing: 0;
        color: var(--text-secondary);
        font-size: var(--x14);
        text-transform: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }

      .title {
        line-height: var(--x15);
        font-weight: var(--font-medium);
        margin-bottom: 3%;
      }

      .timeStamp {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 7%;
        font-size: var(--x12);
        font-weight: var(--font-medium);
        line-height: var(--space-14);
      }

      dd {
        display: flex;
        justify-content: space-between;
        font-size: var(--x12);
        line-height: var(--space-24);

        &.reduced-line-height {
          line-height: var(--space-16);
        }

        .highlight {
          font-weight: 600;
        }

        .metric-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .dot-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .dot {
            height: var(--space-8);
            width: var(--space-8);
            min-height: var(--space-8);
            min-width: var(--space-8);
            border-radius: var(--space-8);
            margin-right: var(--space-6);
          }

          .metric-stat {
            color: var(--action-500);
            display: flex;
            align-items: center;
          }
        }

        .grey-text {
          color: var(--text-primary);
          font-weight: var(--font-normal);
        }
      }
    }
  }
}

@media print {
  .card {
    border: 1px solid var(--border);
    box-shadow: none;
    transform: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}
</style>
