<template>
  <div v-tooltip="wrapperTooltip" class="account-item">
    <LetterAvatar :account-id="$vnode.key" :name="label" large />
    <p>{{ label }}</p>
    <ToggleSwitch
      v-model="toggleValue"
      :disabled="disabled"
      :tooltip="toggleTooltip"
      class="switcher"
      @input="handleToggleChange"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LetterAvatar from '@/components/LetterAvatar.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AccountToggle',
  components: {
    LetterAvatar,
    ToggleSwitch,
  },
  props: {
    account: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    label: { type: String, required: true },
    toggled: { type: Boolean, default: false },
    toggleTooltip: { type: String, default: '' },
    wrapperTooltip: { type: [String, Object], default: null },
  },
  emits: ['change'],
  data() {
    return { toggleValue: this.toggled };
  },
  methods: {
    handleToggleChange(value) {
      this.$emit('change', this.account, value);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.account-item {
  display: flex;
  text-align: center;
  padding: var(--space-16);
  align-items: center;

  p {
    margin-left: var(--space-16);
  }

  .switcher {
    margin-left: auto;
  }

  .brand-photo {
    width: var(--space-40);
    height: var(--space-40);
    border-radius: 50%;
    display: block;
  }

  :deep(.dh-letter-avatar) {
    margin-left: 0;
  }
}
</style>
