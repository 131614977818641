export const PINTEREST_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'SAVES',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'CLOSEUPS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'PINTEREST',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['PINTEREST'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['PINTEREST'],
      metric: 'SAVES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['PINTEREST'],
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['PINTEREST'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 20,
    meta: {
      name: 'Pin Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
