/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'date-blocked': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M10.008 18.5v1.992H1.992a1.918 1.918 0 01-1.406-.586C.196 19.516 0 19.046 0 18.5V5.492c0-.547.195-1.015.586-1.406.39-.39.86-.586 1.406-.586h2.016v1.992h.984V.5h2.016v3h6.984v1.992H15V.5h1.992v3H18c.547 0 1.016.195 1.406.586.39.39.586.86.586 1.406v5.016H18V8.492H1.992V18.5h8.016zm7.992-6c.828 0 1.61.156 2.344.469a6.096 6.096 0 013.187 3.187c.313.735.469 1.516.469 2.344 0 .828-.156 1.602-.469 2.32a6.022 6.022 0 01-1.289 1.922c-.547.531-1.18.961-1.898 1.29A5.922 5.922 0 0118 24.5c-.828 0-1.61-.156-2.344-.469a6.684 6.684 0 01-1.898-1.289 6.022 6.022 0 01-1.29-1.922A5.754 5.754 0 0112 18.5c0-.828.156-1.61.469-2.344a6.096 6.096 0 013.187-3.187A5.922 5.922 0 0118 12.5zm0 1.992c-1.11 0-2.055.39-2.836 1.172-.781.781-1.172 1.727-1.172 2.836 0 .36.047.71.14 1.055.11.343.259.664.446.96l5.438-5.46a4.02 4.02 0 00-.961-.399A3.317 3.317 0 0018 14.492zm0 7.992c1.11 0 2.055-.39 2.836-1.172.781-.78 1.172-1.718 1.172-2.812 0-.375-.055-.727-.164-1.055a3.528 3.528 0 00-.422-.96l-5.438 5.437c.297.172.61.312.938.422.344.093.703.14 1.078.14z" _fill="#E36042"/>'
  }
})
