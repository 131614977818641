/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location': {
    width: 134,
    height: 197,
    viewBox: '0 0 134 197',
    data: '<path pid="0" d="M66.667 95.238c-15.753 0-28.572-12.819-28.572-28.571 0-15.753 12.82-28.572 28.572-28.572 15.752 0 28.571 12.82 28.571 28.572 0 15.752-12.819 28.571-28.571 28.571zm0-38.095c-5.248 0-9.524 4.276-9.524 9.524 0 5.257 4.276 9.523 9.524 9.523 5.247 0 9.523-4.266 9.523-9.523 0-5.248-4.276-9.524-9.523-9.524zm0 139.686C44.2 166.857 0 104.152 0 66.667 0 29.905 29.905 0 66.667 0s66.666 29.905 66.666 66.667c0 37.485-44.2 100.2-66.666 130.162zm0-177.781c-26.257 0-47.62 21.362-47.62 47.619 0 24.676 29.591 72.219 47.62 98.01 18.019-25.8 47.619-73.353 47.619-98.01 0-26.257-21.362-47.62-47.62-47.62z" _fill="#000" fill-rule="nonzero"/>'
  }
})
