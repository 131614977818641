/**
 * An extension for Tiptap that adds support for serializing & deserializing HTML in the form
 * `<span class="hashtag">#tag</span>`. The process of actually detecting hashtags and wrapping
 * them in `<span/>` is handled externally to Tiptap - see `encodeHashtagsForTiptap` and
 * `decodeHashtagsForTiptap` in `src/app/scheduler/utils/tiptap.js`.
 */
import { Mark } from '@tiptap/core';

const HashTag = Mark.create({
  name: 'hashTag',
  parseHTML() {
    return [{ tag: 'span.hashtag' }];
  },
  renderHTML() {
    return ['span', { class: 'hashtag' }, 0];
  },
});

export default HashTag;
