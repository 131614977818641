<template>
  <Popup :close="hideRequestErrorPopup" type="medium">
    <div class="center">
      <span>
        <h2>Unable to Post Rights Request</h2>
        <p>
          Due to Instagram's API limitations, you are only able to publish comments via third-party
          platforms on posts in which you have been @mentioned. The rights request comment you
          selected has been copied to your clipboard.
        </p>
      </span>
      <a :href="mediaPostUrl" target="_blank" rel="noopener">
        <Button large primary>Go to Instagram</Button>
      </a>
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'RequestErrorPopup',
  components: {
    Button,
    Popup,
  },
  props: {
    hideRequestErrorPopup: { type: Function, required: true },
    mediaPostUrl: { type: String, default: '' },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.center {
  text-align: center;

  h2 {
    margin: var(--space-24) auto var(--space-16) auto;
  }

  button {
    margin: var(--space-48) auto 0;
  }
}
</style>
