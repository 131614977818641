import FloatingVue from 'floating-vue';

const install = (Vue) => {
  Vue.use(FloatingVue, {
    distance: 10,
    themes: {
      tooltip: {
        html: false,
        delay: { show: 100, hide: 0 },
      },
      dropdown: {
        html: true,
        delay: { show: 100, hide: 0 },
      },
      'dh-tooltip-small': {
        $extend: 'tooltip',
        html: false,
        delay: { show: 100, hide: 0 },
      },
      'dh-tooltip-center': {
        $extend: 'dh-tooltip-small',
        html: false,
        delay: { show: 100, hide: 0 },
      },
      'dh-tooltip-medium': {
        $extend: 'tooltip',
        $resetCss: true,
        html: false,
        delay: { show: 100, hide: 0 },
      },
      'dh-tooltip-wide': {
        $extend: 'tooltip',
        $resetCss: true,
        html: false,
        delay: { show: 100, hide: 0 },
      },
      'dh-dropdown-full': {
        $extend: 'dropdown',
        $resetCss: true,
        html: true,
        delay: { show: 100, hide: 0 },
      },
      'dh-hover-menu': {
        $extend: 'menu',
        html: true,
        delay: {
          show: 500,
          hide: 200,
        },
      },
    },
  });
};

export default {
  install,
};
