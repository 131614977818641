/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lineItems': {
    width: 18,
    height: 21,
    viewBox: '0 0 18 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 6.998h14c1.104 0 2-.897 2-2v-2c0-1.102-.896-2-2-2H2c-1.104 0-2 .898-2 2v2c0 1.103.896 2 2 2zm0-2v-2h14v2H2zM2 13.998h14c1.104 0 2-.897 2-2v-2c0-1.102-.896-2-2-2H2c-1.104 0-2 .898-2 2v2c0 1.103.896 2 2 2zm0-2v-2h14v2H2zM2 20.998h14c1.104 0 2-.897 2-2v-2c0-1.103-.896-2-2-2H2c-1.104 0-2 .897-2 2v2c0 1.103.896 2 2 2zm0-2v-2h14v2H2z" _fill="#686a7a"/>'
  }
})
