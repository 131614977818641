/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video-3': {
    width: 12,
    height: 8,
    viewBox: '0 0 12 8',
    data: '<defs><path pid="0" d="M11.684 1.432a.667.667 0 00-.649-.03l-1.702.852v-.921C9.333.597 8.735-.001 8-.001H2C1.265 0 .667.597.667 1.333v5.333c0 .736.598 1.333 1.333 1.333h6c.735 0 1.333-.597 1.333-1.333v-.921l1.702.85a.67.67 0 00.649-.029.666.666 0 00.316-.567V2a.668.668 0 00-.316-.567zM7.998 6.666H2V1.333h6l-.002 5.333zm2.669-1.745l-1.334-.667v-.51l1.334-.666v1.843z" id="svgicon_video-3_a"/></defs><use _fill="#858585" fill-rule="nonzero" xlink:href="#svgicon_video-3_a"/>'
  }
})
