export function joinWithTruncation(list, maxCharacters) {
  const getStringByItemsShown = (n) => {
    if (list.length === 1) {
      return list[0];
    }
    if (n >= list.length) {
      return `${list.slice(0, list.length - 1).join(', ')}, and ${list[list.length - 1]}`;
    }
    return `${list.slice(0, n).join(', ')}, and ${list.length - n} more`;
  };

  const itemsToShow =
    list
      .map((_, i) => i + 1)
      .toReversed()
      .find((n) => getStringByItemsShown(n).length <= maxCharacters) ?? 1;

  return getStringByItemsShown(itemsToShow);
}
