<script setup>
import { computed, ref, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useContentTagsStore } from '@/stores/content-tags';
import { useNotificationStore } from '@/stores/notification';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Icon from '@/components/foundation/Icon.vue';
import MediaContentTagsList from '@/components/ContentTags/MediaContentTagsList.vue';
import { useAuthStore } from '@/stores/auth';
import { BRAND } from '@/models/auth/permissions.enum';
import { useTrackingStore } from '@/stores/tracking';

const contentTagsStore = useContentTagsStore();
const notificationStore = useNotificationStore();
const trackingStore = useTrackingStore();
const route = useRoute();

const props = defineProps({
  brandIds: {
    type: Array,
    default: () => [],
  },
  brandMediaId: {
    type: Number,
    default: null,
  },
  mediaContentTags: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['update', 'addedContentTag', 'removedContentTag']);

const saving = ref(false);

const showContentTags = computed(() => {
  const authStore = useAuthStore();
  return authStore.guard(BRAND.TAGS.CAN_ACCESS_CONTENT_TAGS);
});
const settingsRoute = computed(() => {
  const authStore = useAuthStore();

  return {
    name: 'settings.contentTags',
    params: { brandLabel: authStore.currentBrandLabel },
  };
});
const hasContentTags = computed(() => contentTagsStore.organizationContentTags?.length > 0);

function mediaContentTagAdded(contentTag) {
  emit('update', [...props.mediaContentTags, contentTag]);
  emit('addedContentTag', contentTag);
}

function mediaContentTagRemoved(contentTag) {
  const newMediaContentTags = props.mediaContentTags.filter(
    (mediaContentTag) => mediaContentTag.id !== contentTag.id,
  );
  emit('update', newMediaContentTags);
  emit('removedContentTag', contentTag);
}

function handleMixpanelEvent() {
  trackingStore.track('Manage Tags', {
    page: 'Settings - ContentTags',
    previousPage: route.name,
  });
}

async function onAddContentTag(contentTag) {
  try {
    if (contentTag) {
      saving.value = true;
      const brandMediaIds = [props.brandMediaId];
      const contentTagIds = [contentTag.id];
      await contentTagsStore.associateContentTagsToBrandMedia({ contentTagIds, brandMediaIds });
      mediaContentTagAdded(contentTag);
    }
  } catch (error) {
    notificationStore.setToast({
      htmlMessage: `An error occurred adding content tag <span class="text-main-medium">${contentTag.name}</span> to media.`,
      type: 'error',
    });
  } finally {
    nextTick(() => {
      saving.value = false;
    });
  }
}

async function onRemoveContentTag(contentTagToRemove) {
  const brandMediaId = props.brandMediaId;
  const contentTagId = contentTagToRemove?.id;
  try {
    if (brandMediaId && contentTagId) {
      contentTagToRemove.deleting = true;
      contentTagsStore.organizationContentTags = [...contentTagsStore.organizationContentTags];
      await contentTagsStore.removeContentTagFromBrandMedia({ contentTagId, brandMediaId });
      mediaContentTagRemoved(contentTagToRemove);
    }
  } catch (error) {
    notificationStore.setToast({
      htmlMessage: `An error occurred removing content tag <span class="text-main-medium">${contentTagToRemove.name}</span> from media.`,
      type: 'error',
    });
  } finally {
    contentTagToRemove.deleting = false;
  }
}

defineExpose({
  showContentTags,
  hasContentTags,
  handleMixpanelEvent,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div v-if="showContentTags" class="tags-container my-2 flex w-full flex-col rounded-md p-6">
    <div class="flex justify-between">
      <div class="flex items-center gap-3">
        <Icon small name="tag" />
        <p>Content Tags</p>
        <InfoTooltip
          tooltip="Content tags can be used to create custom categories for content reporting in Dashboards"
          xsmall
          :margin="false"
        />
      </div>
      <div v-if="hasContentTags">
        <router-link :to="settingsRoute" class="text-link text-small-medium">
          <span @click="handleMixpanelEvent()"> Manage in Settings </span>
        </router-link>
      </div>
    </div>
    <div class="mt-3">
      <template v-if="hasContentTags">
        <MediaContentTagsList
          :brand-ids="props.brandIds"
          :media-content-tags="props.mediaContentTags"
          :saving="saving"
          @add="onAddContentTag"
          @remove="onRemoveContentTag"
        />
      </template>
      <template v-else>
        <div class="flex flex-col">
          <div class="text-small mt-6">You haven’t created any content tags yet.</div>
          <div class="mb-3 mt-6">
            <router-link :to="settingsRoute" class="text-link text-small-medium">
              <span @click="handleMixpanelEvent()"> + Create tags in Settings </span>
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.tags-container {
  background: var(--background-300);
}
</style>
