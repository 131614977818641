<template>
  <div class="media-container">
    <RadialProgressBar
      v-if="mediaUploading"
      :completed-steps="media.uploadProgress"
      :total-steps="100"
      :diameter="75"
      :stroke-width="8"
      :start-color="iconColor"
      :stop-color="iconColor"
      class="center"
      inner-stroke-color="lightGrey"
    />
    <InfiniteLoader v-if="mediaProcessing" />
    <img class="media" :src="mediaUrl" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import { mediaIsUploading } from '@/utils/media';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'LinkPreviewMedia',
  components: {
    InfiniteLoader,
    RadialProgressBar,
  },
  props: {
    media: { type: Object, required: true },
  },
  emits: ['update:playing', 'uploaded-duration'],
  data() {
    return {
      iconColor: colours.BASIC.WHITE,
    };
  },
  computed: {
    mediaUrl() {
      if (this.isUpload) {
        return this.media.previewUrl;
      }
      const formattedMedia = this.media.fullMediaObject || this.media;
      return formattedMedia?.sizes?.originalConverted
        ? formattedMedia?.sizes?.originalConverted?.url
        : formattedMedia?.sizes?.original?.url;
    },
    isUpload() {
      return Boolean(this.media.uploadStatus);
    },
    mediaUploading() {
      return mediaIsUploading(this.media);
    },
    mediaProcessing() {
      return this.isUpload && this.media.uploadStatus === UPLOAD_STATUS.PROCESSING;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100%;

  .media {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.center {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
