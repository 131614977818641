<template>
  <Popup
    :close="close"
    :close-confirmation="confirmation"
    :type="isUtmVisible ? 'xlarge' : 'larger'"
    close-on-route-change
  >
    <div class="flex">
      <div class="flex flex-1 flex-col">
        <Header
          :avatar="avatarUrl"
          :handle="handle"
          title="pinterest"
          platform="pinterest"
          icon="pinterest"
        />
        <div
          class="bulk-add-pin-container flex"
          :class="canShowUtmPanel ? 'flex-row' : 'flex-row-reverse'"
        >
          <template v-if="mediaItems.length > 0">
            <PinterestBoardControl
              v-if="screen === 'MainControl'"
              v-model:scheduled-time="scheduledTime"
              :is-app-connected="isAppConnected"
              :disable-publish-now="shouldDisablePublishNow || !canAutoPublish"
              :error-message="errorMessage"
              :is-saving="isSaving"
              :media-items="mediaItems"
              :default-timestamp="defaultTimestamp"
              @on-save="save"
            />
            <SinglePinMetaFields
              v-if="screen === 'EditPin'"
              ref="singlePinMetaFields"
              :is-app-connected="isAppConnected"
              :on-back="() => switchSubScreen('MainControl')"
              @pin-updated="pinUpdated"
            />
          </template>
          <section :class="{ fullWidth: mediaItems.length === 0 }" class="media">
            <DropzoneContainer
              v-if="mediaItems.length === 0"
              :accept-video="true"
              :media-removed="mediaItemRemoved"
              :on-accept="handleUploadStarted"
              :on-error="handleUploadError"
              :on-preview-generated="handlePreviewGenerated"
              :on-upload-progress="handleUploadProgress"
              :on-success="handleUploadSuccess"
              :on-converted="handleUploadConverted"
              :open-media-popup="openSelectMediaPopup"
            />
            <div v-else>
              <div class="top-bar">
                <p class="schedule-pins">
                  Schedule
                  <span>{{ mediaItems.length }}</span>
                  Pins
                </p>
                <div class="right-section">
                  <button data-cy="select-waterfall-button" @click="switchMediaView('waterfall')">
                    <Icon :color="imageViewIconColor" name="image-view" small />
                  </button>
                  <button data-cy="select-list-view-button" @click="switchMediaView('list')">
                    <Icon :color="listViewIconColor" name="list-view" small />
                  </button>
                  <div v-tooltip="addPinTooltip" class="add-media-button">
                    <DropdownButton
                      :dropdown-list="addDropdownList"
                      :small-button="smallAddPinButton"
                      :align-right="addPinDropdownAlignRight"
                      :disabled="disableAddMorePins"
                      @dropdown-action-clicked="addActionClicked"
                    >
                      <template #buttonContent>
                        <span>Add Pin</span>
                      </template>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <Banner v-if="uploadErrorMessage" alert-type="error" class="upload-error">
                {{ uploadErrorMessage }}
              </Banner>
              <Banner v-if="showApprovalRequiredMessage" class="banner-message">
                {{ approvalRequiredBannerMessage }}
              </Banner>
            </div>
            <Dropzone
              v-if="mediaItems.length > 0 && !loading"
              ref="dropzone"
              :thumbnail="false"
              :accept-video="true"
              :on-accept="handleUploadStarted"
              :on-error="handleUploadError"
              :on-preview-generated="handlePreviewGenerated"
              :on-upload-progress="handleUploadProgress"
              :on-success="handleUploadSuccess"
              :on-converted="handleUploadConverted"
              class="cover-full"
            >
              <div class="cover-full">
                <WaterfallContainer
                  v-if="mediaView === 'waterfall'"
                  :items="mediaItems"
                  :crop-clicked="openMediaCropper"
                  @remove="removePin"
                  @focus-pin-web-link="focusPinLink"
                />
                <PinListContainer
                  v-else-if="mediaItems.length"
                  v-model="mediaItems"
                  :is-app-connected="isAppConnected"
                  :scheduled-time="scheduledTime"
                  :crop-clicked="openMediaCropper"
                  @input="validatePinContent"
                  @check-media-format="checkMediaFormat"
                  @open-editor-panel="toggleUtmPanel"
                />
              </div>
            </Dropzone>
          </section>
        </div>
        <SelectMediaPopup
          v-if="showSelectMediaPopup"
          :close="closeSelectMediaPopup"
          :media-selected="libraryMediaSelected"
          :media-count-limit="pinterestMediaCountLimit - mediaItems.length"
          :media-type-limit="null"
          disallow-past-publish-dates
        />
        <MediaCropperPopup
          v-if="showMediaCropperPopup && mediaToCrop && mediaToCrop.type === 'IMAGE'"
          :close="closeMediaCropperPopup"
          :media="mediaToCrop"
          :original-media="cropperStore.cropperOriginalMedia"
          :on-save="replaceMediaItem"
          platform="pinterest"
        />
        <VideoCropperPopup
          v-if="showMediaCropperPopup && mediaToCrop && mediaToCrop.type === 'VIDEO'"
          :close="closeMediaCropperPopup"
          :media="mediaToCrop"
          :original-media="cropperStore.cropperOriginalMedia"
          :on-save="replaceMediaItem"
          platform="pinterest"
        />
      </div>
      <div v-if="isUtmVisible" class="flex-[0.3] rounded-[--round-corner] bg-[--background-300]">
        <UtmEditorPanel
          :url="utmPreviewLink"
          :channel="utmChannel"
          :brand-channel-utm-settings="utmSettings"
          :tracking-data="trackingData"
          :link-number="utmPanelLinkNumber"
          :link-title="utmPanelTitle"
          @close="toggleUtmPanel"
          @tracking-parameters-updated="setUtmLink"
        />
      </div>
    </div>
  </Popup>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import humps from 'humps';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import { useProductStore } from '@/stores/product';
import { externalAxios } from '@/apis/external-apis';
import { debounceInputDelay, discardConfirmMessage, toolTips, UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import {
  postStatus,
  pinterestCaptionLimit,
  pinterestMediaCountLimit,
  pinterestMediaCountLimitMessage,
  pinterestBadMediaRequirementsMessage,
  somethingWentWrongTitle,
  somethingWentWrongMessage,
  PLATFORMS,
  APPROVAL_REQUIRED_BANNER_MESSAGE,
} from '@/app/scheduler/constants';
import {
  validatePinterestMedia,
  validatePin,
  ThumbnailUrlGenerator,
  getUnscheduledRouteName,
} from '@/app/scheduler/utils';
import { recursiveObjectPromiseAll } from '@/utils';
import Icon from '@/components/foundation/Icon.vue';
import Popup from '@/components/Popup.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Dropzone from '@/app/scheduler/components/EditPost/MediaViewer/Dropzone.vue';
import MediaCropperPopup from '@/components/MediaCropping/MediaCropperPopup.vue';
import VideoCropperPopup from '@/components/MediaCropping/VideoCropperPopup.vue';
import SelectMediaPopup from '@/components/core/SelectMediaPopup.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { useMediaStore } from '@/stores/media';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import { usePlatformStore } from '@/stores/platform';
import { trackSchedulerUtmPopout } from '@/app/scheduler/mixpanel';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import { LibraryAPI } from '@/apis';
import { utmChannel } from '@/app/settings/components/Utm/const';
import { fetchUtmSettings } from '@/app/settings/components/Utm/utils';
import { useFlagStore } from '@/stores/flag';
import { logger } from '@/utils/logger';
import { useImpersonatorStore } from '@/stores/impersonator';
import PinterestBoardControl from '../BulkAddPinterest/PinterestBoardControl.vue';
import SinglePinMetaFields from '../BulkAddPinterest/SinglePinMetaFields.vue';
import PinListContainer from '../BulkAddPinterest/PinListContainer.vue';
import Header from './Layout/Header.vue';
import WaterfallContainer from '../BulkAddPinterest/WaterfallContainer.vue';
import DropzoneContainer from './Layout/DropzoneContainer.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'BulkAddPinterestPost',
  components: {
    DropdownButton,
    Dropzone,
    DropzoneContainer,
    Header,
    Icon,
    Banner,
    MediaCropperPopup,
    VideoCropperPopup,
    PinListContainer,
    PinterestBoardControl,
    Popup,
    SelectMediaPopup,
    SinglePinMetaFields,
    WaterfallContainer,
    UtmEditorPanel,
  },
  props: {
    close: { type: Function, required: true },
    context: { type: String, default: null },
    initialPublishType: { type: String, default: 'notification' },
    mediaList: { type: Array, default: () => [] },
    defaultTimestamp: { type: Date, default: null },
  },
  data() {
    return {
      addDropdownList: [
        {
          text: 'Select From Library',
          action: 'openSelectMediaPopup',
        },
        {
          text: 'Upload Media',
          action: 'uploadClicked',
        },
      ],
      addPinDropdownAlignRight: true,
      disablePublishNow: true,
      errorMessage: '',
      isSaving: false,
      loading: false,
      mediaItems: [],
      mediaToCrop: null,
      mediaToCropIdx: null,
      mediaView: 'list',
      pinterestMediaCountLimit,
      scheduledTime: null,
      pinterestMediaCountLimitMessage,
      screen: 'MainControl',
      showMediaCropperPopup: false,
      showSelectMediaPopup: false,
      smallAddPinButton: true,
      timelinePostCount: 0,
      unScheduledPostCount: 0,
      uploadErrorMessage: null,
      mediaValidationError: null,
      allPinsHaveBoards: false,
      allPinsHaveTitles: false,
      utmChannel: utmChannel.PINTEREST,
      showUtmPanel: false,
      utmPreviewLink: '',
      utmPanelTitle: '',
      utmPanelLinkNumber: 1,
      utmSettings: null,
      utmNewUrl: '',
      trackingData: {
        postPlatform: 'Pinterest',
        postType: 'Bulk Post',
      },
      modifiedUtmsMap: {},
    };
  },
  computed: {
    ...mapStores(
      useSchedulerStore,
      useNotificationStore,
      useCropperStore,
      useMediaLinksStore,
      useMediaStore,
      useProductStore,
      useSchedulerPinterestStore,
      useAuthStore,
      usePlatformStore,
      useFlagStore,
      useTrackingStore,
      useImpersonatorStore,
    ),
    connection() {
      const flagStore = useFlagStore();
      if (flagStore.ready && flagStore.flags['pinterest-migration']) {
        return this.platformStore.platformConnectionsMap.pinterest_v5?.[
          this.authStore.currentBrand?.id
        ];
      }
      return this.platformStore.platformConnectionsMap.pinterest?.[this.authStore.currentBrand?.id];
    },
    isAppConnected() {
      return this.connection?.status === 'connected';
    },
    isUtmVisible() {
      return this.utmSettings !== null && this.showUtmPanel;
    },
    disableAddMorePins() {
      return this.mediaItems.length >= this.pinterestMediaCountLimit;
    },
    addPinTooltip() {
      if (this.disableAddMorePins) {
        return this.pinterestMediaCountLimitMessage;
      }
      return '';
    },
    imageViewIconColor() {
      if (this.mediaView === 'waterfall') {
        return colours.ICON.ICON_PRIMARY;
      }
      return null;
    },
    listViewIconColor() {
      if (this.mediaView === 'list') {
        return colours.ICON.ICON_PRIMARY;
      }
      return null;
    },
    confirmation() {
      if (this.mediaItems.length !== 0) {
        return discardConfirmMessage;
      }
      return null;
    },
    avatarUrl() {
      if (this.platformStore.pinterestAccount) {
        return this.platformStore.pinterestAccount.avatarUrl;
      }
      if (this.authStore.currentBrand) {
        return this.authStore.currentBrand.avatarUrl;
      }
      return '';
    },
    handle() {
      if (this.platformStore.pinterestAccount) {
        return this.platformStore.pinterestAccount.pinterestUsername;
      }
      if (this.authStore.currentBrand) {
        return this.authStore.currentBrand.name;
      }
      return '';
    },
    canAutoPublish() {
      return (
        this.authStore.user_can('scheduler', 'can_auto_publish_pinterest') &&
        !this.impersonatorStore.isImpersonating
      );
    },
    getValidMediaPublishType() {
      // when adding another piece of media to bulk pin selection, give same publish type as other
      // pins or default to sendNotification
      if (this.schedulerPinterestStore.publishAtSameTimeSetting?.on && this.mediaItems[0]) {
        return this.mediaItems[0].publishType;
      }
      return 'notification';
    },
    shouldDisablePublishNow() {
      return (
        this.disablePublishNow ||
        !this.allPinsHaveBoards ||
        !this.allPinsHaveTitles ||
        Boolean(this.mediaValidationError) ||
        !this.canAssetPublishToday
      );
    },
    canShowUtmPanel() {
      return this.flagStore.ready && this.flagStore.flags.utmScheduler;
    },
    showApprovalRequiredMessage() {
      return this.authStore.user_can('scheduler', 'require_approvals_to_post');
    },
    approvalRequiredBannerMessage() {
      return APPROVAL_REQUIRED_BANNER_MESSAGE;
    },
  },
  watch: {
    async utmPreviewLink(show) {
      if (show && this.utmSettings === null) {
        this.utmSettings = await this.getUtmSettings();
      }
    },
    mediaItems(to) {
      this.errorMessage = '';
      this.mediaValidationError = null;
      if (to.length !== 0) {
        this.switchUtmPanel();
        this.checkMediaFormat();
        if (
          this.schedulerPinterestStore.addToSameBoardSetting &&
          this.schedulerPinterestStore.addToSameBoardSetting.on
        ) {
          this.mediaItems.forEach((pin) => {
            const mutablePin = pin;
            mutablePin.selectedGalleries =
              this.schedulerPinterestStore.addToSameBoardSetting.galleries || [];
            mutablePin.pinterestBoard = this.schedulerPinterestStore.addToSameBoardSetting.board;
          });
        }
        if (
          this.schedulerPinterestStore.publishAtSameTimeSetting &&
          this.schedulerPinterestStore.publishAtSameTimeSetting.on
        ) {
          this.mediaItems.forEach((pin) => {
            const mutablePin = pin;
            mutablePin.publishType =
              this.schedulerPinterestStore.publishAtSameTimeSetting.type || 'autoPublish';
            mutablePin.scheduledTime =
              this.schedulerPinterestStore.publishAtSameTimeSetting.timestamp;
          });
        }
        this.schedulerPinterestStore.triggerPinUpdate();
      }
    },
    'schedulerPinterestStore.addToSameBoardSetting': {
      handler(to) {
        if (to) {
          const { galleries, board } = to;
          this.mediaItems = this.mediaItems.map((pin) => {
            const mutablePin = pin;
            mutablePin.selectedGalleries = galleries || [];
            mutablePin.pinterestBoard = board;
            return mutablePin;
          });
          this.validatePinContent(this.mediaItems);
          this.schedulerPinterestStore.triggerPinUpdate();
        }
      },
    },
    'schedulerPinterestStore.publishAtSameTimeSetting': {
      handler(to) {
        if (to && to.on) {
          const { type, timestamp } = to;
          this.mediaItems = this.mediaItems.map((pin) => {
            const mutablePin = pin;
            mutablePin.publishType = type || 'autoPublish';
            mutablePin.scheduledTime = timestamp;
            return mutablePin;
          });
          this.schedulerPinterestStore.triggerPinUpdate();
        }
      },
    },
    'schedulerPinterestStore.selectedPin': {
      handler(to) {
        if (to) {
          this.screen = 'EditPin';
        } else {
          this.screen = 'MainControl';
        }
      },
    },
    mediaList: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    scheduledTime(to, from) {
      // If we're in the process of saving a post, we don't want to update the scheduled time
      if (to && this.isSaving) {
        this.scheduledTime = from;
      }
    },
  },
  mounted() {
    if (this.schedulerPinterestStore.selectedPin) {
      this.mediaItems = [this.schedulerPinterestStore.selectedPin];
      this.screen = 'EditPin';
    }
  },
  beforeUnmount() {
    this.productStore.clearUrlMetadata();
    this.schedulerPinterestStore.updateAddToSameBoard({ setting: null });
    this.schedulerPinterestStore.updatePublishAtSameTime({ setting: null });
    this.schedulerPinterestStore.setSelectedPin({ pin: null });
  },
  methods: {
    async init() {
      if (!this.mediaList) {
        return;
      }

      this.loading = true;
      const mutableItems = await this.getMediaFromIds();
      mutableItems.map(async (media) => {
        await this.mediaLinksStore.listMediaLinks({
          brandId: this.authStore.currentBrand.id,
          mediaId: media.id,
        });
        if (this.mediaLinksStore.mediaLinks && this.mediaLinksStore.mediaLinks.length > 0) {
          const link = this.mediaLinksStore.mediaLinks[0];
          media.linkUrl = link.url;
          media.title = link.title;
        }
        media.publishType = this.initialPublishType;
        return media;
      });

      this.updateAddToSameBoardSettingFromMediaList(mutableItems);
      this.updatePublishAtSameTimeSettingFromMediaList(mutableItems);
      if (mutableItems.length > 0) mutableItems[0].description = this.mediaList?.[0]?.description;
      this.mediaItems = mutableItems;

      this.loading = false;
    },
    setUtmLink(newUrl, trackingParameters, urlLinkCustomized) {
      this.utmNewUrl = newUrl;
      this.modifiedUtmsMap[this.utmPanelLinkNumber - 1] = urlLinkCustomized;
      this.mediaItems[this.utmPanelLinkNumber - 1].linkUrl = newUrl;
    },
    async getUtmSettings() {
      return fetchUtmSettings({
        brandId: this.authStore.currentBrand.id,
        channel: this.utmChannel,
      });
    },
    async openMediaCropper(media, index) {
      if (media.transforms && media.transforms.parentMediaId) {
        await this.cropperStore.getCropperOriginalMedia({
          brandId: this.authStore.currentBrand.id,
          mediaId: media.transforms.parentMediaId,
        });
      }
      this.mediaToCrop = media;
      this.mediaToCropIdx = index;
      this.showMediaCropperPopup = true;

      this.trackingStore.track('Open media cropper', {
        platformType: 'Pinterest Bulk Post',
        mediaType: media.type,
      });
    },
    updateAddToSameBoardSettingFromMediaList(mediaList) {
      let setting = null;
      if (mediaList.length === 1) {
        setting = {
          on: true,
          board: mediaList[0].pinterestBoard,
          galleries: mediaList[0].selectedGalleries,
        };
      } else if (mediaList.length > 1) {
        setting = {
          on: false,
        };
      }

      if (setting) {
        this.schedulerPinterestStore.updateAddToSameBoard({ setting });
      }
    },
    updatePublishAtSameTimeSettingFromMediaList(mediaList) {
      let setting = null;
      if (mediaList.length === 1) {
        setting = {
          on: true,
          type: mediaList[0].publishType,
          timestamp: mediaList[0].scheduledTime,
        };
      } else if (mediaList.length > 1) {
        setting = {
          on: false,
        };
      }

      if (setting) {
        this.schedulerPinterestStore.updatePublishAtSameTime({ setting });
      }
    },
    validatePinContent(pins) {
      this.allPinsHaveTitles = pins.every((p) => Boolean(p.title));
      this.allPinsHaveBoards = pins.every((p) => Boolean(p.pinterestBoard));
    },
    closeMediaCropperPopup() {
      this.showMediaCropperPopup = false;
      this.mediaToCropIdx = null;
      this.cropperStore.removeCropperOriginalMedia();
    },
    async replaceMediaItem(mediaItem) {
      const targetIndex = this.mediaToCropIdx;
      await this.mediaStore.validateMediaObject({
        brandId: this.authStore.currentBrand.id,
        media: mediaItem,
      });

      const newMedia = this.mediaStore.mediaV2;
      const copy = cloneDeep(this.mediaItems[targetIndex]);
      // Delete upload data.
      if ('fullMediaObject' in copy) {
        delete copy.fullMediaObject;
        delete copy.uploadStatus;
        delete copy.uploadProgress;
      }
      Object.assign(copy, newMedia);
      this.mediaItems.splice(targetIndex, 1, copy);
      this.closeMediaCropperPopup();
    },
    addActionClicked(option) {
      this[option]();
    },
    async checkMediaFormat() {
      let disablePublishNow = !this.canAutoPublish;
      let errorMessage = '';
      let allUnscheduled = true;
      let someUploading = false;
      let anyInvalidMedia = false;

      this.mediaItems.forEach((media) => {
        if (media.uploadStatus && media.uploadStatus !== 'success') {
          someUploading = true;
        }
        // Check link validation status
        if (media.scheduledTime) {
          allUnscheduled = false;
        }
        if (media.invalidLink) {
          disablePublishNow = true;
          if (media.linkUrl.length > 2000) {
            errorMessage = toolTips.invalidLinkLength;
          } else {
            errorMessage = toolTips.invalidLinkURL;
          }
        }
        // undefined > Number === false
        if (media.title?.length > pinterestCaptionLimit.title) {
          errorMessage = toolTips.maxPinTitleCharactersReached;
        }
        if (media.pinterest?.note?.length > pinterestCaptionLimit.description) {
          errorMessage = toolTips.maxPinDescriptionCharactersReached;
        }
        // validate pin
        if (!media.publishType) {
          media.publishType = this.getValidMediaPublishType;
        }
        const errMsg = validatePin(
          media,
          media.pinterestBoard,
          media.title,
          media.publishType === 'autoPublish',
          media.scheduledTime,
        );
        // don't allow immediate publishing if any errors
        if (errMsg) {
          errorMessage = errMsg;
          disablePublishNow = true;
        }
      });

      await this.validateMediaList();
      if (this.mediaValidationError) {
        anyInvalidMedia = true;
      }

      if (anyInvalidMedia) {
        this.errorMessage = pinterestBadMediaRequirementsMessage;
        this.disablePublishNow = true;
        return;
      }
      if (someUploading) {
        this.errorMessage = toolTips.mediaUploading;
        this.disablePublishNow = true;
        return;
      }
      if (allUnscheduled && !this.mediaValidationError && !errorMessage) {
        this.errorMessage = '';
        this.disablePublishNow = disablePublishNow;
        return;
      }
      if (!this.canAutoPublish) {
        this.errorMessage = toolTips.insufficientPermission;
        this.disablePublishNow = true;
      }

      this.errorMessage = errorMessage;
      this.disablePublishNow = disablePublishNow;
    },
    closeSelectMediaPopup() {
      this.showSelectMediaPopup = false;
    },
    findFile(file) {
      return this.mediaItems.find((m) => m.id === file.upload.uuid);
    },
    async libraryMediaSelected(mediaList) {
      this.disablePublishNow = true;
      this.validatePinContent(mediaList);
      // Remove any existing error message
      this.displayErrorMessage(null);
      const promises = mediaList.map(async (media) => {
        await this.mediaLinksStore.listMediaLinks({
          brandId: this.authStore.currentBrand.id,
          mediaId: media.id,
        });
        if (this.mediaLinksStore.mediaLinks && this.mediaLinksStore.mediaLinks.length > 0) {
          const link = this.mediaLinksStore.mediaLinks[0];
          media.linkUrl = link.url;
          media.title = link.title;
        }
        return media;
      });
      await Promise.all(promises);
      this.mediaItems = [...this.mediaItems, ...mediaList];
    },
    mediaItemsAdded(items) {
      this.disablePublishNow = true;
      const existingIds = this.mediaItems.map((item) => item.id);
      const newItems = items.filter((item) => existingIds.indexOf(item.id) < 0);
      this.mediaItems = uniq([...this.mediaItems, ...newItems]).splice(
        0,
        this.pinterestMediaCountLimit,
      );
    },
    mediaItemRemoved(mediaId) {
      this.mediaItems = this.mediaItems.filter((item) => item.id !== mediaId);
      this.notificationStore.setToast({
        message: this.uploadErrorMessage,
        type: 'error',
      });
    },
    openSelectMediaPopup() {
      this.showSelectMediaPopup = true;
    },
    pinUpdated(pin) {
      const targetIndex = this.mediaItems.map((p) => p.id).indexOf(pin.id);
      this.mediaItems[targetIndex] = pin;
      // ugly way of triggering pin update event in child components
      this.schedulerPinterestStore.triggerPinUpdate();
      this.checkMediaFormat();
    },
    removePin(pin) {
      this.mediaItems = this.mediaItems.filter((item) => item.id !== pin.id);
      this.mediaValidationError = null;
    },
    focusPinLink(pin) {
      this.screen = 'EditPin';
      this.schedulerPinterestStore.setSelectedPin({ pin });
      nextTick(() => {
        this.$refs.singlePinMetaFields.focusWebLink(pin);
      });
    },
    switchSubScreen(screenName) {
      if (screenName === 'MainControl') {
        this.schedulerPinterestStore.setSelectedPin({ pin: null });
      }
      this.screen = screenName;
    },
    switchMediaView(view) {
      this.mediaView = view;
      this.schedulerPinterestStore.setSelectedPin({ pin: null });
      // always show board control screen in list view.
      if (view === 'list') {
        this.screen = 'MainControl';
      }
    },
    async generateThumbnails(mediaItems) {
      const thumbs = {};
      const generators = [];

      mediaItems.forEach((pin) => {
        const curThumbGenerator = new ThumbnailUrlGenerator();
        generators.push(curThumbGenerator);
        if (pin.type === 'VIDEO' && pin.thumbOffset) {
          thumbs[pin.id] = curThumbGenerator.createThumbnailUrl([pin], pin.thumbOffset);
        }
      });

      const ret = await recursiveObjectPromiseAll(thumbs);
      // Wait to destroy thumbGenerators until promises are resolved (destroy to avoid mem leaks)
      generators.forEach((gen) => {
        gen.destroy();
      });

      return ret;
    },
    async encodeUtmForPin(pin) {
      if (!pin.linkUrl) return;
      const utmResponse = await LibraryAPI.encodeUtmSettings({
        brandId: this.authStore.currentBrand.id,
        content: pin.linkUrl,
        channel: this.utmChannel,
        shortenUrl: false,
        mediaId: pin.id,
        productId: pin.productId,
        urls: [pin.linkUrl],
      });
      pin.linkUrl = utmResponse.data.content;
    },
    async encodeUtms() {
      const promises = this.mediaItems.map((pin) => {
        return this.encodeUtmForPin(pin);
      });
      await Promise.all(promises);
    },
    async constructPosts(mediaItems, publishNow, currentTime, isDraft) {
      const newPosts = [];
      const thumbs = await this.generateThumbnails(mediaItems);

      mediaItems.forEach((pin, index) => {
        const meta = {};
        if (pin.id in thumbs) {
          meta.thumbnail_url = thumbs[pin.id];
          meta.thumb_offset = pin.thumbOffset * 1000;
        }
        const data = {
          platform: PLATFORMS.PINTEREST,
          media_ids: [pin.id],
          brand_id: this.authStore.currentBrand.id,
          board_ids: pin.selectedGalleries?.filter((g) => !g.campaign).map((g) => g.id) ?? [],
          campaign_ids: pin.selectedGalleries?.filter((g) => g.campaign).map((g) => g.id) ?? [],
          link: pin.linkUrl || '',
          note: pin.description || '',
          timestamp: pin.scheduledTime?.toISOString() || this.defaultTimestamp?.toISOString(),
          auto_publish: this.canAutoPublish && pin.publishType !== 'notification',
          meta: {
            ...meta,
            title: pin.title,
            pinterestBoard: pin.pinterestBoard,
          },
          ...(isDraft ? { status: postStatus.DRAFT } : {}),
        };
        if (publishNow) {
          data.status = postStatus.AUTOPUBLISHING;
          data.timestamp = currentTime;
          data.auto_publish = true;
        }
        data.custom_utms = this.modifiedUtmsMap[index] || false;

        if (pin.scheduledTime) {
          this.timelinePostCount += 1;
        } else {
          this.unScheduledPostCount += 1;
        }
        newPosts.push(data);

        this.schedulerStore.createPost({ bulkSchedule: true, ...data });
      });

      return newPosts;
    },
    async save(publishNow, isDraft) {
      const currentTime = dayjs().toISOString();

      this.checkMediaFormat();
      if (this.errorMessage) {
        return;
      }
      this.isSaving = true;
      try {
        await this.encodeUtms();
      } catch (error) {
        this.notificationStore.setToast({
          message: somethingWentWrongTitle,
          subtext: somethingWentWrongMessage,
          type: 'error',
        });
        logger.error(error);
        return;
      }
      await this.constructPosts(this.mediaItems, publishNow, currentTime, isDraft);

      const timelinePinLabel = this.timelinePostCount === 1 ? 'Pin' : 'Pins';
      const unscheduledPinLabel = this.unScheduledPostCount === 1 ? 'Pin' : 'Pins';

      if (publishNow) {
        this.notificationStore.setToast({
          message: 'Publishing in progress. See Pins on your',
          actionText: 'timeline.',
          actionTo: { name: 'scheduler.pinterest.timeline' },
          type: 'primary',
        });
      } else {
        if (this.timelinePostCount > 0) {
          this.notificationStore.setToast({
            message: `${this.timelinePostCount} ${timelinePinLabel} added to your`,
            actionText: 'timeline.',
            actionTo: { name: 'scheduler.pinterest.timeline' },
          });
        }
        if (this.unScheduledPostCount > 0) {
          this.notificationStore.setToast({
            message: `${this.unScheduledPostCount} ${unscheduledPinLabel} added to your `,
            actionText: 'unscheduled Pins.',
            actionTo: { name: getUnscheduledRouteName(PLATFORMS.PINTEREST) },
            sameLine: true,
          });
        }
      }
      this.isSaving = false;
      this.close();
    },
    async validateMediaList() {
      if (!this.authStore.currentBrand?.id) {
        return;
      }
      const validations = this.mediaItems.map((media) => validatePinterestMedia(media));
      const validationResults = await Promise.all(validations);
      this.mediaValidationError = validationResults.find((result) => result) || null;
      if (this.mediaValidationError && this.errorMessage === '') {
        this.errorMessage = pinterestBadMediaRequirementsMessage;
      }
    },
    uploadClicked() {
      if (this.$refs.dropzone) {
        this.$refs.dropzone.openFileDialog();
      }
    },
    displayErrorMessage(message) {
      this.uploadErrorMessage = message;
    },
    async handleUploadConverted(file) {
      const f = this.mediaItems.find((m) => m.id === file.id);
      const res = await externalAxios.get(file.urls.full, { responseType: 'blob' });
      if (res.status === 200) {
        const vid = URL.createObjectURL(res.data);
        if (f) {
          f.type = file.type;
          f.previewUrl = vid;
          f.uploadStatus = UPLOAD_STATUS.SUCCESS;
          f.fullMediaObject.urls = file.urls;
          f.fullMediaObject.videoSizes = humps.camelizeKeys(file.video_sizes);
          f.duration = file.duration;
        }
        this.validateMediaList();
      }
    },
    handleUploadError(file) {
      // Remove media that caused error
      if (file && file.upload && file.upload.uuid) {
        this.mediaItems = this.mediaItems.filter((m) => m.id !== file.upload.uuid);
      }
      // Display error message
      this.displayErrorMessage(
        'An error occurred while trying to upload your media.  Please try again.',
      );
    },
    handlePreviewGenerated(file, dataUrl) {
      const f = this.findFile(file);
      if (f) {
        f.url = dataUrl;
        f.width = file.width ? file.width : null;
        f.height = file.height ? file.height : null;
      }
    },
    handleUploadProgress(file, progress) {
      const f = this.findFile(file);
      if (f) {
        f.uploadProgress = progress;
        f.uploadStatus = UPLOAD_STATUS.UPLOADING;
      }
    },
    handleUploadStarted(file) {
      // Remove any existing error message
      this.displayErrorMessage(null);
      this.mediaItems.push({
        id: file.upload.uuid,
        type: file.type.split('/')[0].toUpperCase(),
        url: file.dataURL,
        handleUploadProgress: 0,
        uploadStatus: file.status,
      });
    },
    async handleUploadSuccess(file, media) {
      const f = this.findFile(file);
      if (f) {
        f.id = media.mediaId;
        await this.mediaStore.validateMediaObject({
          brandId: this.authStore.currentBrand.id,
          media: f,
        });

        const uploadedMedia = this.mediaStore.mediaV2;
        if (f.type === 'IMAGE') {
          f.uploadStatus = UPLOAD_STATUS.SUCCESS;
          f.previewUrl = media.urls.full;
        } else {
          f.uploadStatus = UPLOAD_STATUS.PROCESSING;
        }
        f.fullMediaObject = uploadedMedia;
      }
    },
    toggleUtmPanel(index) {
      const action = this.showUtmPanel ? 'close' : 'open';

      if (
        index === undefined ||
        index + 1 === this.utmPanelLinkNumber ||
        (index + 1 !== this.utmPanelLinkNumber && !this.showUtmPanel)
      ) {
        this.showUtmPanel = !this.showUtmPanel;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action,
        });
      }

      if (this.showUtmPanel) {
        this.utmPreviewLink = this.mediaItems[index]?.linkUrl ?? '';
        this.utmPanelTitle = this.mediaItems[index]?.title ?? '';
        this.utmPanelLinkNumber = index + 1;
      }
    },
    switchUtmPanel: debounce(function utmPanel() {
      const pinLink = this.mediaItems.find((p) => p?.linkUrl);
      this.utmPreviewLink = pinLink?.linkUrl ?? '';
      if (this.utmPreviewLink) {
        if (!this.showUtmPanel) {
          this.showUtmPanel = true;
          trackSchedulerUtmPopout({
            ...this.trackingData,
            action: 'auto open',
          });
        }
      } else if (this.showUtmPanel) {
        this.showUtmPanel = false;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: 'auto close',
        });
      }
    }, debounceInputDelay),
    async getMediaFromIds() {
      if (this.mediaList?.length > 0) {
        const mediaArrList = this.mediaList;
        const brandId = this.authStore.currentBrand.id;
        const mediaList = await this.mediaStore.validateMediaList({
          brandId,
          mediaArrList,
        });
        return mediaList ?? [];
      }
      return [];
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.bulk-add-pin-container {
  display: flex;
  min-height: 75vh;

  .media {
    width: 70%;

    .cover-full {
      width: 100%;
      min-height: calc(75vh - 2rem);
    }
  }

  .media.fullWidth {
    width: 100%;
  }
}

.error-message {
  margin: var(--space-16) var(--space-24);
  padding: var(--space-8) var(--space-16);
  background-color: var(--error-100);
  color: var(--error-500);
  font-size: var(--x13);
  border-radius: var(--round-corner-small);
}

.upload-error,
.banner-message {
  margin: 0 var(--space-32);
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--space-32) var(--space-4) var(--space-16);
  padding: 0 var(--space-12) 0 var(--space-28);

  .schedule-pins {
    font-weight: var(--font-medium);
    font-size: var(--x18);
    color: var(--text-primary);

    span {
      color: var(--action-500);
    }
  }

  .right-section {
    width: 15rem;
    display: flex;
    justify-content: space-evenly;

    button {
      justify-content: center;
      align-items: center;
      background: var(--background-0);
      cursor: pointer;
      box-sizing: content-box;
      border: 0;
    }
  }
}
</style>
