/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pinterest': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M11 0C4.927 0 0 4.925 0 11c0 4.504 2.709 8.374 6.586 10.075-.031-.767-.006-1.69.19-2.526.212-.893 1.415-5.993 1.415-5.993s-.35-.703-.35-1.742c0-1.63.945-2.847 2.12-2.847 1 0 1.485.752 1.485 1.652 0 1.005-.642 2.51-.972 3.903-.275 1.168.585 2.119 1.736 2.119 2.084 0 3.488-2.677 3.488-5.849 0-2.41-1.624-4.214-4.577-4.214-3.336 0-5.415 2.488-5.415 5.266 0 .96.282 1.635.724 2.158.205.242.232.337.158.614-.052.2-.173.69-.224.883-.073.278-.298.379-.55.275-1.537-.627-2.253-2.31-2.253-4.203 0-3.124 2.635-6.873 7.861-6.873 4.2 0 6.965 3.042 6.965 6.303 0 4.315-2.398 7.539-5.936 7.539-1.186 0-2.304-.642-2.687-1.37 0 0-.64 2.534-.774 3.023-.232.847-.69 1.696-1.107 2.356.99.292 2.035.451 3.118.451C17.075 22 22 17.075 22 11S17.075 0 11 0" _fill="#adadad" fill-rule="nonzero"/>'
  }
})
