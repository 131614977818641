import { unref, computed } from 'vue';
import { extractBenchmarksObjectFromIndustries } from '@/app/dashboards/utils/benchmarks.utils';
import {
  extractReportType,
  getBrandOccurrenceInfo,
  getCompetitorOccurrenceInfo,
  getMediaTypeOccurrenceInfo,
  getSimplifiedOccurrenceDict,
} from '@/app/dashboards/utils/dashboard-general.util';
import { CHANNELS } from '@/models/dashboards/channels.enum';

function getAdAccountOccurrenceInfo(occurrences, report) {
  const ids = report?.meta?.source_ad_account_ids || [];
  const channel = report?.meta?.channels?.[0];
  ids.forEach((id) => {
    if (!occurrences?.[channel]?.[id]) {
      occurrences[channel][id] = {
        id,
        count: 1,
      };
    } else {
      occurrences[channel][id].count += 1;
    }
  });
}

function getAdCampaignOccurrenceInfo(occurrences, report) {
  const ids = report?.meta?.source_campaign_ids || [];
  const channel = report?.meta?.channels?.[0];
  ids.forEach((id) => {
    if (!occurrences?.[channel]?.[id]) {
      occurrences[channel][id] = {
        id,
        count: 1,
      };
    } else {
      occurrences[channel][id].count += 1;
    }
  });
}

export function useDashboardTrackingProps({
  dashboard,
  reportDateRange,
  contextDateRange,
  reports,
  industries,
  brands,
  competitors,
} = {}) {
  const reportsList = Object.values(unref(reports) ?? []).filter(
    (report) => report.dashboard_id === unref(dashboard)?.id,
  );
  const brandOccurrences = {};
  const competitorOccurrences = {};
  const channelsOccurrences = {};
  const reportTypesOccurrences = {};
  const aggregateByOccurrences = {};
  const mediaTypeMetricsOccurrences = {};
  const baseMetricOccurrences = {};
  const mediaTypeOccurrences = {};
  const benchmarksOccurrences = {};
  const adAccountOccurrences = { [CHANNELS.META_ADS.value]: {}, [CHANNELS.TIKTOK_ADS.value]: {} };
  const adCampaignOccurrences = { [CHANNELS.META_ADS.value]: {}, [CHANNELS.TIKTOK_ADS.value]: {} };
  const reportIdsIncluded = [];
  let numberReportsWithBenchmarks = 0;
  let totalNumberOfBenchmarks = 0;
  let numberOfReportsWithCompetitors = 0;
  let totalNumberOfCompetitors = 0;
  let numberOfReportsWithStaticDates = 0;
  let numberOfReportsWithAds = 0;

  reportsList.forEach((report) => {
    const reportType = report?.type || '';
    const aggregateBy = report?.meta?.aggregate_by || '';
    const brandIds = report?.meta?.brand_ids || [];
    const channels = report?.meta?.channels || [].concat(report?.meta?.channel || []);
    const metrics = report?.meta?.metrics || [].concat(report?.meta?.metric || []);

    reportIdsIncluded.push(report.id);

    if (report?.meta?.static_dates) {
      numberOfReportsWithStaticDates += 1;
    }

    const benchmarks = report?.meta?.benchmarks || [];
    const benchmarksNames = extractBenchmarksObjectFromIndustries(benchmarks, unref(industries));
    if (benchmarksNames.length > 0) {
      numberReportsWithBenchmarks += 1;
    }
    benchmarksNames.forEach((benchmark) => {
      totalNumberOfBenchmarks += 1;
      const formattedName = benchmark.name.replace(':', ' -');
      benchmarksOccurrences[formattedName] = (benchmarksOccurrences?.[formattedName] || 0) + 1;
    });

    if (reportType) {
      const updatedReportType = extractReportType(reportType, brandIds, metrics, channels);
      reportTypesOccurrences[updatedReportType] =
        (reportTypesOccurrences?.[updatedReportType] || 0) + 1;
    }

    if (aggregateBy) {
      aggregateByOccurrences[aggregateBy] = (aggregateByOccurrences?.[aggregateBy] ?? 0) + 1;
    }

    channels.forEach((reportChannel) => {
      if (reportChannel) {
        channelsOccurrences[reportChannel] = (channelsOccurrences?.[reportChannel] || 0) + 1;
      }
    });

    getMediaTypeOccurrenceInfo(
      mediaTypeOccurrences,
      mediaTypeMetricsOccurrences,
      baseMetricOccurrences,
      metrics,
      channels,
      reportType,
    );

    getBrandOccurrenceInfo(brandOccurrences, report, unref(brands));
    if (unref(competitors)?.length > 0) {
      numberOfReportsWithCompetitors += 1;
      totalNumberOfCompetitors += unref(competitors).length;
    }
    getCompetitorOccurrenceInfo(competitorOccurrences, report, unref(competitors));

    if (report?.meta?.source_ad_account_ids?.length > 0) {
      numberOfReportsWithAds += 1;
    }
    getAdAccountOccurrenceInfo(adAccountOccurrences, report);
    getAdCampaignOccurrenceInfo(adCampaignOccurrences, report);
  });
  const brandIdOccurrences = getSimplifiedOccurrenceDict(brandOccurrences, 'id');
  const brandNameOccurrences = getSimplifiedOccurrenceDict(brandOccurrences, 'name');
  const competitorIdOccurrences = getSimplifiedOccurrenceDict(competitorOccurrences, 'id');
  const competitorHandleOccurrences = getSimplifiedOccurrenceDict(competitorOccurrences, 'handle');

  const metaAdAccountIdOccurrences = getSimplifiedOccurrenceDict(
    adAccountOccurrences.META_ADS,
    'id',
  );
  const tikTokAdAccountIdOccurrences = getSimplifiedOccurrenceDict(
    adAccountOccurrences.TIKTOK_ADS,
    'id',
  );
  const metaAdCampaignIdOccurrences = getSimplifiedOccurrenceDict(
    adCampaignOccurrences.META_ADS,
    'id',
  );
  const tikTokAdCampaignIdOccurrences = getSimplifiedOccurrenceDict(
    adCampaignOccurrences.TIKTOK_ADS,
    'id',
  );

  const dashboardTrackingProps = computed(() => {
    const results = {};
    if (unref(dashboard)) {
      results.dashboardName = unref(dashboard).name;
      results.dashboardId = unref(dashboard).id;
    }

    if (unref(reportDateRange)) {
      results.dashboardStartDate = unref(reportDateRange)?.[0];
      results.dashboardEndDate = unref(reportDateRange)?.[1];
    }

    if (unref(contextDateRange)) {
      results.dashboardContextStartDate = unref(contextDateRange)?.[0];
      results.dashboardContextEndDate = unref(contextDateRange)?.[1];
    }

    if (unref(reports)) {
      results.numberOfReports = reportsList.length;
      results.reportTypesIncluded = Object.keys(reportTypesOccurrences);
      results.reportTypesAmounts = reportTypesOccurrences;
      results.reportAggregateByIncluded = Object.keys(aggregateByOccurrences);
      results.reportAggregateByAmounts = aggregateByOccurrences;
      results.numberOfReportsWithStaticDates = numberOfReportsWithStaticDates;
      results.reportIdsIncluded = reportIdsIncluded;
      results.metricsIncluded = Object.keys(baseMetricOccurrences);
      results.metricsAmounts = baseMetricOccurrences;
      results.reportMetricsMediaTypeMetricsIncluded = Object.keys(mediaTypeMetricsOccurrences);
      results.reportMetricsMediaTypeMetricsAmounts = mediaTypeMetricsOccurrences;
      results.channelsIncluded = Object.keys(channelsOccurrences);
      results.channelsAmounts = channelsOccurrences;
      results.reportMetricsMediaTypeIncluded = Object.keys(mediaTypeOccurrences);
      results.reportMetricsMediaTypeAmounts = mediaTypeOccurrences;

      if (unref(brands)) {
        results.brandIdsIncluded = Object.keys(brandIdOccurrences);
        results.brandIdsAmounts = brandIdOccurrences;
        results.brandNamesIncluded = Object.keys(brandNameOccurrences);
        results.brandNamesAmounts = brandNameOccurrences;
      }

      if (unref(competitors)) {
        results.totalNumberOfCompetitors = totalNumberOfCompetitors;
        results.numberOfReportsWithCompetitors = numberOfReportsWithCompetitors;
        results.competitorIdsIncluded = Object.keys(competitorIdOccurrences);
        results.competitorIdsAmounts = competitorIdOccurrences;
        results.competitorHandlesIncluded = Object.keys(competitorHandleOccurrences);
        results.competitorHandlesAmounts = competitorHandleOccurrences;
      }

      if (unref(industries)) {
        results.benchmarksIncluded = Object.keys(benchmarksOccurrences);
        results.benchmarksAmounts = benchmarksOccurrences;
        results.numberOfReportsWithBenchmarks = numberReportsWithBenchmarks;
        results.totalNumberOfBenchmarks = totalNumberOfBenchmarks;
      }

      results.numberOfReportsWithAds = numberOfReportsWithAds;
      results.metaAdAccountIdsIncluded = Object.keys(metaAdAccountIdOccurrences);
      results.metaAdAccountIdsAmounts = metaAdAccountIdOccurrences;
      results.tikTokAdAccountIdsIncluded = Object.keys(tikTokAdAccountIdOccurrences);
      results.tikTokAdAccountIdsAmounts = tikTokAdAccountIdOccurrences;
      results.metaAdCampaignIdsIncluded = Object.keys(metaAdCampaignIdOccurrences);
      results.metaAdCampaignIdsAmounts = metaAdCampaignIdOccurrences;
      results.tikTokAdCampaignIdsIncluded = Object.keys(tikTokAdCampaignIdOccurrences);
      results.tikTokAdCampaignIdsAmounts = tikTokAdCampaignIdOccurrences;
    }

    return results;
  });

  return {
    dashboardTrackingProps,
  };
}
