/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'videoControlPause': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g id="Media-Cropping-Feature"><g id="pause" transform="translate(-7 -5)"><g transform="translate(6.5 4.5)" id="color_x2F_border"><g id="Mask"><path pid="0" id="path-1_1_" d="M7.5 5.5h2v14h-2v-14zm8 0h2v14h-2v-14z" fill-rule="evenodd" clip-rule="evenodd" _fill="#666"/></g></g></g></g>'
  }
})
