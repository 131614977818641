/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paid': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle pid="0" cx="8.001" cy="8" r="6" _fill="#FBFBFB"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.99 13.005a4.996 4.996 0 100-9.991 4.996 4.996 0 000 9.991zm0 1.661a6.657 6.657 0 100-13.313 6.657 6.657 0 000 13.313z" _fill="#686a7a"/><path pid="2" d="M7.284 4.491h1.42v.694h1.404v1.42h-2.46c-.1 0-.188.033-.265.1a.336.336 0 00-.1.247c0 .099.034.181.1.247.077.067.165.1.264.1h.694c.484 0 .897.176 1.238.528.352.341.529.754.529 1.238 0 .43-.138.804-.413 1.123-.264.32-.595.518-.991.595v.743h-1.42v-.694H5.88v-1.42h2.46c.1 0 .182-.033.248-.099a.313.313 0 00.116-.248c0-.099-.039-.181-.116-.247a.336.336 0 00-.247-.1h-.694c-.484 0-.903-.17-1.255-.511a1.742 1.742 0 01-.512-1.255c0-.43.132-.804.396-1.123.276-.32.611-.518 1.008-.595v-.743z" _fill="#686a7a"/>'
  }
})
