<script setup>
import { computed, onMounted, ref, toRef } from 'vue';
import ActionPopup from '@/components/ActionPopup.vue';
import { useTiktokStore } from '@/stores/tiktok';
import { usePlatformStore } from '@/stores/platform';
import { useNotificationStore } from '@/stores/notification';
import { useTikTokCreatorMarketplace } from '@/app/settings/composables/tiktokCreatorMarketplace';
import { storeToRefs } from 'pinia';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import AccountToggle from '@/components/AccountToggle.vue';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { connectionNotifications } from '@/app/auth/constants';
import dayjs from 'dayjs';
import { getUserTimezone } from '@/utils/timezone';

const tiktokStore = useTiktokStore();
const platformStore = usePlatformStore();
const notificationStore = useNotificationStore();

// Props
const props = defineProps({
  brand: {
    type: Object,
    required: true,
  },
});
const brand = toRef(props, 'brand');

// Events
const emit = defineEmits(['close']);

// State
const initialIsMonitoredMap = ref();

// computed
const { ttcmAccountsFromApi: accounts, pending } = storeToRefs(tiktokStore);

const hasAccounts = computed(() => {
  return accounts.value?.length > 0;
});

const {
  brandId,
  canEnableAccounts,
  hasMonitoredTTCMAccount: hasAlreadyMonitoredTTCMAccount,
  platformConnection,
} = useTikTokCreatorMarketplace(brand);

const isFirstTimeConnecting = computed(() => {
  return !hasAlreadyMonitoredTTCMAccount.value;
});
const monitoredAccountsCount = computed(
  () => accounts.value.filter((account) => account.isMonitored).length,
);

const popupTitle = computed(() => {
  return canEnableAccounts.value
    ? 'Enable TikTok Creator Marketplace'
    : 'TikTok Creator Marketplace Accounts';
});
const popupMessage = computed(() => {
  if (!hasAccounts.value) return null;

  if (canEnableAccounts.value) {
    return 'Select the creator marketplace accounts you want to associate with this brand.';
  }
  return 'TikTok Creators authorization and invites are linked to these accounts.';
});
const popupButtonLabel = computed(() => {
  return hasAccounts.value ? 'Done' : 'OK';
});
const popupSize = computed(() => {
  return hasAccounts.value ? 'medium' : 'small';
});

// actions
const changeAccountMonitoredStatus = async (account, value) => {
  const updatedAccount = accounts.value.find(
    (element) => element.sourceTtcmAccountId === account.sourceTtcmAccountId,
  );
  updatedAccount.isMonitored = value;
};

const closePopup = () => {
  platformStore.closeConnectionPopup();
  emit('close');
};

const disconnectTikTokCreatorMarketplace = async () => {
  try {
    const connectionId = platformConnection.value?.id;
    await platformStore.deletePlatformConnection({
      platform: PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE,
      connectionId,
      brandId: brandId.value,
    });
  } catch (error) {
    notificationStore.setToast({
      message: 'Something went wrong, please try again.',
      type: 'error',
    });
  }
};

const formatTooltip = (account) => {
  if (!initialIsMonitoredMap.value || !initialIsMonitoredMap.value[account.sourceTtcmAccountId]) {
    return '';
  }

  const connectedByRow = platformConnection.value?.connected_by_name
    ? `<br>Connected by ${platformConnection.value.connected_by_name}`
    : '';

  let connectedAtRow = '';
  if (account.connectedAt) {
    const connectedAtDate = dayjs.utc(account.connectedAt).tz(getUserTimezone());
    connectedAtRow = `<br>${connectedAtDate.format('DD/MM/YYYY')}`;
  }

  return `${account.sourceTtcmAccountId}${connectedByRow}${connectedAtRow}`;
};

const exit = async () => {
  const hasModifiedAccounts = accounts.value.some(
    (account) => account.isMonitored !== initialIsMonitoredMap.value[account.sourceTtcmAccountId],
  );

  if (canEnableAccounts.value && (hasModifiedAccounts || isFirstTimeConnecting.value)) {
    await notificationStore.confirm(
      'Exit Connection Process?',
      'If you exit now, your account won’t be connected',
      {
        confirmAlias: 'Exit',
        onConfirm: disconnectTikTokCreatorMarketplace,
      },
    );
  }
  closePopup();
};
const saveChanges = async () => {
  const modifiedAccounts = accounts.value.filter(
    (account) => account.isMonitored !== initialIsMonitoredMap.value[account.sourceTtcmAccountId],
  );

  if (canEnableAccounts.value && modifiedAccounts.length > 0) {
    await Promise.all(
      modifiedAccounts.map(async (account) => {
        await tiktokStore.updateCreatorMarketplaceAccount({
          brandId: brandId.value,
          sourceTtcmAccountId: account.sourceTtcmAccountId,
          isMonitored: account.isMonitored,
        });
      }),
    );
    notificationStore.setToast(connectionNotifications.tiktok_creator_marketplace.success);
  }

  platformStore.getTikTokCreatorMarketplaceAccounts(brandId.value);
  closePopup();
};

// hooks
onMounted(async () => {
  await tiktokStore.getBrandTTCMAccounts({
    brandId: brandId.value,
  });
  initialIsMonitoredMap.value = Object.assign(
    {},
    ...accounts.value.map((account) => ({
      [account.sourceTtcmAccountId]: account.isMonitored,
    })),
  );
});
</script>

<template>
  <ActionPopup
    :title="popupTitle"
    :message="popupMessage"
    :button-label="popupButtonLabel"
    :button-disabled="canEnableAccounts && hasAccounts && monitoredAccountsCount === 0"
    :type="popupSize"
    :loading="pending.ttcmAccountsFromApi"
    class="ttcm-accounts-popup"
    @submit="saveChanges"
    @cancel="exit"
  >
    <template #actionPopupContent>
      <SkeletonLoader :loading="pending.ttcmAccountsFromApi" type="adsAccountsPopupSkeleton">
        <ul v-if="hasAccounts" class="brand-list">
          <li v-for="account in accounts" :key="account.sourceTtcmAccountId">
            <AccountToggle
              :key="account.sourceTtcmAccountId"
              :account="account"
              :label="account.sourceTtcmAccountId"
              :disabled="
                !canEnableAccounts || (account.isMonitored && monitoredAccountsCount === 1)
              "
              :toggled="account.isMonitored"
              :wrapper-tooltip="{ content: formatTooltip(account), html: true }"
              @change="changeAccountMonitoredStatus"
            ></AccountToggle>
          </li>
        </ul>
        <p v-else class="empty">
          There are no active TikTok Creator Marketplace accounts associated with your TikTok
          Business Center account.
        </p>
      </SkeletonLoader>
    </template>
  </ActionPopup>
</template>

<style lang="postcss" scoped>
.ttcm-accounts-popup {
  .brand-list > li:nth-child(odd) {
    background-color: var(--background-300);
    border-radius: var(--space-4);
  }

  .empty {
    text-align: center;
    margin-bottom: var(--space-48);
    color: var(--text-secondary);
  }
}
</style>
