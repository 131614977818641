/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_471_44923)"><path pid="0" d="M16.414 1H5c-1.103 0-2 .898-2 2v18c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5.586L16.414 1zM17 14h-2v-2h-2v6h2v2H9v-2h2v-6H9v2H7v-4h10v4zm-2-7V2l5 5h-5z" _fill="#fff"/></g><defs><clipPath id="clip0_471_44923"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
