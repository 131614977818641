<template>
  <section class="ugc-content-rights" data-cy="UgcContentRights">
    <div class="content-rights-top relative">
      <header class="left">
        <Icon small name="copyrights" />
        <span>Content Rights</span>
      </header>
      <CircularLoader
        v-if="platformStore.pending.platformConnections"
        class="absolute right-0 m-0"
        small
      />
      <section v-else-if="showRequestButton">
        <div class="type-wrapper">
          <Button
            :source-id="mediaItem.postSourceId"
            class="request"
            light
            small
            data-cy="RequestButton"
            @click="contentRightsHandle"
          >
            Request
          </Button>

          <DropdownButton
            :dropdown-list="contentRightsDropdown"
            align-right
            button-classes="semi-circle-white"
            @dropdown-action-clicked="dropdownActionClicked"
          >
            <template #buttonContent>
              <Icon dir="right" name="caret" xxsmall />
            </template>
          </DropdownButton>
        </div>
      </section>
      <div
        v-else-if="showType"
        v-on-click-outside="clickOutsideDirection"
        @click="triggerDirection"
      >
        <DropdownButton
          :source-id="mediaItem.postSourceId"
          :dropdown-list="contentRightsDropdown"
          button-classes="rectangular"
          @dropdown-action-clicked="dropdownActionClicked"
        >
          >
          <template #buttonContent>
            <span
              :class="[
                { approved: selectedType === approved },
                { lapsed: selectedType === lapsed },
                { requested: selectedType === requested },
              ]"
              class="left"
            >
              {{ showSelectedType }}
              <Icon :dir="direction" class="right-arrow" xxsmall name="caret" />
            </span>
          </template>
        </DropdownButton>
      </div>
    </div>
    <dl v-if="showRightsRequestApprovalDetail" class="content-rights-bottom">
      <dt v-if="rightsRequestItem.replyComment">
        <span>{{ mediaItem.userName }}:</span>
        {{ rightsRequestItem.replyComment }}
      </dt>
      <dt v-else>Manually approved by brand.</dt>
      <dd v-if="rightsRequestItem.acquiredDate">
        {{ formattedRightsRequestItemAcquiredDate }}
      </dd>
    </dl>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import upperFirst from 'lodash/upperFirst';
import { vOnClickOutside } from '@vueuse/components';
import { useMediaDetailStore } from '@/stores/media-detail';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { contentRightsPostTypes } from '@/app/library/constants';
import ContentRightsMixins from '@/app/instagram/mixins/ContentRightsMixins';
import { useNotificationStore } from '@/stores/notification';
import { useInstagramContentRightsStore } from '@/stores/instagram-content-rights';
import { usePlatformStore } from '@/stores/platform';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ContentRights',
  components: {
    Button,
    Icon,
    DropdownButton,
    CircularLoader,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  mixins: [ContentRightsMixins],
  props: {
    mediaItem: { type: Object, default: null },
    contentRightsHandle: { type: Function, required: true },
    rightsRequestMedia: { type: Object, default: null },
  },
  data() {
    return {
      contentRightsTypes: [],
      direction: '',
      selectedType: contentRightsPostTypes.ORIGINAL_TYPE,
      approved: contentRightsPostTypes.APPROVED,
      lapsed: contentRightsPostTypes.LAPSED,
      requested: contentRightsPostTypes.REQUESTED,
      rightsRequestItem: null,
    };
  },
  computed: {
    ...mapStores(
      useMediaDetailStore,
      useNotificationStore,
      useInstagramContentRightsStore,
      usePlatformStore,
    ),
    formattedRightsRequestItemAcquiredDate() {
      return dayjs(this.rightsRequestItem.acquiredDate).format('LLL');
    },
    showSelectedType() {
      return upperFirst(this.selectedType.toLowerCase());
    },
    showRightsRequestApprovalDetail() {
      if (
        this.rightsRequestItem &&
        this.rightsRequestItem.type === contentRightsPostTypes.APPROVED
      ) {
        return true;
      }
      return false;
    },
    showRequestButton() {
      if (
        (!this.platformStore.pending.platformConnections &&
          !this.mediaItem.contentRightsRequested &&
          this.selectedType === contentRightsPostTypes.ORIGINAL_TYPE) ||
        this.selectedType === contentRightsPostTypes.RESET
      ) {
        return true;
      }

      return false;
    },
    showType() {
      if (
        (this.mediaItem.contentRightsRequested && this.mediaItem.contentRightsRequestType) ||
        this.selectedType !== contentRightsPostTypes.ORIGINAL_TYPE
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    'instagramContentRightsStore.rightsRequestPost': {
      handler(to) {
        if (to) {
          this.rightsRequestItem = to;
          this.selectedType = to.type;
          this.mediaDetailStore.setMediaDetailContentRightsRequested({
            requested: true,
          });
        }
      },
    },
  },
  created() {
    this.rightsRequestItem = this.rightsRequestMedia;
    this.formatType();
  },
  methods: {
    triggerDirection() {
      if (this.direction === '') {
        this.direction = 'right';
      }
    },
    clickOutsideDirection() {
      if (this.direction === 'right') {
        this.direction = '';
      }
    },
    dropdownActionClicked(option) {
      this[option]();
    },
    formatType() {
      if (!this.mediaItem.contentRightsRequested) {
        this.selectedType = contentRightsPostTypes.ORIGINAL_TYPE;
      } else {
        this.selectedType = this.mediaItem.contentRightsRequestType;
      }
    },
    changeType() {
      if (!this.mediaItem.contentRightsRequested && !this.rightsRequestItem) {
        // Manually creating request
        this.instagramContentRightsStore.createRightsRequestMedia({
          postId: this.mediaItem.postSourceId,
          type: this.selectedType,
          requestComment: null,
          termLink: null,
          trackingHashtag: null,
        });
      } else if (this.mediaItem.contentRightsRequested) {
        this.direction = '';
        this.instagramContentRightsStore.updateRightsRequestMedia({
          id: this.rightsRequestItem.id,
          type: this.selectedType,
        });
      }
    },
    selectApproved() {
      this.direction = '';
      this.selectedType = contentRightsPostTypes.APPROVED;
      this.changeType();
      this.mediaDetailStore.setMediaDetailContentRightsRequested({
        requested: true,
      });
    },
    selectLapsed() {
      this.direction = '';
      this.selectedType = contentRightsPostTypes.LAPSED;
      this.changeType();
      this.mediaDetailStore.setMediaDetailContentRightsRequested({
        requested: true,
      });
    },
    async selectReset() {
      this.direction = '';
      this.mediaDetailStore.setMediaDetailContentRightsRequested({
        requested: false,
      });
      const title = 'Reset Status';
      const message = 'Resetting status will remove this post from content rights section.';
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Reset',
        onConfirm: this.confirmReset,
      });
    },
    cancelReset() {
      this.showResetConfirm = false;
    },
    async confirmReset() {
      this.selectedType = contentRightsPostTypes.RESET;
      await this.instagramContentRightsStore.deleteRightsRequestMedia({
        id: this.rightsRequestItem.id,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.ugc-content-rights {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: var(--background-300);
  padding: var(--space-16) var(--space-16);
  border-radius: var(--round-corner-small);
  margin: var(--space-8) 0;

  :deep(.dropdown-button .rectangular.button) {
    border: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  .type-wrapper {
    display: flex;

    button.request {
      min-width: 0;
      transform: none;
      box-shadow: none;
      border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
      border-right: none;
      padding: 0 var(--space-12) 0 var(--space-16);
    }

    .type-dropdown {
      transition: var(--transition-all);
    }
  }

  .dropdown-button .button .right-arrow {
    margin-left: var(--space-12);
    display: flex;
    align-items: center;
  }

  .left {
    display: flex;
    align-items: center;

    p {
      font-size: var(--x16);
      font-weight: var(--font-medium);
      margin-left: var(--space-16);
    }
  }

  dl {
    text-align: left;

    dd {
      font-size: var(--x12);
      color: var(--text-secondary);
      line-height: normal;
      padding-bottom: var(--space-16);
    }

    dt {
      padding: var(--space-8) 0;

      span {
        font-weight: var(--font-medium);
      }
    }
  }

  .content-rights-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    padding: var(--space-8) 0;

    .left span {
      margin-left: var(--space-12);
    }
  }

  .content-rights-bottom {
    padding-left: var(--space-32);
  }

  .approved {
    color: var(--success-500);
  }

  .lapsed {
    color: var(--text-secondary);
  }

  .requested {
    color: var(--alert-400);
  }

  .rights-request-status {
    background: var(--background-0);
    border-radius: var(--button-border-radius);
    border: 1px solid var(--border);
    padding: var(--space-4) var(--space-16);
    text-transform: capitalize;
    font-size: var(--x14);
    font-weight: var(--font-medium);

    &.approved {
      color: var(--success-500);
    }
  }
}
</style>
