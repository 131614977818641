/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'boosted': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<defs><path pid="0" id="svgicon_boosted_a" d="M13 0v2h3.586L11 7.586l-4-4-6.707 6.707 1.414 1.414L7 6.414l4 4 7-7V7h2V0z"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_boosted_b" _fill="#fff"><use xlink:href="#svgicon_boosted_a"/></mask><use _fill="#D6B034" fill-rule="nonzero" xlink:href="#svgicon_boosted_a"/></g>'
  }
})
