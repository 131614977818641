<script setup>
import { computed, ref } from 'vue';
import { Avatar, DropdownBase } from '@dashhudson/dashing-ui';
import UtilityNavigation from '@/components/layout/navigation/UtilityNavigation.vue';
import { useIdentityStore } from '@/stores/identity';
import { useImpersonatorStore } from '@/stores/impersonator';
import BrandSwitcherV2 from '@/components/layout/navigation/BrandSwitcherV2.vue';
import { getBrandInitials } from '@/utils/brand';
import { MIXPANEL_VALUES } from '@/components/layout/navigation/constants';
import { trackNavigation } from '@/utils/analytics/tracking';

const identityStore = useIdentityStore();
const impersonatorStore = useImpersonatorStore();

const brandAvatarUrl = computed(() => {
  return identityStore.currentBrand?.avatarUrl;
});

const shown = ref(false);

function trackOpen(isBeingOpened) {
  if (isBeingOpened) {
    trackNavigation(MIXPANEL_VALUES.UTILITY_MENU.ROOT);
  }
}

function close(event) {
  shown.value = false;
  if (event?.tracking) {
    trackNavigation(MIXPANEL_VALUES.UTILITY_MENU.ROOT, event.tracking);
  }
}
</script>

<template>
  <DropdownBase
    v-model:shown="shown"
    placement="right-end"
    visual-bridge
    strategy="fixed"
    @update:shown="trackOpen($event)"
  >
    <div class="relative h-10 w-10">
      <Avatar
        v-dash-tooltip="{
          content: identityStore.currentBrand?.name,
          theme: 'dash-tooltip--nav',
        }"
        :image="brandAvatarUrl"
        :label="!!brandAvatarUrl ? null : getBrandInitials(identityStore.currentBrand)"
        size="lg"
        class="cy-test--utility-panel-avatar hover:dash-elevation dash-transition-md cursor-pointer"
      />
      <Avatar
        v-if="impersonatorStore.isImpersonating"
        label="DH"
        size="sm"
        class="dash-elevation-sm absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 cursor-pointer select-none"
      />
    </div>
    <template #content>
      <div class="grid h-full" :class="{ 'grid-cols-2': identityStore.canAccessMultipleBrands }">
        <UtilityNavigation @close="close($event)" />
        <BrandSwitcherV2 v-if="identityStore.canAccessMultipleBrands" @selected="close" />
      </div>
    </template>
  </DropdownBase>
</template>
