/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'leftRightArrows': {
    width: 42,
    height: 16,
    viewBox: '0 0 42 16',
    data: '<g _stroke="#adadad" stroke-width="2" _fill="none" fill-rule="evenodd"><path pid="0" d="M33 1l7 6.927L33 15M9 1L2 7.927 9 15"/></g>'
  }
})
