const applyLineClampStyles = (el, binding) => {
  if (binding.value === 1) {
    // use this approach for single line because it works with or without white spaces
    el.style.textOverflow = 'ellipsis';
    el.style.whiteSpace = 'nowrap';
  } else {
    el.style.display = '-webkit-box';
    el.style['-webkit-line-clamp'] = binding.value || 3;
    el.style['-webkit-box-orient'] = binding.arg === 'horizontal' ? 'horizontal' : 'vertical';
  }
  el.style.overflow = 'hidden';
};

export default {
  beforeMount: (el, binding) => applyLineClampStyles(el, binding),
  updated: (el, binding) => applyLineClampStyles(el, binding),
};
