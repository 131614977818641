export function getAsset(path) {
  return new URL(`./${path}`, import.meta.url).href;
}

export function getImageSource(path) {
  return getAsset(`img/${path}`);
}

export const IMG = {
  googleAnalytics: getImageSource('googleAnalytics.svg'),
  logoDhDark: getImageSource('logos/logo-dh-dark.svg'),
  google: getImageSource('logos/google.svg'),
  connectionRevokedAvatar: getImageSource('connection-revoked-avatar@2x.png'),
  igMessageEnableAccess: getImageSource('ig-message-enable-access.png'),
  twitterMessageEnableAccess: getImageSource('twitter-message-enable-access.png'),
  dashboardsPreviewCompetitiveReport: getImageSource('dashboards/preview-competitive-report.png'),
  dashboardsPreviewContentReport: getImageSource('dashboards/preview-contentreport.png'),
  dashboardsCompetitorGraphPreview: getImageSource('dashboards/competitor-graph-preview.png'),
  dashboardsGraphSingleLine: getImageSource('dashboards/graph_single-line.png'),
  dashboardsSingleBarGraphPreview: getImageSource('dashboards/single-bar-graph-preview.png'),
  dashboardsStackedBarGraphPreview: getImageSource('dashboards/stacked-bar-graph-preview.png'),
  dashboardsPreviewGraph: getImageSource('dashboards/preview-graph.png'),
  dashboardsMultipleBrandGraph: getImageSource('dashboards/multi-graph-preview.png'),
  emptyThumbup: getImageSource('empty-thumbup.png'),
  atSymbol: getImageSource('at-symbol.svg'),
  friends: getImageSource('friends.png'),
  logosWhiteDh: getImageSource('logos/white-dh.png'),
  checkMarkBlue: getImageSource('checkmark-blue.svg'),
  logosTiktokTrendingSounds: getImageSource('logos/tiktok-trending-sounds.svg'),
  soundsList: getImageSource('sounds-list.svg'),
  emptySeashore: getImageSource('empty-seashore.png'),
  chromeExtension: getImageSource('chrome-extension.png'),
  engageVisual: getImageSource('engage-visual.png'),
  importing: getImageSource('importing.png'),
  arrowLeft: getImageSource('arrow-left.png'),
  arrowRight: getImageSource('arrow-right.png'),
  iconsTwitterWhite: getAsset('icons/twitter.svg'),
  iconsFacebookWhite: getAsset('icons/facebookWhite.svg'),
  iconsTwitterDarkGrey: getAsset('icons/twitterDarkGrey.svg'),
  emptyKanpai: getImageSource('empty-kanpai.png'),
  emptyWave: getImageSource('empty-wave@2x.png'),
  emptyCattub: getImageSource('empty-cattub.png'),
  emptyBeach: getImageSource('empty-beach.png'),
  emptyField: getImageSource('empty-field@2x.png'),
  radioButtonOn: getImageSource('radioButtonOn.svg'),
  radioButtonOff: getImageSource('radioButtonOff.svg'),
  beisLogo: getImageSource('inAppTrials/beis-logo.png'),
  coterieLogo: getImageSource('inAppTrials/coterie-logo.png'),
  stellMcCartneyLogo: getImageSource('inAppTrials/stellmccartney-logo.png'),
  summerFridaysLogo: getImageSource('inAppTrials/summerfridays-logo.png'),
};
