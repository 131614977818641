<template>
  <main>
    <Icon name="megaphoneDynamic" small />
    <div class="ad-dynamic-description">
      <span class="highlight">{{ numberOfAds }}</span>
      media for this dynamic Ad
    </div>
    <a :href="mediaItem.adsManagerUrl" class="ad-manager-link highlight">See on FB Ad manager</a>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'FBAdsDynamicInfo',
  components: {
    Icon,
  },
  props: {
    mediaItem: { type: Object, default: null },
  },
  data() {
    return {};
  },
  computed: {
    numberOfAds() {
      return this.mediaItem.adsInfo.adDynamicMediaCount
        ? this.mediaItem.adsInfo.adDynamicMediaCount
        : this.mediaItem.media.length;
    },
  },
  created() {},
  methods: {},
});
export default comp;
</script>

<style lang="postcss" scoped>
main {
  width: 100%;
  padding: var(--space-16) var(--space-24);
  background-color: var(--background-0);
  border-radius: 0.5rem;
  font-size: var(--x16);
  display: inline-block;

  .icon {
    margin-right: var(--space-8);
    float: left;
  }

  .ad-manager-link {
    padding-right: 0.5rem;
    float: right;
    font-weight: var(--font-medium);
  }

  .highlight {
    color: var(--action-500);
  }

  .ad-dynamic-description {
    float: left;
  }

  a {
    font-size: var(--x14);
  }

  a:hover {
    text-decoration: underline;
  }
}
</style>
