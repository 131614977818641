<template>
  <div class="user-details" :data-cy="`UserListItem-${userId}`">
    <UserAvatar v-if="displayUserAvatar" :user="user" class="avatar" large />
    <div class="brand-user-container">
      <div data-cy="brand-user" class="content-name">
        <div v-if="shouldDisplayAsPending(user)" class="pending-text">Pending User</div>
        <div v-else class="brand-user-name">{{ formatUserName(user) }}</div>
        <Badge
          v-if="displayRoleTags && isAdmin"
          data-cy="brand-admin-badge"
          class="admin-badge"
          color="grey"
          text="Admin"
        />
      </div>
      <div v-if="jobTitle" class="content-details job-title">{{ jobTitle }}</div>
      <div class="content-details email-timezone">
        <div class="email-container">
          <Icon class="details-icon" name="emailEnvelope" xsmall />
          <TooltipOnelineText class="email" :text="user.email" />
        </div>
        <UserTimeZone v-if="displayTimeZone" :user="user" />
      </div>
    </div>
    <div class="action-panel">
      <slot name="actions" :user="user" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Badge from '@/components/Badge.vue';
import Icon from '@/components/foundation/Icon.vue';
import UserAvatar from '@/components/core/UserAvatar.vue';
import TooltipOnelineText from '@/components/TooltipOnelineText.vue';
import { getFormattedUserName } from '@/utils/user';
import UserTimeZone from '@/components/core/UserTimeZoneDisplay.vue';
/**
 * Displays a user as a list item.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'UserListItem',
  components: {
    UserTimeZone,
    TooltipOnelineText,
    Badge,
    Icon,
    UserAvatar,
  },
  props: {
    /**
     * User object to display.  Assumes properties are camalized.
     */
    user: {
      type: Object,
      default: null,
    },
    /**
     * Boolean flag to display/not display the 'Admin' role tag next to the user name.
     * Default `false` (don't display the role tags)
     */
    displayRoleTags: { type: Boolean, default: false },
    /**
     * Set to `false` to not show the user time zone in list
     */
    displayTimeZone: { type: Boolean, default: true },
    /**
     * Set to `false` to not show the user avatar in list
     */
    displayUserAvatar: { type: Boolean, default: true },
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['identity']),
    jobTitle() {
      return this.user?.jobTitle || this.user?.job_title;
    },
    timeZoneName() {
      return this.user?.timeZoneName || this.user?.time_zone_name;
    },
    invitationStatus() {
      return this.user?.invitationStatus || this.user?.invitation_status;
    },
    userId() {
      return this.user?.id || this.user?.user_id || '';
    },
    isAdmin() {
      return this.user?.role === 'admin';
    },
  },
  methods: {
    isCurrentUser() {
      return this.identity?.id === this.user?.id;
    },
    formatUserName() {
      return getFormattedUserName(this.user);
    },
    shouldDisplayAsPending() {
      return this.invitationStatus && this.invitationStatus !== 'completed';
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.user-details {
  display: flex;

  .brand-user-container {
    flex-grow: 1;

    .pending-text {
      color: var(--text-secondary);
    }

    .content-name {
      font-weight: var(--font-medium);
      color: var(--text-primary);
      font-size: var(--x16);
      line-height: var(--x20);
      margin-bottom: var(--space-4);
      display: flex;
      align-items: center;
      user-select: text;

      .admin-badge {
        margin-left: var(--space-8);
      }

      .brand-user-name {
        white-space: pre;
      }
    }

    .content-details {
      line-height: var(--x15);
      align-content: baseline;
      font-size: var(--x12);
      user-select: text;

      .details-icon {
        margin-right: var(--space-4);
        flex: none;
      }

      .email-container {
        display: inline-flex;
        max-width: 100%;

        .email {
          margin-right: var(--space-16);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .job-title {
      color: var(--text-primary);
      margin-bottom: var(--space-4);
    }

    .email-timezone {
      color: var(--text-secondary);
      display: flex;
      flex-wrap: wrap;
    }
  }

  .avatar {
    padding: 0 var(--space-16) 0 0;
    display: flex;
    align-items: center;
  }

  .action-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
