<script setup>
// Components
import Popup from '@/components/Popup.vue';

const props = defineProps({
  enableClose: {
    type: Boolean,
    default: true,
  },
});
const emits = defineEmits(['close']);

function close() {
  emits('close');
}
</script>

<template>
  <Popup
    :enable-close="props.enableClose"
    type="medium"
    :inline-styles="{ padding: '0px' }"
    :close="close"
    class="z-[var(--z-index-toolbar)]"
  >
    <div class="grid min-h-[27.5rem] grid-cols-[auto_29.5rem]">
      <div class="grid place-items-center rounded-bl-md rounded-tl-md bg-[var(--action-150)]">
        <slot name="left"></slot>
      </div>
      <slot name="right"></slot>
    </div>
  </Popup>
</template>
