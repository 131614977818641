/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'layers': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.594 12.238a1.033 1.033 0 00.812 0l9-3.867A.966.966 0 0022 7.487a.964.964 0 00-.593-.883l-9-3.867a1.033 1.033 0 00-.813 0l-9 3.867A.962.962 0 002 7.487c0 .383.232.729.594.884l9 3.867zM12 10.297l-6.537-2.81L12 4.677l6.537 2.81L12 10.297zm10-.53L11.982 14.17 2 10.147v2.094l9.615 3.875a1.05 1.05 0 00.798-.012L22 11.892V9.768zm-10.018 8.27L22 13.634v2.124l-9.587 4.213a1.052 1.052 0 01-.798.011L2 16.108v-2.094l9.982 4.022z" _fill="#686a7a"/><mask id="svgicon_layers_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="19"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.594 12.238a1.033 1.033 0 00.812 0l9-3.867A.966.966 0 0022 7.487a.964.964 0 00-.593-.883l-9-3.867a1.033 1.033 0 00-.813 0l-9 3.867A.962.962 0 002 7.487c0 .383.232.729.594.884l9 3.867zM12 10.297l-6.537-2.81L12 4.677l6.537 2.81L12 10.297zm10-.53L11.982 14.17 2 10.147v2.094l9.615 3.875a1.05 1.05 0 00.798-.012L22 11.892V9.768zm-10.018 8.27L22 13.634v2.124l-9.587 4.213a1.052 1.052 0 01-.798.011L2 16.108v-2.094l9.982 4.022z" _fill="#fff"/></mask>'
  }
})
