<template>
  <table
    class="dh-gallery-table"
    style="border-spacing: 0; margin: 0 auto; border-collapse: separate; max-width: 960px"
  >
    <tr v-for="row in rows" :key="row" style="padding: 0; margin: 0; line-height: 0">
      <td v-for="column in columns" :key="column" :style="columnStyle">
        <a v-if="customLinks" :href="getImageLink(row, column)" target="_blank" rel="noopener">
          <img :src="getImagePath(row, column)" width="100%" style="height: auto" />
        </a>
        <img v-else :src="getImagePath(row, column)" width="100%" style="height: auto" />
      </td>
    </tr>
  </table>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'EmailTemplate',
  props: {
    rows: { type: Number, default: 1 },
    columns: { type: Number, default: 1 },
    gaps: { type: Number, default: 4 },
    customLinks: { type: Boolean, default: false },
    imagePathPrefix: { type: String, default: null },
    imageLinkPrefix: { type: String, default: null },
  },
  computed: {
    columnStyle() {
      const ratioSize = 100.0 / Number(this.columns);
      return {
        padding: `${this.gaps}px`,
        width: `${ratioSize}%`,
      };
    },
  },
  methods: {
    getImagePath(row, column) {
      const number = (row - 1) * this.columns + column;
      return `${this.imagePathPrefix}/${number}.jpeg`;
    },
    getImageLink(row, column) {
      const number = (row - 1) * this.columns + column;
      return `${this.imageLinkPrefix}/${number}`;
    },
  },
});
export default comp;
</script>
