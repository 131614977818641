<template>
  <main class="media-sources">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon name="synchronize" small />
        Media Sources
        <div v-if="!searchStore.pending.mediaSourceList" class="sources-count">
          {{ sortedSources.length }}
        </div>
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <CollapsibleContainer class="media-sources-list" :open="dropdownOpen">
      <div
        v-for="source in sortedSources"
        :key="source.mediaId"
        class="row"
        @click="switchMedia(source.mediaId)"
      >
        <span :class="{ 'current-source': source.mediaId === mediaDetailStore.mediaDetail.id }">
          <Icon :color="iconColor" name="check" small class="check" />
          <img :src="source.urls.thumbs" />
          <span class="source-type">{{ formatSourceType(source.type) }}</span>
        </span>

        <dl>
          <dt>{{ formatSourceCreator(source) }}</dt>
          <dd>{{ formattedSourceCreatedAt }}</dd>
        </dl>
      </div>
    </CollapsibleContainer>
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';
import { mapStores } from 'pinia';
import enumTypes from '@/app/library/constants';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import { useSearchStore } from '@/stores/search';
import { useMediaDetailStore } from '@/stores/media-detail';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaSources',
  components: { Icon, CollapsibleContainer },
  props: { sources: { type: Array, default: null } },
  emits: ['dropdownOpen'],
  data() {
    return {
      dropdownOpen: false,
      iconColor: colours.ACTION.ACTION_500,
    };
  },
  computed: {
    ...mapStores(useSearchStore, useMediaDetailStore),
    formattedSourceCreatedAt() {
      return this.source && dayjs(this.source.sourceCreatedAt).format('LL');
    },
    sortedSources() {
      const sortedArray = cloneDeep(this.sources);
      sortedArray?.sort((a, b) => dayjs(b.sourceCreatedAt).diff(dayjs(a.sourceCreatedAt)));
      return sortedArray ?? [];
    },
  },
  methods: {
    formatSourceType(source) {
      if (source === enumTypes.INSTAGRAM_UGC) {
        return 'Instagram UGC';
      }
      if (source === enumTypes.INSTAGRAM_OTHER) {
        return 'Instagram';
      }
      if (source === enumTypes.TIKTOK_OWNED) {
        return 'TikTok Owned';
      }
      if (source === enumTypes.YOUTUBE_OWNED) {
        return 'YouTube Owned';
      }
      if (source === enumTypes.TWITTER_OTHER) {
        return 'X';
      }
      if (source === enumTypes.TWITTER_OWNED) {
        return 'X Owned';
      }
      if (source === enumTypes.YOUTUBE_OTHER) {
        return 'YouTube';
      }
      if (enumTypes.FACEBOOK_COMPETITIVE_SOURCE_LIST.includes(source)) {
        return 'Facebook';
      }
      if (source === enumTypes.TIKTOK_UGC) {
        return 'TikTok UGC';
      }
      return startCase(source).toLowerCase();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        this.$emit('dropdownOpen', 'Media Sources', 'Show Media Sources');
      }
    },
    formatSourceCreator(source) {
      switch (source.source) {
        case enumTypes.INSTAGRAM:
          if (source.sourceData.instagramUser) {
            return `@${source.sourceData.instagramUser.handle}`;
          }
          return '';
        case enumTypes.PINTEREST:
          if (source.sourceData.pinner) {
            return source.sourceData.pinner.username;
          }
          return '';

        case enumTypes.UPLOAD:
          if (source.sourceData.fullName) {
            return source.sourceData.fullName;
          }
          return '';
        default:
          return '';
      }
    },
    switchMedia(mediaId) {
      this.mediaDetailStore.getMediaDetail({ mediaId });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--background-300);
  margin: var(--space-8) auto;
  padding: var(--space-24);
  border-radius: var(--round-corner-small);

  header {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-16);
      }
    }

    .sources-count {
      height: 1rem;
      border-radius: var(--round-corner-large);
      background: var(--text-secondary);
      color: var(--white);
      margin-left: var(--space-8);
      padding: 0 var(--space-8);
      font-weight: var(--font-medium);
      font-size: var(--x12);
      display: flex;
      align-items: center;
      line-height: var(--space-16);
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .media-sources-list {
    width: 100%;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: var(--x14);
      padding: var(--space-12) 0;
      border-bottom: 1px solid var(--border);
      cursor: pointer;

      span {
        display: flex;
        align-items: center;
        font-weight: var(--font-medium);

        img {
          height: 2rem;
          width: 2rem;
          object-fit: cover;
          border-radius: var(--round-corner-small);
          margin: 0 var(--space-8) 0 var(--space-12);
        }

        .check {
          visibility: hidden;
        }
      }

      span.current-source {
        color: var(--action-500);

        .check {
          visibility: visible;
        }
      }

      span.source-type {
        text-transform: capitalize;
      }

      dl {
        text-align: right;

        dt {
          font-size: var(--x16);
        }

        dd {
          font-size: var(--x12);
          color: var(--text-secondary);
        }
      }

      &:last-child {
        border: none;
        padding-bottom: var(--space-24);
      }
    }
  }
}
</style>
