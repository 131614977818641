import xssOriginal, { whiteList, safeAttrValue, escapeHtml } from 'xss';
import merge from 'lodash/merge';

function xss(dirty, customOptions) {
  const defaultOptions = merge(
    {},
    { whiteList },
    {
      onTagAttr(tag, name, value) {
        const isClass = name === 'class';
        const isStyle = name === 'style';
        const isData = name?.startsWith('data');
        if (isClass || isStyle || isData) {
          const safeValue = safeAttrValue(tag, name, value);
          return `${name}="${safeValue}"`;
        }
        return undefined;
      },
    },
  );
  const options = customOptions || defaultOptions;
  return xssOriginal(dirty, options);
}

function xssStrip(dirty) {
  return xssOriginal(dirty, {
    whiteList: [], // empty, means filter out all tags
    stripIgnoreTag: true, // filter out all HTML not in the whilelist
    stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
  });
}

function xssEscapeHtml(html) {
  return escapeHtml(html);
}

export { xss, xssStrip, xssEscapeHtml };
