/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'resourcecenter': {
    width: 14,
    height: 13,
    viewBox: '0 0 14 13',
    data: '<defs><path pid="0" d="M14.583 2.778h-4.86a.691.691 0 00-.492.203l-.898.898-.898-.898a.694.694 0 00-.49-.203H2.082a.695.695 0 00-.694.694V12.5c0 .383.31.694.694.694h4.574l1.185 1.186a.693.693 0 00.982 0l1.186-1.186h4.573a.694.694 0 00.695-.694V3.472a.695.695 0 00-.695-.694zm-7.639 9.028H2.778v-7.64h3.879l.982.983v7.064l-.204-.203a.692.692 0 00-.49-.204zm6.945 0H9.722a.691.691 0 00-.49.203l-.204.204V5.149l.982-.982h3.879v7.639z" id="svgicon_resourcecenter_a"/></defs><use _fill="#949494" fill-rule="nonzero" xlink:href="#svgicon_resourcecenter_a" transform="translate(-1.333 -2.333)"/>'
  }
})
