import dayjs from 'dayjs';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PLATFORMS, postStatus, schedulerPostsSortTypes } from '@/app/scheduler/constants';
import { useSchedulerStore } from '@/stores/scheduler';
import { useFlagStore } from '@/stores/flag';
import { allPosts } from '@/app/scheduler/utils/post-query';

export default {
  computed: {
    ...mapStores(useSchedulerStore, useFlagStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    sortedBrandInstagramPosts() {
      return allPosts
        .forPlatform(PLATFORMS.INSTAGRAM)
        .forBrand(this.currentBrand.id)
        .sortBy(schedulerPostsSortTypes.TIMESTAMP_ASC);
    },
    maxDailyInstagramAutoPublishPosts() {
      return this.flagStore.ready && this.flagStore.flags.igIncreasedRateLimit ? 50 : 25;
    },
  },
  methods: {
    async validateMaxAutoPublishPosts() {
      this.disableAutoPublishDueToAPILimit = await this.validateMaxAutoPublishPostsAtScheduledTime(
        this.post,
        this.scheduledTime ? this.scheduledTime?.toDate() : null,
      );
      this.disablePublishNowDueToAPILimit = await this.validateMaxAutoPublishPostsAtNow();
    },
    async validateMaxAutoPublishPostsAtScheduledTime(post, newTime) {
      const isNewPost = !post?.id;
      let limitReached = false;
      if (newTime) {
        const query = this.sortedBrandInstagramPosts.betweenDates(
          dayjs(newTime).subtract(1, 'day').toDate(),
          dayjs(newTime).add(1, 'day').toDate(),
        );
        await this.schedulerStore.fetchPosts(query);
        const posts = this.schedulerStore.getPosts(query).filter((p) => p.autoPublish);

        while (posts.length >= this.maxDailyInstagramAutoPublishPosts) {
          // Check each 24 hour window from each post going back 24 hours,
          // to check that no window will go over the limit
          const window = dayjs(posts.shift().timestamp).add(1, 'day').toDate();
          const chunk = posts.filter((p) => p.timestamp <= window);
          if (chunk.length + 1 >= this.maxDailyInstagramAutoPublishPosts) {
            limitReached = true;
            break;
          }
        }
      }
      return limitReached && (isNewPost || !this.autoPublish);
    },
    async validateMaxAutoPublishPostsAtNow() {
      const now = new Date();
      const query = this.sortedBrandInstagramPosts
        .withStatuses([
          postStatus.AUTOPUBLISHING,
          postStatus.USERS_NOTIFIED,
          postStatus.POSTED,
          postStatus.IMPORTED,
          postStatus.EXPIRED,
        ])
        .betweenDates(dayjs().subtract(1, 'day').toDate(), now);
      await this.schedulerStore.fetchPosts(query);
      const posts = this.schedulerStore.getPosts(query).filter((p) => p.autoPublish);
      return posts.length >= this.maxDailyInstagramAutoPublishPosts;
    },
  },
};
