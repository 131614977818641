import { defineStore } from 'pinia';
import { LibraryAPI } from '@/apis';
import { logger } from '@/utils/logger';
import { encodeUtmErrorMessage } from '@/app/settings/components/Utm/const';
import { fetchUtmSettings as fetchUtmSettingsUtil } from '@/app/settings/components/Utm/utils';
import { useNotificationStore } from '@/stores/notification';
import { trackSchedulerUtmPopout } from '@/app/scheduler/mixpanel';
import { useGalleryStore } from './gallery';
import { useAuthStore } from './auth';

export const useMediaLinksStore = defineStore('mediaLinks', {
  resetOnBrandChange: true,
  state: () => ({
    pending: {
      mediaLinks: false,
    },
    mediaItem: null,
    mediaLinks: [],
    linkLimit: null,
    showLinkPopup: null,
    linkUpdateStatus: null,
    linkUpdatingMediaId: null,
    linkUpdateSource: null,
    utmChannel: null,
    utmTrackingData: null,
    utmSettings: {},
    utmEditorPanelIndex: null,
    showUtmEditorPanel: false,
    isUgcMedia: null,
    isCompetitiveMedia: null,
  }),
  getters: {},
  actions: {
    setLinkLimit(linkLimit) {
      this.linkLimit = linkLimit;
    },
    openLinkPopup(mediaItem) {
      this.mediaItem = mediaItem;
      this.showLinkPopup = true;
    },
    closeLinkPopup() {
      this.showLinkPopup = false;
      this.linkLimit = undefined;
      this.mediaItem = null;
    },
    clearMediaLinks() {
      this.mediaLinks = [];
    },
    setLinkUpdateSource(source) {
      this.linkUpdateSource = source;
    },
    openUtmEditorPanel(index) {
      this.utmEditorPanelIndex = index;
      if (!this.showUtmEditorPanel) {
        this.showUtmEditorPanel = true;
        try {
          trackSchedulerUtmPopout({
            ...this.utmTrackingData,
            action: 'open',
          });
        } catch (error) {
          logger.error(error.message, {}, error);
        }
      }
    },
    closeUtmEditorPanel() {
      if (this.showUtmEditorPanel) {
        this.utmEditorPanelIndex = null;
        this.showUtmEditorPanel = false;
        try {
          trackSchedulerUtmPopout({
            ...this.utmTrackingData,
            action: 'close',
          });
        } catch (error) {
          logger.error(error.message, {}, error);
        }
      }
    },
    async encodeUtm({ mediaId, brandId, mediaLink }) {
      const response = await LibraryAPI.encodeUtmSettings({
        brandId,
        mediaId,
        channel: this.utmChannel,
        content: mediaLink.url,
        productId: mediaLink?.product?.id,
        shortenUrl: false,
        urls: [mediaLink.url],
      });
      mediaLink.url = response.data.content;
    },
    async encodeUtms({ mediaId, brandId, data }) {
      const promises = data.map((mediaLink) => {
        return this.encodeUtm({ mediaId, brandId, mediaLink });
      });
      try {
        await Promise.all(promises);
      } catch (error) {
        const notificationStore = useNotificationStore();
        logger.error(`Error while encoding url: ${error}`);
        notificationStore.setToast({
          message: encodeUtmErrorMessage,
          type: 'error',
        });
      }
    },
    async updateMediaLinks({ mediaId, brandId, data }) {
      this.linkUpdatingMediaId = mediaId;
      this.linkUpdateStatus = 'pending';
      this.pending.mediaLinks = true;

      try {
        // channel can be not set for some channels in library popup
        if (!this.isUgcMedia && !this.isCompetitiveMedia && this.utmChannel) {
          const modifiedLinks = data.filter((link) => link.urlLinkCustomized);
          await this.encodeUtms({ mediaId, brandId, data: modifiedLinks });
        }
        const res = await LibraryAPI.updateMediaLinks({ mediaId, brandId, data });
        this.linkUpdatingMediaId = null;
        this.mediaLinks = res.data;
        this.linkUpdateStatus = 'success';
        this.pending.mediaLinks = false;
        const galleryStore = useGalleryStore();
        // TODO Remove this when converting gallery store to pinia in sc-76573
        galleryStore.updateGalleryMediaLink({ mediaId, links: res.data });
      } catch (e) {
        logger.error(`Failed to update media links for brand ${brandId}, media ${mediaId}`, {}, e);
        this.linkUpdatingMediaId = null;
        this.linkUpdateStatus = 'error';
        this.pending.mediaLinks = false;
      }
    },
    async listMediaLinks({ brandId, mediaId }) {
      this.pending.mediaLinks = true;
      try {
        const res = await LibraryAPI.getListMediaLinks({ brandId, mediaId });
        this.mediaLinks = res.data;
      } catch (e) {
        this.mediaLinks = [];
      }
      this.pending.mediaLinks = false;
    },
    async fetchUtmSettings() {
      const authStore = useAuthStore();
      this.utmSettings = await fetchUtmSettingsUtil({
        brandId: authStore?.currentBrand?.id,
        channel: this.utmChannel,
      });
    },
  },
});
