<template>
  <div ref="container" class="media-cropper">
    <div :style="{ visibility: `${isVisible}` }" class="cropping-container">
      <div v-if="!cropperReady" class="loader-wrapper">
        <CircularLoader />
      </div>
      <img
        ref="cropImage"
        :src="selectedMediaUrl"
        :style="{ maxWidth: '100%', width: '100%', maxHeight: '100%', height: '100%' }"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Cropper from 'cropperjs';
import CircularLoader from '@/components/CircularLoader.vue';
import { getImageDataFromMedia } from '@/utils/media';
import { useCropperStore } from '@/stores/cropper';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaCropper',
  components: { CircularLoader },
  props: {
    aspectRatio: { type: Number, default: null },
    media: { type: [Object, String], required: true },
    onCropChange: { type: Function, required: true },
    originalMedia: { type: Object, default: null },
    zoomValue: { type: Number, default: 0 },
  },
  data() {
    return {
      cropper: null,
      cropperReady: false,
    };
  },
  computed: {
    ...mapStores(useCropperStore),
    isVisible() {
      return this.cropperReady ? 'visible' : 'hidden';
    },
    selectedMediaUrl() {
      if (typeof this.media === 'string') {
        return this.media;
      }
      // If a media item has been cropped before, it will pass through an "original media" prop
      // to reload the original media item into the cropper
      const mediaToCrop = this.originalMedia || this.media;
      const mediaObject = getImageDataFromMedia(mediaToCrop);
      return mediaObject.url;
    },
  },
  watch: {
    'cropperStore.cropperRatio': {
      handler(newRatio, oldRatio) {
        if (this.cropper) {
          if (
            newRatio !== null &&
            (oldRatio === null || newRatio.ratioType !== oldRatio.ratioType)
          ) {
            if (newRatio.ratio) {
              const res = newRatio.ratio.split(':');
              const widthRatio = Number(res[0]);
              const heightRatio = Number(res[1]);
              this.cropper.setAspectRatio(widthRatio / heightRatio);
            } else {
              this.cropper.setAspectRatio(null);
            }
            this.onCropMove();
          }
        }
      },
    },
    zoomValue(to) {
      if (this.cropper) {
        const cropBoxData = this.cropper.getCropBoxData();
        this.cropper.zoomTo(to, {
          x: cropBoxData.left + cropBoxData.width / 2,
          y: cropBoxData.top + cropBoxData.height / 2,
        });
        this.onCropMove();
      }
    },
  },
  mounted() {
    this.cropper = new Cropper(this.$refs.cropImage, {
      aspectRatio: this.aspectRatio,
      autoCropArea: 1,
      checkOrientation: false,
      cropend: this.onCropMove,
      ready: this.setCropData,
      restore: false,
      toggleDragModeOnDblclick: false,
      viewMode: 2,
      zoomOnWheel: false,
      dragMode: 'move',
    });

    if (this.cropperStore.cropperRatio && this.cropperStore.cropperRatio.ratio) {
      const res = this.cropperStore.cropperRatio.ratio.split(':');
      const widthRatio = Number(res[0]);
      const heightRatio = Number(res[1]);
      this.cropper.setAspectRatio(widthRatio / heightRatio);
    }
    window.addEventListener('resize', this.resetCropper);
  },
  methods: {
    resetCropper() {
      if (this.cropper) {
        const canvasData = this.cropper.getCanvasData();
        this.cropper.zoomTo(0, { x: 0, y: 0, scaleX: 1, scaleY: 1 });
        this.cropper.setCanvasData({
          left: 0,
          top: 0,
          width: canvasData.width,
          height: canvasData.height,
        });
        this.cropper.scale(1, 1);
        this.onCropMove();
      }
    },
    onCropMove() {
      if (this.cropper.getCroppedCanvas()) {
        const originalCanvasData = this.cropper.getCanvasData();
        const canvasData = {};
        Object.keys(originalCanvasData).forEach((key) => {
          canvasData[key] = Math.round(originalCanvasData[key] * 100) / 100;
        });
        const orginalCropData = this.cropper.getData();
        const cropData = {};
        // Height and width need to be whole numbers to keep them consistent with the values
        // stored in our backend media metadata
        Object.keys(orginalCropData).forEach((key) => {
          if (key === 'height' || key === 'width') {
            cropData[key] = Math.round(orginalCropData[key]);
          } else {
            cropData[key] = Math.round(orginalCropData[key] * 100) / 100;
          }
        });
        const data = {
          canvas: this.cropper.getCroppedCanvas({
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'high',
          }),
          cropData,
          canvasData,
        };
        this.onCropChange(data);
      }
    },
    setCropData() {
      if (this.originalMedia) {
        const { editorData, transforms } = this.media;
        if (editorData && editorData.canvasData) {
          this.cropper.setCanvasData(editorData.canvasData);
          this.cropper.setData(transforms.crop);
        }
      }
      this.onCropMove();
      this.cropperReady = true;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.media-cropper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .loader-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cropping-container {
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  :deep(.cropper-bg) {
    background-image: none;
  }

  :deep(.cropper-modal) {
    background-color: var(--background-0);
  }
}

:deep(.validation-error) {
  justify-content: center;
  align-items: center;
}
</style>
