/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'closeCircle': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<g _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M10.042 20c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm0-18c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"/><path pid="1" d="M14.749 6.707l-1.415-1.414-3.292 3.293-3.293-3.293-1.415 1.414L8.627 10l-3.293 3.293 1.415 1.414 3.293-3.292 3.292 3.292 1.415-1.414L11.456 10z"/></g>'
  }
})
