/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'businessGraphBar': {
    width: 24,
    height: 16,
    viewBox: '0 0 24 16',
    data: '<path pid="0" d="M22 14V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v13h-1V6.999a1 1 0 00-1-1h-4a1 1 0 00-1 1V14H8V4.999a1 1 0 00-1-1H3a1 1 0 00-1 1V14H0v2h24v-2h-2zM18 2h2v11.999h-2V2zm-7 5.999h2v6h-2v-6zm-7-2h2v8H4v-8z" _fill="#DDD" fill-rule="nonzero"/>'
  }
})
