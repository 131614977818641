import { computed, ref } from 'vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import { LISTENING_SEARCH_LIMIT, THRESHOLD_MINIMUMS } from '@/components/VisionAi/constants';
import { useIdentityStore } from '@/stores/identity';
import {
  getKeywordORString,
  getBackendFilterPayload,
  getStartAndEndDate,
} from '@/app/socialListening/utils/api-request-utils';
import {
  POST_SORT_OPTIONS,
  SOCIAL_LISTENING_DATE_OPTIONS,
  SOCIAL_LISTENING_LOADED_SOURCE,
  SOCIAL_LISTENING_ROUTE_NAMES,
} from '@/app/socialListening/constants';
import { formatTopicQueryParamsVisualDataForSourceLink } from '@/app/socialListening/utils/formatters';
import { useRoute } from 'vue-router';

export function useListeningVisionAi() {
  const socialListeningStore = useSocialListeningStore();
  const identityStore = useIdentityStore();
  const route = useRoute();

  const paging = ref({ limit: LISTENING_SEARCH_LIMIT, offset: 0 });
  const sorts = ref([
    `-${POST_SORT_OPTIONS.engagements.value}`,
    `-${POST_SORT_OPTIONS.date.value}`,
  ]);

  const topicIntentQuery = computed(() => {
    const filters = socialListeningStore.filters || {};
    const {
      industryIds,
      keywordsAndHashtags,
      sourceAndCreatorIds,
      sourceCreated,
      mediaTypes,
      sentiment,
      sources,
      visualData,
    } = filters;
    const additionalFilters = {};
    if (socialListeningStore.filters?.websearch?.enabled) {
      additionalFilters.websearch = { enabled: true };
    }

    const organizationId = identityStore.currentBrand?.organizationId;

    return {
      brandId: identityStore.currentBrand.id,
      searchParams: {
        organizationId,
        topicId: socialListeningStore.selectedTopic?.id,
        filters: {
          industryIds,
          keywordsAndHashtags,
          sourceAndCreatorIds,
          sourceCreated,
          mediaTypes,
          sentiment,
          sources,
          visualData,
          ...additionalFilters,
        },
        paging: paging.value,
        sorts: sorts.value,
      },
      sourceLinkParams: {
        topicId: socialListeningStore.selectedTopic?.id,
        source: SOCIAL_LISTENING_LOADED_SOURCE.VISION_AI,
        organizationId,
        brandLabel: identityStore.currentBrand?.label,
        mediaTypes: mediaTypes?.includes,
        includeNegative: sentiment?.includeNegative,
        includeNeutral: sentiment?.includeNeutral,
        includePositive: sentiment?.includePositive,
        onOrBefore: sourceCreated?.onOrBefore,
        onOrAfter: sourceCreated?.onOrAfter,
        includeVisualDataSourceAndId: visualData?.includes,
        excludeVisualDataSourceAndId: visualData?.doesNotInclude,
        sources,
      },
      promptInfo: {
        topicName: socialListeningStore.selectedTopic?.name,
      },
    };
  });

  const trendsIntentQuery = computed(() => {
    const topKeywordList = socialListeningStore.topKeywords?.map((entry) => entry.keyword) || [];
    const listeningFilters = socialListeningStore.listeningFilters || {};
    const { mediaTypes, channels, sentiments, dateRange, customDateRange, keyword, keywordTypes } =
      listeningFilters;
    let onOrAfter;
    let onOrBefore;
    if (customDateRange) {
      onOrAfter = customDateRange.onOrAfter;
      onOrBefore = customDateRange.onOrBefore;
    } else {
      const { startDate, endDate } = getStartAndEndDate(dateRange);
      onOrAfter = startDate;
      onOrBefore = endDate;
    }

    const keywordList = keyword ? [keyword] : topKeywordList;

    const keywordsStringFormatted = getKeywordORString(
      socialListeningStore.listeningFilters,
      socialListeningStore.topKeywords,
    );

    const organizationId = identityStore.currentBrand?.organizationId;
    return {
      brandId: identityStore.currentBrand.id,
      searchParams: {
        organizationId,
        filters: getBackendFilterPayload(
          dateRange,
          sentiments,
          channels,
          mediaTypes,
          keywordsStringFormatted,
          SOCIAL_LISTENING_DATE_OPTIONS,
        ),
        sorts: sorts.value,
        paging: paging.value,
      },
      sourceLinkParams: {
        onOrAfter,
        onOrBefore,
        mediaTypes,
        sentiments,
        channels,
        brandLabel: identityStore.currentBrand?.label,
        organizationId,
        keyword,
        keywordTypes,
      },
      promptInfo: {
        topKeywords: keywordList,
      },
    };
  });

  const hasValidSocialListeningTrendsThreshold = computed(() => {
    return (
      socialListeningStore.topPostsPreview.length >= THRESHOLD_MINIMUMS.LISTENING.TRENDS_TOP_POSTS
    );
  });

  const hasValidSocialListeningTopicsThreshold = computed(() => {
    return (
      socialListeningStore.topPostsPreview.length >= THRESHOLD_MINIMUMS.LISTENING.TOPICS_TOP_POSTS
    );
  });

  const onListeningOverviewRoute = computed(() => {
    return route?.name === SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW;
  });

  const isListeningEmptyState = computed(() => {
    return Boolean(onListeningOverviewRoute.value);
  });

  function getRouteBrandLabel(defaultBrandLabel, organizationId) {
    // A user can belong to brands that span multiple organizations.
    // We must see if the vision message was produced by a brand in the same org as the current
    // brand. If not, we need to route to the producing brand. If yes, route to SL normally
    const intentOrganizationBrands = Object.values(identityStore.identity.brands).filter(
      (brand) => {
        return brand.organizationId === organizationId;
      },
    );

    const currentBrandInIntentOrg = !!intentOrganizationBrands.find(
      (brand) => brand.id === identityStore.currentBrand.id,
    );

    const routeBrandLabel = currentBrandInIntentOrg
      ? identityStore.currentBrand.label
      : defaultBrandLabel;
    return routeBrandLabel;
  }

  function getTopicUrlFromIntentQuery(intentQuery) {
    const { topicId, brandLabel, organizationId, ...remainingQueryParams } =
      intentQuery?.sourceLinkParams || {};
    const formattedVisualDataQueryParams =
      formatTopicQueryParamsVisualDataForSourceLink(remainingQueryParams);

    const routeBrandLabel = getRouteBrandLabel(brandLabel, organizationId);

    return {
      name: `${SOCIAL_LISTENING_ROUTE_NAMES.TOPICS}`,
      params: {
        id: topicId,
        brandLabel: routeBrandLabel,
      },
      query: {
        ...remainingQueryParams,
        includeVisualDataSourceAndId: formattedVisualDataQueryParams.includeVisualDataSourceAndId,
        excludeVisualDataSourceAndId: formattedVisualDataQueryParams.excludeVisualDataSourceAndId,
        source: SOCIAL_LISTENING_LOADED_SOURCE.VISION_AI,
      },
    };
  }

  function getTrendsUrlFromIntentQuery(intentQuery) {
    const { brandLabel, organizationId, ...remainingQueryParams } =
      intentQuery?.sourceLinkParams || {};

    const routeBrandLabel = getRouteBrandLabel(brandLabel, organizationId);

    return {
      name: `${SOCIAL_LISTENING_ROUTE_NAMES.TRENDS}`,
      params: {
        brandLabel: routeBrandLabel,
        organizationId,
      },
      query: {
        ...remainingQueryParams,
        source: SOCIAL_LISTENING_LOADED_SOURCE.VISION_AI,
      },
    };
  }

  return {
    getRouteBrandLabel,
    topicIntentQuery,
    trendsIntentQuery,
    hasValidSocialListeningTrendsThreshold,
    hasValidSocialListeningTopicsThreshold,
    getTopicUrlFromIntentQuery,
    getTrendsUrlFromIntentQuery,
    isListeningEmptyState,
  };
}
