import humps from 'humps';
import constants from '@/app/dashboards/constants';

export function filterAndSortUsers(users) {
  return (
    users
      ?.filter?.((user) => user.status === constants.USER_STATUS.ACTIVE)
      ?.sort?.((left, right) => {
        const leftUser = humps.camelizeKeys(left);
        const rightUser = humps.camelizeKeys(right);
        let compareOwner = 0;
        if (leftUser?.userRole === constants.ROLES.OWNER.value) {
          compareOwner = -1;
        } else if (rightUser?.userRole === constants.ROLES.OWNER.value) {
          compareOwner = 1;
        }
        const compareFirstName = leftUser?.firstName?.localeCompare(rightUser?.firstName);
        const compareLastName = leftUser?.lastName?.localeCompare(rightUser?.lastName);
        return compareOwner || compareFirstName || compareLastName;
      }) || []
  );
}
