<template>
  <div :class="[{ small: $props.small }, { medium: $props.medium }]" class="tab-container">
    <span
      v-for="(tab, index) in localTabs"
      v-show="isTabVisible(tab, index)"
      :key="index"
      v-tooltip="disabledTooltip(tab)"
      :class="[
        'tab-label',
        { 'align-left': alignLeft, baseline: baseline, disabled: tab.disabled },
      ]"
      :data-cy="tab.name"
      @click.stop="setTabByIndex(index)"
    >
      <div :class="['tab-content', { act: currentTabIndex === index }]">
        {{ format(tab.label || tab.name) }}
        <span v-if="tab.count" class="tab-count">({{ tab.count }})</span>
      </div>
      <Icon v-if="tab.tooltip" v-tooltip="tab.tooltip" name="info" xsmall />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { logger } from '@/utils/logger';
import Icon from '@/components/foundation/Icon.vue';
import { formatTabName } from '@/utils/formatters';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Tabs',
  components: {
    Icon,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
      validator(value) {
        return value.every((tab) => {
          if (typeof tab === 'string') {
            return true;
          }
          if (!tab.name) {
            logger.warn('When using object syntax, a tab name must be provided.');
            return false;
          }
          return true;
        });
      },
    },
    value: { type: [Object, String], default: null },
    small: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    alignLeft: { type: Boolean, default: false },
    noMedia: { type: Boolean, default: false },
    baseline: { type: Boolean, default: false },
    initialTabIndex: { type: Number, default: 0 },
  },
  emits: ['onChange', 'input'],
  data() {
    return {
      currentTabIndex: this.initialTabIndex,
    };
  },
  computed: {
    localTabs() {
      return this.tabs.map((tab) => {
        if (typeof tab === 'string') {
          return { name: tab };
        }
        return tab;
      });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(to) {
        this.setTabByValue(to);
      },
    },
  },
  created() {
    if (this.value) {
      this.setTabByValue(this.value);
    }
  },
  methods: {
    isTabVisible(tab, index) {
      if (index === 0) {
        return true;
      }

      if (!(tab.visible ?? true)) {
        return false;
      }
      return tab.hasMedia ?? !this.noMedia;
    },
    setTabByIndex(index) {
      if (this.currentTabIndex !== index && !this.tabs[index].disabled) {
        const previousValue = this.tabs[this.currentTabIndex];
        this.currentTabIndex = index;
        // TODO: deprecate onChange flow and use v-model instead
        // Ticket: https://app.clubhouse.io/dashhudson/story/26626/
        this.$emit('onChange', index);
        this.$emit('input', this.tabs[index], previousValue);
      }
    },
    setTabByValue(value) {
      let tabIndex;
      if (typeof value === 'string') {
        tabIndex = this.tabs.findIndex(
          (tab) =>
            tab?.toLowerCase() === value?.toLowerCase() && value?.toLowerCase() !== undefined,
        );
      } else {
        tabIndex = this.tabs.findIndex((tab) => tab?.name === value?.name);
      }
      this.currentTabIndex = tabIndex;
    },
    format(name) {
      return formatTabName(name);
    },
    disabledTooltip(tab) {
      return (tab.disabled && tab.disabledTooltip) || null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.tab-container {
  color: var(--text-secondary);
  display: flex;
  flex-direction: row;

  .tab-label {
    font-size: var(--x18);
    font-weight: var(--font-normal);
    margin: 0 var(--space-16) 0 var(--space-16);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: baseline;

    &.align-left {
      margin-left: 0;
      margin-right: var(--space-32);
    }

    .tab-content {
      display: flex;
      align-items: baseline;
      padding-bottom: var(--space-6);
      border-bottom: 2px solid transparent;

      &.baseline {
        position: relative;
        top: var(--space-6);
      }

      &.act {
        color: var(--brand-accent);
        border-bottom: 2px solid var(--brand-accent);
        font-weight: var(--font-medium);
        cursor: default;
      }

      .tab-count {
        color: var(--text-secondary);
        font-weight: var(--font-normal);
        padding-left: var(--space-4);
      }
    }
  }

  .icon {
    cursor: default;
    outline: 0;
    transform: translateY(0.1rem);
    margin-left: var(--space-4);
  }
}

.small {
  .act {
    border-bottom: 2px solid var(--brand-accent);
  }

  .tab-label {
    font-size: var(--x14);
  }
}

.medium {
  .act {
    border-bottom: 2px solid var(--brand-accent);
  }

  .tab-label {
    font-size: var(--x16);
  }
}

.tab-container .tab-label:not(.disabled):hover {
  color: var(--brand-accent);
}

span.disabled div {
  cursor: default;
}
</style>
