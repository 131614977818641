/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reach': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.01 21h-12v-2c0-2.852 2.58-5 6.001-5 3.42 0 5.999 2.148 5.999 5v2zm-10-2h8c0-1.738-1.682-3-3.999-3-2.319 0-4.001 1.262-4.001 3zm.467-6.32c-.646.264-1.239.6-1.767.999-1.796-2.535-1.566-6.078.704-8.348a6.455 6.455 0 014.598-1.905c1.737 0 3.369.675 4.597 1.904 2.269 2.27 2.499 5.813.704 8.348a7.983 7.983 0 00-1.768-.999c1.373-1.765 1.269-4.314-.351-5.935a4.47 4.47 0 00-3.183-1.318 4.462 4.462 0 00-3.182 1.318c-1.622 1.622-1.725 4.17-.352 5.936zM3.58 14.745a6.612 6.612 0 00-1.1 1.818c-3.422-3.922-3.277-9.898.458-13.635C6.837-.97 13.182-.97 17.08 2.928c3.736 3.737 3.882 9.713.458 13.637a6.583 6.583 0 00-1.1-1.82c2.311-3.129 2.06-7.569-.772-10.402-3.12-3.118-8.194-3.118-11.313 0-2.832 2.832-3.084 7.273-.773 10.402zM10.01 13c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1z" id="svgicon_reach_a"/></defs><g transform="translate(2 2)" _fill="none" fill-rule="evenodd"><mask id="svgicon_reach_b" _fill="#fff"><use xlink:href="#svgicon_reach_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_reach_a"/><g mask="url(#svgicon_reach_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M-2-2h24v24H-2z"/></g></g>'
  }
})
