import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { formatGalleryMediaListData } from '@/utils';
import * as LibraryAPI from '@/apis/library';

export const usePopupGalleryStore = defineStore(
  'popupGallery',
  () => {
    const pending = ref({
      getGalleryMedia: false,
      galleries: false,
    });

    const galleries = ref({});
    const galleryMediaList = ref([]);
    const galleryMediaListFullyLoaded = ref(false);
    const galleryMediaListNext = ref(null);
    const gallerySearchTerm = ref(null);
    const uniqueImageGroupIds = ref([]);

    const formattedGalleryMediaList = computed(() =>
      formatGalleryMediaListData(galleryMediaList.value),
    );

    function clearGalleryMedia() {
      galleryMediaList.value = [];
      galleryMediaListNext.value = null;
      galleryMediaListFullyLoaded.value = false;
      uniqueImageGroupIds.value = [];
    }

    function clearGalleries() {
      galleries.value = {};
    }

    function setGallerySearchTerm(searchTerm) {
      gallerySearchTerm.value = searchTerm;
    }

    function clearGallerySearchTerm() {
      gallerySearchTerm.value = null;
    }

    async function getGalleries({ type, brandId, sort, tagsFilterType, search, tags }) {
      pending.value.galleries = true;
      try {
        const response = await LibraryAPI.getGalleries({
          type,
          brandId,
          sort,
          tagsFilterType,
          search,
          tags,
        });
        galleries.value = response.data;
      } finally {
        pending.value.galleries = false;
      }
    }

    async function getGalleryMedia({ brandId, galleryId, url, limit, sort }) {
      pending.value.getGalleryMedia = true;
      try {
        const response = await LibraryAPI.getGalleryMedia({ brandId, galleryId, url, limit, sort });

        const uniqueMediaList = response.data.data
          .map((item) => {
            if (uniqueImageGroupIds.value.indexOf(item.media.id) < 0) {
              uniqueImageGroupIds.value.push(item.media.id);
              return item;
            }
            return null;
          })
          .filter((item) => item !== null);
        galleryMediaList.value = [...galleryMediaList.value, ...uniqueMediaList];
        galleryMediaListNext.value = response.data.paging.next;
        if (response.data.paging.next === null) {
          galleryMediaListFullyLoaded.value = true;
        }
      } finally {
        pending.value.getGalleryMedia = false;
      }
    }

    return {
      pending,
      galleries,
      galleryMediaList,
      galleryMediaListFullyLoaded,
      galleryMediaListNext,
      gallerySearchTerm,
      uniqueImageGroupIds,
      formattedGalleryMediaList,
      clearGalleryMedia,
      clearGalleries,
      setGallerySearchTerm,
      clearGallerySearchTerm,
      getGalleries,
      getGalleryMedia,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
