import { mapStores } from 'pinia';
import { useFlagStore } from '@/stores/flag';

export default (requiredFeatureFlag, to, invertFlag) => ({
  data() {
    return {
      ldRedirectReadyWatcher: null,
      ldRedirectFlagWatcher: null,
      ldRedirectHasBeenDeactivated: false,
    };
  },
  computed: {
    ...mapStores(useFlagStore),
    ldRedirectFlagValue() {
      return this.invertFlag || invertFlag
        ? !this.flagStore.flags[requiredFeatureFlag || this.requiredFeatureFlag]
        : this.flagStore.flags[requiredFeatureFlag || this.requiredFeatureFlag];
    },
    ldRedirectShouldRedirect() {
      return this.flagStore.userFlagsReady && !this.ldRedirectFlagValue;
    },
    ldRedirectShouldDestroy() {
      return this.flagStore.userFlagsReady && this.ldRedirectFlagValue;
    },
    ldRedirectResolveTo() {
      // handles 'to' redirect values passed as functions
      const redirectVal = to == null ? this.ldRedirectTo : to;
      if (typeof redirectVal === 'function') {
        const boundRedirectTo = redirectVal.bind(this);
        return boundRedirectTo();
      }
      return redirectVal;
    },
  },
  watch: {
    'flagStore.userFlagsReady': {
      handler(value) {
        if (value) {
          if (this.ldRedirectShouldRedirect) {
            this.$router.push(this.ldRedirectResolveTo);
          }
        }
      },
    },
  },
  methods: {
    setLdRedirectReadyWatcher() {
      this.ldRedirectReadyWatcher = this.$watch(
        () => this.flagStore.userFlagsReady,
        () => {
          if (this.ldRedirectShouldRedirect) {
            this.$router.push(this.ldRedirectResolveTo);
          } else if (this.ldRedirectShouldDestroy) {
            this.ldRedirectDestroyWatchers();
          }
        },
      );
    },
    setLdRedirectFlagWatcher() {
      this.ldRedirectFlagWatcher = this.$watch(
        () => this.ldRedirectFlagValue,
        () => {
          if (this.ldRedirectShouldRedirect) {
            this.$router.push(this.ldRedirectResolveTo);
          } else if (this.ldRedirectShouldDestroy) {
            this.ldRedirectDestroyWatchers();
          }
        },
      );
    },
    ldRedirectDestroyWatchers() {
      if (this.ldRedirectReadyWatcher) {
        this.ldRedirectReadyWatcher();
        this.ldRedirectReadyWatcher = null;
      }
      if (this.ldRedirectFlagWatcher) {
        this.ldRedirectFlagWatcher();
        this.ldRedirectFlagWatcher = null;
      }
    },
    ldRedirectHandler() {
      if (this.flagStore.userFlagsReady && !this.ldRedirectFlagValue) {
        this.$router.push(this.ldRedirectResolveTo);
      } else if (!this.flagStore.userFlagsReady) {
        this.setLdRedirectReadyWatcher();
        this.setLdRedirectFlagWatcher();
      }
    },
  },
  activated() {
    // activated lifecycle trigger used for keep-alive components
    if (this.ldRedirectHasBeenDeactivated) {
      this.ldRedirectHandler();
    }
  },
  mounted() {
    this.ldRedirectHandler();
  },
  deactivated() {
    this.ldRedirectHasBeenDeactivated = true;
  },
});
