/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'store': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.937 10.649l-3-8A1.002 1.002 0 0019 2H5c-.417 0-.79.259-.937.649l-3 8c-.114.307-.072.651.114.92.188.27.495.431.823.431h1v9a1 1 0 001 1h16a1 1 0 001-1v-9h1a1 1 0 00.937-1.351zM20.557 10h-2.775l-1.5-6h2.025l2.25 6zM9.781 4l-1.5 6H11V4H9.781zM13 4h1.219l1.5 6H13V4zM7.718 4H5.693l-2.25 6h2.775l1.5-6zM14 20v-5h2v5h-2zm4 0h1v-8H5v8h7v-6a1 1 0 011-1h4a1 1 0 011 1v6z" _fill="#686a7a"/><path pid="1" d="M7 18h3a1 1 0 001-1v-3a1 1 0 00-1-1H7a1 1 0 00-1 1v3a1 1 0 001 1z" _fill="#686a7a"/>'
  }
})
