<template>
  <span id="textMatchDisplay" data-cy="TextMatchDisplay">
    <!-- eslint-disable-next-line prettier/prettier -->
    {{ before }}<span v-if="match" class="search-match">{{ match }}</span>{{ after }}
  </span>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
  },
  computed: {
    indexOfMatch() {
      const text = this.text?.toLowerCase();
      return this.search ? text.indexOf(this.search.toLowerCase()) : -1;
    },
    hasMatch() {
      return this.indexOfMatch > -1;
    },
    before() {
      return this.hasMatch ? this.text.substring(0, this.indexOfMatch) : this.text;
    },
    match() {
      return this.hasMatch
        ? this.text.substring(this.indexOfMatch, this.indexOfMatch + this.search.length)
        : '';
    },
    after() {
      return this.hasMatch ? this.text.substring(this.indexOfMatch + this.search.length) : '';
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.search-match {
  background: var(--background-300);
}
</style>
