/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'smiley-smile-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#686a7a" d="M12 2C6.477 2 2 6.477 2 12c0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM8 6a2 2 0 11-.001 4.001A2 2 0 018 6zm10 8c0 2.617-2.86 5-6 5s-6-2.383-6-5v-1h12v1zm-2-4a2 2 0 110-4 2 2 0 010 4z"/>'
  }
})
