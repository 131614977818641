import html2canvas from 'html2canvas';
import { IMAGE_DOWNLOAD_FILE_FORMATS } from '@/app/dashboards/constants';
import { env } from '@/env';

export function downloadImage(element, format) {
  html2canvas(element, {
    proxy: `${env.dashboardApiUrl}export_proxy`,
    onclone: (clonedDoc) => {
      clonedDoc.querySelectorAll('.image-download, .see-more-button').forEach((item) => {
        item.style.display = 'none';
      });

      clonedDoc.querySelectorAll('.avatar-outer').forEach((item) => {
        item.style.padding = '0.3125rem';
        item.style.borderRadius = '100%';
        item.style.border = '1px solid #D2D1D1';
      });

      clonedDoc.querySelectorAll('.media').forEach((mediaDiv) => {
        // This is done to preserve image scaling in screenshot
        mediaDiv.querySelectorAll('img').forEach((img) => {
          const div = document.createElement('div');
          div.style.width = '100%';
          div.style.height = '100%';
          div.style.backgroundImage = `url('${img.src}')`;
          div.style.backgroundSize = 'cover';
          div.style.backgroundPosition = 'center';

          // Replace the img with the new div
          img.parentNode.replaceChild(div, img);
        });
      });
    },
  }).then((canvas) => {
    const formatKey = format.toUpperCase();
    const imageFormatKey = IMAGE_DOWNLOAD_FILE_FORMATS[formatKey];
    const dataURL = canvas.toDataURL(imageFormatKey.dataUrl);
    const url = dataURL.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    const link = document.createElement('a');
    const title = 'Widget PNG';

    if (title) {
      link.download = `${title}.${imageFormatKey.extension}`;
    } else {
      link.download = `DH Report.${imageFormatKey.extension}`;
    }
    link.href = url;
    link.click();
  });
}
