<template>
  <div
    class="select"
    :class="[
      {
        'filter-group': filterGroup,
        border: border,
        'select-relative-position': selectRelativePosition,
      },
    ]"
    data-cy="DHSelect"
  >
    <div data-cy="SelectToggleOptions" class="flex items-center" @click.prevent="toggleOptions">
      <slot :display-label="displayLabel" :open="showOptions">
        <template v-if="chip">
          <SelectionDisplayChip
            :border="border"
            :active="showOptions"
            :class="{ 'w-full': fullWidth }"
            :hide-dropdown-icon="active"
          >
            {{ displayLabel }}
          </SelectionDisplayChip>
        </template>
        <template v-else>
          <SelectionDisplay
            class="picker w-full"
            :label="dropdownLabel"
            :class="{ 'is-report': isReport, 'w-full': fullWidth }"
            :active="showOptions || active"
            :disabled="disabledSelect"
            :disabled-tooltip-text="disabledTooltipText"
            :hide-button="!showDisplayLabel"
            :large-text="largeText"
          >
            {{ displayLabel }}
            <slot name="after-label"></slot>
          </SelectionDisplay>
        </template>
      </slot>
    </div>

    <transition name="slide">
      <div
        v-if="showOptions"
        v-on-click-outside="() => closeOptions(true)"
        :class="['options', ...optionsStyle]"
        :style="dropdownOptionsStyles"
      >
        <ul v-if="!calendarAlwaysCustom" :style="{ columnCount }">
          <SelectOption
            v-for="(option, index) in options"
            :key="option.value ?? option.label"
            class="select-option"
            v-bind="optionStyleProps"
            :option="option"
            :selected="selectedOption?.value"
            :expanded="index === expandedAccordionIndex"
            @select="onSelect"
            @toggle-accordion="() => onToggleAccordion(index)"
          />
          <SelectOption
            v-if="isCalendarType && !calendarAlwaysCustom"
            data-cy="customOption"
            v-bind="optionStyleProps"
            :option="customDateRangeOption"
            :selected="selectedOption?.value"
            @select="openCalendar"
          />
        </ul>

        <template v-if="isCalendarType">
          <div
            :class="['calendar-section', { open: showCalendar || calendarAlwaysCustom }]"
            @transitionend.passive="onCalendarSectionTransitionEnd"
          >
            <DateRangePicker
              v-model="customRange"
              :shown="calendarShown"
              :class="['date-picker', { open: showCalendar || calendarAlwaysCustom }]"
              :min-date="calendarMinDate"
              :max-date="calendarMaxDate"
              :attributes="calendarAttributes"
              :show-top-line="!calendarAlwaysCustom"
              @cancel="cancelCustomRange"
              @apply="applyCustomRange"
            >
              <template #below-calendar="{ startDate, endDate }">
                <slot name="below-calendar" :start-date="startDate" :end-date="endDate" />
              </template>
            </DateRangePicker>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import dayjs from 'dayjs';
import { vOnClickOutside } from '@vueuse/components';
import { getCurrentDate, findNestedOption, findIndexNestedOption } from '@/utils';
import { formatDateRangeLabel } from '@/utils/formatters';
import { colours } from '@/ux/colours';
import DateRangePicker from '@/components/DateRangePicker.vue';
import SelectOption from '@/components/SelectOption.vue';
import SelectionDisplay from '@/components/foundation/SelectionDisplay.vue';
import SelectionDisplayChip from '@/components/foundation/SelectionDisplayChip.vue';
import { useNotificationStore } from '@/stores/notification';

const CUSTOM_DATE_RANGE_OPTION = { label: 'Custom', value: 'custom' };

const comp = defineComponent({
  name: 'Select',
  components: {
    DateRangePicker,
    SelectOption,
    SelectionDisplay,
    SelectionDisplayChip,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  inject: {
    multiSelectMode: { default: () => () => false },
  },
  props: {
    active: { type: Boolean, default: false },
    dateRangeValue: { type: [Array, Number], default: null },
    calendarMinDate: {
      type: Date,
      default: null,
    },
    calendarMaxDate: {
      type: Date,
      default: () => new Date(),
    },
    calendarAlwaysCustom: { type: Boolean, default: false },
    calendarAttributes: {
      type: Array,
      default: null,
    },
    defaultOption: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    filterGroup: { type: Boolean, default: false },
    options: { type: Array, default: null },
    placeholder: { type: String, default: 'Custom' },
    type: { type: String, default: 'select' },
    modelValue: { type: [String, Number, Object, Boolean], default: null },
    optionsStyle: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dropdownLabel: { type: String, default: null },
    border: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: [Number, String],
      default: null,
    },
    syncOnUpdate: {
      // true if you wish to sync currentOptionIndex & value on the update lifecycle hook
      type: Boolean,
      default: false,
    },
    disabledTooltipText: {
      type: String,
      default: null,
    },
    dropdownPositionTop: {
      type: String,
      default: null,
    },
    selectRelativePosition: { type: Boolean, default: true },
    resetOnOpen: { type: Boolean, default: false },
    chip: {
      type: Boolean,
      default: false,
    },
    largeText: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoSelect: { type: Boolean, default: true },
    showRangeLabel: { type: Boolean, default: true },
    eventTracker: { type: Object, default: null },
  },
  emits: ['selected', 'update:modelValue'],
  data() {
    return {
      checkColor: colours.ACTION.ACTION_500,
      currentOptionIndex: null,
      range: null,
      customRange: null,
      expandedAccordionIndex: null,
      showCalendar: false,
      calendarShown: false,
      showOptions: false,
      customDateRangeOption: CUSTOM_DATE_RANGE_OPTION,
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    isCalendarType() {
      return this.type === 'calendar';
    },
    showDisplayLabel() {
      return this.options && this.options.length > 0;
    },
    displayLabel() {
      if (this.isCalendarType && this.rangeLabel && this.showRangeLabel) {
        return this.rangeLabel;
      }
      if (this.selectedOption) {
        return this.selectedOption.displayLabel || this.selectedOption.label;
      }
      return this.placeholder;
    },
    rangeLabel() {
      return formatDateRangeLabel(this.range?.startDate, this.range?.endDate);
    },
    rangeOffsets() {
      return (
        this.options?.map((option) => {
          return {
            startOffset: option.startValue || option.value,
            endOffset: option.endValue || 0,
          };
        }) || []
      );
    },
    isCustomRange() {
      return this.selectedOption === CUSTOM_DATE_RANGE_OPTION;
    },
    isCustomRangeValid() {
      return !!this.customRange?.startDate;
    },
    isReport() {
      return !!this.$route?.meta?.report;
    },
    columnCount() {
      if (!this.columns) {
        if (this.isCalendarType) {
          return 2;
        }
        return 1;
      }

      if (this.columns === 'auto') {
        return Math.ceil(this.options.length / 10);
      }

      return this.columns;
    },
    disabledSelect() {
      return this.disabled || this.multiSelectMode();
    },
    dropdownOptionsStyles() {
      if (this.dropdownPositionTop) {
        return { top: this.dropdownPositionTop };
      }

      if (this.border) {
        return { top: '2.5rem' };
      }

      if (!this.dropdownLabel) {
        return { top: '2rem' };
      }

      return { top: '3rem' };
    },
    selectedOption() {
      if (this.isCalendarType) {
        if (this.calendarAlwaysCustom) {
          return CUSTOM_DATE_RANGE_OPTION;
        }
        if (!this.autoSelect) {
          return findNestedOption(this.modelValue, this.options) ?? CUSTOM_DATE_RANGE_OPTION;
        }
        const namedDateRangeOption = this.options?.find((_, index) => {
          return this.isRangeOffsetSameAsRange(this.rangeOffsets[index], this.range);
        });
        return namedDateRangeOption ?? (this.range ? CUSTOM_DATE_RANGE_OPTION : undefined);
      }
      return (
        this.options?.[this.currentOptionIndex] ?? findNestedOption(this.modelValue, this.options)
      );
    },
    optionStyleProps() {
      if (this.columnCount === 1) {
        return {
          hoverBackground: true,
          hoverHighlight: false,
          spacing: '1.75rem',
        };
      }
      return {
        hoverBackground: false,
        hoverHighlight: true,
        spacing: '2rem',
      };
    },
  },
  watch: {
    currentOptionIndex(val, oldVal) {
      if (this.calendarAlwaysCustom) {
        return;
      }
      if (val < 0 || val == null) {
        return;
      }
      if (val === this.defaultOption && oldVal === null) {
        return;
      }
      if (this.isCalendarType) {
        const { startOffset, endOffset } = this.rangeOffsets[val];
        if (!startOffset) {
          this.$emit('selected', null);
          return;
        }
        const startDate = getCurrentDate(startOffset);
        const endDate = getCurrentDate(endOffset);
        const range = [startDate, endDate];
        this.$emit('selected', range, val);
      } else if (oldVal !== null) {
        this.$emit('selected', val);
      }
    },
    selectedOption: {
      handler(option) {
        // The accordion should expand to the nested selected option when the options are reopened
        const selectedChildIndex = findIndexNestedOption(option?.value, this.options);
        this.expandedAccordionIndex = this.options?.[selectedChildIndex]?.children
          ? selectedChildIndex
          : null;
      },
      immediate: true,
    },
    modelValue() {
      this.updateCurrentOptionIndex();
    },
    options() {
      this.updateCurrentOptionIndex();
    },
    dateRangeValue(val) {
      this.setDateRange(val);
    },
    showOptions(show) {
      if (show) {
        if (this.isCalendarType) {
          this.currentOptionIndex = -1;
        }

        if (this.isCustomRange) {
          this.showCalendar = true;
          this.customRange = this.range;
        } else {
          this.showCalendar = false;
          this.customRange = null;
        }
      } else if (this.resetOnOpen) {
        this.range = null;
      }
    },
    disabledSelect(disabled) {
      if (disabled) this.showOptions = false;
    },
  },
  updated() {
    if (this.syncOnUpdate) {
      this.updateCurrentOptionIndex();
    }
  },
  mounted() {
    if (this.currentOptionIndex === null && this.options) {
      if (this.modelValue) {
        this.updateCurrentOptionIndex();
      } else {
        this.currentOptionIndex = this.defaultOption;
      }
    }
    this.setDateRange(this.dateRangeValue);
  },
  methods: {
    setDateRange(val) {
      if (!val) {
        this.range = val;
        return;
      }
      if (val.length === 2) {
        const startDate = dayjs(val[0]);
        const endDate = dayjs(val[1]);
        this.range = { startDate, endDate };
      } else {
        const period = val[0];
        const startDate = dayjs().subtract(period, 'd');
        const endDate = dayjs();
        this.range = { startDate, endDate };
      }
      this.currentOptionIndex = -1;
    },
    toggleOptions() {
      if (!this.disabledSelect) {
        this.showOptions = !this.showOptions;
      }
      if (this.eventTracker && this.showOptions) {
        const { tracker, filterName } = this.eventTracker;
        tracker.filters('Clicked', filterName, this.modelValue || this.dateRangeValue);
      }
    },
    closeOptions(clickOutside = false) {
      if (this.showOptions) {
        this.showOptions = false;

        if (this.isCalendarType && this.currentOptionIndex === -1) {
          if (this.showCalendar && this.isCustomRangeValid) {
            this.range = this.customRange;
          }

          if (this.range && this.range.startDate && this.range.endDate) {
            this.$emit(
              'selected',
              [this.range.startDate.format('YYYY-MM-DD'), this.range.endDate.format('YYYY-MM-DD')],
              this.currentOptionIndex,
              clickOutside,
            );
          } else {
            // if select date didn't provide complete data range, change to 'All Time'
            this.currentOptionIndex = this.defaultOption;
          }
        }
      }
    },
    openCalendar() {
      this.showCalendar = true;
      this.currentOptionIndex = -1;
    },
    onSelect(value) {
      if (this.isCalendarType ? this.dateRangeValue !== value : this.modelValue !== value) {
        this.updateCurrentOptionIndex(value);
        if (this.options?.[this.currentOptionIndex]?.label?.toLowerCase() === 'all time') {
          this.range = null;
        }
        this.$emit('update:modelValue', value);
        this.closeOptions();
      }
    },
    onToggleAccordion(index) {
      if (this.expandedAccordionIndex === index) {
        this.expandedAccordionIndex = null;
      } else {
        this.expandedAccordionIndex = index;
      }
    },
    applyCustomRange() {
      this.closeOptions();
    },
    cancelCustomRange() {
      this.customRange = null;
      this.showCalendar = false;
      this.showOptions = false;
      this.closeOptions();
    },
    isRangeOffsetSameAsRange(rangeOffset, range) {
      if (rangeOffset.startOffset === null) {
        // 'All Time' selected
        return range === null;
      }
      const optionStartDate = dayjs().subtract(rangeOffset?.startOffset, 'd');
      const optionEndDate = dayjs().subtract(rangeOffset?.endOffset, 'd');
      return (
        range?.startDate?.isSame(optionStartDate, 'day') &&
        range?.endDate?.isSame(optionEndDate, 'day')
      );
    },
    updateCurrentOptionIndex(newValue) {
      // Note: currentOptionIndex doesn't support options in accordions
      const value = newValue ?? this.modelValue;
      const selectIndex = this.options?.findIndex((item) => item.value === value);
      this.currentOptionIndex = selectIndex >= -1 ? selectIndex : -1;
    },
    onCalendarSectionTransitionEnd(event) {
      this.calendarShown = event?.propertyName === 'max-height' && this.showCalendar;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.select {
  &.select-relative-position {
    position: relative;
  }

  .picker .is-report {
    background: none;
  }

  &.border {
    .picker {
      border: 1px solid var(--border);
      border-radius: var(--round-corner-small);
      font-weight: var(--font-medium);
      padding: 0 var(--space-16);
      min-height: var(--space-40);

      .is-report {
        background: none;
      }
    }
  }

  .options {
    position: absolute;
    z-index: var(--z-index-dropdown);
    background-color: var(--background-0);
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-4);
    left: 0;

    ul {
      min-width: 8.5rem;
      padding: var(--space-8) 0;
      border-radius: var(--round-corner);
      color: var(--text-primary);
      vertical-align: middle;
      display: inline-block;
      width: 100%;
    }
  }

  .options.cols-2,
  .options.cols-2-reduced-width {
    border-radius: var(--round-corner-small);
    right: var(--space-16);
    left: unset;

    ul {
      width: 30rem;
      display: flex;
      flex-wrap: wrap;

      .select-option {
        width: 47.5%;
      }
    }
  }

  .options.cols-2-reduced-width {
    ul {
      width: 28rem;
    }
  }

  .options.cols {
    border-radius: var(--round-corner-small);
    right: var(--space-16);
    left: unset;

    ul {
      display: flex;

      .select-option {
        width: 47.5%;
      }
    }
  }

  .options.sorted-vertically {
    ul {
      display: inline-block;
      column-fill: balance;

      .select-option {
        min-width: 16rem;
      }
    }
  }

  .options.calendar {
    border-radius: var(--round-corner-small);

    ul {
      width: 17rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 47.5%;
        line-height: 2rem;
        display: flex;

        &:hover {
          background: var(--background-0);
          color: var(--action-500);
        }
      }
    }
  }

  .options.right-align {
    left: auto;
    right: 0;
  }

  .options.max-height {
    max-height: 22.5rem;
    overflow: scroll;
  }
}

.calendar-section {
  max-height: 0;
  opacity: 0;
  transition:
    max-height 0.3s ease-in,
    opacity 0.4s ease-out;
  overflow: hidden;
}

.calendar-section.open {
  opacity: 1;
  max-height: 600px;
}

.select.filter-group {
  margin-right: var(--space-12);
  min-width: 0;
  border-color: var(--border);

  :deep(.v-date-range) {
    position: relative;
    box-shadow: none;
  }
}

.date-picker {
  height: auto;
  min-width: 310px;
  max-width: 350px;
  visibility: hidden;
}

.date-picker.open {
  visibility: visible;
}
</style>
