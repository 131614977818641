/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upgrade-arrow': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M8.566 4.234L7.434 5.367l1.838 1.834H0v1.6h9.266l-1.832 1.835 1.132 1.13 3.765-3.772-3.765-3.76z" _fill="#4990E2"/><path pid="1" d="M8.001 0C4.426 0 1.393 2.36.371 5.6h1.7c.954-2.342 3.25-4 5.93-4 3.53 0 6.4 2.871 6.4 6.4 0 3.529-2.87 6.4-6.4 6.4-2.68 0-4.976-1.658-5.928-4H.37C1.392 13.642 4.425 16 8.001 16c4.412 0 8-3.589 8-8s-3.589-8-8-8z" _fill="#4990E2"/>'
  }
})
