<script setup>
const props = defineProps({
  useSummaryMessage: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    class="mb-3 rounded-br-2xl rounded-tl-2xl rounded-tr-2xl bg-white bg-opacity-60 px-7 py-6 text-base"
  >
    <template v-if="props.useSummaryMessage">
      <p>
        <strong>Welcome to Vision AI Summaries!</strong><br /><br />

        By clicking "Summarize", Vision AI will instantly analyze your data to highlight important
        trends and generate a report-ready digest.<br /><br />

        Vision AI Summaries are available in Community, Dashboards and Social Listening.
      </p>
    </template>
    <template v-else>
      <span class="text-main-bold">Welcome to Vision AI!</span>
      <br /><br />
      <p class="text-main">There are two ways to analyze your performance data:<br /><br /></p>
      <ul class="list-disc pl-4">
        <li>
          Click <strong class="text-main-bold">Summarize</strong> to spot trends and generate a
          report-ready digest. <br />Available in Community, Dashboards and Social Listening.<br /><br />
        </li>
        <li>
          Enter a question into the chat box. <br />
          For best results, make sure to include a <strong class="text-main-bold">metric</strong>, a
          <strong class="text-main-bold">channel</strong>, and a
          <strong class="text-main-bold">timeframe</strong> in your question!
        </li>
      </ul>
    </template>
  </div>
</template>
