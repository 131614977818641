import { defineAsyncComponent } from 'vue';
import { constants } from '@/config';
import MediaPopup from '@/app/library/components/MediaPopup.vue';

const AddProduct = () => import('@/app/library/pages/AddProduct.vue');
const App = () => import('@/app/library/App.vue');
const Discover = () => import('@/app/library/pages/Discover.vue');
const Galleries = () => import('@/app/library/pages/Galleries.vue');
const LibraryGallery = () => import('@/app/library/pages/LibraryGallery.vue');
const GalleryEdit = () => import('@/components/Galleries/GalleryEdit.vue');
const ProductDetail = () => import('@/app/library/pages/ProductDetail.vue');
const ProductFeed = () => import('@/app/library/pages/ProductFeed.vue');
const Products = () => import('@/app/library/pages/Products.vue');
const UploadPopup = defineAsyncComponent(() => import('@/app/library/pages/UploadPopup.vue'));

const PAGE_LEVEL_TOP = `Library`;

export default {
  path: 'library',
  component: App,
  meta: {
    requiresAuth: true,
    allowsAds: true,
  },
  children: [
    {
      name: 'library',
      path: '',
      redirect: { name: 'library.index' },
    },
    {
      // route for open media popup externally
      name: 'library.media',
      path: 'media/:id',
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - View Media`,
        modal: {
          component: MediaPopup,
        },
      },
    },
    {
      // route for media upload popup
      name: 'library.media.upload',
      path: 'media/upload',
      meta: {
        modal: {
          component: UploadPopup,
        },
        analytics: `${PAGE_LEVEL_TOP} - Upload Media`,
      },
    },
    {
      name: 'library.index',
      // have to give it a unique path,
      // otherwise 'Find Media' item in the side menu will always be highlighted
      // because we are not using 'exact match' mode (shows highlight if there is a route/child route match)
      path: 'discover',
      component: Discover,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Find Media`,
      },
    },
    {
      path: 'galleries',
      children: [
        {
          name: 'library.galleries',
          path: '',
          component: Galleries,
          props: {
            galleryType: constants.LIBRARY,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Galleries`,
          },
          children: [
            {
              name: 'library.galleries.new',
              path: 'new',
              component: GalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - New Gallery`,
              },
            },
          ],
        },

        {
          name: 'library.galleries.board',
          path: ':id',
          component: LibraryGallery,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - View Gallery`,
          },
          props: {
            galleryType: constants.LIBRARY,
          },
          children: [
            {
              name: 'library.galleries.edit',
              path: 'edit',
              component: GalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Edit Gallery`,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'library.galleries.report',
      path: 'galleries/:id/report',
      component: LibraryGallery,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Gallery Report`,
        hideNav: true,
        hideVisionAi: true,
        report: true,
      },
    },
    {
      path: 'products',
      children: [
        {
          name: 'library.products',
          path: '',
          component: Products,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Products`,
          },
          children: [
            {
              name: 'library.products.feed',
              path: 'feed',
              component: ProductFeed,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Products - Feed`,
                hideNav: true,
                hideVisionAi: true,
                report: true,
              },
            },
            {
              name: 'library.products.new',
              path: 'new',
              component: AddProduct,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - New Product`,
              },
            },
          ],
        },
        {
          name: 'library.products.detail',
          path: ':id',
          component: ProductDetail,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - View Product`,
          },
          children: [
            {
              name: 'library.products.edit',
              path: 'edit',
              component: AddProduct,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Edit Product`,
              },
            },
          ],
        },
      ],
    },
  ],
};
