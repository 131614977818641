export const SOCIAL_LISTENING_TIERS = Object.freeze({
  TRIAL: {
    name: 'Trial',
    value: 'trial',
    data: {
      topics: 5,
      mentions: 1000000,
    },
    price: 0,
  },
  TIER_ONE: {
    name: 'Tier One',
    value: 'tier_one',
    data: {
      lookup_key: 'social_listening_tier_1',
      topics: 5,
      mentions: 10000,
    },
    price: 499,
  },
  TIER_TWO: {
    name: 'Tier Two',
    value: 'tier_two',
    data: {
      lookup_key: 'social_listening_tier_2',
      topics: 10,
      mentions: 25000,
    },
    price: 749,
  },
  TIER_THREE: {
    name: 'Tier Three',
    value: 'tier_three',
    data: {
      lookup_key: 'social_listening_tier_3',
      topics: 20,
      mentions: 50000,
    },
    price: 999,
  },
});

export const SOCIAL_LISTENING_CUSTOM_TIER = Object.freeze({
  name: 'Custom',
  value: 'custom',
  data: {
    topics: null,
    mentions: null,
  },
  price: null,
});
