<template>
  <EmbedPreviewMediaView
    class="embed-preview-media-view"
    :src-url="srcUrl"
    :iframe-attributes="iframeAttributes"
  />
</template>

<script>
import { defineComponent } from 'vue';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';
import { stringifyQuery } from '@/utils/query';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'YouTubeEmbed',
  components: { EmbedPreviewMediaView },
  props: {
    mediaItem: { type: Object, required: true },
  },
  data() {
    return {
      iframeAttributes: {
        allowFullscreen: true,
      },
    };
  },
  computed: {
    srcUrl() {
      const queryParams = stringifyQuery({
        // do not show related videos on pause
        rel: 0,
        // do not show YouTube logo in control bar
        modestbranding: 1,
        // set the color used in the video progress bar to white
        color: 'white',
        // use light UI theme
        theme: 'light',
      });

      return `https://www.youtube.com/embed/${this.mediaItem.sourceVideoId}?${queryParams}`;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
div.embedded-content-container {
  :deep(iframe) {
    width: 580px;
    height: 326px;
    border-radius: var(--round-corner-large);
  }
}
</style>
