import { CHANNELS } from '@/models/dashboards/channels.enum';

export const CHANNEL_SELECTOR_ROUTE_MAP = {
  [CHANNELS.INSTAGRAM.value]: {
    value: CHANNELS.INSTAGRAM.value,
    iconNameDefault: 'instagram-mono',
    iconNameSelected: 'instagram-color',
    routeName: 'instagram',
  },
  [CHANNELS.TIKTOK.value]: {
    value: CHANNELS.TIKTOK.value,
    iconNameDefault: 'tiktok-mono',
    iconNameSelected: 'tiktok-color',
    routeName: 'tiktok',
  },
  [CHANNELS.FACEBOOK.value]: {
    value: CHANNELS.FACEBOOK.value,
    iconNameDefault: 'facebook-mono',
    iconNameSelected: 'facebook-color',
    routeName: 'facebook',
  },
  [CHANNELS.PINTEREST.value]: {
    value: CHANNELS.PINTEREST.value,
    iconNameDefault: 'pinterest-mono',
    iconNameSelected: 'pinterest-color',
    routeName: 'pinterest',
  },
  [CHANNELS.TWITTER.value]: {
    value: CHANNELS.TWITTER.value,
    iconNameDefault: 'x-mono',
    iconNameSelected: 'x-color',
    routeName: 'twitter',
  },
  [CHANNELS.YOUTUBE.value]: {
    value: CHANNELS.YOUTUBE.value,
    iconNameDefault: 'youtube-mono',
    iconNameSelected: 'youtube-color',
    routeName: 'youtube',
  },
  [CHANNELS.LINKEDIN.value]: {
    value: CHANNELS.LINKEDIN.value,
    iconNameDefault: 'linkedin-mono',
    iconNameSelected: 'linkedin-color',
    routeName: 'linkedin',
  },
};

export const MIXPANEL_VALUES = {
  UTILITY_MENU: {
    ROOT: 'utility menu',
    SETTINGS: 'Settings',
    BILLING: 'Billing and Plan',
    RESOURCE_CENTER: 'Resource Center',
    LOGOUT: 'Log Out',
    SWITCH_BRAND: 'Brand Switched',
    SEARCH_BRAND: 'Typed in Search Bar',
  },
  NOTIFICATIONS: {
    ROOT: 'notifications panel',
  },
};
