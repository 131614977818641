/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'videoControlPlay': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g id="Media-Cropping-Feature"><g id="play" transform="translate(-6 -3)"><g id="color_x2F_border" transform="translate(6 3)"><g id="Mask"><path pid="0" id="path-1_1_" d="M5.9 3.3v17.5l15-8.7-15-8.8zm2 3.4l9 5.3-9 5.3V6.7z" _fill="#666"/></g></g></g></g>'
  }
})
