/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_740_18946)"><path pid="0" d="M21.924 8.572A1 1 0 0021 7.955h-5.382l-2.724-5.447c-.34-.677-1.45-.677-1.79 0L8.383 7.955H3a1 1 0 00-.707 1.707l4.584 4.584-1.838 6.435a1 1 0 001.515 1.106L12 18.157l5.445 3.63a.998.998 0 001.516-1.106l-1.838-6.435 4.584-4.584c.286-.285.37-.717.217-1.09z" _fill="#FEB43A"/></g><defs><clipPath id="clip0_740_18946"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
