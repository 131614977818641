<template>
  <div v-tooltip="tooltipObject" class="split-button" :class="{ 'show-dropdown': showDropdown }">
    <Button
      v-bind="$attrs"
      :disabled="disabled"
      :loading="loading"
      class="main-button"
      data-cy="MainButton"
      primary
      @click="$emit('click', $event)"
    >
      <slot />
    </Button>
    <DropdownButton
      v-if="showDropdown"
      :disabled="disabled"
      :dropdown-list="options"
      :button-classes="['semi-circle', { disabled }]"
      class="dropdown-button"
      :dropdown-list-styles="dropdownListStyles"
      align-right
      data-cy="SplitDropdownButton"
    >
      <template #buttonContent="{ open }">
        <Icon color="white" name="chevronDown" :dir="open ? 'down' : 'up'" xxsmall />
      </template>
    </DropdownButton>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isString from 'lodash/isString';
import Button from '@/components/foundation/Button.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Icon from '@/components/foundation/Icon.vue';

/**
 * A button, a dropdown menu, let's have both!
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Button,
    DropdownButton,
    Icon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    tooltip: {
      type: [String, Object, Boolean],
      default: null,
    },
    dropdownListStyles: {
      type: Object,
      default: () => ({
        minWidth: '150px',
      }),
    },
  },
  emits: ['click'],
  computed: {
    tooltipObject() {
      if (isString(this.tooltip)) {
        return { content: this.tooltip, boundary: 'viewport' };
      }
      return this.tooltip;
    },
    showDropdown() {
      return this.options.length > 0 && !this.loading;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
div.split-button {
  display: inline-flex;

  .main-button,
  .main-button:hover {
    flex: 1;
    min-width: 0;
    transform: none;
    box-shadow: none;
  }

  &.show-dropdown .main-button {
    padding-right: var(--space-12);
    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
  }

  .dropdown-button {
    transition: var(--transition-all);
  }
}
</style>
