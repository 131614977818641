import { useTiktokStore } from '@/stores/tiktok';
import { computed, unref } from 'vue';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';

export function useTikTokAdsAccountDetails() {
  const tiktokStore = useTiktokStore();
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const adAccountsList = computed(() => {
    return tiktokStore.adAccountsList.map((account) => {
      return {
        ...account,
        sourceAdAccountId: account.sourceAdvertiserId,
      };
    });
  });

  const adCampaigns = computed(() => tiktokStore.adCampaigns);

  const adCampaignsList = computed(() => {
    if (!tiktokStore.adCampaigns) return [];
    return Object.values(tiktokStore.adCampaigns);
  });

  const adAccountToCurrency = computed(() => {
    return tiktokStore.tiktokAdsAccountToCurrency;
  });

  const adAccountsLoading = computed(() => tiktokStore.pending.adAccounts);
  const adCampaignsLoading = computed(() => tiktokStore.pending.adCampaigns);

  async function getAdAccounts({ brandIds }) {
    await tiktokStore.getAdAccounts({ brandIds: unref(brandIds) });
  }

  async function getAdCampaigns({ brandIds, adAccountIds, sourceCampaignIds }) {
    await tiktokStore.getAdCampaigns({
      sourceAdAccountIds: unref(adAccountIds),
      brandIds: unref(brandIds),
      sourceCampaignIds: unref(sourceCampaignIds),
    });
  }

  function brandHasPermission(brand) {
    if (
      flagStore.ready &&
      (flagStore.flags.tiktokAdsGraphReports || flagStore.flags.tiktokAdsMetricReports)
    ) {
      return identityStore.guard(BRAND.ADS.CAN_ACCESS_TIKTOK_ADS, brand);
    }
    return false;
  }

  function userHasPermission(brand) {
    if (
      flagStore.ready &&
      (flagStore.flags.tiktokAdsGraphReports || flagStore.flags.tiktokAdsMetricReports)
    ) {
      return identityStore.guard(USER.ADS.CAN_ACCESS_TIKTOK_ADS, brand);
    }
    return false;
  }

  function userAndBrandHavePermission(brand) {
    return brandHasPermission(brand) && userHasPermission(brand);
  }

  return {
    adAccountsList,
    adAccountsLoading,
    adCampaigns,
    adCampaignsList,
    adCampaignsLoading,
    adAccountToCurrency,
    getAdAccounts,
    getAdCampaigns,
    brandHasPermission,
    userHasPermission,
    userAndBrandHavePermission,
  };
}
