import chunk from 'lodash/chunk';
import { extractUrlsFromText } from '@/utils';

export const COMMENTING_SPAN_TYPES = {
  TEXT: 'TEXT',
  MENTION: 'MENTION',
  LINK: 'LINK',
};

export function decodeSpansFromText(text, userReferences) {
  // Find @[123] instances, checking that the @ symbol has not been
  // escaped by a backslash "\". This is done by checking the text before the
  // mention match is either the start of the string, something that isn't a backslash,
  // or some even number of backslashes (escaped backslashes).
  return chunk(text.split(/@\[(\d+)]/), 2)
    .flatMap(([beforeMention, mentionId]) => {
      const mentionEscaped = mentionId && beforeMention.match(/(?:^|[^\\])(?:\\\\)*\\$/);
      const textValue = mentionEscaped ? `${beforeMention}@[${mentionId}]` : beforeMention;
      const escapedTextValue = textValue.replace(/\\([@\\])/g, '$1');
      const spans = [];
      if (escapedTextValue) {
        const links = extractUrlsFromText(escapedTextValue);

        // Extract links into separate elements
        let splitText = escapedTextValue;
        if (links.length) {
          links.forEach((link) => {
            splitText = splitText.split(link);
            spans.push({
              type: COMMENTING_SPAN_TYPES.TEXT,
              value: splitText[0],
            });
            spans.push({
              type: COMMENTING_SPAN_TYPES.LINK,
              url:
                !link.startsWith('http://') && !link.startsWith('https://')
                  ? `https://${link}`
                  : link,
              value: link,
            });
            // eslint-disable-next-line prefer-destructuring
            splitText = splitText[1];
          });
        }
        if (splitText.length > 0) {
          spans.push({
            type: COMMENTING_SPAN_TYPES.TEXT,
            value: splitText,
          });
        }
      }
      if (mentionId && !mentionEscaped) {
        spans.push({
          type: COMMENTING_SPAN_TYPES.MENTION,
          user: userReferences[mentionId],
        });
      }
      return spans;
    })
    .reduce((spans, span) => {
      const lastSpan = spans[spans.length - 1];
      const remainingSpans = spans.slice(0, -1);
      if (
        lastSpan?.type === COMMENTING_SPAN_TYPES.TEXT &&
        span.type === COMMENTING_SPAN_TYPES.TEXT
      ) {
        return [
          ...remainingSpans,
          {
            type: COMMENTING_SPAN_TYPES.TEXT,
            value: `${lastSpan.value}${span.value}`,
          },
        ];
      }
      return [...spans, span];
    }, []);
}

export function containsMention(text) {
  // returns true if the text contains a comment mention
  const spans = decodeSpansFromText(text, []);

  return spans.reduce(
    (hasMentions, span) => hasMentions || span.type === COMMENTING_SPAN_TYPES.MENTION,
    false,
  );
}
