import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useMediaPopupStore = defineStore('mediaPopup', () => {
  const contextList = ref(null);

  function setMediaContextList({ mediaContextList }) {
    contextList.value = mediaContextList;
  }

  function clearMediaContextList() {
    contextList.value = null;
  }

  return {
    contextList,
    setMediaContextList,
    clearMediaContextList,
  };
});
