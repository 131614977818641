/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'businessGraphBarStatus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M22 21.999h2v2H0v-2h2v-10a1 1 0 011-1h4a1 1 0 011 1v10h1v-7a1 1 0 011-1h4a1 1 0 011 1v7h1v-13a1 1 0 011-1h4a1 1 0 011 1v13zm-18 0h2v-9H4v9zm7 0h2v-6h-2v6zm7 0h2v-12h-2v12zM5 7a2 2 0 111.999-2.009l4.002 2.286C11.296 7.106 11.635 7 12 7c.178 0 .347.031.511.074l4.563-4.563A1.996 1.996 0 0117 2a2 2 0 112 2c-.178 0-.348-.031-.511-.074l-4.563 4.563c.043.164.074.332.074.51a2 2 0 01-3.999.01L5.999 6.723A1.976 1.976 0 015 7z" id="svgicon_businessGraphBarStatus_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_businessGraphBarStatus_b" _fill="#fff"><use xlink:href="#svgicon_businessGraphBarStatus_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_businessGraphBarStatus_a"/><g mask="url(#svgicon_businessGraphBarStatus_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
