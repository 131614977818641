/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'atSymbol': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2C6.486 2 2 6.486 2 12c0 5.515 4.486 10 10 10a9.937 9.937 0 005.652-1.741l-1.131-1.648A7.962 7.962 0 0112 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8v.782C20 14.17 19.402 15 18.4 15l-.002.018a.904.904 0 00-.189-.018H18c-.563 0-1.4-.818-1.4-1.369V12c0-2.536-2.063-4.6-4.6-4.6S7.4 9.463 7.4 12s2.063 4.6 4.6 4.6c1.234 0 2.35-.494 3.177-1.287C15.826 16.269 16.93 17 18 17l.002-.019a.943.943 0 00.193.019h.205c2.152 0 3.6-1.694 3.6-4.218V12c0-5.514-4.486-10-10-10zm0 12.599c-1.434 0-2.6-1.166-2.6-2.6s1.166-2.6 2.6-2.6 2.6 1.166 2.6 2.6-1.166 2.6-2.6 2.6z" _fill="#adadad"/>'
  }
})
