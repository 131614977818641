import { useTrackingStore } from '@/stores/tracking';
import {
  INTENT_TYPES,
  VISION_AI_MIXPANEL_EVENTS,
  VISION_AI_INTENT_TYPE_NAMES,
  CONVERSATIONAL_RESPONSE_TYPES_MIXPANEL,
  SUMMARY_INTENT_TYPES,
} from '@/components/VisionAi/constants';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { useDashboardsStore } from '@/stores/dashboards';
import { useSocialListeningStore } from '@/stores/social-listening';
import merge from 'lodash/merge';
import { useAuthStore } from '@/stores/auth';
import { useSearchStore } from '@/stores/search';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { getMetricDetails } from '@/utils/metrics';
import { REPORTS } from '@/app/dashboards/utils/reports.enum';

export function widgetLaunchEvent({ interaction, path }) {
  const trackingStore = useTrackingStore();
  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_WIDGET_LAUNCH, { interaction, path });
}

export function aiWidgetInteractionEvent({ interaction, summaryCount = null, renameChat = null }) {
  const trackingStore = useTrackingStore();
  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_WIDGET_INTERACTION, {
    interaction,
    summaryCount,
    renameChat,
  });
}

function getPlatformSuperProperties(intentType) {
  const communityInteractionStore = useCommunityInteractionStore();
  const dashboardsStore = useDashboardsStore();
  const socialListeningStore = useSocialListeningStore();

  const payload = {};

  switch (intentType) {
    case INTENT_TYPES.DASHBOARD_REPORTS:
      payload.dashboardsId = dashboardsStore.currentDashboardId;
      payload.dashboardsName = dashboardsStore.currentDashboardName;
      break;
    case INTENT_TYPES.COMMENTS:
    case INTENT_TYPES.DMS:
      payload.communityInteractionId = communityInteractionStore.selectedCommunityInteractionId;
      break;
    case INTENT_TYPES.TOPICS:
      payload.topicId = socialListeningStore.selectedTopic?.id;
      payload.topicName = socialListeningStore.selectedTopic?.name;
      break;
    default:
      break;
  }
  return payload;
}

export function aiMessageInteractionEvent({ interaction, intentType, messageId }) {
  const trackingStore = useTrackingStore();
  const payload = merge(
    {
      intentType: VISION_AI_INTENT_TYPE_NAMES[intentType],
      messageId,
    },
    getPlatformSuperProperties(intentType),
  );
  if (interaction) payload.interaction = interaction;

  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_MESSAGE_INTERACTION, payload);
}

export function aiSummaryLaunchEvent({ intentType, launchedFrom }) {
  if (!SUMMARY_INTENT_TYPES.includes(intentType)) return;
  const trackingStore = useTrackingStore();
  const payload = merge(
    {
      summaryType: VISION_AI_INTENT_TYPE_NAMES[intentType],
      launchedFrom,
    },
    getPlatformSuperProperties(intentType),
  );

  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_SUMMARY_LAUNCH, payload);
}

export function userMessageSentEvent({
  messageSent,
  messageId,
  intentType,
  usedPrompt,
  followUps,
  conversationId,
}) {
  const trackingStore = useTrackingStore();
  const payload = {
    messageId,
    intentType: VISION_AI_INTENT_TYPE_NAMES[intentType],
    followUps,
    conversationId,
  };
  if (usedPrompt) {
    payload.promptSelected = messageSent;
  } else {
    payload.messageTyped = messageSent;
  }
  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.USER_MESSAGE_SENT, payload);
}

function getResponseType(payload) {
  if (payload.questionCompetitors.length >= 1)
    return CONVERSATIONAL_RESPONSE_TYPES_MIXPANEL.COMPETITOR_BRANDS;

  if (payload.questionBrands.length >= 1)
    return CONVERSATIONAL_RESPONSE_TYPES_MIXPANEL.OWNED_BRANDS;

  return CONVERSATIONAL_RESPONSE_TYPES_MIXPANEL.OTHER;
}

export function assistantMessageReceivedEvent({ message, conversation }) {
  const trackingStore = useTrackingStore();
  const authStore = useAuthStore();
  const searchStore = useSearchStore();

  if (message.intentType !== INTENT_TYPES.METRIC_ANALYTICS || !message.additionalContent) return;

  const reports = Object.values(message.additionalContent);
  const payload = reports.reduce(
    (acc, report) => {
      const reportMeta = report?.meta;
      if (!reportMeta) return acc;

      const brandIds = reportMeta.brand_ids;
      if (brandIds) {
        const allBrandNames = acc.questionBrands.concat(authStore.getBrandNames(brandIds));
        acc.questionBrands = [...new Set(allBrandNames)];
      }

      const competitorIds = reportMeta.competitor_source_account_ids;
      if (competitorIds) {
        const source = CHANNELS[reportMeta.channels[0]].competitiveChannelType;
        const allCompetitorNames = acc.questionCompetitors.concat(
          searchStore.getCompetitorNames(competitorIds, source),
        );
        acc.questionCompetitors = [...new Set(allCompetitorNames)];
      }

      const allChannels = acc.questionChannels.concat(reportMeta.channels);
      const allMetrics = acc.questionMetrics.concat(reportMeta.metrics);

      const mediaType = getMetricDetails(
        reportMeta.metrics[0],
        reportMeta.channels,
        REPORTS[report.type].metricTypeReportKey,
      ).mediaTypeLabel;
      if (mediaType) {
        const allMediaTypes = acc.questionMediaTypes.concat([mediaType]);
        acc.questionMediaTypes = [...new Set(allMediaTypes)];
      }

      acc.questionChannels = [...new Set(allChannels)];
      acc.questionMetrics = [...new Set(allMetrics)];
      acc.visualResponses.push(report.type);

      return acc;
    },
    {
      visualResponses: [],
      questionBrands: [],
      questionCompetitors: [],
      questionChannels: [],
      questionMetrics: [],
      questionMediaTypes: [],
    },
  );

  // This event is always for Metric Analytics intent, but filtering on Mixpanel is simpler with
  // this field provided.
  payload.intentType = VISION_AI_INTENT_TYPE_NAMES[INTENT_TYPES.METRIC_ANALYTICS];
  payload.conversationId = conversation.id;
  payload.conversationName = conversation.name;
  payload.messageId = message.id;
  payload.response = message.message;
  payload.numberOfVisuals = payload.visualResponses.length;
  payload.responseType = getResponseType(payload);
  trackingStore.track(VISION_AI_MIXPANEL_EVENTS.ASSISTANT_MESSAGE_RECEIVED_METRICS, payload);
}
