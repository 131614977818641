<template>
  <div class="sources-filter" data-cy="SourcesFilter">
    <SelectionDisplay
      ref="sourcesToggle"
      data-cy="SourcesFilterToggle"
      :active="dropdownOpen || !selectedAll"
      :label="displayLabel"
      @click="toggleDropdown"
    >
      {{ sourcesLabel }}
    </SelectionDisplay>

    <transition name="slide">
      <div
        v-if="dropdownOpen"
        v-on-click-outside="handleClickOutside"
        class="sources-filter-dropdown"
      >
        <ul class="sources-list">
          <li class="dropdown-header" @click="clearFilters">
            <div class="source-toggle">
              All Sources
              <Icon v-if="selectedAll" :color="checkColor" name="check" xsmall />
            </div>
          </li>
          <li
            v-for="(source, index) in sources"
            :key="source.name"
            :class="['dropdown', { open: source.open }]"
            @click="toggleSingleTypeSource(source, index)"
          >
            <div class="source-toggle">
              <span
                :data-cy="`SourceToggle-${source.name}`"
                @click.prevent="toggleCaretDropdown(index)"
              >
                {{ source.name }}
                <Icon
                  v-if="source.platformOptions"
                  :color="caretColor"
                  name="caret"
                  class="caret"
                  xxxsmall
                />
              </span>
              <Icon
                v-if="source.checked"
                :color="checkColor"
                name="check"
                xsmall
                @click="toggleSourceChecked(source, index)"
              />
              <span
                v-else
                class="icon-placeholder"
                @click.prevent="toggleSourceChecked(source, index)"
              />
            </div>

            <ul
              v-if="source.platformOptions"
              class="platform-list"
              :data-cy="`PlatformList-${source.name}`"
            >
              <template v-for="(platform, id) in source.platformOptions">
                <li
                  v-if="!platform.hidden"
                  :key="id"
                  @click="toggleFilter(source, platform.name, index)"
                >
                  <span class="platform" :data-cy="`SourcePlatform-${platform.name}`">
                    <Icon :name="platform.iconName" small />
                    {{ platform.label || platform.name }}
                  </span>
                  <Icon
                    v-if="source.selectedPlatforms.includes(platform.name)"
                    :color="checkColor"
                    name="check"
                    xsmall
                  />
                </li>
              </template>
            </ul>
          </li>
        </ul>

        <footer>
          <a href="#" @click.prevent="clearFilters">Clear</a>
          <a href="#" class="blue" data-cy="SourcesFilterApplyButton" @click.prevent="showResults">
            See Results
          </a>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { colours } from '@/ux/colours';
import enumTypes, { sourcesFilterNames } from '@/app/library/constants';
import Icon from '@/components/foundation/Icon.vue';
import SelectionDisplay from '@/components/foundation/SelectionDisplay.vue';
import { selectorContainsEventTarget } from '@/utils/dom';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SourcesFilter',
  components: {
    Icon,
    SelectionDisplay,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    displayLabel: { type: String, default: null },
    value: { type: Object, default: null },
    limitSource: { type: String, default: null },
  },
  emits: ['filtersSelected', 'dropdownToggled'],
  data() {
    return {
      checkColor: colours.ACTION.ACTION_500,
      caretColor: colours.TEXT.TEXT_PRIMARY,
      dropdownOpen: false,
      selectedAll: true,
      selectedPlatforms: [],
      sources: [],
      selectedFilters: null,
    };
  },
  computed: {
    ...mapStores(useFlagStore),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'identity', 'user_can', 'brand_can', 'guard']),
    sourcesLabel() {
      const sources = this.sources.filter((source) => source.checked);
      if (sources.length > 0) {
        const { length } = sources[0].selectedPlatforms;
        if (length) {
          return `${sources[0].name} (${sources[0].selectedPlatforms.length})`;
        }
        return sources[0].name;
      }
      return 'All Sources';
    },
    hasTiktokUgc() {
      return this.flagStore.ready && this.flagStore.flags.tiktokUgc;
    },
  },
  watch: {
    currentBrand() {
      this.reloadSources();
    },
    value() {
      this.loadSelectedFilterFromParents();
    },
  },
  mounted() {
    this.reloadSources();
  },
  methods: {
    loadInitialSources() {
      this.sources = [
        {
          name: 'Owned',
          open: false,
          checked: false,
          platformOptions: [
            { id: 1, name: 'instagram', iconName: 'instagram' },
            { id: 2, name: enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES, iconName: 'stories' },
          ],
          selectedPlatforms: [],
        },
        {
          name: 'UGC',
          open: false,
          checked: false,
          platformOptions: [
            { id: 1, name: 'instagram', iconName: 'instagram' },
            { id: 2, name: enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES, iconName: 'stories' },
          ],
          selectedPlatforms: [],
        },
        {
          name: 'Uploads',
          open: false,
          checked: false,
          selectedPlatforms: [],
        },
      ];
    },
    addAdditionalSourceTypes() {
      if (this.brand_can('facebook', 'can_access_fb_insights')) {
        this.sources[0].platformOptions.push({
          id: 3,
          name: sourcesFilterNames.SOURCE_FILTER_FACEBOOK,
          iconName: 'facebook',
        });
      }
      if (this.guard(USER.PINTEREST.CAN_ACCESS_PINT)) {
        // add Pinterest search filter for Owned
        this.sources[0].platformOptions.push({
          id: 4,
          name: sourcesFilterNames.SOURCE_FILTER_PINTEREST,
          iconName: 'pinterest',
        });
      }
      if (this.brand_can('twitter', 'can_access_twitter_insights')) {
        this.sources[0].platformOptions.push({
          id: 6,
          name: sourcesFilterNames.SOURCE_FILTER_TWITTER,
          label: 'X',
          iconName: 'twitter',
        });
      }
      if (this.brand_can('tiktok', 'can_access_tiktok_insights')) {
        this.sources[0].platformOptions.unshift({
          id: 7,
          name: sourcesFilterNames.SOURCE_FILTER_TIKTOK,
          iconName: 'tiktok',
        });
      }
      if (this.guard(BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS)) {
        this.sources[0].platformOptions.push({
          id: 8,
          name: sourcesFilterNames.SOURCE_FILTER_YOUTUBE,
          iconName: 'youtube',
        });
      }
      if (
        this.guard(BRAND.LINKEDIN.CAN_ACCESS_LINKEDIN_INSIGHTS) &&
        !this.$route.name.includes('campaign')
      ) {
        this.sources[0].platformOptions.push({
          id: 9,
          name: sourcesFilterNames.SOURCE_FILTER_LINKEDIN,
          iconName: 'linkedin',
        });
      }
      // Instagram can_access_ugc_more is used because that is where the permission lives
      // Permission will move to a separate UGC module in sc-97560
      if (this.hasTiktokUgc) {
        if (
          this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE) &&
          this.guard(USER.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
          this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) &&
          this.guard(BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
          !this.$route.name.includes('campaign')
        ) {
          this.sources[1].platformOptions.unshift({
            id: 3,
            name: enumTypes.SOURCE_FILTER_TIKTOK,
            iconName: 'tiktok',
          });
        }
      }
      // Hide uploads source filter on campaigns pages
      if (this.$route.name.includes('campaign')) {
        this.sources = this.sources.filter((source) => source.name !== 'Uploads');
      }
      // Hide UGC source filter on campaigns if user/ brand have no access, or if brand has no access to Campaign UGC & relationships
      if (
        this.$route.name.includes('campaign') &&
        (!this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) ||
          !this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE))
      ) {
        this.sources = this.sources.filter((source) => source.name !== 'UGC');
      }

      if (
        !(
          this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) &&
          this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE)
        )
      ) {
        this.sources = this.sources.filter((source) => source.name !== 'UGC');
      }

      if (this.limitSource) {
        this.sources = this.sources.filter(
          (source) => source.name.toUpperCase() === this.limitSource,
        );
      }
    },
    loadSelectedFilterFromParents() {
      if (!this.value) {
        return;
      }
      const { type, platform } = this.value;
      if (type === null) {
        this.clearFilters();
      } else if (type === 'Uploads') {
        const sourceIndex = this.sources.findIndex((source) => source.name === type);
        this.sources[sourceIndex].checked = true;
        this.selectedAll = false;
      } else {
        const sourceIndex = this.sources.findIndex((source) => source.name === type);
        this.selectedAll = false;
        this.sources[sourceIndex].open = true;
        this.sources[sourceIndex].checked = true;
        if (platform.constructor === Array) {
          // it will be an array if generated through operation
          this.sources[sourceIndex].selectedPlatforms = platform;
        } else {
          // it will be an string if load from url
          this.sources[sourceIndex].selectedPlatforms = platform.split(',');
        }
      }
      this.setFilters();
    },
    reloadSources() {
      this.loadInitialSources();
      this.loadSelectedFilterFromParents();
      this.addAdditionalSourceTypes();
    },
    clearFilters() {
      this.selectedAll = true;
      this.sources.forEach((source, index) => {
        this.sources[index].selectedPlatforms = [];
        this.sources[index].open = false;
        this.sources[index].checked = false;
      });
      this.setFilters();
    },
    showResults() {
      this.$emit('filtersSelected', this.selectedFilters);
      this.closeDropdown();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.$emit('dropdownToggled', this.dropdownOpen);
    },
    closeDropdown() {
      this.dropdownOpen = false;
      this.$emit('dropdownToggled', false);
    },
    toggleCaretDropdown(index) {
      this.sources[index].open = !this.sources[index].open;
      // only allow open up to one dropdown because we do single type selection anyway
      // make the whole thing shorter so the user don't need to scroll to the button to click 'Clear' / 'Sea Results
      this.sources.forEach((itemObject, itemIndex) => {
        if (itemIndex !== index) {
          this.sources[itemIndex].open = false;
        }
      });
    },
    // toggle uploads, all sources
    toggleSingleTypeSource(source, index) {
      if (!this.sources[index].platformOptions) {
        this.clearFilters();
        this.sources[index].checked = !source.checked;
        if (source.checked) {
          this.selectedAll = false;
        }
        this.setFilters();
      }
    },
    // toggle owned / ugc
    toggleSourceChecked(source, index) {
      if (source.checked && source.selectedPlatforms.length === source.platformOptions.length) {
        this.clearFilters();
      } else {
        this.selectedAll = false;
        this.sources.forEach((itemObject, itemIndex) => {
          if (itemIndex === index) {
            // select all platforms under current source type
            this.sources[itemIndex].open = true;
            this.sources[itemIndex].checked = true;
            this.sources[itemIndex].selectedPlatforms = this.sources[itemIndex].platformOptions.map(
              (s) => s.name,
            );
          } else {
            // clear other source types
            this.sources[itemIndex].selectedPlatforms = [];
            this.sources[itemIndex].checked = false;
            this.sources[itemIndex].open = false;
          }
        });
      }
      this.setFilters();
    },
    // toggle instagram/pinterest/twitter/facebook
    toggleFilter(source, platform, index) {
      // uncheck select all
      this.selectedAll = false;
      // uncheck other sources type because we do single type query
      this.sources.forEach((itemObject, itemIndex) => {
        if (itemIndex !== index) {
          this.sources[itemIndex].selectedPlatforms = [];
          this.sources[itemIndex].checked = false;
        }
      });
      const { selectedPlatforms } = this.sources[index];

      // If the platform is checked, uncheck it
      if (source.selectedPlatforms.includes(platform)) {
        selectedPlatforms.splice(selectedPlatforms.indexOf(platform), 1);
        if (selectedPlatforms.length < 1) {
          this.sources[index].checked = false;
          // If user unchecks everything, return to default select all
          this.selectedAll = true;
        }
      } else {
        // If it's not checked, check it!
        selectedPlatforms.push(platform);
        this.sources[index].checked = true;
      }
      this.setFilters();
    },
    isFilterChanged() {
      // compare setting between prop and selectedFilter data.
      // value in prop is the previous setting, value in selectedFilter is the current setting
      const { type, platform } = this.value;
      const previousPlatforms = platform || [];
      // if both null, unchanged
      if (this.selectedFilters === null || this.selectedFilters.mediaSource === null) {
        return type !== null;
      }
      if (
        type === this.selectedFilters.mediaSource &&
        this.selectedFilters.platforms.length === previousPlatforms.length
      ) {
        // if both have same type and same platform, unchanged
        let notSame = false;
        this.selectedFilters.platforms.forEach((currentPlatform) => {
          if (previousPlatforms.indexOf(currentPlatform) < 0) {
            notSame = true;
          }
        });
        return notSame;
      }
      // otherwise, filter has changed
      return true;
    },
    handleClickOutside(e) {
      const clickedOnToggle = selectorContainsEventTarget('[data-cy="SourcesFilterToggle"]', e);
      if (!clickedOnToggle && this.dropdownOpen) {
        if (this.isFilterChanged()) {
          // if new filter is selected, click outside to perform new search
          this.showResults();
        } else {
          // if nothing changed, just close the drop down.
          this.closeDropdown();
        }
      }
    },

    // Send filters to parent component
    setFilters() {
      if (this.selectedAll) {
        this.selectedFilters = { mediaSource: null, platforms: [] };
      } else {
        const sourceFilter = this.sources.filter((source) => source.checked)[0];
        this.selectedFilters = {
          mediaSource: sourceFilter.name,
          platforms: sourceFilter.selectedPlatforms,
        };
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.sources-filter {
  position: relative;
  font-weight: var(--font-medium);
}

.sources-filter-dropdown {
  background: var(--background-0);
  color: var(--text-primary);
  width: 18rem;
  box-shadow: var(--heavy-shadow);
  border-radius: var(--round-corner-small);
  position: absolute;
  top: 2.75rem;
  left: 0;
  height: auto;
  z-index: var(--z-index-dropdown);
  transition: var(--transition-all);
}

ul.sources-list {
  height: auto;
  padding: var(--space-12) 0;
}

ul.sources-list li {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 var(--space-24);
  text-transform: capitalize;
  cursor: pointer;
  font-size: var(--x14);

  .source-toggle {
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;

      &:hover {
        color: var(--action-500);
      }
    }
  }

  .caret {
    margin: var(--space-4) 0 0 var(--space-12);
    transition: var(--transition-all);
  }

  .icon-placeholder {
    height: 100%;
    width: 1.5em;
  }
}

ul.platform-list {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-all);

  li {
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 var(--space-40);
    text-transform: capitalize;
    cursor: pointer;

    .platform {
      display: flex;
      align-items: center;
      transition: var(--transition-all);

      .svg-icon {
        margin-right: var(--space-8);
      }
    }

    &:hover {
      color: var(--action-500);
    }
  }
}

ul.sources-list li.open {
  height: auto;

  .caret {
    transform: rotate(90deg);
  }

  ul.platform-list {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

footer {
  background: var(--background-300);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  font-size: var(--x14);

  a:active,
  a:hover {
    color: var(--action-500);
  }

  .blue {
    color: var(--action-500);
  }
}
</style>
