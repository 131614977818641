<template>
  <div>
    <div class="avatar-label">
      <div class="platform-avatar" :data-platform="platform">
        <template v-if="avatarUrl">
          <img :src="avatarUrl" />
          <span class="icon-wrapper">
            <Icon v-bind="getIconSize(platform)" :name="platform" :color="whiteIcon" />
          </span>
        </template>
        <PostIcon v-else :type="postIconType" />
      </div>
      <p v-if="showHandle">
        <span v-if="handleClickable" class="handle">
          <a :href="handleURL" target="_blank" rel="noopener">{{ handle }}</a>
        </span>
        <span v-else class="handle">{{ handle }}</span>
        <span v-if="handle">/</span>
        <span>{{ platformLabel }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';

import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import { formatPlatformConnectionLabel } from '@/utils/formatters';
import { usePlatformStore } from '@/stores/platform';
import { useFlagStore } from '@/stores/flag';
import { PLATFORMS_TO_MULTI_CHANNEL_POST_TYPES } from '@/app/scheduler/constants';
import PostIcon from '@/app/scheduler/components/PostIcon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PlatformAvatar',
  components: {
    Icon,
    PostIcon,
  },
  props: {
    avatarUrl: { type: String, default: null },
    handle: { type: String, required: true },
    platform: { type: String, default: null },
    showHandle: { type: Boolean, default: true },
  },
  data() {
    return {
      whiteIcon: colours.BASIC.WHITE,
      greyIcon: colours.ICON.ICON_SECONDARY,
    };
  },
  computed: {
    ...mapStores(useAuthStore, usePlatformStore, useFlagStore),
    facebookPageId() {
      return this.platformStore.facebookPage?.fbPageId ?? null;
    },
    platformLabel() {
      return formatPlatformConnectionLabel(this.platform);
    },
    handleClickable() {
      return !!this.handleURL && this.platform !== 'twitter';
    },
    handleURL() {
      if (this.platform === 'facebook') {
        return this.facebookPageId && `https://facebook.com/${this.facebookPageId}`;
      }
      if (this.platform === 'tiktok') {
        return this.handle && this.platform && `https://${this.platform}.com/@${this.handle}`;
      }
      if (this.platform === 'linkedin') {
        return (
          this.handle && this.platform && `https://${this.platform}.com/company/${this.handle}`
        );
      }
      return this.handle && this.platform && `https://${this.platform}.com/${this.handle}`;
    },
    postIconType() {
      return PLATFORMS_TO_MULTI_CHANNEL_POST_TYPES[this.platform];
    },
  },
  methods: {
    getIconSize(platform) {
      const props = {};
      if (platform === 'google_analytics') {
        props.small = true;
      } else if (platform === 'facebook') {
        props.xsmall = true;
      } else {
        props.xxsmall = true;
      }
      return props;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.avatar-label {
  display: flex;
  justify-content: center;
  align-items: center;

  .platform-avatar {
    position: relative;
    height: var(--space-40);
    width: var(--space-40);
    border-radius: 50%;

    .dh-letter-avatar {
      margin-left: 0;
      min-width: 0;
      border: 0;
    }

    .actionable {
      cursor: pointer;
    }

    img,
    .placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: var(--space-40);
      width: var(--space-40);
      border-radius: 50%;
      background: var(--border);
      box-shadow: var(--shadow-1);
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--space-24);
      height: var(--space-24);
      border-radius: 50%;
      background: var(--background-900);
      position: absolute;
      bottom: 0;
      left: 30px;
      z-index: 3;
      box-shadow: var(--shadow-1);
    }
  }

  .handle,
  .handle a {
    color: var(--text-primary);
    font-weight: normal;
    text-transform: none;
    transition: var(--transition-all);
  }

  .handle a:hover {
    color: var(--action-500);
  }

  p {
    font-size: var(--x16);
    color: var(--text-secondary);
    line-height: 20px;
    text-transform: capitalize;
    margin-left: var(--space-24);

    &.no-icon {
      margin-left: var(--space-12);
    }
  }
}
</style>
