export const SOCIAL_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['SUBSCRIBER_GROWTH_RATE'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_ALL_POSTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'FACEBOOK', 'PINTEREST', 'TWITTER', 'YOUTUBE', 'LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'FACEBOOK', 'PINTEREST', 'TWITTER', 'LINKEDIN'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'PINTEREST', 'YOUTUBE'],
      metrics: ['TOTAL_VIDEO_VIEWS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK', 'TWITTER', 'LINKEDIN'],
      metrics: ['ORGANIC_VIDEO_VIEWS'],
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      name: 'TikTok Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_RETENTION_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENTERTAINMENT_SCORE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 19,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 19,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 23,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENTERTAINMENT_SCORE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 27,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 31,
    meta: {
      name: 'Instagram Feed Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'LIKESHOP_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['TOTAL_VIDEO_VIEWS', 'TOTAL_VIDEO_VIEWS_VIDEO', 'TOTAL_VIDEO_VIEWS_REEL'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_METRIC_MEDIA_TYPE',
    w: 3,
    x: 3,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: [
        'NUMBER_OF_POSTS_VIDEO',
        'NUMBER_OF_POSTS_REEL',
        'NUMBER_OF_POSTS_PHOTO',
        'NUMBER_OF_POSTS_CAROUSEL',
      ],
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 42,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 46,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 50,
    meta: {
      name: 'Instagram Story Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_EXIT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'SWIPE_UPS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 53,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 53,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 57,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'SWIPE_UPS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 61,
    meta: {
      name: 'Facebook Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 64,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 64,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 68,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 68,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 72,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 76,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 80,
    meta: {
      name: 'Pinterest Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'SAVES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'CLOSEUPS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 83,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 83,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 87,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metric: 'SAVES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 87,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 91,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 95,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 99,
    meta: {
      name: 'X Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'RETWEETS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'REPLIES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 102,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 102,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 106,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 106,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 110,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 114,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 118,
    meta: {
      name: 'YouTube Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['NUMBER_OF_POSTS'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_AVG_VIEW_DURATION'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ENGAGEMENTS'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_LIKES'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ESTIMATED_SECONDS_WATCHED'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEO_VIEWS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 121,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_ENGAGEMENTS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 121,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_VIDEO_VIEWS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 125,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['SUBSCRIBERS_NET_NEW'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 125,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['VIEWS'],
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 129,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 133,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEO_VIEWS'],
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 137,
    meta: {
      name: 'LinkedIn Performance',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'IMPRESSIONS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICKS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'REACTIONS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 138,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'SHARES_BY_POST',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 140,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['PAGE_VIEWS_ALL_POSTS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 140,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 144,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['ENGAGEMENTS_BY_POST'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 144,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['IMPRESSIONS_BY_POST'],
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 148,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metrics: ['ENGAGEMENT_RATE'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 152,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICK_THROUGH_RATE_BY_POST'],
      sort_order: 'DESC',
    },
  },
];
