<script setup>
// Vue
import { computed } from 'vue';
// Components
// Libraries
import { useRoute } from 'vue-router';
import { useTrackingStore } from '@/stores/tracking';
import Icon from '@/components/foundation/Icon.vue';
// Config
import { env } from '@/env';
import { colours } from '@/ux/colours';

const route = useRoute();
const trackingStore = useTrackingStore();

const zendeskResourceCenterUrl = computed(() => !!env.zendeskResourceCenterUrl);

const zendeskResourceCenterTooltip = computed(() =>
  zendeskResourceCenterUrl.value ? null : 'Resource Center is not available in this environment.',
);

const link = computed(() => ({
  iconName: 'question-mark',
  disabled: !zendeskResourceCenterUrl.value,
  text: 'Resource Center',
  url: env.zendeskResourceCenterUrl,
  tooltip: zendeskResourceCenterTooltip.value,
}));

function mixpanelEvent(el) {
  trackingStore.track('Navigation Heading Selected', {
    itemSelected: el?.text,
    secondary_item_selected: null,
    page: route?.path,
  });
}

function onLinkClick(el) {
  if (el.disabled) {
    return;
  }
  window.open(el.url, '_blank');
  mixpanelEvent(el);
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div v-tooltip="link.tooltip" class="resource-center-link" :class="{ disabled: link.disabled }">
    <a :href="link.url" @click.prevent="onLinkClick(link)">
      <Icon small :color="colours.ICON.ICON_PRIMARY" :name="link.iconName" />
      <span class="visually-hidden">
        {{ link.text }}
      </span>
    </a>
  </div>
</template>

<style lang="postcss" scoped>
.resource-center-link {
  align-items: center;
  display: flex;
  padding: 0 0.25rem;
}

a {
  display: flex;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
</style>
