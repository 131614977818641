<script setup>
import TrialPopupBase from '@/components/InAppTrials/TrialPopupBase.vue';
import Button from '@/components/foundation/Button.vue';
import ActionItem from '@/components/InAppTrials/ActionItem.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { TRIAL_BOOK_TRAINING_URL, TRIAL_PRODUCT_DETAILS } from '@/components/InAppTrials/constants';
import { getImageSource } from '@/assets';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { trackTrialSuccessButtonClicked } from '@/utils/analytics/tracking';
import { PRODUCT_MAP, START_TRIAL } from '@/models/auth/customer-plan.enum';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';

const chiliPiperStore = useChiliPiperStore();
const customerJourneyStore = useCustomerJourneyStore();
const route = useRoute();

const product = computed(() => customerJourneyStore.popups.successTrial.product);

const actionList = computed(() => {
  return TRIAL_PRODUCT_DETAILS[product.value].actionList;
});

const fromBilling = computed(() => {
  return route.name === 'settings.billing' || route.name === 'plans';
});

const trialPlanImage = computed(() => {
  return getImageSource(TRIAL_PRODUCT_DETAILS[product.value].imagePath);
});

function trackButtonClick(buttonText, sendIntercomEvent = false) {
  const trialProduct = START_TRIAL[product.value];
  const keyName = trialProduct.product_type === 'Plan' ? 'trialPlan' : 'trialAddOn';
  let properties = { [keyName]: trialProduct.product_name, buttonText };
  if (customerJourneyStore.trialProduct === PRODUCT_MAP.ADVANCE_PREMIUM) {
    properties = { ...properties, trialPlan: START_TRIAL[PRODUCT_MAP.ADVANCE].product_name };
  }
  trackTrialSuccessButtonClicked({ ...properties, sendIntercomEvent });
}

const emits = defineEmits(['close']);

function close(buttonText, sendIntercomEvent = false) {
  trackButtonClick(buttonText, sendIntercomEvent);
  emits('close');
}

function openChiliPiperForm() {
  chiliPiperStore.openChiliPiperTab(
    TRIAL_BOOK_TRAINING_URL,
    CHILI_PIPER_FORMS_MAP.button.bookATraining.router,
    CHILI_PIPER_FORMS_MAP.button.bookATraining.name,
    'Book a Training',
  );
  close('Book a Training');
}

defineExpose(
  process.env.NODE_ENV === 'test' && {
    openChiliPiperForm,
  },
);
</script>

<template>
  <TrialPopupBase :enable-close="false">
    <template #left>
      <img
        :src="trialPlanImage"
        class="h-full w-full rounded-bl-md rounded-tl-md"
        alt="Descriptive text"
      />
    </template>
    <template #right>
      <div class="relative h-full gap-8 p-10">
        <h4 class="mb-6">Welcome! Let's Start Exploring</h4>
        <div v-if="fromBilling">
          <p class="mb-6">What do you want to do first?</p>
          <div class="mb-8 mt-[4.5rem]">
            <ActionItem
              v-for="({ text, trialRoute }, index) in actionList"
              :key="index"
              :trial-product="product"
              :route="trialRoute"
              :copy="text"
              @action="close(text, true)"
            />
          </div>
          <ActionItem
            copy="Book a training"
            :trial-product="product"
            @action="openChiliPiperForm"
          />
        </div>
        <div v-if="!fromBilling">
          <p class="mb-6">
            Book a training call to learn how leading brands are optimizing their workflows with
            these newly unlocked features.
          </p>
          <div
            class="absolute bottom-0 left-0 m-10 flex flex-row items-center gap-8 justify-self-start"
          >
            <Button no-hover dark-blue bold data-cy="book-a-training" @click="openChiliPiperForm()">
              Book a Training
            </Button>
            <a
              class="text-center text-sm font-medium leading-[--space-18] text-[--action-500]"
              data-cy="explore-on-my-own"
              @click="close('Explore On My Own')"
            >
              Explore On My Own
            </a>
          </div>
        </div>
      </div>
    </template>
  </TrialPopupBase>
</template>
