<template>
  <VDropdown
    placement="bottom"
    :triggers="[]"
    :shown="open"
    class="parent-menu"
    boundary="viewport"
    distance="24"
    :auto-hide="false"
  >
    <div
      class="parent-menu-trigger text-small text-secondary"
      :class="{ active: isActive(platform) }"
      :data-cy="dataCy"
      @click="onParentClick"
    >
      <div>{{ platform.formattedLinkText }}</div>
      <div class="menu-caret">
        <Icon name="caret" :dir="open ? 'left' : 'right'" xxxsmall />
      </div>
    </div>
    <template #popper>
      <ul v-on-click-outside="onClickOutside" class="child-menu">
        <li v-for="(child, index) in avaliableItems" :key="`${index}:${child.to.name}`">
          <router-link
            :to="child.to"
            :class="['child-menu-link', child.to.name, { active: isActive(child) }]"
            :data-cy="`ProductNav-${child.to.name}`"
            @click="onMenuItemClick(child, platform)"
          >
            <Icon :name="child.iconName" small />
            {{ child.label }}
          </router-link>
        </li>
      </ul>
    </template>
  </VDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import Icon from '@/components/foundation/Icon.vue';
import { selectorContainsEventTarget } from '@/utils/dom';

function isMenuRouteMatch(menuItem, currentRouteName) {
  if (menuItem.children) {
    return menuItem.children.some((child) => isMenuRouteMatch(child, currentRouteName));
  }

  const routeName = menuItem?.to?.name;
  if (routeName && currentRouteName) {
    return currentRouteName.startsWith(routeName);
  }

  return false;
}

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Icon,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    platform: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
    avaliableItems() {
      return this.platform.children.filter((item) => item?.visible);
    },
    dataCy() {
      return `ProductNav-${this.platform.name}`;
    },
  },
  methods: {
    onParentClick() {
      this.open = !this.open;
    },
    onMenuItemClick(item, parent) {
      this.mixpanelEvent(item, parent);
      this.open = false;
    },
    mixpanelEvent(platform, platformParent) {
      this.trackingStore.track('Navigation Heading Selected', {
        itemSelected: platformParent.formattedLinkText,
        secondary_item_selected: platform.label,
        page: this.$route.path,
      });
    },
    isActive(menuItem) {
      return isMenuRouteMatch(menuItem, this.$route?.name);
    },
    onClickOutside(e) {
      const clickedOnDropdown = selectorContainsEventTarget(`[data-cy="${this.dataCy}"]`, e);
      if (this.open && !clickedOnDropdown) {
        this.open = false;
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.parent-menu {
  display: flex;
  align-items: center;

  .parent-menu-trigger {
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    .menu-caret {
      margin-left: var(--space-4);
    }
  }
}

.child-menu {
  /* cancel out the left/right padding in popover */
  margin-left: calc(-1 * var(--space-12));
  margin-right: calc(-1 * var(--space-12));

  li {
    padding: var(--space-8) var(--space-24);
    display: flex;

    &:hover {
      background-color: var(--background-300);
    }

    .child-menu-link {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: var(--space-8);
      }
    }
  }
}

.active {
  color: var(--brand-accent);
  font-weight: var(--font-medium);

  .svg-icon {
    fill: var(--brand-accent);
  }
}
</style>
