<template>
  <Avatar v-bind="$attrs" :img-src="brandAvatar" :tooltip="tooltipContent" data-cy="BrandAvatar">
    <span v-if="!brandAvatar" class="initials">{{ brandInitials }}</span>
  </Avatar>
</template>

<script>
import { defineComponent } from 'vue';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import Avatar from '@/components/foundation/Avatar.vue';

/**
 * Wraps an Avatar component for a given brand.
 */
function getBrandInitials(brand) {
  const brandName = brand?.name;
  return (brandName && `${brandName.charAt(0)}${brandName.charAt(1)}`) || '';
}
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Avatar,
  },
  props: {
    brand: {
      type: [Object, Array],
      default: null,
    },
    /**
     * Show brand name tooltip if set to true.  Can be set to a string for function for custom tooltips.
     */
    tooltip: {
      type: [Boolean, String, Function],
      default: false,
    },
  },
  computed: {
    brandInitials() {
      return getBrandInitials(this.brand);
    },
    avatarUrl() {
      return this.brand?.avatarUrl || this.brand?.avatar_url;
    },
    brandAvatar() {
      return this.brand?.avatar || this.avatarUrl;
    },
    brandName() {
      if (this.hideTooltip) {
        return '';
      }
      return this.brand?.name || this.brand?.label || '';
    },

    tooltipContent() {
      if (isFunction(this.tooltip)) {
        return this.tooltip(this.brand);
      }
      if (isString(this.tooltip)) {
        return this.tooltip;
      }
      if (this.tooltip === true) {
        return this.brand?.name || this.brand?.label || '';
      }
      return '';
    },
  },
});

export default comp;
</script>
<style lang="postcss" scoped>
.initials {
  text-transform: uppercase;
}
</style>
