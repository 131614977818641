/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M7.401 15.763L0 9.03l2.04-2.041 5.263 4.594L17.831 0l2.135 1.942z" _fill="#4990E2" fill-rule="nonzero"/>'
  }
})
