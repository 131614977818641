const App = () => import('@/app/reports/App.vue');
const ThreeSixtyMonthly = () => import('@/app/reports/pages/ThreeSixtyMonthly.vue');

const PAGE_LEVEL_TOP = 'Reports';

export default {
  name: 'reports',
  path: 'reports',
  component: App,
  children: [
    {
      name: 'reports.360',
      path: '360',
      component: ThreeSixtyMonthly,
      meta: {
        report: true,
        analytics: `${PAGE_LEVEL_TOP} - 360`,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    hideNav: true,
    hideVisionAi: true,
  },
};
