/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lock-1': {
    width: 24,
    height: 34,
    viewBox: '0 0 24 34',
    data: '<g transform="translate(.333 .333)" _fill="#686a7a" fill-rule="nonzero"><circle pid="0" cx="11.667" cy="24.167" r="2.5"/><path pid="1" d="M20 15V8.333C20 3.738 16.262 0 11.667 0 7.072 0 3.333 3.738 3.333 8.333V15A3.335 3.335 0 000 18.333V30a3.336 3.336 0 003.333 3.333H20A3.336 3.336 0 0023.333 30V18.333A3.335 3.335 0 0020 15zM6.667 8.333c0-2.756 2.243-5 5-5 2.756 0 5 2.244 5 5V15h-10V8.333zM3.333 30V18.333H20L20.003 30H3.333z"/></g>'
  }
})
