export const PINTEREST_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'SAVES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'CLOSEUPS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['PINTEREST'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['PINTEREST'],
      metric: 'SAVES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 6,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['PINTEREST'],
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 6,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['PINTEREST'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 10,
    meta: {
      name: 'Pin Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 11,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
