/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'newChat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.5 5.126c0-1.168.947-2.114 2.115-2.114h5.69v2.114h-5.69v14.27h14.27v-5.894H21v5.894a2.114 2.114 0 01-2.114 2.115H4.615A2.114 2.114 0 012.5 19.397V5.125z" _fill="#686a7a"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.69 5.966l-3.171-3.172a1.056 1.056 0 00-1.495 0l-7.4 7.4a1.06 1.06 0 00-.278.49L7.29 14.913a1.057 1.057 0 001.283 1.282l4.228-1.057c.186-.047.355-.143.49-.28l7.4-7.399a1.054 1.054 0 000-1.493zm-8.687 7.192l-2.235.558.558-2.235L13.6 8.207l1.676 1.677-3.273 3.274zm3.092-6.445l1.676 1.676 1.677-1.676-1.677-1.677-1.676 1.677z" _fill="#686a7a"/>'
  }
})
