import {
  FILTER_DETAILS,
  FILTER_TYPE,
  MANAGE_USAGE_POPUP_CLICK_LOCATION,
  POST_VOLUME_GRAPH_OPTIONS,
  SOCIAL_LISTENING_EVENT_NAMES,
  SOCIAL_LISTENING_REPORT_INTERACTION_TYPES,
  SOCIAL_LISTENING_REPORT_SECTIONS,
  SOCIAL_LISTENING_ROUTE_NAMES,
  ALERT_EVENT_NAMES,
} from '@/app/socialListening/constants';
import {
  formattedMediaSentiment,
  getBaseProperties,
  getBaseAlertProperties,
} from '@/app/socialListening/utils/mixpanel-events-utils';
import { useTrackingStore } from '@/stores/tracking';

/**
 * Mixpanel event fields already added by default:
 * brand_id, brandName, customerStage, impersonating, planType, page_sp_test
 *
 */

export function socialListeningMixpanelEvent(event, properties) {
  useTrackingStore().track(event, properties);
}

export function mediaPopupOpenedEvent({
  mediaOpened,
  mediaType,
  pageOpenedFrom,
  path,
  sourceType,
  sentiment,
}) {
  const topicEvent = [
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS,
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS_TOP_POSTS,
  ].includes(pageOpenedFrom);
  const { keywordApplied, topicName, topicID } = getBaseProperties(topicEvent);

  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.MEDIA_POPUP_OPENED, {
    mediaOpened,
    mediaType,
    pageOpenedFrom,
    path,
    platform: sourceType,
    sourceType,
    keywordApplied,
    sentiment: formattedMediaSentiment(sentiment),
    topicName,
    topicID,
  });
}

export function socialListeningFiltersEvent({
  filterUsed,
  filterValue,
  section = SOCIAL_LISTENING_REPORT_SECTIONS.TOPIC,
  eventFrom,
}) {
  const topicEvent = [
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS,
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS_TOP_POSTS,
  ].includes(eventFrom);
  const { platform, dateScale, topicName, topicID } = getBaseProperties(topicEvent);
  const selectedFilter = FILTER_DETAILS[filterUsed];
  const details = {
    filterUsed: selectedFilter.filter,
    action: selectedFilter?.action,
  };
  switch (filterUsed) {
    case FILTER_TYPE.VISUAL.value:
      details.visualFilterSelection = filterValue?.selection;
      details.filterValue = filterValue?.value;
      break;
    default:
      details.filterValue = topicEvent ? filterValue : filterValue ?? selectedFilter?.default;
      break;
  }

  let payload = {
    section,
    platform,
    dateScale,
    ...details,
  };

  if (topicEvent) {
    payload = { ...payload, topicID, topicName };
  }

  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.SOCIAL_LISTENING_FILTERS, payload);
}

export function socialListeningReportInteractionEvent({
  reportType,
  reportValue,
  reportDetails,
  interactionType,
  path,
}) {
  const details = {};

  switch (reportType) {
    case SOCIAL_LISTENING_REPORT_SECTIONS.SHARE_OF_VOICE_INDUSTRY:
      details.industries = reportValue;
      break;
    case SOCIAL_LISTENING_REPORT_SECTIONS.SHARE_OF_VOICE_COMPETITOR:
      details.competitors = reportValue;
      break;
    case SOCIAL_LISTENING_REPORT_SECTIONS.TOP_KEYWORDS:
      details.keyword = reportValue;
      break;
    case SOCIAL_LISTENING_REPORT_SECTIONS.TOTAL_POSTS:
      details.graphMetric = reportValue;
      if (interactionType === SOCIAL_LISTENING_REPORT_INTERACTION_TYPES.DATA_POINT_CLICKED) {
        details.platform = reportDetails.channels;
        details.sentiment = reportDetails.sentiments;
        details.mediaType = reportDetails.mediaTypes;
      } else if (reportValue === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL.label) {
        details.platform = reportDetails;
      } else if (reportValue === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.label) {
        details.sentiment = reportDetails;
      } else if (reportValue === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE.label) {
        details.mediaType = reportDetails;
      }
      break;
    default:
      break;
  }

  let payload = {
    interactionType,
    path,
    reportType,
    ...details,
  };

  if (path !== SOCIAL_LISTENING_ROUTE_NAMES.TRENDS) {
    const { topicName, topicID } = getBaseProperties(true);
    payload = { ...payload, topicName, topicID };
  }

  useTrackingStore().track(
    SOCIAL_LISTENING_EVENT_NAMES.SOCIAL_LISTENING_REPORT_INTERACTION,
    payload,
  );
}

export function visualTrendsPostsClickedEvent({ clickedFrom, path }) {
  const topicEvent = clickedFrom === SOCIAL_LISTENING_ROUTE_NAMES.TOPICS;
  const { sentiment, keywordApplied, topicName, topicID, mediaType } =
    getBaseProperties(topicEvent);

  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.VISUAL_TRENDS_POST_CLICKED, {
    path,
    sentiment,
    keywordApplied,
    mediaType,
    topicName,
    topicID,
  });
}

export function topicCreationStartedEvent({
  topicInstanceID = undefined,
  pageAccess = true,
  fromTeaser = false,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_CREATION_STARTED, {
    topicInstanceID,
    pageAccess,
    fromTeaser,
  });
}

export function topicDataSourceSelectedEvent({ dataSource, topicInstanceID = undefined }) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_DATA_SOURCE_SELECTED, {
    dataSource,
    topicInstanceID,
  });
}

export function topicCompetitorsAddedEvent({
  numberOfCompetitors,
  competitorNames,
  topicInstanceID = undefined,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_COMPETITORS_ADDED, {
    numberOfCompetitors,
    competitorNames,
    topicInstanceID,
  });
}

export function topicKeywordsAddedEvent({
  inclusions,
  exclusions,
  numberOfKeywordsIncluded,
  numberOfKeywordsExcluded,
  topicInstanceID = undefined,
  keywordsString,
  listInclude,
  listExclude,
  keywordGroups,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_KEYWORDS_ADDED, {
    inclusions,
    exclusions,
    numberOfKeywordsIncluded,
    numberOfKeywordsExcluded,
    topicInstanceID,
    keywordsString,
    listInclude,
    listExclude,
    keywordGroups,
  });
}

export function topicCreatedEvent({
  topicInstanceID = undefined,
  dataSource,
  inclusions,
  exclusions,
  numberOfKeywordsIncluded,
  numberOfKeywordsExcluded,
  topicName,
  topicID,
  webSource,
  keywordsString,
  listInclude,
  listExclude,
  keywordGroups,
  fromTeaser,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_CREATED, {
    topicInstanceID,
    dataSource,
    inclusions,
    exclusions,
    numberOfKeywordsIncluded,
    numberOfKeywordsExcluded,
    topicName,
    topicID,
    webSource,
    keywordsString,
    listInclude,
    listExclude,
    keywordGroups,
    fromTeaser,
  });
}

export function topicCreationFailedEvent({ topicInstanceID = undefined, errorType }) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_CREATION_FAILED, {
    topicInstanceID,
    errorType,
  });
}

export function topicDeletedEvent({ topicID, topicName }) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_DELETED, { topicID, topicName });
}

export function topicSettingsChangedEvent({
  topicID,
  topicName,
  editContext,
  pageAccess = true,
  fromTeaser = false,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_SETTINGS_CHANGED, {
    topicID,
    topicName,
    editContext,
    pageAccess,
    fromTeaser,
  });
}

export function manageUsageCtaEvent(buttonName) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.MANAGE_USAGE_UPGRADE_LIMITS, {
    buttonName,
  });
}

export function manageUseClickedEvent(
  topicID,
  topicName,
  clickLocation = MANAGE_USAGE_POPUP_CLICK_LOCATION.NAV_BAR,
) {
  let payload = {
    clickLocation,
  };
  if (topicName && topicID) {
    payload = { ...payload, topicID, topicName };
  }
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.MANAGE_USE_CLICKED, payload);
}

export function topicKeywordsEditedEvent({
  topicName,
  topicID,
  change,
  totalKeywords,
  keywordsString,
  listInclude,
  listExclude,
  keywordGroups,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_KEYWORDS_EDITED, {
    topicName,
    topicID,
    change,
    totalKeywords,
    keywordsString,
    listInclude,
    listExclude,
    keywordGroups,
  });
}

export function topicViewed({
  topicID,
  topicName,
  dataSource,
  visualFilter,
  inclusions,
  exclusions,
  numberOfKeywordsIncluded,
  numberOfKeywordsExcluded,
  webSource,
  pageOpenedFrom,
  keywordsString,
  listInclude,
  listExclude,
  keywordGroups,
}) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.TOPIC_VIEWED, {
    topicID,
    topicName,
    dataSource,
    visualFilter,
    inclusions,
    exclusions,
    numberOfKeywordsIncluded,
    numberOfKeywordsExcluded,
    webSource,
    pageOpenedFrom,
    keywordsString,
    listInclude,
    listExclude,
    keywordGroups,
  });
}

export function socialListeningCardClickedEvent({
  cardType,
  pageOpenedFrom,
  path,
  cardDetails = {},
}) {
  const topicEvent = [
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS,
    SOCIAL_LISTENING_ROUTE_NAMES.TOPICS_TOP_POSTS,
    SOCIAL_LISTENING_ROUTE_NAMES.WEB_RESULTS,
  ].includes(pageOpenedFrom);
  const { topicName, topicID } = getBaseProperties(topicEvent);
  const topicDetails = topicEvent ? { topicName, topicID } : {};
  if (cardDetails?.sentiment) {
    cardDetails.sentiment = formattedMediaSentiment(cardDetails.sentiment);
  }

  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.SOCIAL_LISTENING_CARD_CLICKED, {
    cardType,
    path,
    pageOpenedFrom,
    ...topicDetails,
    ...cardDetails,
  });
}

export function alertSettingsChanged({ editContext }) {
  useTrackingStore().track(ALERT_EVENT_NAMES.SOCIAL_LISTENING_ALERT_SETTINGS_CHANGED, {
    editContext,
    ...getBaseAlertProperties(),
  });
}

export function alertActivated({ numberOfRecipients, recipientEmails, recipientIDs, sensitivity }) {
  useTrackingStore().track(ALERT_EVENT_NAMES.SOCIAL_LISTENING_TOPIC_ALERT_ACTIVATED, {
    numberOfRecipients,
    recipientEmails,
    recipientIDs,
    sensitivity,
    ...getBaseAlertProperties(),
  });
}

export function alertCreated({ numberOfRecipients, recipientEmails, recipientIDs, sensitivity }) {
  useTrackingStore().track(ALERT_EVENT_NAMES.SOCIAL_LISTENING_TOPIC_ALERT_CREATED, {
    numberOfRecipients,
    recipientEmails,
    recipientIDs,
    sensitivity,
    ...getBaseAlertProperties(),
  });
}

export function alertDeactivated({
  numberOfRecipients,
  recipientEmails,
  recipientIDs,
  sensitivity,
}) {
  useTrackingStore().track(ALERT_EVENT_NAMES.SOCIAL_LISTENING_TOPIC_ALERT_DEACTIVATED, {
    numberOfRecipients,
    recipientEmails,
    recipientIDs,
    sensitivity,
    ...getBaseAlertProperties(),
  });
}

export function socialListeningViewedReportInApp({ topicID, topicName, pageDirectedTo }) {
  useTrackingStore().track(SOCIAL_LISTENING_EVENT_NAMES.SOCIAL_LISTENING_VIEWED_REPORT_IN_APP, {
    topicID,
    topicName,
    pageDirectedTo,
  });
}
