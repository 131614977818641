import { ref } from 'vue';
import { defineStore } from 'pinia';
import uniqBy from 'lodash/uniqBy';
import union from 'lodash/union';
import * as api from '@/apis/instagram';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';
import { useAuthStore } from './auth';
import { useSocketStore } from './socket';

export const useInstagramRelationshipsStore = defineStore('instagramRelationships', () => {
  const authStore = useAuthStore();
  const socketStore = useSocketStore();

  const relationshipListTags = ref([]);
  const relationshipList = ref([]);
  const relationshipListNextUrl = ref(null);
  const brandId = ref(null);
  const updateRelationshipStatus = ref(null);
  const relationship = ref(null);
  const mediaRelationship = ref(null);
  const deleteRelationshipStatus = ref(null);
  const brandRelationshipTags = ref([]);
  const showRelationshipSuccess = ref(false);
  const hasRelationships = ref(false);

  const pending = ref({
    relationship: false,
    createRelationshipStatus: false,
    updateRelationshipStatus: false,
    createOrUpdateInfluencerStatus: false,
    deleteRelationshipTagStatus: false,
    relationshipList: 0,
    instagramRiqPosts: false,
    instagramRiqStoryPosts: false,
    instagramPiqPosts: false,
    findRelationshipByInfluencerIdStatus: false,
  });

  const error = ref({
    createRelationshipStatus: null,
    updateRelationshipStatus: null,
    addRelationshipTagStatus: null,
    deleteRelationshipStatus: null,
    deleteRelationshipTagStatus: null,
  });

  const abortControllers = {
    getRelationshipList: ref(null),
    getRelationshipRIQPosts: ref(null),
    getRelationshipRIQStoryPosts: ref(null),
    getRelationshipPIQPosts: ref(null),
  };

  function mergeFreshRelationshipData(relationshipIn) {
    relationship.value = {
      ...relationshipIn,
      riqPostList: [],
      riqStoryPostList: [],
      piqPostList: [],
      riqAmountOfPosts: null,
      riqStoryAmountOfPosts: null,
      piqAmountOfPosts: null,
    };
  }

  function updateRelationshipsTagList() {
    relationshipListTags.value = uniqBy(union(...relationshipList.value.map((r) => r.tags)), 'id');
  }

  function updateRelationshipInRelationshipList() {
    const targetRelationshipIndex = relationshipList.value.findIndex(
      (r) => r.id === relationship?.value?.id,
    );
    if (targetRelationshipIndex > -1) {
      relationshipList.value.splice(targetRelationshipIndex, 1, relationship.value);
      updateRelationshipsTagList();
    }
  }

  // Removes deleted tag from all relationships in list optimistically
  function removeDeletedTagFromState(tagId) {
    relationshipList.value.forEach(({ tags }) => {
      const tagIdx = tags.findIndex(({ id }) => id === tagId);
      if (tagIdx >= 0) {
        tags.splice(tagIdx, 1);
      }
    });
  }

  function clearRelationshipList() {
    relationshipList.value = [];
    relationshipListTags.value = [];
    relationshipListNextUrl.value = null;
  }

  function clearRelationship() {
    relationship.value = null;
  }

  function clearBrandRelationshipTags() {
    brandRelationshipTags.value = [];
  }

  function showRelationshipSuccessPopup(flag) {
    showRelationshipSuccess.value = flag;
  }

  async function getRelationshipList({
    url,
    keyword,
    sort,
    publishedStartDate,
    publishedEndDate,
    isBusiness,
    type,
    onlyPostsWithType,
    tagIds,
    tagFilterType,
    isFbConnected,
    acceptanceStatuses,
  }) {
    const brandIdIn = authStore.currentBrand?.id;
    pending.value.relationshipList += 1;

    try {
      const signal = refreshAbortController(abortControllers.getRelationshipList);

      const response = await api.getRelationshipList(
        {
          brandId: brandIdIn,
          url,
          keyword,
          sort,
          publishedStartDate,
          publishedEndDate,
          isBusiness,
          type,
          onlyPostsWithType,
          tagIds,
          tagFilterType,
          isFbConnected,
          acceptanceStatuses,
        },
        { signal },
      );
      const payload = response?.data;

      if (payload) {
        if (brandIdIn !== brandId.value) {
          relationshipList.value = [];
          brandId.value = brandIdIn;
        }
        if (payload.data) {
          relationshipList.value = [...relationshipList.value, ...payload.data];
          relationshipListNextUrl.value = payload.paging.next;
          updateRelationshipsTagList();
        }
      }
    } catch (e) {
      handleCancelError(e);
    } finally {
      pending.value.relationshipList -= 1;
    }
  }

  async function exportRelationshipsOverviewCSV({
    keyword,
    sort,
    publishedStartDate,
    publishedEndDate,
    isBusiness,
    type,
    onlyPostsWithType,
    tagIds,
    tagFilterType,
    isFbConnected,
    acceptanceStatuses,
    csvType,
  }) {
    const socketId = socketStore.id;

    return api.exportRelationshipOverviewCSV({
      brandId: authStore.currentBrand?.id,
      keyword,
      sort,
      publishedStartDate,
      publishedEndDate,
      isBusiness,
      type,
      onlyPostsWithType,
      tagIds,
      tagFilterType,
      isFbConnected,
      acceptanceStatuses,
      csvType,
      socketId,
    });
  }

  async function findRelationshipByHandle({ brandId: brandIdIn, handle }) {
    const response = await api.findRelationshipByHandle({ brandId: brandIdIn, handle });
    const payload = response?.data;

    if (payload.data && payload.data.length > 0) {
      [relationship.value] = payload.data;
    } else {
      relationship.value = null;
    }
  }

  async function findMediaRelationshipByHandle({ brandId: brandIdIn, handle }) {
    const response = await api.findMediaRelationshipByHandle({ brandId: brandIdIn, handle });
    const payload = response?.data;

    if (payload.data && payload.data.length > 0) {
      [mediaRelationship.value] = payload.data;
    } else {
      mediaRelationship.value = null;
    }
  }

  async function findRelationshipByInfluencerId({
    brandId: brandIdIn,
    influencerId,
    connectInstagramInfluencerToken,
  }) {
    pending.value.findRelationshipByInfluencerIdStatus = true;
    try {
      const response = await api.findRelationshipByInfluencerId({
        brandId: brandIdIn,
        influencerId,
        connectInstagramInfluencerToken,
      });
      const payload = response?.data;

      if (payload.data && payload.data.length > 0) {
        [relationship.value] = payload.data;
      }
    } finally {
      pending.value.findRelationshipByInfluencerIdStatus = false;
    }
  }

  async function getRelationship({
    brandId: brandIdIn,
    relationshipId,
    type,
    onlyVideoPosts,
    publishedStartDate,
    publishedEndDate,
  }) {
    if (relationship.value) {
      relationship.value.initialized = true;
    }

    pending.value.relationship = true;
    try {
      const response = await api.getRelationship({
        brandId: brandIdIn,
        relationshipId,
        type,
        onlyVideoPosts,
        publishedStartDate,
        publishedEndDate,
      });
      const payload = response?.data;

      if (relationship.value) {
        const pType = type?.toLowerCase();
        Object.entries(payload).forEach(([key, value]) => {
          const isNonStatsKey = !key.startsWith('riq') && !key.startsWith('piq');
          if (key.startsWith(pType) || isNonStatsKey) {
            [relationship.value[key]] = [value];
          }
        });
      } else {
        mergeFreshRelationshipData(payload);
      }
    } finally {
      pending.value.relationship = false;
    }
  }

  async function createRelationship({ brandId: brandIdIn, handle }) {
    error.value.createRelationshipStatus = null;
    pending.value.createRelationshipStatus = true;
    try {
      const response = await api.createRelationship({ brandId: brandIdIn, handle });
      const payload = response?.data;

      mergeFreshRelationshipData(payload);
    } catch (err) {
      error.value.createRelationshipStatus = err;
      throw err;
    } finally {
      pending.value.createRelationshipStatus = false;
    }
  }

  async function updateRelationship({
    brandId: brandIdIn,
    relationshipId,
    email,
    invitationStatus,
    emailContent,
    notes,
    hasPiq,
    influencerId,
    connectInstagramInfluencerToken,
  }) {
    error.value.updateRelationshipStatus = null;
    pending.value.updateRelationshipStatus = true;
    try {
      const response = await api.updateRelationship({
        brandId: brandIdIn,
        relationshipId,
        email,
        invitationStatus,
        emailContent,
        notes,
        hasPiq,
        influencerId,
        connectInstagramInfluencerToken,
      });
      const payload = response?.data;

      relationship.value = { ...relationship.value, ...payload };
      updateRelationshipInRelationshipList();
    } catch (e) {
      error.value.updateRelationshipStatus = e;
      throw e;
    } finally {
      pending.value.updateRelationshipStatus = false;
    }
  }

  async function deleteRelationship({ brandId: brandIdIn, relationshipId }) {
    error.value.deleteRelationshipStatus = null;
    pending.value.deleteRelationshipStatus = true;
    try {
      const response = await api.deleteRelationship({ brandId: brandIdIn, relationshipId });
      const payload = response?.data;

      relationshipList.value = relationshipList.value.filter(
        (r) => Number(r.id) !== Number(payload.relationshipId),
      );
      updateRelationshipsTagList();
    } catch (e) {
      error.value.deleteRelationshipStatus = e;
      throw e;
    } finally {
      pending.value.deleteRelationshipStatus = false;
    }
  }

  async function getRelationshipRIQPosts({
    brandId: brandIdIn,
    relationshipId,
    onlyVideoPosts,
    publishedStartDate,
    publishedEndDate,
    sort,
    url,
  }) {
    pending.value.instagramRiqPosts = true;
    try {
      const signal = refreshAbortController(abortControllers.getRelationshipRIQPosts);

      const response = await api.getRelationshipPosts(
        {
          brandId: brandIdIn,
          relationshipId,
          type: 'RIQ',
          onlyVideoPosts,
          publishedStartDate,
          publishedEndDate,
          sort,
          url,
        },
        { signal },
      );
      const payload = response?.data;
      if (relationship.value) {
        if (payload.data) {
          relationship.value.riqPostList = [...relationship.value.riqPostList, ...payload.data];
          relationship.value.riqPostsNextUrl = payload.paging.next;
          relationship.value.riqAmountOfPosts = relationship.value.riqTotalPosts;
        }

        if (payload?.data?.length === 0) {
          relationship.value.riqAmountOfPosts = 0;
        }
      }
    } catch (e) {
      handleCancelError(e);
    } finally {
      pending.value.instagramRiqPosts = false;
    }
  }

  async function getRelationshipRIQStoryPosts({
    brandId: brandIdIn,
    relationshipId,
    type,
    isStories,
    publishedStartDate,
    publishedEndDate,
    sourceInstagramId,
    url,
    sort,
  }) {
    pending.value.instagramRiqStoryPosts = true;
    try {
      const signal = refreshAbortController(abortControllers.getRelationshipRIQStoryPosts);

      const response = await api.getRelationshipRIQStoryPosts(
        {
          brandId: brandIdIn,
          relationshipId,
          type,
          isStories,
          publishedStartDate,
          publishedEndDate,
          sourceInstagramId,
          url,
          sort,
        },
        { signal },
      );
      const payload = response?.data;

      if (relationship.value) {
        if (payload.data) {
          relationship.value.riqStoryPostList = [
            ...relationship.value.riqStoryPostList,
            ...payload.data,
          ];
          relationship.value.riqStoryPostsNextUrl = payload.paging.next;
          relationship.value.riqStoryAmountOfPosts = payload.paging.count;
        }

        if (payload?.data?.length === 0) {
          relationship.value.riqStoryAmountOfPosts = 0;
        }
      }
    } catch (e) {
      handleCancelError(e);
    } finally {
      pending.value.instagramRiqStoryPosts = false;
    }
  }

  async function exportRelationshipRIQStoryPostsCSV({
    brandId: brandIdIn,
    relationshipId,
    type,
    socketId,
    sort,
    sourceInstagramId,
    publishedStartDate,
    publishedEndDate,
  }) {
    return api.exportRelationshipRIQStoryPostsCSV({
      brandId: brandIdIn,
      relationshipId,
      type,
      socketId,
      sort,
      sourceInstagramId,
      publishedStartDate,
      publishedEndDate,
    });
  }

  async function getRelationshipPIQPosts({
    brandId: brandIdIn,
    relationshipId,
    isStories,
    publishedStartDate,
    publishedEndDate,
    sourceInstagramId,
    url,
    sort,
  }) {
    pending.value.instagramPiqPosts = true;
    try {
      const signal = refreshAbortController(abortControllers.getRelationshipPIQPosts);

      const response = await api.getRelationshipPosts(
        {
          brandId: brandIdIn,
          relationshipId,
          type: 'PIQ',
          isStories,
          publishedStartDate,
          publishedEndDate,
          sourceInstagramId,
          url,
          sort,
        },
        { signal },
      );
      const payload = response?.data;

      if (relationship.value) {
        if (payload.data) {
          relationship.value.piqPostList = [...relationship.value.piqPostList, ...payload.data];
          relationship.value.piqPostsNextUrl = payload.paging.next;
          relationship.value.piqAmountOfPosts = relationship.value.piqTotalPosts;
        }

        if (payload?.data?.length === 0) {
          relationship.value.piqStoryAmountOfPosts = 0;
        }
      }
    } catch (e) {
      handleCancelError(e);
    } finally {
      pending.value.instagramPiqPosts = false;
    }
  }

  async function exportRelationshipPostsCSV({
    brandId: brandIdIn,
    relationshipId,
    type,
    socketId,
    sort,
    publishedStartDate,
    publishedEndDate,
  }) {
    await api.exportRelationshipPostsCSV({
      brandId: brandIdIn,
      relationshipId,
      type,
      socketId,
      sort,
      publishedStartDate,
      publishedEndDate,
    });
  }

  async function addRelationshipTag({ brandId: brandIdIn, relationshipId, tagId, tagName }) {
    error.value.addRelationshipTagStatus = null;
    try {
      const response = await api.addRelationshipTag({
        brandId: brandIdIn,
        relationshipId,
        tagId,
        tagName,
      });
      const payload = response?.data;

      if (payload) {
        relationship.value.tags.push(payload);
        updateRelationshipInRelationshipList();
        // if this is a brand new tag, add it to the brand tag list
        if (tagName) {
          brandRelationshipTags.value.push(payload);
        }
      }
    } catch (e) {
      error.value.addRelationshipTagStatus = e.response?.data?.description || e;
      throw e;
    }
  }

  async function getBrandRelationshipTags() {
    const brandIdIn = authStore.currentBrand?.id;
    const response = await api.getBrandRelationshipTags({ brandId: brandIdIn });

    const payload = response?.data;

    if (payload) {
      brandRelationshipTags.value = payload;
    }

    return response;
  }

  async function deleteRelationshipTag({ brandId: brandIdIn, relationshipId, tagId }) {
    error.value.deleteRelationshipTagStatus = null;
    pending.value.deleteRelationshipTagStatus = true;
    try {
      await api.deleteRelationshipTag({ brandId: brandIdIn, relationshipId, tagId });

      if (relationshipId) {
        relationship.value.tags = relationship.value.tags.filter((t) => t.id !== tagId);
        getBrandRelationshipTags();
        updateRelationshipInRelationshipList();
      } else {
        removeDeletedTagFromState(tagId);
      }
    } catch (e) {
      error.value.deleteRelationshipTagStatus = e;
      throw e;
    } finally {
      pending.value.deleteRelationshipTagStatus = false;
    }
  }

  async function createOrUpdateInfluencer({ brandId: brandIdIn, influencerInstagramId }) {
    pending.value.createOrUpdateInfluencerStatus = true;
    try {
      return api.createOrUpdateInfluencer({ brandId: brandIdIn, influencerInstagramId });
    } finally {
      pending.value.createOrUpdateInfluencerStatus = false;
    }
  }

  async function addMentionUGC({ brandId: brandIdIn, facebookMediaId }) {
    return api.addMentionUGC({ brandId: brandIdIn, facebookMediaId });
  }

  return {
    relationshipListTags,
    relationshipList,
    relationshipListNextUrl,
    brandId,
    updateRelationshipStatus,
    relationship,
    mediaRelationship,
    deleteRelationshipStatus,
    brandRelationshipTags,
    showRelationshipSuccess,
    pending,
    error,
    mergeFreshRelationshipData,
    updateRelationshipInRelationshipList,
    clearRelationshipList,
    clearRelationship,
    clearBrandRelationshipTags,
    showRelationshipSuccessPopup,
    getRelationshipList,
    exportRelationshipsOverviewCSV,
    findRelationshipByHandle,
    findMediaRelationshipByHandle,
    findRelationshipByInfluencerId,
    getRelationship,
    createRelationship,
    updateRelationship,
    deleteRelationship,
    getRelationshipRIQPosts,
    getRelationshipRIQStoryPosts,
    exportRelationshipRIQStoryPostsCSV,
    getRelationshipPIQPosts,
    exportRelationshipPostsCSV,
    addRelationshipTag,
    getBrandRelationshipTags,
    deleteRelationshipTag,
    createOrUpdateInfluencer,
    addMentionUGC,
    abortControllers,
    hasRelationships,
  };
});
