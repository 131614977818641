/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magic-wand-2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#767676"><path pid="0" d="M3.293 19.212L13.9 8.606l1.414 1.414L4.707 20.626zM15.314 7.192l2.829-2.829 1.414 1.414-2.83 2.83zM16.796 12.73l.518-1.932 3.864 1.035-.517 1.932zM14.297 18.714l-1.035-3.865 1.932-.517 1.035 3.865zM12.088 2.741l1.035 3.864-1.932.517-1.035-3.863zM5.723 7.691l3.865 1.036-.518 1.93-3.864-1.035z"/></g>'
  }
})
