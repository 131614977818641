export const GRAPH_STATS = Object.freeze({
  CURRENT: {
    value: 'CURRENT',
  },
  SUM: {
    value: 'SUM',
  },
  MEAN: {
    value: 'MEAN',
  },
  MEAN_BY_DAY: {
    value: 'MEAN_BY_DAY',
  },
});
