/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'megaphone': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<defs><path pid="0" d="M18 0v1.197L2 4.754V4H0v8h2v-.754l1 .223V15c0 .459.313.858.757.971l4 1a1 1 0 001.042-.371l2.471-3.293L18 14.801V16h2V0h-2zM7.598 14.869L5 14.219v-2.307l4.127.918-1.529 2.039zM2 9.197V6.803l16-3.557v9.508L2 9.197z" id="svgicon_megaphone_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_megaphone_b" _fill="#fff"><use xlink:href="#svgicon_megaphone_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_megaphone_a"/></g>'
  }
})
