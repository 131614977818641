<template>
  <main class="insights">
    <header>
      <span>
        <Icon v-if="isDynamicAd" name="megaphoneDynamic" />
        <Icon v-else name="megaphone" />
        Ad Insights
      </span>
    </header>

    <div class="insights-list">
      <CircularLoader v-if="!mediaItem.insights" />
      <div v-if="mediaItem.adsInfo" class="insights-desc">
        <div class="section-container">
          <div class="section-header">
            <header>
              <span>
                Campaign
                <div
                  v-tooltip="setStatusTooltip(mediaItem.campaignEffectiveStatus)"
                  :class="[isCampaignActive ? 'active-badge' : 'inactive-badge']"
                >
                  {{ simplifyStatus(mediaItem.campaignEffectiveStatus) }}
                </div>
              </span>
            </header>
            <p class="campaign-title">
              {{ mediaItem.adsInfo.campaignName }}
            </p>
          </div>
        </div>
        <div class="section-container">
          <div class="section-header" @click.prevent="adSetOpen = !adSetOpen">
            <header>
              <span>
                Ad Set
                <div
                  v-tooltip="setStatusTooltip(mediaItem.adSetEffectiveStatus)"
                  :class="[isAdSetActive ? 'active-badge' : 'inactive-badge']"
                >
                  {{ simplifyStatus(mediaItem.adSetEffectiveStatus) }}
                </div>
              </span>
              <Icon :class="['dropdown-toggle', { open: adSetOpen }]" name="caret" xxsmall />
            </header>
            <p
              v-tooltip="setTooltipContent(mediaItem.adsInfo.adSet, !adSetOpen)"
              :class="[{ 'full-text': adSetOpen }, { hoverable: !adSetOpen }]"
            >
              {{ mediaItem.adsInfo.adSet }}
            </p>
          </div>
          <div :class="['section-content', { open: adSetOpen }]">
            <ul>
              <li v-for="(value, key) in adDates" :key="key" class="ad-date pair-row">
                <span>
                  {{ formatKey(key) }}
                  <InfoTooltip :tooltip="tooltips[key]" xsmall />
                </span>
                <span>
                  <p class="ads-value">{{ value }}</p>
                </span>
              </li>
              <li v-for="item in adSetInfo" :key="item.label" class="pair-row">
                <span class="category">{{ formatKey(item.label) }}</span>
                <span>
                  <p class="ads-value">{{ formatValue(item.label, item.value) }}</p>
                </span>
              </li>
            </ul>
            <span>Location</span>
            <p class="full-text">
              {{ formatValue('location', mediaItem.adsInfo.audienceLocations) }}
            </p>
          </div>
        </div>
        <div :class="['section-container', { open: adOpen }]">
          <div class="section-header" @click.prevent="adOpen = !adOpen">
            <header>
              <span>
                Ad
                <div
                  v-tooltip="setStatusTooltip(adStatus)"
                  :class="[isAdActive ? 'active-badge' : 'inactive-badge']"
                >
                  {{ simplifyStatus(adStatus) }}
                </div>
              </span>
              <Icon :class="['dropdown-toggle', { open: adOpen }]" name="caret" xxsmall />
            </header>
            <p
              v-tooltip="setTooltipContent(mediaItem.adsInfo.adName, !adOpen)"
              :class="[{ 'full-text': adOpen }, { hoverable: !adOpen }]"
            >
              {{ mediaItem.adsInfo.adName }}
            </p>
          </div>
          <div :class="['section-content', { open: adOpen }]">
            <div v-for="item in adInfo" :key="item.label">
              <span>{{ formatKey(item.label) }}</span>
              <p class="full-text">{{ item.value || '-' }}</p>
            </div>
          </div>
        </div>

        <header>
          <span>Placements</span>
        </header>

        <div class="section-container placement-container">
          <div class="section-header" @click.prevent="setToggle('total')">
            <header>
              <span>
                Total
                <InfoTooltip :tooltip="tooltips['total']" xsmall />
              </span>
              <Icon
                :class="['dropdown-toggle', { open: selectedPublishersInsights === 'total' }]"
                name="caret"
                xxsmall
              />
            </header>
          </div>
          <div :class="['section-content', { open: selectedPublishersInsights === 'total' }]">
            <ul v-if="mediaItem.insights" class="insights-stats">
              <li>
                <Select
                  :date-range-value="dateRange"
                  :options="dateOptions"
                  type="calendar"
                  placeholder="All Time"
                  class="select-control"
                  @selected="dateChanged"
                />
              </li>

              <li v-for="(value, key) in mediaItem.insights" :key="key" class="pair-row">
                <span class="stat-label">
                  {{ formatKey(key) }}
                  <InfoTooltip v-if="fieldsNeedTooltip(key)" :tooltip="tooltips[key]" xsmall />
                </span>
                <span class="stat">
                  {{ formatValue(key, value) }}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-for="(value, key) in mediaItem.publishersInsights"
          :key="key"
          class="section-container placement-container"
        >
          <div class="section-header" @click.prevent="setToggle(key)">
            <header>
              <span>
                {{ formatPlacementTitle(key) }}
              </span>
              <Icon
                :class="['dropdown-toggle', { open: selectedPublishersInsights === key }]"
                name="caret"
                xxsmall
              />
            </header>
          </div>
          <div :class="['section-content', { open: selectedPublishersInsights === key }]">
            <ul v-if="mediaItem.publishersInsights" class="insights-stats">
              <li>
                <Select
                  :options="dateOptionsDisabled"
                  type="calendar"
                  placeholder="All Time"
                  class="select-control"
                  disabled
                />
                <span class="select-tooltip">
                  <InfoTooltip :tooltip="tooltips['apiLimitationsMessage']" small />
                </span>
              </li>

              <li v-for="(v, k) in mediaItem.publishersInsights[key]" :key="k" class="pair-row">
                <span class="stat-label">
                  {{ formatKey(k) }}
                  <InfoTooltip v-if="fieldsNeedTooltip(key)" :tooltip="tooltips[k]" xsmall />
                </span>
                <span class="stat">
                  {{ formatValue(k, v) }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <a
        v-if="mediaItem.adsManagerUrl"
        :href="mediaItem.adsManagerUrl"
        target="_blank"
        rel="noopener"
      >
        <Button light>See on Ads Manager</Button>
      </a>
    </div>
  </main>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { toolTips } from '@/config';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import Select from '@/components/Select.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'FBAdsInsights',
  components: {
    Button,
    Icon,
    InfoTooltip,
    CircularLoader,
    Select,
  },
  props: {
    mediaItem: { type: Object, default: null },
  },
  emits: ['dateRangeUpdated'],
  data() {
    return {
      adSetOpen: false,
      adOpen: false,
      selectedPublishersInsights: 'total',
      tooltips: toolTips.facebookAdsInsights,
      dateRange: this.mediaItem.dateRange,
      dateOptions: [
        { label: 'All Time', value: null },
        { label: 'Last 4 Weeks', value: 28 },
        { label: 'Past 7 Days', value: 7 },
        { label: 'Past 3 Days', value: 3 },
        { label: 'Past 24 Hours', value: 1 },
      ],
      dateOptionsDisabled: [{ label: 'All Time', value: null }],
    };
  },
  computed: {
    isDynamicAd() {
      return this.mediaItem.isDynamic;
    },
    isAdActive() {
      return this.mediaItem.adEffectiveStatus === 'ACTIVE' && !this.adFinished;
    },
    isAdSetActive() {
      return this.mediaItem.adSetEffectiveStatus === 'ACTIVE';
    },
    isCampaignActive() {
      return this.mediaItem.campaignEffectiveStatus === 'ACTIVE';
    },
    adFinished() {
      // if the campaign or ad set ended in the past, mark ad as inactive
      const currentDate = new Date();
      const { adSetEndTime, campaignStopTime } = this.mediaItem.adsInfo;

      return (
        (adSetEndTime && dayjs(adSetEndTime).isBefore(currentDate)) ||
        (campaignStopTime && dayjs(campaignStopTime).isBefore(currentDate))
      );
    },
    adStatus() {
      return this.adFinished ? 'COMPLETED' : this.mediaItem.adEffectiveStatus;
    },
    adDates() {
      const { startDate, endDate } = this.mediaItem.adsInfo;
      return { startDate: startDate.toLocaleString(), endDate: endDate.toLocaleString() };
    },
    adSetInfo() {
      const { adsInfo } = this.mediaItem;
      return [
        { label: 'objective', value: adsInfo.objective },
        { label: 'age', value: { min: adsInfo.audienceAgeMin, max: adsInfo.audienceAgeMax } },
        { label: 'gender', value: adsInfo.audienceGenders },
      ];
    },
    adInfo() {
      const { adsInfo, link, title, caption } = this.mediaItem;
      return [
        { label: 'placements', value: adsInfo.distributionChannels },
        { label: 'creation date', value: adsInfo.adCreatedTime },
        { label: 'destination', value: link },
        { label: 'title', value: title },
        { label: 'caption', value: caption },
      ];
    },
  },
  methods: {
    // Ensures only one insights section is shown at a time
    setToggle(insight) {
      if (this.selectedPublishersInsights === insight) {
        this.selectedPublishersInsights = null;
      } else {
        this.selectedPublishersInsights = insight;
      }
    },
    formatPlacementTitle(key) {
      return startCase(key.replace('_', ' '));
    },
    formatKey(key) {
      if (key === 'cpc') {
        return 'Avg. CPC';
      }
      if (key === 'ctr') {
        return 'CTR';
      }
      if (key === 'roas') {
        return 'ROAS';
      }
      return startCase(key);
    },
    formatValue(key, value) {
      if (value === null || value === undefined) {
        if (key === 'gender' || key === 'location') {
          return 'Unspecified';
        }
        return '-';
      }
      if (typeof value === 'string' && ['reach', 'impressions', 'link_clicks'].includes(key)) {
        return Number(value).toLocaleString();
      }
      if (key === 'ctr') {
        return `${(value * 100).toFixed(2)}%`;
      }
      if (key === 'age') {
        return `${value.min} - ${value.max === 200 ? '65+' : value.max}`;
      }
      if (key === 'gender') {
        const capitalized = startCase(value);
        return capitalized.replace(/[ ]+/g, ', ');
      }
      if (key === 'location') {
        return value.split(', ').map(startCase).join(', ');
      }
      if (key === 'roas' || key === 'spend') {
        let val = value;
        if (typeof val === 'string') {
          val = parseFloat(val);
        }
        return `$${val.toFixed(2)}`;
      }
      if (key !== 'cpc' && typeof value === 'string') {
        return startCase(value.toLowerCase());
      }
      return value.toLocaleString();
    },
    fieldsNeedTooltip(key) {
      return ['cpc', 'ctr'].indexOf(key) > -1;
    },
    setTooltipContent(content, showTooltip) {
      const tooltipText = showTooltip ? content : '';
      return { content: tooltipText, theme: 'dh-tooltip-medium', delay: { show: 300, hide: 0 } };
    },
    setStatusTooltip(content) {
      return content === undefined || content === 'ACTIVE' ? '' : startCase(content.toLowerCase());
    },
    simplifyStatus(status) {
      return status === 'ACTIVE' ? status : 'INACTIVE';
    },
    dateChanged(option) {
      const rangeWasEmpty = !this.dateRange;
      if (!option) {
        this.dateRange = null;
      } else if (option.length === 2) {
        this.dateRange = option;
      } else {
        this.dateRange = [this.dateOptions[option].value];
      }
      if (this.dateRange !== null || !rangeWasEmpty) {
        // Only emit empty date range when switching from another range
        // To avoid endless loopiong when initialized as empty date range
        this.$emit('dateRangeUpdated', this.dateRange);
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    padding: var(--space-8) 0;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-12);
      }
    }

    div {
      display: flex;
      align-items: center;
      height: 1rem;
      border-radius: 0.5rem;
      font-size: var(--x10);
      color: var(--white);
      margin-left: var(--space-16);
      padding: 0 var(--space-8);
    }

    .active-badge {
      background-color: var(--success-500);
    }

    .inactive-badge {
      background-color: var(--text-secondary);
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .ad-date {
    span {
      align-items: center;
      display: flex;
    }
  }

  .insights-list {
    text-align: center;
    font-size: var(--x14);

    .hoverable:hover {
      cursor: pointer;
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        margin-bottom: var(--space-8);

        span {
          display: flex;
          align-items: center;
          overflow: hidden;

          svg {
            margin-left: var(--space-8);
          }
        }

        p {
          color: var(--text-primary);
        }

        .ads-value {
          color: var(--text-secondary);
        }

        .url {
          text-decoration: underline;
        }
      }
    }

    .insights-stats li {
      margin-top: var(--space-8);

      .stat-label {
        padding-right: var(--space-8);
      }

      .stat {
        color: var(--action-500);
        padding-right: var(--space-8);
      }

      .caption {
        text-align: left;
        white-space: pre-wrap;
      }

      span + span {
        float: right;
        padding-left: var(--space-8);
        background: var(--background-300);
      }
    }

    .insights-desc {
      margin: var(--space-16) 0;

      .pair-row {
        span + span {
          background: var(--background-0);
          float: right;
          padding-left: var(--space-8);
        }

        span:first-child {
          background: var(--background-0) !important;
        }
      }

      .ad-container {
        padding-bottom: 0 !important;
      }

      .section-container {
        background: var(--background-0);
        border-radius: var(--round-corner);
        overflow: hidden;
        padding: 0 var(--space-16) var(--space-12);
        margin-bottom: var(--space-8);
        text-align: left;

        .section-header header {
          font-size: var(--x14);
        }

        .section-content {
          visibility: hidden;
          height: 0;
          opacity: 0;
          transition: all 0.3s;

          .category {
            padding-right: var(--space-8);
          }

          .placement-label {
            padding-top: var(--space-16);
          }
        }

        .open {
          visibility: visible;
          height: auto;
          opacity: 1;
        }

        .full-text,
        .campaign-title {
          overflow-wrap: break-word;
          white-space: normal;
        }

        .full-text {
          padding-bottom: var(--space-16);
        }

        p {
          color: var(--text-secondary);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .placement-container {
        padding: 0 1rem 0.1rem !important;
        overflow: visible;
      }

      .row-content {
        min-height: var(--space-32);
        margin-bottom: var(--space-8);

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: var(--space-4);
        }
      }
    }

    .select-control {
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      height: var(--space-32);
      background: var(--background-300);
      align-items: center;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: calc(-1 * var(--space-4));

      :deep(.picker) {
        padding-bottom: var(--space-12);
        background-position-y: 0.3rem;
      }
    }

    .select-tooltip {
      margin-top: calc(-1 * var(--space-8));
      margin-right: 7rem;
      display: inline-block;
    }

    .insights-stats,
    .insights-desc {
      .pair-row::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: '--------------------------------------------------------------------------------';
        color: var(--border);
      }

      .pair-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        span:first-child {
          position: absolute;
          background: var(--background-300);
        }
      }
    }

    button {
      margin: var(--space-16) auto 0 auto;
    }
  }

  .hide {
    display: none;
  }
}
</style>
