/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'smile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 13H7a1 1 0 00-1 1c0 2.617 2.86 5 6 5s6-2.383 6-5a1 1 0 00-1-1zm-5 4c-1.636 0-3.094-.951-3.701-2h7.411c-.591 1.052-2.026 2-3.71 2z"/><path pid="1" d="M12 2C6.486 2 2 6.486 2 12c0 5.515 4.486 10 10 10s10-4.485 10-10c0-5.514-4.486-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"/><path pid="2" d="M8 7h2v4H8zM14 7h2v4h-2z"/>'
  }
})
