/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><path pid="0" d="M10 0c5.514 0 10 4.486 10 10 0 5.515-4.486 10-10 10S0 15.515 0 10C0 4.486 4.486 0 10 0zm0 18c4.411 0 8-3.589 8-8s-3.589-8-8-8-8 3.589-8 8 3.589 8 8 8zm-2-4V6l6 4-6 4z" id="svgicon_video_a"/></defs><use _fill="#adadad" fill-rule="nonzero" xlink:href="#svgicon_video_a"/>'
  }
})
