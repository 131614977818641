import partial from 'lodash/partial';
import { nextTick } from 'vue';

export const observerMap = new WeakMap();
const valueMap = new WeakMap();
const previousLastChildMap = new WeakMap();

const handleIntersect = (el, vnode, entries) => {
  const { loading } = valueMap.get(el);
  if (loading) {
    return;
  }
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      el.dispatchEvent(new Event('infinite-scroll-down'));
    }
  });
};

const createObserver = (el, binding, vnode) => {
  const { distance = 200, onObserverCreated } = binding?.value ?? {};
  const observer = new IntersectionObserver(partial(handleIntersect, el, vnode), {
    root: el,
    rootMargin: `${distance}px`, // Effectively offset
  });
  observerMap.set(el, observer);

  // Just for testing in jest
  if (onObserverCreated) {
    onObserverCreated(observer);
  }

  return observer;
};

export const setup = async (el, binding) => {
  valueMap.set(el, binding?.value ?? {});
  // Ensure children are inserted into the DOM
  await nextTick();
};

/**
 * Directive that can be used to scroll infinitely
 *
 * Template:
 *
 * <ul v-infinite-scroll="{ loading }" @infinite-scroll-down="onInfiniteScrollDown">
 *   <li>...</li>
 * </ul>
 *
 * Why use this over InfiniteScroll.vue?
 * - It is more efficient; the handler is only called when visibility of the last element changes
 * - It does not introduce an unwanted element into the DOM to make CSS more complicated
 */
export default {
  async beforeMount(el, binding, vnode) {
    await setup(el, binding);

    const observer = createObserver(el, binding, vnode);
    const lastChild = el.lastElementChild;
    if (lastChild) {
      observer.observe(lastChild);
    }

    previousLastChildMap.set(el, lastChild);
  },
  async updated(el, binding) {
    await setup(el, binding);

    const observer = observerMap.get(el);
    const previousLastChild = previousLastChildMap.get(el);
    const lastChild = el.lastElementChild;
    if (previousLastChild && lastChild !== previousLastChild) {
      observer.unobserve(previousLastChild);
    }
    if (lastChild && lastChild !== previousLastChild) {
      observer.observe(lastChild);
    }

    previousLastChildMap.set(el, lastChild);
  },
};
