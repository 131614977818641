import { ref } from 'vue';
import { defineStore } from 'pinia';
import MediaModel from '@/models/media';
import { getMediaV2 } from '@/apis/library';

export const useCropperStore = defineStore('cropper', () => {
  const adjustLinksPrompt = ref(false);
  const adjustTagsPrompt = ref(false);
  const adjustShoppingTagsPrompt = ref(false);
  const adjustFacebookProductTagsPrompt = ref(false);
  const cropperRatio = ref(null);
  const cropperOriginalMedia = ref(null);
  const cropperZoom = ref(0);
  const videoDuration = ref(null);

  async function getCropperOriginalMedia({ brandId, mediaId }) {
    const response = await getMediaV2({ brandId, mediaId });
    const payload = response?.data;
    if (payload) {
      cropperOriginalMedia.value = new MediaModel(payload);
    }
  }

  function setCropperRatio(cropperRatioValue) {
    cropperRatio.value = cropperRatioValue;
  }
  function removeCropperRatio() {
    cropperRatio.value = null;
  }
  function setAdjustLinksPrompt() {
    adjustLinksPrompt.value = true;
  }
  function removeAdjustLinksPrompt() {
    adjustLinksPrompt.value = false;
  }
  function setAdjustTagsPrompt() {
    adjustTagsPrompt.value = true;
  }
  function removeAdjustTagsPrompt() {
    adjustTagsPrompt.value = false;
  }
  function setAdjustShoppingTagsPrompt() {
    adjustShoppingTagsPrompt.value = true;
  }
  function removeAdjustShoppingTagsPrompt() {
    adjustShoppingTagsPrompt.value = false;
  }
  function setAdjustFacebookProductTagsPrompt() {
    adjustFacebookProductTagsPrompt.value = true;
  }
  function removeAdjustFacebookProductTagsPrompt() {
    adjustFacebookProductTagsPrompt.value = false;
  }
  function removeCropperOriginalMedia() {
    cropperOriginalMedia.value = null;
  }
  function setCropperZoom(cropperZoomValue) {
    cropperZoom.value = cropperZoomValue;
  }
  function resetCropperZoom() {
    cropperZoom.value = 0;
  }
  function setVideoDuration(videoDurationValue) {
    videoDuration.value = videoDurationValue;
  }
  function removeVideoDuration() {
    videoDuration.value = null;
  }

  return {
    adjustLinksPrompt,
    adjustTagsPrompt,
    adjustShoppingTagsPrompt,
    adjustFacebookProductTagsPrompt,
    cropperRatio,
    cropperOriginalMedia,
    cropperZoom,
    videoDuration,
    getCropperOriginalMedia,
    setCropperRatio,
    removeCropperRatio,
    setAdjustLinksPrompt,
    removeAdjustLinksPrompt,
    setAdjustTagsPrompt,
    removeAdjustTagsPrompt,
    setAdjustShoppingTagsPrompt,
    removeAdjustShoppingTagsPrompt,
    setAdjustFacebookProductTagsPrompt,
    removeAdjustFacebookProductTagsPrompt,
    removeCropperOriginalMedia,
    setCropperZoom,
    resetCropperZoom,
    setVideoDuration,
    removeVideoDuration,
  };
});
