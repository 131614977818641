import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { logger } from '@/utils/logger';
import * as VisionAiAPI from '@/apis/vision-ai';
import {
  enrichMessages,
  processMessages,
  skipCamelizeMessageKeysOnDashboardReportsAdditionalContent,
  getStartTimeForApiRequest,
} from '@/utils/vision-ai';
import sortBy from 'lodash/sortBy';
import { useNotificationStore } from '@/stores/notification';
import { useTrackingStore } from '@/stores/tracking';
import { VISION_AI_SENDER } from '@/config';
import {
  ACTION_TYPES,
  DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE,
  INTENT_TYPES,
  INTENT_TYPES_BY_ROUTE_NAME,
  VISION_AI_MIXPANEL_EVENTS,
  VISION_AI_SOCKET_EVENTS,
  DEFAULT_METRIC_AND_ANALYTICS_PROMPTS,
  SUMMARY_INTENT_TYPES,
  VISION_AI_WIDGET_INTERACTIONS,
  OLD_VISION_AI_RESOURCE_CENTER_URL,
  VISION_AI_RESOURCE_CENTER_URL,
  VISION_AI_WIDGET_LAUNCH_INTERACTIONS,
  BACKUP_PROMPT_MESSAGE,
} from '@/components/VisionAi/constants';
import { useRoute } from 'vue-router';
import { getIntentQuery } from '@/components/VisionAi/utils/intent_query';
import { communityRouteName } from '@/app/community/routes';
import { useDashboardsStore } from '@/stores/dashboards';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { DASHBOARD_ROUTE_NAMES } from '@/app/dashboards/constants';
import { useCommunityVisionAi } from '@/app/community/composables/useCommunityVisionAi';
import { useSocketStore } from '@/stores/socket';
import { useIdentityStore } from '@/stores/identity';
import { useVisionAiSourceLinks } from '@/components/VisionAi/composables/useVisionAiSourceLinks';
import { useFlagStore } from '@/stores/flag';
import { useVisionAiPermissions } from '@/components/VisionAi/composables/useVisionAiPermissions';
import {
  assistantMessageReceivedEvent,
  userMessageSentEvent,
  widgetLaunchEvent,
} from '@/components/VisionAi/utils/mixpanel-events';
import { COMMUNITY_ROUTE_NAMES } from '@/app/community/constants';
import dayjs from 'dayjs';
import { useIntentMap } from '@/components/VisionAi/composables/useIntentMap';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { useVisionAiConversationsStore } from './vision-ai-conversations';

export const useVisionAiStore = defineStore('visionAi', () => {
  const socketStore = useSocketStore();
  const notificationStore = useNotificationStore();
  const dashboardsStore = useDashboardsStore();
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();
  const visionAiConversationsStore = useVisionAiConversationsStore();
  const { getWarningMessage } = useIntentMap();

  const route = useRoute();

  // refs
  const visionAiChatWindowOpen = ref(false);
  // TODO: SC-132312: Remove isAssistantMessageLoading and references
  const isAssistantMessageLoading = ref(false);
  const forceHideVisionAi = ref(false);
  const initMessagesLoaded = ref(false);
  const currentSourceUrl = ref(null);
  const popupIsExpanded = ref(false);
  const showConversationHistorySidebar = ref(false);
  const summaryLoading = ref(false);
  const anySummaryLoaded = ref(false);

  const { userHasVisionAiPermissionForCurrentRoute } = useVisionAiPermissions();

  const pending = ref({
    conversation: false,
    messages: false,
    regenerateMessage: false,
  });

  const messages = ref([]);
  const UPDATE_ACTION = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  };
  const messagesListNextUrl = ref(null);
  const notificationPopupOpen = ref(false);

  // computed
  const isDashboardConversationalAiEnabled = computed(
    () => flagStore.ready && flagStore.flags.conversationalVisionAiDashboards,
  );

  const isDashboardConversationalAiPhase2Enabled = computed(
    () => flagStore.ready && flagStore.flags.conversationalVisionAiDashboardsPhase2,
  );

  const canAccessConversational = computed(() => {
    const {
      someBrandCanAccessDashboardsAndVisionAi,
      someUserBrandCanAccessVisionAiConversational,
    } = useVisionAiPermissions();
    return (
      isDashboardConversationalAiEnabled.value &&
      someBrandCanAccessDashboardsAndVisionAi.value &&
      someUserBrandCanAccessVisionAiConversational.value
    );
  });

  // TODO: SC-132312: Remove setAssistantMessageLoadingState function and references
  function setAssistantMessageLoadingState(value) {
    isAssistantMessageLoading.value = value;
  }

  function setMessages({ newMessages = [], toBeginning = false, toIndex = 0 }) {
    if (toBeginning) {
      messages.value.unshift(...newMessages);
    } else if (toIndex > 0) {
      messages.value.splice(toIndex, 1, newMessages);
    } else {
      messages.value = newMessages;
    }
  }

  function expandChatPopup() {
    popupIsExpanded.value = true;
    showConversationHistorySidebar.value = true;
  }

  function shrinkChatPopup() {
    popupIsExpanded.value = false;
    showConversationHistorySidebar.value = false;
  }

  function hideConversationHistory() {
    showConversationHistorySidebar.value = false;
  }

  const promptForRoute = computed(() => {
    if (route?.name?.startsWith(communityRouteName)) {
      const { communityDefaultPrompt } = useCommunityVisionAi();
      return communityDefaultPrompt.value;
    }

    if (route?.name === SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW) {
      return BACKUP_PROMPT_MESSAGE;
    }

    const intent = INTENT_TYPES_BY_ROUTE_NAME?.[route?.name];
    return DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE?.[intent];
  });

  const defaultPrompts = computed(() => {
    let prompts = [];

    if (!isDashboardConversationalAiEnabled.value) {
      const routePrompt = promptForRoute.value;
      if (routePrompt) {
        prompts.push(routePrompt);
      }
      return prompts;
    }

    if (route && userHasVisionAiPermissionForCurrentRoute.value) {
      prompts.push(promptForRoute.value);
    }
    if (canAccessConversational.value) {
      prompts = prompts.concat(DEFAULT_METRIC_AND_ANALYTICS_PROMPTS);
    }

    return prompts;
  });

  const loadingMessageInCurrentConversation = computed(() => {
    if (visionAiConversationsStore.summariesSelected) {
      anySummaryLoaded.value = true;
      return summaryLoading.value;
    }
    if (isDashboardConversationalAiEnabled.value) {
      return visionAiConversationsStore.isMessageLoadingInCurrentConversation;
    }
    return isAssistantMessageLoading.value;
  });

  const isCommunityInsightsRoute = computed(() => {
    return route?.name === COMMUNITY_ROUTE_NAMES.INSIGHTS;
  });

  const currentIntentType = computed(() => {
    if (route?.name?.startsWith(communityRouteName)) {
      const { communityIntentType } = useCommunityVisionAi();
      return communityIntentType.value;
    }
    return INTENT_TYPES_BY_ROUTE_NAME?.[route?.name];
  });

  function clearLocalChatHistory() {
    setMessages({});
  }

  function openChatWindow() {
    if (visionAiChatWindowOpen.value === true) return;

    visionAiChatWindowOpen.value = true;
    widgetLaunchEvent({
      interaction: VISION_AI_WIDGET_LAUNCH_INTERACTIONS.OPEN,
      path: route?.path,
    });
  }

  function closeChatWindow() {
    if (visionAiChatWindowOpen.value === false) return;
    visionAiChatWindowOpen.value = false;
    widgetLaunchEvent({
      interaction: VISION_AI_WIDGET_LAUNCH_INTERACTIONS.CLOSE,
      path: route?.path,
    });
  }

  async function getMessages({ limit = 30, offset = 0, startTime = null, intentTypes = null }) {
    pending.value.messages = true;

    if (intentTypes && intentTypes.length > 0) {
      visionAiConversationsStore.setSummariesSelectedValue(
        intentTypes.every((intentType) => SUMMARY_INTENT_TYPES.includes(intentType)),
      );
    }
    const messagesStartTime = getStartTimeForApiRequest(startTime);
    const delimitedIntentTypes = intentTypes?.join(',');
    try {
      const response = await VisionAiAPI.getMessages({
        startTime: messagesStartTime,
        limit,
        offset,
        intentTypes: delimitedIntentTypes,
      });
      const messageList = processMessages(response.data.data);
      // add messages at beginning of array as we are scrolling up the chat window
      setMessages({
        newMessages: messageList,
        toBeginning: true,
      });
      messagesListNextUrl.value = response.data.paging?.next;
      if (!initMessagesLoaded.value) {
        initMessagesLoaded.value = true;
      }
    } catch (error) {
      logger.error('Failed to get Vision AI messages', {}, error);
      setMessages({});
      notificationStore.setToast({
        message: 'Failed to get Vision AI messages',
        type: 'error',
      });
    } finally {
      pending.value.messages = false;
    }
  }

  async function getMessageById(id, params = { includeDeleted: false }) {
    try {
      const response = await VisionAiAPI.getMessageById(id, params);
      const { data: fetchedMessage } = response;
      const messageToReplaceIndex = messages.value.findIndex(
        (msg) => msg.id === fetchedMessage?.id,
      );
      const processedMessage = processMessages([fetchedMessage])[0];
      setMessages({
        newMessages: processedMessage,
        toIndex: messageToReplaceIndex,
      });

      return processedMessage;
    } catch (error) {
      logger.error('Failed to get Vision AI message by id', {}, error);
      return messages.value.find((msg) => msg.id === id);
    }
  }

  async function clearChatHistory() {
    pending.value.messages = true;
    let response;
    try {
      response = await VisionAiAPI.clearMessages();
      setMessages({});
      visionAiConversationsStore.setCurrentConversationId(null);
      anySummaryLoaded.value = false;
    } catch (error) {
      logger.error('Failed to clear vision ai messages', {}, error);
      throw error;
    } finally {
      pending.value.messages = false;
    }

    return response ?? {};
  }

  function clearMessageFromHistory(messageId) {
    const newMessages = messages.value.filter((message) => message.id !== messageId);
    setMessages({ newMessages });
  }

  function sortMessages() {
    const newMessages = sortBy(messages.value, [
      (message) => message.createdAt,
      (message) => message.id,
    ]);
    setMessages({ newMessages });
  }

  function sendToResourceCenter() {
    if (!isDashboardConversationalAiEnabled.value) {
      window.open(OLD_VISION_AI_RESOURCE_CENTER_URL, '_blank');
    } else {
      window.open(VISION_AI_RESOURCE_CENTER_URL, '_blank');
    }
  }

  function addLoadingStates({ intentType, sender, conversationId }) {
    const isSummary = SUMMARY_INTENT_TYPES.includes(intentType);
    const isUserMessage = sender === VISION_AI_SENDER.USER;

    if (isSummary && isUserMessage) {
      summaryLoading.value = true;
      anySummaryLoaded.value = true;
    } else if (isUserMessage) {
      visionAiConversationsStore.addLoadingConversationIds(conversationId);
      setAssistantMessageLoadingState(true);
    }
  }

  function removeLoadingStates({ isSummary, sender, conversationId }) {
    const isAssistantMessage = sender === VISION_AI_SENDER.ASSISTANT;

    if (isSummary && isAssistantMessage) {
      summaryLoading.value = false;
    } else if (isAssistantMessage) {
      visionAiConversationsStore.removeLoadingConversationIds(conversationId);
      setAssistantMessageLoadingState(false);
    }
  }

  function addMessageToChatWindow(newMsg) {
    const isSummary = SUMMARY_INTENT_TYPES.includes(newMsg.intentType);
    anySummaryLoaded.value = true;
    removeLoadingStates({
      isSummary,
      sender: newMsg.sender,
      conversationId: newMsg.conversationId,
    });

    const messageIsInCurrentTab =
      newMsg.conversationId === visionAiConversationsStore.currentConversationId ||
      (visionAiConversationsStore.summariesSelected && isSummary);
    if (!messageIsInCurrentTab) return;

    // to support multiple browser tabs open with different message list states, check if msg exists
    const currentMessageIds = new Set(messages.value.map((msg) => msg.id));

    if (!currentMessageIds.has(newMsg.id)) {
      const newMessages = [...messages.value, ...enrichMessages([newMsg])];
      setMessages({ newMessages });

      // re-sort message list to ensure order as USER and ASSISTANT messages are received independently
      sortMessages();
    }
  }

  async function createMockMessages({ isMockPrompt = false }) {
    // We want the fake summary intent type added to summaries tab, so we should grab any
    // summary intent
    const intentType = isMockPrompt ? SUMMARY_INTENT_TYPES[0] : currentIntentType.value;

    const mockedUserMessage = {
      id: 'mockedUserMessage',
      message: defaultPrompts.value[0],
      sender: VISION_AI_SENDER.USER,
      createdAt: dayjs(),
      isWarningMessage: true,
      intentType,
      intentQuery: {},
    };
    addMessageToChatWindow(mockedUserMessage);

    const warningMessage = {
      id: 'warning',
      message: getWarningMessage(currentIntentType.value),
      isWarningMessage: true,
      sender: VISION_AI_SENDER.ASSISTANT,
      createdAt: dayjs(),
      intentType,
      intentQuery: {},
    };
    addMessageToChatWindow(warningMessage);
  }

  function onMessageCreatedNotification(messageData) {
    messageData.updates.forEach((msgUpdate) => {
      // convert keys to camelcase to match msgs in store
      let message = msgUpdate.data;
      message = skipCamelizeMessageKeysOnDashboardReportsAdditionalContent([message])[0];
      if (msgUpdate.action === UPDATE_ACTION.CREATE) {
        if (JSON.stringify(message).includes('updates')) {
          logger.error(`Suspicious payload for message - ${JSON.stringify(message)}`);
        }
        addMessageToChatWindow(message);
        const targetConversation = visionAiConversationsStore.conversations.find((conversation) => {
          return conversation.id === message.conversationId;
        });
        assistantMessageReceivedEvent({
          message,
          conversation: targetConversation,
        });
      }
    });
  }

  async function handleClearChatHistory() {
    const visionAiStore = useVisionAiStore();
    notificationPopupOpen.value = true;

    const isConversationalAiEnabled = visionAiStore.isDashboardConversationalAiEnabled;
    const confirmationMessage = isConversationalAiEnabled
      ? 'This will erase your entire chat history. Are you sure you want to proceed?'
      : 'This will erase all summaries from the chat. Are you sure you want to proceed?';

    if (
      await notificationStore.confirm('Delete Chat History?', confirmationMessage, {
        confirmAlias: 'Delete',
        onCancel: () => {
          notificationPopupOpen.value = false;
        },
        onConfirm: () => {
          notificationPopupOpen.value = false;
        },
      })
    ) {
      try {
        const response = await clearChatHistory();
        const trackingStore = useTrackingStore();
        trackingStore.track(VISION_AI_MIXPANEL_EVENTS.AI_WIDGET_INTERACTION, {
          interaction: VISION_AI_WIDGET_INTERACTIONS.DELETE_ALL_HISTORY,
          summaryCount: response?.data?.assistantMessagesDeleted ?? null,
        });
      } catch {
        notificationStore.setToast({
          message: 'Your chat history could not be deleted.',
          type: 'error',
        });
      }
    }
  }

  function onClearMessagesNotification(clearMsgNotification) {
    if (clearMsgNotification.error) {
      notificationStore.setToast({
        message: `Failed to delete Vision AI chat history.`,
        type: 'error',
      });
      return;
    }

    setMessages({});
  }

  function onConversationCreatedNotification(conversationData) {
    visionAiConversationsStore.addConversationWebSocket(conversationData);
  }

  function onConversationsDeletedNotification(conversationsData) {
    visionAiConversationsStore.deleteConversationsWebSocket(conversationsData);
  }

  function onConversationUpdatedNotification(conversationData) {
    visionAiConversationsStore.updateConversationWebSocket(conversationData);
  }

  async function createMessage({
    conversationId,
    sender,
    message,
    intentType,
    intentQuery,
    actionType,
    usedPrompt,
  }) {
    addLoadingStates({ intentType, sender, conversationId });
    try {
      const response = await VisionAiAPI.createMessage({
        conversationId,
        sender,
        message,
        intentType,
        intentQuery,
        actionType,
      });
      if (sender === VISION_AI_SENDER.USER) {
        userMessageSentEvent({
          messageSent: message,
          messageId: response?.data?.id,
          intentType,
          usedPrompt,
          followUps: response?.data?.userMessageCount,
          conversationId,
        });
      }
      return response?.data;
    } catch (error) {
      logger.error('Failed to create Vision AI message', {}, error);
      throw error;
    }
  }

  async function regenerateMessage({ conversationId, messageId, messageIntentType }) {
    addLoadingStates({ intentType: messageIntentType, sender: 'USER', conversationId });
    try {
      await VisionAiAPI.regenerateMessage({
        conversationId,
        messageId,
      });
    } catch (error) {
      logger.error('Failed to regenerate Vision AI message', {}, error);
      throw error;
    }
  }

  async function updateMessage({ messageId, regeneratedId }) {
    try {
      const response = await VisionAiAPI.updateMessage({
        messageId,
        regeneratedId,
      });
      return response.data;
    } catch (error) {
      logger.error('Failed to update Vision AI message', {}, error);
      throw error;
    }
  }

  async function copyResponse(message) {
    try {
      let messageToCopy = message.replace(/(<\/?p>)/g, '');
      messageToCopy = messageToCopy.replace(/(<ul>)/g, '');
      messageToCopy = messageToCopy.replace(/(<\/ul>)/g, '\n');
      messageToCopy = messageToCopy.replace(/(<li>)/g, '- ');
      messageToCopy = messageToCopy.replace(/(<\/li>)/g, '');
      messageToCopy = messageToCopy.replace(/(<\/?(strong|i)>)/g, '');
      await navigator.clipboard.writeText(messageToCopy);
      notificationStore.setToast({
        message: `Summary copied`,
      });
    } catch (error) {
      notificationStore.setToast({
        message: `Failed to copy Summary. Please try again.`,
        type: 'error',
      });
    }
  }

  async function startConversation({ message }) {
    const isMetricAnalyticsPrompt = DEFAULT_METRIC_AND_ANALYTICS_PROMPTS.includes(message);
    const intentType = isMetricAnalyticsPrompt
      ? INTENT_TYPES.METRIC_ANALYTICS
      : currentIntentType.value;
    const sender = VISION_AI_SENDER.USER;
    const intentQuery = getIntentQuery(intentType);
    const actionType = isMetricAnalyticsPrompt ? ACTION_TYPES.QUESTION : null;
    let conversationId = visionAiConversationsStore.currentConversationId;

    if (!conversationId) {
      const conversation = await visionAiConversationsStore.createConversation();
      conversationId = conversation.id;
    }

    if (JSON.stringify(intentQuery).includes('updates.0.data')) {
      logger.error(`Malformed intent_query sent for conversation id: ${conversationId}`);
    }

    await createMessage({
      conversationId,
      sender,
      message,
      intentType,
      intentQuery,
      actionType,
      usedPrompt: true,
    });
  }

  async function handleSourceButtonClicked(intentType, sourceUrl) {
    if (intentType === INTENT_TYPES.DASHBOARD_REPORTS && route.name === DASHBOARD_ROUTE_NAMES.ID) {
      // if the new dashboard route.id is not equal to the current dashboard route.id
      if (sourceUrl.params.id.toString() !== route.params.id) {
        await dashboardsStore.setCurrentDashboard({ id: sourceUrl.params.id });
      }

      // if the new dashboard route.id is equal to the current dashboard route.id but the query is different
      const routeQuery = pick(route.query, Object.keys(sourceUrl.query));

      if (!isEqual(sourceUrl.query, routeQuery)) {
        const { startDate, endDate, contextStartDate, contextEndDate } = sourceUrl.query;
        await dashboardsStore.updateDateRanges({
          reportDateRange: [startDate, endDate],
          contextDateRange: [contextStartDate, contextEndDate],
        });
      }
    }

    if (
      (intentType === INTENT_TYPES.COMMENTS || intentType === INTENT_TYPES.DMS) &&
      route.name.includes(communityRouteName)
    ) {
      if (sourceUrl.params.brandLabel !== identityStore.currentBrandLabel) {
        currentSourceUrl.value = sourceUrl;
      }
    }
  }

  function clearCurrentSourceUrl() {
    currentSourceUrl.value = null;
  }

  function checkIfSamePageSource(sourceUrl) {
    const {
      isDashboardsRoute,
      isTrendsRoute,
      isTopicsRoute,
      isCommunityRoute,
      checkTopicSourceParamsEqual,
      checkTrendsSourceParamsEqual,
      checkCommunitySourceParamsEqual,
    } = useVisionAiSourceLinks();
    if (isDashboardsRoute.value) {
      if (sourceUrl && sourceUrl.query) {
        const routeQuery = pick(route.query, Object.keys(sourceUrl.query));
        return isEqual(sourceUrl.query, routeQuery);
      }
    }

    if (isTrendsRoute.value) {
      return checkTrendsSourceParamsEqual(sourceUrl);
    }

    if (isTopicsRoute.value) {
      return checkTopicSourceParamsEqual(sourceUrl);
    }

    if (isCommunityRoute.value) {
      return checkCommunitySourceParamsEqual(sourceUrl);
    }

    return false;
  }

  function startNewChat() {
    visionAiConversationsStore.setSummariesSelectedValue(false);
    visionAiConversationsStore.setCurrentConversationId(null);
    clearLocalChatHistory();
  }

  function initSocketListeners() {
    // Register socket events
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_MESSAGE_CREATED,
      onMessageCreatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CLEAR_MESSAGES,
      onClearMessagesNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATION_CREATED,
      onConversationCreatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATION_UPDATED,
      onConversationUpdatedNotification,
      null,
    );
    socketStore.addListener(
      VISION_AI_SOCKET_EVENTS.VISION_AI_CONVERSATIONS_DELETED,
      onConversationsDeletedNotification,
      null,
    );
  }

  // watchers
  watch(
    () => socketStore.id,
    (id) => {
      if (id) {
        initSocketListeners();
      }
    },
    { immediate: true },
  );

  watch(
    () => identityStore.currentBrand,
    () => {
      // Re-enrich messages after brand change, so that source links are updated
      if (messages.value && messages.value.length > 0) {
        setMessages({ newMessages: enrichMessages(messages.value) });
      }
    },
  );

  watch(
    () => visionAiConversationsStore.conversationMessages,
    () => {
      clearLocalChatHistory();
      setMessages({ newMessages: visionAiConversationsStore.conversationMessages });
    },
  );

  watch(
    () => route?.name,
    () => {
      if (canAccessConversational.value) {
        clearLocalChatHistory();
        visionAiConversationsStore.resetToDefaultConversationState();
      }
    },
  );

  return {
    // states
    canAccessConversational,
    currentIntentType,
    currentSourceUrl,
    defaultPrompts,
    forceHideVisionAi,
    initMessagesLoaded,
    isAssistantMessageLoading,
    isCommunityInsightsRoute,
    isDashboardConversationalAiEnabled,
    isDashboardConversationalAiPhase2Enabled,
    loadingMessageInCurrentConversation,
    messages,
    messagesListNextUrl,
    notificationPopupOpen,
    pending,
    popupIsExpanded,
    showConversationHistorySidebar,
    summaryLoading,
    UPDATE_ACTION,
    visionAiChatWindowOpen,
    anySummaryLoaded,

    // methods
    addMessageToChatWindow,
    checkIfSamePageSource,
    clearChatHistory,
    clearCurrentSourceUrl,
    clearLocalChatHistory,
    clearMessageFromHistory,
    closeChatWindow,
    copyResponse,
    createMessage,
    createMockMessages,
    expandChatPopup,
    getMessageById,
    getMessages,
    handleClearChatHistory,
    handleSourceButtonClicked,
    hideConversationHistory,
    onClearMessagesNotification,
    onConversationCreatedNotification,
    onConversationsDeletedNotification,
    onConversationUpdatedNotification,
    onMessageCreatedNotification,
    openChatWindow,
    regenerateMessage,
    sendToResourceCenter,
    setAssistantMessageLoadingState,
    setMessages,
    shrinkChatPopup,
    startConversation,
    updateMessage,
    startNewChat,
  };
});
