<script setup>
import { ref, computed, nextTick } from 'vue';
import { onClickOutside } from '@vueuse/core';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import { colours } from '@/ux/colours';
import { useContentTagsStore } from '@/stores/content-tags';
import { useNotificationStore } from '@/stores/notification';
import Icon from '@/components/foundation/Icon.vue';
import MediaContentTagsList from '@/components/ContentTags/MediaContentTagsList.vue';
import { useMediaSelectStore } from '@/stores/media-select';
import {
  canAccessContentTags,
  mediaSupportsContentTags,
  MEDIA_SUPPORT_CONTENT_TAG_TOOLTIP,
  NO_ACCESS_TO_CONTENT_TAG_TOOLTIP,
} from '@/components/ContentTags/content-tag-utils';
import { useTrackingStore } from '@/stores/tracking';
import {
  CONTENT_TAG_ADDED_METHOD,
  CONTENT_TAG_MEDIA_ACTIONS,
  CONTENT_TAGS_EVENT_NAMES,
} from '@/app/settings/constants';
import { getPlatformFromMediaSource } from '@/stores/campaigns';
import { useAuthStore } from '@/stores/auth';
import { logger } from '@/utils/logger';

const contentTagsStore = useContentTagsStore();
const mediaSelectStore = useMediaSelectStore();
const notificationStore = useNotificationStore();
const trackingStore = useTrackingStore();
const authStore = useAuthStore();

const open = ref(false);
const addContentTagsDropdown = ref(null);
const contentTagsToAdd = ref([]);
const adding = ref(false); // Used to keep add input open in TagsBase
const saving = ref(false);
const TAG_CHUNK_SIZE = 20;
const MEDIA_CHUNK_SIZE = 100;

const hasTags = computed(() => contentTagsToAdd.value?.length > 0);
const disableActions = computed(() => !hasTags.value || saving.value);
const contentTagIdsToAdd = computed(() =>
  contentTagsToAdd.value.map((contentTagToAdd) => contentTagToAdd.id),
);
const selectedMedia = computed(() => {
  return (
    mediaSelectStore.multiSelectSelectedItems?.map((item) => {
      // some channels are using camel case objects and some snake case
      let mediaItem = cloneDeep(item);
      mediaItem = mapKeys(mediaItem, (v, k) => camelCase(k));
      return mediaItem;
    }) ?? []
  );
});
const selectedMediaIds = computed(() => {
  if (mediaSelectStore.associatedBrandMedia.length === 0) {
    return (
      selectedMedia.value?.map((mediaItem) => {
        /*
        /media/v1 stores the Brand Media ID in the id field
        /media/v2 stores the Brand Media ID in the brand_media_id field
        */
        return mediaItem.brandMediaId ?? mediaItem.id;
      }) ?? []
    );
  }
  // when bulk uploading, tags can be associated to multiple brands, media ID is id field,
  // and it's matched with new uploaded media by mediaId
  const selection = selectedMedia.value?.map((mediaItem) => mediaItem.id) ?? [];
  const selectedBrandMedia = mediaSelectStore.associatedBrandMedia.filter((brandMedia) =>
    selection.includes(brandMedia.mediaId),
  );
  return selectedBrandMedia
    .map((mediaItem) => mediaItem.id ?? mediaItem.brandMediaId)
    .filter((mediaId) => mediaId);
});

const currentBrandOrTargetedBrandsCanAccessContentTags = computed(() => {
  const currentBrandCanAccessContentTags = canAccessContentTags();

  const brandIdsWithAccessToContentTags = Object.values(
    contentTagsStore.brandsWithAccessToContentTags,
  ).map((brand) => {
    return brand.id;
  });

  const targetedBrandsCanAccessContentTags = mediaSelectStore.targetBrands.some((brand) =>
    brandIdsWithAccessToContentTags.includes(brand),
  );

  return currentBrandCanAccessContentTags || targetedBrandsCanAccessContentTags;
});
const openContentTagDropdown = computed(() => {
  return (
    currentBrandOrTargetedBrandsCanAccessContentTags.value &&
    mediaSupportsContentTags(mediaSelectStore.multiSelectSelectedItems)
  );
});
const openDropdownTooltip = computed(() => {
  if (openContentTagDropdown.value) {
    return `Add Content Tags`;
  }

  return !currentBrandOrTargetedBrandsCanAccessContentTags.value
    ? NO_ACCESS_TO_CONTENT_TAG_TOOLTIP
    : MEDIA_SUPPORT_CONTENT_TAG_TOOLTIP;
});

const showTagsForBrandIds = computed(() => {
  return [authStore.currentBrand.id, ...mediaSelectStore.targetBrands];
});

const emit = defineEmits(['clicked']);

function onOpenClick() {
  if (openContentTagDropdown.value) {
    open.value = true;
  }
  emit('clicked');
}
function closeDropdown() {
  open.value = false;
}
function onAddTag(contentTag) {
  adding.value = true;
  contentTagsToAdd.value.push(contentTag);
  nextTick(() => {
    adding.value = false;
  });
}
function onRemoveTag(contentTag) {
  const indexOf = contentTagsToAdd.value.indexOf(contentTag);
  if (indexOf > -1) {
    contentTagsToAdd.value.splice(indexOf, 1);
  }
}
function onClearClick() {
  contentTagsToAdd.value = [];
}

function sendAddedContentTagsEvent() {
  const brandsAssociatedToTags = {};
  contentTagsToAdd.value.forEach((tag) => {
    brandsAssociatedToTags[tag.name] = authStore.getBrandNames(tag.brandIds);
  });

  trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_MEDIA_ACTION, {
    action: CONTENT_TAG_MEDIA_ACTIONS.ADD,
    tagNames: contentTagsToAdd.value.map((tag) => tag.name),
    tagIDs: contentTagsToAdd.value.map((tag) => tag.id),
    tagAddedMethod: CONTENT_TAG_ADDED_METHOD.MEDIA_MULTI_SELECT,
    channels: Array.from(
      new Set(
        selectedMedia.value.map((media) => getPlatformFromMediaSource(media?.source ?? 'UPLOAD')),
      ),
    ),
    totalMedia: selectedMedia.value.length,
    brandsAssociatedToTags,
  });
}
function assignContentTags(contentTagIds, brandMediaIds) {
  return contentTagsStore.associateContentTagsToBrandMedia({
    contentTagIds,
    brandMediaIds,
    createMissingAssociations: mediaSelectStore.isTargetingBrands,
  });
}
async function onSaveClick() {
  if (saving.value) return;
  const brandMediaIds = selectedMediaIds.value;
  const contentTagIds = contentTagIdsToAdd.value;

  if (brandMediaIds?.length < 1 || contentTagIds?.length < 1) {
    return;
  }

  saving.value = true;
  const assignContentTagPromises = [];
  for (let i = 0; i < contentTagIds.length; i += TAG_CHUNK_SIZE) {
    const chunkOfContentTagIds = contentTagIds.slice(i, i + TAG_CHUNK_SIZE);
    for (let j = 0; j < brandMediaIds.length; j += MEDIA_CHUNK_SIZE) {
      const chunkOfBrandMediaIds = brandMediaIds.slice(j, j + MEDIA_CHUNK_SIZE);
      assignContentTagPromises.push(assignContentTags(chunkOfContentTagIds, chunkOfBrandMediaIds));
    }
  }
  try {
    await Promise.all(assignContentTagPromises);
    sendAddedContentTagsEvent();
    closeDropdown();
    mediaSelectStore.clearItemsFromMultiSelect();
    notificationStore.setToast({
      htmlMessage: `Your content tags have been added!`,
    });
  } catch (error) {
    logger.error(`Assign content tags error`, {}, error);
    notificationStore.setToast({
      htmlMessage: `An error occurred adding content tags to media.`,
      type: 'error',
    });
  } finally {
    saving.value = false;
  }
}
onClickOutside(addContentTagsDropdown, closeDropdown, {
  ignore: ['[data-cy="AddContentTagDropdown-Options"]'],
});
defineExpose({
  selectedMediaIds,
  contentTagsToAdd,
  currentBrandOrTargetedBrandsCanAccessContentTags,
  onSaveClick,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div class="relative">
    <div
      v-tooltip="openDropdownTooltip"
      class="flex items-center"
      :class="{
        disabled: !openContentTagDropdown,
        'cursor-pointer': openContentTagDropdown,
      }"
      data-cy="MSBar-AddContentTags"
      @click="onOpenClick"
    >
      <Icon :color="colours.BASIC.WHITE" name="tagAdd" small />
    </div>
    <transition name="slide">
      <div v-if="open" ref="addContentTagsDropdown" class="add-tags-dropdown">
        <p>Add Content Tags</p>

        <MediaContentTagsList
          open-add-on-mount
          class="handle-overflow m-6"
          :media-content-tags="contentTagsToAdd"
          :saving="adding"
          :brand-ids="showTagsForBrandIds"
          @add="onAddTag"
          @remove="onRemoveTag"
        />

        <footer class="text-small-medium flex justify-between px-6 py-4">
          <div
            class="text-primary cursor-pointer"
            :class="{ disabled: disableActions }"
            @click="onClearClick"
          >
            Clear
          </div>
          <div
            class="text-link cursor-pointer"
            :class="{ disabled: disableActions }"
            data-cy="MSBar-AddContentTags-save"
            @click.stop="onSaveClick"
          >
            Save
          </div>
        </footer>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.add-tags-dropdown {
  position: absolute;
  top: var(--space-32);
  z-index: var(--z-index-dropdown);
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  width: 18.75rem;
  height: fit-content;
  padding-top: var(--space-24);

  p {
    color: var(--text-primary);
    font-size: var(--x16);
    font-weight: var(--font-medium);
    padding: 0 var(--space-24);
  }

  footer {
    background-color: var(--background-300);
    border-bottom-left-radius: var(--round-corner-small);
    border-bottom-right-radius: var(--round-corner-small);
  }

  .handle-overflow {
    max-height: calc(100vh * 0.7);
    overflow-y: auto;
  }
}
</style>
