/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chatHistory': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.828 11.745l1.335 1.246a7.917 7.917 0 011.229-2.155 7.94 7.94 0 011.78-1.673 8.295 8.295 0 012.226-1.104 8.22 8.22 0 012.547-.392c1.151 0 2.231.214 3.24.641a8.471 8.471 0 012.671 1.799 8.685 8.685 0 011.781 2.653c.44 1.02.659 2.107.659 3.258a8.084 8.084 0 01-.588 3.08 8.653 8.653 0 01-1.602 2.565 8.317 8.317 0 01-2.422 1.816 8.055 8.055 0 01-2.991.855v-1.532a6.96 6.96 0 002.403-.712 6.989 6.989 0 001.924-1.496c.546-.605.973-1.3 1.282-2.083a6.52 6.52 0 00.48-2.493c0-.938-.184-1.822-.552-2.653a6.86 6.86 0 00-1.46-2.172 6.717 6.717 0 00-2.172-1.478 6.663 6.663 0 00-2.653-.534c-.784 0-1.526.124-2.226.373a6.81 6.81 0 00-1.923 1.033c-.57.44-1.069.962-1.496 1.567a6.693 6.693 0 00-.926 1.977l1.1 1.203-3.646.684v-4.273zm7.674 10.95c.226.048.451.084.677.107v1.532a8.765 8.765 0 01-.997-.16 9.685 9.685 0 01-.962-.268l.624-1.389c.213.072.433.131.658.178zm-2.6-1.175c.179.13.369.256.57.374l-.623 1.407a6.8 6.8 0 01-.837-.552 10.23 10.23 0 01-.783-.641l1.14-1.015c.178.154.356.297.534.427zm-2.19-2.706c.179.403.398.783.66 1.14l-1.14 1.014a9.018 9.018 0 01-.944-1.602 8.031 8.031 0 01-.57-1.834h1.567c.095.45.238.878.428 1.282zm10.882-5.35v4.521c0 .806-.653 1.459-1.458 1.459h-.145c-.323.874-1.447 1.27-2.26.642l-.833-.642h-3.144a1.459 1.459 0 01-1.459-1.459v-4.52c0-.806.653-1.459 1.459-1.459h6.382c.805 0 1.458.653 1.458 1.459zm-3.121 5.468l-.917-.708-.073-.056-.094-.073a.092.092 0 00-.055-.019h-3.58a.091.091 0 01-.091-.09v-4.521c0-.05.04-.091.09-.091h6.383c.05 0 .09.04.09.09v4.521c0 .05-.04.091-.09.091H17.804c-.05 0-.091.041-.091.091v.765a.091.091 0 01-.147.072l-.093-.072z" _fill="#666"/>'
  }
})
