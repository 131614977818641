<script setup>
// Vue
import { computed, onMounted } from 'vue';
// Components
import Icon from '@/components/foundation/Icon.vue';
import TrialPopupBase from '@/components/InAppTrials/TrialPopupBase.vue';
import MarketingQuote from '@/components/InAppTrials/MarketingQuote.vue';
import Button from '@/components/foundation/Button.vue';
// Stores
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
// Constants
import { PRODUCT_MAP, START_TRIAL } from '@/models/auth/customer-plan.enum';
// UX
import { ACTION } from '@/ux/colours';
import { IMG } from '@/assets';
// Utils
import { trackTrialOverviewModal } from '@/app/settings/mixpanel';
import Chip from '@/components/foundation/Chip.vue';

const customerJourneyStore = useCustomerJourneyStore();
const notificationStore = useNotificationStore();
const authStore = useAuthStore();

const popupButtonText = 'Start Free Trial';

const bannedOrgs = [1620];

const product = computed(() => customerJourneyStore.popups.startTrial.product);
const productKey = computed(() =>
  product.value === PRODUCT_MAP.PREMIUM_ANALYTICS &&
  !customerJourneyStore.isEligibleForPremiumAnalytics
    ? PRODUCT_MAP.ADVANCE_PREMIUM
    : product.value,
);
const trialInfo = computed(() => START_TRIAL[PRODUCT_MAP[productKey.value]]);
const planType = computed(() => trialInfo.value.product_type);
const title = computed(() => trialInfo.value.title);
const descriptionItems = computed(() => trialInfo.value.description_items);
const buttonClass = computed(() => {
  if (product.value in PRODUCT_MAP) {
    return `${PRODUCT_MAP[product.value].toLowerCase()}_trial_btn trial-btn my-10`;
  }
  return 'trial-btn my-10';
});

const emits = defineEmits(['close']);

function close() {
  emits('close');
}
async function startTrial() {
  const productValue = product.value;
  const currentOrg = authStore.currentBrand.organization.id;
  customerJourneyStore.trialProduct = productKey.value;

  if (productKey.value === PRODUCT_MAP.SOCIAL_LISTENING && bannedOrgs.includes(currentOrg)) {
    notificationStore.setToast({
      message:
        'Your organization cannot trial Social Listening. Speak with your Account Owner for details.',
      type: 'warning',
    });
    close();
    return;
  }
  if (productKey.value === PRODUCT_MAP.ADVANCE_PREMIUM) {
    const success = await customerJourneyStore.createTrialSubscription(PRODUCT_MAP.ADVANCE);
    if (!success) return;
  }
  const success = await customerJourneyStore.createTrialSubscription(product.value);
  if (success) {
    close();
    await authStore.identify();
    customerJourneyStore.togglePopup('successTrial', true, productValue);
  }
}
onMounted(() => {
  const startTrialProduct = START_TRIAL[product.value];
  const keyName = startTrialProduct.product_type === 'Plan' ? 'plan' : 'addOn';
  const properties =
    product.value === PRODUCT_MAP.PREMIUM_ANALYTICS &&
    !customerJourneyStore.isEligibleForPremiumAnalytics
      ? { [keyName]: startTrialProduct.product_name, plan: START_TRIAL.ADVANCE.product_name }
      : { [keyName]: startTrialProduct.product_name };
  trackTrialOverviewModal(properties);
});
</script>

<template>
  <TrialPopupBase @close="close">
    <template #left>
      <MarketingQuote :product-key="productKey" />
    </template>
    <template #right>
      <div class="items-left flex-col p-10">
        <Chip
          small
          class="add-on-btn mb-6 flex cursor-default justify-start bg-[var(--action-150)] px-1.5 text-[var(--action-500)]"
        >
          <Icon name="lock-2" xsmall :color="ACTION.ACTION_500" class="mr-1 align-text-bottom" />{{
            planType
          }}
        </Chip>
        <h4 class="mb-6 normal-case">{{ title }}</h4>
        <ul>
          <li v-for="item in descriptionItems" :key="item" class="mb-4 flex text-sm">
            <img :src="IMG.checkMarkBlue" class="mr-2 mt-0.5 self-start" alt="checkmark" />
            {{ item }}
          </li>
        </ul>
        <Button :class="buttonClass" dark-blue bold no-hover @click="startTrial()">
          {{ popupButtonText }}
        </Button>
      </div>
    </template>
  </TrialPopupBase>
</template>
