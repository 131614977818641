<template>
  <div class="custom-tags" data-cy="add-custom-tags">
    <div class="add-tags-container">
      <div
        v-on-click-outside="handleNewTag"
        :class="['custom-tag', 'custom-tag-input', { open: showInput }]"
        data-cy="add-custom-tag-input"
        @click.prevent="openTagInput"
      >
        <a v-if="!showInput" ref="addButton">+</a>
        <form @submit.prevent="handleNewTag">
          <input
            v-show="showInput"
            ref="tagInput"
            v-model="newTag"
            :class="['add-tag', { open: showInput }]"
            placeholder="Enter tag"
          />
        </form>
      </div>
      <div v-for="tag in customTagList" :key="tag" class="custom-tag">
        {{ tag }}
        <Icon name="close" xxsmall @click="removeTag(tag)" />
      </div>
    </div>
    <footer>
      <Button :class="{ disabled: customTagList.length === 0 }" link @click="clearTags">
        Clear
      </Button>
      <Button
        :class="{ disabled: customTagList.length === 0 }"
        link
        class="save-btn"
        data-cy="add-custom-tags-save"
        @click="saveTags"
      >
        Save
      </Button>
    </footer>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import { useMediaSelectStore } from '@/stores/media-select';
import { useMediaDetailStore } from '@/stores/media-detail';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Tags',
  components: {
    Button,
    Icon,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  emits: ['onSave'],
  data() {
    return {
      customTagList: [],
      showInput: true,
      newTag: null,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useMediaSelectStore, useMediaDetailStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
  },
  watch: {
    'mediaDetailStore.pending.multipleMediaTagsStatus': function addMultipleMediaTagsWatcher(to) {
      if (!to) {
        this.$emit('onSave');
        this.mediaSelectStore.clearItemsFromMultiSelect();
        this.notificationStore.setToast({ message: 'Your tags have been added!' });
      }
    },
  },
  mounted() {
    this.openTagInput();
  },
  methods: {
    openTagInput() {
      this.showInput = true;
      nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    handleNewTag(e) {
      if (!this.showInput) {
        return;
      }
      if (e && e.target === this.$refs.addButton) {
        this.$refs.tagInput.focus();
        return;
      }
      if (this.newTag) {
        this.addTag();
      }
      this.showInput = false;
      this.newTag = null;
    },
    addTag() {
      const tagIndex = this.customTagList.findIndex((item) => item === this.newTag);
      if (tagIndex === -1) {
        this.customTagList.push(this.newTag);
      }
      this.newTag = null;
    },
    removeTag(tag) {
      const tagIndex = this.customTagList.findIndex((item) => item === tag);
      this.customTagList.splice(tagIndex, 1);
    },
    clearTags() {
      this.customTagList = [];
      this.openTagInput();
    },
    saveTags() {
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        const mediaIds = this.mediaSelectStore.multiSelectSelectedItems.map((item) => {
          // ad cards have their media id in item.media.media_id
          return (item.media && item.media.media_id) || item.id;
        });
        this.mediaDetailStore.addMultipleMediaTags({
          brandId: this.currentBrand.id,
          mediaIds,
          tags: this.customTagList,
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.add-tags-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: var(--space-24);
  font-size: var(--x14);
  padding: var(--space-24);

  .custom-tag-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    transition: width 0.3s ease-in-out;

    &.open {
      transition: var(--transition-all);
      width: 6.75rem;
    }

    a {
      font-size: var(--x20);
      line-height: 1.65rem;
      height: 2rem;
    }
  }

  input.add-tag {
    border: none;
    background: transparent;
    text-align: left;
    width: 0;
    height: 100%;
    color: var(--text-primary);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    transition: var(--transition-all);

    &.open,
    &:focus {
      width: 100%;
    }
  }

  input.add-tag::placeholder {
    color: var(--text-primary);
    opacity: 0.5;
    font-weight: var(--font-medium);
  }

  .custom-tag {
    color: var(--text-primary);
    border-radius: var(--space-16);
    background: var(--border);
    margin: var(--space-4);
    padding: 0 var(--space-16);
    height: var(--space-32);
    line-height: var(--space-28);
    font-weight: var(--font-medium);
    transition: var(--transition-all);

    svg {
      margin-left: var(--space-4);

      /* little trick to make the icon align in the middle. */
      transform: translateY(1.5px);
      cursor: pointer;
    }
  }
}

.custom-tags footer {
  background: var(--background-300);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  font-size: var(--x14);

  button {
    color: var(--text-primary);
    font-weight: var(--font-medium);
    font-size: var(--x14);
    transition: var(--transition-all);

    &:hover {
      text-decoration: none;
      color: var(--action-500);
    }
  }

  button.disabled {
    pointer-events: none;
  }

  .save-btn {
    color: var(--action-500);
  }
}
</style>
