export const MEDIA_TYPES = Object.freeze({
  ALL_MEDIA: {
    icon: 'feed',
    text: 'All Media',
    title: 'All Media',
    tooltip: 'All Media',
    value: 'ALL_MEDIA',
  },
  CAROUSEL: {
    icon: 'multipleMedia',
    text: 'Carousels',
    title: 'Carousels',
    tooltip: 'Carousels',
    value: 'CAROUSEL',
    subString: '_CAROUSEL',
    chartColor: '#E179DA',
  },
  LINK: {
    icon: 'link',
    text: 'Links',
    title: 'Links',
    tooltip: 'Links',
    value: 'LINK',
    subString: '_LINK',
    chartColor: '#008000',
  },
  PHOTO: {
    icon: 'fileLandscapeImage',
    text: 'Photos',
    title: 'Photos',
    tooltip: 'Photos',
    value: 'PHOTO',
    subString: '_PHOTO',
    chartColor: '#F5A623',
  },
  PHOTO_CAROUSEL: {
    icon: 'filter',
    text: 'Photos + Carousels',
    title: 'Photos and Carousels',
    tooltip: 'Photos + Carousels',
    value: 'PHOTO_CAROUSEL',
    subString: '_PHOTO_CAROUSEL',
    chartColor: '#39BCE6',
  },
  REEL: {
    icon: 'reels',
    text: 'Reels',
    title: 'Reels',
    tooltip: 'Reels',
    value: 'REEL',
    subString: '_REEL',
    chartColor: '#E36042',
  },
  STATUS: {
    icon: 'text',
    text: 'Text',
    title: 'Text',
    tooltip: 'Text',
    value: 'STATUS',
    subString: '_STATUS',
    chartColor: '#000000',
  },
  VIDEO: {
    icon: 'video',
    text: 'Videos',
    title: 'Videos',
    tooltip: 'Videos',
    value: 'VIDEO',
    subString: '_VIDEO',
    chartColor: '#27C449',
  },
  VIDEO_REEL: {
    icon: 'filter',
    text: 'Videos + Reels',
    title: 'Videos and Reels',
    tooltip: 'Videos + Reels',
    value: 'VIDEO_REEL',
    subString: '_VIDEO_REEL',
    chartColor: '#8943FF',
  },
});

export const INSTAGRAM_MEDIA_TYPE_LIST = [
  MEDIA_TYPES.ALL_MEDIA,
  MEDIA_TYPES.CAROUSEL,
  MEDIA_TYPES.PHOTO,
  MEDIA_TYPES.PHOTO_CAROUSEL,
  MEDIA_TYPES.REEL,
  MEDIA_TYPES.VIDEO,
  MEDIA_TYPES.VIDEO_REEL,
];

export function formatMediaSubtypes(metric, mediaTypes) {
  return Object.fromEntries(
    mediaTypes.map((mediaType) => {
      const key = mediaType.value;
      const value = {
        metric: `${metric}${mediaType.subString ?? ''}`,
        ...mediaType,
      };
      return [key, value];
    }),
  );
}
