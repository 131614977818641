<template>
  <li v-tooltip="tooltip" :class="{ disabled: item.disabled }" class="multi-select-item">
    <label>
      <label class="checkbox">
        <input
          :checked="selected"
          type="checkbox"
          @click="preventDisabled"
          @change="inputChanged"
        />
        <div class="indicator" />
      </label>
      <span>
        <Icon v-if="icon" :name="icon" small />
        {{ label }}
      </span>
    </label>
  </li>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MultiSelectListItem',
  components: {
    Icon,
  },
  props: {
    item: { type: Object, default: null },
    label: { type: String, required: true },
    selected: { type: Boolean, required: true },
    onChange: { type: Function, required: true },
    icon: { type: String, default: null },
  },
  computed: {
    tooltip() {
      if (this.item.tooltip) {
        return {
          content: this.item.tooltip,
          theme: 'dh-tooltip-small',
          placement: 'bottom',
        };
      }
      return '';
    },
  },
  methods: {
    inputChanged(e) {
      this.onChange(e.target.checked);
    },
    preventDisabled(e) {
      if (this.item.disabled) {
        e.preventDefault();
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.multi-select-item {
  height: var(--space-32);

  label {
    cursor: pointer;
    margin-bottom: 0;

    span {
      margin: 0 0 0 var(--space-32);
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-8);
      }
    }
  }

  .checkbox {
    position: relative;
    display: block;
    top: var(--space-8);
    cursor: pointer;
    text-align: left;
  }

  .checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .indicator {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    background: var(--background-0);
    border-radius: var(--round-corner-small);
    border: 1px solid var(--border);
  }

  /* Check mark */
  .indicator::after {
    position: absolute;
    display: none;
    content: '';
  }

  /* Checkbox tick */
  .checkbox .indicator::after {
    top: 3px;
    left: 7px;
    width: 3px;
    height: 8px;
    transform: rotate(45deg);
    border: solid var(--white);
    border-width: 0 2px 2px 0;
  }

  /* Hover and focus states */
  .checkbox:hover input ~ .indicator,
  .checkbox input:focus ~ .indicator {
    background: var(--background-300);
  }

  /* Checked state */
  .checkbox input:checked ~ .indicator {
    background: var(--action-500);
    border: 1px solid var(--action-500);
  }

  /* Show check mark */
  .checkbox input:checked ~ .indicator::after {
    display: block;
  }
}
</style>
