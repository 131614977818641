/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ugc': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<defs><path pid="0" d="M19.177 16.001H5.047c-.559 0-1.01-.448-1.01-1v-14c0-.552.451-1 1.01-1h14.13c.558 0 1.009.448 1.009 1v14c0 .552-.451 1-1.01 1zm-13.121-2h12.111v-12H6.056v12zm10.093 6H1.009c-.558 0-1.009-.448-1.009-1v-15h2.019v14h14.13v2zm-7.065-13a2.01 2.01 0 01-2.019-2c0-1.105.904-2 2.019-2a2.01 2.01 0 012.018 2c0 1.105-.903 2-2.018 2zm5.046 0l3.028 6H7.065l3.028-4 1.425 1.882 2.612-3.882z" id="svgicon_ugc_a"/></defs><g transform="translate(2.251 2)" _fill="none" fill-rule="evenodd"><mask id="svgicon_ugc_b" _fill="#fff"><use xlink:href="#svgicon_ugc_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_ugc_a"/><g mask="url(#svgicon_ugc_b)" _fill="#D6B034" fill-rule="nonzero"><path pid="1" d="M-2.019-2h24.223v24H-2.019z"/></g></g>'
  }
})
