<script setup>
import PortalPopup from '@/components/PortalPopup/PortalPopup.vue';
import { computed, reactive, watch } from 'vue';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import Button from '@/components/foundation/Button.vue';
import TextInput from '@/components/foundation/form/TextInput.vue';
import { required, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { aiWidgetInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';
import { VISION_AI_WIDGET_INTERACTIONS } from '@/components/VisionAi/constants';

const NAME_MAX_LENGTH = 255;

const visionAiConversationsStore = useVisionAiConversationsStore();

const form = reactive({
  name: null,
});

const show = computed(() => {
  return visionAiConversationsStore.conversationModes.renameConversation;
});

const rules = computed(() => {
  return {
    form: {
      name: {
        required,
        maxLength: maxLength(NAME_MAX_LENGTH),
      },
    },
  };
});

const v$ = useVuelidate(rules, { form });

function onCancel() {
  visionAiConversationsStore.closeRenameConversationWidget();
  form.name = null;
  v$.value.$reset();
}

async function onSave() {
  if (v$.value.$invalid) {
    return;
  }

  const conversationId = visionAiConversationsStore.renameSelectedConversationId;
  const newConversationName = form.name;
  await visionAiConversationsStore.updateConversationName({
    conversationId,
    name: newConversationName,
  });

  v$.value.form.name.$touch();
  aiWidgetInteractionEvent({
    interaction: VISION_AI_WIDGET_INTERACTIONS.RENAME_CHAT,
    renameChat: newConversationName,
  });
}

watch(show, () => {
  const selectedConversation = visionAiConversationsStore.conversations.find(
    (conversation) => visionAiConversationsStore.renameSelectedConversationId === conversation.id,
  );
  form.name = selectedConversation?.name;
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        show,
        form,
        onSave,
      }
    : {},
);
</script>

<template>
  <PortalPopup
    v-if="show"
    size="small"
    data-cy="rename-conversation-popup"
    @close="visionAiConversationsStore.closeRenameConversationWidget"
  >
    <div class="conversation-popup-container flex flex-col items-center justify-center gap-10">
      <span class="text-h4">Rename Chat</span>

      <div class="flex w-full flex-col">
        <TextInput
          v-model="form.name"
          class="conversations-font-style"
          focus
          label="Chat Name"
          placeholder="Name your chat"
          :validator="v$.form.name"
          @keyup.enter="onSave"
        />
        <span class="conversations-font-style text-xs">Chat names are generated automatically</span>
      </div>

      <div class="flex w-[335px] justify-between">
        <Button back wide @click="onCancel"> Cancel </Button>
        <Button
          primary
          wide
          :loading="visionAiConversationsStore.pending.updatingConversation"
          :disabled="!form.name"
          @click="onSave"
        >
          Save
        </Button>
      </div>
    </div>
  </PortalPopup>
</template>
