<template>
  <SubScreen :title="title" @on-back="handleBack">
    <template #content>
      <section>
        <div class="add-location">
          <div v-on-click-outside="handleClickOutside" class="search-container">
            <div :class="['input-wrapper', { loading }]">
              <input
                ref="searchInput"
                v-model="searchInput"
                :placeholder="placeholder"
                :class="{ 'has-search-button': hasSearchButton }"
                type="text"
                @input="handleInput"
                @focus="handleFocus"
              />
              <Icon
                v-if="selection && selection[titleKey] === searchInput && !searchPending"
                :color="iconColor"
                :hover-color="iconHoverColor"
                name="closeCircleSolid"
                class="clear-search"
                @click="clearSearch"
              />
            </div>
            <Button
              v-if="hasSearchButton"
              class="search-button"
              rectangular
              primary
              @click="searchLocations"
            >
              Search
            </Button>
          </div>
          <transition name="slide">
            <div
              v-show="locations.length || emptySearchResult"
              v-on-click-outside="handleResultsClickOutside"
              class="location-search-results"
            >
              <dl v-if="emptySearchResult">
                <dd>No Results</dd>
              </dl>
              <dl
                v-for="loc in locations"
                v-else
                :key="loc.id"
                class="search-result"
                @click="selectLocation(loc)"
              >
                <dt>
                  {{ loc[titleKey] }}
                </dt>
                <dd v-if="platform === 'instagram' || platform === 'facebook'">
                  <span>{{ formatLocation(loc.location) }}</span>
                </dd>
                <dd v-else>
                  {{ loc[subTitleKey] }}
                </dd>
              </dl>
            </div>
          </transition>
          <ValidationError v-if="schedulerStore.locationRateLimitReached" class="rate-limit-error">
            <p>
              You have reached your max of {{ schedulerStore.locationRateLimit }} location searches.
              Come back in 15 minutes and try again!
            </p>
          </ValidationError>
        </div>
      </section>
    </template>
  </SubScreen>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import { vOnClickOutside } from '@vueuse/components';
import { useAuthStore } from '@/stores/auth';
import { debounceInputDelay } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import ValidationError from '@/components/ValidationError.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import { useSchedulerStore } from '@/stores/scheduler';

const maxResults = 25;

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AddLocation',
  components: {
    SubScreen,
    Button,
    Icon,
    ValidationError,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    platform: { type: String, required: true },
    loading: { type: Boolean, default: false },
    location: { type: Object, default: null },
    onLocationSelected: { type: Function, default: () => {} },
    hasSearchButton: { type: Boolean, default: false },
    titleKey: { type: String, required: true },
    subTitleKey: { type: String, required: true },
  },
  data() {
    return {
      searchInput: '',
      selection: null,
      iconColor: colours.ICON.ICON_SECONDARY,
      iconHoverColor: colours.ICON.ICON_PRIMARY,
      emptySearchResult: false,
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useAuthStore),
    title() {
      return 'Add Location';
    },
    searchPending() {
      if (this.isTwitter) {
        return !!this.schedulerStore.pending.twitterLocationSearchList;
      }
      if (this.isInstagram) {
        return !!this.schedulerStore.pending.instagramLocationSearchList;
      }
      if (this.isFacebook) {
        return !!this.schedulerStore.pending.facebookLocationSearchList;
      }
      return false;
    },
    locations() {
      if (this.isTwitter) {
        return this.schedulerStore.twitterLocationSearchList.slice(0, maxResults);
      }
      if (this.isInstagram) {
        return this.schedulerStore.instagramLocationSearchList.slice(0, maxResults);
      }
      if (this.isFacebook) {
        return this.schedulerStore.facebookLocationSearchList.slice(0, maxResults);
      }
      return [];
    },
    placeholder() {
      return this.selection ? this.selection[this.titleKey] : 'Search locations';
    },
    isTwitter() {
      return this.platform === 'twitter';
    },
    isInstagram() {
      return this.platform === 'instagram';
    },
    isFacebook() {
      return this.platform === 'facebook';
    },
  },
  created() {
    if (this.location) {
      this.searchInput = this.location[this.titleKey];
      this.selection = this.location;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.enterKeyHandler);
    if (!this.selection) {
      this.$refs.searchInput.focus();
    }
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.enterKeyHandler);
    this.clearLocationSearchList();
  },
  methods: {
    clearSearch() {
      this.searchInput = '';
      this.selection = null;
      this.emptySearchResult = false;
      this.clearLocationSearchList();
      this.$refs.searchInput.focus();
    },
    async searchLocations() {
      this.emptySearchResult = false;
      if (!this.searchInput) {
        return;
      }
      this.getLocationSearchList();
    },
    selectLocation(location) {
      this.selection = location;
      this.searchInput = location[this.titleKey];
      this.clearLocationSearchList();
      this.onLocationSelected(this.selection);
    },
    enterKeyHandler(e) {
      const ENTER_KEYCODE = 13;
      if (e.keyCode === ENTER_KEYCODE) {
        this.searchLocations();
      }
    },
    handleInput() {
      if (!this.hasSearchButton) {
        this.debounceInput();
      }
    },
    handleFocus() {
      if (this.selection) {
        this.searchInput = '';
      }
    },
    handleClickOutside() {
      if (this.selection) {
        this.searchInput = this.selection[this.titleKey];
      }
    },
    handleResultsClickOutside() {
      this.clearLocationSearchList();
    },
    handleBack() {
      this.onLocationSelected(this.selection);
    },
    debounceInput: debounce(function debounceSearchInput() {
      this.searchLocations();
    }, debounceInputDelay),
    // used to generate unique results from Facebook Search API
    formatLocation(location) {
      if (!location) {
        return null;
      }

      const street = location.street ? `${location.street},` : '';
      const city = location.city ? `${location.city},` : '';
      const country = location.country || '';

      return `${street} ${city} ${country}`;
    },
    async getLocationSearchList() {
      if (this.isTwitter) {
        await this.schedulerStore.getTwitterLocationSearchList({
          query: this.searchInput,
        });
        if (this.schedulerStore.twitterLocationSearchList?.length === 0) {
          this.emptySearchResult = true;
        }
      } else if (this.isInstagram) {
        await this.schedulerStore.getInstagramLocationSearchList({
          query: this.searchInput,
          limit: maxResults,
        });
        if (this.schedulerStore.instagramLocationSearchList?.length === 0) {
          this.emptySearchResult = true;
        }
      } else if (this.isFacebook) {
        await this.schedulerStore.getFacebookLocationSearchList({
          query: this.searchInput,
          limit: maxResults,
        });
        if (this.schedulerStore.facebookLocationSearchList.length === 0) {
          this.emptySearchResult = true;
        }
      }
    },
    clearLocationSearchList() {
      if (this.isTwitter) {
        this.schedulerStore.clearTwitterLocationSearchList();
      } else if (this.isInstagram) {
        this.schedulerStore.clearInstagramLocationSearchList();
      } else if (this.isFacebook) {
        this.schedulerStore.clearFacebookLocationSearchList();
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.add-location {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-top: var(--space-24);

  .search-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .input-wrapper {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      padding: var(--space-8) var(--space-40) var(--space-8) var(--space-16);
      border-radius: var(--round-corner-small);

      &.has-search-button {
        border-radius: var(--round-corner-small) 0 0 var(--round-corner-small);
      }
    }

    .clear-search {
      position: absolute;
      cursor: pointer;
      top: 0.58rem;
      right: 1rem;
    }
  }

  .input-wrapper.loading::after {
    content: '';
    display: block;
    border: 2px solid #4c5f71;
    border-left-color: var(--shadow-dark);
    border-right-color: var(--shadow-dark);
    border-bottom-color: var(--shadow-dark);
    height: var(--space-16);
    width: var(--space-16);
    border-radius: 50%;
    position: absolute;
    right: var(--space-12);
    top: 50%;
    box-sizing: border-box;
    margin-left: calc(-1 * var(--space-8));
    margin-top: calc(-1 * var(--space-8));
    animation: rotate 1s linear infinite;
  }

  .search-button {
    border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
    border-left: none;
    font-size: var(--x14);

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  .location-search-results {
    position: absolute;
    top: var(--space-48);
    left: 0;
    width: 100%;
    max-height: 21.5rem;
    overflow: auto;
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-3);
    background: var(--background-0);

    dl {
      border-bottom: 1px solid var(--border);
      padding: var(--space-12) var(--space-24);
      text-align: left;

      dt {
        font-size: var(--x16);
        color: var(--text-primary);
      }

      dd {
        font-size: var(--x14);
        color: var(--text-secondary);
      }

      &:last-child {
        border: none;
      }
    }

    dl.search-result:hover {
      background: var(--background-300);
      cursor: pointer;
    }
  }

  button.done {
    margin: var(--space-40) auto 0;
  }

  .rate-limit-error {
    margin-top: var(--space-8);
    font-size: var(--x14);
    text-align: left;
  }
}
</style>
