/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chatBubbleHeart': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M2.499 11.833V9.5H1.332A1.167 1.167 0 01.166 8.333v-7C.166.69.688.167 1.332.167h9.334c.644 0 1.166.522 1.166 1.166v7c0 .644-.522 1.167-1.166 1.167H5.61L2.5 11.833zm-1.167-10.5v7h2.334V9.5L5.22 8.333h5.445v-7H1.332z"/><path pid="1" d="M5.999 7.167s2.67-1.943 2.67-3.382c0-1.367-2.077-1.96-2.67-.166-.594-1.794-2.671-1.201-2.671.166 0 1.44 2.67 3.382 2.67 3.382z"/></g>'
  }
})
