import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import find from 'lodash/find';
import communityApi, { axios } from '@/apis/community';
import { parseTimestamps } from '@/app/community/utils/time';
import { applyBulkUpdateToCollection } from '@/app/community/utils/websockets';
import { setReaction, sortMessages } from '@/app/community/utils/messages';
import { useNotificationStore } from '@/stores/notification';
import { useIdentityStore } from '@/stores/identity';
import { useCommunityInteractionRulesStore } from '@/stores/community-interaction-rules';
import { useCommunityTranslationStore } from '@/stores/community-translation';
import { ruleExecutedMessageFormatter } from '@/app/community/utils/interaction-rules';
import unionBy from 'lodash/unionBy';

export const useCommunityDmsStore = defineStore('communityDms', {
  resetOnBrandChange: true,
  state: () => ({
    messages: {},
    messagesPaging: {},
    triggeredMessageRules: {},
    pending: {
      messagesPaging: false,
      messages: false,
    },
  }),
  actions: {
    async getPlatformMessages({ platform, id, createdOnOrAfter, createdOnOrBefore }) {
      this.pending.messages = true;
      this.messages = { ...this.messages, [id]: [] };
      this.messagesPaging = { ...this.messagesPaging, [id]: [] };

      const res = await communityApi.getPlatformMessages({
        platform,
        id,
        createdOnOrAfter,
        createdOnOrBefore,
      });
      const { data, paging, triggeredMessageRules } = res.data;
      this.messages = {
        ...this.messages,
        [id]: sortMessages(data.map(parseTimestamps)),
      };
      this.messagesPaging = { ...this.messagesPaging, [id]: paging };

      const interactionRulesStore = useCommunityInteractionRulesStore();
      this.triggeredMessageRules = {
        ...this.triggeredMessageRules,
        [id]: triggeredMessageRules?.length
          ? ruleExecutedMessageFormatter(
              triggeredMessageRules,
              interactionRulesStore.checkRuleDeleted,
              interactionRulesStore.undoAction,
              this.deleteRuleExecutedMessage,
              interactionRulesStore.openRuleBuilderToEdit,
            )
          : [],
      };
      this.pending.messages = false;
    },
    async getPlatformMessagesNextPage({ url }) {
      this.pending.messagesPaging = true;
      const res = await axios.get(url);
      const { data, paging } = res.data;
      if (data.length > 0) {
        const newMessageIds = data.map((message) => message.id);
        const { conversationId } = data[0];
        this.messages = {
          ...this.messages,
          [conversationId]: sortMessages([
            ...(this.messages[conversationId] ?? []).filter(
              (message) => !newMessageIds.includes(message.id),
            ),
            ...data.map(parseTimestamps),
          ]),
        };
        this.messagesPaging = {
          ...this.messagesPaging,
          [conversationId]: paging,
        };
      }
      this.pending.messagesPaging = false;
      return res;
    },
    async updatePlatformMessage({ platform, conversationId, messageId, participantId, data }) {
      // Add reactions to the message optimistically. This makes the reaction experience feel
      // more responsive.
      this.messages = {
        ...this.messages,
        [conversationId]: (this.messages[conversationId] ?? []).map((message) =>
          message.id === messageId ? setReaction(message, data.reaction, participantId) : message,
        ),
      };
      try {
        await communityApi.updateDM({ conversationId, platform, messageId, payload: data });
      } catch {
        if (this.messages[conversationId]?.length > 0) {
          this.messages = {
            ...this.messages,
            [conversationId]: this.messages[conversationId].filter((m) => m.uuid !== data.uuid),
          };
        }
      }
    },
    updatePlatformMessageLocally({ updatedMessage }) {
      this.messages[updatedMessage.conversationId] = (
        this.messages[updatedMessage.conversationId] ?? []
      ).map((message) =>
        // TODO: may make sense to compare uuid instead of id once ch49238 is merged
        message.id === updatedMessage.id ? updatedMessage : message,
      );
    },
    bulkUpdatePlatformMessages({ updates }) {
      updates.forEach((update) => {
        if (this.messages[update.data.conversationId]) {
          applyBulkUpdateToCollection(
            this.messages[update.data.conversationId],
            'uuid',
            update,
            false,
          );
          this.messages[update.data.conversationId] = sortMessages(
            this.messages[update.data.conversationId],
          );
        }
      });
    },
    async createPlatformMessage({ platform, data, conversation, isTranslated = false }) {
      const notificationStore = useNotificationStore();
      const identityStore = useIdentityStore();
      // Optimistically create the message
      this.messages = {
        ...this.messages,
        [conversation.id]: this.messages[conversation.id] ?? [],
      };
      const messageStoreData = {
        ...data,
        conversationId: conversation.id,
        fromParticipantId: conversation.brandParticipantId,
        toParticipantId: conversation.followerParticipantId,
        isPending: true,
        brandUserReply: {
          brandId: identityStore.currentBrand.id,
          userId: identityStore.identity.id,
        },
      };
      messageStoreData[`${platform}CreatedAt`] = dayjs();
      this.messages = {
        ...this.messages,
        [conversation.id]: [...this.messages[conversation.id], messageStoreData],
      };
      try {
        const res = await communityApi.createDM({
          conversationId: conversation.id,
          platform,
          uuid: data.uuid,
          text: data.text,
        });
        if (res.data) {
          const messageId = res.data.data?.id;
          // update translation map with new message id
          if (isTranslated && messageId) {
            const translationStore = useCommunityTranslationStore();
            translationStore.updateTranslationMap(messageId);
          }
          // update optimistically created message with id
          // to check translation map with the id and show translate button
          // without waiting for socket event
          const newMessage = find(this.messages[conversation.id], { uuid: data.uuid });
          if (newMessage) {
            newMessage.id = messageId;
          }
        }
      } catch (e) {
        // Remove optimistically added message
        notificationStore.setToast({
          message: 'Message failed',
          type: 'error',
        });
        this.messages = {
          ...this.messages,
          [conversation.id]: (this.messages[conversation.id] ?? []).filter(
            (m) => m.uuid !== data.uuid,
          ),
        };
      }
    },
    clearPlatformMessages(conversationId) {
      delete this.messages[conversationId];
    },
    deleteRuleExecutedMessage(message) {
      this.triggeredMessageRules[message.interactionId] = this.triggeredMessageRules[
        message.interactionId
      ].filter((t) => t.id !== message.id);
    },
    addRuleExecutionRecord(webhookRuleExecutionRecords, interaction) {
      const interactionRulesStore = useCommunityInteractionRulesStore();
      const newMessages = ruleExecutedMessageFormatter(
        webhookRuleExecutionRecords,
        interactionRulesStore.checkRuleDeleted,
        interactionRulesStore.undoAction,
        this.deleteRuleExecutedMessage,
        interactionRulesStore.openRuleBuilderToEdit,
      );

      if (!this.triggeredMessageRules[interaction.id]) {
        this.triggeredMessageRules[interaction.id] = [];
      }
      this.triggeredMessageRules[interaction.id] = unionBy(
        this.triggeredMessageRules[interaction.id],
        newMessages,
        'id',
      );
    },
  },
});
