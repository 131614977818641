import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as ShortUrlApi from '@/apis/short-url';
import { useAuthStore } from './auth';

export const useShortUrlStore = defineStore('shortUrl', () => {
  const authStore = useAuthStore();

  const shortURL = ref(null);

  // TODO: @storm: deprecated, remove with utmScheduler FF
  async function createShortURL({ url, type, brandId }) {
    const response = await ShortUrlApi.createShortURL({
      url,
      type,
      brandId: brandId ?? authStore.currendBrand?.id,
    });
    const payload = response?.data;
    shortURL.value = payload;
    return payload;
  }

  async function getShortURL({ code }) {
    const response = await ShortUrlApi.getShortURL({ code });
    const payload = response?.data;
    if (payload) {
      shortURL.value = {
        data: payload.shortCode,
        url: payload.url,
        clicks: payload.totalClicks || 0,
      };
    }
    return payload;
  }

  return {
    createShortURL,
    getShortURL,
    shortURL,
  };
});
