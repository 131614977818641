<template>
  <transition name="slide">
    <div v-if="showTopBar" class="ms-bar" data-cy="ms-bar">
      <section v-if="isProducts" class="ms-actions">
        <div v-if="showAddToGalleryControl">
          <a v-tooltip="'Archive'" @click.prevent="showArchiveProductsConfirm">
            <Icon :color="whiteIcon" name="inbox" />
          </a>
          <AddToDropdown ref="archiveProductsDropdown" />
        </div>
      </section>
      <section v-else class="ms-actions">
        <div v-if="showAddToGalleryControl">
          <a
            ref="addToButton"
            v-tooltip="'Add to'"
            class="add-to-dropdown-button"
            @click.prevent="addToGalleryClicked"
          >
            <Icon :color="whiteIcon" name="folderAdd" />
          </a>
          <AddToDropdown ref="addToDropdown" :parent-name="$options.name" />
        </div>
        <AddContentTagsDropdown class="mx-3" @clicked="addContentTagsClicked" />
        <div v-if="addVisualTagsEnabled">
          <a
            ref="addTagsButton"
            v-tooltip="'Add Visual Tags'"
            data-cy="MSBar-AddTags"
            @click.prevent="addVisualTagsClicked"
          >
            <Icon :color="whiteIcon" name="magic-wand-1-add-filled" small />
          </a>
          <AddTagsDropdown ref="addTagsDropdown" />
        </div>
        <div v-if="showRemoveOption">
          <a v-tooltip="removeTooltip" @click.prevent="removeAllClicked">
            <Icon :color="whiteIcon" small name="subtract" />
          </a>
        </div>
        <div v-if="showDeleteOption">
          <a v-tooltip="deleteTooltip" @click.prevent="deleteAllClicked">
            <Icon :color="whiteIcon" name="bin" />
          </a>
        </div>
        <div v-if="hasSchedulerAccess">
          <a
            v-tooltip="addToscheduleTooltip"
            data-cy="MSBar-ScheduleMedia"
            @click.prevent="openScheduleOptionDropdown"
          >
            <Icon :color="whiteIcon" small name="calendar" />
          </a>
          <ul
            v-if="showScheduleOptions"
            v-on-click-outside="closeScheduleOptionDropdown"
            class="options"
            data-cy="ms-bar-dropdown-options"
          >
            <li
              v-for="option in scheduleOptions"
              :key="option.text"
              v-tooltip="option.tooltip ? option.tooltip : null"
              :class="{ disabled: option.disabled }"
              @click="option.disabled ? null : fireAction(option.action)"
            >
              <a>
                <div class="left">
                  <Icon :name="option.icon" small />
                  <p>{{ option.text }}</p>
                </div>
                <Icon v-if="option.subList" name="caret" xxsmall />
              </a>
            </li>
          </ul>
          <transition name="slide">
            <AddToUnscheduled
              v-if="showAddToUnscheduled"
              v-on-click-outside="closeAddToUnscheduled"
              @close="closeAddToUnscheduled"
              @back="openScheduleOptionDropdown"
            />
          </transition>
        </div>
        <template v-if="isNarrative">
          <a v-tooltip="addTooltip" @click.prevent="createStoryClicked">
            <Icon :color="whiteIcon" name="addCircle" />
          </a>
          <a v-tooltip="removeTooltip" @click.prevent="removeAllClicked">
            <Icon :color="whiteIcon" small name="subtract" />
          </a>
        </template>
      </section>
      <section>
        <span>{{ mediaSelectStore.multiSelectSelectedItems.length }} selected</span>
        <a v-tooltip="'Deselect all'" @click="mediaSelectStore.clearItemsFromMultiSelect">
          <Icon :color="whiteIcon" name="close" xsmall />
        </a>
      </section>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useProductStore } from '@/stores/product';
import { constants } from '@/config';
import { colours } from '@/ux/colours';
import enumTypes, { multiSelectTopBarArchiveConfirmationCopy } from '@/app/library/constants';
import AddToDropdown from '@/components/AddToDropdown.vue';
import AddToUnscheduled from '@/components/AddToUnscheduled.vue';
import Icon from '@/components/foundation/Icon.vue';
import AddTagsDropdown from '@/components/AddTagsDropdown.vue';
import AddContentTagsDropdown from '@/components/ContentTags/AddContentTagsDropdown.vue';
import instagramConstants from '@/app/instagram/constants';
import { formatDate } from '@/utils/dateUtils';
import { productFeedType } from '@/utils/productFeed';
import { useSchedulerStore } from '@/stores/scheduler';
import { useCampaignsStore } from '@/stores/campaigns';
import { useNotificationStore } from '@/stores/notification';
import { campaignRouteNames } from '@/app/campaigns/constants';
import { getCompetitorAndUGCHandles } from '@/app/library/utils';
import { useSearchStore } from '@/stores/search';
import { useMediaSelectStore } from '@/stores/media-select';
import { useMediaStore } from '@/stores/media';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useGalleryStore } from '@/stores/gallery';
import { useFlagStore } from '@/stores/flag';
import { useMediaDetailStore } from '@/stores/media-detail';
import { competitiveRouteNames } from '@/app/competitive/constants';
import { useInstagramStoryStore } from '@/stores/instagram-story';
import { usePopupGalleryStore } from '@/stores/popup-gallery';
import { trackMultiSelectActionClicked } from '@/components/Galleries/mixpanel';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MultiSelectTopBar',
  components: {
    AddContentTagsDropdown,
    AddTagsDropdown,
    AddToDropdown,
    AddToUnscheduled,
    Icon,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  beforeRouteLeave(to, from, next) {
    this.mediaSelectStore.clearItemsFromMultiSelect();
    next();
  },
  props: {
    showAddToGalleryControl: { type: Boolean, default: true },
  },
  data() {
    return {
      whiteIcon: colours.BASIC.WHITE,
      showTopBar: false,
      showCreateConfirm: false,
      showArchiveConfirm: false,
      showDeleteConfirm: false,
      showRemoveConfirm: false,
      showLoadingButton: false,
      showScheduleOptions: false,
      showAddToUnscheduled: false,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useSchedulerStore,
      useCampaignsStore,
      useNotificationStore,
      useSearchStore,
      useMediaSelectStore,
      useMediaStore,
      useMediaPopupStore,
      useFlagStore,
      useProductStore,
      useMediaDetailStore,
      useGalleryStore,
      useInstagramStoryStore,
      usePopupGalleryStore,
      useTrackingStore,
    ),
    showConfirmation() {
      return (
        this.showDeleteConfirm ||
        this.showRemoveConfirm ||
        this.showCreateConfirm ||
        this.showArchiveConfirm
      );
    },
    addVisualTagsEnabled() {
      const { meta } = this.$route;
      return (
        !meta.schedulerRoute && !meta.narrativeRoute && !this.mediaSelectStore.isTargetingBrands
      );
    },
    hasSchedulerAccess() {
      return (
        this.authStore.user_can('scheduler', 'can_access_scheduler') &&
        !this.isTikTokBoard &&
        !this.isYouTube &&
        !this.mediaSelectStore.isTargetingBrands
      );
    },
    scheduleOptions() {
      return [
        {
          text: 'Pinterest Pins',
          icon: 'pinterest',
          action: 'schedulePinterest',
        },
        {
          text: 'Unscheduled',
          icon: 'addCalendar',
          action: 'openAddToUnscheduled',
          subList: true,
        },
      ];
    },
    isLibraryGallery() {
      return this.$route.name === 'library.galleries.board';
    },
    isPinterestBoard() {
      return this.$route.name === 'pinterest.galleries.detail';
    },
    isPinterestEngagement() {
      return this.$route.name === 'pinterest.insights.engagement';
    },
    isInstagramBoard() {
      const instagramBoardRouteNames = [
        'instagram.galleries.board',
        competitiveRouteNames.instagram.boards.main,
      ];
      return instagramBoardRouteNames.includes(this.$route.name);
    },
    isInstagramStoryBoard() {
      return this.$route.name === 'instagram.stories.boards.board';
    },
    isInstagramStoryBoardV2() {
      return this.$route.name === 'instagram.yourStories.storyBoardsDetails';
    },
    isTikTokBoard() {
      return this.$route.name === 'tiktok.gallery.detail';
    },
    isFacebookBoard() {
      return this.$route.name === 'facebook.gallery.detail';
    },
    isTwitterBoard() {
      const twitterBoardRouteNames = [
        'twitter.gallery.detail',
        'competitors.twitter.galleries.board',
      ];
      return twitterBoardRouteNames.includes(this.$route.name);
    },
    isYouTubeBoard() {
      const youTubeBoardRouteNames = [
        'youtube.gallery.detail',
        'competitors.youtube.galleries.board',
      ];
      return youTubeBoardRouteNames.includes(this.$route.name);
    },
    isCompetitiveBoard() {
      return this.$route.name === competitiveRouteNames.instagram.boards.main;
    },
    isCampaign() {
      return this.$route.name.includes(campaignRouteNames.CAMPAIGN_DETAIL);
    },
    isYouTube() {
      return this.$route.name.startsWith('youtube');
    },
    isNarrative() {
      return this.$route.meta.narrativeRoute;
    },
    isProducts() {
      return this.$route.name === 'library.products';
    },
    galleryType() {
      if (this.isLibraryGallery) {
        return constants.LIBRARY;
      }
      if (this.isPinterestBoard) {
        return constants.PINTEREST;
      }
      if (this.isInstagramBoard) {
        return constants.INSTAGRAM;
      }
      if (this.isInstagramStoryBoard || this.isInstagramStoryBoardV2) {
        return constants.INSTAGRAM_STORY;
      }
      if (this.isTikTokBoard) {
        return constants.TIKTOK;
      }
      if (this.isFacebookBoard) {
        return constants.FACEBOOK;
      }
      if (this.isTwitterBoard) {
        return constants.TWITTER;
      }
      if (this.isYouTubeBoard) {
        return constants.YOUTUBE;
      }
      if (this.isNarrative) {
        return constants.INSTAGRAM_STORY_NARRATIVE;
      }
      if (this.isCampaign) {
        return constants.CAMPAIGNS;
      }
      return null;
    },
    showRemoveOption() {
      return (
        this.isLibraryGallery ||
        this.isPinterestBoard ||
        this.isInstagramBoard ||
        this.isInstagramStoryBoard ||
        this.isInstagramStoryBoardV2 ||
        this.isFacebookBoard ||
        this.isTikTokBoard ||
        this.isTwitterBoard ||
        this.isYouTubeBoard ||
        this.isCampaign ||
        this.isCompetitiveBoard
      );
    },
    showDeleteOption() {
      // TODO - get source_type from gallery media so the multiselect bar is only hidden from PINTEREST_OWNED and INSTAGRAM_OWNED
      // Enable delete button in multi-select for CS team for demo brands
      if (this.flagStore.flags.enableDeleteForOwnedContent) {
        return true;
      }
      if (this.isPinterestEngagement) {
        return false;
      }
      const deleteForbidden = this.mediaSelectStore.multiSelectSelectedItems.filter(
        (item) =>
          item.source === constants.PINTEREST ||
          item.source === constants.INSTAGRAM ||
          item.source === constants.INSTAGRAM_STORY ||
          item.source === constants.TIKTOK ||
          item.source === constants.LINKEDIN ||
          item.source === constants.YOUTUBE ||
          enumTypes.FACEBOOK_SOURCE_LIST.includes(item.source) ||
          enumTypes.TWITTER_SOURCE_LIST.includes(item.source),
      );
      return deleteForbidden.length === 0;
    },
    deleteTooltip() {
      if (this.$route.meta.schedulerRoute) {
        return 'Remove from scheduler';
      }
      return 'Delete Selected Media';
    },
    deleteMessage() {
      if (this.$route.meta.schedulerRoute) {
        return 'Delete posts?';
      }
      return 'Delete media?';
    },
    addTooltip() {
      if (this.isNarrative) {
        return 'Create new story with selected frames';
      }
      return '';
    },
    removeTooltip() {
      if (this.isPinterestBoard) {
        return 'Remove selected media from DH Board';
      }
      if (this.isLibraryGallery) {
        return 'Remove selected media from gallery';
      }
      if (this.isNarrative || this.isInstagramStoryBoardV2) {
        return 'Remove selected frames from story';
      }
      if (this.isTikTokBoard) {
        return 'Remove selected videos from Board';
      }
      if (this.isYouTubeBoard) {
        return 'Remove selected videos from Board';
      }
      if (this.isCampaign) {
        return 'Remove selected media from Campaign';
      }
      return 'Remove selected media from Board';
    },
    removeMessage() {
      if (this.isPinterestBoard) {
        return 'Remove media from DH Board?';
      }
      if (this.isLibraryGallery) {
        return 'Remove media from gallery?';
      }
      if (this.isNarrative || this.isInstagramStoryBoardV2) {
        return "Removed frames will still be available in the 'Story Frames' section.";
      }
      if (this.isCampaign) {
        return 'Remove media from Campaign?';
      }
      return 'Remove media from Board?';
    },
    confirmationContent() {
      if (this.showCreateConfirm) {
        return {
          title: 'Create New Story?',
          message: 'Creating a new story will remove the selected story frames from this story.',
          confirmAlias: 'Create New Story',
          confirmType: 'primary',
          onConfirm: this.confirmCreateNewStory,
        };
      }
      if (this.showRemoveConfirm) {
        return {
          title: this.isNarrative || this.isInstagramStoryBoardV2 ? 'Remove Story Frames?' : '',
          message: this.removeMessage,
          confirmAlias:
            this.isNarrative || this.isInstagramStoryBoardV2 ? 'Remove Frames' : 'Remove',
          onConfirm: this.confirmRemove,
          largerText: true,
        };
      }
      if (this.showDeleteConfirm) {
        return {
          title: this.deleteMessage,
          confirmAlias: 'Delete',
          onConfirm: this.confirmDelete,
        };
      }
      if (this.showArchiveConfirm) {
        return {
          title: multiSelectTopBarArchiveConfirmationCopy.title,
          message: multiSelectTopBarArchiveConfirmationCopy.message,
          confirmAlias: 'Archive',
          onConfirm: this.archiveProducts,
        };
      }
      return {};
    },
    addToscheduleTooltip() {
      return 'Schedule Media';
    },
    getFirstFramePublishedTime() {
      let firstFramePublishedTime;
      const mediaTimestamps = this.mediaSelectStore.multiSelectSelectedItems
        .map((media) => media.source_created_at)
        .sort();
      if (this.mediaSelectStore.multiSelectSelectedItems.length > 0) {
        [firstFramePublishedTime] = mediaTimestamps;
      }
      return firstFramePublishedTime;
    },
    getSelectedItems() {
      return this.mediaSelectStore.multiSelectSelectedItems.map((media) => media.id);
    },
    getNarrativeStoryName() {
      return formatDate(new Date(this.getFirstFramePublishedTime), 'PP');
    },
  },
  watch: {
    'mediaSelectStore.multiSelectSelectedItems.length': function multiSelectStatusWatcher(to) {
      if (to) {
        this.showTopBar = true;
      } else {
        this.showTopBar = false;
      }
    },
    'mediaDetailStore.pending.bulkDeleteMediaStatus': function bulkDeleteMediaStatusWatcher(to) {
      if (!to && this.mediaSelectStore.multiSelectSelectedItems.length) {
        setTimeout(() => {
          this.showLoadingButton = false;
          this.showDeleteConfirm = false;
          if (
            this.$route.name === 'library.index' ||
            this.$route.name === 'instagram.galleries.board'
          ) {
            this.searchStore.clearMediaSearchList();
          } else if (this.$route.name === 'library.galleries.board') {
            this.popupGalleryStore.clearGalleryMedia();
          }
          this.mediaSelectStore.clearItemsFromMultiSelect();
          this.notificationStore.setToast({ message: 'Your media has been deleted.' });
        }, 1000);
      }
    },
    'mediaDetailStore.error.bulkDeleteMediaStatusWatcher': function deleteMediaItemErrorWatcher(
      to,
    ) {
      if (to) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'warning',
        });
      }
    },
    'galleryStore.error.removeMediaStatus': function removeMediaFromGalleryErrorWatcher(to) {
      if (to) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'warning',
        });
      }
    },
    'galleryStore.pending.removeMediaStatus': function removeMediaFromGalleryWatcher(to) {
      if (!to && !this.galleryStore.error.removeMediaStatus) {
        this.mediaSelectStore.clearItemsFromMultiSelect();
        this.$router.push({ name: this.$route.name });
        if (this.isLibraryGallery) {
          this.notificationStore.setToast({
            message: 'Media has been removed from gallery.',
          });
          this.galleryStore.gallery = null;
          this.galleryStore.clearGalleryV2();
        } else if (this.isInstagramStoryBoardV2) {
          this.notificationStore.setToast({
            message: 'Story frames have been removed from Board.',
          });
          this.galleryStore.gallery = null;
          this.galleryStore.clearGalleryV2();
        } else {
          this.notificationStore.setToast({ message: 'Media has been removed from Board.' });
          this.galleryStore.gallery = null;
          this.galleryStore.clearGalleryV2();
        }
      }
    },
    'campaignsStore.pending.removeMediaFromCampaign': function removeMediaFromCampaignWatcher(to) {
      if (!to && !this.campaignsStore.error.removeMediaFromCampaign) {
        this.mediaSelectStore.clearItemsFromMultiSelect();
        this.$router.push({ name: this.$route.name });
        this.notificationStore.setToast({
          message: 'Media has been removed from Campaign.',
        });
        this.campaignsStore.clearCampaignChannelMedia();
      }
    },
    'campaignsStore.error.removeMediaFromCampaign': function removeMediaFromGalleryErrorWatcher(
      to,
    ) {
      if (to) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'warning',
        });
      }
    },
    $route() {
      this.mediaSelectStore.clearItemsFromMultiSelect();
    },
    async showConfirmation(value) {
      if (value) {
        const { title, message, confirmAlias, confirmType, onConfirm, largerText } =
          this.confirmationContent;

        await this.notificationStore.confirm(title, message, {
          confirmAlias,
          confirmType,
          onConfirm,
          onCancel: this.cancelConfirm,
          largerText,
        });
      }
    },
  },
  mounted() {
    this.showTopBar = this.mediaSelectStore.multiSelectSelectedItems?.length > 0;
  },
  methods: {
    addToGalleryClicked() {
      this.$refs.addToDropdown.openAddToDropdown();
      trackMultiSelectActionClicked('Add To', this.getSelectedItems.length);
    },
    addContentTagsClicked() {
      trackMultiSelectActionClicked('Add Content Tags', this.getSelectedItems.length);
    },
    addVisualTagsClicked() {
      if (this.$refs.addTagsDropdown) {
        this.$refs.addTagsDropdown.dropdownOpen = true;
      }
      trackMultiSelectActionClicked('Add Visual Tags', this.getSelectedItems.length);
    },
    createStoryClicked() {
      this.showCreateConfirm = true;
    },
    deleteAllClicked() {
      this.showDeleteConfirm = true;
      trackMultiSelectActionClicked('Delete', this.getSelectedItems.length);
    },
    cancelConfirm() {
      this.showDeleteConfirm = false;
      this.showRemoveConfirm = false;
      this.showCreateConfirm = false;
      this.showArchiveConfirm = false;
      this.showLoadingButton = false;
    },
    async confirmDelete() {
      this.showLoadingButton = true;
      if (this.$route.meta.schedulerRoute) {
        const promises = this.mediaSelectStore.multiSelectSelectedItems.map(async (item) => {
          const { id } = item;
          const platform = this.$route.name.split('.')[1];
          this.schedulerStore.deletePost({ platform, id });
          return item;
        });
        await Promise.all(promises).then(() => {
          this.deletePostSuccessCallback();
        });
      } else {
        await this.mediaDetailStore.bulkDeleteMedia({
          brandIds: this.mediaSelectStore.isTargetingBrands
            ? this.mediaSelectStore.targetBrands
            : [this.authStore.currentBrand.id],
          mediaIds: this.mediaSelectStore.multiSelectSelectedItems.map((item) => item.id),
        });
        this.deletePostSuccessCallback();
      }
    },
    removeAllClicked() {
      this.showRemoveConfirm = true;
      trackMultiSelectActionClicked('Remove', this.getSelectedItems.length);
    },
    async confirmRemove() {
      this.showRemoveConfirm = false;
      const selectedMediaIDs = this.mediaSelectStore.multiSelectSelectedItems.map((mediaItem) => {
        return mediaItem.id;
      });
      const mediaList = this.mediaSelectStore.multiSelectSelectedItems;
      let removePromises = [];
      const galleryId = this.$route.params.id;

      if (this.isNarrative) {
        const properties = {
          storyName: this.getFirstFramePublishedTime,
          storyId: this.$route.params.id,
          framesSelected: this.getSelectedItems.join(','),
          totalFramesSelected: this.mediaSelectStore.multiSelectSelectedItems.length,
          storyDeleted:
            this.mediaSelectStore.multiSelectSelectedItems.length ===
            this.instagramStoryStore.narrative?.gallerySize
              ? 'yes'
              : 'no',
        };
        this.trackingStore.track('Story Remove Frames', properties);
      }
      if (
        this.isLibraryGallery ||
        this.isInstagramBoard ||
        this.isInstagramStoryBoard ||
        this.isInstagramStoryBoardV2 ||
        this.isTikTokBoard ||
        this.isFacebookBoard ||
        this.isTwitterBoard ||
        this.isYouTubeBoard ||
        this.isPinterestBoard ||
        this.isCompetitiveBoard
      ) {
        removePromises = [
          await this.galleryStore.removeBulkMediaFromGallery({
            galleryId,
            mediaIds: selectedMediaIDs,
          }),
        ];
      } else if (this.isCampaign) {
        const campaignParams = {
          campaign: this.campaignsStore.campaign,
          media: this.mediaSelectStore.multiSelectSelectedItems,
        };
        removePromises = [await this.campaignsStore.removeMediaFromCampaign(campaignParams)];
      } else {
        // remove media is done one by one, therefore use promises.all
        removePromises = this.mediaSelectStore.multiSelectSelectedItems.map(async (item) => {
          if (this.isNarrative) {
            await this.instagramStoryStore.removeFrameFromNarrative({
              galleryId,
              mediaId: item.id,
            });
          }
        });
      }
      let storyBoardsParams;
      if (this.isInstagramStoryBoardV2) {
        storyBoardsParams = this.instagramStoryStore.storyBoardsFilters;
      } else if (this.isNarrative) {
        storyBoardsParams = this.instagramStoryStore.storyNarrativeFilters;
      }
      // Force refresh.
      if (storyBoardsParams) {
        storyBoardsParams.reload = true;
        this.instagramStoryStore.setStoryBoardsParams({ storyBoardsParams });
      }
      if (!this.isCampaign) {
        const eventParams = {};
        if (this.isInstagramBoard) {
          const brandMedia = getCompetitorAndUGCHandles(mediaList);
          eventParams.ugcMedia = brandMedia.ugc;
          eventParams.competitorMedia = brandMedia.competitor;
        }
        this.trackingStore.track('Board Media Removed', {
          ...eventParams,
          galleryType: this.galleryType,
          mediaRemoved: selectedMediaIDs.length,
          mediaIDs: selectedMediaIDs,
          galleryID: this.$route.params.id,
          ownedMedia: mediaList.filter((media) => media.source_type === enumTypes.OWNED).length,
        });
      }
      await Promise.all(removePromises);
      this.handlePostRemoveProcess();
    },
    deletePostSuccessCallback() {
      if (this.$route.meta.schedulerRoute) {
        this.showLoadingButton = false;
        this.showDeleteConfirm = false;
        this.mediaSelectStore.clearItemsFromMultiSelect();
        this.notificationStore.setToast({ message: 'Your posts have been deleted.' });
      }
    },
    handlePostRemoveProcess() {
      if (!this.isNarrative) return;
      this.mediaSelectStore.clearItemsFromMultiSelect();
      const galleryId = this.$route.params.id;
      if (
        this.instagramStoryStore.narrativeMedia.length === 0 &&
        this.instagramStoryStore.narrativeMediaFullyLoaded
      ) {
        this.galleryStore.deleteGallery({ galleryId });
        this.$router.push({ name: instagramConstants.routeNames.STORY_OVERVIEW });
      } else {
        this.instagramStoryStore.getNarrative({ galleryId }).then(() => {
          this.mediaPopupStore.setMediaContextList({
            mediaContextList: this.instagramStoryStore.narrativeMedia,
          });
        });
        this.cancelConfirm();
      }
    },
    async confirmCreateNewStory() {
      const firstFramePublishedTime = this.getFirstFramePublishedTime;
      const mediaIds = this.getSelectedItems;
      this.showLoadingButton = true;
      const storyName = this.getNarrativeStoryName;
      await this.instagramStoryStore
        .createNarrative({
          name: `${new Date(firstFramePublishedTime).toLocaleString('default', {
            month: 'short',
            day: '2-digit',
          })}`,
        })
        .then(async (payload) => {
          const galleryId = payload?.id;
          await this.instagramStoryStore.addFrameToNarrative({
            galleryId,
            mediaIds,
          });
          const removePromises = mediaIds.map(async (mediaId) => {
            await this.instagramStoryStore.removeFrameFromNarrative({
              mediaId,
              galleryId: this.$route.params.id,
            });
          });
          await Promise.all(removePromises);
          this.handlePostRemoveProcess();
          const storyBoardsParams = this.instagramStoryStore.storyNarrativeFilters;
          // Force refresh.
          if (storyBoardsParams) {
            storyBoardsParams.reload = true;
            this.instagramStoryStore.setStoryBoardsParams({ storyBoardsParams });
          }
          this.notificationStore.setToast({
            actionText: 'New Story Created!',
            actionTo: {
              name: instagramConstants.routeNames.STORY_NARRATIVE,
              params: { id: galleryId },
            },
            sameLine: true,
          });
          const properties = {
            storyId: galleryId,
            storyName,
            totalFramesSelected: mediaIds.length,
            framesAdded: mediaIds.join(','),
          };
          this.trackingStore.track('Story Create New Story', properties);
        })
        .finally(() => {
          this.cancelConfirm();
        });
    },
    fireAction(actionName) {
      this[actionName]();
    },
    openScheduleOptionDropdown() {
      this.showScheduleOptions = true;
      this.showAddToUnscheduled = false;
      if (this.$refs.addTagsDropdown) {
        this.$refs.addTagsDropdown.dropdownOpen = false;
      }
      if (this.$refs.addToDropdown) {
        this.$refs.addToDropdown.showAddToDropdown = false;
      }
      trackMultiSelectActionClicked('Schedule', this.getSelectedItems.length);
    },
    closeScheduleOptionDropdown() {
      this.showScheduleOptions = false;
    },
    openAddToUnscheduled() {
      this.showAddToUnscheduled = true;
      this.showScheduleOptions = false;
    },
    closeAddToUnscheduled() {
      this.showAddToUnscheduled = false;
    },
    async schedulePinterest() {
      const mediaToSchedule = this.mediaSelectStore.multiSelectSelectedItems
        .map((item) => {
          if (this.isNonMediaPost(item)) return null;
          // item can be media object or post object, only post has media_ids field
          if (item.media_ids != null || item.mediaIds != null) {
            // get media id from post object
            if (item.media && item.media.length > 0) {
              return { id: item.media[0].id };
            }
            return null;
          }
          // return self if it is media object
          return item;
        })
        .filter((item) => item !== null);

      // Validate that media is in MediaV2 format before passing media to the scheduler popup
      const mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: mediaToSchedule,
      });
      this.$router.push({
        name: 'scheduler.pinterest.posts.new.bulk',
        query: {
          mediaIds: (mediaList ?? []).map((m) => m?.id),
        },
      });
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.showScheduleOptions = false;
    },
    showArchiveProductsConfirm() {
      this.showArchiveConfirm = true;
    },
    async archiveProducts() {
      this.showLoadingButton = true;
      const productIds = this.mediaSelectStore.multiSelectSelectedItems.map(
        (product) => product.id,
      );

      try {
        const res = await this.productStore.updateProductArchivedStatusByIds({
          brandId: this.authStore.currentBrand.id,
          productIds,
          archived: true,
        });
        const rowsAffected = res.data.rows;
        const keyword = rowsAffected === 1 ? 'product' : 'products';
        const archiveSuccessMessage = `${rowsAffected} ${keyword} archived`;

        const data = this.mediaSelectStore.multiSelectSelectedItems.map((selectedProduct) => {
          return {
            feedType: productFeedType(selectedProduct),
            productCatalog: selectedProduct?.productFeed?.productCatalog?.name ?? null,
            productId: selectedProduct?.id,
            productName: selectedProduct?.title ?? null,
          };
        });

        this.trackingStore.track('Product Archive Product', data);

        this.mediaSelectStore.clearItemsFromMultiSelect();
        this.showArchiveConfirm = false;
        this.notificationStore.setToast({ message: archiveSuccessMessage });
      } catch (e) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'warning',
        });
        throw e;
      }
      this.showLoadingButton = false;
    },
    isNonMediaPost(post) {
      if (post?.source === 'FACEBOOK_LINK') {
        return false;
      }
      return post?.source?.includes('_TEXT') || post?.source?.includes('_LINK');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.ms-bar {
  height: var(--space-64);
  width: 100%;
  z-index: var(--z-index-toolbar);
  background: var(--action-500);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: var(--light-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-48);

  .product-confirmation :deep(h3) {
    text-transform: none;
  }

  section {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      position: relative;
    }

    span {
      margin: 0 var(--space-8) var(--space-4);
    }

    a {
      margin: 0 var(--space-12);
      color: white;
      position: relative;

      .svg-icon {
        pointer-events: none;
      }
    }

    .add-to-dropdown {
      position: absolute;
      top: var(--space-32);
      left: var(--space-12);

      input {
        z-index: var(--z-index-raised);
      }
    }

    .add-tags-dropdown {
      position: absolute;
      top: var(--space-32);
      left: var(--space-12);
    }

    .options {
      position: absolute;
      top: var(--space-32);
      left: var(--space-12);
      background: var(--background-0);
      padding: var(--space-8) var(--space-16);
      border-radius: var(--round-corner-small);
      box-shadow: var(--shadow-4);

      li {
        min-width: 10rem;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        height: var(--space-40);
        align-items: center;

        a {
          width: 100%;
          color: var(--text-primary);
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: var(--x14);
          margin: 0;
          vertical-align: center;

          .svg-icon {
            box-sizing: content-box;
            margin-top: 1px;
          }

          p {
            margin-left: var(--space-8);
          }

          &:hover {
            color: var(--action-500);
          }

          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  button {
    width: 40%;
  }
}
</style>
