/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'anysize': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 2a1 1 0 110-2h4a1 1 0 110 2H3zm8 0h4a1 1 0 000-2h-4a1 1 0 000 2zm8 0h2a1 1 0 01.867.5 1 1 0 101.73-1.001A2.999 2.999 0 0021 0h-2a1 1 0 000 2zm3 3.939v4a1 1 0 002 0v-4a1 1 0 00-2 0zm0 8v4a1 1 0 102 0v-4a1 1 0 00-2 0zm-.11 7.517A1 1 0 0121 22h-1.877a1 1 0 000 2H21a3 3 0 002.67-1.63 1 1 0 10-1.78-.914zM15.124 22h-4a1 1 0 000 2h4a1 1 0 100-2zm-8 0h-4a1 1 0 000 2h4a1 1 0 000-2zM2 20.184v-4a1 1 0 10-2 0v4a1 1 0 002 0zm0-8v-4a1 1 0 10-2 0v4a1 1 0 002 0zm0-8a1 1 0 11-2 0V3A3 3 0 012.606.026a1 1 0 01.26 1.983A1 1 0 002 3v1.184z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
