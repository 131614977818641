<template>
  <div class="connection">
    <div class="top-bar">
      <img alt="Dash Hudson" :src="IMG.logoDhDark" />
    </div>
    <div class="content">
      <img :src="IMG.emptyKanpai" />
      <h4 class="first-line-title">Thank You!</h4>
      <slot name="connectionSuccessTitle"></slot>
      <slot :open-article="openArticle" name="connectionSuccessContent"></slot>
      <slot name="popup"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ConnectionSuccess',
  computed: {
    IMG() {
      return IMG;
    },
  },
  methods: {
    openArticle(link) {
      window.open(link, '_blank');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.connection {
  background: var(--background-300);
  width: 100%;
  position: absolute;
  text-align: center;

  .top-bar {
    padding-top: var(--space-24);
    padding-bottom: var(--space-24);
    background: var(--background-0);
    display: flex;
    justify-content: center;
    box-shadow: var(--shadow-3);
    border: 0;

    img {
      height: 0.75rem;
    }
  }

  .content {
    img {
      height: 10rem;
      margin-top: 4rem;
    }

    .first-line-title {
      margin-top: var(--space-32);
    }

    h4 {
      span {
        font-weight: var(--font-medium);
      }
    }

    p {
      font-size: var(--x16);
      color: var(--text-primary);
      max-width: 30rem;
      margin: var(--space-24) auto 3rem;
    }

    hr {
      max-width: 30rem;
    }

    .svg-icon {
      margin-right: var(--space-8);
    }

    .caption {
      margin: 0 auto var(--space-24);

      span {
        color: var(--action-500);
      }
    }

    .blog-article {
      border-radius: var(--round-corner-small);
      background-image: url('@/assets/img/article-growreach.png');
      background-size: cover;
      width: 28rem;
      margin: 3rem auto var(--space-16);
      padding: var(--space-24) var(--space-16) var(--space-16) var(--space-24);
      cursor: pointer;

      p {
        text-align: left;
        color: white;
        margin: 0;
        font-size: var(--x16);
        cursor: pointer;

        a {
          text-decoration: none;
          color: white;
          font-weight: var(--font-normal);
        }

        span {
          font-size: var(--x22);
        }

        &:hover {
          transform: var(--hover-move);
          transition-duration: 0.3s;
        }
      }
    }
  }
}
</style>
