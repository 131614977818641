/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hideChatHistory': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M9.254 11.014a7.913 7.913 0 00-1.09 1.977l-1.336-1.247v4.274l3.647-.684-1.1-1.204c.201-.712.51-1.37.925-1.976l.015-.022-1.06-1.118zM13.063 9.82l-1.092-1.152a8.221 8.221 0 013.974-1.002c1.151 0 2.232.214 3.24.642a8.47 8.47 0 012.672 1.798 8.684 8.684 0 011.78 2.653c.44 1.021.66 2.107.66 3.259a8.086 8.086 0 01-.588 3.08c-.182.447-.395.87-.638 1.27l-1.092-1.151a7.16 7.16 0 00.323-.706 6.52 6.52 0 00.48-2.493c0-.938-.183-1.823-.552-2.654a6.86 6.86 0 00-1.46-2.172 6.717 6.717 0 00-2.172-1.478 6.664 6.664 0 00-2.653-.534c-.784 0-1.526.125-2.226.374a7.241 7.241 0 00-.656.266zM11.32 13.192c-.016.088-.024.18-.024.272v4.52c0 .806.653 1.46 1.458 1.46H15.9l.832.642c.418.323.918.375 1.349.23l-2.126-2.24h-3.2a.091.091 0 01-.091-.091v-3.378l-1.342-1.415z" _fill="#666"/><path pid="1" d="M16.435 13.373l-1.298-1.367h3.999c.806 0 1.459.653 1.459 1.458v4.294l-1.368-1.441v-2.853c0-.05-.04-.09-.091-.09h-2.701zM19.527 21.842c-.14.087-.283.17-.43.248a6.96 6.96 0 01-2.404.712v1.531a8.055 8.055 0 002.991-.854 8.59 8.59 0 00.902-.521l-1.06-1.116zM15.18 22.802a6.659 6.659 0 01-.677-.107 5.963 5.963 0 01-.66-.178l-.622 1.389c.32.107.64.196.961.267.32.071.653.125.997.16v-1.531zM12.473 21.894a6.749 6.749 0 01-.57-.374 8.396 8.396 0 01-.534-.428l-1.14 1.015c.25.226.51.44.784.641.26.202.54.386.837.552l.623-1.406zM10.372 19.953a6.05 6.05 0 01-.66-1.14 5.81 5.81 0 01-.427-1.282H7.718a8.03 8.03 0 00.57 1.834c.261.57.576 1.104.944 1.603l1.14-1.015z" _fill="#666"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M8.966 8.106a.717.717 0 011.013.026l13.266 13.983a.717.717 0 11-1.04.987L8.938 9.119a.717.717 0 01.027-1.013z" _fill="#666"/>'
  }
})
