/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'question-mark': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.333 14c0 6.434 5.234 11.667 11.667 11.667S25.667 20.434 25.667 14c0-6.433-5.234-11.667-11.667-11.667S2.334 7.568 2.334 14zm2.334 0c0-5.146 4.187-9.333 9.333-9.333S23.333 8.854 23.333 14 19.146 23.333 14 23.333 4.667 19.146 4.667 14zm8.166 3.5V14H14a2.334 2.334 0 10-2.333-2.333H9.333A4.673 4.673 0 0114 7a4.673 4.673 0 014.667 4.667c0 2.17-1.49 3.999-3.5 4.52V17.5h-2.334zM14 21.292a1.458 1.458 0 100-2.917 1.458 1.458 0 000 2.917z" _fill="#686a7a"/><mask id="svgicon_question-mark_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="24" height="24"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M2.333 14c0 6.434 5.234 11.667 11.667 11.667S25.667 20.434 25.667 14c0-6.433-5.234-11.667-11.667-11.667S2.334 7.568 2.334 14zm2.334 0c0-5.146 4.187-9.333 9.333-9.333S23.333 8.854 23.333 14 19.146 23.333 14 23.333 4.667 19.146 4.667 14zm8.166 3.5V14H14a2.334 2.334 0 10-2.333-2.333H9.333A4.673 4.673 0 0114 7a4.673 4.673 0 014.667 4.667c0 2.17-1.49 3.999-3.5 4.52V17.5h-2.334zM14 21.292a1.458 1.458 0 100-2.917 1.458 1.458 0 000 2.917z" _fill="#fff"/></mask><g mask="url(#svgicon_question-mark_a)"><path pid="2" _fill="#686a7a" d="M-3.5-5.833h37.333V31.5H-3.5z"/></g>'
  }
})
