/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tagAlert': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#686a7a"><path pid="0" d="M10 16.667a.831.831 0 00.59-.245l5.833-5.833a.832.832 0 000-1.178L7.256.244A.83.83 0 006.666 0H.834A.833.833 0 000 .833v5.834a.83.83 0 00.244.589l9.167 9.166a.831.831 0 00.59.245zM14.655 10L10 14.655 1.667 6.322V1.667h4.655L14.655 10z"/><path pid="1" d="M4.166 5.833a1.667 1.667 0 110-3.333 1.667 1.667 0 010 3.333zM18.333 11.666H20v5h-1.667v-5zM19.167 19.167a.833.833 0 110-1.667.833.833 0 010 1.667z"/></g>'
  }
})
