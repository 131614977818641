import { defineStore } from 'pinia';
import { watch, ref, computed } from 'vue';
import padStart from 'lodash/padStart';
import snakeCase from 'lodash/snakeCase';
import { logger } from '@/utils/logger';
import { useFlagStore } from '@/stores/flag';
import { useMixpanelStore } from '@/stores/mixpanel';
// eslint-disable-next-line import/no-cycle
import { useIntercomStore } from '@/stores/intercom';
import { useFullstoryStore } from '@/stores/fullstory';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useDashboardPermissionsStore } from '@/stores/dashboard-permissions';
import { BRAND, ORGANIZATION, ORGANIZATION_USER, USER } from '@/models/auth/permissions.enum';
import { useIdentityStore } from '@/stores/identity';
import { createQueryObject } from '@/utils/query';
import { getUserTimezone } from '@/utils/timezone';

const IGNORED_ANALYTIC_ROUTE_NAMES = [
  'pinterest.insights.engagement.report',
  'pinterest.insights.pins.report',
  'pinterest.galleries.boards.report',
  'scheduler.instagram.report',
  'scheduler.instagram.feed.report',
  'scheduler.pinterest.report',
  'scheduler.twitter.report',
  'scheduler.facebook.report',
];

const PAGE_NAMES = {
  DASHBOARDS: 'Dashboards',
  LIBRARY: 'Library',
};

const utmQueryParams = {};
const query = createQueryObject(document.URL);
const UTM_PARAMETER_NAMES = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
UTM_PARAMETER_NAMES.forEach((utmParamName) => {
  const value = query[utmParamName];
  if (value) {
    utmQueryParams[utmParamName] = value;
  }
});

export const useTrackingStore = defineStore('tracking', () => {
  const identityStore = useIdentityStore();
  const mixpanelStore = useMixpanelStore();
  const intercomStore = useIntercomStore();
  const fullstoryStore = useFullstoryStore();
  const impersonatorStore = useImpersonatorStore();
  const dashboardPermissionsStore = useDashboardPermissionsStore();
  const superProperties = ref({});

  const utmQueryParamsProps = computed(() => {
    const firstTouchParams = Object.keys(utmQueryParams).reduce((acc, value) => {
      acc[`${value} [first touch]`] = utmQueryParams[value];
      return acc;
    }, {});
    const lastTouchParams = Object.keys(utmQueryParams).reduce((acc, value) => {
      acc[`${value} [last touch]`] = utmQueryParams[value];
      return acc;
    }, {});
    return {
      firstTouchParams,
      lastTouchParams,
    };
  });
  const currentRoute = ref(null);
  const sessionId = ref(null);
  const sessionProps = computed(() => {
    return {
      ddRumSessionId: sessionId.value,
    };
  });
  const impersonatingProps = computed(() => {
    return {
      impersonating: impersonatorStore.isImpersonating,
      impersonator_id: impersonatorStore.impersonatorUserId,
      impersonated_id: impersonatorStore.impersonatedUserId,
    };
  });

  const commonIdentityProps = computed(() => {
    const identity = identityStore.identity;
    return {
      timezone: getUserTimezone(),
      user_organization_name: identity?.organization?.name,
      organizationId: identity.organization?.id,
      organizationName: identity.organization?.name,
      organizationCustomerStage: identity.organization?.customer_stage,
    };
  });

  function subscriptionMixpanelProperties(subscription) {
    return {
      brand: subscription.brandId,
      organization: subscription.organizationId,
      dh_product_id: subscription.dhProduct.id,
      dh_product_name: subscription.dhProduct.name,
      is_trial: subscription.isTrial,
    };
  }

  const currentBrandSubscriptionProp = computed(() => {
    if (!identityStore.currentSubscriptions) {
      return {};
    }
    const subscriptions = [];
    const brandSubscriptions = [];
    const brandTrialSubscriptions = [];
    const orgSubscriptions = [];
    const orgTrialSubscriptions = [];

    identityStore.currentSubscriptions?.forEach((subscription) => {
      const mpProps = subscriptionMixpanelProperties(subscription);
      if (subscription.brandId && subscription.isTrial) {
        brandTrialSubscriptions.push(mpProps);
      } else if (subscription.brandId) {
        brandSubscriptions.push(mpProps);
      } else if (subscription.organizationId && subscription.isTrial) {
        orgTrialSubscriptions.push(mpProps);
      } else if (subscription.organizationId) {
        orgSubscriptions.push(mpProps);
      }
      subscriptions.push(mpProps);
    });

    return {
      subscriptions,
      brandSubscriptions,
      brandTrialSubscriptions,
      orgSubscriptions,
      orgTrialSubscriptions,
    };
  });

  const currentBrandProps = computed(() => {
    const currentBrand = identityStore.currentBrand;

    return {
      brand_id: currentBrand?.id,
      brandName: currentBrand?.name,
      customerStage: currentBrand?.plan?.customer_stage,
      prospectJourney: currentBrand?.plan?.prospect_journey,
      planType: currentBrand?.plan?.plan_type,
      isActive: currentBrand?.isActive ?? currentBrand?.is_active,
      brandOrganizationId: currentBrand?.organizationId,
      brandOrganizationName: currentBrand?.organization?.name,
      companyId: currentBrand?.organization?.company?.id,
      companyLifecycleStage: currentBrand?.organization?.company?.hubspot_lifecycle_stage,
      companyName: currentBrand?.organization?.company?.name,
      ...currentBrandSubscriptionProp.value,
    };
  });

  function setSessionId(value) {
    sessionId.value = value;
  }

  function track(eventName, ...args) {
    return mixpanelStore.track(eventName, ...args);
  }

  function parsePageLevels(page, prefix = `pageLevel`) {
    const levels = page?.split('-').map((level) => level.trim()) ?? [];
    return levels.reduce((results, level, index) => {
      results[`${prefix}${padStart(index, 2, '0')}`] = level;
      return results;
    }, {});
  }

  function shouldRecordPageViewedEvent(to, from) {
    const initialRoute = to?.path === '/' && from?.path === null;
    const samePath = to?.path === from?.path;
    const ignoredRoute = IGNORED_ANALYTIC_ROUTE_NAMES.some(
      (name) => to?.name?.toLowerCase() === name.toLowerCase(),
    );
    return !initialRoute && !samePath && !ignoredRoute;
  }

  function hasPageAccess(pageName) {
    let pageAccess;
    const permission = `CAN_ACCESS_${snakeCase(pageName).toUpperCase()}`;
    const feature = Object.entries(BRAND).find(([, value]) =>
      Object.keys(value).some((perm) => perm === permission),
    )?.[0];
    if (pageName === PAGE_NAMES.DASHBOARDS) {
      pageAccess = dashboardPermissionsStore.canAccessDashboards;
    } else if (pageName === PAGE_NAMES.LIBRARY) {
      pageAccess = identityStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    } else if (feature) {
      pageAccess =
        [BRAND, USER].every((entity) => identityStore.guard(entity?.[feature]?.[permission])) ||
        [ORGANIZATION, ORGANIZATION_USER].every((entity) =>
          identityStore.guard(entity?.[feature]?.[permission]),
        );
    } else {
      pageAccess = true;
    }
    return pageAccess;
  }

  function generatePageName(route) {
    if (route) {
      if (route.meta?.analytics) {
        return route.meta.analytics;
      }
      const rootRoute = route.path === '/';
      if (!rootRoute) {
        logger.warn(
          `Route meta.analytics not set for '${route.name} - ${route.path}'.  Please set this to report correct analytics to mixpanel.`,
        );
      }
    }
    return null;
  }

  function trackPageViewedEvent(to, from, { unload = false } = {}) {
    if (shouldRecordPageViewedEvent(to, from)) {
      currentRoute.value = to;

      const page = generatePageName(to);
      const previousPage = generatePageName(from);
      const pageLevels = parsePageLevels(page);
      const previousPageLevels = parsePageLevels(previousPage, 'previousPageLevel');

      const payload = {
        // New Route
        page: page || to?.path || null,
        path: to?.path || null,
        routeName: to?.name || null,
        fullPath: to?.fullPath || null,
        ...pageLevels,

        // Previous Route
        previousPage: previousPage || from?.path || null,
        previousPath: from?.path || null,
        previousRouteName: from?.name || null,
        previousFullPath: from?.fullPath || null,
        ...previousPageLevels,
      };

      if (to?.meta?.socialMediaType) {
        payload.platform = to.meta.socialMediaType.toLowerCase();
      }

      payload.pageAccess = hasPageAccess(pageLevels.pageLevel00);
      payload.previousPageAccess = hasPageAccess(previousPageLevels.previousPageLevel00);

      // After the visual bridge changes we will be handling nav tracking within the
      // primary/secondary nav components
      // Should be able to remove this block after release
      if (!useFlagStore().flags?.visualBridge) {
        // Replaces the click event in ProductNav component.
        // This is done to avoid the race condition between the execution of "Navigation Heading Selected" event and "Page Viewed event"
        if (to?.path.split('/')[2] !== from?.path.split('/')[2]) {
          mixpanelStore.track('Navigation Heading Selected', {
            itemSelected: page,
            secondary_item_selected: null,
            page: to?.path,
          });
        }
      }

      mixpanelStore.trackPage({ page, payload, unload });
    }
  }

  function setupUnloadListener(router) {
    window.addEventListener(`beforeunload`, () => {
      trackPageViewedEvent(null, router.currentRoute.value, { unload: true });
    });
  }

  function setupRouter(router) {
    currentRoute.value = router?.currentRoute?.value;
    router.afterEach(trackPageViewedEvent);
    setupUnloadListener(router);
  }

  function registerSuperProperties(superProps) {
    superProperties.value = {
      ...superProperties.value,
      ...(superProps ?? {}),
    };
    mixpanelStore.registerSuperProperties(superProps);
  }

  watch(
    () => identityStore.identity,
    (identity) => {
      if (identity) {
        mixpanelStore.setIdentity({
          userId: identity?.id,
          $name: `${identity?.first_name || ''} ${identity?.last_name || ''}`,
          $email: identity?.email,
          ...commonIdentityProps.value,
        });

        registerSuperProperties({
          organizationId: identity.organization?.id,
          organizationName: identity.organization?.name,
          organizationCustomerStage: identity.organization?.customer_stage,
        });
      } else {
        mixpanelStore.reset();
      }
    },
    { immediate: true },
  );

  watch(
    () => identityStore.currentBrand,
    (currentBrand) => {
      if (currentBrand) {
        registerSuperProperties(currentBrandProps.value);
        mixpanelStore.setIdentity({
          brand_id: currentBrand.id,
        });
      }
    },
    { immediate: true },
  );

  watch(
    () => mixpanelStore.ready,
    (ready) => {
      if (ready) {
        mixpanelStore.setUtmQueryParams(utmQueryParamsProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => impersonatorStore.isImpersonating,
    () => {
      registerSuperProperties(impersonatingProps.value);
    },
    { immediate: true },
  );

  watch(
    () => sessionId.value,
    () => {
      registerSuperProperties(sessionProps.value);
    },
    { immediate: true },
  );

  watch(
    () => fullstoryStore.ready && !!sessionId.value,
    (recordEvent) => {
      if (recordEvent) {
        fullstoryStore.sendFullstoryEvent('Session', sessionProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => intercomStore.ready && !!sessionId.value,
    (recordEvent) => {
      if (recordEvent) {
        intercomStore.trackEvent('Session', sessionProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => fullstoryStore.ready && impersonatorStore.isImpersonating,
    (recordEvent) => {
      if (recordEvent) {
        fullstoryStore.sendFullstoryEvent('Impersonating', impersonatingProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => intercomStore.ready && impersonatorStore.isImpersonating,
    (recordEvent) => {
      if (recordEvent) {
        intercomStore.trackEvent('Impersonating', impersonatingProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => fullstoryStore.ready && !!identityStore.identity,
    (recordEvent) => {
      if (recordEvent) {
        fullstoryStore.setIdentity({
          id: identityStore.identity.id,
          displayName: `${identityStore.identity.first_name} ${identityStore.identity.last_name}`,
          email: identityStore.identity.email,
          ...commonIdentityProps.value,
        });
      }
    },
    { immediate: true },
  );

  watch(
    () => intercomStore.ready && !!identityStore.identity,
    (recordEvent) => {
      if (recordEvent) {
        intercomStore.update(commonIdentityProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => fullstoryStore.ready && !!identityStore.currentBrand,
    (recordEvent) => {
      if (recordEvent) {
        fullstoryStore.sendFullstoryEvent('Brand', currentBrandProps.value);
      }
    },
    { immediate: true },
  );

  watch(
    () => intercomStore.ready && !!identityStore.currentBrand,
    (recordEvent) => {
      if (recordEvent) {
        intercomStore.trackEvent('Brand', currentBrandProps.value);
      }
    },
    { immediate: true },
  );

  return {
    track,
    setSessionId,
    setupRouter,
    // exposed for testing
    shouldRecordPageViewedEvent,
    trackPageViewedEvent,
    currentRoute,
  };
});
