export const BENCHMARK_REPORT_TYPES = Object.freeze({
  BENCHMARK_METRIC: {
    value: 'BENCHMARK_METRIC',
    text: 'Table',
    icon: 'table',
  },
  BENCHMARK_GRAPH: {
    value: 'BENCHMARK_GRAPH',
    text: 'Graph',
    icon: 'graph',
  },
});

export const ALL_INDUSTRIES = Object.freeze({
  value: 'All Industries',
  text: 'All Industries',
  follower_bucket: 'BUCKET_OVERALL',
});

export const INDUSTRY_FOLLOWER_BUCKETS = Object.freeze({
  BUCKET_OVERALL: {
    value: 'BUCKET_OVERALL',
    text: 'Overall',
    follower_bucket: 'BUCKET_OVERALL',
    graph_label: 'Industry',
  },
  BUCKET_10K_200K: {
    value: 'BUCKET_10K_200K',
    text: '10K - 200K Followers',
    follower_bucket: 'BUCKET_10K_200K',
    graph_label: 'Industry: 10K - 200K Followers',
  },
  BUCKET_200K_1M: {
    value: 'BUCKET_200K_1M',
    text: '200K - 1M Followers',
    follower_bucket: 'BUCKET_200K_1M',
    graph_label: 'Industry: 200K - 1M Followers',
  },
  BUCKET_1M_PLUS: {
    value: 'BUCKET_1M_PLUS',
    text: '1M+ Followers',
    follower_bucket: 'BUCKET_1M_PLUS',
    graph_label: 'Industry: 1M+ Followers',
  },
});
