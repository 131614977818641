<template>
  <div
    :style="{ backgroundColor }"
    class="banner-box text-small flex w-full gap-2 rounded border border-solid"
    :class="bannerClasses"
  >
    <div v-if="showIcon" class="banner-icon">
      <Icon v-bind="iconProps" />
    </div>
    <div class="banner-content flex flex-col gap-2 text-left">
      <div>
        <slot />
      </div>
      <div v-if="hasAction" class="banner-action">
        <slot name="action">
          <div v-if="actionText && actionTo" class="action">
            <a v-if="isFunction(actionTo)" @click="clickAction">
              {{ actionText }}
            </a>
            <a v-else-if="isUrlExternal(actionTo)" :href="actionTo" target="_blank" rel="noopener">
              {{ actionText }}
            </a>
            <router-link v-else :to="actionTo">
              {{ actionText }}
            </router-link>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { enumProp } from '@/utils/props';

export const ALERT_TYPES = {
  INFORMATION: 'information',
  WHITE: 'white',
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  ERROR: 'error',
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Banner',
  components: { Icon },
  props: {
    hideDefaultIcon: { type: Boolean, default: false },
    customIcon: { type: String, default: null },
    customIconColor: { type: String, default: null },
    alertType: enumProp(ALERT_TYPES, ALERT_TYPES.INFORMATION),
    small: { type: Boolean, default: false },
    actionText: { type: String, default: null },
    actionTo: { type: String, default: null },
  },
  emits: ['clickAction'],
  computed: {
    showIcon() {
      return !!this.customIcon || (!!this.alertType && !this.hideDefaultIcon);
    },
    iconName() {
      return this.customIcon || this.typeMap[this.alertType]?.icon;
    },
    iconProps() {
      return {
        name: this.iconName,
        small: !this.small,
        xsmall: this.small,
        color: this.customIconColor,
      };
    },
    backgroundColor() {
      return this.typeMap[this.alertType]?.color;
    },
    type() {
      return this.alertType ?? ALERT_TYPES.INFORMATION;
    },
    bannerClasses() {
      return {
        [this.type]: true,
        small: this.small,
      };
    },
    hasAction() {
      return !!this.$slots.action || (this.actionText && this.actionTo);
    },
    typeMap() {
      return {
        white: {
          icon: 'info',
        },
        information: {
          icon: 'info',
        },
        neutral: {
          icon: 'checkCircle',
        },
        success: {
          icon: 'checkCircle',
        },
        warning: {
          icon: 'alertCircle',
        },
        danger: {
          icon: 'alertTriangle',
        },
        error: {
          icon: 'alertTriangle',
        },
      };
    },
  },
  methods: {
    isUrlExternal(url) {
      return typeof url === 'string' && url.startsWith('http');
    },
    isFunction(actionTo) {
      return actionTo instanceof Function;
    },
    clickAction() {
      this.$emit('clickAction');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.banner-box {
  max-width: 36.25rem;
  padding: 1.375rem 1.5rem 1.5rem;
  color: var(--text-primary);
  border-color: var(--border);

  .banner-icon {
    svg {
      fill: var(--text-secondary);
    }
  }

  .banner-action {
    a {
      color: var(--text-link);
    }
  }

  &.small {
    padding: 1rem;
  }

  &.information {
    border-color: var(--border);
    background-color: var(--background-300);
  }

  &.white {
    border-color: var(--border);
    background-color: var(--white);
  }

  &.neutral {
    border-color: var(--action-400);
    background-color: var(--action-100);

    .banner-icon {
      svg {
        fill: var(--action-400);
      }
    }
  }

  &.success {
    color: var(--success-700);
    border-color: var(--success-500);
    background-color: var(--success-50);

    .banner-icon {
      svg {
        fill: var(--success-500);
      }
    }
  }

  &.warning {
    color: var(--alert-700);
    border-color: var(--alert-400);
    background-color: var(--alert-100);

    .banner-icon {
      svg {
        fill: var(--alert-500);
      }
    }
  }

  &.error,
  &.danger {
    color: var(--error-700);
    border-color: var(--error-500);
    background-color: var(--error-100);

    .banner-icon {
      svg {
        fill: var(--error-500);
      }
    }
  }
}
</style>
