import { configureCompat } from 'vue';

export function setupVueCompat({ unitTests = false } = {}) {
  const suppressWarnings = unitTests
    ? {
        CONFIG_WHITESPACE: 'suppress-warning',
      }
    : {
        CONFIG_WHITESPACE: false,
      };

  configureCompat({
    MODE: () => {
      return 3;
    },
    ...suppressWarnings,
    ATTR_FALSE_VALUE: false,
    COMPONENT_V_MODEL: false,
    WATCH_ARRAY: false,
    TRANSITION_CLASSES: false,
    CONFIG_IGNORED_ELEMENTS: false,
    COMPILER_INLINE_TEMPLATE: false,
    GLOBAL_PRIVATE_UTIL: false,
    CONFIG_PRODUCTION_TIP: false,
    CONFIG_SILENT: false,
    GLOBAL_MOUNT: false,
    GLOBAL_PROTOTYPE: false,
    GLOBAL_DELETE: false,
    GLOBAL_OBSERVABLE: false,
    CONFIG_KEY_CODES: false,
    INSTANCE_DELETE: false,
    INSTANCE_EVENT_EMITTER: false,
    INSTANCE_EVENT_HOOKS: false,
    INSTANCE_CHILDREN: false,
    INSTANCE_LISTENERS: false,
    INSTANCE_SCOPED_SLOTS: false,
    INSTANCE_ATTRS_CLASS_STYLE: false,
    INSTANCE_SET: false,
    OPTIONS_DATA_FN: false,
    OPTIONS_DATA_MERGE: false,
    OPTIONS_BEFORE_DESTROY: false,
    OPTIONS_DESTROYED: false,
    V_ON_KEYCODE_MODIFIER: false,
    COMPONENT_ASYNC: false,
    COMPONENT_FUNCTIONAL: false,
    RENDER_FUNCTION: false,
    FILTERS: false,
    TRANSITION_GROUP_ROOT: false,
    GLOBAL_SET: false,
    INSTANCE_DESTROY: false,
    GLOBAL_EXTEND: false,
    PROPS_DEFAULT_THIS: false,
    CUSTOM_DIR: false,
  });
}
