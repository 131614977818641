/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fileOfficePDF': {
    width: 18,
    height: 22,
    viewBox: '0 0 18 22',
    data: '<g _fill="#adadad" fill-rule="evenodd"><path pid="0" d="M8.5 14.5v3c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5zM3.75 14.5H3V16h.75a.75.75 0 000-1.5z"/><path pid="1" d="M13.414 0H2C.897 0 0 .898 0 2v18c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4.586L13.414 0zM3.75 17.5H3V19H1.5v-6h2.25C4.99 13 6 14.009 6 15.25S4.99 17.5 3.75 17.5zM8.5 19H7v-6h1.5c1.655 0 3 1.345 3 3s-1.345 3-3 3zm8.3-4.5h-3.3v1H16V17h-2.5v2H12v-4.5c0-.827.673-1.5 1.5-1.5h3.3v1.5zM12 6V1l5 5h-5z" fill-rule="nonzero"/></g>'
  }
})
