<template>
  <div class="link-editor-container" data-cy="media-link-editor">
    <span class="badge">{{ linkNumber }}</span>
    <div class="media-link-container">
      <section class="input-row">
        <div class="input">
          <input
            ref="linkInput"
            v-model="link.productUrlInput"
            :class="{ warning: showLinkError }"
            :disabled="saving"
            placeholder="https://"
            data-cy="link-input"
            @focus="beforeEditedLink = link.productUrlInput"
            @input="setIsEditing"
            @blur="updateLink"
          />

          <Button
            v-tooltip="'Choose from products'"
            :disabled="saving"
            icon-name="windowLink"
            @click="editProduct"
          />
        </div>
        <Button
          v-tooltip="'Remove link'"
          data-cy="remove-link-button"
          :disabled="saving"
          icon-name="bin"
          @click="deleteLink"
        />
      </section>

      <CircularLoader v-if="productInformationPending" />
      <p v-else-if="showLinkError" class="alert-error" data-cy="invalid-link-error">
        {{ invalidLinkMessage }}
      </p>
      <div v-else-if="linkUrl.length" class="content-row" data-cy="link-content-row">
        <ProductCard
          :product="link.product"
          enable-editing-product
          @open-edit-product-popup="openEditProductPopup"
        />
        <div v-if="link.showNewLinkMessage" class="new-link-message">
          This link is not in your product catalog. Saving it will create a new product in Dash
          Hudson.
        </div>
        <!-- TODO - Only show this button on non-UGC Instagram / TikTok LikeShop posts -->
        <Button
          v-if="hasUtmSource && !mediaLinksStore.isUgcMedia && !mediaLinksStore.isCompetitiveMedia"
          data-cy="EditUtmParametersButton"
          class="mt-2 flex"
          :disabled="false"
          link
          @click="toggleShowUtmEditor(linkNumber)"
        >
          {{ utmScheduleOpenEditTrackingButton }}
        </Button>
      </div>
    </div>
    <teleport v-if="showUtmEditor" to="#MediaLinksSidePanel">
      <UtmEditorPanel
        class="max-h-[calc(80vh+4.5rem)] w-80 pb-6"
        :brand-channel-utm-settings="link.utms"
        :channel="mediaLinksStore.utmChannel"
        :url="link.product.url"
        :link-title="linkTitle"
        :link-number="linkNumber"
        :tracking-data="mediaLinksStore.utmTrackingData"
        @tracking-parameters-updated="debouncedOnUtmUpdate"
        @close="mediaLinksStore.closeUtmEditorPanel"
      />
    </teleport>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import { vOnClickOutside } from '@vueuse/components';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { useProductStore } from '@/stores/product';
import { debounceInputDelay, linkValidationMessages } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { logger } from '@/utils/logger';
import ProductCard from '@/app/library/components/ProductCard.vue';
import { useAuthStore } from '@/stores/auth';
import { useMediaLinksStore } from '@/stores/media-links';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import { utmScheduleOpenEditTrackingButton } from '@/app/settings/components/Utm/const';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaLinkEditor',
  components: {
    Button,
    CircularLoader,
    ProductCard,
    UtmEditorPanel,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    presetLink: { type: Object, default: null },
  },
  emits: [
    'onChange',
    'onInput',
    'onEditProduct',
    'onRemove',
    'errorValidationChanged',
    'openEditProductPopup',
    'utmValueModified',
  ],
  data() {
    return {
      iconColor: colours.ICON.ICON_PRIMARY,
      iconHoverColor: colours.ACTION.ACTION_500,
      link: {
        productUrlInput: '',
        invalidUrl: false,
        edited: false,
        showNewLinkMessage: false,
        product: {},
        utms: {},
      },
      saving: false,
      beforeEditedLink: null,
      productInformationPending: false,
      utmScheduleOpenEditTrackingButton,
    };
  },
  computed: {
    ...mapStores(useProductStore, useAuthStore, useMediaLinksStore),
    linkUrl() {
      return this.link?.product?.url ?? '';
    },
    linkTitle() {
      return this.link?.product?.title ?? '';
    },
    linkNumber() {
      if (this.$vnode.key) {
        return this.$vnode.key + 1;
      }
      return 1;
    },
    showUtmEditor() {
      return (
        this.hasUtmSource &&
        this.mediaLinksStore.showUtmEditorPanel &&
        this.mediaLinksStore.utmEditorPanelIndex === this.linkNumber
      );
    },
    showLinkError() {
      return this.hasLinkError && this.link.edited && !this.productInformationPending;
    },
    hasLinkError() {
      return !this.link.productUrlInput || this.link.invalidUrl;
    },
    invalidLinkMessage() {
      if (this.link.invalidUrl) {
        return linkValidationMessages.invalidLink;
      }
      if (!this.link.productUrlInput) {
        return linkValidationMessages.empty;
      }
      return null;
    },
    hasUtmSource() {
      return Boolean(this.mediaLinksStore.utmChannel);
    },
  },
  watch: {
    'link.productUrlInput': function productUrlInputWatcher(to) {
      // clear link meta text once link is cleared;
      if (!to) {
        this.clearLinkProduct();
        this.$emit('onChange', this.link, this.$vnode.key);
      }
    },
    presetLink(to) {
      this.setLinkValues(to);
    },
    showLinkError() {
      this.$emit('errorValidationChanged');
    },
    'mediaLinksStore.utmSettings': function utmSettingsWatcher(to) {
      if (this.hasUtmSource) {
        this.link.utms = merge(this.link.utms, to);
      }
    },
  },
  mounted() {
    this.setLinkValues(this.presetLink);
    this.initKeyboardSupport();

    // only focus new links
    if (!this.link.productUrlInput) {
      this.$refs.linkInput.focus();
    }

    if (this.hasUtmSource) {
      this.link.utms = cloneDeep(this.mediaLinksStore.utmSettings);
    }
  },
  methods: {
    initKeyboardSupport() {
      const ENTER_KEYCODE = 13;
      this.$refs.linkInput.addEventListener('keypress', (e) => {
        const key = e.which || e.keyCode;
        if (key === ENTER_KEYCODE) {
          this.$emit('onInput', false, this.$vnode.key);
          this.$refs.linkInput.blur();
        }
      });
    },
    // auto perform meta data update if there is a valid url
    // after no more input for 2 second.
    debouncedInput: debounce(function debounceInputControl() {
      // make sure component is reachable through $refs before calling component method.
      // if close popup before debounce input is called, component is gone with the popup and therefore unreachable
      this.existingProduct = null;
      if (this.$refs.linkInput) {
        this.$refs.linkInput.blur();
      }

      this.$emit('onInput', false, this.$vnode.key);
    }, 2000),
    async setIsEditing() {
      if (!this.link.product?.url) {
        this.link.invalidUrl = false;
      }
      // Disable save links button while user is typing so
      // they cannot click save before it hits the debounce
      this.$emit('onInput', true, this.$vnode.key);
      await this.debouncedInput();
    },
    editProduct() {
      this.$emit('onEditProduct', this.$vnode.key);
    },
    setLinkValues(linkObj) {
      this.link = {
        ...this.link,
        ...linkObj,
        invalidUrl: Boolean(linkObj?.invalidUrl),
        productUrlInput: linkObj?.product?.url || linkObj?.productUrlInput || '',
      };
    },
    clearLinkProduct() {
      this.link.product = null;
    },
    async updateLink() {
      this.link.edited = true;

      if (this.link.productUrlInput === this.beforeEditedLink) {
        this.beforeEditedLink = null;
        return;
      }

      if (this.link.productUrlInput) {
        const splitUrl = this.link.productUrlInput.split('://');
        if (!splitUrl[1]) {
          if (splitUrl[0] === 'https') {
            // url === 'https://' so reset to empty string
            this.link.productUrlInput = '';
          } else {
            // url does not start with https:// so add it in
            this.link.productUrlInput = `https://${this.link.productUrlInput.trim()}`;
          }
        }
        this.link.productUrlInput = this.link.productUrlInput.trim();

        await this.updateLinkWithProductDataOrMetadata();

        this.$emit('onChange', this.link, this.$vnode.key);
      }
      this.beforeEditedLink = null;
      this.$forceUpdate();
    },
    async updateLinkWithProductDataOrMetadata() {
      this.productInformationPending = true;
      this.link.showNewLinkMessage = false;

      const existingProduct = await this.findExistingProduct(this.link.productUrlInput);
      if (existingProduct) {
        this.updateLinkAndPlaceholderWithExistingProduct(existingProduct);
      }
      if (!existingProduct) {
        const metadata = await this.getLinkMeta(this.link.productUrlInput);
        this.updateLinkAndPlaceholderWithMetadata(metadata);
      }
      this.productInformationPending = false;
    },
    async findExistingProduct(url) {
      try {
        return await this.productStore.findProductByUrl({
          url,
          brandId: this.authStore.currentBrand?.id,
        });
      } catch {
        return null;
      }
    },
    async getLinkMeta(uri) {
      try {
        const metadata = await this.productStore.getUrlMetadata(
          { url: uri, linkNumber: this.linkNumber },
          { camelize: true },
        );
        return metadata.data;
      } catch (error) {
        logger.error(`Failed to fetch url metadata`, {}, error);
        this.link.invalidUrl = true;
        return null;
      }
    },
    deleteLink() {
      this.$emit('onRemove', this.$vnode.key);

      if (this.hasUtmSource) {
        this.mediaLinksStore.closeUtmEditorPanel();
      }
    },
    updateLinkAndPlaceholderWithMetadata(metadata) {
      if (!metadata) return;

      const product = {
        url: this.link.productUrlInput,
        title: '',
        clicks: 0,
      };
      if (metadata.url === this.link.productUrlInput) {
        product.imageUrl = metadata.imageUrl;
        product.title = metadata.title;
      }
      this.link = {
        ...this.link,
        product,
      };
      this.link.invalidUrl = false;
      this.link.showNewLinkMessage = true;
    },
    updateLinkAndPlaceholderWithExistingProduct(product) {
      this.link = {
        ...this.link,
        product,
      };
      this.link.invalidUrl = false;
    },
    openEditProductPopup() {
      this.$emit('openEditProductPopup', this.$vnode.key);
    },
    debouncedOnUtmUpdate: debounce(function onUtmUpdate(
      value,
      trackingParameters,
      urlLinkCustomized,
    ) {
      this.link.productUrlInput = value;
      this.updateLink();
      this.$emit('utmValueModified', urlLinkCustomized);
    }, debounceInputDelay),
    toggleShowUtmEditor(index) {
      return this.showUtmEditor
        ? this.mediaLinksStore.closeUtmEditorPanel()
        : this.mediaLinksStore.openUtmEditorPanel(index);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.link-editor-container {
  width: 100%;
  position: relative;
  background: var(--background-300);
  border-radius: var(--round-corner);
  margin: var(--space-32) 0;

  .badge {
    position: absolute;
    display: block;
    top: calc(-1 * var(--space-16));
    left: calc(-1 * var(--space-16));
    width: var(--space-32);
    height: var(--space-32);
    border-radius: 50%;
    color: var(--white);
    text-align: center;
    line-height: var(--space-28);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    background: var(--background-900);
    padding: 0;
  }

  .media-link-container {
    padding: var(--space-16) var(--space-24);
    font-size: var(--x14);

    header {
      transform: translateY(calc(-1 * var(--space-12)));
      display: flex;
      font-weight: var(--font-medium);
      font-size: var(--x18);

      svg {
        margin-right: var(--space-8);
      }
    }

    .button-row {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--space-8);

      button {
        margin: 0 var(--space-12);
        min-width: 9.5rem;
      }
    }

    .alert-error {
      text-align: left;
      text-transform: none;
      color: var(--error-500);
      font-size: var(--x14);
      line-height: var(--x18);
    }

    .input-row {
      display: flex;
      margin: var(--space-8) 0 var(--space-16) 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--space-40);
        height: var(--space-40);
        border: 1px solid var(--border);
        border-radius: var(--round-corner-small);
        margin-left: var(--space-8);
        background: var(--background-0);

        &:hover {
          color: var(--action-500);
        }
      }

      .input {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .round-border {
          border-radius: var(--round-corner-small);
        }

        .warning {
          border-color: var(--error-500);
        }

        input {
          width: 100%;
          border: 1px solid var(--border);
          height: var(--space-40);
          line-height: var(--space-32);
          border-radius: var(--round-corner-small) 0 0 var(--round-corner-small);
          background: var(--background-0);
          color: var(--text-primary);
          font-size: var(--x14);
          padding: 0 var(--space-8);
          text-overflow: ellipsis;
        }

        button {
          border-left: none;
          border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
          margin: 0;
        }
      }
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;

      .loader {
        color: transparent;
        position: absolute;
      }

      .loader::after {
        content: '';
        display: block;
        border: 2px solid var(--text-primary);
        border-left-color: rgb(76 95 113 / 20%);
        border-right-color: rgb(76 95 113 / 20%);
        border-bottom-color: rgb(76 95 113 / 20%);
        height: 2.1em;
        width: 2.1em;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        box-sizing: border-box;
        margin-left: -0.75em;
        margin-top: -0.75em;
        animation: rotate 1s linear infinite;
      }
    }
  }
}

.loading {
  color: transparent !important;
  position: relative;
}

.edit-link-title-button {
  &:focus {
    outline: none;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
    fill: var(--action-500);
  }
}

.loading::after {
  content: '';
  display: block;
  border: 3px solid var(--white);
  border-left-color: rgb(255 255 255 / 20%);
  border-right-color: rgb(255 255 255 / 20%);
  border-bottom-color: rgb(255 255 255 / 20%);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  margin-left: -0.75em;
  margin-top: -0.75em;
  animation: rotate 1s linear infinite;
}

.new-link-message {
  text-align: left;
  margin-top: var(--space-16);
}

@media (max-width: 26rem) {
  .button-row {
    flex-direction: column;

    button {
      margin: var(--space-8) auto !important;
    }
  }
}
</style>
