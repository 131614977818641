<template>
  <transition-group
    name="fade"
    tag="div"
    class="toasts"
    :class="{ 'click-through': allowClickingThroughNotifications }"
  >
    <template v-for="toast in notificationStore.toasts" :key="toast.id">
      <Toast
        v-show="!isReport"
        :toast="toast"
        @click-action="clickAction"
        @click-button="clickButton"
        @close="dismiss"
      />
    </template>
  </transition-group>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import Toast from '@/components/foundation/feedback/Toast.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ToastPopups',
  components: { Toast },
  computed: {
    ...mapStores(useNotificationStore),
    allowClickingThroughNotifications() {
      return !!window.Cypress;
    },
    isReport() {
      return this.$route?.meta?.report;
    },
  },
  methods: {
    clickButton(toast) {
      this.notificationStore.clearToasts({ id: toast.id });
      if (toast.buttonAction) {
        toast.buttonAction();
      }
    },
    clickAction(toast) {
      this.notificationStore.clearToasts({ id: toast.id });
      if (this.isFunction(toast.actionTo)) {
        toast.actionTo();
      }
    },
    dismiss(toast) {
      this.notificationStore.clearToasts({ id: toast.id });
      if (toast.onDismiss instanceof Function) {
        toast.onDismiss();
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.toasts {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: var(--z-index-notifications);
  top: var(--space-40);
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  max-width: 100%;

  &.click-through {
    pointer-events: none;
  }

  > * {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1024px) {
  .toasts {
    padding: 0 var(--space-16) 0 var(--space-16);
  }
}
</style>
