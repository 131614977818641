<template>
  <div v-tooltip="tooltip" class="toggle-wrapper">
    <label
      data-cy="toggle-switch"
      class="container"
      :class="[
        { 'has-text': toggleText, 'has-label': toggleLabel, disabled: disabled, ...customClass },
      ]"
    >
      <span :class="{ act: switcherOn }" class="slider" @click="toggle" />
      <span
        v-if="toggleText"
        :class="['toggle-text', 'left', { active: toggleText && !switcherOn }]"
        @click="toggle"
      >
        {{ toggleText[0] }}
      </span>
      <span
        v-if="toggleText"
        :class="['toggle-text', 'right', { active: toggleText && switcherOn }]"
        @click="toggle"
      >
        {{ toggleText[1] }}
      </span>
    </label>
    <p v-if="toggleLabel" :class="{ disabled: disabled }" @click="toggle">{{ toggleLabel }}</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ToggleSwitch',
  props: {
    switcherIsOn: { type: Boolean, default: true },
    value: { type: Boolean, default: null },
    disabled: { type: Boolean, default: false },
    toggleText: { type: Array, default: null },
    toggleLabel: { type: String, default: null },
    tooltip: { type: String, default: null },
    customClass: { type: Object, default: () => ({}) },
    /*
    Function to return boolean flag to update/not update the switch value
     */
    onToggle: { type: Function, default: null },
  },
  emits: ['onChange', 'input'],
  data() {
    return {
      switcherOn: this.value,
    };
  },
  watch: {
    value(to) {
      this.switcherOn = to;
    },
    switcherIsOn(to) {
      this.switcherOn = to;
    },
  },
  mounted() {
    // upgrade ToggleSwitch to support v-model instead of prop and emit('onChange').
    // keep both ways to make it backward compatible
    if (this.value !== null) {
      this.switcherOn = this.value;
    } else {
      this.switcherOn = this.switcherIsOn;
    }
  },
  methods: {
    updateSwitcher() {
      this.switcherOn = !this.switcherOn;
      const value = this.switcherOn ? 'on' : 'off';
      this.$emit('onChange', value);
      this.$emit('input', this.switcherOn);
    },
    toggle() {
      if (this.onToggle) {
        this.onToggle(!this.switcherOn).then((shouldToggle) => {
          if (shouldToggle) {
            this.updateSwitcher();
          }
        });
      } else {
        this.updateSwitcher();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.toggle-wrapper {
  display: flex;
  margin-right: var(--space-8);

  .container {
    position: relative;
    display: flex;
    vertical-align: middle;
    width: 2.75rem;
    height: 1.5rem;
    margin: 0 auto;

    span {
      margin-bottom: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      inset: 0;
      background-color: var(--text-secondary);
      transition: 0.4s;
      border-radius: 1.25rem;
    }

    .slider::before {
      position: absolute;
      content: '';
      height: 1.1rem;
      width: 1.1rem;
      bottom: 0.2rem;
      left: 0.2rem;
      background-color: var(--background-0);
      transition: 0.4s;
      border-radius: 50%;
    }

    .slider.act {
      background-color: var(--action-500);
    }

    .slider.act::before {
      transform: translateX(1.25rem);
    }
  }

  .container.has-text {
    height: 2rem;
    width: 4.5rem;

    .slider::before {
      position: absolute;
      content: '';
      height: 1.4rem;
      width: 1.4rem;
      bottom: 0.3rem;
      left: 0.3rem;
      background-color: var(--background-0);
      transition: 0.4s;
      border-radius: 50%;
    }

    .slider {
      background-color: var(--action-500);
    }

    .slider-act::before {
      left: 0.4rem;
    }

    .slider.act::before {
      transform: translateX(2.5rem);
    }

    span.toggle-text {
      position: absolute;
      width: 100%;
      z-index: 2;
      color: var(--white);
      text-transform: uppercase;
      font-weight: var(--font-medium);
      font-size: var(--x12);
      padding: 0.2rem var(--space-12);
      cursor: pointer;
      text-align: left;
      line-height: 1.5rem;
      visibility: hidden;
      opacity: 0;
      transition: var(--transition-all);

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }

    span.toggle-text.left {
      right: 0;
      text-align: right;
    }

    span.toggle-text.right {
      left: 0;
    }
  }

  .container.has-label {
    margin: 0 var(--space-8) 0 var(--space-16);
  }

  .container.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: auto;
  }

  p {
    height: var(--space-24);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
  }
}
</style>
