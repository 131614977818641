/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bookmark-simple-solid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M13 0H1a1 1 0 00-1 1v18a1 1 0 001.707.707L7 14.414l5.293 5.293A1 1 0 0014 19V1a1 1 0 00-1-1z" id="svgicon_bookmark-simple-solid_a"/></defs><use _fill="#2a2a2a" fill-rule="nonzero" xlink:href="#svgicon_bookmark-simple-solid_a" transform="translate(5 2)"/>'
  }
})
