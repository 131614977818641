<template>
  <div :class="platform" class="edit-header">
    <div class="connected-platform-title">
      <PlatformAvatar :avatar-url="avatar" :handle="handle" :platform="platform" />
    </div>

    <Tabs v-if="tabs" :tabs="tabs" :value="activeTab" small class="tabs" @on-change="tabChanged" />
    <CommentPanelToggle
      :is-active="showPostEditorSidePanel"
      :platform="platform"
      :post-id="post?.id"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CommentPanelToggle from '@/components/CommentingModule/CommentPanelToggle.vue';
import Tabs from '@/components/Tabs.vue';
import PlatformAvatar from '@/components/PlatformAvatar.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Header',
  components: {
    CommentPanelToggle,
    PlatformAvatar,
    Tabs,
  },
  props: {
    activeTab: { type: String, default: null },
    avatar: { type: String, default: null },
    handle: { type: String, default: null },
    icon: { type: String, required: true },
    platform: { type: String, default: null },
    post: { type: Object, default: null },
    showPostEditorSidePanel: { type: Boolean, default: false },
    tabChanged: { type: Function, default: null },
    tabs: { type: Array, default: null },
    title: { type: String, required: true },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.edit-header {
  width: 100%;
  display: flex;
  padding: var(--space-12) var(--space-12);
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  font-weight: var(--font-medium);
  font-size: var(--x18);

  .tabs {
    padding-top: var(--space-2);
  }

  .title {
    display: flex;
    line-height: var(--space-16);

    svg {
      margin: 0 var(--space-8);
    }
  }
}

.connected-platform-title {
  display: flex;
  align-items: center;
  line-height: var(--space-16);
  font-weight: var(--font-normal);
  color: var(--text-secondary);
  text-transform: capitalize;

  .platform-avatar {
    margin: 0 var(--space-8);
  }
}
</style>
