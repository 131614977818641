/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tagAdd': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#adadad"><path pid="0" d="M20 8.006v-7a1 1 0 00-1-1h-7a.996.996 0 00-.707.293l-11 11a.999.999 0 000 1.414l7 7a.997.997 0 001.414 0l11-11A1 1 0 0020 8.006zm-2-.414l-10 10-5.586-5.586 10-10H18v5.586z"/><circle pid="1" cx="15" cy="5.006" r="2"/><path pid="2" d="M24 19h-3v-3h-2v3h-3v2h3v3h2v-3h3z"/></g>'
  }
})
