export default {
  inject: {
    register: {
      default: () => {},
    },
    unregister: {
      default: () => {},
    },
  },
  data() {
    return {
      isGroupItemActive: false,
    };
  },
  mounted() {
    if (this.register) {
      this.register(this);
    }
  },
  beforeUnmount() {
    if (this.unregister) {
      this.unregister(this);
    }
  },
};
