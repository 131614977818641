/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lineGraph': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 19V3H3v17a1 1 0 001 1h17v-2H5z"/><path pid="1" d="M11 12.414l2.293 2.292a.999.999 0 001.414 0l6-6-1.414-1.414L14 12.586l-2.293-2.293a.999.999 0 00-1.414 0l-4 4 1.414 1.414L11 12.414z"/>'
  }
})
