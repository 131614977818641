<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import dayjs from 'dayjs';
import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';
import { useSocialListeningStore } from '@/stores/social-listening';
import { CUSTOMER_PLAN } from '@/models/auth/customer-plan.enum';
import { TRIAL_BOOK_TRAINING_URL } from '@/components/InAppTrials/constants';

const props = defineProps({
  visualBridge: { type: Boolean, default: false },
});

const emits = defineEmits(['selected']);

const authStore = useAuthStore();
const chiliPiperStore = useChiliPiperStore();
const customerJourneyStore = useCustomerJourneyStore();
const socialListeningStore = useSocialListeningStore();

const router = useRouter();

function trialActiveOrExpiredForTwoDays(trial) {
  const endDate = dayjs(trial.trialEndDate).add(2, 'days');
  const today = dayjs();
  return endDate >= today;
}

function getTrialDaysLeft(trial) {
  const trialEndDate = dayjs(trial?.trialEndDate);
  const today = dayjs();
  return Math.ceil(trialEndDate.diff(today, 'days', true));
}

const showPlanTrial = computed(() => {
  return (
    customerJourneyStore.additionalPlanTrial?.trialEndDate &&
    trialActiveOrExpiredForTwoDays(customerJourneyStore.additionalPlanTrial)
  );
});

const showPremiumAnalyticsTrial = computed(() => {
  return (
    customerJourneyStore.additionalPremiumAnalyticsTrial?.trialEndDate &&
    trialActiveOrExpiredForTwoDays(customerJourneyStore.additionalPremiumAnalyticsTrial)
  );
});

const showSocialListeningTrial = computed(() => {
  return (
    customerJourneyStore.socialListeningTrial?.trialEndDate &&
    trialActiveOrExpiredForTwoDays(customerJourneyStore.socialListeningTrial)
  );
});

const planTrialType = computed(() => {
  return customerJourneyStore.additionalPlanTrial?.dhProduct?.name;
});

const planTrialDaysLeft = computed(() => {
  return getTrialDaysLeft(customerJourneyStore.additionalPlanTrial);
});

const premiumAnalyticsTrialDaysLeft = computed(() => {
  return getTrialDaysLeft(customerJourneyStore.additionalPremiumAnalyticsTrial);
});

const socialListeningTrialDaysLeft = computed(() => {
  return getTrialDaysLeft(customerJourneyStore.socialListeningTrial);
});

const basePlanTrialDaysLeft = computed(() => {
  return getTrialDaysLeft(customerJourneyStore.basePlanTrial);
});

const brandTrialDaysLeft = computed(() => {
  const earliestExpiration =
    planTrialDaysLeft.value > basePlanTrialDaysLeft.value &&
    customerJourneyStore.basePlanTrial?.dhProduct?.name === CUSTOMER_PLAN.GROW.name
      ? basePlanTrialDaysLeft.value
      : planTrialDaysLeft.value;
  return showPlanTrial.value && earliestExpiration > 0
    ? earliestExpiration
    : premiumAnalyticsTrialDaysLeft.value;
});
const brandTrialProductCopy = computed(() => {
  if (planTrialDaysLeft.value > 0 && premiumAnalyticsTrialDaysLeft.value <= 0) {
    return planTrialType.value;
  }
  if (premiumAnalyticsTrialDaysLeft.value > 0 && planTrialDaysLeft.value <= 0) {
    return 'Premium Analytics';
  }
  return `${showPlanTrial.value ? planTrialType.value : ''}${showPlanTrial.value && showPremiumAnalyticsTrial.value ? ' + ' : ''}${showPremiumAnalyticsTrial.value ? 'Premium Analytics' : ''}`;
});

const activeTrialsInfo = computed(() => {
  const activeTrials = [];
  if (planTrialDaysLeft.value > 0 || premiumAnalyticsTrialDaysLeft.value > 0) {
    activeTrials.push({
      product: brandTrialProductCopy.value,
      daysLeft: brandTrialDaysLeft.value,
    });
  }
  if (socialListeningTrialDaysLeft.value > 0) {
    activeTrials.push({
      product: 'Social Listening',
      daysLeft: socialListeningTrialDaysLeft.value,
    });
  }
  return activeTrials;
});

const expiredTrialsInfo = computed(() => {
  const expiredTrials = [];
  if (
    ((showPlanTrial.value && planTrialDaysLeft.value <= 0) ||
      (showPremiumAnalyticsTrial.value && premiumAnalyticsTrialDaysLeft.value <= 0)) &&
    !(planTrialDaysLeft.value > 0 || premiumAnalyticsTrialDaysLeft.value > 0)
  ) {
    expiredTrials.push(brandTrialProductCopy.value);
  }
  if (showSocialListeningTrial.value && socialListeningTrialDaysLeft.value <= 0) {
    expiredTrials.push('Social Listening');
  }
  return expiredTrials;
});

const isAdmin = computed(() => {
  return authStore.isCurrentBrandRoleAdmin;
});

function openChiliPiperForm(buttonText) {
  let formRouter;
  let formName;
  if (buttonText === 'Book A Training') {
    chiliPiperStore.openChiliPiperTab(
      TRIAL_BOOK_TRAINING_URL,
      CHILI_PIPER_FORMS_MAP.button.bookATraining.router,
      CHILI_PIPER_FORMS_MAP.button.bookATraining.name,
      'Book A Training',
      'profile dropdown',
    );
    emits('selected');
    return;
  }
  if (buttonText === 'Extend Trial') {
    formRouter = CHILI_PIPER_FORMS_MAP.button.extendTrial.router;
    formName = CHILI_PIPER_FORMS_MAP.button.extendTrial.name;
  } else if (showPlanTrial.value) {
    formRouter = CHILI_PIPER_FORMS_MAP[planTrialType.value.toLowerCase()].subscribe.router;
    formName = CHILI_PIPER_FORMS_MAP[planTrialType.value.toLowerCase()].subscribe.name;
  } else {
    formRouter = CHILI_PIPER_FORMS_MAP.premiumAnalytics.subscribe.router;
    formName = CHILI_PIPER_FORMS_MAP.premiumAnalytics.subscribe.name;
  }
  chiliPiperStore.submit(formRouter, formName, buttonText, 'profile dropdown');
  emits('selected');
}

function subscribeClicked() {
  const trialsInfoToCheck =
    activeTrialsInfo.value?.length > 0 ? activeTrialsInfo.value : expiredTrialsInfo.value;
  const isSocialListeningOnly =
    trialsInfoToCheck.length === 1 &&
    (trialsInfoToCheck[0] === 'Social Listening' ||
      trialsInfoToCheck[0]?.product === 'Social Listening');
  if (isSocialListeningOnly && !customerJourneyStore.isEligibleForSocialListening) {
    router.push({
      name: 'settings.billing',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
    socialListeningStore.togglePopup('upgradePlan', true);
    return;
  }
  if (!isAdmin.value) {
    customerJourneyStore.togglePopup(
      'featureAccess',
      true,
      isSocialListeningOnly ? 'socialListening' : null,
    );
    return;
  }
  if (trialsInfoToCheck.length > 1) {
    router.push({
      name: 'settings.billing',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
  } else if (isSocialListeningOnly) {
    router.push({
      name: 'settings.socialListeningUpgrade',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
  } else {
    openChiliPiperForm('Subscribe');
  }
  emits('selected');
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        activeTrialsInfo,
        expiredTrialsInfo,
        subscribeClicked,
      }
    : {},
);
</script>

<script>
export default {
  computed: {
    expansionTrialDaysLeft() {
      if (this.visualBridge) {
        return (trialInfo) =>
          trialInfo.daysLeft > 1
            ? `<span>${trialInfo.daysLeft}</span> days remaining in your ${trialInfo.product} trial.`
            : `This is the last day of your <span class="dash-typo-base dash-typo-base-semibold">${trialInfo.product}</span> trial.`;
      }
      return (trialInfo) =>
        trialInfo.daysLeft > 1
          ? `<span class="text-[--action-500]">${trialInfo.daysLeft}</span> days remaining in your <span class="font-medium">${trialInfo.product}</span> trial.`
          : `This is the last day of your <span class="font-medium">${trialInfo.product}</span> trial.`;
    },
  },
};
</script>

<template>
  <div
    v-if="activeTrialsInfo?.length > 0 || expiredTrialsInfo?.length > 0"
    :class="{ 'mb-6': visualBridge }"
  >
    <div
      v-if="activeTrialsInfo?.length > 0"
      :class="[
        props.visualBridge
          ? 'dash-bg-background-inform-muted dash-rounded-sm dash-typo-base p-6'
          : 'p4 w-[--space-272] rounded-sm bg-[--action-100]',
      ]"
    >
      <div
        v-for="trialInfo in activeTrialsInfo"
        :key="trialInfo.product"
        v-sanitize-html="expansionTrialDaysLeft(trialInfo)"
        class="mb-4"
      ></div>
      <div
        class="text-[--action-500]"
        :class="[
          props.visualBridge ? 'dash-typo-base dash-typo-base-semibold' : 'text-sm font-medium',
        ]"
      >
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Book A Training')"
            >Book a Training</span
          >
        </div>
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Extend Trial')"
            >Extend Trial</span
          >
        </div>
        <div class="mt-3 cursor-pointer">
          <span class="cursor-pointer" @click="subscribeClicked">Subscribe</span>
        </div>
      </div>
    </div>
    <div
      v-if="expiredTrialsInfo?.length > 0"
      :class="[
        { 'mt-4': activeTrialsInfo?.length > 0 },
        props.visualBridge
          ? 'dash-bg-background-inform-muted dash-rounded-sm dash-typo-base p-6'
          : 'w-[--space-272] rounded-sm bg-[--action-100] p-4',
      ]"
    >
      <div
        v-for="trialInfo in expiredTrialsInfo"
        :key="trialInfo"
        :class="{ 'mb-4': activeTrialsInfo?.length === 0 }"
      >
        Your <span class="font-medium">{{ trialInfo }}</span> trial has expired.
      </div>
      <div v-if="activeTrialsInfo?.length === 0" class="text-sm font-medium text-[--action-500]">
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Extend Trial')"
            >Extend Trial</span
          >
        </div>
        <div class="mt-3">
          <span class="cursor-pointer" @click="subscribeClicked">Subscribe</span>
        </div>
      </div>
    </div>
  </div>
</template>
