<template>
  <transition
    name="collapse"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @after-enter="onAfterTransition"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterTransition"
  >
    <div v-show="open" class="collapsible">
      <!-- Used to measure the height of the content (scrollHeight has issues with margin collapsing) -->
      <div ref="content" class="content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CollapsibleContainer',
  props: {
    open: { type: Boolean, required: true },
    horizontal: { type: Boolean, default: false },
    transitionDuration: { type: String, default: undefined },
  },
  computed: {
    dimension() {
      return this.horizontal ? 'width' : 'height';
    },
  },
  methods: {
    onBeforeEnter(element) {
      element.style[this.dimension] = 0;
    },
    onEnter(element) {
      const size = this.$refs.content.getBoundingClientRect()[this.dimension] - 2;
      element.style[this.dimension] = `${size}px`;
      if (this.transitionDuration) {
        element.style.transitionDuration = this.transitionDuration;
      }
    },
    onBeforeLeave(element) {
      const size = this.$refs.content.getBoundingClientRect()[this.dimension] - 2;
      element.style[this.dimension] = `${size}px`;
    },
    onLeave(element) {
      // No idea why requestAnimationFrame is needed...
      requestAnimationFrame(() => {
        element.style[this.dimension] = 0;
        if (this.transitionDuration) {
          element.style.transitionDuration = this.transitionDuration;
        }
      });
    },
    onAfterTransition(element) {
      element.style[this.dimension] = null;
      element.style.transitionDuration = null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.collapse-enter-from,
.collapse-leave-to {
  opacity: 0;
}

.collapse-enter-to,
.collapse-leave-from {
  opacity: 1;
}

.collapse-enter-active,
.collapse-leave-active {
  overflow: hidden;
  transition: var(--transition-all);
}

.content {
  border: 1px solid transparent;
  margin: -1px;
}
</style>
