import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import * as FacebookAPI from '@/apis/facebook';
import { useSchedulerStore } from '@/stores/scheduler';
// eslint-disable-next-line import/no-cycle
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';

export const useInstagramShoppingTaggerStore = defineStore(
  'instagramShoppingTagger',
  () => {
    const authStore = useAuthStore();
    const schedulerStore = useSchedulerStore();
    const instagramUserTaggerStore = useInstagramUserTaggerStore();

    const shoppingTagsEligible = ref(null);
    const instagramUserId = ref(null);
    const catalogs = ref([]);
    const shoppingTaggerEnabled = ref(false);
    const editingShoppingTag = ref(false);
    const internalTaggedProducts = ref({});
    const postId = ref(null);

    const pending = ref({
      checkShoppingTagsEligible: 0,
      getInstagramProductTagsCatalogs: 0,
    });

    const activeMediaId = computed(() => {
      return schedulerStore.instagramCarouselActiveMedia?.id;
    });
    const taggedProducts = computed(() => {
      return Object.keys(internalTaggedProducts.value).reduce((tags, mediaId) => {
        internalTaggedProducts.value[mediaId].forEach((tag) => tags.push({ mediaId, ...tag }));
        return tags;
      }, []);
    });

    const activeMediaTaggedProducts = computed(() => {
      return internalTaggedProducts.value?.[activeMediaId.value] ?? [];
    });

    const totalMediaTagsAndProductTags = computed(() => {
      const taggedUsers = instagramUserTaggerStore.taggedUsers?.length ?? 0;
      return (taggedProducts.value?.length ?? 0) + taggedUsers;
    });

    const totalActiveMediaTags = computed(() => {
      const activeMediaTaggedUsers = instagramUserTaggerStore.activeMediaTaggedUsers?.length ?? 0;
      return activeMediaTaggedProducts.value.length + activeMediaTaggedUsers;
    });

    async function checkShoppingTagsEligible() {
      const brandId = authStore.currentBrand?.id;

      pending.value.checkShoppingTagsEligible += 1;
      try {
        const response = await FacebookAPI.getInstagramProductTagsEligibility({ brandId });
        const payload = response?.data;

        shoppingTagsEligible.value = payload?.shopping_product_tag_eligibility;
        instagramUserId.value = payload?.fb_business_instagram_id;

        return payload;
      } finally {
        pending.value.checkShoppingTagsEligible -= 1;
      }
    }

    async function getInstagramProductTagsCatalogs() {
      const brandId = authStore.currentBrand?.id;

      pending.value.getInstagramProductTagsCatalogs += 1;

      try {
        const response = await FacebookAPI.getInstagramProductTagsCatalogs({
          brandId,
          instagramUserId: instagramUserId.value,
        });
        const payload = response?.data;

        catalogs.value = payload;

        return payload;
      } finally {
        pending.value.getInstagramProductTagsCatalogs -= 1;
      }
    }

    function enableShoppingTagger() {
      shoppingTaggerEnabled.value = true;
    }

    function disableShoppingTagger() {
      shoppingTaggerEnabled.value = false;
    }

    function addShoppingTag({ mediaId = activeMediaId.value, shoppingTag }) {
      if (!(mediaId in internalTaggedProducts.value)) {
        internalTaggedProducts.value = {
          ...internalTaggedProducts.value,
          [mediaId]: [],
        };
      }
      internalTaggedProducts.value[mediaId].push(shoppingTag);
    }

    function updateShoppingTag({
      mediaId = activeMediaId.value,
      index = internalTaggedProducts.value[mediaId].length - 1,
      shoppingTag,
    }) {
      const existingShoppingTag = internalTaggedProducts.value[mediaId][index];
      internalTaggedProducts.value[mediaId].splice(index, 1, {
        ...existingShoppingTag,
        ...shoppingTag,
      });
    }

    function deleteShoppingTag({
      mediaId = activeMediaId.value,
      index = internalTaggedProducts.value[mediaId].length - 1,
    }) {
      internalTaggedProducts.value[mediaId].splice(index, 1);
    }

    function setEditingShoppingTag({ value }) {
      editingShoppingTag.value = value;
    }

    function setShoppingTags({ shoppingTags, postId: incomingPostId = null }) {
      internalTaggedProducts.value = {};
      if (postId.value === incomingPostId) {
        shoppingTags.forEach(({ mediaId, ...tag }) => {
          if (!(mediaId in internalTaggedProducts.value)) {
            internalTaggedProducts.value = {
              ...internalTaggedProducts.value,
              [mediaId]: [],
            };
          }
          internalTaggedProducts.value[mediaId].push(tag);
        });
      }
    }

    function clearShoppingTags() {
      postId.value = null;
      internalTaggedProducts.value = {};
    }

    function deleteMediaShoppingTags({ mediaId }) {
      const newValue = {
        ...internalTaggedProducts.value,
      };
      delete newValue[mediaId];
      internalTaggedProducts.value = newValue;
    }

    function setShoppingTaggerPostId({ postId: newPostId }) {
      postId.value = newPostId;
    }

    return {
      shoppingTagsEligible,
      instagramUserId,
      catalogs,
      pending,
      shoppingTaggerEnabled,
      editingShoppingTag,
      taggedProducts,
      internalTaggedProducts,
      postId,
      totalMediaTagsAndProductTags,
      totalActiveMediaTags,
      activeMediaTaggedProducts,
      activeMediaId,
      getInstagramProductTagsCatalogs,
      checkShoppingTagsEligible,
      enableShoppingTagger,
      disableShoppingTagger,
      addShoppingTag,
      updateShoppingTag,
      deleteShoppingTag,
      setEditingShoppingTag,
      setShoppingTags,
      clearShoppingTags,
      deleteMediaShoppingTags,
      setShoppingTaggerPostId,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
