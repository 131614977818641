import camelCase from 'lodash/camelCase';

function omit(obj, predicate) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => !predicate(k, v)));
}

export function extractEventsFromAttributes(attrs, removeOnPrefix = false) {
  const events = omit(attrs, (k) => !k.startsWith('on'));

  if (removeOnPrefix) {
    return Object.fromEntries(
      Object.entries(events).map(([k, v]) => {
        const newKey = k.startsWith('on') ? camelCase(k.substring(2)) : k;
        return [newKey, v];
      }),
    );
  }

  return events;
}

export function extractNonEventsFromAttributes(attrs) {
  const keysToRemove = Object.keys(extractEventsFromAttributes(attrs));
  return omit(attrs, (k) => keysToRemove.includes(k));
}
