import { computed, onBeforeMount, onUnmounted, ref, unref, watch } from 'vue';
import { useEventListener } from '@vueuse/core';

export function useLazyList({
  list,
  pageSize = 50,
  loadMoreDistance = 200,
  disableLazyList = false,
}) {
  const pageLimit = unref(pageSize);

  const scrollEventListener = ref(null);
  const scrollableContainerRef = ref(null);
  const currentLimit = ref(0);

  const lazyItems = computed(() => {
    if (unref(disableLazyList)) {
      return unref(list);
    }
    return unref(list).slice(0, unref(currentLimit));
  });

  function updateCurrentLimit(newLimit) {
    if (newLimit < unref(list).length) {
      currentLimit.value = newLimit;
    } else {
      currentLimit.value = unref(list).length;
    }
  }

  function loadMoreItems() {
    const newLimit = unref(currentLimit) + pageLimit;
    updateCurrentLimit(newLimit);
  }

  function resetLimit() {
    updateCurrentLimit(pageLimit);
  }

  function handleScrollEvent() {
    if (
      scrollableContainerRef.value.scrollTop >=
      scrollableContainerRef.value.scrollHeight -
        scrollableContainerRef.value.offsetHeight -
        loadMoreDistance
    ) {
      loadMoreItems();
    }
  }

  onBeforeMount(() => {
    updateCurrentLimit(pageLimit);
  });

  watch(scrollableContainerRef, (to) => {
    if (to) {
      scrollEventListener.value = useEventListener(
        scrollableContainerRef,
        'scroll',
        handleScrollEvent,
      );
    }
  });

  watch(list, (to) => {
    if (to.length) {
      currentLimit.value = pageLimit;
    } else {
      currentLimit.value = 0;
    }
  });

  onUnmounted(() => {
    if (scrollEventListener.value) {
      scrollEventListener.value();
    }
  });

  return {
    lazyItems,
    resetLimit,
    currentLimit,
    scrollableContainerProps: {
      ref: scrollableContainerRef,
    },
    handleScrollEvent,
  };
}
