/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shootingStar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.541 15.907l-7.069-4.915 1.159-.386c.37-.124.635-.452.678-.84a.997.997 0 00-.481-.966l-3.156-1.894-.619-3.102a1.003 1.003 0 00-.69-.762 1.002 1.002 0 00-.997.25L8.68 4.979 5.023 3.758a1.001 1.001 0 00-1.265 1.265L4.979 8.68l-1.686 1.685a.999.999 0 00.511 1.688l3.102.62L8.8 15.828a1 1 0 001.806-.199l.398-1.192 4.902 7.102a.999.999 0 001.802-.373l.576-2.883 2.882-.576a.997.997 0 00.782-.776.995.995 0 00-.407-1.024zM9.396 12.936l-1.004-1.672a.996.996 0 00-.661-.465l-1.702-.342.8-.799a.998.998 0 00.241-1.024L6.289 6.29l2.345.78a.997.997 0 001.023-.241l.8-.8.341 1.703c.056.276.224.515.466.661l1.672 1.003-2.18.726a1.005 1.005 0 00-.633.632l-.727 2.182zm7.843 3.518a1 1 0 00-.784.784l-.244 1.223-4.416-6.396c.086-.255.008-.183.309-.282l6.367 4.424-1.232.247z"/>'
  }
})
