<template>
  <Popup :close="hideCommentErrorPopup" type="medium">
    <div class="center">
      <span>
        <h4>Unable to Comment</h4>
        <p>
          Due to Instagram API limitations, you are only able to publish comments via third-party
          platforms on posts in which you have been @mentioned.
        </p>
      </span>
      <a :href="mediaPostUrl" target="_blank" rel="noopener">
        <Button large primary>Comment on Instagram</Button>
      </a>
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CommentErrorPopup',
  components: {
    Button,
    Popup,
  },
  props: {
    hideCommentErrorPopup: { type: Function, required: true },
    mediaPostUrl: { type: String, default: '' },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.center {
  text-align: center;

  h4 {
    margin: var(--space-4) auto var(--space-24) auto;
    font-weight: 500;
  }

  p {
    text-align: left;
  }

  button {
    margin: var(--space-48) auto 0;
  }
}
</style>
