import { Buffer } from 'buffer';
import { env } from '@/env';

const prodSourceUrls = [env.prodS3Url, env.prodCdnUrl, env.prodCloudFrontUrl];
const devSourceUrls = [env.devS3Url, env.devCdnUrl, env.devCloudFrontUrl];
const oldDevSourceUrls = [env.oldDevS3Url, env.oldDevCdnUrl, env.oldDevCloudFrontUrl];

export const IMAGE_API_DOMAINS = [env.oldDevImageApiUrl, env.devImageApiUrl, env.prodImageApiUrl]
  .filter((url) => typeof url === 'string' && url.length > 1)
  .map((url) => new URL(url).hostname);

export const SOURCE_DOMAINS = [...prodSourceUrls, ...devSourceUrls, ...oldDevSourceUrls]
  .filter((url) => typeof url === 'string' && url.length > 1)
  .map((url) => new URL(url).hostname);

export const sourceDomainValidator = (value) => {
  return SOURCE_DOMAINS.includes(new URL(value).hostname);
};

export const getImageApiDomainForUrl = (originalUrl) => {
  let host = env.oldDevImageApiUrl;
  prodSourceUrls.forEach((cdnUrl) => {
    if (originalUrl.startsWith(cdnUrl)) {
      host = env.prodImageApiUrl;
    }
  });
  devSourceUrls.forEach((cdnUrl) => {
    if (originalUrl.startsWith(cdnUrl)) {
      host = env.devImageApiUrl;
    }
  });
  return host;
};

export const urlEncode = (unencoded) => {
  const encoded = Buffer.from(unencoded || '').toString('base64');
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const FIT_INSIDE = 'inside';
export const FIT_COVER = 'cover';
export const ALLOWED_FITS = [FIT_INSIDE, FIT_COVER, null];

export const imageFitValidator = (value) => {
  return ALLOWED_FITS.some((fit) => value.includes(fit));
};

export class ImageAPI {
  static url(
    urlOrig,
    dimensions = { w: null, h: null },
    fit = null,
    download = false,
    extension = 'jpg',
  ) {
    const host = getImageApiDomainForUrl(urlOrig);
    const encodedUrl = urlEncode(urlOrig);
    const params = [];
    if (dimensions) {
      if (dimensions.w) params.push(`w=${dimensions.w}`);
      if (dimensions.h) params.push(`h=${dimensions.h}`);
    }
    if (fit) params.push(`fit=${fit}`);
    if (download) params.push('download=true');
    const paramString = params.length ? `?${params.join('&')}` : '';
    return `${host}/${encodedUrl}.${extension}${paramString}`;
  }
}
