import { unref } from 'vue';
import { useApprovedPublishingDates } from '@/app/scheduler/composables/useApprovedPublishingDates';

export default {
  computed: {
    composable() {
      return unref(
        useApprovedPublishingDates({
          scheduledTime: this.scheduledTime,
          post: this.post,
          mediaList: this.allMedia,
        }),
      );
    },
    allMedia() {
      return this.mediaList;
    },
    scheduledTimeError() {
      return unref(this.composable.scheduledTimeError);
    },
    approvedPublishingDatesExpired() {
      return unref(this.composable.approvedPublishingDatesExpired);
    },
    canPublishWithin() {
      return unref(this.composable.canPublishWithin);
    },
    publishDateRangeWarning() {
      return unref(this.composable.publishDateRangeWarning);
    },
    publishDateError() {
      return unref(this.composable.publishDateError);
    },
    canAssetPublishToday() {
      return unref(this.composable.canAssetPublishToday);
    },
    publishNowWarning() {
      return unref(this.composable.publishNowWarning);
    },
  },
};
