/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'moveDown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 11h9V2H2v9zm2-7h5v5H4V4zM21.293 14.294L19 16.586V11h-2v5.586l-2.293-2.292-1.414 1.413L18 20.414l4.707-4.707zM17 8h2v2h-2zM17 5h2v2h-2zM2 22h9v-9H2v9zm2-7h5v5H4v-5z"/>'
  }
})
