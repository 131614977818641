import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import * as InstagramAPI from '@/apis/instagram';
import * as LibraryAPI from '@/apis/library';
import { formatMediaListData } from '@/utils';
import { useSocketStore } from '@/stores/socket';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';
import { guessTimezone } from '@/utils/timezone';

export const UGC_POST_LIMIT = 25;
export const CSV_TYPE_INSTAGRAM_UGC_POST = 'instagramUgcPost';

export const useInstagramPostsStore = defineStore(
  'instagramPosts',
  () => {
    const authStore = useAuthStore();
    const socketStore = useSocketStore();

    const idealPostTimes = ref([]);
    const posts = ref([]);
    const igPost = ref(null);
    const relationshipId = ref(null);
    const ugcPosts = ref([]);
    const ugcPostOffset = ref(0);
    const ugcPostFullyLoaded = ref(false);
    const reachStats = ref(null);
    const engagementCount = ref(null);
    const internalAccountPosts = ref([]);
    const accountPostsLoaded = ref(false);
    const predictions = ref({});

    const pending = ref({
      posts: false,
      igPost: false,
      getUgcPostsStatus: false,
      relationshipId: false,
      reachStats: false,
      accountPosts: false,
    });

    const paging = ref({
      accountPosts: null,
    });

    const error = ref({
      relationshipId: null,
    });

    const abortControllers = {
      getReachstats: ref(null),
    };

    const accountPosts = computed(() => {
      return internalAccountPosts.value.map((post) => {
        const newPost = {
          ...post,
          sourceType: 'UGC',
        };

        const prediction = predictions.value[post.id];
        if (prediction || prediction === null) {
          newPost.predictions = { engagement: prediction ?? 'processing' };
        }

        return newPost;
      });
    });

    async function listIdealPostTimes() {
      const brandId = authStore.currentBrand?.id;

      const timezone = guessTimezone();

      const response = await InstagramAPI.getIdealPostTimes({ brandId, timezone });
      const payload = response?.data;
      idealPostTimes.value = payload;
      return payload;
    }

    async function listPosts() {
      const brandId = authStore.currentBrand?.id;

      pending.value.posts = true;
      try {
        const response = await InstagramAPI.getPosts({ brandId });
        const payload = response?.data;
        posts.value = payload;
        return payload;
      } finally {
        pending.value.posts = false;
      }
    }

    function initPredictions(postsToPredict) {
      predictions.value = {
        ...predictions.value,
        ...postsToPredict.reduce((acc, post) => {
          acc[post.id] = null;
          return acc;
        }, {}),
      };
    }

    function clearOutstandingPredictions() {
      predictions.value = Object.entries(predictions.value).reduce((acc, [key, value]) => {
        if (predictions.value[key]) {
          acc[key] = value;
        }
        return acc;
      }, {});
    }

    async function predictFromPosts() {
      const brandId = authStore.currentBrand?.id;
      const toPopulate = accountPosts.value.filter((post) => !predictions.value[post.id]);
      initPredictions(toPopulate);

      try {
        const response = await LibraryAPI.getPredictions({
          brandId,
          files: toPopulate.map((post) => ({ id: post.id, url: post.imageUrl })),
        });
        const newPredictions = response?.data ?? {};
        predictions.value = {
          ...predictions.value,
          ...newPredictions,
        };
      } catch (e) {
        // Remove loading icons from predictions
        clearOutstandingPredictions();
        throw e;
      }
    }

    async function addRelationship({ instagramName }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.relationshipId = true;
      error.value.relationshipId = null;
      try {
        const response = await InstagramAPI.addRelationship({ brandId, instagramName });
        const payload = response?.data;
        relationshipId.value = payload;
        return payload;
      } catch (e) {
        error.value.relationshipId = e;
        throw e;
      } finally {
        pending.value.relationshipId = false;
      }
    }

    async function updateIGPost({ postSourceId, postBrandId }) {
      const brandId = postBrandId ?? authStore.currentBrand?.id;

      pending.value.igPost = true;
      try {
        const response = await InstagramAPI.updatePost({ brandId, postSourceId });
        const payload = response?.data;
        igPost.value = payload;
        return payload;
      } finally {
        pending.value.igPost = false;
      }
    }

    async function getReachstats({ startDate, endDate, scale }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.reachStats = true;
      try {
        const signal = refreshAbortController(abortControllers.getReachstats);

        const response = await InstagramAPI.getReachStats(
          { brandId, startDate, endDate, scale },
          { signal },
        );
        const payload = response?.data;
        reachStats.value = payload;
        return payload;
      } catch (e) {
        handleCancelError(e);
      } finally {
        pending.value.reachStats = false;
      }
      return undefined;
    }

    async function getUgcPosts({ ugcMediaType, startDate, endDate, startHour }) {
      const brandId = authStore.currentBrand?.id;

      const limit = UGC_POST_LIMIT;
      const offset = ugcPostOffset.value;

      pending.value.getUgcPostsStatus = true;
      try {
        const response = await InstagramAPI.getUgcPosts({
          brandId,
          ugcMediaType,
          startDate,
          endDate,
          offset,
          limit,
          startHour,
        });
        const payload = response?.data;
        ugcPostOffset.value += UGC_POST_LIMIT;
        if (!payload?.paging?.next) {
          ugcPostFullyLoaded.value = true;
        }
        const mediaList = payload?.data?.map((media) => ({
          ...media.library_media,
          is_commented: media.is_commented,
          post_type: media.type,
        }));

        ugcPosts.value = [...ugcPosts.value, ...formatMediaListData(mediaList)];
        return payload;
      } finally {
        pending.value.getUgcPostsStatus = false;
      }
    }

    async function getEngagementCount({ ugcMediaType, startDate, endDate, startHour }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.getUgcPostsCounts({
        brandId,
        ugcMediaType,
        startDate,
        endDate,
        startHour,
      });
      const payload = response?.data;
      engagementCount.value = payload;
      return payload;
    }

    async function getUgcReachCSV({ startDate, endDate, ugcMediaType }) {
      const brandId = authStore.currentBrand?.id;
      const socketId = socketStore.id;
      const type = CSV_TYPE_INSTAGRAM_UGC_POST;

      const response = await InstagramAPI.getUgcPostsCountsCsv({
        brandId,
        startDate,
        endDate,
        ugcMediaType,
        socketId,
        type,
      });
      const payload = response?.data;

      return payload;
    }

    function clearUgcPosts() {
      ugcPosts.value = [];
      ugcPostOffset.value = 0;
      ugcPostFullyLoaded.value = false;
    }

    async function getAccountPosts({ instagramId, limit = 60, sortOrder = 'asc' }) {
      pending.value.accountPosts = true;

      try {
        const response = await InstagramAPI.getAccountPosts({ instagramId, limit, sortOrder });
        const payload = response.data;
        internalAccountPosts.value = payload.data;
        paging.value.accountPosts = payload.paging;
        accountPostsLoaded.value = true;

        predictFromPosts();

        return payload;
      } finally {
        pending.value.accountPosts = false;
      }
    }

    async function getAccountPostsNextPage() {
      if (!paging.value.accountPosts?.next || pending.value.accountPosts) {
        return;
      }

      pending.value.accountPosts = true;

      try {
        const response = await InstagramAPI.axiosCamelCase.get(paging.value.accountPosts.next);
        const payload = response.data;
        internalAccountPosts.value = [...internalAccountPosts.value, ...payload.data];
        paging.value.accountPosts = payload.paging;

        predictFromPosts();
      } finally {
        pending.value.accountPosts = false;
      }
    }

    async function clearAccountPosts() {
      internalAccountPosts.value = [];
      predictions.value = {};
      pending.value.accountPosts = false;
      paging.value.accountPosts = null;
      accountPostsLoaded.value = false;
    }

    return {
      pending,
      error,
      idealPostTimes,
      posts,
      igPost,
      relationshipId,
      ugcPosts,
      ugcPostOffset,
      ugcPostFullyLoaded,
      reachStats,
      engagementCount,
      listIdealPostTimes,
      listPosts,
      addRelationship,
      updateIGPost,
      getReachstats,
      getUgcPosts,
      getEngagementCount,
      getUgcReachCSV,
      clearUgcPosts,
      getAccountPosts,
      getAccountPostsNextPage,
      clearAccountPosts,
      accountPosts,
      accountPostsLoaded,
      predictFromPosts,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
