import { DASHBOARD_ROUTE_NAMES } from '@/app/dashboards/constants';

const App = () => import('@/app/dashboards/App.vue');
const DashboardsHome = () => import('@/app/dashboards/pages/DashboardsHome.vue');

const PAGE_LEVEL_TOP = `Dashboards`;

export const dashboardsRouteName = 'dashboards';
export default {
  path: '/dashboards',
  component: App,
  meta: {
    requiresAuth: true,
    hideSecondaryNav: true,
  },
  children: [
    {
      name: DASHBOARD_ROUTE_NAMES.REPORT,
      path: ':id/:slug?/report',
      component: DashboardsHome,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: DASHBOARD_ROUTE_NAMES.ID,
      path: ':id/:slug?',
      component: DashboardsHome,
      meta: {
        analytics: `${PAGE_LEVEL_TOP}`,
      },
    },
    {
      name: DASHBOARD_ROUTE_NAMES.DASHBOARDS,
      path: '',
      component: DashboardsHome,
      meta: {
        analytics: `${PAGE_LEVEL_TOP}`,
      },
    },
  ],
};
