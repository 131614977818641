/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'instagram-reels': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M5.754 0h8.492c1.58 0 3.018.645 4.06 1.68A5.682 5.682 0 0120 5.72v8.562a5.687 5.687 0 01-1.69 4.035l-.003.003a5.755 5.755 0 01-4.06 1.68H5.755a5.762 5.762 0 01-4.064-1.68l-.04-.044A5.68 5.68 0 010 14.281V5.719c0-1.573.647-3.003 1.69-4.04A5.752 5.752 0 015.754 0zm9.23 5.049l.012.018h3.537a4.306 4.306 0 00-1.225-2.391 4.336 4.336 0 00-3.062-1.266h-1.452l2.19 3.639zm-1.635.018L11.146 1.41h-4.83l2.228 3.657h4.805zm-6.454 0L4.74 1.528a4.337 4.337 0 00-2.048 1.148 4.311 4.311 0 00-1.225 2.39h5.428zm11.687 1.41H1.418v7.805c0 1.167.473 2.23 1.238 3.008l.036.034a4.34 4.34 0 003.062 1.268h8.492a4.334 4.334 0 003.06-1.265l.004-.003a4.282 4.282 0 001.272-3.042V6.476zM8.342 8.943l4.394 2.84a.632.632 0 01.021 1.058l-4.375 2.567a.638.638 0 01-.406.143.638.638 0 01-.64-.637v-5.45h.003a.641.641 0 011.004-.52v-.001z" _fill="#686a7a" fill-rule="evenodd"/>'
  }
})
