/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'people-user': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M22.502 16.5h3c0-2.07-1.727-3.61-3.944-4.412 1.035-.961 1.694-2.318 1.694-3.838A5.256 5.256 0 0018.002 3a5.256 5.256 0 00-5.25 5.25c0 1.52.658 2.877 1.693 3.838-2.217.802-3.944 2.342-3.944 4.412h3c0-.897 2.12-2.036 4.5-2.036 2.381 0 4.5 1.139 4.5 2.036zM18.002 6c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25c-1.241 0-2.25-1.01-2.25-2.25S16.76 6 18.002 6zm-3.75 18.75c0 1.52-.659 2.877-1.694 3.838 2.217.802 3.944 2.342 3.944 4.412h-3c0-.895-2.12-2.035-4.5-2.035-2.381 0-4.5 1.138-4.5 2.035h-3c0-2.07 1.726-3.61 3.943-4.412-1.035-.961-1.694-2.319-1.694-3.838a5.256 5.256 0 015.25-5.25 5.256 5.256 0 015.25 5.25zm-3 0c0-1.24-1.01-2.25-2.25-2.25-1.241 0-2.25 1.01-2.25 2.25S7.76 27 9.001 27c1.24 0 2.25-1.01 2.25-2.25zm21 0c0 1.52-.659 2.877-1.694 3.838 2.217.802 3.944 2.342 3.944 4.412h-3c0-.895-2.12-2.035-4.5-2.035-2.381 0-4.5 1.138-4.5 2.035h-3c0-2.069 1.726-3.61 3.943-4.412-1.035-.961-1.694-2.319-1.694-3.838a5.256 5.256 0 015.25-5.25 5.256 5.256 0 015.25 5.25zm-3 0c0-1.24-1.01-2.25-2.25-2.25-1.241 0-2.25 1.01-2.25 2.25S25.76 27 27.002 27c1.24 0 2.25-1.01 2.25-2.25z" _fill="#666"/>'
  }
})
