<template>
  <div class="gallery-card">
    <img v-if="galleryHasLatestMedia" :src="gallery.latestMedia[0].imageUrl" />
    <section>
      <header>{{ gallery.name }}</header>
      <main>
        <div class="media-count">
          <span class="stats">{{ gallery.gallerySize }}</span>
          Media
        </div>
        <span v-if="gallery.tags" class="tag-labels">
          <div
            v-for="(tag, index) in gallery.tags"
            :key="index"
            :style="{ background: tag.color }"
            class="tag"
          />
        </span>
      </main>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'GalleryCard',
  props: { gallery: { type: Object, default: null } },
  computed: {
    galleryHasLatestMedia() {
      return this.gallery?.latestMedia?.length > 0;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.gallery-card {
  display: flex;
  align-items: center;
  min-width: 0;
  background: var(--background-0);
  border-radius: var(--round-corner);
  box-shadow: var(--light-shadow);
  padding: var(--space-24) var(--space-40) var(--space-24) var(--space-24);
  transition: var(--transition-all);
}

header {
  height: 1.5rem;
  text-transform: capitalize;
  font-size: var(--x18);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

section {
  width: 100%;
  min-width: 0;

  header {
    display: flex;
    align-content: left;
  }
}

.gallery-card img {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: var(--round-corner-small);
  margin-right: var(--space-12);
  transition: none;
}

main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-count {
  font-size: var(--x14);
  color: var(--text-secondary);
  display: flex;
  flex-wrap: nowrap;

  .stats {
    color: var(--action-500);
    margin-right: var(--space-4);
  }
}

.tag-labels {
  display: flex;
  flex-wrap: wrap;

  .tag {
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 100%;
    margin: var(--space-4) 0 0 var(--space-4);
  }
}

.gallery-card:hover {
  cursor: pointer;
  box-shadow: var(--light-shadow);
  transform: scale(1.03, 1.03);
}
</style>
