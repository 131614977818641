/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'accountDisconnected': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 7h4v3l4-4-4-4v3H6c-1.104 0-2 .897-2 2v10c0 1.103.896 2 2 2h2v-2H6V7zM18 5h-2v2h2v10h-4v-3l-4 4 4 4v-3h4c1.104 0 2-.897 2-2V7c0-1.103-.896-2-2-2z" _fill="#686a7a"/><path pid="1" _fill="#686a7a" d="M21.486 2L22.9 3.414 3.414 22.9 2 21.486z"/>'
  }
})
