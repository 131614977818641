/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'copy': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.333 2.667a.667.667 0 00-.666.666v7.5a.667.667 0 00.666.667h.834a1 1 0 110 2h-.834a2.667 2.667 0 01-2.666-2.667v-7.5A2.667 2.667 0 013.333.667h7.5A2.667 2.667 0 0113.5 3.333v.834a1 1 0 11-2 0v-.834a.666.666 0 00-.667-.666h-7.5zM9.167 8.5a.667.667 0 00-.667.667v7.5c0 .368.298.666.667.666h7.5a.667.667 0 00.666-.666v-7.5a.667.667 0 00-.666-.667h-7.5zM6.5 9.167A2.667 2.667 0 019.167 6.5h7.5a2.667 2.667 0 012.666 2.667v7.5a2.667 2.667 0 01-2.666 2.666h-7.5A2.667 2.667 0 016.5 16.667v-7.5z" _fill="#4990E2"/>'
  }
})
