/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tagFilled': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.707 11.293l-11-11A.997.997 0 008 0H1a1 1 0 00-1 1v7c0 .266.105.519.293.707l11 11a.997.997 0 001.414 0l7-7a.999.999 0 000-1.414zM5 7a2 2 0 11-.001-3.999A2 2 0 015 7z" _fill="#4990E2"/>'
  }
})
