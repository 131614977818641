/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-triangle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 22H3a1.003 1.003 0 01-.895-1.448l9-18c.339-.677 1.45-.677 1.789 0l9 18A1.003 1.003 0 0121 22zM4.618 20h14.764L12 5.237 4.618 20z"/><path pid="1" d="M11 10h2v6h-2z"/><circle pid="2" cx="12" cy="18" r="1.25"/>'
  }
})
