import humps from 'humps';
import dayjs from 'dayjs';
import { sourceTypeEnum } from '@/app/library/constants';
import { sourceEnum, typeEnum } from '@/config';

/**
  Media model to mock the structure of the unified media object (v2). This model handles the
  (de)serialization, (de)camel-casing, computed properties, etc. Technical spec doc:
  https://docs.google.com/document/d/1mfX6ISObAVzqxwsmQTN7jKIPd9C8cD5jQrecXhNIiYI/edit?usp=sharing
*/
class MediaModel {
  constructor(media) {
    // recursively convert the keys of each media property to camelCase
    const formattedMedia = humps.camelizeKeys(media);
    const type = formattedMedia.type.toLowerCase();
    this.brandId = formattedMedia.brandId;
    this.createdAt = formattedMedia.createdAt;
    this.editorData = formattedMedia[type]?.editorData || null;
    this.id = formattedMedia.id;
    this.predictions = formattedMedia.predictions || null;
    this.videoPredictions = formattedMedia.videoPredictions || null;
    this.videoSuggestedThumbnailList = formattedMedia.videoSuggestedThumbnailList || null;
    this.videoPredictionInterval = formattedMedia.videoPredictionsIntervalSec || null;
    // TODO: add visualFeatures here once that's been added to the V2 search endpoint
    this.sizes = formattedMedia[type]?.sizes;
    this.source = sourceEnum[formattedMedia.source];
    this.sourceType = sourceTypeEnum[formattedMedia.sourceType] || null;
    this.transforms = formattedMedia[type]?.transforms || null;
    this.type = typeEnum[formattedMedia.type];
    this.updatedAt = formattedMedia.updatedAt;
    this.instagram = formattedMedia.instagram || null;
    this.facebook = formattedMedia.facebook || null;
    this.twitter = formattedMedia.twitter || null;
    this.pinterest = formattedMedia.pinterest || null;
    this.linkedin = formattedMedia.linkedin || null;
    this.canPublishWithin = formattedMedia.canPublishWithin && {
      start: dayjs(formattedMedia.canPublishWithin.start).toDate(),
      end: dayjs(formattedMedia.canPublishWithin.end).toDate(),
    };
    this.sourceId = formattedMedia.sourceId || null;
    this.visualFeatures = formattedMedia.visualFeatures || null;

    if (type === 'video') {
      this.duration = formattedMedia.video.duration;
      this.frameRate = formattedMedia.video.frameRate;
      this.thumbnails = formattedMedia.video.thumbnails;
    }
  }
}

export default MediaModel;
