<template>
  <div
    :class="['select', { disabled: disabled, compact, highlight: showOptions }]"
    data-cy="pin-board-selector"
  >
    <SelectionDisplayInput :open="showOptions" @click="toggleOptions">
      <template v-if="value && value.name">{{ value.name }}</template>

      <template v-else>
        Choose a Pinterest Board
        <span class="asterisk">*</span>
      </template>
    </SelectionDisplayInput>
    <transition name="slide">
      <div
        v-if="showOptions"
        v-on-click-outside="hideOptions"
        data-cy="PinterestBoardSelectorOptions"
        :class="{ alignTop }"
        class="dropdownMenu"
      >
        <!-- add new board view -->
        <section v-if="showAddNewScreen">
          <div class="content">
            <p class="title">
              Create a new Pinterest Board
              <InfoTooltip
                tooltip="A new board will be created on Pinterest and will be visible to your audience when the first Pin scheduled to that board is published."
              />
            </p>
            <CircularLoader v-if="schedulerPinterestStore.createBoardStatus === 'pending'" />
            <template v-else>
              <p>Pinterest Board Name</p>
              <input ref="boardNameInput" v-model="newBoardName" placeholder="Enter a board name" />
            </template>
          </div>
          <div class="footer">
            <span @click="openBoardList">Cancel</span>
            <span class="primary" @click="createNewBoard">Save</span>
          </div>
        </section>
        <!-- board list view -->
        <section v-else class="board-list">
          <div class="top">
            <input
              v-model="search"
              :placeholder="searchPlaceholder"
              data-cy="pin-board-selector-input"
            />
            <Icon
              :hover-color="colours.ACTION.ACTION_500"
              name="add"
              small
              class="add-icon"
              @click="openAddNew"
            />
          </div>
          <ul>
            <li
              v-for="board in displayedBoardList"
              :key="board.id"
              :class="{ selected: value && value.id === board.id }"
              @click="selectBoard(board)"
            >
              <div class="left">
                <img v-if="board.protected === false" :src="board.preview_url" />
                <div v-else class="private-board">
                  <Icon :color="colours.BRAND.BRAND_ACCENT" name="folderLock" />
                </div>
                {{ board.name }}
              </div>
              <div class="icon-container">
                <Icon
                  v-if="value && value.id === board.id"
                  :color="colours.ACTION.ACTION_500"
                  name="check"
                  class="select-icon"
                  xsmall
                />
              </div>
            </li>
          </ul>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { createBoardFailedMessages } from '@/config';
import { colours } from '@/ux/colours';
import CircularLoader from '@/components/CircularLoader.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Icon from '@/components/foundation/Icon.vue';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import SelectionDisplayInput from '@/components/foundation/SelectionDisplayInput.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PinterestBoardSelector',
  components: {
    CircularLoader,
    Icon,
    InfoTooltip,
    SelectionDisplayInput,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    alignTop: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: Object, default: null },
  },
  emits: ['input'],
  data() {
    return {
      placeholder: 'Choose a Pinterest Board',
      searchPlaceholder: 'Search Pinterest Boards',
      showOptions: false,
      search: '',
      showAddNewScreen: false,
      newBoardName: '',
    };
  },
  computed: {
    ...mapStores(useAuthStore, useNotificationStore, useSchedulerPinterestStore, useTrackingStore),
    colours() {
      return colours;
    },
    displayedBoardList() {
      if (this.schedulerPinterestStore.boardList.length > 0) {
        if (this.search) {
          return this.schedulerPinterestStore.boardList.filter(
            (board) => board.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1,
          );
        }
        return this.schedulerPinterestStore.boardList;
      }
      return [];
    },
  },
  watch: {
    'schedulerPinterestStore.createBoardStatus': {
      handler(to) {
        if (!this.showAddNewScreen) {
          return;
        }
        if (to === 'success') {
          const { newBoardName } = this;
          this.newBoardName = '';
          this.selectBoard(this.schedulerPinterestStore.boardList[0]);
          this.trackingStore.track('Pinterest Board Created', { boardName: newBoardName });
        } else if (to === 'failed') {
          const { newBoardName } = this;
          if (this.schedulerPinterestStore.errorMsg === createBoardFailedMessages.duplicate) {
            this.notificationStore.setToast({
              message: `You already have a Pinterest Board called ${newBoardName}!`,
              type: 'error',
            });
          } else if (this.schedulerPinterestStore.errorMsg === createBoardFailedMessages.deleted) {
            this.notificationStore.setToast({
              message: `You recently deleted a Pinterest Board called ${newBoardName}, please restore that board on Pinterest`,
              type: 'error',
            });
          } else {
            this.notificationStore.setToast({
              message: `${this.schedulerPinterestStore.errorMsg}`,
              type: 'error',
            });
          }
        }
      },
    },
  },
  async mounted() {
    this.showAddNewScreen = false;
    await this.schedulerPinterestStore.listPinterestBoards();
  },
  methods: {
    toggleOptions() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
      }
    },
    hideOptions() {
      this.search = '';
      this.showOptions = false;
      this.showAddNewScreen = false;
    },
    selectBoard(board) {
      if (this.value && this.value.id === board.id) {
        // unselect current and stay in popup
        this.$emit('input', null);
      } else {
        // select new board and close popup
        this.$emit('input', board);
      }
      this.hideOptions();
    },
    createNewBoard() {
      this.schedulerPinterestStore.createPinterestBoards({ name: this.newBoardName });
    },
    openAddNew() {
      this.showAddNewScreen = true;
      // auto focus on input field
      nextTick(() => {
        this.$refs.boardNameInput.focus();
      });
    },
    openBoardList() {
      this.showAddNewScreen = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.select {
  margin: 0 var(--space-4);
  min-width: 10.5rem;
  position: relative;

  .asterisk {
    color: var(--error-500);
  }

  .picker:hover {
    color: var(--action-500);
  }

  .picker.placeholder {
    font-weight: normal;
    text-transform: none;
  }

  .picker.highlighted {
    color: var(--action-500);
    border-color: var(--action-500);
  }

  .dropdownMenu {
    position: absolute;
    z-index: var(--z-index-dropdown);
    width: 100%;
    background-color: var(--background-0);
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-4);
    top: 3rem;

    .content {
      padding: var(--space-28) var(--space-32);
      align-items: center;

      p.title {
        font-size: var(--x16);
        text-transform: none;
        font-weight: var(--font-medium);
        margin-bottom: var(--space-24);

        :deep(.svg-icon) {
          transform: translate(-5px, 2.5px);
        }
      }

      input {
        width: 100%;
        margin: var(--space-8) auto;
        height: var(--space-40);
        border: 1px solid var(--border);
        border-radius: var(--round-corner-small);
        padding: 0 var(--space-12) var(--space-4);
        font-size: var(--x14);
        color: var(--text-primary);
      }
    }

    .footer {
      height: var(--space-48);
      background-color: var(--background-300);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--x14);
      font-weight: var(--font-medium);
      padding: 0 var(--space-32) var(--space-4);

      .primary {
        color: var(--action-500);
      }

      span {
        cursor: pointer;

        &:hover {
          color: var(--action-500);
        }
      }
    }

    .board-list {
      .top {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding: var(--space-16) 0 var(--space-12) var(--space-20);
      }

      input {
        width: 100%;
        height: var(--space-40);
        border: 1px solid var(--border);
        border-radius: 1.375rem;
        padding: var(--space-12) var(--space-12) var(--space-12) var(--space-48);
        background: url('@/assets/icons/search.svg') var(--background-0) no-repeat 1rem center;
        transition: var(--transition-all);
        font-size: var(--x12);

        &:hover,
        &:focus {
          border-color: var(--action-500);
        }
      }

      .add-icon {
        margin: 0 var(--space-20) 0 var(--space-12);
      }

      ul {
        width: 100%;
        border-radius: var(--round-corner);
        color: var(--text-primary);
        vertical-align: middle;
        display: inline-block;
        max-height: 13rem;
        overflow-y: auto;
        padding-bottom: var(--space-12);

        .selected {
          color: var(--action-500);
        }

        .select-icon {
          margin-right: var(--space-16);
        }

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: var(--space-4);
          cursor: pointer;
          line-height: var(--space-32);
          font-size: var(--x14);
          font-weight: var(--font-medium);
          text-transform: capitalize;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            word-break: break-word;
            line-height: var(--space-16);
            padding-right: var(--space-12);
            text-transform: none;
          }

          .private-board {
            width: var(--space-40);
            height: var(--space-40);
            margin: 0 var(--space-12);
            background-color: var(--alert-100);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            width: var(--space-40);
            height: var(--space-40);
            margin: 0 var(--space-12);
          }
        }

        li:hover {
          background: var(--background-300);
        }

        li .icon-container {
          width: var(--space-32);
          height: var(--space-32);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .alignTop.dropdownMenu {
    top: unset;
    bottom: var(--space-56);
  }
}

.disabled .picker:hover {
  color: var(--text-primary);
  cursor: not-allowed;
}

.select.compact .picker {
  font-size: var(--x12);
  font-weight: var(--font-medium);
}

.select.compact .dropdownMenu {
  .top {
    padding: var(--space-12) var(--space-12) var(--space-8) var(--space-12);

    .add-icon {
      margin: 0 var(--space-4) 0 var(--space-12);
    }
  }

  .board-list ul {
    li {
      line-height: var(--space-16);
      font-size: var(--x12);

      .select-icon {
        margin-right: var(--space-12);
      }

      img {
        margin: 0 var(--space-12);
      }
    }
  }
}
</style>
