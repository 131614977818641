<template>
  <div ref="container" class="vertically-truncated-text">
    <div
      class="displayed-text"
      :style="{ '-webkit-line-clamp': displayLineCount, height: displayHeight }"
    >
      <slot />
    </div>
    <div ref="referenceLine" class="reference-line">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'VerticallyTruncatedText',
  data() {
    return {
      resizeObserver: new ResizeObserver(([containerEvent, referenceLineEvent]) => {
        if (containerEvent && referenceLineEvent) {
          this.containerHeight = containerEvent.contentRect.height;
          this.referenceLineHeight = referenceLineEvent.contentRect.height;
        }
      }),
      containerHeight: null,
      referenceLineHeight: null,
    };
  },
  computed: {
    heightsAvailable() {
      return this.containerHeight !== null && this.referenceLineHeight !== null;
    },
    displayLineCount() {
      if (!this.heightsAvailable) return 0;
      return Math.floor(this.containerHeight / this.referenceLineHeight);
    },
    displayHeight() {
      if (!this.heightsAvailable) return '0';
      return `${this.displayLineCount * this.referenceLineHeight}px`;
    },
  },
  updated() {
    this.containerHeight = this.$refs.container.clientHeight;
    this.referenceLineHeight = parseFloat(getComputedStyle(this.$refs.referenceLine).lineHeight);
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.container);
    this.resizeObserver.observe(this.$refs.referenceLine);
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.vertically-truncated-text {
  position: relative;
  overflow: hidden;

  .displayed-text,
  .reference-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .displayed-text {
    bottom: 0;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .reference-line {
    opacity: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
