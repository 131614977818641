/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isError from 'lodash/isError';
import { env } from '@/env';

function checkArguments(...args) {
  const message = args?.[0];
  const messageContext = args?.[1];
  const error = args?.[2];
  if (message && !isString(message)) {
    throw Error('logger message must be a string');
  }
  if (messageContext && (!isObject(messageContext) || isError(messageContext))) {
    throw Error('logger messageContext must be an object and not an error');
  }
  if (error && !isError(error)) {
    throw Error('logger error must be an Error instance');
  }
}

export const logger = {
  debug(message, messageContext, error) {
    checkArguments(...arguments);
    if (env.isLocalDev) {
      return console.debug(...arguments);
    }
    return datadogLogs.logger.debug(...arguments);
  },
  info(message, messageContext, error) {
    checkArguments(...arguments);
    if (env.isLocalDev) {
      return console.info(...arguments);
    }
    return datadogLogs.logger.info(...arguments);
  },
  warn(message, messageContext, error) {
    checkArguments(...arguments);
    if (env.isLocalDev) {
      return console.warn(...arguments);
    }
    return datadogLogs.logger.warn(...arguments);
  },
  error(message, messageContext, error) {
    checkArguments(...arguments);
    if (env.isLocalDev) {
      return console.error(...arguments);
    }
    return datadogLogs.logger.error(...arguments);
  },
};
