import sortBy from 'lodash/sortBy';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import groupBy from 'lodash/groupBy';
import { COMMUNITY_INTERACTION_TYPES } from '@/app/community/constants';

export const filterMetaDms = (messages) => {
  return messages.filter((currentMessage, index, array) => {
    if (index === array.length - 1) return true; // keep the last object
    const nextMessage = array[index + 1];
    const isTextMessage =
      currentMessage.text !== 'with media' &&
      !!currentMessage.text?.length &&
      !currentMessage.isDhUnsupported &&
      !currentMessage.isFbUnsupported &&
      !currentMessage.storyMention &&
      !currentMessage.storyReply;
    const isFromSameAuthor = currentMessage.fromParticipantId === nextMessage.fromParticipantId;
    const hasDifferentText = currentMessage.text?.trim() !== nextMessage.text?.trim();
    return (
      (hasDifferentText && isTextMessage) || // Is not a duplicate message
      (!hasDifferentText && !isFromSameAuthor) || // Has same text, but from different authors
      !isTextMessage // Is not a text message
    );
  });
};

export const sortMessages = (messages) => {
  if (messages.length === 0) {
    return messages;
  }
  const { type } = messages[0];
  let createdAtField;
  if (type === COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION) {
    createdAtField = 'facebookCreatedAt';
  } else if (type === COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION) {
    createdAtField = 'twitterCreatedAt';
  } else {
    createdAtField = 'instagramCreatedAt';
  }

  const sortedByCreatedAt = sortBy(messages, [
    (message) => message[createdAtField].valueOf(),
    (message) => message.id,
  ]);
  const sentMessages = sortedByCreatedAt.filter((m) => !m.isPending);
  const pendingMessages = sortedByCreatedAt.filter((m) => m.isPending);
  const messageList = [...sentMessages, ...pendingMessages];

  /*
   SC-122680
   Filter logic is a temp workaround to a meta issue. Can eventually be removed.
   Assumes that the websocket message was created after the API POST. Given the messageList with
   the newest messages at the end of the array, filter out the older one. Only looking to filter
   text messages, as we do not allow sending story mention / replies, or unsupported types, from DH
   */
  if (
    ![
      COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION,
      COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION,
    ].includes(type)
  ) {
    return messageList;
  }
  return filterMetaDms(messageList);
};

const reactionsToMap = (reactions) =>
  fromPairs(
    (reactions || []).flatMap(({ type, participantIds }) =>
      participantIds.map((pid) => [pid, type]),
    ),
  );

const reactionsToList = (reactionsMap) => {
  const reactions = toPairs(groupBy(toPairs(reactionsMap), (pair) => pair[1]))
    .map(([type, items]) => ({
      type,
      participantIds: items.map((item) => parseInt(item[0], 10)),
    }))
    .filter(({ participantIds, type }) => type && participantIds.length > 0);
  return reactions.length === 0 ? null : reactions;
};

export const setReaction = (message, reaction, participantId) => {
  const reactionsMap = reactionsToMap(message.reactions);
  if (reaction) {
    reactionsMap[participantId] = reaction;
  } else {
    delete reactionsMap[participantId];
  }
  return {
    ...message,
    reactions: reactionsToList(reactionsMap),
  };
};
