<template>
  <SchedulePostPopupWrapper
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :linked-comment-id="linkedCommentId"
    :avatar="avatarUrl"
    :display-name="handle"
    :tabs="tabs"
    :tab-changed="tabChanged"
    :active-tab="currentTab"
    :show-media-placeholder-message="mediaList.length === 0"
    :has-unsaved-changes="hasUnsavedChanges"
    platform="instagram"
    class="instagram-scheduling"
    data-cy="instagram-scheduling"
  >
    <template #media>
      <MediaManager
        :disable-editing="disableEditing"
        :display-error-message="onMediaUploadError"
        :download-media-clicked="downloadMediaClicked"
        :media-list="mediaList"
        :media-count-limit="mediaCountLimit"
        :media-selected="mediaSelected"
        :viewer-component="viewerComponent"
        :viewer-component-props="viewerComponentProps"
        :on-upload-status-change="uploadStatusChanged"
        :full-height="mediaList.length > 1 && isFeedTab"
        :custom-cropper-presets="customCropperPresets"
        :enable-free-crop="false"
        :media-type-limit="isReelTab ? 'VIDEO' : null"
        :post-type="currentTab"
        :on-cropping-cover-image="onCroppingCoverImage"
        :vision-usage-info="visionUsageInfo"
        :can-be-published-at="scheduledTime"
        :publish-dates-must-overlap-with="canPublishWithin"
        disallow-past-publish-dates
        disallow-incompatible-publish-dates
        @remove-cover-photo="removeReelCoverImage"
        @select-cover-image="coverImageAdded"
      />
      <VideoPredictionThumbnails
        v-if="displayKeyframes"
        :media="mediaList[0]"
        :is-processing="mediaList[0].isProcessing"
        :video-predictions="mediaList[0].videoPredictions"
        :suggested-thumbnails="mediaList[0].videoSuggestedThumbnailList"
        :prediction-interval="mediaList[0].videoPredictionInterval"
        @offset-update="handleOffset"
      />
      <Banner
        v-if="coverPhotoValidationError && isReelTab"
        :alert-type="coverPhotoValidationError.level"
        class="validation-banner"
        data-cy="validation-banner"
        hide-default-icon
      >
        {{ coverPhotoValidationError.message }}
      </Banner>
      <Banner
        v-if="showValidationBox"
        :alert-type="validationErrorOrWarning.level"
        :custom-icon="validationErrorOrWarning.icon"
        class="validation-banner"
        data-cy="validation-banner"
        hide-default-icon
      >
        {{ validationErrorOrWarning.message }}
      </Banner>
      <Banner
        v-if="publishDateRangeWarning"
        :alert-type="publishDateRangeWarning.level"
        class="validation-banner"
        data-cy="validation-banner"
      >
        {{ publishDateRangeWarning.message }}
      </Banner>
    </template>
    <template #details>
      <KeepAlive>
        <component :is="editComponent" ref="instagramPost" v-bind="editComponentAttrs" />
      </KeepAlive>
    </template>

    <!-- Temp solution for displaying media links as popup -->
    <MediaLinks
      v-if="mediaLinksStore.showLinkPopup"
      context="schedulerPopup"
      :prefetch-links="false"
    />
    <!-- There are two story popup links -->
    <ProductPopup
      v-if="showStoryProductPopup"
      @on-change="updateSelectedStoryLink"
      @close="closeStoryProductPopup"
    />
  </SchedulePostPopupWrapper>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import invert from 'lodash/invert';
import { isPosted } from '@/app/scheduler/utils/post-status';
import { useAuthStore } from '@/stores/auth';
import { usePlatformStore } from '@/stores/platform';
import {
  discardCommentConfirmMessage,
  discardConfirmMessage,
  instagramFeedPostCropperPresetsV2,
  instagramStoryCropperPresetsV2,
  instagramStoryReelCropperPresets,
  instagramReelCoverImageCropperPresets,
  toolTips,
} from '@/config';
import {
  validatePostMedia,
  validateIGCustomCoverImage,
} from '@/app/scheduler/utils/mediaValidation';
import {
  postStatus,
  PLATFORMS,
  invalidUserTagsWarningMessage,
  invalidCollaboratorsWarningMessage,
} from '@/app/scheduler/constants';
import { shouldDisplayKeyFrames } from '@/app/scheduler/utils';
import Banner from '@/components/foundation/feedback/Banner.vue';
import MediaLinks from '@/app/library/components/MediaLinks.vue';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import VideoPredictionThumbnails from '@/app/scheduler/components/EditPost/MediaViewer/VideoPredictionThumbnails.vue';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { useCommentingStore } from '@/stores/commenting';
import { useMediaLinksStore } from '@/stores/media-links';
import { useMediaStore } from '@/stores/media';
import { useFlagStore } from '@/stores/flag';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { useIdentityStore } from '@/stores/identity';
import {
  mixpanelPublishTypes,
  postTypeLabelMap,
  SchedulerUserEventTracker,
  trackInstagramPostTypeClick,
} from '@/app/scheduler/mixpanel';
import { mediaTypes } from '@/app/library/constants';
import { validatePublishDates, getConflictingMediaIds } from '@/app/scheduler/utils/publish-dates';
import { USER } from '@/models/auth/permissions.enum';
import Header from './Layout/Header.vue';
import EditInstagramFeedPost from './EditInstagramFeedPost.vue';
import EditInstagramStoryPost from './EditInstagramStoryPost.vue';
import EditInstagramReelPost from './EditInstagramReelPost.vue';
import MediaContainer from './Layout/MediaContainer.vue';
import MediaManager from './MediaViewer/MediaManager.vue';
import GridViewer from './MediaViewer/GridViewer.vue';
import CarouselViewer from './MediaViewer/CarouselViewer.vue';

const postTypes = {
  FEED: 'Feed Post',
  STORY: 'Story',
  REEL: 'Reel',
};

const aspectRatios = {
  square: 'square',
  portrait: 'portrait',
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'EditInstagramPost',
  components: {
    Banner,
    ProductPopup: defineAsyncComponent(
      () => import('@/app/library/components/MediaPopup/ProductPopup.vue'),
    ),
    Header,
    EditInstagramStoryPost,
    EditInstagramFeedPost,
    EditInstagramReelPost,
    MediaLinks,
    MediaContainer,
    MediaManager,
    SchedulePostPopupWrapper,
    VideoPredictionThumbnails,
  },
  mixins: [ApprovedPublishingDatesMixin],
  props: {
    close: { type: Function, required: true },
    post: { type: Object, default: null },
    media: { type: Array, default: null },
    mediaCaption: { type: String, default: null },
    context: { type: String, default: null },
    disableEditing: { type: Boolean, default: false },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
    postType: { type: String, default: 'FEED' },
  },
  data() {
    return {
      avatarUrl: null,
      currentTab: postTypes[this.post?.post_type] || postTypes[this.postType],
      currentPostData: null,
      duration: null,
      handle: '',
      hasUnsavedChanges: false,
      mediaList: [],
      mediaUploadError: null,
      mediaValidationError: null,
      coverPhotoValidationErrorMessage: null,
      isValidatingMedia: false,
      mediaWarning: null,
      mediaWarningIds: [],
      platform: 'instagram',
      showStoryProductPopup: false,
      uploadPending: false,
      mediaValidationPending: true,
      coverImageMedia: this.post?.coverImageMedia,
      isCroppingCoverImage: false,
    };
  },
  computed: {
    ...mapStores(
      useCommentingStore,
      useAuthStore,
      usePlatformStore,
      useMediaLinksStore,
      useMediaStore,
      useFlagStore,
      useInstagramAccountStore,
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useIdentityStore,
    ),
    editComponent() {
      if (this.isFeedTab && this.flagStore.ready) {
        return EditInstagramFeedPost;
      }
      if (this.isStoryTab) {
        return EditInstagramStoryPost;
      }
      if (this.isReelTab) {
        return EditInstagramReelPost;
      }
      return null;
    },
    editComponentAttrs() {
      if (this.isFeedTab && this.flagStore.ready) {
        return {
          context: this.context,
          close: this.close,
          isActiveTab: this.isFeedTab,
          post: this.post,
          invalidUserTagsWarning: this.invalidUserTagsWarning,
          invalidCollaboratorsWarning: this.invalidCollaboratorsWarning,
          media: this.mediaList,
          mediaCaption: this.mediaCaption,
          disableEditing: this.disableEditing,
          uploadPending: this.uploadPending,
          mediaValidationPending: this.mediaValidationPending,
          mediaValidationError: this.mediaValidationError,
          isValidatingMedia: this.isValidatingMedia,
          onPostDataUpdated: this.postDataUpdated,
          onUpdateUnsavedStatus: this.updateUnsavedStatus,
        };
      }

      if (this.isStoryTab) {
        return {
          close: this.close,
          isActiveTab: this.isStoryTab,
          post: this.post,
          media: this.mediaList,
          disableEditing: this.disableEditing,
          uploadPending: this.uploadPending,
          mediaValidationError: this.mediaValidationError,
          isValidatingMedia: this.isValidatingMedia,
          showPostEditorSidePanel: this.showPostEditorSidePanel,
          onOnEditProduct: this.openStoryProductPopup,
          onPostDataUpdated: this.postDataUpdated,
          onUpdateUnsavedStatus: this.updateUnsavedStatus,
        };
      }

      if (this.isReelTab) {
        return {
          close: this.close,
          isActiveTab: this.isReelTab,
          post: this.post,
          invalidUserTagsWarning: this.invalidUserTagsWarning,
          invalidCollaboratorsWarning: this.invalidCollaboratorsWarning,
          media: this.mediaList,
          disableEditing: this.disableEditing,
          uploadPending: this.uploadPending,
          mediaValidationPending: this.mediaValidationPending,
          mediaValidationError: this.mediaValidationError || this.coverPhotoValidationError,
          isValidatingMedia: this.isValidatingMedia,
          coverImageMedia: this.coverImageMedia,
          onPostDataUpdated: this.postDataUpdated,
          onUpdateUnsavedStatus: this.updateUnsavedStatus,
        };
      }

      return {};
    },
    publishDateError() {
      return validatePublishDates(this.canPublishWithin, this.currentPostData?.timestamp);
    },
    viewerComponent() {
      return this.isFeedTab && this.mediaList.length > 1 && !this.taggerSubscreenActive
        ? GridViewer
        : CarouselViewer;
    },
    invalidMediaIds() {
      if (this.publishDateError?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES') {
        return getConflictingMediaIds(
          this.mediaList,
          dayjs(this.currentPostData?.timestamp).toDate(),
        );
      }
      // TODO sc-105187: Re -enable when warnings are accurately attributed to specific ids
      return this.mediaList.length > 1 ? [] : this.mediaWarningIds;
    },
    invalidUserTagsWarning() {
      if (this.post?.invalid_user_tags && isPosted(this.post, {})) {
        const invalidUserTags = this.post.invalid_user_tags.join(', ');
        return `${invalidUserTagsWarningMessage}: ${invalidUserTags}.`;
      }
      return null;
    },
    invalidCollaboratorsWarning() {
      if (this.post?.invalid_collaborators && isPosted(this.post, {})) {
        const invalidCollaborators = this.post.invalid_collaborators.join(', ');
        return `${invalidCollaboratorsWarningMessage}: ${invalidCollaborators}.`;
      }
      return null;
    },
    viewerComponentProps() {
      const props = {
        aspectRatio: this.aspectRatio,
        mediaWarningIds: this.invalidMediaIds,
        platform: 'instagram',
        productTaggingViewOnly:
          ['POSTED', 'IMPORTED'].includes(this.post?.status) && this.post?.hasInvalidProductTags,
        coverImage: this.currentTab === postTypes.REEL ? this.coverImageMedia : null,
        onCroppingCoverImage: this.onCroppingCoverImage,
        validationError: this.validationErrorOrWarning,
      };
      if (this.viewerComponent === CarouselViewer) {
        props.navigationEnabled = true;
      }
      return props;
    },
    confirmation() {
      if (!this.disableEditing && this.hasUnsavedChanges) {
        return discardConfirmMessage;
      }
      if (this.commentingStore?.hasUnsavedComments) {
        return discardCommentConfirmMessage;
      }
      return null;
    },
    mediaCountLimit() {
      return this.isReelTab || (this.isStoryTab && this.schedulerStore.autoPublish) ? 1 : 10;
    },
    title() {
      // when start a post from calendar view, post will has a selected timestamp (not null),
      // but it is still a new post. Safer to check both post and post.id
      let verb = this.post && this.post.id ? 'Edit' : 'Add';
      if (this.disableEditing) {
        verb = 'Posted';
      }
      return `${verb} Instagram ${this.currentTab}`;
    },
    tabs() {
      const tabs = Object.values(postTypes);
      return this.post?.id ||
        (this.schedulerStore.activeSubScreen && this.schedulerStore.activeSubScreen !== 'index')
        ? []
        : tabs;
    },
    aspectRatio() {
      return this.isFeedTab ? aspectRatios.square : aspectRatios.portrait;
    },
    displayKeyframes() {
      const mediaValidForDisplayKeyFrames = shouldDisplayKeyFrames(
        this.mediaList,
        this.schedulerStore.autoPublish,
        this.post,
      );
      const isIndexScreen =
        this.schedulerStore.activeSubScreen && this.schedulerStore.activeSubScreen === 'index';
      const noCoverPhoto = !this.isReelTab || !this.coverImageMedia;

      return mediaValidForDisplayKeyFrames && !this.isStoryTab && isIndexScreen && noCoverPhoto;
    },
    validationErrorOrWarning() {
      const errorOrWarning =
        this.publishDateError ||
        this.mediaUploadError ||
        this.mediaValidationError ||
        this.coverPhotoValidationErrorMessage ||
        this.mediaWarning;
      return [postStatus.IMPORTED, postStatus.POSTED].includes(this.post?.status)
        ? null
        : errorOrWarning;
    },
    coverPhotoValidationError() {
      return this.post?.status === postStatus.IMPORTED
        ? null
        : this.coverPhotoValidationErrorMessage;
    },
    taggerSubscreenActive() {
      return (
        (this.isFeedTab || this.isReelTab) &&
        ['tagPeople', 'tagProducts'].includes(this.schedulerStore.activeSubScreen)
      );
    },
    customCropperPresets() {
      if (this.isFeedTab) {
        return instagramFeedPostCropperPresetsV2;
      }
      if (this.isReelTab) {
        if (this.isCroppingCoverImage) {
          return instagramReelCoverImageCropperPresets;
        }
        return instagramStoryReelCropperPresets;
      }
      return instagramStoryCropperPresetsV2;
    },
    isFeedTab() {
      return this.currentTab === postTypes.FEED;
    },
    isStoryTab() {
      return this.currentTab === postTypes.STORY;
    },
    isReelTab() {
      return this.currentTab === postTypes.REEL;
    },
    showValidationBox() {
      // GridViewer handles validationError prop to show its own info box, CarouselViewer does not
      return this.validationErrorOrWarning && this.viewerComponent === CarouselViewer;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
    isReelVideo() {
      return (
        this.isReelTab ||
        (this.isFeedTab &&
          this.mediaList?.length === 1 &&
          this.mediaList[0]?.type === mediaTypes.VIDEO)
      );
    },
    visionUsageInfo() {
      const usageInfo = this.identityStore.guard(USER.VISION.CAN_ACCESS_VISION)
        ? toolTips.enableVision
        : toolTips.promoteVision;
      if (
        this.mediaList?.length === 1 &&
        this.mediaList[0]?.type === mediaTypes.VIDEO &&
        this.isFeedTab &&
        !this.schedulerStore.autoPublish
      ) {
        return usageInfo;
      }
      if (this.mediaList?.length > 0 && this.isReelTab) {
        if (!this.schedulerStore.autoPublish) {
          return usageInfo;
        }
        if (
          this.schedulerStore.autoPublish &&
          this.coverImageMedia &&
          this.schedulerStore.thumbnailSource !== 'VISION'
        ) {
          return toolTips.visionRemoveCoverImage;
        }
      }
      return null;
    },
    scheduledTime() {
      const timestamp = this.currentPostData?.timestamp;
      return timestamp && dayjs(timestamp).toDate();
    },
    allMedia() {
      const allMedia = [...(this.mediaList ?? [])];
      if (this.coverImageMedia) {
        allMedia.push(this.coverImageMedia);
      }
      return allMedia;
    },
  },
  watch: {
    coverImageMedia() {
      this.validateCoverPhoto();
    },
    mediaList(newList) {
      this.validateMedia();
      if (newList.length > 1) {
        this.schedulerStore.setThumbOffset(null);
        this.schedulerStore.setThumbnailSource(null);
      }
    },
    currentTab() {
      this.validateMedia();
    },
    'schedulerStore.autoPublish': {
      handler(newVal) {
        this.validateMedia();
        // Clear thumbnail selection if auto publish is disabled
        // and there's no custom cover image added
        if (!newVal && !this.coverImageMedia) {
          this.schedulerStore.setThumbOffset(0);
          this.schedulerStore.setThumbnailSource(null);
        }
      },
    },
    post: {
      immediate: true,
      handler(post) {
        if (post && post.post_type) {
          this.currentTab = postTypes[post.post_type];
        }
        this.coverImageMedia = this.post?.coverImageMedia;
      },
    },
  },
  async created() {
    await this.getInstagramAccount();
    // Note: Neither mediaStore.validateMediaList validate any media specifications,
    // They fetch and reshape/cast media data into models. TODO: rename/modify to be less confusing.

    // Media can either come from the media prop (e.g. scheduling a post from the library)
    if (this.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: this.media,
      });
    }
    // ... or an existing scheduled post object (e.g. editing a scheduled post).
    else if (this.post?.media) {
      this.mediaList = await this.mediaStore.validateMediaList({
        brandId: this.authStore.currentBrand.id,
        mediaArrList: this.post.media,
      });
    }
    this.mediaValidationPending = false;

    this.schedulerStore.setInstagramTabContext(this.currentTab);
    this.platformStore.getPlatformConnections();
  },
  beforeUnmount() {
    this.schedulerStore.setThumbOffset(null);
    this.schedulerStore.setThumbnailSource(null);
    this.schedulerStore.setInstagramTabContext('');
  },
  mounted() {
    // set thumbOffset if it exists in scheduled post
    // need to do this before any awaits so it's set for children
    if (Number.isInteger(this.post?.thumb_offset)) {
      this.schedulerStore.setThumbOffset(this.post.thumb_offset / 1000); // Divide by 1000 to convert to seconds
      if (this.post?.media?.length > 0) {
        this.duration = this.post.media[0].duration;
      }
    }
  },
  methods: {
    tabChanged(tabIndex) {
      trackInstagramPostTypeClick({
        postPlatformTypeTab: this.tabs[tabIndex],
        postPlatformTypeTabPrevious: this.currentTab,
      });
      this.currentTab = this.tabs[tabIndex];
      // feed post and story have different validation requirements, sub components like MediaContainer
      // should be aware which context they are in.
      this.schedulerStore.setInstagramTabContext(this.currentTab);
      if (this.schedulerStore.activeSubScreen === 'tagPeople') {
        if ([postTypes.STORY, postTypes.REEL].includes(this.currentTab)) {
          // Disable userTagger overlay when switching tabs
          this.instagramUserTaggerStore.disable();
        } else if (this.isFeedTab) {
          // Enable userTagger overlay when switching back to Feed Post with tagPeople subScreen already open
          this.instagramUserTaggerStore.enable();
        }
      } else if (this.schedulerStore.activeSubScreen === 'tagProducts') {
        if (this.currentTab === postTypes.STORY) {
          // Disable shoppingTagger overlay when switching tabs
          this.instagramShoppingTaggerStore.disableShoppingTagger();
        } else if (this.isFeedTab || this.isReelTab) {
          // Enable shoppingTagger overlay when switching back to Feed Post with shoppingTags subScreen already open
          this.instagramShoppingTaggerStore.enableShoppingTagger();
        }
      }
    },
    coverImageAdded(media) {
      this.coverImageMedia = media;
    },
    removeReelCoverImage() {
      this.coverImageMedia = null;
      this.coverPhotoValidationErrorMessage = null;
      if (this.$refs?.instagramPost?.thumbnailUrl) {
        this.$refs.instagramPost.thumbnailUrl = null;
      }
      if (!this.schedulerStore.thumbOffset) {
        this.schedulerStore.setThumbOffset(0);
      }
    },
    onCroppingCoverImage(val) {
      this.isCroppingCoverImage = val;
    },
    mediaSelected(media) {
      const mediaRemoved = media?.length === 0;
      if (mediaRemoved) {
        this.removeReelCoverImage();
      }
      this.mediaList = media;
    },
    validateCoverPhoto() {
      this.isValidatingMedia = true;
      const validation = validateIGCustomCoverImage(
        this.coverImageMedia,
        this.schedulerStore.autoPublish,
      );
      this.coverPhotoValidationErrorMessage = validation;
      this.isValidatingMedia = false;
    },
    validateMedia() {
      this.isValidatingMedia = true;
      const validation = validatePostMedia(
        this.mediaList,
        PLATFORMS.INSTAGRAM,
        this.schedulerStore.autoPublish,
        invert(postTypes)[this.isReelVideo ? postTypes.REEL : this.currentTab],
      );
      this.mediaValidationError = validation;
      this.isValidatingMedia = false;
    },
    updateUnsavedStatus(status) {
      this.hasUnsavedChanges = status;
    },
    postDataUpdated(data) {
      this.currentPostData = data;
    },
    checkUnsavedChanges() {
      return this.hasUnsavedChanges;
    },
    uploadStatusChanged(status) {
      this.uploadPending = status;
    },
    openStoryProductPopup() {
      this.showStoryProductPopup = true;
    },
    closeStoryProductPopup() {
      this.showStoryProductPopup = false;
    },
    updateSelectedStoryLink(link) {
      this.$refs.instagramPost.newStoryLink = link.product.url;
      this.$refs.instagramPost.selectedProductId = link.product.id;
    },
    async getInstagramAccount() {
      if (!this.instagramAccountStore.brandInstagramAccount) {
        await this.instagramAccountStore.getBrandInstagramAccount();
      }
      this.avatarUrl = this.instagramAccountStore.brandInstagramAccount?.avatarUrl;
      this.handle = this.instagramAccountStore.brandInstagramAccount?.handle;
    },
    handleOffset(offset) {
      this.schedulerStore.setThumbOffset(offset);
    },
    onMediaUploadError(errorMessage) {
      this.mediaUploadError = errorMessage;
    },
    downloadMediaClicked(media) {
      const postData = this.currentPostData ? this.currentPostData : this.post;
      const postTrackingData = {
        postId: this.post?.id ?? null,
        postPlatform: postTypeLabelMap.instagram,
        postPlatformType: postTypeLabelMap[postData.post_type.toLowerCase()],
        postStatus: this.post?.status ?? null,
        publishType: postData.auto_publish
          ? mixpanelPublishTypes.AUTO_PUBLISH
          : mixpanelPublishTypes.SEND_NOTIFICATION,
        timestamp: postData.timestamp,
      };
      this.trackMixpanel.downloadMediaFromScheduler(postTrackingData, media);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.validation-banner {
  margin: var(--space-16) 0 0 0;

  &.carousel-visible {
    margin-top: var(--space-56);
  }
}

.keyframesSlider {
  margin-top: var(--space-8);
}
</style>
