<template>
  <teleport to="body">
    <PortalPopupBase v-bind="$attrs" :size="size">
      <div class="base-dialog-popup">
        <div v-if="title || $slots.titleContent" class="base-dialog-title text-h4">
          <slot name="titleContent">
            <div v-if="title" v-sanitize-html="title" />
          </slot>
        </div>
        <div v-if="message || $slots.default" class="base-dialog-content">
          <slot>
            <div v-if="message" v-sanitize-html="message" :class="textSizeClass" />
          </slot>
        </div>
        <div v-if="buttons || $slots.buttonsContent" class="base-dialog-buttons">
          <slot name="buttonsContent">
            <template v-if="buttons">
              <template v-for="(button, index) in buttonProps" :key="index">
                <Button v-bind="button.attrs" @click="onClick(button)">
                  {{ button.attrs.text }}
                </Button>
              </template>
            </template>
          </slot>
        </div>
      </div>
    </PortalPopupBase>
  </teleport>
</template>

<script>
import { defineComponent } from 'vue';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import PortalPopupBase from '@/components/PortalPopup/PortalPopupBase.vue';
import Button from '@/components/foundation/Button.vue';

const DEFAULT_BUTTON_PROPS = {
  wide: true,
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ConfirmDialog',
  components: {
    PortalPopupBase,
    Button,
  },
  props: {
    /**
     * Title of the dialog
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Message of the dialog
     */
    message: {
      type: String,
      default: null,
    },
    /**
     * Array of button config objects.
     * <code>
     *
     * </code>
     */
    buttons: {
      type: Array,
      default: null,
    },
    /**
     * Size of the dialog.  Sizes are set in the `PortalPopupBase`
     */
    size: {
      type: String,
      default: 'small',
    },
    /**
     * If true, increases font size of the popup message to 16
     */
    largerText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonProps() {
      if (this.buttons?.length > 0) {
        return this.buttons.map((button) => {
          const attrs = omitBy(button, (value, prop) => prop.startsWith('on'));
          const listeners = mapKeys(
            pickBy(button, (value, prop) => prop.startsWith('on')),
            (value, key) => camelCase(key.substring(2)),
          );
          return {
            attrs: { ...DEFAULT_BUTTON_PROPS, ...attrs },
            listeners,
          };
        });
      }
      return null;
    },
    textSizeClass() {
      return this.largerText ? 'text-main' : 'text-small';
    },
  },
  methods: {
    onClick(button) {
      if (button?.listeners?.click) {
        button.listeners.click();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.base-dialog-popup {
  .base-dialog-title {
    margin-bottom: var(--space-32);
    text-align: center;
  }

  .base-dialog-content {
    text-align: center;
  }

  .base-dialog-buttons {
    display: flex;
    justify-content: center;
    margin-top: var(--space-40);

    > *:not(:first-child) {
      margin-left: var(--space-16);
    }
  }
}
</style>
