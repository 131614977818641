/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'payment-card': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M18 0H2C.89 0 .01.89.01 2L0 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2zm-1 14H3c-.55 0-1-.45-1-1V8h16v5c0 .55-.45 1-1 1zm1-10H2V2h16v2z" _fill="#686a7a"/>'
  }
})
