/**
 * An extension for Tiptap that detects when "Enter" is pressed and executes a callback.
 * Useful for comment or chat boxes where you want "Enter" to submit instead of create a new line.
 */
import { Extension } from '@tiptap/core';

const CaptureKeys = Extension.create({
  name: 'captureEnter',
  addOptions() {
    return {
      handlers: {},
    };
  },
  addKeyboardShortcuts() {
    return this.options.handlers;
  },
});

export default CaptureKeys;
