/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'comments': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.998 21.001h-10a.998.998 0 01-.732-1.68l2.305-2.484c-1.031-1.444-1.572-3.101-1.572-4.836 0-4.963 4.486-9 10-9s10 4.037 10 9-4.487 9-10.001 9zm-7.707-2h7.707c4.411 0 8-3.14 8-7 0-3.859-3.589-7-8-7s-8 3.141-8 7c0 1.537.568 3 1.642 4.232a1 1 0 01-.021 1.338l-1.328 1.43z"/><circle pid="1" cx="7.998" cy="12.001" r="1"/><circle pid="2" cx="11.998" cy="12.001" r="1"/><circle pid="3" cx="15.998" cy="12.001" r="1"/>'
  }
})
