/**
 * Plugin for handling suggestions in Tiptap (e.g. autocomplete on mentions, tags, etc.)
 *
 * Tiptap ships with a suggestion plugin, but it has very poor handling for spaces in suggestions.
 * This plugin borrows parts of that suggestion plugin, but has a completely different matching
 * strategy that supports spaces well. Instead of using regular expressions to extract suggestion
 * queries as users type them, this plugin enters an "active" mode as soon as a trigger character
 * is entered. Only moving the caret outside of the suggestion, unfocusing the editor,
 * or inserting a line break cancels it. While in "active" mode, all characters are captured
 * and set as the "query".
 *
 * Author: @pmdarrow
 *
 * Credits:
 * - https://github.com/ueberdosis/tiptap SuggestionsPlugin
 * - https://github.com/quartzy/prosemirror-suggestions
 *
 * License for borrowed code:
 *
 * Copyright 2017 Quartzy, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

const initialState = {
  active: false, // True when a suggestion is in progress
  range: {
    from: null, // Start position of the active suggestion
    to: null, // End position of the active suggestion
  },
  decorationId: null, // Unique identifier for the active suggestion
  query: null, // The suggestion search query
};

export default function SuggestionsPlugin({
  editor,
  triggerChar = '@',
  suggestionNodeType = 'mention',
  suggestionClass = 'suggestion',
  render = () => ({}),
}) {
  let props;
  const renderer = render();

  return new Plugin({
    key: new PluginKey('suggestions'),

    view() {
      return {
        update: (view, prevState) => {
          const prev = this.key.getState(prevState);
          const next = this.key.getState(view.state);

          // See how the state changed
          const started = !prev.active && next.active;
          const stopped = prev.active && !next.active;
          const changed = !started && !stopped && prev.query !== next.query;

          // Cancel when suggestion isn't active
          if (!started && !stopped && !changed) {
            return;
          }

          const state = stopped ? prev : next;
          const decorationNode = document.querySelector(
            `[data-decoration-id="${state.decorationId}"]`,
          );

          // Prepare arguments for callbacks
          props = {
            editor,
            range: state.range,
            query: state.query,
            decorationNode,
            clientRect: () => decorationNode.getBoundingClientRect(),
            insertSuggestion: (suggestion) => {
              // Inject suggestion into editor and add a space after it
              editor
                .chain()
                .focus()
                .insertContentAt(state.range, [
                  {
                    type: suggestionNodeType,
                    attrs: suggestion,
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                ])
                .run();
            },
            cancelSuggestion: () => {
              const transaction = view.state.tr.setMeta('cancelSuggestion', true);
              view.dispatch(transaction);
            },
          };

          // Trigger the hooks when necessary
          if (stopped) {
            renderer.onExit(props);
          }

          if (changed) {
            renderer.onUpdate(props);
          }

          if (started) {
            renderer.onStart(props);
          }
        },
        destroy: () => {
          if (!props) {
            return;
          }
          renderer.onExit(props);
        },
      };
    },

    state: {
      /**
       * Initialize the plugin's internal state.
       *
       * @returns {Object} The initial plugin state.
       */
      init() {
        return initialState;
      },

      /**
       * Apply the given transaction to produce a new state. This hook is called after any state
       * change, including changes to the document, text selection, etc.
       *
       * @param {Transaction} tr The transaction that occurred.
       * @param {Object} prev The previous plugin state.
       *
       * @returns {Object} The new plugin state.
       */
      apply(tr, prev) {
        let next = { ...prev };
        const { doc, docChanged, meta, steps, selection } = tr;

        // Ignore paste events - we don't scan for suggestions in pasted text (for now)
        if (meta.paste) {
          return next;
        }

        // If the user has typed something...
        if (docChanged) {
          const step = steps[0].toJSON();
          const editorText = doc.textBetween(1, selection.$to.pos, null, ' ');
          const cursorPosition = selection.$anchor.pos;
          const precedingChar = editorText.slice(cursorPosition - 3, cursorPosition - 2);

          // A few examples of what step can look like:
          // {"stepType":"replace","from":10,"to":10,"slice":{"content":[{"type":"hard_break"}]}}
          // {"stepType":"replace","from":7,"to":7,"slice":{"content":[{"type":"text","text":"t"}]}}
          // {"stepType":"replace","from":7,"to":8}

          // ...and there's an active suggestion, adjust the bounds of the suggestion range.
          if (prev.active) {
            // If a character is added, increase the bounds and update the query
            if (step.slice) {
              const node = step.slice.content[0];
              if (node.type === 'text') {
                next.range.to += node.text.length;
                next.query = doc.textBetween(next.range.from + triggerChar.length, next.range.to);
              }
              // If a suggestion was inserted, cancel the suggestion
              else if (node.type === suggestionNodeType) {
                next = initialState;
              }
            } else {
              // If a character is deleted within the bounds, decrease the bounds and update the query
              const numCharsDeleted = step.to - step.from;
              if (next.range.to - numCharsDeleted > next.range.from) {
                next.range.to -= numCharsDeleted;
                next.query = doc.textBetween(next.range.from + triggerChar.length, next.range.to);
              }
              // If a character is deleted outside the bounds, cancel the suggestion
              else {
                next = initialState;
              }
            }
          }
          // If there's no active suggestion, look to see if the user started one.
          else if (
            step.slice &&
            step.slice.content[0].text === triggerChar &&
            ['', ' '].indexOf(precedingChar) > -1
          ) {
            // Open the suggestion popup
            next.active = true;
            next.range.from = selection.from - 1;
            next.range.to = selection.from;
            next.decorationId = (Math.random() + 1).toString(36).substr(2, 5);
            next.query = '';
          }
        }
        // If the transaction is "cancel suggestion" transaction or a selection outside the bounds,
        // cancel the suggestion.
        else if (
          prev.active &&
          (meta.cancelSuggestion ||
            (meta.focused !== false &&
              (selection.from <= prev.range.from || selection.to > prev.range.to)))
        ) {
          next = initialState;
        }

        return next;
      },
    },

    // In Tiptap props are configuration values that can be passed to an editor view or
    // included in a plugin.
    props: {
      /**
       * Call onKeyDown handler if suggestion is in progress.
       *
       * Prop is called when the editor receives a keydown event.
       *
       * @param {EditorView} view The current editor view.
       * @param {dom.KeyboardEvent} event The keyboard event that occurred.
       *
       * @returns {bool} ?
       */
      handleKeyDown(view, event) {
        const { active, range } = this.getState(view.state);

        if (!active) return false;

        return renderer.onKeyDown({ editor, view, event, range }) || false;
      },

      /**
       * Wrap the suggestion the user is currently typing in a span.
       *
       * Prop is called after every state change to obtain the set of document decorations to
       * show in the view.
       *
       * @param {EditorState} editorState The state of the Tiptap editor.
       * @returns {DecorationSet} Set of decorations to add to the document.
       */
      decorations(editorState) {
        const { active, range, decorationId } = this.getState(editorState);

        if (!active) return null;

        return DecorationSet.create(editorState.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: 'span',
            class: suggestionClass,
            'data-decoration-id': decorationId,
          }),
        ]);
      },
    },
  });
}
