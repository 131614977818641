<script setup>
// Vue
import { computed } from 'vue';
import { MARKETING_QUOTES, PRODUCT_MAP } from '@/models/auth/customer-plan.enum';
import { IMG } from '@/assets';

const props = defineProps({
  productKey: {
    type: String,
    required: true,
  },
});

const quoteInfo = computed(() => MARKETING_QUOTES[PRODUCT_MAP[props.productKey]]);
const quote = computed(() => quoteInfo.value.quote);
const name = computed(() => quoteInfo.value.name);
const title = computed(() => quoteInfo.value.title);
const logo = computed(() => IMG[quoteInfo.value.image]);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div class="flex">
    <div
      class="mx-8 my-16 min-w-[21.25rem] flex-col items-center rounded-md bg-white p-6 text-center"
    >
      <p class="mb-4 text-base">{{ quote }}</p>
      <p class="text-sm font-bold">{{ name }}</p>
      <p class="mb-4 text-sm text-[--text-secondary]">{{ title }}</p>
      <img :src="logo" class="h-auto max-h-[66px] max-w-[200px] py-2" alt="logo" />
    </div>
  </div>
</template>
