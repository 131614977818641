import findLastKey from 'lodash/findLastKey';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useTranslationStore } from '@/stores/translation';
import { useNotificationStore } from '@/stores/notification';
import { useCommunityPermissions } from '@/app/community/composables/useCommunityPermissions';
import {
  COMMUNITY_GENERIC_TYPES,
  COMMUNITY_TRANSLATION_ERROR_TOAST,
  COMMUNITY_TRANSLATION_INCORRECT_LANGUAGE_TOAST,
  DEFAULT_DETECTED_LANGAUGE,
} from '@/app/community/constants';
import { useCommunityInteractionStore } from '@/stores/community-interactions';

export const useCommunityTranslationStore = defineStore('communityTranslation', () => {
  const translationStore = useTranslationStore();
  const notificationStore = useNotificationStore();
  const { canAccessCommunityTranslation } = useCommunityPermissions();

  const translationMap = ref({});
  const targetTranslation = ref({});

  /**
   * Function which takes community comments and messages and detects the text language
   * @constructor
   * @param {Array} content - List of community comments or messages
   */
  async function detectLanguages(content) {
    if (!canAccessCommunityTranslation.value || !content) {
      return;
    }
    const interactionStore = useCommunityInteractionStore();
    const interactionId = String(interactionStore.selectedCommunityInteractionId);

    const flattenedTranslationList = content.reduce((translationList, currentInteraction) => {
      translationList.push({ id: currentInteraction.id, text: currentInteraction.text });
      const replies = currentInteraction.replies;
      if (replies) {
        // Flatten comment types
        replies.forEach((reply) => translationList.push({ id: reply.id, text: reply.text }));
      }
      return translationList;
    }, []);

    const existingTranslations = Object.keys(translationMap.value);

    const texts = [
      ...flattenedTranslationList
        .filter((entry) => !!entry.text && !existingTranslations.includes(String(entry.id)))
        .map((entry) => {
          return { id: String(entry.id), content: entry.text };
        }),
    ];

    if (texts.length === 0) {
      return;
    }

    const detections = await translationStore.detectLanguage(texts);
    const detectionMap = detections.reduce((map, item) => {
      map[Number(item.id)] = {
        detectedLanguage: item.detectedLanguage,
        translatable:
          item.detectedLanguage && item.detectedLanguage !== translationStore.preferredLanguage,
        isToggled: false,
      };
      return map;
    }, {});

    if (interactionId === String(interactionStore.selectedCommunityInteractionId)) {
      translationMap.value = {
        ...translationMap.value,
        ...detectionMap,
      };
    }
  }

  /**
   * Function to translate text into target language
   * @constructor
   * @param {Number} messageId - community comment or dm id used in translation map
   * @param {String} content - message text to translate
   */
  async function translateMessage(messageId, content, toPreferred = false, isNewMessage = false) {
    if (!canAccessCommunityTranslation.value || !translationMap.value[messageId]?.translatable) {
      return null;
    }

    const text = {
      id: String(messageId),
      content,
    };

    let translation;
    try {
      const promises = [];
      const inputLangauge = translationStore.detectLanguage([text]);
      promises.push(inputLangauge);
      const translatedResult = translationStore.translateLanguage([
        {
          ...text,
          targetLanguage: toPreferred
            ? translationStore.preferredLanguage
            : translationMap.value[messageId].detectedLanguage,
        },
      ]);
      promises.push(translatedResult);

      const results = await Promise.allSettled(promises);
      const detectedLanguage = results[0].value[0].detectedLanguage;
      const translatedText = results[1].value[0].translation;
      const translatedId = results[1].value[0].id;

      // only show toast if detected language is non-english for new messages (messages written in message composer)
      if (detectedLanguage !== DEFAULT_DETECTED_LANGAUGE && !isNewMessage) {
        notificationStore.setToast({
          message: COMMUNITY_TRANSLATION_INCORRECT_LANGUAGE_TOAST,
          type: 'error',
        });
      } else if (translatedId === String(messageId)) {
        translation = translatedText;
      }
    } catch {
      notificationStore.setToast({
        message: COMMUNITY_TRANSLATION_ERROR_TOAST,
        type: 'error',
      });
    }
    return translation;
  }

  /**
   * Function to find translatable or translated interaction item in translation map
   * @constructor
   * @param {Object} interaction - community comment or dm that has id used in translation map
   * @param {Boolean} checkTranslated - check if user translates the comment or message
   */
  function findTranslationMapItemId(interaction, checkToggled = false) {
    if (!interaction) {
      return null;
    }
    let messageId = interaction.id;
    const isConversation = interaction?.type?.includes(COMMUNITY_GENERIC_TYPES.CONVERSATION);

    // find the most recent message with translation toggled on for conversation or toggle check
    if (checkToggled || isConversation) {
      const key = findLastKey(translationMap.value, 'isToggled');
      messageId = key ? Number(key) : null;
    }
    // find the most recent translatable message for conversation if nothing toggled on
    if (!messageId && isConversation) {
      const key = findLastKey(translationMap.value, 'translatable');
      messageId = key ? Number(key) : null;
    }
    return messageId;
  }

  /**
   * Function to toggle translation map item
   * @constructor
   * @param {Number} messageId - community comment or dm id used in translation map
   * @param {Boolean} isTranslated - mark true if user translates the comment or message
   */
  function toggleTranslationMapItem(messageId, isTranslated) {
    translationMap.value[messageId].isToggled =
      isTranslated ?? !translationMap.value[messageId].isToggled;
  }

  /**
   * Function to update translation map to add/remove item with target translation
   * @constructor
   * @param {Number} messageId - community comment or dm id used in translation map
   * @param {Boolean} add - add new item to translation map if true, else remove the item
   * @param {Object} translation - new item translation data
   */
  function updateTranslationMap(messageId, add = true, translation = null) {
    const mapData = translation || targetTranslation.value;
    if (add) {
      translationMap.value[messageId] = mapData;
    } else if (translationMap.value[messageId]) {
      delete translationMap.value[messageId];
    }
  }

  return {
    detectLanguages,
    translateMessage,
    findTranslationMapItemId,
    toggleTranslationMapItem,
    updateTranslationMap,
    translationMap,
    targetTranslation,
  };
});
