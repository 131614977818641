<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { onClickOutside } from '@vueuse/core';
import { LibraryAPI } from '@/apis';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import {
  channelToUtmDynamicValues,
  baseUtmDynamicValues,
  utmValueCategoryTooltips,
} from '@/app/settings/components/Utm/const';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import UtmEditPresetPopup from '@/app/settings/components/Utm/UtmEditPresetPopup.vue';

// Constants
const { getUtmPresetValues } = LibraryAPI;

// Hooks / Composables
const notificationStore = useNotificationStore();
const { setToast } = notificationStore;

const authStore = useAuthStore();
const { currentBrand } = storeToRefs(authStore);

// Props
const props = defineProps({
  value: {
    type: String,
    required: false,
    default: '',
  },
  channel: {
    type: String,
    required: true,
  },
  trackingKey: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hidePresetValues: {
    type: Boolean,
    default: false,
  },
});

// Emits
const emits = defineEmits(['input', 'blur']);

// Refs
const presetValues = ref([]);
const autocomplete = ref(null);
const autocompleteInput = ref(null);
const autocompleteFocused = ref(false);
const autocompleteIndex = ref(-1);
const showEditPresetPopup = ref(false);
const isDirty = ref(null);

// Computed
const utmDynamicValues = computed(() => {
  return channelToUtmDynamicValues?.[props.channel] ?? baseUtmDynamicValues;
});

const filteredDynamicValues = computed(() => {
  if (!props.value || !isDirty.value) return utmDynamicValues.value;
  return utmDynamicValues.value.filter(
    (utmDynamicValue) =>
      utmDynamicValue.text.toLowerCase().includes(props.value.toLowerCase()) ||
      utmDynamicValue.value.toLowerCase().includes(props.value.toLowerCase()),
  );
});

const filteredPresetValues = computed(() => {
  if (!props.value || !isDirty.value) return presetValues.value.filter((preset) => preset.value);
  return presetValues.value.filter((presetValue) =>
    presetValue.value.toLowerCase().includes(props.value.toLowerCase()),
  );
});

const combinedFilteredPresetAndDynamicValues = computed(() => {
  return [...filteredPresetValues.value, ...filteredDynamicValues.value];
});

// Methods
function handleInput(value) {
  emits('input', value);
  isDirty.value = true;
}

function handleSelected(selection) {
  emits('input', selection);
  emits('blur', selection);
  autocompleteInput.value.blur();
  autocompleteFocused.value = false;
  isDirty.value = false;
}

function handleCleared() {
  emits('input', '');
}

function handleUpKeyStroke() {
  if (autocompleteIndex.value > -1) {
    autocompleteIndex.value -= 1;
  }
}

function handleDownKeyStroke() {
  if (autocompleteIndex.value < combinedFilteredPresetAndDynamicValues.value.length - 1) {
    autocompleteIndex.value += 1;
  }
}

function handleEscapeKeyStroke() {
  autocompleteInput.value.blur();
  autocompleteFocused.value = false;
}

function handleEnterKeyStroke() {
  const selection = combinedFilteredPresetAndDynamicValues.value[autocompleteIndex.value];
  if (selection) handleSelected(selection.value);
}

function openEditPresetPopup() {
  showEditPresetPopup.value = true;
}

function closeEditPresetPopup(updatedPresetValues) {
  if (updatedPresetValues) {
    presetValues.value = updatedPresetValues;
  }
  showEditPresetPopup.value = false;
}

async function getPresetValues() {
  try {
    const result = await getUtmPresetValues({
      brandId: currentBrand.value.id,
      channel: props.channel,
      trackingKey: props.trackingKey,
    });
    return result.data;
  } catch (e) {
    setToast({
      message: 'Not able to retrieve preset UTM values',
      type: 'error',
    });
    return null;
  }
}

// Lifecycle Hooks
onMounted(async () => {
  if (!props.hidePresetValues) presetValues.value = await getPresetValues();
});

onClickOutside(autocomplete, () => {
  autocompleteFocused.value = false;
});

// Watch
watch(autocompleteFocused, (focus) => {
  if (!focus) {
    autocompleteIndex.value = -1;
    isDirty.value = false;
  }
});

watch(
  () => props.value,
  () => {
    autocompleteIndex.value = -1;
  },
);

watch(
  () => props.channel,
  async (channel) => {
    presetValues.value = await getPresetValues(channel);
  },
);

defineExpose({
  presetValues,
  utmDynamicValues,
  combinedFilteredPresetAndDynamicValues,
  handleSelected,
  handleInput,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div ref="autocomplete" class="relative">
    <div
      class="flex items-center rounded border border-solid bg-white transition-all duration-300 ease-in-out"
      :class="{
        'border-[--border]': !autocompleteFocused,
        'border-[--action-500]': autocompleteFocused,
      }"
    >
      <input
        ref="autocompleteInput"
        type="text"
        class="w-full border-none pr-1"
        :class="{ 'bg-[--background-400] text-[color:--text-secondary]': disabled }"
        :value="value"
        :disabled="disabled"
        @keydown.up.prevent="handleUpKeyStroke"
        @keydown.down.prevent="handleDownKeyStroke"
        @keydown.enter.prevent="handleEnterKeyStroke"
        @keydown.escape.prevent="handleEscapeKeyStroke"
        @focus="autocompleteFocused = true"
        @input="handleInput($event.target.value)"
        @change="$emit('blur', $event.target.value)"
      />
      <Icon
        v-if="!disabled && value?.length > 0"
        class="mr-3 cursor-pointer hover:fill-[--action-500]"
        name="close"
        xxsmall
        @click="handleCleared"
      />
    </div>
    <transition name="slide">
      <div
        v-if="autocompleteFocused"
        class="absolute z-dropdown mt-2 w-full rounded bg-[--background-0] py-4 text-[length:--x12] font-medium text-[color:--text-primary] shadow-heavy"
      >
        <div v-if="!hidePresetValues">
          <div class="flex justify-between px-6 py-2">
            <div class="flex items-center leading-normal text-[--text-secondary]">
              Preset Values
              <InfoTooltip :tooltip="utmValueCategoryTooltips.preset" class="mx-1" />
            </div>
            <div class="cursor-pointer text-[--action-500]" @click.stop="openEditPresetPopup">
              Edit List
            </div>
          </div>
          <ul v-if="filteredPresetValues.length > 0">
            <li
              v-for="(presetValue, index) in filteredPresetValues"
              :key="index"
              class="cursor-pointer hover:bg-[--background-300]"
              :class="{
                'bg-[--background-300]': autocompleteIndex === index,
              }"
              @click.stop="handleSelected(presetValue.value)"
            >
              <div class="px-6 py-2 text-[length:--x14] leading-normal">
                {{ presetValue.value }}
              </div>
            </li>
          </ul>
          <div
            v-else
            class="px-6 py-2 text-[length:--x14] leading-normal text-[color:--text-secondary]"
          >
            No results.
          </div>
        </div>
        <hr v-if="!hidePresetValues" class="mx-4" />
        <div>
          <div class="flex items-center px-6 py-2 leading-normal text-[--text-secondary]">
            Dynamic Values
            <InfoTooltip :tooltip="utmValueCategoryTooltips.dynamic" class="mx-1" />
          </div>
          <ul v-if="filteredDynamicValues.length > 0">
            <li
              v-for="(dynamicValue, index) in filteredDynamicValues"
              :key="index"
              class="cursor-pointer hover:bg-[--background-300]"
              :class="{
                'bg-[--background-300]': autocompleteIndex === index + filteredPresetValues.length,
              }"
              @click.stop="handleSelected(dynamicValue.value)"
            >
              <div class="px-6 py-2 text-[length:--x14] leading-normal">
                {{ dynamicValue.text }}
              </div>
            </li>
          </ul>
          <div
            v-else
            class="px-6 py-2 text-[length:--x14] leading-normal text-[color:--text-secondary]"
          >
            No results.
          </div>
        </div>
      </div>
    </transition>
    <UtmEditPresetPopup
      v-if="showEditPresetPopup"
      :channel="channel"
      :tracking-key="trackingKey"
      :preset-values="presetValues"
      @close="closeEditPresetPopup"
    />
  </div>
</template>
