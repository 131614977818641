import { constants, BOARD_DETAIL_ROUTES, BOARD_ROUTES } from '@/config';
import competitiveConst, { competitiveRouteNames } from '@/app/competitive/constants';
import PlatformTypeWrapper from '@/app/competitive/pages/PlatformTypeWrapper.vue';
import LDRouteGuard from '@/components/core/LDRouteGuard';
import { sourceLists } from '@/app/library/constants';
import { useTrackingStore } from '@/stores/tracking';

const App = () => import('@/app/competitive/App.vue');
const InstagramPosts = () => import('@/app/competitive/pages/posts/InstagramPosts.vue');
const TwitterTweets = () => import('@/app/competitive/pages/posts/TwitterTweets.vue');
const YoutubeVideos = () => import('@/app/competitive/pages/posts/YoutubeVideos.vue');
const ViqCompetitive = () => import('@/app/instagram/pages/ViqCompetitive.vue');
const ViqTrends = () => import('@/app/instagram/pages/ViqTrends.vue');
const CompetitiveClusterDetail = () => import('@/app/instagram/pages/CompetitiveClusterDetail.vue');
const TrendEdit = () => import('@/app/instagram/components/TrendEdit.vue');
const ViqTrend = () => import('@/app/instagram/pages/ViqTrend.vue');
const InstagramGallery = () => import('@/app/instagram/pages/InstagramGallery.vue');
const Gallery = () => import('@/components/Galleries/Gallery.vue');
const GalleryEdit = () => import('@/components/Galleries/GalleryEdit.vue');
const InstagramGalleries = () => import('@/app/instagram/pages/Galleries.vue');
const TwitterGalleries = () => import('@/app/twitter/pages/Galleries.vue');
const YoutubeGalleries = () => import('@/app/youtube/pages/Galleries.vue');
const TwitterCompetitorOverview = () =>
  import('@/app/competitive/pages/overview/TwitterCompetitorOverview.vue');
const YouTubeCompetitorOverview = () =>
  import('@/app/competitive/pages/overview/YouTubeCompetitorOverview.vue');
const InstagramCompetitorOverview = () =>
  import('@/app/competitive/pages/overview/InstagramCompetitorOverview.vue');

const PAGE_LEVEL_TOP = `Competitors`;
const PAGE_LEVEL_VIQ = `Visual IQ`;
const PAGE_LEVEL_TRENDS = 'Trends';
const PAGE_LEVEL_BOARDS = `Boards`;
const PAGE_LEVEL_TYPE = {
  instagram: 'Instagram',
  twitter: 'Twitter',
  youtube: 'YouTube',
};

export default {
  path: 'competitors',
  component: App,
  redirect: { name: 'competitors.competitorPosts' },
  meta: {
    requiresAuth: true,
    analytics: PAGE_LEVEL_TOP,
  },
  children: [
    {
      name: 'competitors',
      path: '',
      redirect: { name: 'competitors.competitorOverview' },
    },
    {
      name: 'competitors.competitorOverview',
      path: 'competitor-overview',
      redirect: { name: 'competitors.instagram.competitorOverview' },
    },
    {
      name: 'competitors.competitorPosts',
      path: 'competitor-posts',
      redirect: { name: 'competitors.instagram.competitorPosts' },
    },
    {
      name: 'competitors.viq.competitive',
      path: 'viq/competitive',
      redirect: { name: 'competitors.instagram.viq.competitive' },
    },
    {
      name: 'competitors.viq.competitive.detail',
      path: 'viq/competitive/:id',
      redirect: { name: 'competitors.instagram.viq.competitive.detail' },
    },
    {
      name: 'competitors.viq.trends',
      path: 'viq/trends',
      redirect: { name: 'competitors.instagram.viq.trends' },
      children: [
        {
          name: 'competitors.viq.trend.new',
          path: 'new',
          redirect: { name: 'competitors.instagram.viq.trend.new' },
        },
      ],
    },
    {
      name: 'competitors.viq.trend',
      path: 'viq/trends/:id',
      redirect: { name: 'competitors.instagram.viq.trend' },
      children: [
        {
          name: 'competitors.viq.trend.edit',
          path: 'edit',
          redirect: { name: 'competitors.instagram.viq.trend.edit' },
        },
      ],
    },
    {
      name: 'competitors.galleries',
      path: 'boards',
      redirect: { name: 'competitors.instagram.galleries' },
      children: [
        {
          name: 'competitors.galleries.new',
          path: 'new',
          redirect: { name: 'competitors.instagram.galleries.new' },
        },
      ],
    },
    {
      name: 'competitors.galleries.board',
      path: 'boards/:id',
      redirect: { name: 'competitors.instagram.galleries.board' },
      children: [
        {
          name: 'competitors.galleries.edit',
          path: 'edit',
          redirect: { name: 'competitors.instagram.galleries.edit' },
        },
      ],
    },
    {
      name: 'competitors.galleries.boards.report',
      path: 'boards/:id/report',
      redirect: { name: 'competitors.instagram.galleries.boards.report' },
    },
    {
      name: competitiveRouteNames.instagram.main,
      path: 'instagram',
      component: PlatformTypeWrapper,
      redirect: { name: 'competitors.instagram.competitorOverview' },
      children: [
        {
          name: competitiveRouteNames.instagram.overview,
          path: 'overview',
          component: InstagramCompetitorOverview,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - Competitor Overview`,
            socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
          },
        },
        {
          name: competitiveRouteNames.instagram.posts,
          path: 'posts',
          component: InstagramPosts,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - Competitor Posts`,
            socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
          },
        },
        {
          path: 'viq/competitive',
          children: [
            {
              name: competitiveRouteNames.instagram.viq.main,
              path: '',
              component: ViqCompetitive,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - VIQ Competitive`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
            },
            {
              name: competitiveRouteNames.instagram.viq.detail,
              path: ':id',
              component: CompetitiveClusterDetail,
              beforeEnter(to, from, next) {
                const trackingData = to.params?.data?.mixpanelObject;
                if (trackingData) {
                  const trackingStore = useTrackingStore();
                  trackingStore.track('Competitor View Competitor', trackingData);
                }
                next();
              },
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - View VIQ Competitive`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
            },
          ],
        },
        {
          path: 'viq/trends',
          children: [
            {
              name: competitiveRouteNames.instagram.viq.trends.main,
              path: '',
              component: ViqTrends,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - VIQ Trends`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
              children: [
                {
                  name: competitiveRouteNames.instagram.viq.trends.new,
                  path: 'new',
                  component: TrendEdit,
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_VIQ} - ${PAGE_LEVEL_TRENDS} - New`,
                    socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
                  },
                },
              ],
            },
            {
              name: competitiveRouteNames.instagram.viq.trend.main,
              path: ':id',
              component: ViqTrend,
              beforeEnter(to, from, next) {
                const trackingData = to.params?.data?.mixpanelObject;
                if (trackingData) {
                  const trackingStore = useTrackingStore();
                  trackingStore.track('Competitor View Trend', trackingData);
                }
                next();
              },
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - View VIQ Trend Detail`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
              children: [
                {
                  name: competitiveRouteNames.instagram.viq.trend.edit,
                  path: 'edit',
                  component: TrendEdit,
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_VIQ} - ${PAGE_LEVEL_TRENDS} - Edit`,
                    socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'boards',
          children: [
            {
              name: competitiveRouteNames.instagram.galleries.main,
              path: '',
              component: InstagramGalleries,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_BOARDS}`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
              children: [
                {
                  name: competitiveRouteNames.instagram.galleries.new,
                  path: 'new',
                  component: GalleryEdit,
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_BOARDS} - New`,
                    socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
                  },
                },
              ],
            },
            {
              name: competitiveRouteNames.instagram.boards.main,
              path: ':id',
              component: InstagramGallery,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_BOARDS} - Detail`,
                socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
              },
              children: [
                {
                  name: competitiveRouteNames.instagram.boards.edit,
                  path: 'edit',
                  component: GalleryEdit,
                  props: {
                    galleryRoute: BOARD_ROUTES[constants.COMPETITIVE_INSTAGRAM],
                    galleryDetailRoute: BOARD_DETAIL_ROUTES[constants.COMPETITIVE_INSTAGRAM],
                  },
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_BOARDS} - Edit`,
                    socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
                  },
                },
              ],
            },
          ],
        },
        {
          name: competitiveRouteNames.instagram.boards.report,
          path: 'boards/:id/report',
          component: InstagramGallery,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.instagram} - ${PAGE_LEVEL_BOARDS} - Detail Report`,
            socialMediaType: competitiveConst.competitiveSources.INSTAGRAM,
            report: true,
            hideNav: true,
            hideVisionAi: true,
          },
        },
      ],
    },
    {
      path: 'twitter:pathMatch(.*)*',
      redirect: (to) => ({ ...to, path: to.path.replace('/twitter', '/x') }),
    },
    {
      name: competitiveRouteNames.twitter.main,
      path: 'x',
      component: LDRouteGuard,
      props: {
        component: PlatformTypeWrapper,
        requiredFeatureFlag: 'twitterCompetitive',
        to: { name: competitiveRouteNames.instagram.overview },
      },
      redirect: { name: 'competitors.twitter.competitorOverview' },
      children: [
        {
          name: competitiveRouteNames.twitter.overview,
          path: 'overview',
          component: TwitterCompetitorOverview,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - Competitor Overview`,
            socialMediaType: competitiveConst.competitiveSources.TWITTER,
          },
        },
        {
          name: competitiveRouteNames.twitter.posts,
          path: 'posts',
          component: TwitterTweets,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - Competitor Posts`,
            socialMediaType: competitiveConst.competitiveSources.TWITTER,
          },
        },
        {
          path: 'boards',
          children: [
            {
              name: competitiveRouteNames.twitter.galleries.main,
              path: '',
              component: TwitterGalleries,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - ${PAGE_LEVEL_BOARDS}`,
                socialMediaType: competitiveConst.competitiveSources.TWITTER,
              },
              children: [
                {
                  name: competitiveRouteNames.twitter.galleries.new,
                  path: 'new',
                  component: GalleryEdit,
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - ${PAGE_LEVEL_BOARDS} - New`,
                    socialMediaType: competitiveConst.competitiveSources.TWITTER,
                  },
                },
              ],
            },
            {
              name: competitiveRouteNames.twitter.boards.main,
              path: ':id',
              component: Gallery,
              props: {
                galleryType: constants.TWITTER,
                competitiveSources: sourceLists.TWITTER_COMPETITIVE_SOURCE_LIST,
              },
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - ${PAGE_LEVEL_BOARDS} - Detail`,
                socialMediaType: competitiveConst.competitiveSources.TWITTER,
              },
              children: [
                {
                  name: competitiveRouteNames.twitter.boards.edit,
                  path: 'edit',
                  component: GalleryEdit,
                  props: {
                    galleryRoute: BOARD_ROUTES[constants.COMPETITIVE_TWITTER],
                    galleryDetailRoute: BOARD_DETAIL_ROUTES[constants.COMPETITIVE_TWITTER],
                  },
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - ${PAGE_LEVEL_BOARDS} - Edit`,
                    socialMediaType: competitiveConst.competitiveSources.TWITTER,
                  },
                },
              ],
            },
          ],
        },
        {
          name: competitiveRouteNames.twitter.boards.report,
          path: 'boards/:id/report',
          component: Gallery,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.twitter} - ${PAGE_LEVEL_BOARDS} - Detail Report`,
            socialMediaType: competitiveConst.competitiveSources.TWITTER,
            report: true,
            hideNav: true,
            hideVisionAi: true,
          },
        },
      ],
    },
    {
      name: competitiveRouteNames.youtube.main,
      path: 'youtube',
      component: LDRouteGuard,
      props: {
        component: PlatformTypeWrapper,
        requiredFeatureFlag: 'youTubeCompetitive',
        to: { name: competitiveRouteNames.instagram.overview },
      },
      redirect: { name: 'competitors.youtube.competitorOverview' },
      children: [
        {
          name: competitiveRouteNames.youtube.overview,
          path: 'overview',
          component: YouTubeCompetitorOverview,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - Competitor Overview`,
            socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
          },
        },
        {
          name: competitiveRouteNames.youtube.posts,
          path: 'posts',
          component: YoutubeVideos,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - Competitor Posts`,
            socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
          },
        },
        {
          path: 'boards',
          children: [
            {
              name: competitiveRouteNames.youtube.galleries.main,
              path: '',
              component: YoutubeGalleries,
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - ${PAGE_LEVEL_BOARDS}`,
                socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
              },
              children: [
                {
                  name: competitiveRouteNames.youtube.galleries.new,
                  path: 'new',
                  component: GalleryEdit,
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - ${PAGE_LEVEL_BOARDS} - New`,
                    socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
                  },
                },
              ],
            },
            {
              name: competitiveRouteNames.youtube.boards.main,
              path: ':id',
              component: Gallery,
              props: {
                galleryType: constants.YOUTUBE,
                competitiveSources: sourceLists.YOUTUBE_COMPETITIVE_SOURCE_LIST,
              },
              meta: {
                analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - ${PAGE_LEVEL_BOARDS} - Detail`,
                socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
              },
              children: [
                {
                  name: competitiveRouteNames.youtube.boards.edit,
                  path: 'edit',
                  component: GalleryEdit,
                  props: {
                    galleryRoute: BOARD_ROUTES[constants.COMPETITIVE_YOUTUBE],
                    galleryDetailRoute: BOARD_DETAIL_ROUTES[constants.COMPETITIVE_YOUTUBE],
                  },
                  meta: {
                    analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - ${PAGE_LEVEL_BOARDS} - Edit`,
                    socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
                  },
                },
              ],
            },
          ],
        },
        {
          name: competitiveRouteNames.youtube.boards.report,
          path: 'boards/:id/report',
          component: Gallery,
          meta: {
            analytics: ` ${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TYPE.youtube} - ${PAGE_LEVEL_BOARDS} - Detail Report`,
            socialMediaType: competitiveConst.competitiveSources.YOUTUBE,
            report: true,
            hideNav: true,
            hideVisionAi: true,
          },
        },
      ],
    },
  ],
};
