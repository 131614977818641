<template>
  <section class="control">
    <section class="sticky">
      <section class="top-section">
        <CheckBox
          v-model="addToSameBoard"
          label="Add all to the same board"
          class="control-items"
          bold-font
          is-required
          data-cy="add-to-same-board-checkbox"
        />
        <template v-if="addToSameBoard">
          <PinterestBoardSelector
            v-model="selectedBoard"
            v-tooltip="boardTooltip"
            :disabled="boardDisabled"
            class="control-items"
          />
          <div
            class="add-post-to"
            @mouseover="showClearGalleries = true"
            @mouseleave="showClearGalleries = false"
          >
            <div class="add-link-main-part">
              <a
                :class="{ 'color-after-select': selectedGalleries.length !== 0 }"
                @click.stop="addToGalleryClicked"
              >
                <div class="icon">
                  <Icon name="folderAdd" />
                </div>
                <p v-line-clamp="3" class="display-label">{{ showGallery }}</p>
              </a>
              <Icon
                v-if="showClearGalleries && selectedGalleries.length > 0"
                name="close"
                xsmall
                class="clear-buttons"
                @click="clearGalleries"
              />
            </div>
            <AddToDropdown
              ref="addToDropdown"
              :select-only="true"
              :on-galleries-selected="galleriesSelected"
              :selected-galleries="selectedGalleries"
            />
          </div>
        </template>
        <div v-else class="control-items">
          <p>You need to select a Pinterest Board for each pin.</p>
        </div>
      </section>
      <hr class="control-items" />
      <section class="bottom-section">
        <CheckBox
          v-model="publishAtSameTime"
          label="Publish all at the same time"
          class="control-items"
          bold-font
        />
        <template v-if="publishAtSameTime">
          <ImageDropdownSelect
            v-model="publishType"
            :options="publishOptions"
            option-icon
            capitalize-text
            class="control-items"
          />
          <QuickSelectCalendar
            :disabled="quickSelectDateDisabled"
            :tooltip="!pinterestConnected ? toolTips.noPinterestConnection : ''"
            :model-value="scheduledTime"
            :restrict-selection-to-interval="canPublishWithinInterval"
            :platform="platformLabels.pinterest"
            dropdown-select-style
            class="control-items"
            style="padding-top: 1.25rem"
            @update:model-value="timeSelected"
          />
        </template>
        <div v-else class="control-items">
          <p>You need to select a date & time for each pin, or publish them now.</p>
        </div>
        <div v-if="hasDraftsFeatureFlag" class="ml-9 flex">
          <span class="new-badge h-6">NEW!</span>
        </div>
        <ToggleSwitch
          v-if="hasDraftsFeatureFlag"
          :disabled="!scheduledTime"
          :switcher-is-on="isDraft"
          toggle-label="Draft"
          class="-ml-4 mb-4"
          @input="(draft) => (isDraft = draft)"
        />
      </section>
      <div class="save-wrapper">
        <SplitButton
          :tooltip="errorMessage"
          :disabled="disableSave"
          :loading="isSaving"
          :options="saveOptions"
          class="save save-dropdown"
          primary
          data-cy="save-pin-dropdown-button"
          @click="save"
        >
          Save All
        </SplitButton>
      </div>
      <Banner
        v-if="publishDateError"
        hide-default-icon
        :custom-icon="publishDateError.icon"
        :alert-type="publishDateError.level"
        class="publish-date-error"
      >
        {{ publishDateError.message }}
      </Banner>
    </section>
  </section>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import { useFlagStore } from '@/stores/flag';
import { useAuthStore } from '@/stores/auth';
import { toolTips } from '@/config';
import { getPublishOptions } from '@/app/scheduler/utils';
import { platformLabels } from '@/app/scheduler/constants';
import SplitButton from '@/components/foundation/SplitButton.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';
import Icon from '@/components/foundation/Icon.vue';
import AddToDropdown from '@/components/AddToDropdown.vue';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import { useImpersonatorStore } from '@/stores/impersonator';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { findDateRangeOverlap, validatePublishDates } from '@/app/scheduler/utils/publish-dates';
import Banner from '@/components/foundation/feedback/Banner.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import PinterestBoardSelector from '../EditPost/PinterestBoardSelector.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PinterestBoardControl',
  components: {
    ToggleSwitch,
    Banner,
    AddToDropdown,
    CheckBox,
    ImageDropdownSelect,
    Icon,
    PinterestBoardSelector,
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    SplitButton,
  },
  mixins: [ApprovedPublishingDatesMixin],
  props: {
    disablePublishNow: { type: Boolean, default: true },
    errorMessage: { type: String, default: '' },
    isSaving: { type: Boolean, default: false },
    mediaItems: { type: Array, default: null },
    defaultTimestamp: { type: Date, default: null },
    scheduledTime: { type: Date, default: null },
    isAppConnected: { type: Boolean, default: false },
  },
  emits: ['onSave', 'update:scheduled-time'],
  data() {
    return {
      addToSameBoard: true,
      publishAtSameTime: true,
      publishType: 'notification',
      selectedBoard: null,
      selectedGalleries: [],
      showClearGalleries: false,
      toolTips,
      isDraft: !this.scheduledTime,
    };
  },
  computed: {
    ...mapStores(
      useFlagStore,
      usePlatformStore,
      useSchedulerPinterestStore,
      useAuthStore,
      useImpersonatorStore,
    ),
    boardDisabled() {
      return !this.isAppConnected;
    },
    boardTooltip() {
      if (!this.isAppConnected) {
        return toolTips.boardNeedsAccount;
      }
      return '';
    },
    saveOptions() {
      let disabledTooltip = '';
      if (!this.canAutoPublish) {
        disabledTooltip = toolTips.insufficientPermission;
      } else if (!this.canAssetPublishToday) {
        disabledTooltip = this.publishNowWarning;
      } else if (this.shouldDisableAutoPublish) {
        disabledTooltip =
          'Pinterest boards, titles, and suitable photos/videos are required for all pins.';
      }
      return [
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.shouldDisableAutoPublish,
          tooltip: disabledTooltip,
        },
      ];
    },
    publishDateError() {
      const sameTimeError = validatePublishDates(this.canPublishWithin, this.scheduledTime);
      const separateError = this.mediaItems?.some((media) =>
        validatePublishDates(findDateRangeOverlap(media), media.scheduledTime),
      )
        ? {
            level: 'error',
            icon: 'date-blocked',
            message:
              'Some media items cannot be published due to issues with approved publishing dates.',
          }
        : null;
      return (
        (this.publishAtSameTime && sameTimeError) ||
        (!this.publishAtSameTime && separateError) ||
        null
      );
    },
    quickSelectDateDisabled() {
      return (
        !this.pinterestConnected ||
        (this.publishAtSameTime &&
          (!this.canPublishWithinInterval || this.canPublishWithinInterval.end < new Date()))
      );
    },
    canPublishWithinInterval() {
      if (!this.canPublishWithin) {
        this.$emit('update:scheduled-time', null);
        return null;
      }
      return this.canPublishWithin;
    },
    mediaList() {
      return this.mediaItems;
    },
    shouldDisableAutoPublish() {
      return this.disablePublishNow || (!this.selectedBoard && this.addToSameBoard);
    },
    canAutoPublish() {
      return (
        this.authStore.user_can('scheduler', 'can_auto_publish_pinterest') &&
        !this.impersonatorStore.isImpersonating
      );
    },
    pinterestConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.PINTEREST);
    },
    publishOptions() {
      let autoPublishTooltip = null;
      if (!this.canAutoPublish) {
        autoPublishTooltip = toolTips.insufficientPermission;
      }
      return getPublishOptions(this.canAutoPublish, autoPublishTooltip);
    },
    showGallery() {
      if (this.selectedGalleries.length <= 2 && this.selectedGalleries.length !== 0) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      if (this.selectedGalleries.length > 2) {
        return `${this.selectedGalleries[0].name} and ${this.selectedGalleries.length - 1} others.`;
      }
      return 'After published, add to...';
    },
    disableSave() {
      return (
        this.isSaving ||
        !!this.errorMessage ||
        this.publishDateError !== null ||
        this.mediaItems?.some((i) => i.invalidLink)
      );
    },
    platformLabels() {
      return platformLabels;
    },
    hasDraftsFeatureFlag() {
      return this.flagStore.ready && this.flagStore.flags.schedulerDrafts;
    },
  },
  watch: {
    addToSameBoard() {
      this.syncAddToBoardSetting();
    },
    publishAtSameTime(to) {
      if (!to) {
        if (!this.scheduledTime) {
          // If there's no scheduled time selected, we only toggle QuickSelectCalendar for pin list items
          this.syncPublishAtSameTimeSetting();
        } else {
          // If a scheduled time is already selected, we clear it and then update the settings
          this.$emit('update:scheduled-time', null);
        }
      } else {
        // Toggle QuickSelectCalendar for pin list items
        this.syncPublishAtSameTimeSetting();
      }
    },
    publishType() {
      this.syncPublishAtSameTimeSetting();
    },
    selectedBoard() {
      this.syncAddToBoardSetting();
    },
    scheduledTime(to, from) {
      // Set a watcher to look for scheduled time sync from parent, and then update the
      // publish time setting
      this.isDraft = !!(from && this.isDraft);
      this.syncPublishAtSameTimeSetting();
    },
  },
  created() {
    if (this.defaultTimestamp) {
      this.$emit('update:scheduled-time', this.defaultTimestamp);
    }
  },
  mounted() {
    if (this.schedulerPinterestStore.addToSameBoardSetting) {
      const { on, galleries, board } = this.schedulerPinterestStore.addToSameBoardSetting;
      if (on === false) {
        this.addToSameBoard = false;
      }
      this.selectedGalleries = galleries || [];
      this.selectedBoard = board;
    }
    if (this.schedulerPinterestStore.publishAtSameTimeSetting) {
      const { on, timestamp } = this.schedulerPinterestStore.publishAtSameTimeSetting;
      if (on === false) {
        this.publishAtSameTime = false;
      }
      this.$emit('update:scheduled-time', timestamp);
    }
  },
  methods: {
    clearGalleries() {
      this.selectedGalleries = [];
    },
    addToGalleryClicked() {
      this.$refs.addToDropdown.openAddToDropdown();
    },
    addActionClicked(option) {
      this[option]();
    },
    galleriesSelected(galleries) {
      this.selectedGalleries = galleries;
      this.syncAddToBoardSetting();
    },
    publishNow() {
      this.$emit('onSave', true);
    },
    syncAddToBoardSetting() {
      const setting = {
        on: this.addToSameBoard,
        board: this.selectedBoard,
        galleries: this.selectedGalleries,
      };
      this.schedulerPinterestStore.updateAddToSameBoard({ setting });
    },
    syncPublishAtSameTimeSetting() {
      const setting = {
        on: this.publishAtSameTime,
        type: this.publishType,
        timestamp: this.scheduledTime,
      };
      this.schedulerPinterestStore.updatePublishAtSameTime({ setting });
    },
    timeSelected(time) {
      // Convert object to Date
      this.$emit('update:scheduled-time', time?.toDate() || null);
    },
    save() {
      this.$emit('onSave', false, this.isDraft);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.top-section {
  height: 9rem;
}

.control {
  border-radius: 0 0 var(--round-corner-small) 0;
  background: var(--background-300);
  width: 30%;
  justify-content: center;
  padding: 0 var(--space-24) 0 var(--space-24);

  .control-items {
    padding-bottom: var(--space-12);

    p {
      font-size: var(--x14);
    }
  }

  hr {
    width: 100%;
  }

  .add-post-to {
    padding-bottom: var(--space-20);

    a {
      display: flex;
      align-items: center;
      text-decoration: underline;
      position: relative;
      margin-right: var(--space-8);

      .icon {
        width: var(--space-32);
        height: var(--space-32);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      p {
        font-size: var(--x13);
      }

      svg {
        margin-right: var(--space-8);
      }
    }

    .add-link-main-part {
      display: flex;
      align-items: center;

      .icon {
        cursor: pointer;
      }
    }
  }
}

.color-after-select {
  color: var(--action-500);

  p.display-label {
    color: var(--action-500);
  }
}

.save-wrapper {
  display: flex;

  .save-dropdown {
    min-width: 0;
    transform: none;
    box-shadow: none;
    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
    width: 100%;
    transition: var(--transition-all);
  }
}

.publish-date-error {
  margin: var(--space-32) 0;
}

section.sticky {
  padding-top: var(--space-20);
  position: sticky;
  top: 0;
}
</style>
