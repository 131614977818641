/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'contentViewModule': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 8.001H4c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4zM6 15.001H4c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4zM6 22.001H4c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2H4zM13 8.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2zM13 15.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2zM13 22.001h-2c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2zM20 8.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2zM20 15.001h-2c-1.104 0-2-.897-2-2v-2c0-1.102.896-2 2-2h2c1.104 0 2 .898 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2zM20 22.001h-2c-1.104 0-2-.897-2-2v-2c0-1.103.896-2 2-2h2c1.104 0 2 .897 2 2v2c0 1.103-.896 2-2 2zm-2-4v2h1.997l.003-2h-2z"/>'
  }
})
