import convert from 'color-convert';

const MIN_LIGHTNESS = 56;
const MAX_LIGHTNESS = 76;
const MIN_CHROMA = 106;
const MAX_CHROMA = 106;

const SAMPLE_SIZE = 1000;

export const generateRandomColor = () =>
  convert.lch.hex([
    MIN_LIGHTNESS + (MAX_LIGHTNESS - MIN_LIGHTNESS) * Math.random(),
    MIN_CHROMA + (MAX_CHROMA - MIN_CHROMA) * Math.random(),
    360 * Math.random(),
  ]);

const getColorDifference = (a, b) =>
  Math.sqrt(
    (a[0] - b[0]) ** 2 +
      (a[1] - b[1]) ** 2 +
      Math.min((a[2] - b[2]) ** 2, (a[2] - b[2] + 360) ** 2, (a[2] - b[2] - 360) ** 2),
  );

const getColorDistinctness = (existingColors, color) =>
  Math.min(...existingColors.map((existingColor) => getColorDifference(existingColor, color)));

export const generateDistinctRandomColor = (existingHexColors) => {
  const existingColors = existingHexColors.map((hex) => convert.hex.lch(hex.slice(1)));
  const randomColors = [...Array(SAMPLE_SIZE)].map(() => convert.hex.lch(generateRandomColor()));
  randomColors.sort(
    (a, b) => getColorDistinctness(existingColors, b) - getColorDistinctness(existingColors, a),
  );
  const mostDistinctColor = randomColors[0];
  return `#${convert.lch.hex(mostDistinctColor)}`;
};
