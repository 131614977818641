<template>
  <div class="slider-container">
    <Icon v-if="leftIcon" :name="leftIcon" :xsmall="isZoomSlider" />
    <input
      v-model="value"
      :defaultValue="defaultValue"
      :min="min"
      :max="max"
      type="range"
      class="slider"
    />
    <Icon v-if="rightIcon" :name="rightIcon" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Slider',
  components: {
    Icon,
  },
  props: {
    sliderType: { type: String, default: '' },
    leftIcon: { type: String, default: null },
    rightIcon: { type: String, default: null },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    defaultValue: { type: Number, default: 50 },
  },
  emits: ['changedSliderValue'],
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    isZoomSlider() {
      return this.sliderType === 'zoom';
    },
  },
  watch: {
    value(newSliderValue) {
      this.$emit('changedSliderValue', newSliderValue);
    },
  },
  created() {
    this.value = this.defaultValue * 100;
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.slider-container {
  display: flex;
  align-items: center;

  .vertical-line {
    height: 8px;
    width: 2px;
    margin-bottom: 2px;
    background: var(--background-500);
  }

  .slider {
    appearance: none;
    width: 240px;
    height: 4px;
    background: var(--background-500);
    outline: none;
    transition: 0.2s;
    transition: opacity 0.2s;
    margin: 0 var(--space-16);

    &::-webkit-slider-thumb {
      appearance: none;
      width: 18px;
      height: 26px;
      border-radius: var(--round-corner-small);
      border: 1px solid var(--border);
      cursor: pointer;
      box-shadow: var(--shadow-1);
      background: url('@/assets/icons/handleLines.svg') center center no-repeat var(--background-0);
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 26px;
      border-radius: var(--round-corner-small);
      border: 1px solid var(--border);
      cursor: pointer;
      box-shadow: var(--shadow-1);
      background: url('@/assets/icons/handleLines.svg') center center no-repeat var(--background-0);
    }
  }
}
</style>
