<template>
  <FormItem
    v-bind="additionalAttributes"
    :label="label"
    :label-bold="labelBold"
    :validator="validator"
    :validation-templates="validationTemplates"
    :error-messages="errorMessages"
    :compact="compact"
    :hide-details="hideDetails"
    disabled
    :large="large"
    class="form-item-text-input"
    :required-field="requiredField"
    :label-for="id"
  >
    <template #label-description>
      <slot name="label-description" />
    </template>
    <template #default="{ invalid }">
      <div
        v-tooltip="disabledMessage"
        class="text-input-wrapper"
        :class="{
          round,
          invalid,
          compact,
          large,
          tall,
          borderless,
          [disabledStyleClass]: disabled,
        }"
      >
        <div
          v-if="!!prependInnerIcon"
          class="prepend-inner-wrapper"
          :class="{ 'small-icon-margin': smallIconMargin }"
        >
          <Icon
            v-bind="{ [localPrependInnerIconSize]: true }"
            :name="prependInnerIcon"
            class="prepend-inner-icon"
          />
        </div>
        <CircularLoader v-if="loading" is-in-search-input class="circular-loader" />
        <input
          ref="textInput"
          v-bind="{ disabled, name, id, type, autocomplete, 'data-1p-ignore': disableOnePassword }"
          v-model="model"
          :placeholder="placeholder"
          class="text-input"
          :class="['text-input', { compact, large, medium, 'appear-disabled': appearDisabled }]"
          :maxlength="maxNumCharacters"
          v-on="inputListeners"
        />
        <slot name="icon">
          <Icon
            v-if="clearable && hasValue"
            :name="iconName"
            class="append-inner-icon clearable"
            data-cy="ClearTextInput"
            xsmall
            @click="clear()"
          />
        </slot>
      </div>
    </template>
  </FormItem>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import isEqual from 'lodash/isEqual';
import CircularLoader from '@/components/CircularLoader.vue';
import Icon from '@/components/foundation/Icon.vue';
import FormItem from '@/components/foundation/form/FormItem.vue';
import { extractEventsFromAttributes, extractNonEventsFromAttributes } from '@/utils/vue-attrs';

/**
 * Generic text input that displays a label and error messages.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    WATCH_ARRAY: true,
  },
  components: {
    CircularLoader,
    FormItem,
    Icon,
  },
  inheritAttrs: false,
  props: {
    /**
     * Model for the input.
     * @model
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * Label to be displayed above the input.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Use a bold label
     */
    labelBold: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder text of the input.
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Sets the focus of the input when set to true.
     */
    focus: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuelidate object for the model.
     */
    validator: {
      type: Object,
      default: null,
    },
    /**
     * Validation message templates to be displayed based on the results of the validation.
     */
    validationTemplates: {
      type: Object,
      default: () => ({}),
    },
    /**
     * List of error messages that are added to the validation messages.
     */
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    /**
     * Use a compact styling for the text input.
     */
    compact: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a large styling for the text input.
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a medium styling for the text input.
     */
    medium: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a tall styling for the text input.
     */
    tall: {
      type: Boolean,
      default: false,
    },
    /**
     * Apply rounded styling to the text input
     */
    round: {
      type: Boolean,
      default: false,
    },
    /**
     * Remove the border from the text input.
     */
    borderless: {
      type: Boolean,
      default: false,
    },
    /**
     * Prepend an icon inside the text input
     */
    prependInnerIcon: {
      type: String,
      default: null,
    },
    /**
     * The size of the icon prepended to the text input
     */
    prependInnerIconSize: {
      type: String,
      default: 'medium',
    },
    /**
     * Adds a clearable button on the right to allow users to clear the input.
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to hide the error and validation messages and reclaim spacing around input.
     */
    hideDetails: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable all interaction with the input
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Use light-text class for disabled input style
     */
    disabledStyleWhiteBg: {
      type: Boolean,
      default: false,
    },
    /**
     * Show a loading spinner on the right side of the text input while requesting results
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Make left margin on icon smaller than the default
     */
    smallIconMargin: {
      type: Boolean,
      default: false,
    },
    /**
     * Will render '* Required' below input when true
     */
    requiredField: {
      type: Boolean,
      default: false,
    },
    /**
     * The style of the close button
     */
    iconName: {
      type: String,
      default: 'close',
    },
    /**
     * Tooltip text
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * Adds tooltip to form item when populated. Set to null to disable.
     */
    disabledMessage: {
      type: String,
      default: null,
    },
    /**
     * Input field type
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Input field name
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * Input field name
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Maximum number of characters allowed
     */
    maxNumCharacters: {
      type: Number,
      default: null,
    },
    /**
     * Style the font to appear disabled, but don't actually disable
     */
    appearDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * disable one password auto fill and icon for input field
     */
    disableOnePassword: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable autocomplete for input field
     */
    autocomplete: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'focus'],
  data() {
    return {
      internalValue: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.internalValue;
      },
      set(val) {
        this.internalValue = val;
        if (this.validator) {
          this.validator.$touch();
        }
        /**
         * Triggers model changes.
         *
         * @property {string} newValue new value for model
         */
        this.$emit('update:modelValue', this.internalValue);
      },
    },
    localPrependInnerIconSize() {
      if (this.prependInnerIconSize) {
        return this.prependInnerIconSize;
      }
      return this.compact ? 'small' : 'medium';
    },
    inputListeners() {
      return extractEventsFromAttributes(this.$attrs, true);
    },
    additionalAttributes() {
      return extractNonEventsFromAttributes(this.$attrs);
    },
    hasValue() {
      return !!this.modelValue;
    },
    disabledStyleClass() {
      return this.disabledStyleWhiteBg ? 'light-text' : 'input-disabled';
    },
  },
  watch: {
    focus: {
      handler(value) {
        if (value) {
          nextTick(() => {
            this.$refs.textInput.focus();
          });
        }
      },
      immediate: true,
    },
    modelValue: {
      handler(newValue) {
        if (!isEqual(newValue, this.internalValue)) {
          this.internalValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clear() {
      this.$emit('update:modelValue', null);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.form-item-text-input {
  .text-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--border);
    border-radius: var(--round-corner-small);
    background: var(--background-0);

    .circular-loader {
      right: 0;
      top: 4px;
    }

    .text-input {
      border: hidden;
      font-size: var(--x14);

      &.compact {
        font-size: var(--x12);
      }

      &.large {
        font-size: var(--x18);
      }

      &.medium {
        font-size: var(--x16);
      }

      &.appear-disabled {
        color: var(--text-secondary);
      }
    }

    input {
      height: var(--input-height);
    }

    .prepend-inner-wrapper {
      display: flex;
      margin-left: var(--space-16);

      &.small-icon-margin {
        margin-left: var(--space-12);
      }
    }

    .append-inner-icon {
      margin-right: var(--space-8);

      &.clearable {
        cursor: pointer;
      }
    }

    &.round {
      border-radius: var(--button-border-radius);

      input {
        border-radius: var(--button-border-radius);
      }
    }

    &.invalid {
      border-color: var(--error-500) !important;

      .prepend-inner-icon {
        fill: var(--error-500);
      }
    }

    &.compact {
      input {
        margin-top: 0;
        height: var(--input-height-compact);
      }
    }

    &.large {
      input {
        margin-top: 0;
        height: var(--input-height-large);
      }
    }

    &.tall {
      margin-top: 0;
      height: var(--input-height-large);

      input {
        height: 100%;
      }
    }

    &.borderless {
      border: hidden;
    }

    &.input-disabled {
      background-color: var(--background-300);
      opacity: 0.3;
    }

    &.light-text {
      input[disabled=''] {
        color: var(--text-secondary);
        background-color: var(--background-0);
      }
    }

    &:focus-within {
      outline: 0;
      border-color: var(--action-500);
    }
  }
}
</style>
