export const TWITTER_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'REPLIES',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'RETWEETS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'QUOTE_TWEETS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'TWITTER',
      metric: 'LIKES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['TWITTER'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['TWITTER'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['TWITTER'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['TWITTER'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 32,
    meta: {
      name: 'Tweet Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 33,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 37,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 41,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
