/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fileNotesDocument': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 13h10v2H7zM7 9h8v2H7zM7 17h10v2H7z"/><path pid="1" d="M19 2h-2V1a1 1 0 00-2 0v1h-2V1a1 1 0 00-2 0v1H9V1a1 1 0 00-2 0v1H5c-1.103 0-2 .896-2 2v18c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4c0-1.104-.897-2-2-2zM5 22V4h2v1a1 1 0 102 0V4h2v1a1 1 0 102 0V4h2v1a1 1 0 102 0V4h2l.001 18H5z"/>'
  }
})
