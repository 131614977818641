/**
 * A modified version of @tiptap/extension-mention that renders mention nodes using our custom Vue
 * component, customizes how they are serialized to HTML, and enables our custom suggestions
 * plugin.
 */
import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import MentionNodeView from './MentionNodeView.vue';
import SuggestionsPlugin from './SuggestionsPlugin';

const Mention = Node.create({
  name: 'mention',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {},
      label: {},
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(MentionNodeView);
  },
  parseHTML() {
    // Finds and parses mentions in the format
    // `<span class="mention" data-mention-id="123">@Joe</span>`
    return [
      {
        tag: 'span.mention',
        getAttrs: (dom) => {
          const id = dom.getAttribute('data-mention-id');
          const label = dom.innerText;
          return { id, label };
        },
      },
    ];
  },
  renderHTML({ node }) {
    // Serializes mentions with the format
    // `<span class="mention" data-mention-id="123">@Joe</span>`
    return [
      'span',
      {
        class: 'mention',
        'data-mention-id': node.attrs.id,
      },
      `${node.attrs.label}`,
    ];
  },
  addProseMirrorPlugins() {
    return [
      SuggestionsPlugin({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default Mention;
