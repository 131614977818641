/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'subtract': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><path pid="0" d="M10 20.001c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm0-18c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm-5 7h10v2H5v-2z" id="svgicon_subtract_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_subtract_b" _fill="#fff"><use xlink:href="#svgicon_subtract_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_subtract_a"/><g mask="url(#svgicon_subtract_b)" _fill="#ACB6BF" fill-rule="nonzero"><path pid="1" d="M-2-2h32v32H-2z"/></g></g>'
  }
})
