<template>
  <div class="media-container">
    <slot />
    <div v-if="showMediaPlaceholderMessage" class="tip">
      <Icon name="info" large />
      <p>If no media is selected we will add a placeholder to your calendar.</p>
    </div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaContainer',
  components: { Icon },
  props: {
    errorMessage: { type: String, default: '' },
    showMediaPlaceholderMessage: { type: Boolean, default: false },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.tip {
  display: flex;
  padding-top: var(--space-24);

  p {
    margin-left: var(--space-16);
    font-size: var(--x14);
  }
}

.error-message {
  margin: var(--space-16) 0;
  padding: var(--space-8) var(--space-16);
  background-color: var(--error-100);
  color: var(--error-500);
  font-size: var(--x13);
  border-radius: var(--round-corner-small);
}

.media-container {
  width: 26.25rem;
  min-height: 22.5rem;
  border-radius: var(--round-corner) 0 0 var(--round-corner);
  background: var(--background-300);
  padding: var(--space-32);
  display: flex;
  flex-direction: column;

  section {
    width: 100%;
    text-align: left;
    font-size: var(--x14);
    margin-bottom: var(--space-24);

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--space-8);

      p {
        font-size: var(--x14);
      }

      label {
        color: var(--text-secondary);
        font-size: var(--x14);
        margin-bottom: 0;
      }
    }

    textarea {
      resize: none;
      height: var(--space-72);
    }
  }
}
</style>
