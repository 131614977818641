import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import * as LibraryAPI from '@/apis/library';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';
import { constants } from '@/config';
import { useSocketStore } from '@/stores/socket';

export const useInstagramStoryStore = defineStore(
  'instagramStory',
  () => {
    const authStore = useAuthStore();
    const socketStore = useSocketStore();

    const narrative = ref(null);
    const narrativeMedia = ref([]);
    const narrativeMediaNextPageUrl = ref(null);
    const narrativeMediaFullyLoaded = ref(false);
    const storyBoardsParams = ref(null);
    const storyBoardsFilters = ref(null);
    const storyNarrativeFilters = ref(null);
    const storyFramesTimeSeries = ref({});
    const storyOverviewPageYOffset = ref(null);
    const storyBoardsPageYOffset = ref(null);

    const pending = ref({
      narrativeMedia: false,
    });
    const error = ref({
      narrative: null,
    });
    const abortControllers = {
      getNarrativeMedia: ref(null),
      getStoryFramesTimeSeries: ref(null),
    };

    async function getNarrative({ brandId, galleryId }) {
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;

      error.value.narrative = null;
      try {
        const response = await LibraryAPI.getGallery({ brandId: brandIdToUse, galleryId });
        const payload = response?.data;
        narrative.value = payload;
        return payload;
      } catch (e) {
        error.value.narrative = e;
        throw e;
      }
    }

    async function createNarrative({ name }) {
      const brandId = authStore.currentBrand?.id;
      const galleryType = constants.INSTAGRAM_STORY_NARRATIVE;

      const response = await LibraryAPI.createGallery({ brandId, name, galleryType });
      const payload = response?.data;

      return payload;
    }

    async function getNarrativeMedia({ brandId, galleryId, sort, url, limit }) {
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;
      const include = ['source_data'];

      if (!url) {
        narrativeMedia.value = [];
        narrativeMediaNextPageUrl.value = null;
        narrativeMediaFullyLoaded.value = false;
      }

      pending.value.narrativeMedia = true;
      try {
        const signal = refreshAbortController(abortControllers.getNarrativeMedia);

        const response = await LibraryAPI.default.getGalleryMediaV2(
          { brandId: brandIdToUse, galleryId, sort, include, url, limit },
          { signal },
        );
        const payload = response?.data;

        narrativeMedia.value = [...narrativeMedia.value, ...(payload?.data ?? [])];
        narrativeMediaNextPageUrl.value = payload?.paging?.next;
        if (!narrativeMediaNextPageUrl.value) {
          narrativeMediaFullyLoaded.value = true;
        }

        return payload;
      } catch (e) {
        handleCancelError(e);
      } finally {
        pending.value.narrativeMedia = false;
      }
      return undefined;
    }

    async function addFrameToNarrative({ brandId, galleryId, mediaIds }) {
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;

      const response = await LibraryAPI.addGalleryMedia({
        brandId: brandIdToUse,
        galleryId,
        mediaIds,
      });
      const payload = response?.data;

      return payload;
    }

    async function removeFrameFromNarrative({ galleryId, mediaId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.removeGalleryMedia({ brandId, galleryId, mediaId });
      const payload = response?.data;

      narrativeMedia.value = narrativeMedia.value.filter((m) => m.id !== mediaId);

      return payload;
    }

    async function getStoryFramesTimeSeries({ scale, startDate, endDate }) {
      const brandId = authStore.currentBrand?.id;

      try {
        const signal = refreshAbortController(abortControllers.getStoryFramesTimeSeries);

        const response = await LibraryAPI.getTimeSeriesMetricsInstagramStory(
          { brandId, scale, startDate, endDate },
          { signal },
        );
        const payload = response?.data;
        storyFramesTimeSeries.value = payload;
        return payload;
      } catch (e) {
        handleCancelError(e);
      }
      return undefined;
    }

    async function getGalleryStoryFramesCSV({ brandId, galleryId, type, sort }) {
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;
      const socketId = socketStore.id;

      const response = await LibraryAPI.getGalleryCSV({
        brandId: brandIdToUse,
        galleryId,
        sort,
        socketId,
        type,
      });
      const payload = response?.data;

      return payload;
    }

    function setStoryBoardsParams({ storyBoardsParams: newParams }) {
      storyBoardsParams.value = newParams;
      if (newParams.type === constants.INSTAGRAM_STORY) {
        storyBoardsFilters.value = storyBoardsParams;
      }
      if (newParams.type === constants.INSTAGRAM_STORY_NARRATIVE) {
        storyNarrativeFilters.value = storyBoardsParams;
      }
    }

    function setStoryBoardsPageYOffset({ storyBoardsPageYOffset: newValue }) {
      storyBoardsPageYOffset.value = newValue;
    }

    function setStoryOverviewPageYOffset({ storyOverviewPageYOffset: newValue }) {
      storyOverviewPageYOffset.value = newValue;
    }

    function clearStoryBoardsParams() {
      storyBoardsParams.value = null;
    }

    function clearNarrative() {
      narrative.value = null;
      narrativeMedia.value = [];
      narrativeMediaNextPageUrl.value = null;
      narrativeMediaFullyLoaded.value = false;
    }

    function clearStoryOverviewMetrics() {
      storyFramesTimeSeries.value = {};
    }

    return {
      pending,
      error,
      narrative,
      narrativeMedia,
      narrativeMediaNextPageUrl,
      narrativeMediaFullyLoaded,
      storyBoardsParams,
      storyBoardsFilters,
      storyNarrativeFilters,
      storyFramesTimeSeries,
      storyBoardsPageYOffset,
      storyOverviewPageYOffset,
      getNarrative,
      createNarrative,
      getNarrativeMedia,
      addFrameToNarrative,
      removeFrameFromNarrative,
      getStoryFramesTimeSeries,
      getGalleryStoryFramesCSV,
      setStoryBoardsParams,
      setStoryBoardsPageYOffset,
      setStoryOverviewPageYOffset,
      clearStoryBoardsParams,
      clearNarrative,
      clearStoryOverviewMetrics,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
