<template>
  <SubScreen
    :sticky-nav="true"
    class="control"
    title="Edit This Pin"
    back-button-color="white"
    @on-back="backClicked"
  >
    <template #content>
      <section :class="sticky">
        <ProductPopup v-if="showProductPopup" @on-change="updateLink" @close="closeProductPopup" />
        <section>
          <section
            v-if="showAddToBoardControl || showPublishControl"
            class="single-control-section"
          >
            <template v-if="showAddToBoardControl">
              <p class="pin-title">
                Select a Pinterest Board to pin
                <span class="required-asterisk">*</span>
              </p>
              <PinterestBoardSelector
                v-model="pinterestBoard"
                v-tooltip="boardTooltip"
                :disabled="boardDisabled"
              />
              <AfterPostGallerySelector v-model="selectedGalleries" />
            </template>
            <template v-if="showPublishControl">
              <hr class="control-items" />
              <p class="pin-title">Select a date &amp; time to publish</p>
              <ImageDropdownSelect
                v-model="publishType"
                :options="publishOptions"
                option-icon
                capitalize-text
              />
              <QuickSelectCalendar
                :disabled="!pinterestConnected"
                :tooltip="!pinterestConnected ? toolTips.noPinterestConnection : ''"
                :model-value="scheduledTime"
                :platform="platformLabels.pinterest"
                dropdown-select-style
                @update:model-value="timeSelected"
              />
            </template>
            <hr />
          </section>
          <div class="edit-pin-item">
            <p class="pin-title">
              Pin Destination Link
              <Icon
                v-tooltip="toolTips.pinterestDestinationLink"
                :hover-color="colours.ACTION.ACTION_500"
                class="pin-title-tooltip"
                name="info"
                xsmall
              />
            </p>
            <div :class="{ highlight, error: linkError }" class="input-link">
              <input
                ref="linkInput"
                v-model="website"
                class="input-field"
                placeholder="http://"
                @focus="highlight = true"
                @blur="onLinkFieldBlur"
              />
              <div class="edit-link-options">
                <button :class="hideClearButton" class="clear-link" @click="clearLink">
                  <Icon name="closeCircleSolid" small />
                </button>
                <button
                  v-tooltip="'Choose from products'"
                  class="link-option"
                  @click="openProductPopup"
                >
                  <Icon color="#666" name="windowLink" />
                </button>
              </div>
            </div>
          </div>
          <div class="edit-pin-item">
            <p class="pin-title flex-layout">
              <span>
                Title
                <span class="required-asterisk">*</span>
              </span>
              <span
                :class="{ 'caption-red': pinTitle.length === pinterestCaptionLimit.title }"
                class="caption-indicator"
              >
                {{ pinterestCaptionLimit.title - pinTitle.length }}
              </span>
            </p>
            <input
              ref="titleInput"
              v-model="pinTitle"
              :class="{ warning: pinTitle.length === pinterestCaptionLimit.title }"
              placeholder="Enter title"
            />
          </div>
          <div class="edit-pin-item">
            <p class="pin-title flex-layout">
              Description
              <span
                :class="{ 'caption-red': description.length === pinterestCaptionLimit.description }"
                class="caption-indicator"
              >
                {{ pinterestCaptionLimit.description - description.length }}
              </span>
            </p>
            <Textarea
              ref="descriptionInput"
              v-model="description"
              :class="{ warning: description.length === pinterestCaptionLimit.description }"
              :limit="pinterestCaptionLimit.description"
              placeholder="Enter description"
            />
          </div>
          <div class="save-wrapper">
            <Button class="save" primary @click="done">Done</Button>
          </div>
        </section>
      </section>
    </template>
  </SubScreen>
</template>

<script>
import { defineComponent, defineAsyncComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useProductStore } from '@/stores/product';
import { getPublishOptions } from '@/app/scheduler/utils';
import { pinterestCaptionLimit, platformLabels } from '@/app/scheduler/constants';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';
import Icon from '@/components/foundation/Icon.vue';
import Textarea from '@/components/Textarea.vue';
import PinterestBoardSelector from '@/app/scheduler/components/EditPost/PinterestBoardSelector.vue';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useMediaLinksStore } from '@/stores/media-links';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import { useImpersonatorStore } from '@/stores/impersonator';
import AfterPostGallerySelector from './AfterPostGallerySelector.vue';
import SubScreen from '../EditPost/Layout/SubScreen.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SinglePinMetaFields',
  components: {
    AfterPostGallerySelector,
    Button,
    ImageDropdownSelect,
    Icon,
    PinterestBoardSelector,
    ProductPopup: defineAsyncComponent(
      () => import('@/app/library/components/MediaPopup/ProductPopup.vue'),
    ),
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    SubScreen,
    Textarea,
  },
  props: {
    onBack: { type: Function, required: true },
    isAppConnected: { type: Boolean, default: false },
  },
  emits: ['pinUpdated'],
  data() {
    return {
      description: '',
      highlight: false,
      linkError: null,
      pinterestBoard: null,
      pinterestCaptionLimit,
      pinTitle: '',
      publishType: 'autoPublish',
      scheduledTime: null,
      selectedGalleries: [],
      showAddToBoardControl: false,
      showPublishControl: false,
      showProductPopup: false,
      toolTips,
      website: '',
    };
  },
  computed: {
    ...mapStores(
      usePlatformStore,
      useMediaLinksStore,
      useProductStore,
      useSchedulerPinterestStore,
      useAuthStore,
      useImpersonatorStore,
    ),
    boardDisabled() {
      return this.disableEditing || !this.isAppConnected;
    },
    boardTooltip() {
      if (!this.isAppConnected) {
        return toolTips.boardNeedsAccount;
      }
      return '';
    },
    colours() {
      return colours;
    },
    pinterestConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.PINTEREST);
    },
    sticky() {
      if (this.showAddToBoardControl || this.showPublishControl) {
        return 'sticky-more-space';
      }
      return 'sticky-less-space';
    },
    hideClearButton() {
      return { hide: !this.website };
    },
    publishOptions() {
      const isDisabledDueToInsufficientPermission =
        // Currently only user permissions are set for pinterest auto publishing (no brand-specific perms)
        !this.authStore.user_can('scheduler', 'can_auto_publish_pinterest') ||
        this.impersonatorStore.isImpersonating;
      const canAutoPublish = !isDisabledDueToInsufficientPermission;
      let autoPublishTooltip = null;
      if (isDisabledDueToInsufficientPermission) {
        autoPublishTooltip = toolTips.insufficientPermission;
      }
      return getPublishOptions(canAutoPublish, autoPublishTooltip);
    },
    platformLabels() {
      return platformLabels;
    },
  },
  watch: {
    'schedulerPinterestStore.addToSameBoardSetting': {
      handler(to) {
        this.showAddToBoardControl = !to.on;
      },
    },
    'productStore.urlValidationStatusDict': function updateUrlValidationStatusDict(to) {
      if (to && to[this.schedulerPinterestStore.selectedPin.id]) {
        this.linkError = true;
        this.schedulerPinterestStore.selectedPin.invalidLink = true;
      } else {
        this.linkError = false;
        this.schedulerPinterestStore.selectedPin.invalidLink = false;
      }
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    description(to) {
      if (to) {
        this.description = to.substr(0, pinterestCaptionLimit.description);
      }
      this.schedulerPinterestStore.selectedPin.description = this.description;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    selectedGalleries(to) {
      this.schedulerPinterestStore.selectedPin.selectedGalleries = to;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    'mediaLinksStore.mediaLinks': function mediaLinksWatcher(to) {
      if (to.length !== 0) {
        if (!this.website) {
          this.website = to[0].url || '';
        }
        if (!this.description) {
          this.description = to[0].description || '';
        }
        if (!this.pinTitle) {
          this.pinTitle = to[0].title || '';
        }
      }
    },
    pinterestBoard(to) {
      this.schedulerPinterestStore.selectedPin.pinterestBoard = to;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    pinTitle(to) {
      if (to) {
        this.pinTitle = to.substr(0, pinterestCaptionLimit.title);
      }
      this.schedulerPinterestStore.selectedPin.title = this.pinTitle;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    'schedulerPinterestStore.publishAtSameTimeSetting': {
      handler(to) {
        if (to) {
          this.showPublishControl = !to.on;
        }
      },
    },
    publishType(to) {
      this.schedulerPinterestStore.selectedPin.publishType = to;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    'schedulerPinterestStore.selectedPin': {
      handler(to) {
        if (to) {
          this.loadDataFields();
        }
      },
      deep: true,
    },
    website(to) {
      this.schedulerPinterestStore.selectedPin.linkUrl = to;
      if (!to) {
        this.linkError = false;
        this.schedulerPinterestStore.selectedPin.invalidLink = false;
      }
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
  },
  mounted() {
    if (
      this.schedulerPinterestStore.addToSameBoardSetting &&
      this.schedulerPinterestStore.addToSameBoardSetting.on === false
    ) {
      this.showAddToBoardControl = true;
    }
    if (
      this.schedulerPinterestStore.publishAtSameTimeSetting &&
      this.schedulerPinterestStore.publishAtSameTimeSetting.on === false
    ) {
      this.showPublishControl = true;
    }
    this.loadDataFields();
  },
  methods: {
    backClicked() {
      this.onBack();
    },
    clearLink() {
      this.website = '';
      this.schedulerPinterestStore.selectedPin.linkUrl = '';
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    closeProductPopup() {
      this.showProductPopup = false;
    },
    done() {
      this.onBack();
    },
    loadDataFields() {
      this.website = this.schedulerPinterestStore.selectedPin.linkUrl || '';
      this.pinTitle = this.schedulerPinterestStore.selectedPin.title || '';
      this.description = this.schedulerPinterestStore.selectedPin.description || '';
      this.pinterestBoard = this.schedulerPinterestStore.selectedPin.pinterestBoard;
      this.publishType = this.schedulerPinterestStore.selectedPin.publishType || 'autoPublish';
      this.scheduledTime = this.schedulerPinterestStore.selectedPin.scheduledTime;
      this.selectedGalleries = this.schedulerPinterestStore.selectedPin.selectedGalleries || [];
      this.linkError = this.schedulerPinterestStore.selectedPin.invalidLink;
    },
    onLinkFieldBlur() {
      this.highlight = false;
      this.validateUrl();
    },
    timeSelected(time) {
      this.scheduledTime = time;
      this.schedulerPinterestStore.selectedPin.scheduledTime = time;
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    openProductPopup() {
      this.showProductPopup = true;
    },
    updateLink(link) {
      const { product } = link;
      this.website = product.url || '';
      this.schedulerPinterestStore.selectedPin.linkUrl = product.url || '';
      if (!this.pinTitle && product.title) {
        this.pinTitle = product.title;
        this.schedulerPinterestStore.selectedPin.title = product.title;
      }
      if (!this.description && product.description) {
        this.description = product.description;
        this.schedulerPinterestStore.selectedPin.description = product.description;
      }
      this.$emit('pinUpdated', this.schedulerPinterestStore.selectedPin);
    },
    validateUrl() {
      if (!this.website) {
        return;
      }
      const hasHeader =
        this.website.indexOf('http://') > -1 || this.website.indexOf('https://') > -1;
      if (!hasHeader) {
        this.website = `https://${this.website}`;
      }
      if (this.schedulerPinterestStore.selectedPin) {
        this.productStore.getUrlMetadata({
          url: this.website,
          id: this.schedulerPinterestStore.selectedPin.id,
        });
      }
    },
    focusWebLink(pin) {
      // If a pin is already selected and selected again
      // then force cursor to be at the end of input
      if (this.schedulerPinterestStore.selectedPin === pin) {
        const tempVal = this.website;
        this.$refs.linkInput.value = '';
        this.$refs.linkInput.value = tempVal;
      }
      nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.control {
  border-radius: 0 0 var(--round-corner-small) 0;
  background: var(--background-300);
  width: 30%;
  justify-content: center;
  padding: var(--space-32) var(--space-24) 0 var(--space-24);
}

.input-link,
.edit-pin-item {
  position: relative;

  input {
    width: 100%;
    border: 1px solid var(--border);
    height: var(--space-40);
    line-height: var(--space-32);
    border-radius: var(--round-corner-small);
    background: var(--background-0);
    color: var(--text-primary);
    font-size: var(--x14);
    padding: 0 var(--space-8);
    text-overflow: ellipsis;
  }

  input:focus {
    border-color: var(--action-500);
  }
}

.pin-title {
  padding: var(--space-16) 0 var(--space-8);
}

.pin-title-tooltip {
  position: relative;
  top: 3px;
}

.pin-title.flex-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: var(--space-4);
}

.single-control-section {
  margin-top: var(--space-16);

  :deep(.select) {
    margin: 0;
  }

  :deep(.schedule-time-box) {
    margin: var(--space-8) 0;
  }

  :deep(.quick-select-calendar) {
    margin: 0;
  }

  hr {
    margin: var(--space-16) 0 (calc(-1 * var(--space-8)));
    width: 100%;
  }
}

.input-link {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: var(--round-corner-small);

  .input-field {
    border: 0;
    padding-right: 0;
  }

  .edit-link-options {
    position: relative;
    display: flex;
  }

  .link-option {
    height: var(--space-40);
    width: var(--space-40);
    border-left: 1px solid var(--border);
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    margin: 0;
    border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
    background: var(--background-0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clear-link {
    height: var(--space-40);
    width: var(--space-32);
    border: 0;
    margin: 0;
    background: var(--background-0);
    cursor: pointer;
  }
}

.hide {
  display: none;
}

.highlight {
  border-color: var(--action-500);
}

.error {
  border-color: var(--error-500);
}

.save-wrapper {
  display: flex;
  padding: var(--space-40) 0;

  button.save {
    min-width: 0;
    transform: none;
    box-shadow: none;
    width: 100%;
  }
}

.caption-indicator {
  color: var(--text-secondary);
  font-size: var(--x12);
}

.required-asterisk {
  color: var(--error-500);
}

.warning {
  &:focus,
  :deep(textarea:focus) {
    border-color: var(--error-500) !important;
  }
}

section.sticky-less-space {
  position: sticky;
  top: 3rem;
}

section.sticky-more-space {
  position: sticky;
  top: 4rem;
}

.caption-red {
  color: var(--error-500);
}
</style>
