<template>
  <Popup :key="sourceId" :close="close" type="xlarge" close-on-route-change data-cy="media-popup">
    <Arrow
      v-if="showPrevArrow"
      :show-previous-arrow="true"
      @click-previous-arrow="previousArrowClicked"
    />

    <Arrow v-if="showNextArrow" :show-next-arrow="true" @click-next-arrow="nextArrowClicked" />
    <main>
      <div v-if="!loading" class="media-main" data-cy="media-popup-content">
        <div class="left-container">
          <div class="media-item-container">
            <!-- Carousel media popup -->
            <Carousel
              v-if="isCarousel"
              ref="carousel"
              :per-page="1"
              :pagination-color="paginationColor"
              :pagination-active-color="paginationActiveColor"
              :navigate-to="[0, false]"
              :navigation-enabled="true"
              :speed="700"
              :navigation-next-label="''"
              :navigation-prev-label="''"
              class="media-carousel"
            >
              <Slide
                v-for="(media, index) in carouselMedia"
                :key="index"
                :title="`Media ${index + 1}`"
              >
                <MediaItem
                  :id="`media-${index}`"
                  :media-type="media.mediaType"
                  :media-url="media.mediaUrl"
                  :cover="cover"
                  :source-type="media.sourceType"
                  carousel
                  @close="close"
                />
              </Slide>
            </Carousel>
            <TwitterTextPreview
              v-else-if="isTextPreview"
              :post-type="mediaType"
              :post-url="permalink"
            />

            <EmbedPreviewMediaView
              v-else-if="isYouTube"
              :key="sourceId"
              class="embed-preview-media-view"
              :src-url="srcUrl"
              :iframe-attributes="iframeAttributes"
              :styles="styles"
              @close="close"
            />

            <!-- Single media popup -->
            <MediaItem
              v-else
              id="media-0"
              :media-type="mediaType"
              :media-url="mediaUrl"
              :cover="cover"
              :thumbnail-url="thumbnailUrl"
              :source-type="sourceType"
              :duration="duration"
              @close="close"
            />
          </div>
        </div>
        <div class="right-container">
          <UserSection
            class="user-info"
            :source-type="sourceType"
            :name="name"
            :user-name="userName"
            :message="message"
            :date-posted="datePosted"
            :platform-home-page-url="sourceId"
            :icon-name="sourceType"
            :avatar-url="avatarUrl"
            :email="email"
            :tooltip="tooltip"
            :post-type="sourceType"
            :source-id="sourceId"
            :permalink="permalink"
            :source-creator-id="sourceCreatorId"
          />
          <Sentiment :sentiment="sentiment" />
          <Insights class="insights-dropdown" :insights="insights" :platform-icon="sourceType" />
        </div>
      </div>
      <CircularLoader v-else class="loading" />
    </main>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { Carousel, Slide } from '@/vendor/vue-carousel';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import globalModalsMixin from '@/mixins/globalModalsMixin';
import enumTypes, { MEDIA_TYPE_OPTIONS } from '@/app/library/constants';
import Popup from '@/components/Popup.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { usePlatformStore } from '@/stores/platform';
import { useMediaLinksStore } from '@/stores/media-links';
import { useSearchStore } from '@/stores/search';
import { useMediaPopupStore } from '@/stores/media-popup';
import { useTiktokStore } from '@/stores/tiktok';
import { useMediaDetailStore } from '@/stores/media-detail';
import UserSection from '@/components/common/MediaPopupV2/UserSection.vue';
import Arrow from '@/components/foundation/Arrow.vue';
import MediaItem from '@/components/common/MediaPopupV2/MediaItem.vue';
import Insights from '@/components/common/MediaPopupV2/Insights.vue';
import Sentiment from '@/components/common/MediaPopupV2/Sentiment.vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import { stringifyQuery } from '@/utils/query';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';
import {
  SOCIAL_LISTENING_CARD_TYPES,
  SOCIAL_LISTENING_ROUTE_NAMES,
} from '@/app/socialListening/constants';

import { colours } from '@/ux/colours';
import { formatChannelForPublicMediaRoute } from '@/utils/formatters';
import TwitterTextPreview from '@/components/common/MediaPopupV2/TwitterTextPreview.vue';
import { socialListeningCardClickedEvent } from '@/app/socialListening/utils/mixpanel-events';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaPopupV2',
  components: {
    MediaItem,
    Arrow,
    Insights,
    CircularLoader,
    Popup,
    UserSection,
    EmbedPreviewMediaView,
    Sentiment,
    Carousel,
    Slide,
    TwitterTextPreview,
  },
  mixins: [globalModalsMixin],
  props: {
    carousel: { type: Boolean, default: false },
    mediaType: { type: String, default: null },
    mediaUrl: { type: String, default: null },
    cover: { type: Boolean, default: false },
    thumbnailUrl: { type: String, default: null },
    sourceType: { type: String, required: true },
    duration: { type: Number, default: null },
    insights: { type: Array, default: () => [] },
    userName: { type: String, default: null },
    name: { type: String, default: null },
    datePosted: { type: String, default: null },
    message: { type: String, default: null },
    avatarUrl: { type: String, default: null },
    invitationStatus: { type: String, default: null },
    email: { type: String, default: null },
    tooltip: { type: String, default: null },
    sourceId: { type: String, default: null },
    permalink: { type: String, default: null },
    sentiment: { type: Object, default: () => {} },
    carouselMedia: { type: Array, default: () => [] },
    sourceCreatorId: { type: String, default: null },
  },
  data() {
    return {
      currentMediaIndex: null,
      isRenderingFinished: false,
      paginationColor: colours.TEXT.TEXT_SECONDARY,
      paginationActiveColor: colours.BASIC.WHITE,
      iframeAttributes: {
        allowFullscreen: true,
      },
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useNotificationStore,
      usePlatformStore,
      useMediaLinksStore,
      useSearchStore,
      useMediaPopupStore,
      useMediaDetailStore,
      useTiktokStore,
      useSocialListeningStore,
    ),
    isTextPreview() {
      return (
        this.isTwitter &&
        (this.mediaType === MEDIA_TYPE_OPTIONS.LINK.value ||
          this.mediaType === MEDIA_TYPE_OPTIONS.TEXT.value)
      );
    },
    mediaIndex() {
      const ids = this.mediaPopupStore.contextList.map((id) => id.sourceId);
      return ids.indexOf(this.sourceId);
    },
    showPrevArrow() {
      return this.currentMediaIndex > 0;
    },
    showNextArrow() {
      return this.currentMediaIndex < this.mediaPopupStore.contextList.length - 1;
    },
    isCarousel() {
      return this.carouselMedia?.length > 1;
    },
    isYouTube() {
      return this.sourceType === enumTypes.YOUTUBE;
    },
    isTwitter() {
      return this.sourceType === enumTypes.TWITTER;
    },
    loading() {
      return !this.mediaType;
    },
    srcUrl() {
      const queryParams = stringifyQuery({
        // do not show related videos on pause
        rel: 0,
        // do not show YouTube logo in control bar
        modestbranding: 1,
        // set the color used in the video progress bar to white
        color: 'white',
        // use light UI theme
        theme: 'light',
      });
      return `https://www.youtube.com/embed/${this.sourceId}?${queryParams}`;
    },
    styles() {
      return {
        width: '600px',
        height: '400px',
      };
    },
  },
  watch: {
    mediaIndex() {
      this.currentMediaIndex = this.mediaIndex;
    },
  },
  created() {
    this.currentMediaIndex = this.mediaIndex;
    socialListeningCardClickedEvent({
      cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
      pageOpenedFrom: this.$route?.name,
      cardDetails: {
        mediaOpened: this.sourceId,
        mediaType: this.mediaType,
        sourceType: this.sourceType,
        sentiment: this.sentiment,
      },
      path: this.$router.history?.pending?.path,
    });
  },
  mounted() {
    window.addEventListener('keydown', this.arrowKeySwitchHandler);
  },
  unmounted() {
    this.mediaDetailStore.clearMediaDetail();
    this.searchStore.clearMediaSourceList();
    window.removeEventListener('keydown', this.arrowKeySwitchHandler);
  },
  methods: {
    close() {
      if (this.mediaDetailStore.showMediaPopup) {
        this.mediaDetailStore.closeMediaPopup();
      } else if (this.route) {
        this.$router.push({ name: this.route });
      } else {
        this.$router.back();
      }
    },
    previousArrowClicked() {
      this.loadedFromPreviousArrow = true;
      this.currentMediaIndex -= 1;
      const previousMediaItem = this.mediaPopupStore.contextList[this.currentMediaIndex];

      this.$router.push({
        name: SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA,
        params: {
          mediaItem: previousMediaItem,
          channel: formatChannelForPublicMediaRoute(previousMediaItem.source),
          id: previousMediaItem.sourceId,
        },
        query: {
          replaceModal: true,
        },
      });
      socialListeningCardClickedEvent({
        cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
        pageOpenedFrom: this.$route?.name,
        cardDetails: {
          mediaOpened: previousMediaItem.sourceId,
          mediaType: previousMediaItem.mediaType,
          sourceType: previousMediaItem.source,
          sentiment: previousMediaItem?.sentiment,
        },
        path: this.$router.history?.pending?.path,
      });
    },
    nextArrowClicked() {
      this.loadedFromNextArrow = true;
      this.currentMediaIndex += 1;
      const nextMediaItem = this.mediaPopupStore.contextList[this.currentMediaIndex];
      this.$router.push({
        name: SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA,
        params: {
          mediaItem: nextMediaItem,
          channel: formatChannelForPublicMediaRoute(nextMediaItem.source),
          id: nextMediaItem.sourceId,
        },
        query: {
          replaceModal: true,
        },
      });
      socialListeningCardClickedEvent({
        cardType: SOCIAL_LISTENING_CARD_TYPES.TOP_POST,
        pageOpenedFrom: this.$route?.name,
        cardDetails: {
          mediaOpened: nextMediaItem.sourceId,
          mediaType: nextMediaItem.mediaType,
          sourceType: nextMediaItem.source,
          sentiment: nextMediaItem?.sentiment,
        },
        path: this.$router.history?.pending?.path,
      });
    },
    arrowKeySwitchHandler(e) {
      const key = e.which || e.keyCode;
      const LEFT_ARROW_KEYCODE = 37;
      const RIGHT_ARROW_KEYCODE = 39;
      if (
        ['TEXTAREA', 'INPUT'].indexOf(document.activeElement.tagName) > -1 ||
        window.location.href.includes('scheduler')
      ) {
        return;
      }
      if (key === LEFT_ARROW_KEYCODE && this.showPrevArrow) {
        this.previousArrowClicked();
      } else if (key === RIGHT_ARROW_KEYCODE && this.showNextArrow) {
        this.nextArrowClicked();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.nav-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--space-24);
  cursor: pointer;
}

.prev-arrow {
  position: absolute;
  left: -60px;
}

.next-arrow {
  position: absolute;
  right: -60px;
}

main {
  position: relative;
  min-height: 600px;
}

.media-main {
  display: flex;

  .left-container {
    width: 55%;
    border-radius: var(--round-corner) 0 0 var(--round-corner);
    text-align: center;
    background: var(--background-300);
    padding: var(--space-40);

    .info-text {
      float: left;
      font-size: var(--x12);
      margin-bottom: 30px;

      .info-icon {
        margin-right: 10px;
        margin-bottom: -3px;
      }
    }

    .similar-media {
      text-align: left;

      label {
        color: var(--text-primary);
        font-size: var(--x18);
      }
    }
  }

  .right-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: var(--space-40);

    :deep(.information-box) {
      margin-bottom: var(--space-16);
    }

    .user-info,
    .content-rights {
      width: 100%;
      background: var(--background-300);
      border-radius: var(--round-corner-small);
    }

    .user-info {
      padding: var(--space-16);
      margin: 0 auto var(--space-8);
    }

    .content-rights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: var(--space-8) auto;
      padding: 0 var(--space-16);
    }

    .insights {
      background: var(--background-300);
      border-radius: var(--round-corner);
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: var(--space-24);
      margin: var(--space-8) auto;
      font-size: var(--x14);
      color: var(--text-primary);
    }
  }
}

.loading {
  padding: 10rem 0;
}

.small.loading {
  padding: 0 var(--space-24);
}

:deep(.chart-title) {
  padding-bottom: 0 !important;
}

:deep(.selection-display) {
  text-align: left;
}

:deep(.chart-container) {
  background: var(--background-300);
  box-shadow: none;
}

.media-item-container {
  margin-bottom: var(--space-32);
}

.media-carousel {
  .VueCarousel-navigation-button.VueCarousel-navigation-next,
  .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    top: 50%;
  }

  .VueCarousel-pagination {
    z-index: var(--z-index-sticky);
    position: absolute;
    bottom: var(--space-18);
    padding-left: var(--space-24);
    padding-right: var(--space-24);

    ul {
      height: 5rem;
    }

    button {
      margin-top: 0 !important;
    }

    button:focus {
      outline: none;
    }
  }
}

.embed-preview-media-view {
  :deep(.embedded-content-container) {
    width: 580px;
    height: 1226px;
    border-radius: var(--round-corner-large);
  }
}

.youtube-media-view {
  margin-bottom: var(--space-40);
}

:deep(#embedded-content-iframe) {
  height: 1000px;
}
</style>
