/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'crop': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#767676"><path pid="0" d="M9 15v-5H7v7h14v-2z"/><path pid="1" d="M15 14h2V7H3v2h12zM7 2h2v4H7zM15 18h2v4h-2z"/></g>'
  }
})
