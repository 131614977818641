/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'youtube': {
    width: 16,
    height: 16,
    viewBox: '-2 -5 24 24',
    data: '<defs><path pid="0" d="M10 0h.5c1.5 0 6 0 7.3.4.9.2 1.6 1 1.8 1.8.3 1.3.4 4 .4 4.6v.4c0 .6 0 3.3-.4 4.6-.2.9-1 1.6-1.8 1.8-.6.2-2 .3-3.5.3h-.5l-3.6.1H5.7c-1.5-.1-2.9-.2-3.5-.4-.9-.2-1.6-1-1.8-1.8A24 24 0 010 7.2v-.4C0 6 0 3.5.4 2.2.6 1.3 1.4.6 2.2.4 3.5.1 8 0 9.5 0h.4zM7 4v6l6-3-6-3z" id="svgicon_youtube_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_youtube_b" _fill="#fff"><use xlink:href="#svgicon_youtube_a"/></mask><use _fill="#222321" xlink:href="#svgicon_youtube_a"/><g mask="url(#svgicon_youtube_b)"><path pid="1" _fill="#FFF" fill-rule="nonzero" d="M-2-5h24v24H-2z"/></g></g>'
  }
})
