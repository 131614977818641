<template>
  <div
    class="dh-card"
    :class="customizedClasses"
    :style="measurableStyles"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <slot v-if="!showHoverContent" />
    <slot v-if="showHoverContent" name="hoverContent" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MeasurableMixin from '@/components/foundation/mixins/measurable.mixin';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  mixins: [MeasurableMixin],
  props: {
    /**
     * Puts the card into hover mode.  Mousing over the card will raise the card up off the page.
     */
    hoverMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    customizedClasses() {
      return {
        hasClick: this.hasClickListener,
        hover: this.hoverMode && this.isHovering,
        'card-disabled': this.disabled,
      };
    },
    hasClickListener() {
      return !!this.$attrs?.onClick;
    },
    showHoverContent() {
      return this.isHovering && this.hoverMode && this.$slots.hoverContent;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-card {
  width: auto;
  height: 100%;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-1);
  transition: var(--transition-all);

  &.hasClick {
    cursor: pointer;
  }

  &.hover {
    box-shadow: var(--shadow-2);
    transform: var(--hover-move);
    z-index: var(--z-index-raised);
  }

  &.card-disabled {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0.3;

    &:hover {
      cursor: auto;
      transform: none;
      transition: none;
      box-shadow: var(--shadow-1);
    }
  }

  :deep(> .dh-card-title),
  :deep(> .dh-card-content),
  :deep(> .dh-card-actions) {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  :deep(> *:first-of-type) {
    &.dh-card-title,
    &.dh-card-content,
    &.dh-card-actions {
      padding-top: var(--space-24);
    }
  }

  :deep(> *:last-of-type) {
    &.dh-card-title,
    &.dh-card-content,
    &.dh-card-actions {
      padding-bottom: var(--space-24);
    }
  }
}

@media print {
  .dh-card {
    border: 1px solid var(--border);
    box-shadow: none;
  }
}
</style>
