import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import * as FacebookAPI from '@/apis/facebook';
import { useAuthStore } from '@/stores/auth';

export const useFacebookProductTaggerStore = defineStore(
  'facebookProductTagger',
  () => {
    const authStore = useAuthStore();

    const facebookProductTaggingEligible = ref(null);
    const facebookPageId = ref(null);
    const facebookProductCatalogs = ref(null);
    const facebookProductTaggerEnabled = ref(false);
    const editingFacebookProductTag = ref(false);
    const selectedFacebookProductCatalogId = ref(null);
    const activeMediaId = ref(null);
    const internalTaggedProducts = ref({});

    const pending = ref({
      facebookProductTaggingEligible: 0,
      facebookProductCatalogs: 0,
    });

    const taggedProducts = computed(() => {
      return Object.keys(internalTaggedProducts.value).reduce((tags, mediaId) => {
        internalTaggedProducts.value[mediaId].forEach((tag) =>
          tags.push({ mediaId: parseInt(mediaId, 10), ...tag }),
        );
        return tags;
      }, []);
    });
    const activeMediaTaggedProducts = computed(() => {
      return internalTaggedProducts.value[activeMediaId.value] ?? [];
    });

    async function checkFacebookProductTaggingEligible() {
      const brandId = authStore.currentBrand?.id;

      pending.value.facebookProductTaggingEligible += 1;
      try {
        const response = await FacebookAPI.getProductTagsEligibility({ brandId });
        const payload = response?.data;

        facebookProductTaggingEligible.value = payload?.shoppingProductTagEligibility;
        facebookPageId.value = payload?.fbPageId;

        return payload;
      } finally {
        pending.value.facebookProductTaggingEligible -= 1;
      }
    }

    async function getFacebookProductCatalogs() {
      const brandId = authStore.currentBrand?.id;

      pending.value.facebookProductCatalogs += 1;
      try {
        const response = await FacebookAPI.getProductTagsCatalogs({
          brandId,
          fbPageId: facebookPageId.value,
        });
        const payload = response?.data;

        facebookProductCatalogs.value = payload;

        return payload;
      } finally {
        pending.value.facebookProductCatalogs -= 1;
      }
    }

    function disableFacebookProductTagger() {
      facebookProductTaggerEnabled.value = false;
    }

    function enableFacebookProductTagger() {
      facebookProductTaggerEnabled.value = true;
    }

    function setEditingFacebookProductTag({ value }) {
      editingFacebookProductTag.value = value;
    }

    function setSelectedFacebookProductCatalogId({ catalogId }) {
      selectedFacebookProductCatalogId.value = catalogId;
    }

    function setActiveMediaId({ mediaId }) {
      activeMediaId.value = mediaId;
    }

    function setFacebookProductTags({ productTags }) {
      internalTaggedProducts.value = {};
      productTags.forEach(({ mediaId, ...productTag }) => {
        if (!(mediaId in internalTaggedProducts.value)) {
          internalTaggedProducts.value = {
            ...internalTaggedProducts.value,
            [mediaId]: [],
          };
        }
        internalTaggedProducts.value[mediaId].push(productTag);
      });
    }

    function addFacebookProductTag({ mediaId = activeMediaId.value, productTag }) {
      if (!(mediaId in internalTaggedProducts.value)) {
        internalTaggedProducts.value = {
          ...internalTaggedProducts.value,
          [mediaId]: [],
        };
      }
      internalTaggedProducts.value[mediaId].push(productTag);
    }

    function updateFacebookProductTag({
      mediaId = activeMediaId.value,
      index = internalTaggedProducts.value[mediaId].length - 1,
      productTag,
    }) {
      const existingProductTag = internalTaggedProducts.value[mediaId][index];
      internalTaggedProducts.value[mediaId].splice(index, 1, {
        ...existingProductTag,
        ...productTag,
      });
    }

    function deleteFacebookProductTag({
      mediaId = activeMediaId.value,
      index = internalTaggedProducts.value[mediaId].length - 1,
    } = {}) {
      internalTaggedProducts.value[mediaId].splice(index, 1);
    }

    function deleteMediaFacebookProductTags({ mediaId = activeMediaId.value } = {}) {
      const newValue = {
        ...internalTaggedProducts.value,
      };
      delete newValue[mediaId];
      internalTaggedProducts.value = newValue;
    }

    function clearFacebookProductTags() {
      internalTaggedProducts.value = {};
    }

    return {
      checkFacebookProductTaggingEligible,
      getFacebookProductCatalogs,
      disableFacebookProductTagger,
      enableFacebookProductTagger,
      setEditingFacebookProductTag,
      setSelectedFacebookProductCatalogId,
      setActiveMediaId,
      setFacebookProductTags,
      addFacebookProductTag,
      updateFacebookProductTag,
      deleteFacebookProductTag,
      deleteMediaFacebookProductTags,
      clearFacebookProductTags,
      facebookProductTaggingEligible,
      facebookPageId,
      facebookProductCatalogs,
      facebookProductTaggerEnabled,
      editingFacebookProductTag,
      selectedFacebookProductCatalogId,
      activeMediaId,
      taggedProducts,
      activeMediaTaggedProducts,
      pending,
      internalTaggedProducts,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
