/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trophy': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M21 3.646h-3.278A1.998 1.998 0 0016 2.662H8c-.737 0-1.375.398-1.722.984H3c-.553 0-1 .44-1 .985v2.992c0 .902.41 1.743 1.126 2.307l4.179 3.292A6.02 6.02 0 0011 15.372v2.059H9c-.553 0-1 .44-1 .984v1.97H7v1.969h10v-1.97h-1v-1.969a.991.991 0 00-1-.984h-2v-2.058a6.016 6.016 0 003.695-2.152l4.18-3.293A2.919 2.919 0 0022 7.623V4.631a.991.991 0 00-1-.985zm-7 16.739h-4V19.4h4v.985zM4.376 8.392A.973.973 0 014 7.623V5.615h2v3.939c0 .041.006.082.006.123l-1.63-1.285zm7.624 5.1c-2.205 0-4-1.766-4-3.938V4.63h8v4.923c0 2.172-1.795 3.938-4 3.938zm8-5.869c0 .3-.137.582-.375.769l-1.631 1.285c0-.041.006-.082.006-.123V5.615h2v2.008z" _fill="#686a7a" fill-rule="nonzero"/>'
  }
})
