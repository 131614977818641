import { defineStore } from 'pinia';
import * as PdfApi from '@/apis/pdf';

export const usePdfStore = defineStore('pdf', {
  state: () => ({
    instagramReportPending: false,
    instagramFeedReportPending: false,
    instagramStoryNarrativeReportPending: false,
    instagramStoryFramesReportPending: false,
    instagramStoryMetricsReportPending: false,
    instagramStoryOverviewReportPending: false,
    instagramStoryBoardsReportPending: false,
    twitterReportPending: false,
    twitterDashboardReportPending: false,
    pinterestReportPending: false,
    facebookReportPending: false,
    facebookDashboardReportPending: false,
    pinFollowerReportPending: false,
    engagementReportPending: false,
    yourPinsReportPending: false,
    DHBoardReportPending: false,
    DHBoardsReportPending: false,
    galleryReportPending: false,
    trendGroupReportPending: false,
    AdBoardReportPending: false,
    AdBoardsReportPending: false,
    AccountOverviewReportPending: false,
    YourPostsReportPending: false,
    relationshipsOverviewReportPending: false,
    relationshipsDetailReportPending: false,
    dashboardReportPending: false,
    instagramOverviewReportPending: false,
    campaignReportPending: false,
  }),
  actions: {
    receiveReport(type) {
      if (type) this[`${type}ReportPending`] = false;
    },
    async getPdfV2({
      url,
      filename,
      notificationSocketId,
      notificationMeta,
      width,
      height,
      format,
      landscape,
      scale,
      idleTimeout,
      idleMaxInflightRequest,
      useWaitFor,
      margin,
    }) {
      const { type } = JSON.parse(notificationMeta);
      this[`${type}ReportPending`] = true;
      try {
        await PdfApi.requestPdf({
          url,
          filename,
          notificationSocketId,
          notificationMeta,
          width,
          height,
          format,
          landscape,
          scale,
          idleTimeout,
          idleMaxInflightRequest,
          useWaitFor,
          margin,
        });
      } catch (err) {
        this[`${type}ReportPending`] = false;
        throw err;
      }
    },
  },
});
