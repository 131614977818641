<template>
  <div :class="item.extraClass" class="dropdown-item">
    <router-link
      v-if="item.route"
      v-tooltip="item.tooltip ? item.tooltip : null"
      :to="item.disabled ? null : item.route"
      class="list-item-container"
      @click="$emit('click')"
    >
      <li :class="{ disabled: item.disabled }">
        <div class="list-text-wrapper">
          <Icon v-if="item.iconName" :name="item.iconName" small />
          <span v-if="item.text">
            <span v-if="item.indent" class="list-text-indent">&nbsp;</span>
            {{ item.text }}
          </span>
          <Icon
            v-if="item.check"
            :color="checkColor"
            name="check"
            small
            style="margin-left: 1rem"
          />
          <div class="flex-spacer"></div>
          <Icon v-if="!!item.subList" name="caret" small />
        </div>
        <hr v-if="item.hr" />
      </li>
    </router-link>
    <div
      v-else
      v-tooltip="item.tooltip ? item.tooltip : null"
      :class="[
        'list-item-container',
        { disabled: item.disabled, header: item.header, divider: item.divider },
      ]"
      @click="handleAction"
    >
      <div class="list-text-wrapper">
        <Icon
          v-if="item.iconName && item.iconPosition !== 'right'"
          v-tooltip="item.iconTooltip"
          :name="item.iconName"
          :style="{ fill: item.itemColor }"
          xsmall
        />
        <img v-if="item.optionImage" :src="item.optionImage" class="dropdown-img" />
        <span v-if="item.text" :style="{ color: item.textColor, fontSize: item.textSize }">
          <span v-if="item.indent" class="list-text-indent">&nbsp;</span>
          {{ item.text }}
        </span>
        <Icon
          v-if="item.iconName && item.iconPosition === 'right'"
          v-tooltip="item.iconTooltip"
          :name="item.iconName"
          xsmall
          style="margin-left: 0.5rem"
        />
        <Icon v-if="item.check" :color="checkColor" name="check" small style="margin-left: 1rem" />
        <div class="flex-spacer"></div>
        <Icon v-if="!!item.subList" name="caret" xsmall />
      </div>
      <hr v-if="item.hr" />
    </div>
    <div :class="['sub-list', subListHorizontalPos, subListVerticalPos, scrollablePopup]">
      <div v-if="item.subList && !item.disabled">
        <dropdown-button-item
          v-for="(subItem, index) in item.subList"
          :key="index"
          :item="subItem"
          @action="(action, params) => $emit('action', action, params)"
          @click="$emit('click')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'DropdownButtonItem',
  components: {
    Icon,
  },
  props: {
    scrollable: { type: Boolean, default: false },
    item: { type: Object, required: true },
  },
  emits: ['action', 'click'],
  data() {
    return {
      showSubList: false,
    };
  },
  computed: {
    checkColor() {
      return colours.ACTION.ACTION_500;
    },
    scrollablePopup() {
      return this.scrollable ? 'scrollable' : '';
    },
    subListHorizontalPos() {
      const options = ['left', 'right'];
      const { subListPosition } = this.item;
      if (options.includes(subListPosition)) {
        return subListPosition;
      }

      // By default, the sub list is displayed in the right side.
      return 'right';
    },
    subListVerticalPos() {
      const options = ['top', 'bottom'];
      const { subListVerticalPosition } = this.item;
      if (options.includes(subListVerticalPosition)) {
        return subListVerticalPosition;
      }

      // By default, the sub list is displayed on top.
      return 'top';
    },
  },
  methods: {
    handleAction() {
      if (this.item.action && !this.item.disabled && !this.item.header) {
        this.$emit('action', this.item.action, this.item.actionParams);
      }
      this.$emit('click');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.list-item-container {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: var(--space-8) var(--space-24);

  &:hover {
    background: var(--background-300);
  }

  &.divider {
    border-top: 1px solid var(--border);
  }
}

.list-text-wrapper {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-weight: var(--font-medium);
  font-size: var(--x14);

  .list-text-indent {
    width: var(--space-8);
  }

  .svg-icon {
    margin-right: var(--space-8);
  }

  .svg-icon:last-child {
    margin-left: var(--space-16);
    margin-right: 0;
  }

  .flex-spacer {
    flex: 1;
  }
}

.header {
  .list-text-wrapper {
    color: var(--text-secondary);
    font-weight: var(--font-medium);
    font-size: var(--x14);
    pointer-events: none;
    list-style: none;
  }

  &:hover {
    background: none !important;
  }
}

.disabled .list-text-wrapper {
  cursor: default;
}

button.semi-circle {
  position: relative;
  height: 2.5rem;
  border: none;
  border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
  padding: 0 var(--space-16) 0 var(--space-12);
  background: var(--action-500);

  :deep(svg) {
    opacity: 0.7 !important;
  }

  &:hover {
    transform: none;
    box-shadow: none;

    :deep(svg) {
      opacity: 1 !important;
    }
  }

  &::after {
    content: '';
    height: 1.62rem;
    width: 0.062rem;
    position: absolute;
    left: 0;
    top: 0.44rem;
    background-color: var(--background-0);
    opacity: 0.7;
  }
}

.dropdown-item {
  position: relative;
  cursor: pointer;

  .dropdown-img {
    width: 16px;
    height: 16px;
    margin-right: var(--space-4);
  }

  .sub-list {
    position: absolute;
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-4);
    margin-top: var(--space-8);
    z-index: var(--z-index-dropdown);
    background: var(--background-0);
    width: max-content;
    padding: var(--space-8) 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms var(--ease-in-out-quad);

    &.scrollable {
      overflow-y: auto;
      max-height: var(--height-400);
    }

    &.left {
      right: calc(100% - var(--space-16));
    }

    &.right {
      left: calc(100% - var(--space-16));
    }

    &.top {
      bottom: calc(-1 * var(--space-8));
    }

    &.bottom {
      margin-top: calc(-1 * var(--space-46));
    }
  }

  &:hover > .sub-list,
  .sub-list:hover {
    display: block;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.dropdown-item.danger .list-text-wrapper {
  color: var(--error-500);
}

.dropdown-item.warn .list-text-wrapper {
  color: var(--alert-500);
}
</style>
