/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'boost': {
    width: 14,
    height: 17,
    viewBox: '0 0 14 17',
    data: '<defs><path pid="0" id="svgicon_boost_a" d="M8.832 6.937v11.397h2.336V6.937l3.847 3.816 1.652-1.638L10 2.5 3.333 9.115l1.652 1.638z"/></defs><g transform="translate(-3 -2)" _fill="none" fill-rule="evenodd"><mask id="svgicon_boost_b" _fill="#fff"><use xlink:href="#svgicon_boost_a"/></mask><use _fill="#27C449" fill-rule="nonzero" xlink:href="#svgicon_boost_a"/><g mask="url(#svgicon_boost_b)" _fill="#27C449" fill-rule="nonzero"><path pid="1" d="M0 0h20v20H0z"/></g></g>'
  }
})
