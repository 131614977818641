export const TWITTER_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'TOTAL_ENGAGEMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'REPLIES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'RETWEETS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'QUOTE_TWEETS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'LIKES',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      name: 'Tweet Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
