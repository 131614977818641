<script setup>
import { ref } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const toggle = ref(null);

const emit = defineEmits('toggled');

function handleToggle(option) {
  emit('toggled', option);
}

function updateFilter(option) {
  toggle.value.switcherOn = option === 'on';
}

defineExpose({
  handleToggle,
  updateFilter,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <main class="flex items-center justify-between px-6 pt-6">
    <span class="text-secondary flex items-center justify-center">
      <Icon name="layers" small class="mr-6" />
      <div class="whitespace-nowrap">Show Duplicate Media</div>
      <InfoTooltip
        tooltip="When toggled on, all duplicate uploaded media will appear in your Library. Duplicate media is combined into a single item when toggled off."
      />
    </span>
    <ToggleSwitch ref="toggle" class="mr-[--space-40]" @on-change="handleToggle" />
  </main>
</template>
