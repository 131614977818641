<template>
  <div
    v-if="predictionRatings"
    class="prediction"
    @mouseover="mouseOver = true"
    @mouseout="mouseOver = false"
  >
    <!--  Full Width Prediction  -->
    <div v-if="fullWidth" class="prediction-full-width">
      <div class="prediction-full-width-icon">
        <Icon :name="rating" :color="predictionRatings.color" xxsmall />
      </div>
      <div class="prediction-full-width-text">
        <div class="prediction-full-width-label">
          {{ label }}
        </div>
        <div class="prediction-full-width-description">
          {{ predictionRatings.tooltip }} with this prediction model.
        </div>
      </div>
    </div>
    <!--  2+ Predictions  -->
    <div v-else class="prediction-many">
      <!-- Tooltip -->
      <div v-if="mouseOver" class="prediction-tooltip">
        <div class="prediction-tooltip-header">
          <div class="prediction-tooltip-header-text">
            <div class="prediction-tooltip-vision-text">Vision Prediction Model</div>
            <div>
              {{ label }}
            </div>
          </div>
          <div class="prediction-tooltip-icon">
            <Icon :name="rating" :color="predictionRatings.color" small />
          </div>
        </div>
        <div class="prediction-tooltip-description">{{ predictionRatings.tooltip }}.</div>
      </div>
      <!-- Prediction -->
      <div v-show="primary" class="primary-bubble">Primary</div>
      <div>
        <div class="prediction-label">{{ label }}</div>
        <Icon :name="rating" :color="predictionRatings.color" xxsmall />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { PREDICTION_RATINGS } from '@/config';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Prediction',
  components: {
    Icon,
  },
  props: {
    rating: { type: String, default: null },
    primary: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    label: { type: String, default: '' },
  },
  data() {
    return {
      ...{ PREDICTION_RATINGS },
      mouseOver: false,
    };
  },
  computed: {
    predictionRatings() {
      return this.PREDICTION_RATINGS[this.rating];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.prediction {
  float: left;
  background-color: var(--background-0);
  border-radius: var(--space-4);
  justify-content: center;
  width: 100%;
  margin: var(--space-4);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .prediction-full-width {
    display: flex;
    text-align: left;
    padding: var(--space-12);

    .prediction-full-width-icon {
      float: left;
      width: var(--space-32);
      height: var(--space-16);
      margin: var(--space-12) var(--space-8);
      line-height: var(--space-16);
    }

    .prediction-full-width-label {
      text-transform: capitalize;
      line-height: var(--space-16);
    }

    .prediction-full-width-description {
      font-size: var(--x12);
      color: var(--text-secondary);
      padding-top: var(--space-4);
    }
  }

  .prediction-many {
    display: flex;
    justify-content: center;
    padding: var(--space-12);

    .prediction-label {
      font-size: var(--x12);
      line-height: var(--x16);
      text-transform: capitalize;
      width: 100%;
    }

    .primary-bubble {
      font-size: var(--x10);
      background-color: var(--text-secondary);
      color: var(--white);
      border-radius: var(--space-12);
      width: var(--space-56);
      margin: auto;
      margin-top: -1.2rem;
      height: var(--space-16);
      line-height: var(--x14);
      position: absolute;
    }

    .prediction-tooltip {
      position: absolute;
      transform: translateY(-100%) translateY(calc(-1 * var(--space-24)));
      margin-left: auto;
      margin-right: auto;
      border-radius: 0.5rem;
      background-color: var(--background-0);
      width: 288px;
      box-shadow: var(--shadow-2);
      padding: var(--space-16) var(--space-20);
      z-index: var(--z-index-tooltip);

      .prediction-tooltip-icon {
        padding-top: var(--space-12);
        float: right;
        padding-right: var(--space-12);
      }

      .prediction-tooltip-header {
        font-size: var(--x16);
        height: var(--space-48);
        text-transform: capitalize;
        margin-bottom: var(--space-4);

        .prediction-tooltip-header-text {
          float: left;
          text-align: left;

          .prediction-tooltip-vision-text {
            font-size: var(--x12);
            color: var(--text-secondary);
          }
        }
      }

      .prediction-tooltip-description {
        text-align: left;
        font-size: var(--x14);
      }
    }
  }
}
</style>
