/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'replay': {
    width: 76,
    height: 76,
    viewBox: '0 0 76 76',
    data: '<path pid="0" d="M64.478 11.516C57.676 4.719 48.342.5 37.977.5 17.245.5.5 17.281.5 38s16.745 37.5 37.477 37.5c17.495 0 32.082-11.953 36.257-28.125h-9.756c-3.847 10.922-14.26 18.75-26.501 18.75C22.45 66.125 9.834 53.515 9.834 38c0-15.516 12.617-28.125 28.143-28.125 7.786 0 14.728 3.234 19.793 8.344L42.667 33.312H75.5V.5L64.478 11.516z" _fill="#fff"/>'
  }
})
