import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.pdfApiUrl);

export async function requestPdf({
  url,
  filename,
  notificationSocketId,
  notificationMeta,
  width,
  height,
  format,
  landscape,
  scale,
  idleTimeout,
  idleMaxInflightRequest,
  useWaitFor,
  margin,
}) {
  return axios.post(`pdfs`, {
    url,
    filename,
    notificationSocketId,
    notificationMeta,
    width,
    height,
    format,
    landscape,
    scale,
    idleTimeout,
    idleMaxInflightRequest,
    useWaitFor,
    margin,
    delay: 15,
  });
}
