<script setup>
import { computed, ref } from 'vue';
import TikTokAdsAccountsPopup from '@/app/settings/components/TikTokAdsAccountsPopup.vue';
import { useRouter } from 'vue-router';
import ConnectionSuccess from './ConnectionSuccess.vue';

const router = useRouter();

const brand = computed(() => {
  return { id: router.currentRoute.value.query.brandId };
});
const showPopup = ref(true);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <ConnectionSuccess>
    <template #connectionSuccessTitle>
      <p>Your TikTok Ads account has been connected to Dash Hudson.</p>
    </template>
    <template v-if="showPopup" #popup>
      <TikTokAdsAccountsPopup :brand="brand" @close="showPopup = false" />
    </template>
  </ConnectionSuccess>
</template>
