/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'heart-solid': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<defs><path pid="0" d="M9 16a.998.998 0 01-.529-.152C8.124 15.632 0 10.491 0 5c0-2.757 2.243-5 5-5 1.594 0 3.07.837 4 2.08C9.93.837 11.406 0 13 0c2.757 0 5 2.243 5 5 0 5.491-8.124 10.632-8.471 10.848A.998.998 0 019 16z" id="svgicon_heart-solid_a"/></defs><g transform="translate(0 .001)" _fill="none" fill-rule="evenodd"><mask id="svgicon_heart-solid_b" _fill="#fff"><use xlink:href="#svgicon_heart-solid_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_heart-solid_a"/><g mask="url(#svgicon_heart-solid_b)"><path pid="1" _fill="#686a7a" fill-rule="nonzero" d="M-2-4.001h22.957v24H-2z"/></g></g>'
  }
})
