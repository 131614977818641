import isString from 'lodash/isString';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

export function getEnumValue(type) {
  return isString(type) ? type.toUpperCase() : type?.value;
}

export function getEnumText(type) {
  const key = getEnumValue(type);
  return startCase(camelCase(key)) || key;
}

export function extractEnumValue(type) {
  return type?.value ?? type;
}
