/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pointCircle': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g clip-path="url(#clip0_537_9284)" _fill="#686a7a"><path pid="0" d="M11.908 11.084l-1.579-1.58 1.131-1.13c.113-.114.08-.239-.076-.278L7.283 7.071c-.156-.039-.251.057-.212.212l1.025 4.1c.039.156.164.19.277.077l1.131-1.132 1.58 1.581.824-.825z"/><path pid="1" d="M5.833 3.501a2.336 2.336 0 012.334 2.333h1.167c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5 1.57 3.5 3.5 3.5V8.168A2.336 2.336 0 013.5 5.834a2.336 2.336 0 012.333-2.333z"/></g><defs><clipPath id="clip0_537_9284"><path pid="2" _fill="#fff" d="M0 0h14v14H0z"/></clipPath></defs>'
  }
})
