<template>
  <div :class="['badge', color]">
    {{ text }}
  </div>
</template>

<script>
/**
 * WARNING: Consider using the Chip component instead, it's more widely used and has more features.
 * This will likely be deprecated.
 */
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Badge',
  props: {
    text: { type: String, default: '' },
    color: { type: String, default: null },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.badge {
  height: var(--space-16);
  line-height: var(--space-14);
  padding: 0 var(--space-8);
  font-size: var(--x10);
  font-weight: var(--font-medium);
  text-align: center;
  border-radius: var(--space-8);
  display: flex;
  align-items: center;
}

.grey {
  background-color: var(--text-secondary);
  color: var(--white);
}
</style>
