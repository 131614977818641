import { PRODUCT_MAP } from '@/models/auth/customer-plan.enum';

export const TRIAL_BOOK_TRAINING_URL =
  'https://dashhudson.na.chilipiper.com/book/in-app-trial-training';

export const TRIAL_PRODUCT_DETAILS = Object.freeze({
  [PRODUCT_MAP.ENGAGE]: {
    actionList: [
      { text: 'Engage with my community', trialRoute: '/community/inbox' },
      { text: 'Segment and analyze content with Boards', trialRoute: '/instagram/boards' },
      { text: 'Plan and organize assets in Library', trialRoute: '/library/discover' },
    ],
    imagePath: 'inAppTrials/engage.png',
  },
  [PRODUCT_MAP.ADVANCE]: {
    actionList: [
      { text: 'Build custom reporting dashboards', trialRoute: '/dashboards' },
      {
        text: 'Use AI to predict performance of content',
        trialRoute: '/library/discover?sort=performance&content_tags=',
      },
      { text: 'Discover top UGC', trialRoute: '/instagram/ugc/reach' },
    ],
    imagePath: 'inAppTrials/advance.png',
  },
  [PRODUCT_MAP.SOCIAL_LISTENING]: {
    actionList: [
      { text: 'Uncover trends with a listening topic', trialRoute: '/listening' },
      {
        text: 'Understand sentiment of my brand',
        trialRoute: '/dashboards',
      },
      { text: 'Analyze sentiment of campaign', trialRoute: '/campaigns' },
    ],
    imagePath: 'inAppTrials/socialListening.png',
  },
  [PRODUCT_MAP.PREMIUM_ANALYTICS]: {
    actionList: [
      { text: 'Benchmark against my competitors', trialRoute: '/competitors/instagram/overview' },
      {
        text: 'Discover or measure creator performance',
        trialRoute: '/relationships/instagram/discovery',
      },
      { text: 'Measure cross-channel campaigns', trialRoute: '/campaigns' },
    ],
    imagePath: 'inAppTrials/premiumAnalytics.png',
  },
});
