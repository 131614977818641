import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { io } from 'socket.io-client';
import startCase from 'lodash/startCase';
import { env } from '@/env';
import { autoPublishErrors, PLATFORMS, postStatus } from '@/app/scheduler/constants';
import {
  getFbAutopublishNotification,
  getIgAutopublishNotification,
  getPinAutopublishNotification,
  getTikTokAutopublishNotification,
  getTwitterAutopublishNotification,
  getLinkedInAutopublishNotification,
} from '@/utils/autopublishNotifications';
import { downloadFileFromUrl } from '@/utils';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { useAuthStore } from '@/stores/auth';
import { USER } from '@/models/auth/permissions.enum';
import { useNotificationStore } from '@/stores/notification';
import { usePdfStore } from '@/stores/pdf';
import { useCsvStore } from '@/stores/csv';
import { useFacebookStore } from '@/stores/facebook';
import { useDashboardsStore } from '@/stores/dashboards';
import { useCampaignsStore } from '@/stores/campaigns';
import { usePlatformStore } from '@/stores/platform';
import { useSchedulerStore } from '@/stores/scheduler';
import { useSocialListeningStore } from '@/stores/social-listening';
import { logger } from '@/utils/logger';
import { usePinterestExportStore } from '@/stores/pinterest-export';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import { useImpersonatorStore } from '@/stores/impersonator';

const SOCKETIO_EVENT = {
  CONNECT: 'connect',
  RECONNECT: 'reconnect',
};

export const EVENT = {
  REGISTER: 'register',
  REGISTER_USER: 'register_user',
  JOIN_BRAND_ROOMS: 'join_brand_rooms',
  NOTIFICATION: 'notification',
  PDF_GENERATED: 'pdf_generated',
  CSV_GENERATED: 'csv_generated',
  XLSX_GENERATED: 'xlsx_generated',
  SCHEDULED_POST_CALLBACK: 'scheduled_post_callback',
  SCHEDULED_POST_UPDATED: 'scheduled_post_updated',
  CLUSTER_TASK_NOTIFICATION: 'CLUSTER_TASK_NOTIFICATION',
};

export const useSocketStore = defineStore('socket', () => {
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const pdfStore = usePdfStore();
  const csvStore = useCsvStore();
  const facebookStore = useFacebookStore();
  const dashboardsStore = useDashboardsStore();
  const campaignsStore = useCampaignsStore();
  const platformStore = usePlatformStore();
  const schedulerStore = useSchedulerStore();
  const pinterestExportStore = usePinterestExportStore();
  const socialListeningStore = useSocialListeningStore();
  const schedulerPinterestStore = useSchedulerPinterestStore();
  const impersonatorStore = useImpersonatorStore();

  let socket = null;
  const id = ref();

  function onNotification(data) {
    notificationStore.setToast({
      message: data.message,
      type: data.level,
    });
  }

  async function onPdfGenerated(data) {
    const meta = data?.meta ? JSON.parse(data.meta) : undefined;
    if (meta?.type) {
      pdfStore.receiveReport(meta.type);
    }
    if (data?.pdf_failed) {
      const type = meta?.type || '';
      notificationStore.setToast({
        message: `Failed to generate ${startCase(type)} PDF, please try again later.`,
        type: 'error',
      });
    } else if (data?.url) {
      downloadFileFromUrl(data.url);
    }
  }

  async function onCsvGenerated(data) {
    const { type } = data;
    // Todo: refactor Pinterest to use csvReceived as well
    // Todo: this if else will be removed when Pinterest is refactored
    // Todo: Pinterest boards csv still needs to be refactored before this can be removed
    // stop the spinner
    const platformCsvList = [
      'community',
      'email',
      'facebook',
      'gallery',
      'instagram',
      'linkedin',
      'pinterest',
      'relationship',
      'tiktok',
      'twitter',
      'youtube',
    ];

    if (platformCsvList.some((platform) => type.toLowerCase().startsWith(platform))) {
      csvStore.csvReceived({ type });
    } else if (type.startsWith('posts')) {
      facebookStore.receivedCSV();
    } else if (type.startsWith('auth')) {
      authStore.receivedCSV();
    } else {
      pinterestExportStore.receiveCSV({ type });
    }
    downloadFileFromUrl(data.url);
  }

  async function onXlsxGenerated(data) {
    const { type } = data;
    if (type.startsWith('dashboard')) {
      dashboardsStore.setDownloadingSpreadsheetExport(false);
    }
    if (type.startsWith('campaign')) {
      campaignsStore.setDownloadingSpreadsheet(false);
    }
    if (data?.error) {
      notificationStore.setToast({
        message: `Failed to generate ${startCase(type)} spreadsheet, please try again later.`,
        type: 'error',
      });
    } else if (data?.url) {
      downloadFileFromUrl(data.url);
    }
  }

  async function onScheduledPostCallback(data) {
    if (!authStore.identity) {
      return;
    }

    // Web doesnt support toast for reminders of non-autopublish scheduled posts.
    // Ignore these callbacks for now
    if (data.post_status) {
      return;
    }
    const { platform, post, result } = data;

    const postBrand = Object.values(authStore.identity.brands).find((b) => b.id === post.brand_id);
    if (
      post.error &&
      (post.error === autoPublishErrors.MISSING_AUTH ||
        post.error === autoPublishErrors.INVALID_AUTH)
    ) {
      platformStore.getPlatformConnections();
    }

    schedulerStore.replacePost({ ...post, platform });

    if (platform === 'instagram') {
      const notification = getIgAutopublishNotification(data, postBrand.name);
      notificationStore.setToast(notification);
    }

    if (platform === 'pinterest') {
      const pinterestAccount = platformStore.pinterestAccount;
      let pinterestUsername = pinterestAccount && pinterestAccount.pinterestUsername;
      if (!pinterestAccount || pinterestAccount.brandId !== postBrand.id) {
        await schedulerPinterestStore.getPinterestAccounts({ brandIds: [postBrand.id.toString()] });
        const pinterestAccounts = schedulerPinterestStore.pinterestAccounts;
        pinterestUsername = pinterestAccounts[0].pinterest_username;
        schedulerPinterestStore.clearPinterestAccounts();
      }

      schedulerStore.updatePendingPostStatus({ id: post.id, result });
      const publishingPinterestPosts = allPosts
        .forBrand(authStore.currentBrand?.id)
        .forPlatform(PLATFORMS.PINTEREST)
        .withStatuses([postStatus.AUTOPUBLISHING]);
      if (schedulerStore.getPosts(publishingPinterestPosts).length === 0) {
        const pendingPostDict = schedulerStore.pendingPostDict;
        const successCount = Object.values(pendingPostDict).filter(
          (status) => status === 'SUCCESS',
        ).length;
        const failCount = Object.values(pendingPostDict).filter(
          (status) => status === 'FAILURE',
        ).length;
        schedulerStore.clearPendingPostDict();
        if (successCount > 0) {
          const notification = getPinAutopublishNotification(
            pinterestUsername,
            data,
            successCount,
            0,
          );
          notificationStore.setToast(notification);
        }
        // TODO: When support for multiple toasts at the same time is added, this timeout
        // can be removed.
        setTimeout(() => {
          if (failCount > 0) {
            const notification = getPinAutopublishNotification(
              pinterestUsername,
              data,
              0,
              failCount,
            );
            notificationStore.setToast(notification);
          }
        }, 5000);
      }
    }

    if (platform === 'facebook') {
      const notification = getFbAutopublishNotification(data, postBrand.name);
      notificationStore.setToast(notification);
    }

    if (platform === 'twitter') {
      const notification = getTwitterAutopublishNotification(data);
      notificationStore.setToast(notification);
    }

    if (platform === 'tiktok') {
      const notification = getTikTokAutopublishNotification(data, postBrand.name);
      notificationStore.setToast(notification);
    }

    if (platform === 'linkedin') {
      const notification = getLinkedInAutopublishNotification(data, postBrand.name);
      notificationStore.setToast(notification);
    }
  }

  async function onScheduledPostUpdated(data) {
    const { platform, post, result } = data;

    if (!post || !authStore.currentBrand?.id) {
      return;
    }

    if (post.brand_id !== authStore.currentBrand.id) {
      return;
    }

    if (result === 'UPDATED' || result === 'CREATED') {
      // Re-fetch via the API instead of using the websocket's data for Instagram updates.
      // This solves a problem with fields (user/product tags) that use separate tables, which
      // causes inconsistent behaviour when the ORM hooks are triggered and the websockets sent.
      if (platform === 'instagram' && result === 'UPDATED') {
        await schedulerStore.fetchPost({ platform, ...post });
      } else {
        schedulerStore.replacePost({ platform, ...post });
      }
    } else if (result === 'DELETED') {
      schedulerStore.removePost({ platform, ...post });
    }
  }

  function registerGlobalListeners() {
    socket.on(EVENT.NOTIFICATION, onNotification);
    socket.on(EVENT.PDF_GENERATED, onPdfGenerated);
    socket.on(EVENT.CSV_GENERATED, onCsvGenerated);
    socket.on(EVENT.XLSX_GENERATED, onXlsxGenerated);
    socket.on(EVENT.SCHEDULED_POST_CALLBACK, onScheduledPostCallback);
    socket.on(EVENT.SCHEDULED_POST_UPDATED, onScheduledPostUpdated);
    socket.on(EVENT.CLUSTER_TASK_NOTIFICATION, socialListeningStore.onClusterTaskNotification);
  }

  function registerIdentity() {
    const isLoggedIn = authStore.isLoggedIn;
    const hasToken = !!authStore.token;
    const notImpersonating = !impersonatorStore.isImpersonating;

    if (isLoggedIn && hasToken && socket?.connected) {
      socket.emit(EVENT.REGISTER, authStore.token);

      const canAccessCommunity = authStore.guard(USER.COMMUNITY.CAN_ACCESS_COMMUNITY);
      const canAccessScheduler = authStore.guard(USER.SCHEDULER.CAN_ACCESS_SCHEDULER);
      if (notImpersonating && (canAccessScheduler || canAccessCommunity)) {
        socket.emit(EVENT.JOIN_BRAND_ROOMS, authStore.token);
      }
    }
  }

  function disconnect() {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  }

  function init() {
    if (socket?.connected) {
      disconnect();
    }

    const extraHeaders = {};
    if (impersonatorStore.isImpersonating) {
      extraHeaders['X-On-Behalf-Of'] = impersonatorStore.impersonatedUserId;
    }

    socket = io(env.notificationServiceUrl, { extraHeaders });
    socket.on(SOCKETIO_EVENT.CONNECT, () => {
      id.value = socket.id;
      registerIdentity();
    });
    socket.on(SOCKETIO_EVENT.RECONNECT, () => {
      id.value = socket.id;
      registerIdentity();
    });
    socket.on('disconnect', (reason, details) => {
      logger.debug(`socket io disconnected, reason: ${reason}`);
      if (details) {
        logger.debug(
          `socket io disconnected, details:
          message=${details.message}
          description=${details.description}
          context=${details.context}`,
        );
      }
      if (reason === 'io server disconnect') {
        logger.warn('socket io server disconnected. Trying to reconnect.');
        // the disconnection was initiated by the server, reconnect manually
        socket.connect();
      }
    });
    registerGlobalListeners();
  }

  function addListener(event, callback, context) {
    if (socket) {
      socket.on(event, (payload) => {
        callback.call(context, payload);
      });
    }
  }

  function removeListener(event, callback, context) {
    if (socket) {
      socket.off(event, (payload) => {
        callback.call(context, payload);
      });
    }
  }

  watch(
    () => authStore.isLoggedIn,
    (newIsLoggedIn, oldIsLoggedIn) => {
      if (newIsLoggedIn === oldIsLoggedIn) {
        return;
      }
      if (newIsLoggedIn) {
        init();
      } else {
        disconnect();
      }
    },
    { immediate: true },
  );

  return {
    id,
    init,
    addListener,
    removeListener,
    registerIdentity,
    onPdfGenerated,
    onNotification,
    onCsvGenerated,
    onXlsxGenerated,
    onScheduledPostUpdated,
  };
});
