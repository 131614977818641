/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 18.001H7a1 1 0 01-1-1v-14a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zm-13-2h12v-12H8v12zm-5 6h15v-2H4v-14H2v15a1 1 0 001 1zm10-15a2 2 0 11-4 0 2 2 0 014 0zm.412 5.882L16 9.001l3 6H9l3-4 1.412 1.882z" _fill="#fff"/><mask id="svgicon_image_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21 18.001H7a1 1 0 01-1-1v-14a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zm-13-2h12v-12H8v12zm-5 6h15v-2H4v-14H2v15a1 1 0 001 1zm10-15a2 2 0 11-4 0 2 2 0 014 0zm.412 5.882L16 9.001l3 6H9l3-4 1.412 1.882z" _fill="#fff"/></mask><g mask="url(#svgicon_image_a)"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></g>'
  }
})
