import { useCommunityVisionAi } from '@/app/community/composables/useCommunityVisionAi';
import { useListeningVisionAi } from '@/app/socialListening/composables/useListeningVisionAi';
import { useDashboardsVisionAi } from '@/app/dashboards/composables/useDashboardsVisionAi';
import {
  COMMUNITY_VISION_AI_TOOLTIPS,
  DASHBOARD_SUMMARIZE_BUTTON_TOOLTIP,
  DASHBOARD_SUMMARY_NO_DASHBOARDS_MESSAGE,
  INTENT_TYPES,
  SOCIAL_LISTENING_VISION_AI_TOOLTIPS,
  FORMATTED_DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE,
  DEFAULT_METRIC_AND_ANALYTICS_PROMPTS_TOOLTIP,
} from '@/components/VisionAi/constants';

export function useIntentMap() {
  const { hasValidCommentsThreshold, hasValidDmsThreshold, isCommunityEmptyState } =
    useCommunityVisionAi();
  const {
    hasValidSocialListeningTrendsThreshold,
    hasValidSocialListeningTopicsThreshold,
    isListeningEmptyState,
  } = useListeningVisionAi();
  const { hasValidReportsThreshold, hasNoDashboards } = useDashboardsVisionAi();

  function checkIfInvalidThreshold(intentType) {
    switch (intentType) {
      case INTENT_TYPES.COMMENTS:
        return !hasValidCommentsThreshold.value;
      case INTENT_TYPES.DMS:
        return !hasValidDmsThreshold.value;
      case INTENT_TYPES.TOPICS:
        return !hasValidSocialListeningTopicsThreshold.value;
      case INTENT_TYPES.TRENDS:
        return !hasValidSocialListeningTrendsThreshold.value;
      case INTENT_TYPES.DASHBOARD_REPORTS:
        return hasNoDashboards.value || !hasValidReportsThreshold.value;
      default:
        return true;
    }
  }

  function defaultTooltip() {
    if (isCommunityEmptyState.value) {
      return COMMUNITY_VISION_AI_TOOLTIPS.EMPTY_STATE.DISABLED;
    }

    if (isListeningEmptyState.value) {
      return SOCIAL_LISTENING_VISION_AI_TOOLTIPS.EMPTY_STATE.DISABLED;
    }
    return null;
  }

  function getTooltip(intentType) {
    switch (intentType) {
      case INTENT_TYPES.COMMENTS:
        return COMMUNITY_VISION_AI_TOOLTIPS.COMMENTS.ENABLED;
      case INTENT_TYPES.DMS:
        return COMMUNITY_VISION_AI_TOOLTIPS.DMS.ENABLED;
      case INTENT_TYPES.TOPICS:
        return SOCIAL_LISTENING_VISION_AI_TOOLTIPS.TOPICS.ENABLED;
      case INTENT_TYPES.TRENDS:
        return SOCIAL_LISTENING_VISION_AI_TOOLTIPS.TRENDS.ENABLED;
      case INTENT_TYPES.DASHBOARD_REPORTS:
        return DASHBOARD_SUMMARIZE_BUTTON_TOOLTIP;
      case INTENT_TYPES.METRIC_ANALYTICS:
        return DEFAULT_METRIC_AND_ANALYTICS_PROMPTS_TOOLTIP;
      default:
        return null;
    }
  }

  function getWarningMessage(intentType) {
    switch (intentType) {
      case INTENT_TYPES.COMMENTS:
        return COMMUNITY_VISION_AI_TOOLTIPS.COMMENTS.DISABLED;
      case INTENT_TYPES.DMS:
        return COMMUNITY_VISION_AI_TOOLTIPS.DMS.DISABLED;
      case INTENT_TYPES.TOPICS:
        return SOCIAL_LISTENING_VISION_AI_TOOLTIPS.TOPICS.DISABLED;
      case INTENT_TYPES.TRENDS:
        return SOCIAL_LISTENING_VISION_AI_TOOLTIPS.TRENDS.DISABLED;
      case INTENT_TYPES.DASHBOARD_REPORTS:
        if (hasNoDashboards.value) {
          return DASHBOARD_SUMMARY_NO_DASHBOARDS_MESSAGE;
        }
        return FORMATTED_DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE;

      default:
        return defaultTooltip();
    }
  }

  return {
    checkIfInvalidThreshold,
    getTooltip,
    getWarningMessage,
  };
}
