/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add-grey-text-color': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" d="M18 8.001h-8v-8H8v8H0v2h8v8h2v-8h8z" _fill="#666" fill-rule="nonzero"/>'
  }
})
