import { REPORTS } from '@/app/dashboards/utils/reports.enum';
import { CHANNELS } from '@/models/dashboards/channels.enum';

export const AGGREGATE_REPORT_TYPES = Object.freeze({
  BRAND: {
    value: 'BRAND',
    text: 'by Brand',
    icon: 'user',
  },
  CHANNEL: {
    value: 'CHANNEL',
    text: 'by Channel',
    icon: 'feed',
  },
  TAG: {
    value: 'TAG',
    text: 'by Tag',
    icon: 'people-user',
  },
});

export const METRIC_DISPLAY_OPTIONS = Object.freeze({
  TOTAL: {
    value: 'TOTAL',
    text: 'Aggregate Total',
    icon: 'lineItems',
  },
  TABLE: {
    value: 'TABLE',
    text: 'Table',
    icon: 'table',
  },
});

export const ADS_METRIC_DISPLAY_OPTIONS = Object.freeze({
  ADS_TOTAL_METRIC: {
    value: REPORTS.ADS_TOTAL_METRIC.value,
    text: 'Aggregate Total',
    icon: 'lineItems',
  },
  ADS_TOTAL_GROUPED_METRIC: {
    value: REPORTS.ADS_TOTAL_GROUPED_METRIC.value,
    text: 'Table',
    icon: 'table',
  },
});

export const ADS_REPORT_AGGREGATE_BY_TYPES = Object.freeze({
  BRAND: {
    value: 'BRAND',
    text: 'by Brand',
    formattedValue: 'Brand',
  },
  TOTAL: {
    value: 'TOTAL',
    text: 'Aggregate Total',
  },
  SOURCE_CAMPAIGN: {
    value: 'SOURCE_CAMPAIGN',
    text: 'by Campaign',
    formattedValue: 'Campaign',
    genericLabels: ['Campaign 1', 'Campaign 2', 'Campaign 3'],
  },
  PUBLISHER_PLATFORM: {
    value: 'PUBLISHER_PLATFORM',
    text: 'by Channel',
    formattedValue: 'Channel',
    genericLabels: ['Facebook', 'Instagram', 'Messenger', 'Meta Audience Network'],
  },
  AGE_GENDER: {
    value: 'AGE_GENDER',
    text: 'by Age and Gender',
    formattedValue: 'Age and Gender',
    genericLabels: ['Men', 'Women', 'Undefined'],
  },
  COUNTRY: {
    value: 'COUNTRY',
    text: 'by Country',
    formattedValue: 'Country',
    genericLabels: ['Country 1', 'Country 2', 'Country 3'],
  },
  REGION: {
    value: 'REGION',
    text: 'by Region',
    formattedValue: 'Region',
    genericLabels: ['Region 1', 'Region 2', 'Region 3'],
  },
});

export const ADS_CHANNEL_TO_AGGREGATE_BY_TYPES = Object.freeze({
  [CHANNELS.META_ADS.value]: [
    ADS_REPORT_AGGREGATE_BY_TYPES.BRAND,
    ADS_REPORT_AGGREGATE_BY_TYPES.SOURCE_CAMPAIGN,
    ADS_REPORT_AGGREGATE_BY_TYPES.PUBLISHER_PLATFORM,
    ADS_REPORT_AGGREGATE_BY_TYPES.COUNTRY,
    ADS_REPORT_AGGREGATE_BY_TYPES.REGION,
    ADS_REPORT_AGGREGATE_BY_TYPES.AGE_GENDER,
  ],
  [CHANNELS.TIKTOK_ADS.value]: [
    ADS_REPORT_AGGREGATE_BY_TYPES.BRAND,
    ADS_REPORT_AGGREGATE_BY_TYPES.SOURCE_CAMPAIGN,
    ADS_REPORT_AGGREGATE_BY_TYPES.AGE_GENDER,
    ADS_REPORT_AGGREGATE_BY_TYPES.COUNTRY,
  ],
});
