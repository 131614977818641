<template>
  <li class="multi-select-item">
    <CheckBox
      v-model="assigned"
      :label="item.name"
      :disabled="disabled"
      data-cy="multi-select-list-item-checkbox"
      @input="checkHandler"
    />
  </li>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useSharedStore } from '@/stores/shared';
import CheckBox from '@/components/foundation/CheckBox.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MultiSelectListItem',
  components: {
    CheckBox,
  },
  props: {
    item: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
  },
  emits: ['item-add', 'item-remove'],
  data() {
    return {
      assigned: false,
    };
  },
  computed: {
    ...mapStores(useSharedStore),
  },
  mounted() {
    const selected = this.sharedStore.selectedMultiSelectListItems.filter(
      (item) => item.id === this.item.id,
    );
    if (selected.length > 0) {
      this.assigned = true;
    }
  },
  methods: {
    checkHandler(value) {
      this.$emit(value ? 'item-add' : 'item-remove', this.item?.id);
      if (value) {
        this.sharedStore.updateSelectedMultiSelectListItems({ type: 'add', item: this.item });
      } else {
        this.sharedStore.updateSelectedMultiSelectListItems({ type: 'remove', item: this.item });
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.multi-select-item {
  height: var(--space-32);

  :deep(span) {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }

  :deep(.indicator) {
    top: 10px;
  }
}
</style>
