/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-shield': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.251 16c0-2.301 1.449-3.75 3.75-3.75s3.75 1.449 3.75 3.75h-7.5z" _fill="#686a7a"/><circle pid="1" cx="12.001" cy="8.5" r="2.5" _fill="#686a7a"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M2.001 6c0 .694.114 17 10 17s10-16.306 10-17a.999.999 0 00-.514-.874l-9-5a1 1 0 00-.972 0l-9 5A1.001 1.001 0 002.001 6zm10 15C5.004 21 4.126 9.341 4.017 6.58l7.984-4.437 7.984 4.437C19.876 9.342 18.998 21 12.001 21z" _fill="#686a7a"/>'
  }
})
