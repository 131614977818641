/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play': {
    width: 56,
    height: 64,
    viewBox: '0 0 56 64',
    data: '<path pid="0" d="M0 6c0-4.97 3.54-7.07 7.906-4.692l44.188 24.077c4.367 2.38 4.486 6.454.277 9.096L7.629 62.564C3.416 65.208 0 63.317 0 58.353V6z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
