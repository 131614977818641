<template>
  <VDropdown
    placement="bottom-end"
    :triggers="[]"
    :shown="panelActive"
    class="notification-menu"
    theme="dh-dropdown-full"
    data-cy="notification-bell-icon"
    auto-boundary-max-size
    distance="2"
    @auto-hide="panelActive = false"
  >
    <div
      ref="notificationsButton"
      class="notification-icon"
      @click="handleNotificationsButtonClick"
    >
      <Icon name="notification" xsmall :color="colours.ICON.ICON_PRIMARY" />
      <div v-if="hasUnreadNotifications" class="notifications-badge" />
    </div>
    <template #popper>
      <div ref="optionsMenu" class="notifications-dropdown-menu" role="menu">
        <div class="notifications-dropdown-menu-header">
          <div class="header-text">Notifications</div>
          <DropdownButton
            :dropdown-list="subMenuContent"
            button-icon="showMore"
            button-classes="corner"
            align-right
          />
        </div>
        <div
          v-if="!pending.notifications && notifications.length === 0"
          class="no-notifications-container"
        >
          <div class="header-text">No notifications</div>
        </div>
        <div v-else class="notifications-dropdown-menu-contents">
          <SkeletonLoader
            :number-of-repeating-component="2"
            :loading="pending.notifications"
            type="notification"
          >
            <div class="notifications-container" @scroll="handleScroll">
              <NotificationPanelItem
                v-for="notification in notifications"
                :key="notification.id"
                :notification="notification"
                :brand="brandsById[notification.comment.brandId]"
                @click="clickNotification(notification)"
              />
              <CircularLoader v-if="pending.notificationsPaging" class="loader" />
              <div v-else-if="!notificationsPaging.next" class="no-notifications-text">
                No more notifications
              </div>
            </div>
          </SkeletonLoader>
        </div>
      </div>
    </template>
  </VDropdown>
</template>
<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import Icon from '@/components/foundation/Icon.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import NotificationPanelItem from '@/components/layout/NotificationPanelItem.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import globalModalsMixin from '@/mixins/globalModalsMixin';
import { colours } from '@/ux/colours';
import { useCommentingStore } from '@/stores/commenting';
import SocketsMixin from '@/mixins/socketsMixin';
import { COMMENT_RESOURCE_TYPES } from '@/config';
import { useSchedulerStore } from '@/stores/scheduler';
import {
  SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE,
  SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE,
} from '@/app/scheduler/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'NotificationsPanel',
  components: {
    CircularLoader,
    NotificationPanelItem,
    SkeletonLoader,
    Icon,
    DropdownButton,
  },
  mixins: [globalModalsMixin, SocketsMixin],
  data: () => ({
    panelActive: false,
  }),
  sockets: {
    COMMENT_NOTIFICATION_CREATED(payload) {
      // Listen for websocket updates to the new mentioned comment notification
      this.commentingStore.createCommentNotificationSocket({ notification: payload });
    },
  },
  computed: {
    ...mapStores(useCommentingStore, useTrackingStore, useSchedulerStore),
    ...mapPiniaState(useAuthStore, ['identity', 'currentBrand', 'currentBrandLabel']),
    colours() {
      return colours;
    },
    notifications() {
      return this.commentingStore?.notifications ?? [];
    },
    notificationsPaging() {
      return this.commentingStore?.notificationsPaging;
    },
    pending() {
      return this.commentingStore?.pending;
    },
    shouldLoadNextPage() {
      return (
        !this.pending.notificationsPaging &&
        !this.pending.notifications &&
        this.notifications.every((notification) => !notification.readAt) &&
        !!this.notificationsPaging.next
      );
    },
    brandsById() {
      const brandsByLabel = this.identity?.brands || {};
      const brandsById = {};
      Object.values(brandsByLabel).forEach((brand) => {
        brandsById[brand.id] = brand;
      });
      return brandsById;
    },
    subMenuContent() {
      const dropdownMenuButtonItemList = [
        {
          text: 'Mark All as Read',
          action: () => {
            this.sendMenuOptionSelectedEvent('Mark All as Read');
            this.commentingStore.markNotificationsRead();
          },
        },
        {
          text: 'Email Preferences',
          action: () => {
            this.panelActive = false;
            this.$router.push({
              name: 'settings.notifications.email',
              params: { brandLabel: this.currentBrandLabel },
            });
            this.sendMenuOptionSelectedEvent('Email Preferences');
          },
        },
      ];
      return dropdownMenuButtonItemList;
    },
    notificationsCount() {
      // currently available
      return this.notifications.length;
    },
    unreadNotificationsCount() {
      return this.notifications.reduce(
        (count, notification) => (!notification.readAt ? count + 1 : count),
        0,
      );
    },
    hasUnreadNotifications() {
      return this.notifications.some((notification) => !notification.readAt);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const panelShouldBeActive = to.query.show_notifications === null;
        if (panelShouldBeActive !== this.panelActive) {
          this.panelActive = panelShouldBeActive;
        }
      },
      pending(to) {
        // if all notifications are unread, and there's another page, load it automatically
        if (
          !to.notifications &&
          !to.notificationsPaging &&
          this.notificationsPaging.next &&
          this.notifications?.every((notification) => !notification.readAt)
        ) {
          this.commentingStore.getNotificationsNextPage();
        }
      },
    },
    globalModals(to, from) {
      if (
        to.length === 0 &&
        from.length > 0 &&
        !this.panelActive &&
        this.$route.query.show_notifications === null
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            show_notifications: undefined,
          },
        });
      }
    },
    panelActive(to) {
      const panelActiveFromQuery = this.$route.query.show_notifications === null;
      if (
        to !== panelActiveFromQuery &&
        this.globalModals.length === 0 &&
        !this.$route?.name?.includes('community')
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            show_notifications: to ? null : undefined,
          },
        });
      }
    },
    shouldLoadNextPage(to) {
      // if all notifications are unread, and there's another page, load it automatically
      if (to) {
        this.commentingStore.getNotificationsNextPage();
      }
    },
  },
  created() {
    this.commentingStore.getNotifications();
  },
  methods: {
    sendPanelExpandedEvent() {
      this.trackingStore.track('Notification Panel Expanded', {
        'Notifications Count': this.notificationsCount,
        'Unread Notifications Count': this.unreadNotificationsCount,
      });
    },
    sendMenuOptionSelectedEvent(option) {
      this.trackingStore.track('Notification Panel Option Selection', {
        'Notifications Count': this.notificationsCount,
        'Unread Notifications Count': this.unreadNotificationsCount,
        Option: option,
      });
    },
    sendNotificationClickedEvent(notification) {
      let action = null;
      let properties = null;

      if (notification?.comment !== undefined && notification?.comment !== null) {
        action = 'Notification Panel View Comment';
        properties = {
          'Resource ID': notification.comment.resourceId,
          'Resource Type': notification.comment.resourceType,
          'Unread Notification': notification.readAt == null,
        };
      }

      if (action !== null && properties !== null) {
        this.trackingStore.track(action, properties);
      }
    },
    loadNextPage() {
      this.commentingStore.getNotificationsNextPage();
    },
    handleScroll({ target: { clientHeight, scrollHeight, scrollTop } }) {
      if (scrollHeight - scrollTop === clientHeight && scrollTop > 0) {
        this.loadNextPage();
      }
    },
    handleNotificationsButtonClick() {
      this.panelActive = !this.panelActive;
    },
    async clickNotification(notification) {
      this.sendNotificationClickedEvent(notification);
      await this.commentingStore.markNotificationRead({ id: notification.id });
      const { url } = notification;
      // Stripping the domain from the URL is a work-around to allow these URLs to work on
      // prod, dev, and deploy previews. The plan is to eventually stop sending domains
      // along with notification URLs, and use just a path instead. The check for http at the
      // beginning of the URL ensures it will work whether a domain or path is provided.
      let path = url.match(/^http/) ? url.replace(new URL(notification.url).origin, '') : url;
      // Ignore the unnecessary query params from the url
      if (notification.comment.resourceType === COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT) {
        path = path
          .replace(`&resource_id=${notification.comment.id}`, '')
          .replace('/assigned-to-me', '/all-messages');
      }
      if (notification.comment.resourceType === COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE) {
        path = path.replace(
          `id=${notification.comment.resourceId}`,
          `mentioned_user_ids=${this.identity.id}`,
        );
      }

      if (
        [
          ...Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE),
          ...Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE),
        ].includes(notification.comment.resourceType)
      ) {
        await this.schedulerStore.fetchPost({
          platform: notification.url.split('/')[5],
          id: notification.comment.resourceId,
        });
      }
      if (notification.comment.brandId === this.currentBrand?.id) {
        this.panelActive = false;
        await this.$router.push(path);
      } else {
        window.location.assign(path);
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.notification-menu {
  display: flex;
  align-items: center;
  position: relative;

  .notification-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
    width: 36px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    .notifications-badge {
      position: absolute;
      width: var(--space-6);
      height: var(--space-6);
      top: 1rem;
      right: 0;
      border-radius: 50%;
      background-color: var(--error-500);
    }
  }
}

.notifications-dropdown-menu {
  display: flex;
  flex-direction: column;
  min-height: 15rem;
  width: 17.75rem;
  font-size: var(--x14);
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  align-items: stretch;

  .notifications-dropdown-menu-header {
    display: flex;
    flex-direction: row;
    padding: var(--space-16) var(--space-8) var(--space-16) var(--space-22);
    justify-content: space-between;
    align-items: center;

    .dropdown-options {
      width: fit-content;
    }
  }

  .header-text {
    color: var(--text-secondary);
    font-size: var(--x14);
    font-weight: var(--font-normal);
  }

  .no-notifications-text {
    margin: var(--space-16) auto;
    text-align: center;
    color: var(--text-secondary);
    font-size: var(--x14);
    font-weight: var(--font-normal);
  }

  .no-notifications-container {
    margin-bottom: var(--space-32);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .notifications-dropdown-menu-contents {
    padding: var(--space-16);
    flex: 1;
  }

  .notifications-container {
    max-height: 640px;
    overflow-y: auto;
    margin: calc(-1 * var(--space-16));

    .loader {
      margin: 0 auto;
    }
  }
}
</style>
