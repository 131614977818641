<script setup>
// Vue
import { computed } from 'vue';
// Components
import StartTrialPopup from '@/components/InAppTrials/StartTrialPopup.vue';
// Stores
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import SuccessTrialPopup from '@/components/InAppTrials/SuccessTrialPopup.vue';
import FeatureAccessPopup from '@/components/FeatureAccessPopup.vue';
import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';

const customerJourneyStore = useCustomerJourneyStore();

const featureAccessChilipiperData = computed(() => {
  if (!customerJourneyStore.popups.featureAccess.product) {
    return null;
  }
  const product = customerJourneyStore.popups.featureAccess.product;
  return CHILI_PIPER_FORMS_MAP?.[product]?.upgrade
    ? {
        router: CHILI_PIPER_FORMS_MAP?.[product]?.upgrade.router,
        name: CHILI_PIPER_FORMS_MAP?.[product]?.upgrade.name,
      }
    : null;
});
</script>

<template>
  <SuccessTrialPopup
    v-if="customerJourneyStore.popups.successTrial.show"
    @close="customerJourneyStore.togglePopup('successTrial', false, null)"
  ></SuccessTrialPopup>
  <StartTrialPopup
    v-if="customerJourneyStore.popups.startTrial.show"
    @close="customerJourneyStore.togglePopup('startTrial', false, null)"
  />
  <FeatureAccessPopup
    v-if="customerJourneyStore.popups.featureAccess.show"
    :on-close="() => customerJourneyStore.togglePopup('featureAccess', false, null)"
    :chili-piper-data="featureAccessChilipiperData"
  />
</template>
