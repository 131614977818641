import { MESSAGE_RULE_FILTER_OPTIONS } from '@/app/community/constants/inboxAutomation';
import {
  COMMENT_RESOURCE_LABEL_BY_TYPE,
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET,
  COMMUNITY_PLATFORM_FILTER_WIDGET,
  COMMUNITY_QUESTION_FILTER_WIDGET,
  COMMUNITY_SENTIMENT_FILTER_WIDGET,
} from '@/app/community/constants';
import dayjs from 'dayjs';

export function pruneAllMessageToggleValues(ruleItems) {
  const cb = ({ key }) => key === 'all';
  const allMessagesIndex = ruleItems.findIndex(cb);
  const allMessagesRule = ruleItems.find(cb);
  const values = ruleItems.reduce((accumulator, { key, value, disabled }) => {
    if (!disabled && key !== 'all') {
      return [...accumulator, value[0]];
    }
    return accumulator;
  }, []);
  ruleItems.splice(allMessagesIndex, 1, { ...allMessagesRule, value: values });
  return ruleItems;
}

export function formatMessageRuleFilterValue(filter) {
  switch (filter.key) {
    case MESSAGE_RULE_FILTER_OPTIONS.MESSAGE_TYPE.key:
      return (
        COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET[filter.value].formattedLabel ||
        COMMUNITY_MESSAGE_TYPE_FILTER_WIDGET[filter.value].label
      );
    case MESSAGE_RULE_FILTER_OPTIONS.CHANNEL.key:
      return COMMUNITY_PLATFORM_FILTER_WIDGET[filter.value].title;
    case MESSAGE_RULE_FILTER_OPTIONS.TAG.key:
      return Object.values(filter.value)[0];
    case MESSAGE_RULE_FILTER_OPTIONS.QUESTION.key:
      return COMMUNITY_QUESTION_FILTER_WIDGET[filter.value].label;
    case MESSAGE_RULE_FILTER_OPTIONS.SENTIMENT.key:
      return COMMUNITY_SENTIMENT_FILTER_WIDGET[filter.value].title;
    case MESSAGE_RULE_FILTER_OPTIONS.REPLIED.key:
      return null;
    default:
      return filter.value;
  }
}

export function ruleExecutedMessageFormatter(
  messages,
  checkRuleDeleted,
  undoAction,
  deleteRuleExecuteMessage,
  editRuleAction,
) {
  return messages.map((message) => {
    const isRuleDeleted = checkRuleDeleted(message.ruleId);
    const dropdownOptions = [
      {
        text: 'Undo Action',
        action: async () => {
          await undoAction(message, deleteRuleExecuteMessage);
        },
      },
      {
        text: 'Edit Message Rule',
        disabled: isRuleDeleted,
        tooltip: isRuleDeleted ? 'This rule has been deleted and can no longer be edited' : null,
        action: isRuleDeleted ? null : () => editRuleAction(message.ruleId, true),
      },
    ];
    return {
      id: message.id,
      resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_RULE_EXECUTED,
      props: {
        comment: { ...message, text: message.triggeredRuleName },
        messageDatetime: dayjs.utc(message.triggeredAt).format('YYYY-MM-DDTHH:mm:ss'),
        label:
          COMMENT_RESOURCE_LABEL_BY_TYPE[COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_RULE_EXECUTED],
        message: message.triggeredRuleName,
        dropdownOptions,
        sender: {},
      },
      createdAt: dayjs.utc(message.triggeredAt),
    };
  });
}
