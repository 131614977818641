<script setup>
import { useUtilityNavigationMenu } from '@/composables/layout/navigation/useUtilityNavigationMenu';
import SecondaryNavigationMenu from '@/components/layout/navigation/SecondaryNavigationMenu.vue';
import TrialInfoPanel from '@/components/layout/TrialInfoPanel.vue';

const emits = defineEmits(['close']);

const { firstName, visibleUtilityNavigationMenuItems } = useUtilityNavigationMenu();

function close(event) {
  emits('close', event);
}
</script>

<template>
  <div class="w-[var(--secondary-navigation-width)] self-end p-4">
    <p v-if="firstName" class="dash-typo-h5 dash-text-primary px-6 py-4">Hi, {{ firstName }}</p>
    <TrialInfoPanel visual-bridge class="mt-6" @selected="close" />
    <SecondaryNavigationMenu
      :menu-items="visibleUtilityNavigationMenuItems"
      @selected="close($event)"
    />
  </div>
</template>
