<script setup>
const tooltip = {
  content: 'Vision is predicting for Engagement',
  placement: 'bottom',
  offset: '-22, 0',
};
</script>

<template>
  <div v-tooltip="tooltip" class="spinner-wrapper">
    <div class="vision-learning-spinner" />
  </div>
</template>

<style lang="postcss" scoped>
.spinner-wrapper {
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 var(--space-10);
  border-radius: 1rem;
  background: var(--background-300);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  .vision-learning-spinner {
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--action-500);
    border-radius: 100%;
    animation: sk-scaleout 1.25s infinite ease-in-out;
  }

  @keyframes sk-scaleout {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes sk-scaleout {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
</style>
