<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { FilterSidePanel, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import PrimaryNavigation from '@/components/layout/navigation/PrimaryNavigation.vue';

const route = useRoute();

const FILTER_SIDE_PANEL_WIDTH = '22rem';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  showNav: {
    type: Boolean,
    default: true,
  },
});

const flagStore = useFlagStore();

const { visible: filterVisible, closeFilter } = useFilterSidePanel();
const { hidden, expanded, collapseNavigationPanel } = useSecondaryNavigationPanel();

const hasVisualBridgeFlag = computed(() => flagStore.ready && flagStore.flags.visualBridge);
const secondaryNavHidden = computed(() => {
  return hidden.value;
});
const secondaryNavExpanded = computed(() => {
  return expanded.value && !route?.meta?.hideSecondaryNav && hasVisualBridgeFlag.value;
});

const hideSecondaryNavigation = computed(() => {
  return secondaryNavHidden.value || route?.meta?.hideSecondaryNav;
});

const routeMetaReport = computed(() => {
  return route?.meta?.report;
});

const backgroundClass = computed(() =>
  hasVisualBridgeFlag.value
    ? `before:fixed before:bottom-0 before:left-[var(--primary-navigation-width)] before:right-0 before:top-0 before:-z-10 before:h-screen before:w-[calc(100%-var(--primary-navigation-width))] before:rounded-tl-[2.5rem] ${!routeMetaReport.value ? 'before:bg-[#f9fbff] ' : ''}before:transition-[all_0.3s_cubic-bezier(0.35,0,0.3,1.1)] before:content-['']`
    : null,
);

const mainContentStyle = computed(() => {
  if (filterVisible.value) {
    return { width: `calc(100% - ${FILTER_SIDE_PANEL_WIDTH})` };
  }
  return {};
});

watch(
  () => filterVisible.value,
  (visible) => {
    if (visible) {
      collapseNavigationPanel();
      document.body.dataset.filterSidePanelOpen = '';
    } else {
      delete document.body.dataset.filterSidePanelOpen;
    }
  },
  { immediate: true },
);

watch(
  () => expanded.value,
  (navExpanded) => {
    if (navExpanded) {
      closeFilter();
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="relative" :class="backgroundClass">
    <PrimaryNavigation v-if="props.showNav && hasVisualBridgeFlag" />
    <div
      class="dash-z-10 dash-transition-md dash-w-full min-h-screen"
      :class="{
        'xl:pl-[var(--secondary-navigation-width)]':
          !secondaryNavHidden && !routeMetaReport && secondaryNavExpanded,
        'dash-elevation-2xl': !hideSecondaryNavigation && filterVisible,
      }"
      :style="mainContentStyle"
    >
      <slot />
    </div>
    <template v-if="flagStore.flags.saveJackie">
      <FilterSidePanel
        :class="{ 'top-12': !flagStore.flags.visualBridge, invisible: !filterVisible }"
      />
    </template>
  </div>
</template>
