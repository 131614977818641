import { ref, computed, watch } from 'vue';
import { defineStore } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { useRoute } from 'vue-router';

export const useLayoutStore = defineStore('layout', () => {
  const route = useRoute();
  const { width } = useWindowSize();

  const sideMenuVisible = ref(true);

  const smallWindow = computed(() => {
    return width.value <= 1200;
  });
  const isSideMenuVisible = computed(() => sideMenuVisible.value);

  function toggleSideMenu() {
    sideMenuVisible.value = !sideMenuVisible.value;
  }
  function hideSideMenu() {
    sideMenuVisible.value = false;
  }
  function showSideMenu() {
    sideMenuVisible.value = true;
  }

  watch(
    () => route?.fullPath,
    () => {
      if (smallWindow.value) {
        hideSideMenu();
      }
    },
  );

  watch(
    () => smallWindow.value,
    () => {
      if (smallWindow.value) {
        hideSideMenu();
      } else {
        showSideMenu();
      }
    },
    { immediate: true },
  );

  return {
    isSideMenuVisible,
    toggleSideMenu,
    hideSideMenu,
    showSideMenu,
    smallWindow,
  };
});
