import { camelize } from 'humps';
import dayjs from 'dayjs';
import {
  COMMENT_RESOURCE_LABEL_BY_TYPE,
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_INTERACTION_TYPES,
  SORT_OPTIONS,
  THREADED_COMMENT_ACTION_BY_PAYLOAD,
  THREADED_COMMENT_UPDATE_PAYLOAD_BY_ACTION,
} from '@/app/community/constants';
import { ERROR } from '@/ux/colours';
import { useFlagStore } from '@/stores/flag';

function getAvatarUrl(brand, handle) {
  if (handle === brand.name) {
    return brand.avatarUrl;
  }
  return '';
}

function generateCommentGreyoutCheck({ targetCommentId }) {
  return function commentGreyoutCheck(comment) {
    return !(comment.id === targetCommentId);
  };
}

function getTikTokProfilePageUrl(username) {
  return username ? `https://www.tiktok.com/@${username}` : '';
}

const getUrlPathByPlatform = (platform) => {
  if (platform.toLowerCase() === 'twitter') {
    return 'tweets';
  }
  return `${platform.toLowerCase()}_comments`;
};

function commentActionPerformed(payload) {
  const payloads = [];
  Object.values(THREADED_COMMENT_UPDATE_PAYLOAD_BY_ACTION).forEach((update) => {
    payloads.push(...Object.keys(update));
  });
  const setOfKeys = new Set(payloads);
  const tuples = Object.entries(payload);
  return tuples.length >= 1
    ? tuples.map((tuple) => {
        const [key, value] = tuple;
        return setOfKeys.has(key) ? THREADED_COMMENT_ACTION_BY_PAYLOAD[key][value] : 'UNKNOWN';
      })[0]
    : 'UNKNOWN';
}

function sortReplies(comment, direction) {
  return (comment.replies || []).sort((a, b) =>
    direction === SORT_OPTIONS.NEW_TO_OLD
      ? dayjs(a.createdAt).diff(dayjs(b.createdAt))
      : dayjs(b.createdAt).diff(dayjs(a.createdAt)),
  );
}

function instagramCommentFormatter(
  comment,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  // Sort replies by createdAt asc.
  return {
    greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(comment)),
    id: comment.id,
    instagramCommentId: comment.instagramCommentId,
    instagramParentCommentId: null,
    text: comment.text,
    timestamp: comment.sourceUpdatedAt,
    brandUserReply: comment.brandUserReply ? comment.brandUserReply : null,
    username: comment.instagramUsername,
    userAvatar: getAvatarUrl(currentBrand, comment.instagramUsername),
    mediaId: comment.mediaId,
    mediaType: comment.mediaType,
    isHidden: comment.isHidden,
    isOwned: !!comment.isOwned,
    sentiment: comment.sentiment,
    type: COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT,
    replies: sortReplies(comment, sortDirection).map((r) => {
      return {
        greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(r)),
        id: r.id,
        instagramCommentId: r.instagramCommentId,
        instagramParentCommentId: comment.instagramCommentId,
        text: r.text,
        timestamp: r.sourceUpdatedAt,
        brandUserReply: r.brandUserReply ? r.brandUserReply : null,
        username: r.instagramUsername,
        userAvatar: getAvatarUrl(currentBrand, r.instagramUsername),
        mediaId: r.mediaId,
        mediaType: r.mediaType,
        isHidden: r.isHidden,
        isOwned: !!r.isOwned,
        sentiment: r.sentiment,
        type: COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT,
      };
    }),
  };
}

function tiktokCommentFormatter(
  comment,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  // only brand replies for tiktok comments

  return {
    greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(comment)),
    id: comment.id,
    tiktokCommentId: comment.tiktokCommentId,
    tiktokParentCommentId: null,
    text: comment.text,
    timestamp: comment.sourceUpdatedAt,
    brandUserReply: comment.brandUserReply ? comment.brandUserReply : null,
    username: comment.tiktokUsername,
    userAvatar: comment.authorProfilePictureUrl,
    userProfile: getTikTokProfilePageUrl(comment.tiktokUsername),
    mediaId: comment.mediaId,
    isLiked: comment.isLiked,
    isPinned: comment.isPinned,
    isHidden: comment.isHidden,
    isOwned: comment.isOwned,
    sentiment: comment.sentiment,
    type: COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT,
    replies: sortReplies(comment, sortDirection).map((r) => {
      return {
        greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(r)),
        id: r.id,
        tiktokParentCommentId: comment.tiktokCommentId,
        text: r.text,
        timestamp: r.sourceUpdatedAt,
        brandUserReply: r.brandUserReply ? r.brandUserReply : null,
        username: currentBrand.id === r.brandId ? currentBrand.name : r.tiktokUsername,
        userAvatar:
          currentBrand.id === r.brandId ? currentBrand.avatarUrl : r.authorProfilePictureUrl,
        userProfile:
          currentBrand.id === r.brandId ? null : getTikTokProfilePageUrl(r.tiktokUsername),
        type: COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT,
        mediaId: r.mediaId,
        isLiked: r.isLiked,
        isPinned: r.isPinned,
        isHidden: r.isHidden,
        isOwned: r.isOwned,
        sentiment: r.sentiment,
      };
    }),
  };
}

function facebookCommentFormatter(
  comment,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  return {
    greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(comment)),
    id: comment.id,
    attachment: comment.attachment,
    facebookCommentId: comment.facebookCommentId,
    facebookParentCommentId: null,
    text: comment.text,
    timestamp: comment.sourceUpdatedAt,
    brandUserReply: comment.brandUserReply ? comment.brandUserReply : null,
    username: comment.facebookUsername,
    userAvatar: comment.facebookProfilePictureUrl,
    mediaId: comment.mediaId,
    isHidden: comment.isHidden,
    isOwned: comment.isOwned,
    isAuthorBlocked: comment.isAuthorBlocked,
    sentiment: comment.sentiment,
    type: COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT,
    replies: sortReplies(comment, sortDirection).map((r) => {
      return {
        greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(r)),
        id: r.id,
        attachment: r.attachment,
        facebookParentCommentId: comment.facebookCommentId,
        text: r.text,
        timestamp: r.sourceUpdatedAt,
        brandUserReply: r.brandUserReply ? r.brandUserReply : null,
        username: r.facebookUsername,
        userAvatar: r.facebookProfilePictureUrl,
        type: COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT,
        mediaId: r.mediaId,
        postPending: !!r?.postPending,
        isHidden: r.isHidden,
        isOwned: r.isOwned,
        sentiment: r.sentiment,
        isAuthorBlocked: r.isAuthorBlocked,
      };
    }),
  };
}

function twitterTweetFormatter(
  tweet,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  return {
    greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(tweet)),
    id: tweet.id,
    attachments: tweet.attachments?.map((a) => {
      return {
        ...a,
        src: a.mediaUrl,
      };
    }),
    twitterTweetId: tweet.twitterTweetId,
    twitterParentTweetId: null,
    text: tweet.text,
    timestamp: tweet.sourceUpdatedAt,
    brandUserReply: tweet.brandUserReply ? tweet.brandUserReply : null,
    username: tweet.authorUsername,
    userAvatar: tweet.authorProfilePictureUrl,
    mediaId: tweet.mediaId,
    isLiked: tweet.isLiked,
    isHidden: tweet.isHidden,
    isOwned: tweet.isOwned,
    isRetweeted: tweet.isRetweeted,
    isQuotedBy: tweet.isQuotedBy,
    sentiment: tweet.sentiment,
    type: tweet.type,
    isDeleted: !!tweet.deletedAt,
    replies: sortReplies(tweet, sortDirection).map((r) => {
      return {
        greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(r)),
        id: r.id,
        attachments: r.attachments?.map((a) => {
          return {
            ...a,
            src: a.mediaUrl,
          };
        }),
        twitterParentTweetId: tweet.twitterTweetId,
        twitterTweetId: r.twitterTweetId,
        text: r.text,
        timestamp: r.sourceUpdatedAt,
        brandUserReply: r.brandUserReply ? r.brandUserReply : null,
        username: r.authorUsername,
        userAvatar: r.authorProfilePictureUrl,
        type: COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION,
        mediaId: r.mediaId,
        postPending: !!r?.postPending,
        isLiked: r.isLiked,
        isHidden: r.isHidden,
        isOwned: r.isOwned,
        isRetweeted: r.isRetweeted,
        isQuotedBy: r.isQuotedBy,
        sentiment: r.sentiment,
        isDeleted: !!r.deletedAt,
      };
    }),
  };
}

function youtubeCommentFomatter(
  comment,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  return {
    greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(comment)),
    id: comment.id,
    text: comment.text,
    timestamp: comment.sourceUpdatedAt,
    brandUserReply: comment.brandUserReply ? comment.brandUserReply : null,
    username: comment.isOwned ? currentBrand.name : comment.authorUsername,
    userAvatar: comment.isOwned ? currentBrand.avatarUrl : comment.authorProfilePictureUrl,
    mediaId: comment.mediaId,
    isOwned: comment.isOwned,
    sentiment: comment.sentiment,
    type: COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT,
    isDeleted: !!comment.deletedAt,
    youtubeCommentId: comment.youtubeCommentId,
    youtubeParentCommentId: null,
    replies: sortReplies(comment, sortDirection).map((r) => {
      return {
        greyout: Boolean(commentGreyoutCheck && commentGreyoutCheck(r)),
        id: r.id,
        text: r.text,
        timestamp: r.sourceUpdatedAt,
        brandUserReply: r.brandUserReply ? r.brandUserReply : null,
        username: r.isOwned ? currentBrand.name : r.authorUsername,
        userAvatar: r.isOwned ? currentBrand.avatarUrl : r.authorProfilePictureUrl,
        type: COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT,
        mediaId: r.mediaId,
        postPending: !!r?.postPending,
        isOwned: r.isOwned,
        sentiment: r.sentiment,
        isDeleted: !!r.deletedAt,
        youtubeCommentId: r.youtubeCommentId,
        youtubeParentCommentId: comment.youtubeCommentId,
      };
    }),
  };
}

function commentFormatter(
  comment,
  currentBrand,
  commentGreyoutCheck = null,
  sortDirection = SORT_OPTIONS.NEW_TO_OLD,
) {
  const flagStore = useFlagStore();

  switch (comment.type) {
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT:
      return instagramCommentFormatter(comment, currentBrand, commentGreyoutCheck, sortDirection);
    case COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT:
      return tiktokCommentFormatter(comment, currentBrand, commentGreyoutCheck, sortDirection);
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT:
      return facebookCommentFormatter(comment, currentBrand, commentGreyoutCheck, sortDirection);
    case COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION:
    case COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET:
    case COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE:
      return twitterTweetFormatter(comment, currentBrand, commentGreyoutCheck, sortDirection);
    case COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT:
      return youtubeCommentFomatter(comment, currentBrand, commentGreyoutCheck, sortDirection);
    case COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT_MENTION:
      return flagStore.flags?.communityTiktokMention
        ? tiktokCommentFormatter(comment, currentBrand, commentGreyoutCheck, sortDirection)
        : '';
    default:
      return null;
  }
}

function findRepliesToComment(platform, commentId, communityInteractions) {
  return communityInteractions.reduce((replies, interaction) => {
    if (
      interaction.type === COMMUNITY_INTERACTION_TYPES[`${platform.toUpperCase()}_COMMENT`] &&
      interaction[`${platform}ParentCommentId`] === commentId
    ) {
      replies.push(interaction.id);
    }
    return replies;
  }, []);
}

function searchCommentListById(commentList, commentId) {
  return commentList.reduce((matched, current) => {
    if (!matched) {
      return current.id === commentId ? current : current.replies.find((r) => r.id === commentId);
    }
    return matched;
  }, undefined);
}

function updateCommentStore(state, commentId, update, undo = false) {
  let comment;
  if (state.commentDetail) {
    comment =
      state.commentDetail.id === commentId
        ? state.commentDetail
        : state.commentDetail.replies.find((r) => r.id === commentId);
    if (comment) {
      Object.keys(update).forEach((key) => {
        comment = {
          ...comment,
          [camelize(key)]: undo ? !update[key] : update[key],
        };
      });
    }
  }
  if (state.commentList.length > 0) {
    comment = searchCommentListById(state.commentList, commentId);
    if (comment) {
      Object.keys(update).forEach((key) => {
        comment = {
          ...comment,
          [camelize(key)]: undo ? !update[key] : update[key],
        };
      });
    }
  }
}

function getThreadedCommentComponentByType(type) {
  switch (type) {
    case COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT:
    case COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT_MENTION:
      return 'TiktokThreadedCommentItem';
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT:
      return 'InstagramThreadedCommentItem';
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT:
      return 'FacebookThreadedCommentItem';
    case COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION:
      return 'TwitterThreadedCommentItem';
    case COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT:
      return 'YouTubeThreadedCommentItem';
    default:
      return '';
  }
}

function attachmentFormatter(attachments, alt) {
  return attachments.map((a) => ({
    ...a,
    src: a?.src || a.mediaUrl,
    alt: alt || 'media attachment',
  }));
}

function getInitials(firstName, lastName) {
  let initials = '';
  if (firstName) {
    initials += firstName.slice(0, 1);
  }
  if (lastName) {
    initials += lastName.slice(0, 1);
  }
  return initials.toUpperCase();
}

function internalMessageFormatter(messages, users, actions, currentUserId) {
  return messages.map((message) => {
    const sender = users[message.userId];
    const { isRead, channel, messageType } = message?.meta ?? {};
    const dropdownOptions =
      message.resourceType === COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE &&
      currentUserId === message.userId
        ? [
            {
              text: 'Delete Internal Note',
              textColor: ERROR.ERROR_500,
              actionParams: message.id,
              action: actions?.delete,
            },
          ]
        : [];
    return {
      id: `${message.resourceId}_${message.id}`,
      resourceType: message.resourceType,
      props: {
        comment: message,
        messageDatetime: dayjs(message.createdAt).format('YYYY-MM-DDTHH:mm:ss'),
        label: COMMENT_RESOURCE_LABEL_BY_TYPE[message.resourceType],
        message: message.text,
        sender: {
          avatarUrl: sender?.avatarUrl,
          initials: getInitials(sender.firstName, sender.lastName),
        },
        dropdownOptions,
      },
      createdAt: message.createdAt,
      isRead,
      channel,
      messageType,
    };
  });
}

export {
  attachmentFormatter,
  commentFormatter,
  getAvatarUrl,
  generateCommentGreyoutCheck,
  getTikTokProfilePageUrl,
  findRepliesToComment,
  searchCommentListById,
  updateCommentStore,
  getThreadedCommentComponentByType,
  getUrlPathByPlatform,
  commentActionPerformed,
  internalMessageFormatter,
};
