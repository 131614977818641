<template>
  <div class="time-input" :class="[{ disabled: disabled }]">
    <div class="time-input-icon">
      <Icon name="clock" xsmall />
    </div>
    <div class="flex items-center justify-center">
      <div class="time-hours-mins-inputs">
        <div>
          <input
            :value="hours"
            class="time-number-input"
            :class="{ invalid: v$.hours.$invalid }"
            type="text"
            :disabled="disabled"
            @input="onHoursInput"
          />
        </div>
        <div class="hours-mins-divider">:</div>
        <div>
          <input
            :value="minutes"
            class="time-number-input"
            :class="{ invalid: v$.minutes.$invalid }"
            type="text"
            :disabled="disabled"
            @input="onMinutesInput"
          />
        </div>
      </div>
      <div v-if="!is24hr" class="am-pm" :class="{ disabled: !(hours >= 0) }">
        <ButtonToggle v-model="amPm" mandatory>
          <Button no-hover xsmall rectangular :disabled="disabled">AM</Button>
          <Button no-hover xsmall rectangular :disabled="disabled">PM</Button>
        </ButtonToggle>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, integer, between } from '@vuelidate/validators';
import padStart from 'lodash/padStart';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import ButtonToggle from '@/components/foundation/ButtonToggle.vue';

const AM = 0;
const PM = 1;

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Button,
    ButtonToggle,
    Icon,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    is24hr: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      hours: null,
      minutes: null,
      amPm: AM,
      busy: false,
    };
  },
  validations() {
    return {
      hours: {
        required,
        integer,
        between: between(1, 24),
      },
      minutes: {
        required,
        integer,
        between: between(0, 59),
      },
    };
  },
  computed: {
    updatedDate() {
      if (!this.value) {
        return null;
      }

      let hoursNumber = parseInt(this.hours, 10) || 0;
      if (hoursNumber < 0) {
        hoursNumber = 0;
      }
      let minutesNumber = parseInt(this.minutes, 10) || 0;
      if (minutesNumber < 0) {
        minutesNumber = 0;
      }
      if (!this.is24hr) {
        hoursNumber = hoursNumber === 12 ? 0 : hoursNumber;
        if (this.amPm === PM) {
          hoursNumber += 12;
        }
      }
      const newDate = new Date(this.value);
      newDate.setHours(hoursNumber, minutesNumber, 0, 0);
      return newDate;
    },
  },
  watch: {
    value: {
      handler() {
        this.setup();
      },
      immediate: true,
    },
    hours() {
      this.updateValue();
    },
    minutes() {
      this.updateValue();
    },
    amPm() {
      this.updateValue();
    },
  },
  methods: {
    onHoursInput(event) {
      this.hours = event?.target?.value;
    },
    onMinutesInput(event) {
      this.minutes = event?.target?.value;
    },
    protected(fn) {
      if (this.busy) return;
      this.busy = true;
      fn();
      nextTick(() => {
        this.busy = false;
      });
    },
    setup() {
      if (!this.value) {
        return;
      }

      let hours = this.value.getHours();
      if (hours === 24) hours = 0;
      const isAM = this.is24hr || hours < 12;
      if (!isAM) {
        hours -= 12;
      }
      this.hours = `${hours === 0 ? '12' : hours}`;
      this.minutes = padStart(this.value.getMinutes(), 2, '0');
      this.amPm = isAM ? AM : PM;
    },
    updateValue() {
      this.protected(() => {
        this.emitInput(this.updatedDate);
      });
    },
    emitInput(newDate) {
      if (!this.v$.$invalid) {
        this.$emit('input', newDate);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.time-input {
  padding: var(--space-8);
  display: flex;
  width: 250px;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .time-input-icon {
    margin-right: var(--space-8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-hours-mins-inputs {
    display: flex;

    .time-number-input {
      width: 52px;
      padding: 0.25rem;
      height: inherit;
      height: var(--space-28);

      &.invalid {
        border-color: var(--error-500) !important;
      }
    }

    .hours-mins-divider {
      margin: 0 var(--space-4);
      color: var(--text-primary);
    }
  }

  .am-pm {
    display: flex;
    margin-left: var(--space-8);
  }
}
</style>
