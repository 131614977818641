<template>
  <div class="notification-entry-container" :class="{ unread: !notification.readAt }">
    <UserAvatar :user="avatarDetails" class="avatar" xsmall />
    <div class="details-container">
      <div class="brand-name">{{ brand.name }}</div>
      <div class="wrapped-text">
        <span class="user-name">{{ author.firstName }} {{ author.lastName }}</span>
        <span class="description">&nbsp;{{ description }}&nbsp;</span>
        <CommentText
          v-if="!shouldHideText"
          :comment="notification.comment"
          :users="commentingStore.users"
          :truncate-to="100"
        />
      </div>
      <div class="time-ago">{{ timeAgo }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import { mapStores } from 'pinia';
import humps, { camelizeKeys } from 'humps';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { notificationDescriptionsByType } from '@/config';
import CommentText from '@/components/CommentingModule/CommentText.vue';
import { useCommentingStore } from '@/stores/commenting';
import {
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES,
} from '@/app/community/constants';
import {
  SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE,
  SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE,
  SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES,
} from '@/app/scheduler/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'NotificationPanelItem',
  components: {
    CommentText,
    UserAvatar,
  },
  props: {
    notification: { type: Object, required: true },
    brand: { type: Object, required: true },
  },
  computed: {
    ...mapStores(useCommentingStore),
    avatarDetails() {
      return humps.camelizeKeys(this.author);
    },
    author() {
      return this.commentingStore?.users[this.notification.comment.userId];
    },
    shouldHideText() {
      const HIDDEN_RESOURCE_TYPES = [
        COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT,
        ...Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE),
        ...Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE),
      ];

      return HIDDEN_RESOURCE_TYPES.includes(this.comment.resourceType);
    },
    description() {
      const resourceType = this.comment.resourceType;
      if (resourceType === COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT) {
        return COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES[
          COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT
        ];
      }
      if (resourceType === COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE) {
        return COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES[
          COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE
        ];
      }
      if (Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE).includes(resourceType)) {
        return SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES.APPROVAL_REQUEST;
      }
      if (Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE).includes(resourceType)) {
        return SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES.REQUEST_APPROVED;
      }
      return notificationDescriptionsByType[this.notification.type];
    },
    timeAgo() {
      const time = dayjs.utc(this.notification.createdAt);
      if (dayjs.duration(dayjs().diff(time)).asSeconds() < 60) {
        return 'Just now';
      }
      return time.fromNow();
    },
    comment() {
      return camelizeKeys(this.notification.comment);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.notification-entry-container {
  display: flex;
  padding: var(--space-16);
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  &.unread {
    background-color: var(--alert-100);
  }

  .avatar {
    height: var(--space-24);
    width: var(--space-24);
    border-radius: var(--space-12);
    margin-right: var(--space-8);
  }

  .details-container {
    font-size: var(--x12);
    line-height: var(--space-16);
    font-weight: var(--font-normal);
    flex: 1;
    margin-top: calc(-1 * var(--space-4));

    .brand-name {
      color: var(--text-secondary);
      word-break: break-word;
    }

    .wrapped-text {
      word-break: break-word;

      .user-name {
        font-weight: var(--font-bold);
      }

      .description {
        color: var(--text-secondary);
      }
    }

    .time-ago {
      color: var(--text-secondary);
      margin-top: var(--space-8);
    }
  }
}
</style>
