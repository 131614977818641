/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'qrcode': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#686a7a" clip-path="url(#clip0_155_16)"><path pid="0" d="M3 10h6a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v6a1 1 0 001 1zm1-6h4v4H4V4zM21 2h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V3a1 1 0 00-1-1zm-1 4h-2V4h2v2zM7 16H3a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4H4v-2h2v2zM15 2h-4v2h4V2zM12 9h3V7h-2V5h-2v3a1 1 0 001 1zM16 10v4a1 1 0 001 1h3v1h2v-2a1 1 0 00-1-1h-3v-2h4V9h-5a1 1 0 00-1 1zM10 15v-3a1 1 0 00-1-1H2v2h2v2h2v-2h2v2h2zM15 13h-4v2h4v-2zM15 10h-3v2h3v-2zM15 18v2h-4v-1H9v2a1 1 0 001 1h6a1 1 0 001-1v-2h5v-2h-6a1 1 0 00-1 1z"/><path pid="1" d="M14 16h-2v3h2v-3zM11 16H9v2h2v-2zM22 20h-4v2h4v-2z"/></g><defs><clipPath id="clip0_155_16"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
