/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'showMore': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#adadad"><circle pid="0" cx="5" cy="12" r="2"/><circle pid="1" cx="12" cy="12" r="2"/><circle pid="2" cx="19" cy="12" r="2"/></g>'
  }
})
