/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'competitive': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<defs><clipPath id="svgicon_competitive_a"><path pid="0" d="M9.333 0c.492 0 .917.27 1.148.667h2.186c.368 0 .666.298.666.666V3.36c0 .611-.273 1.18-.75 1.561L9.797 7.15a3.998 3.998 0 01-2.464 1.457V10h1.334c.368 0 .666.298.666.667V12H10v1.333H3.333V12H4v-1.333c0-.369.298-.667.667-.667H6V8.607A4 4 0 013.537 7.15L.75 4.921A1.99 1.99 0 010 3.36V1.333C0 .965.298.667.667.667h2.185A1.329 1.329 0 014 0zM8 11.333H5.333V12H8v-.667zm1.333-10H4v3.334a2.67 2.67 0 002.667 2.666 2.67 2.67 0 002.666-2.666V1.333zM2.667 2H1.333v1.36c0 .203.092.393.251.52l1.087.87-.004-.083V2zM12 2h-1.333v2.667l-.004.083 1.087-.87a.664.664 0 00.25-.52V2z"/></clipPath><clipPath id="svgicon_competitive_b"><path pid="1" d="M16 0v16H0V0h16z"/></clipPath></defs><g clip-path="url(#svgicon_competitive_a)" transform="translate(.333 .333)"><path pid="2" _fill="#686a7a" d="M0 0h13.333v13.333H0V0z"/><g clip-path="url(#svgicon_competitive_b)" transform="translate(-1.333 -1.333)"><path pid="3" _fill="#666" d="M1.333 1.333h13.334v13.334H1.333V1.333z"/></g></g>'
  }
})
