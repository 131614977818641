<template>
  <div :class="['cropper-ratio-button', { selected, disabled }]" @click="onClickButton">
    <div class="dh-thumbnail">
      <img :style="imageStyle" :src="mediaUrl" class="dh-image" />
    </div>
    <div class="dh-text">
      <span :class="['dh-title', { selected }]">{{ title }}</span>
      <span :class="['dh-description', { selected }]">{{ description }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useCropperStore } from '@/stores/cropper';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AspectRatioButton',
  props: {
    disabled: { type: Boolean, default: false },
    media: { type: [Object, String], required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    ratio: { type: String, default: null },
    ratioType: { type: String, required: true },
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    ...mapStores(useCropperStore),
    mediaUrl() {
      // If it's an url(cover image thumbnail)
      if (typeof this.media === 'string') {
        return this.media;
      }
      // Use thumbnail size image
      if (this.media.type === 'IMAGE') {
        return this.media.fullMediaObject
          ? this.media.fullMediaObject.sizes.small.url
          : this.media.sizes.small.url;
      }
      // video
      return this.media.fullMediaObject
        ? this.media.fullMediaObject.thumbnails.small.url
        : this.media.thumbnails.small.url;
    },
    imageStyle() {
      if (this.ratio) {
        const res = this.ratio.split(':');
        const widthRatio = Number(res[0]);
        const heightRatio = Number(res[1]);
        let width = 60;
        let height = 60;
        if (heightRatio / widthRatio > 1) {
          width = (widthRatio / heightRatio) * height;
        } else if (heightRatio / widthRatio < 1) {
          height = (heightRatio / widthRatio) * width;
        }
        const leftMargin = (60 - width) / 2;
        return { width: `${width}px`, height: `${height}px`, 'margin-left': `${leftMargin}px` };
      }
      return null;
    },
  },
  watch: {
    'cropperStore.cropperRatio': {
      handler(newRatio) {
        if (newRatio) {
          this.selected = newRatio.ratioType === this.ratioType;
        }
      },
    },
  },
  created() {
    if (!this.cropperStore.cropperRatio) {
      this.cropperStore.setCropperRatio({
        ratioType: this.ratioType,
        name: this.title,
        ratio: this.ratio,
      });
    }
    this.selected = this.cropperStore.cropperRatio.ratioType === this.ratioType;
  },
  beforeUnmount() {
    this.cropperStore.removeCropperRatio();
  },
  methods: {
    onClickButton() {
      if (!this.disabled) {
        this.cropperStore.setCropperRatio({
          ratioType: this.ratioType,
          name: this.title,
          ratio: this.ratio,
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
div.selected {
  border: 1px solid rgb(214 176 52 / 80%);
  border-radius: 3px;
  background-color: rgb(214 176 52 / 10%);
}

div.disabled:hover {
  cursor: default;
}

.cropper-ratio-button {
  height: 92px;
  width: 260px;
  border-radius: var(--round-corner-extra-small);
  background-color: var(--background-0);
  margin: var(--space-16) auto;

  &:hover {
    outline: 1px solid var(--border);
    cursor: pointer;
  }

  .dh-thumbnail {
    width: 35%;
    height: 92px;
    float: left;
    padding: var(--space-16);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dh-image {
      object-fit: cover;
      width: 60px;
      overflow: hidden;
      margin: 0;
      box-sizing: border-box;
      border: 2px solid rgb(255 255 255 / 88%);
      border-radius: var(--round-corner-extra-small);
      box-shadow: var(--shadow-4);
    }
  }

  .dh-text {
    margin-left: 35%;
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dh-title {
      color: var(--text-primary);
      font-size: var(--x13);
      font-weight: var(--font-medium);
      line-height: var(--x18);
      display: block;
      padding-bottom: var(--space-4);
    }

    .dh-description {
      color: var(--text-secondary);
      font-size: var(--x12);
      font-weight: var(--font-medium);
      line-height: var(--x15);
      display: block;
    }

    span.selected {
      color: var(--brand-accent);
    }
  }
}
</style>
