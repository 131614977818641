/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.514 16.227l1.414-1.414-.707-.707a1.002 1.002 0 010-1.415l8.486-8.485c.188-.189.439-.293.707-.293s.518.104.708.293l.707.707L16.242 3.5l-.706-.707c-1.134-1.134-3.111-1.134-4.243 0l-8.485 8.484a3.003 3.003 0 000 4.243l.706.707zM21.192 8.451l-.708-.707-1.414 1.414.708.707a.99.99 0 01.292.707.996.996 0 01-.292.706l-8.485 8.486a1.027 1.027 0 01-1.415 0l-.707-.707-1.414 1.413.707.707a2.98 2.98 0 002.122.879 2.98 2.98 0 002.121-.879l8.485-8.485a2.979 2.979 0 00.878-2.12c0-.801-.312-1.555-.878-2.121zM19.777 2.793l1.414 1.414-7.07 7.071-1.415-1.414z"/><g><path pid="1" d="M9.878 12.691l1.413 1.413-7.07 7.073-1.414-1.413z"/></g>'
  }
})
