<template>
  <img :src="src" :class="{ large }" class="post-icon" :alt="alt" />
</template>

<script>
import { defineComponent } from 'vue';
import {
  MULTI_CHANNEL_POST_TYPES,
  MULTI_CHANNEL_POST_DESCRIPTIONS,
} from '@/app/scheduler/constants';
import { enumProp } from '@/utils/props';
import { exhaustiveMap } from '@/utils';
import tiktokIcon from '@/assets/img/badges/tiktok.svg';
import instagramIcon from '@/assets/img/badges/instagram.svg';
import linkedinIcon from '@/assets/img/badges/linkedin.svg';
import storiesIcon from '@/assets/img/badges/stories.svg';
import pinterestIcon from '@/assets/img/badges/pinterest.svg';

// These two icons were being blocked by uBlock Origin. The filenames
// are reversed as a workaround.
import twitterIcon from '@/assets/img/badges/rettiwt.svg';
import facebookIcon from '@/assets/img/badges/koobecaf.svg';
import chatBubbleSquareCheck from '@/assets/icons/chatBubbleSquareCheck.svg';

const ICONS_BY_TYPE = exhaustiveMap(MULTI_CHANNEL_POST_TYPES, {
  TIKTOK_POST: tiktokIcon,
  INSTAGRAM_POST: instagramIcon,
  INSTAGRAM_STORY_FRAME: storiesIcon,
  FACEBOOK_POST: facebookIcon,
  PINTEREST_PIN: pinterestIcon,
  TWITTER_TWEET: twitterIcon,
  LINKEDIN_POST: linkedinIcon,
  APPROVAL_PENDING_POST: chatBubbleSquareCheck,
});

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PostIcon',
  props: {
    /**
     * The type of posts in the group.
     */
    type: enumProp(MULTI_CHANNEL_POST_TYPES),
    /**
     * Should the icon be large.
     */
    large: { type: Boolean, default: false },
  },
  computed: {
    src() {
      return ICONS_BY_TYPE[this.type];
    },
    alt() {
      return `${MULTI_CHANNEL_POST_DESCRIPTIONS[this.type]} icon`;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.post-icon {
  width: var(--space-20);
  height: var(--space-20);

  &.large {
    width: var(--space-26);
    height: var(--space-26);
  }
}
</style>
