<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import isEqual from 'lodash/isEqual';
import { Icon } from '@dashhudson/dashing-ui';
import { usePrimaryNavigationMenu } from '@/composables/layout/navigation/usePrimaryNavigationMenu';
import { trackNavigation } from '@/utils/analytics/tracking';
import { useDocumentVisibility, useWindowFocus } from '@vueuse/core';
import { destroyTooltip } from 'floating-vue';

const route = useRoute();

const windowVisible = useDocumentVisibility();
const windowFocus = useWindowFocus();
const { visibleNavigationItems } = usePrimaryNavigationMenu();

const analyticsItems = [
  'instagram',
  'facebook',
  'tiktok',
  'twitter',
  'youtube',
  'linkedin',
  'pinterest',
];

const linkRefs = ref([]);

const currentPlatformName = computed(() => {
  const routeName = route.name.split('.')[0];
  if (routeName && analyticsItems.includes(routeName)) {
    return 'analytics';
  }
  if (routeName) {
    return routeName;
  }
  return null;
});

const activeItem = computed(() => {
  if (analyticsItems.includes(currentPlatformName.value)) {
    return 'analytics';
  }
  return visibleNavigationItems.value.find((item) => item.name === currentPlatformName.value)?.name;
});

const primaryNavigationClassList = computed(() =>
  visibleNavigationItems.value.map((item) => {
    const activeClass = activeItem.value === item.name ? item.backgroundClass : null;
    const hoverClass = `hover:${item.backgroundClass}`;
    if (!activeClass) {
      return hoverClass;
    }
    return `${activeClass} ${hoverClass}`;
  }),
);

const navigationSelectionMap = {
  competitors: 'Competitors',
};

function setRefs(el, index) {
  if (el) {
    linkRefs.value[index] = el;
  }
}

function trackPrimaryNavSelection(selection) {
  const value = navigationSelectionMap[selection] || selection;
  trackNavigation(value);
}

watch(
  () => [windowVisible.value, windowFocus.value],
  (to, from) => {
    if (!isEqual(to, from)) {
      linkRefs.value.forEach((el) => {
        destroyTooltip(el);
      });
    }
  },
);
</script>

<template>
  <ul class="grid max-h-[calc(100vh-12rem)] w-fit gap-5 overflow-y-auto px-1 pb-4 pt-1">
    <li v-for="(item, index) in visibleNavigationItems" :key="item.to.name">
      <router-link
        :ref="(el) => setRefs(el, index)"
        v-dash-tooltip="{
          content: item.label,
          theme: 'dash-tooltip--nav',
        }"
        :to="item.to"
        class="dash-rounded-sm dash-transition-sm group relative grid h-8 w-8 place-items-center focus:outline-[color:--action-500]"
        :class="primaryNavigationClassList[index]"
        :data-cy="`ProductNav-${item.name}`"
        @click="trackPrimaryNavSelection(item.name)"
      >
        <Icon
          :name="item.icon"
          size="md"
          class="dash-fill-icon-subtle group-hover:fill-white"
          :class="{ 'fill-white': activeItem === item.name }"
        />
        <span class="visually-hidden"> Go to {{ item.label }} </span>
      </router-link>
    </li>
  </ul>
</template>
