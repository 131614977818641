import { nextTick, ref } from 'vue';
import { defineStore } from 'pinia';
import uniq from 'lodash/uniq';

export const useSharedStore = defineStore('shared', () => {
  const selectedMultiSelectListItems = ref([]);
  const mediaTileSelectForceEnabled = ref(false);
  const resetBrandStatePending = ref(false);

  function updateSelectedMultiSelectListItems({ item, type }) {
    if (type === 'add') {
      selectedMultiSelectListItems.value = uniq([...selectedMultiSelectListItems.value, item]);
    } else {
      selectedMultiSelectListItems.value = selectedMultiSelectListItems.value.filter(
        (selectedItem) => item?.id !== selectedItem?.id,
      );
    }
  }

  function clearSelectedMultiSelectListItems() {
    selectedMultiSelectListItems.value = [];
  }

  function updateMediaTileSelectForceEnabled(value) {
    mediaTileSelectForceEnabled.value = value;
  }

  async function resetBrandState() {
    resetBrandStatePending.value = true;
    await nextTick();
    resetBrandStatePending.value = false;
  }

  return {
    selectedMultiSelectListItems,
    mediaTileSelectForceEnabled,
    updateSelectedMultiSelectListItems,
    clearSelectedMultiSelectListItems,
    updateMediaTileSelectForceEnabled,
    resetBrandState,
    resetBrandStatePending,
  };
});
