<script setup>
import { ICON } from '@/ux/colours';

const props = defineProps({
  color: {
    type: String,
    default: ICON.ICON_SECONDARY,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <span v-if="props.loading" class="loader" />
</template>

<style lang="postcss" scoped>
.loader {
  align-items: center;
  display: inline-flex;
  height: 1em;
  justify-content: center;
  position: relative;
  width: 1em;
}

.loader::after {
  animation: rotate 1s linear infinite;
  border: 1px solid transparent;
  border-left-color: v-bind(props.color);
  border-right-color: v-bind(props.color);
  border-bottom-color: v-bind(props.color);
  border-radius: 50%;
  box-sizing: border-box;
  content: '';
  display: block;
  height: 0.8em;
  width: 0.8em;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
