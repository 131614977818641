<template>
  <div class="details-container">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'DetailsContainer',
});
export default comp;
</script>

<style lang="postcss" scoped>
.details-container {
  width: 34rem;
  text-align: center;
  align-items: center;
  padding: var(--space-32) var(--space-32) 0;

  :deep(hr) {
    width: 100%;
    border-top: 1px solid var(--border);
    margin-bottom: var(--space-24);
  }

  :deep(section) {
    width: 100%;
    text-align: left;
    font-size: var(--x14);
    margin-bottom: var(--space-16);

    p.section-title {
      font-size: var(--x14);
      margin-bottom: var(--space-8);
    }

    :deep(textarea) {
      resize: none;
      height: var(--space-72);
    }

    button {
      transition: none;
      margin: auto;
    }
  }

  :deep(.validate) {
    margin-top: var(--space-4);
    display: flex;
    text-decoration: underline;
    color: var(--action-500);
    align-items: center;
    cursor: pointer;

    svg {
      margin: var(--space-4) 0 0 var(--space-8);
    }
  }

  :deep(input.compact-input) {
    width: 100%;
    height: var(--space-40);
    border: 1px solid var(--border);
    border-radius: var(--round-corner-small);
    padding: 0 0.75rem;
    color: var(--text-primary);
    font-size: var(--x14);
  }

  :deep(input.popup-input) {
    margin: var(--space-16) 0 13.5rem;
    width: 100%;
    height: var(--space-40);
    border: 1px solid var(--border);
    border-radius: var(--round-corner-small);
    padding: 0 var(--space-8);
    color: var(--text-primary);
    font-size: var(--x14);
  }

  :deep(.publishOptions) {
    display: flex;
    flex-flow: column;
    align-self: stretch;
    font-size: var(--x14);
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
</style>
