<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';

const { expanded, setMounted } = useSecondaryNavigationPanel();

const isExpanded = computed(() => {
  return expanded.value;
});

onMounted(() => {
  setMounted(true);
});

onUnmounted(() => {
  setMounted(false);
});
</script>

<template>
  <div
    class="dash-transition-md fixed left-[var(--primary-navigation-width)] top-0 h-full w-[var(--secondary-navigation-width)] rounded-tl-[40px] bg-[#f9fbff]"
    :class="{ 'invisible opacity-0': !isExpanded }"
  >
    <div class="dash-elevation-2xl relative h-full py-5 xl:shadow-none">
      <div id="sideNavigationPanel" class="flex flex-col items-start justify-center pl-3" />
    </div>
  </div>
</template>
