<template>
  <div>
    <Dropzone
      ref="dropzone"
      :items-changed="mediaSelected"
      :brand-ids="[authStore.currentBrand.id]"
    >
      <section :class="['media-container', type]">
        <template v-if="mediaList.length > 0">
          <div class="aspect-ratio-container">
            <RadialProgressBar
              v-if="
                mediaList[0].uploadStatus && mediaList[0].uploadStatus !== UPLOAD_STATUS.SUCCESS
              "
              :completed-steps="mediaList[0].uploadProgress"
              :diameter="100"
              :total-steps="100"
              class="progress"
              start-color="white"
              stop-color="white"
              inner-stroke-color="lightGrey"
            />

            <template v-if="isVideo(mediaList)">
              <video
                id="coverVideo"
                ref="coverVideo"
                :src="getMediaUrl(mediaList)"
                class="media"
                @loadstart="loadVideoWithOffset"
              />
            </template>
            <template v-else>
              <img :src="getMediaUrl(mediaList)" class="media" @load="mediaLoaded = true" />
            </template>
          </div>

          <!-- Provide the ability to restrict the tagging area to image size -->
          <TaggerContainer
            v-if="showLinkView && mediaLoaded"
            :tagger-component="taggerComponent"
            :tagger-component-props="{
              presetLinks: links,
              updateLinkView,
              linkLimit: linkLimit,
            }"
            :media-width="imageSize.width"
            :media-height="imageSize.height"
          />
        </template>
        <div v-if="enableOperation" class="control-group">
          <Button
            v-if="mediaList.length"
            :icon-color="buttonIconColor"
            :icon-hover-color="buttonIconHoverColor"
            icon-name="bin"
            rectangular
            @click="removeItems"
          />

          <DropdownButton
            :dropdown-list="addDropdownList"
            :button-icon-color="buttonIconColor"
            :button-icon-hover-color="buttonIconHoverColor"
            align-right
            button-classes="rectangular"
            button-icon="add"
            @dropdown-action-clicked="addActionClicked"
          />
        </div>
      </section>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Dropzone from '@/app/library/components/Dropzone.vue';
import TaggerContainer from '@/components/TaggerContainer.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { useAuthStore } from '@/stores/auth';
import LinkView from '../LinkView.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MediaView',
  components: {
    Button,
    DropdownButton,
    Dropzone,
    TaggerContainer,
    RadialProgressBar,
  },
  props: {
    showLinkView: { type: Boolean, default: false },
    enableOperation: { type: Boolean, default: true },
    mediaLinks: { type: Array, default: null },
    mediaList: { type: Array, required: true },
    mediaSelected: { type: Function, required: true },
    type: { type: String, default: 'square' },
    linkLimit: { type: Number, default: null },
  },
  emits: ['updateLinks'],
  data() {
    return {
      ...{ UPLOAD_STATUS },
      showSelectMediaPopup: false,
      links: this.mediaLinks,
      buttonIconColor: colours.ICON.ICON_PRIMARY,
      buttonIconHoverColor: colours.ICON.ICON_LINK,
      taggerComponent: LinkView,
      mediaLoaded: false,
      addDropdownList: [
        {
          text: 'From Library',
          action: 'openSelectMediaPopup',
        },
        {
          text: 'From Your Computer',
          action: 'uploadClicked',
        },
      ],
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useAuthStore),
    imageSize() {
      // If media is available, return width and height of the media
      let mediaWidth = 0;
      let mediaHeight = 0;

      if (this.mediaList[0]) {
        const media = this.mediaList[0];
        if (media.mediaType === 'VIDEO' || media.media_type === 'VIDEO' || media.type === 'VIDEO') {
          if (media.imageSizes && media.imageSizes.small) {
            mediaWidth = media.imageSizes.small.width;
            mediaHeight = media.imageSizes.small.height;
          } else if (media.urls && media.urls.imageSizes && media.urls.imageSizes.small) {
            mediaWidth = media.urls.imageSizes.small.width;
            mediaHeight = media.urls.imageSizes.small.height;
          } else if (media.thumbnails) {
            // MediaV2
            mediaWidth = media.thumbnails.small.width;
            mediaHeight = media.thumbnails.small.height;
          } else {
            mediaWidth = media.width;
            mediaHeight = media.height;
          }
        } else if (
          media.mediaType === 'IMAGE' ||
          media.media_type === 'IMAGE' ||
          media.type === 'IMAGE'
        ) {
          if (media.imageSizes) {
            mediaWidth = media.imageSizes.originalConverted.width;
            mediaHeight = media.imageSizes.originalConverted.height;
          } else if (media.urls && media.urls.imageSizes) {
            mediaWidth = media.urls.imageSizes.originalConverted.width;
            mediaHeight = media.urls.imageSizes.originalConverted.height;
          } else if (media.sizes) {
            // MediaV2
            const imageData = media.sizes.originalConverted || media.sizes.original;
            mediaWidth = imageData.width;
            mediaHeight = imageData.height;
          } else if (media.image?.sizes) {
            // GalleryMediaV2
            const imageData = media.image.sizes.originalConverted || media.image.sizes.original;
            mediaWidth = imageData.width;
            mediaHeight = imageData.height;
          } else {
            mediaWidth = media.width;
            mediaHeight = media.height;
          }
        }
      }

      return { width: mediaWidth, height: mediaHeight };
    },
  },
  watch: {
    mediaLinks(to) {
      if (to) {
        this.links = to;
      }
    },
  },
  methods: {
    loadVideoWithOffset() {
      this.mediaLoaded = true;
      this.$refs.coverVideo.currentTime = this.schedulerStore.thumbOffset;
    },
    isVideo(mediaList) {
      return (
        mediaList[0].mediaType === 'VIDEO' ||
        mediaList[0].media_type === 'VIDEO' ||
        mediaList[0].type === 'VIDEO'
      );
    },
    getMediaUrl(mediaList) {
      if (this.isVideo(mediaList)) {
        if (mediaList[0].sizes) {
          // MediaModel Usage
          return mediaList[0].sizes.originalConverted
            ? mediaList[0].sizes.originalConverted.url
            : mediaList[0].sizes.original.url;
        }
        if (mediaList[0].video) {
          // Video size from GalleryMediaV2
          return mediaList[0].video.sizes.originalConverted
            ? mediaList[0].video.sizes.originalConverted.url
            : mediaList[0].video.sizes.original.url;
        }
        return this.mediaList[0].urls.full || this.mediaList[0].urls.original;
      }
      // return the image url because link positions are set relative to full size media file.
      return (
        // Sizes from MediaV1
        (mediaList[0].urls && (mediaList[0].urls.full || mediaList[0].urls.original)) ||
        // Sizes from MediaModel
        (mediaList[0].sizes &&
          (mediaList[0].sizes.originalConverted.url || mediaList[0].sizes.original.url)) ||
        // Sizes from GalleryMediaV2
        (mediaList[0].image.sizes &&
          (mediaList[0].image.sizes.originalConverted?.url ||
            mediaList[0].image.sizes.original.url)) ||
        mediaList[0].url
      );
    },
    addActionClicked(option) {
      this[option]();
    },
    uploadClicked() {
      this.$refs.dropzone.openFileDialog();
    },
    openSelectMediaPopup() {
      this.showSelectMediaPopup = true;
    },
    closeSelectMediaPopup() {
      this.showSelectMediaPopup = false;
    },
    removeItems() {
      this.$refs.dropzone.reset();
    },
    updateLinkView(links) {
      this.links = links;
      this.$emit('updateLinks', links);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.aspect-ratio-container {
  width: 100%;
  height: 100%;
}

.square {
  aspect-ratio: 1 / 1;
}

.portrait {
  aspect-ratio: 9 / 16;
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url('@/assets/img/logos/white-dh.png') no-repeat center var(--background-500);
  border-radius: var(--round-corner-small);
  height: 100%;
}

.media {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.progress {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-group {
  position: absolute;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  right: var(--space-16);
  bottom: var(--space-16);

  .dropdown-button {
    margin-left: var(--space-8);
  }
}
</style>
