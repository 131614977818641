export const FACEBOOK_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'REACTIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ORGANIC_COMMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'SHARES',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['FACEBOOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['FACEBOOK'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['FACEBOOK'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['FACEBOOK'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      name: 'Post Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
