import snakeCase from 'lodash/snakeCase';

// due to pinterest metric renames occurring. The old backend metric Enums remain the same
const metricKeyOverrides = {
  CLOSEUPS: 'PIN_CLICKS',
  LINK_CLICKS: 'OUTBOUND_CLICKS',
};

export function getMetricKeyOverride(metric) {
  return metricKeyOverrides[snakeCase(metric).toUpperCase()] || metric;
}
