import { defineStore } from 'pinia';
import { ref } from 'vue';
import { detectTextLanguage, translateTextLanguage } from '@/apis/library-intelligence';

const DEFAULT_LANGUAGE = 'en';

export const useTranslationStore = defineStore('translation', () => {
  const preferredLanguage = ref(DEFAULT_LANGUAGE);
  async function detectLanguage(texts) {
    const res = await detectTextLanguage({
      texts,
    });
    return res.data?.texts || [];
  }

  async function translateLanguage(texts) {
    const res = await translateTextLanguage({
      texts,
    });
    return res.data?.texts;
  }

  return {
    detectLanguage,
    translateLanguage,
    preferredLanguage,
  };
});
