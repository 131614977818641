<template>
  <div :class="['upload-media-list', { 'single-media-view': singleMediaView }]">
    <ListViewerItem
      v-for="(item, index) in value"
      :key="index"
      :crop-clicked="() => cropClicked(item, index)"
      :disable-editing="disableEditing"
      :download-media-clicked="downloadMediaClicked"
      :media="item"
      :remove="removeClicked"
      :single-media-view="singleMediaView"
      :validation-error="validationError(index)"
      :platform="platform"
      :class="{ 'has-warning': mediaWarningIds.includes(item.id) }"
      :alt-text-media-map="altTextMediaMap"
      :save-alt-text="saveAltText"
      :creating-brand-media="creatingBrandMedia"
    />

    <DropdownButton
      v-if="!disableEditing && !singleMediaView && allowAdd"
      v-tooltip="'Add media'"
      :dropdown-list="addDropdownList"
      :button-icon-color="addIconColor"
      button-classes="rectangular"
      button-icon="addImageFile"
      class="add-button"
      data-cy="list-viewer-add-media"
      @dropdown-action-clicked="addActionClicked"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { colours } from '@/ux/colours';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { useCropperStore } from '@/stores/cropper';
import { useAuthStore } from '@/stores/auth';
import { BRAND } from '@/models/auth/permissions.enum';
import ListViewerItem from './ListViewerItem.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ListViewer',
  components: {
    DropdownButton,
    ListViewerItem,
  },
  props: {
    cropClicked: { type: Function, default: () => {} },
    disableEditing: { type: Boolean, default: false },
    downloadMediaClicked: { type: Function, default: () => {} },
    allowAdd: { type: Boolean, default: true },
    libraryClicked: { type: Function, required: true },
    value: { type: Array, required: true },
    mediaSelected: { type: Function, required: true },
    mediaValidationErrorList: { type: Array, default: null },
    mediaWarningIds: { type: Array, default: () => [] },
    platform: { type: String, default: null },
    removeClicked: { type: Function, required: true },
    uploadClicked: { type: Function, required: true },
    altTextMediaMap: { type: Object, default: null },
    saveAltText: { type: Function, default: () => {} },
    creatingBrandMedia: { type: Boolean, default: false },
  },
  data() {
    return {
      addIconColor: colours.ICON.ICON_SECONDARY,
      media: cloneDeep(this.value),
    };
  },
  computed: {
    ...mapStores(useCropperStore, useAuthStore),
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    addDropdownList() {
      const fromLibrary = this.hasLibraryAccess
        ? [
            {
              text: 'From Library',
              action: 'libraryClicked',
            },
          ]
        : [];
      return fromLibrary.concat([
        {
          text: 'From Your Computer',
          action: 'uploadClicked',
        },
      ]);
    },
    singleMediaView() {
      return (
        this.platform === 'facebook' && this.value.length === 1 && this.value[0].type === 'VIDEO'
      );
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && !isEqual(this.media, newVal)) {
        // Handle asynchronous call for MediaV2 data to be passed through props
        this.media = cloneDeep(newVal);
      }
    },
  },
  methods: {
    addActionClicked(option) {
      this[option]();
    },
    validationError(index) {
      return this.platform && this.mediaValidationErrorList && this.mediaValidationErrorList[index];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.upload-media-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--space-80));
  grid-gap: var(--space-12);

  &.single-media-view {
    display: flex;
  }

  .has-warning :deep(.media-container.thumbnail) {
    border: 1.5px solid var(--alert-500);
  }
}

.add-button {
  :deep(.button) {
    width: var(--space-80);
    height: var(--space-80);
    border: dashed 1px var(--border);
    transition: all 0.3s;

    &:hover {
      background: #fdfdfd;
      border-color: var(--action-500);
    }
  }

  :deep(.icon) {
    width: var(--space-24) !important;
    height: var(--space-24) !important;
  }

  :deep(.dropdown-list) {
    top: 5.5rem;
  }
}
</style>
