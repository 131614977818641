<template>
  <div class="pin-list-container">
    <Banner v-if="containsVideoPin" alert-type="information" class="info-alert">
      <span v-sanitize-html="pinStatusMessage" />
    </Banner>
    <PinListItem
      v-for="(pin, index) in mutablePinList"
      :key="pin.id"
      :is-app-connected="isAppConnected"
      :crop-clicked="() => cropClicked(pin, index)"
      :pin="pin"
      :top-popup="positionPopupAtTop(index)"
      :scheduled-time="scheduledTime"
      @pin-updated="pinUpdated"
      @remove="removePin"
      @check-media-format="checkMediaFormat"
    >
      <template #edit-utm-controls>
        <Button :disabled="!pin.linkUrl" class="w-fit" link @click="openUtmPanel(index)">
          {{ utmScheduleOpenEditTrackingButton }}
        </Button>
      </template>
    </PinListItem>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { pinterestVideoProcessInfoMessage } from '@/app/scheduler/constants';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import Button from '@/components/foundation/Button.vue';
import { utmScheduleOpenEditTrackingButton } from '@/app/settings/components/Utm/const';
import PinListItem from './PinListItem.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PinListContainer',
  components: {
    Button,
    PinListItem,
    Banner,
  },
  props: {
    cropClicked: { type: Function, default: () => {} },
    value: { type: Array, default: () => [] },
    scheduledTime: { type: Date, default: null },
    isAppConnected: { type: Boolean, default: false },
  },
  emits: ['input', 'checkMediaFormat', 'openEditorPanel'],
  data() {
    return {
      mutablePinList: this.value,
      utmScheduleOpenEditTrackingButton,
    };
  },
  computed: {
    ...mapStores(useSchedulerPinterestStore),
    containsVideoPin() {
      return this.mutablePinList.length > 0 && this.mutablePinList.some((p) => p.type === 'VIDEO');
    },
    pinStatusMessage() {
      if (this.containsVideoPin) {
        return pinterestVideoProcessInfoMessage;
      }
      return '';
    },
  },
  watch: {
    value: {
      handler(to) {
        this.mutablePinList = to;
      },
      deep: true,
    },
  },
  methods: {
    removePin(pin) {
      this.mutablePinList = this.mutablePinList.filter((p) => p.id !== pin.id);
      this.$emit('input', this.mutablePinList);
    },
    checkMediaFormat() {
      this.$emit('checkMediaFormat');
    },
    pinUpdated(pin) {
      const targetIndex = this.mutablePinList.map((p) => p.id).indexOf(pin.id);
      this.mutablePinList.splice(targetIndex, 1, pin);
      this.$emit('input', this.mutablePinList);
      this.schedulerPinterestStore.triggerPinUpdate();
    },
    positionPopupAtTop(index) {
      // position popup at top for the last item if there are more than one item.
      return this.mutablePinList.length > 1 && index === this.mutablePinList.length - 1;
    },
    openUtmPanel(index) {
      this.$emit('openEditorPanel', index);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.pin-list-container {
  padding: var(--space-16) var(--space-32);
  height: 100%;
  width: 100%;

  .info-alert {
    margin-bottom: var(--space-16);

    :deep(a) {
      color: var(--action-500);
      font-weight: var(--font-medium);
    }
  }
}
</style>
