/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'folder': {
    width: 34,
    height: 29,
    viewBox: '0 0 34 29',
    data: '<path pid="0" d="M30.203 5.667h-13.75L13.703 2A3.348 3.348 0 0011.036.667h-7.5A3.338 3.338 0 00.203 4v21.667A3.337 3.337 0 003.536 29h26.667a3.337 3.337 0 003.333-3.333V9a3.338 3.338 0 00-3.333-3.333zm-26.667 20V4h7.5l2.75 3.667A3.348 3.348 0 0016.453 9h13.75l.001 16.667H3.536z" _fill="#adadad" fill-rule="nonzero"/>'
  }
})
