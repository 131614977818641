<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import { Avatar } from '@dashhudson/dashing-ui';
import { notificationDescriptionsByType } from '@/config';
import CommentText from '@/components/CommentingModule/CommentText.vue';
import { useCommentingStore } from '@/stores/commenting';
import {
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES,
} from '@/app/community/constants';
import {
  SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE,
  SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE,
  SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES,
} from '@/app/scheduler/constants';
import { getUserInitials, getFormattedUserName } from '@/utils/user';

const props = defineProps({
  notification: { type: Object, required: true },
  brand: { type: Object, required: true },
});

const commentingStore = useCommentingStore();

const comment = computed(() => {
  return camelizeKeys(props.notification.comment);
});

const author = computed(() => {
  return commentingStore?.users[props.notification.comment.userId];
});

const avatarDetails = computed(() => {
  return camelizeKeys(author.value);
});

const authorInitials = computed(() => {
  return getUserInitials(author.value);
});

const authorFullName = computed(() => {
  return getFormattedUserName(author.value);
});

const shouldHideText = computed(() => {
  const HIDDEN_RESOURCE_TYPES = [
    COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT,
    ...Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE),
    ...Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE),
  ];

  return HIDDEN_RESOURCE_TYPES.includes(comment.value.resourceType);
});

const description = computed(() => {
  const resourceType = comment.value.resourceType;
  if (resourceType === COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT) {
    return COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES[
      COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT
    ];
  }
  if (resourceType === COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE) {
    return COMMUNITY_INTERNAL_NOTIFICATION_MESSAGES[
      COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE
    ];
  }
  if (Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE).includes(resourceType)) {
    return SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES.APPROVAL_REQUEST;
  }
  if (Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE).includes(resourceType)) {
    return SCHEDULER_APPROVALS_NOTIFICATION_MESSAGES.REQUEST_APPROVED;
  }
  return notificationDescriptionsByType[props.notification.type];
});

const timeAgo = computed(() => {
  const time = dayjs.utc(props.notification.createdAt);
  if (dayjs.duration(dayjs().diff(time)).asSeconds() < 60) {
    return 'Just now';
  }
  return time.fromNow();
});
</script>

<template>
  <div
    class="grid cursor-pointer gap-2 px-6 py-4"
    :class="{ 'dash-bg-background-page': !notification.readAt }"
  >
    <div class="flex items-center gap-2">
      <Avatar
        :image="avatarDetails?.avatarUrl"
        :label="avatarDetails?.avatarUrl ? null : authorInitials"
        size="sm"
        class="dash-text-subtle"
      />
      <span class="dash-text-subtle">{{ brand.name }}</span>
    </div>

    <p>
      <strong>{{ authorFullName }}</strong>
      <span>&nbsp;{{ description }}<span v-if="!shouldHideText">:</span>&nbsp;</span>
      <CommentText
        v-if="!shouldHideText"
        :comment="props.notification.comment"
        :users="commentingStore.users"
        :truncate-to="100"
      />
    </p>
    <time :datetime="props.notification.createdAt" class="dash-text-subtle">{{ timeAgo }}</time>
  </div>
</template>
