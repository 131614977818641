<script setup>
import { toRefs } from 'vue';

import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';

import { useChiliPiperStore } from '@/stores/chili-piper';
import { useTrackingStore } from '@/stores/tracking';

import { ACTION, colours } from '@/ux/colours';

const trackingStore = useTrackingStore();
const chiliPiperStore = useChiliPiperStore();

const checkColor = colours.TEXT.TEXT_LINK;

const props = defineProps({
  upgradeCtaConfig: {
    type: Object,
    required: true,
    /**
     * badge: 'Upgrade',
     * bulletPoints: ['foo', 'bar'],
     * cta: 'Upgrade Now'
     * chiliPiperFormName: CHILI_PIPER_FORMS_MAP.advance.upgrade.name,
     * chiliPiperFormRouter: CHILI_PIPER_FORMS_MAP.advance.upgrade.router,
     * mixpanelEventName: 'Upgrade clicked',
     * title: 'Upgrade your package'
     */
  },
});
const { upgradeCtaConfig } = toRefs(props);

function ctaButtonClicked() {
  const formRouter = upgradeCtaConfig.value.chiliPiperFormRouter;
  const formName = upgradeCtaConfig.value.chiliPiperFormName;
  chiliPiperStore.submit(formRouter, formName, upgradeCtaConfig.value.cta);
  trackingStore.track(upgradeCtaConfig.value.mixpanelEventName);
}
</script>

<template>
  <div class="max-w-[22rem]">
    <Button
      class="cta-add-on-btn mb-6 flex justify-start bg-[var(--action-150)] px-4 py-2 text-sm text-[var(--action-500)]"
      small
      no-border
      no-hover
      @click="ctaButtonClicked"
    >
      <Icon name="lock-2" xsmall :color="ACTION.ACTION_500" class="mr-1" />{{
        upgradeCtaConfig.badge
      }}
    </Button>

    <h4 class="cta-title mb-6 text-[1.375rem] normal-case">{{ upgradeCtaConfig.title }}</h4>
    <div
      v-for="(item, indexKey) in upgradeCtaConfig.bulletPoints"
      :key="indexKey"
      class="cta-bullet-points flex items-center pb-4"
    >
      <div class="mx-1 mt-1 flex self-start">
        <Icon name="check" xxsmall :color="checkColor" />
      </div>
      <p class="mx-1.5 text-sm">
        {{ item }}
      </p>
    </div>

    <div class="mt-6 flex">
      <Button dark-blue class="cta-upgrade-button" @click="ctaButtonClicked">{{
        upgradeCtaConfig.cta
      }}</Button>
    </div>
  </div>
</template>
