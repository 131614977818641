/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'videosTop': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<defs><path pid="0" d="M19 14.585l4.707 4.707-1.414 1.414L20 18.413V24h-2v-5.587l-2.293 2.293-1.414-1.414L19 14.585zM13.414 0L18 4.586v7.418h-1.999L16 6h-4V2H2v18h10v2H2a2.002 2.002 0 01-1.995-1.85L0 20V2C0 .948.817.082 1.85.006L2 0h11.414zM6 9l7 4-7 4V9z" id="svgicon_videosTop_a"/></defs><g transform="translate(.707)" _fill="none" fill-rule="evenodd"><mask id="svgicon_videosTop_b" _fill="#fff"><use xlink:href="#svgicon_videosTop_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_videosTop_a"/><g mask="url(#svgicon_videosTop_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
