import { colours } from '@/ux/colours';

export const SENTIMENTS = Object.freeze({
  POSITIVE: {
    value: 'POSITIVE',
    text: 'Positive',
    icon: 'smiley-smile-1',
    iconColour: colours.SUCCESS.SUCCESS_500,
    severity: 'positive',
  },
  NEUTRAL: {
    value: 'NEUTRAL',
    text: 'Neutral',
    icon: 'smiley-poker-face',
    iconColour: colours.ACTION.ACTION_500,
    severity: 'neutral',
  },
  NEGATIVE: {
    value: 'NEGATIVE',
    text: 'Negative',
    icon: 'smiley-frown-1',
    iconColour: colours.ERROR.ERROR_500,
    severity: 'negative',
  },
});
