/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'graph': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 3v16h16v2H4a1 1 0 01-1-1V3h2z" _fill="#686a7a"/><path pid="1" d="M19.293 7.292l1.414 1.414-6 6a.999.999 0 01-1.414 0L11 12.414l-3.293 3.293-1.414-1.414 4-4a.999.999 0 011.414 0L14 12.586l5.293-5.294z" _fill="#686a7a"/>'
  }
})
