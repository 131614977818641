/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magic-wand-1-add-filled': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.384 3.414L19.088 8.19l3.71 3.709-5.064-.822-2.522 5.001-.887-5.604-5.605-.888 5.001-2.522L12.899 2l3.71 3.71 4.775-2.296zM3 20.385L4.415 21.8l8.484-8.485-1.414-1.414L3 20.385z" _fill="#fff"/><mask id="svgicon_magic-wand-1-add-filled_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="2" width="20" height="20"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21.384 3.414L19.088 8.19l3.71 3.709-5.064-.822-2.522 5.001-.887-5.604-5.605-.888 5.001-2.522L12.899 2l3.71 3.71 4.775-2.296zM3 20.385L4.415 21.8l8.484-8.485-1.414-1.414L3 20.385z" _fill="#fff"/></mask><g mask="url(#svgicon_magic-wand-1-add-filled_a)"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></g><path pid="3" d="M24 19h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z" _fill="#fff"/>'
  }
})
