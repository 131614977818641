/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'navigationNext': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 5.587l-1.414 1.414 2 2H12c-2.205 0-4 1.794-4 4v1h2v-1c0-1.102.896-2 2-2h5.586l-2 2L17 14.416l4.414-4.414L17 5.587z"/><path pid="1" d="M13 18.001H4v-12h11v-2H2v16h13v-5h-2z"/>'
  }
})
