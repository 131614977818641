import { UserEventTracker } from '@/utils/analytics/tracking';

export class AuthEventTracker extends UserEventTracker {
  EVENT_NAMES = {
    SET_PASSWORD_INTERACTION: 'Set Password Interaction',
    ACCOUNT_CREATION_SET_PASSWORD: 'Account Creation - Set Password',
    PASSWORD_RESET_FAILED: 'Password Reset Failed',
    PASSWORD_RESET_SUCCESS: 'Password Reset Success',
    LOGIN: 'Login',
    MFA_VERIFICATION: 'MFA Verification',
    MFA_VERIFICATION_FAILED: 'MFA Verification Failed',
    LOGGED_ON: 'Logged on',
    LOGIN_FAILED: 'Login Failed',
    ACCOUNT_CREATED: 'Account Creation - Account Created',
    ONBOARDING_FLOW_QUESTIONS: 'Onboarding Flow Questions',
  };

  setPasswordInteraction(buttonClicked, linkUrl) {
    this.trackingStore.track(this.EVENT_NAMES.SET_PASSWORD_INTERACTION, { buttonClicked, linkUrl });
  }

  accountCreationSetPassword({
    method,
    successful,
    userId = null,
    brandId = null,
    brandName = null,
    customerStage = null,
    planType = null,
  }) {
    this.trackingStore.track(this.EVENT_NAMES.ACCOUNT_CREATION_SET_PASSWORD, {
      method,
      successful,
      $user_id: userId,
      brand_id: brandId,
      distinct_id: userId,
      brandName,
      customerStage,
      planType,
    });
  }

  passwordResetStatus({ success, email, message }) {
    const payload = success ? { email, message } : { email, error: message };
    const event = success
      ? this.EVENT_NAMES.PASSWORD_RESET_SUCCESS
      : this.EVENT_NAMES.PASSWORD_RESET_FAILED;
    this.trackingStore.track(event, payload);
  }

  login({ email, method, plgSetPassword }) {
    this.trackingStore.track(this.EVENT_NAMES.LOGIN, { email, method, plgSetPassword });
  }

  loggedOn({ method, enforcedLoginMethod }) {
    this.trackingStore.track(this.EVENT_NAMES.LOGGED_ON, { method, enforcedLoginMethod });
  }

  loginFailed({ email, error, method }) {
    this.trackingStore.track(this.EVENT_NAMES.LOGIN_FAILED, { email, error, method });
  }

  mfaVerification({ email, setup = false }) {
    this.trackingStore.track(this.EVENT_NAMES.MFA_VERIFICATION, { email, mfaSetup: setup });
  }

  mfaVerificationFailed({ email, error, setup = false }) {
    this.trackingStore.track(this.EVENT_NAMES.MFA_VERIFICATION_FAILED, {
      email,
      error,
      mfaSetup: setup,
    });
  }

  accountCreated({
    userId = null,
    brandId = null,
    brandName = null,
    customerStage = null,
    planType = null,
    page,
  }) {
    this.trackingStore.track(this.EVENT_NAMES.ACCOUNT_CREATED, {
      $user_id: userId,
      brand_id: brandId,
      distinct_id: userId,
      brandName,
      customerStage,
      planType,
      page,
    });
  }

  onboardingFlowQuestions({
    questionKey = null,
    questionResponse = null,
    teamInvites = null,
    platformConnectedStatusList = null,
    buttonText = null,
  }) {
    this.trackingStore.track(this.EVENT_NAMES.ONBOARDING_FLOW_QUESTIONS, {
      questionKey,
      questionResponse,
      teamInvites,
      platformConnectedStatusList,
      buttonText,
    });
  }
}
