import {
  COMMUNITY_INTERACTION_FILTER_WIDGETS,
  COMMUNITY_INTERACTION_FILTERS,
} from '@/app/community/constants';
import { parseBoolean } from '@/app/community/utils/store-utils';
import isObject from 'lodash/isObject';

export const MESSAGE_RULE_TYPES = {
  FILTER: 'FILTER',
  ACTION: 'ACTION',
};

export const MESSAGE_RULE_OPERATOR_TYPES = {
  AND: 'AND',
  OR: 'OR',
};

const filterOptions = (filterType) => {
  return COMMUNITY_INTERACTION_FILTER_WIDGETS[filterType].options?.filterOptions;
};

export const tagOptionFormatter = (tag) => {
  return {
    label: tag.name,
    icon: {
      name: 'dot',
      color: tag.color,
    },
    value: tag.id,
    tooltip: true,
  };
};

const tagWidget = {
  options: [],
  formatOption: (option) => tagOptionFormatter(option),
};

export const MESSAGE_RULE_WIDGET_TYPES = {
  SELECT: 'SELECT',
  INPUT: 'INPUT',
  SELECT_INPUT: 'SELECT_INPUT',
};

export const MESSAGE_RULE_FILTER_OPTIONS = {
  CHANNEL: {
    label: 'Channel',
    labelOperator: 'is',
    key: 'platform',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT,
      options: filterOptions(COMMUNITY_INTERACTION_FILTERS.FILTER_PLATFORM),
      formatValue: (value) => value?.value,
    },
  },
  KEYWORD: {
    label: 'Keyword',
    labelOperator: 'is',
    key: 'text',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.INPUT,
    },
  },
  MESSAGE_TYPE: {
    label: 'Message Type',
    labelOperator: 'is',
    key: 'message_type',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT,
      options: filterOptions(COMMUNITY_INTERACTION_FILTERS.FILTER_MESSAGE_TYPE),
      formatValue: (value) => value?.value,
    },
  },
  QUESTION: {
    label: 'Question',
    labelOperator: 'is',
    key: 'is_question',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT,
      options: filterOptions(COMMUNITY_INTERACTION_FILTERS.FILTER_QUESTIONS).map((v) => ({
        ...v,
        value: parseBoolean(v.value),
      })),
      formatValue: (value) => value?.value,
    },
  },
  REPLIED: {
    label: 'Replied',
    key: 'is_replied',
    value: true,
  },
  SENTIMENT: {
    label: 'Sentiment',
    labelOperator: 'is',
    key: 'sentiment',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT,
      options: filterOptions(COMMUNITY_INTERACTION_FILTERS.FILTER_SENTIMENT),
      formatValue: (value) => value?.value,
    },
  },
  TAG: {
    label: 'Tag',
    labelOperator: 'is',
    key: 'tag',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT_INPUT,
      ...tagWidget,
      formatValue: (value) => ({ [`${value.value}`]: value.label }),
      getValue: (value) => (isObject(value) ? Object.keys(value)[0] : value),
    },
  },
};

export const MESSAGE_RULE_ACTION_OPTIONS = {
  ARCHIVE: {
    label: 'Archive',
    key: 'archive',
    value: true,
  },
  TAG: {
    label: 'Tag',
    key: 'tags',
    widget: {
      type: MESSAGE_RULE_WIDGET_TYPES.SELECT_INPUT,
      ...tagWidget,
      formatValue: (value) => [{ [`${value.value}`]: value.label }],
      getValue: (value) => (isObject(value[0]) ? Object.keys(value[0])[0] : value),
    },
  },
};

export const MESSAGE_RULE_INIT_OPTIONS = {
  [MESSAGE_RULE_TYPES.FILTER]: {
    label: 'Add Filter',
    optionsMap: MESSAGE_RULE_FILTER_OPTIONS,
  },
  [MESSAGE_RULE_TYPES.ACTION]: {
    label: 'Add Action',
    optionsMap: MESSAGE_RULE_ACTION_OPTIONS,
  },
};

export const messageRuleFilterByKey = (key) => {
  return Object.values(MESSAGE_RULE_FILTER_OPTIONS).find((o) => o.key === key);
};

export const MESSAGE_RULES_POPUP_MESSAGES = {
  EMPTY_STATE: 'Automate your Community inbox',
};

export const DEPRECATED_RULE_NAME = 'Archive by Message Type Preset';

export const MESSAGE_RULE_BUTTON_TOOLTIP =
  'Create custom automations to action messages in your Community inbox';

export const RULE_EXECUTION_NOTIFICATION_SUBSTRINGS = {
  ARCHIVE: 'moved to Archive',
  TAGGED: 'has been Tagged',
};
export const CREATE_RULE_DISABLED_TOOLTIP = "You've reached your limit of 10 message rules";

export const MAX_MESSAGE_RULE = 10;

export const getRuleItemLabel = (key, isFilter = true) => {
  const options = isFilter ? MESSAGE_RULE_FILTER_OPTIONS : MESSAGE_RULE_ACTION_OPTIONS;
  const item = Object.values(options).find((o) => o.key === key);

  return item?.label;
};

export const MESSAGE_RULE_SAVE_OPTIONS = {
  SAVE: 'Save',
  SAVE_AND_APPLY_TO_EXISTING: 'Save & Apply to Existing',
};

export const MESSAGE_RULE_MIXPANEL_CONSTANTS = {
  RULE_BUILDER_OPENED_FROM: {
    CREATE: 'Create Rule Button Clicked',
    EDIT: 'Edit Button Clicked',
    EDIT_FROM_MESSAGE: 'Edit Button from Message Clicked',
  },
  MESSAGE_RULE_INTERACTIONS: {
    EDIT: 'Edit',
    EDIT_FROM_MESSAGE: 'Edit From Message',
    DELETE: 'Delete',
    VIEW_ALL: 'View All',
    TOGGLE_ON: 'Toggle On',
    TOGGLE_OFF: 'Toggle Off',
  },
};
export const COMMUNITY_INTERACTION_RULE_TOASTS = {
  SUCCESS: 'Your message rule has been saved',
  SUCCESS_REACTIVATED: 'Your message rules have been saved and re-activated',
  APPLY_TO_EXISTING: 'It may take a few moments to apply it to all messages.',
  FAILURE: 'There was an issue saving your message rules, please try again',
  DELETED: {
    SUCCESS: 'Your rule has been deleted',
    FAILURE: 'There was an issue deleting your rule. Please try again.',
  },
  UNDO: {
    SUCCESS: 'Undo successful. Message restored to its original state in your Inbox.',
    ERROR: 'Undo failed. Please try again.',
  },
};

export const messageRuleToggleToast = (to) => {
  return `Your message rule has been ${to ? 'enabled' : 'disabled'}`;
};
