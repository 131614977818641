<template>
  <div>
    <MessagePopup
      v-if="notificationStore.messagePopupProps['showMessagePopup']"
      :hide-message-popup="
        () => {
          notificationStore.setMessagePopup({ showMessagePopup: false });
        }
      "
      :click-handle="
        () => {
          notificationStore.messagePopupProps['clickHandle']
            ? notificationStore.messagePopupProps['clickHandle']()
            : notificationStore.setMessagePopup({ showMessagePopup: false });
        }
      "
      :message="notificationStore.messagePopupProps['message']"
      :title="notificationStore.messagePopupProps['title']"
      :button-text="buttonText"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import MessagePopup from '@/components/MessagePopup.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SharedMessagePopup',
  components: {
    MessagePopup,
  },
  computed: {
    ...mapStores(useNotificationStore),
    buttonText() {
      return this.notificationStore.messagePopupProps.buttonText
        ? this.notificationStore.messagePopupProps.buttonText
        : 'Got it';
    },
  },
});
export default comp;
</script>
