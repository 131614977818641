/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#686a7a" d="M15.293 15.293L13 17.586V2h-2v15.586l-2.293-2.293-1.414 1.413L12 21.414l4.707-4.708z"/>'
  }
})
