<template>
  <div>
    <div
      v-on-click-outside="() => (showEditButton = false)"
      :data-id="`comment-${comment.id}`"
      :class="[
        'comment-container',
        { highlight },
        { reply: commentType === commentAndNotificationTypes.COMMENT_REPLY },
      ]"
      @mouseenter="showEditButton = true"
      @mouseleave="handleMouseLeave"
    >
      <header class="comment-header">
        <CommentAuthorDetails
          v-if="!comment.deletedAt"
          :comment-author="commentAuthor"
          :comment-created-at="comment.createdAt"
        />
        <div v-else class="deleted-comment">
          <Icon name="bin" />
          <span>This comment was removed.</span>
        </div>
        <div class="edit-dropdown-wrapper">
          <DropdownButton
            ref="editDropdown"
            :dropdown-list="dropdownOptions"
            button-classes="corner"
            align-right
            button-icon="ellipsis"
            :class="[{ editable: isEditable }, 'edit-button']"
          />
        </div>
      </header>
      <div v-if="!comment._composing_edit && !comment.deletedAt" class="comment-content">
        <CommentText :comment="comment" :users="commentingStore.users" />
        <span v-if="comment.editedAt" class="comment-edited">&nbsp;(edited)</span>
      </div>
      <div
        v-if="commentType === commentAndNotificationTypes.COMMENT && !comment._composing_edit"
        class="reply-button"
      >
        <a @click.prevent="clickReply(comment.id)">Reply</a>
      </div>
    </div>
    <Composer
      v-if="comment._composing_edit"
      :id="`edit-composer-${comment.id}`"
      :composer-type="COMPOSER_TYPES.edit.label"
      focus-on-mount
      :value="comment._edit_text"
      :clear-on-submit="false"
      :submit-disabled="
        comment._saving || !comment._edit_text || comment._edit_text === comment.text
      "
      :editor-disabled="comment._saving"
      @input="commentingStore.setEditText({ id: comment.id, value: $event })"
      @cancel="commentingStore.cancelEdit({ id: comment.id })"
      @submit="submitEdit"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { commentAndNotificationTypes, COMPOSER_TYPES } from '@/config';
import { containsMention } from '@/utils/commenting';
import { extractUrlsFromText } from '@/utils';
import CommentAuthorDetails from '@/components/CommentingModule/CommentAuthorDetails.vue';
import CommentText from '@/components/CommentingModule/CommentText.vue';
import Composer from '@/components/CommentingModule/Composer.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import Icon from '@/components/foundation/Icon.vue';
import { useCommentingStore } from '@/stores/commenting';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Comment',
  components: {
    CommentAuthorDetails,
    CommentText,
    Composer,
    DropdownButton,
    Icon,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    comment: { type: Object, required: true },
    isVisible: { type: Boolean, required: true },
    highlight: { type: Boolean, default: false },
  },
  emits: ['edit-clicked', 'delete-clicked', 'cancel-edit', 'reply-clicked'],
  data() {
    return {
      showEditButton: false,
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['identity']),
    ...mapStores(useCommentingStore, useNotificationStore, useTrackingStore),
    commentType() {
      return this.comment.parentId
        ? commentAndNotificationTypes.COMMENT_REPLY
        : commentAndNotificationTypes.COMMENT;
    },
    commentAndNotificationTypes() {
      return commentAndNotificationTypes;
    },
    COMPOSER_TYPES() {
      return COMPOSER_TYPES;
    },
    commentAuthor() {
      return this.commentingStore?.users?.[this.comment.userId];
    },
    isEditable() {
      return (
        this.showEditButton && this.commentAuthor.id === this.identity.id && !this.comment.deletedAt
      );
    },
    dropdownOptions() {
      return [
        {
          text: 'Edit',
          action: () => {
            this.showEditButton = false;
            this.commentingStore.startEdit({ id: this.comment.id });
            this.$emit('edit-clicked', {
              commentId: this.comment.id,
              parentId: this.comment.parentId,
            });
          },
        },
        {
          text: 'Delete',
          action: () => {
            this.showEditButton = false;
            this.$emit('delete-clicked', {
              commentId: this.comment.id,
              parentId: this.comment.parentId,
              commentText: this.comment.text,
            });
          },
        },
      ];
    },
  },
  watch: {
    isVisible(newVal) {
      if (!newVal) {
        // When the comment panel is closed, comments are no longer
        // visible and unsaved edits edits should be discarded.
        this.$emit('cancel-edit');
      }
    },
  },
  methods: {
    sendCommentEditingEvent() {
      this.trackingStore.track('Commenting Editing Comment', {
        'Resource ID': this.comment.resourceId,
        'Resource Type': this.comment.resourceType,
        Reply: this.comment.parentId !== undefined && this.comment.parentId !== null,
        Mention: containsMention(this.comment.text),
        Hyperlink: extractUrlsFromText(this.comment.text).length > 0,
      });
    },
    clickReply(id) {
      this.commentingStore.startReply({ id });
      this.$emit('reply-clicked');
    },
    async submitEdit() {
      await this.commentingStore.updateComment({
        id: this.comment.id,
        text: this.comment._edit_text,
      });
      this.sendCommentEditingEvent();
    },
    handleMouseLeave() {
      if (!this.$refs.editDropdown.dropdownVisible) {
        this.showEditButton = false;
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.comment-container {
  display: flex;
  flex-direction: column;
  font-size: var(--x12);
  color: var(--text-primary);
  line-height: 1rem;
  padding: var(--space-16) var(--space-24);
  transition: background-color 0.4s;

  &.highlight {
    background-color: var(--alert-100);
  }

  .comment-header {
    display: flex;
    align-items: center;

    .edit-dropdown-wrapper {
      margin-left: auto;

      .edit-button {
        cursor: pointer;
        visibility: hidden;

        &.editable {
          visibility: visible;
        }
      }
    }

    .edit-dropdown-wrapper:hover :deep(.svg-icon) {
      fill: var(--action-500) !important;
    }
  }

  .comment-content {
    cursor: text;
    padding: var(--space-8) 0 0 var(--space-32);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    user-select: text;

    .comment-edited {
      color: var(--text-secondary);
    }
  }

  .reply-button {
    padding: var(--space-16) 0 0 var(--space-32);

    a {
      color: var(--text-secondary);
      cursor: pointer;
    }

    a:hover {
      color: var(--action-500);
    }
  }

  &.reply {
    padding-left: var(--space-56);
  }
}

.deleted-comment {
  display: flex;
  align-items: center;

  span {
    color: var(--text-secondary);
    padding-left: var(--space-6);
  }
}
</style>
