import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useSessionStorage } from '@/composables/useSessionStorage';
import { useIdentityStore } from '@/stores/identity';

export const IMPERSONATED_USER_ID_KEY = 'impersonatedUserId';
export const IMPERSONATOR_USER_ID_KEY = 'impersonatorUserId';
const IMPERSONATED_USER_EXPIRY_KEY = 'accountUserExpiry';
const IMPERSONATED_USER_TOKEN_LIMIT_IN_HOURS = 2;

export const useImpersonatorStore = defineStore('impersonator', () => {
  const identityStore = useIdentityStore();

  const impersonatedUserId = useSessionStorage(IMPERSONATED_USER_ID_KEY);
  const impersonatorUserId = useSessionStorage(IMPERSONATOR_USER_ID_KEY);
  const impersonatedUserExpiry = useSessionStorage(IMPERSONATED_USER_EXPIRY_KEY);

  const isImpersonatedUserExpired = computed(() => {
    const today = new Date();
    const impersonatedUserExpiryDate = new Date(impersonatedUserExpiry.value);
    return !impersonatedUserExpiry.value || today > impersonatedUserExpiryDate;
  });
  const isImpersonating = computed(() => {
    return !!impersonatedUserId.value;
  });

  function clearImpersonatedUser() {
    impersonatedUserId.value = null;
    impersonatorUserId.value = null;
    impersonatedUserExpiry.value = null;
  }
  async function impersonateUserById({ userId }) {
    if (!isImpersonating.value) {
      impersonatorUserId.value = identityStore.identity?.id;
    }

    impersonatedUserId.value = userId;

    const expires = new Date();
    expires.setTime(expires.getTime() + IMPERSONATED_USER_TOKEN_LIMIT_IN_HOURS * 60 * 60 * 1000);
    impersonatedUserExpiry.value = expires.toISOString();

    identityStore.clearCurrentBrand();
  }

  return {
    impersonatedUserId,
    impersonatorUserId,
    impersonatedUserExpiry,
    isImpersonatedUserExpired,
    isImpersonating,
    clearImpersonatedUser,
    impersonateUserById,
  };
});
