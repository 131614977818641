import { defineStore } from 'pinia';
import * as AuthAPI from '@/apis/auth';
import * as InstagramAPI from '@/apis/instagram';

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    changePasswordPopupOpen: false,
    changePasswordUserId: null,
    isAdminPasswordChange: false,
    sendReportsPopupOpen: false,
    sendReportsBrand: null,
    sendReportsPlatform: null,
    userDetails: null,
    pending: {
      updateUser: false,
      sendEmailReport: false,
    },
    error: {
      sendEmailReport: null,
    },
  }),
  actions: {
    async getUserDetails({ userId }) {
      try {
        const response = await AuthAPI.getUserDetails({ userId });
        this.userDetails = response.data;
      } catch (error) {
        if (error.response?.status === 403 || error.response?.status === 404) {
          this.userDetails = null;
        }
        throw error;
      }
    },
    async updateUser({ userId, data, authenticated = true }) {
      this.pending.updateUser = true;
      try {
        await AuthAPI.updateUser({ userId, data, authenticated });
      } finally {
        this.pending.updateUser = false;
      }
    },
    async sendEmailReport({ brandId, reportType, ...params }) {
      this.error.sendEmailReport = null;
      this.pending.sendEmailReport = true;
      try {
        await InstagramAPI.sendEmailReport({ brandId, reportType, ...params });
      } catch (error) {
        this.error.sendEmailReport = error;
      } finally {
        this.pending.sendEmailReport = false;
      }
    },
    openChangePasswordPopup({ userId }) {
      this.changePasswordUserId = userId;
      this.changePasswordPopupOpen = true;
    },
    closeChangePasswordPopup() {
      this.changePasswordUserId = null;
      this.isAdminPasswordChange = false;
      this.changePasswordPopupOpen = false;
    },
    openSendReportsPopup({ brand, platform }) {
      this.sendReportsBrand = brand;
      this.sendReportsPlatform = platform;
      this.sendReportsPopupOpen = true;
    },
    closeSendReportsPopup() {
      this.sendReportsBrand = null;
      this.sendReportsPlatform = null;
      this.sendReportsPopupOpen = false;
    },
  },
});
