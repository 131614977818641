<script setup>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Drawer, useDrawer } from '@dashhudson/dashing-ui';

const route = useRoute();
const router = useRouter();

const { registerDrawer, stateKey, launchDrawer, closeDrawer } = useDrawer();

// Centralized drawer registration
registerDrawer(
  'creatorProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorProfile.vue')),
);
registerDrawer(
  'accountProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/AccountProfile.vue')),
);

// Watch for stateKey changes and update the route query
watch(
  () => stateKey.value,
  (sk) => {
    if (sk) {
      router.push({ query: { ...route.query, d: sk } });
    } else {
      router.push({ query: { ...route.query, d: null } });
    }
  },
);

// Watch for route query changes and launch/close the drawer
watch(
  () => route.query,
  (q) => {
    if (q.d) {
      launchDrawer({ ...q, stateKey: q.d });
    } else {
      closeDrawer();
    }
  },
  {
    immediate: true,
  },
);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <Drawer />
</template>
