<template>
  <div class="clicks-dropdown">
    <div v-if="props.products && props.products.length > 0" :class="{ open: show }">
      <Button class="dropdown-button" @click.stop="show = !show">
        {{ props.products.length }} links
        <Icon :color="iconColor" name="caret" class="link-icon" xxxsmall />
      </Button>
    </div>

    <transition name="fade">
      <div v-if="show" v-on-click-outside.bubble="dropdownHandler" class="options">
        <ul>
          <li v-for="(option, index) in props.products" :key="index">
            <div class="link-row">
              <a :href="option.url" class="link-circle" target="_blank" rel="noopener" @click.stop>
                {{ index + 1 }}
              </a>
              <span class="dots" />
              <span class="num-clicks">{{ option.clicks.toLocaleString() }}</span>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';

const props = defineProps({
  products: { type: Array, default: null },
});

const iconColor = ref(colours.BASIC.WHITE);
const show = ref(false);

function dropdownHandler() {
  show.value = false;
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<style scoped lang="postcss">
.num-clicks {
  color: var(--action-500);
  margin: 0 var(--space-4) 0 var(--space-8);
}

.link-row {
  display: flex;
  margin-top: var(--space-4);
}

.link-circle {
  border: 1px solid var(--text-primary);
  border-radius: var(--space-32);
  margin: 0 var(--space-8) 0 0;
  flex: 0 0 auto;
  font-size: var(--x14);
  max-width: 100%;
  text-align: center;
  width: 27px;
  height: 27px;

  &:hover {
    background: var(--text-primary);
    color: var(--white);
  }
}

.dots::before {
  display: inline-block;
  height: var(--space-32);
  overflow: hidden;
  text-overflow: clip;
  content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
  color: var(--text-secondary);
  font-size: var(--x10);
}

.options {
  position: absolute;
  left: var(--space-80);
  bottom: var(--space-16);
  background-color: var(--background-0);
  border-radius: var(--space-8);

  ul {
    display: inline-block;
    border-radius: var(--space-8);
    padding: var(--space-16);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    box-shadow: var(--shadow-4);
  }
}

.dropdown-button {
  color: var(--white);
  background-color: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  left: var(--space-8);
  bottom: var(--space-4);

  .link-icon {
    margin: var(--space-4) 0 0 var(--space-4);
  }

  &:hover {
    transform: none;
    color: var(--white);
    box-shadow: none;
    text-decoration: underline;
  }
}
</style>
