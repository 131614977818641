<template>
  <div :class="['flex flex-col', { disabled }]">
    <VDropdown
      v-model:shown="open"
      placement="bottom-start"
      :triggers="[]"
      theme="dh-dropdown-full"
      @apply-show="onShow"
      @apply-hide="onHide"
    >
      <slot v-bind="{ inputValue, togglePopover, open }" />
      <template #popper>
        <DatePickerBase
          v-bind="$attrs"
          :model-value="modelValue"
          :disabled="disabled"
          @update:model-value="onInput"
          @page="onPage"
        >
          <template #header-prev-button>
            <slot name="header-prev-button" />
          </template>
          <template #header-next-button>
            <slot name="header-next-button" />
          </template>
        </DatePickerBase>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import DatePickerBase from '@/components/foundation/pickers/DatePickerBase.vue';

const comp = defineComponent({
  components: {
    DatePickerBase,
  },
  props: {
    modelValue: {
      type: [Object, Date],
      default: null,
    },
    disabled: { type: Boolean, default: false },
    masks: {
      type: Object,
      default: null,
    },
    keepVisibleOnInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show', 'hide', 'update:modelValue', 'page'],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    dateFormat() {
      if (Array.isArray(this.masks?.input) && this.masks.input.length > 0) {
        return this.masks.input[0];
      }
      return this.masks?.input;
    },
    inputValue() {
      if (this.dateFormat) {
        return this.modelValue ? dayjs(this.modelValue).format(this.dateFormat) : this.modelValue;
      }
      return this.modelValue;
    },
  },
  methods: {
    onShow() {
      this.$emit('show');
    },
    onHide() {
      this.$emit('hide');
    },
    onInput(value) {
      this.$emit('update:modelValue', value);
      if (!this.keepVisibleOnInput) {
        this.open = false;
      }
    },
    togglePopover() {
      this.open = !this.open;
    },
    onPage(page) {
      this.$emit('page', page);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.disabled {
  opacity: 0.3;
  cursor: default;
}
</style>
