/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#686a7a" d="M16.707 7.293L12 2.586 7.293 7.293l1.414 1.414L11 6.414V22h2V6.414l2.293 2.293z"/>'
  }
})
