import dayjs from 'dayjs';
import { isValidInterval, isIntervalWithinInterval } from '@/utils/dateUtils';
import { postStatus, schedulerPostsSortTypes, ALL_PLATFORMS } from '@/app/scheduler/constants';

export class PostQuery {
  constructor({ brandId, platforms, interval, statuses, sort, hasScheduledTime = null } = {}) {
    if (platforms && !platforms.every((platform) => ALL_PLATFORMS.includes(platform))) {
      throw new Error(`${platforms} includes an invalid platform!`);
    }
    if (platforms && platforms.length === 0) {
      throw new Error('A query must include at least one platform!');
    }
    if (interval && !isValidInterval(interval)) {
      throw new Error(`${interval} is not a valid date interval!`);
    }
    if (statuses && !statuses.every((status) => Object.values(postStatus).includes(status))) {
      throw new Error(`${statuses} contains an invalid status!`);
    }
    if (statuses && statuses.length === 0) {
      throw new Error('A query must include at least one status!');
    }
    if (sort && !Object.values(schedulerPostsSortTypes).includes(sort)) {
      throw new Error(`${sort} is an invalid sort type!`);
    }
    this.platforms = Object.freeze(platforms ?? [...ALL_PLATFORMS]);
    if (brandId) this.brandId = brandId;
    if (interval) this.interval = Object.freeze(interval);
    if (statuses) this.statuses = Object.freeze(statuses);
    if (sort) this.sort = sort;
    this.hasScheduledTime = hasScheduledTime;

    Object.freeze(this);
  }

  forBrand(brandId) {
    return new PostQuery({ ...this, brandId });
  }

  forPlatform(platform) {
    return new PostQuery({ ...this, platforms: [platform] });
  }

  areScheduled(scheduled) {
    return new PostQuery({ ...this, hasScheduledTime: scheduled });
  }

  forPlatforms(platforms) {
    return new PostQuery({ ...this, platforms });
  }

  overInterval(interval) {
    return new PostQuery({ ...this, interval });
  }

  betweenDates(start, end) {
    return this.overInterval({
      start: dayjs(start).toDate(),
      end: dayjs(end).toDate(),
    });
  }

  withStatuses(statuses) {
    return new PostQuery({ ...this, statuses });
  }

  sortBy(sort) {
    return new PostQuery({ ...this, sort });
  }

  thatAre(query) {
    return new PostQuery({
      ...this,
      ...query,
      platforms: this.platforms.filter((platform) => query.platforms.includes(platform)),
      statuses:
        this.statuses && query.statuses
          ? this.statuses.filter((status) => query.statuses.includes(status))
          : this.statuses ?? query.statuses,
    });
  }

  includes(query) {
    return (
      query.platforms.every((platform) => this.platforms.includes(platform)) &&
      (!this.brandId || query.brandId === this.brandId) &&
      ((!this.interval && !query.interval) ||
        (!!this.interval &&
          !!query.interval &&
          isIntervalWithinInterval(query.interval, this.interval))) &&
      (!this.statuses ||
        (!!query.statuses && query.statuses.every((status) => this.statuses.includes(status)))) &&
      this.sort === query.sort
    );
  }

  filterAndSort(posts) {
    const { platforms, brandId, interval, statuses, sort, hasScheduledTime = null } = this;
    const filteredPosts = [];
    let post;
    for (let i = 0; i < posts.length; i += 1) {
      post = posts[i];
      if (
        (!interval ||
          (post.timestamp && post.timestamp >= interval.start && post.timestamp <= interval.end)) &&
        platforms.includes(post.platform) &&
        (!brandId || post.brandId === brandId) &&
        (!statuses || statuses.includes(post.status)) &&
        (hasScheduledTime === null || post.hasScheduledTime === hasScheduledTime)
      ) {
        filteredPosts.push(post);
      }
    }
    if (sort === schedulerPostsSortTypes.INDEX_DESC) {
      filteredPosts.sort((a, b) => b.sortIndex - a.sortIndex);
    } else if (sort === schedulerPostsSortTypes.TIMESTAMP_ASC) {
      filteredPosts.sort((a, b) => a.timestamp - b.timestamp);
    } else if (sort === schedulerPostsSortTypes.TIMESTAMP_DESC) {
      filteredPosts.sort((a, b) => b.timestamp - a.timestamp);
    }
    return filteredPosts;
  }

  toString() {
    return [
      this.brandId,
      this.platforms.join(','),
      this.interval?.start,
      this.interval?.end,
      this.statuses?.join(','),
      this.sort,
    ].join(';');
  }
}

export const allPosts = new PostQuery();

export const timelinePosts = allPosts
  .withStatuses([
    postStatus.SCHEDULED,
    postStatus.USERS_NOTIFIED,
    postStatus.POSTED,
    postStatus.AUTOPUBLISHING,
    postStatus.EXPIRED,
    postStatus.FAILED,
    postStatus.PARTIALLY_FAILED,
    postStatus.MISSING_APPROVALS,
    postStatus.DRAFT,
  ])
  .betweenDates(dayjs().subtract(7, 'days').toDate(), dayjs().add(1000, 'years').toDate())
  .sortBy(schedulerPostsSortTypes.TIMESTAMP_ASC);

export const unscheduledPosts = allPosts
  .withStatuses([postStatus.DRAFT])
  .sortBy(schedulerPostsSortTypes.TIMESTAMP_ASC)
  .sortBy(schedulerPostsSortTypes.INDEX_DESC);

export const unscheduledDrafts = allPosts
  .withStatuses([postStatus.DRAFT])
  .areScheduled(false)
  .sortBy(schedulerPostsSortTypes.INDEX_DESC);

export const scheduledDrafts = allPosts
  .withStatuses([postStatus.DRAFT])
  .areScheduled(true)
  .sortBy(schedulerPostsSortTypes.TIMESTAMP_ASC);
