/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dot': {
    width: 800,
    height: 800,
    viewBox: '-7.99 -7.99 47.94 47.94',
    data: '<g _fill="#030104"><path pid="0" d="M27.25 4.655c-6.254-6.226-16.37-6.201-22.594.051-6.227 6.254-6.204 16.37.049 22.594 6.256 6.226 16.374 6.203 22.597-.051 6.224-6.254 6.203-16.371-.052-22.594z"/><path pid="1" d="M13.288 23.896l-1.768 5.207c2.567.829 5.331.886 7.926.17l-.665-5.416a8.382 8.382 0 01-5.493.039zM8.12 13.122l-5.645-.859a13.856 13.856 0 00.225 8.143l5.491-1.375a8.391 8.391 0 01-.071-5.909zm20.643-1.789l-4.965 1.675a8.39 8.39 0 01-.247 6.522l5.351.672a13.868 13.868 0 00-.139-8.869zm-17.369-8.45l1.018 5.528a8.395 8.395 0 016.442-.288l1.583-5.137a13.855 13.855 0 00-9.043-.103z"/><circle pid="2" cx="15.979" cy="15.977" r="6.117"/></g>'
  }
})
