import { ref } from 'vue';
import { defineStore } from 'pinia';
import isNumber from 'lodash/isNumber';
import * as InstagramAPI from '@/apis/instagram';
import { useAuthStore } from '@/stores/auth';

const MEDIA_LIST_LIMIT = 100;

export const useInstagramContentRightsStore = defineStore(
  'instagramContentRights',
  () => {
    const authStore = useAuthStore();

    const rightsRequestDocuments = ref({ data: [] });
    const mediaListOffset = ref(0);
    const mediaListFullyLoaded = ref(false);
    const mediaList = ref([]);
    const rightsRequestPost = ref(null);
    const hashtag = ref(null);

    const pending = ref({
      mediaList: false,
      addRightsRequestDocuments: false,
      updateRightsRequestDocuments: false,
    });

    async function getMediaList({
      startDate,
      endDate,
      userId,
      contentRightsType,
      limit = MEDIA_LIST_LIMIT,
      offset,
    }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.mediaList = true;
      try {
        const response = await InstagramAPI.getRightsRequestMedia({
          brandId,
          startDate,
          endDate,
          userId,
          contentRightsType,
          limit,
          offset: isNumber(offset) ? offset : mediaListOffset.value,
        });
        const payload = response?.data;

        mediaListOffset.value += limit;
        if (payload?.paging?.count <= mediaListOffset.value) {
          mediaListFullyLoaded.value = true;
        }
        mediaList.value = [...mediaList.value, ...(payload?.data ?? [])];

        return payload;
      } finally {
        pending.value.mediaList = false;
      }
    }

    async function createRightsRequestMedia({
      postId,
      type,
      requestComment,
      termLink,
      trackingHashtag,
    }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.createRightsRequestMediaPost({
        brandId,
        postId,
        type,
        requestComment,
        termLink,
        trackingHashtag,
      });
      const payload = response?.data;

      rightsRequestPost.value = payload;
      mediaList.value.push(payload);

      return payload;
    }

    async function updateRightsRequestMedia({ id, type }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.updateRightsRequestMediaPost({ brandId, id, type });
      const payload = response?.data;

      const index = mediaList.value.findIndex((rightsMedia) => id === rightsMedia.id);
      if (index > -1) {
        const updatedMedia = {
          ...payload,
          user: mediaList.value[index].user,
          post: mediaList.value[index].post,
        };
        mediaList.value.splice(index, 1, updatedMedia);
      }

      return payload;
    }

    async function deleteRightsRequestMedia({ id }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.deleteRightsRequestMediaPost({ brandId, id });
      const payload = response?.data;

      const index = mediaList.value.findIndex((rightsMedia) => id === rightsMedia.id);
      mediaList.value.splice(index, 1);

      return payload;
    }

    async function getRightsRequestDocuments() {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.getRightsRequestDocuments({ brandId });
      const payload = response?.data;
      rightsRequestDocuments.value = payload;
      return payload;
    }

    async function updateRightsRequestDocuments({ id, url, displayName }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.updateRightsRequestDocuments = true;
      try {
        const response = await InstagramAPI.updateRightsRequestDocuments({
          brandId,
          id,
          url,
          displayName,
        });
        const payload = response?.data;

        payload.inputDisabled = true;
        payload.iconsVisible = false;
        const index = rightsRequestDocuments.value.data.findIndex((document) => id === document.id);
        rightsRequestDocuments.value.data.splice(index, 1, payload);

        return payload;
      } catch (error) {
        const index = rightsRequestDocuments.value.data.findIndex((document) => id === document.id);
        let errorMessage = 'Invalid URL';
        // Because when short url service return 'Invalid url',
        // instagram_backend `raise_for_status` and will response 500.
        if (
          error.response &&
          error.response.status !== 500 &&
          error.response.data &&
          error.response.data.description
        ) {
          errorMessage = error.response.data.description;
        }
        rightsRequestDocuments.value.data[index] = {
          ...rightsRequestDocuments.value.data[index],
          errorMessage,
        };
        throw error;
      } finally {
        pending.value.updateRightsRequestDocuments = false;
      }
    }

    async function deleteRightsRequestDocuments({ id }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.deleteRightsRequestDocuments({ brandId, id });
      const payload = response?.data;

      const index = rightsRequestDocuments.value.data.findIndex((document) => id === document.id);
      rightsRequestDocuments.value.data.splice(index, 1);

      return payload;
    }

    async function addRightsRequestDocuments({ url, displayName }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.addRightsRequestDocuments = true;
      try {
        const response = await InstagramAPI.createRightsRequestDocuments({
          brandId,
          url,
          displayName,
        });
        const payload = response?.data;

        payload.inputDisabled = true;
        payload.iconsVisible = false;
        rightsRequestDocuments.value.data.splice(-1, 1, payload);

        return payload;
      } finally {
        pending.value.addRightsRequestDocuments = false;
      }
    }

    async function addDocumentS3Url({ fileName, contentType }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.createRightsRequestDocumentsS3Url({
        brandId,
        fileName,
        contentType,
      });
      const payload = response?.data;

      return payload;
    }

    async function getHashtag() {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.getRightsRequestHashtags({ brandId });
      const payload = response?.data;
      hashtag.value = payload;
      return payload;
    }

    async function updateHashtag({ id, trackingHashtag }) {
      const brandId = authStore.currentBrand?.id;

      const response = await InstagramAPI.updateRightsRequestHashtags({
        brandId,
        id,
        trackingHashtag,
      });
      const payload = response?.data;
      hashtag.value = payload;
      return payload;
    }

    function clearMediaList() {
      mediaList.value = [];
      mediaListOffset.value = 0;
      mediaListFullyLoaded.value = false;
    }

    return {
      pending,
      mediaListOffset,
      rightsRequestDocuments,
      mediaListFullyLoaded,
      mediaList,
      rightsRequestPost,
      hashtag,
      getMediaList,
      createRightsRequestMedia,
      updateRightsRequestMedia,
      deleteRightsRequestMedia,
      getRightsRequestDocuments,
      updateRightsRequestDocuments,
      deleteRightsRequestDocuments,
      addRightsRequestDocuments,
      addDocumentS3Url,
      getHashtag,
      updateHashtag,
      clearMediaList,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
