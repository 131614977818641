<script setup>
import { computed, ref } from 'vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { colours } from '@/ux/colours';
import { CONVERSATION_SETTING_ACTIONS } from '@/components/VisionAi/constants';
import { useVisionAiStore } from '@/stores/vision-ai';

const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiStore = useVisionAiStore();

const showMenuItems = ref(false);
const isHoverElement = ref(false);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  conversationId: {
    type: Number,
    default: null,
  },
  conversationContainerHeight: {
    type: Number,
    default: null,
  },
  isSummary: {
    type: Boolean,
    default: false,
  },
});

const showMenu = computed(() => {
  return props.show || showMenuItems.value;
});

const conversationActions = computed(() => {
  const actions = [];
  actions.push({
    text: CONVERSATION_SETTING_ACTIONS.RENAME,
    action: () =>
      visionAiConversationsStore.openRenameConversationWidget({
        conversationId: props.conversationId,
      }),
    disabled: false,
    roles: [],
  });
  actions.push({
    text: CONVERSATION_SETTING_ACTIONS.DELETE,
    action: () => {
      visionAiConversationsStore.deleteConversationClicked({
        conversationId: props.conversationId,
        isSummary: props.isSummary,
      });
      if (props.isSummary) {
        visionAiStore.anySummaryLoaded = false;
      }
    },
    disabled: props.isSummary && !visionAiStore.anySummaryLoaded,
    textColor: colours.ERROR.ERROR_500,
    roles: [],
    dataCy: 'delete-conversation',
  });
  if (props.isSummary) {
    return actions.filter((action) => action.text !== CONVERSATION_SETTING_ACTIONS.RENAME);
  }

  return actions;
});

const showButtonIconHoverColor = computed(() => {
  return isHoverElement.value ? colours.TEXT.TEXT_LINK : colours.TEXT.ICON_SECONDARY;
});

function handleMouseEnter() {
  isHoverElement.value = true;
}

function handleMouseLeave() {
  isHoverElement.value = false;
}

const isConversationNameWrapped = computed(() => {
  if (props.conversationContainerHeight >= 40) {
    return true;
  }
  return false;
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        isHoverElement,
      }
    : {},
);
</script>

<template>
  <div
    v-if="showMenu"
    class="absolute bottom-1 right-1 flex w-14 items-center justify-end"
    :class="{
      'top-2.5 ': isConversationNameWrapped,
    }"
    data-cy="conversation-settings-dropdown"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <DropdownButton
      v-model="showMenuItems"
      :dropdown-list="conversationActions"
      button-icon="ellipsis"
      align-center
      round-small
      data-html2canvas-ignore
      :button-classes="'border-none h-6 w-6 bg-white'"
      :button-icon-color="showButtonIconHoverColor"
      :button-icon-hover-color="showButtonIconHoverColor"
    />
  </div>
</template>
