import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.youtubeApiUrl);
export const camelCaseAxios = createAxiosInstance(env.youtubeApiUrl, {
  camelizeKeys: true,
});

export async function getYouTubeChannel({ brandId }, axiosConfig = {}) {
  return camelCaseAxios.get(`/brands/${brandId}/channels/mine`, axiosConfig);
}

export async function getTrendingVideos({ brandId, startDate, endDate, limit }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/videos/trending`,
    merge(
      {
        params: {
          startDate,
          endDate,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getPublicAccountsByIds({ sourceChannelIds }, axiosConfig = {}) {
  return camelCaseAxios.post('/competitors/public', { sourceChannelIds }, axiosConfig);
}

export async function getYouTubeSearchCounts(
  { topicQuery, bucket, fromDate, toDate },
  axiosConfig = {},
) {
  return axios.get(
    `/search/counts/`,
    merge(
      {
        params: {
          query: topicQuery,
          bucket,
          from_date: fromDate.replace('T', ' '),
          to_date: toDate.replace('T', ' '),
        },
      },
      axiosConfig,
    ),
  );
}
