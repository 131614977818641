<template>
  <div
    :class="['select', selectClasses]"
    :data-cy="dataCy"
    @mouseenter="showHoverState = true"
    @mouseleave="showHoverState = false"
  >
    <VDropdown
      v-model:shown="open"
      :triggers="[]"
      theme="dh-dropdown-full"
      boundary="viewport"
      :placement="popoverPlacement"
      distance="4"
      @auto-hide="onAutoHide"
    >
      <component
        :is="selectionDisplay"
        ref="picker"
        class="picker"
        data-cy="ImageDropdownSelectPicker"
        :open="open"
        :hide-open-indicator="searchOnly || (autocomplete && multiple)"
        :active="showFocusStyle"
        :style="pickerStyles"
        :prepend-img="prependImg"
        @click="showOptions"
        @keyup.stop="onKeyup"
      >
        <template v-if="$slots.prependImg" #prependImg>
          <slot name="prependImg" />
        </template>

        <template v-if="showChipsMultiNoAuto">
          <div class="flex flex-wrap gap-1 py-3">
            <Chip
              v-for="(opt, index) in currentOption"
              :key="index"
              xxsmall
              truncated-text-width="var(--width-196)"
              remove
              @remove="onOptionClick(opt)"
            >
              <span class="text-extra-small">{{ opt[optionText] }}</span>
              <slot name="append-text" :option="opt"></slot>
            </Chip>
          </div>
        </template>

        <template v-else-if="showChipsMultiAuto">
          <div class="flex flex-wrap gap-1 py-3">
            <Chip
              v-for="(opt, index) in currentOption"
              :key="index"
              xxsmall
              truncated-text-width="var(--width-196)"
              remove
              @remove="onOptionClick(opt)"
            >
              <span class="text-extra-small">{{ opt[optionText] }}</span>
              <slot name="append-text" :option="opt"></slot>
            </Chip>
            <input
              ref="searchText"
              :value="search"
              type="text"
              class="multisearch-input search-input placeholder-text"
              spellcheck="false"
              :placeholder="placeholderText"
              data-cy="ImageDropdownSelect"
              :disabled="disabled"
              @input="onSearchInput"
              @focus="onSearchFocus"
              @blur="onSearchBlur"
            />
          </div>
        </template>

        <template v-else-if="autocomplete && open">
          <input
            ref="searchText"
            :value="search"
            type="text"
            class="search-input placeholder-text"
            spellcheck="false"
            :placeholder="placeholder"
            data-cy="ImageDropdownSelect"
            :disabled="disabled"
            @input="onSearchInput"
            @focus="onSearchFocus"
            @blur="onSearchBlur"
          />
        </template>
        <template v-else-if="hasCurrentOption">
          <img
            v-if="optionImage && currentOption"
            :src="currentOption.image"
            class="selected-img"
          />
          <Icon
            v-if="optionIcon && currentOption && currentOption.iconName"
            :name="currentOption.iconName"
            :color="currentOption.iconColor"
            small
          />
          <span class="selected-option">
            {{ currentOption && currentOption[optionText] }}
          </span>
        </template>
        <template v-else>
          <span class="placeholder-text">{{ placeholder }}</span>
        </template>
      </component>
      <template #popper>
        <div
          :class="[dropdownClass, { 'align-top': alignTop, ...selectClasses }]"
          class="dropdown-menu"
          :data-cy="`${dataCy}-Options`"
          :style="dropdownStyles"
        >
          <div class="dropdown-menu-options webkit-scrollbar--dynamically-visible">
            <ul data-cy="ImageDropdownSelectOptions">
              <template v-if="optionsLoading">
                <li class="disabled invalid-metric">
                  {{ optionsLoadingText }}
                </li>
              </template>
              <template v-else-if="hasDisplayOptions">
                <template v-if="selectAllOption">
                  <li class="select-all-option select-none">
                    <CheckBox
                      :value="selectAllInput"
                      label="Select All"
                      @input="onSelectAllInput"
                    />
                  </li>
                </template>
                <template v-for="(option, index) in displayOptions">
                  <slot
                    name="option"
                    :option="option"
                    :selected="isOptionSelected(option)"
                    :on="{ click: () => onOptionClick(option) }"
                  >
                    <li
                      id="dropdownSelectOption"
                      :key="index"
                      v-tooltip="hideOptionTooltip ? null : option.tooltip"
                      class="option-item"
                      :class="{
                        header: option.header,
                        disabled: isOptionDisabled(option),
                        divider: option.divider,
                        selected: isOptionSelected(option),
                        focusOption: isFocusOption(option),
                      }"
                      data-cy="ImageDropdownSelectOption"
                      :data-value="`option-${getValue(option)}`"
                      @click="onOptionClick(option)"
                    >
                      <div class="left">
                        <Icon
                          v-if="optionIcon && option.iconName"
                          class="option-icon"
                          :color="option.iconColor"
                          :name="option.iconName"
                          small
                        />
                        <img v-if="optionImage" :src="option.image" class="dropdown-img" />
                        <TextMatchDisplay
                          :text="option[optionText]"
                          :search="search"
                          class="pr-1"
                        />
                        <slot name="append-text" :option="option"></slot>
                      </div>
                      <template v-if="!outline">
                        <Icon
                          v-if="isOptionSelected(option)"
                          class="option-right-icon"
                          :color="colours.ACTION.ACTION_500"
                          name="check"
                          xsmall
                        />
                      </template>
                    </li>
                  </slot>
                </template>
              </template>
              <template v-else>
                <li class="disabled invalid-metric">
                  {{ noDataText }}
                </li>
              </template>
            </ul>
          </div>
          <div v-if="hasActions && !useActionMenu" class="dropdown-menu-actions">
            <ul>
              <li
                v-for="(action, index) in actions"
                :key="index"
                data-cy="ImageDropdownSelectAction"
                @click="onActionClick(action)"
              >
                <div
                  :class="{
                    left: action.iconPosition !== 'right',
                    right: action.iconPosition === 'right',
                  }"
                >
                  <Icon
                    v-if="action.iconName"
                    :class="{
                      'option-icon': action.iconPosition !== 'right',
                    }"
                    :color="action.iconColor"
                    :name="action.iconName"
                    small
                  />
                  <span :style="action.style">{{ action.text }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </VDropdown>
    <DropdownButton
      v-if="useActionMenu"
      v-model="showingActionMenuItems"
      :dropdown-list="actions"
      :disabled="disableActionMenu"
      :class="actionMenuClass"
      button-classes="corner"
      button-icon="ellipsis"
      align-left
      :data-cy="`ActionMenuDropdown-${dataCy}`"
      data-html2canvas-ignore
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { autoCompleteDebounceDelay } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import TextMatchDisplay from '@/components/TextMatchDisplay.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import { UP_ARROW, DOWN_ARROW, ENTER, ESCAPE } from '@/utils/keycodes';
import SelectionDisplayInput from '@/components/foundation/SelectionDisplayInput.vue';
import SelectionDisplayButton from '@/components/foundation/SelectionDisplayButton.vue';
import Chip from '@/components/foundation/Chip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ImageDropdownSelect',
  components: {
    Chip,
    CheckBox,
    DropdownButton,
    Icon,
    TextMatchDisplay,
    SelectionDisplayInput,
    SelectionDisplayButton,
  },
  props: {
    /**
     * List of options for the dropdown.  Properties available for each option:
     *
     * <code>
     * <pre>
     * {
     *  text: required,
     *  value: required,
     *  optionIcon: optional,
     *  optionImage: optional,
     *  header, optional,
     *  disabled: optional,
     *  divider: optional
     * }
     * </pre>
     * </code>
     */
    options: { type: Array, default: null },
    /**
     * Value for the dropdown.  Expecting an Array if `multiple` is specified.
     */
    value: { type: [String, Number, Object, Array], default: null },
    /**
     * Disables the input.
     */
    disabled: { type: Boolean, default: false },
    /**
     * Disables the benchmark.
     */
    disableActionMenu: { type: Boolean, default: true },
    /**
     * Determins if the option's icon should be dislayed.
     */
    max: { type: Number, default: null },
    /**
     * Sets the maximum number of selected options when `multiple` is set.
     */
    optionIcon: { type: Boolean, default: false },
    /**
     * Determins if the option's image should be displayed.
     */
    optionImage: { type: Boolean, default: false },
    /**
     * Name of the property on the option object to use for the display text.
     */
    optionText: { type: String, default: 'text' },
    /**
     * Name of the property on the option object to use for the value.
     */
    optionValue: { type: String, default: 'value' },
    /**
     * Will return the option object instead of the option's value on the input event.
     */
    returnObject: { type: Boolean, default: false },
    /**
     * List of actions to append to the bottom of the dropdown.
     *
     * <code>
     * <pre>
     * {
     *  text: required,
     *  action: required,
     *  textColor: optional (only used in action menu dropdown),
     *  iconName: optional,
     *  iconColor: optional,
     *  iconPosition: optional,
     *  disabled: optional,
     *  style: optional
     * }
     * </pre>
     * </code>
     */
    actions: { type: Array, default: null },
    /**
     * Enables auto complete mode to search the list of options.
     */
    autocomplete: { type: Boolean, default: false },
    /**
     * Highlights the text of the option's text that matches the auto complete search.
     */
    autoSelectText: { type: Boolean, default: true },
    /**
     * Enforce a min picker width.
     */
    minPickerWidth: { type: String, default: '8.5rem' },
    /**
     * Enforce a max picker width.
     */
    maxPickerWidth: { type: String, default: '' },
    /**
     * Enforce a max picker height.
     */
    maxPickerHeight: { type: String, default: '' },
    /**
     * Enables multiple select mode for the dropdown.
     */
    multiple: { type: Boolean, default: false },
    /**
     * Enable to select all option when using multiple select.
     */
    selectAllOption: { type: Boolean, default: false },
    /**
     * Text to display when there are no options.
     */
    noDataText: { type: String, default: 'No Data' },
    /**
     * Text to display when there are no options selected.
     */
    placeholder: { type: String, default: '' },
    /**
     * Configure data-cy attribute for testing tag.
     */
    dataCy: { type: String, default: '' },
    /*
     * Only show the dropdown while searching
     */
    searchOnly: { type: Boolean, default: false },
    /**
     * Moves actions from bottom of dropdown to a separate menu
     */
    useActionMenu: { type: Boolean, default: false },
    /**
     * Set focus to the search input
     */
    focus: { type: Boolean, default: false },
    /**
     * Property to be used to hide tooltip value.
     */
    hideOptionTooltip: { type: Boolean, default: false },
    /**
     * Property to be used to display loading text.
     */
    optionsLoading: { type: Boolean, default: false },
    /**
     * Text to display when options are loading.
     */
    optionsLoadingText: { type: String, default: 'Options loading...' },
    /**
     * A function that is triggered when searching. Allows user to specify how options are filtered.
     */
    customSearch: { type: Function, default: null },
    /**
     * Flag to override defaults and force display the picker by moving it back into the view
     */
    forceShowPicker: { type: Boolean, default: false },
    /**
     * Flag to override defaults and force display the action menu
     */
    forceShowActionMenu: { type: Boolean, default: false },
    // styling
    alignTop: { type: Boolean, default: false },
    capitalizeText: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    compactOptions: { type: Boolean, default: false },
    grey: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    invisible: { type: Boolean, default: false },
    dropdownClass: { type: String, default: '' },
    bold: { type: Boolean, default: false },
    prependImg: {
      type: String,
      default: null,
    },
  },
  emits: ['input', 'opened', 'closed'],
  data() {
    return {
      open: false,
      search: null,
      isSearchFocused: false,
      pickerWidth: undefined,
      showHoverState: false,
      showingActionMenuItems: false,
      selectAllInput: false,
      focusOption: null,
    };
  },
  computed: {
    colours() {
      return colours;
    },
    showChipsMultiNoAuto() {
      return this.multiple && !this.autocomplete && this.currentOption?.length > 0;
    },
    showChipsMultiAuto() {
      return this.multiple && this.autocomplete;
    },
    selectClasses() {
      return {
        disabled: this.disabled,
        compact: this.compact,
        compactOptions: this.compactOptions,
        round: this.round,
        outlined: this.outline,
        center: this.center,
        grey: this.grey,
        highlight: this.open,
        'capitalize-text': this.capitalizeText && !this.autocomplete,
        multiple: this.multiple,
        simple: this.simple,
        hidePicker: this.invisible,
        searchOnly: this.searchOnly,
        focus: this.showFocusStyle,
        flex: this.useActionMenu,
        'items-center': this.useActionMenu,
        bold: this.bold,
        forceShowPicker: this.forceShowPicker,
      };
    },
    currentOption() {
      if (this.multiple) {
        return Array.isArray(this.value)
          ? this.value
              .map((valueElem) =>
                this.options.find((option) => isEqual(this.getValue(option), valueElem)),
              )
              .filter((option) => option)
          : [];
      }
      return this.options?.find((opt) => isEqual(this.getValue(opt), this.value));
    },
    hasCurrentOption() {
      return this.multiple ? this.currentOption.length > 0 : !!this.currentOption;
    },
    displayOptions() {
      // When using custom search, the component which implements handles the filtering
      if (this.customSearch) {
        return this.options;
      }

      if (this.autocomplete) {
        return (
          this.options?.filter((option) => {
            // If we have search text, filter the options
            if (this.hasSearch) {
              // Filter options based on search
              return option[this.optionText].toLowerCase().includes(this.search.toLowerCase());
            }

            // If we are only seaching and don't have search text, show nothing
            if (this.searchOnly) {
              return false;
            }

            // Show all options
            return true;
          }) || []
        );
      }
      return this.options;
    },
    hasDisplayOptions() {
      return this.displayOptions?.length > 0;
    },
    hasActions() {
      return this.actions?.length > 0;
    },
    popoverPlacement() {
      return this.simple ? 'bottom-end' : 'bottom-start';
    },
    showFocusStyle() {
      return this.open || this.isSearchFocused;
    },
    showActionsMenu() {
      return (
        this.hasActions &&
        this.useActionMenu &&
        (this.showHoverState || this.showingActionMenuItems || this.forceShowActionMenu)
      );
    },
    actionMenuClass() {
      return this.showActionsMenu ? '' : 'transparent';
    },
    hasSearch() {
      return this.search?.length > 0;
    },
    dropdownStyles() {
      return {
        minWidth: this.pickerWidth ? `${this.pickerWidth}px` : undefined,
      };
    },
    numberOfSelectedOptions() {
      return [].concat(this.value || []).length;
    },
    maxOptionsSelected() {
      return !isNil(this.max) && this.numberOfSelectedOptions >= this.max;
    },
    pickerStyles() {
      const pickerStyles = {
        'min-width': this.minPickerWidth,
      };

      if (this.maxPickerWidth !== '') {
        pickerStyles['max-width'] = this.maxPickerWidth;
      }
      if (this.maxPickerHeight !== '') {
        pickerStyles['max-height'] = this.maxPickerHeight;
        pickerStyles['overflow-y'] = 'scroll';
      }
      return pickerStyles;
    },
    placeholderText() {
      return !this.value?.length ? this.placeholder : '';
    },
    allVisibleOptionsSelected() {
      if (Array.isArray(this.value) && Array.isArray(this.displayOptions)) {
        return this.displayOptions.every((displayOption) => {
          return this.value.some((selectedValue) =>
            isEqual(selectedValue, this.getValue(displayOption)),
          );
        });
      }
      return false;
    },
    focusOptionValue() {
      return this.getValue(this.focusOption);
    },
    focusOptionIndex() {
      return this.displayOptions.findIndex((option) => isEqual(option, this.focusOption));
    },
    firstDisplayOption() {
      return this.displayOptions?.length > 0 ? this.displayOptions[0] : null;
    },
    lastDisplayOption() {
      return this.displayOptions?.length > 0
        ? this.displayOptions[this.displayOptions.length - 1]
        : null;
    },
    selectionDisplay() {
      return this.outline ? SelectionDisplayButton : SelectionDisplayInput;
    },
  },
  watch: {
    options() {
      if (!this.currentOption && this.value !== null) {
        this.$emit('input', null);
      }
    },
    search() {
      if (!this.open && this.hasSearch) {
        this.open = true;
      }
    },
    focus: {
      handler(value) {
        if (value) {
          this.focusSearchInput();
        }
      },
      immediate: true,
    },
    open(value) {
      if (value && this.$refs.picker) {
        this.pickerWidth = this.$refs.picker.offsetWidth;
      }
      if (value) {
        this.focusOption = null;
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    },
    selectAllInput(value) {
      if (value && this.selectAllOption && this.multiple) {
        this.selectAllVisibleOptions();
      }
    },
    allVisibleOptionsSelected: {
      handler(value) {
        this.setSelectAllOption(value);
      },
    },
    focusOption(value) {
      if (value) {
        const elem = document.querySelector(`[data-value="option-${value}"]`);
        if (elem) {
          elem.scrollIntoView(false);
        }
      }
    },
    hasDisplayOptions(value) {
      if (!value) {
        this.focusOption = null;
      }
    },
  },
  mounted() {
    this.setSelectAllOption(this.allVisibleOptionsSelected);
  },
  methods: {
    showOptions() {
      if (!this.searchOnly) {
        this.open = true;
        this.focusSearchInput();
      }
    },
    hideOptions() {
      this.clearSearchInput();
      this.open = false;
    },
    onOptionClick(option) {
      if (!(option.disabled || option.header)) {
        if (this.multiple) {
          if (this.searchOnly) {
            this.hideOptions();
            this.focusSearchInput();
          }
          const selectedValues = Array.isArray(this.value) ? [...this.value] : [];
          const indexOfValue = selectedValues.findIndex((selectedValue) =>
            isEqual(selectedValue, this.getValue(option)),
          );
          if (indexOfValue > -1) {
            selectedValues.splice(indexOfValue, 1);
          } else if (!this.maxOptionsSelected) {
            selectedValues.push(this.getValue(option));
          }
          this.$emit('input', selectedValues);
        } else {
          this.$emit('input', this.getValue(option));
          this.hideOptions();
        }
      }
    },
    onActionClick(action) {
      if (!action.disabled) {
        action.action();
        this.hideOptions();
      }
    },
    isOptionSelected(option) {
      if (this.multiple) {
        return this.currentOption.some((o) => isEqual(o, option));
      }
      return isEqual(option, this.currentOption);
    },
    isOptionDisabled(option) {
      const notSelectedAndMaxReached = !this.isOptionSelected(option) && this.maxOptionsSelected;
      return option.disabled || notSelectedAndMaxReached;
    },
    onSearchInput: debounce(function delay(event) {
      this.search = event?.target?.value || '';
      if (this.customSearch) this.customSearch(this.search);
    }, autoCompleteDebounceDelay),
    resetAutocompleteSearch() {
      if (this.autocomplete) {
        this.search = this.currentOption?.[this.optionText] || '';
        this.focusSearchInput();
      }
    },
    getValue(option) {
      return this.returnObject ? option : option[this.optionValue];
    },
    onSearchFocus() {
      this.isSearchFocused = true;
    },
    onSearchBlur(event) {
      // New popper will get focus when displayed.  v2 has a new noAutoFocus that isn't in v1.
      // https://github.com/Akryum/floating-vue/blob/main/CHANGELOG.md#200-beta20-2022-09-08
      // For now, we just move focus back to input if popper gets focus
      const id = event?.relatedTarget?.id ?? '';
      if (id.includes('popper')) {
        this.focusSearchInput();
        return;
      }

      this.isSearchFocused = false;
      if (!this.open) {
        this.hideOptions();
      }
    },
    focusSearchInput() {
      nextTick(() => {
        if (this.$refs.searchText) {
          this.$refs.searchText.focus();
          if (this.autoSelectText && !this.searchOnly) {
            this.$refs.searchText.select();
          }
        }
      });
    },
    clearSearchInput() {
      this.search = null;
    },
    onAutoHide() {
      if (!this.isSearchFocused) {
        this.clearSearchInput();
      }
    },
    isFocusOption(option) {
      return isEqual(option, this.focusOption);
    },
    pickFocusOption() {
      if (this.focusOption) {
        this.onOptionClick(this.focusOption);
      }
    },
    moveFocusOptionUp() {
      if (this.hasDisplayOptions) {
        const noFocusOption = this.focusOption === null;
        const notFirstOption = this.focusOptionIndex > 0;
        if (noFocusOption) {
          this.focusOption = this.lastDisplayOption;
        } else if (notFirstOption) {
          this.focusOption = this.displayOptions[this.focusOptionIndex - 1];
        }
      }
    },
    moveFocusOptionDown() {
      if (this.hasDisplayOptions) {
        const noFocusOption = this.focusOption === null;
        const notLastBrand = this.focusOptionIndex < this.displayOptions.length - 1;
        if (noFocusOption) {
          this.focusOption = this.firstDisplayOption;
        } else if (notLastBrand) {
          this.focusOption = this.displayOptions[this.focusOptionIndex + 1];
        }
      }
    },
    onKeyup(event) {
      const keyCode = event.which ?? event.keyCode;
      if (keyCode === ESCAPE) {
        this.hideOptions();
      } else if (keyCode === ENTER) {
        this.pickFocusOption();
      } else if (keyCode === UP_ARROW) {
        this.moveFocusOptionUp();
      } else if (keyCode === DOWN_ARROW) {
        this.moveFocusOptionDown();
      }
    },
    onSelectAllInput() {
      this.selectAllInput = !this.selectAllInput;
      if (this.selectAllInput === false) {
        this.unselectAllVisibleOptions();
      }
    },
    selectAllVisibleOptions() {
      const selectedValues = [].concat(this.value);
      const visibleUnselectedOptions =
        this.displayOptions?.filter((option) => {
          return !selectedValues.some((selectedValue) => {
            return isEqual(selectedValue, this.getValue(option));
          });
        }) ?? [];
      const visibleUnselectedValues = visibleUnselectedOptions.map((option) =>
        this.getValue(option),
      );
      const newSelectedValues = selectedValues.concat(visibleUnselectedValues);
      if (!isEqual(this.value, newSelectedValues)) {
        this.$emit('input', newSelectedValues);
      }
    },
    unselectAllVisibleOptions() {
      const selectedValues = [].concat(this.value);
      const visibleSelectedOptions =
        this.displayOptions?.filter((option) => {
          return selectedValues.some((selectedValue) => {
            return isEqual(selectedValue, this.getValue(option));
          });
        }) ?? [];
      const visibleSelectedValues = visibleSelectedOptions.map((option) => this.getValue(option));
      const newSelectedValues = selectedValues.filter((selectedValue) => {
        return !visibleSelectedValues.some((visibleSelectedValue) =>
          isEqual(visibleSelectedValue, selectedValue),
        );
      });
      if (!isEqual(this.value, newSelectedValues)) {
        this.$emit('input', newSelectedValues);
      }
    },
    setSelectAllOption(value) {
      if (this.selectAllOption && this.multiple) {
        if (value && this.selectAllInput === false) {
          this.selectAllInput = true;
        } else if (value === false && this.selectAllInput === true) {
          this.selectAllInput = false;
        }
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.select {
  min-width: 10.5rem;
  position: relative;

  :deep(.trigger) {
    display: block !important;
  }

  .picker {
    input[disabled=''] {
      color: var(--text-secondary);
      background-color: var(--background-0);
    }

    .selected-option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }

    .placeholder-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: var(--font-normal);
      color: var(--text-secondary);
    }

    .selected-img {
      margin-right: var(--space-12);
      width: 24px;
      height: 24px;
      border-radius: var(--space-16);
    }

    .search-input {
      border: 0;
      padding: 0;
      height: 16px;
      width: 100%;
      font-weight: var(--font-normal);
      min-width: 75px;
    }

    .multisearch-input {
      flex: 1;
    }
  }

  .align-top.dropdown-menu {
    top: unset;
    bottom: var(--space-40);
  }

  &.simple {
    min-width: 0;

    .picker {
      border: none;
      justify-content: flex-end;
      background: transparent;
      padding: 0 var(--space-20) 0 var(--space-16);
      min-width: 0;

      .selected-option {
        width: inherit;
        text-align: right;
      }
    }
  }

  &.hidePicker {
    min-width: 0;

    .picker {
      border: none;
      justify-content: flex-end;
      background: none;
      padding: 0;
      min-width: 0;

      input {
        background: none;
      }

      .selected-option {
        width: inherit;
        text-align: right;
      }
    }
  }
}

.dropdown-menu {
  z-index: var(--z-index-dropdown);
  background-color: var(--background-0);
  border-radius: var(--round-corner);
  box-shadow: var(--shadow-4);
  min-width: 10rem;

  ul {
    width: 100%;
    border-radius: var(--round-corner);
    color: var(--text-primary);
    vertical-align: middle;
    display: inline-block;
    max-height: 20rem;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--space-4) var(--space-16);
      cursor: pointer;
      line-height: 1.75rem;
      font-size: var(--x14);

      .left {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .dropdown-img {
          width: 32px;
          height: 32px;
          border-radius: var(--space-16);
          margin: var(--space-8) var(--space-12) var(--space-8) 0;
        }
      }

      .right {
        display: flex;
        flex: 1;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .search-match {
        color: rgb(0 0 0 / 38%);
        background: #eee;
      }

      .option-icon {
        margin-right: var(--space-12);
      }

      .option-right-icon {
        margin-left: var(--space-16);
      }

      span {
        width: max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 500px;
      }

      &.option-item:hover {
        background: var(--background-300);
      }

      &.header {
        color: var(--text-secondary);
        cursor: default;
        padding-top: var(--space-8) !important;
        padding-bottom: var(--space-6) !important;
        line-height: normal;

        &:not(:first-child) {
          margin-top: var(--space-10);
        }

        &:hover {
          background: none;
        }
      }

      &.disabled {
        cursor: auto;
        opacity: 0.4;

        &.invalid-metric {
          background: none;
        }
      }

      &.divider {
        border-top: 1px solid var(--border);
      }

      &.focusOption {
        background-color: var(--background-300);
      }
    }
  }

  .dropdown-menu-options {
    overflow: hidden auto;

    ul {
      padding-top: var(--space-8);

      &::after {
        content: '';
        display: block;
        height: var(--space-8);
        width: 100%;
      }
    }
  }

  .dropdown-menu-actions {
    width: 100%;
    border-top: 1px solid var(--border);

    ul {
      padding: var(--space-8) 0;
    }

    span {
      font-weight: var(--font-medium);
    }
  }

  &.outlined li.option-item {
    padding: var(--space-8) var(--space-24);
    font-weight: var(--font-normal);

    &.selected {
      font-weight: var(--font-medium);
    }
  }

  &.compactOptions li.option-item {
    line-height: normal;
    padding: var(--space-6) var(--space-24) !important;
  }

  &.capitalize-text ul li {
    text-transform: capitalize;
  }

  ul li:last-child:hover {
    border-radius: 0 0 var(--space-6) var(--space-6);
  }

  &.simple {
    .dropdown-menu-actions {
      span {
        font-weight: normal;
      }
    }
  }
}

.transparent {
  opacity: 0;
}

.select.disabled {
  pointer-events: none;
}

.select.round,
.select.outlined {
  .picker {
    border-radius: var(--space-20);
    padding: 0 var(--space-28);
  }
}

.select.capitalize-text {
  .picker {
    text-transform: capitalize;
  }
}

.select.center {
  .picker {
    padding: 0 var(--space-28) 0 var(--space-20);
    justify-content: center;
  }
}

.select.grey {
  .picker {
    background-color: var(--background-300);
  }
}

.select.compact {
  .picker {
    padding: 0 var(--space-32) 0 var(--space-12);
    min-height: var(--space-34);
  }

  .dropdown-menu ul li {
    font-size: var(--x12);
    padding: var(--space-4) var(--space-12);
  }
}

.select.multiple {
  .picker {
    padding: 0;
    padding-right: var(--space-12);
  }

  .placeholder-text {
    padding-left: var(--space-12);
  }
}

.select.forceShowPicker .picker {
  padding-right: var(--space-32);
}

.select.compact.highlight {
  .picker {
    color: var(--action-500);
    border-color: var(--action-500);
  }
}

.select.bold {
  .selected-option {
    font-weight: var(--font-medium);
  }
}

.select-all-option {
  border-bottom: 1px solid var(--border);
}
</style>
