import { useTrackingStore } from '@/stores/tracking';
import { getDateOptionLabel } from '@/utils/formatters';
import pinia from '@/stores/pinia';
import { timeScaleTypes } from '@/config';
import { useIntercomStore } from '@/stores/intercom';

export class UserEventTracker {
  constructor() {
    this.trackingStore = useTrackingStore(pinia);
  }

  csvDownloaded(type) {
    this.trackingStore.track('CSV Downloaded', { type });
  }

  handleMixpanelOverviewFilterEvent({ pageName, changedFilterName, query, previousQuery }) {
    const eventData = {
      'Filter Used': changedFilterName,
      'View Graph': timeScaleTypes[query.scale].label,
      'Previous View Graph': timeScaleTypes[previousQuery.scale].label,
      'Graph 2 Metric': query?.graphMetric,
      'Previous Graph 2 Metric': previousQuery?.graphMetric,
      'Reporting Period': getDateOptionLabel(query.reportStartDate, query.reportEndDate, false),
      'Previous Reporting Period': getDateOptionLabel(
        previousQuery.reportStartDate,
        previousQuery.reportEndDate,
        false,
      ),
      'Start Date': query.reportStartDate,
      'End Date': query.reportEndDate,
      'Previous Start Date': previousQuery.reportStartDate,
      'Previous End Date': previousQuery.reportEndDate,
      'Comparison Start Date': query?.contextStartDate,
      'Comparison End Date': query?.contextEndDate,
      'Previous Comparison Start Date': previousQuery?.contextStartDate,
      'Previous Comparison End Date': previousQuery?.contextEndDate,
    };
    this.trackingStore.track(`${pageName} Filter`, eventData);
  }

  handleMixpanelMediaFilterEvent({ channelName, changedFilterName, query, previousQuery }) {
    const eventData = {
      'Filter Used': changedFilterName,
      'Date Published': getDateOptionLabel(query.startDate, query.endDate),
      'Previous Date Published': getDateOptionLabel(previousQuery.startDate, previousQuery.endDate),
      'Start Date': query.startDate,
      'End Date': query.endDate,
      'Previous Start Date': previousQuery.startDate,
      'Previous End Date': previousQuery.endDate,
      'Sort By': query.sortBy,
      'Previous Sort By': previousQuery.sortBy,
      'Sort Order': query.sortOrder,
      'Previous Sort Order': previousQuery.sortOrder,
      'Media Types': query.mediaTypes ?? null,
      'Previous Media Types': previousQuery.mediaTypes ?? null,
      'Contains Keywords': query.containsKeywords ?? null,
      'Previous Contains Keywords': previousQuery.containsKeywords ?? null,
      'Not Contains Keywords': query.notContainsKeywords ?? null,
      'Previous Not Contains Keywords': previousQuery.notContainsKeywords ?? null,
    };
    this.trackingStore.track(`${channelName} Your Posts Filter`, eventData);
  }

  handleCsvDownloadedEvent({ csvType }) {
    this.trackingStore.track('CSV Downloaded', { type: csvType });
  }

  trackTeaserPageView({ pageAccess } = {}) {
    this.trackingStore.track('Teaser Page View', { pageAccess });
  }
}

export function trackTrialSuccessButtonClicked({
  trialPlan,
  trialAddOn,
  buttonText,
  sendIntercomEvent = false,
}) {
  const event = 'Trial Modal Started - Button Clicked';
  const properties = {
    trialPlan,
    trialAddOn,
    buttonText,
  };
  const trackingStore = useTrackingStore();
  trackingStore.track(event, properties);
  if (sendIntercomEvent) {
    const intercomStore = useIntercomStore();
    intercomStore.trackEvent(event, properties);
  }
}

export function trackNavigation(primary, secondary) {
  useTrackingStore().track('Navigation Heading Selected', {
    itemSelected: primary,
    secondary_item_selected: secondary,
    page: window.location.pathname,
  });
}

export function trackSecondaryNavigationInteractions(action) {
  useTrackingStore().track('Secondary Nav Interaction', {
    action,
  });
}
