<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import VisionAiButton from '@/components/VisionAi/VisionAiButton.vue';
import VerticalMenu from '@/components/VisionAi/VerticalMenu.vue';
import { vOnClickOutside } from '@vueuse/components';
import { ELEMENTS_TO_IGNORE_WHEN_CLOSING_POPUP } from '@/components/VisionAi/constants';
import { selectorContainsEventTarget } from '@/utils/dom';
import { useMetricsStore } from '@/stores/metrics';
import { useVisionAiStore } from '@/stores/vision-ai';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import ConversationHistorySidebar from '@/components/VisionAi/ConversationHistorySidebar.vue';
import RenameConversationPopup from '@/components/VisionAi/RenameConversationPopup.vue';
import MessageArea from '@/components/VisionAi/MessageArea.vue';

const visionAiStore = useVisionAiStore();
const visionAiConversationsStore = useVisionAiConversationsStore();
const metricsStore = useMetricsStore();
const delayShowConversationSidebar = ref(false);

const forceNotificationPopupToTheTop = ref(false);

const showChatBox = computed(() => {
  return visionAiStore.visionAiChatWindowOpen;
});

const messages = computed(() => {
  return visionAiStore.messages;
});

const messagesLoading = computed(() => {
  return visionAiStore.pending.messages;
});

const hasMessages = computed(() => {
  return Boolean(messages.value?.length);
});

const defaultPrompts = computed(() => {
  return visionAiStore.defaultPrompts;
});

const showNotAvailableEmptyState = computed(() => {
  if (visionAiStore.isDashboardConversationalAiEnabled) {
    // show upgrade screen instead of empty state since conversational is available everywhere
    return false;
  }
  return !defaultPrompts.value.length;
});

const disableClear = computed(() => {
  const isLoading = Boolean(messagesLoading.value);
  const hasNoMessages = Boolean(!messagesLoading.value && !hasMessages.value);
  const isAssistantLoading = Boolean(visionAiStore.isAssistantMessageLoading);
  const noMetricsDetails = Boolean(!metricsStore.metricDetailsReady);

  return isLoading || hasNoMessages || isAssistantLoading || noMetricsDetails;
});

function handleCloseChatWindow(e = null) {
  const ignoreElement = ELEMENTS_TO_IGNORE_WHEN_CLOSING_POPUP.some((el) =>
    selectorContainsEventTarget(el, e),
  );

  if (ignoreElement) return;

  visionAiStore.closeChatWindow();
}

watch(
  () => [
    visionAiStore.notificationPopupOpen,
    visionAiConversationsStore.pending.notificationPopupOpen,
  ],
  (to) => {
    const someNotificationPopupOpen = to.some((value) => value);
    forceNotificationPopupToTheTop.value = someNotificationPopupOpen;
  },
);

watch(
  () => visionAiStore.popupIsExpanded,
  (to) => {
    // when the chat box is expanded, the conversation sidebar is shown immediately
    // The MessageArea and ConversationHistorySidebar components breaks the chat box layout
    // delay showing conversation sidebar to avoid flickering
    if (to) {
      setTimeout(() => {
        delayShowConversationSidebar.value = true;
      }, 100);
    } else {
      delayShowConversationSidebar.value = false;
    }
  },
);

onBeforeMount(() => {
  // only fetch messages on first time mounting the chat box. Web sockets will continually add
  // additional messages to the list as needed
  visionAiConversationsStore.getConversations({});
});

onBeforeUnmount(() => {
  visionAiStore.clearLocalChatHistory();
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        showChatBox,
        messages,
        hasMessages,
        defaultPrompts,
        showNotAvailableEmptyState,
      }
    : {},
);
</script>

<template>
  <VisionAiButton v-if="!showChatBox" is-global-popup />
  <transition name="expand">
    <div
      v-if="showChatBox"
      v-on-click-outside="handleCloseChatWindow"
      class="fixed flex h-[--height-752] min-h-[--height-636] flex-row gap-5"
      :class="[
        'chat-box-container',
        {
          'chat-box': visionAiStore.isDashboardConversationalAiEnabled,
          'empty-chat-box': showNotAvailableEmptyState,
          'w-[--width-480]': !visionAiStore.popupIsExpanded,
          'w-[--width-1280]': visionAiStore.popupIsExpanded,
        },
      ]"
    >
      <!-- <ConversationsBubble /> -->
      <VerticalMenu
        v-if="!visionAiStore.isDashboardConversationalAiEnabled"
        :disable-clear="disableClear"
        :chat-popup-is-expanded="visionAiStore.popupIsExpanded"
        @close-chat-window="handleCloseChatWindow"
        @clear-chat-history="visionAiStore.handleClearChatHistory"
        @launch-vision-ai-resource-center="visionAiStore.sendToResourceCenter"
      />

      <ConversationHistorySidebar
        v-if="visionAiStore.canAccessConversational && delayShowConversationSidebar"
      />

      <MessageArea
        :default-prompts="defaultPrompts"
        :has-messages="hasMessages"
        :messages="messages"
        :messages-loading="messagesLoading"
        :scrollable-chat-loading="!metricsStore.metricDetailsReady"
        :show-not-available-empty-state="showNotAvailableEmptyState"
      />

      <VerticalMenu
        v-if="visionAiStore.isDashboardConversationalAiEnabled"
        class="pb-1"
        :disable-clear="disableClear"
        :chat-popup-is-expanded="visionAiStore.popupIsExpanded"
        :showing-conversation-history="visionAiStore.showConversationHistorySidebar"
        @close-chat-window="handleCloseChatWindow"
        @clear-chat-history="visionAiStore.handleClearChatHistory"
        @launch-vision-ai-resource-center="visionAiStore.sendToResourceCenter"
        @expand-chat-popup="visionAiStore.expandChatPopup"
        @shrink-chat-popup="visionAiStore.shrinkChatPopup"
        @show-conversation-history="visionAiStore.expandChatPopup"
        @hide-conversation-history="visionAiStore.hideConversationHistory"
      />
    </div>
  </transition>
  <RenameConversationPopup />
</template>
<style lang="postcss" scoped>
.chat-box-container {
  max-height: calc(100vh - var(--space-80));
  border: 1px solid rgb(123 156 197 / 8%);
  background: var(--vision-chat-box-gradient);
  z-index: calc(var(--z-index-popup) - 1);
  transition: all 0.4s ease;

  /* TODO: https://app.shortcut.com/dashhudson/story/131718/expand-and-minimize-animation-fix */

  &.chat-box {
    @apply origin-bottom-right items-center
    justify-between rounded-t-3xl rounded-bl-3xl pb-6 pl-[--space-20] pr-[--space-16] shadow-visionAiRight  !important;

    right: 80px;
    bottom: 16px;
  }

  &:not(.chat-box) {
    @apply bottom-5 left-3 origin-bottom-left items-center
    justify-between gap-6 rounded-t-3xl rounded-br-3xl pb-6 pl-4 shadow-visionAiLeft !important;
  }
}

.empty-chat-box {
  background: var(--vision-chat-box-gradient);
}

.cta {
  background: var(--vision-chat-box-gradient);
}

.expand-enter-active {
  animation: expand 0.4s;
}

.expand-leave-active {
  animation: expand 0.4s reverse;
}

@keyframes expand {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
</style>
