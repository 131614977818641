import { SHOW_ALL_BREAKDOWN_THRESHOLD, VISUAL_DATA_TYPES } from '@/app/socialListening/constants';

export function getElementPositionMap(list) {
  return list.reduce((acc, cur, idx) => {
    const id =
      cur?.[VISUAL_DATA_TYPES.TRENDS] ||
      cur?.[VISUAL_DATA_TYPES.LIBRARY] ||
      cur?.[VISUAL_DATA_TYPES.UPLOAD];
    return id
      ? {
          ...acc,
          [id]: idx,
        }
      : acc;
  }, {});
}

export function filterBreakdownData(data, totalCount, threshold = SHOW_ALL_BREAKDOWN_THRESHOLD) {
  // we want to filter out entries that will be rounded to 0% of the total volume
  const filterPoint = totalCount * threshold;
  return Object.fromEntries(
    [...data].map((item) => [item.group, item.count]).filter((item) => item[1] >= filterPoint),
  );
}
