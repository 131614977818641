/**
 * Directive that can be used for dragging an element within the bounds of a container.
 *
 * Template:
 *
 * <div
 *   v-drag-move-2="{ draggableClass: 'draggable' }"
 *   class="container"
 * >
 *   <div class="draggable">
 *     <div>drag-me</div>
 *   </div>
 * </div>
 *
 *
 * Style:
 *
 * .container {
 *   width: 200px;
 *   height: 200px;
 *   position: relative;
 * }
 *
 * .draggable {
 *   position: absolute;
 * }
 *
 * TODO: Currently we only support one instance of drag-move at a time. According to Vue
 * maintainers, once a directive needs state, it should probably be a component:
 * https://github.com/vuejs/vue/issues/6385#issuecomment-323011039. If want to support more than
 * one instance of drag-move at a time, we should convert it to a component.
 */
class DragMove2 {
  constructor({ containerEl, draggableClass, onDragStarted, onDragFinished }) {
    this.containerEl = containerEl;
    this.draggableClass = draggableClass;
    this.onDragStarted = onDragStarted;
    this.onDragFinished = onDragFinished;

    this.draggedEl = null;
    this.x = null;
    this.y = null;
  }

  init() {
    document.addEventListener('mousedown', this.mouseDownHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  deinit() {
    document.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  move(el, x, y) {
    el.style.left = `${x}px`;
    el.style.top = `${y}px`;
    this.x = x;
    this.y = y;
  }

  mouseDownHandler = (e) => {
    // Only left clicks should be relevant here.
    if (e.button !== 0) {
      return;
    }
    const draggable = e.target.closest('.draggable');
    if (draggable) {
      this.draggedEl = draggable;
      const draggedElBox = draggable.getBoundingClientRect();
      this.offsetX = e.clientX - draggedElBox.left;
      this.offsetY = e.clientY - draggedElBox.top;

      const boundingBox = this.containerEl.getBoundingClientRect();
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      this.x = mouseX - boundingBox.left - this.offsetX;
      this.y = mouseY - boundingBox.top - this.offsetY;

      this.onDragStarted(this.draggedEl);
      document.addEventListener('mousemove', this.mouseMoveHandler);
    }
  };

  mouseMoveHandler = (e) => {
    const boundingBox = this.containerEl.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    let x = mouseX - boundingBox.left - this.offsetX;
    let y = mouseY - boundingBox.top - this.offsetY;
    const draggedElPointX = mouseX - this.offsetX;
    const draggedElPointY = mouseY - this.offsetY;

    if (draggedElPointX < boundingBox.left) x = 0;
    if (draggedElPointY < boundingBox.top) y = 0;
    if (draggedElPointX > boundingBox.right) x = boundingBox.width;
    if (draggedElPointY > boundingBox.bottom) y = boundingBox.height;

    this.move(this.draggedEl, x, y);
  };

  mouseUpHandler = (e) => {
    // Only left clicks should be relevant here.
    if (e.button !== 0) {
      return;
    }

    if (this.draggedEl) {
      this.onDragFinished(this.draggedEl, this.x, this.y);
    }
    this.draggedEl = null;
    document.removeEventListener('mousemove', this.mouseMoveHandler);
  };
}

export default {
  beforeMount: (containerEl, bind) => {
    const { draggableClass, onDragStarted, onDragFinished } = bind.value;
    const dragMove = new DragMove2({ containerEl, draggableClass, onDragStarted, onDragFinished });
    dragMove.init();

    // If we convert this directive a component, this eslint-disable won't be necessary
    containerEl.dragMove = dragMove; // eslint-disable-line
  },
  unmounted: (containerEl) => {
    containerEl.dragMove.deinit();
  },
};
