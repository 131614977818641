import { useDashboardsStore } from '@/stores/dashboards';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { computed } from 'vue';
import { VALID_DASHBOARD_REPORTS } from '@/components/VisionAi/constants';
import { DASHBOARD_ROUTE_NAMES, TOTAL_METRIC_GROUPS } from '@/app/dashboards/constants';
import { useIdentityStore } from '@/stores/identity';

import { useReport } from '@/app/dashboards/composables/useReport';

export function useDashboardsVisionAi() {
  const identityStore = useIdentityStore();
  const dashboardsStore = useDashboardsStore();
  const dashboardReportsStore = useDashboardReportsStore();

  const dashboardIntentQuery = computed(() => {
    const dashboardId = dashboardsStore.currentDashboardId;
    const userId = identityStore.identity?.id;
    const [startDate, endDate] = dashboardReportsStore.reportDateRangeInUserTimezone;
    const [contextStartDate, contextEndDate] = dashboardReportsStore.contextDateRangeInUserTimezone;
    const currentBrandId = identityStore.currentBrand.id;

    return {
      currentBrandId,
      dashboardId,
      userId,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
    };
  });

  function reportIsValidForSummary(report) {
    const isValidReportType = Object.values(VALID_DASHBOARD_REPORTS).includes(report.type);
    if (!isValidReportType) return false;

    const { aggregateBy, isMultiBrand, isMultiChannel, reportHasData } = useReport({
      report,
    });

    if (!reportHasData.value) return false;

    switch (aggregateBy.value) {
      case TOTAL_METRIC_GROUPS.TOTAL:
        return !isMultiBrand.value && !isMultiChannel.value;
      case TOTAL_METRIC_GROUPS.CHANNEL:
        return !isMultiBrand.value;
      case TOTAL_METRIC_GROUPS.BRAND:
        return !isMultiChannel.value;
      default:
        return true;
    }
  }

  const hasValidReportsThreshold = computed(() => {
    const reports = Object.values(dashboardsStore.reports);
    const numberOfValidDashboardReports = (reports || []).filter((report) =>
      reportIsValidForSummary(report),
    ).length;
    return numberOfValidDashboardReports >= 3;
  });

  const hasNoDashboards = computed(() => {
    return !dashboardsStore.hasDashboards;
  });

  function formatDateRangeFromIntentQuery(intentQuery) {
    return {
      startDate: dashboardReportsStore.convertUtcDateTimeToLocalTimeByTimeZone(
        intentQuery?.startDate,
      ),
      endDate: dashboardReportsStore.convertUtcDateTimeToLocalTimeByTimeZone(intentQuery?.endDate),
      contextStartDate: dashboardReportsStore.convertUtcDateTimeToLocalTimeByTimeZone(
        intentQuery?.contextStartDate,
      ),
      contextEndDate: dashboardReportsStore.convertUtcDateTimeToLocalTimeByTimeZone(
        intentQuery?.contextEndDate,
      ),
    };
  }

  function getDashboardUrlFromIntentQuery(intentQuery) {
    return {
      name: DASHBOARD_ROUTE_NAMES.ID,
      params: { id: intentQuery.dashboardId },
      query: formatDateRangeFromIntentQuery(intentQuery),
    };
  }

  return {
    dashboardIntentQuery,
    hasValidReportsThreshold,
    formatDateRangeFromIntentQuery,
    getDashboardUrlFromIntentQuery,
    hasNoDashboards,
    reportIsValidForSummary,
  };
}
