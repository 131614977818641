export function getBrandIdToReportAdCampaign(reportAdCampaigns) {
  return reportAdCampaigns.reduce((mapping, brandReport) => {
    mapping[brandReport.id] = brandReport;
    return mapping;
  }, {});
}

export function getFormattedMetrics(metricsObject, reportMetric, channel) {
  const metricValue =
    metricsObject?.[reportMetric]?.[channel] ?? metricsObject?.[reportMetric] ?? {};

  const formattedMetrics =
    Object.keys(metricsObject ?? {}).map((metric) => {
      const metricValues = metricsObject?.[metric]?.[channel] || metricsObject?.[metric] || {};
      return { metric, ...metricValues };
    }) || [];

  return {
    formattedMetrics,
    metric: metricValue,
  };
}
