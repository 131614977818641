<script setup>
import { computed } from 'vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import MultiSelectDropdown from '@/components/foundation/form/MultiSelectDropdown.vue';
import FormItem from '@/components/foundation/form/FormItem.vue';
import { joinWithTruncation } from '@/utils/lists';

const DISPLAY_COUNTRY_GROUP_KEYS = [
  'africa',
  'asia',
  'caribbean',
  'central_america',
  'europe',
  'north_america',
  'oceania',
  'south_america',
];

const MAX_RECENT_SUGGESTION_DESCRIPTION_LENGTH = 50;

const emit = defineEmits(['back', 'update:targeting']);
const props = defineProps({
  locations: {
    type: Object,
    default: null,
  },
  targeting: {
    type: Object,
    default: null,
  },
  recentlyUsedTargeting: {
    type: Array,
    default: null,
  },
  tooltip: {
    type: String,
    default: null,
  },
});

const countryCodesToOptions = (countryCodes) =>
  (props.locations?.country ?? [])
    .filter((country) => countryCodes.includes(country.countryCode))
    .map((country) => ({
      label: country.name,
      value: country.key,
    }));

const selectedCountryKeys = computed({
  get() {
    return props.targeting?.geoLocations?.countries ?? [];
  },
  set(to) {
    emit('update:targeting', !to || to.length === 0 ? null : { geoLocations: { countries: to } });
  },
});

const optionLoading = computed(() => {
  return !props.locations.country || !props.locations.countryGroup;
});

const options = computed(() => {
  const countries = props.locations?.country ?? [];
  const countryGroups = props.locations?.countryGroup ?? [];
  const countryGroupsToDisplay = countryGroups.filter((g) =>
    DISPLAY_COUNTRY_GROUP_KEYS.includes(g.key),
  );
  const otherCountries = countries.filter(
    (c) => !countryGroupsToDisplay.some((g) => g.countryCodes.includes(c.countryCode)),
  );
  const otherGroup = {
    name: 'Other',
    key: 'other',
    countryCodes: otherCountries.map((country) => country.countryCode),
  };
  const allGroupsToDisplay = [...countryGroupsToDisplay, otherGroup];
  return allGroupsToDisplay.map((group) => ({
    label: group.name,
    value: group.key,
    children: countryCodesToOptions(group.countryCodes),
  }));
});

const suggestedOptions = computed(() => {
  const countries = props.locations?.country ?? [];
  return (props.recentlyUsedTargeting ?? [])
    .map((targeting, i) =>
      targeting.geoLocations.countries.length === 1
        ? countryCodesToOptions(targeting.geoLocations.countries)[0]
        : {
            label: joinWithTruncation(
              countries
                .filter((country) => targeting.geoLocations.countries.includes(country.countryCode))
                .map((country) => country.name),
              MAX_RECENT_SUGGESTION_DESCRIPTION_LENGTH,
            ),
            value: `recent-${i}`,
            children: countryCodesToOptions(targeting.geoLocations.countries),
          },
    )
    .filter((suggestedOption) => suggestedOption);
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <SubScreen title="Limit by Location" @on-back="emit('back')">
    <template #content>
      <FormItem label="Audience" :tooltip-text="tooltip">
        <MultiSelectDropdown
          v-model="selectedCountryKeys"
          :options="options"
          :suggested-options="suggestedOptions"
          :options-loading="optionLoading"
          :max-chips="14"
          hide-select-all
          placeholder="Select location"
          no-data-text="No Results"
          lines-per-label="2"
        />
      </FormItem>
    </template>
  </SubScreen>
</template>
