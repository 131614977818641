<script setup>
import { computed } from 'vue';
import DashboardReportContent from '@/app/dashboards/components/DashboardReportContent.vue';
import {
  EXCLUDE_REPORT_TYPES_FROM_EXPANSION,
  METRIC_TABLE_REPORTS,
} from '@/app/dashboards/utils/reports.enum';

const props = defineProps({
  /*
    list of formatted report objects to pass to DashboardReportContent
    [
      {
        reportOverride: {
          data: {},
          meta: {},
          type: '',
        },
        type: 'TOTAL_METRIC|GRAPH|...'
      }
    ]
  */
  reports: { type: Array, default: () => [] },
});

const reportsToDisplay = computed(() => {
  return props.reports;
});

const reportOverride = computed(() => {
  return reportsToDisplay.value[0]?.reportOverride;
});

const visionAiReportInExpandedMode = computed(
  () => reportOverride.value && reportOverride.value.isExpanded,
);

const visionAiReportWithConversationSidebar = computed(
  () => reportOverride.value && reportOverride.value.hasConversationSidebarExpanded,
);

function getClassesForMinimizedMode(reportType) {
  if (visionAiReportInExpandedMode.value) return {};

  const scrollableHeightReport = METRIC_TABLE_REPORTS.includes(reportType);
  return {
    'line-height-style w-full text-[0.375rem]': true,
    'max-h-[340px]': scrollableHeightReport,
    'h-full': !scrollableHeightReport,
  };
}

function getClassesForExpandedMode() {
  if (!visionAiReportInExpandedMode.value) return {};

  return {
    'h-[420px]': true,
    'w-[--width-850]': visionAiReportWithConversationSidebar.value,
    'w-full': !visionAiReportWithConversationSidebar.value,
  };
}

function getClassesForReportType(reportType) {
  if (EXCLUDE_REPORT_TYPES_FROM_EXPANSION.includes(reportType)) return {};

  return {
    ...getClassesForMinimizedMode(reportType),
    ...getClassesForExpandedMode(),
  };
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        getClassesForReportType,
        reportsToDisplay,
        visionAiReportWithConversationSidebar,
        visionAiReportInExpandedMode,
      }
    : {},
);
</script>

<template>
  <div class="mt-3 flex w-full flex-wrap gap-2">
    <div
      v-for="(report, index) in reportsToDisplay"
      :key="`vision-ai-report-${index}`"
      :class="[getClassesForReportType(report.type)]"
    >
      <DashboardReportContent :type="report.type" :report-override="report.reportOverride" />
    </div>
  </div>
</template>
