<template>
  <div class="more-filters">
    <SelectionDisplay
      ref="moreFiltersToggle"
      :active="dropdownOpen || !!activeFilterLabel"
      label="Filters"
      @click="toggleDropdown"
    >
      {{ moreFiltersLabel }}{{ activeFilterLabel }}
    </SelectionDisplay>

    <transition name="slide">
      <div v-show="dropdownOpen" v-on-click-outside="onClickOutside" class="more-filters-dropdown">
        <main>
          <ShowDuplicateMediaFilter
            ref="show-duplicate-media-filter"
            @toggled="showDuplicateMediaFilter"
          />

          <MediaDetailFilter
            ref="media-type"
            :icon-name="filters.mediaType.iconName"
            :filter-name="filters.mediaType.filterName"
            :filter-options="filters.mediaType.filterOptions"
            :media-type-limit="mediaTypeLimit"
            @filter-selected="addMediaTypeFilter"
          />

          <PerformanceFilter
            v-if="canAccessVision"
            ref="performance-filter"
            @filter-selected="addPerformanceFilter"
          />

          <MediaDetailFilter
            ref="aspect-ratio"
            :icon-name="filters.aspectRatio.iconName"
            :filter-name="filters.aspectRatio.filterName"
            :filter-options="filters.aspectRatio.filterOptions"
            @filter-selected="addAspectRatioFilter"
          />

          <MediaDetailFilter
            ref="size-filter"
            :icon-name="filters.size.iconName"
            :filter-name="filters.size.filterName"
            :filter-options="filters.size.filterOptions"
            @filter-selected="addSizeFilter"
          />

          <RightsAcquiredFilter ref="contentrights-filter" @toggled="addRightsFilter" />

          <ColorFilter ref="color-filter" @filter-selected="addColorFilter" />

          <footer>
            <a href="#" @click.prevent="clearFilters">Clear</a>
            <a href="#" class="blue" @click.prevent="showResults">See Results</a>
          </footer>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import ColorFilter from '@/components/MediaFilters/ColorFilter.vue';
import MediaDetailFilter from '@/components/MediaFilters/MediaDetailFilter.vue';
import PerformanceFilter from '@/components/MediaFilters/PerformanceFilter.vue';
import RightsAcquiredFilter from '@/components/MediaFilters/RightsAcquiredFilter.vue';
import SelectionDisplay from '@/components/foundation/SelectionDisplay.vue';
import { enumProp } from '@/utils/props';
import { mediaTypes } from '@/app/library/constants';
import { selectorContainsEventTarget } from '@/utils/dom';
import ShowDuplicateMediaFilter from '@/components/MediaFilters/ShowDuplicateMediaFilter.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'MoreFiltersDropdown',
  components: {
    ColorFilter,
    MediaDetailFilter,
    PerformanceFilter,
    RightsAcquiredFilter,
    SelectionDisplay,
    ShowDuplicateMediaFilter,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    value: { type: Object, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
  },
  emits: ['filtersSelected'],
  data() {
    const filterOptionsByMediaTypeLimit = {
      [mediaTypes.VIDEO]: ['video only'],
      [mediaTypes.IMAGE]: ['photo only'],
      [null]: ['any', 'photo only', 'video only'],
    };
    return {
      dropdownOpen: false,
      moreFiltersLabel: 'More Filters',
      filters: {
        aspectRatio: {
          iconName: 'aspectRatio',
          filterName: 'aspect ratio',
          filterOptions: ['any', 'square', 'landscape', 'portrait'],
        },
        mediaType: {
          iconName: 'fileQuestionMark',
          filterName: 'media type',
          filterOptions: filterOptionsByMediaTypeLimit[this.mediaTypeLimit],
        },
        size: {
          iconName: 'size',
          filterName: 'size',
          filterOptions: ['any', '> 1024 x 768', '> 800 x 600', '> 1600 x 1200'],
        },
      },
      selectedFilters: {
        aspectRatio: null,
        color: null,
        contentRights: null,
        mediaType: null,
        size: null,
        performance: null,
        showDuplicateMedia: null,
      },
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
    ...mapPiniaState(useAuthStore, ['brand_can']),
    activeFilterLabel() {
      let count = 0;
      if (this.selectedFilters.aspectRatio && this.selectedFilters.aspectRatio !== '0') {
        count += 1;
      }
      if (this.selectedFilters.color !== null) {
        count += 1;
      }
      if (this.selectedFilters.contentRights && this.selectedFilters.contentRights !== 'off') {
        count += 1;
      }
      if (this.selectedFilters.performance) {
        count += 1;
      }
      if (
        this.selectedFilters.mediaType &&
        this.selectedFilters.mediaType !== '0' &&
        this.mediaTypeLimit === null
      ) {
        count += 1;
      }
      if (this.selectedFilters.size && this.selectedFilters.size !== '0') {
        count += 1;
      }
      if (
        this.selectedFilters.showDuplicateMedia &&
        this.selectedFilters.showDuplicateMedia !== 'off'
      ) {
        count += 1;
      }
      return count === 0 ? '' : ` (${count})`;
    },
    canAccessVision() {
      return this.brand_can('vision', 'can_access_vision');
    },
  },
  watch: {
    value(to, from) {
      this.selectedFilters.aspectRatio = to.aspectRatio;
      this.$refs['aspect-ratio'].updateFilter(to.aspectRatio);
      this.selectedFilters.color = to.color;
      this.$refs['color-filter'].updateFilter(to.color);
      this.selectedFilters.contentRights = to.contentRights;
      this.$refs['contentrights-filter'].updateFilter(to.contentRights);
      this.selectedFilters.mediaType = to.mediaType;
      this.$refs['media-type'].updateFilter(to.mediaType);
      this.selectedFilters.size = to.size;
      this.$refs['size-filter'].updateFilter(to.size);
      this.selectedFilters.performance = to.performance;
      this.selectedFilters.showDuplicateMedia = to.showDuplicateMedia;
      this.$refs['show-duplicate-media-filter'].updateFilter(to.showDuplicateMedia);
      if (this.canAccessVision && to.performance !== from.performance) {
        this.$refs['performance-filter'].updateFilter(to.performance);
      }
    },
  },
  methods: {
    clearFilters() {
      this.selectedFilters.aspectRatio = null;
      this.selectedFilters.color = null;
      this.selectedFilters.mediaType = null;
      this.selectedFilters.size = null;
      this.selectedFilters.contentRights = null;
      this.selectedFilters.performance = null;
      this.selectedFilters.showDuplicateMedia = null;
      this.$refs['aspect-ratio'].updateFilter(null);
      this.$refs['color-filter'].updateFilter(null);
      this.$refs['media-type'].updateFilter(null);
      this.$refs['size-filter'].updateFilter(null);
      if (this.canAccessVision) this.$refs['performance-filter'].updateFilter(null);
      this.$refs['contentrights-filter'].updateFilter('off');
      this.$refs['show-duplicate-media-filter'].updateFilter('off');
    },
    addSizeFilter(option) {
      const filterValue = this.filters.size.filterOptions[option];
      this.selectedFilters.size = option;
      this.trackingStore.track('Search Filter Changed', { filter: 'Media Size', filterValue });
    },
    addAspectRatioFilter(option) {
      const filterValue = this.filters.aspectRatio.filterOptions[option];
      this.selectedFilters.aspectRatio = option;
      this.trackingStore.track('Search Filter Changed', { filter: 'Aspect Ratio', filterValue });
    },
    addColorFilter(option) {
      this.selectedFilters.color = option;
    },
    addMediaTypeFilter(option) {
      if (this.mediaTypeLimit) {
        return;
      }
      const filterValue = this.filters.mediaType.filterOptions[option];
      this.selectedFilters.mediaType = option;
      this.trackingStore.track('Search Filter Changed', { filter: 'Media Type', filterValue });
    },
    addRightsFilter(option) {
      this.selectedFilters.contentRights = option;
      this.trackingStore.track('Search Filter Changed', {
        filter: 'Rights Status',
        filterValue: option,
      });
    },
    showDuplicateMediaFilter(option) {
      this.selectedFilters.showDuplicateMedia = option;
      this.trackingStore.track('Search Filter Changed', {
        filter: 'Show Duplicate Media',
        filterValue: option,
      });
    },
    addPerformanceFilter(option) {
      this.selectedFilters.performance = option;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    onClickOutside(e) {
      const clickedOnToggle = selectorContainsEventTarget('[data-cy="SelectionDisplayToggle"]', e);
      if (!clickedOnToggle && this.dropdownOpen) {
        // when click outside, if filter changed, perform search, otherwise, close dropdown
        if (e.target !== this.$refs.moreFiltersToggle && this.dropdownOpen) {
          if (this.isFilterChanged()) {
            this.showResults();
          } else {
            this.dropdownOpen = false;
          }
        }
      }
    },
    showResults() {
      this.$emit('filtersSelected', this.selectedFilters);
      this.closeDropdown();
    },
    isFilterChanged() {
      let isSame = true;
      Object.keys(this.value).forEach((key) => {
        if (String(this.value[key]) !== String(this.selectedFilters[key])) {
          isSame = false;
        }
      });
      return !isSame;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.more-filters {
  position: relative;
  font-weight: var(--font-medium);
}

.more-filters-dropdown {
  background: var(--background-0);
  color: var(--text-primary);
  width: 22.5rem;
  box-shadow: var(--heavy-shadow);
  border-radius: var(--round-corner-small);
  position: absolute;
  top: 2.75rem;
  left: 0;
  height: auto;
  transition: var(--transition-all);
  z-index: var(--z-index-dropdown);
  margin-bottom: var(--space-32);
  font-size: var(--x14);
  font-weight: var(--font-normal);
}

footer {
  background: var(--background-300);
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-24);
  font-size: var(--x14);

  a:active,
  a:hover {
    color: var(--action-500);
  }

  .blue {
    color: var(--action-500);
  }
}
</style>
