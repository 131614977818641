import dayjs from 'dayjs';
import { COMMUNITY_INTERACTION_TYPES } from '@/app/community/constants';

export const DM_PLATFORM_MAP = {
  [COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION]: 'instagram',
  [COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION]: 'facebook',
  [COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION]: 'twitter',
};

const validWebsocketPlatformTypes = Object.keys(DM_PLATFORM_MAP);

export const isValidPlatformType = (type) => {
  return validWebsocketPlatformTypes.includes(type);
};

export const getDMPlatform = (type) => {
  if (!isValidPlatformType(type)) {
    throw new Error(
      `Invalid type, please provide a valid value from ${validWebsocketPlatformTypes}`,
    );
  }

  return DM_PLATFORM_MAP[type];
};

export const instagramConversationFormatter = (conversation) => {
  return {
    ...conversation,
    brandParticipantId: conversation.brandParticipant?.instagramScopedId,
    followerParticipantId: conversation.followerParticipant?.instagramScopedId,
  };
};

export const twitterConversationFormatter = (conversation) => {
  return {
    ...conversation,
    brandParticipant: conversation.twitterBrandParticipant,
    followerParticipant: conversation.twitterFollowerParticipant,
    brandParticipantId: conversation.twitterBrandParticipant?.twitterUserId,
    followerParticipantId: conversation.twitterFollowerParticipant?.twitterUserId,
  };
};

export const facebookConversationFormatter = (conversation) => {
  return {
    ...conversation,
    brandParticipant: conversation.fbBrandParticipant,
    followerParticipant: conversation.fbFollowerParticipant,
    brandParticipantId: conversation.fbBrandParticipant?.facebookPsid,
    followerParticipantId: conversation.fbFollowerParticipant?.facebookPsid,
  };
};

export const conversationFormatter = (conversation) => {
  const { type } = conversation;
  switch (type) {
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION:
      return instagramConversationFormatter(conversation);
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION:
      return facebookConversationFormatter(conversation);
    case COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION:
      return twitterConversationFormatter(conversation);
    default:
      return {};
  }
};

export const instagramMessageFormatter = (messages) => {
  return messages.map((message) => {
    return {
      ...message,
      seenAt: message.instagramSeenAt,
      createdAt: message.instagramCreatedAt,
      instagramCreatedAt: dayjs(message.instagramCreatedAt),
    };
  });
};

export const facebookMessageFormatter = (messages) => {
  return messages.map((message) => {
    return {
      ...message,
      seenAt: message.facebookSeenAt,
      createdAt: message.facebookCreatedAt,
      facebookCreatedAt: dayjs(message.facebookCreatedAt),
    };
  });
};

export const twitterMessageFormatter = (messages) => {
  return messages.map((message) => {
    return {
      ...message,
      seenAt: message.twitterSeenAt,
      createdAt: message.twitterCreatedAt,
      twitterCreatedAt: dayjs(message.twitterCreatedAt),
    };
  });
};

export const messagesFormatter = (messages, type) => {
  switch (type) {
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION:
      return instagramMessageFormatter(messages);
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION:
      return facebookMessageFormatter(messages);
    case COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION:
      return twitterMessageFormatter(messages);
    default:
      return {};
  }
};
