function depthFirstSearch(branch, path = [], rulePaths = []) {
  if (!branch.children || branch.children.length === 0) {
    // Base case: If there are no more children, print the current path and return
    rulePaths.push(path);
    return;
  }

  for (let i = 0; i < branch.children.length; i += 1) {
    // Add the current tree's rule to the path
    const child = branch.children[i];
    const newPath = path.slice(); // Create a new path to not affect other branches
    newPath.push(child?.rule || child?.keyword);

    depthFirstSearch(child, newPath, rulePaths);
  }
}

function getRulePaths(tree) {
  let rulePaths = [];
  // Perform DFS on each top-level branch separately
  for (let i = 0; i < tree.children.length; i += 1) {
    const child = tree.children[i];
    const path = [child.rule];

    depthFirstSearch(child, path, rulePaths);
  }
  rulePaths = rulePaths.map((path) => [tree.rule, ...path]);
  return rulePaths;
}

export function useKeywordExpressionValidators() {
  function parenthesisDepthExceededNear(tree) {
    const rulePaths = getRulePaths(tree);
    // if there is somewhere too deeply nested, return a snippet of where it is i.e near "AND foo"
    const problemIndex = rulePaths.findIndex(
      (path) => path.filter((rule) => rule === 'atom')?.length > 2,
    );
    return problemIndex > -1
      ? rulePaths[problemIndex]
          .slice(-2)
          .map((item) => {
            if (['and', 'or', 'not'].includes(item)) {
              return item.toUpperCase();
            }
            if (item === 'atom') {
              return null;
            }
            return item;
          })
          .join(' ')
      : undefined;
  }

  function andDepthExceededNear(tree) {
    const rulePaths = getRulePaths(tree);
    // if there is somewhere too deeply nested, return a snippet of where it is i.e near "AND foo"
    const problemIndex = rulePaths.findIndex((path) =>
      path.join(',').includes('and,atom,or,atom,and'),
    );
    return problemIndex > -1
      ? rulePaths[problemIndex]
          .slice(-2)
          .map((item) => {
            if (['and', 'or', 'not'].includes(item)) {
              return item.toUpperCase();
            }
            if (item === 'atom') {
              return null;
            }
            return item;
          })
          .filter((result) => result !== null)
          .reverse()
          .join(' ')
      : undefined;
  }

  function notClauseContainsAndNear(tree) {
    const rulePaths = getRulePaths(tree);
    const problemIndex = rulePaths.findIndex((path) => path.join(',').includes('not,atom,and'));
    return problemIndex > -1
      ? rulePaths[problemIndex]
          .slice(-2)
          .map((item) => {
            if (['and', 'or', 'not'].includes(item)) {
              return item.toUpperCase();
            }
            if (item === 'atom') {
              return null;
            }
            return item;
          })
          .filter((result) => result !== null)
          .reverse()
          .join(' ')
      : undefined;
  }

  function hasMoreThanOneNotClause(expression) {
    return expression.split(/\s/).filter((token) => token === 'NOT')?.length > 1;
  }

  return {
    andDepthExceededNear,
    parenthesisDepthExceededNear,
    notClauseContainsAndNear,
    hasMoreThanOneNotClause,
  };
}
