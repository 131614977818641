/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'colorPalette': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#767676"><path pid="0" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10c.462 0 .923-.031 1.368-.093a2.782 2.782 0 002.273-1.881 2.778 2.778 0 00-.675-2.873l-.987-.988a.799.799 0 01-.173-.872c.031-.072.122-.293.739-.293H19.2c1.492 0 2.8-1.401 2.8-3 0-5.514-4.486-10-10-10zm7.2 11h-4.655c-1.242 0-2.184.558-2.586 1.528a2.79 2.79 0 00.606 3.052c.511.512 1.412 1.123 1.181 1.808-.217.647-1.061.612-1.746.612-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8c0 .486-.411 1-.8 1z"/><path pid="1" d="M11 5c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2s2-.897 2-2c0-1.102-.897-2-2-2zM7 10c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2s2-.897 2-2c0-1.102-.897-2-2-2zM16 8c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2s2-.897 2-2c0-1.102-.897-2-2-2z"/></g>'
  }
})
