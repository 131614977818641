/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rejectedCircle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><path pid="0" d="M8.028 1.333A6.674 6.674 0 0114.695 8a6.674 6.674 0 01-6.667 6.667A6.674 6.674 0 011.361 8a6.674 6.674 0 016.667-6.667zm2.195 3.529l-5.334 5.333.944.943 5.333-5.333-.943-.943z" id="svgicon_rejectedCircle_a"/></defs><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon_rejectedCircle_a"/>'
  }
})
