import { convertToUnit } from '@/utils';

export default {
  props: {
    /**
     * Set `height` style of component.
     */
    height: {
      type: [Number, String],
      default: null,
    },
    /**
     * Set `max-height` style of component.
     */
    maxHeight: {
      type: [Number, String],
      default: null,
    },
    /**
     * Set `max-width` style of component.
     */
    maxWidth: {
      type: [Number, String],
      default: null,
    },
    /**
     * Set `min-height` style of component.
     */
    minHeight: {
      type: [Number, String],
      default: null,
    },
    /**
     * Set `min-width` style of component.
     */
    minWidth: {
      type: [Number, String],
      default: null,
    },
    /**
     * Set `width` style of component.
     */
    width: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    measurableStyles() {
      return {
        height: convertToUnit(this.height),
        maxHeight: convertToUnit(this.maxHeight),
        maxWidth: convertToUnit(this.maxWidth),
        minHeight: convertToUnit(this.minHeight),
        minWidth: convertToUnit(this.minWidth),
        width: convertToUnit(this.width),
      };
    },
  },
};
