/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 198,
    height: 198,
    viewBox: '0 0 198 198',
    data: '<path pid="0" d="M98.824 98.824c-27.251 0-49.412-22.162-49.412-49.412C49.412 22.174 71.572 0 98.824 0c27.25 0 49.411 22.174 49.411 49.412 0 27.25-22.16 49.412-49.411 49.412zM98.412 19c-16.545 0-30 13.44-30 30 0 16.545 13.455 30 30 30s30-13.455 30-30c0-16.56-13.455-30-30-30zm99.235 178.647H0v-12.353c0-43.643 40.641-74.118 98.824-74.118 58.182 0 98.823 30.475 98.823 74.118v12.353zM21.975 178.941h154c0-20.85-25.075-49.015-77-49.015s-77 28.165-77 49.015z" _fill="#000" fill-rule="nonzero"/>'
  }
})
