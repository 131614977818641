// Vue
import { computed, toRefs } from 'vue';
// Composables
import { useElementSize, useScroll } from '@vueuse/core';

export function useOverflowScroll(parentRef, childRef) {
  const { arrivedState } = useScroll(parentRef);
  const { height: childHeight } = useElementSize(childRef);
  const { height: parentHeight } = useElementSize(parentRef);
  const { bottom: bottomArrived } = toRefs(arrivedState);

  const isOverflowing = computed(() => childHeight.value > parentHeight.value);

  return { isOverflowing, bottomArrived };
}
