import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import humps from 'humps';
import { useTrackingStore } from '@/stores/tracking';
import { UserEventTracker } from '@/utils/analytics/tracking';
import { getIntervalByMode } from '@/components/core/calendar/calendar-utils';
import {
  DIALOG_TYPES,
  mixpanelCRUDAction,
  postPublishType,
  postSources,
  APPROVAL_STATUS,
  APPROVAL_POLICY,
  SCHEDULER_ACTION_NAMES,
  DATE_RESTRICTION_ERRORS,
} from '@/app/scheduler/constants';
import { getRating } from '@/utils/vision';
import { useAuthStore } from '@/stores/auth';
import { useIdentityStore } from '@/stores/identity';
import { approvalsPermissionEnum } from '@/components/UserPermissions/utils';

const formatInterval = ({ start, end }) => ({
  startDate: dayjs(start).format('YYYY-MM-DD'),
  endDate: dayjs(end).format('YYYY-MM-DD'),
});

const VISION_INDICATOR_MAP = {
  excellent: 'Star',
  good: 'Up Arrow',
  bad: 'Down Arrow',
};

export const postTypeLabelMap = {
  reel: 'Instagram Reel',
  story: 'Instagram Story',
  feed: 'Instagram Feed Post',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  facebook: 'Facebook',
  twitter: 'X',
  pinterest: 'Pinterest',
  linkedin: 'LinkedIn',
};

export const mixpanelPublishTypes = {
  AUTO_PUBLISH: 'Auto Publish',
  PUBLISH_NOW: 'Publish Now',
  SEND_NOTIFICATION: 'Send Notification',
};

export function getPostSource(post, action) {
  const { NEW, SCHEDULED, UNSCHEDULED } = postSources;
  if (action === mixpanelCRUDAction.CREATED) {
    return NEW;
  }
  return post?.timestamp ? SCHEDULED : UNSCHEDULED;
}

export function getPostType(postData) {
  if (postData.autoPublish) {
    if (postData.isPublishNow) {
      return mixpanelPublishTypes.PUBLISH_NOW;
    }
    return mixpanelPublishTypes.AUTO_PUBLISH;
  }
  return mixpanelPublishTypes.SEND_NOTIFICATION;
}

// Unfortunately, TikTok & Reels capitalize their mediaSources in Mixpanel
// while the other platforms do not so we need to add in a check to keep
// the data consistent
export function getMediaSource(post, postType) {
  if (postType === 'TikTok' || postType === 'Instagram Reel') {
    return post?.media?.[0]?.uploadStatus === 'success' ? 'UPLOAD' : 'LIBRARY';
  }
  return post?.media?.[0]?.uploadStatus === 'success' ? 'Upload' : 'Library';
}

export function getTikTokProperties(postData) {
  return {
    comment: postData?.autoPublish ? postData.disableComment : 'Not Displayed',
    duet: postData?.autoPublish ? postData.disableDuet : 'Not Displayed',
    stitch: postData?.autoPublish ? postData.disableStitch : 'Not Displayed',
  };
}

export function getIGReelProperties(postData) {
  return {
    shareReelToFeed: postData?.shareToFeed,
    coverPhotoSelection: Boolean(postData?.coverImageMedia || postData?.thumbOffset !== null),
    locationTagged: postData?.locationId !== null,
    instagramReelsProductTag: postData?.shoppingTags?.length > 0,
    instagramReelsProductTagAmount: postData?.shoppingTags?.length,
    instagramReelsProductList: postData?.shoppingTags?.map((item) => item.fbProductId),
    publishType: getPostType(postData),
    coverImageOrigin: postData.thumbnailSource,
    collabInviteSent: Boolean(postData?.collaborators?.length > 0),
    collabAmount: postData?.collaborators?.length,
    collabHandles: postData?.collaborators?.map((item) => item.username),
    accountTaggedAmount: postData?.userTags?.length,
    accountTaggedHandles: postData?.userTags?.map((item) => item.username),
  };
}

export function getIGStoryProperties(postData) {
  return {
    hasTrackableLink: postData?.hasTrackableLink,
    withLink: postData?.withLink,
    isCarousel: postData?.isCarousel,
    publishType: getPostType(postData),
  };
}

export function getIGFeedProperties(postData) {
  return {
    locationTagged: postData?.location !== null,
    accountTagged: postData?.accountTagged,
    isCarousel: postData?.isCarousel,
    instagramProductTag: postData?.shoppingTags?.length > 0,
    instagramProductTagAmount: postData?.shoppingTags?.length,
    instagramProductList: postData?.shoppingTags?.map((item) => item.fbProductId),
    publishType: getPostType(postData),
    collabInviteSent: Boolean(postData?.collaborators?.length > 0),
    collabAmount: postData?.collaborators?.length,
    collabHandles: postData?.collaborators?.map((item) => item.username),
    accountTaggedAmount: postData?.userTags?.length,
    accountTaggedHandles: postData?.userTags?.map((item) => item.username),
  };
}

export function getFacebookProperties(postData) {
  return {
    altTextAdded: postData?.altTextMediaMap
      ? Object.keys(postData?.altTextMediaMap)?.length > 0
      : false,
    mediaStatus: postData?.mediaList?.length ? `${postData.mediaList.length} Media` : 'No Media',
    urlLinkAdded: !!postData?.link,
    urlLink: postData?.link,
    urlImageChanged: !!postData?.linkPreviewChanges?.image_media_id,
    urlImageMediaId:
      postData?.linkPreviewChanges?.image_media_id || postData?.linkPreview?.image_media_id,
    urlTitleChanged: !!postData?.linkPreviewChanges?.title,
    urlDescriptionChanged: !!postData?.linkPreviewChanges?.description,
    facebookProductTag: postData?.taggedProducts?.length > 0,
    facebookProductTagAmount: postData?.taggedProducts?.length,
    facebookProductList: postData?.taggedProducts?.map(
      (taggedProduct) => taggedProduct.fbProductId,
    ),
    publishType: getPostType(postData),
    audienceGated: Boolean(postData?.targeting),
    audienceCountries: postData?.targeting?.geoLocations?.countries ?? [],
  };
}

export function getPinterestProperties(postData) {
  const publishType = getPostType(postData);

  return {
    bulkSchedule: !!postData.bulkSchedule,
    publishType,
  };
}

export function getTwitterProperties(postData) {
  let mediaStatus = 'No Media';
  const countMentions = postData?.mentionedUsernames?.length;

  if (postData?.mediaList?.length) {
    mediaStatus = `${postData.mediaList.length} Media`;
  }

  return {
    altTextAdded:
      (postData?.altTextMediaMap ? Object.keys(postData.altTextMediaMap)?.length > 0 : false) ||
      postData?.replies?.filter((reply) =>
        reply?.altTextMediaMap ? Object.keys(reply.altTextMediaMap)?.length > 0 : [],
      )?.length > 0, // First .length checks MediaMap isn't empty, second one checks at least one non-empty was found
    mediaStatus,
    mention: countMentions > 0,
    countMentions,
    mentionAccount: postData?.mentionedUsernames,
    mentionWarningType: postData?.mentionWarningType,
    threaded: postData?.replies && postData?.replies.length > 0,
    autoNumberEnabled: postData?.autoNumberingEnabled,
  };
}

export function getAdditionalProperties(postType, postData) {
  switch (postType) {
    case 'TikTok':
      return getTikTokProperties(postData);
    case 'Instagram Reel':
      return getIGReelProperties(postData);
    case 'Instagram Story':
      return getIGStoryProperties(postData);
    case 'Instagram Feed Post':
      return getIGFeedProperties(postData);
    case 'Facebook':
      return getFacebookProperties(postData);
    case 'Pinterest':
      return getPinterestProperties(postData);
    case 'X':
      return getTwitterProperties(postData);
    default:
      return null;
  }
}

// There’s a discrepancy in the naming convention for scheduled post mixpanel events.
// Reels & TikTok are the reverse of the others. Unfortunately, since these differing
// naming conventions have already existed for some time and the data is linked to them
// we have to accommodate them with a switch function to determine which type of title
// to assign.
export function getMixpanelEventName(postType) {
  switch (postType) {
    case 'Instagram Feed Post':
      return 'Instagram Feed Post Scheduled';
    case 'Instagram Reel':
      return 'Instagram Reel Scheduled Post';
    case 'TikTok':
      return 'TikTok Scheduled Post';
    case 'LinkedIn':
      return 'LinkedIn Scheduled Post';
    default:
      return `${postType} Post Scheduled`;
  }
}

export class SchedulerUserEventTracker extends UserEventTracker {
  EVENT_NAME = {
    SCHEDULER_CALENDAR_INTERACTION: 'Scheduler Calendar Interaction',
    SCHEDULER_CALENDAR_EVENT: 'Scheduler Calendar Event',
    SCHEDULER_MEDIA_DOWNLOAD: 'Scheduler Media Download',
    SCHEDULER_VIEW_POST: 'Scheduler View Post',
    SCHEDULER_CREATE_POST: 'Scheduler Create Post',
    DRAFT_TOGGLE_CLICKED: 'Scheduler Draft Toggle Clicked',
    SCHEDULER_POST_DRAFT_TOGGLE_CLICKED: 'Scheduler Post Draft Toggle Clicked',
  };

  ACTIONS = {
    CREATED: 'Created',
    UPDATED: 'Updated',
    DELETED: 'Deleted',
  };

  constructor(section) {
    super();
    this.section = startCase(section);
  }

  calendarInteraction(filterUsed, calendarMode, selectedDate) {
    this.trackingStore.track(this.EVENT_NAME.SCHEDULER_CALENDAR_INTERACTION, {
      section: this.section,
      ...formatInterval(getIntervalByMode(selectedDate, calendarMode)),
      filterUsed,
      calendarMode,
    });
  }

  calendarEvent(action, event) {
    this.trackingStore.track(this.EVENT_NAME.SCHEDULER_CALENDAR_EVENT, {
      section: this.section,
      action,
      ...formatInterval({
        start: dayjs(event.startDate).toDate(),
        end: dayjs(event.endDate).toDate(),
      }),
      noteAdded: event.description ? 'yes' : 'no',
      eventID: event.id,
      eventName: event.title,
    });
  }

  viewScheduledPost(post) {
    const firstMediaItem = post.media?.[0] || null;
    const postPlatform = startCase(post.platform);
    const postType = post.post_type ? post.post_type.toLowerCase() : 'post';

    this.trackingStore.track(this.EVENT_NAME.SCHEDULER_VIEW_POST, {
      section: this.section,
      postID: post.id,
      postPlatform,
      postPlatformType: `${postPlatform} ${startCase(postType)}`,
      publishType: post.auto_publish ? 'Auto Publish' : 'Send Notification',
      postStatus: post.status,
      scheduledDate: post.timestamp?.toISOString() ?? null,
      mediaType: firstMediaItem?.type,
      mediaIds: post.media_ids,
      draftPost: post.status === 'DRAFT',
    });
  }

  schedulerCreatePost(section, platform, scheduledDate, createdFrom, platformType = false) {
    this.trackingStore.track(this.EVENT_NAME.SCHEDULER_CREATE_POST, {
      section,
      postPlatform: platform === DIALOG_TYPES.PINTEREST_BULK ? DIALOG_TYPES.PINTEREST : platform,
      postPlatformType: platformType ? `${platformType}Post` : `${platform}Post`,
      scheduledDate,
      createdFrom,
    });
  }

  // TODO: Should move visionAccess property inside this file when auth store is converted to pinia.
  schedulerPostEvents(post, postType, action) {
    const postData = humps.camelizeKeys(post);
    const authStore = useAuthStore();
    const visionAccess = authStore.user_can('vision', 'can_access_vision');
    const approvalsRequired = authStore.user_can(
      'scheduler',
      'scheduler_num_of_approvals_required',
    );
    const title = getMixpanelEventName(postType);
    const requireApprovalsToPost = authStore.user_can('scheduler', 'require_approvals_to_post');

    this.trackingStore.track(title, {
      action,
      addToBoard: postData?.boardIds?.length > 0,
      addToGallery: postData?.boardIds?.length > 0,
      addToCampaign: postData?.campaignIds?.length > 0,
      path: window.location.pathname,
      publishType: postData?.autoPublish
        ? postPublishType.AUTO_PUBLISH
        : postPublishType.SEND_NOTIFICATION,
      postSource: getPostSource(postData, action),
      mediaSource: getMediaSource(postData, postType),
      mediaType: postData?.media?.length ? postData.media[0].type : '',
      mediaIds: postData?.media?.map((media) => media.id),
      visionAccess: visionAccess ? 'Yes' : 'No',
      scheduled_date: postData?.timestamp,
      visionIndicator: visionAccess
        ? VISION_INDICATOR_MAP[getRating(postData?.media?.[0]?.predictions?.engagement)]
        : undefined,
      shortenLink: postData?.shortenLink,
      urlLinkCustomized: postData?.customUtms,
      approvalRequested: postData?.approvalRequests?.length > 0,
      approvalRequestAmount: postData?.approvalRequests?.length,
      approvalsRequired,
      approvedAmount: postData?.approvalRequests?.filter(
        (request) => request.approvalStatus === APPROVAL_STATUS.APPROVED,
      ).length,
      postUnlocked: postData?.approvalPolicy
        ? postData?.approvalPolicy !== APPROVAL_POLICY.APPROVAL_REQUIRED
        : !requireApprovalsToPost,
      approverUserIDs: postData?.approvalRequests?.map((request) => request.reviewUserId),
      draftPost: postData?.status === 'DRAFT',
      ...getAdditionalProperties(postType, postData),
    });
  }

  assetSchedulerRestriction(post, postType) {
    const postData = humps.camelizeKeys(post);
    this.trackingStore.track('Scheduler Asset Scheduler Restriction', {
      section: this.section,
      postID: postData.id,
      postPlatform: startCase(post?.platform),
      postPlatformType: postType,
      publishType: postData?.autoPublish
        ? postPublishType.AUTO_PUBLISH
        : postPublishType.SEND_NOTIFICATION,
      scheduledDate: postData.timestamp,
      mediaIds: postData?.media?.map((media) => media.id),
    });
  }

  twitterMentionWarning(postID, mentionLimitType) {
    this.trackingStore.track('Twitter Scheduled Post Mention Warning', {
      postID,
      mentionLimitType,
    });
  }

  schedulerQRCodeNotification(postPlatform, interaction) {
    this.trackingStore.track('Scheduler QR Code Notification', {
      postPlatform,
      interaction,
    });
  }

  downloadMediaFromScheduler(postData, media) {
    const { postId, postPlatform, postPlatformType, postStatus, publishType } = postData;
    const mixpanelData = {
      mediaIds: [media.id],
      mediaType: media.type,
      postId,
      postPlatform,
      postPlatformType,
      postStatus,
      publishType,
      scheduledDate: postData.timestamp ? dayjs(postData.timestamp).format('llll') : null,
    };
    this.trackingStore.track(this.EVENT_NAME.SCHEDULER_MEDIA_DOWNLOAD, mixpanelData);
  }

  approvalPopout(post, platform, postType, action) {
    const postData = humps.camelizeKeys(post);
    const identityStore = useIdentityStore();
    const isReviewer = postData?.approvalRequests?.find(
      (req) => req.reviewUserId === identityStore.identity.id,
    );
    const postPlatform = startCase(platform);
    const postPlatformType = `${postPlatform} ${startCase(postType)}`;

    this.trackingStore.track('Scheduler - Approval Popout', {
      section: this.section,
      postID: postData?.id,
      postPlatform,
      postPlatformType,
      publishType: postData?.autoPublish
        ? postPublishType.AUTO_PUBLISH
        : postPublishType.SEND_NOTIFICATION,
      postStatus: post?.status,
      scheduledDate: post?.timestamp?.toISOString() ?? null,
      mediaSource: getMediaSource(postData, postType),
      mediaType: postData?.media?.length ? postData.media[0].type : '',
      mediaIds: postData?.media?.map((media) => media.id),
      approvalView: isReviewer ? 'Approval View' : 'Requested View',
      action,
    });
  }

  approvalButtonClicked(post, platform, approvalRequests, buttonLabel) {
    const postData = humps.camelizeKeys(post);
    const identityStore = useIdentityStore();
    const isReviewer = approvalRequests?.find(
      (req) => req.reviewUserId === identityStore.identity.id,
    );
    const postPlatform = startCase(platform);
    const postType = post?.post_type ? post.post_type.toLowerCase() : 'post';
    const postPlatformType = `${postPlatform} ${startCase(postType)}`;

    this.trackingStore.track('Scheduler - Approval Button Clicked', {
      section: this.section,
      postID: postData?.id,
      postPlatform,
      postPlatformType,
      publishType: postData?.autoPublish
        ? postPublishType.AUTO_PUBLISH
        : postPublishType.SEND_NOTIFICATION,
      postStatus: post?.status,
      scheduledDate: post?.timestamp?.toISOString() ?? null,
      mediaSource: getMediaSource(postData, postType),
      mediaType: postData?.media?.length ? postData.media[0].type : '',
      mediaIds: postData?.media?.map((media) => media.id),
      approvalView: isReviewer ? 'Approval View' : 'Requested View',
      buttonLabel,
    });
  }

  approvalRequestApprover(post, approvalRequests) {
    const postData = humps.camelizeKeys(post);
    const identityStore = useIdentityStore();
    const authStore = useAuthStore();
    const isReviewer = approvalRequests?.find(
      (req) => req.reviewUserId === identityStore.identity.id,
    );
    const postType = post?.post_type ? post.post_type.toLowerCase() : 'post';
    const postPlatformType = `${post?.platform} ${startCase(postType)}`;
    const approvalsRequired = authStore.user_can(
      'scheduler',
      'scheduler_num_of_approvals_required',
    );
    const requireApprovalsToPost = authStore.user_can('scheduler', 'require_approvals_to_post');
    this.trackingStore.track('Scheduler - Approval Request Approver', {
      section: this.section,
      postID: postData?.id,
      postPlatform: post?.platform,
      postPlatformType,
      publishType: postData?.autoPublish
        ? postPublishType.AUTO_PUBLISH
        : postPublishType.SEND_NOTIFICATION,
      postStatus: post?.status,
      scheduledDate: post?.timestamp ?? null,
      mediaSource: getMediaSource(postData, postType),
      mediaType: postData?.media?.length ? postData.media[0].type : '',
      mediaIds: postData?.media?.map((media) => media.id),
      approvalView: isReviewer ? 'Approval View' : 'Requested View',
      approvalRequested: approvalRequests?.length > 0,
      approvalRequestAmount: approvalRequests?.length,
      approvalsRequired,
      approvedAmount: approvalRequests?.filter(
        (request) => request.approvalStatus === APPROVAL_STATUS.APPROVED,
      ).length,
      postUnlocked: postData?.approvalPolicy
        ? postData?.approvalPolicy !== APPROVAL_POLICY.APPROVAL_REQUIRED
        : !requireApprovalsToPost,
      approverUserIDs: approvalRequests?.map((request) => request.reviewUserId),
    });
  }

  approvalFilterChipClicked(filterList) {
    const identityStore = useIdentityStore();
    this.trackingStore.track('Approval Filter Chip Clicked', {
      filterList,
      userID: identityStore.identity.id,
    });
  }

  approvalSettingsChanged(oldPerms, newPerms, memberUserID) {
    this.trackingStore.track('Approval Settings Changed', {
      canApprove: newPerms[approvalsPermissionEnum.CAN_REVIEW_SCHEDULED_POST],
      requiresApprove: newPerms[approvalsPermissionEnum.REQUIRE_APPROVALS_TO_POST],
      approvalsAmount: newPerms[approvalsPermissionEnum.SCHEDULER_NUM_OF_APPROVALS_REQUIRED],
      memberUserID,
      previousCanApprove: oldPerms[approvalsPermissionEnum.CAN_REVIEW_SCHEDULED_POST],
      previousRequiresApprove: oldPerms[approvalsPermissionEnum.REQUIRE_APPROVALS_TO_POST],
      previousRequiresApprovalsAmount:
        oldPerms[approvalsPermissionEnum.SCHEDULER_NUM_OF_APPROVALS_REQUIRED],
    });
  }

  draftToggleClicked(showDrafts, postPlatform = null, postPlatformType = null) {
    if (postPlatform) {
      this.trackingStore.track(this.EVENT_NAME.SCHEDULER_POST_DRAFT_TOGGLE_CLICKED, {
        postPlatform,
        postPlatformType,
        draftPost: showDrafts,
      });
    } else this.trackingStore.track(this.EVENT_NAME.DRAFT_TOGGLE_CLICKED, { showDrafts });
  }
}

/**
 * @typedef {'Instagram'|'Facebook'|'Pinterest'|'Twitter'|'TikTok'} SchedulerPostUtmPlatform
 * @typedef {'Post'|'Bulk Post'|'Tweet'|'Single Pin'|'Story'|'Reel'|'LikeShop'} SchedulerPostUtmType
 */

/**
 * @param {Object} properties
 * @param {number} [properties.postId]
 * @param {SchedulerPostUtmPlatform} properties.postPlatform
 * @param {SchedulerPostUtmType} properties.postType
 * @param {'open'|'close'|'auto open'|'auto close'} properties.action
 */
export function trackSchedulerUtmPopout({ postId = null, postPlatform, postType, action }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Scheduler - UTM Popout', {
    postId,
    postPlatform,
    postType,
    action,
  });
}

/**
 * @param {Object} properties
 * @param {number} [properties.postId]
 * @param {SchedulerPostUtmPlatform} properties.postPlatform
 * @param {SchedulerPostUtmType} properties.postType
 * @param {Object.<string, string>[]} properties.currentUtms
 * @param {Object.<string, string>[]} properties.defaultUtms
 * @param {string} properties.keyChanged
 * @param {string} properties.value
 * @param {string} properties.previousValue
 */
export function trackSchedulerUtmCustomize({
  postId = null,
  postPlatform,
  postType,
  currentUtms,
  defaultUtms,
  keyChanged,
  value,
  previousValue,
}) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Scheduler - UTM Customize', {
    postId,
    postPlatform,
    postType,
    currentUtms,
    defaultUtms,
    keyChanged,
    value,
    previousValue,
  });
}

/**
 * @param {Object} properties
 * @param {'Feed Post', 'Story', 'Reel'} properties.postPlatformTypeTab
 * @param {'Feed Post', 'Story', 'Reel', null} properties.postPlatformTypeTabPrevious
 */
export function trackInstagramPostTypeClick({
  postPlatformTypeTab,
  postPlatformTypeTabPrevious = null,
}) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Scheduler Instagram Post Type Tab Selected', {
    postPlatformTypeTab,
    postPlatformTypeTabPrevious,
  });
}

export const schedulerStoreEventListener = (store) => {
  const trackMixpanel = new SchedulerUserEventTracker('Scheduler Editor');
  store.$onAction(({ name, args, after, onError }) => {
    const postData = args[0];
    const newApprovalRequests = postData?.approvalRequests?.filter(
      (request) => !request?.approvalStatus,
    );
    const postType = postData?.post_type?.toLowerCase() || postData?.platform;
    const formattedPostType = postTypeLabelMap[postType];
    const postTypes = Object.keys(postTypeLabelMap);
    const sidePanelAction = window.location.href.includes('comments') ? 'Closed' : 'Opened';

    after(() => {
      switch (name) {
        case SCHEDULER_ACTION_NAMES.UPDATE_POST:
          if (newApprovalRequests?.length > 0) {
            trackMixpanel.approvalRequestApprover(postData, newApprovalRequests);
          }
          if (postType) {
            trackMixpanel.schedulerPostEvents(postData, formattedPostType, 'Updated');
          }
          break;
        case SCHEDULER_ACTION_NAMES.CREATE_POST:
          if (newApprovalRequests?.length > 0) {
            trackMixpanel.approvalRequestApprover(postData, newApprovalRequests);
          }
          if (postTypes.includes(postType)) {
            trackMixpanel.schedulerPostEvents(postData, formattedPostType, 'Created');
          }
          break;
        case SCHEDULER_ACTION_NAMES.DELETE_POST:
          if (postTypes.includes(postType)) {
            trackMixpanel.schedulerPostEvents(postData, formattedPostType, 'Deleted');
          }
          break;
        case SCHEDULER_ACTION_NAMES.TOGGLE_SIDE_PANEL:
          trackMixpanel.approvalPopout(postData.post, postData.platform, postType, sidePanelAction);
          break;
        default:
          break;
      }
    });

    onError((error) => {
      switch (name) {
        case SCHEDULER_ACTION_NAMES.UPDATE_POST:
          if (
            error.response?.data?.errors &&
            Object.keys(DATE_RESTRICTION_ERRORS).some((errorCode) =>
              error.response.data.errors.some((e) => e.code === errorCode),
            )
          ) {
            trackMixpanel.assetSchedulerRestriction(postData, postData.platform, formattedPostType);
          }
          break;
        case SCHEDULER_ACTION_NAMES.CREATE_POST:
          if (
            error.response?.data?.errors &&
            Object.keys(DATE_RESTRICTION_ERRORS).some((errorCode) =>
              error.response.data.errors.some((e) => e.code === errorCode),
            )
          ) {
            trackMixpanel.assetSchedulerRestriction(postData, postData.platform, formattedPostType);
          }
          break;
        default:
          break;
      }
    });
  });
};
