export const LINKEDIN_OVERVIEW_SINGLE_BRAND = [
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: {
      name: 'All Published Posts',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['PAGE_VIEWS_ALL_POSTS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'PAGE_VIEWS_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'COMMENTS_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'REACTIONS_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICK_THROUGH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICKS_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'SHARES_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'ENGAGEMENTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'IMPRESSIONS_ALL_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'UNIQUE_IMPRESSIONS_ALL_POSTS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 9,
    meta: {
      name: 'Posts Published During Reporting Period',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'WATCH_TIME_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'ENGAGEMENTS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_ENGAGEMENT_RATE_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'COMMENTS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'CLICKS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_CLICK_THROUGH_RATE_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'IMPRESSIONS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'SHARES_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'REACTIONS_BY_POST',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'UNIQUE_VISITORS_ALL_POSTS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 14,
    meta: {
      name: 'Post Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metrics: ['ENGAGEMENT_RATE'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 19,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICK_THROUGH_RATE_BY_POST'],
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 23,
    meta: {
      name: 'Follower Activity',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 26,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metric: 'AVG_UNIQUE_IMPRESSIONS_BY_POST',
    },
  },
];
