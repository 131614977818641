/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fileCopy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.414 1H9c-1.103 0-2 .898-2 2v14c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V5.586L16.414 1zm2.587 16H9V3h6v4h4l.001 10z"/><path pid="1" d="M5 5H3v16c0 1.103.897 2 2 2h12v-2H5V5z"/>'
  }
})
