<template>
  <transition name="fade" appear :css="transition">
    <div
      class="portal-popup"
      :class="{
        [size]: !!size,
        'veritcal-center': verticalCenter,
      }"
      :style="styles"
      @mousedown.self="handleMouseDown"
      @mouseup.self="handleMouseUp"
    >
      <a
        v-if="!hideCloseButton"
        ref="close"
        v-tooltip="'Close'"
        class="portal-popup__close"
        data-cy="portal-popup-close"
        @click="close"
      >
        <Icon :color="closeIconColor" name="close" small />
      </a>

      <div
        class="portal-popup___content-container"
        :class="containerClasses"
        :style="containerStyles"
      >
        <div v-if="showNavbar" class="portal-popup___content-navbar">
          <Button
            v-if="showBack"
            ref="back"
            back
            link
            icon-name="caret"
            data-cy="portal-popup-back"
            @click="$emit('back')"
          />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';

import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import { detectPageScroll } from '@/utils/dom';

const sizes = ['full', 'xsmall', 'small', 'medium', 'medium-large', 'large', 'larger', 'xlarge'];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PortalPopupBase',
  components: {
    Button: defineAsyncComponent(() => import('@/components/foundation/Button.vue')),
    Icon,
  },
  props: {
    // It is safer to use the containerStyles prop over size
    size: {
      type: String,
      default: null,
      validator(value) {
        return sizes.includes(value) || value == null;
      },
    },
    containerStyles: {
      type: Object,
      default: () => {},
    },
    popupStyles: {
      type: Object,
      default: () => {},
    },
    verticalCenter: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: Boolean,
      default: true,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noPaddingHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back', 'close'],
  data() {
    return {
      mouseDownEvent: null,
    };
  },
  computed: {
    closeIconColor() {
      if (this.size === 'full') {
        return colours.ICON.ICON_SECONDARY;
      }
      return colours.BASIC.WHITE;
    },
    showBack() {
      return this.$attrs.back;
    },
    showNavbar() {
      return this.showBack;
    },
    styles() {
      return { ...this.popupStyles, ...{ background: 'rgba(0, 0, 0, 0.6)' } };
    },
    containerClasses() {
      return {
        'no-padding': this.noPadding,
        'no-padding-horizontal': this.noPaddingHorizontal,
      };
    },
  },
  mounted() {
    detectPageScroll();
    document.body?.classList.add('popup-open');
    window.addEventListener('keyup', () => this.handleEscape);
  },
  beforeUnmount() {
    document.body?.classList.remove('popup-open', 'scrollbar-padding');
    window.removeEventListener('keyup', () => this.handleEscape);
  },
  methods: {
    close({ force = false } = {}) {
      this.$emit('close', { force });
    },
    handleMouseDown(event) {
      this.mouseDownEvent = event;
    },
    handleMouseUp(event) {
      if (event?.target === this.mouseDownEvent?.target) {
        this.close();
      }
    },
    handleEscape(event) {
      const ESCAPE_KEY = 27;
      const key = event.which || event.keyCode;
      if (key === ESCAPE_KEY) {
        this.close();
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.portal-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden;
  z-index: var(--z-index-popup);
  padding: 0 var(--space-4);
  align-items: center;
  display: flex;
  flex-direction: column;

  .portal-popup__close {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
  }

  .portal-popup___content-container {
    margin: var(--space-40);
    padding: var(--space-40);
    background: var(--background-0);
    border-radius: var(--round-corner);
    max-height: calc(100vh - var(--space-80));
    position: relative;

    &.no-padding {
      padding: 0 !important;
    }

    &.no-padding-horizontal {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    /* Positioning this abolutely to avoid complicating max-height calculations */
    .portal-popup___content-navbar {
      border-radius: var(--round-corner);
      left: 0;
      overflow: hidden;
      padding: var(--space-6);
      position: absolute;
      right: 0;
      top: 0;
    }

    :deep(> *) {
      max-height: 100%;
    }
  }

  &.veritcal-center {
    .portal-popup___content-container {
      margin: auto;
    }
  }

  /* full screen */
  &.full {
    background-color: var(--background-0);

    .portal-popup__close {
      position: absolute;
      z-index: 3;
    }

    .portal-popup___content-container {
      margin: 0;
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;
    }
  }

  /* 1200px */
  &.xlarge {
    .portal-popup___content-container {
      max-width: 75rem;
      min-width: 64rem;
      position: relative;
    }
  }

  &.larger {
    .portal-popup___content-container {
      width: 68rem;
    }
  }

  &.large {
    .portal-popup___content-container {
      width: 60rem;
    }
  }

  &.medium-large {
    .portal-popup___content-container {
      width: 50rem;
    }
  }

  /* 620px */
  &.medium {
    .portal-popup___content-container {
      max-width: 38.75rem;
      width: 100%;
    }
  }

  /* 580px */
  &.small {
    .portal-popup___content-container {
      width: 36.25rem;
      min-width: 36.25rem;
    }
  }

  /* 440px */
  &.xsmall {
    .portal-popup___content-container {
      width: 27.5rem;
      min-width: 27.5rem;
    }
  }
}
</style>

<style lang="postcss">
body {
  &.popup-open {
    overflow: hidden;
  }

  &.scrollbar-padding {
    padding: 0 var(--space-10) 0 0;
  }
}
</style>
