let modals = [];
let callbacks = [];

export function pushGlobalModal({ component, props }) {
  modals = [
    ...modals,
    {
      component,
      props,
    },
  ];
  callbacks.forEach((cb) => cb(modals));
}

export function getGlobalModals() {
  return modals;
}

export function popGlobalModal() {
  const modal = modals[modals.length - 1];
  modals = modals.slice(0, -1);
  callbacks.forEach((cb) => cb(modals));
  return modal;
}

export function clearGlobalModals() {
  modals = [];
  callbacks.forEach((cb) => cb(modals));
}

export function watchGlobalModals(callback) {
  callbacks.push(callback);
  callback(modals);
  return () => {
    callbacks = callbacks.filter((cb) => cb !== callback);
  };
}
