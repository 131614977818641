import dayjs from 'dayjs';
import pinia from '@/stores/pinia';
import { useIdentityStore } from '@/stores/identity';

export function guessTimezone() {
  return dayjs?.tz?.guess();
}

export function getTimezoneNames() {
  return Intl.supportedValuesOf('timeZone') || [];
}

export function getUserTimezone() {
  const identityStore = useIdentityStore(pinia);
  const userTimeZone =
    identityStore.identity?.timeZoneName || identityStore.identity?.time_zone_name;
  return userTimeZone || guessTimezone();
}
