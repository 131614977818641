<template>
  <CircularLoader v-if="mediaLinksStore.linkUpdatingMediaId === mediaItem.id" />
  <div v-else>
    <div v-if="mediaItem.products.length === 0" class="spirit-tile">
      <Button light small class="add-link-button" @click.stop="editLinks">Add Links</Button>
    </div>

    <div v-if="mediaItem.products.length > 0" class="spirit-tile stats">
      <header>
        <div v-if="mediaItem.clicks != null" class="clicks-wrapper">
          <h2>
            {{ mediaItem.clicks.toLocaleString() }}
          </h2>
          <span>Clicks</span>
        </div>
        <Icon :color="iconColor" name="link" medium class="edit" @click.stop="editLinks" />
      </header>
      <section v-if="mediaItem.products.length > 1">
        <LinkClicksBox :products="mediaItem.products" />
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import LinkClicksBox from '@/components/LinkClicksBox.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { useMediaLinksStore } from '@/stores/media-links';
import { mixpanelActions } from '@/app/scheduler/constants';
import { useTrackingStore } from '@/stores/tracking';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SpiritTile',
  components: {
    CircularLoader,
    LinkClicksBox,
    Button,
    Icon,
  },
  props: {
    mediaItem: { type: Object, default: null },
    linkLimit: { type: Number, default: null },
    likeShopType: { type: String, default: null },
  },
  data() {
    return {
      iconColor: colours.BASIC.WHITE,
    };
  },
  computed: {
    ...mapStores(useMediaLinksStore, useTrackingStore),
  },
  methods: {
    editLinks() {
      if (this.linkLimit) {
        this.mediaLinksStore.setLinkLimit(this.linkLimit);
      }
      this.mediaLinksStore.openLinkPopup(this.mediaItem);
      const updateLinkData = {
        editFrom: `Likeshop - ${this.likeShopType}`,
        firstLink: this.mediaItem?.products.length === 0,
      };
      this.trackingStore.track(mixpanelActions.LINK_UPDATE_CLICKED, updateLinkData);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.spirit-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: default;
  padding: var(--space-8);
  border-radius: var(--round-corner);
  z-index: 2;
  position: absolute;
  inset: 0;

  .add-link-button {
    max-width: 100%;
    line-height: initial;
    height: auto;
    min-height: 2rem;
    text-align: center;
  }
}

.spirit-tile.stats {
  color: var(--white);
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  bottom: 0;

  header {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex: 0 0 auto;

    .clicks-wrapper {
      max-width: 100%;
    }

    h2 {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--white);
    }

    span {
      font-size: var(--x14);
      color: var(--white);
      font-weight: 500;
    }

    .edit {
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  section {
    display: flex;
    flex-flow: row wrap;
    padding-top: var(--space-16);
    max-width: 100%;
  }
}
</style>
