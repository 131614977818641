import { LibraryAPI } from '@/apis';
import * as CreatorsAPI from '@/apis/creators';
import { useIdentityStore } from '@/stores/identity';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { CancelToken, CanceledError } from 'axios';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';
import { getCountryCode } from '@/utils/countries';
import { creatorSource } from '@/app/creators/constants';

const RECENT_LOCATIONS_KEY = 'creator-discovery-recent-locations';
export const MAX_RECENT_LOCATIONS = 3;

export const useCreatorsStore = defineStore('creators', () => {
  const accounts = ref([]);
  const creators = ref([]);
  const creatorsCount = ref();
  const account = ref();
  const tikTokAccount = ref();
  const instagramAccount = ref();
  const instagramAccountPosts = ref([]);
  const tikTokAccountPosts = ref([]);
  const tikTokRIQPosts = ref([]);
  const brandRelationshipTags = ref([]);

  const pendingCounts = ref({
    account: 0,
    accounts: 0,
    accountPosts: 0,
    tikTokAccountPosts: 0,
    creator: 0,
    creators: 0,
    creatorsCount: 0,
    creatorDetails: 0,
    deleteRelationship: 0,
    addBrandRelationshipTags: 0,
    deleteBrandRelationshipTags: 0,
    brandRelationshipTags: 0,
    relationships: 0,
  });

  const accountsCancelToken = ref(null);
  const creatorsCancelToken = ref(null);

  const paging = ref({
    nextCursor: null,
    lastRequestBody: null,
    accountPostsNextUrl: null,
    tikTokPostsAfterCursor: null,
  });

  const abortControllers = {
    getAccountPosts: ref(null),
    fetchTikTokAccountPosts: ref(null),
  };

  const error = ref({
    accounts: null,
  });

  const recentLocations = ref(JSON.parse(browserStorageGetItem(RECENT_LOCATIONS_KEY) || '[]'));

  const hasAccountsNextPage = computed(
    () => !!paging.value.nextCursor && !!paging.value.lastRequestBody,
  );

  const pending = computed(() =>
    Object.fromEntries(Object.entries(pendingCounts.value).map(([k, v]) => [k, !!v])),
  );

  const accountsBySource = computed(() => groupBy(accounts.value, 'source'));

  const mappedAccounts = computed(() =>
    Object.fromEntries(
      Object.entries(accountsBySource.value).map(([source, sourceAccounts]) => [
        source,
        sourceAccounts.reduce(
          (mapById, accountValue) => ({
            ...mapById,
            [accountValue.sourceCreatorId]: accountValue,
          }),
          {},
        ),
      ]),
    ),
  );

  function addRecentLocations(newLocations) {
    recentLocations.value = [
      ...[...newLocations].reverse(),
      ...recentLocations.value.filter((location) => !newLocations.includes(location)),
    ].slice(0, MAX_RECENT_LOCATIONS);
    browserStorageSetItem(RECENT_LOCATIONS_KEY, JSON.stringify(recentLocations.value));
  }

  async function searchAccounts({
    source,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    interests,
    countries,
    visualFeatures,
    languages,
    minAvgViews,
    maxAvgViews,
    tiktok,
    sortBy,
    brandId,
    minEngagementRate,
    maxEngagementRate,
    mobileOS,
    audience,
    limit,
    afterCursor,
    hasPermission = true,
  }) {
    if (source !== 'INSTAGRAM' && !hasPermission) {
      throw new Error('Teaser results are only supported for Instagram!');
    }

    // Reset the error value before fetching.
    error.value.accounts = null;

    const requestBody = {
      brandId,
      sources: [source],
      followersGreaterThan,
      followersLessThan,
      bioIncludes,
      bioDoesNotInclude,
      captionsInclude,
      captionsDoNotInclude,
      bioOrCaptionsInclude,
      hasMentionedBrand,
      hasPostedBetween,
      interests,
      countryCodes: source === 'INSTAGRAM' ? countries?.map(getCountryCode) : countries,
      languages,
      minAvgViews,
      maxAvgViews,
      visualFeatures,
      audience,
      tiktok,
      sortBy,
      minEngagementRate,
      maxEngagementRate,
      mobileOS,
      limit,
      afterCursor,
      hasPermission,
    };

    // If we have already loaded accounts with the provided parameters, we
    // can skip making the request again.
    if (isEqual(requestBody, paging.value.lastRequestBody)) {
      return;
    }

    // Cancel pending request, if there is one
    accountsCancelToken.value?.cancel();

    const searchAccountsFunction = hasPermission
      ? CreatorsAPI.searchAccounts
      : CreatorsAPI.searchAccountsForTeaser;
    try {
      pendingCounts.value.accounts += 1;
      accounts.value = [];
      paging.value.lastRequestBody = null;
      accountsCancelToken.value = CancelToken.source();
      const response = await searchAccountsFunction(requestBody, {
        cancelToken: accountsCancelToken.value.token,
      });
      const payload = hasPermission ? response.data : response;
      accounts.value = payload?.data;
      paging.value.nextCursor = payload?.paging?.nextCursor;
      paging.value.lastRequestBody = requestBody;
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        error.value.accounts = e.response;
        throw e;
      }
    } finally {
      if (pendingCounts.value.accounts > 0) pendingCounts.value.accounts -= 1;
    }
  }

  async function fetchAccount({ source, sourceCreatorId, handle, brandId }) {
    let existingAccount;
    if (sourceCreatorId) {
      existingAccount = mappedAccounts.value[source]?.[sourceCreatorId];
    }
    if (existingAccount && paging.value.lastRequestBody?.brandId === brandId) {
      account.value = existingAccount;
      return existingAccount;
    }

    pendingCounts.value.account += 1;
    try {
      const response = await CreatorsAPI.fetchAccount({ source, sourceCreatorId, handle, brandId });
      account.value = response.data.data;
      return response.data.data;
    } finally {
      pendingCounts.value.account -= 1;
    }
  }

  async function fetchTikTokAccount({ brandId, handle }) {
    tikTokAccount.value = await fetchAccount({ brandId, handle, source: creatorSource.TIKTOK });
  }

  async function clearTikTokAccount() {
    tikTokAccount.value = null;
    account.value = null;
  }

  async function fetchInstagramAccount({ brandId, sourceCreatorId }) {
    instagramAccount.value = await fetchAccount({
      brandId,
      sourceCreatorId,
      source: creatorSource.INSTAGRAM,
    });
  }

  async function clearInstagramAccount() {
    instagramAccount.value = null;
    account.value = null;
  }

  async function fetchAccountsNextPage() {
    if (!hasAccountsNextPage.value || pending.value.accounts) {
      return;
    }

    pendingCounts.value.accounts += 1;
    try {
      accountsCancelToken.value = CancelToken.source();
      const response = await CreatorsAPI.searchAccounts(
        {
          ...paging.value.lastRequestBody,
          afterCursor: paging.value.nextCursor,
        },
        { cancelToken: accountsCancelToken.value.token },
      );

      const payload = response.data;
      const data = payload.data;
      accounts.value = [...accounts.value, ...data];
      paging.value.nextCursor = payload.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.accounts -= 1;
    }
  }

  function removeAccount({ source, sourceCreatorId }) {
    accounts.value = accounts.value.filter(
      (accountValue) =>
        accountValue.source !== source || accountValue.sourceCreatorId !== sourceCreatorId,
    );
  }

  function clearAccounts() {
    accountsCancelToken.value?.cancel();
    accountsCancelToken.value = null;
    creatorsCancelToken.value?.cancel();
    creatorsCancelToken.value = null;
    accounts.value = [];
    creators.value = [];
    pendingCounts.value = {
      account: 0,
      accounts: 0,
      creators: 0,
      creator: 0,
      creatorDetails: 0,
      creatorsCount: 0,
      brandRelationshipTags: 0,
    };
    paging.value = {
      nextCursor: null,
      lastRequestBody: null,
    };
  }

  async function fetchAllBrandCreators() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    try {
      pendingCounts.value.creators += 1;

      let nextUrl;
      const data = [];

      const fetchBrandCreatorsRecursive = async () => {
        const response = await CreatorsAPI.fetchBrandCreators({ url: nextUrl, brandId });
        data.push(...response.data.data);
        nextUrl = response.data.paging.next;

        if (nextUrl) {
          await fetchBrandCreatorsRecursive();
        }
      };

      await fetchBrandCreatorsRecursive();

      creators.value = data;
    } finally {
      pendingCounts.value.creators = false;
    }
  }

  async function fetchBrandCreators({ creatorIds }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.creators += 1;
    try {
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds,
      });
      creators.value = response.data.data;
    } finally {
      pendingCounts.value.creators -= 1;
    }
  }

  async function getAccountPosts({ brandId, limit, offset, sortFields, filters, includeCreator }) {
    pendingCounts.value.accountPosts += 1;

    try {
      const signal = refreshAbortController(abortControllers.getAccountPosts);

      const response = await LibraryAPI.getMediaListV2(
        {
          brandId,
          limit,
          offset,
          sortFields,
          filters,
          includeCreator,
          url: paging?.value?.accountPostsNextUrl,
        },
        { signal },
      );
      const payload = response.status === 200 ? response.data : response;

      if (payload.data) {
        if (account?.value?.source === creatorSource.INSTAGRAM)
          instagramAccountPosts.value = [...instagramAccountPosts.value, ...payload.data];
        else if (account?.value?.source === creatorSource.TIKTOK)
          tikTokRIQPosts.value = [...tikTokRIQPosts.value, ...payload.data];

        paging.value.accountPostsNextUrl = payload?.paging?.next;
      }

      return payload;
    } catch (e) {
      return handleCancelError(e);
    } finally {
      pendingCounts.value.accountPosts -= 1;
    }
  }

  // Creator Profile Drawer Related State
  const creator = ref();
  const editCreator = ref(false);

  function clearCreator() {
    pendingCounts.value.creator = 0;
    pendingCounts.value.creatorDetails = 0;
    editCreator.value = false;
    creator.value = undefined;
  }

  async function fetchCreator({ creatorId }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.creator += 1;
    try {
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds: [creatorId],
      });
      creator.value = response.data.data[0];
    } finally {
      pendingCounts.value.creator -= 1;
    }
  }

  async function updateCreatorDetails({ creatorId, creatorDetails }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.creatorDetails += 1;
    try {
      await CreatorsAPI.updateCreatorDetails({
        brandId,
        creatorId,
        creatorDetails,
      });
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds: [creatorId],
      });
      creator.value = response.data.data[0];
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creatorDetails -= 1;
    }
  }

  function clearAccountsPosts() {
    instagramAccountPosts.value = [];
    paging.value = {
      accountPostsNextUrl: null,
    };
  }

  function clearTikTokRIQPosts() {
    tikTokRIQPosts.value = [];
    paging.value = {
      accountPostsNextUrl: null,
    };
  }

  function clearBrandRelationshipTags() {
    brandRelationshipTags.value = [];
  }

  function clearAccount() {
    account.value = null;
  }

  async function fetchBrandRelationshipTags() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    try {
      pendingCounts.value.brandRelationshipTags += 1;

      let nextUrl;
      const data = [];

      const fetchBrandRelationshipTagsRecursive = async () => {
        const response = await CreatorsAPI.fetchBrandRelationshipTags({ url: nextUrl, brandId });
        data.push(...response.data.data);
        nextUrl = response.data.paging.next;

        if (nextUrl) {
          await fetchBrandRelationshipTagsRecursive();
        }
      };

      await fetchBrandRelationshipTagsRecursive();

      brandRelationshipTags.value = data;
    } finally {
      pendingCounts.value.brandRelationshipTags -= 1;
    }
  }

  async function deleteRelationship({ creatorId }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    const creatorIds = [creatorId];
    pendingCounts.value.deleteRelationship += 1;
    try {
      await CreatorsAPI.bulkDeleteRelationships({
        brandId,
        creatorIds,
      });

      creators.value = creators.value.filter((t) => t.creatorId !== parseInt(creatorId, 10));
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteRelationship -= 1;
    }
  }

  async function bulkDeleteRelationships({ creatorIds }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    pendingCounts.value.deleteRelationship += 1;
    try {
      await CreatorsAPI.bulkDeleteRelationships({
        brandId,
        creatorIds,
      });

      creators.value = creators.value.filter((t) => !creatorIds.includes(t.creatorId));
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteRelationship -= 1;
    }
  }

  async function addTagToRelationship({ creatorId, name, color }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.addBrandRelationshipTags += 1;
    try {
      const response = await CreatorsAPI.addRelationshipTag({
        brandId,
        creatorId,
        name,
        color,
      });
      const payload = response.data;
      creator.value.tags.push(payload.name);
      creators.value?.find((c) => c.creatorId === creatorId)?.tags?.push(payload.name);

      // Check if brandrelationship tag has the tag else update it
      const index = brandRelationshipTags.value.findIndex((tag) => tag.name === name);
      if (index === -1) {
        brandRelationshipTags.value = [payload, ...brandRelationshipTags.value];
      }
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.addBrandRelationshipTags -= 1;
    }
  }

  async function bulkAddTagsToRelationships({ creatorIds, tags }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.addBrandRelationshipTags += 1;
    try {
      const response = await CreatorsAPI.bulkAddRelationshipTags({
        brandId,
        creatorIds,
        tags,
      });
      const newTags = response.data.data.filter(
        (tag) => !brandRelationshipTags.value.find((t) => t.id === tag.id),
      );
      brandRelationshipTags.value = [...brandRelationshipTags.value, ...newTags];
      creatorIds.forEach((creatorId) => {
        const creatorObj = creators.value.find((c) => c.creatorId === creatorId);
        creatorObj.tags = response.data.data.map((tag) => tag.name);
        if (creator.value?.creatorId === creatorId) {
          creator.value.tags = response.data.data.map((tag) => tag.name);
        }
      });
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.addBrandRelationshipTags -= 1;
    }
  }

  async function removeTagFromRelationship({ creatorId, tagId }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.deleteBrandRelationshipTags += 1;
    try {
      await CreatorsAPI.deleteRelationshipTag({
        brandId,
        creatorId,
        tagId,
      });
      const label = brandRelationshipTags.value.find((item) => item.id === tagId);
      creator.value.tags = creator.value.tags.filter((t) => t !== label.name);
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteBrandRelationshipTags -= 1;
    }
  }

  async function createRelationships({ creatorAccounts }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.relationships += 1;
    try {
      const creatorResponse = await CreatorsAPI.findOrCreateCreators({ creators: creatorAccounts });
      await CreatorsAPI.createRelationships({
        brandId,
        creatorIds: creatorResponse.data.data.map(({ id }) => id),
      });
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.relationships -= 1;
    }
  }

  // Your Creators Related State
  const creatorsPaging = ref({
    nextCursor: null,
    requestBody: null,
  });

  async function fetchYourCreators(
    {
      limit,
      sort,
      lookupName,
      restrictToPlatforms,
      postsPublishedStartDate,
      postsPublishedEndDate,
      tagFilterType,
      tags,
      hasMentionedBrand,
      instagramPostType,
      instagramConnectionStatus,
      instagramFollowersGreaterThan,
      instagramFollowersLessThan,
      instagramInterests,
      instagramRegions,
      instagramIsBusiness,
      tiktokConnectionStatus,
      tiktokFollowersGreaterThan,
      tiktokFollowersLessThan,
      tiktokTTCMStatus,
      tiktokInterests,
      tiktokRegions,
      tiktokAudienceRegions,
      tiktokAudienceGender,
      tiktokAudienceAge,
    },
    refresh = true,
  ) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    if (refresh) creators.value = [];
    pendingCounts.value.creators += 1;
    creatorsPaging.value.requestBody = {
      limit,
      sort,
      lookupName,
      restrictToPlatforms,
      postsPublishedStartDate,
      postsPublishedEndDate,
      tagFilterType,
      tags,
      hasMentionedBrand,
      instagramPostType,
      instagramConnectionStatus,
      instagramFollowersGreaterThan,
      instagramFollowersLessThan,
      instagramInterests,
      instagramRegions,
      instagramIsBusiness,
      tiktokConnectionStatus,
      tiktokFollowersGreaterThan,
      tiktokFollowersLessThan,
      tiktokTTCMStatus,
      tiktokInterests,
      tiktokRegions,
      tiktokAudienceRegions,
      tiktokAudienceGender,
      tiktokAudienceAge,
    };
    creatorsCancelToken.value?.cancel();
    creatorsCancelToken.value = CancelToken.source();
    try {
      const response = await CreatorsAPI.searchCreators(
        {
          brandId,
          ...creatorsPaging.value.requestBody,
        },
        {
          cancelToken: creatorsCancelToken.value.token,
        },
      );
      creators.value = response.data.data;
      creatorsPaging.value.nextCursor = response.data.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creators -= 1;
    }
  }

  async function fetchYourCreatorsNextPage() {
    if (!creatorsPaging.value.nextCursor) return;
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    pendingCounts.value.creators += 1;
    creatorsCancelToken.value = CancelToken.source();
    try {
      const response = await CreatorsAPI.searchCreators(
        {
          brandId,
          ...creatorsPaging.value.requestBody,
          afterCursor: creatorsPaging.value.nextCursor,
        },
        { cancelToken: creatorsCancelToken.value.token },
      );
      creators.value = [...creators.value, ...response.data.data];
      creatorsPaging.value.nextCursor = response.data.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creators -= 1;
    }
  }

  async function fetchCreatorsCount() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    try {
      pendingCounts.value.creatorsCount += 1;
      const response = await CreatorsAPI.fetchCreatorsCount({ brandId });
      creatorsCount.value = response.data.paging.size;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creatorsCount -= 1;
    }
  }

  async function fetchTikTokAccountPosts({ brandId, source, handle, cache, limit }) {
    pendingCounts.value.tikTokAccountPosts += 1;

    try {
      const signal = refreshAbortController(abortControllers.fetchTikTokAccountPosts);

      const response = await CreatorsAPI.fetchAccountPosts(
        {
          brandId,
          source,
          handle,
          cache,
          afterCursor: creatorsPaging.value.tikTokPostsAfterCursor,
          limit,
        },
        { signal },
      );
      tikTokAccountPosts.value = [...tikTokAccountPosts.value, ...response.data.data];
      creatorsPaging.value.tikTokPostsAfterCursor = response.data.paging.nextCursor;
    } catch (e) {
      handleCancelError(e);
    } finally {
      pendingCounts.value.tikTokAccountPosts -= 1;
    }
  }

  function clearTikTokAccountPosts() {
    tikTokAccountPosts.value = [];
    creatorsPaging.value.tikTokPostsAfterCursor = null;
  }

  return {
    account,
    tikTokAccount,
    instagramAccount,
    accounts,
    accountsBySource,
    pending,
    hasAccountsNextPage,
    searchAccounts,
    clearAccount,
    fetchAccount,
    fetchTikTokAccount,
    fetchInstagramAccount,
    fetchAccountsNextPage,
    removeAccount,
    clearTikTokAccount,
    clearInstagramAccount,
    clearAccounts,
    clearTikTokRIQPosts,
    recentLocations,
    addRecentLocations,
    paging,
    creators,
    fetchAllBrandCreators,
    fetchBrandCreators,
    getAccountPosts,
    instagramAccountPosts,
    brandRelationshipTags,
    clearBrandRelationshipTags,
    fetchBrandRelationshipTags,
    updateCreatorDetails,
    creator,
    editCreator,
    fetchCreator,
    clearCreator,
    clearAccountsPosts,
    deleteRelationship,
    bulkDeleteRelationships,
    addTagToRelationship,
    bulkAddTagsToRelationships,
    removeTagFromRelationship,
    creatorsPaging,
    fetchYourCreators,
    fetchYourCreatorsNextPage,
    createRelationships,
    creatorsCount,
    fetchCreatorsCount,
    tikTokAccountPosts,
    tikTokRIQPosts,
    fetchTikTokAccountPosts,
    clearTikTokAccountPosts,
    error,
  };
});
