<template>
  <div v-on-click-outside="closeProfileDropdown" class="brand-switcher">
    <div
      v-if="authStore.currentBrand"
      :class="['current-account text-small', { 'single-brand': !hasMultipleBrands }]"
      @click.prevent="toggleProfileDropdown"
    >
      <BrandAvatar class="brand-avatar" :brand="authStore.currentBrand" small />
      <p class="brand-name">
        {{ authStore.currentBrand.name }}
      </p>
    </div>
    <transition name="slide-right">
      <div v-if="profileDropdownOpen" class="account-list" @keyup="onKeyup">
        <div v-if="showSearchBox" class="list-search-box" @click.prevent="openProfileDropdown">
          <input
            ref="searchbox"
            v-model="searchTerm"
            class="list-search form-control"
            type="text"
            placeholder="Search brand"
            @click="focus"
          />
        </div>
        <ul role="menu">
          <li
            v-for="brand in filteredBrandList"
            :id="`brand-switcher-item-${brand.id}`"
            :key="brand.id"
          >
            <a
              class="brand-switch"
              :class="{
                'selected-brand': brand.id === authStore.currentBrand.id,
                'focus-brand': brand.id === focusBrandId,
              }"
              :title="brand.name"
              @click.prevent="changeBrand(brand)"
            >
              <BrandAvatar class="brand-list-avatar" :brand="brand" xsmall />
              <div class="brand-list-name">{{ brand.name }}</div>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useAuthStore } from '@/stores/auth';
import BrandAvatar from '@/components/core/BrandAvatar.vue';
import { UP_ARROW, DOWN_ARROW, ENTER, ESCAPE } from '@/utils/keycodes';
import { useTrackingStore } from '@/stores/tracking';
import { useUtmStore } from '@/stores/utm';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'BrandSwitcher',
  components: {
    BrandAvatar,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  data() {
    return {
      profileDropdownOpen: false,
      searchTerm: '',
      windowWidth: null,
      focusBrand: null,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useTrackingStore, useUtmStore),
    brandList() {
      if (this.authStore.identity) {
        return Object.keys(this.authStore.identity.brands).map(
          (brandLabel) => this.authStore.identity.brands[brandLabel],
        );
      }
      return [];
    },
    currentBrandLabel() {
      return this.authStore.currentBrand?.label;
    },
    filteredBrandList() {
      return this.brandList.filter((item) => {
        const searching = this.searchTerm !== null;
        const foundMatch = item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        return searching && foundMatch;
      });
    },
    hasMultipleBrands() {
      return this.brandList.length > 1;
    },
    showSearchBox() {
      return this.brandList.length > 2;
    },
    focusBrandId() {
      return this.focusBrand?.id;
    },
    focusBrandIndex() {
      return this.filteredBrandList.findIndex((brand) => brand.id === this.focusBrand?.id);
    },
    firstFilteredBrand() {
      return this.filteredBrandList?.length > 0 ? this.filteredBrandList[0] : null;
    },
    lastFilteredBrand() {
      return this.filteredBrandList?.length > 0
        ? this.filteredBrandList[this.filteredBrandList.length - 1]
        : null;
    },
  },
  watch: {
    $route() {
      this.closeProfileDropdown();
    },
    profileDropdownOpen(open) {
      if (open) {
        this.focusBrand = null;
      }
    },
    filteredBrandList: {
      immediate: true,
      handler(newBrandList = []) {
        if (newBrandList?.length < 1) {
          this.focusBrand = null;
        }
      },
    },
    focusBrand(brand) {
      if (brand) {
        const elem = document.getElementById(`brand-switcher-item-${brand.id}`);
        if (elem) {
          elem.scrollIntoView(false);
        }
      }
    },
  },
  methods: {
    toggleProfileDropdown() {
      if (this.hasMultipleBrands) {
        this.profileDropdownOpen = !this.profileDropdownOpen;
        if (this.profileDropdownOpen) {
          // hack to get around render timing issue
          setTimeout(() => {
            this.focus();
          }, 100);
        }
        this.searchTerm = '';
      }
    },
    pickFocusBrand() {
      if (this.focusBrand) {
        this.changeBrand(this.focusBrand);
      }
    },
    changeBrand(brand) {
      this.closeProfileDropdown();
      const brandLabel = brand.label;

      if (this.$route.params.brandLabel) {
        if (this.utmStore.isManagementSettingsDirty) {
          this.utmStore.confirmLeaving(() => {
            this.$router.push({
              name: this.$route.name,
              params: { brandLabel },
            });
          });
        } else {
          this.$router.push({
            name: this.$route.name,
            params: { brandLabel },
          });
        }
      } else {
        this.authStore.setCurrentBrand({ brandLabel });
        nextTick(() => {
          // faking a Page Viewed event to keep metrics consistent
          this.trackingStore.trackPageViewedEvent(this.$route, null);
        });
      }
    },
    closeProfileDropdown() {
      this.profileDropdownOpen = false;
    },
    openProfileDropdown() {
      this.profileDropdownOpen = true;
    },
    focus() {
      if (this.$refs.searchbox) {
        this.$refs.searchbox.focus();
      }
    },
    moveFocusBrandUp() {
      const hasFilteredBrands = this.filteredBrandList?.length > 0;
      if (hasFilteredBrands) {
        const noFocusBrand = this.focusBrand === null;
        const notFirstBrand = this.focusBrandIndex > 0;
        if (noFocusBrand) {
          this.focusBrand = this.lastFilteredBrand;
        } else if (notFirstBrand) {
          this.focusBrand = this.filteredBrandList[this.focusBrandIndex - 1];
        }
      }
    },
    moveFocusBrandDown() {
      const hasFilteredBrands = this.filteredBrandList?.length > 0;
      if (hasFilteredBrands) {
        const noFocusBrand = this.focusBrand === null;
        const notLastBrand = this.focusBrandIndex < this.filteredBrandList.length - 1;
        if (noFocusBrand) {
          this.focusBrand = this.firstFilteredBrand;
        } else if (notLastBrand) {
          this.focusBrand = this.filteredBrandList[this.focusBrandIndex + 1];
        }
      }
    },
    onKeyup(event) {
      const keyCode = event.which ?? event.keyCode;
      if (keyCode === ESCAPE) {
        this.closeProfileDropdown();
      } else if (keyCode === ENTER) {
        this.pickFocusBrand();
      } else if (keyCode === UP_ARROW) {
        this.moveFocusBrandUp();
      } else if (keyCode === DOWN_ARROW) {
        this.moveFocusBrandDown();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.account-list {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 225px;
  z-index: var(--z-index-raised);
  box-shadow: var(--shadow-4);
  overflow: hidden;
  background-color: var(--background-0);
  display: flex;
  flex-direction: column;

  .brand-switch {
    display: flex;

    .brand-list-avatar {
      margin-right: var(--space-8);
      flex-shrink: 0;
    }
  }

  .list-search-box {
    margin: var(--space-12) var(--space-16);

    .list-search {
      width: 100%;
      height: 2rem;
      font-size: var(--x13);
      background: url('@/assets/icons/search.svg') no-repeat 0.875rem center var(--background-0);
      padding: 0 var(--space-32) 0 var(--space-40);
      border-radius: 1.125rem;
    }

    .list-search:focus {
      background-color: var(--background-0);
      outline: none;
    }
  }

  ul {
    overflow: auto;
    height: 100%;

    a {
      padding: var(--space-8) var(--space-8) var(--space-8) var(--space-16);
      display: inline-block;
      width: 100%;
      font-size: var(--x14);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--text-secondary);

      &.selected-brand {
        color: var(--text-primary);
      }

      &.focus-brand {
        background-color: var(--background-300);
      }

      img {
        width: 1.875rem;
        height: 1.875rem;
        margin-right: var(--space-12);
        vertical-align: middle;
        border-radius: 50%;
      }

      .brand-list-name {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  a:hover {
    color: var(--text-primary);
  }
}

.brand-switcher {
  display: flex;
  align-items: center;

  .current-account {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: var(--font-medium);
    max-width: 215px; /* width - 10px for margin */

    .brand-name {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: var(--space-8);
      opacity: 0.7;
    }

    &:hover {
      .brand-name {
        opacity: 1;
      }
    }

    &.single-brand {
      cursor: auto;
    }

    .brand-avatar {
      margin-right: var(--space-8);
      flex-shrink: 0;
    }
  }
}
</style>
