import isString from 'lodash/isString';

export const PRICE_PLANS = Object.freeze({
  GROW_YEARLY: {
    lookupKey: 'grow_yearly_full',
    buttonLabel: 'Subscribe for $5988 USD/year',
    price: 5988,
  },
});

export function getPricePlan(value) {
  return isString(value)
    ? Object.values(PRICE_PLANS).find((plan) => plan.lookupKey === value)
    : value;
}
