<template>
  <InsightsDropdown
    :has-insights-access="accessPinInsight"
    header-title="Pinterest Insights"
    :header-tooltip="insightsTooltip"
    platform-icon="pinterest"
  >
    <template #dropdownContents>
      <div class="insights-list">
        <div v-if="!insightsExist" class="alert-message">
          <p>Pinterest source data is currently unavailable for this pin.</p>
        </div>
        <div v-else>
          <div v-if="postedBeforeConnection && timeInterval === 'allTime'" class="alert-message">
            <p>
              Estimated Total Stats only reflects interactions or views which occurred after you
              connected your Pinterest account to Dash Hudson.
            </p>
            <p v-if="connectionDate">Pinterest was connected on {{ formatConnectionDate }}.</p>
          </div>
          <div v-else-if="!isPublicPin" class="alert-message">
            <p>{{ privatePinMessage }}</p>
          </div>
          <div
            v-if="postedBeforeConnection === false && timeInterval === 'allTime'"
            class="alert-message"
          >
            <p>Estimated Total Stats are estimated by aggregating total 24hr Pin stats</p>
          </div>
          <div v-if="accessPinInsight && !displayPinStatusWarning" class="stat-interval">
            <Select
              v-model="timeInterval"
              :options="getIntervalOptions"
              class="select-control flex h-10 px-3 pt-3"
            />
          </div>
          <ul class="insights-stats">
            <template v-for="(value, key) in mediaItem.insights[timeInterval]">
              <li
                v-if="shouldDisplayInsight(value, key)"
                :key="key"
                :class="{ disabled: !accessPinInsight || displayPinStatusWarning }"
              >
                <!-- '!= null' will filter both null and undefined,
                 '!== null' will only catch null
                  we want the first case here -->
                <span>
                  {{ formatKey(key) }}
                  <InfoTooltip :tooltip="metricsTooltip(key)" />
                </span>
                <span class="stat">{{ formatValue(key, value) }}</span>
              </li>
            </template>
          </ul>
          <div :class="['disable-message', { hide: accessPinInsight }]">
            <Icon name="lock-1" height="40" width="40" />
            <p>
              Looks like this feature isn't included in your plan.
              <a @click="contactUsClicked">Message us</a>
              on chat or send us an email to try it out!
            </p>
          </div>
          <!-- end pin details -->

          <VideoStatusOverlay
            v-if="displayPinStatusWarning && accessPinInsight"
            :warning-type="getOverlayType"
          />
        </div>
      </div>
    </template>
  </InsightsDropdown>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import { useAuthStore } from '@/stores/auth';
import { formatMillisecondsAsTime } from '@/utils/formatters';
import { toolTips } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import Select from '@/components/Select.vue';
import { getMediaTypeFromDetail } from '@/utils/media';
import { getMetricKeyOverride } from '@/app/pinterest/Utils';
import { usePlatformStore } from '@/stores/platform';
import { useSupportChatStore } from '@/stores/support-chat';
import InsightsDropdown from './InsightsDropdown.vue';
import VideoStatusOverlay from './VideoStatusOverlay.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PinterestInsights',
  components: { Icon, InfoTooltip, InsightsDropdown, Select, VideoStatusOverlay },
  props: { mediaItem: { type: Object, default: null } },
  data() {
    return {
      timeInterval: '30d',
      pendingReview: false,
      intervalOptions: [
        { label: 'Est. Total Stats', value: 'allTime' },
        { label: 'Last 24 Hours', value: '24h' },
        { label: 'Last 7 Days', value: '7d' },
        { label: 'Last 30 Days', value: '30d' },
        { label: 'Last 90 Days', value: '90d' },
      ],
      tooltips: toolTips.pinterestInsights,
      privatePinMessage:
        'This pin was created in a protected board. Pin insights for pins created in protected boards are unavailable via the Pinterest API.',
    };
  },
  computed: {
    ...mapStores(usePlatformStore, useSupportChatStore),
    ...mapPiniaState(useAuthStore, ['identity', 'currentBrand', 'user_can']),
    displayPinStatusWarning() {
      return this.mediaItem.videoStatus && this.mediaItem.videoStatus !== 'SUCCESS';
    },
    getIntervalOptions() {
      return [
        { label: 'Est. Total Stats', value: 'allTime' },
        { label: 'Last 30 Days', value: '30d' },
        { label: 'Last 90 Days', value: '90d' },
      ];
    },
    insightsTooltip() {
      return toolTips.pinterestInsights.insightsHeader;
    },
    getOverlayType() {
      const status = this.mediaItem.videoStatus;
      // Defaulting to one of two warning overlays currently as we only have two designs
      if (status === 'PROCESSING' || status === 'REVIEWING') {
        return 'PROCESSING';
      }
      if (status === 'PROCESSING_FAILED' || status === 'REVIEWING_FAILED') {
        return 'FAILED';
      }
      return 'NO_OVERLAY';
    },
    isPublicPin() {
      if (this.mediaItem) {
        const { pinBoardPrivacy } = this.mediaItem;
        return pinBoardPrivacy === 'public';
      }
      return false;
    },
    formatConnectionDate() {
      return dayjs(this.connectionDate).format('ll');
    },
    isImage() {
      return getMediaTypeFromDetail(this.mediaItem) === 'photo';
    },
    connectionDate() {
      const platformConnection = this.platformStore.platformConnectionsMap.pinterest;
      if (platformConnection && platformConnection[this.currentBrand.id]) {
        return platformConnection[this.currentBrand.id].created_at;
      }
      return null;
    },
    accessPinInsight() {
      return this.user_can('pinterest', 'can_access_pint');
    },
    postedBeforeConnection() {
      return !this.connectionDate || this.mediaItem.datePosted < this.connectionDate;
    },
    insightsExist() {
      return Object.keys(this.mediaItem?.insights || {}).length > 0;
    },
  },
  methods: {
    shouldDisplayInsight(value, key) {
      const imageStatsKeys = ['closeups'];
      const videoStatsKeys = ['videoViews', 'averageWatchTime'];

      if (value == null || key === 'lastUpdated') {
        return false;
      }
      if (this.mediaItem.isVideo) {
        return !imageStatsKeys.includes(key);
      }
      return !videoStatsKeys.includes(key);
    },
    contactUsClicked() {
      this.supportChatStore.show();
    },
    formatKey(key) {
      return startCase(camelCase(getMetricKeyOverride(key)));
    },
    formatValue(key, value) {
      if (!this.accessPinInsight || this.displayPinStatusWarning || !this.isPublicPin) {
        return '-';
      }
      if (key === 'engagementRate') {
        const {
          mediaItem: {
            insights: {
              allTime: { engagementRate },
            },
          },
        } = this;
        if (!engagementRate) {
          return '-';
        }
        return `${(value * 100).toFixed(2)}%`;
      }
      if (key === 'averageWatchTime') {
        return formatMillisecondsAsTime(value);
      }
      return value.toLocaleString();
    },
    metricsTooltip(key) {
      const pinTooltips = toolTips.pinterestInsights;
      if (key === 'engagementRate') {
        if (this.isImage) {
          return pinTooltips[`${key}Image`];
        }
        return pinTooltips[`${key}Video`];
      }
      return pinTooltips[camelCase(getMetricKeyOverride(key))];
    },
    formatStartDate(endDate, days) {
      return dayjs(endDate).subtract(days, 'day').format('MMM DD, YYYY');
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.insights-list {
  .stat-interval {
    display: flex;
    align-items: center;
    margin: var(--space-16) 0 0 var(--space-32);
    padding-left: var(--space-4);

    .select-control {
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      height: var(--space-32);
      background: var(--background-0);
      align-items: center;
      margin: 0 0 0 auto;

      :deep(.picker) {
        padding-bottom: var(--space-12);
        background-position-y: 0.3rem;
        font-size: var(--x12);
      }

      :deep(.options ul) {
        margin: 0;
      }
    }

    p {
      color: var(--text-secondary);
      font-size: var(--x12);
    }
  }

  .warning-message {
    color: var(--error-500);
  }
}
</style>
