<template>
  <div
    v-if="!disableQuickSelect"
    v-tooltip="tooltip"
    :class="[
      'quick-select-calendar',
      { 'quick-select-calendar-padding-top': !dropdownSelectStyle },
    ]"
  >
    <DatePicker
      v-model="internalModel"
      mode="dateTime"
      :disabled="disabled"
      :min-date="minPublishDate"
      :max-date="maxPublishDate"
      :masks="{
        input: ['h:mm A, MMM D, YYYY'],
      }"
      is-required
      keep-visible-on-input
      :attributes="outsideRangeAttribute"
      @show="onCalendarShow"
      @hide="onCalendarHide"
      @page="onCalendarPage"
    >
      <template #default="{ inputValue, togglePopover, open }">
        <template v-if="dropdownSelectStyle">
          <SelectionDisplayInput
            :open="!disabled ? open : false"
            :disabled="disabled"
            @click="!disabled ? togglePopover() : null"
          >
            {{ getQuickSelectBoxLabel(inputValue) }}
          </SelectionDisplayInput>
        </template>
        <template v-else>
          <a
            ref="quickSelectButton"
            data-cy="QuickSelectLink"
            @click="quickSelectClicked(togglePopover)"
          >
            Quick Select
          </a>
        </template>
      </template>
      <template #header-prev-button>
        <Icon v-tooltip="showPrevTooltip ? popoverLabel : null" dir="down" name="caret" xsmall />
      </template>
      <template #header-next-button>
        <Icon v-tooltip="showNextTooltip ? popoverLabel : null" dir="up" name="caret" xsmall />
      </template>
    </DatePicker>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { postStatus, mixpanelActions } from '@/app/scheduler/constants';
import DatePicker from '@/components/foundation/pickers/DatePicker.vue';
import {
  FAR_FUTURE,
  ALL_TIME,
  getFutureAsInterval,
  intersectIntervals,
  isValidDate,
  isValidInterval,
} from '@/utils/dateUtils';
import { useSchedulerStore } from '@/stores/scheduler';
import Icon from '@/components/foundation/Icon.vue';
import SelectionDisplayInput from '@/components/foundation/SelectionDisplayInput.vue';
import { formatDateRangeLabel } from '@/utils/formatters';

const comp = defineComponent({
  name: 'QuickSelectCalendar',
  components: {
    DatePicker,
    Icon,
    SelectionDisplayInput,
  },
  props: {
    modelValue: { type: [String, Object, Date], default: null },
    dropdownSelectStyle: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    tooltip: { type: String, default: '' },
    restrictSelectionToInterval: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
    platform: { type: String, default: '' },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalModel: null,
      page: null,
    };
  },
  computed: {
    ...mapStores(useSchedulerStore, useTrackingStore),
    disableQuickSelect() {
      return (
        this.schedulerStore.popupData &&
        this.schedulerStore.popupData.post &&
        this.schedulerStore.popupData.post.status === postStatus.IMPORTED
      );
    },
    allowableInterval() {
      return intersectIntervals(getFutureAsInterval(), this.restrictSelectionToInterval);
    },
    hasAllowableEndDate() {
      return this.allowableInterval.end !== FAR_FUTURE;
    },
    minPublishDate() {
      return this.allowableInterval.start;
    },
    maxPublishDate() {
      return this.hasAllowableEndDate
        ? dayjs(this.allowableInterval.end).subtract(1, 'minute').toDate()
        : null;
    },
    publishingDatesDescription() {
      // We're using the restrictSelectionInterval to match with the exact publishing dates, as allowInterval
      // starts with the current date.
      return formatDateRangeLabel(
        this.restrictSelectionToInterval.start,
        this.restrictSelectionToInterval.end,
        {
          forceYearDisplay: false,
          withTime: true,
        },
      );
    },
    popoverLabel() {
      return `This asset can only be published between ${this.publishingDatesDescription}`;
    },
    outsideRangeAttribute() {
      if (!this.hasAllowableEndDate) {
        return null;
      }

      return [
        {
          key: 'outsideRange',
          popover: {
            label: this.popoverLabel,
          },
          dates: [
            {
              start: new Date(),
              end: dayjs(this.allowableInterval.start).subtract(1, 'date').toDate(),
            },
            { start: this.allowableInterval.end, end: null },
          ],
        },
      ];
    },
    showNextTooltip() {
      const endOfMonth = dayjs(`${this.page.year}-${this.page.month}`).endOf('month');
      const isBeforeEndOfMonth = dayjs(this.maxPublishDate).isSameOrBefore(endOfMonth);
      return this.hasAllowableEndDate && isBeforeEndOfMonth;
    },
    showPrevTooltip() {
      const startOfMonth = dayjs(`${this.page.year}-${this.page.month}`).startOf('month');
      const areIntervalsAfterMonthStart =
        dayjs(this.minPublishDate).isSameOrAfter(startOfMonth) &&
        dayjs(this.restrictSelectionToInterval.start).isSameOrAfter(startOfMonth);
      return this.hasAllowableEndDate && areIntervalsAfterMonthStart;
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (dayjs.isDayjs(this.modelValue) && this.modelValue?.isValid()) {
          this.internalModel = this.modelValue.toDate();
        } else if (isValidDate(this.modelValue)) {
          this.internalModel = this.modelValue;
        } else {
          this.internalModel = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getQuickSelectBoxLabel(inputValue) {
      return (this.modelValue && inputValue) || 'Schedule Date & Time';
    },
    getEarliestDateTime() {
      const now = new Date();
      const timeDiffToNextScale = 10 - (now.getMinutes() % 5);
      return dayjs(dayjs(now).add(timeDiffToNextScale, 'minutes').toDate());
    },
    onCalendarShow() {
      if (!this.internalModel) {
        this.internalModel = this.getEarliestDateTime().toDate();
      }
    },
    onCalendarHide() {
      const now = new Date();
      if (!!this.internalModel && this.internalModel < now) {
        this.$emit('update:modelValue', this.getEarliestDateTime());
      } else {
        let earliestDateTime;

        if (this.internalModel < this.minPublishDate) {
          earliestDateTime = dayjs(this.minPublishDate);
        } else {
          earliestDateTime =
            this.internalModel instanceof Date
              ? dayjs(this.internalModel)
              : this.getEarliestDateTime().toDate();
        }
        this.$emit('update:modelValue', earliestDateTime);
      }
    },
    onCalendarPage(page) {
      this.page = page;
    },
    quickSelectClicked(togglePopover) {
      togglePopover();
      this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
        postPlatform: this.platform,
        quickSelectClicked: true,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.svg-icon {
  cursor: default;
  pointer-events: auto;

  &:active {
    pointer-events: none;
  }
}

.quick-select-calendar {
  width: 100%;
  margin: calc(-1 * var(--space-16)) 0 var(--space-16);
  text-align: left;

  a {
    font-size: var(--x14);
    text-decoration: underline;
    font-weight: var(--font-normal);

    &:hover {
      color: var(--action-500);
    }
  }
}

.quick-select-calendar-padding-top {
  padding-top: var(--space-8);
  margin-bottom: var(--space-8);
}
</style>
