import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { loginPageRedirect } from '@/utils/redirect';

export const useOneTimePasswordStore = defineStore('oneTimePassword', () => {
  const showPopup = ref(false);
  const provisioningUri = ref(null);
  const appBasedOtp = ref(false);
  const otpRequired = ref(false);
  const userEmail = ref(null);
  const userPassword = ref(null);
  const setupRequired = computed(() => !!provisioningUri.value);

  function launchPopup({ email, password }) {
    userEmail.value = email;
    userPassword.value = password;
    showPopup.value = true;
  }

  function closePopup() {
    provisioningUri.value = null;
    appBasedOtp.value = false;
    otpRequired.value = false;
    userEmail.value = null;
    userPassword.value = null;
    showPopup.value = false;
  }

  function onLoginSuccess({ router }) {
    closePopup();
    if (router) {
      loginPageRedirect(router);
    }
  }

  function isOtpError({ error }) {
    const response = error?.response;
    const data = response?.data;
    if (response?.status === 400) {
      if (data?.otp_provisioning_uri) {
        return true;
      }
      if (data?.otp_invalid) {
        return true;
      }
      if (data?.errors?.otp_code) {
        return true;
      }
    } else if (response?.status === 401) {
      if (data?.errors?.otp_code) {
        return true;
      }
      if (data?.otp_invalid) {
        return true;
      }
    }
    return false;
  }

  function handleLoginError({ error, email, password }) {
    if (isOtpError({ error })) {
      const response = error?.response;
      const data = response?.data;

      if (data?.otp_provisioning_uri) {
        provisioningUri.value = data.otp_provisioning_uri;
      }
      if (data?.app_based_otp) {
        appBasedOtp.value = true;
      }
      if (data?.otp_invalid) {
        otpRequired.value = true;
      }

      if (email && password) {
        launchPopup({ email, password });
      }

      return true;
    }
    return false;
  }

  return {
    isOtpError,
    handleLoginError,
    setupRequired,
    provisioningUri,
    appBasedOtp,
    showPopup,
    userEmail,
    userPassword,
    otpRequired,
    closePopup,
    onLoginSuccess,
    launchPopup,
  };
});
