/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'funnel': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M14 0H2C.896 0 0 .898 0 2v4c0 .256.098.512.293.707L5 11.415V19a1.002 1.002 0 001.707.707l4-4A.996.996 0 0011 15v-3.586l4.707-4.707A.996.996 0 0016 6V2c0-1.102-.896-2-2-2zM9.293 10.293A1 1 0 009 11v3.586l-2 2V11a.997.997 0 00-.293-.707L2 5.586V2h12v3.586l-4.707 4.707z" _fill="#686a7a"/>'
  }
})
