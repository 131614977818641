export const BRAND_HEALTH_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 21,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 25,
    meta: { brand_ids: ['brand_ids'], channel: 'INSTAGRAM', metrics: ['AVG_ENGAGEMENT_RATE'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 29,
    meta: { brand_ids: ['brand_ids'], channel: 'INSTAGRAM', metrics: ['SUM_TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 29,
    meta: { brand_ids: ['brand_ids'], channel: 'INSTAGRAM', metrics: ['NUMBER_OF_POSTS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 30,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: ['SUM_TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 20, meta: { name: 'Instagram Health' } },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 0, meta: { name: 'TikTok Health' } },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 5,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metrics: ['AVG_ENGAGEMENT_RATE'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 9,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 9,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metrics: ['NUMBER_OF_POSTS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 34, meta: { name: 'Facebook Health' } },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 35,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 35,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 39,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 39,
    meta: { brand_ids: ['brand_ids'], channel: 'FACEBOOK', metrics: ['AVG_ENGAGEMENT_RATE'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 43,
    meta: { brand_ids: ['brand_ids'], channel: 'FACEBOOK', metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 43,
    meta: { brand_ids: ['brand_ids'], channel: 'FACEBOOK', metrics: ['NUMBER_OF_POSTS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 47,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 51, meta: { name: 'X Health' } },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 52,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 52,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 56,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 56,
    meta: { brand_ids: ['brand_ids'], channel: 'TWITTER', metrics: ['AVG_ENGAGEMENT_RATE'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 60,
    meta: { brand_ids: ['brand_ids'], channel: 'TWITTER', metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 60,
    meta: { brand_ids: ['brand_ids'], channel: 'TWITTER', metrics: ['NUMBER_OF_POSTS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 64,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 68, meta: { name: 'Youtube Health' } },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 69,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 69,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 73,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 73,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 77,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['NUMBER_OF_POSTS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 85, meta: { name: 'Sentiment Trend' } },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 86,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 86,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 90,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
];
