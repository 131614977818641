/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<g fill-rule="nonzero" _fill="#B1C1D2"><path pid="0" d="M10 4.999c-2.757 0-5 2.243-5 5 0 2.758 2.243 5 5 5s5-2.242 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"/><path pid="1" d="M18 2.999h-3.465L13.129.89a1.996 1.996 0 00-1.664-.891h-2.93c-.67 0-1.292.333-1.664.891L5.465 2.999H2c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-11c0-1.103-.897-2-2-2zm-16 13v-11h4.535l2-3h2.93l2 3H18l.001 11H2z"/></g>'
  }
})
