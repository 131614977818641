<script setup>
import Button from '@/components/foundation/Button.vue';
import { computed, ref } from 'vue';
import { COMMON_ICON_PROPS } from './constants';

const props = defineProps({
  displayVertically: {
    type: Boolean,
    default: false,
  },
  /*
    Make the toolbar display vertically or default horizontally
  */
  clickableIcons: {
    type: Array,
    default: () => [],

    /*
      iconColor,
      iconName,
      iconHoverColor,
      iconWidth,
      iconHeight,
      class,
      dismiss,
      noBorder,
      round,
      tooltip,
      clickEvent,
      showBorderOnHover,
      buttonClass,
      disabled
    */
  },
});

const mouseOver = ref(null);

const toolbarMenuClass = computed(() => {
  return { 'flex-col gap-3': props.displayVertically };
});

const icons = computed(() => {
  return props.clickableIcons.map((icon) => {
    return {
      ...COMMON_ICON_PROPS,
      ...icon,
    };
  });
});
</script>

<template>
  <div class="vision-toolbar flex h-full items-center justify-between" :class="toolbarMenuClass">
    <slot name="leftIcons"> </slot>

    <div class="flex items-center justify-end" :class="toolbarMenuClass">
      <div v-for="icon in icons" :key="icon.iconName">
        <Button
          :id="icon.iconName"
          v-tooltip="icon.tooltip"
          :data-cy="icon.dataCy"
          :disabled="icon.disabled"
          :round="icon.round"
          :no-border="icon.noBorder"
          :dismiss="icon.dismiss"
          :class="[
            icon.buttonClass,
            {
              'border-none ': icon.showBorderOnHover,
            },
            {
              'hover:bg-white hover:bg-opacity-30': !icon.disabled,
            },
          ]"
          class="flex h-8 w-8 items-center justify-center hover:rounded-[100%]"
          :style="icon.buttonStyle"
          @click="icon.clickEvent"
          @mouseenter="icon.mouseEnterEvent"
          @mouseleave="icon.mouseLeaveEvent"
        >
          <slot name="clickableIcons" v-bind="icon" :has-button-hover="mouseOver" />
        </Button>
      </div>
    </div>
  </div>
</template>
