import { email, minValue, numeric } from '@vuelidate/validators';
import get from 'lodash/get';
import { debounceInputDelay } from '@/config';
import { getEnv } from '@/env';

export const minValueIfNumeric = (getMin) => (value) =>
  !numeric.$validator(value) || minValue(getMin()).$validator(value);

export const mapVuelidateErrorMessage = (validation, messageMap, { force = false }) => {
  if (!force && !validation.$error) {
    return null;
  }
  const firstFailedValidatorName = Object.keys(messageMap).find(
    (validatorName) => validation[validatorName]?.$invalid,
  );
  if (firstFailedValidatorName) {
    return messageMap[firstFailedValidatorName];
  }
  // eslint-disable-next-line no-console
  console.warn('Unhandled validation error message.');
  return null;
};

export const delayTouchMixin = {
  data() {
    return {
      touchMap: new WeakMap(),
    };
  },
  methods: {
    delayTouch(validation, delay = debounceInputDelay) {
      validation.$reset();
      if (this.touchMap.has(validation)) {
        clearTimeout(this.touchMap.get(validation));
      }
      // Avoid having to wait on tests
      this.touchMap.set(
        validation,
        setTimeout(validation.$touch, getEnv().JEST_WORKER_ID ? 0 : delay),
      );
    },
  },
};

export const caseInsensitiveEmailValidator = (value) => email.$validator(value.toLowerCase());

export function validationGroup(v$, fields) {
  if (!v$) {
    return false;
  }
  return []
    .concat(fields || [])
    .map((field) => get(v$, field))
    .every((fieldValidator) => fieldValidator && !fieldValidator.$invalid);
}
