export const FACEBOOK_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'REACTIONS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'ORGANIC_COMMENTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'SHARES',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'LINK_CLICKS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'FACEBOOK',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['FACEBOOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['FACEBOOK'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['FACEBOOK'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['FACEBOOK'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 32,
    meta: {
      name: 'Post Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 33,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 37,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 41,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
