import { ALL_INDUSTRIES, INDUSTRY_FOLLOWER_BUCKETS } from '@/models/dashboards/benchmark-type.enum';

export const parseIndustryName = (industryName, followerBucket, hasFollowerBucket) => {
  // Parse the industry name for benchmark options from the list of industries
  // Returns in the form on (Beauty: 10K - 200K Followers)
  if (hasFollowerBucket) {
    return `${industryName}: ${followerBucket}`;
  }
  return industryName;
};

export const updateBenchmarksBasedOnSelectedBenchmarks = (selectedBenchmarks, benchmarkOptions) => {
  if (!selectedBenchmarks?.[0]?.industry) {
    return [];
  }

  return selectedBenchmarks.map((selectedBenchmark) => {
    if (selectedBenchmark.industry === ALL_INDUSTRIES.value) {
      return {
        industry_id: null,
        follower_bucket: ALL_INDUSTRIES.follower_bucket,
      };
    }

    const benchmark = benchmarkOptions.find(
      (benchmarkOption) => benchmarkOption.value === selectedBenchmark.industry,
    );
    return {
      industry_id: benchmark?.id,
      follower_bucket: benchmark?.follower_bucket,
    };
  });
};

export const extractBenchmarksObjectFromIndustries = (benchmarks, industries) => {
  return benchmarks?.map((benchmark) => {
    const industryObject = industries?.find(
      (industry) =>
        industry?.id === benchmark?.industry_id &&
        industry?.follower_bucket === benchmark?.follower_bucket,
    );
    const followerBucketObject = INDUSTRY_FOLLOWER_BUCKETS[benchmark?.follower_bucket];

    if (!industryObject?.name) {
      return {
        name: ALL_INDUSTRIES.text,
        follower_bucket: ALL_INDUSTRIES.follower_bucket,
      };
    }

    return {
      name: parseIndustryName(
        industryObject?.name,
        followerBucketObject?.text,
        industryObject.dashboard_follower_buckets,
      ),
      id: benchmark?.industry_id,
      follower_bucket: followerBucketObject?.follower_bucket,
    };
  });
};

export const parseBenchmarkLabel = (benchmarkName, followerBucket) => {
  // Returns in the form on (Beauty Industry: 10K - 200K Followers )
  if (benchmarkName === ALL_INDUSTRIES.value) {
    return ALL_INDUSTRIES.text;
  }

  const BenchmarkIndustryObject = Object.values(INDUSTRY_FOLLOWER_BUCKETS).find(
    (bucket) => bucket.text === followerBucket,
  );

  return `${benchmarkName} ${BenchmarkIndustryObject?.graph_label}`;
};
