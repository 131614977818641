import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useIntercomStore } from '@/stores/intercom';
import { useHubspotStore } from '@/stores/hubspot';

export const useSupportChatStore = defineStore('supportChat', () => {
  const flagStore = useFlagStore();
  const intercomStore = useIntercomStore();
  const hubspotStore = useHubspotStore();

  const hasHubspotLiveChatFlag = computed(() => flagStore.ready && flagStore.flags.hubspotLiveChat);

  function show(...args) {
    if (hasHubspotLiveChatFlag.value && hubspotStore.enabled) {
      // show chat here
      hubspotStore.show(...args);
    } else {
      intercomStore.show(...args);
    }
  }

  return {
    show,
  };
});
