/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'greater': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<path pid="0" d="M.478 12.897a1 1 0 101.044 1.706l10-6.128a1 1 0 00-.016-1.715l-10-5.872A1 1 0 10.494 2.612l8.562 5.029-8.578 5.256z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
