export function createLazyInstance(createFunc, createArgs = []) {
  let internalResults = null;
  return new Proxy(
    {},
    {
      get(target, property) {
        internalResults =
          internalResults ||
          (() => {
            return createFunc(...createArgs);
          })();

        const item = internalResults[property];
        if (typeof item === 'function') {
          return function makeCall(...args) {
            return item.call(internalResults, ...args);
          };
        }
        return item;
      },

      set(target, property, value) {
        internalResults =
          internalResults ||
          (() => {
            return createFunc(...createArgs);
          })();

        internalResults[property] = value;
      },
    },
  );
}
