<template>
  <div
    id="linkViewContainer"
    ref="linkView"
    v-drag-move="dragMoveParams"
    data-cy="link-view-container"
    class="link-view"
    @click="addLink"
    @onChange="updateLinkView"
  >
    <label
      v-for="(link, index) in links"
      :key="index"
      :style="{ left: ratioToOffsetX(link.position.x), top: ratioToOffsetY(link.position.y) }"
      class="number-badge"
      @click.stop=""
    >
      {{ index + 1 }}
    </label>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'LinkView',
  props: {
    presetLinks: { type: Array, default: null },
    updateLinkView: { type: Function, default: () => {} },
    linkLimit: { type: Number, default: null },
  },
  emits: ['onChange'],
  data() {
    return {
      links: [],
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    dragMoveParams() {
      return {
        targetClass: 'number-badge',
        targetRadius: this.tagRadius,
        scrollContainerId: 'popup-page-container',
        scrollOffset: window.scrollY,
        dragCallBack: this.updateLinkPosition,
      };
    },
    linkViewWidth() {
      return this.$refs.linkView.clientWidth;
    },
    linkViewHeight() {
      return this.$refs.linkView.clientHeight;
    },
    tagRadius() {
      return parseFloat(getComputedStyle(document.documentElement).fontSize);
    },
  },
  watch: {
    presetLinks() {
      if (this.presetLinks) {
        this.links = this.presetLinks;
      }
    },
  },
  mounted() {
    if (this.presetLinks) {
      this.links = this.presetLinks;
    }
  },
  methods: {
    handleDragStarted(target) {
      target.style.zIndex = this.zIndex;
      this.zIndex += 1;
    },
    handleDragFinished(target, x, y) {
      const index = parseInt(target.dataset.tagIndex, 10);
      this.updateUserTag({
        index,
        tag: {
          x: this.offsetToRatioX(x, 'x'),
          y: this.offsetToRatioY(y, 'y'),
        },
      });
    },
    ratioToOffsetX(ratio) {
      let offset = parseInt(ratio * this.linkViewWidth - this.tagRadius, 10);
      offset = offset > this.linkViewWidth ? this.linkViewWidth : offset;
      return `${offset}px`;
    },
    ratioToOffsetY(ratio) {
      let offset = parseInt(ratio * this.linkViewHeight - this.tagRadius, 10);
      offset = offset > this.linkViewHeight ? this.linkViewHeight : offset;
      return `${offset}px`;
    },
    offsetToRatioX(offset) {
      return offset / this.linkViewWidth;
    },
    offsetToRatioY(offset) {
      return offset / this.linkViewHeight;
    },
    addLink(e) {
      const newLink = {
        position: { x: this.offsetToRatioX(e.offsetX), y: this.offsetToRatioY(e.offsetY) },
      };
      if (this.linkLimit == null || this.links.length < this.linkLimit) {
        this.links.push(newLink);
        this.scrollToLatestLink();
        this.$emit('onChange', this.links);
      } else {
        const subject = this.linkLimit !== 1 ? 'links' : 'link';
        this.notificationStore.setToast({
          message: `You can only add up to ${this.linkLimit} ${subject}.`,
          type: 'warning',
        });
      }
    },
    updateLinkPosition(e, x, y) {
      const id = e.innerText;
      if (id !== null) {
        const index = id - 1;
        this.links[index].position.x = this.offsetToRatioX(x);
        this.links[index].position.y = this.offsetToRatioY(y);
        this.$emit('onChange', this.links);
      }
    },
    scrollToLatestLink() {
      const container = document.getElementById('linkEditorsContainer');
      if (container) {
        nextTick(() => {
          // wait until the container scrollHeight is updated.
          container.scrollTo(0, container.scrollHeight);
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.link-view {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: var(--round-corner-small);
  cursor: pointer;

  .number-badge {
    position: absolute;
    display: block;
    margin: 0;
    background: var(--background-900);
    cursor: pointer;
    color: var(--white);
    height: var(--space-32);
    width: var(--space-32);
    line-height: 1.75rem;
    border-radius: var(--space-32);
  }
}
</style>
