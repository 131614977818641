import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { formatNumber, formatPercentage } from '@/utils/formatters';
import {
  metricFormats,
  SENTIMENT_LABEL,
  SOCIAL_LISTENING_METRICS,
  SOCIAL_LISTENING_SENTIMENT_METRICS,
} from '../constants';

export function getMetricKeys(metrics) {
  const validMetricKeys = Object.keys(SOCIAL_LISTENING_METRICS);
  return validMetricKeys.filter((metric) => Object.keys(metrics).includes(metric));
}

export function getSentimentKeys(metrics) {
  const validSentimentMetrics = Object.keys(SOCIAL_LISTENING_SENTIMENT_METRICS);
  return validSentimentMetrics.filter((metric) => Object.keys(metrics).includes(metric));
}

export function getMetricFormat(metric) {
  return SOCIAL_LISTENING_METRICS[metric]?.format;
}

export function getIconColor(metric) {
  return SOCIAL_LISTENING_METRICS[metric]?.iconColor;
}

export function getMetricValue(value, format) {
  if (format === metricFormats.PERCENT) {
    return formatPercentage(value);
  }
  if (format === metricFormats.NUMBER) {
    return formatNumber(value);
  }

  return value;
}

export function formatValue(value, format) {
  return isNumber(value) ? getMetricValue(value, format) : value;
}

export function formatlabel(metric) {
  return SOCIAL_LISTENING_METRICS[metric]?.label;
}

export function getMetrics(label, value, format, iconColor) {
  return {
    label,
    value: isNil(value) ? '-' : formatValue(value, format),
    iconColor,
  };
}

export function getVisualTrendMetrics(trend) {
  const visualTrendsMetrics = [];
  const metricKeys = getMetricKeys(trend);

  metricKeys.forEach((metric) => {
    const value = get(trend, metric, null); // Returns value else return null as default
    const label = formatlabel(metric);
    const format = getMetricFormat(metric);
    const iconColor = getIconColor(metric);
    const metrics = getMetrics(label, value, format, iconColor);
    visualTrendsMetrics.push(metrics);
  });

  return visualTrendsMetrics;
}

export function getDefaultMediaCardMetrics(mediaItem) {
  const mediaCardMetrics = [];
  const metricKeys = getMetricKeys(mediaItem);
  const sentimentMetricKeys = getSentimentKeys(mediaItem?.sentiment);
  const sentimentExists =
    mediaItem?.sentiment?.isPositive ||
    mediaItem?.sentiment?.isNeutral ||
    mediaItem?.sentiment?.isNegative;

  metricKeys.forEach((metricKey) => {
    const value = get(mediaItem, metricKey, null);
    const label = formatlabel(metricKey);
    const format = getMetricFormat(metricKey);
    const metrics = getMetrics(label, value, format);
    mediaCardMetrics.push(metrics);
  });

  if (sentimentExists) {
    sentimentMetricKeys.reduce((acc, sentimentKey) => {
      const sentimentMetric = SOCIAL_LISTENING_SENTIMENT_METRICS[sentimentKey];
      if (mediaItem?.sentiment[sentimentKey]) {
        acc.push({
          value: sentimentMetric.value,
          label: sentimentMetric.label,
          iconColor: sentimentMetric.iconColor,
        });
      }
      return acc;
    }, mediaCardMetrics);
  } else {
    // return sentiment label with hypen
    mediaCardMetrics.push(getMetrics(SENTIMENT_LABEL));
  }

  return mediaCardMetrics;
}
