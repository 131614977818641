import { colours } from '@/ux/colours';

export function todayAttribute() {
  return {
    key: 'today',
    highlight: {
      fillMode: null,
      contentStyle: {
        color: colours.ACTION.ACTION_500,
        fontWeight: 'bold',
      },
    },
    dates: new Date(),
    order: 0,
  };
}

export function dragAttribute({
  mainColor = colours.ACTION.ACTION_500,
  lightColor = 'rgba(72,143,225,0.15)',
} = {}) {
  return {
    highlight: {
      start: {
        fillMode: 'outline',
        style: {
          borderRadius: '0.375rem 0 0 0.375rem',
          borderColor: mainColor,
        },
        contentStyle: {
          fontWeight: 'normal',
        },
      },
      base: {
        fillMode: 'light',
        style: {
          backgroundColor: lightColor,
        },
        contentStyle: {
          fontWeight: 'normal',
        },
      },
      end: {
        fillMode: 'outline',
        style: {
          borderRadius: '0 0.375rem 0.375rem 0',
          borderColor: mainColor,
        },
        contentStyle: {
          fontWeight: 'normal',
        },
      },
    },
    order: 20,
  };
}

export function rangeAttribute({
  mainColor = colours.ACTION.ACTION_500,
  lightColor = 'rgba(72,143,225,0.15)',
  isSameDay = false,
} = {}) {
  const borderRadiusStart = isSameDay ? '0.375rem' : '0.375rem 0 0 0.375rem';
  const borderRadiusEnd = isSameDay ? '0.375rem' : '0 0.375rem 0.375rem 0';
  return {
    highlight: {
      start: {
        fillMode: 'solid',
        style: {
          borderRadius: borderRadiusStart,
          backgroundColor: mainColor,
        },
        contentStyle: {
          fontWeight: 'bold',
        },
      },
      base: {
        fillMode: 'light',
        style: {
          backgroundColor: lightColor,
        },
        contentStyle: {
          fontWeight: 'normal',
        },
      },
      end: {
        fillMode: 'solid',
        style: {
          borderRadius: borderRadiusEnd,
          backgroundColor: mainColor,
        },
        contentStyle: {
          fontWeight: 'bold',
        },
      },
    },
    order: 10,
  };
}
