/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cube': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M6.586 0L0 6.586V19h12.414L19 12.414V0H6.586zm.828 2h8.172l-4 4H3.414l4-4zM2 8h9v9H2V8zm11 7.586V7.414l4-4v8.172l-4 4z" _fill="#adadad" fill-rule="nonzero"/>'
  }
})
