import { BRAND, USER } from '@/models/auth/permissions.enum';
import { getEnumValue } from '@/models/enum.utils';

export const METRIC_TYPE_REPORT_KEYS = {
  CONTENT_REPORT: 'content_report',
  GRAPH_REPORT: 'graph_report',
  METRICS_REPORT: 'metrics_report',
};

export const REPORTS = Object.freeze({
  SINGLE_METRIC: {
    value: 'SINGLE_METRIC',
    width: 1,
    height: 2,
    components: {
      report: {
        name: 'GroupedMetricReportPanel',
      },
      editForm: {
        name: 'EditTotalMetricReportForm',
      },
      preview: {
        name: 'GroupedMetricReportPanel',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  TOTAL_METRIC: {
    value: 'TOTAL_METRIC',
    width: 1,
    height: 2,
    components: {
      report: {
        name: 'GroupedMetricReportPanel',
      },
      editForm: {
        name: 'EditTotalMetricReportForm',
      },
      preview: {
        name: 'GroupedMetricReportPanel',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  ADS_TOTAL_METRIC: {
    value: 'ADS_TOTAL_METRIC',
    width: 1,
    height: 2,
    components: {
      report: {
        name: 'GroupedMetricReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GroupedMetricReportPanel',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  META_ADS_TOTAL_METRIC: {
    value: 'META_ADS_TOTAL_METRIC',
    width: 1,
    height: 2,
    components: {
      report: {
        name: 'GroupedMetricReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GroupedMetricReportPanel',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  COMPETITIVE_TOTAL_METRIC: {
    value: 'COMPETITIVE_TOTAL_METRIC',
  },
  MULTI_BRAND_METRIC: {
    value: 'MULTI_BRAND_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditMultiBrandMetricReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'GroupedMetricReportPanel',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  TOTAL_GROUPED_METRIC: {
    value: 'TOTAL_GROUPED_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditTotalGroupedMetricReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  ADS_TOTAL_GROUPED_METRIC: {
    value: 'ADS_TOTAL_GROUPED_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  META_ADS_TOTAL_GROUPED_METRIC: {
    value: 'META_ADS_TOTAL_GROUPED_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  MULTI_METRIC_MEDIA_TYPE: {
    value: 'MULTI_METRIC_MEDIA_TYPE',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditMultiMetricMediaTypeReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  COMPETITIVE_MULTI_BRAND_METRIC: {
    value: 'COMPETITIVE_MULTI_BRAND_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditCompetitiveMetricReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  // TODO Remove CONTENT type once we run migration to set report type to OWNED or UGC
  //  (See src/app/dashboards/store/actions.js, and tests/unit/factories/dashboards/report.js also)
  CONTENT: {
    value: 'CONTENT',
    width: 6,
    height: 4,
    components: {
      report: {
        name: 'ContentReportPanel',
      },
      editForm: {
        name: 'EditContentReportForm',
      },
      preview: {
        name: 'ContentReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
  },
  CONTENT_OWNED: {
    value: 'CONTENT_OWNED',
    width: 6,
    height: 4,
    components: {
      report: {
        name: 'ContentReportPanel',
      },
      editForm: {
        name: 'EditContentReportForm',
      },
      preview: {
        name: 'ContentReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
  },
  CONTENT_OWNED_STORIES: {
    value: 'CONTENT_OWNED_STORIES',
    width: 6,
    height: 4,
    components: {
      report: {
        name: 'ContentReportPanel',
      },
      editForm: {
        name: 'EditContentReportForm',
      },
      preview: {
        name: 'ContentReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
  },
  CONTENT_UGC: {
    value: 'CONTENT_UGC',
    width: 6,
    height: 4,
    components: {
      report: {
        name: 'ContentReportPanel',
      },
      editForm: {
        name: 'EditContentReportForm',
      },
      preview: {
        name: 'ContentReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
  },
  COMPETITIVE_CONTENT: {
    value: 'COMPETITIVE_CONTENT',
    width: 6,
    height: 4,
    components: {
      report: {
        name: 'CompetitiveContentReportPanel',
      },
      editForm: {
        name: 'EditCompetitiveContentReportForm',
      },
      preview: {
        name: 'ContentReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
  },
  GRAPH: {
    value: 'GRAPH',
    text: 'Line Graph',
    icon: 'graph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditGraphReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  COMPETITIVE_GRAPH: {
    value: 'COMPETITIVE_GRAPH',
    text: 'Line Graph',
    icon: 'graph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditCompetitiveGraphReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  ADS_GRAPH: {
    value: 'ADS_GRAPH',
    text: 'Line Graph',
    icon: 'graph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  META_ADS_GRAPH: {
    value: 'META_ADS_GRAPH',
    text: 'Line Graph',
    icon: 'graph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  BENCHMARK_METRIC: {
    value: 'BENCHMARK_METRIC',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'MetricReportTablePanel',
      },
      editForm: {
        name: 'EditBenchmarkReportForm',
      },
      preview: {
        width: 1,
        height: 3,
        name: 'MultiBrandMetricReportPanelPreview',
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
  },
  BENCHMARK_GRAPH: {
    value: 'BENCHMARK_GRAPH',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditBenchmarkReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  BAR_METRIC: {
    value: 'BAR_METRIC',
    text: 'Single Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditBarMetricReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  COMPETITIVE_BAR_METRIC: {
    value: 'COMPETITIVE_BAR_METRIC',
    text: 'Single Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditCompetitiveBarMetricReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  ADS_BAR_METRIC: {
    value: 'ADS_BAR_METRIC',
    text: 'Single Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  META_ADS_BAR_METRIC: {
    value: 'META_ADS_BAR_METRIC',
    text: 'Single Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  STACKED_BAR_METRIC: {
    value: 'STACKED_BAR_METRIC',
    text: 'Stacked Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditStackedBarMetricReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  STACKED_BAR_METRIC_TIME_SERIES: {
    value: 'STACKED_BAR_METRIC_TIME_SERIES',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditStackedBarMetricReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  ADS_STACKED_BAR_METRIC: {
    value: 'ADS_STACKED_BAR_METRIC',
    text: 'Stacked Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  META_ADS_STACKED_BAR_METRIC: {
    value: 'META_ADS_STACKED_BAR_METRIC',
    text: 'Stacked Bar Chart',
    icon: 'stackedGraph',
    width: 3,
    height: 4,
    components: {
      report: {
        name: 'GraphReportPanel',
      },
      editForm: {
        name: 'EditAdsReportForm',
      },
      preview: {
        name: 'GraphReportPanelPreview',
        height: 3.4,
      },
    },
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
  },
  HEADER: {
    value: 'HEADER',
    width: 6,
    height: 1,
    widget: true,
    components: {
      report: {
        name: 'HeaderReportPanel',
      },
      widgetForm: {
        name: 'HeaderReportForm',
      },
    },
  },
  DASHBOARD_SUMMARY: {
    value: 'DASHBOARD_SUMMARY',
    width: 1,
    height: 2,
    components: {
      report: {
        name: 'VisionAiReportPanelPlaceholder',
      },
    },
  },
});

export const ADD_REPORTS = Object.freeze({
  METRIC: {
    value: 'METRIC',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
    components: {
      addForm: {
        name: 'AddMetricReportForm',
      },
      addFormV2: {
        // TODO: remove feature flag and test form
        name: 'AddMetricReportFormV2',
      },
    },
    requireFlag: true,
  },
  COMPETITIVE_METRIC: {
    value: 'COMPETITIVE_METRIC',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
    components: {
      addForm: {
        name: 'AddCompetitiveMetricReportForm',
      },
      addFormV2: {
        // TODO: remove feature flag and test form: https://app.shortcut.com/dashhudson/story/111781/remove-feature-flag-dashboard-report-form-refactor-from-code
        name: 'AddCompetitiveMetricReportFormV2',
      },
    },
    requireFlag: true,
  },
  ADS_METRIC: {
    value: 'ADS_METRIC',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.METRICS_REPORT,
    components: {
      addForm: {
        name: 'AddAdsMetricReportForm',
      },
    },
  },
  CONTENT: {
    value: 'CONTENT',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
    components: {
      addForm: {
        name: 'AddContentReportForm',
      },
      addFormV2: {
        // TODO: remove feature flag and test form: https://app.shortcut.com/dashhudson/story/111781/remove-feature-flag-dashboard-report-form-refactor-from-code
        name: 'AddContentReportFormV2',
      },
    },
    requireFlag: true,
  },
  COMPETITIVE_CONTENT: {
    value: 'COMPETITIVE_CONTENT',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.CONTENT_REPORT,
    components: {
      addForm: {
        name: 'AddCompetitiveContentReportForm',
      },
    },
  },
  GRAPH: {
    value: 'GRAPH',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
    components: {
      addForm: {
        name: 'AddGraphReportForm',
      },
      addFormV2: {
        // TODO: remove feature flag and test form #111781
        name: 'AddGraphReportFormV2',
      },
    },
    requireFlag: true,
  },
  COMPETITIVE_GRAPH: {
    value: 'COMPETITIVE_GRAPH',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
    components: {
      addForm: {
        name: 'AddCompetitiveGraphReportForm',
      },
      addFormV2: {
        // TODO: remove feature flag and test form #111781
        name: 'AddCompetitiveGraphReportFormV2',
      },
    },
    requireFlag: true,
  },
  ADS_GRAPH: {
    value: 'ADS_GRAPH',
    metricTypeReportKey: METRIC_TYPE_REPORT_KEYS.GRAPH_REPORT,
    components: {
      addForm: {
        name: 'AddAdsGraphReportForm',
      },
    },
  },
  INDUSTRY: {
    value: 'INDUSTRY',
    components: {
      addForm: {
        name: 'AddBenchmarkReportForm',
      },
    },
  },
});

export const REPORT_CATEGORY_OPTIONS = Object.freeze({
  ALL: {
    value: 'ALL',
    text: 'All Reports',
  },
  OWNED: {
    value: 'OWNED',
    text: 'Owned Reports',
  },
  COMPETITIVE: {
    value: 'COMPETITIVE',
    text: 'Competitive Reports',
    permissions: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
    userPermissions: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
    requiredFlagForCheckingPermissions: 'hideCompetitiveReports',
  },
  INDUSTRY: {
    value: 'INDUSTRY',
    text: 'Benchmark Reports',
    permissions: [BRAND.DASHBOARD.CAN_ACCESS_INDUSTRY_BENCHMARKS],
  },
  SOCIAL_ADVERTISING: {
    value: 'SOCIAL_ADVERTISING',
    text: 'Social Advertising',
    permissions: [BRAND.ADS.CAN_ACCESS_ADS],
    userPermissions: [USER.ADS.CAN_ACCESS_ADS],
    requiredFlag: 'metaAdsDashboards',
  },
});

export const REPORT_FINISH_BUTTON_TEXT = {
  ADD_REPORT: 'Add Report',
  Next: 'Next',
};

export const REPORT_FORM_EMIT = {
  INPUT: 'input',
  VALIDATION: 'validation',
};

export const SINGLE_BRAND_REPORT_TYPES = Object.freeze([
  REPORTS.CONTENT_OWNED.value,
  REPORTS.CONTENT_OWNED_STORIES.value,
  REPORTS.CONTENT_UGC.value,
  REPORTS.CONTENT.value,
]);

export const MULTI_CHANNEL_REPORT_TYPES = Object.freeze([
  REPORTS.GRAPH.value,
  REPORTS.TOTAL_METRIC.value,
  REPORTS.TOTAL_GROUPED_METRIC.value,
]);

export const SINGLE_COMPETITOR_REPORT_TYPES = Object.freeze([REPORTS.COMPETITIVE_CONTENT.value]);

export function isWidgetReport(reportType) {
  const key = getEnumValue(reportType);
  return REPORTS?.[key]?.widget;
}

export const getReportConfigProp = (report, property) => {
  const reportKey = getEnumValue(report);
  const reportConfig = REPORTS[reportKey];
  return reportConfig?.[property];
};

export const OWNED_CONTENT_REPORT_TYPES = [
  REPORTS.CONTENT_OWNED.value,
  REPORTS.CONTENT_OWNED_STORIES.value,
  REPORTS.CONTENT_UGC.value,
  REPORTS.CONTENT.value,
];

export const COMPETITIVE_CONTENT_REPORT_TYPES = [REPORTS.COMPETITIVE_CONTENT.value];

export const CONTENT_REPORT_TYPES = [
  ...OWNED_CONTENT_REPORT_TYPES,
  ...COMPETITIVE_CONTENT_REPORT_TYPES,
];

export function isContentReport(reportType) {
  return CONTENT_REPORT_TYPES.includes(reportType);
}

export function isMultiMediaMetricReport(reportType) {
  return reportType === REPORTS.MULTI_METRIC_MEDIA_TYPE.value;
}

export const COMPETITIVE_REPORTS = [
  REPORTS.COMPETITIVE_CONTENT.value,
  REPORTS.COMPETITIVE_GRAPH.value,
  REPORTS.COMPETITIVE_MULTI_BRAND_METRIC.value,
  REPORTS.DASHBOARD_SUMMARY.value,
];

export const METRIC_TABLE_REPORTS = [
  REPORTS.MULTI_BRAND_METRIC.value,
  REPORTS.COMPETITIVE_MULTI_BRAND_METRIC.value,
  REPORTS.BENCHMARK_METRIC.value,
  REPORTS.TOTAL_GROUPED_METRIC.value,
  REPORTS.ADS_TOTAL_GROUPED_METRIC.value,
  REPORTS.META_ADS_TOTAL_GROUPED_METRIC.value,
];

export const GRAPH_REPORTS = [
  REPORTS.GRAPH.value,
  REPORTS.COMPETITIVE_GRAPH.value,
  REPORTS.BENCHMARK_GRAPH.value,
  REPORTS.ADS_GRAPH.value,
  REPORTS.META_ADS_GRAPH.value,
];

export const LINE_GRAPH_REPORT_TYPES = Object.freeze({
  GRAPH: 'GRAPH',
  GRAPH_MULTI_BRAND: 'GRAPH_MULTI_BRAND',
  GRAPH_MULTI_BRAND_SINGLE_MEDIA_TYPE: 'GRAPH_MULTI_BRAND_SINGLE_MEDIA_TYPE',
  GRAPH_SINGLE_BRAND_SINGLE_MEDIA_TYPE: 'GRAPH_SINGLE_BRAND_SINGLE_MEDIA_TYPE',
  GRAPH_SINGLE_BRAND_MULTI_MEDIA_TYPE: 'GRAPH_SINGLE_BRAND_MULTI_MEDIA_TYPE',
  BENCHMARK_GRAPH: 'BENCHMARK_GRAPH',
});

export const COMPETITIVE_GRAPH_CHART_TYPES = Object.freeze({
  LINE_CHART: REPORTS.COMPETITIVE_GRAPH,
  SINGLE_COLUMN_GRAPH: REPORTS.COMPETITIVE_BAR_METRIC,
});

export const GRAPH_CHART_TYPES = Object.freeze({
  LINE_CHART: REPORTS.GRAPH,
  SINGLE_COLUMN_GRAPH: REPORTS.BAR_METRIC,
  STACKED_COLUMN_GRAPH: REPORTS.STACKED_BAR_METRIC,
});

export const SINGLE_METRIC_REPORT_TYPE = Object.freeze({
  SINGLE_METRIC: 'SINGLE_METRIC',
  TOTAL_METRIC: 'TOTAL_METRIC',
  SINGLE_METRIC_MEDIA_TYPE: 'SINGLE_METRIC_MEDIA_TYPE',
});

export const BENCHMARK_REPORTS = [REPORTS.BENCHMARK_METRIC.value, REPORTS.BENCHMARK_GRAPH.value];

export const BAR_CHART_REPORTS = [
  REPORTS.BAR_METRIC.value,
  REPORTS.COMPETITIVE_BAR_METRIC.value,
  REPORTS.ADS_BAR_METRIC.value,
  REPORTS.ADS_STACKED_BAR_METRIC.value,
  REPORTS.META_ADS_BAR_METRIC.value,
  REPORTS.META_ADS_STACKED_BAR_METRIC.value,
  REPORTS.STACKED_BAR_METRIC.value,
  REPORTS.STACKED_BAR_METRIC_TIME_SERIES.value,
];

export const LINE_CHART_REPORTS = [
  REPORTS.GRAPH.value,
  LINE_GRAPH_REPORT_TYPES.GRAPH_MULTI_BRAND.value,
  LINE_GRAPH_REPORT_TYPES.GRAPH_MULTI_BRAND_SINGLE_MEDIA_TYPE.value,
  LINE_GRAPH_REPORT_TYPES.GRAPH_SINGLE_BRAND_SINGLE_MEDIA_TYPE.value,
  LINE_GRAPH_REPORT_TYPES.GRAPH_SINGLE_BRAND_MULTI_MEDIA_TYPE.value,
  REPORTS.BENCHMARK_GRAPH.value,
  REPORTS.ADS_GRAPH.value,
  REPORTS.META_ADS_GRAPH.value,
];
export const TOTAL_REPORT_TYPES = [REPORTS.TOTAL_METRIC.value, REPORTS.TOTAL_GROUPED_METRIC.value];

export const CHANNEL_TO_CHANNELS_CONVERSION_REPORT_TYPES = [
  REPORTS.SINGLE_METRIC.value,
  REPORTS.MULTI_BRAND_METRIC.value,
  REPORTS.MULTI_METRIC_MEDIA_TYPE.value,
];

export const AGGREGATED_REPORTS_TYPES = [
  REPORTS.TOTAL_GROUPED_METRIC.value,
  REPORTS.TOTAL_METRIC.value,
  REPORTS.BAR_METRIC.value,
];

export const META_ADS_REPORT_TYPES = [
  REPORTS.META_ADS_TOTAL_METRIC.value,
  REPORTS.META_ADS_TOTAL_GROUPED_METRIC.value,
  REPORTS.META_ADS_GRAPH.value,
  REPORTS.META_ADS_BAR_METRIC.value,
  REPORTS.META_ADS_STACKED_BAR_METRIC.value,
];

export const ADS_REPORT_TYPES = [
  ...META_ADS_REPORT_TYPES,
  REPORTS.ADS_TOTAL_METRIC.value,
  REPORTS.ADS_TOTAL_GROUPED_METRIC.value,
  REPORTS.ADS_GRAPH.value,
  REPORTS.ADS_BAR_METRIC.value,
  REPORTS.ADS_STACKED_BAR_METRIC.value,
];

export const REPORT_LABEL_TYPES = {
  BRAND: 'brand',
  COMPETITOR: 'competitor',
};

export const REPORT_TAG_TYPES = {
  BRAND: 'brand',
  COMPETITOR: 'competitor',
  CONTENT: 'content',
};

export const REPORT_TITLES = {
  METRICS_REPORT: 'Metric Report',
  CONTENT_REPORT: 'Content Report',
  GRAPH_REPORT: 'Graph Report',
  COMPETITIVE_GRAPH_REPORT: 'Competitive Graph Report',
  COMPETITIVE_METRIC_REPORT: 'Competitive Metric Report',
  COMPETITIVE_CONTENT_REPORT: 'Competitive Content Report',
};

export const REPORT_DESCRIPTIONS = {
  ADS_ACCOUNT_CONNECTION: 'No ad account connection',
  AGGREGATE_BY: 'Select report breakdown',
  BRANDS: 'Select brands to report on',
  CAMPAIGNS: 'Select your ad accounts and campaigns',
  COMPETITORS: 'Select competitors to report on',
  CHANNEL: 'Select social media channel',
  CHANNELS: 'Select social media channels',
  CONTENT_TAGS: 'Do you want to report on all content or tagged content?',
  CONTENT_TYPE: 'Select either Owned or User Generated content',
  INDUSTRY: 'Select which industry and follower size to report on',
  METRIC: 'Select a metric to report on',
  MEDIA_TYPE: 'Select which media type to report on',
  MEDIA_TYPES: 'Select which media types to report on',
  VISUALIZATION: 'Select report visualization',
  SORT_ORDER: 'Select a sort order',

  [REPORT_TITLES.GRAPH_REPORT]: {
    REPORT_VISUALIZATION_HEADER: 'Select report visualization',
    REPORT_AGGREGATION_HEADER: 'Select report breakdown',
  },
  [REPORT_TITLES.METRICS_REPORT]: {
    REPORT_VISUALIZATION_HEADER: 'Select report visualization',
    REPORT_AGGREGATION_HEADER: 'Select report breakdown',
  },
  [REPORT_TITLES.CONTENT_REPORT]: {
    REPORT_VISUALIZATION_HEADER: 'Select report visualization',
    REPORT_AGGREGATION_HEADER: 'Select a sort order',
  },
};

export const EXCLUDE_REPORT_TYPES_FROM_EXPANSION = [
  REPORTS.SINGLE_METRIC.value,
  REPORTS.TOTAL_METRIC.value,
  REPORTS.DASHBOARD_SUMMARY.value,
];
