<template>
  <div class="media-actions">
    <Button
      v-tooltip="includeTooltip"
      icon-name="viewAdd"
      icon-size="small"
      class="include-media"
      round-small
      @click.stop="handleClickedMedia(true)"
    />
    <Button
      v-tooltip="excludeTooltip"
      icon-name="viewSubtract"
      icon-size="small"
      class="exclude-media"
      :icon-hover-color="excludeHover"
      round-small
      @click.stop="handleClickedMedia(false)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import humps from 'humps';
import Button from '@/components/foundation/Button.vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import {
  FILTER_TYPE,
  SOCIAL_LISTENING_VISUAL_FILTER_TYPES,
  VISUAL_DATA_TYPES,
} from '@/app/socialListening/constants';
import { socialListeningFiltersEvent } from '@/app/socialListening/utils/mixpanel-events';
import { useNotificationStore } from '@/stores/notification';
import { ERROR } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'VisualFilterMediaTile',
  components: { Button },
  props: {
    mediaItem: { type: Object, default: null },
  },
  emits: ['media-clicked'],
  data() {
    return {
      includeTooltip: 'Include similar media',
      excludeTooltip: 'Exclude similar media',
      excludeHover: ERROR.ERROR_500,
    };
  },
  computed: {
    ...mapStores(useSocialListeningStore, useNotificationStore),
  },
  methods: {
    handleClickedMedia(include) {
      const media = humps.camelizeKeys(this.mediaItem);
      const brandId = media.brandType?.split(':')[0] || null;
      const item = {
        ...media,
        filterType: brandId ? VISUAL_DATA_TYPES.LIBRARY : VISUAL_DATA_TYPES.TRENDS,
        id: `${brandId ? `${brandId}:` : ''}${media.source}:${media.sourceId}`,
      };
      try {
        this.socialListeningStore.updateVisualFilters(include, item);
        this.$emit('media-clicked');

        socialListeningFiltersEvent({
          filterUsed: FILTER_TYPE.VISUAL.value,
          filterValue: {
            value: `${include ? 'includes' : 'excludes'}:${media.sourceId}`,
            selection: brandId
              ? SOCIAL_LISTENING_VISUAL_FILTER_TYPES.LIBRARY
              : SOCIAL_LISTENING_VISUAL_FILTER_TYPES.TRENDS,
          },
          section: this.$route?.meta?.section,
          eventFrom: this.$route?.name,
        });
      } catch (err) {
        this.notificationStore.setToast({
          type: 'error',
          message: err?.message || 'Something went wrong',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-actions {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .include-media {
    margin-right: var(--space-8);
  }
}
</style>
