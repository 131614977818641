/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'boards': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M2.222 13.778h15.556V2.222H8V0h9.778C19.003 0 20 .998 20 2.222v15.556A2.224 2.224 0 0117.778 20H2.222A2.224 2.224 0 010 17.778v-11h2.222v7zm15.556 2.035H2.222v1.965h15.556v-1.965zM9.288 4.006h1.999v6.657H4.63v-2h3.243l-6.95-6.95L2.337.3l6.95 6.95V4.006z" fill-rule="nonzero" _fill="#4D5F71"/>'
  }
})
