import maxBy from 'lodash/maxBy';
import { MEDIA_TYPES } from '@/models/dashboards/media-types.enum';
import { METRICS } from '@/models/dashboards/metrics';
import { getMetricDetails } from '@/utils/metrics';
import { useMetricsStore } from '@/stores/metrics';

function replaceLast(str, replaced, replacement) {
  const lastIndex = str.lastIndexOf(replaced);
  return `${str.substring(0, lastIndex)}${replacement}${str.substring(
    lastIndex + replaced.length,
  )}`;
}

export const removeMediaTypeFromMetric = (metricName) => {
  if (!metricName) return metricName;
  const mediaDescriptions = Object.values(MEDIA_TYPES).filter(
    (mediaDesc) => mediaDesc.subString && metricName.endsWith(mediaDesc.subString),
  );
  if (!mediaDescriptions.length) return metricName;
  const mediaDescription = maxBy(mediaDescriptions, (mediaDesc) => mediaDesc.subString.length);
  return replaceLast(metricName, mediaDescription.subString, '');
};

export function getMediaTypeDetailsFromMetricKeys(
  metricKeys,
  channels,
  metricTypeReportKey,
  filterStrictLockedMetric = false,
) {
  /**
   *
   * @param  {boolean} filterStrictLockedMetric  if true, remove 'All Media' option if it's locked
   * to a specific media type. E.g. Instagram Shares is locked to Reels.
   *
   */
  let lockedMetric = false;
  const mediaTypeMetrics = metricKeys.reduce((acc, metricKey) => {
    const metricDetails = getMetricDetails(metricKey, channels, metricTypeReportKey);
    if (metricDetails.lockedMediaBreakdown) lockedMetric = true;
    const mediaTypeDetails = Object.values(MEDIA_TYPES).filter((mediaTypeInfo) => {
      return mediaTypeInfo.text === metricDetails.mediaTypeLabel;
    })[0];
    acc[mediaTypeDetails.value] = {
      metric: metricKey,
      ...mediaTypeDetails,
    };
    return acc;
  }, {});

  if (lockedMetric && filterStrictLockedMetric && Object.keys(mediaTypeMetrics).length > 1) {
    delete mediaTypeMetrics[MEDIA_TYPES.ALL_MEDIA.value];
  }

  return mediaTypeMetrics;
}

export function getMediaTypeOptionsObjectFromMetricDetails(
  selectedMetricDetails,
  channel,
  metricTypeReportKey,
  filterStrictLockedMetric = false,
) {
  const metricsStore = useMetricsStore();
  if (!selectedMetricDetails.supportsMediaBreakdown) return {};
  const parentMetric = selectedMetricDetails.parentMetric;
  const childMetricKeys = metricsStore.getMetricKeysByParent(
    channel,
    metricTypeReportKey,
    parentMetric,
  );
  return getMediaTypeDetailsFromMetricKeys(
    childMetricKeys,
    [channel],
    metricTypeReportKey,
    filterStrictLockedMetric,
  );
}

export function resetOrLockMediaTypeFromMetricDetails(metricDetails, mediaTypeOptions) {
  if (metricDetails?.lockedMediaBreakdown) {
    if (mediaTypeOptions.length >= 1) {
      return mediaTypeOptions.reduce((acc, mediaTypeInfo) => {
        if (mediaTypeInfo.value !== MEDIA_TYPES.ALL_MEDIA.value) {
          acc.push(mediaTypeInfo.value);
        }
        return acc;
      }, []);
    }
  }
  return [];
}

export const getMetricMediaType = (channel, metric, reportType = null) => {
  const metricMediaSubtypes =
    METRICS[channel]?.[removeMediaTypeFromMetric(metric)]?.mediaSubtypes ?? {};

  /**
   * TODO: remove this legacy check once all of the frontend configs are removed
   * https://app.shortcut.com/dashhudson/story/104187/remove-all-code-using-frontend-configs
   */
  const legacyMetricConfigMediaType = Object.values(metricMediaSubtypes).find(
    (mediaSubType) => mediaSubType.metric === metric,
  );
  if (legacyMetricConfigMediaType) return legacyMetricConfigMediaType;

  const metricDetails = getMetricDetails(metric, [channel], reportType);
  const mediaTypeMetrics = getMediaTypeOptionsObjectFromMetricDetails(
    metricDetails,
    channel,
    reportType,
    true,
  );
  return Object.values(mediaTypeMetrics).find((mediaSubType) => mediaSubType.metric === metric);
};

export const getMediaTypesBasedOnMetrics = (selectedMetricObject, mediaTypes) => {
  if (!selectedMetricObject?.mediaSubtypes) {
    return [];
  }

  if (selectedMetricObject?.mediaSubtypeLocked || !mediaTypes) {
    return [Object.keys(selectedMetricObject.mediaSubtypes)[0]];
  }

  return mediaTypes.filter((mediaType) =>
    Object.keys(selectedMetricObject.mediaSubtypes).includes(mediaType),
  );
};

export const getMetricsBasedOnMediaTypes = (
  selectedMetric,
  selectedMetricObject,
  mediaTypes = [],
  channel = null,
  reportKey = null,
) => {
  if (mediaTypes.length === 0) {
    return selectedMetric ? [selectedMetric] : [];
  }
  const legacyMediaTypes = mediaTypes.map(
    (type) => selectedMetricObject.mediaSubtypes?.[type]?.metric,
  );

  /**
   * TODO: remove this once all of the frontend configs are removed
   * https://app.shortcut.com/dashhudson/story/104187/remove-all-code-using-frontend-configs
   */
  if (legacyMediaTypes.every((mediaType) => Boolean(mediaType))) return legacyMediaTypes;

  const metricDetails = getMetricDetails(selectedMetric, [channel], reportKey);
  const mediaTypeMetrics = getMediaTypeOptionsObjectFromMetricDetails(
    metricDetails,
    channel,
    reportKey,
    true,
  );
  return mediaTypes.map((type) => mediaTypeMetrics?.[type]?.metric);
};

export const reorderSelectedMediaTypes = (selectedMediaTypes) => {
  return Object.keys(MEDIA_TYPES).filter((mediaType) => selectedMediaTypes.includes(mediaType));
};
