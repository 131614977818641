export const TIKTOK_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channels: ['TIKTOK'], metric: 'VIDEO_VIEWS' },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: { name: 'All Published Posts' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 5,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 5,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 9,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'ORGANIC_LIKES' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 9,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'ORGANIC_COMMENTS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 13,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'SHARES' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 13,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'PROFILE_VIEWS' },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 17,
    meta: { name: 'Posts Published During Reporting Period' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 18,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 18,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 22,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_LIKES' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 22,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_COMMENTS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 26,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_SHARES' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 26,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'NUMBER_OF_POSTS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 30,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'AVG_VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 30,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'AVG_REACH' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_RETENTION_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'AVG_ENTERTAINMENT_SCORE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 38,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'AVG_TIME_WATCHED' },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 43,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENTERTAINMENT_SCORE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 47,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 55,
    meta: { name: 'Follower Activity' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 56,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 56,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 60,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'TOTAL_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 60,
    meta: { brand_ids: ['brand_ids'], channel: 'TIKTOK', metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 42,
    meta: { name: 'Post Performance' },
  },
];
