/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'metricReach': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g transform="translate(-274 -199)" id="Stories"><g id="Group-6" transform="translate(273 195)"><g id="metric-reach" _fill="#000"><path pid="0" id="Combined-Shape" d="M13 8.5c5 0 9.3 3.1 11 7.5-1.7 4.4-6 7.5-11 7.5S3.7 20.4 2 16c1.7-4.4 6-7.5 11-7.5zm-.1 2c-4.1 0-7.2 2-8.6 5.5 1.4 3.5 4.7 5.5 8.7 5.5s7.1-2 8.5-5.5c-1.4-3.5-4.5-5.5-8.6-5.5z"/><circle pid="1" id="Oval" cx="13" cy="16" r="3" fill-rule="evenodd" clip-rule="evenodd"/></g></g></g>'
  }
})
