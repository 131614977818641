/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gauge': {
    width: 22,
    height: 16,
    viewBox: '0 0 22 16',
    data: '<path pid="0" d="M11 0c6.065 0 11 4.934 11 11v5H0v-5C0 4.934 4.935 0 11 0zM9.269 14h3.462c-.346-.597-.993-1-1.731-1-.738 0-1.384.403-1.731 1zM20 14v-3c0-4.962-4.037-9-9-9s-9 4.038-9 9v3h5.143A3.993 3.993 0 0110 11.142V7h2v4.142A3.997 3.997 0 0114.857 14H20zm-9-9a1 1 0 110-2 1 1 0 010 2zm4.95 2.049a1 1 0 110-2 1 1 0 010 2zM18 12a1 1 0 110-2 1 1 0 010 2zM6.05 7.051a1.001 1.001 0 110-2.002 1.001 1.001 0 010 2.002zM4 12a1 1 0 110-2 1 1 0 010 2z" _fill="#DDD" fill-rule="nonzero"/>'
  }
})
