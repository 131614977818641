/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pause': {
    width: 33,
    height: 46,
    viewBox: '0 0 33 46',
    data: '<path pid="0" d="M10.5.507c1.375 0 2.5 1.125 2.5 2.5v40c0 1.376-1.125 2.5-2.5 2.5H3a2.507 2.507 0 01-2.5-2.5v-40c0-1.375 1.125-2.5 2.5-2.5h7.5zm20 0c1.375 0 2.5 1.125 2.5 2.5v40c0 1.376-1.125 2.5-2.5 2.5H23a2.507 2.507 0 01-2.5-2.5v-40c0-1.375 1.125-2.5 2.5-2.5h7.5z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})
