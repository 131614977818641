export const tooltips = {
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  connectCallToAction:
    "Dash Hudson brings you all of the insights you love, including Facebook! Ask your Dash Hudson administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
  avgEngagementRate:
    'The average engagement rate for your organic posts published during the selected date range\n' +
    '\n' +
    'Engagement Rate = Total Engagements / Impressions',
  averageEngagementRatePublic:
    'The Total Engagements / Page Fans of posts published during the Reporting Period. This excludes Post Clicks and Impressions to ensure a fair comparison with competitors based on publicly available data.',
  averageEffectiveness:
    'The average effectiveness rate for your organic posts published during the selected date range\n' +
    '\n' +
    'Effectiveness Rate = (Total Engagements + Video Views) / Reach\n',
  linkClicks:
    'The total number of link clicks for your organic posts published during the selected date range',
  netNewFans: 'The net total of new fans your page has accumulated during the selected date range',
  avgDailyPostReach:
    "The average daily reach for any of your page's organic posts during the selected date range",
  engagements:
    "The number of total engagements on any of your page's organic and paid posts during the selected date range",
  impressionsTooltip: `The total number of impressions for any of your page's organic posts during the selected date range`,
};

export const promotedTopLineStatsTooltips = {
  engagements:
    'The total number of engagements on your organic posts published during the selected date range' +
    '\n\n' +
    'Engagements = Reactions + Comments + Shares + Post Clicks',
  avgEngagementRate:
    'The average engagement rate for your organic posts published during the selected date range' +
    '\n\n' +
    'Engagement Rate = Engagements / Impressions',
  avgEffectiveness:
    'The average effectiveness rate for your organic posts published during the selected date range' +
    '\n\n' +
    'Effectiveness Rate = (Engagements + Video Views) / Reach',
  avgReach:
    "The average reach for any of your page's organic and promoted posts published during the selected date range" +
    '\n\n' +
    'Average Reach = Total Reach / Number of Posts published during the time frame',
  impressions:
    'The total number of impressions for your organic and promoted posts published during the selected date range',
  linkClicks:
    'The total number of link clicks for your organic and promoted posts published during the selected date range',
};

export const promotedMediaPopupTooltips = {
  amountSpent: "The total amount of money you've spent promoting this post",
  clicks:
    'The total number of clicks this promoted post received. This can include link clicks, clicks to your profile, and more.',
  comments: 'The total number of times people commented on this post',
  costPerThruPlay:
    'The average cost per ThruPlay for this video\n' +
    '\n' +
    'Cost Per ThruPlay = Amount spent / ThruPlays',
  cpc:
    'The average cost per click on this promoted post\n' +
    '\n' +
    'CPC = Amount Spent / Total Promoted Clicks',
  cpm:
    'The average cost per 1,000 impressions on this promoted post\n' +
    '\n' +
    'CPM = Amount Spent / Total Promoted Impressions x 1,000',
  ctr:
    'The percentage of times people clicked your promoted post after seeing it\n' +
    '\n' +
    'CTR = Total Clicks / Promoted Impressions',
  effectivenessNonVideo:
    "The rate of this post's engagement based on the unique accounts reached\n" +
    '\n' +
    'Effectiveness = (Likes + Comments + Saves + Shares) / Reach',
  effectivenessVideo:
    "The rate of this post's views and engagement based on the unique accounts reached\n" +
    '\n' +
    'Effectiveness Rate = (Total Engagements + Video Views) / Reach',
  engagementsOrganic:
    'The total number of organic engagements on this post\n' +
    '\n' +
    'Engagements = Reactions + Comments + Shares + Post Clicks',
  engagementsPromoted:
    'The total number of promoted engagements on this post\n' +
    '\n' +
    'Engagements = Reactions + Comments + Shares\n',
  engagementRate:
    'The average organic engagement rate for this post\n' +
    '\n' +
    'Engagement Rate = Engagements / Impressions',
  engagementRatePromoted:
    'The average promoted engagement rate for this post\n' +
    '\n' +
    'Engagement Rate = Promoted Engagements / Promoted  Impressions',
  frequency: 'The average number of times people saw this promoted post',
  impressions: 'The total number of impressions this post received',
  linkClicksPromoted: 'The total number of times links on this promoted post were clicked',
  pageLikes: 'The total number of likes this post received',
  reach: 'The total number of unique accounts that saw this post',
  reactions:
    'The number of times people clicked on like, care, love, wow, haha, sad, and anger reactions on your post',
  shares: 'The total number of times people shared this post',
  thruPlays:
    'The total number of times this promoted post was played to completion or for at least 15 seconds',
  videoCompleteViews:
    'The total number of times this video was played from the beginning to 95% or more of its length',
  videoPlay25: 'The total number of times your promoted video was played to 25% of its length',
  videoPlay50: 'The total number of times your promoted video was played to 50% of its length',
  videoPlay75: 'The total number of times your promoted video was played to 75% of its length',
  videoViews:
    'The total number times this video was played for at least 3 seconds, or for nearly its total length if shorter than 3 seconds. Excluding replays.',
};

export const defaultGraphMetric = 'TOTAL_ENGAGEMENTS';

export const filterTypes = {
  GRAPH: 'Graph',
  DATE: 'Date',
  SCALE: 'Scale',
};
export const timeScaleTypes = {
  DAILY: {
    value: 'DAILY',
    label: 'By Day',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'By Month',
  },
};

export const facebookMediaCardMetricsAds = [
  'paidAndOrganicEngagementRate',
  'paidAndOrganicImpressions',
  'paidAndOrganicEngagements',
  'paidAndOrganicReach',
];

export const facebookMediaCardDefaults = {
  metrics: ['engagementRate', 'impressions', 'totalEngagements', 'reach'],
  labels: {
    engagementRate: 'Engagement Rate',
    totalEngagements: 'Total Engagements',
  },
};

export const facebookCompetitiveMediaCardDefaults = {
  labels: {
    comments: 'Comments',
    engagementRatePublic: 'Engagement Rate',
    reactions: 'Reactions',
    shares: 'Shares',
  },
  metrics: ['comments', 'engagementRatePublic', 'reactions', 'shares'],
};

export const facebookYourPostsFilterOptions = [
  { label: 'Post Type', value: 'is_boosted' },
  { label: 'Media Type', value: 'post_types' },
  { label: 'Caption', value: 'caption_keywords' },
];

export const facebookYourPostsWidgets = {
  is_boosted: {
    name: 'MediaFilterRadioWidget',
    label: 'Post Type',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Organic Posts', value: 'false' },
        { label: 'Promoted Posts', value: 'true' },
      ],
    },
  },
  post_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Photo', value: 'PHOTO' },
        { label: 'Video', value: 'VIDEO' },
        { label: 'Reel', value: 'REEL' },
        { label: 'Carousel', value: 'CAROUSEL' },
        { label: 'Text', value: 'TEXT' },
        { label: 'Link', value: 'LINK' },
      ],
    },
  },
  caption_keywords: {
    name: 'MediaFilterSearchWidget',
    label: 'Caption',
    options: {
      placeholder: 'Enter keywords, #hashtags or @mentions',
    },
  },
};
export const yourPostsMediaTypes = [
  'FACEBOOK_OWNED',
  'FACEBOOK_LINK',
  'FACEBOOK_TEXT',
  'FACEBOOK_TEXT_LINK',
];

export const yourPostsSortOptions = [
  {
    value: 'FACEBOOK_REELS_FB_REELS_TOTAL_PLAYS',
    label: 'All Plays',
    field: 'reel.fbReelsTotalPlays',
  },
  {
    value: 'FACEBOOK_REELS_POST_VIDEO_AVG_TIME_WATCHED',
    label: 'Avg. Time Watched',
    field: 'reel.postVideoAvgTimeWatched',
  },
  {
    value: 'FACEBOOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: 'FACEBOOK_EFFECTIVENESS',
    label: 'Effectiveness',
    field: 'effectiveness',
  },
  {
    value: 'FACEBOOK_REELS_EFFECTIVENESS',
    label: 'Effectiveness - Reels',
    field: 'reel.effectiveness',
  },
  {
    value: 'FACEBOOK_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: 'FACEBOOK_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: 'FACEBOOK_LINK_CLICKS',
    label: 'Link Clicks',
    field: 'linkClicks',
  },
  {
    value: 'FACEBOOK_OTHER_CLICKS',
    label: 'Other Clicks',
    field: 'otherClicks',
  },
  {
    value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
    label: 'Photo View Clicks',
    field: 'photoViewClicks',
  },
  {
    value: 'FACEBOOK_BLUE_REELS_PLAYS_COUNT',
    label: 'Plays',
    field: 'reel.blueReelsPlayCount',
  },
  {
    value: 'FACEBOOK_POST_CLICKS',
    label: 'Post Clicks',
    field: 'postClicks',
  },
  {
    value: 'FACEBOOK_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: 'FACEBOOK_REACTIONS',
    label: 'Reactions',
    field: 'reactions',
  },
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'FACEBOOK_REELS_REPLAY_COUNT',
    label: 'Replays',
    field: 'reel.fbReelsReplayCount',
  },
  {
    value: 'FACEBOOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
  {
    value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'totalEngagements',
  },
  {
    value: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
    label: 'Video Complete Views',
    field: 'videoCompleteViews',
  },
  {
    value: 'FACEBOOK_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViews',
  },
];
export const yourPostsDefaultSort = 'DATE';
export const yourPostsSortOptionsAdsNeverConnected = [
  {
    value: 'FACEBOOK_REELS_FB_REELS_TOTAL_PLAYS',
    label: 'All Plays',
    field: 'reel.fbReelsTotalPlays',
  },
  {
    value: 'FACEBOOK_REELS_POST_VIDEO_AVG_TIME_WATCHED',
    label: 'Avg. Time Watched',
    field: 'reel.postVideoAvgTimeWatched',
  },
  {
    value: 'FACEBOOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: 'FACEBOOK_EFFECTIVENESS',
    label: 'Effectiveness',
    field: 'effectiveness',
  },
  {
    value: 'FACEBOOK_REELS_EFFECTIVENESS',
    label: 'Effectiveness - Reels',
    field: 'reel.effectiveness',
  },
  {
    value: 'FACEBOOK_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: 'FACEBOOK_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: 'FACEBOOK_LINK_CLICKS',
    label: 'Link Clicks',
    field: 'linkClicks',
  },
  {
    value: 'FACEBOOK_OTHER_CLICKS',
    label: 'Other Clicks',
    field: 'otherClicks',
  },
  {
    value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
    label: 'Photo View Clicks',
    field: 'photoViewClicks',
  },
  {
    value: 'FACEBOOK_BLUE_REELS_PLAYS_COUNT',
    label: 'Plays',
    field: 'reel.blueReelsPlayCount',
  },
  {
    value: 'FACEBOOK_POST_CLICKS',
    label: 'Post Clicks',
    field: 'postClicks',
  },
  {
    value: 'FACEBOOK_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: 'FACEBOOK_REACTIONS',
    label: 'Reactions',
    field: 'reactions',
  },
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'FACEBOOK_REELS_REPLAY_COUNT',
    label: 'Replays',
    field: 'reel.fbReelsReplayCount',
  },
  {
    value: 'FACEBOOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
  {
    value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'totalEngagements',
  },
  {
    value: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
    label: 'Video Complete Views',
    field: 'videoCompleteViews',
  },
  {
    value: 'FACEBOOK_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViews',
  },
];
export const yourPostsSortOptionsAdsConnected = [
  { label: 'Recently Published', value: 'DATE' },
  {
    label: 'Organic',
    children: [
      {
        value: 'FACEBOOK_REELS_FB_REELS_TOTAL_PLAYS',
        label: 'All Plays',
        displayLabel: 'Reels Total Plays - Organic',
        field: 'reel.fbReelsTotalPlays',
      },
      {
        value: 'FACEBOOK_REELS_POST_VIDEO_AVG_TIME_WATCHED',
        label: 'Avg. Time Watched',
        displayLabel: 'Reels Avg. Time Watched - Organic',
        field: 'reel.postVideoAvgTimeWatched',
      },
      {
        value: 'FACEBOOK_ORGANIC_COMMENTS',
        label: 'Comments',
        displayLabel: 'Comments - Organic',
        field: 'organicComments',
      },
      {
        value: 'FACEBOOK_ORGANIC_EFFECTIVENESS',
        label: 'Effectiveness',
        displayLabel: 'Effectiveness - Organic',
        field: 'organicEffectiveness',
      },
      {
        value: 'FACEBOOK_REELS_EFFECTIVENESS',
        label: 'Effectiveness - Reels',
        displayLabel: 'Reels Effectiveness - Organic',
        field: 'reel.effectiveness',
      },
      {
        value: 'FACEBOOK_ORGANIC_ENGAGEMENT_RATE',
        label: 'Engagement Rate',
        displayLabel: 'Engagement Rate - Organic',
        field: 'organicEngagementRate',
      },
      {
        value: 'FACEBOOK_ORGANIC_ENGAGEMENTS',
        label: 'Engagements',
        displayLabel: 'Engagements - Organic',
        field: 'organicEngagements',
      },
      {
        value: 'FACEBOOK_ORGANIC_IMPRESSIONS',
        label: 'Impressions',
        displayLabel: 'Impressions - Organic',
        field: 'organicImpressions',
      },
      {
        value: 'FACEBOOK_ORGANIC_LINK_CLICKS',
        label: 'Link Clicks',
        displayLabel: 'Link Clicks - Organic',
        field: 'organicLinkClicks',
      },
      {
        value: 'FACEBOOK_OTHER_CLICKS',
        label: 'Other Clicks',
        displayLabel: 'Other Clicks',
        field: 'otherClicks',
      },
      {
        value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
        label: 'Photo View Clicks',
        displayLabel: 'Photo View Clicks',
        field: 'photoViewClicks',
      },
      {
        value: 'FACEBOOK_BLUE_REELS_PLAYS_COUNT',
        label: 'Plays',
        displayLabel: 'Reels Plays - Organic',
        field: 'reel.blueReelsPlayCount',
      },
      {
        value: 'FACEBOOK_POST_CLICKS',
        label: 'Post Clicks',
        displayLabel: 'Post Clicks',
        field: 'postClicks',
      },
      {
        value: 'FACEBOOK_ORGANIC_REACH',
        label: 'Reach',
        displayLabel: 'Reach - Organic',
        field: 'organicReach',
      },
      {
        value: 'FACEBOOK_ORGANIC_REACTIONS',
        label: 'Reactions',
        displayLabel: 'Reactions - Organic',
        field: 'organicReactions',
      },
      {
        value: 'FACEBOOK_REELS_REPLAY_COUNT',
        label: 'Replays',
        displayLabel: 'Reels Replays - Organic',
        field: 'reel.fbReelsReplayCount',
      },
      {
        value: 'FACEBOOK_ORGANIC_SHARES',
        label: 'Shares',
        displayLabel: 'Shares - Organic',
        field: 'organicShares',
      },
      {
        value: 'FACEBOOK_ORGANIC_VIDEO_COMPLETE_VIEWS',
        label: 'Video Complete Views',
        displayLabel: 'Video Complete Views - Organic',
        field: 'organicVideoCompleteViews',
      },
      {
        value: 'FACEBOOK_ORGANIC_VIDEO_VIEWS',
        label: 'Video Views',
        displayLabel: 'Video Views - Organic',
        field: 'organicVideoViews',
      },
    ],
  },
  {
    label: 'Promoted',
    children: [
      {
        label: 'Comments',
        displayLabel: 'Comments - Promoted',
        value: 'FACEBOOK_PAID_COMMENTS',
        field: 'paidComments',
      },
      {
        label: 'CPC',
        value: 'FACEBOOK_CPC',
        field: 'cpc',
      },
      {
        label: 'CPM',
        value: 'FACEBOOK_CPM',
        field: 'cpm',
      },
      {
        label: 'CTR',
        value: 'FACEBOOK_CTR',
        field: 'ctr',
      },
      {
        label: 'Engagement Rate',
        displayLabel: 'Engagement Rate - Promoted',
        value: 'FACEBOOK_PAID_ENGAGEMENT_RATE',
        field: 'paidEngagementRate',
      },
      {
        label: 'Engagements',
        displayLabel: 'Engagements - Promoted',
        value: 'FACEBOOK_PAID_ENGAGEMENTS',
        field: 'paidEngagements',
      },
      {
        label: 'Impressions',
        displayLabel: 'Impressions - Promoted',
        value: 'FACEBOOK_PAID_IMPRESSIONS',
        field: 'paidImpressions',
      },
      {
        label: 'Reach',
        displayLabel: 'Reach - Promoted',
        value: 'FACEBOOK_PAID_REACH',
        field: 'paidReach',
      },
      {
        label: 'Reactions',
        displayLabel: 'Reactions - Promoted',
        value: 'FACEBOOK_PAID_REACTIONS',
        field: 'paidReactions',
      },
      {
        label: 'Video Views',
        displayLabel: 'Video Views - Promoted',
        value: 'FACEBOOK_PAID_VIDEO_VIEWS',
        field: 'paidVideoViews',
      },
    ],
  },
  {
    label: 'Total',
    children: [
      {
        label: 'Comments',
        displayLabel: 'Comments - Total',
        value: 'FACEBOOK_PAID_AND_ORGANIC_COMMENTS',
        field: 'paidAndOrganicComments',
      },
      {
        label: 'Impressions',
        displayLabel: 'Impressions - Total',
        value: 'FACEBOOK_PAID_AND_ORGANIC_IMPRESSIONS',
        field: 'paidAndOrganicImpressions',
      },
      {
        label: 'Reach',
        displayLabel: 'Reach - Total',
        value: 'FACEBOOK_PAID_AND_ORGANIC_REACH',
        field: 'paidAndOrganicReach',
      },
      {
        label: 'Reactions',
        displayLabel: 'Reactions - Total',
        value: 'FACEBOOK_PAID_AND_ORGANIC_REACTIONS',
        field: 'paidAndOrganicReactions',
      },
      {
        label: 'Video Views',
        displayLabel: 'Video Views - Total',
        value: 'FACEBOOK_PAID_AND_ORGANIC_VIDEO_VIEWS',
        field: 'paidAndOrganicVideoViews',
      },
    ],
  },
];

export const facebookGalleryCardDefaults = {
  metrics: [
    'fbTotalEngagements',
    'fbAvgEngagementRate',
    'fbAvgEffectiveness',
    'fbAvgReach',
    'fbTotalImpressions',
  ],
  labels: {
    fbTotalEngagements: 'Total Engagements',
    fbAvgEngagementRate: 'Avg. Engagement Rate',
    fbAvgEffectiveness: 'Avg. Effectiveness',
    fbAvgReach: 'Avg. Reach',
    fbTotalImpressions: 'Impressions',
  },
};

export const facebookMetricFormats = {
  fbAvgEngagementRate: 'percent',
  fbAvgEffectiveness: 'percent',
};

export const galleriesSortOptions = [
  {
    value: 'CREATED',
    label: 'Recently Added',
  },
  {
    value: 'FACEBOOK_AVG_EFFECTIVENESS',
    label: 'Avg. Effectiveness',
  },
  {
    value: 'FACEBOOK_AVG_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate',
  },
  {
    value: 'FACEBOOK_AVG_REACH',
    label: 'Avg. Reach',
  },
  {
    value: 'FACEBOOK_TOTAL_IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
  },
];

export const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

export const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const facebookGalleriesDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-DATE',
  },
  {
    value: '-FACEBOOK_POST_CLICKS',
    label: 'Post Clicks',
    field: 'postClicks',
  },
  {
    value: '-FACEBOOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: '-FACEBOOK_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: '-FACEBOOK_EFFECTIVENESS',
    label: 'Effectiveness',
    field: 'effectiveness',
  },
  {
    value: '-FACEBOOK_REACTIONS',
    label: 'Reactions',
    field: 'reactions',
  },
  {
    value: '-FACEBOOK_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: '-FACEBOOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
  {
    value: '-FACEBOOK_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: '-FACEBOOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'totalEngagements',
  },
  {
    value: '-FACEBOOK_LINK_CLICKS',
    label: 'Link Clicks',
    field: 'linkClicks',
  },
  {
    value: '-FACEBOOK_VIDEO_COMPLETE_VIEWS',
    label: 'Video Complete Views',
    field: 'videoCompleteViews',
  },
  {
    value: '-FACEBOOK_OTHER_CLICKS',
    label: 'Other Clicks',
    field: 'otherClicks',
  },
  {
    value: '-FACEBOOK_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViews',
  },
  {
    value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
    label: 'Photo View Clicks',
    field: 'photoViewClicks',
  },
];

const routeNames = {
  YOUR_POSTS: 'facebook.yourPosts',
};

export default {
  routeNames,
  facebookMediaCardDefaults,
};

export const facebookCommentRequiredScopes = [
  'pages_manage_engagement',
  'pages_read_engagement',
  'pages_manage_metadata',
];

export const facebookDirectMessageRequiredScopes = ['pages_messaging', 'pages_manage_metadata'];

export const facebookPromotedTopLineStatsMetrics = [
  {
    keys: {
      netNew: 'NET_NEW_FOLLOWERS',
      total: 'TOTAL_FOLLOWERS',
    },
    value: 'NET_NEW_FOLLOWERS',
    label: 'Net New Fans',
    tooltip: tooltips.netNewFans,
  },
  {
    keys: {
      organic: 'ORGANIC_ENGAGEMENTS_POSTS',
    },
    value: 'ORGANIC_ENGAGEMENTS_POSTS',
    label: 'Engagements',
    tooltip: promotedTopLineStatsTooltips.engagements,
  },
  {
    keys: {
      organic: 'AVG_ORGANIC_ENGAGEMENT_RATE',
    },
    value: 'AVG_ORGANIC_ENGAGEMENT_RATE',
    label: 'Average Engagement Rate',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEngagementRate,
  },
  {
    keys: {
      organic: 'AVG_ORGANIC_EFFECTIVENESS',
    },
    value: 'AVG_ORGANIC_EFFECTIVENESS',
    label: 'Average Effectiveness',
    percent: true,
    tooltip: promotedTopLineStatsTooltips.avgEffectiveness,
  },
  {
    keys: {
      organic: 'AVG_ORGANIC_POST_REACH',
      promoted: 'AVG_PAID_POST_REACH',
      total: 'AVG_PAID_AND_ORGANIC_POST_REACH',
    },
    value: 'AVG_PAID_AND_ORGANIC_POST_REACH',
    label: 'Average Reach',
    tooltip: promotedTopLineStatsTooltips.avgReach,
  },
  {
    keys: {
      organic: 'ORGANIC_IMPRESSIONS_BY_POST',
      promoted: 'PAID_IMPRESSIONS_BY_POST',
      total: 'PAID_AND_ORGANIC_IMPRESSIONS_BY_POST',
    },
    value: 'PAID_AND_ORGANIC_IMPRESSIONS_BY_POST',
    label: 'Impressions',
    tooltip: promotedTopLineStatsTooltips.impressions,
  },
  {
    keys: {
      organic: 'ORGANIC_LINK_CLICKS',
      promoted: 'PAID_LINK_CLICKS',
      total: 'LINK_CLICKS',
    },
    value: 'LINK_CLICKS',
    label: 'Link Clicks',
    tooltip: promotedTopLineStatsTooltips.linkClicks,
  },
];

export const facebookTopLineStatsMetrics = [
  { value: 'NET_NEW_FOLLOWERS', label: 'Net New Fans', tooltip: tooltips.netNewFans },
  {
    value: 'TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    tooltip: tooltips.engagements,
  },
  {
    value: 'AVG_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate',
    percent: true,
    tooltip: tooltips.avgEngagementRate,
  },
  {
    value: 'AVG_EFFECTIVENESS',
    label: 'Avg. Effectiveness',
    percent: true,
    tooltip: tooltips.averageEffectiveness,
  },
  {
    value: 'AVG_REACH',
    label: 'Avg. Daily Post Reach',
    tooltip: tooltips.avgDailyPostReach,
  },
  {
    value: 'IMPRESSIONS',
    label: 'Post Impressions',
    tooltip: tooltips.impressionsTooltip,
  },
  { value: 'LINK_CLICKS', label: 'Link Clicks', tooltip: tooltips.linkClicks },
];

export const facebookPromotedInsightsMetrics = [
  {
    label: 'Amount Spent',
    tooltip:
      "The total amount of money you've spent on promoted posts published during the selected date range",
    metrics: {
      promoted: 'TOTAL_SPENT',
    },
  },
  {
    label: 'CPC',
    tooltip:
      'The average cost per click of your promoted posts published during the selected date range\n' +
      '\n' +
      'CPC = Amount Spent / Promoted Clicks',
    metrics: {
      promoted: 'COST_PER_CLICK',
    },
  },
  {
    label: 'CPM',
    tooltip:
      'The average cost per 1,000 impressions on your promoted posts published during the selected date range\n' +
      '\n' +
      'CPM = Amount Spent / Total Promoted Impressions x 1,000',
    metrics: {
      promoted: 'COST_PER_MILE',
    },
  },
  {
    label: 'CTR',
    tooltip:
      'The percentage of times people clicked your promoted posts published during the selected date range\n' +
      '\n' +
      'CTR = Clicks / Promoted Impressions',
    metrics: {
      promoted: 'CLICK_THROUGH_RATE',
    },
  },
  {
    label: 'Comments',
    tooltip:
      'The total number of times people commented on your promoted posts published during the selected date range',
    metrics: {
      promoted: 'PAID_COMMENTS',
      organic: 'ORGANIC_COMMENTS_POSTS_PAID',
    },
  },
  {
    label: 'Impressions',
    tooltip:
      'The total number of impressions on promoted posts published during the selected date range',
    metrics: {
      promoted: 'PAID_IMPRESSIONS_BY_POST',
      organic: 'ORGANIC_IMPRESSIONS_POSTS_PAID',
    },
  },
  {
    label: 'Reactions',
    tooltip:
      'The total number of times people reacted to your promoted posts published during the selected date range',
    metrics: {
      promoted: 'PAID_REACTIONS',
      organic: 'ORGANIC_REACTIONS_POSTS_PAID',
    },
  },
  {
    label: 'Reach',
    tooltip:
      'The total number of unique accounts that saw your promoted posts published during the selected date range',
    metrics: {
      promoted: 'PAID_POST_REACH',
      organic: 'ORGANIC_REACH_POSTS_PAID',
    },
  },
  {
    label: 'Shares',
    tooltip:
      'The total number of times people shared your promoted posts published during the selected date range',
    metrics: {
      promoted: 'PAID_SHARES',
      organic: 'ORGANIC_SHARES_POSTS_PAID',
    },
  },
  {
    label: 'Video Views',
    tooltip:
      'The total number times your promoted videos published during the reporting period were played for at least 3 seconds, or for nearly its total length if shorter than 3 seconds. This excludes replays.',
    metrics: {
      promoted: 'PAID_VIDEO_VIEWS',
      organic: 'ORGANIC_VIDEO_VIEWS_POSTS_PAID',
    },
  },
];

export const facebookPromotedMediaPopupMetrics = [
  {
    tooltip: promotedMediaPopupTooltips.amountSpent,
    key: 'promotedAmountSpent',
    label: 'Amount Spent - Promoted',
    monetary: true,
  },
  // promotedClicks missing
  {
    tooltip: promotedMediaPopupTooltips.comments,
    key: 'promotedAndOrganicComments',
    label: 'Comments',
  },
  {
    key: 'organicComments',
    alternativeKey: 'comments',
    parentKey: 'promotedAndOrganicComments',
    label: 'Organic',
    nested: true,
  },
  {
    key: 'promotedComments',
    parentKey: 'promotedAndOrganicComments',
    label: 'Promoted',
    nested: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.costPerThruPlay,
    key: 'promotedCostPerThruPlay',
    label: 'Cost Per ThruPlay - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.cpc,
    key: 'promotedCpc',
    label: 'CPC - Promoted',
    monetary: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.cpm,
    key: 'promotedCpm',
    label: 'CPM - Promoted',
    monetary: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.ctr,
    key: 'promotedCtr',
    label: 'CTR - Promoted',
    percent: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.effectivenessNonVideo,
    key: 'organicEffectiveness',
    alternativeKey: 'effectiveness',
    label: 'Effectiveness - Organic',
    percent: true,
    videoMetric: false,
  },
  {
    tooltip: promotedMediaPopupTooltips.effectivenessVideo,
    key: 'organicEffectiveness',
    alternativeKey: 'effectiveness',
    label: 'Effectiveness - Organic',
    percent: true,
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.engagementsOrganic,
    key: 'organicEngagements',
    alternativeKey: 'totalEngagements',
    label: 'Engagements - Organic',
  },
  {
    tooltip: promotedMediaPopupTooltips.engagementsPromoted,
    key: 'promotedEngagements',
    label: 'Engagements - Promoted',
  },
  {
    tooltip: promotedMediaPopupTooltips.engagementRate,
    key: 'organicEngagementRate',
    alternativeKey: 'engagementRate',
    label: 'Engagement Rate - Organic',
    percent: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.engagementRatePromoted,
    key: 'promotedEngagementRate',
    label: 'Engagement Rate - Promoted',
    percent: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.frequency,
    key: 'promotedFrequency',
    label: 'Frequency - Promoted',
  },
  {
    tooltip: promotedMediaPopupTooltips.impressions,
    key: 'promotedAndOrganicImpressions',
    label: 'Impressions',
  },
  {
    key: 'organicImpressions',
    alternativeKey: 'impressions',
    parentKey: 'promotedAndOrganicImpressions',
    label: 'Organic',
    nested: true,
  },
  {
    key: 'promotedImpressions',
    parentKey: 'promotedAndOrganicImpressions',
    label: 'Promoted',
    nested: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.linkClicksPromoted,
    key: 'promotedLinkClicks',
    label: 'Link Clicks - Promoted',
  },
  { key: 'otherClicks', label: 'Other Clicks - Organic' },
  // promotedPageLikes missing
  { key: 'postClicks', label: 'Post Clicks - Organic' },
  { key: 'photoViewClicks', label: 'Photo View Clicks - Organic', videoMetric: false },
  { tooltip: promotedMediaPopupTooltips.reach, key: 'promotedAndOrganicReach', label: 'Reach' },
  {
    key: 'organicReach',
    alternativeKey: 'reach',
    parentKey: 'promotedAndOrganicReach',
    label: 'Organic',
    nested: true,
  },
  { key: 'promotedReach', label: 'Promoted', parentKey: 'promotedAndOrganicReach', nested: true },
  {
    tooltip: promotedMediaPopupTooltips.reactions,
    key: 'promotedAndOrganicReactions',
    label: 'Reactions',
  },
  {
    key: 'organicReactions',
    alternativeKey: 'reactions',
    parentKey: 'promotedAndOrganicReactions',
    label: 'Organic',
    nested: true,
  },
  {
    key: 'promotedReactions',
    label: 'Promoted',
    parentKey: 'promotedAndOrganicReactions',
    nested: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.shares,
    key: 'promotedAndOrganicShares',
    label: 'Shares',
  },
  {
    key: 'organicShares',
    alternativeKey: 'shares',
    parentKey: 'promotedAndOrganicShares',
    label: 'Organic',
    nested: true,
  },
  { key: 'promotedShares', parentKey: 'promotedAndOrganicShares', label: 'Promoted', nested: true },
  {
    tooltip: promotedMediaPopupTooltips.thruPlays,
    key: 'promotedThruPlays',
    label: 'ThruPlays - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoCompleteViews,
    key: 'organicVideoCompleteViews',
    alternativeKey: 'videoCompleteViews',
    label: 'Video Complete Views - Organic',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoPlay25,
    key: 'promotedVideoPlay25',
    label: 'Video Plays 25% - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoPlay50,
    key: 'promotedVideoPlay50',
    label: 'Video Plays 50% - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoPlay75,
    key: 'promotedVideoPlay75',
    label: 'Video Plays 75% - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoCompleteViews,
    key: 'promotedVideoCompleteViews',
    label: 'Video Plays 100% - Promoted',
    videoMetric: true,
  },
  {
    tooltip: promotedMediaPopupTooltips.videoViews,
    key: 'promotedAndOrganicVideoViews',
    label: 'Video Views',
    videoMetric: true,
  },
  {
    key: 'organicVideoViews',
    alternativeKey: 'videoViews',
    label: 'Organic',
    parentKey: 'promotedAndOrganicVideoViews',
    nested: true,
    videoMetric: true,
  },
  {
    key: 'promotedVideoViews',
    label: 'Promoted',
    parentKey: 'promotedAndOrganicVideoViews',
    nested: true,
    videoMetric: true,
  },
];

export const overviewCSVConfig = {
  metrics: {
    label: 'Metrics CSV',
    filenamePrefix: 'metrics_csv',
    metricsListOrganicOnly: [
      'TOTAL_ENGAGEMENTS',
      'AVG_ENGAGEMENT_RATE',
      'AVG_EFFECTIVENESS',
      'AVG_REACH',
      'IMPRESSIONS',
      'LINK_CLICKS',
    ],
    metricsListPromotedBreakdown: [
      'ORGANIC_ENGAGEMENTS_POSTS',
      'AVG_ORGANIC_ENGAGEMENT_RATE',
      'AVG_ORGANIC_EFFECTIVENESS',
      'AVG_ORGANIC_POST_REACH',
      'AVG_PAID_POST_REACH',
      'AVG_PAID_AND_ORGANIC_POST_REACH',
      'ORGANIC_IMPRESSIONS_BY_POST',
      'PAID_IMPRESSIONS_BY_POST',
      'PAID_AND_ORGANIC_IMPRESSIONS_BY_POST',
      'ORGANIC_LINK_CLICKS',
      'PAID_LINK_CLICKS',
      'LINK_CLICKS',
    ],
  },
};
