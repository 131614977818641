import { isFinite } from 'chart.js/helpers';
import { colours } from '@/ux/colours';

function inArea(scale, pixel) {
  const area = scale.chart.chartArea;
  return scale.isHorizontal()
    ? pixel >= area.left && pixel <= area.right
    : pixel >= area.top && pixel <= area.bottom;
}

function scaleValue(scale, value, fallback) {
  const valueAsNumber = typeof value === 'number' ? value : scale.parse(value);
  const pixelForValue = isFinite(valueAsNumber) ? scale.getPixelForValue(valueAsNumber) : fallback;
  return inArea(scale, pixelForValue) ? pixelForValue : fallback;
}

function draw(ctx, x, y, width, height) {
  ctx.save();

  ctx.lineWidth = 1;
  ctx.strokeStyle = 'transparent';
  ctx.fillStyle = colours.CHART.BACKGROUND_BAR;

  ctx.setLineDash([]);
  ctx.lineDashOffset = 0;
  ctx.fillRect(x, y, width, height);

  ctx.restore();
}

export default {
  id: 'horizontalgridbars',
  beforeDatasetsDraw(chart) {
    const { ctx } = chart;
    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    const x = 0;
    const x2 = chart.width;
    let { top: y, bottom: y2 } = chart.chartArea;

    if (!xScale && !yScale) {
      return;
    }

    const tickValues = chart?.scales?.y?.ticks?.map((tick) => tick.value);
    if (tickValues) {
      const tickPairs =
        tickValues?.reduce((acc, value, index, array) => {
          if (index % 2 === 1) {
            const yMin = value;
            const yMax = array[index - 1];
            acc.push({
              yMin,
              yMax,
            });
          }
          return acc;
        }, []) || [];

      tickPairs.forEach((tickPair) => {
        if (yScale) {
          const min = scaleValue(yScale, tickPair.yMin, y2);
          const max = scaleValue(yScale, tickPair.yMax, y);
          y = Math.min(min, max);
          y2 = Math.max(min, max);
        }
        const width = x2 - x;
        const height = y2 - y;
        draw(ctx, x, y, width, height);
      });
    }
  },
};
