import { env } from '@/env';
import instagramConstants from '@/app/instagram/constants';

function getLeadingChar(str) {
  return (str && str.charAt(0)) || '';
}

export function getUserInitials(user) {
  const firstName = user?.firstName || user?.first_name || '';
  const lastName = user?.lastName || user?.last_name || '';
  if (!firstName && !lastName) {
    return null;
  }
  return `${getLeadingChar(firstName)}${getLeadingChar(lastName)}`;
}

export function getUserDisplayName(user) {
  if (!user) {
    return '';
  }
  const firstName = user?.firstName || user?.first_name || '';
  const lastName = user?.lastName || user?.last_name || '';
  return firstName && lastName ? `${firstName} ${lastName}` : user.email;
}

export function getFormattedUserName(user) {
  const firstName = user?.firstName || user?.first_name || '';
  const lastName = user?.lastName || user?.last_name || '';
  return firstName && lastName ? `${firstName} ${lastName}` : `${firstName}${lastName}`;
}

export function getUserEmail(user) {
  return user?.email ?? '';
}

export function validateUserAvatar(url) {
  if (!url) return false;
  return url.startsWith(env.prodCdnUrl) || url.startsWith(env.devCdnUrl);
}

export function getNumbersFromString(string) {
  if (/\d/.test(string)) return string.replace(/\D/g, '');
  return 0;
}

export function validateRealUser(userName) {
  return !['null', instagramConstants.igHashtagUser].includes(userName);
}
