<script setup>
// Vue
import { computed } from 'vue';
// Components
import QrCode from 'qrcode.vue';
// Libraries
import { useStorage } from '@vueuse/core';
import Icon from '@/components/foundation/Icon.vue';
// Utils
import { browserStorageNamespacedKey } from '@/utils/browserStorage';
import { SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';

const props = defineProps({
  link: {
    type: Object,
    default: () => {},
  },
  qrCodeLink: {
    type: String,
    default: '',
  },
  storageKey: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    default: '',
  },
  platform: {
    type: String,
    default: null,
  },
});

const gridStyle = computed(() => (props.qrCodeLink ? 'auto 1fr' : 'auto'));
const storageKey = computed(() => browserStorageNamespacedKey(props.storageKey));
const showBanner = useStorage(storageKey.value, true);
const tracker = new SchedulerUserEventTracker();

function close() {
  showBanner.value = false;
  tracker.schedulerQRCodeNotification(props.platform, 'Exit QR Code');
}

// Expose refs for testing
defineExpose({
  showBanner,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <transition name="slide-up-fade">
    <div v-if="showBanner" class="dismissible-inline-banner">
      <button @click="close">
        <Icon name="close" xxsmall />
        <span class="visually-hidden">Close</span>
      </button>
      <div class="content">
        <QrCode
          v-if="props.qrCodeLink"
          :value="props.qrCodeLink"
          :size="75"
          level="H"
          class="qr-code-container"
        />
        <p v-if="props.text || props.link" class="text-small">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="props.text" v-html="props.text" />
          <a
            v-if="props.link"
            :href="props.link.url"
            target="_blank"
            @click="tracker.schedulerQRCodeNotification(props.platform, 'Click Link')"
          >
            {{ props.link.text }}
          </a>
        </p>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.dismissible-inline-banner {
  background-color: var(--action-100);
  border-radius: var(--round-corner-small);
  margin: 0 0 var(--space-24) 0;
  overflow: hidden;
  padding: var(--space-24);
  position: relative;
  transform: translateZ(0);
}

.content {
  align-items: center;
  display: grid;
  grid-gap: var(--space-16);
  grid-template-columns: v-bind(gridStyle);
}

.qr-code-container {
  background-color: var(--background-0);
  display: grid;
  padding: var(--space-6);
}

.text-small {
  text-align: left !important;
}

.text-small a {
  display: block;
  margin: var(--space-8) 0 0;
}

button {
  all: unset;
  cursor: pointer;
  display: block;
  line-height: var(--space-12);
  position: absolute;
  right: var(--space-10);
  top: var(--space-10);
}

.slide-up-fade-leave-to {
  animation: slide-up-fade 0.5s forwards;
}

/* stylelint-disable rule-empty-line-before */
@keyframes slide-up-fade {
  from {
    max-height: 9999px; /* Height animation hack */
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}
/* stylelint-enable rule-empty-line-before */
</style>
