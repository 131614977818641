import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.tiktokApiUrl);
export const axiosCamelCase = createAxiosInstance(env.tiktokApiUrl, {
  camelizeKeys: true,
});

export function getTikTokAccount({ brandId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/brands/${brandId}/account`, axiosConfig);
}

export function getTrendingVideoList({ brandId, startDate, endDate, limit }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/top_videos`,
    merge(
      {
        params: {
          startDate,
          endDate,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export function getFollowersDemographics({ brandId, date }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/demographics`,
    merge(
      {
        params: {
          date,
        },
      },
      axiosConfig,
    ),
  );
}

export function getVideoViews(
  { brandId, videoId, startDate, endDate, timeScale },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/videos/${videoId}/views`,
    merge(
      {
        params: {
          startDate,
          endDate,
          timeScale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getTopKeywords(
  { brandId, includeKeywords, includeHashtags },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/ugc/top_keywords`,
    merge(
      {
        params: {
          includeKeywords,
          includeHashtags,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getAdAccounts(
  { brandId, fetchApi, connectTiktokAdsToken },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/marketing/brands/${brandId}/ad_accounts`,
    merge(
      {
        params: {
          fetchApi,
          connectTiktokAdsToken,
        },
      },
      axiosConfig,
    ),
  );
}

export async function updateAdAccount(
  { brandId, sourceAdvertiserId, isMonitored, connectTiktokAdsToken },
  axiosConfig = {},
) {
  return axiosCamelCase.patch(
    `/marketing/brands/${brandId}/ad_accounts/${sourceAdvertiserId}`,
    { isMonitored, connectTiktokAdsToken },
    axiosConfig,
  );
}

export async function getAdAccountsAllBrands({ brandIds }, axiosConfig = {}) {
  return axiosCamelCase.put(`/marketing/ad_accounts`, { brand_ids: brandIds }, axiosConfig);
}

export async function getAdCampaignsAllBrands(
  { brandIds, sourceAdAccountIds, sourceCampaignIds },
  axiosConfig = {},
) {
  const data = {
    brand_ids: brandIds,
    source_ad_account_ids: sourceAdAccountIds,
    source_campaign_ids: sourceCampaignIds,
  };
  return axiosCamelCase.put(`/marketing/ad_campaigns`, data, axiosConfig);
}

export async function getBrandTTCMAccounts({ brandId, fetchApi }, axiosConfig = {}) {
  return axiosCamelCase.get(
    `/creator_marketplace/brands/${brandId}/ttcm_accounts`,
    merge(
      {
        params: {
          fetchApi,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getTTCMAccountsForMultipleBrands({ brandIds }, axiosConfig = {}) {
  return axiosCamelCase.put(
    `/creator_marketplace/ttcm_accounts`,
    { brand_ids: brandIds },
    axiosConfig,
  );
}

export async function updateCreatorMarketplaceAccount(
  { brandId, sourceTtcmAccountId, isMonitored },
  axiosConfig = {},
) {
  return axiosCamelCase.patch(
    `/creator_marketplace/brands/${brandId}/ttcm_accounts/${sourceTtcmAccountId}`,
    { isMonitored },
    axiosConfig,
  );
}

export async function getCreatorByHandle({ handle }, axiosConfig = {}) {
  const url = `/creator_marketplace/creators?handle_name=${handle}`;
  return axiosCamelCase.get(url, axiosConfig);
}
export async function getTrendingHashtags(
  { categoryName, countryCode, dateRange },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `hashtags/trending`,
    merge(
      {
        params: {
          categoryName,
          countryCode,
          dateRange,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getHashtagDetails({ hashtagId, countryCode, dateRange }, axiosConfig = {}) {
  return axiosCamelCase.get(
    `hashtags/${hashtagId}/details`,
    merge(
      {
        params: {
          countryCode,
          dateRange,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getFeedPosts({ url, creatorId, limit }, axiosConfig = {}) {
  const apiUrl = url ?? `/creator_marketplace/creators/${creatorId}/feed`;
  return axiosCamelCase.get(
    apiUrl,
    merge(
      {
        params: {
          limit,
        },
      },
      axiosConfig,
    ),
  );
}
