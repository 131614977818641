/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vision-ai': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" _fill="#fff" fill-opacity=".3"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M2.712 16.847A10.458 10.458 0 007.5 18C13.3 18 18 13.299 18 7.5c0-1.725-.416-3.353-1.153-4.788A9.972 9.972 0 0120 10c0 5.523-4.477 10-10 10a9.972 9.972 0 01-7.288-3.153z" _fill="#fff"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M.05 11.002A17.915 17.915 0 0010 14c3.679 0 7.1-1.104 9.95-2.998C19.448 16.054 15.185 20 10 20S.552 16.054.05 11.002z" _fill="#fff" fill-opacity=".6"/>'
  }
})
