/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bad': {
    width: 12,
    height: 11,
    viewBox: '0 0 12 11',
    data: '<path pid="0" d="M5.074 11V8.853h2.418L0 1.391 1.674 0 9.01 7.335V4.917h2.147V11z" _fill="#adadad" fill-rule="evenodd"/>'
  }
})
