/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'addUser': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M8.002 12.006c-2.205 0-4-1.795-4-4s1.795-4 4-4 4 1.795 4 4-1.794 4-4 4zm0-6a2 2 0 10.001 4.001 2 2 0 00-.001-4.001zm8 14h-16v-1c0-3.533 3.289-6 8-6s8 2.467 8 6v1zm-13.841-2h11.684c-.599-1.808-2.834-3-5.842-3-3.008 0-5.244 1.192-5.842 3zm19.841-15v2h-3v3h-2v-3h-3v-2h3v-3h2v3h3z" id="svgicon_addUser_a"/></defs><g transform="translate(1 2)" _fill="none" fill-rule="evenodd"><mask id="svgicon_addUser_b" _fill="#fff"><use xlink:href="#svgicon_addUser_a"/></mask><g mask="url(#svgicon_addUser_b)" _fill="#ACB6BF" fill-rule="nonzero"><path pid="1" d="M-4-7h32v32H-4z"/></g></g>'
  }
})
