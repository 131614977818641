/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'image-view': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M6.5 8.168H2.333C1.413 8.168.667 7.42.667 6.5V2.334c0-.918.746-1.666 1.666-1.666H6.5c.92 0 1.667.748 1.667 1.666v4.167c0 .919-.747 1.667-1.667 1.667zM2.333 2.334v4.167h4.165L6.5 2.334H2.333zm13.334 5.834H11.5c-.92 0-1.667-.748-1.667-1.667V2.334c0-.918.747-1.666 1.667-1.666h4.167c.92 0 1.666.748 1.666 1.666v4.167c0 .919-.746 1.667-1.666 1.667zM11.5 2.334v4.167h4.164l.003-4.167H11.5zm-5 15H2.333c-.92 0-1.666-.747-1.666-1.666V11.5c0-.918.746-1.667 1.666-1.667H6.5c.92 0 1.667.749 1.667 1.667v4.167c0 .919-.747 1.666-1.667 1.666zm-4.167-5.833v4.167h4.165L6.5 11.5H2.333zm13.334 5.833H11.5c-.92 0-1.667-.747-1.667-1.666V11.5c0-.918.747-1.667 1.667-1.667h4.167c.92 0 1.666.749 1.666 1.667v4.167c0 .919-.746 1.666-1.666 1.666zM11.5 11.501v4.167h4.164l.003-4.167H11.5z" _fill="#666" fill-rule="nonzero"/>'
  }
})
