<script setup>
defineProps({
  itemList: {
    type: Array,
    default: () => [],
  },
  dropdownDisabled: {
    type: Boolean,
    default: false,
  },
  dropdownPlacement: {
    type: String,
    default: 'bottom',
  },
  itemContainerMaxHeight: {
    type: String,
    default: '14.75rem',
  },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <div class="element-container">
    <VDropdown
      :placement="dropdownPlacement"
      theme="menu"
      boundary="viewport"
      :disabled="dropdownDisabled"
    >
      <slot name="hoverableElement" />
      <template #popper>
        <div class="dropdown-items-container" :style="{ 'max-height': itemContainerMaxHeight }">
          <div
            v-for="item in itemList"
            :key="item.name"
            class="text-small-medium m-2 flex items-center"
            :class="{ 'item-header': item.header }"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<style scoped lang="postcss">
.element-container {
  width: 100%;
}

.dropdown-items-container {
  overflow-y: auto;
}

.item-header {
  cursor: auto;
  opacity: 0.6;
  pointer-events: none;
}
</style>
