/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'automation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 3H9.487v1.65a7.419 7.419 0 00-2.624 1.095L5.691 4.572 3.574 6.69l1.172 1.173c-.52.784-.9 1.67-1.096 2.625H2v2.994h1.65c.196.956.577 1.84 1.096 2.627l-1.172 1.174 2.118 2.115 1.171-1.174a7.425 7.425 0 002.625 1.097v1.65h2.995v-1.652a7.457 7.457 0 002.625-1.096l1.174 1.172 2.115-2.118-1.175-1.171a7.452 7.452 0 001.098-2.624h1.65V12h-5.993a2.996 2.996 0 01-5.99-.016A2.995 2.995 0 0111 8.989V3z" _fill="#666"/><path pid="1" d="M13 5.222a8.902 8.902 0 014.358 2.42L15 10h6V4l-2.229 2.229A10.881 10.881 0 0013 3.181v2.041z" _fill="#666"/>'
  }
})
