import {
  DEFAULT_PERCENTAGE_GRAPH_PRECISION,
  METRIC_FORMATS,
  QUERY_TIMEFRAME,
} from './metrics.constants';

export const META_ADS_METRICS = Object.freeze({
  TOTAL_SPENT: {
    value: 'TOTAL_SPENT',
    text: 'Amount Spent - Ads',
    formats: {
      NORMAL: METRIC_FORMATS.MONETARY_NORMAL,
      LONG: METRIC_FORMATS.MONETARY_LONG,
    },
    currency: true,
    tooltip:
      "The estimated total amount of money you've spent on your selected ad campaign during the Reporting Period.",
    graphStats: [],
  },
  CONVERSION_RATE: {
    value: 'CONVERSION_RATE',
    text: 'Conversion Rate - Ads',
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    estimated: true,
    tooltip:
      'The total percentage of ad conversions achieved per click on your selected ad campaign during the Reporting Period.',
    graphStats: [],
  },
  COST_PER_CLICK: {
    value: 'COST_PER_CLICK',
    text: 'CPC - Ads',
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
    metricIncreaseInRed: true,
    formats: {
      NORMAL: METRIC_FORMATS.MONETARY_NORMAL,
      LONG: METRIC_FORMATS.MONETARY_LONG,
    },
    currency: true,
    tooltip:
      'The average cost per click on your social ads during the Reporting Period. CPC = Spend / Total Clicks',
    graphStats: [],
  },
  COST_PER_MILE: {
    value: 'COST_PER_MILE',
    text: 'CPM - Ads',
    metricIncreaseInRed: true,
    formats: {
      NORMAL: METRIC_FORMATS.MONETARY_NORMAL,
      LONG: METRIC_FORMATS.MONETARY_LONG,
    },
    currency: true,
    tooltip:
      'The average cost per 1,000 impressions on your selected ad campaign during the Reporting Period. CPM = Total Spend / Impressions',
    graphStats: [],
  },
  CLICK_THROUGH_RATE: {
    value: 'CLICK_THROUGH_RATE',
    text: 'CTR - Ads',
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    tooltip:
      'The percentage of times people clicked your ads after seeing them during the Reporting Period. CTR = Total Clicks / Impressions',
    graphStats: [],
  },
  ENGAGEMENT_RATE: {
    value: 'ENGAGEMENT_RATE',
    text: 'Engagement Rate - Ads',
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    tooltip:
      'The total percentage of users who engaged with your selected campaign after seeing an ad during the Reporting Period. Engagement Rate = Total Engagements / Impressions',
    graphStats: [],
  },
  PAGE_ENGAGEMENTS: {
    value: 'PAGE_ENGAGEMENTS',
    text: 'Page Engagements - Ads',
    tooltip:
      'The total number of engagements your page and its posts received from your ads during the Reporting Period.',
    graphStats: [],
  },
  ENGAGEMENTS: {
    value: 'ENGAGEMENTS',
    text: 'Engagements - Ads',
    tooltip:
      'The total number of engagements on all ads within your selected campaign during the Reporting Period.',
    graphStats: [],
  },
  FREQUENCY: {
    value: 'FREQUENCY',
    text: 'Frequency - Ads',
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
    estimated: true,
    tooltip:
      'The average number of times users saw ads in your campaign during the Reporting Period.',
    graphStats: [],
  },
  IMPRESSIONS: {
    value: 'IMPRESSIONS',
    text: 'Impressions - Ads',
    tooltip:
      'The total number of impressions the ads in your selected campaign received during the Reporting Period.',
    graphStats: [],
  },
  LINK_CLICKS: {
    value: 'LINK_CLICKS',
    text: 'Link Clicks - Ads',
    tooltip:
      'The total number of times links on the ads in your selected campaign were clicked during the Reporting Period.',
    graphStats: [],
  },
  REACH: {
    value: 'REACH',
    text: 'Reach - Ads',
    estimated: true,
    tooltip:
      'The total number of unique accounts that saw ads within your selected campaign during the Reporting Period.',
    graphStats: [],
  },
  RETURN_ON_AD_SPEND: {
    value: 'RETURN_ON_AD_SPEND',
    text: 'ROAS - Ads',
    estimated: true,
    formats: {
      NORMAL: METRIC_FORMATS.MONETARY_NORMAL,
      LONG: METRIC_FORMATS.MONETARY_LONG,
    },
    currency: true,
    tooltip:
      'The total return on ad spend for your selected campaign, as provided by Meta. ROAS = Calculated Purchase Conversion Value / Amount Spent',
    graphStats: [],
  },
  VIDEO_VIEWS: {
    value: 'VIDEO_VIEWS',
    text: 'Video Views - Ads',
    tooltip:
      'The total number of times videos in your selected ad campaign were viewed for over 3 seconds during the Reporting Period.',
    graphStats: [],
  },
  WEB_CONVERSIONS: {
    value: 'WEB_CONVERSIONS',
    text: 'Web Conversions - Ads',
    tooltip:
      'The estimated number of conversions your selected ad campaign received during the Reporting Period, as measured by the Meta Pixel installed on your website.',
    estimated: true,
    graphStats: [],
  },
});
