<template>
  <section class="aspect-ratio-selector-container" data-cy="aspect-ratio-selector-container">
    <template v-if="enableFreeCrop">
      <AspectRatioButton
        :disabled="disabled"
        :media="media"
        ratio-type="freeCrop"
        title="Free Crop"
        description="Custom Crop Size"
        data-cy="free-crop-button"
      />
    </template>

    <AspectRatioButton
      :disabled="disabled"
      :media="media"
      ratio-type="original"
      title="Original"
      description="Original Media Size"
    />
    <div class="dh-line" />
    <div v-if="!customCropperPresets" class="dh-header">Social Platform Crop Sizes</div>
    <AspectRatioButton
      v-for="(cropSize, ratioType) in cropperPresets"
      :key="ratioType"
      :disabled="disabled"
      :ratio-type="ratioType"
      :media="media"
      :title="cropSize.name"
      :description="cropSize.ratio"
      :ratio="cropSize.ratio"
    />
    <Banner
      v-if="isProfileGrid"
      hide-default-icon
      alert-type="information"
      class="aspect-ratio-banner mb-8"
    >
      Cropping your cover image for the profile grid will affect how it appears in the Reels tab.
    </Banner>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useCropperStore } from '@/stores/cropper';
import { imageCropperPresets, videoCropperPresets } from '@/config';
import AspectRatioButton from '@/components/MediaCropping/AspectRatioButton.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AspectRatioSelector',
  components: {
    AspectRatioButton,
    Banner,
  },
  props: {
    disabled: { type: Boolean, default: false },
    media: { type: [Object, String], required: true },
    enableFreeCrop: { type: Boolean, default: true },
    customCropperPresets: { type: Object, default: null },
    postType: { type: String, default: '' },
  },
  data() {
    return {
      isProfileGrid: false,
    };
  },
  computed: {
    ...mapStores(useCropperStore),
    cropperPresets() {
      if (this.customCropperPresets) {
        return this.customCropperPresets;
      }

      return this.platformCropperPresets;
    },
    platformCropperPresets() {
      return this.media?.type === 'VIDEO' ? videoCropperPresets : imageCropperPresets;
    },
  },
  watch: {
    'cropperStore.cropperRatio': {
      immediate: true,
      handler(newRatio) {
        if (newRatio) {
          this.isProfileGrid = newRatio.name === 'Profile Grid';
        }
      },
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.aspect-ratio-selector-container {
  background: var(--background-300);
  width: 300px;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

.dh-line {
  box-sizing: border-box;
  height: 1px;
  width: 250px;
  border: 0.5px solid var(--border);
  margin: var(--space-16) auto;
}

.dh-header {
  color: var(--text-primary);
  font-size: var(--x12);
  font-weight: var(--font-medium);
  line-height: var(--x15);
  margin: var(--space-16) auto;
  width: 250px;
}

.aspect-ratio-banner {
  background-color: #fff6e7 !important;
  margin-left: var(--space-16);
  margin-right: var(--space-16);
}
</style>
