import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.pinterestApiUrl);

export function getPinterestAccounts({ brandIds }, axiosConfig = {}) {
  return axios.get(
    `/brands/accounts`,
    merge(
      {
        params: {
          brandIds: brandIds?.join(','),
        },
      },
      axiosConfig,
    ),
  );
}

export function getBoards({ brandId }, axiosConfig = {}) {
  return axios.get(`/v5/brands/${brandId}/boards`, axiosConfig);
}

export function createBoard({ brandId, name }, axiosConfig = {}) {
  return axios.post(`/v5/brands/${brandId}/boards`, { name }, axiosConfig);
}

export function getPinterestAccount({ brandId }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/account`,
    merge(
      {
        camelizeKeys: true,
      },
      axiosConfig,
    ),
  );
}

export async function getMetrics({ brandId, startDate, endDate }, axiosConfig = {}) {
  return axios.get(
    `/v5/brands/${brandId}/metrics/account`,
    merge(
      {
        params: {
          startDate,
          endDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getPins(
  { brandId, startDate, endDate, sort, limit, privacy, has7dInsights, pinType, mediaType, offset },
  axiosConfig = {},
) {
  return axios.get(
    `/v5/brands/${brandId}/pins`,
    merge(
      {
        params: {
          startDate,
          endDate,
          sort,
          limit,
          privacy,
          has7dInsights,
          pinType,
          mediaType,
          offset,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getTopPins({ brandId, startDate, endDate, sort, limit }, axiosConfig = {}) {
  return axios.get(
    `/v5/brands/${brandId}/metrics/top_pins`,
    merge(
      {
        params: { startDate, endDate, sort, limit },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowersExport(
  { brandId, startDate, endDate, type, filename, includeAggregate, scale },
  axiosConfig = {},
) {
  return axios.get(
    `/v5/brands/${brandId}/exports/metrics/followers`,
    merge(
      {
        params: {
          startDate,
          endDate,
          type,
          filename,
          includeAggregate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getAnalyticsPinMetricsExport(
  { brandId, startDate, endDate, filename, scale },
  axiosConfig = {},
) {
  return axios.get(
    `/v5/brands/${brandId}/exports/metrics/account`,
    merge(
      {
        params: {
          startDate,
          endDate,
          filename,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getAnalyticsTopPinsExport(
  { brandId, startDate, endDate, filename, sort },
  axiosConfig = {},
) {
  return axios.get(
    `/v5/brands/${brandId}/exports/metrics/top_pins`,
    merge(
      {
        params: {
          startDate,
          endDate,
          filename,
          sort,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getYoursPinsExport(
  { brandId, startDate, endDate, mediaType, privacy },
  axiosConfig = {},
) {
  return axios.get(
    `/v5/brands/${brandId}/exports/your_pins`,
    merge(
      {
        params: {
          startDate,
          endDate,
          mediaType,
          privacy,
        },
      },
      axiosConfig,
    ),
  );
}
