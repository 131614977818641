<template>
  <div
    class="add-to-container"
    @mouseover="showClearGalleries = true"
    @mouseleave="showClearGalleries = false"
  >
    <div class="container-main-part">
      <a
        :class="{ 'color-after-select': selectedGalleries.length !== 0 }"
        @click.prevent="addToGalleryClicked"
      >
        <div class="icon">
          <Icon name="folderAdd" />
        </div>
        <p
          v-line-clamp="2"
          class="display-label add-to-dropdown-button"
          data-cy="add-to-board-dropdown-button"
        >
          {{ displayLabel }}
        </p>
      </a>
      <Icon
        v-if="showClearGalleries && selectedGalleries.length > 0"
        name="close"
        xsmall
        class="clear-buttons"
        @click="clearGalleries"
      />
    </div>
    <AddToDropdown
      ref="addToDropdown"
      :select-only="true"
      :on-galleries-selected="galleriesSelected"
      :selected-galleries="selectedGalleries"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import AddToDropdown from '@/components/AddToDropdown.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'AfterPostGallerySelector',
  components: {
    AddToDropdown,
    Icon,
  },
  props: {
    postType: { type: String, default: 'PINTEREST' },
    value: { type: Array, default: () => [] },
  },
  emits: ['input'],
  data() {
    return {
      showClearGalleries: false,
      selectedGalleries: this.value,
      showAfterPostAddToPopup: false,
    };
  },
  computed: {
    displayLabel() {
      if (this.selectedGalleries.length) {
        if (this.selectedGalleries.length >= 3) {
          return `${this.selectedGalleries[0].name} and ${
            this.selectedGalleries.length - 1
          } others.`;
        }
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return 'After published, add to...';
    },
  },
  watch: {
    value(to) {
      this.selectedGalleries = to;
    },
  },
  methods: {
    clearGalleries() {
      this.selectedGalleries = [];
    },
    addToGalleryClicked() {
      this.$refs.addToDropdown.openAddToDropdown();
    },
    galleriesSelected(galleries) {
      this.selectedGalleries = galleries;
      this.$emit('input', galleries);
    },
    closePopup() {
      this.showAfterPostAddToPopup = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.add-to-container {
  padding: var(--space-4) 0;

  .container-main-part {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    position: relative;
    margin-right: var(--space-8);

    .icon {
      width: var(--space-32);
      height: var(--space-32);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: var(--x13);
    }

    svg {
      margin-right: var(--space-8);
    }
  }

  .color-after-select {
    p.display-label {
      color: var(--action-500);
    }
  }
}
</style>
