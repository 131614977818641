/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'table': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><clipPath id="svgicon_table_a"><path pid="0" d="M188 0v154H0V0h188z"/></clipPath><clipPath id="svgicon_table_b"><path pid="1" d="M19 0a1 1 0 011 1v18a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zM9 11H2v7h7v-7zm9 0h-7v7h7v-7zm0-9h-7v7h7V2zM9 2H2v7h7V2z"/></clipPath></defs><g clip-path="url(#svgicon_table_a)" transform="translate(-80 -84)"><g clip-path="url(#svgicon_table_b)" transform="translate(82 86)"><path pid="2" _fill="#686a7a" d="M0 0h20v20H0V0z"/></g></g>'
  }
})
