import { toolTips } from '@/config';
import { GRAPH_STATS } from '@/models/dashboards/graph-stats.enum';
import {
  DEFAULT_PERCENTAGE_GRAPH_PRECISION,
  METRIC_FORMATS,
  QUERY_TIMEFRAME,
} from '@/models/dashboards/metrics.constants';

import { MEDIA_TYPES } from './media-types.enum';

export const FACEBOOK_COMPETITIVE_METRICS = Object.freeze({
  TOTAL_FOLLOWERS: {
    value: 'TOTAL_FOLLOWERS',
    text: 'Page Fans - Total',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    graphStats: [GRAPH_STATS.CURRENT.value, GRAPH_STATS.MEAN_BY_DAY.value],
    chart: {
      beginAtZero: false,
      min: 0,
    },
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
  },
  NET_NEW_FOLLOWERS: {
    value: 'NET_NEW_FOLLOWERS',
    text: 'Page Fans - Net New',
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
  },
  FOLLOWER_GROWTH_RATE: {
    value: 'FOLLOWER_GROWTH_RATE',
    timeframe: QUERY_TIMEFRAME.ACTIVITY_DURING_TIMEFRAME,
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl_WITH_COMMA,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG_WITH_COMMA,
    },
    text: 'Page Fans - Growth Rate',
    graphStats: [GRAPH_STATS.MEAN_BY_DAY.value],
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
  },
  NUMBER_OF_POSTS: {
    value: 'NUMBER_OF_POSTS',
    text: 'Number of Posts',
    allowContentTags: true,
    formats: { NORMAL: METRIC_FORMATS.INTEGER, LONG: METRIC_FORMATS.INTEGER },
    mediaSubtypes: {
      ALL_MEDIA: {
        metric: 'NUMBER_OF_POSTS',
        ...MEDIA_TYPES.ALL_MEDIA,
      },
      VIDEO: {
        metric: 'NUMBER_OF_POSTS_VIDEO',
        ...MEDIA_TYPES.VIDEO,
      },
      PHOTO: {
        metric: 'NUMBER_OF_POSTS_PHOTO',
        ...MEDIA_TYPES.PHOTO,
      },
      CAROUSEL: {
        metric: 'NUMBER_OF_POSTS_CAROUSEL',
        ...MEDIA_TYPES.CAROUSEL,
      },
      STATUS: {
        ...MEDIA_TYPES.STATUS,
        metric: 'NUMBER_OF_POSTS_STATUS',
      },
      LINK: {
        metric: 'NUMBER_OF_POSTS_LINK',
        ...MEDIA_TYPES.LINK,
      },
      PHOTO_CAROUSEL: {
        metric: 'NUMBER_OF_POSTS_PHOTO_CAROUSEL',
        ...MEDIA_TYPES.PHOTO_CAROUSEL,
      },
    },
  },
  AVG_ENGAGEMENT_RATE_PUBLIC: {
    value: 'AVG_ENGAGEMENT_RATE_PUBLIC',
    text: 'Avg. Engagement Rate - Competitive',
    allowContentTags: true,
    formats: {
      NORMAL: METRIC_FORMATS.PERCENT_FLOAT_NORMAl,
      LONG: METRIC_FORMATS.PERCENT_FLOAT_LONG,
    },
    tooltip: toolTips.facebook.averageEngagementRatePublic,
    graphStats: [],
    chart: {
      precision: DEFAULT_PERCENTAGE_GRAPH_PRECISION,
    },
  },
  COMMENTS: {
    value: 'COMMENTS',
    text: 'Comments',
    allowContentTags: true,
  },
  ENGAGEMENT_RATE_PUBLIC: {
    value: 'ENGAGEMENT_RATE_PUBLIC',
    text: 'Engagement Rate',
    allowContentTags: true,
  },
  SHARES: {
    value: 'SHARES',
    text: 'Shares',
    allowContentTags: true,
  },
  REACTIONS: {
    value: 'REACTIONS',
    text: 'Reactions',
    allowContentTags: true,
  },
});
