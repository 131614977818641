import { Mark } from '@tiptap/core';

const TwitterMention = Mark.create({
  name: 'twitterMention',
  addAttributes() {
    return {
      handle: null,
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span.mention',
        getAttrs: (dom) => ({
          handle: dom.getAttribute('data-mention-handle'),
        }),
      },
    ];
  },
  renderHTML() {
    return ['span', { class: 'mention' }, 0];
  },
});

export default TwitterMention;
