import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';
import merge from 'lodash/merge';
import { SUMMARY_INTENT_TYPES } from '@/components/VisionAi/constants';

export const camelCaseAxios = createAxiosInstance(env.visionAiApiUrl, {
  camelizeKeys: true,
});
/**
 * NOTE: We only use this as the dashboard additional content response requires its
 * keys to remain unchanged. It is preferred to use the camelCaseAxios if given the
 * option
 */
export const axios = createAxiosInstance(env.visionAiApiUrl);

export async function getMessages(
  { startTime, endTime, limit, offset, intentTypes },
  axiosConfig = {},
) {
  return axios.get(
    '/messages',
    merge(
      {
        params: {
          startTime,
          endTime,
          limit,
          offset,
          intentTypes,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getMessageById(id, { includeDeleted }, axiosConfig = {}) {
  return axios.get(
    `/messages/${id}`,
    merge(
      {
        params: {
          includeDeleted,
        },
      },
      axiosConfig,
    ),
  );
}

export async function clearMessages(axiosConfig = {}) {
  return camelCaseAxios.delete('/messages', axiosConfig);
}

export async function updateMessage({ messageId, regeneratedId }, axiosConfig = {}) {
  return camelCaseAxios.patch(
    `/messages/${messageId}`,
    {
      regeneratedId,
    },
    axiosConfig,
  );
}

export async function createMessage(
  { conversationId, sender, message, intentType, intentQuery, actionType },
  axiosConfig = {},
) {
  const params = {
    sender,
    message,
    intentType,
    ...(intentQuery && { intentQuery }),
    ...(actionType && { actionType }),
  };
  return camelCaseAxios.post(`conversations/${conversationId}/messages`, params, axiosConfig);
}

export async function regenerateMessage({ conversationId, messageId }, axiosConfig = {}) {
  return camelCaseAxios.post(
    `conversations/${conversationId}/messages/${messageId}/regenerate`,
    axiosConfig,
  );
}

export async function createConversation(axiosConfig = {}) {
  return camelCaseAxios.post(`/conversations`, axiosConfig);
}

export async function getConversations(
  { firstMessageStartTime, firstMessageEndTime, intentTypes },
  axiosConfig = {},
) {
  return camelCaseAxios.get(
    `/conversations`,
    merge(
      {
        params: {
          firstMessageStartTime,
          firstMessageEndTime,
          intentTypes,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getConversationMessages({ conversationId }, axiosConfig = {}) {
  return axios.get(`conversations/${conversationId}/messages`, axiosConfig);
}

export async function updateConversation({ conversationId, name }, axiosConfig = {}) {
  return camelCaseAxios.patch(`conversations/${conversationId}`, { name }, axiosConfig);
}

export async function deleteConversation({ conversationId }, axiosConfig = {}) {
  return camelCaseAxios.delete(`conversations/${conversationId}`, axiosConfig);
}

export async function deleteSummaryMessages(axiosConfig = {}) {
  const summaryIntentTypes = SUMMARY_INTENT_TYPES.join(',');
  return camelCaseAxios.delete(
    `messages`,
    {
      params: {
        intentTypes: summaryIntentTypes,
      },
    },
    axiosConfig,
  );
}
