import humps from 'humps';
import { VISION_AI_SENDER } from '@/config';
import { INTENT_TYPES, SKIP_CAMELIZE_CONTENT_INTENT_TYPES } from '@/components/VisionAi/constants';
import { useDashboardsVisionAi } from '@/app/dashboards/composables/useDashboardsVisionAi';
import { useListeningVisionAi } from '@/app/socialListening/composables/useListeningVisionAi';
import { useCommunityVisionAi } from '@/app/community/composables/useCommunityVisionAi';
import dayjs from 'dayjs';
import { getUserTimezone } from '@/utils/timezone';
import { convertDateToUtcDateTimeByZoneISOString } from '@/utils';
import { day } from '@/utils/vision';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';

const MESSAGE_DAYS_AGO = 30;

export const INTENT_ROUTE_MAPPING = {
  [INTENT_TYPES.SAMPLE]: (intentQuery) => {
    const baseUrl = '/library/discover';

    if (!intentQuery) return baseUrl;
    // Construct query string from intentQuery object
    const queryParams = new URLSearchParams(humps.decamelizeKeys(intentQuery));
    return queryParams ? `${baseUrl}?${queryParams.toString()}` : baseUrl;
  },
  [INTENT_TYPES.DMS]: (intentQuery) => {
    const baseUrl = '/community/all-messages';
    const { getDMUrlFromIntentQuery } = useCommunityVisionAi();
    const { brandLabel, id } = intentQuery?.sourceLinkParams || {};

    return brandLabel && id ? getDMUrlFromIntentQuery(intentQuery) : baseUrl;
  },
  [INTENT_TYPES.COMMENTS]: (intentQuery) => {
    const baseUrl = '/community/all-messages';
    const { getCommentUrlFromIntentQuery } = useCommunityVisionAi();
    const { brandLabel, id, platform } = intentQuery?.sourceLinkParams || {};

    return brandLabel && id && platform ? getCommentUrlFromIntentQuery(intentQuery) : baseUrl;
  },
  [INTENT_TYPES.TOPICS]: (intentQuery) => {
    const baseUrl = '/listening/topics/';
    const { getTopicUrlFromIntentQuery } = useListeningVisionAi();
    const { topicId, brandLabel } = intentQuery?.sourceLinkParams || {};
    return topicId && brandLabel ? getTopicUrlFromIntentQuery(intentQuery) : baseUrl;
  },
  [INTENT_TYPES.TRENDS]: (intentQuery) => {
    const baseUrl = '/listening/topics/';
    const { getTrendsUrlFromIntentQuery } = useListeningVisionAi();
    const { brandLabel } = intentQuery?.sourceLinkParams || {};
    return brandLabel ? getTrendsUrlFromIntentQuery(intentQuery) : baseUrl;
  },
  [INTENT_TYPES.DASHBOARD_REPORTS]: (intentQuery) => {
    const baseUrl = '/dashboards';

    const { dashboardId } = intentQuery;
    const { getDashboardUrlFromIntentQuery } = useDashboardsVisionAi();
    return dashboardId ? getDashboardUrlFromIntentQuery(intentQuery) : baseUrl;
  },
  // Add more intent types and their custom URL construction functions as needed
};

export function enrichMessages(messages) {
  return messages
    .map((message) => {
      if (message.sender === VISION_AI_SENDER.USER) return message;
      const sourceUrlConstructor = INTENT_ROUTE_MAPPING[message.intentType];
      return {
        ...message,
        sourceUrl: sourceUrlConstructor ? sourceUrlConstructor(message.intentQuery) : null,
      };
    })
    .reverse();
}

/**
 * NOTE: With dashboards the underlying logic in the report components rely on the keys of the
 * params to be in snake case. This workaround enables the dashboard reports additional content
 * to reuse existing dashboard components such as the report tiles
 */
export function skipCamelizeMessageKeysOnDashboardReportsAdditionalContent(messages) {
  if (!messages) return [];

  return messages.map((message) => {
    if (!SKIP_CAMELIZE_CONTENT_INTENT_TYPES.includes(message.intent_type)) {
      return humps.camelizeKeys(message);
    }

    const additionalContent = message.additional_content;
    delete message.additional_content;
    return { ...humps.camelizeKeys(message), additionalContent };
  });
}

export function processMessages(messages) {
  // combines the above utils into one convenient method
  const casedMessages = skipCamelizeMessageKeysOnDashboardReportsAdditionalContent(messages);
  const enrichedMessages = enrichMessages(casedMessages);
  return enrichedMessages;
}

export function getStartTimeForApiRequest(startTime) {
  let messagesStartTime = startTime;
  if (!messagesStartTime) {
    const timezone = getUserTimezone();
    const startDate = dayjs().tz(timezone).subtract(MESSAGE_DAYS_AGO, 'days');
    messagesStartTime = convertDateToUtcDateTimeByZoneISOString(startDate, timezone);
  }

  return messagesStartTime;
}

export function getEndTimeForApiRequest(endTime) {
  let messagesEndTime = endTime;
  if (!messagesEndTime) {
    const timezone = getUserTimezone();
    const endDate = dayjs().tz(timezone);
    messagesEndTime = convertDateToUtcDateTimeByZoneISOString(endDate, timezone);
  }

  return messagesEndTime;
}

export function formatConversationDate(date) {
  const formatType = 'YYYY-MM-DD';
  const currDate = dayjs().format(formatType);
  const yesterdaysDate = dayjs().subtract(1, 'day').format(formatType);
  const lastSevenDaysDate = dayjs().subtract(7, 'day').format(formatType);
  const last30DaysDate = dayjs().subtract(30, 'day').format(formatType);
  let formattedDate = dayjs(new Date(date)).format(formatType);

  if (formattedDate === currDate) {
    formattedDate = day.today;
  }
  if (formattedDate === yesterdaysDate) {
    formattedDate = day.yesterday;
  }
  if (dayjs(formattedDate).isBetween(lastSevenDaysDate, yesterdaysDate, 'day', '[)')) {
    formattedDate = day.lastSevenDays;
  }
  if (dayjs(formattedDate).isBetween(last30DaysDate, lastSevenDaysDate, 'day', '[)')) {
    formattedDate = day.last30Days;
  }
  return formattedDate;
}

export function groupConversationsByDate(visionAiData) {
  const conversationsByDate = visionAiData.map((item) => {
    const date = formatConversationDate(item.createdAt);
    return {
      ...item,
      date,
    };
  });

  const GroupedData = Object.entries(groupBy(conversationsByDate, 'date')).map(
    ([date, conversations]) => {
      return {
        date,
        conversations: conversations.reverse(),
      };
    },
  );

  return GroupedData;
}

export function orderByDate(visionAiData, groupedByName, order = ['asc']) {
  return orderBy(visionAiData, [(group) => new Date(group[groupedByName][0].createdAt)], order);
}
