import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import dayjs from 'dayjs';
import { isDatadogSyntheticsBrowser } from '@/config';
import { loadScript } from '@/utils/dom';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import { env } from '@/env';

export const useSurvicateStore = defineStore('survicate', () => {
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  const ready = ref(false);
  const initCalled = ref(false);
  const initializing = ref(false);

  const workspaceKey = env.survicateWorkspaceKey;
  const enabled = env.survicateEnabled && !isDatadogSyntheticsBrowser;

  function init() {
    initCalled.value = true;
    if (!window.Cypress && enabled && flagStore.flags.surveySurvicate && !ready.value) {
      initializing.value = true;
      loadScript(`https://survey.survicate.com/workspaces/${workspaceKey}/web_surveys.js`, {
        id: 'survicate-widget',
        onload: () => {
          ready.value = true;
        },
      });
    }
  }

  watch(
    () => flagStore.flags.surveySurvicate,
    (flagValue) => {
      if (flagValue && initCalled.value && !initializing.value) {
        init();
      }
    },
  );

  watch(
    () => {
      return {
        identity: identityStore.identity,
        currentBrand: identityStore.currentBrand,
        ready: ready.value,
      };
    },
    (value) => {
      if (value.ready && value.identity) {
        const identity = value.identity;
        let daysAsCustomer = -1;
        if (identity?.created_at) {
          const userCreatedAt = dayjs(identity.created_at);
          daysAsCustomer = dayjs().diff(userCreatedAt, 'd');
        }

        const plan = value.currentBrand?.plan;
        let currentBrandDaysAsCustomer = -1;
        if (plan?.created_at) {
          const planCreatedAt = dayjs(plan.created_at);
          currentBrandDaysAsCustomer = dayjs().diff(planCreatedAt, 'd');
        }

        window._sva = window._sva ?? {};
        window._sva.traits = {
          email: identity.email,
          firstName: identity.first_name,
          lastName: identity.last_name,
          jobTitle: identity.job_title,
          daysAsCustomer,
          organizationName: identity.organization?.name,
          organizationCustomerStage: identity.organization?.customer_stage,
          currentBrandName: value.currentBrand.name,
          currentBrandDaysAsCustomer,
          currentBrandPlanType: value.currentBrand?.plan?.plan_type,
          currentBrandPlanCustomerStage: plan?.customer_stage,
        };
      }
    },
    {
      immediate: true,
    },
  );

  return {
    init,
    ready,
  };
});
