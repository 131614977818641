/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shrinkVertical': {
    width: 24,
    height: 24.001,
    viewBox: '0 0 24 24.001',
    data: '<g _fill="#adadad"><path pid="0" d="M12 7.586L6.707 2.293 5.293 3.707 12 10.414l6.707-6.707-1.414-1.414zM5.293 20.293l1.414 1.414L12 16.414l5.293 5.293 1.414-1.414L12 13.586z"/></g>'
  }
})
