<template>
  <SubScreen
    :title="productTaggingViewOnly ? 'Product Tags' : shoppingTagsSubScreenTitle"
    @on-back="handleBack"
  >
    <template #content>
      <SearchSelect
        v-if="isReadyToTag && !productTaggingViewOnly"
        ref="searchSelect"
        v-model="searchTerm"
        class="product-search-select"
        :options="shoppingTagProductOptions"
        max-dropdown-height="33rem"
        option-text="title"
        placeholder="Search product name"
        return-object
        round
        :loading="pending.searchProducts"
        :disabled="disableSearchSelect"
        :disabled-tooltip="disabledTooltip"
        @option-click="handleOptionClick"
        @input="handleInput"
        @blur="clearShoppingTagProducts"
        @focus="handleInputFocused"
        @infinite-scroll-down="handleInfinite"
      >
        <template #option="{ option }">
          <ProductListItem :product="option" />
        </template>

        <template #noResults>
          <p class="empty-search-message">No products found</p>
        </template>
      </SearchSelect>

      <div v-if="activeMediaSavedShoppingTags.length > 0" class="shopping-tags-container">
        <List
          class="search-list"
          :items="activeMediaSavedShoppingTags"
          :alternate-item-color="false"
          option-key="fbProductId"
        >
          <template #main-content="{ item: productItem, index }">
            <ProductListItem
              :product="productItem"
              @delete-tag="instagramShoppingTaggerStore.deleteShoppingTag({ index })"
            />
          </template>
        </List>
      </div>

      <CircularLoader v-if="isPending" />

      <InstagramShoppingTagsErrorState
        v-else-if="showErrorState"
        :show-reconnect-message="showReconnectMessage"
      />

      <div
        v-else-if="
          isReadyToTag && instagramShoppingTaggerStore.activeMediaTaggedProducts.length === 0
        "
      >
        <p class="empty-state-message">{{ shoppingTagsSubScreenEmptyStateMessage }}</p>
      </div>
    </template>
  </SubScreen>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import SearchSelect from '@/components/foundation/form/SearchSelect.vue';
import ProductListItem from '@/components/core/products/ProductListItem.vue';
import { debounceInputDelayShort } from '@/config';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import {
  eligibilityCallFailureNotificationMessage,
  eligibilityCallFailureNotificationSubtext,
  shoppingTagsSubScreenEmptyStateMessage,
  shoppingTagsSubScreenTitle,
  maxShoppingTagsPerMedia,
  maxShoppingTagsPerPost,
  maxTagsPerMedia,
  maxTagsPerPost,
  tooManyShoppingTagsPerMediaMessage,
  tooManyShoppingTagsPerPostMessage,
  tooManyTagsPerMediaMessage,
  tooManyTagsPerPostMessage,
  notEligibleMessageContent,
  notEligibleMessageTitle,
  instagramCommerceEligibilityRequirementsLink,
} from '@/app/scheduler/constants';
import { FacebookAPI } from '@/apis';
import { collateData } from '@/apis/facebook';
import List from '@/components/foundation/List.vue';
import { fbGraphApiErrors, productReviewStatusTypes } from '@/app/library/constants';
import InstagramShoppingTagsErrorState from '@/app/scheduler/components/EditPost/InstagramShoppingTagsErrorState.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'InstagramShoppingTags',
  components: {
    SubScreen,
    ProductListItem,
    SearchSelect,
    List,
    InstagramShoppingTagsErrorState,
    CircularLoader,
  },
  props: {
    onBack: { type: Function, required: true },
    onShoppingTagsChanged: { type: Function, required: true },
    savedTags: { type: Array, default: null },
    productTaggingViewOnly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      searchTerm: null,
      shoppingTagsSubScreenEmptyStateMessage,
      shoppingTagsSubScreenTitle,
      shoppingTagProducts: [],
      after: null,
      pending: {
        searchProducts: false,
      },
      showReconnectMessage: false,
      showErrorState: false,
      notEligibleMessageContent,
      notEligibleMessageTitle,
      instagramCommerceEligibilityRequirementsLink,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useInstagramShoppingTaggerStore, useAuthStore),
    isReadyToTag() {
      return (
        this.instagramShoppingTaggerStore.shoppingTagsEligible &&
        this.instagramShoppingTaggerStore.catalogs.length > 0
      );
    },
    isPending() {
      return (
        !!this.instagramShoppingTaggerStore.pending.checkShoppingTagsEligible ||
        !!this.instagramShoppingTaggerStore.pending.getInstagramProductTagsCatalogs
      );
    },
    activeMediaSavedShoppingTags() {
      // If one of the saved tags is now returning from Facebook with an error,
      // default to the tag's title and image saved in the Scheduled Post
      return this.instagramShoppingTaggerStore.activeMediaTaggedProducts
        .filter((tag) => tag.fbProductId !== null)
        .map((tag) => {
          return tag.error
            ? { ...tag, ...this.savedTags.find((t) => t.fbProductId === tag.fbProductId) }
            : tag;
        });
    },
    shoppingTagProductOptions() {
      return this.shoppingTagProducts.map((product) => {
        const disabled =
          this.instagramShoppingTaggerStore.activeMediaTaggedProducts.findIndex(
            (tag) => tag.fbProductId === product.fbProductId,
          ) !== -1 || product.reviewStatus?.toLowerCase() === productReviewStatusTypes.REJECTED;

        return {
          ...product,
          disabled,
        };
      });
    },
    disabledTooltip() {
      if (this.instagramShoppingTaggerStore.editingShoppingTag) {
        return null;
      }
      if (this.instagramShoppingTaggerStore.taggedProducts.length >= maxShoppingTagsPerPost) {
        return tooManyShoppingTagsPerPostMessage;
      }
      if (this.instagramShoppingTaggerStore.totalMediaTagsAndProductTags >= maxTagsPerPost) {
        return tooManyTagsPerPostMessage;
      }
      if (
        this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length >=
        maxShoppingTagsPerMedia
      ) {
        return tooManyShoppingTagsPerMediaMessage;
      }
      if (this.instagramShoppingTaggerStore.totalActiveMediaTags >= maxTagsPerMedia) {
        return tooManyTagsPerMediaMessage;
      }
      return null;
    },
    disableSearchSelect() {
      return this.disabledTooltip != null;
    },
  },
  watch: {
    'instagramShoppingTaggerStore.editingShoppingTag': {
      handler(to) {
        if (to) {
          this.$refs.searchSelect.$refs.textInput.$refs.textInput.focus();
        }
      },
    },
  },
  async created() {
    if (!this.instagramShoppingTaggerStore.shoppingTagsEligible) {
      await this.fetchEligibility();
    }

    if (
      this.instagramShoppingTaggerStore.shoppingTagsEligible &&
      this.instagramShoppingTaggerStore.catalogs.length === 0
    ) {
      await this.fetchEligibleCatalogs();
    }

    if (this.isReadyToTag) {
      this.instagramShoppingTaggerStore.enableShoppingTagger();
    } else {
      this.showErrorState = true;
    }
  },
  unmounted() {
    this.instagramShoppingTaggerStore.disableShoppingTagger();
    this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: false });
  },
  methods: {
    async fetchEligibility() {
      try {
        await this.instagramShoppingTaggerStore.checkShoppingTagsEligible();
      } catch (error) {
        // Facebook graph api is inconsistent with error codes, for now we want to catch these errors when showing the connection popup
        if (
          [fbGraphApiErrors.API_PERMISSION_ERROR, fbGraphApiErrors.INVALID_PARAMETER].includes(
            error.response?.data?.fb_graph_api_error,
          )
        ) {
          this.showReconnectMessage = true;
          return;
        }

        this.handleUnknownFbGraphApiError(error);
      }
    },
    async fetchEligibleCatalogs() {
      await this.instagramShoppingTaggerStore.getInstagramProductTagsCatalogs();
    },
    handleInput() {
      this.pending.searchProducts = true;
      this.debouncedSearchProducts({ clear: true });
    },
    debouncedSearchProducts: debounce(function typeAhead(...options) {
      this.searchProducts(...options);
    }, debounceInputDelayShort),

    async searchProducts({ clear } = {}) {
      if (!this.searchTerm) {
        this.clearShoppingTagProducts();
        this.pending.searchProducts = false;
        return;
      }

      this.pending.searchProducts = true;

      const params = {
        instagramUserId: this.instagramShoppingTaggerStore.instagramUserId,
        catalogId: this.instagramShoppingTaggerStore.catalogs?.[0]?.catalog_id,
        query: this.searchTerm,
        after: this.after ?? undefined,
      };

      const response = await FacebookAPI.instagramProductTags.searchProducts({
        brandId: this.authStore.currentBrand.id,
        params,
      });

      if (clear) {
        this.clearShoppingTagProducts();
      }

      this.shoppingTagProducts = collateData(this.shoppingTagProducts, response.data.products);
      this.after = response.data.after;
      this.pending.searchProducts = false;
    },
    clearShoppingTagProducts() {
      this.shoppingTagProducts = [];
      this.after = null;
    },
    handleInfinite() {
      if (this.after) {
        this.searchProducts();
      }
    },
    handleOptionClick(value) {
      // options will have the disabled property but we do not want to save it
      const { disabled: _, ...productOption } = value;

      this.instagramShoppingTaggerStore.updateShoppingTag({
        index: this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length - 1,
        shoppingTag: productOption,
      });
      this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: false });
      this.clearShoppingTagProducts();
    },
    handleBack() {
      if (this.instagramShoppingTaggerStore.editingShoppingTag) {
        this.instagramShoppingTaggerStore.deleteShoppingTag({
          index: this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length - 1,
        });
        this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: false });
      }
      this.onShoppingTagsChanged(this.instagramShoppingTaggerStore.taggedProducts);
      this.onBack();
    },
    handleInputFocused() {
      if (!this.instagramShoppingTaggerStore.editingShoppingTag) {
        this.instagramShoppingTaggerStore.addShoppingTag({
          shoppingTag: {
            fbProductId: null,
            title: null,
            imageUrl: null,
            price: null,
            x: 0.5,
            y: 0.5,
          },
        });
        this.instagramShoppingTaggerStore.setEditingShoppingTag({ value: true });
      }
    },
    handleUnknownFbGraphApiError(error) {
      this.notificationStore.setToast({
        message: eligibilityCallFailureNotificationMessage,
        subtext: eligibilityCallFailureNotificationSubtext,
        buttonText: 'Got It',
        autoClear: false,
        type: 'error',
      });
      throw error;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.shopping-tags-container {
  margin-top: var(--space-12);
}

.product-search-select {
  margin-top: var(--space-32);
}

.empty-state-message {
  margin-top: var(--space-88);
  font-weight: var(--font-medium);
  font-size: var(--x18);
  color: var(--text-secondary);
}

.search-list {
  margin-top: var(--space-24);
}

:deep(.main-content-panel) {
  margin-left: 0;
  width: 100%;
}

:deep(.item-details) {
  padding: var(--space-8) 0;
}
</style>
