/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'smallHeart': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M8.5 14.422a.806.806 0 01-.426-.122c-.28-.174-6.823-4.3-6.823-8.708 0-2.213 1.806-4.014 4.027-4.014 1.284 0 2.473.672 3.222 1.67.75-.998 1.938-1.67 3.222-1.67 2.22 0 4.027 1.8 4.027 4.014 0 4.408-6.543 8.534-6.823 8.708a.806.806 0 01-.426.122zM5.278 3.184a2.415 2.415 0 00-2.416 2.408c0 2.7 3.74 5.785 5.638 7.068 1.899-1.283 5.638-4.368 5.638-7.068a2.415 2.415 0 00-2.416-2.408c-1.36 0-2.417 1.294-2.417 2.408 0 .443-.36.803-.805.803a.804.804 0 01-.805-.803c0-1.114-1.056-2.408-2.417-2.408z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
