import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import humps from 'humps';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import axios, { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import difference from 'lodash/difference';
import union from 'lodash/union';
import flatMap from 'lodash/flatMap';
import { useAuthStore } from '@/stores/auth';
import * as socialListeningApi from '@/apis/social-listening';
import {
  SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
  SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
  POST_VOLUME_GRAPH_OPTIONS,
  SOCIAL_LISTENING_AUDIENCE_TYPES,
  SHARE_OF_VOICE_BREAKDOWN_BY,
  GRAPH_SCALE,
  POST_SORT_OPTIONS,
  OVERVIEW_NUM_POSTS_TO_FETCH,
  VISUAL_DATA_TYPES,
  KEYWORD_GROUP_TYPE,
  SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
  SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
  SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT,
  SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS,
  TOPIC_BUILDER_SOURCE_OPTIONS,
  SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT,
  SOV_GRAPH_METRIC_OPTIONS,
  VISUAL_FILTER_SEARCH_MAX_MEDIA,
  WEB_RESULT_PREVIEW_MAX_POSTS,
  WEB_RESULT_PAGE_INITIAL_MAX_POSTS,
  ALERT_SENSITIVITY_THRESHOLDS,
  EDIT_TOPIC_KEYWORD_TOAST_MESSAGES,
} from '@/app/socialListening/constants';
import { useNotificationStore } from '@/stores/notification';
import { DashboardAPI, LibraryAPI } from '@/apis';
import * as InstagramAPI from '@/apis/instagram';
import * as TwitterAPI from '@/apis/twitter';
import * as YouTubeAPI from '@/apis/youtube';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { parseToQuery, parseTreeToGroups } from '@/app/socialListening/utils/parsing';
import { camelCaseObjDeep } from '@/app/socialListening/utils/api-request-utils';
import { UPLOAD_STATUS } from '@/config';
import { refreshCancelToken } from '@/apis/axios.utils';
import { usePlatformStore } from '@/stores/platform';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { getElementPositionMap } from '@/app/socialListening/utils/sorting';
import * as AuthAPI from '@/apis/auth';
import { getUserTimezone } from '@/utils/timezone';
import { useTrackingStore } from '@/stores/tracking';
import { getCurrentDate } from '@/utils';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import {
  buildErrorForSyntaxError,
  handleTextEditorErrorsUsingExpression,
  handleTextEditorErrorsUsingKeywordGroups,
  handleTextEditorErrorsUsingParseTree,
} from '@/app/socialListening/utils/keyword-composer-utils';
import { useFlagStore } from './flag';

export const useSocialListeningStore = defineStore('socialListening', {
  state: () => ({
    topicList: [],
    topKeywords: [],
    topPosts: [],
    topPostsPreview: [],
    industries: [],
    visualTrends: [],
    visualTrendsRequestId: null,
    visualTrend: {},
    trendGroups: [],
    selectedTopic: null,
    topicMonitors: [],
    pending: {
      topicComponentData: false,
      populateTopicStates: false,
      editTopic: false,
      topKeywords: true,
      topPosts: true,
      topPostsPreview: true,
      postsByChannelTimeseries: false,
      postsBySentimentTimeseries: false,
      postsByMediaTypeTimeseries: false,
      totalPostsTimeseries: false,
      postVolume: false,
      postVolumeTimeSeries: false,
      postEngagement: false,
      postEngagementTimeSeries: false,
      getParseTree: false,
      updateSavedTopic: false,
      visualTrends: false,
      trendGroups: false,
      visualTrend: false,
      loadVisualData: true,
      visualDataRefinedResults: true,
      visualDataTotalResultsCount: false,
      webResultsPreview: true,
      webResults: true,
      topicMonitorCreateOrUpdate: false,
      topicMonitors: false,
      processExpression: false,
    },
    errors: {
      trendGroups: false,
    },
    filters: null,
    cachedFilters: null,
    topicsUsage: null,
    wordcloud: {
      includeHashtags: true,
      includeKeywords: true,
    },
    listeningFilters: {
      channels: SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
      sentiments: SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
      mediaTypes: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
      dateRange: SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT[0],
      keyword: '',
      keywordTypes: SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT,
      customDateRange: null,
    },
    topPostFilters: {
      channels: [],
      sentiments: [],
      mediaTypes: [],
      dateRange: '',
      sortBy: '',
      keyword: '',
      keywordTypes: [],
    },
    topPostsCustomDate: {
      label: '',
      start: null,
      end: null,
    },
    postsByChannelTimeseries: {},
    postsBySentimentTimeseries: {},
    postsByMediaTypeTimeseries: {},
    totalPostsTimeseries: {},
    postVolumeSelectedGraph: null,
    sovSelectedGraphMetric: SOV_GRAPH_METRIC_OPTIONS.POST_VOLUME.value,
    keywordGroups: null,
    teaserKeywordGroups: null,
    topicStats: {
      totalPosts: null,
      uniqueCreators: null,
      totalEngagements: null,
      averageEngagementsPerPost: null,
    },
    queryKeyword: '',
    visualTrendPopupShow: false,
    popups: {
      editKeywords: {
        show: false,
      },
      createTopic: {
        show: false,
      },
      manageUsage: {
        show: false,
      },
      topicAlert: {
        show: false,
      },
      invalidTopicAlert: {
        show: false,
      },
      upgradeTier: {
        show: false,
      },
      upgradeTierAccess: {
        show: false,
      },
      upgradePlan: {
        show: false,
      },
    },
    postVolume: {},
    postVolumeTimeSeries: {},
    postEngagement: {},
    postEngagementTimeSeries: {},
    visualFilters: {
      includes: [],
      doesNotInclude: [],
    },
    topicKeywordFilter: {
      rule: KEYWORD_GROUP_TYPE.INCLUDES,
      word: null,
    },
    unsavedFilters: {},
    createTopicInstanceId: null,
    mixpanelProps: {
      selectedCompetitorsHandles: [],
    },
    topLevelFiltersDisabled: false,
    unsavedKeywordChanges: false,
    topicCancelToken: {},
    keywordInputErrors: [],
    webResults: [],
    webResultsPreview: [],
    brandAccountIds: {},
    brandIndustries: [],
    postVolumeBrand: {},
    postVolumeTimeSeriesBrand: {},
    postEngagementBrand: {},
    postEngagementTimeSeriesBrand: {},
    organizationUsers: [],
    topicMonitorEvents: {},
    plainTextMode: false,
    keywordBooleanExpression: '',
    keywordBooleanExpressionErrors: [],
    suggestedTopics: [],
  }),
  getters: {
    canSaveKeywordComposer: (state) => {
      if (state.plainTextMode) {
        return (
          !state.pending.getParseTree &&
          !state.pending.processExpression &&
          !state.keywordBooleanExpressionErrors.length
        );
      }
      return !state.keywordInputErrors.length;
    },
    hasExceededTopicLimit: (state) => {
      return state?.topicsUsage?.topicsCreated >= state?.topicsUsage?.topicsLimit;
    },
    hasExceededMentionLimit: (state) => {
      return state?.topicsUsage?.mentionsAdded >= state?.topicsUsage?.mentionsRollingLimit;
    },
    hasActiveTopicMonitors: (state) => {
      return state?.topicMonitors?.some((topicMonitor) => topicMonitor.muted === false);
    },
    hasTopicMonitors: (state) => {
      return state?.topicMonitors?.length;
    },
    hasTopics: (state) => {
      return state.topicList?.length > 0;
    },
    topKeywordsListStr: (state) => {
      return state?.topKeywords.map(({ keyword }) => keyword);
    },
    keywordBooleanExpressionErrorMessages: (state) => {
      return union(state?.keywordBooleanExpressionErrors.map(({ error }) => error));
    },
    showWebResultsPreview: (state) => {
      return (
        state.selectedTopic.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC &&
        state.filters?.websearch?.enabled
      );
    },
  },
  actions: {
    resetWordcloudState() {
      this.wordcloud.includeKeywords = true;
      this.wordcloud.includeHashtags = true;
    },
    setIncludeKeywords(newValue) {
      this.wordcloud.includeKeywords = newValue;
    },
    setIncludeHashtags(newValue) {
      this.wordcloud.includeHashtags = newValue;
    },
    setPlainTextMode(newValue) {
      this.plainTextMode = newValue;
    },
    getOrganizationId() {
      const authStore = useAuthStore();
      const currentBrandLabel = authStore?.currentBrand?.label;
      const brandOrganizationId = authStore?.identity?.brands?.[currentBrandLabel]?.organizationId;
      return brandOrganizationId;
    },
    launchVisualTrendsPopup() {
      this.visualTrendPopupShow = true;
    },
    closeVisualTrendsPopup() {
      this.visualTrendPopupShow = false;
    },
    setOverviewFilters({
      channels,
      sentiments,
      mediaTypes,
      dateRange,
      keyword,
      keywordTypes,
      customDateRange,
    }) {
      this.listeningFilters = {
        channels,
        sentiments,
        mediaTypes,
        dateRange,
        keyword,
        keywordTypes,
        customDateRange,
      };
    },
    setTopPostFilters({
      channels,
      sentiments,
      mediaTypes,
      dateRange,
      sortBy,
      keyword,
      keywordTypes,
    }) {
      this.topPostFilters = {
        channels,
        sentiments,
        mediaTypes,
        dateRange,
        sortBy,
        keyword,
        keywordTypes,
      };
    },
    clearTopPosts() {
      this.topPosts = [];
    },
    clearTopPostsPreview() {
      this.topPostsPreview = [];
    },
    resetOverviewFilters() {
      this.listeningFilters = {
        channels: SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
        sentiments: SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
        mediaTypes: SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
        dateRange: SOCIAL_LISTENING_DATERANGE_FILTER_DEFAULT[0],
        keyword: '',
        keywordTypes: SOCIAL_LISTENING_KEYWORDTYPE_FILTER_DEFAULT,
        customDateRange: null,
      };
      this.queryKeyword = '';
    },
    enableTopLevelFilters() {
      this.topLevelFiltersDisabled = false;
    },
    disableTopLevelFilters() {
      this.topLevelFiltersDisabled = true;
    },
    setOverviewKeywordFilter(keyword) {
      this.listeningFilters = {
        ...this.listeningFilters,
        keyword,
      };
    },
    removeOverviewKeywordFilter() {
      this.listeningFilters = {
        ...this.listeningFilters,
        keyword: '',
      };
      this.enableTopLevelFilters();
    },
    removeTopPostKeywordFilter() {
      this.topPostFilters = {
        ...this.topPostFilters,
        keyword: '',
      };
    },
    async getSavedTopics() {
      const organizationId = await this.getOrganizationId();
      try {
        const res = await socialListeningApi.getTopics(organizationId, true);
        this.topicList = res.data.data;
      } catch {
        this.topicList = [];
      }
    },
    async getTopicsUsage() {
      const flagStore = useFlagStore();
      const customerJourneyStore = useCustomerJourneyStore();
      const socialListeningTier = await customerJourneyStore.getSocialListeningTier()?.value;
      const organizationId = await this.getOrganizationId();
      const cancelToken = refreshCancelToken(this, 'topicsUsageCancelToken');
      try {
        const res = await socialListeningApi.getTopicsUsage(organizationId, { cancelToken });
        this.topicsUsage = res.data;
        if (flagStore.flags.inAppTrials && socialListeningTier === 'trial') {
          this.topicsUsage.topicsLimit = 5;
          this.topicsUsage.mentionsRollingLimit = 1000000;
        }
      } catch {
        this.topicsUsage = null;
      }
    },
    async getStats(
      refresh = true,
      useEstimates = null,
      overrideFilters = {},
      updateStore = true,
      source = null,
      teaserEndpoint = false,
    ) {
      const organizationId = await this.getOrganizationId();
      const flagStore = useFlagStore();
      const isYouTubeSlEnabled = flagStore.ready && flagStore.flags.youtubeSlSearch;
      if (refresh) {
        this.topicStats = {
          totalPosts: null,
          uniqueCreators: null,
          totalEngagements: null,
          averageEngagementsPerPost: null,
        };
      }
      const cancelToken = refreshCancelToken(this, 'statsCancelToken');
      try {
        const promises = [];

        let getFunction;
        if (source === SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC) {
          getFunction = socialListeningApi.getInstagramStats;
        } else if (teaserEndpoint) {
          getFunction = socialListeningApi.getTeaserStats;
        } else {
          getFunction = socialListeningApi.getTopicStats;
        }

        const topicStats = await getFunction(
          {
            organizationId,
            ...this.getBaseSearchParams(),
            ...overrideFilters,
            ...(useEstimates === null ? this.getUseEstimatesParam(teaserEndpoint) : useEstimates),
            ...this.getScaleParam(teaserEndpoint),
          },
          { cancelToken },
        );
        promises.push(topicStats);
        if (source === SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC) {
          const searchFilters =
            Object.keys(overrideFilters).length !== 0
              ? overrideFilters.searchFilters
              : this.getBaseSearchParams().searchFilters;

          const topicQuery = searchFilters.keywordsAndHashtags;
          const fromDate = searchFilters.sourceCreated.onOrAfter;
          const toDate = searchFilters.sourceCreated.onOrBefore;
          const bucket = 'day';

          if (topicQuery !== undefined) {
            const twitterSearch = TwitterAPI.getTwitterSearchCounts({
              topicQuery,
              bucket,
              fromDate,
              toDate,
            });
            promises.push(twitterSearch);
            if (isYouTubeSlEnabled) {
              const youtubeSearch = YouTubeAPI.getYouTubeSearchCounts({
                topicQuery,
                bucket,
                fromDate,
                toDate,
              });
              promises.push(youtubeSearch);
            }
          }
        }
        const results = await Promise.allSettled(promises);
        const statsData = results[0].value.data.data;
        if (results.length > 1) {
          const twitterCountsData = results[1].value.data;
          statsData.totalPosts += twitterCountsData.total_count;
        }
        if (results.length > 2) {
          const youTubeCountsData = results[2].value.data.data;
          statsData.totalPosts += youTubeCountsData.total_count;
        }
        if (updateStore) {
          this.topicStats = statsData;
        }
        return statsData;
      } catch (error) {
        if (axios.isCancel(error) || !updateStore) {
          return null;
        }
        Object.keys(this.topicStats).forEach((key) => {
          this.topicStats[key] = '-';
        });
        return null;
      }
    },
    async getTopicData(topicId) {
      const organizationId = await this.getOrganizationId();
      this.pending.populateTopicStates = true;
      this.filters = null;
      try {
        const res = await socialListeningApi.getTopic({ organizationId, topicId });
        const {
          id,
          name,
          audienceType,
          selectedBrandId,
          searchBody,
          createdAt,
          filtersLastUpdatedAt,
          keywordsAndHashtagsUpdatedAt,
        } = res.data.data;
        this.selectedTopic = {
          id,
          name,
          audienceType,
          selectedBrandId,
          createdAt,
          filtersLastUpdatedAt,
          keywordsAndHashtagsUpdatedAt,
        };
        const searchBodyFilters = searchBody.filters;
        const offset = searchBodyFilters?.sourceCreated?.rollingDateRangeOffset;
        if (offset) {
          const { startDate, endDate } = this.formatDateOptions(
            getCurrentDate(offset),
            getCurrentDate(),
            offset,
          );
          searchBodyFilters.sourceCreated = {
            onOrAfter: startDate,
            onOrBefore: endDate,
            rollingDateRangeOffset: offset,
          };
        }
        this.filters = searchBodyFilters;
      } catch (error) {
        this.selectedTopic = null;
        this.filters = null;
        throw error;
      } finally {
        this.pending.populateTopicStates = false;
      }
    },
    addKeywordBooleanExpressionErrors(newErrors = []) {
      this.keywordBooleanExpressionErrors = [...this.keywordBooleanExpressionErrors, ...newErrors];
    },
    handleKeywordBooleanExpressionErrors(newGroups, parseTree, expression) {
      let errors = [];
      // using keyword groups
      const groups = newGroups || this.keywordGroups;
      if (groups?.length) {
        errors = errors.concat(handleTextEditorErrorsUsingKeywordGroups(groups));
      }
      // using parse tree
      if (parseTree) {
        const parseTreeErrors = handleTextEditorErrorsUsingParseTree(parseTree);
        if (parseTreeErrors?.length) {
          errors = errors.concat(parseTreeErrors);
        }
      }
      // using expression string
      if (expression) {
        const expressionErrors = handleTextEditorErrorsUsingExpression(expression);
        if (expressionErrors?.length) {
          errors = errors.concat(expressionErrors);
        }
      }
      this.addKeywordBooleanExpressionErrors(errors);
    },
    handleParseTreeError(error) {
      const unexpected = error.tokens.unexpected;
      const expected = error.tokens.expected;
      const { column, line } = error.location;
      const expressionByLine = this.keywordBooleanExpression.split('\n');
      const sliceOffset = Math.max(0, unexpected.length - 1); // some unexpected tokens have multiple characters, e.g. "AND"
      const n = expressionByLine[line - 1]
        .slice(0, column + sliceOffset) // slice up to the column + length of the unexpected token
        .split(/(\b|\s+)/)
        .filter((char) => char.trim() === unexpected).length;
      let formattedError = {};
      // TODO sc-135802: implement highlighting for non-unexpected errors (missing tokens)
      if (unexpected || expected.length) {
        formattedError = buildErrorForSyntaxError(unexpected, expected, n);
      }
      this.addKeywordBooleanExpressionErrors([formattedError]);
    },
    async getParseTree(expressionInput) {
      this.pending.getParseTree = true;
      this.keywordBooleanExpressionErrors = [];
      const organizationId = await this.getOrganizationId();
      try {
        const expression = expressionInput || this.filters?.keywordsAndHashtags;
        if (expression) {
          this.keywordBooleanExpression = expression;
          const res = await socialListeningApi.getParseTree({
            organizationId,
            payload: { expression },
          });
          this.keywordGroups = parseTreeToGroups(res.data.data);
          if (this.plainTextMode) {
            await this.handleKeywordBooleanExpressionErrors(
              undefined,
              res?.data?.data,
              expressionInput,
            );
          }
        } else {
          this.keywordGroups = null;
          this.keywordBooleanExpression = '';
        }
      } catch (err) {
        const parseTreeError = err.response.data?.errors?.json?.expression?.error;
        if (err.response.status === 400 && parseTreeError) {
          this.handleParseTreeError(parseTreeError);
        }
        this.keywordBooleanExpression = '';
      } finally {
        this.pending.getParseTree = false;
      }
    },
    async deleteTopic(topicId) {
      const organizationId = await this.getOrganizationId();
      let res;
      try {
        res = await socialListeningApi.deleteTopic({ organizationId, topicId });
        this.topicList = this.topicList.filter((topic) => topic.id !== topicId);
        if (this.selectedTopic?.id === topicId || isEmpty(this.topicList)) {
          this.selectedTopic = null;
        }
      } catch {
        const notificationStore = useNotificationStore();
        notificationStore.setToast({
          message: 'Topic failed to delete. Please refresh and try again.',
          type: 'error',
        });
      }
      return res;
    },
    clearSOVPostVolumeData() {
      this.postVolume = {};
      this.postVolumeTimeSeries = {};
      this.postEngagement = {};
      this.postEngagementTimeSeries = {};
      this.postVolumeBrand = {};
      this.postVolumeTimeSeriesBrand = {};
      this.postEngagementBrand = {};
      this.postEngagementTimeSeriesBrand = {};
    },
    async getShareOfVoiceForMetric(
      selectedPostVolumeMetric = SOV_GRAPH_METRIC_OPTIONS.POST_VOLUME.value,
    ) {
      let brandAccounts = null;
      if (this.selectedTopic.selectedBrandId) {
        brandAccounts = await this.getBrandPlatformAccounts(this.selectedTopic.selectedBrandId);
        if (this.selectedTopic.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY) {
          await this.fetchIndustries();
          await this.getBrandIndustries(this.selectedTopic.selectedBrandId);
        }
      }
      const { aggregationMetric, property } = SOV_GRAPH_METRIC_OPTIONS[selectedPostVolumeMetric];
      this.getPostVolume({
        brandCreatorIds: brandAccounts,
        aggregationMetric,
        property,
      });
      this.getPostVolumeTimeSeries({
        brandCreatorIds: brandAccounts,
        aggregationMetric,
        property,
      });
    },
    async getBrandPostData({ organizationId, payload, dataType, brandCreatorIds, cancelToken }) {
      try {
        if (
          this.brandIndustries.length &&
          this.brandAccountIds[this.selectedTopic.selectedBrandId].length &&
          this.selectedTopic.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY
        ) {
          payload.searchFilters.industryIds = [];
          payload.searchFilters.sourceAndCreatorIds = brandCreatorIds;
          const request = dataType === 'postVolume' ? 'getPostVolume' : 'getTimeSeriesPostVolume';
          const brandRes = await socialListeningApi[request](
            {
              organizationId,
              payload: {
                ...payload,
                breakdownBy:
                  SHARE_OF_VOICE_BREAKDOWN_BY[SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR],
              },
            },
            { cancelToken },
          );
          this[`${dataType}Brand`] = brandRes.data.data;
        }
      } catch {
        this[`${dataType}Brand`] = {};
      }
    },
    async getPostVolume({
      breakdown,
      topicId = null,
      brandCreatorIds = null,
      searchFilters = null,
      aggregationMetric = null,
      property = 'postVolume',
      teaserEndpoint = false,
    }) {
      const organizationId = await this.getOrganizationId();
      const useEstimates = this.getUseEstimatesParam(teaserEndpoint);
      const payload = {
        breakdownBy: SHARE_OF_VOICE_BREAKDOWN_BY[breakdown ?? this.selectedTopic.audienceType],
        ...(searchFilters ? { searchFilters } : this.getBaseSearchParams()),
        ...(aggregationMetric ? { aggregationMetric } : {}),
      };
      if (topicId) {
        payload.topicId = topicId;
      }
      this[`${property}Brand`] = {};
      if (
        brandCreatorIds &&
        this.selectedTopic.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR
      ) {
        if (payload.searchFilters.sourceAndCreatorIds?.length) {
          payload.searchFilters.sourceAndCreatorIds =
            payload.searchFilters.sourceAndCreatorIds.concat(brandCreatorIds);
        } else {
          payload.searchFilters.sourceAndCreatorIds = brandCreatorIds;
        }
      }
      const cancelToken = refreshCancelToken(this, `${property}CancelToken`);
      this.pending[property] = true;
      try {
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserPostVolume
          : socialListeningApi.getPostVolume;
        const res = await getFunction({ organizationId, payload, useEstimates }, { cancelToken });
        this[property] = res.data.data;

        // get brand post volume for industry sov
        await this.getBrandPostData({
          organizationId,
          payload,
          dataType: property,
          brandCreatorIds,
          cancelToken,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        this[property] = {};
        this[`${property}Brand`] = {};
      }
      this.pending[property] = false;
    },
    async getPostVolumeTimeSeries({
      brandCreatorIds = null,
      aggregationMetric = null,
      property = 'postVolume',
      isTeaser = false,
      teaserFilters = null,
      teaserEndpoint = false,
    }) {
      const organizationId = await this.getOrganizationId();
      const useEstimates = this.getUseEstimatesParam(teaserEndpoint);
      const breakdownBy = isTeaser
        ? SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY
        : SHARE_OF_VOICE_BREAKDOWN_BY[this.selectedTopic.audienceType];
      const payload = isTeaser
        ? {
            breakdownBy,
            scale: 'DAY',
            search_filters: teaserFilters,
          }
        : {
            breakdownBy,
            scale: this.calculateScale(),
            ...this.getBaseSearchParams(),
            ...(aggregationMetric ? { aggregationMetric } : {}),
          };
      this[`${property}TimeSeriesBrand`] = {};
      if (
        !isTeaser &&
        brandCreatorIds &&
        this.selectedTopic.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR
      ) {
        if (payload.searchFilters.sourceAndCreatorIds?.length) {
          payload.searchFilters.sourceAndCreatorIds =
            payload.searchFilters.sourceAndCreatorIds.concat(brandCreatorIds);
        } else {
          payload.searchFilters.sourceAndCreatorIds = brandCreatorIds;
        }
      }
      const cancelToken = refreshCancelToken(this, `${property}TSCancelToken`);
      this.pending[`${property}TimeSeries`] = true;
      try {
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserTimeSeriesPostVolume
          : socialListeningApi.getTimeSeriesPostVolume;
        const res = await getFunction({ organizationId, payload, useEstimates }, { cancelToken });
        this[`${property}TimeSeries`] = res.data.data;

        // get brand post volume time series for industry sov
        await this.getBrandPostData({
          organizationId,
          payload,
          dataType: `${property}TimeSeries`,
          brandCreatorIds,
          cancelToken,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        this[`${property}TimeSeries`] = {};
        this[`${property}TimeSeriesBrand`] = {};
      }
      this.pending[`${property}TimeSeries`] = false;
    },
    async getBrandIndustries(brandId) {
      this.brandIndustries = [];
      const brandAccountIds = this.brandAccountIds[brandId].map((sourceAndId) => {
        return sourceAndId.split(':')[1];
      });
      if (brandAccountIds.length) {
        const promises = brandAccountIds.map((id) => DashboardAPI.getIndustriesBySourceAccount(id));
        let brandIndustryData = [];
        await Promise.allSettled(promises).then((results) => {
          results.forEach((result) => {
            if (result.value) {
              brandIndustryData = [...brandIndustryData, ...result.value.data];
            }
          });
        });
        const { searchFilters } = this.getBaseSearchParams();
        const brandIndustryNames = brandIndustryData.map((i) => i.name);
        this.brandIndustries = this.industries.filter(
          (i) => searchFilters.industryIds.includes(i.id) && brandIndustryNames.includes(i.name),
        );
      }
    },
    async getBrandPlatformAccounts(brandId) {
      if (this.brandAccountIds[brandId]) {
        return this.brandAccountIds[brandId];
      }
      this.brandAccountIds[brandId] = [];
      const platformStore = usePlatformStore();
      let instagramAccount =
        platformStore.accounts?.[PLATFORM_CONNECTION.FACEBOOK.value]?.[brandId];
      if (!instagramAccount) {
        instagramAccount = platformStore.getInstagramAccount(brandId);
      }
      let twitterAccount = platformStore.accounts?.[PLATFORM_CONNECTION.TWITTER.value]?.[brandId];
      if (!twitterAccount) {
        twitterAccount = platformStore.getTwitterAccount(brandId);
      }
      let youtubeAccount = platformStore.accounts?.[PLATFORM_CONNECTION.YOUTUBE.value]?.[brandId];
      if (!youtubeAccount) {
        youtubeAccount = platformStore.getYouTubeChannel(brandId);
      }

      const accounts = await Promise.allSettled([instagramAccount, twitterAccount, youtubeAccount]);
      return accounts.reduce((accountList, promise) => {
        if (promise.value) {
          let sourceAndId = null;
          if (promise.value.instagramId) {
            sourceAndId = `INSTAGRAM:${promise.value.instagramId}`;
          } else if (promise.value.twitter_user_id) {
            sourceAndId = `TWITTER:${promise.value.twitter_user_id}`;
          } else if (promise.value.sourceChannelId) {
            sourceAndId = `YOUTUBE:${promise.value.sourceChannelId}`;
          }
          if (sourceAndId) {
            this.brandAccountIds[brandId].push(sourceAndId);
            accountList.push(sourceAndId);
          }
        }
        return accountList;
      }, []);
    },

    async updateComponentData({ topicId, socketId = null }) {
      // Method to get all component data based on the filters
      // Need the topic to get filters for the rest of the calls, so await is needed
      // The rest of the calls can be promises since they are independent.
      this.clearUnsavedTopicKeywordFilter();
      await this.getTopicData(topicId);
      await this.getBrandPlatformAccounts(this.selectedTopic.selectedBrandId);
      if (!this.topicCancelToken[topicId]) {
        this.topicCancelToken[topicId] = axios.CancelToken.source();
      }
      await Promise.allSettled([this.getOrganizationUsers(), this.getTopicComponentData(socketId)]);
    },
    async getTopicComponentData(socketId = null, fromTopPosts = false) {
      this.pending.topicComponentData = true;
      const promises = [this.getStats()];

      if (!fromTopPosts) {
        this.cachedFilters = { ...this.filters };
      }
      const { topicId, searchFilters } = this.getBaseSearchParams();

      if (this.selectedTopic.audienceType !== SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC) {
        this.clearSOVPostVolumeData();
        promises.push(this.getShareOfVoiceForMetric(this.sovSelectedGraphMetric));
      } else {
        await this.fetchIndustries();
        promises.push(
          this.getPostVolume({
            breakdown: SOCIAL_LISTENING_AUDIENCE_TYPES.INDUSTRY,
            topicId,
            searchFilters: {
              ...searchFilters,
              industryIds: this.industries.map((i) => i.id),
            },
          }),
        );
      }
      promises.push(
        this.getTopicsUsage(),
        this.getTopPostsPreview({
          filters: { ...searchFilters },
          sorts: [`-${POST_SORT_OPTIONS.engagements.value}`, `-${POST_SORT_OPTIONS.date.value}`],
          paging: {
            limit: OVERVIEW_NUM_POSTS_TO_FETCH,
            offset: 0,
          },
          topic_id: this.selectedTopic.id,
        }),
        this.getTopicMonitors(this.selectedTopic.id).then(async () => {
          if (this.topicMonitors.length > 0) {
            await this.getTopicMonitorEvents(this.selectedTopic.id, this.topicMonitors[0].id);
          } else {
            this.topicMonitorEvents = {};
          }
        }),
      );

      if (this.showWebResultsPreview) {
        promises.push(this.getWebResultsPreview(this.selectedTopic.id, true));
      }
      await Promise.allSettled(promises);

      // retry loading web results if the previous request return no results
      if (
        this.showWebResultsPreview &&
        this.pending.webResultsPreview &&
        !this.webResultsPreview.length
      ) {
        setTimeout(() => {
          this.getWebResultsPreview(this.selectedTopic.id, false);
        }, 3000);
      } else {
        this.pending.webResultsPreview = false;
      }

      const nextPromises = [
        this.getTopKeywords({
          ...this.getBaseSearchParams(),
          includeEmojis: true,
          includeKeywords: this.wordcloud.includeKeywords,
          includeHashtags: this.wordcloud.includeHashtags,
        }),
      ];

      if (socketId !== null) {
        // Socket store ID is not available until after component is mounted
        nextPromises.push(this.fetchVisualTrends(socketId));
      }

      await Promise.allSettled(nextPromises);

      this.pending.topicComponentData = false;
    },
    async fetchVisualTrends(socketId) {
      // Fetch visual trends for topics page
      const requestId = uuidv4();

      const { topicId, searchFilters } = this.getBaseSearchParams();
      this.visualTrendsRequestId = requestId;
      await this.getVisualTrends({
        requestId,
        socketId,
        searchBody: { filters: searchFilters },
        topicId,
      });
    },
    async updateSavedTopic(payload) {
      const organizationId = await this.getOrganizationId();

      try {
        this.pending.updateSavedTopic = true;
        await socialListeningApi.updateTopic(
          { organizationId, topicId: this.selectedTopic.id },
          payload,
        );
        this.pending.updateSavedTopic = false;
        return true;
      } catch {
        this.pending.updateSavedTopic = false;
        const notificationStore = useNotificationStore();
        notificationStore.setToast({
          message: 'Update failed to save. Please refresh and try again.',
          type: 'error',
        });
        return false;
      }
    },
    formatDateOptions(onOrAfter, onOrBefore, offset) {
      let startDate = dayjs(onOrAfter);
      let endDate = dayjs(onOrBefore);
      if (dayjs().isSame(endDate, 'day') && endDate.diff(startDate, 'day') === 1 && offset === 1) {
        const currentHour = dayjs().hour();
        startDate = startDate.set('hour', currentHour).set('minute', 0).set('second', 0);
        endDate = endDate.set('hour', currentHour).set('minute', 0).set('second', 0);
      } else {
        startDate = startDate.startOf('day');
        endDate = endDate.endOf('day');
      }
      return {
        startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      };
    },
    async updateSourceCreated(onOrAfter, onOrBefore, offset, updateTopic = true) {
      const { startDate, endDate } = this.formatDateOptions(onOrAfter, onOrBefore, offset);

      let sourceCreated =
        onOrAfter || onOrBefore
          ? {
              ...(onOrAfter
                ? {
                    onOrAfter: startDate,
                  }
                : {}),
              ...(onOrBefore
                ? {
                    onOrBefore: endDate,
                  }
                : {}),
            }
          : undefined;

      const sourceCreatedUpdate = offset ? { rollingDateRangeOffset: offset } : sourceCreated;

      const updated = updateTopic
        ? await this.updateSavedTopic({
            searchBody: { filters: { ...this.filters, sourceCreated: sourceCreatedUpdate } },
          })
        : true;
      if (updated) {
        sourceCreated =
          sourceCreated && offset
            ? { ...sourceCreated, rollingDateRangeOffset: offset }
            : sourceCreated;
        this.filters = { ...this.filters, sourceCreated };
      }
    },
    async updateFiltersLocally({
      mediaTypes,
      sources,
      sentiment,
      sourceCreated,
      visualData,
      industryIds,
      keywordsAndHashtags,
      topicId,
    }) {
      let sentimentUpdated = {};
      let mediaTypesUpdated;
      let sourcesUpdated;
      if (sentiment && !isEqual(SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT, sentiment)) {
        Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).forEach((s) => {
          Object.assign(sentimentUpdated, { [s.field]: sentiment.includes(s.value) });
        });
      } else {
        sentimentUpdated = null;
      }
      if (!isEqual(SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT, mediaTypes)) {
        mediaTypesUpdated = { includes: mediaTypes };
      }
      if (!isEqual(SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT, sources)) {
        sourcesUpdated = sources;
      }
      this.filters = {
        ...this.filters,
        ...(sourcesUpdated ? { sources: sourcesUpdated } : {}),
        ...(mediaTypesUpdated ? { mediaTypes: mediaTypesUpdated } : {}),
        ...(sentimentUpdated ? { sentiment: sentimentUpdated } : {}),
        ...(sourceCreated ? { sourceCreated } : {}),
        ...(visualData ? { visualData } : {}),
        ...(industryIds ? { industryIds } : {}),
        ...(keywordsAndHashtags ? { keywordsAndHashtags } : {}),
        ...(topicId ? { topicId } : {}),
      };
    },
    async updateTopicName(name) {
      const updated = await this.updateSavedTopic({ name });
      if (updated && this.selectedTopic) {
        this.selectedTopic.name = name;
        const index = this.topicList.findIndex((t) => t.id === this.selectedTopic.id);
        this.topicList[index].name = name;
      }
    },
    getBaseSearchParams() {
      let filteredCompetitorIds = null;
      if (this.selectedTopic?.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.COMPETITOR) {
        const brandAccountIds = this.brandAccountIds[this.selectedTopic.selectedBrandId] ?? [];
        filteredCompetitorIds = this.filters.sourceAndCreatorIds.filter(
          (id) => !brandAccountIds.includes(id),
        );
      }

      return {
        topicId: this.selectedTopic?.id,
        searchFilters: {
          ...this.filters,
          ...this.unsavedFilters,
          ...(filteredCompetitorIds ? { sourceAndCreatorIds: filteredCompetitorIds } : {}),
        },
      };
    },
    canUseEstimates(teaserEndpoint = false) {
      const flagStore = useFlagStore();
      const flagEnabled = flagStore.ready && flagStore.flags.socialListeningEstimates;
      const isPublic = this.selectedTopic?.audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC;
      return isPublic && flagEnabled && !teaserEndpoint;
    },
    getUseEstimatesParam(teaserEndpoint = false) {
      return { useEstimates: this.canUseEstimates(teaserEndpoint) };
    },
    getScaleParam(teaserEndpoint = false) {
      return this.canUseEstimates(teaserEndpoint) ? { scale: this.calculateScale() } : {};
    },
    async updateFilters({ mediaTypes, sources, visualData, sentiment }, updateTopic = true) {
      let mediaTypesArray = null;
      let newSentiment = {};
      // cachedFilters saves filters as objects, so we want to send the correct values when using cachedFilters
      if (sentiment && sentiment instanceof Array) {
        Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).forEach((s) => {
          newSentiment[s.field] = sentiment.includes(s.value);
        });
      } else {
        newSentiment = sentiment;
      }
      if (mediaTypes && !(mediaTypes instanceof Array)) {
        mediaTypesArray = [...mediaTypes.includes];
      }
      const newFilters = {
        ...this.filters,
        sources: sources ?? undefined,
        mediaTypes: mediaTypes ? { includes: mediaTypesArray ?? mediaTypes } : undefined,
        visualData:
          visualData?.includes?.length || visualData?.doesNotInclude?.length
            ? visualData
            : undefined,
        sentiment: !isEmpty(sentiment) ? newSentiment : undefined,
      };
      if (!isEqual(this.filters, newFilters)) {
        const updated = updateTopic
          ? await this.updateSavedTopic({ searchBody: { filters: newFilters } })
          : true;
        if (updated) {
          this.filters = newFilters;
        }
      }
    },
    async getTopKeywords(payload, teaserEndpoint = false) {
      const cancelToken = refreshCancelToken(this, 'topKeywordsCancelToken');
      const organizationId = await this.getOrganizationId();
      const useEstimates = this.getUseEstimatesParam(teaserEndpoint);
      this.pending.topKeywords = true;
      try {
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserTopKeywords
          : socialListeningApi.getTopKeywords;
        const res = await getFunction({ organizationId, payload, useEstimates }, { cancelToken });
        this.topKeywords = res.data.data;
        this.pending.topKeywords = false;
      } catch (error) {
        if (axios.isCancel(error)) {
          // show loading state until newer request finishes
          return;
        }
        this.topKeywords = [];
        this.pending.topKeywords = false;
      }
    },
    async getTopPostsPreview(payload, teaserEndpoint = false) {
      const organizationId = await this.getOrganizationId();
      this.pending.topPostsPreview = true;
      const cancelToken = refreshCancelToken(this, 'topPostsPreviewCancelToken');
      try {
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserSearchResults
          : socialListeningApi.getSearchResults;
        const res = await getFunction({ organizationId, payload }, { cancelToken });
        this.topPostsPreview = res.data.data;
      } catch (error) {
        if (axios.isCancel(error)) return;
        this.topPostsPreview = [];
      }
      this.pending.topPostsPreview = false;
    },
    async getTopPosts(payload) {
      const organizationId = await this.getOrganizationId();
      this.pending.topPosts = true;
      try {
        const res = await socialListeningApi.getSearchResults({
          organizationId,
          payload,
        });
        if (payload?.paging?.offset) {
          this.topPosts = this.topPosts.concat(res.data.data);
        } else {
          this.topPosts = res.data.data;
        }
      } catch {
        this.topPosts = [];
      }
      this.pending.topPosts = false;
    },
    async getPostById(sourceId, source, teaserEndpoint = false) {
      const organizationId = await this.getOrganizationId();
      const payload = teaserEndpoint
        ? {
            filters: {
              source_and_ids: [`${source}:${sourceId}`],
              industry_ids: this.industries.map((i) => i.id),
              source_created: {
                on_or_after: dayjs()
                  .subtract(29, 'day')
                  .startOf('day')
                  .format('YYYY-MM-DDTHH:mm:ss'),
                on_or_before: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
              },
            },
          }
        : {
            filters: {
              source_and_ids: [`${source}:${sourceId}`],
            },
          };
      try {
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserSearchResults
          : socialListeningApi.getSearchResults;
        const res = await getFunction({ organizationId, payload });
        let media = null;
        const mediaItems = res.data.data;
        if (mediaItems) {
          media = mediaItems[0];
        }
        return media;
      } catch (error) {
        return null;
      }
    },
    async fetchIndustries() {
      try {
        const response = await DashboardAPI.getIndustries(false);
        this.industries = response?.data;
      } catch {
        this.industries = [];
      }
    },
    async updateIndustryIds(industryIds) {
      const updated = await this.updateSavedTopic({
        searchBody: { filters: { ...this.filters, industryIds } },
      });
      if (updated) {
        this.filters = { ...this.filters, industryIds };
      }
    },
    async createTopic(audienceType, topic) {
      const organizationId = await this.getOrganizationId();
      const websearch =
        audienceType === SOCIAL_LISTENING_AUDIENCE_TYPES.PUBLIC && topic.filters?.webSourceIncluded
          ? {
              ...(topic.filters?.webSource ? { site: topic.filters.webSource } : {}),
              enabled: true,
            }
          : undefined;
      const sources =
        topic.filters?.sources?.length &&
        !isEqual(SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT, topic.filters.sources)
          ? topic.filters.sources
          : undefined;
      const payload = {
        organizationId,
        name: topic.name,
        audienceType,
        searchBody: {
          filters: {
            sources,
            sourceAndCreatorIds: topic.filters.competitorIds,
            industryIds: topic.filters.industryIds,
            keywordsAndHashtags:
              parseToQuery(topic.filters.keywords.filter(({ keywords }) => !isEmpty(keywords))) ||
              undefined,
            sourceCreated: {
              onOrAfter: dayjs().subtract(4, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
              onOrBefore: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            },
            websearch,
          },
        },
      };
      if (audienceType !== TOPIC_BUILDER_SOURCE_OPTIONS.PUBLIC.value) {
        const authStore = useAuthStore();
        payload.selectedBrandId = topic.filters?.brandIds?.[0] ?? authStore?.currentBrand?.id;
      }
      const res = await socialListeningApi.createTopic(payload);
      this.topicList.push(res.data.data);
      return res.data.data;
    },
    getUserAccessibleBrandsWithCompetitorsAccess() {
      const authStore = useAuthStore();
      const permissions = authStore.identity?.permissions;
      const identityBrands = authStore.identity?.brands || {};
      if (!isEmpty(identityBrands)) {
        return Object.values(identityBrands).filter(
          (brand) => permissions.competitive[brand.label].can_access_competitors,
        );
      }
      return [];
    },
    async fetchCompetitors(isEdit, selectedBrandIds) {
      // create a new topic: fetch competitors of brands selected in previous step
      // edit an existing topic: fetch competitors of all user accessible brands in the org that have competitor access
      const brandIds =
        selectedBrandIds?.length > 0
          ? selectedBrandIds
          : this.getUserAccessibleBrandsWithCompetitorsAccess().map((b) => b.id);
      const sourceList =
        !isEmpty(this.filters?.sources) && isEdit
          ? this.filters.sources
          : SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT;
      const authStore = useAuthStore();
      const accessibleSelectedBrandIds = intersection(
        brandIds,
        Object.values(authStore.identity.brands).map((brand) => brand.id),
      );
      try {
        let competitors = [];

        if (accessibleSelectedBrandIds.length > 0) {
          const response = await LibraryAPI.apiGetCompetitors({
            payload: {
              brandIds: accessibleSelectedBrandIds,
              sourceList,
            },
          });
          const cleanData = humps
            .camelizeKeys(response.data)
            .filter((c) => c.handle && c.avatarUrl && c.sourceAccountId);

          competitors = cleanData.map((c) => ({
            id: `${c.source}:${c.sourceAccountId}`,
            name: c.handle,
            avatarUrl: c.avatarUrl,
            channel: CHANNELS[c.source].channelIcon,
          }));
        }

        // edit competitors
        if (isEdit && !(competitors.length === brandIds.length)) {
          // Need to fetch by source_and_creator_ids
          return this.addPublicCompetitors(competitors);
        }

        return competitors;
      } catch {
        return [];
      }
    },
    async addPublicCompetitors(savedCompetitors) {
      const availableIds = savedCompetitors.map((c) => c.id);
      const unavailableIds = {
        [CHANNELS.INSTAGRAM.value]: [],
        [CHANNELS.TWITTER.value]: [],
        [CHANNELS.YOUTUBE.value]: [],
      };
      // check if library competitor list includes all saved competitor ids
      difference(this.filters.sourceAndCreatorIds, availableIds).forEach((sourceAndCreatorId) => {
        const [channel, accountId] = sourceAndCreatorId.split(':');
        unavailableIds[channel].push(accountId);
      });
      // call api to get public account of unavailable ids
      await Promise.all(
        Object.entries(unavailableIds)
          .filter(([, ids]) => !isEmpty(ids))
          .map(async ([channel, ids]) => {
            const accounts = await this.getPublicAccountsByIds(channel, ids);
            savedCompetitors.push(...accounts);
          }),
      );
      return savedCompetitors;
    },
    async getPublicAccountsByIds(channel, ids) {
      let response;

      switch (channel) {
        case CHANNELS.INSTAGRAM.value:
          response = await InstagramAPI.getPublicAccountsByIds({ instagramIds: ids });
          return response.data.map((r) => ({
            id: `${channel}:${r.instagramId}`,
            name: r.handle,
            avatarUrl: r.avatar,
            channel: CHANNELS.INSTAGRAM.channelIcon,
          }));
        case CHANNELS.TWITTER.value:
          response = await TwitterAPI.getPublicAccountsByIds({ twitterUserIds: ids });
          return response.data.data.map((r) => ({
            id: `${channel}:${r.twitterUserId}`,
            name: r.handle,
            avatarUrl: r.twitterAvatar,
            channel: CHANNELS.TWITTER.channelIcon,
          }));
        case CHANNELS.YOUTUBE.value:
          response = await YouTubeAPI.getPublicAccountsByIds({ sourceChannelIds: ids });
          return response.data.map((r) => ({
            id: `${channel}:${r.sourceChannelId}`,
            name: r.title,
            avatarUrl: r.thumbnailUrl,
            channel: CHANNELS.YOUTUBE.channelIcon,
          }));
        default:
          return [];
      }
    },
    async updateSourceAndCreatorIds(competitorIds) {
      const updated = await this.updateSavedTopic({
        searchBody: { filters: { ...this.filters, sourceAndCreatorIds: competitorIds } },
      });
      if (updated) {
        this.filters = { ...this.filters, sourceAndCreatorIds: competitorIds };
      }
    },
    togglePopup(name, value) {
      if (!name) {
        return;
      }
      const newPopupValue = value ?? !this.popups[name].show;
      if (name === 'createTopic') {
        // set instance id fire off mixpanel event
        this.createTopicInstanceId = newPopupValue ? uuidv4() : null;
      }
      this.popups[name].show = newPopupValue;
    },
    updateKeywordGroups(newGroups) {
      this.keywordGroups = newGroups;
    },
    updateTeaserKeywordGroups(newGroups) {
      this.teaserKeywordGroups = newGroups;
    },
    async editTopicKeywords(topicId, socketId) {
      const groups = this.keywordGroups.filter((group) => group.keywords?.length);
      const keywordsAndHashtags = groups && groups?.length ? parseToQuery(groups) : undefined;
      const updated = await this.updateSavedTopic({
        searchBody: { filters: { ...this.filters, keywordsAndHashtags } },
      });
      const notificationStore = useNotificationStore();
      if (updated) {
        notificationStore.setToast({
          message: EDIT_TOPIC_KEYWORD_TOAST_MESSAGES.SAVED,
        });
        this.unsavedKeywordChanges = false;
      }
      await this.updateComponentData({
        topicId,
        socketId,
      });
    },
    async getTimeSeriesPostVolume(payload, graphType, cancelToken, teaserEndpoint = false) {
      this.setPostVolumePending(true, graphType);
      const useEstimates = this.getUseEstimatesParam(teaserEndpoint);
      try {
        const organizationId = await this.getOrganizationId();
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserTimeSeriesPostVolume
          : socialListeningApi.getTimeSeriesPostVolume;
        const res = await getFunction({ organizationId, payload, useEstimates }, { cancelToken });
        const data = res.data.data;
        this.setPostVolumeData(data, graphType);
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.setPostVolumeData({}, graphType);
        }
      } finally {
        this.setPostVolumePending(false, graphType);
      }
    },
    async getVisualTrendById(trendId) {
      const organizationId = await this.getOrganizationId();
      const res = await socialListeningApi.getTrendGroup({
        organizationId,
        trendId,
      });
      return res.data.data;
    },
    async getTrendGroups(trendIds) {
      if (isEmpty(trendIds)) {
        return;
      }

      this.pending.trendGroups = true;
      this.errors.trendGroups = false;
      const promises = trendIds.map((id) => this.getVisualTrendById(id));
      const groups = [];
      const errors = [];

      Promise.allSettled(promises)
        .then((results) => {
          results.forEach((result) => {
            if (result.value) {
              groups.push(result.value);
            } else if (result.status === 'rejected') {
              errors.push(true);
            }
          });
        })
        .finally(() => {
          this.trendGroups = groups;
          this.pending.trendGroups = false;
          this.errors.trendGroups = errors.length === promises.length;
        });
    },
    clearPostVolumeTimeSeriesData() {
      this.postsByChannelTimeseries = {};
      this.postsBySentimentTimeseries = {};
      this.postsByMediaTypeTimeseries = {};
      this.totalPostsTimeseries = {};
    },
    setPostVolumeData(data, graphType) {
      if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL.value) {
        this.postsByChannelTimeseries = data;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value) {
        this.postsBySentimentTimeseries = data;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE.value) {
        this.postsByMediaTypeTimeseries = data;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS.value) {
        this.totalPostsTimeseries = data;
      }
    },
    setPostVolumePending(value, graphType) {
      if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL.value) {
        this.pending.postsByChannelTimeseries = value;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value) {
        this.pending.postsBySentimentTimeseries = value;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_MEDIA_TYPE.value) {
        this.pending.postsByMediaTypeTimeseries = value;
      } else if (graphType === POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS.value) {
        this.pending.totalPostsTimeseries = value;
      }
    },
    updateVisualFilters(include, mediaItem, index, deleteCount = 0) {
      const [key, verb] = include ? ['includes', 'include'] : ['doesNotInclude', 'exclude'];
      const idx = index ?? this.visualFilters[key].length;
      const totalVisualFilters = Object.values(this.visualFilters).reduce(
        (prev, next) => prev + next.length,
        0,
      );
      const isDuplicate =
        deleteCount === 0 && this.visualFilters[key].some((item) => item.id === mediaItem?.id);
      if (isDuplicate) {
        throw new Error(`You have already ${verb}d this media`);
      } else if (
        mediaItem &&
        totalVisualFilters >= VISUAL_FILTER_SEARCH_MAX_MEDIA &&
        deleteCount === 0
      ) {
        throw new Error(`You may only ${verb} up to ${VISUAL_FILTER_SEARCH_MAX_MEDIA} media`);
      } else if (mediaItem && include) {
        this.visualFilters.includes.splice(idx, deleteCount, mediaItem);
      } else if (mediaItem && !include) {
        this.visualFilters.doesNotInclude.splice(idx, deleteCount, mediaItem);
        // remove excluded media from includes if it was included before
        this.visualFilters.includes = this.visualFilters.includes.filter(
          (item) => item.id !== mediaItem.id,
        );
      }
    },
    removeVisualFilters(index, include) {
      const key = include ? 'includes' : 'doesNotInclude';
      this.visualFilters[key].splice(index, 1);
    },
    async fetchSavedMedia(isIncludes, data, sourceIds) {
      const brandSourceToSourceIdMap = data.reduce((acc, { libraryMediaDetails }) => {
        if (libraryMediaDetails) {
          const [brandId, source, sourceId] = libraryMediaDetails.split(':');
          return {
            ...acc,
            [brandId]: {
              ...(acc[brandId] ?? {}),
              [source]: [...(acc?.[brandId]?.[source] ?? []), sourceId],
            },
          };
        }
        return acc;
      }, {});
      await Promise.all(
        Object.keys(brandSourceToSourceIdMap).map(async (brandId) => {
          const brandIdMap = brandSourceToSourceIdMap[brandId];
          await Promise.all(
            Object.keys(brandIdMap).map(async (source) => {
              try {
                const res = await LibraryAPI.getBrandMediaListBySource({
                  brandId,
                  source,
                  sourceIds: brandIdMap[source],
                });
                if (res?.data?.length) {
                  res.data.forEach((mediaItem) => {
                    const media = humps.camelizeKeys(mediaItem);
                    const item = {
                      id: `${brandId ?? ''}:${source}:${media.sourceId}`,
                      filterType: VISUAL_DATA_TYPES.LIBRARY,
                    };
                    this.updateVisualFilters(isIncludes, { ...media, ...item });
                  });
                }
              } catch {
                const key = isIncludes ? 'includes' : 'doesNotInclude';
                // remove all visual filter items from the store that failed to fetch
                this.visualFilters[key] = this.visualFilters[key].filter((filter) =>
                  brandIdMap[source].includes(filter.id.split(':').slice(-1)),
                );
              }
            }),
          );
        }),
      );
      data.forEach((mediaItem) => {
        if (mediaItem.data) {
          // Uploaded media
          this.updateVisualFilters(isIncludes, {
            id: mediaItem.data,
            previewUrl: mediaItem.data,
            uploadStatus: UPLOAD_STATUS.SUCCESS,
            filterType: VISUAL_DATA_TYPES.UPLOAD,
            mediaType: 'IMAGE',
          });
        } else if (mediaItem.sourceAndId) {
          // Trends posts
          sourceIds.push(mediaItem.sourceAndId);
          this.updateVisualFilters(isIncludes, {
            id: mediaItem.sourceAndId,
            filterType: VISUAL_DATA_TYPES.TRENDS,
          });
        }
      });
    },
    async loadSavedVisualFilters() {
      this.pending.loadVisualData = true;
      this.visualFilters = { includes: [], doesNotInclude: [] };
      if (isEmpty(this.filters?.visualData)) {
        this.pending.loadVisualData = false;
        return;
      }
      // keep track of the original saved order of visual filter items
      // we use this sort order later since library media are fetched and loaded separately
      const includesDataPositionMap = getElementPositionMap(this.filters.visualData.includes);
      const excludesDataPositionMap = getElementPositionMap(this.filters.visualData.doesNotInclude);
      try {
        const sourceIds = [];
        const visualData = this.filters.visualData || {};
        if (visualData.includes?.length) {
          await this.fetchSavedMedia(true, visualData.includes, sourceIds);
        }
        if (visualData.doesNotInclude?.length) {
          await this.fetchSavedMedia(false, visualData.doesNotInclude, sourceIds);
        }
        if (isEmpty(sourceIds)) {
          // Need to trigger the watcher again for uploaded media
          this.visualFilters = { ...this.visualFilters };
          this.pending.loadVisualData = false;
          return;
        }
        const filters = { sourceAndIds: sourceIds };
        const organizationId = this.getOrganizationId();

        try {
          const res = await socialListeningApi.getSearchResults({
            organizationId,
            payload: {
              filters,
            },
          });
          if (!res.data) {
            return;
          }
          // adds mediaType and sizes to all media returned from /search
          const mediaList = humps.camelizeKeys(res.data.data);
          mediaList.forEach((media) => {
            const id = `${media.source}:${media.sourceId}`;
            const cb = (item) => item.id === id;
            const includesIndex = this.visualFilters.includes.findIndex(cb);
            const excludesIndex = this.visualFilters.doesNotInclude.findIndex(cb);
            if (includesIndex >= 0) {
              this.updateVisualFilters(
                true,
                {
                  ...this.visualFilters.includes[includesIndex],
                  mediaType: media.mediaType,
                  sizes: media.urls.imageSizes || media.urls.videoSizes,
                },
                includesIndex,
                1,
              );
            }
            if (excludesIndex >= 0) {
              this.updateVisualFilters(
                false,
                {
                  ...this.visualFilters.doesNotInclude[excludesIndex],
                  mediaType: media.mediaType,
                  sizes: media.urls.imageSizes || media.urls.videoSizes,
                },
                excludesIndex,
                1,
              );
            }
          });
        } catch (error) {
          sourceIds.forEach((id) => {
            const cb = (item) => item.id === id;
            const includesIndex = this.visualFilters.includes.findIndex(cb);
            const excludesIndex = this.visualFilters.doesNotInclude.findIndex(cb);
            if (includesIndex >= 0) {
              this.updateVisualFilters(
                true,
                {
                  ...this.visualFilters.includes[includesIndex],
                  error: true,
                },
                includesIndex,
                1,
              );
            }
            if (excludesIndex >= 0) {
              this.updateVisualFilters(
                false,
                { ...this.visualFilters.doesNotInclude[excludesIndex], error: true },
                excludesIndex,
                1,
              );
            }
          });
        }
      } finally {
        // apply original sort order
        this.visualFilters.includes.sort(function sort(left, right) {
          return includesDataPositionMap[left.id] - includesDataPositionMap[right.id];
        });
        this.visualFilters.doesNotInclude.sort(function sort(left, right) {
          return excludesDataPositionMap[left.id] - excludesDataPositionMap[right.id];
        });
        this.pending.loadVisualData = false;
      }
    },
    formatVisualData() {
      const callback = (item) => ({ [item.filterType]: item.id });
      const includes = this.visualFilters.includes.map(callback);
      const doesNotInclude = this.visualFilters.doesNotInclude.map(callback);
      return {
        includes,
        doesNotInclude,
      };
    },
    async updateTopicVisualData(updateTopic = true) {
      const visualData = this.formatVisualData();
      const updated = updateTopic
        ? await this.updateSavedTopic({
            searchBody: { filters: { ...this.filters, visualData } },
          })
        : true;
      if (updated) {
        this.filters = { ...this.filters, visualData };
      }
    },
    async getVisualTrends(payload, teaserEndpoint = false) {
      const organizationId = await this.getOrganizationId();
      try {
        this.pending.visualTrends = true;
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserVisualTrends
          : socialListeningApi.getVisualTrends;
        const res = await getFunction({ organizationId, payload });
        if (res.status === HttpStatusCode.Ok) {
          this.visualTrends = res.data?.data || [];
          this.pending.visualTrends = false;
        } else {
          this.visualTrendsRequestId = payload?.requestId || null;
        }
      } catch {
        this.visualTrends = [];
        this.pending.visualTrends = false;
      }
    },
    onClusterTaskNotification(visualTrendsData) {
      // If data is being sent back from an old request, we want to ignore it
      if (visualTrendsData.request_id === this.visualTrendsRequestId) {
        const trends = visualTrendsData?.buckets || [];
        this.visualTrends = trends.map((trend) => camelCaseObjDeep(trend));
      }
      this.pending.visualTrends = false;
    },
    async getVisualTrendWithOffset(trendId, offset, limit, teaserEndpoint = false) {
      const organizationId = await this.getOrganizationId();
      try {
        this.pending.visualTrend = true;
        const getFunction = teaserEndpoint
          ? socialListeningApi.getTeaserTrendGroup
          : socialListeningApi.getTrendGroup;
        const res = await getFunction({ organizationId, trendId, offset, limit });
        if (offset) {
          const oldContent = [...this.visualTrend.content];
          const newTrend = res.data.data;
          newTrend.content = oldContent.concat(newTrend.content);
          this.visualTrend = newTrend;
        } else {
          this.visualTrend = res.data.data;
        }
      } catch {
        this.visualTrend = {};
      } finally {
        this.pending.visualTrend = false;
      }
    },
    formatVisualFilters(filters) {
      // Formats visual filters for refined results queries
      // Exclude TEXT, LINK from refined results, since they cannot produce visual filter results
      const mediaFilterExcludes = [
        SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.LINK.value,
        SOCIAL_LISTENING_MEDIA_TYPE_OPTIONS.TEXT.value,
      ];
      const excludes = union(filters.mediaTypes?.doesNotInclude || [], mediaFilterExcludes);
      if (!filters.mediaTypes) {
        filters.mediaTypes = {};
      }
      filters.mediaTypes.doesNotInclude = excludes;
      if (filters?.mediaTypes?.includes) {
        // Simplify includes since we don't want to include & exclude the same things
        filters.mediaTypes.includes = difference(filters.mediaTypes.includes, excludes);
      }
    },
    async fetchRefinedResultsStats() {
      const organizationId = await this.getOrganizationId();
      const visualData = this.formatVisualData();

      this.pending.visualDataTotalResultsCount = true;
      try {
        const { topicId, searchFilters } = this.getBaseSearchParams(visualData);
        const filters = { ...searchFilters, visualData };

        this.formatVisualFilters(filters);
        const res = await socialListeningApi.getTopicStats({
          organizationId,
          topicId,
          searchFilters: filters,
        });
        return res.data.data;
      } catch {
        return {};
      } finally {
        this.pending.visualDataTotalResultsCount = false;
      }
    },
    async fetchRefinedResultsMedia() {
      const organizationId = await this.getOrganizationId();
      const visualData = this.formatVisualData();
      if (isEmpty(visualData.includes) && isEmpty(visualData.doesNotInclude)) {
        return [];
      }
      this.pending.visualDataRefinedResults = true;
      try {
        const { topicId, searchFilters } = this.getBaseSearchParams(visualData);
        const filters = { ...searchFilters, visualData };
        this.formatVisualFilters(filters);

        const res = await socialListeningApi.getSearchResults({
          organizationId,
          payload: {
            topicId,
            filters,
            paging: { limit: 100, offset: 0 },
          },
        });
        return res.data?.data || [];
      } catch {
        return [];
      } finally {
        this.pending.visualDataRefinedResults = false;
      }
    },
    updateUnsavedFilters(filters = {}) {
      this.unsavedFilters = filters;
    },
    updateKeywordFilter(filters = {}) {
      this.topicKeywordFilter = {
        ...this.topicKeywordFilter,
        ...filters,
      };
    },
    clearUnsavedTopicKeywordFilter() {
      this.updateUnsavedFilters();
      this.updateKeywordFilter({
        word: null,
        rule: KEYWORD_GROUP_TYPE.INCLUDES,
      });
    },
    updateMixpanelProps(name, value) {
      if (this.mixpanelProps?.[name]) {
        this.mixpanelProps[name] = value;
      }
    },
    logMixpanelEvent(event, properties) {
      const trackingStore = useTrackingStore();
      trackingStore.track(event, properties);
    },
    addKeywordInputError(index) {
      this.keywordInputErrors.splice(-1, 0, index);
    },
    removeKeywordInputError(index) {
      this.keywordInputErrors = this.keywordInputErrors.filter((item) => item !== index);
    },
    clearOverviewData() {
      this.topPostsPreview = [];
      this.visualTrends = [];
      this.postVolume = {};
      this.clearPostVolumeTimeSeriesData();
    },
    resetTopicBuilder() {
      this.plainTextMode = false;
      this.keywordBooleanExpressionErrors = [];
      this.keywordInputErrors = [];
    },
    async getWebResultsPreview(topicId, shouldRetry = false) {
      const organizationId = await this.getOrganizationId();
      this.pending.webResultsPreview = true;
      try {
        const res = await socialListeningApi.getWebResults({
          organizationId,
          topicId,
          limit: WEB_RESULT_PREVIEW_MAX_POSTS,
        });
        this.webResultsPreview = res.data.data;
      } catch (error) {
        if (axios.isCancel(error)) return;
        this.webResultsPreview = [];
      }
      if (!shouldRetry) {
        this.pending.webResultsPreview = false;
      }
    },
    async getWebResults(topicId, offsetValue = null) {
      const organizationId = await this.getOrganizationId();
      this.pending.webResults = true;
      try {
        const res = await socialListeningApi.getWebResults({
          organizationId,
          topicId,
          limit: WEB_RESULT_PAGE_INITIAL_MAX_POSTS,
          offset: offsetValue,
        });
        if (offsetValue) {
          this.webResults = this.webResults.concat(res.data.data);
        } else {
          this.webResults = res.data.data;
        }
      } catch {
        this.webResults = [];
      }
      this.pending.webResults = false;
    },
    async getTopicMonitors(topicId) {
      const organizationId = await this.getOrganizationId();
      this.pending.topicMonitors = true;
      try {
        const res = await socialListeningApi.getTopicMonitors({
          organizationId,
          topicId,
        });
        this.topicMonitors = res.data.data;
      } catch {
        this.topicMonitors = [];
      } finally {
        this.pending.topicMonitors = false;
      }
    },
    async getTopicMonitorEvents(topicId, monitorId) {
      const organizationId = await this.getOrganizationId();
      const { startDate, endDate } = this.getAnomalyDateRange();
      if (isEqual(startDate, endDate)) this.topicMonitorEvents = {};
      else {
        try {
          const res = await socialListeningApi.getTopicMonitorEvents({
            organizationId,
            topicId,
            monitorId,
            startDate,
            endDate,
          });
          this.topicMonitorEvents = res.data.data;
        } catch {
          this.topicMonitorEvents = {};
        }
      }
    },
    getAnomalyDateRange() {
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
      const { onOrAfter, onOrBefore } = this.filters.sourceCreated;
      let filtersLastUpdatedAt = this.selectedTopic.filtersLastUpdatedAt;
      let startDate;
      let endDate;

      if (filtersLastUpdatedAt === null) {
        startDate = onOrAfter;
        endDate = onOrBefore;
      } else {
        // filtersLastUpdatedAt is received as UTC, so we need to convert it before
        // using it in the request to get topic monitor events
        filtersLastUpdatedAt = dayjs
          .utc(filtersLastUpdatedAt)
          .tz(getUserTimezone())
          .format(dateFormat);
        startDate = dayjs(onOrAfter).isAfter(filtersLastUpdatedAt)
          ? onOrAfter
          : filtersLastUpdatedAt;
        endDate = dayjs(onOrBefore).isAfter(filtersLastUpdatedAt)
          ? onOrBefore
          : filtersLastUpdatedAt;
      }
      startDate = dayjs(startDate).format(dateFormat);
      endDate = dayjs(endDate).format(dateFormat);
      return { startDate, endDate };
    },
    calculateScale() {
      if (!this.filters?.sourceCreated) return GRAPH_SCALE.DAY;
      const start = dayjs(this.filters.sourceCreated.onOrAfter);
      const end = dayjs(this.filters.sourceCreated.onOrBefore);
      const diff = end.diff(start, 'day');
      if (diff <= 1) {
        return GRAPH_SCALE.HOUR;
      }
      if (diff > 1 && diff <= 30) {
        return GRAPH_SCALE.DAY;
      }
      if (diff > 30 && diff <= 90) {
        return GRAPH_SCALE.WEEK;
      }
      return GRAPH_SCALE.MONTH;
    },
    clearWebResults() {
      this.webResults = [];
      this.webResultsPreview = [];
    },

    async getOrganizationUsers() {
      const authStore = useAuthStore();
      const currentOrganizationId = this.getOrganizationId();
      const orgAccessibleBrands = Object.values(authStore.identity?.brands ?? {})
        .filter((brand) => brand.organizationId === currentOrganizationId)
        .map((brand) => brand.id);
      const responses = await Promise.allSettled(
        orgAccessibleBrands.map((brandId) => {
          return AuthAPI.getBrandUsers({ brandId, limit: 1000 });
        }),
      );
      const allUsers = flatMap(responses, (response) => response?.value?.data?.data || []);
      const uniqueUsers = new Set();
      this.organizationUsers = allUsers.filter((user) => {
        const isDuplicate = uniqueUsers.has(user.id);
        uniqueUsers.add(user.id);
        return !isDuplicate;
      });
    },
    async createOrUpdateTopicMonitor({ alertRecipients, muted, sensitivity, topicId }) {
      let previousAlert;
      try {
        this.pending.topicMonitorCreateOrUpdate = true;
        if (topicId === this.selectedTopic?.id && this.hasTopicMonitors) {
          const res = await socialListeningApi.updateTopicMonitor({
            topicId: this.selectedTopic.id,
            organizationId: this.getOrganizationId(),
            topicMonitorId: this.topicMonitors[0].id,
            alertRecipients,
            muted,
            sensitivity: ALERT_SENSITIVITY_THRESHOLDS[sensitivity],
          });
          previousAlert = this.topicMonitors.splice(0, 1, res.data.data);
        } else {
          const res = await socialListeningApi.createTopicMonitor({
            topicId: topicId ?? this.selectedTopic.id,
            organizationId: this.getOrganizationId(),
            alertRecipients,
            muted,
            sensitivity: ALERT_SENSITIVITY_THRESHOLDS[sensitivity],
          });
          this.topicMonitors.splice(this.topicMonitors.length, 0, res.data.data);
        }
      } finally {
        this.pending.topicMonitorCreateOrUpdate = false;
      }
      return { updatedAlert: this.topicMonitors[0], previousAlert: previousAlert?.[0] ?? {} };
    },
    toggleCreateTopicPopup() {
      const popup =
        this.hasExceededTopicLimit || this.hasExceededMentionLimit ? 'manageUsage' : 'createTopic';
      this.togglePopup(popup);
    },
    updateProcessExpressionPending(newValue) {
      this.pending.processExpression = newValue;
    },
    async fetchSuggestedTopicsForOrganization() {
      const organizationId = this.getOrganizationId();
      try {
        const res = await socialListeningApi.getSuggestedTopicsForOrganization(organizationId);

        this.suggestedTopics = res.data.data.map((topic) => {
          if (topic.meta && Array.isArray(topic.meta.mediaUrls)) {
            topic.meta.mediaUrls = topic.meta.mediaUrls.filter((url) => url.trim() !== '');
          }
          return topic;
        });
      } catch {
        this.suggestedTopics = [];
      }
    },
  },
});
