/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 22a1 1 0 01-1-1v-7H5a1 1 0 01-.78-1.625l8-10A1 1 0 0114 3v7h5a1 1 0 01.78 1.625l-8 10A1 1 0 0111 22zM7.081 12H11a1 1 0 011 1v5.15L16.919 12H13a1 1 0 01-1-1V5.85L7.081 12z"/>'
  }
})
