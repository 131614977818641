/**
 * Compute new text and position when inserting a mention
 *
 * @param {string} text Current text into which we are inserting a mention
 * @param {number} cursorPosition Current position of the cursor
 * @param {string} username The username of the mention to insert
 * @returns {{cursorPosition: number, text: string}} New text and cursor position after insert
 */
export const insertMentionIntoText = ({ text, cursorPosition, username }) => {
  const before = text.slice(0, cursorPosition - 1).replace(/\w*$/, '');
  const after = text.slice(cursorPosition - 1).replace(/^\w*/, '') || ' ';
  return {
    cursorPosition: before.length + username.length + 2,
    text: `${before}${username}${after}`,
  };
};
