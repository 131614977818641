/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-banned': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.387 4.094a.507.507 0 00.031.031l2.04 2.04a.499.499 0 00.023.024l7.499 7.498a.5.5 0 10.707-.707l-.75-.75A6.503 6.503 0 003.77 3.063l-.75-.75a.5.5 0 00-.707.707l1.074 1.074zm8.84 7.426A5.501 5.501 0 004.48 3.772L5.696 4.99A2.795 2.795 0 018 3.779a2.834 2.834 0 012.834 2.835c0 .962-.482 1.798-1.21 2.304l2.604 2.603zM8.9 8.194c.56-.313.934-.9.934-1.58C9.834 5.602 9.013 4.78 8 4.78c-.68 0-1.267.374-1.58.933l2.48 2.48zm-4.27 2.64a2.503 2.503 0 011.698-.665H8a.5.5 0 110 1H6.328c-.377 0-.74.142-1.019.398l-.043.041a1.65 1.65 0 00-.411.6l-.096.24a5.507 5.507 0 005.711.466.5.5 0 11.45.894 6.501 6.501 0 01-7.517-1.21 6.501 6.501 0 01-1.21-7.514.5.5 0 01.894.449 5.502 5.502 0 00.88 6.209 2.65 2.65 0 01.618-.867l.044-.041z" _fill="#000"/>'
  }
})
