/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alarm-timeout': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.555 22h6.89c-.693 1.19-1.968 2-3.445 2-1.477 0-2.752-.81-3.445-2zm9.592-6.193a3.383 3.383 0 002.169 2.245l.684.227V21H0v-2.721l.684-.227A3.385 3.385 0 003 14.838V12c0-2.983 2.19-5.458 5.046-5.917A9.26 9.26 0 008 7c0 .379.031.751.077 1.119C6.317 8.538 5 10.114 5 12v2.838c0 1.648-.745 3.16-1.965 4.161h11.931a5.372 5.372 0 01-1.953-3.94 8.9 8.9 0 002.134.748zM17 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm3 6h-4V3h2v3h2v2z" _fill="#000" fill-rule="nonzero"/>'
  }
})
