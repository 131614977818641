<template>
  <div ref="dropzoneElement" data-cy="dropzone-element">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import Dropzone from 'dropzone';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import enumTypes from '@/app/library/constants';
import { axios } from '@/apis/library';
import SocketsMixin from '@/mixins/socketsMixin';
import { useSocketStore } from '@/stores/socket';
import { logger } from '@/utils/logger';

Dropzone.autoDiscover = false;

const imageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/tiff',
];
const videoTypes = [
  'video/mp4',
  'video/avi',
  'video/quicktime',
  'video/mov',
  'video/webm',
  'video/m4v',
  'video/MP4V-ES',
];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Dropzone',
  mixins: [SocketsMixin],
  props: {
    onAccept: { type: Function, default: null },
    onError: { type: Function, default: null },
    onAddedFile: { type: Function, default: null },
    onPreviewGenerated: { type: Function, default: null },
    onProcessing: { type: Function, default: null },
    onConverted: { type: Function, default: null },
    onUploadProgress: { type: Function, default: null },
    // Called when the file has been uploaded successfully.
    onSuccess: { type: Function, default: null },
    // Called when the upload was either successful or erroneous. Occurs after onSuccess.
    onComplete: { type: Function, default: null },
    onProcessed: { type: Function, default: null },
    addToLibrary: { type: Boolean, default: true },
    acceptVideo: { type: Boolean, default: true },
    acceptImage: { type: Boolean, default: true },
    maxFiles: { type: Number, default: null },
    thumbnail: { type: Boolean, default: true },
    maxFilesize: { type: Number, default: 1024 },
    resize: { type: Boolean, default: false },
    unacceptableFiles: { type: Array, default: () => [] },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapStores(useSocketStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'token', 'identity']),
    acceptedFiles() {
      const acceptedFiles = [
        ...(this.acceptImage ? imageTypes : []),
        ...(this.acceptVideo ? videoTypes : []),
      ];
      return acceptedFiles.filter((file) => !this.unacceptableFiles.includes(file)).join(', ');
    },
  },
  watch: {
    acceptedFiles(val) {
      if (this.dropzone) {
        this.dropzone.hiddenFileInput.setAttribute('accept', val);
      }
    },
  },
  mounted() {
    const options = {
      // The URL will be changed for each new file being processing
      url: '/',

      // Since we're going to do a `PUT` upload to S3 directly
      method: 'put',

      // Hijack the xhr.send since Dropzone always upload file by using formData and S3 signed URLs expect raw files
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        const originalSend = xhr.send;
        // eslint-disable-next-line
        xhr.send = (formData) => {
          if (formData.has && formData.has('file')) {
            originalSend.call(xhr, formData.get('file'));
          } else {
            originalSend.call(xhr, file);
          }
        };
      },

      // Maximum upload file size in mb
      maxFilesize: this.maxFilesize,

      // When the filesize exceeds this limit, the thumbnail will not be generated (in mb)
      maxThumbnailFilesize: 30,

      // Maximum number of files that can be selected by file selector
      maxFiles: this.maxFiles,

      // Upload two file at a time. On slow connections, uploading too many files in parallel can
      // saturate the user's connection and bring upload speed to a crawl.
      parallelUploads: 2,
      uploadMultiple: false,
      timeout: 3600000, // 60 minutes
      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: '',

      // We're using our own 'preview'
      previewsContainer: false,

      acceptedFiles: this.acceptedFiles,

      // Append hidden input to dropzone element (default is 'body') so that click-outside handlers
      // aren't accidentally triggered when input is clicked in openFileDialog() method below.
      hiddenInputContainer: this.$refs.dropzoneElement,

      resizeWidth: this.resize ? 400 : null,
      resizeHeight: this.resize ? 400 : null,

      createImageThumbnails: this.thumbnail,
      thumbnailWidth: this.thumbnail ? 400 : null,
      thumbnailHeight: this.thumbnail ? 400 : null,
      thumbnailMethod: 'contain',

      // Here we request a signed upload URL when a file being accepted
      accept: (file, done) => {
        if (this.onAccept) {
          this.onAccept(file);
        }
        const params = { filename: file.name, content_type: file.type };
        axios
          .post(`/media_upload_url`, params)
          .then((res) => {
            // Dropzone doesn't provide a clean way to assign parameters to the incoming file
            // so we just assign the signed URL to the file object directly and ignore eslint.
            file.uploadURL = res.data;
            done();
          })
          .catch((err) => {
            done('Failed to get an S3 signed upload URL', err);
          });
      },
    };

    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$refs.dropzoneElement, options);

    this.dropzone.on('addedfile', (file) => {
      if (this.onAddedFile) {
        this.onAddedFile(file);
      }
    });

    this.dropzone.on('thumbnail', (file, dataURL) => {
      // Sometimes dataURL is returns from dropzone as an event, we don't want those
      if (!(dataURL instanceof Event)) {
        if (this.onPreviewGenerated) this.onPreviewGenerated(file, dataURL);
      }
    });

    this.dropzone.on('processing', (file) => {
      // Set signed upload URL for each file
      this.dropzone.options.url = file.uploadURL;
      if (this.onProcessing) this.onProcessing(file);
    });

    this.dropzone.on('uploadprogress', (file, progress, bytesSent) => {
      if (this.onUploadProgress) this.onUploadProgress(file, progress, bytesSent);
    });

    this.dropzone.on('success', async (file) => {
      if (!this.addToLibrary) {
        if (this.onSuccess) this.onSuccess(file);
        return;
      }

      // Create a media object in the library for the uploaded file
      const payload = {
        url: file.uploadURL.split('?')[0],
        source: 'UPLOAD',
        type: 'UPLOADED',
        source_id: file.upload.uuid,
        source_created_at: new Date(),
        meta: {
          filename: file.name,
          uploaded_by: this.identity.id,
          last_modified_at: file.lastModifiedDate,
        },
        socket_id: this.socketStore.id,
      };
      if (file.fullPath) {
        payload.meta.fullPath = file.fullPath;
      }
      await axios
        .post(`/brands/${this.currentBrand.id}/media`, payload)
        .then((res) => {
          if (this.onSuccess) this.onSuccess(file, res.data);
          this.trackingStore.track('Media Uploaded');
        })
        .catch(() => {
          if (this.onError) {
            this.onError(file);
          }
        });
    });

    this.dropzone.on('complete', (file) => {
      if (this.onComplete) this.onComplete(file);
    });

    this.dropzone.on('error', (file) => {
      if (this.onError) {
        this.onError(file);
      }
    });
  },
  sockets: {
    convert_video_callback(videoData) {
      logger.info('convert_video_callback websocket received.', videoData, '');
      if (videoData.source === enumTypes.UPLOAD && this.onConverted) {
        this.onConverted(videoData);
      }
    },
    media_processed(data) {
      if (this.onProcessed) {
        this.onProcessed(data);
      }
    },
  },
  methods: {
    openFileDialog() {
      this.dropzone.hiddenFileInput.click();
    },
    removeFile(media) {
      const fileToBeRemoved = this.dropzone.files.find((file) => file.upload.uuid === media.uuid);
      if (fileToBeRemoved) {
        this.dropzone.removeFile(fileToBeRemoved);
      }
    },
    reset() {
      this.dropzone.removeAllFiles(true);
    },
  },
});
export default comp;
</script>
