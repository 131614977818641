import {
  COMMUNITY_INTERACTION_TYPES,
  COMMUNITY_INTERACTION_ACTIONS,
  COMMUNITY_INTERACTION_ERROR_TOAST,
  COMMUNITY_GENERIC_TYPES,
  COMMUNITY_INTERACTION_ADDITIONAL_HIDE_INFO,
  COMMUNITY_INTERACTION_PLATFORM_BY_TYPE,
} from '@/app/community/constants';
import { useAuthStore } from '@/stores/auth';

export function getFollowerHandleFromInteraction(interaction) {
  switch (interaction?.type) {
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT:
      return interaction?.instagramUsername;
    case COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION:
      return interaction.followerParticipant?.username;
    case COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT:
      return interaction.tiktokUsername;
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION:
      return interaction.fbFollowerParticipant?.username;
    case COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT:
      return interaction.facebookUsername;
    case COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT:
      return interaction.authorUsername;
    default:
      return 'UNKNOWN';
  }
}

export function getInteractionGenericType(interactionType) {
  if (
    interactionType?.includes(COMMUNITY_GENERIC_TYPES.CONVERSATION) ||
    interactionType?.includes('DM')
  ) {
    return 'Conversation';
  }
  if (interactionType?.includes(COMMUNITY_GENERIC_TYPES.COMMENT)) {
    return 'Comment';
  }
  if (
    [
      COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION,
      COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET,
      COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE,
    ].includes(interactionType)
  ) {
    return 'Post';
  }
  return 'Message';
}

export function getToastForInteractionType(
  interactionType,
  action,
  count = null,
  actionDetails = {},
) {
  let interactionBucketType = getInteractionGenericType(interactionType);

  if (count && count > 0) {
    interactionBucketType = `${count} ${interactionBucketType.toLowerCase()}${
      count > 1 ? 's' : ''
    }`;
  }

  const additionalHideInfo = COMMUNITY_INTERACTION_ADDITIONAL_HIDE_INFO[interactionType] || '';

  switch (action) {
    case COMMUNITY_INTERACTION_ACTIONS.ARCHIVE:
      return `${interactionBucketType} moved to Archive`;
    case COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE:
      return `${interactionBucketType} moved to Inbox`;
    case COMMUNITY_INTERACTION_ACTIONS.MARK_AS_FOLLOW_UP:
      return `${interactionBucketType} marked as Follow Up`;
    case COMMUNITY_INTERACTION_ACTIONS.REMOVE_FOLLOW_UP:
      return `Removed Follow Up from ${interactionBucketType}`;
    case COMMUNITY_INTERACTION_ACTIONS.UNREAD:
      return `${interactionBucketType} marked as Unread`;
    case COMMUNITY_INTERACTION_ACTIONS.READ:
      return `${interactionBucketType} marked as Read`;
    case COMMUNITY_INTERACTION_ACTIONS.POSTED:
      return `${interactionBucketType} posted`;
    case COMMUNITY_INTERACTION_ACTIONS.DELETE:
      return `${interactionBucketType} deleted`;
    case COMMUNITY_INTERACTION_ACTIONS.ADD_TAGS:
      return `Your tags have been added to ${interactionBucketType}`;
    case COMMUNITY_INTERACTION_ACTIONS.HIDE:
      return `User hidden. ${additionalHideInfo}`;
    case COMMUNITY_INTERACTION_ACTIONS.ASSIGN:
      return `${interactionBucketType} assigned to @${actionDetails.username}`;
    case COMMUNITY_INTERACTION_ACTIONS.UNASSIGN:
      return `${interactionBucketType} unassigned`;
    default:
      return COMMUNITY_INTERACTION_ERROR_TOAST;
  }
}

export function getPlatformByType(interaction) {
  const { platform = 'UNKNOWN', messageType = 'UNKNOWN' } =
    COMMUNITY_INTERACTION_PLATFORM_BY_TYPE?.[interaction?.type ?? interaction] ?? {};
  return {
    platform,
    messageType,
  };
}

export function getInteractionUrl(interaction) {
  const authStore = useAuthStore();
  const searchParams = new URLSearchParams({ id: interaction?.id });
  const url = new URL(
    `/${authStore.currentBrand?.label}/community/all-messages?${searchParams.toString()}`,
    window.location.origin,
  );
  return url.href;
}

export function isInteractionMediaCommentType(interaction) {
  const commentTypes = Object.values(COMMUNITY_INTERACTION_TYPES).filter((val) =>
    val.endsWith('_COMMENT'),
  );
  if (interaction?.type === COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION && interaction?.mediaId) {
    return true;
  }
  return commentTypes.includes(interaction?.type);
}
