<template>
  <div
    v-tooltip="disabledTooltip"
    class="product-list-item"
    :data-cy="`ProductListItem-${product.fbProductId}`"
  >
    <div class="product-photo">
      <SkeletonLoader
        v-if="!imageLoaded || !product.imageUrl"
        type="picture"
        :loading="true"
        :no-animation="!product.imageUrl"
      />
      <Picture
        v-if="product.imageUrl"
        :class="{ 'product-invalid': product.error }"
        :url="product.imageUrl"
        :width="150"
        :height="150"
        fit="cover"
      />
      <Icon v-if="product.error" :color="colours.ERROR.ERROR_500" name="alert-triangle" />
    </div>
    <div :class="{ 'product-invalid': product.error }" class="product-info">
      <AvailabilityLabel class="text-extra-small" :availability="product.availability" />
      <ReviewStatusLabel
        v-if="product.reviewStatus === productReviewStatusTypes.REJECTED"
        :review-status="product.reviewStatus"
      />
      <p v-if="product.title" v-line-clamp="3" class="text-main product-title">
        {{ product.title }}
      </p>
      <p v-else v-line-clamp="3" class="text-main product-title">Product Unavailable</p>
      <p v-if="product.variantAttributes" class="product-variant">{{ displayVariants }}</p>
      <p class="product-price text-main text-secondary">
        <span v-if="product.salePrice" class="sale-price">{{ product.salePrice }}</span>
        <span :class="{ 'original-price': product.salePrice }">{{ product.price }}</span>
      </p>
    </div>
    <Icon
      v-if="product.error || !product.title"
      class="delete-icon"
      :color="colours.ICON.ICON_SECONDARY"
      name="close"
      @click="() => $emit('deleteTag')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { productReviewStatusTypes } from '@/app/library/constants';
import AvailabilityLabel from '@/components/core/products/AvailabilityLabel.vue';
import Picture from '@/components/foundation/Picture.vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import ReviewStatusLabel from '@/components/core/products/ReviewStatusLabel.vue';
import Icon from '@/components/foundation/Icon.vue';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
/**
 * Displays a product as a list item.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ProductListItem',
  components: {
    ReviewStatusLabel,
    AvailabilityLabel,
    Picture,
    SkeletonLoader,
    Icon,
  },
  props: {
    /**
     * Product object to display.  Assumes properties are camalized.
     */
    product: {
      type: Object,
      default: null,
    },
  },
  emits: ['deleteTag'],
  data() {
    return {
      imageLoaded: false,
      productReviewStatusTypes,
    };
  },
  computed: {
    colours() {
      return colours;
    },
    displayVariants() {
      return this.product.variantAttributes
        .map((variant) => `${variant.label}: ${variant.value}`)
        .join(', ');
    },
    disabledTooltip() {
      return this.product.error ? toolTips.productTagIssue : null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.product-invalid {
  opacity: 0.25;
}

.product-list-item {
  display: flex;
  text-align: left;
  width: 100%;
  min-height: var(--height-100);

  .product-photo {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 0 0 100px;
    margin-right: var(--space-24);
    justify-content: center;
    border-radius: var(--round-corner-small);
    position: relative;

    :deep(img) {
      max-height: var(--height-100);
      object-fit: contain;
    }

    svg {
      position: absolute;
      opacity: 1;
    }
  }

  .delete-icon {
    align-self: center;
    cursor: pointer;
  }

  .product-info {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :not(:last-child) {
      margin-bottom: var(--space-4);
    }

    .product-title {
      overflow-wrap: break-word;
      font-weight: var(--font-medium);
    }

    .product-variant {
      font-size: var(--x14);
    }

    .product-price {
      font-size: var(--x14);

      .sale-price {
        color: var(--error-500);
        margin: 0 var(--space-6) 0 0;
      }

      .original-price {
        text-decoration: line-through;
      }
    }
  }
}
</style>
