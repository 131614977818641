/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'heart': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M8.5 15.06a.945.945 0 01-.5-.143c-.327-.204-8-5.042-8-10.21C0 2.112 2.118 0 4.722 0c1.506 0 2.9.788 3.778 1.958C9.378.789 10.772 0 12.278 0 14.882 0 17 2.112 17 4.707c0 5.168-7.673 10.006-8 10.21a.945.945 0 01-.5.143zM4.722 1.883A2.832 2.832 0 001.89 4.707c0 3.165 4.385 6.783 6.611 8.288 2.226-1.505 6.611-5.122 6.611-8.288a2.832 2.832 0 00-2.833-2.824c-1.595 0-2.834 1.517-2.834 2.824 0 .52-.422.941-.944.941a.943.943 0 01-.944-.941c0-1.307-1.239-2.824-2.834-2.824z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
