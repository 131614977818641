/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'megaphoneDynamic': {
    width: 21,
    height: 22,
    viewBox: '0 0 21 22',
    data: '<defs><path pid="0" d="M8 7.621v2.17l-6 1.412v2.535l16 3.766V11h2v9.942h-2v-1.27l-6.73-1.581-2.471 3.486A.983.983 0 018 22l-.122-.008-.121-.023-4-1.059a1.046 1.046 0 01-.75-.908L3 19.882v-3.738l-1-.236v.798H0v-8.47h2v.798L8 7.62zm-3 8.992v2.442l2.598.689 1.529-2.16L5 16.614zM18 0l3 2-3 2V3h-1.52l-1.2 1.5 1.2 1.5H18V5l3 2-3 2V8h-2.48L14 6.1 12.48 8H10V6h1.52l1.2-1.5-1.2-1.5H10V1h2.48L14 2.899 15.52 1H18V0z" id="svgicon_megaphoneDynamic_a"/></defs><g _fill="none" fill-rule="evenodd"><g><mask id="svgicon_megaphoneDynamic_b" _fill="#fff"><use xlink:href="#svgicon_megaphoneDynamic_a"/></mask><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_megaphoneDynamic_a"/></g></g>'
  }
})
