/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tiktok': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12.483 0c.33 2.838 1.914 4.53 4.668 4.71v3.192c-1.596.156-2.994-.366-4.62-1.35v5.97c0 7.585-8.268 9.955-11.593 4.519-2.136-3.499-.828-9.637 6.025-9.883v3.366a9.914 9.914 0 00-1.59.39c-1.524.516-2.388 1.482-2.148 3.186.462 3.265 6.45 4.23 5.952-2.148V.006h3.306V0z" id="svgicon_tiktok_a"/></defs><use _fill="#000" xlink:href="#svgicon_tiktok_a" transform="translate(3.43 2)" fill-rule="evenodd"/>'
  }
})
