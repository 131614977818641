/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'prompt-icon': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M3.15 7.426a.473.473 0 10.67-.67l-.67.67zM1.773 4.712a.473.473 0 10-.669.67l.669-.67zm-.658-.01a.473.473 0 00.647.689l-.647-.69zM3.809 3.47a.473.473 0 10-.648-.69l.648.69zM1.437 4.573a.473.473 0 000 .947v-.947zm5.14.474l.002-.474h-.002v.474zm1.27-.522l.334.335-.334-.335zM8.85 3.259a.473.473 0 00-.946-.002l.946.002zM3.82 6.756L1.772 4.712l-.669.67 2.048 2.044.668-.67zM1.761 5.391L3.81 3.47l-.648-.69-2.047 1.921.647.69zm-.323.129h5.14v-.947h-5.14v.947zm5.138 0a2.267 2.267 0 001.605-.66l-.667-.67a1.32 1.32 0 01-.935.383l-.003.947zm1.605-.66c.426-.424.667-1 .668-1.6l-.946-.003a1.32 1.32 0 01-.39.932l.668.671z" _fill="#fff"/>'
  }
})
