/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'radioButtonOff': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="9" _fill="#fff" _stroke="#DDD" stroke-width="2"/>'
  }
})
