/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'notification': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<defs><path pid="0" d="M5.555 16c.693 1.19 1.969 2 3.445 2 1.476 0 2.752-.81 3.445-2h-6.89zM18 15H0v-2.721l.684-.227A3.385 3.385 0 003 8.838V6c0-3.309 2.691-6 6-6s6 2.691 6 6v2.838c0 1.46.931 2.751 2.316 3.214l.684.227V15zM3.035 13h11.931A5.376 5.376 0 0113 8.838V6c0-2.206-1.794-4-4-4S5 3.794 5 6v2.838c0 1.648-.745 3.16-1.965 4.162z" id="svgicon_notification_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon_notification_b" _fill="#fff"><use xlink:href="#svgicon_notification_a"/></mask><use _fill="#686a7a" xlink:href="#svgicon_notification_a"/><g mask="url(#svgicon_notification_b)"><path pid="1" _fill="#686a7a" fill-rule="nonzero" d="M-6-7h32v32H-6z"/></g></g>'
  }
})
