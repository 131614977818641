import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import * as VisionAiAPI from '@/apis/vision-ai';
import { logger } from '@/utils/logger';
import {
  SUMMARY_INTENT_TYPES,
  INTENT_TYPES,
  VISION_AI_WIDGET_INTERACTIONS,
} from '@/components/VisionAi/constants';
import {
  getStartTimeForApiRequest,
  getEndTimeForApiRequest,
  groupConversationsByDate,
  orderByDate,
  processMessages,
} from '@/utils/vision-ai';
import { useNotificationStore } from '@/stores/notification';
import { camelizeKeys } from 'humps';
import { aiWidgetInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';

export const useVisionAiConversationsStore = defineStore('visionAiConversationsStore', () => {
  const notificationStore = useNotificationStore();

  // States
  const pending = ref({
    conversations: false,
    conversationMessages: false,
    notificationPopupOpen: false,
    updatingConversation: false,
    deletingConversation: false,
  });
  const conversations = ref([]);
  const conversationMessages = ref([]);
  const conversationModes = reactive({
    renameConversation: false,
  });
  const renameSelectedConversationId = ref(null);
  const conversationMessagesNextPageUrl = ref(null);
  const currentConversationId = ref(null);
  const summariesSelected = ref(false);
  const loadingConversationIds = ref([]);

  // Functions

  function setCurrentConversationId(conversationId) {
    currentConversationId.value = conversationId;
  }

  function setConversations(newConversations) {
    conversations.value = newConversations;
  }

  function resetToDefaultConversationState() {
    Object.keys(conversationModes).forEach((conversationMode) => {
      conversationModes[conversationMode] = false;
    });
    renameSelectedConversationId.value = null;
    conversationMessagesNextPageUrl.value = null;
    summariesSelected.value = false;
    currentConversationId.value = null;
  }

  async function getConversations({
    intentTypes = INTENT_TYPES.METRIC_ANALYTICS,
    firstMessageStartTime = null,
    firstMessageEndTime = null,
  }) {
    pending.value.conversations = true;

    const conversationStartTime = getStartTimeForApiRequest(firstMessageStartTime);
    const conversationEndTime = getEndTimeForApiRequest(firstMessageEndTime);

    try {
      const response = await VisionAiAPI.getConversations({
        firstMessageStartTime: conversationStartTime,
        firstMessageEndTime: conversationEndTime,
        intentTypes,
      });
      conversations.value = response?.data?.data;
    } catch (error) {
      logger.error('Failed to create Vision Ai conversation', {}, error);
      throw error;
    } finally {
      pending.value.conversations = false;
    }
  }

  async function createConversation() {
    pending.value.conversation = true;

    try {
      const response = await VisionAiAPI.createConversation();
      if (!summariesSelected.value) {
        setCurrentConversationId(response.data.id);
      } else {
        setCurrentConversationId(null);
      }
      return response?.data;
    } catch (error) {
      pending.value.summarize = false;
      logger.error('Failed to create Vision Ai conversation', {}, error);
      throw error;
    } finally {
      pending.value.conversation = false;
    }
  }

  function addConversationWebSocket(conversationData) {
    conversationData.updates.forEach((conversationUpdate) => {
      if (summariesSelected.value) {
        return;
      }
      const camelizeConversationUpdate = camelizeKeys(conversationUpdate);
      if (!camelizeConversationUpdate.name) {
        camelizeConversationUpdate.name = 'New Chat';
      }
      const existingConversation = conversations.value.find(
        (conversation) => conversation.id === camelizeConversationUpdate.id,
      );

      if (!existingConversation) {
        conversations.value.push(camelizeConversationUpdate);
      }
    });
  }

  function deleteConversationsWebSocket(conversationsData) {
    conversationsData.updates.forEach((conversationUpdate) => {
      const conversationIndex = conversations.value.findIndex(
        (c) => c.id === conversationUpdate.id,
      );
      if (conversationIndex !== -1) {
        conversations.value.splice(conversationIndex, 1);
      }
    });
  }

  function updateConversationWebSocket(conversationData) {
    conversationData.updates.forEach((conversationUpdate) => {
      const conversationIndex = conversations.value.findIndex(
        (c) => c.id === conversationUpdate.id,
      );
      if (conversationIndex !== -1) {
        conversations.value.splice(conversationIndex, 1, camelizeKeys(conversationUpdate));
      }
    });
  }

  const groupedConversations = computed(() => {
    const nonSummaryConversations = conversations.value.filter(
      (conversation) => !SUMMARY_INTENT_TYPES.includes(conversation.intentType),
    );
    const conversationGrouped = groupConversationsByDate(nonSummaryConversations);
    return orderByDate(conversationGrouped, 'conversations', ['desc']);
  });

  async function getConversationMessages({ conversationId }) {
    pending.value.conversationMessages = true;

    try {
      const response = await VisionAiAPI.getConversationMessages({ conversationId });
      conversationMessages.value = processMessages(response.data.data);
      conversationMessagesNextPageUrl.value = response?.data?.paging?.next;
    } catch (error) {
      pending.value.summarize = false;
      logger.error('Failed to get Vision Ai conversation messages', {}, error);
      throw error;
    } finally {
      pending.value.conversationMessages = false;
    }
  }

  function openRenameConversationWidget({ conversationId }) {
    pending.value.notificationPopupOpen = true;
    conversationModes.renameConversation = true;
    renameSelectedConversationId.value = conversationId;
  }

  function closeRenameConversationWidget() {
    pending.value.notificationPopupOpen = false;
    conversationModes.renameConversation = false;
    renameSelectedConversationId.value = null;
  }

  async function deleteConversation({ conversationId, isSummary }) {
    pending.value.deletingConversation = true;
    try {
      if (isSummary) {
        await VisionAiAPI.deleteSummaryMessages();
      } else {
        await VisionAiAPI.deleteConversation({ conversationId });
      }
      if (currentConversationId.value === conversationId) {
        setCurrentConversationId(null);
      }
    } catch (error) {
      logger.error('Failed to clear vision ai messages', {}, error);
      throw error;
    } finally {
      pending.value.deletingConversation = false;
    }
  }

  async function deleteConversationClicked({ conversationId, isSummary }) {
    pending.value.notificationPopupOpen = true;

    if (
      await notificationStore.confirm(
        'Delete Chat?',
        'This will permanently delete it from your history. Are you sure you want to proceed?',
        {
          confirmAlias: 'Delete',
          onCancel: () => {
            pending.value.notificationPopupOpen = false;
          },
          onConfirm: () => {
            pending.value.notificationPopupOpen = false;
            const mixpanelInteraction = isSummary
              ? VISION_AI_WIDGET_INTERACTIONS.DELETE_SUMMARIES
              : VISION_AI_WIDGET_INTERACTIONS.DELETE_SINGLE_CHAT;
            aiWidgetInteractionEvent({
              interaction: mixpanelInteraction,
            });
          },
        },
      )
    ) {
      try {
        await deleteConversation({ conversationId, isSummary });
      } catch (error) {
        notificationStore.setToast({
          message: 'Your chat history could not be deleted.',
          type: 'error',
        });
      }
    }
  }

  async function updateConversationName({ conversationId, name }) {
    pending.value.updatingConversation = true;

    try {
      const response = await VisionAiAPI.updateConversation({
        conversationId,
        name,
      });

      return response?.data;
    } catch (error) {
      pending.value.updatingConversation = false;
      logger.error('Failed to update Vision Ai conversation name', {}, error);
      throw error;
    } finally {
      pending.value.updatingConversation = false;
      closeRenameConversationWidget();
    }
  }

  function setSummariesSelectedValue(selected) {
    summariesSelected.value = selected;
  }

  function selectSummaries() {
    setCurrentConversationId(null);
    setSummariesSelectedValue(true);
  }

  const isCurrentConversationEmpty = computed(() => {
    const currentConversation = conversations.value.find(
      (conversation) => conversation.id === currentConversationId.value,
    );
    return !currentConversation?.firstMessageAt;
  });

  function addLoadingConversationIds(conversationId) {
    if (conversationId === null) return;
    loadingConversationIds.value.push(conversationId);
  }

  function removeLoadingConversationIds(conversationId) {
    loadingConversationIds.value = loadingConversationIds.value.filter(
      (id) => id !== conversationId,
    );
  }

  const isMessageLoadingInCurrentConversation = computed(() => {
    return loadingConversationIds.value.includes(currentConversationId.value);
  });

  return {
    // states
    conversationMessages,
    conversationMessagesNextPageUrl,
    conversationModes,
    conversations,
    currentConversationId,
    groupedConversations,
    isCurrentConversationEmpty,
    isMessageLoadingInCurrentConversation,
    loadingConversationIds,
    pending,
    renameSelectedConversationId,
    summariesSelected,

    // methods
    addConversationWebSocket,
    addLoadingConversationIds,
    closeRenameConversationWidget,
    createConversation,
    deleteConversation,
    deleteConversationClicked,
    deleteConversationsWebSocket,
    getConversationMessages,
    getConversations,
    openRenameConversationWidget,
    removeLoadingConversationIds,
    resetToDefaultConversationState,
    selectSummaries,
    setConversations,
    setCurrentConversationId,
    setSummariesSelectedValue,
    updateConversationName,
    updateConversationWebSocket,
  };
});
