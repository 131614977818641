<script setup>
import Icon from '@/components/foundation/Icon.vue';
import { computed, toRefs } from 'vue';
import { toolTips } from '@/config';
import { colours, VISION_AI } from '@/ux/colours';
import VisionToolbar from '@/components/VisionAi/VisionToolbar.vue';
import { getPropsForIntentType, MESSAGE_WIDGET_MAP } from '@/components/VisionAi/utils/message';
import CircularLoader from '@/components/CircularLoader.vue';
import { aiMessageInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';
import {
  INTENT_TYPES,
  VISION_AI_SUMMARY_INTERACTIONS,
  REGENERATE_TOOLTIPS,
  SUMMARY_INTENT_TYPES,
} from '@/components/VisionAi/constants';
import { useIdentityStore } from '@/stores/identity';
import Button from '@/components/foundation/Button.vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import AssistantStyleWrapper from '@/components/VisionAi/AssistantStyleWrapper.vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  messageId: {
    type: Number,
    default: null,
  },
  time: {
    type: String,
    default: '',
  },
  date: {
    type: String,
    default: '',
  },
  sourceUrl: {
    type: [String, Object],
    default: null,
  },
  regeneratingMessageLoading: {
    type: Boolean,
    default: false,
  },
  disableRegenerateButton: {
    type: Boolean,
    default: null,
  },
  intentType: {
    type: String,
    default: '',
  },
  additionalContent: {
    type: Object,
    default: null,
  },
  intentQuery: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'regenerateMessage',
  'copyResponse',
  'sourceButtonClicked',
  'expandMedia',
]);
const { sourceUrl, intentType } = toRefs(props);

// stores
const identityStore = useIdentityStore();
const visionAiStore = useVisionAiStore();

// consts
const disclaimerToolTipMessage = toolTips.visionAi.disclaimerMessage;

// computed
const dateTooltipMessage = computed(() => `${props.date} at ${props.time}`);

const regenerateResponseTooltip = computed(() => {
  return props.regeneratingMessageLoading || props.disableRegenerateButton
    ? REGENERATE_TOOLTIPS.LOADING
    : REGENERATE_TOOLTIPS.READY;
});

const isSamePageSource = computed(() => {
  return visionAiStore.checkIfSamePageSource(sourceUrl.value);
});

const isMetricsAnalytics = computed(() => {
  return props.intentType === INTENT_TYPES.METRIC_ANALYTICS;
});

const isSummaryIntent = computed(() => {
  return SUMMARY_INTENT_TYPES.includes(props.intentType);
});

const sourceTooltipMessage = computed(() => {
  return isSamePageSource.value
    ? toolTips.visionAi.disabledSourceButton
    : toolTips.visionAi.activeSourceButton;
});

const showRegenerateButton = computed(() => {
  if (isSummaryIntent.value) return true;

  const mostRecentMessage = visionAiStore.messages?.[visionAiStore.messages.length - 1];
  return props.messageId === mostRecentMessage?.id;
});

const assistantToolbarIcons = computed(() => {
  const icons = [
    {
      iconName: 'copy',
      iconColor: colours.TEXT.TEXT_LINK,
      tooltip: 'Copy response',
      showBorderOnHover: true,
      buttonClass: 'hover:bg-opacity-80',
      clickEvent: () => {
        aiMessageInteractionEvent({
          interaction: VISION_AI_SUMMARY_INTERACTIONS.COPY,
          intentType: props.intentType,
          messageId: props.messageId,
        });
        emit('copyResponse', props.text);
      },
      iconWidth: '18.67px',
      iconHeight: '18.67px',
    },
  ];
  if (showRegenerateButton.value) {
    icons.push({
      iconName: 'replay-2',
      iconColor: colours.TEXT.TEXT_LINK,
      iconHoverColor: colours.TEXT.TEXT_LINK,
      tooltip: regenerateResponseTooltip.value,
      showBorderOnHover: true,
      buttonClass: 'hover:bg-opacity-80 m-0',
      clickEvent: () => {
        aiMessageInteractionEvent({
          interaction: VISION_AI_SUMMARY_INTERACTIONS.REGENERATE,
          intentType: props.intentType,
          messageId: props.messageId,
        });
        emit('regenerateMessage', props.messageId);
      },
      iconWidth: '19.42px',
      iconHeight: '16.67px',
      iconLoading: props.regeneratingMessageLoading,
      disabled: props.disableRegenerateButton,
    });
  }
  return icons;
});

const widgetComponent = computed(() => {
  return MESSAGE_WIDGET_MAP[props.intentType];
});

const widgetProps = computed(() => {
  const content = { ...props.additionalContent };

  if (props.intentType === INTENT_TYPES.COMMENTS) {
    const brandLabel = props.intentQuery?.sourceLinkParams?.brandLabel;
    const brandAvatarUrl = identityStore.identity.brands[brandLabel]?.avatarUrl;

    if (content?.media?.source && content.media.source.split('_')[0] === 'TWITTER') {
      content.brandAvatarUrl = content.media.userAvatar || brandAvatarUrl;
    } else {
      content.brandAvatarUrl = brandAvatarUrl;
    }
  }
  const additionalProps = {
    isExpanded: visionAiStore.popupIsExpanded,
    hasConversationSidebarExpanded: visionAiStore.showConversationHistorySidebar,
  };

  return getPropsForIntentType(props.intentType, content, props.intentQuery, additionalProps);
});

const showExpandViewButton = computed(
  () => !visionAiStore.popupIsExpanded && visionAiStore.canAccessConversational,
);
// methods
function handleSourceButtonClicked() {
  emit('sourceButtonClicked', intentType.value, sourceUrl.value);
  aiMessageInteractionEvent({
    interaction: VISION_AI_SUMMARY_INTERACTIONS.SOURCE_LINK,
    intentType: props.intentType,
    messageId: props.messageId,
  });
}

function handleDownloadButtonClicked() {
  aiMessageInteractionEvent({
    interaction: VISION_AI_SUMMARY_INTERACTIONS.DOWNLOAD_MEDIA,
    intentType: props.intentType,
    messageId: props.messageId,
  });
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        disclaimerToolTipMessage,
        isSamePageSource,
        sourceTooltipMessage,
        isMetricsAnalytics,
      }
    : {},
);
</script>

<template>
  <AssistantStyleWrapper class="w-full" data-cy="assistant-message">
    <span v-sanitize-html="props.text" class="sanitize-html-string" />

    <VisionToolbar :clickable-icons="assistantToolbarIcons">
      <template
        #clickableIcons="{
          iconName,
          iconColor,
          iconHeight,
          iconWidth,
          iconHoverColor,
          iconLoading,
        }"
      >
        <CircularLoader v-if="iconLoading" />
        <Icon
          v-else
          :name="iconName"
          :color="iconColor"
          :hover-color="iconHoverColor"
          :height="iconHeight"
          :width="iconWidth"
        />
      </template>
    </VisionToolbar>

    <template v-if="props.additionalContent">
      <div class="h-[1px] w-full bg-black bg-opacity-[12%]"></div>
      <component
        :is="widgetComponent"
        v-bind="widgetProps"
        @download-button-clicked="handleDownloadButtonClicked"
      >
      </component>
    </template>

    <Button
      v-if="showExpandViewButton"
      link
      class="cursor-pointer text-[--text-link] no-underline"
      data-cy="expand-view-button"
      @click="emit('expandMedia')"
      >Expand to view report</Button
    >

    <VisionToolbar class="toolbar w-full">
      <template #leftIcons>
        <div class="flex w-60 items-center gap-3">
          <div v-tooltip="dateTooltipMessage" class="text-extra-small cursor-default">
            {{ props.time }}
          </div>

          <div
            v-if="props.sourceUrl && !isMetricsAnalytics"
            v-tooltip="sourceTooltipMessage"
            class="flex items-center"
          >
            <Button
              id="source-url"
              :to="props.sourceUrl"
              class="flex items-center no-underline"
              aria-label="View source for this summary"
              :style="{ color: VISION_AI.VISION_AI_03 }"
              :disabled="isSamePageSource"
              link
              :class="{ disabled: isSamePageSource }"
              @click="handleSourceButtonClicked"
            >
              <Icon name="link" xsmall :color="VISION_AI.VISION_AI_03" />
              <span class="text-extra-small-medium ml-1">Source</span>
            </Button>
          </div>
          <Icon
            v-tooltip="{
              content: disclaimerToolTipMessage,
              theme: 'dh-tooltip-small',
            }"
            name="info"
            xsmall
          />
        </div>
      </template>
    </VisionToolbar>
  </AssistantStyleWrapper>
</template>

<style lang="postcss" scoped>
.sanitize-html-string {
  :deep() {
    b,
    strong {
      font-weight: bold;
      font-size: inherit;
    }

    p:not(:last-child) {
      margin-bottom: var(--space-16);
    }

    i,
    em {
      font-style: italic;
      font-size: inherit;
    }

    ul {
      list-style-type: disc;
      padding-left: var(--space-20);
    }
  }
}

.toolbar {
  .disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>
