import { defineStore } from 'pinia';
import { camelizeKeys } from 'humps';
import axios from 'axios';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import communityApi, * as CommunityAPI from '@/apis/community';
import * as collaborationApi from '@/apis/collaboration';
import {
  COMMUNITY_ASSIGNMENT_COMMENT_TEXT,
  COMMUNITY_COMMENT_RESOURCE_TYPES,
  COMMUNITY_INTERACTION_ACTIONS,
  COMMUNITY_INTERACTION_PLATFORM_BY_TYPE,
  COMMUNITY_ROUTE_NAMES,
  MAX_RECENT_BRAND_USERS,
} from '@/app/community/constants';
import { DM_PLATFORM_MAP, getDMPlatform } from '@/app/community/utils/dms';
import { useAuthStore } from '@/stores/auth';
import { useIdentityStore } from '@/stores/identity';
import { internalMessageFormatter } from '@/app/community/utils/comments';
import { logger } from '@/utils/logger';
import { parseTimestamps } from '@/app/community/utils/time';
import { useNotificationStore } from '@/stores/notification';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { getToastForInteractionType } from '@/app/community/utils/community-interactions';
import { refreshCancelToken } from '@/apis/axios.utils';
import { useTrackingStore } from '@/stores/tracking';

export const useCommunityStore = defineStore('community', {
  resetOnBrandChange: true,
  state: () => ({
    composerPresetReplyDisabled: false,
    presetReplyList: [],
    presetReply: null,
    pending: {
      instagramAccount: false,
      presetReplyList: false,
      internalMessages: false,
      internalMessagesPaging: false,
    },
    internalMessages: null,
    unreadInternalNotesCount: null,
    internalMessagesPaging: {},
    internalMessagesReferencedUsers: {},
    communityAssignments: [],
    internalNotesQueries: {},
    instagramAccount: null,
  }),
  actions: {
    async getInstagramAccount(brandId) {
      this.pending.instagramAccount = true;
      const res = await communityApi.getPlatformAccount({
        platform: DM_PLATFORM_MAP.INSTAGRAM_CONVERSATION,
        brandId,
      });
      this.instagramAccount = res.data.data;
      this.pending.instagramAccount = false;
    },
    async getPresetReplyList(brandId, type) {
      this.pending.presetReplyList = true;
      const res = await communityApi.getPresetReplyList({ brandId, type });
      this.presetReplyList = res.data.data;
      this.pending.presetReplyList = false;
      return res;
    },
    async createPresetReply(presetReply) {
      const res = await communityApi.createPresetReply({ payload: presetReply?.data });
      this.presetReplyList.push(res.data.data);
      return res;
    },
    async updatePresetReply({ replyId, data }) {
      const res = await communityApi.updatePresetReply({ replyId, payload: data });
      const updatedData = res?.data?.data;
      if (updatedData) {
        this.presetReplyList = this.presetReplyList.map((item) => {
          return item.id === updatedData.id ? updatedData : item;
        });
      }
      return res;
    },
    async deletePresetReply(replyId) {
      const res = await communityApi.deletePresetReply(replyId);
      this.presetReplyList = this.presetReplyList.filter((reply) => reply.id !== replyId);
      return res;
    },
    async clearPresetReplyList() {
      this.presetReplyList = [];
    },
    async setSelectedPresetReply(reply) {
      this.presetReply = reply;
    },
    async toggleComposerPresetReplyDisabled(disabled) {
      this.composerPresetReplyDisabled = disabled;
    },
    async getCommentList({ platform, brandId, mediaId, limit }) {
      const platformType =
        COMMUNITY_INTERACTION_PLATFORM_BY_TYPE[platform]?.platform?.toLowerCase() ?? platform;

      const response = await CommunityAPI.getCommentInteractionList({
        platform: platformType,
        brandId,
        mediaId,
        limit,
      });
      const payload = response.data;
      return payload.data;
    },
    async getCommentDetail({ platform, communityInteractionId, returnParent }) {
      const platformType =
        COMMUNITY_INTERACTION_PLATFORM_BY_TYPE[platform]?.platform?.toLowerCase() ?? platform;

      const response = await CommunityAPI.getCommentInteractionDetail({
        platform: platformType,
        communityInteractionId,
        returnParent,
      });
      const payload = response.data;
      return payload;
    },
    async getMessages({ platform, communityInteractionId, limit }) {
      const platformType = getDMPlatform(platform);
      const response = await communityApi.getPlatformMessages({
        platform: platformType,
        id: communityInteractionId,
        limit,
      });
      const payload = response.data;
      return payload.data;
    },
    clearInternalMessages() {
      this.internalMessages = null;
      this.internalMessagesPaging = {};
      this.internalMessagesReferencedUsers = {};
    },
    async getInternalMessages(params = {}, isPaging = false) {
      const axiosConfig = !isPaging
        ? { cancelToken: refreshCancelToken(this, 'internalMessagesCancelToken') }
        : {};
      // join any array type params
      const processedParams = Object.fromEntries(
        Object.entries(params).map(([key, value]) => {
          if (value instanceof Array) {
            if (value.length === 0) {
              return [];
            }
            return [key, value.join(',')];
          }
          return [key, value];
        }),
      );
      const identityStore = useIdentityStore();
      const brandId = identityStore.currentBrand?.id;

      const pendingKey = isPaging ? 'internalMessagesPaging' : 'internalMessages';
      if (this.pending[pendingKey] === processedParams.resourceIds) {
        return;
      }

      try {
        const existingIds = (this.internalMessages || []).map((m) => m.id);

        this.pending[pendingKey] = processedParams.resourceIds;
        const response = await collaborationApi.getBrandComments(
          { ...processedParams, brandId },
          axiosConfig,
        );
        const { data, paging, referencedObjects } = response.data;
        this.internalMessages = [
          ...(this.internalMessages || []),
          ...data.filter((m) => !existingIds.includes(m.id)),
        ];
        this.internalMessagesPaging = paging;
        this.internalMessagesReferencedUsers = referencedObjects.user;
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        logger.error(`Failed to get brand comments for brand: ${brandId}`);
      } finally {
        this.pending[pendingKey] = false;
      }
    },
    async createInternalNote({ resourceId, text, meta }) {
      const identityStore = useIdentityStore();
      const brandId = identityStore.currentBrand.id;
      const res = await collaborationApi.createComment(
        {
          brandId,
          resourceId,
          resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE,
          text,
          meta,
        },
        {},
      );
      const { data, referencedObjects } = camelizeKeys(res.data);
      const updatedReferencedObjects = merge(
        this.internalMessagesReferencedUsers,
        referencedObjects?.user ?? {},
      );
      this.internalMessages = this.internalMessages.concat(data);
      this.internalMessagesReferencedUsers = updatedReferencedObjects;
      // current user is selected for mentioned user filter as default
      // do not update count on the internal notes page since we don't refresh the notes after creating a new one
      const trackingStore = useTrackingStore();
      const mentionedUserRegex = new RegExp(`@[${identityStore.identity?.id}]*`);
      if (
        trackingStore.currentRoute?.name !== COMMUNITY_ROUTE_NAMES.INTERNAL_NOTES &&
        mentionedUserRegex.test(text)
      ) {
        this.unreadInternalNotesCount += 1;
      }
      return data;
    },
    async createCommunityAssignment(
      { brandId, interactionId, assignmentPayload },
      showSuccessToast = false,
      showErrorToast = true,
    ) {
      const interactionStore = useCommunityInteractionStore();
      const authStore = useAuthStore();
      const notificationStore = useNotificationStore();
      // Find interaction and do optimistic update
      const originalInteraction = interactionStore.communityInteractions.find(
        (interaction) => interaction.id === interactionId,
      );
      const { assigneeId: originalAssignee, type: interactionType } = originalInteraction;
      originalInteraction.assigneeId = assignmentPayload.assignedToUser;

      const { allMessagesChange, assignedToMeChange, inboxChange } =
        interactionStore.calculateAssigneeCountChanges(
          assignmentPayload.assignedToUser,
          originalAssignee,
          originalInteraction,
        );
      interactionStore.updateInteractionCounts(allMessagesChange, assignedToMeChange, inboxChange);

      let data;
      try {
        const res = await communityApi.createAssignment(brandId, interactionId, assignmentPayload);
        data = res.data.data;
      } catch (err) {
        if (showErrorToast) {
          notificationStore.setToast({
            message: 'Unable to assign message. Please try again.',
            type: 'error',
          });
        }
        logger.error(`Failed to create community assignment for brand ${brandId}`, {}, err);

        // Reverse optimistic update
        interactionStore.communityInteractions.find(
          (interaction) => interaction.id === interactionId,
        ).assigneeId = originalAssignee;

        const {
          allMessagesChange: reversedAllMessagesChange,
          assignedToMeChange: reversedAssignedToMeChange,
          inboxChange: reversedInboxChange,
        } = interactionStore.calculateAssigneeCountChanges(
          assignmentPayload.assignedToUser,
          originalAssignee,
          originalInteraction,
          true,
        );
        interactionStore.updateInteractionCounts(
          reversedAllMessagesChange,
          reversedAssignedToMeChange,
          reversedInboxChange,
        );
        throw err;
      }

      const newAssignment = {
        id: data.id,
        brandId,
        communityInteractionId: interactionId,
        assignedToUser: assignmentPayload.assignedToUser,
        assignedByUser: assignmentPayload.assignedByUser,
        createdAt: data.createdAt,
        userId: assignmentPayload.assignedByUser,
        resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT,
        text: `@[${assignmentPayload.assignedToUser}]`,
        meta: {
          channel: interactionType.split('_')[0],
          messageType: interactionType.split('_')[1],
        },
      };

      const newAssignee = assignmentPayload.assignedToUser;
      const communityInteraction = interactionStore.communityInteractions.find(
        (interaction) => interaction.id === interactionId,
      );
      if (communityInteraction) {
        communityInteraction.assigneeId = newAssignee;
      }

      const brandUser =
        authStore.brandAccessibleUsers.filter(
          (user) => user.id === assignmentPayload.assignedToUser,
        )[0] || {};
      const { firstName, lastName, avatarUrl } = brandUser;

      if (!showSuccessToast) {
        if (!newAssignment.assignedToUser) {
          // Unassigned
          newAssignment.text = COMMUNITY_ASSIGNMENT_COMMENT_TEXT.UNASSIGNED;
        } else {
          this.internalMessagesReferencedUsers = merge(this.internalMessagesReferencedUsers, {
            [newAssignment.assignedToUser]: { firstName, lastName, avatarUrl },
          });
        }
        this.internalMessages = [...this.internalMessages, newAssignment];
      } else {
        notificationStore.setToast({
          message: getToastForInteractionType(
            interactionType,
            !newAssignment.assignedToUser
              ? COMMUNITY_INTERACTION_ACTIONS.UNASSIGN
              : COMMUNITY_INTERACTION_ACTIONS.ASSIGN,
            null,
            !newAssignment.assignedToUser ? {} : { username: `${firstName} ${lastName}` },
          ),
        });
      }
    },
    async deleteInternalMessage(id) {
      const response = await collaborationApi.deleteComment({ id });
      if (response?.status === 204) {
        const targetMessageIndex = this.internalMessages.findIndex((message) => message.id === id);
        this.internalMessages.splice(targetMessageIndex, 1);

        const interactionStore = useCommunityInteractionStore();
        // Find internal note and do optimistic update
        const deletedNoteIndex = interactionStore.internalNotes.findIndex((n) => n.id === id);
        if (deletedNoteIndex > -1) {
          interactionStore.internalNotes.splice(deletedNoteIndex, 1);
        }
        await this.getUnreadInternalNotesCounts(this.internalNotesQueries);
      }
    },
    async updateInternalNote({ id, meta }) {
      try {
        const response = await collaborationApi.updateComment({ id, meta }, { camelizeKeys: true });
        const { data } = response.data;
        const interactionStore = useCommunityInteractionStore();
        const updatedInternalNote = interactionStore.internalNotes.find(
          (item) => item.id === data.id,
        );
        updatedInternalNote.isRead = !!data.meta?.isRead;
        if (data.meta?.isRead) {
          this.unreadInternalNotesCount -= 1;
        } else {
          this.unreadInternalNotesCount += 1;
        }
      } catch {
        const notificationStore = useNotificationStore();
        notificationStore.setToast({
          message: 'Unable to update internal note. Please try again.',
          type: 'error',
        });
      }
    },
    async getCommunityAssignments({ brandId }) {
      this.pending.communityAssignments = true;
      try {
        const response = await collaborationApi.getBrandComments({
          brandId,
          resourceTypes: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT,
          limit: 100,
        });
        const page = response.data;
        this.communityAssignments = page?.data;
      } finally {
        this.pending.communityAssignments = false;
      }
    },
    formatUser(user = {}) {
      const assignee = camelizeKeys(user);
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: assignee.id,
        firstName: assignee.firstName,
        lastName: assignee.lastName,
        avatarUrl: assignee.avatarUrl,
        type: 'item',
      };
    },
    async getUnreadInternalNotesCounts({ meta, mentionedUserIds, createdBefore, createdAfter }) {
      const identityStore = useIdentityStore();
      const brandId = identityStore.currentBrand.id;
      const payload = {
        brandId,
        resourceType: COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE,
        mentionedUserIds,
        createdBefore,
        createdAfter,
        meta: { ...meta, isRead: false },
      };
      const res = await collaborationApi.getCommentCounts(payload);
      this.unreadInternalNotesCount = res.data.count;
    },
  },
  getters: {
    canAccessCommunityTeams() {
      const authStore = useAuthStore();
      return authStore.brand_can('community', 'can_access_community_collaboration');
    },
    enabledResourceTypes() {
      const authStore = useAuthStore();
      const internalNotes = authStore.brand_can('community', 'can_access_community_collaboration');
      return Object.entries({
        [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT]: true,
        [COMMUNITY_COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE]: internalNotes,
      }).reduce((accumulator, current) => {
        if (current[1]) {
          return [...accumulator, current[0]];
        }
        return accumulator;
      }, []);
    },
    formattedInternalMessages() {
      const authStore = useAuthStore();
      if (this.internalMessages == null) return [];
      return internalMessageFormatter(
        this.internalMessages.map(parseTimestamps),
        this.internalMessagesReferencedUsers,
        { delete: this.deleteInternalMessage },
        authStore.identity.id,
      );
    },
    mostRecentlyAssignedUserIds() {
      const authStore = useAuthStore();
      return [
        ...new Set(
          sortBy(this.communityAssignments || [], (m) => m.createdAt)
            .reverse()
            .map((assignment) => parseInt(assignment.text.replaceAll(/[@[\]]/gi, ''), 10))
            .filter((n) => !Number.isNaN(n) && n !== authStore.identity.id),
        ),
      ].slice(0, MAX_RECENT_BRAND_USERS);
    },
    mostRecentAssignedUserFilterOptions() {
      const authStore = useAuthStore();
      const userItems = [];
      if (authStore.identity) {
        // push the current user first
        userItems.push({
          ...this.formatUser(authStore.identity),
          note: '(you)',
        });
      }

      // push unassigned filter option and a divider line
      userItems.push({
        label: 'Unassigned',
        firstName: '',
        lastName: '',
        value: -1,
        avatarUrl: null,
        type: 'item',
      });
      userItems.push({
        type: 'line',
      });

      if (authStore.brandAccessibleUsers?.length) {
        // add the most recently assigned brand accessible users
        if (this.mostRecentlyAssignedUserIds.length) {
          // push the most recently assigned users
          userItems.push(
            ...authStore.brandAccessibleUsers
              .filter((user) => this.mostRecentlyAssignedUserIds.includes(user.id))
              .sort(
                (a, b) =>
                  this.mostRecentlyAssignedUserIds.indexOf(a.id) -
                  this.mostRecentlyAssignedUserIds.indexOf(b.id),
              )
              .map((user) => this.formatUser(user)),
          );
        }
        // push the remaining users who aren't the most recent
        userItems.push(
          ...authStore.brandAccessibleUsers
            .filter(
              (user) =>
                ![authStore.identity.id, ...this.mostRecentlyAssignedUserIds].includes(user.id),
            )
            .map((user) => this.formatUser(user)),
        );
      }
      return userItems;
    },
  },
});
