<template>
  <div class="error-state-container">
    <div class="error-state-title">{{ errorContent.title }}</div>
    <p class="error-state-message">{{ errorContent.message }}</p>
    <ConnectButton
      v-if="showReconnectMessage"
      class="connect-button"
      platform="facebook_analytics"
      plain-button
      :custom-text="enableFacebookShoppingAccessButtonText"
    />
    <p v-else class="error-state-link">
      Learn more at
      <a target="_blank" :href="facebookCommerceEligibilityRequirementsLink">
        Facebook Commerce Eligibility Requirements.
      </a>
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  enableFacebookShoppingAccessTitle,
  enableFacebookShoppingAccessMessage,
  enableFacebookShoppingAccessButtonText,
  notEligibleMessageContent,
  notEligibleFacebookMessageTitle,
  facebookCommerceEligibilityRequirementsLink,
} from '@/app/scheduler/constants';
import ConnectButton from '@/components/ConnectButton.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'FacebookProductTagsErrorState',
  components: { ConnectButton },
  props: {
    showReconnectMessage: { type: Boolean, default: false },
  },
  data() {
    return {
      enableFacebookShoppingAccessButtonText,
      facebookCommerceEligibilityRequirementsLink,
    };
  },
  computed: {
    errorContent() {
      if (this.showReconnectMessage) {
        return {
          title: enableFacebookShoppingAccessTitle,
          message: enableFacebookShoppingAccessMessage,
        };
      }
      return { title: notEligibleFacebookMessageTitle, message: notEligibleMessageContent };
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.error-state-container {
  margin-top: var(--space-22);
  color: var(--text-primary);

  .error-state-title {
    font-size: var(--x18);
    line-height: var(--space-20);
    margin-bottom: var(--space-12);
  }

  .error-state-message {
    font-size: var(--x16);
    line-height: var(--space-18);
    margin-bottom: var(--space-24);
  }

  .error-state-link {
    font-size: var(--x16);
    line-height: var(--space-16);
  }

  .connect-button {
    display: inline-block;
    height: var(--space-40);
  }
}
</style>
