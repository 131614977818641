import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import * as CommunityAPI from '@/apis/community';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
import {
  COMMUNITY_INTERACTION_RULES_DELETE_POPUP,
  COMMUNITY_INTERACTION_RULES_UNDO_ACTION_POPUP,
  COMMUNITY_INTERACTION_RULES_SAVE_AND_APPLY_TO_EXISTING_POPUP,
} from '@/app/community/constants';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import {
  createOrEditMessageRuleEvent,
  messageRuleInteractionsEvent,
  undoMessageRuleEvent,
} from '@/app/community/utils/mixpanel-events';
import { useAuthStore } from '@/stores/auth';
import { useCommunityMessageRuleBuilder } from '@/composables/community/useCommunityRuleBuilder';
import {
  COMMUNITY_INTERACTION_RULE_TOASTS,
  MESSAGE_RULE_MIXPANEL_CONSTANTS,
  messageRuleToggleToast,
} from '@/app/community/constants/inboxAutomation';
import { refreshCancelToken } from '@/apis/axios.utils';

export const useCommunityInteractionRulesStore = defineStore('communityInteractionRules', () => {
  const identityStore = useIdentityStore();
  const notificationStore = useNotificationStore();

  const interactionRules = ref([]);
  const showPopup = ref({
    messageRules: false,
    messageRuleBuilder: false,
  });
  const editedRuleId = ref();
  const pending = ref({
    interactionRules: true,
  });

  const hasInteractionRules = computed(() => {
    return interactionRules.value.length > 0;
  });

  const {
    actions,
    conditionGroups,
    isFiltersComplete,
    isActionsComplete,
    isRuleComplete,
    hasRepliedFilter,
    filterMatchedMessagesCount,
    ruleBuilderErrors,
    ruleDetail,
    buildJSONRule,
    setConditionGroupOperator,
    updateGroup,
    removeFilterGroup,
    buildJSONRuleFilters,
    buildJSONRuleActions,
    checkGroupHasCompleteItem,
    clearRuleBuilder,
    updateFilterMatchCount,
    setRuleActionsFromJSON,
    setRuleFiltersFromJSON,
    isRuleDetailChanged,
    getFilterUsed,
    getFilterGroups,
  } = useCommunityMessageRuleBuilder();

  async function getInteractionRules() {
    pending.value.interactionRules = true;
    try {
      const res = await CommunityAPI.getInteractionRules(identityStore.currentBrand.id);
      interactionRules.value = res.data.data;
    } catch {
      pending.value.interactionRules = false;
    } finally {
      pending.value.interactionRules = false;
    }
  }

  async function confirmSaveAndApplyToExisting(applyToHistorical) {
    if (applyToHistorical) {
      const { title, confirmMessage, confirmAlias, cancelAlias } =
        COMMUNITY_INTERACTION_RULES_SAVE_AND_APPLY_TO_EXISTING_POPUP;
      const confirmMessageWithCount = confirmMessage.replace(
        'NUM',
        filterMatchedMessagesCount.value,
      );
      const confirmed = await notificationStore.confirm(title, confirmMessageWithCount, {
        confirmType: 'primary',
        confirmAlias,
        cancelAlias,
      });
      return confirmed;
    }
    return true;
  }

  async function createInteractionRule(name, detail, applyToHistorical = false) {
    const notification = { message: COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS };
    const brandId = identityStore.currentBrand.id;
    const payload = { name, detail };
    payload.authorId = identityStore.identity?.id;
    payload.enabled = true;
    payload.applyToHistorical = applyToHistorical;

    if (payload.applyToHistorical) {
      notification.message = `${notification.message}. ${COMMUNITY_INTERACTION_RULE_TOASTS.APPLY_TO_EXISTING}`;
    }

    const confirmed = await confirmSaveAndApplyToExisting(applyToHistorical);
    if (!confirmed) {
      return false;
    }
    try {
      const res = await CommunityAPI.createInteractionRule(brandId, payload);
      interactionRules.value.unshift({
        ...res.data,
        new: true,
      });
      notificationStore.setToast(notification);
    } catch (error) {
      const message =
        [409, 400].includes(error.response.status) && error.response.data?.error
          ? error.response.data.error
          : COMMUNITY_INTERACTION_RULE_TOASTS.FAILURE;
      notificationStore.setToast({
        message,
        type: 'error',
      });
      throw error;
    }
    return true;
  }

  async function updateInteractionRule(
    ruleId,
    payload,
    reactive = false,
    ruleModified = false,
    cancelToken = null,
  ) {
    const notification = { message: COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS };
    if (reactive) {
      notification.message = COMMUNITY_INTERACTION_RULE_TOASTS.SUCCESS_REACTIVATED;
    }
    if (!ruleModified && payload.enabled !== undefined) {
      notification.message = messageRuleToggleToast(payload.enabled);
    }
    if (payload.applyToHistorical) {
      notification.message = `${notification.message}. ${COMMUNITY_INTERACTION_RULE_TOASTS.APPLY_TO_EXISTING}`;
    }
    const confirmed = await confirmSaveAndApplyToExisting(payload.applyToHistorical);
    if (!confirmed) {
      return false;
    }
    try {
      let axiosCancelToken = null;
      if (cancelToken) {
        const { key, value } = cancelToken;
        axiosCancelToken = refreshCancelToken(value, key);
      }
      const res = await CommunityAPI.updateInteractionRule(
        identityStore.currentBrand.id,
        ruleId,
        payload,
        { cancelToken: axiosCancelToken },
      );
      const updatedRule = res.data;
      const existingRuleIndex = interactionRules.value.findIndex((rule) => rule.id === ruleId);
      if (ruleModified) {
        interactionRules.value.splice(existingRuleIndex, 1);
        interactionRules.value.unshift(updatedRule);
      } else {
        interactionRules.value.splice(existingRuleIndex, 1, updatedRule);
      }
      notificationStore.setToast(notification);
    } catch (error) {
      const message =
        error.response?.status === 409 && error.response.data?.error
          ? error.response.data.error
          : COMMUNITY_INTERACTION_RULE_TOASTS.FAILURE;
      notificationStore.setToast({
        message,
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function deleteInteractionRule(rule) {
    let notification = {
      message: COMMUNITY_INTERACTION_RULE_TOASTS.DELETED.SUCCESS,
      type: 'success',
    };
    const { title, confirmMessage, confirmAlias, cancelAlias } =
      COMMUNITY_INTERACTION_RULES_DELETE_POPUP;
    const confirmed = await notificationStore.confirm(title, confirmMessage, {
      confirmAlias,
      cancelAlias,
    });
    if (confirmed) {
      try {
        await CommunityAPI.deleteInteractionRule(identityStore.currentBrand.id, rule.id);
        interactionRules.value = interactionRules.value.filter((r) => r.id !== rule.id);
        messageRuleInteractionsEvent({
          interaction: MESSAGE_RULE_MIXPANEL_CONSTANTS.MESSAGE_RULE_INTERACTIONS.DELETE,
          ruleId: rule.id,
          ruleName: rule.name,
        });
      } catch (error) {
        notification = {
          message: COMMUNITY_INTERACTION_RULE_TOASTS.DELETED.FAILURE,
          type: 'error',
        };
      }
      notificationStore.setToast(notification);
    }
  }

  function setEditedRuleId(id) {
    editedRuleId.value = id;
  }

  function togglePopup(name, value) {
    showPopup.value[name] = !!value;
  }

  async function openRuleBuilderToEdit(ruleId, fromMessage = false) {
    await setEditedRuleId(ruleId);
    togglePopup('messageRuleBuilder', true);

    const editedRule = interactionRules.value.find((r) => r.id === ruleId);
    if (editedRule) {
      const option = fromMessage ? 'EDIT_FROM_MESSAGE' : 'EDIT';
      createOrEditMessageRuleEvent({
        openedFrom: MESSAGE_RULE_MIXPANEL_CONSTANTS.RULE_BUILDER_OPENED_FROM[option],
        ruleId,
        ruleName: editedRule.name,
      });
      messageRuleInteractionsEvent({
        interaction: MESSAGE_RULE_MIXPANEL_CONSTANTS.MESSAGE_RULE_INTERACTIONS[option],
        ruleId,
        ruleName: editedRule.name,
      });
    }
  }

  function formatTags(tagList) {
    const communityInteractionStore = useCommunityInteractionStore();
    const tagsMap = tagList.reduce((result, tag) => Object.assign(result, tag), {});
    const formatted = Object.keys(tagsMap).map((tagId) => {
      const savedTag = communityInteractionStore.tags.find((t) => t.id === Number(tagId));
      const item = { icon: { name: 'dot' } };
      if (savedTag) {
        item.icon.color = savedTag.color;
        item.text = savedTag.name;
        item.id = savedTag.id;
      } else {
        item.text = tagsMap[tagId];
        item.id = Number(tagId);
        item.error = true;
      }
      return item;
    });

    return formatted;
  }

  function getAuthorName(authorId) {
    if (authorId) {
      const authStore = useAuthStore();
      const author = authStore.brandAccessibleUsers.find((u) => u.id === authorId);
      if (author) {
        return [author.firstName, author.lastName].join(' ');
      }
      return 'Unavailable User';
    }
    return 'System';
  }
  async function updateInternalMessageRuleInteraction(interactionId, payload) {
    const communityInteractionStore = useCommunityInteractionStore();
    await communityInteractionStore.updateCommunityInteraction(
      interactionId,
      payload,
      false,
      null,
      null,
      true,
    );
    return true;
  }

  async function deleteInteractionRulesHistory(brandId, ruleExecutionHistoryId) {
    try {
      await CommunityAPI.deleteInteractionRulesHistory(brandId, ruleExecutionHistoryId);
      return true;
    } catch (error) {
      throw new Error('Failed to delete interaction rule history');
    }
  }

  async function undoAction(message, deleteRuleExecutedMessage) {
    const communityInteractionStore = useCommunityInteractionStore();
    const interactionId = communityInteractionStore.selectedCommunityInteractionId;
    const { title, confirmMessage, confirmAlias } = COMMUNITY_INTERACTION_RULES_UNDO_ACTION_POPUP;
    const confirmed = await notificationStore.confirm(title, confirmMessage, {
      confirmAlias,
      confirmType: 'primary',
    });
    if (confirmed) {
      const payload = {};
      const revertPayload = {};
      if (message.action.archive) {
        payload.isArchived = false;
        revertPayload.isArchived = true;
      }
      if (message.action.tags) {
        const tagIds = Object.keys(message.action.tags[0]).map(Number);
        payload.tagIds = { remove: tagIds };
        revertPayload.tagIds = { add: tagIds };
      }

      try {
        const undoResult = await updateInternalMessageRuleInteraction(interactionId, payload);
        if (undoResult) {
          try {
            const deleteHistory = await deleteInteractionRulesHistory(
              identityStore.currentBrand.id,
              message.id,
            );
            if (deleteHistory) {
              deleteRuleExecutedMessage({ ...message, interactionId });
              notificationStore.setToast({
                message: COMMUNITY_INTERACTION_RULE_TOASTS.UNDO.SUCCESS,
                type: 'success',
              });
            }
          } catch (error) {
            await updateInternalMessageRuleInteraction(interactionId, revertPayload);
            throw error;
          }
        }
        undoMessageRuleEvent({ ruleId: message.ruleId, ruleName: message.triggeredRuleName });
      } catch {
        notificationStore.setToast({
          message: COMMUNITY_INTERACTION_RULE_TOASTS.UNDO.ERROR,
          type: 'error',
        });
      }
    }
  }

  function checkRuleDeleted(ruleId) {
    const ruleFound = interactionRules.value.some((rule) => rule.id === ruleId);
    return !ruleFound;
  }

  return {
    interactionRules,
    showPopup,
    pending,
    hasInteractionRules,
    editedRuleId,
    getInteractionRules,
    updateInteractionRule,
    createInteractionRule,
    togglePopup,
    setEditedRuleId,
    openRuleBuilderToEdit,
    formatTags,
    getAuthorName,
    actions,
    conditionGroups,
    isFiltersComplete,
    isActionsComplete,
    isRuleComplete,
    hasRepliedFilter,
    filterMatchedMessagesCount,
    ruleBuilderErrors,
    ruleDetail,
    updateGroup,
    removeFilterGroup,
    deleteInteractionRule,
    deleteInteractionRulesHistory,
    undoAction,
    checkRuleDeleted,
    setConditionGroupOperator,
    buildJSONRule,
    buildJSONRuleActions,
    buildJSONRuleFilters,
    clearRuleBuilder,
    updateFilterMatchCount,
    checkGroupHasCompleteItem,
    setRuleActionsFromJSON,
    setRuleFiltersFromJSON,
    isRuleDetailChanged,
    getFilterUsed,
    getFilterGroups,
  };
});
