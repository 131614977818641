import { useAuthStore } from '@/stores/auth';
import { APPROVAL_POLICY, APPROVAL_STATUS, postStatus } from '@/app/scheduler/constants';

export const getApprovalChipText = ({ post, approvalRequests }) => {
  const authStore = useAuthStore();

  const requireApprovalsToPost = post?.approvalPolicy
    ? post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED
    : authStore.user_can('scheduler', 'require_approvals_to_post');

  if (approvalRequests?.length === 0) {
    return requireApprovalsToPost
      ? `${authStore.user_can('scheduler', 'scheduler_num_of_approvals_required')} required`
      : null;
  }

  const newPostRequestPending = approvalRequests?.length > 0 && !post?.id;
  const existingPostRequestPending =
    approvalRequests?.length > 0 &&
    (!post?.approvalStatus || post?.approvalStatus === APPROVAL_STATUS.UNASSIGNED);

  const newRequestPending = newPostRequestPending || existingPostRequestPending;

  if (newRequestPending) {
    return APPROVAL_STATUS.PENDING;
  }

  const numOfApprovalRequired = post?.numOfApprovalsRequired;
  if (!numOfApprovalRequired) return null;

  if (post.approvalStatus === APPROVAL_STATUS.UNASSIGNED) {
    return `${numOfApprovalRequired} required`;
  }

  return post.approvalStatus;
};

export const postRequiresApproval = ({ post }) => {
  const authStore = useAuthStore();
  if (!post?.id) {
    return authStore.user_can('scheduler', 'require_approvals_to_post');
  }

  const numOfApprovalRequired = post?.numOfApprovalsRequired;
  if (post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_NOT_REQUIRED) {
    return false;
  }
  if (post?.approvalRequests?.length === 0) {
    return post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED && numOfApprovalRequired;
  }

  const numOfApprovedRequests = post?.approvalRequests?.filter((request) => {
    return request.approvalStatus === APPROVAL_STATUS.APPROVED;
  }).length;
  return numOfApprovalRequired > numOfApprovedRequests;
};

export const getApprovalRequestTitle = ({ post }) => {
  if (post?.approvalRequests?.length > 1) {
    return `${post.approvalRequests.length} Reviewers`;
  }
  if (post?.approvalRequests?.length === 1) {
    return '1 Reviewer';
  }
  return null;
};

export const getPendingPosts = (posts) => {
  const pendingPosts = posts.filter((post) => {
    const status = getApprovalChipText({ post });
    return (
      status === APPROVAL_STATUS.PENDING &&
      [postStatus.SCHEDULED, postStatus.DRAFT].includes(post.status)
    );
  });
  if (pendingPosts.length > 0) return pendingPosts;
  return null;
};
