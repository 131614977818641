<script setup>
import { ref, computed } from 'vue';
import { colours } from '@/ux/colours';
import { findIndexNestedOption } from '@/utils';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import Icon from '@/components/foundation/Icon.vue';
import NewBadge from '@/components/core/NewBadge.vue';

const checkColor = colours.ACTION.ACTION_500;
const caretColor = colours.ICON.ICON_SECONDARY;

const props = defineProps({
  option: { type: Object, required: true },
  selected: { type: [String, Number, Object, Boolean], default: null },
  expanded: { type: Boolean, default: false },
  // Two different hover effects supported: background colour change and text colour change
  hoverBackground: { type: Boolean, default: true },
  hoverHighlight: { type: Boolean, default: false },
  // Spacing between values
  spacing: { type: String, default: '1.75rem' },
  // Used internally to keep track of the option depth in accordions
  level: { type: Number, default: 1 },
});

const emit = defineEmits(['select', 'toggleAccordion']);

const selectedChildIndex = computed(() => findIndexNestedOption(props.selected, [props.option]));
const expandedChildIndex = ref(
  // If a child accordion has a selected ancestor, make it initially expanded
  props.option.children?.[selectedChildIndex.value]?.children ? selectedChildIndex.value : null,
);

const isSelected = computed(() => selectedChildIndex.value >= 0);
const hasChildren = computed(() => props.option.children !== undefined);

const hoverStyle = computed(() => {
  if (props.hoverBackground) return 'hover:bg-grey-300';
  if (props.hoverHighlight) return 'hover:text-action-500';
  return null;
});

const onToggleAccordion = (index) => {
  if (expandedChildIndex.value === index) {
    expandedChildIndex.value = null;
  } else {
    expandedChildIndex.value = index;
  }
};

const onClick = () => {
  if (hasChildren.value) {
    emit('toggleAccordion');
  } else if (!props.option.disabled) {
    emit('select', props.option.value);
  }
};
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <li
    v-tooltip="option.disabled && option.tooltip ? option.tooltip : null"
    data-cy="select-option"
    class="cursor-pointer"
    :class="{ 'select-option-disabled': option.disabled }"
    @click.stop.prevent="onClick"
  >
    <template v-if="hasChildren">
      <div class="padding flex items-center justify-between" :class="[hoverStyle]">
        <div class="flex items-center">
          <span class="line-spacing w-max text-sm font-medium">{{ option.label }}</span>
          <NewBadge v-if="option.showNewBadge" class="ml-2" show />
          <Icon v-if="isSelected" class="ml-2" name="check" :color="checkColor" xsmall />
        </div>
        <Icon
          class="ml-2 transition-transform"
          :class="{ 'rotate-90': expanded }"
          name="caret"
          :color="caretColor"
          xxsmall
        />
      </div>
      <ul>
        <CollapsibleContainer :open="expanded">
          <SelectOption
            v-for="(childOption, index) in option.children"
            :key="childOption.value ?? childOption.label"
            :option="childOption"
            :selected="selected"
            :expanded="index === expandedChildIndex"
            :hover-background="hoverBackground"
            :hover-highlight="hoverHighlight"
            :spacing="spacing"
            :level="level + 1"
            @select="(value) => emit('select', value)"
            @toggle-accordion="() => onToggleAccordion(index)"
          />
        </CollapsibleContainer>
      </ul>
    </template>
    <template v-else>
      <div class="padding flex items-center" :class="[hoverStyle]">
        <span class="line-spacing w-max text-sm font-medium">{{ option.label }}</span>
        <NewBadge v-if="option.showNewBadge" class="ml-2" show />
        <Icon v-if="isSelected" class="ml-2" name="check" :color="checkColor" xsmall />
      </div>
    </template>
  </li>
</template>

<style lang="postcss" scoped>
/* Can't use tailwind because we need to calculate padding-left */
.padding {
  padding-left: calc(var(--space-16) * v-bind(level));
  padding-right: var(--space-16);
}

.line-spacing {
  /* !important needed to override tailwind font size */
  line-height: v-bind(spacing) !important;
}
</style>
