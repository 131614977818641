<template>
  <div :class="['empty-status', emptyStatusType]">
    <img :src="getImageSource(image)" :style="{ width: imageWidth, height: imageHeight }" />
    <h4>{{ title }}</h4>
    <div class="message-text">
      <span v-if="containsHtml" v-sanitize-html="message"></span>
      <slot v-else>{{ message }}</slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getImageSource } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'EmptyStatus',
  props: {
    image: { type: String, default: 'empty-beach.png' },
    imageWidth: { type: String, default: '10rem' },
    imageHeight: { type: String, default: '10rem' },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    containsHtml: { type: Boolean, default: false },
    emptyStatusType: { type: String, default: '' },
  },
  methods: {
    getImageSource,
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.empty-status {
  margin-top: 6rem;
  text-align: center;
  padding-bottom: 6rem;

  img {
    border-radius: 50%;
  }

  h4 {
    margin: var(--space-24) 0 var(--space-8);
    text-transform: none;
    color: var(--text-primary);
  }

  .message-text {
    font-size: var(--x16);
    color: var(--text-primary);
    max-width: 28rem;
    display: inline-block;
    overflow-wrap: normal;
  }
}

.coming-soon {
  margin: auto;
  transform: translateY(calc(-1 * var(--space-32)));
  padding-bottom: 0;

  .message-text {
    max-width: 34rem;
    white-space: pre-wrap;
    text-align: left;
  }
}

.connected-tool-disabled {
  margin: auto;
  transform: translateY(calc(-1 * var(--space-32)));
  padding-bottom: 0;

  h4 {
    font-size: var(--x22);
  }

  .message-text {
    font-size: var(--x14);
  }
}

.empty-message {
  margin: auto;
  transform: translateY(calc(-1 * var(--space-32)));
  padding-bottom: 0;
  color: var(--text-secondary);

  .message-text {
    color: var(--text-secondary);
  }
}
</style>
