import isFunction from 'lodash/isFunction';
import { watchGlobalModals } from '@/utils/globalModals';

export default {
  data: () => ({
    globalModals: [],
    unsubscribeToGlobalModalUpdates: () => {},
  }),
  created() {
    this.unsubscribeToGlobalModalUpdates = watchGlobalModals((modals) => {
      this.globalModals = modals;
    });
  },
  beforeUnmount() {
    this.unsubscribeToGlobalModalUpdates();
  },
  methods: {
    isGlobalModalOpen(name) {
      return this.globalModals.some(async (modal) => {
        const component = isFunction(modal?.component) ? await modal.component() : modal?.component;
        return component.name === name;
      });
    },
  },
};
