import { extractEnumValue } from '@/models/enum.utils';

export const CUSTOMER_STAGE = Object.freeze({
  OUTREACH: {
    value: 'outreach',
  },
  TRIAL: {
    value: 'trial',
  },
  SELF_SERVE_TRIAL: {
    value: 'self_serve_trial',
  },
  CUSTOMER: {
    value: 'customer',
  },
  CHURN: {
    value: 'churn',
  },
});

export function isSameCustomerStage(left, right) {
  const leftType = extractEnumValue(left);
  const rightType = extractEnumValue(right);
  return leftType === rightType;
}
