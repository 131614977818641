<script setup>
import { stripHtmlFromMessage } from '@/components/VisionAi/utils/message';
import UserWrapper from '@/components/VisionAi/UserWrapper.vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <UserWrapper is-text-only-button class="w-fit" data-cy="user-message">
    <span>{{ stripHtmlFromMessage(props.text) }}</span>
  </UserWrapper>
</template>
