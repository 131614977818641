/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'followers': {
    width: 148,
    height: 94,
    viewBox: '0 0 148 94',
    data: '<path pid="0" d="M104 53.667c-8 0-20.467 2.266-30 6.666-9.533-4.466-22-6.666-30-6.666-14.467 0-43.333 7.2-43.333 21.666v18.334h146.666V75.333c0-14.466-28.866-21.666-43.333-21.666zm-26.667 30H10.667v-8.334c0-3.6 17.066-11.666 33.333-11.666s33.333 8.066 33.333 11.666v8.334zm60 0h-50v-8.334c0-3.066-1.333-5.733-3.466-8.133 5.866-2 13.066-3.533 20.133-3.533 16.267 0 33.333 8.066 33.333 11.666v8.334zM44 47c12.867 0 23.333-10.467 23.333-23.333C67.333 10.8 56.867.333 44 .333 31.133.333 20.667 10.8 20.667 23.667 20.667 36.533 31.133 47 44 47zm0-36.667c7.333 0 13.333 6 13.333 13.334C57.333 31 51.333 37 44 37s-13.333-6-13.333-13.333c0-7.334 6-13.334 13.333-13.334zM104 47c12.867 0 23.333-10.467 23.333-23.333C127.333 10.8 116.867.333 104 .333 91.133.333 80.667 10.8 80.667 23.667 80.667 36.533 91.133 47 104 47zm0-36.667c7.333 0 13.333 6 13.333 13.334C117.333 31 111.333 37 104 37s-13.333-6-13.333-13.333c0-7.334 6-13.334 13.333-13.334z" _fill="#F2F2F2" fill-rule="nonzero"/>'
  }
})
