/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chatBubbleCircle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.998 21h-10a.998.998 0 01-.732-1.68l2.305-2.484C2.539 15.392 1.998 13.735 1.998 12c0-4.963 4.486-9 10-9s10 4.037 10 9-4.486 9-10 9zm-7.707-2h7.707c4.411 0 8-3.14 8-7 0-3.859-3.589-7-8-7s-8 3.141-8 7c0 1.537.568 3 1.642 4.232a1 1 0 01-.021 1.338L4.291 19z"/><path pid="1" d="M10.998 9h6v2h-6zM10.998 13h6v2h-6z"/><g><circle pid="2" cx="7.998" cy="10" r="1"/></g><g><circle pid="3" cx="7.998" cy="14" r="1"/></g>'
  }
})
