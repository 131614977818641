import { computed } from 'vue';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { competitiveRouteNames } from '@/app/competitive/constants';

export function usePrimaryNavigationMenu() {
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  const currentBrandLabel = computed(() => identityStore.currentBrand?.label);

  const primaryNavigationItems = computed(() => {
    return [
      {
        backgroundClass: 'dash-gradient-bg-nav-pink',
        icon: 'dashboards',
        label: 'Dashboards',
        link: 'dashboards',
        name: 'dashboards',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-green',
        icon: 'analytics',
        label: 'Analytics',
        link: 'instagram',
        name: 'analytics',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-orange',
        icon: 'creators',
        label: 'Relationships',
        link: 'relationships',
        name: 'relationships',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-blue',
        icon: 'creators',
        label: 'Creators',
        link: 'creators',
        name: 'creators',
        visible: flagStore.ready && flagStore?.flags?.saveJackie && true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-purple',
        icon: 'campaigns',
        label: 'Campaigns',
        link: 'campaigns',
        name: 'campaigns',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-pink',
        icon: 'community',
        label: 'Community',
        link: 'community',
        name: 'community',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-green',
        icon: 'competitors',
        label: 'Competitors',
        link: competitiveRouteNames.instagram.main,
        name: 'competitors',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-orange',
        icon: 'publishing',
        label: 'Scheduler',
        link: 'scheduler',
        name: 'scheduler',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-blue',
        icon: 'library',
        label: 'Library',
        link: 'library',
        name: 'library',
        visible: true,
      },
      {
        backgroundClass: 'dash-gradient-bg-nav-purple',
        icon: 'listening',
        label: 'Listening',
        link: 'socialListening',
        name: 'listening',
        visible: true,
      },
    ];
  });

  const visibleNavigationItems = computed(() => {
    return primaryNavigationItems.value
      .filter((platform) => platform.visible)
      .map((platform) => {
        return {
          to: {
            name: platform.link,
            params: { brandLabel: currentBrandLabel.value },
          },
          ...platform,
        };
      });
  });

  return {
    visibleNavigationItems,
  };
}
