<template>
  <Popup :close="hideRequestSuccessPopup" class="request-success-popup">
    <img :src="IMG.emptyThumbup" />
    <h2>Rights Request Comment Posted to Instagram</h2>
    <p>
      The content rights status has been updated to "requested". You can monitor the content in
      <router-link :to="{ name: 'instagram.ugc.content-rights' }">Content Rights</router-link>
      .
    </p>
    <Button primary wide @click="hideRequestSuccessPopup">Got It</Button>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'RequestSuccessPopup',
  components: {
    Button,
    Popup,
  },
  props: {
    hideRequestSuccessPopup: { type: Function, required: true },
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    IMG() {
      return IMG;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.request-success-popup {
  text-align: center;

  img {
    height: 8.5rem;
  }

  h2 {
    margin: var(--space-24) auto var(--space-16) auto;
    width: 22.2rem;
    word-break: keep-all;
  }

  button {
    margin: var(--space-48) auto 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }
}
</style>
