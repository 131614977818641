import dayjs from 'dayjs';
import { reactive } from 'vue';
import { format, subWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { format as formatInTimeZone } from 'date-fns-tz';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { GRAPH_SCALES, isValidGraphScale } from '@/models/dashboards/graph-scales.enum';
import {
  CONTEXT_PERIOD_MODES,
  REPORTING_PERIOD_MODES,
  isValidContextPeriodMode,
  isValidReportingPeriodMode,
} from '@/components/core/comparison-date-picker/constants';
import constants from '@/app/dashboards/constants';

export function useDashboardsReportFilters() {
  const dashboardsFilters = reactive({
    reportDateRange: [],
    contextDateRange: [],
    reportingPeriodMode: null,
    contextPeriodMode: null,
    graphScale: null,
  });

  function isValidDateRange(dateRange) {
    return (
      Array.isArray(dateRange) &&
      dateRange.length === 2 &&
      dateRange.every((date) => dayjs(date, 'YYYY-MM-DD', true).isValid())
    );
  }

  function generateDashboardLocalStorageKey(key, dashboardId, currentDashboard) {
    const dashboardKey = `-${dashboardId || currentDashboard?.id}`;
    return `${key}${dashboardKey}`;
  }

  function generateDefaultReportDateRange(timeZone) {
    const oneWeekAgoFromToday = subWeeks(new Date(), 1);

    const start = startOfWeek(oneWeekAgoFromToday);
    const end = endOfWeek(oneWeekAgoFromToday);

    return timeZone
      ? [
          formatInTimeZone(start, constants.FORMAT_STRING, { timeZone }),
          formatInTimeZone(end, constants.FORMAT_STRING, { timeZone }),
        ]
      : [format(start, constants.FORMAT_STRING), format(end, constants.FORMAT_STRING)];
  }

  function generateDefaultContextDateRange(timeZone) {
    const twoWeeksAgoFromToday = subWeeks(new Date(), 2);

    const start = startOfWeek(twoWeeksAgoFromToday);
    const end = endOfWeek(twoWeeksAgoFromToday);

    return timeZone
      ? [
          formatInTimeZone(start, constants.FORMAT_STRING, { timeZone }),
          formatInTimeZone(end, constants.FORMAT_STRING, { timeZone }),
        ]
      : [format(start, constants.FORMAT_STRING), format(end, constants.FORMAT_STRING)];
  }

  function updateReportDate({ newReportDateRange, dashboardId, currentDashboard, timeZone }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.REPORT_DATE_RANGE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageDateRange =
      browserStorageGetItem(localStorageKey)?.toString()?.split(',') || null;
    let dateToBeUsed = null;
    if (isValidDateRange(newReportDateRange)) {
      dateToBeUsed = newReportDateRange;
    } else if (isValidDateRange(localStorageDateRange)) {
      dateToBeUsed = localStorageDateRange;
    } else {
      dateToBeUsed = generateDefaultReportDateRange(timeZone);
    }

    dashboardsFilters.reportDateRange = dateToBeUsed;
    browserStorageSetItem(localStorageKey, dateToBeUsed.join(','));
  }

  function updateContextDate({ newContextDateRange, dashboardId, currentDashboard, timeZone }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.CONTEXT_DATE_RANGE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageDateRange =
      browserStorageGetItem(localStorageKey)?.toString()?.split(',') || null;
    let dateToBeUsed = null;
    if (isValidDateRange(newContextDateRange)) {
      dateToBeUsed = newContextDateRange;
    } else if (isValidDateRange(localStorageDateRange)) {
      dateToBeUsed = localStorageDateRange;
    } else {
      dateToBeUsed = generateDefaultContextDateRange(timeZone);
    }

    dashboardsFilters.contextDateRange = dateToBeUsed;
    browserStorageSetItem(localStorageKey, dateToBeUsed.join(','));
  }

  function updateReportingPeriodMode({ mode, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.REPORTING_PERIOD_MODE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageValue = browserStorageGetItem(localStorageKey)?.toString() || null;
    let valueToBeUsed = null;

    if (isValidReportingPeriodMode(mode)) {
      valueToBeUsed = mode;
    } else if (isValidReportingPeriodMode(localStorageValue)) {
      valueToBeUsed = localStorageValue;
    } else {
      valueToBeUsed = REPORTING_PERIOD_MODES.LAST_WEEK.value;
    }
    dashboardsFilters.reportingPeriodMode = valueToBeUsed;
    browserStorageSetItem(localStorageKey, valueToBeUsed);
  }

  function updateContextPeriodMode({ mode, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.CONTEXT_PERIOD_MODE_LS_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageValue = browserStorageGetItem(localStorageKey)?.toString() || null;
    let valueToBeUsed = null;
    if (isValidContextPeriodMode(mode)) {
      valueToBeUsed = mode;
    } else if (isValidContextPeriodMode(localStorageValue)) {
      valueToBeUsed = localStorageValue;
    } else {
      valueToBeUsed = CONTEXT_PERIOD_MODES.PREVIOUS_WEEK.value;
    }

    dashboardsFilters.contextPeriodMode = valueToBeUsed;
    browserStorageSetItem(localStorageKey, valueToBeUsed);
  }

  function updateGraphScale({ scale, dashboardId, currentDashboard }) {
    const localStorageKey = generateDashboardLocalStorageKey(
      constants.GRAPH_SCALE_LOCAL_STORE_KEY,
      dashboardId,
      currentDashboard,
    );

    const localStorageGraphScale = browserStorageGetItem(localStorageKey)?.toString() || null;
    let graphScaleToBeUsed = null;
    if (isValidGraphScale(scale)) {
      graphScaleToBeUsed = scale;
    } else if (isValidGraphScale(localStorageGraphScale)) {
      graphScaleToBeUsed = localStorageGraphScale;
    } else {
      graphScaleToBeUsed = GRAPH_SCALES.DAILY.value;
    }
    dashboardsFilters.graphScale = graphScaleToBeUsed;
    browserStorageSetItem(localStorageKey, graphScaleToBeUsed);
  }

  return {
    dashboardsFilters,
    updateReportDate,
    updateContextDate,
    updateReportingPeriodMode,
    updateContextPeriodMode,
    updateGraphScale,
  };
}
