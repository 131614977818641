/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fileQuestionMark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11 8c-2.206 0-4 1.794-4 4h2c0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2h-1v2h2v-.126A4.008 4.008 0 0015 12c0-2.206-1.794-4-4-4z"/><circle pid="1" cx="11" cy="18" r="1"/><path pid="2" d="M15.414 1H4c-1.103 0-2 .898-2 2v18c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5.586L15.414 1zM4 21V3h10v4h4l.001 14H4z"/>'
  }
})
