import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { colours } from '@/ux/colours';
import { DASHBOARD_ROUTE_NAMES } from '@/app/dashboards/constants';
import { COMMUNITY_ROUTE_NAMES } from '@/app/community/constants';

export const INTENT_TYPES = {
  SAMPLE: 'SAMPLE',
  METRIC_ANALYTICS: 'METRIC_ANALYTICS',
  DASHBOARD_REPORTS: 'DASHBOARD_REPORTS',
  TRENDS: 'TRENDS',
  TOPICS: 'TOPICS',
  COMMENTS: 'COMMENTS',
  DMS: 'DMS',
};

export const SUMMARY_INTENT_TYPES = [
  INTENT_TYPES.COMMENTS,
  INTENT_TYPES.DASHBOARD_REPORTS,
  INTENT_TYPES.DMS,
  INTENT_TYPES.TOPICS,
  INTENT_TYPES.TRENDS,
];

// some report components used to display message additional content rely on snake case fields
export const SKIP_CAMELIZE_CONTENT_INTENT_TYPES = [
  INTENT_TYPES.DASHBOARD_REPORTS,
  INTENT_TYPES.METRIC_ANALYTICS,
];

export const ACTION_TYPES = {
  PROMPT: 'PROMPT',
  QUESTION: 'QUESTION',
};

export const VISION_AI_SOCKET_EVENTS = {
  VISION_AI_MESSAGE_CREATED: 'VISION_AI_MESSAGE_CREATED',
  VISION_AI_CLEAR_MESSAGES: 'VISION_AI_CLEAR_MESSAGES',
  VISION_AI_CONVERSATION_UPDATED: 'VISION_AI_CONVERSATION_UPDATED',
  VISION_AI_CONVERSATION_CREATED: 'VISION_AI_CONVERSATION_CREATED',
  VISION_AI_CONVERSATIONS_DELETED: 'VISION_AI_CONVERSATIONS_DELETED',
};

export const VISION_AI_MIXPANEL_EVENTS = {
  AI_WIDGET_INTERACTION: 'AI Widget Interaction',
  AI_WIDGET_LAUNCH: 'AI Widget Launch',
  AI_MESSAGE_INTERACTION: 'AI Message Interaction',
  AI_SUMMARY_LAUNCH: 'AI Summary Launch',
  USER_MESSAGE_SENT: 'User Message Sent',
  ASSISTANT_MESSAGE_RECEIVED_METRICS: 'Assistant Message Received - Metrics',
};

export const CONVERSATIONAL_RESPONSE_TYPES_MIXPANEL = {
  OWNED_BRANDS: 'Owned Brands',
  COMPETITOR_BRANDS: 'Competitor Brands',
  OTHER: 'Other',
};

export const VISION_AI_WIDGET_INTERACTIONS = {
  DELETE_ALL_HISTORY: 'Delete All History',
  DELETE_ICON_CLICKED: 'Delete Icon Clicked',
  DELETE_SINGLE_CHAT: 'Delete Single Chat',
  DELETE_SUMMARIES: 'Delete Summaries',
  EXPAND: 'Expand',
  HELP: 'Help',
  HIDE_CHAT_HISTORY: 'Hide Chat History',
  LOAD_HISTORY: 'Load History',
  NEW_CHAT: 'New Chat',
  RENAME_CHAT: 'Rename Chat',
  SHOW_CHAT_HISTORY: 'Show Chat History',
  SHRINK: 'Shrink',
};

export const VISION_AI_WIDGET_LAUNCH_INTERACTIONS = {
  OPEN: 'Open',
  CLOSE: 'Close',
};

export const VISION_AI_SUMMARY_INTERACTIONS = {
  REGENERATE: 'Regenerate',
  COPY: 'Copy',
  DOWNLOAD_MEDIA: 'Download Media',
  SOURCE_LINK: 'Source Link',
};

export const VISION_AI_INTENT_TYPE_NAMES = {
  [INTENT_TYPES.METRIC_ANALYTICS]: 'Metric Analytics',
  [INTENT_TYPES.DASHBOARD_REPORTS]: 'Dashboard',
  [INTENT_TYPES.COMMENTS]: 'Community Comment',
  [INTENT_TYPES.DMS]: 'Community DM',
  [INTENT_TYPES.TOPICS]: 'Social Listening Topic',
  [INTENT_TYPES.TRENDS]: 'Social Listening Trend',
};

export const VISION_AI_SUMMARY_LAUNCH_TYPE = {
  ICON: 'Icon',
  PROMPT: 'Prompt',
};

export const PNG_DOWNLOAD_TOOLTIP = 'Download as .png';

export const OLD_VISION_AI_RESOURCE_CENTER_URL =
  'https://help.dashhudson.com/hc/en-us/articles/25549170222477';

export const VISION_AI_RESOURCE_CENTER_URL =
  'https://help.dashhudson.com/hc/en-us/articles/29038584328973';

export const NO_ACCESS_MESSAGES = {
  SUMMARY_NOT_AVAILABLE: 'Vision AI Summaries are not available for this page yet.',
};

export const INTENT_TYPES_BY_ROUTE_NAME = {
  [DASHBOARD_ROUTE_NAMES.ID]: INTENT_TYPES.DASHBOARD_REPORTS,
  [DASHBOARD_ROUTE_NAMES.DASHBOARDS]: INTENT_TYPES.DASHBOARD_REPORTS,
  [SOCIAL_LISTENING_ROUTE_NAMES.TOPICS]: INTENT_TYPES.TOPICS,
  [SOCIAL_LISTENING_ROUTE_NAMES.TRENDS]: INTENT_TYPES.TRENDS,
};

export const DEFAULT_METRIC_AND_ANALYTICS_PROMPTS = [
  'What was my Average Engagement Rate for Facebook and Instagram last week?',
  'What is my total net new followers for all channels between January and today?',
  'How many Video Views did I receive on TikTok last month?',
];

export const DEFAULT_METRIC_AND_ANALYTICS_PROMPTS_TOOLTIP =
  'Click this prompt to ask Vision AI to analyze your data';

export const DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE = {
  [INTENT_TYPES.DASHBOARD_REPORTS]: 'Summarize this Dashboard',
  [INTENT_TYPES.SAMPLE]: 'Send the sample message',
  [INTENT_TYPES.TOPICS]: 'Summarize this Topic',
  [INTENT_TYPES.TRENDS]: 'Summarize Trends',
  [INTENT_TYPES.DMS]: 'Summarize DMs',
  [INTENT_TYPES.COMMENTS]: 'Summarize Comments',
};

export const COMMON_ICON_PROPS = {
  iconColor: colours.TEXT.TEXT_PRIMARY,
  dismiss: true,
  round: true,
};

export const VALID_DASHBOARD_REPORTS = {
  SINGLE_METRIC: 'SINGLE_METRIC',
  TOTAL_METRIC: 'TOTAL_METRIC',
  MULTI_BRAND_METRIC: 'MULTI_BRAND_METRIC',
  MULTI_METRIC_MEDIA_TYPE: 'MULTI_METRIC_MEDIA_TYPE',
  TOTAL_GROUPED_METRIC: 'TOTAL_GROUPED_METRIC',
  COMPETITIVE_MULTI_BRAND_METRIC: 'COMPETITIVE_MULTI_BRAND_METRIC',
  GRAPH: 'GRAPH',
  COMPETITIVE_GRAPH: 'COMPETITIVE_GRAPH',
};

export const THRESHOLD_MINIMUMS = {
  COMMUNITY: {
    COMMENTS: 3,
    DMS: 5,
  },
  LISTENING: {
    TRENDS_TOP_POSTS: 3,
    TOPICS_TOP_POSTS: 3,
  },
  DASHBOARDS: 3,
};

export const REGENERATE_TOOLTIPS = {
  LOADING: 'Vision AI is generating a response',
  READY: 'Regenerate response',
};

export const VISIT_RESOURCE_CENTER = `For more information, visit the `;
export const COMMUNITY_VISION_AI_TOOLTIPS = {
  COMMENTS: {
    ENABLED: 'Use Vision AI to summarize the comments on this post',
    DISABLED: `You need a minimum of ${THRESHOLD_MINIMUMS.COMMUNITY.COMMENTS} eligible comments to use AI Summaries. `,
  },
  DMS: {
    ENABLED: 'Use Vision AI to summarize this conversation',
    DISABLED: `You need a minimum of ${THRESHOLD_MINIMUMS.COMMUNITY.DMS} eligible messages to use AI Summaries. `,
  },
  EMPTY_STATE: {
    DISABLED: 'Vision AI Summaries are only available for DMs and Comments in the Community Inbox',
  },
};
export const SOCIAL_LISTENING_VISION_AI_TOOLTIPS = {
  TRENDS: {
    ENABLED: 'Use Vision AI to summarize Social Trends',
    DISABLED: `You need a minimum of ${THRESHOLD_MINIMUMS.LISTENING.TRENDS_TOP_POSTS} eligible posts on this page to use AI Summaries. `,
  },
  TOPICS: {
    ENABLED: 'Use Vision AI to summarize this Topic',
    DISABLED: `You need a minimum of ${THRESHOLD_MINIMUMS.LISTENING.TOPICS_TOP_POSTS} eligible posts on this page to use AI Summaries. `,
  },
  EMPTY_STATE: {
    DISABLED: 'Vision AI Summaries are only available for Social Listening Trends and Topics.',
  },
};

export const DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE = `This Dashboard can’t be summarized. It needs to have a minimum of ${THRESHOLD_MINIMUMS.DASHBOARDS} eligible reports:`;
export const DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE_RULES = [
  'Reports must contain data',
  'Only line graphs and metric reports are currently supported',
  'Data must be for owned or competitive reports only',
  'Multi-brand reports must be aggregated by brand',
  'Multi-channel reports must be aggregated by channel',
];

const bulletPoints = DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE_RULES.map(
  (rule) => `   <li class="list-item">    ${rule}</li>`,
).join('');

export const FORMATTED_DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE = `${DASHBOARD_SUMMARY_REPORT_MINIMUM_MESSAGE}<ul  class="indented-list"> ${bulletPoints}</ul>`;

export const DASHBOARD_SUMMARY_NO_DASHBOARDS_MESSAGE = `AI Summaries requires you to create a Dashboard first.`;

export const DASHBOARD_SUMMARIZE_BUTTON_TOOLTIP = 'Use Vision AI to summarize this Dashboard';

/*
error codes that may get set on assistant messages from the backend.
for runtime errors, messages can be attempted to be regenerated.
specify in config if any error codes should disallow message regeneration.
*/
export const ERROR_TYPES = {
  RUNTIME_ERROR: {
    value: 'RUNTIME_ERROR',
    statusCode: 500,
    errorMessage: 'Oops! There was an issue generating a response. Please try again.',
  },
  // error message unique per intent type
  UNAUTHORIZED_ERROR: {
    value: 'UNAUTHORIZED_ERROR',
    statusCode: 403,
    disallowMessageRegeneration: true,
  },
};

export const BACKUP_PROMPT_MESSAGE = 'Summarize';

export const UPGRADE_DASHBOARDS_CTA_CONFIG = {
  badge: 'Add-On',
  bulletPoints: [
    'Vision AI Summaries help you spot performance trends and create report-ready analytics digests',
    'Available with Premium Analytics add-on',
  ],
  cta: 'Try Premium Analytics',
  chiliPiperFormName: CHILI_PIPER_FORMS_MAP.premiumAnalytics.trial.name,
  chiliPiperFormRouter: CHILI_PIPER_FORMS_MAP.premiumAnalytics.trial.router,
  mixpanelEventName: 'Upgrade Options - Button Clicked',
  title: 'Summarize your Dashboards in seconds',
};

export const UPGRADE_CONVERSATIONAL_DASHBOARDS_CTA_CONFIG = {
  badge: 'Add-On',
  bulletPoints: [
    'Vision AI helps you spot performance trends and create report-ready analytics digests',
    'Available with the Premium Analytics add-on',
  ],
  cta: 'Try Premium Analytics',
  chiliPiperFormName: CHILI_PIPER_FORMS_MAP.premiumAnalytics.trial.name,
  chiliPiperFormRouter: CHILI_PIPER_FORMS_MAP.premiumAnalytics.trial.router,
  mixpanelEventName: 'Upgrade Options - Button Clicked',
  title: 'Understand your data in seconds',
};

export const UPGRADE_COMMUNITY_CTA_CONFIG = {
  badge: 'Upgrade',
  bulletPoints: [
    'Vision AI Summaries identify themes and provide quick insights into how your audience is engaging with your posts',
    'Available in the Advance plan',
  ],
  cta: 'Try Advance',
  chiliPiperFormName: CHILI_PIPER_FORMS_MAP.advance.upgrade.name,
  chiliPiperFormRouter: CHILI_PIPER_FORMS_MAP.advance.upgrade.router,
  mixpanelEventName: 'Upgrade Options - Button Clicked',
  title: 'Summarize your messages in seconds',
};

export const LISTENING_SEARCH_LIMIT = 25;

export const CONVERSATION_SETTING_ACTIONS = {
  RENAME: 'Rename',
  DELETE: 'Delete',
};

export const CONVERSATION_TEXT_BOX_WARNING =
  'When asking Vision AI about your social media performance, try to be clear and specific for the best results';

export const NEW_CHAT_TOOLTIP = {
  ENABLED: 'New Chat',
  DISABLED: 'You are already in a new chat',
};

export const ELEMENTS_TO_IGNORE_WHEN_CLOSING_POPUP = [
  '[data-cy="see-more-content-popup"]',
  '[data-cy="media-popup"]',
  '[data-cy="DropdownButton-Item"]',
  '[data-cy="rename-conversation-popup"]',
  '[data-cy="ConfirmDialog"]',
  '[ data-cy="vision-ai-button"]',
];

export const TEXTBOX_PLACEHOLDERS = {
  DEFAULT: 'Ask Vision AI about your analytics data',
  SUMMARIES: 'Start a new chat about your analytics data',
};

export const VISION_AI_MOCK_PROMPT_ROUTES = [
  COMMUNITY_ROUTE_NAMES.INSIGHTS,
  SOCIAL_LISTENING_ROUTE_NAMES.OVERVIEW,
];
