import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.linkedinApiUrl);

export const camelCaseAxios = createAxiosInstance(env.linkedinApiUrl, {
  camelizeKeys: true,
});

export async function getLinkedinAccount({ brandId }, axiosConfig = {}) {
  return camelCaseAxios.get(`/brands/${brandId}/account/me`, axiosConfig);
}

export function getLinkedinAccountAllBrands({ brandIds }, axiosConfig = {}) {
  return camelCaseAxios.put(`/brands/accounts`, { brand_ids: brandIds }, axiosConfig);
}
