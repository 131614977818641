import { computed, ref } from 'vue';
import { USER } from '@/models/auth/permissions.enum';
import { useAuthStore } from '@/stores/auth';
import { usePlatformStore } from '@/stores/platform';
import { useIdentityStore } from '@/stores/identity';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useFlagStore } from '@/stores/flag';

export function useTikTokAds(brand) {
  const platformType = PLATFORM_CONNECTION.TIKTOK_ADS;

  const authStore = useAuthStore();
  const platformStore = usePlatformStore();
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const brandToConnect = ref(brand.value ?? authStore.currentBrand);
  const brandId = ref(brandToConnect.value.id);

  const platformConnection = computed(() =>
    platformStore.getPlatformConnection(platformType, {
      brandId: brandId.value,
    }),
  );

  // computed
  const connectTikTokAdsToken = computed(() => {
    return authStore.oauthBrandToken || undefined;
  });
  const hasTikTokAdsConnectionFlag = computed(
    () => flagStore.ready && flagStore.flags.tiktokAdsConnection,
  );
  const hasValidPlatformConnection = computed(() =>
    platformStore.isPlatformConnected(platformType.value, { brandId: brandToConnect.value.id }),
  );
  const canManageTikTokAds = computed(() =>
    authStore.guard(USER.ADS.CAN_MANAGE_TIKTOK_ADS, brandToConnect.value),
  );
  const canEnableAccounts = computed(() => {
    if (identityStore.identity) {
      const userIdThatConnectedTikTokAds = platformConnection.value?.connected_by ?? null;
      const currentUserConnectedTikTokAds =
        !userIdThatConnectedTikTokAds || identityStore.identity.id === userIdThatConnectedTikTokAds;
      return canManageTikTokAds.value && currentUserConnectedTikTokAds;
    }
    return !!(connectTikTokAdsToken.value && hasValidPlatformConnection.value);
  });
  const canConnect = computed(() => {
    return canManageTikTokAds.value || hasValidPlatformConnection.value;
  });
  const hasTikTokAdAccount = computed(() => {
    const adAccount = platformStore.accounts[platformType.value]?.[brandId.value];
    return Boolean(adAccount);
  });

  return {
    brandToConnect,
    brandId,
    platformConnection,
    hasTikTokAdAccount,
    hasTikTokAdsConnectionFlag,
    hasValidPlatformConnection,
    canManageTikTokAds,
    canEnableAccounts,
    canConnect,
    connectTikTokAdsToken,
  };
}
