/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'linkedin': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M22.96 2.24H5.04a2.801 2.801 0 00-2.8 2.8v17.92c0 1.546 1.254 2.8 2.8 2.8h17.92c1.546 0 2.8-1.254 2.8-2.8V5.04c0-1.546-1.254-2.8-2.8-2.8zM9.52 11.2v10.64H6.16V11.2h3.36zM6.16 8.103c0-.784.672-1.383 1.68-1.383s1.64.6 1.68 1.383c0 .784-.627 1.417-1.68 1.417-1.008 0-1.68-.633-1.68-1.417zM21.84 21.84h-3.36v-5.6c0-1.12-.56-2.24-1.96-2.262h-.045c-1.355 0-1.915 1.153-1.915 2.262v5.6H11.2V11.2h3.36v1.434s1.08-1.434 3.254-1.434c2.223 0 4.026 1.529 4.026 4.626v6.014z" _fill="#fff"/>'
  }
})
