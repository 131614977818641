<template>
  <div>
    <DatePicker
      v-bind="$attrs"
      ref="calendar"
      :model-value="internalModel"
      locale="en"
      :is-range="isRange"
      :attributes="combinedAttributes"
      :select-attribute="calendarSelectAttribute"
      :drag-attribute="calendarDragAttribute"
      :masks="combinedMasks"
      @dayclick="onDayClick"
      @drag="onDrag"
      @update:pages="onUpdate"
      v-on="datePickerListeners"
    >
      <template #header-prev-button>
        <slot name="header-prev-button">
          <Icon dir="down" name="caret" xsmall />
        </slot>
      </template>
      <template #header-next-button>
        <slot name="header-next-button">
          <Icon dir="up" name="caret" xsmall />
        </slot>
      </template>
    </DatePicker>
    <template v-if="isDateTime">
      <TimeInput
        v-model="startDate"
        :disabled="(isDateTime && !startDate) || isDragging"
        class="date-picker-time-input"
      />
      <template v-if="isRange">
        <TimeInput
          v-model="endDate"
          :disabled="(isDateTime && !endDate) || isDragging"
          class="date-picker-time-input"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isSameDay from 'date-fns/isSameDay';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { DatePicker } from 'v-calendar';
import { todayAttribute, dragAttribute, rangeAttribute } from '@/utils/vcalendar.utils';
import TimeInput from '@/components/foundation/pickers/TimeInput.vue';
import { extractEventsFromAttributes } from '@/utils/vue-attrs';
import Icon from '@/components/foundation/Icon.vue';

function mergeArrayByProperty(leftArray, rightArray, property) {
  const merged = merge(keyBy(leftArray, property), keyBy(rightArray, property));
  return values(merged);
}

const DEFAULT_MASKS = {
  dayPopover: '',
};

function setDateOnly(oldDate, newDate) {
  if (!oldDate) {
    return newDate;
  }
  const updatedDate = new Date(oldDate);
  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const date = newDate.getDate();
  updatedDate.setFullYear(year, month, date);

  return updatedDate;
}

const comp = defineComponent({
  components: {
    DatePicker,
    TimeInput,
    Icon,
  },
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
    selectAttribute: {
      type: Object,
      default: null,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Object, Date],
      default: null,
    },
    mode: {
      type: String,
      default: undefined,
    },
    masks: {
      type: Object,
      default: null,
    },
    disabled: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'drag', 'page'],
  data() {
    return {
      isDragging: false,
      internalModel: null,
      dragRange: null,
    };
  },
  computed: {
    startDate: {
      get() {
        return this.isRange ? this.modelValue?.start || null : this.modelValue;
      },
      set(newValue) {
        this.internalModel = this.isRange
          ? { start: newValue, end: this.internalModel?.end || null }
          : newValue;
      },
    },
    endDate: {
      get() {
        return this.isRange ? this.modelValue?.end || null : null;
      },
      set(newValue) {
        if (this.isRange) {
          this.internalModel = { start: this.internalModel?.start || null, end: newValue };
        }
      },
    },
    defaultAttributes() {
      return [todayAttribute()];
    },
    combinedAttributes() {
      return mergeArrayByProperty(this.defaultAttributes, this.attributes, 'key');
    },
    isRangeSameDay() {
      return this.isRange && isSameDay(this.internalModel?.start, this.internalModel?.end);
    },
    calendarDragAttribute() {
      return dragAttribute();
    },
    calendarSelectAttribute() {
      return (
        this.selectAttribute || rangeAttribute({ isSameDay: !this.isRange || this.isRangeSameDay })
      );
    },
    isDateTime() {
      return this.mode === 'dateTime';
    },
    datePickerListeners() {
      const events = extractEventsFromAttributes(this.$attrs, true);
      const { input, drag, ...listeners } = events;
      return mapKeys(listeners, (v, k) => camelCase(k));
    },
    combinedMasks() {
      return merge({}, DEFAULT_MASKS, this.masks);
    },
    calendarRef() {
      return this.$refs.calendar;
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.internalModel = newValue;
      },
      immediate: true,
    },
    internalModel: {
      handler(newValue) {
        if (!isEqual(this.modelValue, newValue)) {
          this.$emit('update:modelValue', newValue);
        }
      },
    },
  },
  methods: {
    onDayClick(value) {
      /* Work around because v-calendar DatePicker doesn't always update the v-model */

      if (this.$refs.calendar) {
        this.isDragging = this.$refs.calendar.isDragging;
      }

      if (!this.isRange) {
        if (this.internalModel instanceof Date && value?.startDate) {
          const newInternalModel = setDateOnly(this.internalModel, value.startDate);
          this.internalModel = newInternalModel;
        } else {
          this.internalModel = value?.startDate;
        }
      } else if (this.dragRange && !this.isDragging) {
        this.internalModel = {
          start: setDateOnly(this.internalModel?.start, this.dragRange?.start),
          end: setDateOnly(this.internalModel?.end, this.dragRange?.end),
        };
        this.dragRange = null;
      }
    },
    onDrag(range) {
      this.dragRange = range;
      this.$emit('drag', range);
    },
    focusDate(...args) {
      if (this.$refs.calendar) {
        this.$refs.calendar.focusDate(...args);
      }
    },
    move(...args) {
      if (this.$refs.calendar) {
        this.$refs.calendar.move(...args);
      }
    },
    onUpdate(page) {
      this.$emit('page', page[0]);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.date-picker-time-input {
  border-top: 1px solid var(--border);
}
</style>
