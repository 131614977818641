import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import {
  getIntervalMillisecondDuration,
  intersectIntervals,
  isAllTime,
  isWithinInterval,
} from '@/utils/dateUtils';
import { formatDateRangeLabel } from '@/utils/formatters';
import { autoPublishErrors } from '@/app/scheduler/constants';

dayjs.extend(minMax);

/**
 * Finds a timestamp overlap of media objects, if one exists
 *
 * @param {Object|Object[]} media one or more media objects
 * @returns {{start: Date, end: Date} | false} start and end times of the time range overlap, false if none exist
 */
export const findDateRangeOverlap = (media) => {
  const mediaList = Array.isArray(media) ? media : [media];

  const publishIntervals = mediaList
    .filter((m) => m?.canPublishWithin?.start && m?.canPublishWithin?.end)
    .map((m) => ({
      start: dayjs(m.canPublishWithin.start).toDate(),
      end: dayjs(m.canPublishWithin.end).toDate(),
    }));

  const intersection = intersectIntervals(...publishIntervals);
  return getIntervalMillisecondDuration(intersection) > 0 ? intersection : false;
};

export const getConflictingMediaIds = (media, timestamp = dayjs()) => {
  return []
    .concat(media)
    .filter((m) => m.canPublishWithin?.start > timestamp || m.canPublishWithin?.end < timestamp)
    .map((m) => m.id);
};

/**
 * Validate approved publishing dates of provided media have some overlap,
 * and the provided timestamp falls within the overlap. If validation
 * is successful, return null, otherwise, return an object specifying
 * an error banner.
 *
 * @param {{start: Date, end: Date} | false} overlap object containing start, and end dates
 * @param {Date} timestamp the timestamp to validate
 * @returns {{level: String, icon: String, message: String} | null} A validation message if invalid, or null if valid
 */
export const validatePublishDates = (overlap, timestamp) => {
  const dateTimestamp = timestamp && dayjs(timestamp).toDate();

  if (!overlap) {
    return {
      level: 'error',
      icon: 'date-blocked',
      code: autoPublishErrors.APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP,
      message:
        'The selected media cannot be published together because their approved date ranges do not overlap. Please choose media with compatible date ranges or adjust your selection.',
    };
  }

  if (overlap.end < new Date()) {
    return {
      level: 'error',
      icon: 'date-blocked',
      message: 'Approved publishing dates for this media are in the past.',
    };
  }

  if (dateTimestamp && !isWithinInterval(dateTimestamp, overlap)) {
    const publishingDatesDescription = formatDateRangeLabel(overlap.start, overlap.end, {
      forceYearDisplay: false,
      withTime: true,
    });
    return {
      level: 'error',
      icon: 'date-blocked',
      code: 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES',
      message: `Media must be published between ${publishingDatesDescription}`,
    };
  }

  return null;
};

/**
 * Validate approved publishing dates of provided media have some overlap,
 * and the provided timestamp falls within the overlap. If validation
 * is successful, return null, otherwise, return an object specifying
 * an error banner.
 *
 * @param {{start: Date, end: Date} | false} overlap object containing start, and end dates
 * @param {Date} timestamp the currently selected post timestamp
 * @returns {{level: String, message: String} | null} A warning message if applicable, otherwise null
 */
export const getPublishDateWarning = (overlap, timestamp) => {
  const dateTimestamp = timestamp && dayjs(timestamp).toDate();

  if (
    !overlap ||
    isAllTime(overlap) ||
    (dateTimestamp && !isWithinInterval(dateTimestamp, overlap))
  ) {
    return null;
  }

  const { start, end } = overlap;
  const approvedPublishDates = formatDateRangeLabel(start, end, {
    forceYearDisplay: false,
    withTime: true,
  });

  return {
    level: 'information',
    message: `Media must be published between ${approvedPublishDates}`,
  };
};
