async function validateRules(rules, value, stopOnFirstError = false) {
  return rules.reduce(async (previousPromise, rule) => {
    const acc = await previousPromise;
    if (acc?.length > 0 && stopOnFirstError) {
      return acc;
    }

    const valid = await rule(value);
    if (valid === false || typeof valid === 'string') {
      acc.push(valid || '');
    }
    return acc;
  }, Promise.resolve([]));
}

export default validateRules;
