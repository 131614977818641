<script setup>
import { computed, ref, onMounted, nextTick } from 'vue';
import { SelectList, Avatar } from '@dashhudson/dashing-ui';
import { useAuthStore } from '@/stores/auth';
import { useTrackingStore } from '@/stores/tracking';
import { useUtmStore } from '@/stores/utm';
import { useElementSize } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { getBrandInitials } from '@/utils/brand';
import { MIXPANEL_VALUES } from '@/components/layout/navigation/constants';
import { trackNavigation } from '@/utils/analytics/tracking';

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const trackingStore = useTrackingStore();
const utmStore = useUtmStore();

const emits = defineEmits(['selected']);

const selectedBrand = ref(authStore.currentBrand);
const selectList = ref(null);

const selectListWrapper = ref(null);
const { height } = useElementSize(selectListWrapper);
const selectListWrapperInitialHeight = ref(null);

const brandList = computed(() => {
  if (authStore.identity) {
    const brands = Object.keys(authStore.identity.brands)
      .map((brandLabel) => ({
        value: authStore.identity.brands[brandLabel],
        label: authStore.identity.brands[brandLabel].name,
        icon: authStore.identity.brands[brandLabel].avatarUrl,
      }))
      .filter((brand) => brand.value.id !== authStore.currentBrand.id);
    return [
      {
        value: authStore.currentBrand,
        label: authStore.currentBrand.name,
        icon: authStore.currentBrand.avatarUrl,
      },
      ...brands,
    ];
  }
  return [];
});

function selectBrand(selectedBrandValue) {
  try {
    const brandLabel = selectedBrandValue.label;
    if (route?.params?.brandLabel) {
      if (utmStore.isManagementSettingsDirty) {
        utmStore.confirmLeaving(() => {
          router.push({
            name: route.name,
            params: { brandLabel },
          });
        });
      } else {
        router.push({
          name: route.name,
          params: { brandLabel },
        });
      }
    } else {
      authStore.setCurrentBrand({ brandLabel });
      nextTick(() => {
        // faking a Page Viewed event to keep metrics consistent
        trackingStore.trackPageViewedEvent(route, null);
      });
    }
  } finally {
    emits('selected');
  }
  trackNavigation(MIXPANEL_VALUES.UTILITY_MENU.ROOT, MIXPANEL_VALUES.UTILITY_MENU.SWITCH_BRAND);
}

function trackSearch(to, from) {
  if (to && to.length === 1 && !from) {
    trackNavigation(MIXPANEL_VALUES.UTILITY_MENU.ROOT, MIXPANEL_VALUES.UTILITY_MENU.SEARCH_BRAND);
  }
}

onMounted(() => {
  selectListWrapperInitialHeight.value = height.value;

  // This is a workaround because of how DropdownBase (FloatingVue) works currently.
  setTimeout(() => {
    selectList.value.$el.querySelector('input').focus();
  }, 200);
});
</script>

<template>
  <div
    ref="selectListWrapper"
    class="dash-bg-background-page flex w-[var(--secondary-navigation-width)] px-4 py-4 [&_label]:!w-full [&_li]:min-h-[2.625rem] [&_li_>div]:w-full [&_ul]:max-h-[33.5rem] [&_ul]:overflow-auto"
    :style="{ minHeight: `${selectListWrapperInitialHeight}px` }"
  >
    <SelectList
      ref="selectList"
      v-model="selectedBrand"
      :options="brandList"
      :searchable="true"
      empty-text="No brands found"
      hide-controls
      search-placeholder="Search brand"
      :display-options-limit="3"
      class="cy-test--brand-switcher-select-list !w-full !max-w-none"
      @update:model-value="selectBrand"
      @search="trackSearch"
    >
      <template #optionLabel="{ option, label }">
        <div
          v-dash-tooltip="{ content: label, delay: { show: 800, hide: 0 } }"
          class="flex w-full items-center gap-2"
        >
          <Avatar
            :image="option.icon"
            :label="option.icon ? null : getBrandInitials(option.value)"
            size="sm"
            class="ml-3 h-5 w-5 shrink-0 cursor-pointer"
          />
          <p
            class="dash-typo-base-medium max-w-44 overflow-hidden text-ellipsis"
            :class="selectedBrand.name === label ? 'dash-text-primary' : 'dash-text-subtle'"
          >
            {{ label }}
          </p>
        </div>
      </template>
    </SelectList>
  </div>
</template>
