export const COMPETITIVE_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'AVG_ENGAGEMENT_RATE_PUBLIC',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 16,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'AVG_ENGAGEMENT_RATE_PUBLIC',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ORGANIC_LIKES',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 20,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ORGANIC_LIKES',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ORGANIC_COMMENTS',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_GRAPH',
    w: 3,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_COMPETITIVE'],
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ORGANIC_COMMENTS',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 28, meta: { name: 'Your Posts' } },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 33,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 37,
    meta: { name: "Your Competitors' Posts" },
  },
  {
    h: 4,
    type: 'COMPETITIVE_CONTENT',
    w: 6,
    x: 0,
    y: 38,
    meta: {
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'COMPETITIVE_CONTENT',
    w: 6,
    x: 0,
    y: 42,
    meta: {
      channel: 'INSTAGRAM_COMPETITIVE',
      competitor_source_account_ids: ['competitor_ids'],
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
