import { ref } from 'vue';
import { defineStore } from 'pinia';
import { LibraryAPI } from '@/apis';
import { useTrackingStore } from '@/stores/tracking';

function formatProducts(products) {
  if (!products) {
    return [];
  }
  return products.map((product) => {
    const res = {
      id: product.id,
      imageUrl: product.imageUrl,
      url: product.url,
      title: product.title,
      description: product.description,
      availability: product.availability,
      sourceId: product.sourceId,
      clicks: product.clicks,
      productFeed: product.productFeed,
      archived: product.archived,
      price: product.price,
      productOverrides: product.productOverrides,
    };
    return res;
  });
}
function formatProductMediaList(productMediaList) {
  return productMediaList.map((media) => {
    const res = {
      id: media.mediaId,
      url: media.urls ? media.urls.ratio : media.urls.original,
      urls: media.urls,
      media_type: media.mediaType,
      source_type: `${media.brandId}:${media.type}`,
      width: media.originalWidth,
      height: media.originalHeight,
      source: media.type,
      // !! converts null/undefined to false
      hidden: !!media.hideInWidget,
      imageUrl: media.urls ? media.urls.ratio : media.urls.original,
      predictions: media.predictions,
    };
    return res;
  });
}

export const useProductStore = defineStore(
  'product',
  () => {
    const trackingStore = useTrackingStore();

    const products = ref([]);
    const productsAnalytics = ref(null);
    const currentLinkEditor = ref({});
    const product = ref({});
    const addProductStatus = ref(null);
    const productClicks = ref(null);
    const productMedia = ref([]);
    const productNextUrl = ref(null);
    const productFeed = ref(null);
    const productFeeds = ref([]);
    const productListFullyLoaded = ref(false);
    const editProductStatus = ref(null);
    const urlMetadata = ref({
      image_url: null,
      url: null,
      title: '',
      description: '',
    });
    const urlValidationStatusDict = ref({});
    const productIndexChartDateRange = ref(null);
    const productIndexChartScale = ref(null);
    const productMediaHiddenStatus = ref(null);
    const updateProductsArchivedStatus = ref(null);

    const pending = ref({
      products: false,
      product: false,
      productMedia: false,
      productClicks: false,
      productFeeds: false,
      urlMetadata: false,
      productsAnalytics: false,
      updateProductsArchivedStatus: false,
      findProductByUrl: false,
    });

    const error = ref({
      urlMetadata: false,
    });

    let productsController = null;

    function clearProducts() {
      products.value = [];
      productNextUrl.value = null;
      productListFullyLoaded.value = false;
    }

    function clearProductFeeds() {
      productFeeds.value = [];
    }

    function clearUrlMetadata() {
      urlMetadata.value = {
        image_url: null,
        url: null,
        title: '',
        description: '',
      };
      currentLinkEditor.value = {};
      urlValidationStatusDict.value = {};
    }

    function setCurrentLinkEditor({ id, url }) {
      if (url) {
        currentLinkEditor.value = {
          ...currentLinkEditor.value,
          [id]: url,
        };
      } else {
        const newValue = { ...currentLinkEditor.value };
        delete newValue[id];
        currentLinkEditor.value = newValue;
      }
    }

    function clearProduct() {
      product.value = {};
      productClicks.value = null;
      productMedia.value = [];
    }

    function setProductIndexChartDateRange(dateRange) {
      productIndexChartDateRange.value = dateRange;
    }

    function clearProductIndexChartDateRange() {
      productIndexChartDateRange.value = null;
    }

    function setProductIndexChartScale(scale) {
      productIndexChartScale.value = scale;
    }

    function clearProductIndexChartScale() {
      productIndexChartScale.value = null;
    }

    async function updateProduct({
      brandId,
      productId,
      data,
      feedType,
      productCatalogName,
      context,
    }) {
      const response = await LibraryAPI.updateProduct({ brandId, productId, data });
      editProductStatus.value = response?.data;
      trackingStore.track('Product Link Updated', {
        product: data,
        feedType,
        productCatalog: productCatalogName,
        productEditContext: context,
      });
    }

    async function getProducts({ brandId, url, ...params }) {
      if (productsController) {
        productsController.abort();
      }
      productsController = new AbortController();
      pending.value.products = true;
      try {
        const response = await LibraryAPI.getProducts(
          {
            brandId,
            url,
            ...params,
          },
          { signal: productsController.signal },
        );
        const payload = response?.data;
        if (payload?.paging) {
          const { next } = payload.paging;
          productNextUrl.value = next;
          productListFullyLoaded.value = !next;
        }
        if (payload?.data) {
          const formattedPayloadData = formatProducts(payload.data);
          products.value = [...products.value, ...formattedPayloadData];
        }
        pending.value.products = false;
      } catch (e) {
        if (e?.response?.status === 404) {
          pending.value.products = false;
        } else if (e?.name === 'CanceledError') {
          pending.value.products = true;
        } else {
          pending.value.products = false;
          throw e;
        }
      }
    }

    async function getProduct({ brandId, productId }) {
      pending.value.product = true;
      try {
        const response = await LibraryAPI.getProduct({ brandId, productId });
        if (response?.data) {
          product.value = response.data;
        }
      } catch (e) {
        if (e?.response?.status !== 404) {
          throw e;
        }
      } finally {
        pending.value.product = false;
      }
    }

    async function findProductByUrl({ brandId, url }) {
      pending.value.findProductByUrl = true;
      try {
        const response = await LibraryAPI.findProductByUrl({
          url,
          brandId,
        });
        return response.data;
      } finally {
        pending.value.findProductByUrl = false;
      }
    }

    async function addProduct({ brandId, context }, data) {
      const response = await LibraryAPI.addProduct({ brandId }, data);
      if (response?.data) {
        addProductStatus.value = response.data;
        trackingStore.track('Product Link Added', {
          product: {
            image_url: data.imageUrl,
            title: data.title,
            url: data.url,
          },
          productAddedContext: context,
        });
      }
    }

    async function updateProductArchivedStatusByIds({ brandId, productIds, archived }) {
      pending.value.updateProductsArchivedStatus = true;
      const response = await LibraryAPI.updateProductArchivedStatusByIds({
        brandId,
        productIds,
        archived,
      });
      updateProductsArchivedStatus.value = response?.data;
      pending.value.updateProductsArchivedStatus = false;
      return response;
    }

    async function getProductFeeds({ brandId }) {
      pending.value.productFeeds = true;
      const response = await LibraryAPI.getProductFeeds({ brandId });
      productFeeds.value = response?.data?.data;
      pending.value.productFeeds = false;
    }

    async function addProductFeed({ brandId }, data) {
      const response = await LibraryAPI.addProductFeed({ brandId }, data);
      productFeed.value = response?.data;
    }

    async function updateProductFeed({ brandId, feedId }, data) {
      const response = await LibraryAPI.updateProductFeed({ brandId, feedId }, data);
      productFeed.value = response?.data;
    }

    async function archiveProductFeedProducts({ brandId, feedId, notificationSocketId, archived }) {
      await LibraryAPI.archiveProductFeedProducts({
        brandId,
        feedId,
        notificationSocketId,
        archived,
      });
    }

    async function deleteProductFeed({ brandId, feedId }) {
      const response = await LibraryAPI.deleteProductFeed({ brandId, feedId });
      productFeed.value = response?.data;
    }

    async function getUrlMetadata({ url, id, linkNumber }, axiosConfig = {}) {
      pending.value.urlMetadata = true;
      error.value.urlMetadata = false;
      if (id && urlValidationStatusDict.value[id]) {
        const newValue = { ...urlValidationStatusDict.value };
        delete newValue[id];
        urlValidationStatusDict.value = newValue;
      }
      try {
        const response = await LibraryAPI.getUrlMetadata({ url, id, linkNumber }, axiosConfig);
        urlMetadata.value = response?.data;
        pending.value.urlMetadata = false;
        return response;
      } catch (e) {
        pending.value.urlMetadata = false;
        error.value.urlMetadata = true;
        if (id) {
          urlValidationStatusDict.value = {
            ...urlValidationStatusDict.value,
            [id]: url,
          };
        }
        throw e;
      }
    }

    async function getProductsAnalytics({ brandId, scale, startDate, endDate }) {
      pending.value.productsAnalytics = true;
      const response = await LibraryAPI.getProductsAnalytics({
        brandId,
        scale,
        startDate,
        endDate,
      });
      productsAnalytics.value = response?.data;
      pending.value.productsAnalytics = false;
    }

    async function getProductMedia({ brandId, productId }) {
      pending.value.productMedia = true;
      const response = await LibraryAPI.getProductMedia({ brandId, productId });
      productMedia.value = formatProductMediaList(response?.data);
      pending.value.productMedia = false;
    }

    async function getProductClicks({ brandId, productId, startDate, endDate, scale, source }) {
      pending.value.productClicks = true;
      const response = await LibraryAPI.getProductClicks({
        brandId,
        productId,
        startDate,
        endDate,
        scale,
        source,
      });
      productClicks.value = response?.data;
      pending.value.productClicks = false;
    }

    async function setProductMediaHiddenStatus({ brandId, productId, mediaId, hideInWidget }) {
      const response = await LibraryAPI.setProductMediaHiddenStatus({
        brandId,
        productId,
        mediaId,
        hideInWidget,
      });
      if (response?.data) {
        productMediaHiddenStatus.value = response?.data;
        const targetIndex = productMedia.value.findIndex((media) => media.id === mediaId);
        if (targetIndex > -1) {
          const targetMedia = productMedia.value[targetIndex];
          targetMedia.hidden = hideInWidget;
          productMedia.value.splice(targetIndex, 1, targetMedia);
        }
      }
    }

    return {
      products,
      productsAnalytics,
      currentLinkEditor,
      product,
      addProductStatus,
      productClicks,
      productMedia,
      productNextUrl,
      productFeed,
      productFeeds,
      productListFullyLoaded,
      editProductStatus,
      urlMetadata,
      urlValidationStatusDict,
      productIndexChartDateRange,
      productIndexChartScale,
      productMediaHiddenStatus,
      updateProductsArchivedStatus,
      pending,
      error,

      clearProducts,
      clearProductFeeds,
      clearUrlMetadata,
      setCurrentLinkEditor,
      clearProduct,
      setProductIndexChartDateRange,
      clearProductIndexChartDateRange,
      setProductIndexChartScale,
      clearProductIndexChartScale,
      updateProduct,
      getProducts,
      getProduct,
      findProductByUrl,
      addProduct,
      updateProductArchivedStatusByIds,
      getProductFeeds,
      addProductFeed,
      updateProductFeed,
      archiveProductFeedProducts,
      deleteProductFeed,
      getUrlMetadata,
      getProductsAnalytics,
      getProductMedia,
      getProductClicks,
      setProductMediaHiddenStatus,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
