/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 2,
    height: 12,
    viewBox: '0 0 2 12',
    data: '<defs><path pid="0" d="M9 4h2v8H9V4zm1 12a1 1 0 100-2 1 1 0 000 2z" id="svgicon_warning_a"/></defs><use _fill="#FFF" fill-rule="nonzero" xlink:href="#svgicon_warning_a" transform="translate(-9 -4)"/>'
  }
})
