import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import {
  getFollowersExport,
  getAnalyticsPinMetricsExport,
  getAnalyticsTopPinsExport,
  getYoursPinsExport,
} from '@/apis/pinterest';
import { downloadFileFromMemory } from '@/utils';
import { useTrackingStore } from '@/stores/tracking';

const TYPES = {
  FOLLOWERS: 'followers',
  BOARDS: 'boards',
  YOUR_PINS: 'yourPins',
};

export const usePinterestExportStore = defineStore('pinterestExport', () => {
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();

  const followersExportPending = ref(false);
  const yourPinsExportPending = ref(false);
  const boardsExportPending = ref(false);
  const pinMetricsExportPending = ref(false);
  const topPinsExportPending = ref(false);

  async function getFollowersCSV({ startDate, endDate, scale }) {
    const filename = `${authStore.currentBrand.label}-Pinterest-followers.csv`;
    const brandId = authStore.currentBrand?.id;
    followersExportPending.value = true;
    try {
      const response = await getFollowersExport({
        brandId,
        startDate,
        endDate,
        type: TYPES.FOLLOWERS,
        filename,
        includeAggregate: 1,
        scale,
      });
      const payload = response?.data;

      downloadFileFromMemory(payload, filename);

      trackingStore.track('CSV Downloaded', {
        type: 'Pinterest Followers',
        fileName: filename,
        format: 'CSV',
      });

      return payload;
    } finally {
      followersExportPending.value = false;
    }
  }

  async function getPinMetricsCSV({ startDate, endDate, scale }) {
    const filename = `pin-metrics-${startDate}-${endDate}.csv`;
    const brandId = authStore.currentBrand?.id;
    pinMetricsExportPending.value = true;
    try {
      const response = await getAnalyticsPinMetricsExport({
        brandId,
        startDate,
        endDate,
        scale,
        filename,
      });
      const payload = response?.data;

      downloadFileFromMemory(payload, filename);

      trackingStore.track('CSV Downloaded', {
        type: 'Engagement Metrics',
        fileName: filename,
        format: 'CSV',
      });

      return payload;
    } finally {
      pinMetricsExportPending.value = false;
    }
  }

  async function getTopPinsCSV({ startDate, endDate, sort }) {
    const filename = `top-pins-${startDate}-${endDate}.csv`;
    const brandId = authStore.currentBrand?.id;
    topPinsExportPending.value = true;
    try {
      const response = await getAnalyticsTopPinsExport({
        brandId,
        startDate,
        endDate,
        filename,
        sort,
      });
      const payload = response?.data;

      downloadFileFromMemory(payload, filename);

      trackingStore.track('CSV Downloaded', {
        type: 'Top Pins',
        fileName: filename,
        format: 'CSV',
      });

      return payload;
    } finally {
      topPinsExportPending.value = false;
    }
  }

  async function getYourPinsCSV({ startDate, endDate, mediaType, privacy }) {
    const filename = `${authStore.currentBrand.label}-pins.csv`;
    const brandId = authStore.currentBrand?.id;
    yourPinsExportPending.value = true;
    try {
      const response = await getYoursPinsExport({
        brandId,
        startDate,
        endDate,
        mediaType,
        privacy,
      });
      const payload = response?.data;

      downloadFileFromMemory(payload, filename);

      trackingStore.track('CSV Downloaded', {
        type: 'Your Pins',
        fileName: filename,
        format: 'CSV',
      });

      return payload;
    } finally {
      yourPinsExportPending.value = false;
    }
  }

  function receiveCSV({ type }) {
    if (type === TYPES.FOLLOWERS) {
      followersExportPending.value = false;
    } else if (type === TYPES.BOARDS) {
      boardsExportPending.value = false;
    } else if (type === TYPES.YOUR_PINS) {
      yourPinsExportPending.value = false;
    }
  }

  return {
    followersExportPending,
    yourPinsExportPending,
    boardsExportPending,
    pinMetricsExportPending,
    topPinsExportPending,
    getFollowersCSV,
    getPinMetricsCSV,
    getTopPinsCSV,
    getYourPinsCSV,
    receiveCSV,
  };
});
