import { useAuthStore } from '@/stores/auth';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';

export function formatToIndividualCompetitorTags(competitorTags) {
  const formattedTags = Object.keys(competitorTags ?? {}).reduce((acc, brandId) => {
    const brandCompetitorTags = competitorTags[brandId];
    brandCompetitorTags.forEach((tag) => acc.add({ brandId: parseInt(brandId, 10), tagId: tag }));
    return acc;
  }, new Set([]));
  return [...formattedTags];
}

export function formatCompetitorTagsByBrand(competitorTags) {
  return competitorTags.reduce((acc, tag) => {
    const existingVal = acc?.[tag.brandId];
    acc[tag.brandId] = existingVal ? existingVal.concat([tag.tagId]) : [tag.tagId];
    return acc;
  }, {});
}

export function convertLibraryCompetitorTagsToArray(competitorTagsByBrand, channel = null) {
  const combinedTagsList = [];

  competitorTagsByBrand.forEach((brandTagObject) => {
    const brandId = brandTagObject.brandId;

    brandTagObject.tags.forEach((tag) => {
      if (tag.competitors.length > 0) {
        const tagWithExtras = {
          ...tag,
          brandId,
          tagId: tag.id,
          text: tag.name,
          value: { tagId: tag.id, brandId },
        };

        if (channel !== null) {
          tagWithExtras.competitors = tagWithExtras.competitors.filter((competitor) => {
            return competitor.source === channel;
          });
        }
        if (tagWithExtras.competitors.length > 0) {
          combinedTagsList.push(tagWithExtras);
        }
      }
    });
  });

  combinedTagsList.sort((left, right) => left.name.localeCompare(right.name));
  return combinedTagsList;
}

export function getCompetitorSourceAccountIDsFromTags(selectedTags) {
  const sourceIDs = [];
  selectedTags.forEach((tagToConvert) => {
    tagToConvert.competitors.forEach((competitor) => {
      sourceIDs.push(competitor.sourceAccountId);
    });
  });
  const uniqueSourceIDs = new Set(sourceIDs);
  return [...uniqueSourceIDs];
}

export function extractBrandTagObjects({ report, brandTags } = {}) {
  const brandTagsToUse = report?.meta?.brand_tags ?? brandTags;
  const authStore = useAuthStore();
  return Object.values(authStore?.brandTagsById)
    .filter((tag) => {
      return brandTagsToUse?.includes(tag.id);
    })
    .map((brandTag) => ({ ...brandTag, dataType: 'BRAND' }));
}

export function extractBrandTagObjectsFromReport(report) {
  return extractBrandTagObjects({ report });
}

export function uniqueBrandIdsFromTags(brandTagObjects) {
  if (brandTagObjects.map((tag) => tag.brands).length === 0) {
    return [];
  }
  return [
    ...new Set(
      brandTagObjects
        ?.map((tag) => tag.brands)
        ?.reduce((prev, next) => prev.concat(next))
        ?.map((brand) => brand.id),
    ),
  ];
}

export function extractBrandIds({ report, brandTags, brandIds }) {
  if (report?.meta?.brand_tags?.length > 0 || brandTags?.length > 0) {
    const brandTagObjects = extractBrandTagObjects({ report, brandTags });
    return uniqueBrandIdsFromTags(brandTagObjects);
  }
  return report?.meta?.brand_ids ?? brandIds ?? [];
}

export function extractBrandIdsFromReport(report) {
  return extractBrandIds({ report });
}

export function extractCompetitorTags({ report, competitorTags }) {
  const dashboardReportsStore = useDashboardReportsStore();

  const metaCompetitorTags = report?.meta?.competitor_tags ?? competitorTags ?? {};
  const competitorTagsToUse = Object.entries(metaCompetitorTags)
    .map(([brandId, competitorTagIds]) => {
      const competitorTagsForBrand = (dashboardReportsStore?.competitorTags ?? [])
        .filter((brandCompetitorTags) => brandCompetitorTags.brandId === Number(brandId))
        .map((brandCompetitorTags) =>
          brandCompetitorTags.tags.filter((tag) => competitorTagIds?.includes(tag.id)),
        )
        .flat()
        .map((competitor) => ({
          ...competitor,
          brandId: Number(brandId),
          dataType: 'COMPETITOR',
        }));
      return competitorTagsForBrand;
    })
    .flat();
  return competitorTagsToUse;
}

export function extractCompetitorTagsFromReport(report) {
  return extractCompetitorTags({ report });
}

export function uniqueSourceAccountIdsFromCompetitorTags(competitorTagObjects) {
  const sourceAccountIds = competitorTagObjects
    .map((competitorTag) =>
      competitorTag?.competitors?.map((competitor) => competitor?.sourceAccountId),
    )
    .flat();
  return [...new Set(sourceAccountIds)];
}

export function uniqueSourceAccountIds({ report, competitorTags, competitorSourceAccountIds }) {
  const metaCompetitorTags = report?.meta?.competitor_tags ?? competitorTags ?? {};
  const hasCompetitorTags = Object.values(metaCompetitorTags)?.length > 0;
  if (hasCompetitorTags) {
    const competitorTagObjects = extractCompetitorTags({ report, competitorTags });
    return uniqueSourceAccountIdsFromCompetitorTags(competitorTagObjects);
  }
  return report?.meta?.competitor_source_account_ids ?? competitorSourceAccountIds ?? [];
}

export function uniqueSourceAccountIdsFromReport(report) {
  return uniqueSourceAccountIds({ report });
}

export function getBrandIdsFromTags(brandTags) {
  return uniqueBrandIdsFromTags(extractBrandTagObjects({ report: null, brandTags }));
}
