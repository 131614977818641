import { computed, unref } from 'vue';
import { TOTAL_METRIC_GROUPS } from '@/app/dashboards/constants';
import { ADS_REPORT_AGGREGATE_BY_TYPES } from '@/models/dashboards/aggregate-type.enum';

export function useReportMetaAggregateBy({ aggregateBy } = {}) {
  const reportAggregateBy = computed(() => unref(aggregateBy));

  const reportIsGroupedByChannel = computed(() => {
    return reportAggregateBy.value === TOTAL_METRIC_GROUPS.CHANNEL;
  });

  const reportIsGroupedByBrand = computed(() => {
    return reportAggregateBy.value === TOTAL_METRIC_GROUPS.BRAND;
  });

  const reportIsGroupedByGender = computed(() => {
    return reportAggregateBy.value === ADS_REPORT_AGGREGATE_BY_TYPES.AGE_GENDER.value;
  });

  const reportIsGroupedByPublisherPlatform = computed(() => {
    return reportAggregateBy.value === ADS_REPORT_AGGREGATE_BY_TYPES.PUBLISHER_PLATFORM.value;
  });

  const reportIsGroupedByCampaign = computed(() => {
    return reportAggregateBy.value === ADS_REPORT_AGGREGATE_BY_TYPES.SOURCE_CAMPAIGN.value;
  });
  const reportIsGroupedByCountry = computed(() => {
    return reportAggregateBy.value === ADS_REPORT_AGGREGATE_BY_TYPES.COUNTRY.value;
  });
  const reportIsGroupedByRegion = computed(() => {
    return reportAggregateBy.value === ADS_REPORT_AGGREGATE_BY_TYPES.REGION.value;
  });

  return {
    reportIsGroupedByChannel,
    reportIsGroupedByBrand,
    reportIsGroupedByGender,
    reportIsGroupedByPublisherPlatform,
    reportIsGroupedByCampaign,
    reportIsGroupedByCountry,
    reportIsGroupedByRegion,
  };
}
