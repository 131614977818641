<template>
  <div
    v-if="showBadge"
    v-tooltip="{ content: PREDICTION_RATINGS[fieldRating].tooltip + '.' }"
    :class="classes"
    class="performance-badge"
  >
    <Icon
      :name="fieldRating"
      :color="PREDICTION_RATINGS[fieldRating].color"
      :xsmall="!small"
      :xxsmall="small"
      :xxxsmall="xsmall"
    />
  </div>
  <div
    v-else-if="showLoader"
    v-tooltip="'Vision prediction in progress'"
    :class="['loading', classes]"
  >
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { PREDICTION_RATINGS } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import { logger } from '@/utils/logger';
import { getPredictionRating, showPredictions } from '@/utils/vision';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PerformaceBadge',
  components: { Icon },
  props: {
    medium: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    xsmall: { type: Boolean, default: false },
    rating: { type: String, default: undefined },
    media: { type: Object, default: null },
    mediaV2: { type: Boolean, default: false },
    predictionModel: { type: Object, default: null },
  },
  data() {
    return {
      ...{ PREDICTION_RATINGS },
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['user_can']),
    classes() {
      return {
        medium: this.medium,
        small: this.small,
        xsmall: this.xsmall,
      };
    },
    fieldRating() {
      if (this.rating && this.media) {
        logger.warn('Received props for rating & media; media should be used instead of rating.');
      }

      if (this.media) {
        const options = {
          mediaV2: this.mediaV2,
        };
        if (this.predictionModel) {
          options.predictionModel = this.predictionModel;
        }
        return getPredictionRating(this.media?.predictions, options);
      }
      return this.rating;
    },
    showPredictions() {
      // label is dash based (e.g:'adore-collective'), name is underscore based (e.g: 'adore_collective'),
      // and permission always use label instead of name.
      return this.media
        ? showPredictions(this.media, this.$router)
        : this.user_can('vision', 'can_access_vision');
    },
    showBadge() {
      return this.showPredictions && this.fieldRating && this.fieldRating !== 'processing';
    },
    showLoader() {
      if (!this.media && !this.showBadge && this.showPredictions) {
        // Keep legacy behavior of showing loader with no predictions.
        return true;
      }
      if (!this.media) {
        return false;
      }
      return this.fieldRating === 'processing' && this.showPredictions;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.performance-badge {
  border-radius: 100%;
  background: var(--background-0);
  height: var(--space-20);
  width: var(--space-20);
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    top: auto;
    right: auto;
  }

  &.xsmall {
    width: var(--space-16);
    height: var(--space-16);
  }

  &.medium {
    width: var(--space-24);
    height: var(--space-24);
  }
}

.loading {
  background: var(--background-0);
  border-radius: 50%;
  z-index: 3;
  width: 20px;
  height: 20px;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: var(--action-500);
  }

  40% {
    stroke: #eeb10d;
  }

  66% {
    stroke: var(--action-500);
  }

  80%,
  90% {
    stroke: #eeb10d;
  }
}
</style>
