/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'approvals': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_104_13889)" _fill="#686a7a"><path pid="0" d="M3.367 19.167v-3.378h-.845a1.69 1.69 0 01-1.689-1.69V6.498c0-.933.757-1.69 1.69-1.69h7.6v1.69h-7.6v7.601h2.533v1.69l2.252-1.69h6.194V9.032h1.69v5.067a1.69 1.69 0 01-1.69 1.69h-5.63l-4.505 3.378zM14.347 6.847l-3.131-3.13 1.194-1.195 1.937 1.937L17.971.833l1.194 1.194-4.82 4.82z"/></g><defs><clipPath id="clip0_104_13889"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
