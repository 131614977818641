/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M18.221 11.262V8.267h-1.648a7.442 7.442 0 00-1.1-2.626l1.176-1.174-2.117-2.117-1.177 1.174a7.37 7.37 0 00-2.622-1.093V.78H7.738v1.65a7.419 7.419 0 00-2.623 1.094L3.943 2.353 1.825 4.47l1.173 1.172c-.52.785-.9 1.671-1.096 2.625H.252v2.995h1.65c.196.955.576 1.84 1.096 2.626l-1.173 1.174 2.119 2.116 1.17-1.174A7.424 7.424 0 007.74 17.1v1.65h2.994v-1.652a7.456 7.456 0 002.625-1.096l1.174 1.173 2.116-2.119-1.176-1.17a7.452 7.452 0 001.098-2.624h1.65zm-8.987 1.497a2.995 2.995 0 110-5.99 2.995 2.995 0 010 5.99z" fill-rule="nonzero" _fill="#adadad"/>'
  }
})
