import { colours } from '@/ux/colours';

export default {
  id: 'verticalhoverline',
  afterDatasetDraw(chart, args, options) {
    let hoverPointElement = document.getElementById('hover-point-element');
    if (!hoverPointElement) {
      hoverPointElement = document.createElement('div');
      hoverPointElement.id = 'hover-point-element';
      hoverPointElement.style.position = 'absolute';
      hoverPointElement.style.width = '20px';
      hoverPointElement.style.height = '20px';
      hoverPointElement.style.borderRadius = '50%';
      hoverPointElement.style.border = '2px solid rgba(74, 144, 226, 0.39)';
      document.body.appendChild(hoverPointElement);
    }

    const activeElements = chart.getActiveElements();
    if (activeElements?.length > 0) {
      const activePoint = activeElements[0];
      const { x, y } = activePoint.element;
      const hoverLineColor =
        chart.data.datasets[activePoint.datasetIndex]?.borderColor ||
        options.color ||
        colours.CHART.HOVER_LINE;

      const position = chart.canvas.getBoundingClientRect();
      const leftPx = position.left + x + window.scrollX - hoverPointElement.clientWidth / 2 - 2;
      const topPx = position.top + y + window.scrollY - hoverPointElement.clientHeight / 2 - 2;
      hoverPointElement.style.borderColor = hoverLineColor;
      hoverPointElement.style.opacity = 1;
      hoverPointElement.style.pointerEvents = 'none';
      hoverPointElement.style.left = `${leftPx}px`;
      hoverPointElement.style.top = `${topPx}px`;

      const pointY = y + 9;
      const bottomY = chart.scales.y.bottom;
      if (bottomY > pointY) {
        const { ctx } = chart;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, pointY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = hoverLineColor;
        ctx.stroke();
        ctx.restore();
      }
    } else {
      hoverPointElement.style.opacity = 0;
    }
  },
};
