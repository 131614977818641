<template>
  <div class="details-container">
    <img v-if="profileImageUrl" :src="profileImageUrl" class="profile-image" alt="Profile Image" />
    <div class="name">{{ name }}</div>
    <Icon
      v-if="verified"
      class="verified-icon"
      :color="checkColor"
      name="twitterVerified"
      width="14"
      height="14"
    />
    <div class="username">@{{ username }}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { SOCIAL_CHANNEL } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'TwitterMentionDetails',
  components: {
    Icon,
  },
  props: {
    profileImageUrl: { type: String, required: true },
    name: { type: String, required: true },
    username: { type: String, required: true },
    verified: { type: Boolean, default: false },
  },
  computed: {
    checkColor() {
      return SOCIAL_CHANNEL.SC_TWITTER;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--space-8) var(--space-16);
  background-color: var(--background-0);
  font-size: var(--x14);
  white-space: nowrap;

  .profile-image {
    width: var(--space-32);
    height: var(--space-32);
    border-radius: var(--space-16);
  }

  .name {
    font-weight: bold;
    color: var(--text-primary);
    margin-left: var(--space-8);
  }

  .verified-icon {
    margin-left: var(--space-8);
  }

  .username {
    margin-left: var(--space-8);
    flex: 1;
    color: var(--text-secondary);
    font-size: var(--x14);
    text-align: left;
  }
}
</style>
