/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bookmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 2H7C4.795 2 3 3.795 3 6v15a1 1 0 001.707.707L9 17.414l4.293 4.293A1 1 0 0015 21V11h5a1 1 0 001-1V6c0-2.205-1.795-4-4-4zm-4 4v12.586l-3.293-3.293a.997.997 0 00-1.414 0L5 18.586V6a2 2 0 012-2h6.537A3.967 3.967 0 0013 6zm6 3h-4V6a2 2 0 014 0v3z"/>'
  }
})
