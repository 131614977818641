<script setup>
import { computed } from 'vue';

import { APPROVAL_STATUS } from '@/app/scheduler/constants';
import { useCustomerJourneyStore } from '@/stores/customer-journey';

const props = defineProps({
  status: {
    type: String,
    default: null,
  },
  source: {
    type: String,
    default: 'editor',
  },
});
const customerJourneyStore = useCustomerJourneyStore();

const badgeStyle = computed(() => {
  if (props.status === APPROVAL_STATUS.PENDING) {
    return 'pending-badge';
  }
  if (props.status === APPROVAL_STATUS.APPROVED) {
    return 'approved-badge';
  }
  return props.source === 'editor' ? 'approvals-required-badge' : 'draft-badge';
});

const chipText = computed(() => {
  const draftStatusLabel =
    props.status?.includes('required') && props.source === 'editor' ? props.status : 'UNASSIGNED';
  if (props.status) {
    return [APPROVAL_STATUS.APPROVED, APPROVAL_STATUS.PENDING].includes(props.status)
      ? props.status
      : draftStatusLabel;
  }
  return null;
});

const showBadge = computed(() => {
  return !customerJourneyStore.hasPlanGrow;
});
</script>

<template>
  <span v-if="showBadge" :class="['approval-status-badge w-fit', badgeStyle]">{{ chipText }}</span>
</template>
<style lang="postcss" scoped>
.header {
  font-size: var(--x18);
}

.approval-status-badge {
  color: var(--white);
  text-transform: uppercase;
  font-size: var(--x10);
  font-weight: 500;
  border-radius: var(--space-16);
  padding: 0 var(--space-12);
}

.pending-badge {
  background-color: var(--alert-400);
}

.approved-badge {
  background-color: var(--success-500);
}

.approvals-required-badge {
  background-color: #e5e5e5;
  color: var(--text-primary);
}

.draft-badge {
  background-color: #a0a3bd;
}
</style>
