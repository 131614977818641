/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'creditCard': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M-2-4h24v24H-2z"/><g _fill="#adadad" fill-rule="nonzero"><path pid="1" d="M18 0H2C.896 0 0 .898 0 2v12c0 1.103.896 2 2 2h16c1.104 0 2-.897 2-2V2c0-1.102-.896-2-2-2zm0 2l-.001 3H2V2h16zM2 14V7h15.999l-.002 7H2z"/><path pid="2" d="M13 8h4v2h-4zM3 8h7v2H3zM3 11h5v2H3z"/></g></g>'
  }
})
