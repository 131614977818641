/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magic-wand-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.799 12.192L18.59 8.984l1.989-4.138a1 1 0 00-1.335-1.334l-4.136 1.989L11.9 2.292a1 1 0 00-1.695.867l.705 4.342-4.347 2.193a1 1 0 00.295 1.88l3.666.58-9.23 9.231L2.707 22.8l9.23-9.232.581 3.666a1.002 1.002 0 001.881.294l2.191-4.347 4.341.705c.426.069.853-.146 1.05-.531a.998.998 0 00-.182-1.163zm-5.611-1.102a1 1 0 00-1.053.537L14.01 13.86l-.403-2.542a.999.999 0 00-.832-.832l-2.542-.403 2.233-1.126a.998.998 0 00.537-1.052l-.326-2.006 1.52 1.519a1 1 0 001.141.194l2.205-1.061-1.063 2.206c-.184.383-.106.84.194 1.141l1.519 1.518-2.005-.326z" _fill="#767676"/>'
  }
})
