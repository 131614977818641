import { ref } from 'vue';
import { UPDATED_CONFIG_CHANNELS } from '@/models/dashboards/metrics.constants';
import { useMetricDetails } from '@/app/dashboards/composables/useMetricDetails';

export function getMetricDetails(metric, channels, metricTypeReportKey) {
  const updatedConfigChannel =
    channels.length === 1 && UPDATED_CONFIG_CHANNELS.includes(channels[0]);
  if (updatedConfigChannel) {
    const {
      allowContentTags,
      displayName,
      formatMetricValue,
      graphBeginAtZero,
      graphPrecision,
      graphStats,
      isNegativeMetric,
      lockedMediaBreakdown,
      mediaTypeLabel,
      parentMetric,
      permissions,
      sourceDataName,
      supportedGraphScales,
      supportsMediaBreakdown,
      tooltip,
    } = useMetricDetails({
      metric: ref(metric),
      channels: ref(channels),
      metricReportTypeKey: ref(metricTypeReportKey),
    });
    return {
      allowContentTags: allowContentTags.value,
      displayName: displayName.value,
      formatMetricValue,
      graphBeginAtZero: graphBeginAtZero.value,
      graphPrecision: graphPrecision.value,
      graphStats: graphStats.value,
      isNegativeMetric: isNegativeMetric.value,
      lockedMediaBreakdown: lockedMediaBreakdown.value,
      mediaTypeLabel: mediaTypeLabel.value,
      parentMetric: parentMetric.value,
      permissions: permissions.value,
      sourceDataName: sourceDataName.value,
      supportedGraphScales: supportedGraphScales.value,
      supportsMediaBreakdown: supportsMediaBreakdown.value,
      tooltip: tooltip.value,
    };
  }
  return {};
}

export function filterMetricsWithParentMetric(channels, allMetricDetails, metricTypeReportKey) {
  return (metric) => {
    return channels.every((channel) => {
      return !allMetricDetails?.[channel]?.[metricTypeReportKey]?.[metric]?.parent_metric;
    });
  };
}
