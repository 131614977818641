/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tag': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<g transform="translate(0 .748)" _fill="#adadad" fill-rule="nonzero"><path pid="0" d="M12 20a.997.997 0 01-.707-.293l-11-11A.996.996 0 010 8V1a1 1 0 011-1h7c.266 0 .52.105.707.293l11 11a.999.999 0 010 1.414l-7 7A.997.997 0 0112 20zM2 7.586l10 10L17.586 12l-10-10H2v5.586z"/><circle pid="1" cx="5" cy="5" r="2"/></g>'
  }
})
