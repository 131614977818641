import { computed } from 'vue';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { useCommunityDmsStore } from '@/stores/community-dms';
import { useCommunityCommentsStore } from '@/stores/community-comments';
import {
  THRESHOLD_MINIMUMS,
  INTENT_TYPES,
  BACKUP_PROMPT_MESSAGE,
} from '@/components/VisionAi/constants';
import { useIdentityStore } from '@/stores/identity';
import {
  COMMUNITY_GENERIC_TYPES,
  COMMUNITY_VISION_PROMPTS,
  COMMUNITY_ROUTE_NAMES,
  COMMUNITY_LOADED_SOURCE,
} from '@/app/community/constants';
import merge from 'lodash/merge';
import { useRoute } from 'vue-router';

export function useCommunityVisionAi() {
  const communityInteractionStore = useCommunityInteractionStore();
  const communityCommentsStore = useCommunityCommentsStore();
  const communityDmsStore = useCommunityDmsStore();
  const identityStore = useIdentityStore();
  const route = useRoute();

  const hasValidCommentsThreshold = computed(() => {
    return (
      communityCommentsStore.commentDetail?.commentCount >= THRESHOLD_MINIMUMS.COMMUNITY.COMMENTS
    );
  });

  const hasValidDmsThreshold = computed(() => {
    return (
      communityDmsStore.messages[communityInteractionStore.selectedCommunityInteractionId]
        ?.length >= THRESHOLD_MINIMUMS.COMMUNITY.DMS
    );
  });

  const selectedInteractionType = computed(() => {
    return communityInteractionStore?.selectedInteractionGenericType;
  });

  const onCommunityRoute = computed(() => {
    return route?.name?.startsWith('community');
  });

  const isCommunityEmptyState = computed(() => {
    return Boolean(!selectedInteractionType.value && onCommunityRoute.value);
  });

  const communityDefaultPrompt = computed(() => {
    switch (selectedInteractionType.value) {
      case COMMUNITY_GENERIC_TYPES.COMMENT:
        return COMMUNITY_VISION_PROMPTS[COMMUNITY_GENERIC_TYPES.COMMENT];
      case COMMUNITY_GENERIC_TYPES.CONVERSATION:
        return COMMUNITY_VISION_PROMPTS[COMMUNITY_GENERIC_TYPES.CONVERSATION];
      default:
        return BACKUP_PROMPT_MESSAGE;
    }
  });

  const communityIntentType = computed(() => {
    switch (selectedInteractionType.value) {
      case COMMUNITY_GENERIC_TYPES.COMMENT:
        return INTENT_TYPES.COMMENTS;
      case COMMUNITY_GENERIC_TYPES.CONVERSATION:
        return INTENT_TYPES.DMS;
      default:
        return undefined;
    }
  });

  const communityIntentQuery = computed(() => {
    const interaction = communityInteractionStore.selectedCommunityInteraction;
    const brand = identityStore.currentBrand;

    let query = {
      brandId: brand.id,
      searchParams: {
        limit: 100,
        offset: 0,
      },
      sourceLinkParams: {
        id: interaction.id,
        brandLabel: brand.label,
      },
    };

    if (selectedInteractionType.value === COMMUNITY_GENERIC_TYPES.COMMENT) {
      const platform = interaction.type.split('_')[0];
      query = merge(query, {
        searchParams: {
          mediaId: interaction?.mediaId,
          brandId: brand.id,
        },
        sourceLinkParams: {
          platform,
        },
      });
    }

    if (selectedInteractionType.value === COMMUNITY_GENERIC_TYPES.CONVERSATION) {
      query.searchParams.communityInteractionId = interaction.id;
    }

    return query;
  });

  function getCommentUrlFromIntentQuery(intentQuery) {
    const { id, brandLabel } = intentQuery?.sourceLinkParams || {};
    return {
      name: `${COMMUNITY_ROUTE_NAMES.ALL_MESSAGES}`,
      params: {
        brandLabel,
      },
      query: {
        id,
        source: COMMUNITY_LOADED_SOURCE.VISION_AI,
      },
    };
  }

  function getDMUrlFromIntentQuery(intentQuery) {
    const { id, brandLabel } = intentQuery?.sourceLinkParams || {};
    return {
      name: `${COMMUNITY_ROUTE_NAMES.ALL_MESSAGES}`,
      params: {
        brandLabel,
      },
      query: {
        id,
      },
    };
  }

  return {
    hasValidCommentsThreshold,
    hasValidDmsThreshold,
    communityIntentType,
    communityIntentQuery,
    communityDefaultPrompt,
    onCommunityRoute,
    isCommunityEmptyState,
    selectedInteractionType,
    getCommentUrlFromIntentQuery,
    getDMUrlFromIntentQuery,
  };
}
