const TYPES = {
  string: String,
  number: Number,
};

export function enumProp(_enum, defaultValue) {
  const values = Object.values(_enum);
  if (values.length === 0) {
    throw new Error('Enum must have at least one value.');
  }
  const type = TYPES[typeof values[0]];
  if (!type) {
    throw new Error(`Enum values types must be one of: ${Object.keys(TYPES)}.`);
  }
  const required = defaultValue === undefined;
  const validator = (value) => (defaultValue === null && value === null) || values.includes(value);
  const options = { type, required, validator };
  if (!required) {
    if (!validator(defaultValue)) {
      throw new Error(`Default enum value "${defaultValue}" is not one of: ${values}.`);
    }
    options.default = defaultValue;
  }
  return options;
}
