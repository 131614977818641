import {
  SOCIAL_LISTENING_TOPICS_OPTIONS_DATA,
  SUBSCRIPTION_PRICE_MAP,
} from '@/app/settings/constants';
import { useTrackingStore } from '@/stores/tracking';

/**
 * @typedef {'INSTAGRAM'|'TWITTER'|'YOUTUBE'|'FACEBOOK'} CompetitorPlatform
 * @typedef {'Brand'|'Competitor'} AccountType
 * @typedef {'TikTok LikeShop','Instagram LikeShop','Instagram Stories','Facebook','Pinterest','X','Shoppable Galleries','Product Page Galleries'} UtmChannel
 */

export const googleAnalyticsAccountAdded = (account) => {
  const trackingStore = useTrackingStore();
  trackingStore.track('Google Analytics Account Added', {
    accountId: account.accountId,
    accountName: account.accountName,
    brandId: account.brandId,
    currencyCode: account.currencyCode,
    propertyId: account.propertyId,
    propertyName: account.propertyName,
    timezone: account.timezone,
    viewId: account.viewId,
    viewName: account.viewName,
    type: 'ua',
  });
};

/**
 * @param {Object} args
 * @param {'Platform'|'Sort By'|'Tag'} args.filterUsed
 * @param {AccountType} args.accountType
 * @param {CompetitorPlatform[]} [args.platforms]
 * @param {string} [args.sortBy]
 * @param {string[]} [args.tagNames]
 * @param {'AND'|'OR'} [args.tagFilterType]
 */
export function trackSettingsAccountFilter({
  filterUsed,
  accountType,
  platform,
  sortBy,
  tags,
  tagFilterType,
}) {
  const properties = {
    filterUsed,
    accountType,
  };
  switch (filterUsed) {
    case 'Sort By':
      properties.sortBy = sortBy;
      break;
    case 'Platform':
      properties.platform = platform;
      break;
    case 'Tag':
      properties.tags = tags?.map((tag) => tag.name) ?? [];
      properties.tagFilterType = tagFilterType;
      break;
    default:
      throw new RangeError(
        `Invalid filter for Settings Account Filter mixpanel event: ${filterUsed}`,
      );
  }
  const trackingStore = useTrackingStore();
  trackingStore.track('Settings Account Filter', properties);
}

/**
 * @param {Object} args
 * @param {Object} args.tag
 * @param {int} args.tag.id
 * @param {string} args.tag.name
 * @param {AccountType} args.accountType
 */
export function trackSettingsAccountTagCreated({ tag, accountType }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Settings Account Tag Created', {
    tagId: tag.id,
    tagName: tag.name,
    accountType,
  });
}

/**
 * @param {Object} args
 * @param {Object} args.tag
 * @param {int} args.tag.id
 * @param {string} args.tag.name
 * @param {AccountType} args.accountType
 */
export function trackSettingsAccountTagDeleted({ tag, accountType }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Settings Account Tag Deleted', {
    tagId: tag.id,
    tagName: tag.name,
    accountType,
  });
}

/**
 * @param {Object} args
 * @param {'Associate'|'Unassociate'} args.action
 * @param {AccountType} args.accountType
 * @param {Object[]} args.tags
 * @param {Object[]} args.accounts
 */
export function trackSettingsAccountTagUpdated({ action, accountType, tags, accounts }) {
  const properties = {
    action,
    accountType,
    tagIds: tags.map((tag) => tag.id),
    tagNames: tags.map((tag) => tag.name),
  };
  if (accountType === 'Brand') {
    properties.brandIds = accounts.map((account) => account.id);
    properties.brandNames = accounts.map((account) => account.name);
  }
  if (accountType === 'Competitor') {
    properties.competitorIds = accounts.map((account) => account.id);
    properties.competitorNames = accounts.map((account) => account.name);
    properties.competitorPlatforms = Object.fromEntries(
      accounts.map((account) => [account.id, account.targetSource]),
    );
  }
  const trackingStore = useTrackingStore();
  trackingStore.track('Settings Account Tag Updated', properties);
}

/**
 * @param {Object} properties
 * @param {'Created'|'Edit'|'Delete'} properties.action
 * @param {UtmChannel} properties.channel
 * @param {'Update Value'|'Toggle Customize Per Post'} [properties.updatedAction]
 * @param {string} properties.key
 * @param {string} properties.value
 * @param {bool} properties.customizePerPost
 */
export function trackUtmManagementKeyUpdate(properties) {
  const trackingStore = useTrackingStore();
  trackingStore.track('UTM Management - Key Update', properties);
}

/**
 * @param {Object} properties
 * @param {UtmChannel} properties.channel
 * @param {Object.<string, {value: string, edit_per_post: bool}>} properties.settings
 * @param {Object.<string, {value: string, edit_per_post: bool}>} properties.previousSettings
 */
export function trackUtmManagementSettingsUpdated({ channel, settings, previousSettings }) {
  const trackingStore = useTrackingStore();
  const properties = {
    channel,
    keys: Object.keys(settings),
    values: Object.values(settings).map(({ value }) => value),
    keyValues: Object.fromEntries(Object.entries(settings).map(([key, { value }]) => [key, value])),
    keyCustomizePerPost: Object.fromEntries(
      Object.entries(settings).map(([key, { edit_per_post: customizePerPost }]) => [
        key,
        customizePerPost,
      ]),
    ),
    previousKeys: Object.keys(previousSettings),
    previousValues: Object.values(previousSettings).map(({ value }) => value),
    previousKeyValues: Object.fromEntries(
      Object.entries(previousSettings).map(([key, { value }]) => [key, value]),
    ),
    previousKeyCustomizePerPost: Object.fromEntries(
      Object.entries(previousSettings).map(([key, { edit_per_post: customizePerPost }]) => [
        key,
        customizePerPost,
      ]),
    ),
  };
  trackingStore.track('UTM Management - UTM Update', properties);
}

/**
 * @param {Object} properties
 * @param {UtmChannel} properties.channel
 * @param {string} properties.trackingKey
 * @param {'add'|'delete'} properties.action
 */
export function trackUtmManagementPresetValueUpdated({ channel, trackingKey, action }) {
  const trackingStore = useTrackingStore();
  const properties = {
    channel,
    trackingKey,
    action,
  };
  trackingStore.track('UTM Management - Preset Value Updated', properties);
}

/**
 * @param {Object} properties
 * @param {UtmChannel} properties.channel
 * @param {string} properties.trackingKey
 * @param {Array.<{id: number, value: string}>} properties.presetValues
 * @param {string[]} properties.previousPresetValues
 */
export function trackUtmManagementPresetList({
  channel,
  trackingKey,
  presetValues,
  previousPresetValues,
}) {
  const trackingStore = useTrackingStore();
  const properties = {
    channel,
    trackingKey,
    presetValues,
    addedPresetValues: presetValues.filter((value) => !previousPresetValues.includes(value)),
    deletedPresetValues: previousPresetValues.filter((value) => !presetValues.includes(value)),
    previousPresetValues,
  };
  trackingStore.track('UTM Management - Preset List', properties);
}

/**
 *
 * @param buttonName {str}
 * @param url {str}
 * @param plan {'Grow'|'Engage'|'Advance'|'Enterprise'}
 * @param addOn
 * @param payPeriod
 * @param popUp
 * @param socialListeningTopics
 * @param openedPlatform
 * @param pricingToggle
 * @param userRole
 */
export function trackBillingPageButtonClick({
  buttonName,
  url,
  plan,
  addOn,
  payPeriod,
  popUp,
  socialListeningTopics,
  openedPlatform,
  pricingToggle,
  userRole,
}) {
  const properties = {
    buttonName,
    url,
    plan,
    addOn,
    payPeriod,
    popUp,
    socialListeningTopics,
    openedPlatform,
    pricingToggle,
    userRole,
  };
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing and Payment - Button Clicked', properties);
}

/**
 * @param {Object} args
 * @param {string} args.oldNumTopics
 * @param {string} args.newNumTopics
 */
export function trackSocialListeningTopicValueChanged({ oldNumTopics, newNumTopics }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing And Payment - Social Listening Upgrade Toggle', {
    previousTopics: SOCIAL_LISTENING_TOPICS_OPTIONS_DATA.filter(
      (option) => option.value === oldNumTopics,
    )[0].text,
    topics: SOCIAL_LISTENING_TOPICS_OPTIONS_DATA.filter(
      (option) => option.value === newNumTopics,
    )[0].text,
  });
}

/**
 * @param {Object} args
 * @param {Object} args.plan
 * @param {string} args.billingPeriod
 * @param {Boolean} args.updated
 */
export function trackSubscribeNowButtonAction({ lookupKey, buttonName }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing And Plan - Subscribe Clicked', {
    buttonName,
    package: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.name,
    value: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.price,
    lookupKey,
    urlLink: 'www.stripe.com',
    billingSchedule: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.billingSchedule,
  });
}

/**
 * @param {string} lookupKey Stripe price lookup key
 * @param {string} source
 * @param {string} purchaseType
 */
export function trackPurchaseAction(lookupKey, source, purchaseType, purchaseAction) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing And Plan - Purchase', {
    source,
    purchaseType,
    value: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.price,
    lookupKey,
    billingSchedule: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.billingSchedule,
    purchaseAction,
  });
}

/**
 * @param {string} args.lookupKey
 * @param {Boolean} args.success
 */
export function trackSubscriptionUpdated({ lookupKey, success }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing and Plan - Subscribe Update', {
    package: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.name,
    value: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.price,
    status: success ? 'Subscribed' : 'Cancelled checkout',
    billingSchedule: SUBSCRIPTION_PRICE_MAP?.[lookupKey]?.billingSchedule,
    addOn: lookupKey?.includes('social_listening') ? 'Social Listening' : null,
    lookupKey,
  });
}

export function trackPurchaseSuccessCta({ buttonName, lookupKey, action }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Billing and Payment - Purchase Success CTA', {
    buttonName,
    lookupKey,
    action,
  });
}

export function trackUpgradeOptionsClick({ buttonText, link, userRole, plan, addOn }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Upgrade Options - Button Clicked', {
    buttonText,
    userRole,
    urlLink: link,
    plan,
    addOn,
  });
}

export function trackTrialOverviewModal({ plan, addOn }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Trial Overview Modal', {
    plan,
    addOn,
  });
}
