<script setup>
import { computed, defineAsyncComponent, toRefs, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import constants from '@/app/dashboards/constants';
import {
  GRAPH_REPORTS,
  METRIC_TABLE_REPORTS,
  REPORTS,
  isWidgetReport,
} from '@/app/dashboards/utils/reports.enum';
import { useServerErrors } from '@/composables/useServerErrors';
import Icon from '@/components/foundation/Icon.vue';
import { logger } from '@/utils/logger';

import { dashboardReportLoading } from '@/app/dashboards/utils/dashboard-general.util';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { useDashboardsStore } from '@/stores/dashboards';
import { useSearchStore } from '@/stores/search';
import { useReport } from '../composables/useReport';

const GroupedMetricReportPanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/GroupedMetricReportPanel.vue'),
);
const MetricReportTablePanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/Report/Panels/MetricReportTablePanel.vue'),
);
const ContentReportPanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/ContentReportPanel.vue'),
);
const CompetitiveContentReportPanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/CompetitiveContentReportPanel.vue'),
);
const GraphReportPanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/GraphReportPanel.vue'),
);
const HeaderReportPanel = defineAsyncComponent(
  () => import('@/app/dashboards/components/HeaderReportPanel.vue'),
);
const VisionAiReportPanelPlaceHolder = defineAsyncComponent(
  () => import('@/app/dashboards/components/Report/Panels/VisionAiReportPanelPlaceHolder.vue'),
);

const dashboardReportsStore = useDashboardReportsStore();
const dashboardsStore = useDashboardsStore();
const searchStore = useSearchStore();

const route = useRoute();

const { onServerError } = useServerErrors();

const props = defineProps({
  reportOverride: { type: Object, default: null },
  reportId: { type: Number, default: null },
  type: { type: String, required: true },
});

const { reportOverride, reportId, type } = toRefs(props);

const serverErrorMessage = ref(null);

const comparisonDate = computed(() => {
  if (!reportId.value) return null;
  return dashboardReportsStore.contextDateRange;
});

const customClasses = computed(() => ({
  simple: isWidgetReport(type.value) && !dashboardsStore.dashboardCustomizeMode,
}));

const reportIndex = computed(() => (reportOverride.value ? 'highlightedReport' : reportId.value));

const error = computed(() => dashboardsStore.reportError[reportIndex.value]);

const isGraphReport = computed(() => GRAPH_REPORTS.includes(type.value));

const isMetricTableReport = computed(() => METRIC_TABLE_REPORTS.includes(type.value));

const report = computed(() => {
  if (reportOverride.value) return reportOverride.value;
  return dashboardsStore.reports[reportId.value];
});

const isReportRoute = computed(() => route?.meta?.report);

const loading = computed(() => {
  if (!reportId.value) return false;

  return dashboardReportLoading(reportId.value, type.value, dashboardsStore, searchStore);
});

const reportLayoutHeight = computed(
  () =>
    dashboardsStore.currentDashboardLayout.find((layout) => layout.report_id === reportId.value)
      ?.h ?? 0,
);

const isMoreThanFourDataPoints = computed(() => {
  const { metricReportRows } = useReport({ report, loading });
  return metricReportRows.value.length > 4;
});

const reportingDate = computed(() => {
  if (!reportId.value) return null;

  return dashboardReportsStore.reportDateRange;
});

const isReportExpanded = computed(
  () => (reportOverride.value && reportOverride.value.isExpanded) || null,
);

function reportComponent(reportType) {
  switch (REPORTS[reportType]?.components?.report?.name) {
    case 'GroupedMetricReportPanel':
      return GroupedMetricReportPanel;
    case 'MetricReportTablePanel':
      return MetricReportTablePanel;
    case 'ContentReportPanel':
      return ContentReportPanel;
    case 'CompetitiveContentReportPanel':
      return CompetitiveContentReportPanel;
    case 'GraphReportPanel':
      return GraphReportPanel;
    case 'HeaderReportPanel':
      return HeaderReportPanel;
    case 'VisionAiReportPanelPlaceholder':
      return VisionAiReportPanelPlaceHolder;
    default:
      return null;
  }
}

function getHeight() {
  const isGraphOrMetricTableReport = Boolean(isGraphReport.value || isMetricTableReport.value);
  if (!isReportRoute.value || !isGraphOrMetricTableReport) return '100%';

  return isMoreThanFourDataPoints.value
    ? 'max-content'
    : `${constants.DASHBOARD_ROW_HEIGHT_IN_PIXELS * reportLayoutHeight.value + 30}px`;
}
const customStyle = computed(() => ({ height: getHeight() }));

watch(
  reportId,
  () => {
    if (!reportId.value) return;
    dashboardsStore.getReportData({ reportId: reportId.value, refresh: true });
  },
  { immediate: true },
);
watch(
  () => dashboardReportsStore.graphScale,
  () => {
    if (!isGraphReport.value) return;
    dashboardsStore.getReportData({ reportId: reportId.value, refresh: true });
  },
);

watch(
  error,
  () => {
    if (!error.value) {
      serverErrorMessage.value = null;
      return;
    }
    serverErrorMessage.value = onServerError(error.value, { notification: false });
    logger.error(serverErrorMessage.value, {}, error.value);
  },
  { deep: true },
);
</script>

<template>
  <div :class="['dashboard-report-item-content', customClasses]" :style="customStyle">
    <template v-if="serverErrorMessage">
      <div class="error-panel">
        <Icon v-tooltip="serverErrorMessage" xlarge name="alertCircle" class="error-icon" />
        <div class="error-message">Unable to load report</div>
      </div>
    </template>
    <component
      :is="reportComponent(type)"
      v-else
      :loading="loading"
      :report="report"
      :type="type"
      :reporting-date="reportingDate"
      :comparison-date="comparisonDate"
      :is-report-expanded="isReportExpanded"
      disabled-static-date-range
    />
  </div>
</template>

<style lang="postcss" scoped>
.dashboard-report-item-content {
  height: 100%;
  border-radius: 6px;
  background-color: var(--background-0);
  box-shadow: var(--shadow-1);
  transition: none;
  -webkit-print-color-adjust: exact;

  &.simple {
    background: none;
    box-shadow: none;
  }

  .error-panel {
    padding: var(--space-16);

    .error-message {
      margin-top: var(--x24);
      color: var(--text-secondary);
    }
  }

  .single-metric-report {
    height: 210px;
  }
}

.line-height-style {
  line-height: 0.5rem;
}

@media print {
  .dashboard-report-item-content {
    border: 1px solid var(--border);
    box-shadow: none;
  }
}
</style>
