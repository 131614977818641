import { onMounted, onUnmounted } from 'vue';
import { useSocketStore } from '@/stores/socket';

export function useRegisterSockets(event, callBack, context = null) {
  const socketStore = useSocketStore();

  onMounted(() => {
    socketStore.addListener(event, callBack, context);
  });

  onUnmounted(() => {
    socketStore.removeListener(event, callBack, context);
  });
}
