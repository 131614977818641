import jsonpAdapter from 'axios-jsonp';
import dayjs from 'dayjs';
import { externalAxios } from '@/apis/external-apis';

export async function getEmbeddableTweet(url) {
  const res = await externalAxios.get(url, {
    adapter: jsonpAdapter,
    params: {
      align: 'center',
      maxwidth: 1000,
    },
  });
  return res.data.html;
}

export function initializeTwitterWidget(widgetLoadedCallback, tweetRenderedCallback) {
  const widgetScriptId = 'twitter-wjs';
  if (document.getElementById(widgetScriptId) && window.twttr) {
    if (tweetRenderedCallback) {
      window.twttr.events.bind('rendered', tweetRenderedCallback);
    }
    if (widgetLoadedCallback) {
      widgetLoadedCallback();
    }
    return document.getElementById(widgetScriptId);
  }

  const widgetScript = document.createElement('script');
  widgetScript.id = widgetScriptId;
  widgetScript.addEventListener('load', () => {
    if (tweetRenderedCallback) {
      window.twttr.events.bind('rendered', tweetRenderedCallback);
    }
    if (widgetLoadedCallback) {
      widgetLoadedCallback();
    }
  });
  widgetScript.src = 'https://platform.twitter.com/widgets.js';
  document.head.appendChild(widgetScript);
  return widgetScript;
}

export function renderTweets(parentHtmlElem) {
  if (window.twttr && window.twttr.widgets) {
    if (parentHtmlElem) {
      window.twttr.widgets.load(parentHtmlElem);
    } else {
      window.twttr.widgets.load();
    }
    return true;
  }
  return false;
}

export function formatThreeSixtyMonthlyData(
  { topTwitterPosts, twitterAccount, twitterMetrics },
  numberOfMonths,
) {
  if (!topTwitterPosts || !twitterAccount || !twitterMetrics[0]) {
    return null;
  }
  const mostRecentMonth = dayjs(twitterMetrics[0].timeseries_metrics[0].timestamp).startOf('month');
  const monthNames = [];
  for (let i = 0; i < numberOfMonths; i += 1) {
    if (!twitterMetrics[i]) {
      return null;
    }
    monthNames.push(mostRecentMonth.clone().subtract(i, 'months').format('MMMM'));
  }
  const netNewFollowers = monthNames.map((monthName, index) => ({
    month: monthName,
    value: twitterMetrics[index].summary_metrics.new_followers,
  }));
  const numberOfPosts = monthNames.map((monthName, index) => ({
    month: monthName,
    value: twitterMetrics[index].summary_metrics.tweets_published,
  }));
  const totalEngagement = monthNames.map((monthName, index) => ({
    month: monthName,
    value: twitterMetrics[index].summary_metrics.engagements,
  }));
  const totalFollowers = monthNames.map((monthName, index) => ({
    month: monthName,
    value:
      twitterMetrics[index].timeseries_metrics[twitterMetrics[index].timeseries_metrics.length - 1]
        .metrics.total_followers,
  }));

  const getAverageValue = (items) =>
    items.length > 0 ? items.reduce((total, item) => total + item.value, 0) / items.length : 0;

  return {
    avatar: twitterAccount.twitter_avatar,
    channel: 'TWITTER',
    channel_data: {},
    chart_pages: [
      {
        charts: [
          { data: 'overview/total_followers', label: 'Total Followers - Past 6 Months' },
          { data: 'overview/net_new_followers', label: 'Net New Followers - Past 6 Months' },
        ],
        label: 'Followers',
      },
      {
        charts: [
          { data: 'overview/number_of_posts', label: 'Total Posts - Past 6 Months' },
          { data: 'overview/total_engagement', label: 'Total Engagements - Past 6 Months' },
        ],
        label: 'Tweet Performance',
      },
    ],
    handle: twitterAccount.handle,
    month: mostRecentMonth.month() + 1,
    month_name: mostRecentMonth.format('MMMM'),
    top_posts: [
      {
        label: 'Top Posts - Engagements',
        posts: topTwitterPosts.map((post) => {
          let imageUrl = '';
          if (post.type === 'IMAGE') {
            imageUrl = post.image?.sizes?.original?.url;
          }
          if (post.type === 'VIDEO') {
            imageUrl = post.video?.thumbnails?.medium_square?.url;
          }
          return {
            caption: post?.twitter?.url,
            type: post.type.toLowerCase(),
            stat: {
              label: 'Engagements',
              value: post?.twitter?.engagements,
            },
            image_url: imageUrl,
            date: dayjs(post.source_created_at).format('MMM DD, YYYY'),
          };
        }),
      },
    ],
    overview: {
      net_new_followers: {
        avg: getAverageValue(netNewFollowers),
        data: netNewFollowers,
        icon: 'followers',
        label: 'Net New Followers',
      },
      number_of_posts: {
        avg: getAverageValue(numberOfPosts),
        data: numberOfPosts,
        icon: 'photo',
        label: 'Number of Posts',
      },
      total_engagement: {
        avg: getAverageValue(totalEngagement),
        data: totalEngagement,
        icon: 'stats',
        label: 'Total Engagements',
      },
      total_followers: {
        avg: getAverageValue(totalFollowers),
        data: totalFollowers,
        icon: 'followers',
        label: 'Total Followers',
      },
    },
  };
}
