export const YOUTUBE_OVERVIEW_SINGLE_BRAND = [
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 0, meta: { name: 'All Published Posts' } },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['SUBSCRIBERS_TOTAL'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['SUBSCRIBERS_NET_NEW'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['AVG_VIEW_DURATION'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['ENGAGEMENTS'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['ESTIMATED_SECONDS_WATCHED'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 1,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['VIEWS'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 3,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['SUBSCRIBERS_NET_NEW'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 3,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['AVG_VIEW_DURATION'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 7,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['VIEWS'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 7,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['ENGAGEMENTS'] },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 11,
    meta: { name: 'Posts Published During Reporting Period' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 12,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_VIDEO_VIEWS'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 12,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_AVG_VIEW_DURATION'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 12,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_AVG_VIEW_PERCENTAGE'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ESTIMATED_SECONDS_WATCHED'],
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 12,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 12,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_LIKES'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 14,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_DISLIKES'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 14,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_COMMENTS'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 14,
    meta: { brand_ids: ['brand_ids'], channel: 'YOUTUBE', metrics: ['TOTAL_SHARES'] },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 14,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEOS_ADDED_TO_PLAYLIST'],
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 16, meta: { name: 'Video Performance' } },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEO_VIEWS'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEO_VIEWS'],
      sort_order: 'ASC',
    },
  },
];
