<template>
  <div class="validation-banner" :style="{ backgroundColor }">
    <Icon v-if="customIcon" :name="customIcon" :color="iconColor" class="custom-icon" medium />
    <div class="validation-banner-contents">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ValidationBanner',
  components: { Icon },
  props: {
    level: {
      default: 'error',
      validator(value) {
        return ['error', 'warning'].includes(value);
      },
    },
    customIcon: { type: String, default: null },
  },
  computed: {
    backgroundColor() {
      return {
        error: colours.ERROR.ERROR_100,
        warning: colours.ALERT.ALERT_100,
      }[this.level];
    },
    iconColor() {
      return {
        error: colours.ERROR.ERROR_500,
        warning: colours.ALERT.ALERT_500,
      }[this.level];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.validation-banner {
  display: flex;
  padding: var(--space-12) var(--space-16);
  border-radius: var(--round-corner-small);

  .validation-banner-contents {
    flex: 1;
    display: flex;
    font-size: var(--x14);
  }

  .custom-icon {
    margin: var(--space-4) var(--space-12) var(--space-4) var(--space-4);
  }
}
</style>
