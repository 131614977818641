import { xss, xssStrip, xssEscapeHtml } from '@/utils/xss';

function sanitizeHtml(el, binding) {
  if (binding.value !== binding.oldValue) {
    if (Array.isArray(binding.value)) {
      el.innerHTML = xss(binding.value[1], binding.value[0]);
    } else if (binding.modifiers.strip) {
      el.innerHTML = xssStrip(binding.value);
    } else if (binding.modifiers.escape) {
      el.innerHTML = xssEscapeHtml(binding.value);
    } else {
      el.innerHTML = xss(binding.value);
    }
  }
}

export default sanitizeHtml;
