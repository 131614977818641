/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'music-note': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<defs><path pid="0" d="M16.634.165a1.044 1.044 0 00-.936-.096L5.654 3.902a.962.962 0 00-.632.89v6.087a3.607 3.607 0 00-1.507-.337C1.577 10.542 0 12.047 0 13.896c0 1.85 1.577 3.354 3.515 3.354 1.939 0 3.516-1.504 3.516-3.354V5.442l8.035-3.067v5.63a3.607 3.607 0 00-1.507-.338c-1.938 0-3.515 1.505-3.515 3.354 0 1.85 1.577 3.354 3.515 3.354 1.939 0 3.516-1.504 3.516-3.354V.96a.948.948 0 00-.441-.794zM3.515 15.334c-.83 0-1.506-.645-1.506-1.438 0-.792.676-1.437 1.506-1.437.831 0 1.507.645 1.507 1.437 0 .793-.676 1.438-1.507 1.438zm10.044-2.875c-.83 0-1.506-.645-1.506-1.438 0-.792.676-1.437 1.506-1.437.831 0 1.507.645 1.507 1.437 0 .793-.676 1.438-1.507 1.438z" id="svgicon_music-note_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_music-note_a" transform="translate(.013 .25)"/>'
  }
})
