/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fileLandscapeImage': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#adadad"><path pid="0" d="M17.414 4H4c-1.103 0-2 .898-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8.586L17.414 4zM4 18V6h12v4h4l.001 8H4z"/><path pid="1" d="M10.006 14.475l-1.527-2.452L6 16h12l-4.472-7.173z"/></g>'
  }
})
