export const INSTAGRAM_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: { brand_ids: ['{brandId0}'], channel: 'INSTAGRAM', metric: 'AVG_REACH' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENTERTAINMENT_SCORE_REEL',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: { brand_ids: ['{brandId0}'], channel: 'INSTAGRAM', metric: 'TOTAL_LIKES' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: { brand_ids: ['{brandId0}'], channel: 'INSTAGRAM', metric: 'SAVES' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'LIKESHOP_CLICKS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM'],
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 12,
    meta: { name: 'Post Performance' },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_UGC',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_UGC',
      metric: 'UGC_TOTAL_ENGAGEMENTS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 29,
    meta: { name: 'Story Performance' },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 0,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 1,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 2,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 3,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 4,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_EXIT_RATE',
    },
  },
  {
    h: 2,
    type: 'SINGLE_METRIC',
    w: 1,
    x: 5,
    y: 30,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'SWIPE_UPS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 32,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 32,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 36,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 36,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'SWIPE_UPS',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED_STORIES',
    w: 6,
    x: 0,
    y: 40,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED_STORIES',
    w: 6,
    x: 0,
    y: 44,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
      sort_order: 'ASC',
    },
  },
];
