/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'thread': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<g _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M1 9.999h11.999v1.998h-12zM1 2.997h12a1 1 0 001-1V.999a1 1 0 00-1-1H1a1 1 0 00-1 1v.998a1 1 0 001 1zM1 6.999h11.999v1.998h-12zM1 3.999h11.999v1.998h-12z"/><path pid="1" d="M17 6.997h3V4.999h-3a3 3 0 00-3 2.998c0 1.654 1.345 3 3 3a1.002 1.002 0 010 2.001H1v1.998h16c1.653 0 3-1.345 3-3 0-1.654-1.347-3-3-3a1.001 1.001 0 010-2zM13 15.998H1a.996.996 0 00-1 .998v1.002a1 1 0 001 1h12a1 1 0 001-1v-1.002c0-.553-.448-1-1-.998z"/></g>'
  }
})
