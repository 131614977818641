import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.libraryIntelligenceApiUrl, {
  camelizeKeys: true,
});

/**
 * Get visual features string from image data
 *
 * Expects a base64 encoding for a jpg image (excluding MIME type).
 * Uses a HTML form content-type rather than JSON, per intelligence's endpoint.
 */
export async function vectoriseV2({ imageData: img }, axiosConfig = {}) {
  return axios.post(
    `/vectorise_v2`,
    { img },
    merge({ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }, axiosConfig),
  );
}

export async function detectTextLanguage({ texts }, axiosConfig = {}) {
  return axios.post(`/detect_language`, { texts }, axiosConfig);
}

export async function translateTextLanguage({ texts }, axiosConfig = {}) {
  return axios.post(`/translate_language`, { texts }, axiosConfig);
}
