import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import { CALENDAR_MODE } from '@/components/core/calendar/constants';
import { clamp, eachDayOfInterval } from '@/utils/dateUtils';

/**
 * An object that combines two dates to represent a time interval.
 * @typedef {{start: Date, end: Date}} Interval
 */

/**
 * A string representing a mode our calendar can be in.
 * @typedef {'week' | 'month'} CalendarMode
 */

/**
 * An object representing the position of a child in a CSS grid.
 * @typedef {{gridRowStart: number, gridRowEnd: number, gridColumnEnd: number, gridColumnStart: number}} GridPosition
 */

/**
 * Get interval containing "date", where the type of interval is specified
 * by the calendar mode.
 * @param {Date} date
 * @param {CalendarMode} mode
 * @returns {Interval}
 */
export const getIntervalByMode = (date, mode) => ({
  start: dayjs(date).startOf(mode).toDate(),
  end: dayjs(date).endOf(mode).toDate(),
});

/**
 * Get a list of dates corresponding to the weekdays within the week containing "date".
 * @param {Date} date
 * @returns Date[]
 */
export const getWeekdays = (date) => eachDayOfInterval(getIntervalByMode(date, CALENDAR_MODE.WEEK));

/**
 * Get interval corresponding to the dates on a monthly calendar for "month", including
 * dates from the previous month that lie in the first week, and dates from the next month
 * that lie in the final week. This always results in a number of dates divisible by 7.
 * @param {Date} month
 * @returns {Interval}
 */
export const getMonthlyCalendarInterval = (month) => ({
  start: dayjs(month).startOf('month').startOf('week').toDate(),
  end: dayjs(month).endOf('month').endOf('week').toDate(),
});

/**
 * Format a date for the monthly calendar.
 * @param {Date} date
 * @param {Date} month
 * @returns {string}
 */
export const formatMonthlyDate = (date, month) => {
  const djs = dayjs(date);
  return djs.format(!djs.isSame(month, 'month') && djs.date() === 1 ? 'MMMM D' : 'D');
};

/**
 * Work out CSS grid areas corresponding to "intervals" within "referenceInterval" such that
 * the number of rows is minimized.
 * @param {Interval[]} intervals
 * @param {Interval} referenceInterval
 * @returns {{interval: Interval, style: GridPosition}[]}
 */
export const arrangeIntervalsInCSSGrid = (intervals, referenceInterval) => {
  const getIndex = (date) =>
    dayjs(clamp(date, referenceInterval)).diff(dayjs(referenceInterval.start), 'day');
  const intervalsByRow = Array.from(Array(intervals.length), () => []);
  const sortedIntervals = sortBy(
    intervals,
    ({ start, end }) => getIndex(end) - getIndex(start),
  ).reverse();
  const intervalsOverlap = (intervalOne, intervalTwo) => {
    const s1 = getIndex(intervalOne.start);
    const s2 = getIndex(intervalTwo.start);
    const e1 = getIndex(intervalOne.end);
    const e2 = getIndex(intervalTwo.end);
    return (
      (s1 <= e2 && s1 >= s2) ||
      (e1 <= e2 && e1 >= s2) ||
      (s2 <= e1 && s2 >= s1) ||
      (e2 <= e1 && e2 >= s1)
    );
  };
  sortedIntervals.forEach((interval) => {
    const firstFitRow = intervalsByRow.findIndex((row) =>
      row.every((rowInterval) => !intervalsOverlap(rowInterval, interval)),
    );
    intervalsByRow[firstFitRow].push(interval);
  });
  return intervalsByRow.flatMap((rowIntervals, index) =>
    rowIntervals.map((interval) => ({
      interval,
      style: {
        gridColumnStart: getIndex(interval.start) + 1,
        gridColumnEnd: getIndex(interval.end) + 2,
        gridRowStart: index + 1,
        gridRowEnd: index + 2,
      },
    })),
  );
};

/**
 * Shift date forward of backwards by a specified number of calendar modes.
 * @param {Date} date
 * @param {CalendarMode} mode
 * @param {number} shiftBy
 * @returns {Date}
 */
export const shiftDateBy = (date, mode, shiftBy) =>
  dayjs(date).startOf(mode).add(shiftBy, mode).toDate();

/**
 * Convert pixel value to equivalent number of rems
 * @param {Number} px
 * @returns {Number}
 */
export const pxToRem = (px) => px * 0.0625;
