/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_740_18938)"><path pid="0" d="M21.924 8.619A1 1 0 0021 8.002h-5.382l-2.724-5.447c-.34-.677-1.45-.677-1.79 0L8.383 8.002H3a1 1 0 00-.707 1.707l4.584 4.584-1.838 6.435a1 1 0 001.515 1.106L12 18.204l5.445 3.63a.998.998 0 001.516-1.106l-1.838-6.435 4.584-4.584c.286-.285.37-.717.217-1.09z" _fill="#FFF6E7"/><path pid="1" d="M18 22.002a1 1 0 01-.555-.168L12 18.204l-5.445 3.63a1 1 0 01-1.516-1.106l1.838-6.435-4.584-4.584A1.001 1.001 0 013 8.002h5.382l2.724-5.447c.339-.677 1.45-.677 1.789 0l2.724 5.447H21a1 1 0 01.707 1.707l-4.584 4.584 1.838 6.435A1 1 0 0118 22.002zm-6-6c.193 0 .387.057.555.168l3.736 2.49-1.252-4.383a.996.996 0 01.254-.982l3.293-3.293H15c-.379 0-.725-.214-.895-.553L12 5.238l-2.105 4.21c-.17.34-.516.554-.895.554H5.414l3.293 3.293a1 1 0 01.254.982L7.709 18.66l3.736-2.491c.168-.111.362-.168.555-.168z" _fill="#FEB43A"/></g><defs><clipPath id="clip0_740_18938"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
