import { decamelizeKeys } from 'humps';
import { parseQuery } from '@/utils/query';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.collaborationApiUrl, {
  camelizeKeys: true,
  decamelizeRequests: true,
});

export async function getNotifications({ next } = {}, axiosConfig = {}) {
  const params = parseQuery(next, { limit: 20 });
  return axios.get(`/notifications`, { params }, axiosConfig);
}

export async function updateNotification({ id, readAt }, axiosConfig = {}) {
  const url = `/notifications/${id}`;
  return axios.patch(url, { readAt }, axiosConfig);
}

export async function updateNotifications({ ids, readAt }, axiosConfig = {}) {
  const url = `/notifications`;
  return axios.patch(url, { readAt }, { ...axiosConfig, params: { ids } });
}

export async function getComments({ brandId, resourceId, resourceType }, axiosConfig = {}) {
  const url = `/comments`;
  return axios.get(url, {
    ...axiosConfig,
    params: { brandId, resourceId, resourceType },
  });
}

export async function createComment(
  { brandId, resourceId, resourceType, text, parentId, meta },
  axiosConfig = {},
) {
  return axios.post(
    `/comments`,
    {
      brandId,
      resourceId,
      resourceType,
      text,
      parentId,
      meta,
    },
    axiosConfig,
  );
}

export async function updateComment({ id, text, meta }, axiosConfig = {}) {
  const url = `/comments/${id}`;
  return axios.patch(url, { text, meta }, axiosConfig);
}

export async function deleteComment({ id }, axiosConfig = {}) {
  const url = `/comments/${id}`;
  return axios.delete(url, axiosConfig);
}

export async function getBrandComments(
  {
    brandId,
    commentId,
    createdAfter,
    createdBefore,
    resourceIds,
    resourceTypes,
    mentionedUserIds,
    meta,
    offset,
    limit,
    sort,
    next,
  },
  axiosConfig = {},
) {
  if (next) {
    return axios.get(next, { ...axiosConfig });
  }
  const url = `/brands/${brandId}/comments`;
  return axios.get(url, {
    params: {
      commentId,
      createdAfter,
      createdBefore,
      resourceIds,
      resourceTypes,
      mentionedUserIds,
      meta: JSON.stringify(decamelizeKeys(meta)),
      offset,
      limit,
      sort,
    },
    ...axiosConfig,
  });
}

export async function getCommentCounts(
  { brandId, resourceType, meta, createdAfter, createdBefore, mentionedUserIds },
  axiosConfig = {},
) {
  const url = `/brands/${brandId}/comments/counts`;
  return axios.get(url, {
    params: {
      resourceType,
      meta: JSON.stringify(decamelizeKeys(meta)),
      createdAfter,
      createdBefore,
      mentionedUserIds,
    },
    ...axiosConfig,
  });
}
