const App = () => import('@/app/landingPage/App.vue');
const LandingPage = () => import('@/app/landingPage/pages/LandingPage.vue');

const PAGE_LEVEL_TOP = `Landing Page`;

export default {
  path: '',
  component: App,
  meta: {
    requiresAuth: true,
    hideSecondaryNav: true,
  },
  children: [
    {
      name: 'landingPage',
      path: '',
      component: LandingPage,
      meta: {
        analytics: PAGE_LEVEL_TOP,
      },
    },
  ],
};
