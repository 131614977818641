<template>
  <div class="pin-list-item-container">
    <ProductPopup v-if="showProductPopup" @on-change="linkSelected" @close="closeProductPopup" />
    <div class="pin-content">
      <div class="media-container">
        <div class="media">
          <div class="video-container">
            <!-- Upload progress bar -->
            <RadialProgressBar
              v-if="pinUploading"
              :diameter="100"
              :completed-steps="displayPin.uploadProgress"
              :total-steps="100"
              start-color="white"
              stop-color="white"
              inner-stroke-color="lightGrey"
              class="center"
            />
            <InfiniteLoader
              v-if="displayPin.uploadStatus && displayPin.uploadStatus === UPLOAD_STATUS.PROCESSING"
            />

            <video v-if="isVideo" ref="video" :src="fullSizeMedia" class="video-player" />
            <img v-else-if="!isText" :src="ratioMediaUrl" />
          </div>

          <div v-if="isVideo" class="video-duration">
            <Icon v-tooltip="'This is a video.'" name="video-2" xxsmall color="white" />
            <span class="video-duration-text">{{ videoDuration }}</span>
          </div>

          <div class="pin-item-mask">
            <div v-if="isVideo" @click="videoClicked">
              <Icon v-if="!videoPlaying" xlarge color="white" class="play-icon" name="play" />
              <Icon v-else xlarge color="white" name="pause" />
            </div>

            <div v-if="showCropButton" class="pin-icon crop" @click="cropClicked">
              <Icon :hover-color="iconHoverColor" xsmall color="white" name="crop"></Icon>
            </div>
          </div>
        </div>
        <KeyframeSliderPrecise
          v-if="displayKeyframes"
          :value="displayPin.thumbOffset"
          :media="pin"
          :predictions="pin.videoPredictions"
          :prediction-interval="pin.videoPredictionInterval"
          :suggested-thumbnails="pin.videoSuggestedThumbnailList"
          class="key-frame"
          @offset-update="handleOffsetChange"
        />
      </div>
      <div class="detail">
        <div class="pin-title-field">
          <textarea
            :value="displayPin.title"
            class="pin-title"
            @input="updateTitle($event.target.value)"
            @keyup="textAreaAdjust"
            @focus="showTitleLimit = true"
            @blur="showTitleLimit = false"
          />
          <span v-if="!displayPin.title" class="placeholder">
            Enter title
            <span class="asterisk">*</span>
          </span>
        </div>
        <div class="caption-box">
          <span
            v-show="showTitleLimit"
            :class="{ 'caption-red': displayPin.title.length >= pinterestCaptionLimit.title }"
            class="caption-indicator"
          >
            {{ pinterestCaptionLimit.title - displayPin.title.length }}
          </span>
        </div>
        <div class="description-textarea">
          <Textarea
            ref="descriptionInput"
            :value="displayPin.description"
            :class="{
              'caption-red': displayPin?.description?.length >= pinterestCaptionLimit.description,
            }"
            :limit="pinterestCaptionLimit.description"
            :pin-list-item-style="true"
            placeholder="Enter description"
            @input="(e) => updatePin({ description: e })"
            @on-focus="showDescriptionLimit = true"
            @on-blur="showDescriptionLimit = false"
          />
        </div>
        <div class="caption-box">
          <span
            v-show="showDescriptionLimit"
            :class="{
              'caption-red': displayPin?.description?.length >= pinterestCaptionLimit.description,
            }"
            class="caption-indicator"
          >
            {{ pinterestCaptionLimit.description - displayPin?.description?.length }}
          </span>
        </div>
        <div class="pin-footer">
          <div
            :class="['link-wrapper', 'add-link']"
            @mouseover="showClearLink = true"
            @mouseleave="showClearLink = false"
          >
            <div class="input-part-link">
              <input
                :value="displayPin.linkUrl"
                placeholder="Add a destination link"
                @input="updateLink($event.target.value)"
                @blur="formatUrlForPin"
              />
              <Icon
                v-if="showClearLink && displayPin.linkUrl"
                name="closeCircleSolid"
                xsmall
                class="clear-buttons"
                @click="clearLink"
              />
              <Icon
                v-tooltip="toolTips.pinterestDestinationLink"
                :hover-color="colours.ACTION.ACTION_500"
                name="info"
                medium
              />
              <button
                v-tooltip="'Choose from products'"
                class="link-option"
                @click="() => (showProductPopup = true)"
              >
                <Icon
                  :hover-color="colours.ACTION.ACTION_500"
                  :color="colours.ICON.ICON_SECONDARY"
                  small
                  name="windowLink"
                />
              </button>
            </div>
            <p v-if="displayPin.invalidLink || websiteHasErrors" class="link-error">
              {{ linkErrorMessage }}
            </p>
            <slot name="edit-utm-controls" :pin="pin"></slot>
          </div>
          <div
            v-if="showAddToBoardControl"
            :class="{ 'add-gallery': displayPin.invalidLink }"
            class="add-link"
            @mouseover="showClearGalleries = true"
            @mouseleave="showClearGalleries = false"
          >
            <div class="add-link-main-part">
              <a @click.prevent="addToGalleryClicked">
                <Icon :hover-color="colours.ACTION.ACTION_500" name="folderAdd" small />
                <span
                  :class="[
                    'add-to-dropdown-button',
                    { placeholder: displayPin.selectedGalleries.length === 0 },
                  ]"
                  data-cy="add-to-board-dropdown-button"
                >
                  {{ addToAfterLabel }}
                </span>
              </a>

              <Icon
                v-if="showClearGalleries && displayPin.selectedGalleries.length > 0"
                name="close"
                xsmall
                class="clear-buttons"
                @click="clearGalleries"
              />
            </div>
            <AddToDropdown
              ref="addToDropdown"
              :select-only="true"
              :on-galleries-selected="galleriesSelected"
              :selected-galleries="displayPin.selectedGalleries"
            />
          </div>
        </div>
        <div class="remove" @click="remove">
          <Icon :hover-color="colours.ACTION.ACTION_500" name="bin" small />
        </div>
      </div>
    </div>
    <div
      v-if="showAddToBoardControl || showPublishControl"
      :class="{
        fullWidth: !showPublishControl,
        halfWidth: !showAddToBoardControl,
      }"
      class="control"
    >
      <PinterestBoardSelector
        v-if="showAddToBoardControl"
        v-tooltip="boardTooltip"
        :align-top="topPopup"
        :value="displayPin.pinterestBoard"
        :disabled="boardDisabled"
        compact
        @input="(e) => updatePin({ pinterestBoard: e })"
      />
      <ImageDropdownSelect
        v-if="showPublishControl"
        :align-top="topPopup"
        :value="displayPin.publishType"
        :options="publishOptions"
        compact
        option-icon
        capitalize-text
        class="control-items"
        @input="(e) => updatePin({ publishType: e })"
      />
      <QuickSelectCalendar
        v-if="showPublishControl"
        :disabled="quickSelectDateDisabled"
        :tooltip="!pinterestConnected ? toolTips.noPinterestConnection : ''"
        :model-value="displayPin.scheduledTime"
        :restrict-selection-to-interval="canPublishWithinInterval"
        :platform="platformLabels.pinterest"
        compact
        dropdown-select-style
        class="control-items"
        @update:model-value="(time) => updatePin({ scheduledTime: time })"
      />
    </div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
    <div v-else-if="mediaValidationError" class="error">{{ mediaValidationError }}</div>
    <Banner
      v-if="publishDateError"
      hide-default-icon
      :custom-icon="publishDateError.icon"
      :alert-type="publishDateError.level"
      class="publish-date-error"
    >
      {{ publishDateError.message }}
    </Banner>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import { logger } from '@/utils/logger';
import { useAuthStore } from '@/stores/auth';
import { useProductStore } from '@/stores/product';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import { toolTips, UPLOAD_STATUS } from '@/config';
import { env } from '@/env';
import { colours } from '@/ux/colours';
import {
  validatePinterestMedia,
  getPublishOptions,
  validatePin,
  mediaUrl,
  shouldDisplayKeyFrames,
} from '@/app/scheduler/utils';
import { pinterestCaptionLimit, platformLabels } from '@/app/scheduler/constants';
import Icon from '@/components/foundation/Icon.vue';
import Textarea from '@/components/Textarea.vue';
import PinterestBoardSelector from '@/app/scheduler/components/EditPost/PinterestBoardSelector.vue';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';
import AddToDropdown from '@/components/AddToDropdown.vue';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import { mediaSizeIsCroppable } from '@/utils/media';
import { formatTime } from '@/utils/formatters';
import { usePlatformStore } from '@/stores/platform';
import { useSharedStore } from '@/stores/shared';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useFlagStore } from '@/stores/flag';
import { validatePublishDates } from '@/app/scheduler/utils/publish-dates';
import Banner from '@/components/foundation/feedback/Banner.vue';
import KeyframeSliderPrecise from '../EditPost/MediaViewer/KeyframeSliderPrecise.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'PinListItem',
  components: {
    Banner,
    ImageDropdownSelect,
    Icon,
    PinterestBoardSelector,
    ProductPopup: defineAsyncComponent(
      () => import('@/app/library/components/MediaPopup/ProductPopup.vue'),
    ),
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    AddToDropdown,
    Textarea,
    KeyframeSliderPrecise,
    InfiniteLoader,
    RadialProgressBar,
  },
  mixins: [ApprovedPublishingDatesMixin],
  props: {
    cropClicked: { type: Function, default: () => {} },
    pin: { type: Object, default: null },
    topPopup: { type: Boolean, default: false },
    scheduledTime: { type: Date, default: null },
    isAppConnected: { type: Boolean, default: false },
  },
  emits: ['checkMediaFormat', 'pinUpdated', 'remove', 'update:scheduled-time'],
  data() {
    return {
      ...{ UPLOAD_STATUS },
      errorMessage: '',
      iconHoverColor: colours.ACTION.ACTION_500,
      mediaValidationError: null,
      pinterestCaptionLimit,
      showClearLink: false,
      showClearGalleries: false,
      showTitleLimit: false,
      showDescriptionLimit: false,
      showAddToBoardControl: false,
      showAfterPostAddToPopup: false,
      showProductPopup: false,
      showPublishControl: false,
      toolTips,
      videoPlaying: false,
      websiteErrors: [],
    };
  },
  computed: {
    ...mapStores(
      useFlagStore,
      usePlatformStore,
      useProductStore,
      useSharedStore,
      useSchedulerPinterestStore,
      useAuthStore,
      useImpersonatorStore,
    ),
    publishDateError() {
      return validatePublishDates(
        this.canPublishWithin,
        this.pin?.scheduledTime || this.scheduledTime,
      );
    },
    quickSelectDateDisabled() {
      return (
        !this.pinterestConnected ||
        !this.canPublishWithinInterval ||
        this.canPublishWithinInterval.end < new Date()
      );
    },
    canPublishWithinInterval() {
      if (!this.canPublishWithin) {
        this.$emit('update:scheduled-time', null);
        return null;
      }
      return this.canPublishWithin;
    },
    mediaList() {
      return [this.pin];
    },
    colours() {
      return colours;
    },
    // set default properties if they are missing.
    displayPin() {
      return {
        title: this.title,
        description: this.description,
        selectedGalleries: [],
        linkUrl: '',
        ...this.pin,
      };
    },
    description() {
      return (
        this.pin?.pinterest?.note || this.pin?.facebook?.message || this.pin?.twitter?.text || ''
      );
    },
    title() {
      return (
        this.pin?.pinterest?.title || this.pin?.facebook?.title || this.pin?.twitter?.title || ''
      );
    },
    showCropButton() {
      return !this.pinUploading && this.mediaIsCroppable;
    },
    pinUploading() {
      return (
        Boolean(this.displayPin.uploadStatus) &&
        this.displayPin.uploadStatus !== UPLOAD_STATUS.SUCCESS &&
        this.displayPin.uploadStatus !== UPLOAD_STATUS.PROCESSING
      );
    },
    addToAfterLabel() {
      const { selectedGalleries } = this.displayPin;
      if (selectedGalleries.length === 0) {
        return 'After published, add to...';
      }
      if (selectedGalleries.length <= 2) {
        return selectedGalleries.map((gallery) => gallery.name).join(',');
      }
      return `${selectedGalleries[0].name} and ${selectedGalleries.length - 1} others.`;
    },
    fullSizeMedia() {
      const { displayPin: pin } = this;
      const pinMediaObject = pin.fullMediaObject || pin;
      if (pinMediaObject.sizes) {
        // MediaV2
        return pinMediaObject.sizes.originalConverted
          ? pinMediaObject.sizes.originalConverted.url
          : pinMediaObject.sizes.original.url;
      }
      // Uploaded media
      return pinMediaObject.url;
    },
    isVideo() {
      return this.displayPin.type === 'VIDEO';
    },
    isText() {
      const textTypes = ['text', 'status'];
      const type = this.pin?.facebook?.type || this.pin?.twitter?.type || null;
      return textTypes.includes(type);
    },
    videoDuration() {
      if (this.isVideo && this.displayPin.duration) {
        return formatTime(this.displayPin.duration);
      }
      return null;
    },
    ratioMediaUrl() {
      const { displayPin: pin } = this;
      const pinMediaObject = pin.fullMediaObject || pin;
      // MediaV2
      if (pinMediaObject.type === 'IMAGE' && pinMediaObject.sizes) {
        return pinMediaObject.sizes.small.url;
      }
      if (pinMediaObject.type === 'VIDEO' && pinMediaObject.sizes) {
        return pinMediaObject.thumbnails.small.url;
      }
      // Uploaded media
      return pinMediaObject.url;
    },
    linkErrorMessage() {
      if (this.displayPin.linkUrl.length > 2000) {
        return toolTips.invalidLinkLength;
      }
      if (this.websiteHasErrors) {
        return this.firstWebsiteError;
      }
      return toolTips.invalidLinkURL;
    },
    websiteHasErrors() {
      return this.websiteErrors?.length > 0;
    },
    firstWebsiteError() {
      return this.websiteErrors?.[0] || '';
    },
    publishOptions() {
      const isDisabledDueToInsufficientPermission =
        // Currently only user permissions are set for pinterest auto publishing (no brand-specific perms)
        !this.authStore.user_can('scheduler', 'can_auto_publish_pinterest') ||
        this.impersonatorStore.isImpersonating;

      const canAutoPublish = !isDisabledDueToInsufficientPermission;
      let autoPublishTooltip = null;
      if (isDisabledDueToInsufficientPermission) {
        autoPublishTooltip = toolTips.insufficientPermission;
      }
      return getPublishOptions(canAutoPublish, autoPublishTooltip);
    },
    pinterestConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.PINTEREST);
    },
    displayKeyframes() {
      const autoPublish = this.displayPin.publishType === 'autoPublish';
      return (
        shouldDisplayKeyFrames([this.pin], autoPublish, this.pin) &&
        !this.pinUploading &&
        (this.displayPin.previewUrl || (this.displayPin && this.displayPin.sizes))
      );
    },
    boardDisabled() {
      return this.disableEditing || !this.isAppConnected;
    },
    boardTooltip() {
      if (!this.isAppConnected) {
        return toolTips.boardNeedsAccount;
      }
      return '';
    },
    platformLabels() {
      return platformLabels;
    },
  },
  watch: {
    pin: {
      deep: true,
      handler(to) {
        if (to.uploadStatus === UPLOAD_STATUS.SUCCESS) {
          this.validatePin();
          this.validateMedia();
        }
        this.websiteErrors = this.validateDestinationUrl(to?.linkUrl);
        this.$emit('checkMediaFormat');
      },
    },
    'schedulerPinterestStore.addToSameBoardSetting': {
      handler(to) {
        this.showAddToBoardControl = !to.on;
        this.validatePin();
      },
    },
    'schedulerPinterestStore.publishAtSameTimeSetting': {
      handler(to) {
        this.showPublishControl = !to.on;
      },
    },
    'schedulerPinterestStore.updatePinTrigger': {
      handler() {
        this.validatePin();
      },
    },
    'productStore.urlValidationStatusDict': function updateUrlValidationStatusDict(to) {
      const obj = {};
      if (
        (to && to[this.displayPin.id] && this.displayPin.linkUrl) ||
        this.displayPin.linkUrl.length > 2000
      ) {
        obj.invalidLink = true;
      } else {
        obj.invalidLink = false;
      }
      this.updatePin(obj);
    },
  },
  mounted() {
    if (
      this.schedulerPinterestStore.addToSameBoardSetting &&
      this.schedulerPinterestStore.addToSameBoardSetting.on === false
    ) {
      this.showAddToBoardControl = true;
    }
    if (
      this.schedulerPinterestStore.publishAtSameTimeSetting &&
      this.schedulerPinterestStore.publishAtSameTimeSetting.on === false
    ) {
      this.showPublishControl = true;
    }
    this.validatePin();
    this.validateMedia();
    this.websiteErrors = this.validateDestinationUrl(this.pin?.linkUrl);
    const elements = document.getElementsByClassName('pin-title');
    [].forEach.call(elements, (element) => {
      const o = element;
      o.style.height = 'auto';
      o.style.height = `${element.scrollHeight}px`;
    });

    if (this.displayPin.thumbOffset) {
      const { video } = this.$refs;
      video.currentTime = this.displayPin.thumbOffset;
    }
  },
  methods: {
    updatePin(object) {
      this.$emit('pinUpdated', cloneDeep({ ...this.displayPin, ...object }));
    },
    updateLink(value) {
      const obj = { linkUrl: value };
      if (!value) {
        obj.invalidLink = false;
      }
      this.websiteErrors = this.validateDestinationUrl(value);
      if (this.websiteErrors.length > 0) {
        obj.invalidLink = true;
      } else {
        obj.invalidLink = false;
      }
      this.updatePin(obj);
    },
    updateTitle(value) {
      this.updatePin({ title: value.substr(0, pinterestCaptionLimit.title) });
    },
    getMediaUrl() {
      return mediaUrl([this.pin]);
    },
    handleOffsetChange(offset) {
      const { video } = this.$refs;
      video.currentTime = offset;
      this.updatePin({ thumbOffset: offset });
    },
    clearLink() {
      this.updatePin({ linkUrl: '', invalidLink: false });
    },
    clearGalleries() {
      this.updatePin({ selectedGalleries: [] });
      this.sharedStore.clearSelectedMultiSelectListItems();
    },
    addToGalleryClicked() {
      this.$refs.addToDropdown.openAddToDropdown();
    },
    textAreaAdjust(o) {
      const element = o.target;
      setTimeout(() => {
        element.style.height = 'auto';
        element.style.height = `${o.target.scrollHeight}px`;
      }, 0);
    },
    closeAfterPostAddToPopup() {
      this.showAfterPostAddToPopup = false;
    },
    closeProductPopup() {
      this.showProductPopup = false;
    },
    async formatUrlForPin() {
      let { linkUrl } = this.pin;
      if (!linkUrl) {
        return;
      }
      linkUrl = this.appendProtocol(linkUrl);
      this.updatePin({ linkUrl });
      try {
        const response = await this.productStore.getUrlMetadata({
          url: linkUrl,
          id: this.displayPin.id,
        });
        if (response.data) {
          if (this.displayPin?.title?.trim().length === 0) {
            this.displayPin.title = response.data.title;
          }
          if (this.displayPin?.description?.trim().length === 0) {
            this.displayPin.description = response.data.description;
          }
        }
      } catch (error) {
        logger.error(`Could not get URL Metadata for ${linkUrl}`, {}, error);
      }
    },
    validateDestinationUrl(value) {
      const errors = [];
      if (!value) {
        return errors;
      }
      const linkUrl = this.appendProtocol(value);
      let urlObj;
      try {
        urlObj = new URL(linkUrl);
      } catch (e) {
        errors.push(toolTips.invalidLinkURL);
        return errors;
      }
      const linkHasDeniedHost = env.pinterestDestLinkDenyList.some(
        (denyHost) => urlObj.host.toLowerCase() === denyHost,
      );
      if (linkHasDeniedHost) {
        errors.push(toolTips.invalidLinkURLShortener);
      }
      return errors;
    },
    appendProtocol(link) {
      if (!(link.startsWith('http://') || link.startsWith('https://'))) {
        return `https://${link}`;
      }
      return link;
    },
    validatePin() {
      const { publishType, pinterestBoard, title, scheduledTime } = this.pin;
      this.errorMessage = validatePin(
        this.pin,
        pinterestBoard && pinterestBoard.name,
        title,
        publishType === 'autoPublish',
        scheduledTime,
      );
    },
    async validateMedia() {
      const isAbbrevMessage = true;
      this.mediaValidationError = await validatePinterestMedia(
        this.pin,
        this.authStore.currentBrand.id,
        isAbbrevMessage,
      );
    },
    galleriesSelected(galleries) {
      this.updatePin({ selectedGalleries: galleries });
      this.closeAfterPostAddToPopup();
    },
    linkSelected(link) {
      const { product } = link;
      const obj = {};
      obj.linkUrl = product.url || '';
      obj.productId = product.id;
      if (!this.displayPin.title) {
        obj.title = product.title || '';
      }
      if (!this.displayPin.description) {
        obj.description = product.description || '';
      }
      this.updatePin(obj);
    },
    mediaIsCroppable() {
      return mediaSizeIsCroppable(this.media);
    },
    remove() {
      this.$emit('remove', this.pin);
      this.mediaValidationError = null;
    },
    videoClicked() {
      const { video } = this.$refs;
      if (video.paused) {
        this.videoPlaying = true;
        video.play();
      } else {
        this.videoPlaying = false;
        video.pause();
      }
      video.onended = () => {
        this.videoPlaying = false;
      };
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.pin-icon {
  position: absolute;
  width: var(--space-28);
  height: var(--space-28);
  background-color: rgb(0 0 0 / 50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 80px;

  .icon {
    margin: 0;
  }
}

.edit-container {
  display: flex;
  min-width: 40rem;
}

.pin-list-item-container {
  margin-bottom: var(--space-16);
  border: 1px solid var(--white);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-1);
  padding: var(--space-16);

  .media-container {
    margin-right: var(--space-16);
    width: 16rem;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .media {
    border-radius: var(--round-corner-small);
    display: flex;
    width: 16rem;
    min-height: 4rem;
    max-height: 25rem;
    background-color: var(--background-500);
    align-items: center;
    flex-wrap: wrap;

    .video-container {
      position: relative;
      display: flex;
      align-items: center;

      .center {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .video-player {
      max-height: 25rem;
    }

    .video-icon {
      position: absolute;
      right: var(--space-8);
      top: var(--space-8);
    }

    .pin-item-mask {
      display: none;
      position: absolute;
      inset: 0;
      cursor: pointer;
      transition: all 0.3s;
      background-color: rgb(0 0 0 / 50%);
      align-items: center;
      justify-content: center;

      .crop {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }
    }

    &:hover {
      .pin-item-mask {
        border-radius: var(--round-corner-small);
        display: flex;
      }
    }

    img,
    video {
      border-radius: var(--round-corner-small);
      width: 100%;
      height: auto;
    }
  }

  .pin-content {
    min-height: 12rem;
    display: flex;

    .media {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  .error {
    color: var(--error-500);
    margin-top: var(--space-16);
    padding: var(--space-8);
    font-size: var(--x12);
    background-color: var(--error-100);
  }

  .control {
    display: grid;
    grid-template-columns: 35% 30% 35%;
    margin: var(--space-20) (calc(-1 * var(--space-4)) var(--space-4));

    .control-items {
      :deep(.picker) {
        min-height: var(--space-40);
      }

      margin: 0;
    }
  }

  .control.fullWidth {
    grid-template-columns: 100%;
  }

  .control.halfWidth {
    grid-template-columns: 50% 50%;
  }

  .detail {
    width: 100%;
    position: relative;

    .description-textarea {
      width: calc(100% - 3rem);
      margin-top: var(--space-8);
    }

    .caption-box {
      height: var(--space-12);
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    textarea {
      width: calc(100% - 3rem);
      border: none;
      line-height: var(--space-20);
      padding: var(--space-12);
      resize: none;
      margin-bottom: var(--space-4);
      height: auto;

      &:focus {
        background-color: var(--background-300);
      }
    }

    .pin-title {
      font-size: var(--x16);
      font-weight: var(--font-medium);
      overflow: hidden;
      margin-bottom: 0;
    }

    .pin-title-field {
      .placeholder {
        position: absolute;
        top: var(--space-12);
        left: var(--space-12);
        color: var(--text-secondary);
        pointer-events: none;
      }

      .asterisk {
        color: var(--error-500);
        pointer-events: none;
      }
    }

    .caption-indicator {
      color: var(--text-secondary);
      font-size: var(--x12);
      display: flex;
      margin-left: 88%;
    }

    .caption-red {
      color: var(--error-500);
    }

    .pin-footer {
      margin-top: var(--space-12);
      display: block;
      right: var(--space-24);
      align-items: center;

      .link-wrapper {
        margin-bottom: var(--space-16);
      }
    }

    .link-error {
      margin-left: var(--space-8);
      color: var(--error-500);
      font-size: var(--x12);
    }

    .add-link {
      width: calc(100% - 0.5rem);
      box-sizing: border-box;
      align-items: center;
      margin-left: var(--space-8);

      .clear-buttons {
        margin-right: var(--space-8);
      }

      .add-link-main-part {
        display: flex;
        align-items: center;
        margin-bottom: var(--space-8);
      }

      .input-part-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border);
      }

      a {
        display: flex;
        align-items: center;
        margin-right: var(--space-8);
      }

      svg {
        cursor: pointer;
      }

      input {
        width: 90%;
        border: none;
        font-size: var(--x14);
        margin-bottom: 1px;
        padding: 0 var(--space-8);
        height: var(--space-28);
        color: var(--action-500);
        margin-right: var(--space-8);
        text-overflow: ellipsis;
      }

      button {
        border: none;
        background: var(--background-0);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        margin-left: var(--space-16);
        color: var(--action-500);
        font-size: var(--x14);
      }

      span.placeholder {
        color: var(--text-secondary);
      }
    }

    .add-gallery {
      margin-bottom: var(--space-24);
    }

    p {
      font-size: var(--x14);
    }
  }

  .video-duration {
    position: absolute;
    bottom: 10px;
    right: 12px;

    .icon {
      position: relative;
      top: 2px;
    }

    .video-duration-text {
      padding-left: var(--space-4);
      color: var(--white);
      font-family: var(--muli);
      font-size: var(--x12);
      font-weight: var(--font-medium);
      line-height: var(--space-18);
    }
  }

  .publish-date-error {
    margin: var(--space-16) 0 0 0;
  }
}
</style>
