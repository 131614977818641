<template>
  <div :class="infiniteLoaderClass">
    <svg :class="[{ 'circular-s': 'small' }, circularClass]" viewBox="25 25 50 50">
      <circle
        cx="50"
        cy="50"
        r="20"
        class="path"
        fill="none"
        stroke-width="5"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'InfiniteLoader',
  props: {
    tile: { type: Boolean, default: false },
    useWhiteOverlay: { type: Boolean, default: false },
  },
  computed: {
    infiniteLoaderClass() {
      if (this.tile) {
        return 'infinite-loader-tile';
      }
      if (this.useWhiteOverlay) {
        return 'infinite-loader-white';
      }
      return 'infinite-loader';
    },
    circularClass() {
      if (this.tile) {
        return 'circular-tile';
      }
      return 'circular';
    },
    color() {
      return this.useWhiteOverlay ? '#000' : 'rgb(255 255 255 / 100%)';
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.infinite-loader {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 30%);
}

.infinite-loader-white {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(255 255 255 / 50%), rgba(255 255 255 / 50%));
}

.infinite-loader-tile {
  position: relative;
  margin: auto;
  width: 6.25rem;
}

.infinite-loader::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.infinite-loader-tile::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 25%;
  transform-origin: center center;
  width: 25%;
  position: absolute;
  inset: 0;
  margin: auto;
}

.circular-tile {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  0%,
  100% {
    stroke: v-bind(color);
  }
}
</style>
