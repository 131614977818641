/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stories': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 16.001c-2.205 0-4-1.794-4-4s1.795-4 4-4 4 1.794 4 4-1.795 4-4 4zm0-6c-1.104 0-2 .898-2 2 0 1.103.896 2 2 2s2-.897 2-2c0-1.102-.896-2-2-2zM12.165 23.046l-.209-1.989.995-.105a8.98 8.98 0 001.838-.391l.951-.309.619 1.901-.951.31c-.728.237-1.484.397-2.248.478l-.995.105zm-2.601-.268l-.951-.308a10.996 10.996 0 01-2.102-.934l-.867-.499.998-1.733.867.499a9.01 9.01 0 001.719.764l.951.307-.615 1.904zm9.058-1.936l-1.339-1.486.742-.669c.467-.42.891-.891 1.26-1.398l.588-.81 1.617 1.177-.588.809c-.451.62-.969 1.194-1.537 1.708l-.743.669zM3.701 19.291l-.59-.808a10.997 10.997 0 01-1.154-1.988l-.409-.912 1.825-.818.408.914c.256.571.574 1.118.945 1.625l.59.807-1.615 1.18zm18.855-4.026l-1.957-.414.206-.978A9.02 9.02 0 0021 12.001v-1l2-.041v1.012c0 .798-.08 1.566-.238 2.314l-.206.979zM1 13.022v-1.001c0-.78.078-1.541.232-2.282l.206-.979 1.957.409-.203.979A8.987 8.987 0 003 12.001v1l-2 .021zM20.621 9.22l-.411-.911a9.047 9.047 0 00-.947-1.624l-.591-.807 1.613-1.183.591.807c.454.62.845 1.289 1.158 1.985l.41.912-1.823.821zM4.117 7.54L2.498 6.367l.586-.81c.45-.621.967-1.196 1.534-1.71l.741-.671 1.343 1.483-.741.67A9.03 9.03 0 004.705 6.73l-.588.81zM17.34 4.688l-.867-.498a8.946 8.946 0 00-1.719-.76l-.952-.306.611-1.904.952.306c.73.235 1.438.548 2.104.93l.867.498-.996 1.734zm-9.097-.929l-.623-1.9.95-.312a10.972 10.972 0 012.248-.483l.994-.106.213 1.989-.994.107a8.971 8.971 0 00-1.838.394l-.95.311z"/>'
  }
})
