/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shorts': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M10.268.463c1.874-1.032 4.195-.28 5.181 1.68.987 1.96.267 4.385-1.607 5.417l-1.541.857c1.327.051 2.594.822 3.257 2.14.986 1.96.268 4.385-1.608 5.417l-8.218 4.563c-1.874 1.032-4.195.28-5.182-1.68-.986-1.96-.266-4.385 1.608-5.417l1.541-.857c-1.328-.051-2.594-.822-3.257-2.14-.986-1.96-.267-4.385 1.608-5.417L10.268.463zM6 7l6 3.508L6 14V7z" id="svgicon_shorts_a"/></defs><g transform="translate(4 1)" _fill="none" fill-rule="evenodd"><mask id="svgicon_shorts_b" _fill="#fff"><use xlink:href="#svgicon_shorts_a"/></mask><use _fill="#686a7a" xlink:href="#svgicon_shorts_a"/><g mask="url(#svgicon_shorts_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M-4.5-1.999h24v23h-24z"/></g></g>'
  }
})
