import { parseTimestamps } from '@/app/community/utils/time';
import { WEBSOCKET_UPDATE_ACTIONS } from '@/app/community/constants';

/**
 * Apply bulk create, update, and delete operations to a collection. Triggered by
 * websocket events on the backend.
 */
export function applyBulkUpdateToCollection(collection, idFieldName, update, addOnUpdate = true) {
  const updatedItem = parseTimestamps(update.data);
  const itemIndex = collection.findIndex((i) => i[idFieldName] === updatedItem[idFieldName]);
  if (
    update.action === WEBSOCKET_UPDATE_ACTIONS.CREATE ||
    update.action === WEBSOCKET_UPDATE_ACTIONS.UPDATE
  ) {
    if (itemIndex > -1) {
      collection.splice(itemIndex, 1, updatedItem);
    } else if (addOnUpdate || update.action === WEBSOCKET_UPDATE_ACTIONS.CREATE) {
      collection.push(updatedItem);
    }
  } else if (update.action === WEBSOCKET_UPDATE_ACTIONS.DELETE) {
    if (itemIndex > -1) {
      collection.splice(itemIndex, 1);
    }
  }
}
