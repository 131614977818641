import { toolTips } from '@/config';
import { colours } from '@/ux/colours';

export const tooltips = {
  topStatNumberOfPosts:
    'The number of posts published for all channels during the reporting period.',
  topStatVideoViews:
    'The sum of video views of all posts published for all channels during the reporting period.',
  topStatTotalEngagements:
    'The total engagements from all posts published for all channels during the reporting period.',
  topStatAvgEngagementRate:
    'The average engagement rates for all media during the reporting period.',
  topStatImpressions:
    'The total impressions from all posts published for all channels during the reporting period.',
  topStatClicks:
    'The number of clicks from from all posts published for all channels during the reporting period.',
  BarLineChartAvgEngagement: 'Your average engagement rate over the last 12 weeks from today.',
  engagementRateChart: {
    campaign:
      'The average engagement rate for posts on this channel within this campaign during the reporting period.',
    campaignAverage:
      'The average engagement rate across all channels within this campaign during the reporting period.',
    channelAverage: 'The average engagement rate on this channel during the reporting period.',
  },
  sentiment: {
    overall:
      'The total sentiment breakdown of comments on owned posts in your campaign during the reporting period',
  },
};

export const accessMessages = {
  noUserAccessTitle: "Looks like you don't have permission to use this feature!",
  noAccessMessage:
    'Dash Hudson brings you all of the insights you love, including Campaigns! Ask your Dash Hudson administrator to provide you access.',
};

export const chartColors = {
  blue: colours.CHART.SET.CHART_SET_01,
  green: colours.CHART.SET.CHART_SET_02,
  orange: colours.CHART.SET.CHART_SET_03,
};

export const timeScaleTypes = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

export const channelList = {
  TIKTOK: 'TikTok',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  PINTEREST: 'Pinterest',
  TWITTER: 'X',
  YOUTUBE: 'YouTube',
  INSTAGRAM_STORY: ['Instagram', 'Stories'],
};
const CAMPAIGN_STATS_METRICS = {
  NUMBER_OF_POSTS: {
    valueName: 'number_of_media',
    label: 'Number of Posts',
    isPercent: false,
  },
  VIDEO_VIEWS: {
    valueName: 'video_views',
    label: 'Video Views',
    isPercent: false,
  },
  PAID_VIDEO_VIEWS: {
    valueName: 'paid_video_views',
    label: 'Video Views (Promoted)',
    isPercent: false,
  },
  TOTAL_VIDEO_VIEWS: {
    valueName: 'total_video_views',
    label: 'Video Views',
    isPercent: false,
  },
  VIDEO_VIEWS_PROMOTED: {
    valueName: 'video_views_promoted',
    label: 'Video Views (Promoted)',
    isPercent: false,
  },
  VIDEO_VIEWS_ORGANIC: {
    valueName: 'video_views_organic',
    label: 'Video Views (Organic)',
    isPercent: false,
  },
  ENGAGEMENTS: {
    valueName: 'engagements',
    label: 'Total Engagements',
    isPercent: false,
  },
  ORGANIC_ENGAGEMENTS: {
    valueName: 'engagements',
    label: 'Engagements (Organic)',
    isPercent: false,
  },
  PAID_ENGAGEMENTS: {
    valueName: 'paid_engagements',
    label: 'Engagements (Paid)',
    isPercent: false,
  },
  ENGAGEMENTS_ORGANIC: {
    valueName: 'engagements_organic',
    label: 'Engagements (Organic)',
    isPercent: false,
  },
  ENGAGEMENTS_PROMOTED: {
    valueName: 'engagements_promoted',
    label: 'Engagements (Promoted)',
    isPercent: false,
  },
  TOTAL_ENGAGEMENTS: {
    valueName: 'total_engagements',
    label: 'Engagements',
    isPercent: false,
  },
  AVG_ENGAGEMENT_RATE: {
    valueName: 'avg_engagement_rate',
    label: 'Avg. Engagement Rate',
    isPercent: true,
  },
  AVG_ENGAGEMENT_RATE_ORGANIC: {
    valueName: 'avg_engagement_rate_organic',
    label: 'Avg. Engagement Rate (Organic)',
    isPercent: true,
  },
  AVG_ENGAGEMENT_RATE_PROMOTED: {
    valueName: 'avg_engagement_rate_promoted',
    label: 'Avg. Engagement Rate (Promoted)',
    isPercent: true,
  },
  AVG_RETENTION_RATE: {
    valueName: 'avg_retention_rate',
    label: 'Avg. Retention Rate',
    isPercent: true,
  },
  AVG_REACH: {
    valueName: 'avg_reach',
    label: 'Avg. Reach',
    isPercent: false,
  },
  FYP_VIEWS: {
    valueName: 'fyp_views_percentage',
    label: 'Avg. FYP Views %',
    isPercent: true,
  },
  AVG_EFFECTIVENESS_RATE: {
    valueName: 'avg_effectiveness_rate',
    label: 'Avg. Effectiveness Rate',
    isPercent: true,
  },
  IMPRESSIONS: {
    valueName: 'impressions',
    label: 'Impressions',
    isPercent: false,
  },
  PAID_IMPRESSIONS: {
    valueName: 'paid_impressions',
    label: 'Impressions (Paid)',
    isPercent: false,
  },
  IMPRESSIONS_ORGANIC: {
    valueName: 'impressions_organic',
    label: 'Impressions (Organic)',
    isPercent: false,
  },
  IMPRESSIONS_PROMOTED: {
    valueName: 'impressions_promoted',
    label: 'Impressions (Promoted)',
    isPercent: false,
  },
  TOTAL_IMPRESSIONS: {
    valueName: 'total_impressions',
    label: 'Impressions',
    isPercent: false,
  },
  AVG_COMPLETION_RATE: {
    valueName: 'avg_completion_rate',
    label: 'Avg. Completion Rate',
    isPercent: true,
  },
  AVG_EXIT_RATE: {
    valueName: 'avg_exit_rate',
    label: 'Avg. Exit Rate',
    isPercent: true,
  },
  EXITS: {
    valueName: 'exits',
    label: 'Exits',
    isPercent: false,
  },
  LINK_CLICKS: {
    valueName: 'link_clicks',
    label: 'Link Clicks',
    isPercent: false,
  },
  LINK_CLICKS_ORGANIC: {
    valueName: 'link_clicks_organic',
    label: 'Link Clicks (Organic)',
    isPercent: false,
  },
  LINK_CLICKS_PROMOTED: {
    valueName: 'link_clicks_promoted',
    label: 'Link Clicks (Promoted)',
    isPercent: false,
  },
  OUTBOUND_CLICKS: {
    valueName: 'link_clicks',
    label: 'Outbound Clicks',
    isPercent: false,
  },
  SAVES: {
    valueName: 'saves',
    label: 'Saves',
    isPercent: false,
  },
  COMMENTS: {
    valueName: 'comments',
    label: 'Comments',
    isPercent: false,
  },
  MENTIONS: {
    valueName: 'mentions',
    label: 'Mentions',
    isPercent: false,
  },
  PEOPLE_TAGS: {
    valueName: 'people_tags',
    label: 'People Tags',
    isPercent: false,
  },
  AVG_ESTIMATED_REACH: {
    valueName: 'estimated_reach',
    label: 'Avg. Estimated Reach',
    tooltip:
      'The average of the estimated number of unique accounts who saw each post published during the reporting period',
    isPercent: false,
  },
  LIKES: {
    valueName: 'likes',
    label: 'Likes',
    isPercent: false,
  },
  LIKES_ORGANIC: {
    valueName: 'likes_organic',
    label: 'Likes (Organic)',
    isPercent: false,
  },
  LIKES_PROMOTED: {
    valueName: 'likes_promoted',
    label: 'Likes (Promoted)',
    isPercent: false,
  },
  WATCH_TIME: {
    valueName: 'watch_time',
    label: 'Watch Time',
    isPercent: false,
  },
  AVG_VIEW_DURATION: {
    valueName: 'avg_view_duration',
    label: 'Avg. View Duration',
    isPercent: false,
  },
  DISLIKES: {
    valueName: 'dislikes',
    label: 'Dislikes',
    isPercent: false,
  },
};

export const topLineValues = {
  tiktok: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of posts published during the reporting period. Engagement Rate = Total Engagements / Reach',
    },
    CAMPAIGN_STATS_METRICS.AVG_RETENTION_RATE,
    CAMPAIGN_STATS_METRICS.AVG_REACH,
    CAMPAIGN_STATS_METRICS.FYP_VIEWS,
  ],
  instagram: [
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_VIDEO_VIEWS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.PAID_VIDEO_VIEWS,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_IMPRESSIONS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.PAID_IMPRESSIONS,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.TOTAL_ENGAGEMENTS,
      valueName: 'engagements',
      tooltip: toolTips.instagramOverview.metrics.TOTAL_ENGAGEMENTS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      label: 'Avg. Engagement Rate (Followers)',
      tooltip: toolTips.instagramOverview.averageEngagementRate,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
      tooltip: toolTips.instagramOverview.averageEffectiveness,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
        label: 'Organic',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_REACH,
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_REACH,
        label: 'Organic',
      },
    },
  ],
  'instagram-stories': [
    CAMPAIGN_STATS_METRICS.AVG_REACH,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    CAMPAIGN_STATS_METRICS.AVG_COMPLETION_RATE,
    CAMPAIGN_STATS_METRICS.AVG_EXIT_RATE,
    CAMPAIGN_STATS_METRICS.EXITS,
    CAMPAIGN_STATS_METRICS.LINK_CLICKS,
  ],
  facebook: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    { ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS, tooltip: toolTips.facebook.engagements },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip: toolTips.facebook.averageEngagementRate,
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_EFFECTIVENESS_RATE,
      tooltip: toolTips.facebook.averageEffectiveness,
    },
    CAMPAIGN_STATS_METRICS.AVG_REACH,
  ],
  pinterest: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.IMPRESSIONS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of the posts published during the reporting period. Engagement Rate = (Total Engagements / Impressions)',
    },
    CAMPAIGN_STATS_METRICS.SAVES,
    CAMPAIGN_STATS_METRICS.OUTBOUND_CLICKS,
  ],
  twitter: [
    {
      ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.VIDEO_VIEWS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.IMPRESSIONS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.IMPRESSIONS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.ENGAGEMENTS_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip:
        'The average engagement rate of the posts published during the reporting period. Engagement Rate = (Total Engagements / Impressions)',
      organic: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.LIKES,
      organic: {
        ...CAMPAIGN_STATS_METRICS.LIKES_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.LIKES_PROMOTED,
        label: 'Promoted',
      },
    },
    {
      ...CAMPAIGN_STATS_METRICS.LINK_CLICKS,
      organic: {
        ...CAMPAIGN_STATS_METRICS.LINK_CLICKS_ORGANIC,
        label: 'Organic',
      },
      promoted: {
        ...CAMPAIGN_STATS_METRICS.LINK_CLICKS_PROMOTED,
        label: 'Promoted',
      },
    },
  ],
  youtube: [
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    CAMPAIGN_STATS_METRICS.WATCH_TIME,
    CAMPAIGN_STATS_METRICS.AVG_VIEW_DURATION,
    CAMPAIGN_STATS_METRICS.LIKES,
    CAMPAIGN_STATS_METRICS.DISLIKES,
  ],
  relationships: [
    CAMPAIGN_STATS_METRICS.NUMBER_OF_POSTS,
    CAMPAIGN_STATS_METRICS.VIDEO_VIEWS,
    {
      ...CAMPAIGN_STATS_METRICS.AVG_ENGAGEMENT_RATE,
      tooltip: 'The average engagement rate for the posts published during the reporting period',
    },
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
    CAMPAIGN_STATS_METRICS.LIKES,
    CAMPAIGN_STATS_METRICS.COMMENTS,
  ],
  ugc: [
    CAMPAIGN_STATS_METRICS.NUMBER_OF_POSTS,
    CAMPAIGN_STATS_METRICS.MENTIONS,
    CAMPAIGN_STATS_METRICS.PEOPLE_TAGS,
    CAMPAIGN_STATS_METRICS.AVG_ESTIMATED_REACH,
    CAMPAIGN_STATS_METRICS.ENGAGEMENTS,
  ],
  'creator-insights': [],
  'creator-deliverables': [],
  ecomm: [],
};

export const postMetricKeys = {
  TIKTOK: {
    VIDEO_VIEWS: 'TIKTOK_VIEWS',
    TOTAL_ENGAGEMENTS: 'TIKTOK_TOTAL_ENGAGEMENTS',
    EFFECTIVENESS: 'TIKTOK_EFFECTIVENESS',
    ENGAGEMENT_RATE: 'TIKTOK_ENGAGEMENT_RATE',
    RETENTION_RATE: 'TIKTOK_AVERAGE_COMPLETION_RATE',
    FYP_VIEWS: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
    DATE: 'DATE',
    IMPRESSIONS_PERSONAL_PROFILE_RATE: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
    AVERAGE_TIME_WATCHED: 'TIKTOK_AVERAGE_TIME_WATCHED',
    REACH: 'TIKTOK_REACH',
    COMMENTS: 'TIKTOK_COMMENTS',
    FULL_VIDEO_WATCHED_RATE: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
    IMPRESSIONS_SEARCH_RATE: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
    SHARES: 'TIKTOK_SHARES',
    IMPRESSIONS_SOUND_RATE: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
    IMPRESSIONS_FOLLOW_RATE: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
    TOTAL_TIME_WATCHED: 'TIKTOK_TOTAL_TIME_WATCHED',
    IMPRESSIONS_HASHTAG_RATE: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
    DURATION: 'TIKTOK_DURATION',
    LIKES: 'TIKTOK_LIKES',
    ENTERTAINMENT_SCORE: 'TIKTOK_ENTERTAINMENT_SCORE',
  },
  FACEBOOK: {
    POST_CLICKS: 'FACEBOOK_POST_CLICKS',
    COMMENTS: 'FACEBOOK_COMMENTS',
    REACH: 'FACEBOOK_REACH',
    EFFECTIVENESS: 'FACEBOOK_EFFECTIVENESS',
    REACTIONS: 'FACEBOOK_REACTIONS',
    ENGAGEMENT_RATE: 'FACEBOOK_ENGAGEMENT_RATE',
    DATE: 'DATE',
    SHARES: 'FACEBOOK_SHARES',
    IMPRESSIONS: 'FACEBOOK_IMPRESSIONS',
    TOTAL_ENGAGEMENTS: 'FACEBOOK_TOTAL_ENGAGEMENTS',
    LINK_CLICKS: 'FACEBOOK_LINK_CLICKS',
    VIDEO_COMPLETE_VIEWS: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
    OTHER_CLICKS: 'FACEBOOK_OTHER_CLICKS',
    VIDEO_VIEWS: 'FACEBOOK_VIDEO_VIEWS',
    PHOTO_VIEW_CLICKS: 'FACEBOOK_PHOTO_VIEW_CLICKS',
  },
  TWITTER: {
    DATE: 'DATE',
    ENGAGEMENT_RATE: 'TWITTER_ENGAGEMENT_RATE',
    TOTAL_ENGAGEMENTS: 'TWITTER_TOTAL_ENGAGEMENTS',
    IMPRESSIONS: 'TWITTER_IMPRESSIONS',
    LIKES: 'TWITTER_LIKES',
    VIDEO_VIEWS: 'TWITTER_VIDEO_VIEWS',
    RETWEETS: 'TWITTER_RETWEETS',
    REPLIES: 'TWITTER_REPLIES',
    TOTAL_RETWEETS: 'TWITTER_TOTAL_RETWEETS',
    URL_CLICKS: 'TWITTER_URL_CLICKS',
    FOLLOWS: 'TWITTER_FOLLOWS',
    PROFILE_CLICKS: 'TWITTER_USER_PROFILE_CLICKS',
    QUOTE_TWEETS: 'TWITTER_QUOTE_TWEETS',
  },
  INSTAGRAM_STORY: {
    DATE: 'DATE',
    REACH: 'INSTAGRAM_STORY_FRAME_REACH',
    IMPRESSIONS: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
    COMPLETION_RATE: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
    EXIT_RATE: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
    EXITS: 'INSTAGRAM_STORY_FRAME_EXITS',
    LINK_CLICKS: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
    REPLIES: 'INSTAGRAM_STORY_FRAME_REPLIES',
  },
  YOUTUBE: {
    DATE: 'DATE',
    VIDEO_VIEWS: 'YOUTUBE_VIEWS',
    WATCH_TIME: 'YOUTUBE_EST_SECONDS_WATCHED',
    AVERAGE_VIEW_DURATION: 'YOUTUBE_AVG_VIEW_DURATION',
    AVERAGE_PERCENTAGE_VIEWED: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
    SUBSCRIBERS: 'YOUTUBE_SUBSCRIBERS',
    CARD_CLICKS: 'YOUTUBE_CARD_CLICKS',
    CARD_CLICK_RATE: 'YOUTUBE_CARD_CLICK_RATE',
    CARD_TEASER_CLICKS: 'YOUTUBE_CARD_TEASER_CLICKS',
    CARD_TEASER_CLICK_RATE: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
    CARD_TEASER_IMPRESSIONS: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
    TOTAL_ENGAGEMENTS: 'YOUTUBE_ENGAGEMENTS',
    LIKES: 'YOUTUBE_LIKES',
    DISLIKES: 'YOUTUBE_DISLIKES',
    COMMENTS: 'YOUTUBE_COMMENTS',
    PREMIUM_VIEWS: 'YOUTUBE_PREMIUM_VIEWS',
    PREMIUM_WATCH_TIME: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
    SHARES: 'YOUTUBE_SHARES',
    ADDED_TO_PLAYLIST: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
  },
  PINTEREST: {
    DATE: 'DATE',
    ENGAGEMENT_RATE: 'PINTEREST_ENGAGEMENT_RATE',
    IMPRESSIONS: 'PINTEREST_TOTAL_IMPRESSIONS',
    OUTBOUND_CLICKS: 'PINTEREST_TOTAL_CLICKS',
    SAVES: 'PINTEREST_TOTAL_SAVES',
    PIN_CLICKS: 'PINTEREST_TOTAL_CLOSEUPS',
    VIDEO_VIEWS: 'PINTEREST_TOTAL_VIDEO_VIEWS',
    AVERAGE_VIDEO_WATCH_TIME: 'PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
  },
  INSTAGRAM: {
    DATE: 'DATE',
    ORGANIC_COMMENTS: 'INSTAGRAM_ORGANIC_COMMENTS',
    PAID_COMMENTS: 'INSTAGRAM_PAID_COMMENTS',
    TOTAL_COMMENTS: 'INSTAGRAM_TOTAL_COMMENTS',
    EFFECTIVENESS: 'INSTAGRAM_EFFECTIVENESS',
    ENGAGEMENT_RATE: 'INSTAGRAM_ENGAGEMENT_RATE',
    ORGANIC_IMPRESSIONS: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
    PAID_IMPRESSIONS: 'INSTAGRAM_PAID_IMPRESSIONS',
    TOTAL_IMPRESSIONS: 'INSTAGRAM_TOTAL_IMPRESSIONS',
    ORGANIC_LIKES: 'INSTAGRAM_ORGANIC_LIKES',
    PAID_LIKES: 'INSTAGRAM_PAID_LIKES',
    TOTAL_LIKES: 'INSTAGRAM_TOTAL_LIKES',
    LIKESHOP_CLICKS: 'LIKESHOP_CLICKS',
    REACH: 'INSTAGRAM_REACH',
    SAVES: 'INSTAGRAM_SAVES',
    TOTAL_ENGAGEMENTS: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    PAID_TOTAL_ENGAGEMENTS: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
    SUM_TOTAL_ENGAGEMENTS: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
    VIDEO_VIEWS: 'INSTAGRAM_VIDEO_VIEWS',
    PAID_VIDEO_VIEWS: 'INSTAGRAM_PAID_VIDEO_VIEWS',
    TOTAL_VIDEO_VIEWS: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
  },
  UGC: {
    DATE: 'DATE',
    ESTIMATED_REACH: 'INSTAGRAM_REACH',
    ENGAGEMENT_RATE: 'INSTAGRAM_ENGAGEMENT_RATE',
    ENGAGEMENTS: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
    LIKES: 'INSTAGRAM_TOTAL_LIKES',
    MENTIONED_YOU: 'MENTIONED_YOU',
    TAGGED_YOU: 'TAGGED_YOU',
    REACH: 'INSTAGRAM_STORY_FRAME_REACH',
    IMPRESSIONS: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS',
    COMPLETION_RATE: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
    EXIT_RATE: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
    EXITS: 'INSTAGRAM_STORY_FRAME_EXITS',
  },
  RELATIONSHIP: {
    ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
    FOLLOWERS_GAINED: 'FOLLOWERS_GAINED',
    EFFECTIVENESS: 'EFFECTIVENESS',
    EMV: 'EMV',
    REACH: 'REACH',
    IMPRESSIONS: 'IMPRESSIONS',
    COMPLETTION_RATE: 'COMPLETTION_RATE',
    EXIT_RATE: 'EXIT_RATE',
  },
};

export const defaultChannelMetrics = {
  TIKTOK: [
    postMetricKeys.TIKTOK.ENTERTAINMENT_SCORE,
    postMetricKeys.TIKTOK.VIDEO_VIEWS,
    postMetricKeys.TIKTOK.TOTAL_ENGAGEMENTS,
    postMetricKeys.TIKTOK.AVERAGE_TIME_WATCHED,
  ],
  FACEBOOK: [
    postMetricKeys.FACEBOOK.ENGAGEMENT_RATE,
    postMetricKeys.FACEBOOK.IMPRESSIONS,
    postMetricKeys.FACEBOOK.TOTAL_ENGAGEMENTS,
    postMetricKeys.FACEBOOK.REACH,
  ],
  TWITTER: [
    postMetricKeys.TWITTER.ENGAGEMENT_RATE,
    postMetricKeys.TWITTER.TOTAL_ENGAGEMENTS,
    postMetricKeys.TWITTER.IMPRESSIONS,
    postMetricKeys.TWITTER.LIKES,
  ],
  INSTAGRAM_STORY: [
    postMetricKeys.INSTAGRAM_STORY.REACH,
    postMetricKeys.INSTAGRAM_STORY.IMPRESSIONS,
    postMetricKeys.INSTAGRAM_STORY.COMPLETION_RATE,
    postMetricKeys.INSTAGRAM_STORY.EXIT_RATE,
    postMetricKeys.INSTAGRAM_STORY.EXITS,
    postMetricKeys.INSTAGRAM_STORY.LINK_CLICKS,
  ],
  YOUTUBE: [
    postMetricKeys.YOUTUBE.VIDEO_VIEWS,
    postMetricKeys.YOUTUBE.WATCH_TIME,
    postMetricKeys.YOUTUBE.AVERAGE_VIEW_DURATION,
    postMetricKeys.YOUTUBE.TOTAL_ENGAGEMENTS,
    postMetricKeys.YOUTUBE.AVERAGE_PERCENTAGE_VIEWED,
  ],
  PINTEREST: [
    postMetricKeys.PINTEREST.ENGAGEMENT_RATE,
    postMetricKeys.PINTEREST.SAVES,
    postMetricKeys.PINTEREST.IMPRESSIONS,
    postMetricKeys.PINTEREST.PIN_CLICKS,
  ],
  INSTAGRAM: [
    postMetricKeys.INSTAGRAM.ENGAGEMENT_RATE,
    postMetricKeys.INSTAGRAM.EFFECTIVENESS,
    postMetricKeys.INSTAGRAM.TOTAL_ENGAGEMENTS,
    postMetricKeys.INSTAGRAM.REACH,
  ],
  UGC: [
    postMetricKeys.UGC.ESTIMATED_REACH,
    postMetricKeys.UGC.ENGAGEMENT_RATE,
    postMetricKeys.UGC.ENGAGEMENTS,
    postMetricKeys.UGC.LIKES,
  ],
  UGC_STORY: [
    postMetricKeys.UGC.ESTIMATED_REACH,
    postMetricKeys.UGC.IMPRESSIONS,
    postMetricKeys.UGC.COMPLETION_RATE,
    postMetricKeys.UGC.EXIT_RATE,
  ],
  RELATIONSHIP_INSTAGRAM: [
    postMetricKeys.RELATIONSHIP.ENGAGEMENT_RATE,
    postMetricKeys.RELATIONSHIP.EFFECTIVENESS,
    postMetricKeys.RELATIONSHIP.FOLLOWERS_GAINED,
    postMetricKeys.RELATIONSHIP.EMV,
  ],
  RELATIONSHIP_STORY: [
    postMetricKeys.RELATIONSHIP.REACH,
    postMetricKeys.RELATIONSHIP.IMPRESSIONS,
    postMetricKeys.RELATIONSHIP.COMPLETTION_RATE,
    postMetricKeys.RELATIONSHIP.EXIT_RATE,
  ],
};

export const channelSortOptions = {
  TIKTOK: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
      label: 'Profile Views %',
      field: 'impressions_personal_profile_rate',
    },
    {
      value: 'TIKTOK_AVERAGE_TIME_WATCHED',
      label: 'Avg. Time Watched',
      field: 'average_time_watched',
    },
    {
      value: 'TIKTOK_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'TIKTOK_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },
    {
      value: 'TIKTOK_AVERAGE_COMPLETION_RATE',
      label: 'Retention Rate',
      field: 'average_completion_rate',
    },
    {
      value: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
      label: 'Completion Rate',
      field: 'full_video_watched_rate',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
      label: 'Search Views %',
      field: 'impressions_search_rate',
    },
    {
      value: 'TIKTOK_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'TIKTOK_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'TIKTOK_ENTERTAINMENT_SCORE',
      label: 'Entertainment Score',
      field: 'entertainment_score',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
      label: 'Sound Views %',
      field: 'impressions_sound_rate',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
      label: 'Following Page Views %',
      field: 'impressions_follow_rate',
    },
    {
      value: 'TIKTOK_TOTAL_ENGAGEMENTS',
      label: 'Total Engagements',
      field: 'total_engagements',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
      label: 'For You Page Views %',
      field: 'impressions_for_you_rate',
    },
    {
      value: 'TIKTOK_TOTAL_TIME_WATCHED',
      label: 'Total Time Watched',
      field: 'total_time_watched',
    },
    {
      value: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
      label: 'Hashtag Views %',
      field: 'impressions_hashtag_rate',
    },
    {
      value: 'TIKTOK_DURATION',
      label: 'Video Duration',
      field: 'duration',
    },
    {
      value: 'TIKTOK_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'TIKTOK_VIEWS',
      label: 'Video Views',
      field: 'views',
    },
  ],
  FACEBOOK: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'FACEBOOK_POST_CLICKS',
      label: 'Post Clicks',
      field: 'post_clicks',
    },
    {
      value: 'FACEBOOK_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },
    {
      value: 'FACEBOOK_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'FACEBOOK_EFFECTIVENESS',
      label: 'Effectiveness',
      field: 'effectiveness',
    },
    {
      value: 'FACEBOOK_REACTIONS',
      label: 'Reactions',
      field: 'reactions',
    },
    {
      value: 'FACEBOOK_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'FACEBOOK_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'FACEBOOK_IMPRESSIONS',
      label: 'Impressions',
      field: 'impressions',
    },
    {
      value: 'FACEBOOK_TOTAL_ENGAGEMENTS',
      label: 'Total Engagements',
      field: 'total_engagements',
    },
    {
      value: 'FACEBOOK_LINK_CLICKS',
      label: 'Link Clicks',
      field: 'link_clicks',
    },
    {
      value: 'FACEBOOK_VIDEO_COMPLETE_VIEWS',
      label: 'Video Complete Views',
      field: 'video_complete_views',
    },
    {
      value: 'FACEBOOK_OTHER_CLICKS',
      label: 'Other Clicks',
      field: 'other_clicks',
    },
    {
      value: 'FACEBOOK_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'video_views',
    },
    {
      value: 'FACEBOOK_PHOTO_VIEW_CLICKS',
      label: 'Photo View Clicks',
      field: 'photo_view_clicks',
    },
  ],
  TWITTER: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'TWITTER_REPLIES',
      label: 'replies',
      field: 'replies',
    },
    {
      value: 'TWITTER_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    {
      value: 'TWITTER_RETWEETS',
      label: 'Reposts',
      field: 'retweets',
    },
    {
      value: 'TWITTER_IMPRESSIONS',
      label: 'Impressions',
      field: 'impressions',
    },
    {
      value: 'TWITTER_TOTAL_ENGAGEMENTS',
      label: 'Total Engagements',
      field: 'total_engagements',
    },
    {
      value: 'TWITTER_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'TWITTER_TOTAL_RETWEETS',
      label: 'Total Reposts',
      field: 'total_retweets',
    },
    {
      value: 'TWITTER_URL_CLICKS',
      label: 'Link Clicks',
      field: 'url_clicks',
    },
    {
      value: 'TWITTER_FOLLOWS',
      label: 'User Follows',
      field: 'follows',
    },
    {
      value: 'TWITTER_USER_PROFILE_CLICKS',
      label: 'Profile Clicks',
      field: 'user_profile_clicks',
    },
    {
      value: 'TWITTER_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'video_views',
    },
    {
      value: 'TWITTER_QUOTE_TWEETS',
      label: 'Quote Posts',
      field: 'quote_tweets',
    },
  ],
  INSTAGRAM_STORY: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    { value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS', label: 'Impressions', field: 'views' },
    {
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_SWIPE_UPS',
      label: 'Link Clicks',
      field: 'swipe_ups',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_REACH',
      label: 'Reach',
      field: 'reach',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXITS',
      label: 'Exits',
      field: 'exits',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_REPLIES',
      label: 'Replies',
      field: 'replies',
    },
  ],
  YOUTUBE: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'YOUTUBE_COMMENTS',
      label: 'Comments',
      field: 'comments',
    },

    {
      value: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
      label: 'Added to Playlist',
      field: 'videos_added_to_playlist',
    },
    {
      value: 'YOUTUBE_DISLIKES',
      label: 'Dislikes',
      field: 'dislikes',
    },
    {
      value: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
      label: 'Avg. % Viewed',
      field: 'avg_view_percentage',
    },
    {
      value: 'YOUTUBE_LIKES',
      label: 'Likes',
      field: 'likes',
    },
    {
      value: 'YOUTUBE_PREMIUM_VIEWS',
      label: 'Premimum Views',
      field: 'premimum_views',
    },

    {
      value: 'YOUTUBE_AVG_VIEW_DURATION',
      label: 'Avg. View Duration',
      field: 'avg_view_duration',
    },
    {
      value: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
      label: 'Premium Watch Time',
      field: 'est_premium_seconds_watched',
    },

    {
      value: 'YOUTUBE_CARD_CLICKS',
      label: 'Card Clicks',
      field: 'card_clicks',
    },
    {
      value: 'YOUTUBE_SHARES',
      label: 'Shares',
      field: 'shares',
    },
    {
      value: 'YOUTUBE_CARD_CLICK_RATE',
      label: 'Card Click Rate',
      field: 'card_click_rate',
    },
    {
      value: 'YOUTUBE_SUBSCRIBERS',
      label: 'Subscribers',
      field: 'subscribers',
    },

    {
      value: 'YOUTUBE_CARD_TEASER_CLICKS',
      label: 'Card Teaser Clicks',
      field: 'card_teaser_clicks',
    },
    {
      value: 'YOUTUBE_ENGAGEMENTS',
      label: 'Total Engagements',
      field: 'engagements',
    },

    {
      value: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
      label: 'Card Teaser Click Rate',
      field: 'card_teaser_click_rate',
    },

    {
      value: 'YOUTUBE_VIEWS',
      label: 'Video Views',
      field: 'views',
    },
    {
      value: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
      label: 'Card Teaser Impressions',
      field: 'card_teaser_impressions',
    },

    {
      value: 'YOUTUBE_EST_SECONDS_WATCHED',
      label: 'Watch Time',
      field: 'est_seconds_watched',
    },
  ],
  PINTEREST: [
    {
      value: 'DATE',
      label: 'Recently Published',
    },
    {
      value: 'PINTEREST_TOTAL_CLICKS',
      label: 'Outbound Clicks',
      field: 'total_clicks',
    },
    {
      value: 'PINTEREST_AVERAGE_VIDEO_WATCH_TIME',
      label: 'Average Watch Time',
      field: 'average_video_watch_time',
    },
    {
      value: 'PINTEREST_TOTAL_CLOSEUPS',
      label: 'Pin Clicks',
      field: 'total_closeups',
    },
    {
      value: 'PINTEREST_ENGAGEMENT_RATE',
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },

    {
      value: 'PINTEREST_TOTAL_SAVES',
      label: 'Saves',
      field: 'total_saves',
    },
    {
      value: 'PINTEREST_TOTAL_IMPRESSIONS',
      label: 'Impressions',
      field: 'total_impressions',
    },

    {
      value: 'PINTEREST_TOTAL_VIDEO_VIEWS',
      label: 'Video Views',
      field: 'total_video_views',
    },
  ],
  INSTAGRAM: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_ORGANIC_COMMENTS', label: 'Comments (Organic)', field: 'comments_count' },
    { value: 'INSTAGRAM_PAID_COMMENTS', label: 'Comments (Promoted)', field: 'paid_comments' },
    { value: 'INSTAGRAM_TOTAL_COMMENTS', label: 'Comments (Total)', field: 'total_comments' },
    { value: 'INSTAGRAM_EFFECTIVENESS', label: 'Effectiveness', field: 'effectiveness' },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
    {
      value: 'INSTAGRAM_ORGANIC_IMPRESSIONS',
      label: 'Impressions (Organic)',
      field: 'impressions',
    },
    {
      value: 'INSTAGRAM_PAID_IMPRESSIONS',
      label: 'Impressions (Promoted)',
      field: 'paid_impressions',
    },
    {
      value: 'INSTAGRAM_TOTAL_IMPRESSIONS',
      label: 'Impressions (Total)',
      field: 'total_impressions',
    },
    { value: 'INSTAGRAM_ORGANIC_LIKES', label: 'Likes (Organic)', field: 'like_count' },
    { value: 'INSTAGRAM_PAID_LIKES', label: 'Likes (Promoted)', field: 'paid_likes' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes (Total)', field: 'total_likes' },
    { value: 'LIKESHOP_CLICKS', label: 'LikeShop Clicks', field: 'likeshop_clicks' },
    { value: 'INSTAGRAM_REACH', label: 'Reach (Organic)', field: 'reach' },
    { value: 'INSTAGRAM_SAVES', label: 'Saves (Organic)', field: 'saved' },
    {
      value: 'INSTAGRAM_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Organic)',
      field: 'total_engagement',
    },
    {
      value: 'INSTAGRAM_PAID_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Promoted) ',
      field: 'paid_total_engagements',
    },
    {
      value: 'INSTAGRAM_SUM_TOTAL_ENGAGEMENTS',
      label: 'Engagements (Total)',
      field: 'sum_total_engagements',
    },
    { value: 'INSTAGRAM_VIDEO_VIEWS', label: 'Video Views (Organic)', field: 'video_views' },
    {
      value: 'INSTAGRAM_PAID_VIDEO_VIEWS',
      label: 'Video Views (Promoted)',
      field: 'paid_video_views',
    },
    {
      value: 'INSTAGRAM_TOTAL_VIDEO_VIEWS',
      label: 'Video Views (Total)',
      field: 'total_video_views',
    },
  ],
  UGC: [
    { value: 'DATE', label: 'Recently Published' },
    { value: 'INSTAGRAM_REACH', label: 'Reach', field: 'reach' },
    { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
    { value: 'INSTAGRAM_TOTAL_ENGAGEMENTS', label: 'Engagements', field: 'total_engagement' },
    { value: 'INSTAGRAM_TOTAL_LIKES', label: 'Likes', field: 'total_likes' },
    { value: 'INSTAGRAM_STORY_FRAME_IMPRESSIONS', label: 'Impressions', field: 'views' },
    {
      value: 'INSTAGRAM_STORY_FRAME_COMPLETION_RATE',
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXIT_RATE',
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    {
      value: 'INSTAGRAM_STORY_FRAME_EXITS',
      label: 'Exits',
      field: 'exits',
    },
  ],
};

export const postMetrics = {
  TIKTOK: {
    [postMetricKeys.TIKTOK.VIDEO_VIEWS]: {
      field: 'views',
      label: 'Video Views',
    },
    [postMetricKeys.TIKTOK.TOTAL_ENGAGEMENTS]: {
      field: 'total_engagements',
      label: 'Total Engagements',
    },
    [postMetricKeys.TIKTOK.EFFECTIVENESS]: {
      field: 'effectiveness',
      label: 'Effectiveness',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.ENGAGEMENT_RATE]: {
      field: 'engagement_rate',
      label: 'Engagement Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.ENTERTAINMENT_SCORE]: {
      field: 'entertainment_score',
      label: 'Entertainment Score',
      format: 'two_decimal_float',
    },
    [postMetricKeys.TIKTOK.RETENTION_RATE]: {
      field: 'average_completion_rate',
      label: 'Retention Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.FYP_VIEWS]: {
      field: 'impressions_for_you_rate',
      label: 'FYP Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_PERSONAL_PROFILE_RATE]: {
      field: 'impressions_personal_profile_rate',
      label: 'Profile Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.AVERAGE_TIME_WATCHED]: {
      field: 'average_time_watched',
      label: 'Avg. Time Watched',
      format: 'duration',
      num_decimals: 1,
    },
    [postMetricKeys.TIKTOK.REACH]: {
      field: 'reach',
      label: 'Reach',
    },
    [postMetricKeys.TIKTOK.COMMENTS]: {
      field: 'comments',
      label: 'Comments',
    },
    [postMetricKeys.TIKTOK.FULL_VIDEO_WATCHED_RATE]: {
      field: 'full_video_watched_rate',
      label: 'Completion Rate',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_SEARCH_RATE]: {
      field: 'impressions_search_rate',
      label: 'Search Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.SHARES]: {
      field: 'shares',
      label: 'Shares',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_SOUND_RATE]: {
      field: 'impressions_sound_rate',
      label: 'Sound Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_FOLLOW_RATE]: {
      field: 'impressions_follow_rate',
      label: 'Following Page Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.TOTAL_TIME_WATCHED]: {
      field: 'total_time_watched',
      label: 'Total Time Watched',
      format: 'duration',
    },
    [postMetricKeys.TIKTOK.IMPRESSIONS_HASHTAG_RATE]: {
      field: 'impressions_hashtag_rate',
      label: 'Hashtag Views %',
      format: 'percent',
    },
    [postMetricKeys.TIKTOK.DURATION]: {
      field: 'duration',
      label: 'Video Duration',
      format: 'duration',
    },
    [postMetricKeys.TIKTOK.LIKES]: {
      field: 'likes',
      label: 'Likes',
    },
  },
  FACEBOOK: {
    [postMetricKeys.FACEBOOK.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.FACEBOOK.POST_CLICKS]: {
      label: 'Post Clicks',
      field: 'post_clicks',
    },
    [postMetricKeys.FACEBOOK.COMMENTS]: {
      label: 'Comments',
      field: 'comments',
    },
    [postMetricKeys.FACEBOOK.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.FACEBOOK.EFFECTIVENESS]: {
      label: 'Effectiveness',
      field: 'effectiveness',
      format: 'percent',
    },
    [postMetricKeys.FACEBOOK.REACTIONS]: {
      label: 'Reactions',
      field: 'reactions',
    },
    [postMetricKeys.FACEBOOK.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement_rate',
      format: 'percent',
    },
    [postMetricKeys.FACEBOOK.SHARES]: {
      label: 'Shares',
      field: 'shares',
    },
    [postMetricKeys.FACEBOOK.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.FACEBOOK.TOTAL_ENGAGEMENTS]: {
      label: 'Total Engagements',
      field: 'total_engagements',
    },
    [postMetricKeys.FACEBOOK.LINK_CLICKS]: {
      label: 'Link Clicks',
      field: 'link_clicks',
    },
    [postMetricKeys.FACEBOOK.VIDEO_COMPLETE_VIEWS]: {
      label: 'Video Complete Views',
      field: 'video_complete_views',
    },
    [postMetricKeys.FACEBOOK.OTHER_CLICKS]: {
      label: 'Other Clicks',
      field: 'other_clicks',
    },
    [postMetricKeys.FACEBOOK.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'video_views',
    },
    [postMetricKeys.FACEBOOK.PHOTO_VIEW_CLICKS]: {
      label: 'Photo View Clicks',
      field: 'photo_view_clicks',
    },
  },
  TWITTER: {
    [postMetricKeys.TWITTER.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.TWITTER.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement_rate',
    },
    [postMetricKeys.TWITTER.TOTAL_ENGAGEMENTS]: {
      label: 'Total Engagements',
      field: 'engagements',
    },
    [postMetricKeys.TWITTER.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.TWITTER.LIKES]: {
      label: 'Likes',
      field: 'likes',
    },
    [postMetricKeys.TWITTER.URL_CLICKS]: {
      label: 'Link Clicks',
      field: 'url_clicks',
    },
    [postMetricKeys.TWITTER.TOTAL_RETWEETS]: {
      label: 'Total Reposts',
      field: 'total_retweets',
    },
    [postMetricKeys.TWITTER.RETWEETS]: {
      label: 'Reposts',
      field: 'retweets',
    },
    [postMetricKeys.TWITTER.FOLLOWS]: {
      label: 'User Follows',
      field: 'follows',
    },
    [postMetricKeys.TWITTER.PROFILE_CLICKS]: {
      label: 'Profile Clicks',
      field: 'user_profile_clicks',
    },
    [postMetricKeys.TWITTER.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'video_views',
    },
    [postMetricKeys.TWITTER.QUOTE_TWEETS]: {
      label: 'Quote Posts',
      field: 'quote_tweets',
    },
    [postMetricKeys.TWITTER.REPLIES]: {
      label: 'Replies',
      field: 'replies',
    },
  },
  INSTAGRAM_STORY: {
    [postMetricKeys.INSTAGRAM_STORY.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.INSTAGRAM_STORY.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'views',
    },
    [postMetricKeys.INSTAGRAM_STORY.COMPLETION_RATE]: {
      label: 'Completion Rate',
      field: 'completion_rate',
      format: 'percent',
    },
    [postMetricKeys.INSTAGRAM_STORY.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    [postMetricKeys.INSTAGRAM_STORY.EXITS]: {
      label: 'Exits',
      field: 'exits',
    },
    [postMetricKeys.INSTAGRAM_STORY.LINK_CLICKS]: {
      label: 'Link Clicks',
      field: 'swipe_ups',
    },
    [postMetricKeys.INSTAGRAM_STORY.REPLIES]: {
      label: 'Replies',
      field: 'replies',
    },
  },
  YOUTUBE: {
    [postMetricKeys.YOUTUBE.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.YOUTUBE.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'views',
    },
    [postMetricKeys.YOUTUBE.AVERAGE_VIEW_DURATION]: {
      label: 'Avg. View Duration',
      field: 'avg_view_duration',
    },
    [postMetricKeys.YOUTUBE.AVERAGE_PERCENTAGE_VIEWED]: {
      label: 'Avg. % Viewed',
      field: 'avg_view_percentage',
    },
    [postMetricKeys.YOUTUBE.WATCH_TIME]: {
      label: 'Watch Time',
      field: 'est_seconds_watched',
    },
    [postMetricKeys.YOUTUBE.SUBSCRIBERS]: {
      label: 'Subscribers',
      field: 'subscribers',
    },
    [postMetricKeys.YOUTUBE.TOTAL_ENGAGEMENTS]: {
      label: 'Total Engagements',
      field: 'engagements',
    },
    [postMetricKeys.YOUTUBE.CARD_CLICKS]: {
      label: 'Card Clicks',
      field: 'card_clicks',
    },
    [postMetricKeys.YOUTUBE.CARD_CLICK_RATE]: {
      label: 'Card Click Rate',
      field: 'card_click_rate',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_CLICKS]: {
      label: 'Card Teaser Clicks',
      field: 'card_teaser_clicks',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_CLICK_RATE]: {
      label: 'Card Teaser Click Rate',
      field: 'card_teaser_click_rate',
    },
    [postMetricKeys.YOUTUBE.CARD_TEASER_IMPRESSIONS]: {
      label: 'Card Teaser Impressions',
      field: 'card_teaser_impressions',
    },
    [postMetricKeys.YOUTUBE.PREMIUM_VIEWS]: {
      label: 'Premium Views',
      field: 'premium_views',
    },
    [postMetricKeys.YOUTUBE.PREMIUM_WATCH_TIME]: {
      label: 'Premium Watch Time',
      field: 'est_premium_seconds_watched',
    },
    [postMetricKeys.YOUTUBE.LIKES]: {
      label: 'Likes',
      field: 'likes',
    },
    [postMetricKeys.YOUTUBE.DISLIKES]: {
      label: 'Dislikes',
      field: 'dislikes',
    },
    [postMetricKeys.YOUTUBE.COMMENTS]: {
      label: 'Comments',
      field: 'comments',
    },
    [postMetricKeys.YOUTUBE.SHARES]: {
      label: 'Shares',
      field: 'shares',
    },
    [postMetricKeys.YOUTUBE.ADDED_TO_PLAYLIST]: {
      label: 'Added to Playlist',
      field: 'videos_added_to_playlist',
    },
  },
  PINTEREST: {
    [postMetricKeys.PINTEREST.DATE]: {
      label: 'Recently Published',
    },
    [postMetricKeys.PINTEREST.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'total_impressions',
    },
    [postMetricKeys.PINTEREST.ENGAGEMENT_RATE]: {
      label: 'Enagement Rate',
      field: 'engagement_rate',
    },
    [postMetricKeys.PINTEREST.SAVES]: {
      label: 'Saves',
      field: 'total_saves',
    },
    [postMetricKeys.PINTEREST.OUTBOUND_CLICKS]: {
      label: 'Outbound Clicks',
      field: 'total_clicks',
    },
    [postMetricKeys.PINTEREST.PIN_CLICKS]: {
      label: 'Pin Clicks',
      field: 'total_closeups',
    },
    [postMetricKeys.PINTEREST.VIDEO_VIEWS]: {
      label: 'Video Views',
      field: 'total_video_views',
    },
    [postMetricKeys.PINTEREST.AVERAGE_VIDEO_WATCH_TIME]: {
      label: 'Average Watch Time',
      field: 'average_video_watch_time',
    },
  },
  INSTAGRAM: {
    [postMetricKeys.INSTAGRAM.DATE]: { label: '' },
    [postMetricKeys.INSTAGRAM.ORGANIC_COMMENTS]: {
      label: 'Comments (Organic)',
      field: 'comments_count',
    },
    [postMetricKeys.INSTAGRAM.PAID_COMMENTS]: {
      label: 'Comments (Promoted)',
      field: 'paid_comments',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_COMMENTS]: {
      label: 'Comments (Total) ',
      field: 'total_comments',
    },
    [postMetricKeys.INSTAGRAM.EFFECTIVENESS]: { label: 'Effectiveness', field: 'effectiveness' },
    [postMetricKeys.INSTAGRAM.ENGAGEMENT_RATE]: { label: 'Engagement Rate', field: 'engagement' },
    [postMetricKeys.INSTAGRAM.ORGANIC_IMPRESSIONS]: {
      label: 'Impressions (Organic)',
      field: 'impressions',
    },
    [postMetricKeys.INSTAGRAM.PAID_IMPRESSIONS]: {
      label: 'Impressions (Promoted)',
      field: 'paid_impressions',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_IMPRESSIONS]: {
      label: 'Impressions (Total)',
      field: 'total_impressions',
    },
    [postMetricKeys.INSTAGRAM.ORGANIC_LIKES]: { label: 'Likes (Organic)', field: 'like_count' },
    [postMetricKeys.INSTAGRAM.PAID_LIKES]: { label: 'Likes (Promoted)', field: 'paid_likes' },
    [postMetricKeys.INSTAGRAM.TOTAL_LIKES]: { label: 'Likes (Total)', field: 'total_likes' },
    [postMetricKeys.INSTAGRAM.LIKESHOP_CLICKS]: {
      label: 'LikeShop Clicks',
      field: 'likeshop_clicks',
    },
    [postMetricKeys.INSTAGRAM.REACH]: { label: 'Reach (Organic)', field: 'reach' },
    [postMetricKeys.INSTAGRAM.SAVES]: { label: 'Saves (Organic)', field: 'saved' },
    [postMetricKeys.INSTAGRAM.TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Organic)',
      field: 'total_engagement',
    },
    [postMetricKeys.INSTAGRAM.PAID_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Promoted) ',
      field: 'paid_total_engagements',
    },
    [postMetricKeys.INSTAGRAM.SUM_TOTAL_ENGAGEMENTS]: {
      label: 'Engagements (Total)',
      field: 'sum_total_engagements',
    },
    [postMetricKeys.INSTAGRAM.VIDEO_VIEWS]: {
      label: 'Video Views (Organic)',
      field: 'video_views',
    },
    [postMetricKeys.INSTAGRAM.PAID_VIDEO_VIEWS]: {
      label: 'Video Views (Promoted)',
      field: 'paid_video_views',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_VIDEO_VIEWS]: {
      label: 'Video Views (Total)',
      field: 'total_video_views',
    },
  },
  UGC: {
    [postMetricKeys.UGC.DATE]: { field: '' },
    [postMetricKeys.UGC.ESTIMATED_REACH]: {
      label: 'Estimated Reach',
      field: 'reach',
    },
    [postMetricKeys.UGC.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement',
    },
    [postMetricKeys.UGC.ENGAGEMENTS]: {
      label: 'Total Engagements - Organic',
      field: 'total_engagement',
    },
    [postMetricKeys.INSTAGRAM.TOTAL_LIKES]: {
      label: 'Likes',
      field: 'total_likes',
    },
    [postMetricKeys.UGC.MENTIONED_YOU]: {
      label: 'Mentioned You',
      field: 'is_mentioned',
    },
    [postMetricKeys.UGC.TAGGED_YOU]: {
      label: 'Tagged You',
      field: 'is_tagged',
    },
    [postMetricKeys.UGC.REACH]: {
      label: 'Estimated Reach',
      field: 'reach',
    },
    [postMetricKeys.UGC.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'views',
    },
    [postMetricKeys.UGC.COMPLETION_RATE]: {
      label: 'Completion Rate',
      field: 'completion_rate',
    },
    [postMetricKeys.UGC.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exit_rate',
    },
    [postMetricKeys.UGC.EXITS]: {
      label: 'Exits',
      field: 'exits',
    },
  },
  RELATIONSHIP: {
    [postMetricKeys.RELATIONSHIP.ENGAGEMENT_RATE]: {
      label: 'Engagement Rate',
      field: 'engagement',
    },
    [postMetricKeys.RELATIONSHIP.EFFECTIVENESS]: {
      label: 'Effectiveness',
      field: 'effectiveness',
    },
    [postMetricKeys.RELATIONSHIP.FOLLOWERS_GAINED]: {
      label: 'Followers Gained',
      field: 'followersGained',
    },
    [postMetricKeys.RELATIONSHIP.EMV]: {
      label: 'EMV',
      field: 'emv',
    },
    [postMetricKeys.RELATIONSHIP.REACH]: {
      label: 'Reach',
      field: 'reach',
    },
    [postMetricKeys.RELATIONSHIP.IMPRESSIONS]: {
      label: 'Impressions',
      field: 'impressions',
    },
    [postMetricKeys.RELATIONSHIP.COMPLETTION_RATE]: {
      label: 'Completion Rate',
      field: 'completionRate',
    },
    [postMetricKeys.RELATIONSHIP.EXIT_RATE]: {
      label: 'Exit Rate',
      field: 'exitRate',
    },
  },
};

export const verticalMediaPanelCopy = {
  campaignsTopPerformingVideos: {
    title: 'Top Performing Videos - Owned',
    tooltip: 'Top performing videos across all channels',
    emptyMessage: 'No videos found during this reporting period',
  },
  campaignsLowestPerformingVideos: {
    title: 'Lowest Performing Videos - Owned',
    tooltip: 'Lowest performing videos across all channels',
    emptyMessage: 'No videos found during this reporting period',
  },
  campaignsTopPerformingPosts: {
    title: 'Top Performing Posts - Owned',
    tooltip: 'Top performing posts across all channels',
    emptyMessage: 'No posts found during this reporting period',
  },
  campaignsLowestPerformingPosts: {
    title: 'Lowest Performing Posts - Owned',
    tooltip: 'Lowest performing posts across all channels',
    emptyMessage: 'No posts found during this reporting period',
  },
};

export const videoViewsAliasMap = {
  INSTAGRAM: 'video_views',
  TIKTOK: 'views',
  FACEBOOK: 'video_views',
  PINTEREST: 'total_video_views',
  TWITTER: 'video_views',
  INSTAGRAM_STORY: 'views',
  YOUTUBE: 'views',
};

export const impressionsAliasMap = {
  INSTAGRAM: 'total_impressions',
  TIKTOK: 'views',
  FACEBOOK: 'impressions',
  PINTEREST: 'total_impressions',
  TWITTER: 'impressions_organic',
  INSTAGRAM_STORY: 'views',
};

export const engagementRateAliasMap = {
  INSTAGRAM: 'engagement',
  TIKTOK: 'engagement_rate',
  FACEBOOK: 'engagement_rate',
  PINTEREST: 'engagement_rate',
  TWITTER: 'engagement_rate_organic',
};

export const videoSortOptions = {
  CROSS_CHANNEL_VIDEO_VIEWS: 'CROSS_CHANNEL_VIDEO_VIEWS',
};

export const postsSortOptions = [
  {
    value: 'CROSS_CHANNEL_ENGAGEMENT_RATE',
    text: 'Engagement Rate',
    aliasMap: engagementRateAliasMap,
    format: 'percent',
  },
  {
    value: 'CROSS_CHANNEL_IMPRESSIONS',
    text: 'Impressions',
    aliasMap: impressionsAliasMap,
    format: 'number_with_commas',
  },
];

export const CHANNELS = Object.freeze({
  TIKTOK: 'TIKTOK',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  FACEBOOK: 'FACEBOOK',
  PINTEREST: 'PINTEREST',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
  RELATIONSHIPS: 'RELATIONSHIPS',
  UGC: 'UGC',
});

export const DATA_SOURCES = Object.freeze({
  TIKTOK_LIKESHOP: 'TIKTOK_LIKESHOP',
  LIKESHOP: 'LIKESHOP',
  ...CHANNELS,
});

export const overviewChannelStatsFormat = {
  number_of_media: {
    label: 'Number of Posts',
    tooltip: tooltips.topStatNumberOfPosts,
    channelsToOmit: [DATA_SOURCES.LIKESHOP, DATA_SOURCES.TIKTOK_LIKESHOP],
  },
  video_views: {
    label: 'Video Views',
    tooltip: tooltips.topStatVideoViews,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.INSTAGRAM_STORY,
      CHANNELS.UGC,
    ],
  },
  engagements: {
    label: 'Total Engagements',
    tooltip: tooltips.topStatTotalEngagements,
    channelsToOmit: [DATA_SOURCES.LIKESHOP, DATA_SOURCES.TIKTOK_LIKESHOP, CHANNELS.INSTAGRAM_STORY],
  },
  avg_engagement_rate: {
    label: 'Avg. Engagement Rate',
    tooltip: tooltips.topStatAvgEngagementRate,
    percent: true,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.INSTAGRAM_STORY,
      CHANNELS.YOUTUBE,
    ],
  },
  impressions: {
    label: 'Impressions',
    tooltip: tooltips.topStatImpressions,
    channelsToOmit: [
      DATA_SOURCES.LIKESHOP,
      DATA_SOURCES.TIKTOK_LIKESHOP,
      CHANNELS.TIKTOK,
      CHANNELS.YOUTUBE,
      CHANNELS.RELATIONSHIPS,
      CHANNELS.UGC,
    ],
  },
  link_clicks: {
    label: 'Clicks',
    tooltip: tooltips.topStatClicks,
    channelsToOmit: [
      CHANNELS.TIKTOK,
      CHANNELS.INSTAGRAM,
      CHANNELS.YOUTUBE,
      CHANNELS.RELATIONSHIPS,
      CHANNELS.UGC,
    ],
  },
};

export const graphMetrics = {
  POSTS: {
    value: 'NUMBER_OF_MEDIA',
    text: 'Posts',
  },
  NUMBER_OF_MEDIA: {
    value: 'NUMBER_OF_MEDIA',
    text: 'Posts',
  },
  NET_NEW_FOLLOWERS: {
    value: 'NET_NEW_FOLLOWERS',
    text: 'Net New Followers',
  },
  VIDEO_VIEWS: {
    value: 'VIDEO_VIEWS',
    text: 'Video Views',
  },
  TOTAL_VIDEO_VIEWS: {
    value: 'TOTAL_VIDEO_VIEWS',
    text: 'Video Views',
  },
  IMPRESSIONS: {
    value: 'IMPRESSIONS',
    text: 'Impressions',
  },
  LINK_CLICKS: {
    value: 'LINK_CLICKS',
    text: 'Link Clicks',
  },
  SUBSCRIBERS_GAINED: {
    value: 'SUBSCRIBERS_GAINED',
    text: 'Subscribers Gained',
  },
  SUBSCRIBERS_LOST: {
    value: 'SUBSCRIBERS_LOST',
    text: 'Subscribers Lost',
  },
};

export const postsDefaultSortOption = 'CROSS_CHANNEL_ENGAGEMENT_RATE';

export const campaignRouteNames = {
  CAMPAIGNS: 'campaigns',
  CAMPAIGNS_CREATE: 'campaigns.create',
  CAMPAIGN_DETAIL: 'campaigns.detail',
  CAMPAIGN_DETAIL_EDIT: 'campaigns.detail.edit',
  CAMPAIGN_DETAIL_DELETE: 'campaigns.detail.delete',
  CAMPAIGN_DETAIL_OVERVIEW: 'campaigns.detail.overview',
  CAMPAIGN_DETAIL_TIKTOK: 'campaigns.detail.tiktok',
  CAMPAIGN_DETAIL_INSTAGRAM: 'campaigns.detail.instagram',
  CAMPAIGN_DETAIL_INSTAGRAM_STORIES: 'campaigns.detail.instagram-stories',
  CAMPAIGN_DETAIL_FACEBOOK: 'campaigns.detail.facebook',
  CAMPAIGN_DETAIL_PINTEREST: 'campaigns.detail.pinterest',
  CAMPAIGN_DETAIL_TWITTER: 'campaigns.detail.twitter',
  CAMPAIGN_DETAIL_YOUTUBE: 'campaigns.detail.youtube',
  CAMPAIGN_DETAIL_ECOMM: 'campaigns.detail.ecomm',
  CAMPAIGN_DETAIL_ECOMM_REPORT: 'campaigns.detail.ecomm.report',
  CAMPAIGN_DETAIL_RELATIONSHIPS: 'campaigns.detail.relationships',
  CAMPAIGN_DETAIL_UGC: 'campaigns.detail.ugc',
  CAMPAIGN_DETAIL_CREATOR_INSIGHTS: 'campaigns.detail.creator-insights',
  CAMPAIGN_DETAIL_CREATOR_DELIVERABLES: 'campaigns.detail.creator-deliverables',
  CAMPAIGN_DETAIL_REPORT: 'campaigns.detail.report',
  CAMPAIGN_DETAIL_OVERVIEW_REPORT: 'campaigns.detail.overview.report',
  CAMPAIGN_DETAIL_TIKTOK_REPORT: 'campaigns.detail.tiktok.report',
  CAMPAIGN_DETAIL_INSTAGRAM_REPORT: 'campaigns.detail.instagram.report',
  CAMPAIGN_DETAIL_INSTAGRAM_STORIES_REPORT: 'campaigns.detail.instagram-stories.report',
  CAMPAIGN_DETAIL_FACEBOOK_REPORT: 'campaigns.detail.facebook.report',
  CAMPAIGN_DETAIL_PINTEREST_REPORT: 'campaigns.detail.pinterest.report',
  CAMPAIGN_DETAIL_TWITTER_REPORT: 'campaigns.detail.twitter.report',
  CAMPAIGN_DETAIL_YOUTUBE_REPORT: 'campaigns.detail.youtube.report',
  CAMPAIGN_DETAIL_RELATIONSHIPS_REPORT: 'campaigns.detail.relationships.report',
  CAMPAIGN_DETAIL_UGC_REPORT: 'campaigns.detail.ugc.report',
  CAMPAIGN_DETAIL_CREATOR_INSIGHTS_REPORT: 'campaigns.detail.creator-insights.report',
  CAMPAIGN_DETAIL_CREATOR_DELIVERABLES_REPORT: 'campaigns.detail.creator-deliverables.report',
  CAMPAIGN_DETAIL_ALL_TABS_REPORT: 'campaigns.detail.all.report',
  COMPARISONS: 'comparisons',
  CAMPAIGNS_COMPARISONS: 'campaigns.comparisons',
  CAMPAIGNS_COMPARISONS_CREATE: 'campaigns.comparisons.create',
  CAMPAIGNS_COMPARISON_DETAIL: 'campaigns.comparison.detail',
};

export const campaignScaleOptions = [
  { label: 'By Day', value: timeScaleTypes.DAILY },
  { label: 'By Month', value: timeScaleTypes.MONTHLY },
];

export const campaignsMenuDropdownList = [
  { text: 'Edit', action: 'editClicked' },
  { text: 'Delete', action: 'deleteClicked', extraClass: 'danger' },
];

export const mixpanelEventProperties = Object.freeze({
  reportTypes: {
    keyword: 'Keyword',
    sentiment: 'Sentiment',
    channel: 'Channel',
  },
  interactionType: {
    labelClicked: 'Label Clicked',
    keywordClicked: 'Keyword Clicked',
    keywordCheckboxUpdated: 'Keyword Checkbox Updated',
  },
  metricTypes: {
    sentiment: 'Campaign sentiment',
  },
});

export const NO_DATA_TOP_KEYWORDS_ERROR_MESSAGE =
  'No top keywords found during this reporting period';

export const CAMPAIGN_TOP_KEYWORDS_TOOLTIP =
  'The top keywords used in the comments on owned posts in your campaign during the reporting period';

export const CAMPAIGN_SENTIMENT_CHANNEL_DISTRIBUTION_TOOLTIP =
  'The channel and sentiment breakdown of comments on owned posts in your campaign during the reporting period';

export const CAMPAIGN_SENTIMENT_BAR_CHART_CONFIG = {
  positive: {
    label: 'Positive',
    backgroundColor: colours.SUCCESS.SUCCESS_500,
    order: 1,
  },
  neutral: {
    label: 'Neutral',
    backgroundColor: colours.ACTION.ACTION_500,
    order: 2,
  },
  negative: {
    label: 'Negative',
    backgroundColor: colours.ERROR.ERROR_500,
    order: 3,
  },
};

export const CAMPAIGN_SENTIMENT_CHANNEL_DISTRIBUTION_PLATFORMS = [
  CHANNELS.TIKTOK,
  CHANNELS.INSTAGRAM,
  CHANNELS.FACEBOOK,
  CHANNELS.TWITTER,
  CHANNELS.YOUTUBE,
];

export const FAILED_TO_LOAD_CAMPAIGN_SENTIMENT_ERROR_MESSAGE =
  'Data failed to load. Refresh the page to try again';

export const CAMPAIGN_SENTIMENT_OVERVIEW_NO_DATA_MESSAGE =
  'No data found during this reporting period';

export const CAMPAIGN_SENTIMENT_PLATFORM_LABELS = {
  [CHANNELS.TIKTOK]: 'TikTok',
  [CHANNELS.INSTAGRAM]: 'Instagram',
  [CHANNELS.FACEBOOK]: 'Facebook',
  [CHANNELS.TWITTER]: 'X',
  [CHANNELS.YOUTUBE]: 'YouTube',
};

export const DOTTED_STATS_UP_TO = 1;

export const campaignCreatorStatusOptions = [
  { label: 'New', value: 'NEW', severity: 'statusNeutral' },
  { label: 'Contacted', value: 'CONTACTED', severity: 'statusNeutral' },
  { label: 'Agreement Sent', value: 'AGREEMENT_SENT', severity: 'info' },
  { label: 'Brief Sent', value: 'BRIEF_SENT', severity: 'info' },
  { label: 'Product Shipped', value: 'PRODUCT_SHIPPED', severity: 'info' },
  { label: 'In Progress', value: 'IN_PROGRESS', severity: 'info' },
  { label: 'Creative Approved', value: 'CREATIVE_APPROVED', severity: 'success' },
];

export const limitedCampaignCreatorStatusOptions = [
  { label: 'New', value: 'NEW', severity: 'statusNeutral' },
  { label: 'Contacted', value: 'CONTACTED', severity: 'statusNeutral' },
  { label: 'Product Shipped', value: 'PRODUCT_SHIPPED', severity: 'info' },
  { label: 'Complete', value: 'COMPLETE', severity: 'success' },
  { label: 'Declined', value: 'DECLINED', severity: 'negative' },
];

export const campaignCreatorPostTypeOptions = [
  { label: 'TikTok Videos', value: 'TIKTOK_VIDEO', icon: 'tiktok-color' },
  { label: 'Instagram Feed', value: 'INSTAGRAM_FEED', icon: 'instagram-color' },
  { label: 'Instagram Reels', value: 'INSTAGRAM_REELS', icon: 'instagram-reel-color' },
  { label: 'Instagram Stories', value: 'INSTAGRAM_STORIES', icon: 'instagram-story-color' },
];

export const campaignCreatorChannelOptions = [
  { label: 'TikTok', value: 'TIKTOK', icon: 'tiktok-color' },
  { label: 'Instagram', value: 'INSTAGRAM', icon: 'instagram-color' },
];

export const campaignCreatorDeliverableFilterDetails = {
  scope: 'creatorDeliverables',
  options: {
    channel: campaignCreatorChannelOptions,
    postType: campaignCreatorPostTypeOptions,
    status: campaignCreatorStatusOptions,
  },
  default: {
    channel: [],
    postType: [],
    tags: [],
    status: [],
  },
};

export const campaignCreatorDeliverableTopLineStats = [
  {
    title: 'Creators',
    value: 'totalCreators',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of creators added to this Campaign, including gifted and paid',
  },
  {
    title: 'Deliverables',
    value: 'totalDeliverables',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of expected creator posts as part of this Campaign',
  },
  {
    title: 'Gifted',
    value: 'totalGifted',
    format: 'number',
    type: 'Total',
    tooltip:
      'The total number of unique creators that were gifted product as part of this Campaign',
  },
  {
    title: 'Posts',
    value: 'totalPosts',
    format: 'number',
    type: 'Total',
    tooltip: 'The total number of published posts by creators as part of this Campaign',
  },
  {
    title: 'Completion',
    value: 'completion',
    format: 'percent',
    type: 'Average',
    tooltip:
      'The percentage of posts that have been published, based on the expected number of deliverables.\n\nCompletion = Number of Posts / Deliverables',
  },
];
