import { BRAND, USER } from '@/models/auth/permissions.enum';
import { PRODUCT_MAP } from '@/models/auth/customer-plan.enum';
import { COMPETITIVE_OVERVIEW_MULTI_BRAND } from './layouts/competitive-overview-multi-brand.enum';
import { FACEBOOK_OVERVIEW_MULTI_BRAND } from './layouts/facebook-overview-multi-brand.enum';
import { FACEBOOK_OVERVIEW_SINGLE_BRAND } from './layouts/facebook-overview-single-brand.enum';
import { INSTAGRAM_OVERVIEW_MULTI_BRAND } from './layouts/instagram-overview-multi-brand.enum';
import { INSTAGRAM_OVERVIEW_SINGLE_BRAND } from './layouts/instagram-overview-single-brand.enum';
import { PINTEREST_OVERVIEW_MULTI_BRAND } from './layouts/pinterest-overview-multi-brand.enum';
import { PINTEREST_OVERVIEW_SINGLE_BRAND } from './layouts/pinterest-overview-single-brand.enum';
import { SOCIAL_OVERVIEW_SINGLE_BRAND } from './layouts/social-overview-single-brand.enum';
import { SOCIAL_OVERVIEW_SINGLE_BRAND_OLD } from './layouts/social-overview-single-brand-old.enum';
import { TIKTOK_OVERVIEW_MULTI_BRAND } from './layouts/tiktok-overview-multi-brand.enum';
import { TIKTOK_OVERVIEW_SINGLE_BRAND } from './layouts/tiktok-overview-single-brand.enum';
import { TWITTER_OVERVIEW_MULTI_BRAND } from './layouts/twitter-overview-multi-brand.enum';
import { TWITTER_OVERVIEW_SINGLE_BRAND } from './layouts/twitter-overview-single-brand.enum';
import { BRAND_HEALTH_SINGLE_BRAND } from './layouts/brand-health-single-brand.enum';
import { BRAND_HEALTH_MULTI_BRAND } from './layouts/brand-health-multi-brand.enum';
import { YOUTUBE_OVERVIEW_SINGLE_BRAND } from './layouts/youtube-overview-single-brand.enum';
import { YOUTUBE_OVERVIEW_MULTI_BRAND } from './layouts/youtube-overview-multi-brand.enum';
import { CHILI_PIPER_FORMS_MAP } from '../../settings/constants';
import { LINKEDIN_OVERVIEW_MULTI_BRAND } from './layouts/linkedin-overview-multi-brand.enum';
import { LINKEDIN_OVERVIEW_SINGLE_BRAND } from './layouts/linkedin-overview-single-brand.enum';

export const TEMPLATES = Object.freeze({
  blank: {
    id: 'blank',
    name: 'Blank Dashboard',
    description:
      'Build your Dashboard from scratch. Add the metrics, channels, and brands that matter most to your team.',
    buttonName: 'Blank Dashboard',
    cardImage: 'img/dashboards/template-card-blank.svg',
    previewImage: 'img/dashboards/template-preview-blank.png',
  },
  socialOverview: {
    id: 'socialOverview',
    name: '360 Social Overview',
    description: 'Get a holistic view of your social strategy across your most important channels.',
    buttonName: '360 Social Overview',
    cardImage: 'img/dashboards/template-card-socialoverview.png',
    previewImage: 'img/dashboards/template-preview-360.png',
    layouts: {
      singleBrand: SOCIAL_OVERVIEW_SINGLE_BRAND,
    },
    requiredFlag: 'linkedinDashboards',
  },
  socialOverviewOld: {
    id: 'socialOverview',
    name: '360 Social Overview',
    description: 'Get a holistic view of your social strategy across your most important channels.',
    buttonName: '360 Social Overview',
    cardImage: 'img/dashboards/template-card-socialoverview.png',
    previewImage: 'img/dashboards/template-preview-360-old.png',
    layouts: {
      singleBrand: SOCIAL_OVERVIEW_SINGLE_BRAND_OLD,
    },
    hiddenRequiredFlag: 'linkedinDashboards',
  },
  competitiveOverview: {
    id: 'competitiveOverview',
    name: 'Competitive Overview',
    description:
      'Track your performance against key competitors and the average of your competitive set.',
    buttonName: 'Competitive',
    cardImage: 'img/dashboards/template-card-compoverview.png',
    previewImage: 'img/dashboards/template-preview-compoverview.png',
    createdAt: 'May 10 2022 00:00:01 UTC',
    layouts: {
      multiBrand: COMPETITIVE_OVERVIEW_MULTI_BRAND,
    },
    requiredPermissions: [BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS],
    showUpgrade: true,
    userPermissions: [USER.COMPETITIVE.CAN_ACCESS_COMPETITORS],
    requiredFlagForUserPermission: 'hideCompetitiveReports',
    brandDisabledTooltip: 'This template is not included in this brand’s plan.',
  },
  brandHealth: {
    id: 'brandHealth',
    name: 'Brand Health',
    description:
      'Measure how your posts are resonating with your audience by tracking overall sentiment and engagement.',
    buttonName: 'Brand Health',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-brandhealth.png',
    createdAt: 'April 24 2023 00:00:01 UTC',
    layouts: {
      singleBrand: BRAND_HEALTH_SINGLE_BRAND,
      multiBrand: BRAND_HEALTH_MULTI_BRAND,
    },
    iconOverrideList: [{ value: 'brandhealth', competitive: false }],
    requiredPermissions: [BRAND.GENERAL.CAN_ACCESS_SENTIMENT],
    brandDisabledTooltip: 'This template is not included in this brand’s plan.',
    showUpgrade: true,
  },
  tikTokOverview: {
    id: 'tikTokOverview',
    name: 'TikTok Overview',
    description:
      'Measure your most important TikTok metrics and view your content performance across brands.',
    buttonName: 'TikTok Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-tiktok.png',
    previewImageOld: 'img/dashboards/template-preview-tiktok-old.png',
    layouts: {
      singleBrand: TIKTOK_OVERVIEW_SINGLE_BRAND,
      multiBrand: TIKTOK_OVERVIEW_MULTI_BRAND,
    },
  },
  instagramOverview: {
    id: 'instagramOverview',
    name: 'Instagram Overview',
    description:
      'Measure your most important Instagram metrics and view your content performance across brands.',
    buttonName: 'Instagram Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-instagram.png',
    layouts: {
      singleBrand: INSTAGRAM_OVERVIEW_SINGLE_BRAND,
      multiBrand: INSTAGRAM_OVERVIEW_MULTI_BRAND,
    },
  },
  facebookOverview: {
    id: 'facebookOverview',
    name: 'Facebook Overview',
    description:
      'Measure your most important Facebook metrics and view your content performance across brands.',
    buttonName: 'Facebook Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-faceboook.png',
    layouts: {
      singleBrand: FACEBOOK_OVERVIEW_SINGLE_BRAND,
      multiBrand: FACEBOOK_OVERVIEW_MULTI_BRAND,
    },
  },
  pinterestOverview: {
    id: 'pinterestOverview',
    name: 'Pinterest Overview',
    description:
      'Measure your most important Pinterest metrics and view your content performance across brands.',
    buttonName: 'Pinterest Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-pinterest.png',
    layouts: {
      singleBrand: PINTEREST_OVERVIEW_SINGLE_BRAND,
      multiBrand: PINTEREST_OVERVIEW_MULTI_BRAND,
    },
  },
  twitterOverview: {
    id: 'twitterOverview',
    name: 'X Overview',
    description:
      'Measure your most important X metrics and view your content performance across brands.',
    buttonName: 'X Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-twitter.png',
    layouts: {
      singleBrand: TWITTER_OVERVIEW_SINGLE_BRAND,
      multiBrand: TWITTER_OVERVIEW_MULTI_BRAND,
    },
  },
  youtubeOverview: {
    id: 'youtubeOverview',
    name: 'YouTube Overview',
    description:
      'Measure your most important YouTube metrics and view your content performance across brands.',
    buttonName: 'YouTube Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-youtube.png',
    createdAt: 'May 17 2023 00:00:01 UTC',
    layouts: {
      singleBrand: YOUTUBE_OVERVIEW_SINGLE_BRAND,
      multiBrand: YOUTUBE_OVERVIEW_MULTI_BRAND,
    },
  },
  linkedinOverview: {
    id: 'linkedinOverview',
    name: 'LinkedIn Overview',
    description:
      'Measure your most important LinkedIn metrics and view your content performance across brands.',
    buttonName: 'LinkedIn Overview',
    cardImage: 'img/dashboards/template-card-channel.svg',
    previewImage: 'img/dashboards/template-preview-linkedin.png',
    createdAt: 'June 3 2024 00:00:01 UTC',
    layouts: {
      singleBrand: LINKEDIN_OVERVIEW_SINGLE_BRAND,
      multiBrand: LINKEDIN_OVERVIEW_MULTI_BRAND,
    },
    requiredFlag: 'linkedinDashboards',
  },
});

export const TEMPLATE_UPGRADE_MAP = Object.freeze({
  brandHealth: {
    title: 'Available With <br/>Social Listening',
    description:
      'Check the pulse of your brand across channels with the Brand Health template. Available with the Social Listening add-on.',
    buttonNameOld: 'Start Free Trial',
    // #TODO: To Be Removed and renamed with feature flag removal ticket https://app.shortcut.com/dashhudson/story/120622/remove-feature-flag
    buttonName: 'Try Social Listening',
    walnutTourLink:
      'https://www.dashhudson.com/interactive-demo/social-listening-product-demo?utm_medium=referral&utm_source=dh_platform&utm_campaign=sl_upgrade',
    chiliPiper: CHILI_PIPER_FORMS_MAP.socialListening.contact,
    product: PRODUCT_MAP.SOCIAL_LISTENING,
  },
  competitiveOverview: {
    title: 'Competitive Reporting Available In Premium Analytics',
    description:
      'Easily benchmark and compare your performance against your competition with Competitive Dashboards. Available with Premium Analytics add-on.',
    buttonNameOld: 'Start Free Trial',
    // #TODO: To Be Removed and renamed with feature flag removal ticket https://app.shortcut.com/dashhudson/story/120622/remove-feature-flag
    buttonName: 'Try Competitive',
    chiliPiper: CHILI_PIPER_FORMS_MAP.premiumAnalytics.competitors,
    product: PRODUCT_MAP.PREMIUM_ANALYTICS,
  },
});
