import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import dayjs from 'dayjs';

export const parseTimestamps = (item) =>
  fromPairs(
    toPairs(item).map(([key, value]) => [
      key,
      key.match(/At$/) && value ? dayjs.utc(value) : value,
    ]),
  );
