/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M13.707 8.707L8 14.414 2.293 8.707l1.414-1.414L7 10.586V0h2v10.586l3.293-3.293 1.414 1.414zM14 16h2v2c0 1.102-.896 2-2 2H2c-1.104 0-2-.898-2-2v-2h2v2h12v-2z" _fill="#686a7a" fill-rule="evenodd"/>'
  }
})
