/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'lock-unlock-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g transform="translate(5 2)" _fill="#686a7a" fill-rule="nonzero"><path pid="0" d="M12 9H4V5c0-1.654 1.346-3 3-3s3 1.346 3 3v1h2V5c0-2.757-2.243-5-5-5S2 2.243 2 5v4a2 2 0 00-2 2v7c0 1.103.896 2 2 2h10c1.104 0 2-.897 2-2v-7a2 2 0 00-2-2zM2 18v-7h10l.002 7H2z"/><circle pid="1" cx="7" cy="14.5" r="1.5"/></g>'
  }
})
