<script setup>
import { stripHtmlFromMessage } from '@/components/VisionAi/utils/message';
import UserWrapper from '@/components/VisionAi/UserWrapper.vue';
import Icon from '@/components/foundation/Icon.vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import { aiSummaryLaunchEvent } from '@/components/VisionAi/utils/mixpanel-events';
import {
  VISION_AI_SUMMARY_LAUNCH_TYPE,
  DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE,
  SUMMARY_INTENT_TYPES,
  DEFAULT_METRIC_AND_ANALYTICS_PROMPTS,
  VISION_AI_MOCK_PROMPT_ROUTES,
} from '@/components/VisionAi/constants';
import { useIntentMap } from '@/components/VisionAi/composables/useIntentMap';
import { computed } from 'vue';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { useRoute } from 'vue-router';

const visionAiStore = useVisionAiStore();
const visionAiConversationsStore = useVisionAiConversationsStore();
const route = useRoute();

const { checkIfInvalidThreshold } = useIntentMap();

const props = defineProps({
  promptMessage: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: null,
  },
});

const tooltipProps = computed(() => {
  if (props.disabled) return null;

  return {
    content: props.tooltip,
    theme: 'dh-tooltip-center',
  };
});

async function handleSummaryPromptButtonClicked(isMockPrompt) {
  visionAiConversationsStore.selectSummaries();
  visionAiStore.clearLocalChatHistory();
  await visionAiStore.getMessages({ intentTypes: SUMMARY_INTENT_TYPES });

  if (checkIfInvalidThreshold(visionAiStore.currentIntentType) || isMockPrompt) {
    await visionAiStore.createMockMessages({ isMockPrompt });
  } else {
    await visionAiStore.startConversation({
      message: props.promptMessage,
    });
  }
  aiSummaryLaunchEvent({
    launchedFrom: VISION_AI_SUMMARY_LAUNCH_TYPE.PROMPT,
    intentType: visionAiStore.currentIntentType,
  });
}

const isSummaryPrompt = computed(() => {
  return Object.values(DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE).includes(props.promptMessage);
});

async function handlePromptButtonClicked() {
  if (props.disabled) return;

  let isMockPrompt = false;

  isMockPrompt =
    VISION_AI_MOCK_PROMPT_ROUTES.includes(route?.name) &&
    !DEFAULT_METRIC_AND_ANALYTICS_PROMPTS.includes(props.promptMessage);

  if (isSummaryPrompt.value || isMockPrompt) {
    await handleSummaryPromptButtonClicked(isMockPrompt);
    return;
  }

  visionAiConversationsStore.setSummariesSelectedValue(false);
  await visionAiStore.startConversation({
    message: props.promptMessage,
  });
}

const summaryPromptReady = computed(() => {
  return isSummaryPrompt.value && !checkIfInvalidThreshold(visionAiStore.currentIntentType);
});
</script>

<template>
  <UserWrapper
    v-if="props.promptMessage"
    v-tooltip.top="tooltipProps"
    :summary-ready="summaryPromptReady"
    :is-disabled="disabled"
    @user-button-clicked="handlePromptButtonClicked"
  >
    <div
      class="flex h-[--space-18] w-[--space-18] items-center justify-center rounded-3xl bg-[--brand-primary] p-[--space-3]"
    >
      <Icon name="prompt-icon" :color="'white'" />
    </div>

    <span>{{ stripHtmlFromMessage(props.promptMessage) }}</span>
  </UserWrapper>
</template>
