<template>
  <div
    ref="container"
    v-drag-move-2="dragMoveParams"
    class="product-tagger"
    @click.left="handleTaggerClick"
  >
    <template v-if="containerRendered && !transitioning">
      <MediaTag
        v-for="{ productTag, index } in sortedProductTagsAndIndex"
        :key="productTag.fbProductId || 'dot'"
        :boundary="boundary"
        :title="productTag.title"
        :style="{ left: ratioToOffset(productTag.x, 'x'), top: ratioToOffset(productTag.y, 'y') }"
        :data-tag-index="index"
        class="draggable"
        @delete-tag="facebookProductTaggerStore.deleteFacebookProductTag({ index })"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import clamp from 'lodash/clamp';
import { mapStores } from 'pinia';
import MediaTag from '@/app/scheduler/components/EditPost/MediaTag.vue';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'FacebookProductTagger',
  components: { MediaTag },
  props: {
    carouselActiveMedia: { type: Object, default: null },
    transitioning: { type: Boolean, default: false },
  },
  data() {
    return {
      containerRendered: false,
      dragMoveParams: {
        draggableClass: 'draggable',
        onDragStarted: this.handleDragStarted,
        onDragFinished: this.handleDragFinished,
      },
      tagRenderPriority: 0,
    };
  },
  computed: {
    ...mapStores(useFacebookProductTaggerStore),
    boundary() {
      return this.$el.closest('.media-container');
    },
    sortedProductTagsAndIndex() {
      return Array.from(
        this.facebookProductTaggerStore.activeMediaTaggedProducts,
        (productTag, index) => {
          return { productTag, index };
        },
      ).sort((a, b) => (a.productTag.renderPriority ?? 0) - (b.productTag.renderPriority ?? 0));
    },
  },
  watch: {
    carouselActiveMedia: {
      handler(newVal, oldVal) {
        if (oldVal && this.facebookProductTaggerStore.editingFacebookProductTag) {
          this.facebookProductTaggerStore.deleteFacebookProductTag({ mediaId: oldVal.id });
          this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: false });
        }
        this.facebookProductTaggerStore.setActiveMediaId({ mediaId: newVal.id });
      },
      immediate: true,
    },
  },
  mounted() {
    this.containerRendered = true;
  },
  methods: {
    getAxisLength(axis) {
      return axis === 'x' ? this.$refs.container.clientWidth : this.$refs.container.clientHeight;
    },
    ratioToOffset(ratio, axis) {
      const offset = Math.round(ratio * this.getAxisLength(axis));
      return `${offset}px`;
    },
    offsetToRatio(offset, axis) {
      const ratio = offset / this.getAxisLength(axis);
      return clamp(ratio, 0, 1);
    },
    handleTaggerClick(event) {
      if (
        event.target.classList.contains('product-tagger') &&
        !this.facebookProductTaggerStore.editingFacebookProductTag
      ) {
        this.facebookProductTaggerStore.addFacebookProductTag({
          productTag: {
            fbProductId: null,
            title: null,
            x: this.offsetToRatio(event.offsetX, 'x'),
            y: this.offsetToRatio(event.offsetY, 'y'),
          },
        });
        this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: true });
      }
    },
    handleDragStarted(target) {
      const index = parseInt(target.dataset.tagIndex, 10);
      this.tagRenderPriority += 1;
      this.facebookProductTaggerStore.updateFacebookProductTag({
        index,
        productTag: { renderPriority: this.tagRenderPriority },
      });
    },
    handleDragFinished(target, x, y) {
      const xRatio = this.offsetToRatio(x, 'x');
      const yRatio = this.offsetToRatio(y, 'y');

      const index = parseInt(target.dataset.tagIndex, 10);
      const taggedProduct = this.facebookProductTaggerStore.activeMediaTaggedProducts[index];

      // if the tag coordinates have changed, update it
      if (taggedProduct.x !== xRatio || taggedProduct.y !== yRatio) {
        this.facebookProductTaggerStore.updateFacebookProductTag({
          index,
          productTag: { x: xRatio, y: yRatio },
        });
      }
      // if target is the editing dot and hasn't changed coordinates, remove it
      else if (target.getElementsByClassName('dot').length !== 0) {
        this.facebookProductTaggerStore.deleteFacebookProductTag({ index });
        this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: false });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.product-tagger {
  height: 100%;
  position: relative;
  cursor: pointer;
}

.draggable {
  position: absolute;
  white-space: nowrap;
}
</style>
