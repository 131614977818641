<template>
  <div
    v-tooltip.bottom-start="tooltipConfig"
    class="dh-avatar"
    :class="avatarClasses"
    :style="avatarStyles"
  >
    <slot>
      <template v-if="imgSrc && !imageError">
        <img
          class="dh-avatar-image"
          :src="imgSrc"
          :style="avatarStyles"
          @load="imageLoaded = true"
          @error="imageError = true"
        />
      </template>
      <template v-else>
        <Icon v-if="!loading" name="user" class="user-icon" width="50%" height="50%" />
      </template>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const SIZES = {
  xxxsmall: {
    size: '1rem',
    fontSize: '0.375rem',
  },
  xxsmall: {
    size: '1.25rem',
    fontSize: '0.375rem',
  },
  xsmall: {
    size: '1.5rem',
    fontSize: '0.5rem',
  },
  small: {
    size: '2rem',
    fontSize: '0.625rem',
  },
  medium: {
    size: '2.5rem',
    fontSize: '0.75rem',
  },
  large: {
    size: '3rem',
    fontSize: '1rem',
  },
  xlarge: {
    size: '4rem',
    fontSize: '1.5rem',
  },
  xxlarge: {
    size: '9.375rem',
    fontSize: '3rem',
  },
};

/**
 * Displays a circular avatar.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    Icon,
  },
  props: {
    /**
     * Image source to display as the avatar.
     */
    imgSrc: {
      type: String,
      default: null,
    },
    /**
     * Puts avatar into loading mode.  Shows skeleton loader animation and disables tooltips.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Text to display as a tooltip on mouseover.
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * Set background color of the avatar.
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * Sets size of the avatar to xxxsmall.
     */
    xxxsmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to xxsmall.
     */
    xxsmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to xsmall.
     */
    xsmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to small.
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to large.
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to xlarge.
     */
    xlarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets size of the avatar to xxlarge.
     */
    xxlarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Displays avatar with rounded borders.
     */
    square: {
      type: Boolean,
      default: false,
    },
    /**
     * Apply disabled styling to the avatar.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Control skeleton loader animation
     */
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imageLoaded: false,
      imageError: false,
    };
  },
  computed: {
    tooltipConfig() {
      return {
        content: this.loading || this.disabled ? '' : this.tooltip,
        theme: 'dh-tooltip-medium',
      };
    },
    avatarClasses() {
      return {
        square: this.square,
        'skeleton-loading': this.loading && this.animate,
        disabled: this.disabled,
      };
    },
    avatarStyles() {
      const styleName = [
        'xxxsmall',
        'xxsmall',
        'xsmall',
        'small',
        'large',
        'xlarge',
        'xxlarge',
      ].find((size) => this[size]);
      const style = SIZES[styleName || 'medium'];
      const styles = {
        width: style.size,
        height: style.size,
        minWidth: style.size,
        minHeight: style.size,
        fontSize: style.fontSize,
        backgroundColor: this.color,
      };
      if (this.loading) {
        styles.border = 'none';
      }
      return styles;
    },
    emptyIconArgs() {
      return { [this.small ? 'xxsmall' : 'xsmall']: true };
    },
  },
  watch: {
    imgSrc() {
      this.imageLoaded = false;
      this.imageError = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--background-400);
  border: 1px solid var(--border);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  font-size: var(--x12);
  font-weight: var(--font-medium);

  &.square {
    border-radius: 15%;
  }

  .dh-avatar-image {
    object-fit: cover;
  }

  &.disabled {
    opacity: 0.3;
  }

  .user-icon {
    max-width: var(--space-20);
    fill: var(--text-primary);
  }
}
</style>
