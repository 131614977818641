<template>
  <section class="preview">
    <section class="head-control">
      <div class="left-control">
        <h4>Preview</h4>
        <a v-if="previewLink" :href="previewLink" target="_blank" rel="noopener">
          Open in new window
        </a>
      </div>
      <div :class="{ disabled: disabled }" class="right-control">
        <span>
          Column:
          <span class="figure-blue">
            {{ shoppableSettingsForm.column }}
          </span>
        </span>
        <span>
          Gap:
          <span class="figure-blue">
            {{ shoppableSettingsForm.gap }}
          </span>
        </span>
        <span v-show="shoppableSettingsForm.viewType === 'gallery'">
          Row:
          <span class="figure-blue">
            {{ shoppableSettingsForm.row }}
          </span>
        </span>
      </div>
    </section>
    <slot>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-show="!disabled && !galleryStore.pending.gallery"
        ref="preview"
        class="preview-box"
        v-html="previewHtmlCode"
      />
      <!-- eslint-enable vue/no-v-html -->
    </slot>
    <CircularLoader v-if="galleryStore.pending.gallery" />
    <div v-if="!galleryStore.pending.gallery && disabled" class="placeholder disabled">
      <h4>Preview is Unavailable</h4>
      <p>To see the preview, turn on "Enable Shoppable Gallery" toggle.</p>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { env } from '@/env';
import { useGalleryStore } from '@/stores/gallery';
import CircularLoader from '@/components/CircularLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Preview',
  components: { CircularLoader },
  props: {
    shoppableSettingsForm: { type: Object, default: null },
    code: { type: String, default: null },
    appendWidget: { type: Boolean, default: true },
    previewLink: { type: String, default: null },
    reload: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapStores(useGalleryStore),
    previewHtmlCode() {
      if (this.disabled || this.galleryStore.pending.gallery) {
        return null;
      }
      return this.code;
    },
  },
  watch: {
    previewHtmlCode() {
      this.reloadScript();
    },
  },
  mounted() {
    this.reloadScript();
  },
  methods: {
    /* eslint-disable */
    reloadScript() {
      if (!this.reload || !this.appendWidget) {
        return;
      }
      const form = this.shoppableSettingsForm;
      const callToActionScript = form.callToAction === '-' ? null : `${form.callToAction}`;
      const source = form.viewType === 'gallery' ? env.galleryEmbedUrl : env.carouselEmbedUrl;
      const dataName = form.viewType === 'gallery' ? 'dhboard' : 'dhboard-carousel';

      const previewWidget = document.createElement('script');
      previewWidget.setAttribute('src', source);
      previewWidget.setAttribute('type', 'text/javascript');
      previewWidget.setAttribute('data-name', dataName);
      previewWidget.setAttribute('id', 'preview-widget');
      previewWidget.setAttribute('data-gallery-id', this.galleryStore.gallery.id);
      previewWidget.setAttribute('data-row-size', form.column);
      previewWidget.setAttribute('data-mobile-row-size', form.mobileColumn);
      previewWidget.setAttribute('data-mobile-gap-size', form.mobileGap);

      if (!form.customLinks) {
        previewWidget.setAttribute('data-links-disabled', 'true');
      }
      callToActionScript
        ? previewWidget.setAttribute('data-call-to-action', callToActionScript)
        : null;

      if (form.viewType === 'gallery') {
        // for Gallery Preview
        previewWidget.setAttribute('data-gap-size', form.gap);
        previewWidget.setAttribute('data-row-limit', form.row);
      } else {
        // for Carousel Preview
        form.scroll === 'hide' ? previewWidget.setAttribute('data-scroll-disabled', 'true') : null;
        form.autoplay === 'on' ? previewWidget.setAttribute('data-autoplay', 'true') : null;
      }

      const previewContainer = this.$el.querySelector('.preview-box');
      const oldWidget = document.querySelector('.preview-box .dh-widget-container');
      const oldScript = document.getElementById('preview-widget');

      if (oldScript) {
        // If the user updates the widget, we remove the old script and widget before creating a new one
        oldScript.remove();
        if (oldWidget) {
          oldWidget.replaceWith(previewWidget);
        }
      } else {
        previewContainer.appendChild(previewWidget);
      }
    },
  },
});
export default comp;
</script>
<style lang="postcss">
.preview-box {
  table.dh-gallery-table {
    tbody {
      display: block;
      max-height: 400px;
    }
  }
}
</style>

<style lang="postcss" scoped>
.head-control {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .left-control {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .right-control {
    display: flex;
    font-size: var(--x14);
    text-transform: capitalize;
  }
}

.preview {
  background: var(--background-300);
  margin: var(--space-40) -2.5rem -2.5rem;
  border-radius: var(--round-corner-small);
  padding: var(--space-40);
  position: relative;

  .head-control {
    display: flex;
    justify-content: space-between;
    line-height: 42px;

    a {
      font-size: var(--x14);
      color: var(--action-500);
      margin-left: var(--space-12);
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }

    .figure-blue {
      margin-right: var(--space-12);
      color: var(--action-500);
    }
  }

  .preview-box {
    overflow: auto;
    width: auto;
    height: auto;
    min-height: 200px;
    max-height: 400px;
    display: block;
    margin-top: var(--space-16);
    align-items: center;

    script {
      display: block;
    }

    &.url-preview {
      opacity: 0.3;
    }
  }

  .placeholder {
    /* same height as min-height of preview box */
    height: 200px;

    h4 {
      text-transform: none;
      padding: var(--space-64) 0 var(--space-12);
    }
  }
}
</style>
