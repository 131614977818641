<template>
  <Popup :close="onCancel" :type="type" class="confirmation-container">
    <main>
      <div v-if="title">
        <h3>{{ title }}</h3>
        <div v-if="message">
          <p>{{ message }}</p>
        </div>
      </div>
      <h4 v-else>{{ message }}</h4>
      <slot name="actionPopupContent" />
      <div class="confirmation-group">
        <Button
          v-tooltip="buttonTooltip"
          :disabled="buttonDisabled"
          :loading="loading"
          @click="$emit('submit')"
        >
          {{ buttonLabel }}
        </Button>
        <slot name="actionButton" />
      </div>
      <slot name="terms" />
    </main>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Popup from '@/components/Popup.vue';
import Button from '@/components/foundation/Button.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'ActionPopup',
  components: {
    Popup,
    Button,
  },
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    buttonLabel: { type: String, default: null },
    buttonDisabled: { type: Boolean, default: false },
    buttonTooltip: { type: String, default: null },
    loading: { type: Boolean, default: false },
    type: { type: String, default: 'medium' },
  },
  emits: ['submit', 'cancel'],
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.confirmation-container {
  h3 {
    text-align: center;
    margin-bottom: var(--space-24);
  }

  h4 {
    padding-top: 0 !important;
    text-transform: none;
    text-align: center;
  }

  .image-header {
    display: flex;
    justify-content: center;

    img {
      margin: 0 auto var(--space-24);
    }
  }

  p {
    text-align: center;
    height: 2.5rem;
    font-size: var(--x16);
    font-weight: var(--font-normal);
    margin: var(--space-20);
  }

  .confirmation-group {
    margin-top: var(--space-32);
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
      background: var(--action-500);
      color: var(--white);
      text-transform: capitalize;
      padding: 0 var(--space-24);
      font-size: var(--x14);
      font-weight: 500;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border: none;
      min-width: 10rem;

      .svg-icon {
        margin-right: var(--space-8);
      }
    }
  }
}
</style>
