/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'excellent': {
    width: 12,
    height: 13,
    viewBox: '0 0 12 13',
    data: '<path pid="0" d="M11.955 4.024a.6.6 0 00-.555-.378H8.17L6.537.31c-.203-.415-.87-.415-1.073 0L3.829 3.646H.6a.6.6 0 00-.554.378.622.622 0 00.13.667l2.751 2.807-1.103 3.94a.62.62 0 00.223.662.589.589 0 00.686.015L6 9.892l3.267 2.223a.59.59 0 00.687-.015.62.62 0 00.223-.663l-1.103-3.94 2.75-2.806a.622.622 0 00.13-.667z" _fill="#F5A623" fill-rule="nonzero"/>'
  }
})
