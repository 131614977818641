export const noConnectionCopy = {
  title: 'Connect your YouTube Channel',
  message: 'To see account insights you are required to connect your YouTube Channel.',
};

export const reconnectionCopy = {
  title: 'Connect your YouTube Channel',
  message: 'To see account insights you are required to connect your YouTube Channel.',
};

export const noAccessCopy = {
  noAccessMessage:
    'Dash Hudson brings you all of the insights you love, including YouTube! Ask your Dash Hudson administrator to provide you access.',
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
};

export const timeScaleTypes = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

export const tooltips = {
  subscribersChart:
    'The number of people who have subscribed to your channel during the selected date range.',
  metricChart: {
    VIEWS: 'The number of times your videos have been viewed.',
    AVG_VIEW_DURATION: 'The average amount of time that viewers watched per video view.',
    COMMENTS: 'The total number of comments your videos received.',
    LIKES: 'The total number of likes that your videos received.',
    ENGAGEMENTS:
      'The total number of engagements your videos received. Total Engagements  = Likes + Dislikes + Comments + Shares',
    ESTIMATED_SECONDS_WATCHED:
      'The total amount of time that viewers watched videos on your channel.',
  },
  topPerformingVideos: 'Your highest performing videos published within the selected date range.',
  totalSubscribers:
    'The total number of users who have subscribed to your channel by the last day of the selected date range.',
  netNewSubscribers:
    'The net new number of users who have subscribed to your channel during the selected date range.',
  avgViewDuration: 'The average amount of time that viewers watched per video view.',
  totalEngagements:
    'The total number of engagements on your videos during the selected date range. Total Engagements = Likes + Dislikes + Comments + Shares',
  watchTime:
    'The total amount of time that viewers watched videos on your channel during the selected date range.',
  trendingVideos:
    'Your videos that received the highest number of views during the selected date range.',
  totalVideoViews: 'Total Video Views',
  avgViewPercentage: 'Avg. Percentage Viewed',
};

export const youtubeGalleryCardDefaults = {
  metrics: [
    'youtubeTotalVideoViews',
    'youtubeTotalWatchTime',
    'youtubeAvgViewDuration',
    'youtubeTotalEngagements',
    'youtubeSubscribers',
  ],
  labels: {
    youtubeTotalVideoViews: 'Video Views',
    youtubeTotalWatchTime: 'Watch Time',
    youtubeAvgViewDuration: 'Avg. View Duration',
    youtubeTotalEngagements: 'Total Engagements',
    youtubeSubscribers: 'Subscribers',
  },
};

export const youtubeMetricFormats = {
  youtubeTotalWatchTime: 'duration',
  youtubeAvgViewDuration: 'duration',
};

export const noVideosReportingPeriod = 'No YouTube videos found during this reporting period.';

export const privacyStatusEnum = {
  PRIVATE: 'private',
  UNLISTED: 'unlisted',
};

export const filterOptions = [
  { label: 'Media Type', value: 'post_types' },
  { label: 'Visibility', value: 'privacy_status' },
  { label: 'Title or Description', value: 'caption_keywords' },
];

export const filterWidgets = {
  post_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Live', value: 'LIVE' },
        { label: 'On-Demand', value: 'ON_DEMAND' },
        { label: 'Shorts', value: 'SHORTS' },
      ],
    },
  },
  privacy_status: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Visibility',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Private', value: 'PRIVATE' },
        { label: 'Public', value: 'PUBLIC' },
        { label: 'Unlisted', value: 'UNLISTED' },
      ],
    },
  },
  caption_keywords: {
    name: 'MediaFilterSearchWidget',
    label: 'Title or Description',
    options: {
      placeholder: 'Enter keywords or #hashtags',
    },
  },
};

export const metrics = {
  AVG_VIEW_PERCENTAGE: {
    value: 'YOUTUBE_AVG_VIEW_PERCENTAGE',
    field: 'avgViewPercentage',
    label: 'Avg. Percentage Viewed',
  },
  AVG_VIEW_DURATION: {
    value: 'YOUTUBE_AVG_VIEW_DURATION',
    field: 'avgViewDuration',
    label: 'Avg. View Duration',
  },
  CARD_CLICKS: {
    value: 'YOUTUBE_CARD_CLICKS',
    field: 'cardClicks',
    label: 'Card Clicks',
  },
  CARD_CLICK_RATE: {
    value: 'YOUTUBE_CARD_CLICK_RATE',
    field: 'cardClickRate',
    label: 'Card Click Rate',
  },
  CARD_TEASER_CLICKS: {
    value: 'YOUTUBE_CARD_TEASER_CLICKS',
    field: 'cardTeaserClicks',
    label: 'Card Teaser Clicks',
  },
  CARD_TEASER_CLICK_RATE: {
    value: 'YOUTUBE_CARD_TEASER_CLICK_RATE',
    field: 'cardTeaserClickRate',
    label: 'Card Teaser Click Rate',
  },
  CARD_TEASER_IMPRESSIONS: {
    value: 'YOUTUBE_CARD_TEASER_IMPRESSIONS',
    field: 'cardTeaserImpressions',
    label: 'Card Teaser Impressions',
  },
  COMMENTS: {
    value: 'YOUTUBE_COMMENTS',
    field: 'comments',
    label: 'Comments',
  },
  DISLIKES: {
    value: 'YOUTUBE_DISLIKES',
    field: 'dislikes',
    label: 'Dislikes',
  },
  LIKES: {
    value: 'YOUTUBE_LIKES',
    field: 'likes',
    label: 'Likes',
  },
  PREMIUM_VIEWS: {
    value: 'YOUTUBE_PREMIUM_VIEWS',
    field: 'premiumViews',
    label: 'Premium Views',
  },
  PREMIUM_WATCH_TIME: {
    value: 'YOUTUBE_EST_PREMIUM_SECONDS_WATCHED',
    field: 'estPremiumSecondsWatched',
    label: 'Premium Watch Time',
  },
  SUBSCRIBERS: {
    value: 'YOUTUBE_SUBSCRIBERS',
    field: 'subscribers',
    label: 'Subscribers',
  },
  SHARES: {
    value: 'YOUTUBE_SHARES',
    field: 'shares',
    label: 'Shares',
  },
  TOTAL_ENGAGEMENTS: {
    value: 'YOUTUBE_ENGAGEMENTS',
    field: 'engagements',
    label: 'Total Engagements',
  },
  VIDEOS_ADDED_TO_PLAYLIST: {
    value: 'YOUTUBE_VIDEOS_ADDED_TO_PLAYLIST',
    field: 'videosAddedToPlaylist',
    label: 'Added to Playlist',
  },
  VIEWS: {
    value: 'YOUTUBE_VIEWS',
    field: 'views',
    label: 'Video Views',
  },
  WATCH_TIME: {
    value: 'YOUTUBE_EST_SECONDS_WATCHED',
    field: 'estSecondsWatched',
    label: 'Watch Time',
  },
};

export const sortOptions = [
  { value: 'DATE', label: 'Recently Published' },
  metrics.VIDEOS_ADDED_TO_PLAYLIST,
  metrics.AVG_VIEW_PERCENTAGE,
  metrics.AVG_VIEW_DURATION,
  metrics.CARD_CLICKS,
  metrics.CARD_CLICK_RATE,
  metrics.CARD_TEASER_CLICKS,
  metrics.CARD_TEASER_CLICK_RATE,
  metrics.CARD_TEASER_IMPRESSIONS,
  metrics.COMMENTS,
  metrics.DISLIKES,
  metrics.LIKES,
  metrics.PREMIUM_VIEWS,
  metrics.PREMIUM_WATCH_TIME,
  metrics.SHARES,
  metrics.SUBSCRIBERS,
  metrics.TOTAL_ENGAGEMENTS,
  metrics.VIEWS,
  metrics.WATCH_TIME,
];

export const defaultSort = sortOptions[0].value;

export const metricLabels = Object.values(metrics).reduce((acc, v) => {
  acc[v.field] = v.label;
  return acc;
}, {});

export const defaultCardMetrics = [
  metrics.VIEWS.field,
  metrics.AVG_VIEW_DURATION.field,
  metrics.TOTAL_ENGAGEMENTS.field,
  metrics.AVG_VIEW_PERCENTAGE.field,
];

export const youtubeMediaCardDefaults = {
  metrics: defaultCardMetrics,
  labels: {
    [metrics.VIEWS.field]: 'Video Views',
    [metrics.AVG_VIEW_DURATION.field]: 'Avg. View Duration',
    [metrics.TOTAL_ENGAGEMENTS.field]: 'Total Engagements',
    [metrics.AVG_VIEW_PERCENTAGE.field]: 'Avg. % Viewed',
  },
};

export const internalTrafficSourceMap = {
  ADVERTISING: 'YouTube Advertising',
  SUBSCRIBER: 'Browse Features',
  YTCHANNEL: 'Channel Pages',
  YTSEARCH: 'YouTube Search',
  RELATEDVIDEO: 'Suggested Videos',
  YTRELATED: 'Suggested Videos',
  YTOTHERPAGE: 'Other YouTube Features',
  ANNOTATION: 'Video Cards',
  PLAYLIST: 'Playlists',
  NOTIFICATION: 'Notifications',
  YTPLAYLISTPAGE: 'Playlist Pages',
  CAMPAIGNCARD: 'Campaign Cards',
  ENDSCREEN: 'End Screens',
  SHORTS: 'Shorts',
  PROMOTED: 'Promoted',
};

export const ageGroupLabelMap = {
  'age13-17': '13-17 years',
  'age18-24': '18-24 years',
  'age25-34': '25-34 years',
  'age35-44': '35-44 years',
  'age45-54': '45-54 years',
  'age55-64': '55-64 years',
  'age65-': '65+ years',
};

export const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

export const galleriesSortOptions = [
  { value: 'CREATED', label: 'Recently Added' },
  { value: 'YOUTUBE_AVG_VIEW_DURATION', label: 'Avg. View Duration' },
  { value: 'YOUTUBE_SUBSCRIBERS', label: 'Subscribers' },
  { value: 'YOUTUBE_TOTAL_ENGAGEMENTS', label: 'Total Engagements' },
  { value: 'YOUTUBE_TOTAL_VIDEO_VIEWS', label: 'Video Views' },
  { value: 'YOUTUBE_TOTAL_WATCH_TIME', label: 'Watch Time' },
];

// Sort descending
export const youtubeGalleriesDetailSortOptions = sortOptions.map((option) => {
  return { label: option.label, value: `-${option.value}` };
});

export const DEMO_YOUTUBE_VIDEO_URL = 'https://www.youtube.com/watch?v=ZPBRlTQsolA';
export const DEMO_VIDEO_SOURCE_ID = 'ZPBRlTQsolA';

export const youtubeCommentsRequiredScopes = ['https://www.googleapis.com/auth/youtube.force-ssl'];

export const youtubeTopLineStatsMetrics = [
  { value: 'SUBSCRIBERS_TOTAL', label: 'Total Subscribers', tooltip: tooltips.totalSubscribers },
  {
    value: 'SUBSCRIBERS_NET_NEW',
    label: 'Net New Subscribers',
    tooltip: tooltips.netNewSubscribers,
  },
  {
    value: 'AVG_VIEW_DURATION',
    label: 'Avg. View Duration',
    isDuration: true,
    tooltip: tooltips.avgViewDuration,
  },
  { value: 'ENGAGEMENTS', label: 'Total Engagements', tooltip: tooltips.totalEngagements },
  {
    value: 'EST_SECONDS_WATCHED',
    label: 'Watch Time',
    overrideContextMetricName: 'ESTIMATED_SECONDS_WATCHED',
    isDuration: true,
    tooltip: tooltips.watchTime,
  },
  { value: 'VIEWS', label: 'Video Views' },
];
