function appendScriptToDocument(src, { id, onload = () => {} } = {}) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  if (id) {
    script.id = id;
  }
  script.onload = () => {
    onload();
  };
  const body = document.getElementsByTagName('body')?.[0];
  if (body) {
    body.insertBefore(script, body.lastElementChild);
  }
}

export function selectorContainsEventTarget(elemSelector, event, element) {
  return [...(element || document).querySelectorAll(elemSelector)].some(
    (el) => el !== event?.target && el.contains(event?.target),
  );
}

export function loadScript(src, { id, onload } = {}) {
  if (document.readyState === 'complete') {
    appendScriptToDocument(src, { id, onload });
  } else if (window.attachEvent) {
    window.attachEvent('onload', () => appendScriptToDocument(src, { id, onload }));
  } else {
    window.addEventListener('load', () => appendScriptToDocument(src, { id, onload }), false);
  }
}

let cssPageMediaStyle = null;

export function setCssPageMedia(rules) {
  if (!cssPageMediaStyle) {
    cssPageMediaStyle = document.createElement(`style`);
    cssPageMediaStyle.setAttribute('id', 'cssPageMedia');
    document.head.appendChild(cssPageMediaStyle);
  }

  cssPageMediaStyle.innerHTML = `@page { ${rules} }`;
}

export function detectPageScroll() {
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const scrollHeight = html.scrollHeight;
  const clientHeight = html.clientHeight;
  if (scrollHeight > clientHeight) {
    body?.classList.add('scrollbar-padding');
  }
}
