/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-enter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 4h-8v2h7v10H5.414l2.293-2.293-1.414-1.414L1.586 17l4.707 4.707 1.414-1.414L5.414 18H20a1 1 0 001-1V5a1 1 0 00-1-1z"/>'
  }
})
