import {
  FACEBOOK_SUCCESSFUL_PUBLISH_WITH_TARGETING_MESSAGE,
  autoPublishErrors,
  firstCommentStatus,
} from '@/app/scheduler/constants';

export const invalidAuthNotification = (platform, apiPlatform, brand, channel = 'post') => {
  return `We were unable to publish your ${platform} ${channel} to ${brand}. Please reconnect your ${apiPlatform} account and try again.`;
};

export const duplicatePostNotification = (platform, brand, channel = 'post') => {
  return `You have already published a ${platform} ${channel} to ${brand} with this text. Make a change to the text and try again.`;
};

const unknownErrorNotification = (platform, brand, channel = 'post') => {
  return `Something went wrong while publishing your ${platform} ${channel} to ${brand}, please try again.`;
};

// eslint-disable-next-line
export function getIgAutopublishNotification(data, postBrandName) {
  const { post, result } = data;

  const channelName =
    {
      FEED: 'post',
      REEL: 'Reel',
    }[post?.post_type] ?? 'post';

  if (result === 'SUCCESS') {
    const failedToTagUsers = !!post.invalid_user_tags;
    const failedToIncludeCollaborators = !!post.invalid_collaborators;
    const failedToPostFirstComment =
      post.first_comment_status && post.first_comment_status === firstCommentStatus.FAILED;
    const failedLocation = !!post.invalid_location;
    const failedProductTags = !!post.has_invalid_product_tags;
    if (failedToTagUsers && !failedToPostFirstComment && !failedLocation) {
      return {
        message: `We were unable to include user tags after publishing your Instagram ${channelName} to
          @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }

    if (failedToIncludeCollaborators) {
      return {
        message: `We were unable to include Collaborators after publishing your Instagram ${channelName} to @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }

    if (!failedToTagUsers && failedToPostFirstComment && !failedLocation) {
      return {
        message: `The first comment did not publish after publishing your Instagram ${channelName} to
          @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (failedToTagUsers && failedToPostFirstComment && !failedLocation) {
      return {
        message: `The first comment did not publish and we were unable to include user tags after
          publishing your Instagram ${channelName} to @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (!failedToTagUsers && !failedToPostFirstComment && failedLocation) {
      return {
        message: `We were unable to include location after publishing your Instagram ${channelName} to
          @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (failedToTagUsers && !failedToPostFirstComment && failedLocation) {
      return {
        message: `We were unable to include location and user tags after publishing your Instagram
          ${channelName} to @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (!failedToTagUsers && failedToPostFirstComment && failedLocation) {
      return {
        message: `The first comment did not publish and we were unable to include location after
          publishing your Instagram ${channelName} to @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (failedToTagUsers && failedToPostFirstComment && failedLocation) {
      return {
        message: `The first comment did not publish and we were unable to include location and
          user tags after publishing your Instagram ${channelName} to @${postBrandName}.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    if (failedProductTags) {
      return {
        message:
          `Product tags could not be added. This may be because one or more of your products ` +
          `are invalid. You can add product tags to the published post on the Instagram app.`,
        actionText: 'See it on Instagram',
        actionTo: post.live_post_url,
        type: 'warning',
        delay: 10000,
      };
    }
    return {
      message: `Instagram ${channelName} successfully published to @${postBrandName}!`,
      actionText: 'See it on Instagram',
      actionTo: post.live_post_url,
    };
  }

  if (result === 'FAILURE') {
    const error = post.auto_publish_error;
    if (error === autoPublishErrors.AUTO_PUBLISH_LIMIT_EXCEEDED) {
      return {
        message: `Instagram ${channelName} Failed to Publish for @${postBrandName}.
          You have reached the API limit of 25 auto published posts in 24hrs.
          Please publish this post via push notification in the Dash Hudson app.`,
        actionText: 'See post on your Timeline',
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.ACCOUNT_RESTRICTED) {
      return {
        message: `This Instagram ${channelName} did not publish successfully due to a restriction on your
          account. Please complete any in-app actions Instagram is requiring to reenable your
          account or contact Instagram to resolve this issue.`,
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.DUPLICATE_POST) {
      return {
        message: duplicatePostNotification('Instagram', postBrandName, channelName),
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.INVALID_IMAGE) {
      return {
        message: `This image could not be published as it did not meet Instagram's size requirements.`,
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.MISSING_AUTH || error === autoPublishErrors.INVALID_AUTH) {
      return {
        message: invalidAuthNotification('Instagram', 'Facebook', postBrandName, channelName),
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.PAGE_PUBLISHING_AUTH_REQUIRED) {
      return {
        message: `This content did not publish. To post to your connected Instagram account, please complete Facebook's Page Publishing Authorization.`,
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    if (error === autoPublishErrors.FLAGGED_AS_SPAM) {
      return {
        message: ` This post failed because Instagram flagged it as restricted content.`,
        actionText: `See ${channelName} on your Timeline`,
        actionTo: { name: 'scheduler.instagram.timeline' },
        type: 'error',
      };
    }
    return {
      message: unknownErrorNotification('Instagram', postBrandName, channelName),
      actionText: `See ${channelName} on your Timeline`,
      actionTo: { name: 'scheduler.instagram.timeline' },
      type: 'error',
    };
  }
  return null;
}

export function getPinAutopublishNotification(handle, data, successCount, failCount) {
  const { post } = data;
  if (failCount > 0) {
    const error = post.auto_publish_error;
    if (error === autoPublishErrors.AUTO_PUBLISH_LIMIT_EXCEEDED) {
      return {
        message: `${failCount} ${failCount > 1 ? 'posts' : 'post'} failed to publish for @${handle}.
        You've reached the API posting frequency limit on Pinterest.
        Please reschedule your pins to a later time or manually post them via the Pinterest app.`,
        actionText: `See ${failCount > 1 ? 'Pins' : 'Pin'} on your Timeline`,
        actionTo: { name: 'scheduler.pinterest.timeline' },
        type: 'error',
      };
    }
    return {
      message: `${failCount} ${failCount > 1 ? 'posts' : 'post'} failed to publish.`,
      actionText: `See ${failCount > 1 ? 'Pins' : 'Pin'} on your Timeline`,
      actionTo: { name: 'scheduler.pinterest.timeline' },
      type: 'error',
    };
  }
  if (successCount > 0) {
    return {
      message: `${successCount} ${
        successCount > 1 ? 'posts' : 'post'
      } successfully published to @${handle}`,
      actionText: `See ${failCount > 1 ? 'Pins' : 'it'} on Pinterest`,
      actionTo: post.live_post_url,
    };
  }
  return null;
}

export function getFbAutopublishNotification(data, postBrandName) {
  const { post, result } = data;
  if (result === 'SUCCESS') {
    let message = `Facebook post successfully published for ${postBrandName}!`;
    if (post.targeting !== null) {
      message = FACEBOOK_SUCCESSFUL_PUBLISH_WITH_TARGETING_MESSAGE;
    }
    return {
      message,
      actionText: 'See it on Facebook',
      actionTo: post.live_post_url,
    };
  }

  if (data.result === 'FAILURE') {
    const error = data.post.auto_publish_error;
    if (error === autoPublishErrors.DUPLICATE_POST) {
      return {
        message: duplicatePostNotification('Facebook', postBrandName),
        type: 'error',
      };
    }
    if (error === autoPublishErrors.MISSING_AUTH || error === autoPublishErrors.INVALID_AUTH) {
      return {
        message: invalidAuthNotification('Facebook', 'Facebook', postBrandName),
        actionText: 'See post on your Timeline',
        actionTo: { name: 'scheduler.facebook.timeline' },
        type: 'error',
      };
    }
    return {
      message: unknownErrorNotification('Facebook', postBrandName),
      actionText: 'See post on your Timeline',
      actionTo: { name: 'scheduler.facebook.timeline' },
      type: 'error',
    };
  }
  return null;
}

export function getTwitterAutopublishNotification(data) {
  let res = null;
  if (data.result === 'SUCCESS') {
    res = {
      message: 'Post successfully published!',
      actionText: 'See it on X.',
      actionTo: data.post.live_post_url,
    };
  } else if (data.result === 'FAILURE') {
    const error = data.post.auto_publish_error;
    if (error === autoPublishErrors.DUPLICATE_POST) {
      res = {
        message:
          'You have already sent a Post with this text. Make a change to the text and try again.',
        type: 'error',
      };
    } else if (error === autoPublishErrors.TEXT_LENGTH_EXCEEDED) {
      res = {
        message:
          'This Post did not go live at the scheduled posting time because it exceeds X’s character limit. Please remove some characters and try again.',
        type: 'error',
      };
    } else if (
      error === autoPublishErrors.MISSING_AUTH ||
      error === autoPublishErrors.INVALID_AUTH
    ) {
      res = {
        message: 'We were unable to post your post. Please reconnect your X account and try again.',
        type: 'error',
      };
    } else {
      res = {
        message: 'Something went wrong while posting your Post, please try again.',
        type: 'error',
      };
    }
  }
  return res;
}

export function getTikTokAutopublishNotification(data, postBrandName) {
  const { post, result } = data;
  if (result === 'SUCCESS') {
    return {
      message: `TikTok post successfully published for ${postBrandName}!`,
      actionText: 'See it on TikTok',
      actionTo: post.live_post_url,
    };
  }

  if (data.result === 'FAILURE') {
    const error = data.post.auto_publish_error;
    if (error === autoPublishErrors.DUPLICATE_POST) {
      return {
        message: duplicatePostNotification('TikTok', postBrandName),
        type: 'error',
      };
    }
    if (error === autoPublishErrors.MISSING_AUTH || error === autoPublishErrors.INVALID_AUTH) {
      return {
        message: invalidAuthNotification('TikTok', 'TikTok', postBrandName),
        actionText: 'See post on your Timeline',
        actionTo: { name: 'scheduler.tiktok.timeline' },
        type: 'error',
      };
    }
    return {
      message: unknownErrorNotification('TikTok', postBrandName),
      actionText: 'See post on your Timeline',
      actionTo: { name: 'scheduler.tiktok.timeline' },
      type: 'error',
    };
  }
  return null;
}

export function getLinkedInAutopublishNotification(data, postBrandName) {
  const { post, result } = data;
  if (result === 'SUCCESS') {
    return {
      message: `LinkedIn post successfully published for ${postBrandName}!`,
      actionText: 'See it on LinkedIn',
      actionTo: post.live_post_url,
    };
  }

  if (data.result === 'FAILURE') {
    const error = data.post.auto_publish_error;
    if (error === autoPublishErrors.DUPLICATE_POST) {
      return {
        message: duplicatePostNotification('LinkedIn', postBrandName),
        type: 'error',
      };
    }
    if (error === autoPublishErrors.MISSING_AUTH || error === autoPublishErrors.INVALID_AUTH) {
      return {
        message: invalidAuthNotification('LinkedIn', 'LinkedIn', postBrandName),
        actionText: 'See post on your Timeline',
        actionTo: { name: 'scheduler.linkedin.timeline' },
        type: 'error',
      };
    }
    return {
      message: unknownErrorNotification('LinkedIn', postBrandName),
      actionText: 'See post on your Timeline',
      actionTo: { name: 'scheduler.linkedin.timeline' },
      type: 'error',
    };
  }
  return null;
}
