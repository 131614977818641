import { useTrackingStore } from '@/stores/tracking';
import { GALLERY_ADDITION_SOURCES, GALLERY_TYPE_NAMES } from '@/components/Galleries/constants';

const EVENT_NAMES = {
  BOARDS_ADD_BOARD_CLICKED: 'Boards Add Board Clicked',
  BOARDS_ADD_MEDIA_CLICKED: 'Boards Add Media Clicked',
  MULTISELECT_ACTION_CLICKED: 'Multi Select Action Clicked',
};

export function trackBoardsAddBoardClicked(galleryType, componentName) {
  const trackingStore = useTrackingStore();
  trackingStore.track(EVENT_NAMES.BOARDS_ADD_BOARD_CLICKED, {
    galleryType: GALLERY_TYPE_NAMES[galleryType],
    clickFrom: GALLERY_ADDITION_SOURCES[componentName],
    fromMultiSelectBar: componentName === 'MultiSelectTopBar',
  });
}

export function trackBoardsAddMediaClicked(galleryType) {
  const trackingStore = useTrackingStore();
  trackingStore.track(EVENT_NAMES.BOARDS_ADD_MEDIA_CLICKED, {
    galleryType: GALLERY_TYPE_NAMES[galleryType],
  });
}

export function trackMultiSelectActionClicked(buttonLabel, totalMedia) {
  const trackingStore = useTrackingStore();
  trackingStore.track(EVENT_NAMES.MULTISELECT_ACTION_CLICKED, {
    buttonLabel,
    totalMedia,
  });
}
