/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'light-bulb': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_546_12322)" _fill="#686a7a"><path pid="0" d="M9 23h2v1h2v-1h2v-2H9v2zM12 5c-3.859 0-7 3.14-7 7a7.011 7.011 0 004 6.319V19a1 1 0 001 1h4a1 1 0 001-1v-.681A7.01 7.01 0 0019 12c0-3.86-3.141-7-7-7zm1.666 11.709a1.001 1.001 0 00-.666.942V18h-2v-.349a1 1 0 00-.666-.942A5.008 5.008 0 017 12c0-2.757 2.243-5 5-5s5 2.243 5 5c0 2.11-1.34 4.003-3.334 4.709zM13 0h-2v4h2V0zM4 11H0v2h4v-2zM24 11h-4v2h4v-2zM21.705 3.706l-1.413-1.413-3 3 1.414 1.414 3-3zM6.708 5.293l-3-3-1.414 1.413 3 3 1.414-1.413z"/></g><defs><clipPath id="clip0_546_12322"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
