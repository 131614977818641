/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'override': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_1465_48951)"><path pid="0" d="M10 13.334A5.833 5.833 0 0115.835 7.5c.284 0 .56.028.833.067v-3.4c0-.918-.748-1.666-1.667-1.666h-1.667v-2.5h-1.666v2.5H5v-2.5H3.333v2.5H1.667C.747 2.501 0 3.249 0 4.168V15c0 .92.748 1.667 1.667 1.667H10v-3.334zM1.668 4.168H15v2.5H1.667v-2.5z" _fill="#686a7a"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.81 14.042h-4.762v-2.167c0-.896.801-1.625 1.786-1.625.984 0 1.785.73 1.785 1.625v.542h1.19v-.542c0-1.493-1.334-2.708-2.975-2.708s-2.976 1.215-2.976 2.708v2.167c-.658 0-1.191.485-1.191 1.083v3.792c0 .598.533 1.083 1.19 1.083h5.953c.657 0 1.19-.485 1.19-1.083v-3.792c0-.598-.533-1.083-1.19-1.083zm-5.953 4.875v-3.792h5.953l.001 3.792h-5.954zm2.976-1.083c.493 0 .893-.364.893-.813 0-.448-.4-.812-.893-.812s-.893.364-.893.813c0 .448.4.812.893.812z" _fill="#686a7a"/><mask id="svgicon_override_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="11" y="9" width="9" height="12"><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M18.81 14.042h-4.762v-2.167c0-.896.801-1.625 1.786-1.625.984 0 1.785.73 1.785 1.625v.542h1.19v-.542c0-1.493-1.334-2.708-2.975-2.708s-2.976 1.215-2.976 2.708v2.167c-.658 0-1.191.485-1.191 1.083v3.792c0 .598.533 1.083 1.19 1.083h5.953c.657 0 1.19-.485 1.19-1.083v-3.792c0-.598-.533-1.083-1.19-1.083zm-5.953 4.875v-3.792h5.953l.001 3.792h-5.954zm2.976-1.083c.493 0 .893-.364.893-.813 0-.448-.4-.812-.893-.812s-.893.364-.893.813c0 .448.4.812.893.812z" _fill="#fff"/></mask><g mask="url(#svgicon_override_a)"><path pid="3" _fill="#686a7a" d="M8.69 8.084h14.286v13H8.69z"/></g></g><defs><clipPath id="clip0_1465_48951"><path pid="4" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
