/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'smiley-frown-1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#686a7a" d="M12 2C6.477 2 2 6.477 2 12c0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM6 8h4v2H6V8zm10.441 9.998A5.412 5.412 0 0012 15.652a5.41 5.41 0 00-4.443 2.349L6 16.442a7.584 7.584 0 016-2.961 7.596 7.596 0 016 2.958l-1.559 1.559zM18 10h-4V8h4v2z"/>'
  }
})
