/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shoppingBag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.996 8.917A.999.999 0 0019 8h-2V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H5a.999.999 0 00-.996.917l-1 12A.997.997 0 004 22h16a1 1 0 00.996-1.083l-1-12zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zM5.087 20l.833-10H7v3h2v-3h6v3h2v-3h1.08l.833 10H5.087z"/>'
  }
})
