export const INSTAGRAM_OVERVIEW_MULTI_BRAND = [
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_EFFECTIVENESS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'AVG_ENTERTAINMENT_SCORE_REEL',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_LIKES',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 16,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'SAVES',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'LIKESHOP_CLICKS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 20,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM'],
      metric: 'TOTAL_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 28,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 32,
    meta: { name: 'Post Performance' },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 33,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 37,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_UGC',
    w: 6,
    x: 0,
    y: 41,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM_UGC',
      metric: 'UGC_TOTAL_ENGAGEMENTS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 45,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 49,
    meta: { name: 'Story Performance' },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 50,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'NUMBER_OF_POSTS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 50,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 54,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 54,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 0,
    y: 58,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_EXIT_RATE',
    },
  },
  {
    h: 4,
    type: 'MULTI_BRAND_METRIC',
    w: 3,
    x: 3,
    y: 58,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'SWIPE_UPS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 62,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_REACH',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 62,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 66,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 66,
    meta: {
      brand_ids: ['{brandId0}', '{brandId1}', '{brandId2}'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'SWIPE_UPS',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED_STORIES',
    w: 6,
    x: 0,
    y: 71,
    meta: {
      brand_ids: ['{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED_STORIES',
    w: 6,
    x: 0,
    y: 75,
    meta: {
      brand_ids: ['{brandId2}'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'AVG_REACH',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 70,
    meta: { name: 'Story Performance' },
  },
];
