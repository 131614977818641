import dayjs from 'dayjs';
import isFunction from 'lodash/isFunction';
import { isSameRange } from '@/utils/dateUtils';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';

export const CONTEXT_PERIOD_MODES = Object.freeze({
  PREVIOUS_WEEK: {
    value: 'PREVIOUS_WEEK',
    text: 'Previous Week',
    range(sourceRange) {
      const previousWeek = sourceRange?.start?.clone().subtract(1, 'weeks') || null;
      const start = previousWeek?.clone().startOf('week') || null;
      const end = previousWeek?.clone().endOf('week') || null;
      return {
        start,
        end,
      };
    },
  },
  PREVIOUS_7_DAYS: {
    value: 'PREVIOUS_7_DAYS',
    text: 'Previous 7 Days',
    range(sourceRange) {
      const start = sourceRange?.start?.clone().subtract(7, 'day') || null;
      const end = sourceRange?.start?.clone().subtract(1, 'day') || null;
      return {
        start,
        end,
      };
    },
  },
  PREVIOUS_4_WEEKS: {
    value: 'PREVIOUS_4_WEEKS',
    text: 'Previous 4 Weeks',
    range(sourceRange) {
      const start = sourceRange?.start?.clone().subtract(28, 'day') || null;
      const end = sourceRange?.start?.clone().subtract(1, 'day') || null;
      return {
        start,
        end,
      };
    },
  },
  PREVIOUS_MONTH: {
    value: 'PREVIOUS_MONTH',
    text: 'Previous Month',
    range(sourceRange) {
      const previousWeek = sourceRange?.start?.clone().subtract(1, 'month') || null;
      const start = previousWeek?.clone().startOf('month') || null;
      const end = previousWeek?.clone().endOf('month') || null;
      return {
        start,
        end,
      };
    },
  },
  YEAR_BEFORE: {
    value: 'YEAR_BEFORE',
    text: 'Year Before',
    range(sourceRange) {
      const start = sourceRange?.start?.clone().subtract(1, 'year') || null;
      const end = sourceRange?.end?.clone().subtract(1, 'year') || null;
      return {
        start,
        end,
      };
    },
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

export const REPORTING_PERIOD_MODES = Object.freeze({
  LAST_WEEK: {
    value: 'LAST_WEEK',
    text: 'Last Week',
    range() {
      return {
        start: dayjs().subtract(1, 'weeks').startOf('week'),
        end: dayjs().subtract(1, 'weeks').endOf('week'),
      };
    },
    contextPeriodMode: CONTEXT_PERIOD_MODES.PREVIOUS_WEEK.value,
  },
  LAST_MONTH: {
    value: 'LAST_MONTH',
    text: 'Last Month',
    range() {
      return {
        start: dayjs().subtract(1, 'month').startOf('month'),
        end: dayjs().subtract(1, 'month').endOf('month'),
      };
    },
    contextPeriodMode: CONTEXT_PERIOD_MODES.PREVIOUS_MONTH.value,
  },
  LAST_7_DAYS: {
    value: 'LAST_7_DAYS',
    text: 'Last 7 Days',
    range() {
      const end = dayjs().subtract(1, 'day');
      const start = dayjs().subtract(7, 'day');
      return {
        start,
        end,
      };
    },
    contextPeriodMode: CONTEXT_PERIOD_MODES.PREVIOUS_7_DAYS.value,
  },
  LAST_4_WEEKS: {
    value: 'LAST_4_WEEKS',
    text: 'Last 4 Weeks',
    range() {
      const end = dayjs().subtract(1, 'day');
      const start = dayjs().subtract(4, 'week');
      return {
        start,
        end,
      };
    },
    contextPeriodMode: CONTEXT_PERIOD_MODES.PREVIOUS_4_WEEKS.value,
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

export function isValidReportingPeriodMode(value) {
  return value && value in REPORTING_PERIOD_MODES;
}

export function getReportingPeriodModeForRange(range) {
  return (
    Object.values(REPORTING_PERIOD_MODES)
      .filter((mode) => isFunction(mode.range))
      .find((mode) => isSameRange([mode.range().start, mode.range().end], range))?.value ??
    REPORTING_PERIOD_MODES.CUSTOM.value
  );
}

export function isValidContextPeriodMode(value) {
  return value && value in CONTEXT_PERIOD_MODES;
}

export function getContextPeriodModeForRange(range) {
  return (
    Object.values(REPORTING_PERIOD_MODES)
      .filter((mode) => isFunction(mode.range))
      .map((mode) => {
        const sourceRange = mode.range();
        const contextMode = CONTEXT_PERIOD_MODES[mode.contextPeriodMode];
        const contextRange = [
          contextMode.range(sourceRange).start,
          contextMode.range(sourceRange).end,
        ];
        return { contextMode, contextRange };
      })
      .find(({ contextRange }) => isSameRange(contextRange, range))?.contextMode?.value ??
    CONTEXT_PERIOD_MODES.CUSTOM.value
  );
}

function isValidDateRange(dateRange) {
  return (
    Array.isArray(dateRange) &&
    dateRange.length === 2 &&
    dateRange.every((date) => dayjs(date, 'YYYY-MM-DD', true).isValid())
  );
}

export function updateDateRange(state, lsKey, range, defaultRange) {
  const localStorageDateRange = browserStorageGetItem(lsKey)?.toString()?.split(',') || null;
  let dateToBeUsed = null;
  if (isValidDateRange(range)) {
    dateToBeUsed = range;
  } else if (isValidDateRange(localStorageDateRange)) {
    dateToBeUsed = localStorageDateRange;
  } else {
    dateToBeUsed = defaultRange;
  }
  const dashIndex = lsKey.indexOf('-');
  const field = dashIndex > -1 ? lsKey.slice(0, dashIndex) : lsKey;
  state[field] = dateToBeUsed;
  browserStorageSetItem(lsKey, dateToBeUsed.join(','));
}
