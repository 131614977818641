import { useRoute } from 'vue-router';
import { UserEventTracker } from '@/utils/analytics/tracking';

export class CreatorDiscoveryEventTracker extends UserEventTracker {
  route = useRoute();

  EVENT_NAMES = {
    VIEWED_CREATOR: 'Creator Discovery Viewed Creator',
    CREATOR_ADDED: 'Creator Discovery Creator Added',
    FILTERS: 'Creator Discovery Filters',
    NO_CREATORS_FOUND: 'Creator Discovery No Creators Found',
    VIEW_INFLUENCER: 'Relationship View Influencer',
    DISCOVER_CLICKED: 'Discover New Creators Clicked',
  };

  #sharedProperties() {
    return {
      platform: 'Instagram',
      path: this.route.path,
    };
  }

  viewedCreator(extra = {}) {
    this.trackingStore.track(this.EVENT_NAMES.VIEWED_CREATOR, {
      ...this.#sharedProperties(),
      ...extra,
    });
  }

  creatorAdded(extra = {}) {
    this.trackingStore.track(this.EVENT_NAMES.CREATOR_ADDED, {
      ...this.#sharedProperties(),
      ...extra,
    });
  }

  filters(actionedPerformed, filterUsed, filterValue) {
    this.trackingStore.track(this.EVENT_NAMES.FILTERS, {
      actionedPerformed,
      filterUsed,
      filterValue,
      ...this.#sharedProperties(),
    });
  }

  noCreatorsFound(extra = {}) {
    this.trackingStore.track(this.EVENT_NAMES.NO_CREATORS_FOUND, {
      ...this.#sharedProperties(),
      ...extra,
    });
  }

  influencerViewed(wasClicked, connectionStatus, influencer) {
    this.trackingStore.track(this.EVENT_NAMES.VIEW_INFLUENCER, {
      openedFrom: wasClicked ? 'Clicked' : 'Searched',
      connectionStatus,
      influencer,
    });
  }

  discoverNewCreatorsClicked(extra = {}) {
    this.trackingStore.track(this.EVENT_NAMES.DISCOVER_CLICKED, {
      ...this.#sharedProperties(),
      ...extra,
    });
  }
}
