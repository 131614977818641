/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'scheduledDraftPost': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_427_25628)" _fill="#686a7a"><path pid="0" d="M4.125 9.792V8.375H10.5V9.45a6.34 6.34 0 014.25-1.033V5.248L11.502 2H3.416C2.635 2 2 2.636 2 3.417v12.75c0 .78.635 1.416 1.417 1.416h4.92a6.34 6.34 0 01-.67-2.833c0-.747.134-1.46.37-2.125H4.125v-1.417h4.25v.63a6.39 6.39 0 011.664-2.046H4.125zM10.5 2.708l3.542 3.542H10.5V2.708zM18.792 12.124L16.667 10a.708.708 0 00-1.001 0l-4.959 4.958a.706.706 0 00-.186.33l-.708 2.833a.706.706 0 00.858.859l2.834-.709a.71.71 0 00.329-.186l4.958-4.958a.707.707 0 000-1.002zm-5.821 4.82l-1.498.374.375-1.497 2.194-2.194 1.123 1.123-2.194 2.194zm3.196-3.196l-1.124-1.123 1.124-1.123 1.123 1.123-1.123 1.123z"/></g><defs><clipPath id="clip0_427_25628"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
