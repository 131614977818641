/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'translation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.696 6.523a1 1 0 01.135 1.408l-7.248 8.785a1 1 0 01-1.543-1.273l7.248-8.785a1 1 0 011.408-.135z" _fill="#686a7a"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.695 11.015a1 1 0 011.413-.068l4.832 4.393a1 1 0 01-1.345 1.48l-4.832-4.393a1 1 0 01-.068-1.412zM2 7.294a1 1 0 011-1h12.08a1 1 0 110 2H3a1 1 0 01-1-1z" _fill="#686a7a"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M9.042 3a1 1 0 011.003.997l.01 3.295a1 1 0 11-2 .005l-.01-3.294A1 1 0 019.042 3zM15.628 9.588a1 1 0 011.323.502l4.5 10a1 1 0 01-1.824.82l-4.5-10a1 1 0 01.501-1.322z" _fill="#686a7a"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M12.539 17.5a1 1 0 011-1h5a1 1 0 110 2h-5a1 1 0 01-1-1z" _fill="#686a7a"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M16.449 9.588a1 1 0 00-1.322.502l-4.5 10a1 1 0 101.823.82l4.5-10a1 1 0 00-.501-1.322z" _fill="#686a7a"/>'
  }
})
