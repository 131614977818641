/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'richPin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M9.89 14.678l-8.126 8.126A1 1 0 11.35 21.389l8.126-8.125L4.692 9.48 6.19 7.983h3.992l4.99-4.99-.998-.997L16.168 0l6.986 6.985-1.996 1.996-.998-.998-4.99 4.99v3.992l-1.496 1.497-3.784-3.784z" id="svgicon_richPin_a"/></defs><use _fill="#adadad" fill-rule="nonzero" xlink:href="#svgicon_richPin_a"/>'
  }
})
