<template>
  <div class="subscreen">
    <section :class="classes">
      <template v-if="showTabs">
        <div class="left-header">
          <div class="back" @click="backToIndex">
            <Icon :color="greyIcon" name="caret" dir="down" xsmall />
          </div>
          {{ title }}
        </div>
        <slot name="tabs" />
      </template>
      <template v-else>
        <div :style="{ background: backButtonColor }" class="back" @click="backToIndex">
          <Icon :color="greyIcon" name="caret" dir="down" xsmall />
        </div>
        {{ title }}
      </template>
    </section>
    <slot name="content" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'SubScreen',
  components: {
    Icon,
  },
  props: {
    stickyNav: { type: Boolean, default: false },
    title: { type: String, default: null },
    showTabs: { type: Boolean, default: false },
    backButtonColor: { type: String, default: null },
  },
  emits: ['onBack'],
  data() {
    return {
      greyIcon: colours.ICON.ICON_PRIMARY,
    };
  },
  computed: {
    classes() {
      if (this.showTabs) {
        return 'tab-nav';
      }
      if (this.stickyNav) {
        return ['nav', 'sticky-nav'];
      }
      return 'nav';
    },
  },
  methods: {
    backToIndex() {
      this.$emit('onBack');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.subscreen {
  width: 100%;

  .nav {
    display: flex;
    align-items: center;
    font-size: var(--x18);
    font-weight: var(--font-medium);
    margin: 0;

    .back {
      background: var(--background-300);
      width: var(--space-40);
      height: var(--space-40);
      display: flex;
      border: solid 1px var(--border);
      border-radius: var(--round-corner-small);
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: var(--space-16);
      transition: var(--transition-all);

      &:hover {
        transform: var(--hover-move);
      }
    }
  }

  .sticky-nav {
    position: sticky;
    top: var(--space-16);
  }

  .tab-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--x18);
    font-weight: var(--font-medium);
    margin: 0;
    height: var(--space-32);

    .left-header {
      display: flex;
      align-items: center;
      justify-content: center;

      .back {
        background: var(--background-300);
        width: var(--space-40);
        height: var(--space-32);
        border: solid 1px var(--border);
        border-radius: var(--round-corner-small);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: var(--space-16);
        transition: var(--transition-all);

        &:hover {
          transform: var(--hover-move);
        }
      }
    }

    .link-tabs {
      display: flex;
      align-items: baseline;

      &:hover {
        transform: none;
      }
    }
  }
}
</style>
