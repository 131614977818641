<script setup>
// Components
import Icon from '@/components/foundation/Icon.vue';
import { useDashboardsStore } from '@/stores/dashboards';
import { PRODUCT_MAP } from '@/models/auth/customer-plan.enum';

const dashboardsStore = useDashboardsStore();

const props = defineProps({
  copy: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    default: '',
  },
  trialProduct: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['action']);

function actionItemClicked() {
  if (props.trialProduct === PRODUCT_MAP.SOCIAL_LISTENING && props.route === '/dashboards') {
    dashboardsStore.launchCreateDashboardForm();
  }
  emits('action');
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
};
</script>

<template>
  <router-link
    :to="route"
    class="mb-2 flex cursor-pointer flex-row justify-between rounded border border-solid border-[--border] bg-neutral-100 py-2.5 pl-6 pr-4"
    @click="actionItemClicked()"
  >
    <p class="text-sm font-medium">{{ props.copy }}</p>
    <Icon name="chevronDown" small class="rotate-[270deg]" />
  </router-link>
</template>
