/* eslint-disable */

let singleton;
const defaultTicks = { 0: true, 50: true, 100: true };

class SlideSelect {
  constructor({ el, disabled = false, sliderId, selectedCallback, enabledTicks = defaultTicks }) {
    this._disabled = disabled;
    this._sliderId = sliderId;
    this._isDragging = false;
    this._selectedCallback = selectedCallback;
    this._el = el;
    this._startX = null;
    this._initialX = null;
    this._startY = null;
    this._endX = null;
    this._endY = null;
    this._movement = 50;
    this._enabledTicks = enabledTicks;
  }

  mouseDownHandler = (e) => {
    const slider = document.getElementById(this._sliderId);
    if (slider) {
      let box = SlideSelect.getBoundingBox(this._el);
      let isMouseInBox = this._isMouseInBox(box, e.pageX, e.pageY);
      if (isMouseInBox) {
        this._initialX = slider.style.left;
        let x = Math.min(Math.max(box.left, e.pageX), box.width + box.left);
        let y = Math.min(Math.max(box.top, e.pageY), box.height + box.top);
        this._detectNearButton(slider, box, x, y);
        this._startX = this._endX = x;
        this._startY = this._endY = y;
        this._dragTimer = setTimeout(() => {
          this._isDragging = true;
        }, 250);
      }
    }
  };

  mouseMoveHandler = (e) => {
    const slider = document.getElementById(this._sliderId);
    let box = SlideSelect.getBoundingBox(this._el);
    if (this._isDragging) {
      slider.style.transition = 'none';
      let x = Math.min(Math.max(box.left, e.pageX), box.width + box.left);
      this._movement = ((x - box.left) / box.width) * 100;
      if (this._movement < 50 && !this._enabledTicks[0]) {
        this._movement = 50;
      } else if (this._movement > 50 && !this._enabledTicks[100]) {
        this._movement = 50;
      }
      slider.style.left = this._movement + '%';
    }
  };

  mouseUpHandler = (e) => {
    const slider = document.getElementById(this._sliderId);
    if (slider) {
      slider.style.transition = 'all 0.3s';
      if (this._isDragging) {
        slider.style.left = this._getNormalizedSlider();
        this._selectedCallback(this._getNormalizedSlider());
        let box = SlideSelect.getBoundingBox(this._el);
        let x = Math.min(Math.max(box.left, e.pageX), box.width + box.left);
        let y = Math.min(Math.max(box.top, e.pageY), box.height + box.top);
        this._endX = x;
        this._endY = y;
        this._isDragging = false;
      }
      if (this._dragTimer) {
        clearTimeout(this._dragTimer);
      }
    }
  };

  init() {
    document.addEventListener('mousedown', this.mouseDownHandler);
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  deinit() {
    document.removeEventListener('mousedown', this.mouseDownHandler);
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  _isMouseInBox(box, eventPageX, eventPageY) {
    return (
      box.top <= eventPageY &&
      eventPageY <= box.top + box.height &&
      box.left - 10 <= eventPageX &&
      eventPageX <= box.left + box.width + 10
    );
  }

  _detectNearButton(slider, box, x, y) {
    this._movement = ((x - box.left) / box.width) * 100;
    if (this._movement <= 10 && this._enabledTicks['0']) {
      this._updateSlider(slider, '0%');
    } else if (this._movement > 40 && this._movement < 60 && this._enabledTicks['50']) {
      this._updateSlider(slider, '50%');
    } else if (this._movement > 90 && this._enabledTicks['100']) {
      this._updateSlider(slider, '100%');
    } else {
      this._updateSlider(slider, this._initialX);
    }
  }

  _getNormalizedSlider() {
    if (this._movement <= 25 && this._enabledTicks['0']) {
      return '0%';
    } else if (this._movement <= 75 && this._enabledTicks['50']) {
      return '50%';
    } else if (this._enabledTicks['100']) {
      return '100%';
    } else {
      return this._initialX;
    }
  }

  _updateSlider(slider, position) {
    slider.style.left = position;
    this._selectedCallback(position);
  }

  static getBoundingBox(element) {
    let box = element.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      left: box.left + window.pageXOffset,
      width: box.width,
      height: box.height,
    };
  }
}

export default {
  beforeMount: (el, binding) => {
    let { disabled, sliderId, selectedCallback, enabledTicks } = binding.value;
    singleton = new SlideSelect({ el, disabled, sliderId, selectedCallback, enabledTicks });
    singleton.init();
  },
  updated: (el, binding) => {
    let { disabled, sliderId, selectedCallback } = binding.value;
    if (disabled) {
      if (singleton) {
        singleton.deinit();
      }
    } else {
      singleton.init();
      singleton._disabled = disabled;
    }
  },
};
