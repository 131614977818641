/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shrinkDiagonal': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M18.668 11.83l3.24-3.24a.833.833 0 111.179 1.178l-3.241 3.241h1.322a.833.833 0 010 1.667H17.5a.5.5 0 01-.5-.5v-3.667a.833.833 0 011.666 0v1.322zM13.008 19.845l-3.24 3.24a.833.833 0 11-1.179-1.178l3.241-3.24h-1.322a.833.833 0 110-1.667h3.667a.5.5 0 01.5.5v3.667a.833.833 0 01-1.667 0v-1.322z" _fill="#666"/>'
  }
})
