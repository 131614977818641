/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'paperplane': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M21.397 8.194A1167.915 1167.915 0 019.705 3.042C4.328.645 2.882 0 1.875 0 1.011 0 .586.524.446.695a2.006 2.006 0 00-.162 2.29L3.96 9.112.285 15.237a2.004 2.004 0 00.162 2.29c.14.173.565.697 1.43.697 1.006 0 2.446-.643 7.799-3.03 2.76-1.231 6.532-2.914 11.722-5.165a.998.998 0 00-.001-1.835zM2.082 2.026c.8.164 2.929 1.114 6.808 2.844 1.91.852 4.312 1.922 7.317 3.241H5.692l-3.61-6.085zm6.779 11.341c-3.942 1.759-6.069 2.707-6.827 2.84l3.658-6.097h10.516a1388.653 1388.653 0 00-7.347 3.257z" id="svgicon_paperplane_a"/></defs><g transform="rotate(-30 14.055 4.966)" _fill="none" fill-rule="evenodd"><mask id="svgicon_paperplane_b" _fill="#fff"><use xlink:href="#svgicon_paperplane_a"/></mask><use _fill="#ACB6BF" fill-rule="nonzero" xlink:href="#svgicon_paperplane_a"/><g mask="url(#svgicon_paperplane_b)" _fill="#686a7a" fill-rule="nonzero"><path pid="1" d="M-2-3h26v26H-2z"/></g></g>'
  }
})
