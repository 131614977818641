<template>
  <teleport to="body">
    <transition name="fade" appear>
      <div
        v-bind="$attrs"
        id="popup-page-container"
        ref="container"
        :class="[
          typeClasses[$props.type],
          containerClass,
          { 'popup-full-height': fullHeight },
          { 'popup-responsive': responsive },
        ]"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
      >
        <a v-if="enableClose" v-tooltip="'Close'" class="close" data-cy="close-popup">
          <!-- Need to call close here for full page popups -->
          <Icon :color="closeIconColor" name="close" small @click="handleConfirmOrClose" />
        </a>
        <div class="body" :style="inlineStyles">
          <slot />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';

import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import { useNotificationStore } from '@/stores/notification';
import { discardConfirmTitle } from '@/config';
import { detectPageScroll } from '@/utils/dom';

let allPopups = [];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'Popup',
  components: {
    Icon,
  },
  props: {
    type: { type: String, default: 'xsmall' },
    fullHeight: { type: Boolean, default: false }, // Fill 100% of the viewport height
    responsive: { type: Boolean, default: false }, // Never fill more than 80% of the viewport height
    close: { type: Function, required: true },
    closeConfirmation: { type: String, default: null },
    closeConfirmationTitle: { type: String, default: discardConfirmTitle },
    enableDragSelect: { type: Boolean, default: false },
    platform: { type: String, default: null },
    inlineStyles: { type: Object, default: () => {} },
    enableClose: { type: Boolean, default: true },
    disableCloseOnBodyClick: { type: Boolean, default: false },
  },
  data() {
    return {
      showCloseConfirm: false,
      mouseDownTarget: null,
      mouseDownX: -1,
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    containerClass() {
      return this.enableDragSelect ? 'drag-select-popup-container' : 'popup-container';
    },
    typeClasses() {
      return {
        full: 'popup-full', // Full screen popup (e.g. 100% height, 100% width)
        xxsmall: 'popup-xxsmall',
        xsmall: 'popup-xsmall',
        small: 'popup-small',
        medium: 'popup-medium',
        large: 'popup-large',
        larger: 'popup-larger',
        xlarge: 'popup-xlarge',
        xxlarge: 'popup-xxlarge',
      };
    },
    closeIconColor() {
      if (this.type === 'full') {
        return colours.ICON.ICON_SECONDARY;
      }
      return colours.BASIC.WHITE;
    },
  },
  mounted() {
    // Disable scroll on lower level.
    detectPageScroll();
    document.body?.classList.add('popup-open');
    window.addEventListener('keyup', this.handleEscapeKeyUp.bind(this));
    allPopups.push(this);
  },
  unmounted() {
    // Re-enable scroll. Uses 'visible' instead of 'auto' here because the InfiniteScroll component
    // only listens for scroll events inside the outermost parent container that has that style
    allPopups = allPopups.filter((popup) => popup !== this);
    if (allPopups.length === 0) {
      document.body?.classList.remove('popup-open', 'scrollbar-padding');
      window.removeEventListener('keyup', this.handleEscapeKeyUp.bind(this));
    }
  },
  methods: {
    handleMouseDown(e) {
      this.mouseDownTarget = e.target;
      this.mouseDownX = e.clientX;
    },
    handleMouseUp(e) {
      if (
        this.enableClose &&
        !this.disableCloseOnBodyClick &&
        e.target === this.$refs.container &&
        e.target === this.mouseDownTarget &&
        !this.isScrollClicked(e.target)
      ) {
        this.handleConfirmOrClose();
      }
    },
    isScrollClicked(target) {
      // true if the container is scrollable and the mouse was clicked on scrollbar area
      // assume that scrollbar width would not be wider than 20px
      return target.clientWidth - this.mouseDownX < 20;
    },
    findParentNodeByAttribute(el, targetAttribute) {
      let node = el;
      while (node.parentNode) {
        node = node.parentNode;
        // we can reach node type 9, which is #document and has no 'hasAttribute' func.
        if (node.nodeType === 1 && node.hasAttribute(targetAttribute)) {
          return node;
        }
      }
      return null;
    },
    handleEscapeKeyUp(e) {
      const ESCAPE_KEY = 27;
      const key = e.which || e.keyCode;
      if (this.enableClose && key === ESCAPE_KEY && allPopups[allPopups.length - 1] === this) {
        this.handleConfirmOrClose();
      }
    },
    async handleConfirmOrClose() {
      if (this.closeConfirmation) {
        await this.notificationStore.confirm(this.closeConfirmationTitle, this.closeConfirmation, {
          confirmAlias: 'Discard',
          platform: this.platform,
          onConfirm: this.close,
        });
      } else {
        this.close({ routeChange: false });
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.popup-container {
  padding: 0 var(--space-56);
}

.drag-select-popup-container,
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  overflow: auto;
  background: rgb(0 0 0 / 60%);
  transition: var(--transition-all);
  z-index: var(--z-index-popup);
  cursor: pointer;

  .close {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
  }

  .body {
    margin: var(--space-80) auto;
    background: var(--background-0);
    border-radius: var(--round-corner);
    cursor: auto;
    position: relative;
  }

  h4 {
    text-align: center;
  }
}

/* full height */

.popup-full-height {
  display: flex;
  justify-content: center;
  overflow: unset;

  > .body {
    margin: var(--space-40) auto;
    transition: width 0.3s ease;
  }
}

/* Responsive styles */
.popup-responsive {
  overflow: unset;

  > .body {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow: hidden;
  }

  @media (max-width: 619px) {
    padding: 0;

    .close {
      z-index: 1;

      svg {
        fill: var(--text-secondary) !important;
      }
    }

    > .body {
      border-radius: none;
      box-shadow: none;
      margin: 0;
      max-height: 100vh;
      min-height: 100vh;
      max-width: 100%;
      padding: var(--space-24);
    }
  }
}

/* full screen */

.popup-full {
  background-color: var(--background-0);
  cursor: unset;

  .close {
    position: fixed;
    z-index: var(--z-index-raised);
  }

  .btn--minimize {
    position: absolute;
    top: 0.625rem;
    right: 3.625rem;
    z-index: 3;
  }

  .body {
    width: 100%;
  }
}

/* 1600px */
.popup-xxlarge {
  > .body {
    margin-top: 50px;
    max-width: 105rem;
    width: 105rem;
    position: relative;
  }
}

/* 1200px */
.popup-xlarge {
  > .body {
    max-width: 75rem;
    width: 75rem;
    position: relative;
  }
}

/* 1088px */
.popup-larger {
  > .body {
    width: var(--width-1088);
  }
}

/* 960px */
.popup-large {
  > .body {
    width: 60rem;
  }
}

/* 880px */
.popup-medium {
  > .body {
    padding: var(--space-40);
    width: var(--width-880);
  }
}

/* 620px */
.popup-small {
  > .body {
    padding: var(--space-40);
    width: var(--width-620);
  }
}

/* 580px */
.popup-xsmall {
  > .body {
    width: var(--width-580);
    min-width: var(--width-580);
    padding: var(--space-40);

    img {
      display: block;
      margin: var(--space-16) auto;
    }

    h4 {
      text-align: center;
      padding: var(--space-12) 0 var(--space-32);
    }

    p {
      text-align: center;
      margin-bottom: var(--space-12);
    }
  }
}

/* 440px */
.popup-xxsmall {
  > .body {
    width: 27.5rem;
    margin: var(--space-30) auto;
  }
}
</style>

<style lang="postcss">
body {
  &.popup-open {
    overflow: hidden;
  }

  &.scrollbar-padding {
    padding: 0 var(--space-10) 0 0;
  }
}
</style>
