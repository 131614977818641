import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import isFinite from 'lodash/isFinite';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { logger } from '@/utils/logger';
import { originForReport } from '@/config';
import { DashboardAPI } from '@/apis';
import * as AuthAPI from '@/apis/auth';
import { useAuthStore } from '@/stores/auth';
import { usePdfStore } from '@/stores/pdf';
import { useSearchStore } from '@/stores/search';
import constants from '@/app/dashboards/constants';
import {
  REPORTS,
  CONTENT_REPORT_TYPES,
  getReportConfigProp,
} from '@/app/dashboards/utils/reports.enum';
import { filterAndSortUsers } from '@/app/dashboards/utils/sharing.utils';
import { TEMPLATES } from '@/app/dashboards/utils/templates.enum';
import gridUtils, { overrideLayoutItemHeightFromConstant } from '@/app/dashboards/utils/grid.utils';
import {
  contentCreator,
  extractBrandNames,
  extractCompetitorNames,
  getBaseReportProperties,
  objectChanges,
  prefixObjectProperties,
} from '@/app/dashboards/utils/store.utils';

import {
  CHANNELS,
  compareChannels,
  filterChannelByFlags,
  filterOutNonSelectableChannels,
  getChannelConfigProp,
  getChannelText,
  filterChannelIfUnknown,
  filterNonCompetitiveChannels,
  filterOutNonSelectableCompetitiveChannels,
} from '@/models/dashboards/channels.enum';
import {
  filterMetricByDisabledMultiChannel,
  filterMetricByFlags,
  getMetricText,
  filterMetricsByBrandPermissions,
  filterMetricsIfHidden,
  filterMetricsIfContentTags,
} from '@/models/dashboards/metrics';
import { downloadFileFromMemory } from '@/utils';
import { stringifyQuery } from '@/utils/query';
import {
  browserStorageGetItem,
  browserStorageRemoveItem,
  browserStorageSetItem,
} from '@/utils/browserStorage';
import { INDUSTRY_FOLLOWER_BUCKETS } from '@/models/dashboards/benchmark-type.enum';
import { parseIndustryName } from '@/app/dashboards/utils/benchmarks.utils';
import { BRAND } from '@/models/auth/permissions.enum';
import { useFlagStore } from '@/stores/flag';
import { useDashboardReportsStore } from '@/stores/dashboards-reports';
import { useDashboardPermissionsStore } from '@/stores/dashboard-permissions';
import { useTrackingStore } from '@/stores/tracking';
import { useContentTagsStore } from '@/stores/content-tags';
import { useDashboardTrackingProps } from '@/app/dashboards/composables/useDashboardTrackingProps';
import { useMetricsStore } from '@/stores/metrics';
import { UPDATED_CONFIG_CHANNELS } from '@/models/dashboards/metrics.constants';
import { filterMetricsWithParentMetric } from '@/utils/metrics';
import { DashboardUserEventTracker } from '@/app/dashboards/mixpanel';

async function loadAllPages(apiCall) {
  let allItems = [];
  const response = await apiCall({
    params: {
      limit: 1000,
    },
  });
  allItems = [...allItems, ...response.data.data];
  return allItems;
}

function channelOptionFormatter(channelName, brandLabels, userPermissions) {
  const text = getChannelText(channelName);
  const channelIcon = getChannelConfigProp(channelName, 'channelIcon');

  const { module, permissions } = CHANNELS?.[channelName]?.auth || {};
  const hasPermission = brandLabels?.every((label) => {
    return permissions?.every((permission) => userPermissions?.[module]?.[label]?.[permission]);
  });

  return {
    value: channelName,
    text,
    channelIcon,
    disabled: !hasPermission,
    tooltip: !hasPermission ? `${text} is not connected` : undefined,
  };
}

function extractBrandLabels(brands) {
  const brandsAsArray = [].concat(brands || []);
  return brandsAsArray.map((brand) => brand.label);
}

export const useDashboardsStore = defineStore('dashboards', {
  state: () => ({
    // Dashboards
    _dashboards: [],
    dashboardsError: false,
    currentDashboard: null,
    currentDashboardLoading: false,
    updatingDashboard: false,
    dashboardCustomizeMode: false,
    saveCustomizeMode: false,
    cancelCustomizeMode: false,
    dashboardFormShow: false,
    dashboardFormMode: null,
    dashboardFormTarget: null,
    // Reports
    reports: {},
    reportLoading: {},
    reportError: {},
    metricTypes: {},
    reportDataCsvLoading: false,
    previewReportData: null,
    showStaticDatesDropdownReportId: null,
    reportsDataCache: [],
    // Exports
    _currentDashboardAutoExports: [],
    allDashboardAutoExports: [],
    autoExportFormShow: false,
    autoExportFormMode: null,
    autoExportFormTarget: null,
    autoExportListShow: false,
    spreadsheetExportDownloading: false,
    // Sharing
    _currentDashboardUsers: [],
    shareFormShow: false,
    dashboardSharableUsers: [],
    // Industries
    industries: [],
  }),
  getters: {
    // Dashboards
    dashboards: (state) => state._dashboards?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [],
    ownedDashboards() {
      const authStore = useAuthStore();
      const currentUserId = authStore.identity?.id;
      return this.dashboards.filter((dashboard) => dashboard.user_id === currentUserId);
    },
    sharedDashboards() {
      const authStore = useAuthStore();
      const currentUserId = authStore.identity?.id;
      return this.dashboards.filter((dashboard) => dashboard.user_id !== currentUserId);
    },
    hasDashboards: (state) => state._dashboards?.length > 0,
    currentDashboardId: (state) => state.currentDashboard?.id,
    currentDashboardName: (state) => state.currentDashboard?.name,
    currentDashboardLayout: (state) => state.currentDashboard?.layout || [],
    currentDashboardLayoutReportIds() {
      return this.currentDashboardLayout.map((item) => item.report_id) || [];
    },
    identityBrands: () => {
      const authStore = useAuthStore();
      const identityBrands = authStore.identity?.brands || {};
      return Object.keys(identityBrands).map((key) => identityBrands[key]);
    },
    dashboardIdentityBrands() {
      const authStore = useAuthStore();
      const dashboardPermissionsStore = useDashboardPermissionsStore();

      return this.identityBrands.filter((brand) => {
        return (
          authStore.guard(BRAND.DASHBOARD.CAN_ACCESS_DASHBOARDS, brand) ||
          dashboardPermissionsStore.canUserAccessDashboards(brand) ||
          false
        );
      });
    },
    dashboardIdentityBrandsByIds() {
      return this.dashboardIdentityBrands.map((brand) => brand.id);
    },
    dashboardCompetitiveIdentityBrands() {
      const authStore = useAuthStore();
      const dashboardPermissionsStore = useDashboardPermissionsStore();
      return this.dashboardIdentityBrands.filter((brand) => {
        return (
          authStore.guard(BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS, brand) ||
          dashboardPermissionsStore.canUserAccessCompetitors(brand) ||
          false
        );
      });
    },
    dashboardTemplates: () => Object.keys(TEMPLATES).map((key) => TEMPLATES[key]),
    currentLayoutTemplate: (state) => {
      const { reports } = state;
      const layout = state.currentDashboard?.layout || [];

      return layout.map((layoutItem) => {
        const layoutItemTemplate = cloneDeep(layoutItem);
        const reportMeta = cloneDeep(reports?.[layoutItemTemplate?.report_id]?.meta);

        if (reportMeta?.brand_ids) {
          reportMeta.brand_ids = ['brand_ids'];
        }

        if (reportMeta?.competitor_source_account_ids) {
          reportMeta.competitor_source_account_ids = ['competitor_ids'];
        }

        delete layoutItemTemplate.report_id;
        layoutItemTemplate.meta = reportMeta;
        return layoutItemTemplate;
      });
    },
    hasReports: (state) => state.currentDashboard?.layout?.length > 0,
    // Reports
    getMetricTypes: (state) => {
      return (channel) => {
        if (channel === CHANNELS.INSTAGRAM.value || channel === CHANNELS.INSTAGRAM_UGC.value) {
          const metricsReport = REPORTS.SINGLE_METRIC.metricTypeReportKey;
          const contentReport = REPORTS.CONTENT_OWNED.metricTypeReportKey;
          const graphReport = REPORTS.GRAPH.metricTypeReportKey;
          return {
            [metricsReport]: [
              ...state.metricTypes[CHANNELS.INSTAGRAM.value][metricsReport],
              ...state.metricTypes[CHANNELS.INSTAGRAM_UGC.value][metricsReport],
            ],
            [contentReport]: state.metricTypes?.[channel]?.[contentReport],
            [graphReport]: [
              ...state.metricTypes[CHANNELS.INSTAGRAM.value][graphReport],
              ...state.metricTypes[CHANNELS.INSTAGRAM_UGC.value][graphReport],
            ],
          };
        }
        return state.metricTypes?.[channel];
      };
    },
    getComparableMetricsForChannels() {
      const dashboardReportsStore = useDashboardReportsStore();
      return (targetChannels) => {
        const allMetrics = Object.keys(dashboardReportsStore.comparableMetrics);
        return allMetrics.reduce((acc, metric) => {
          const metricChannels = Object.keys(dashboardReportsStore.comparableMetrics[metric]);
          const metricHasTargetChannels = targetChannels.every((channel) => {
            return metricChannels.includes(channel);
          });
          if (metricHasTargetChannels) {
            acc.push(metric);
          }
          return acc;
        }, []);
      };
    },
    getComparableMetricKey() {
      const dashboardReportsStore = useDashboardReportsStore();
      return (targetChannels, parentMetric) => {
        let comparable = null;
        Object.keys(dashboardReportsStore.comparableMetrics).forEach((metric) => {
          const isComparable = targetChannels.every((channel) => {
            return !!dashboardReportsStore.comparableMetrics[metric]?.[channel];
          });
          const containsParent = Object.values(
            dashboardReportsStore.comparableMetrics[metric],
          ).includes(parentMetric);
          if (isComparable && containsParent) {
            comparable = metric;
          }
        });
        return comparable;
      };
    },
    getMetricOptions(state) {
      const metricsStore = useMetricsStore();
      return (report, channel, brands = null, contentTagIds = null) => {
        if (isEmpty(state.metricTypes)) {
          return [];
        }
        const flagStore = useFlagStore();
        const metricTypeReportKey = getReportConfigProp(report, 'metricTypeReportKey');
        const channels = [].concat(channel || []);
        const channelReportTypes = channels?.map((channelKey) => this.getMetricTypes(channelKey));
        let metrics = [];
        if (channelReportTypes.length === 1) {
          metrics = channelReportTypes[0]?.[metricTypeReportKey] || [];
        } else if (channelReportTypes.length > 1) {
          metrics = this.getComparableMetricsForChannels(channels);
        }
        const allMetricDetails = metricsStore.metricDetails;
        return metrics
          .filter(filterMetricByFlags(flagStore.flags, channel))
          .filter(filterMetricByDisabledMultiChannel(channels))
          .filter(filterMetricsByBrandPermissions(channels, brands))
          .filter(filterMetricsIfHidden(channels, allMetricDetails, metricTypeReportKey))
          .filter(filterMetricsIfContentTags(channels, contentTagIds, report, allMetricDetails))
          .filter(filterMetricsWithParentMetric(channels, allMetricDetails, metricTypeReportKey))
          .map((value) => {
            const firstChannel = channels[0];
            let displayName;
            if (channels.length === 1 && UPDATED_CONFIG_CHANNELS.includes(firstChannel)) {
              displayName =
                allMetricDetails[firstChannel]?.[metricTypeReportKey]?.[value]?.display_name ||
                allMetricDetails[CHANNELS.INSTAGRAM_UGC.value]?.[metricTypeReportKey]?.[value]
                  ?.display_name;
            }
            if (!displayName) {
              displayName = getMetricText(firstChannel, value, channels, report);
            }
            return {
              value,
              text: displayName,
            };
          })
          .sort((a, b) => a.text.localeCompare(b.text));
      };
    },
    channels: (state) => {
      return Object.keys(state.metricTypes || {}).sort(compareChannels);
    },
    getChannelOptions() {
      return (brands) => {
        return this.channels
          .filter(filterChannelByFlags(useFlagStore().flags))
          .filter(filterOutNonSelectableChannels())
          .filter(filterChannelIfUnknown())
          .map((channelName) =>
            channelOptionFormatter(
              channelName,
              extractBrandLabels(brands),
              useAuthStore().identity?.permissions,
            ),
          );
      };
    },
    getCompetitiveChannelOptions() {
      return (brands) => {
        return this.channels
          .filter(filterNonCompetitiveChannels())
          .filter(filterOutNonSelectableCompetitiveChannels())
          .filter(filterChannelByFlags(useFlagStore().flags))
          .filter(filterChannelIfUnknown())
          .map((channelName) =>
            channelOptionFormatter(
              channelName,
              extractBrandLabels(brands),
              useAuthStore().identity?.permissions,
            ),
          );
      };
    },
    // Exports
    currentDashboardAutoExports(state) {
      return state._currentDashboardAutoExports.map((autoExport) => {
        if (autoExport.dashboard_id === this.currentDashboard?.id) {
          // Add dashboard object to be used in common auto export list component
          autoExport.dashboard = {
            id: this.currentDashboard?.id,
            name: this.currentDashboard?.name,
            user_role: this.currentDashboardRole,
          };
        }
        return autoExport;
      });
    },
    pdfReady: (state) => {
      const searchStore = useSearchStore();
      const hasDashboard = !!state.currentDashboard;
      if (hasDashboard) {
        const hasReports = state.currentDashboard?.layout?.length > 0;
        if (hasReports) {
          const reportLoadingIds = Object.keys(state.reportLoading);
          const hasReportLoading = reportLoadingIds.length > 0;
          const allReportsLoaded = reportLoadingIds.every((id) => !state.reportLoading[id]);
          const competitorsLoaded = searchStore.competitorsRequestStatus === 'success';
          return hasReportLoading && allReportsLoaded && competitorsLoaded;
        }
        // Dashboard with no reports
        return true;
      }
      return false;
    },
    // Sharing
    isOwner() {
      return this.currentDashboardRole === constants.ROLES.OWNER.value;
    },
    isEditor() {
      return this.currentDashboardRole === constants.ROLES.EDITOR.value;
    },
    isViewer() {
      return this.currentDashboardRole === constants.ROLES.VIEWER.value;
    },
    currentDashboardUser() {
      const authStore = useAuthStore();
      const currentUserId = authStore.identity?.id;
      return this.currentDashboardUsers?.find((user) => user.id === currentUserId);
    },
    currentDashboardUsers: (state) => {
      const combinedUsers = state._currentDashboardUsers.map((dashboardUser) => {
        const combinedUser = { ...(dashboardUser.user || {}) };
        if (dashboardUser.user_role) {
          combinedUser.user_role = dashboardUser.user_role;
        }
        return combinedUser;
      });
      return filterAndSortUsers(combinedUsers);
    },
    currentDashboardRole() {
      return this.currentDashboardUser?.user_role;
    },
    // Industries //
    industryBenchmarkIdentityBrands() {
      return this.identityBrands.filter((brand) => {
        return brand?.permissions?.dashboard?.can_access_industry_benchmarks || false;
      });
    },
    getBenchmarkOptions: (state) => {
      return state.industries.map((industry) => {
        const followerBucket = industry?.follower_bucket || '';
        const industryName = industry?.name || '';
        const cleanedFollowerBucketName = INDUSTRY_FOLLOWER_BUCKETS[followerBucket].text;
        const text = parseIndustryName(
          industryName,
          cleanedFollowerBucketName,
          industry.dashboard_follower_buckets,
        );
        const value = parseIndustryName(industry.id, followerBucket, true);

        return {
          value,
          text,
          id: industry.id,
          follower_bucket: followerBucket,
          hasFollowerBucket: industry.dashboard_follower_buckets,
        };
      });
    },
    reportsWithStaticDates: (state) => {
      return Object.values(state.reports).filter((report) => {
        return report?.meta?.static_dates?.start_date && report?.meta?.static_dates?.end_date;
      });
    },
    hasAnyStaticDates: (state) => {
      return state.reportsWithStaticDates?.length > 0;
    },
    allReportSourceAdAccountIds(state) {
      return uniq(
        Object.values(state.reports)
          .filter((report) => report?.meta?.source_ad_account_ids?.length > 0)
          .flatMap((report) => report.meta.source_ad_account_ids),
      );
    },
    mixpanelTracker() {
      return new DashboardUserEventTracker();
    },
  },
  actions: {
    // Dashboards
    updateCurrentDashboard({ dashboard, users, identity, autoExports }) {
      this.currentDashboard = dashboard;
      this._currentDashboardUsers = Array.isArray(users) ? users : [];
      this._currentDashboardAutoExports = Array.isArray(autoExports) ? autoExports : [];
      // remove reports from previous dashboard
      const currentDashboardReportIds = new Set(
        this.currentDashboard?.layout?.map((layoutItem) => layoutItem.report_id),
      );
      Object.keys(this.reports).forEach((reportId) => {
        if (!currentDashboardReportIds.has(Number(reportId))) {
          delete this.reports[reportId];
        }
      });
      browserStorageSetItem(`currentDashboardId:${identity?.id}`, dashboard?.id);
    },
    clearCurrentDashboard({ identity }) {
      this.currentDashboard = null;
      this._currentDashboardUsers = [];
      this._currentDashboardAutoExports = [];
      browserStorageRemoveItem(`currentDashboardId:${identity?.id}`);
    },
    addDashboardToList(incomingDashboard) {
      const found = this._dashboards.some((dashboard) => dashboard.id === incomingDashboard.id);
      if (found) {
        this._dashboards = this._dashboards.map((dashboard) =>
          dashboard.id === incomingDashboard.id ? incomingDashboard : dashboard,
        );
      } else {
        this._dashboards = [...this._dashboards, incomingDashboard];
      }
    },
    removeDashboardsFromList({ dashboardIds, identity }) {
      const dashboardIdsToRemove = dashboardIds || [];
      if (dashboardIdsToRemove.includes(this.currentDashboard?.id)) {
        browserStorageRemoveItem(`currentDashboardId:${identity?.id}`);
      }
      this._dashboards = this._dashboards.filter(
        (dashboard) => !dashboardIdsToRemove.includes(dashboard.id),
      );
    },
    launchDashboardCustomizeMode() {
      this.dashboardCustomizeMode = true;
    },
    closeCustomizeMode() {
      this.dashboardCustomizeMode = false;
    },
    launchCreateDashboardForm() {
      this.mixpanelTracker.createDashboardClick();
      this.dashboardFormMode = 'CREATE';
      this.dashboardFormShow = true;
    },
    launchDuplicateDashboardForm(dashboardToDuplicate) {
      this.dashboardFormMode = 'DUPLICATE';
      this.dashboardFormTarget = dashboardToDuplicate;
      this.dashboardFormShow = true;
    },
    launchRenameDashboardForm(dashboardToRename) {
      this.dashboardFormMode = 'RENAME';
      this.dashboardFormTarget = dashboardToRename;
      this.dashboardFormShow = true;
    },
    closeDashboardForm() {
      this.dashboardFormMode = null;
      this.dashboardFormTarget = null;
      this.dashboardFormShow = false;
    },
    requestDashboardCustomizeCancel() {
      this.cancelCustomizeMode = !this.cancelCustomizeMode;
    },
    requestDashboardCustomizeSave() {
      this.saveCustomizeMode = !this.saveCustomizeMode;
    },
    async listDashboards() {
      this.dashboardsError = false;
      try {
        const dashboardList = await loadAllPages(({ params }) =>
          DashboardAPI.getDashboards(params),
        );
        this._dashboards = dashboardList;
      } catch (error) {
        this.dashboardsError = true;
        throw error;
      }
    },
    loadLocalDashboardState({
      dashboardId,
      reportDateRange,
      contextDateRange,
      graphScale,
      reportingPeriodMode,
      contextPeriodMode,
    }) {
      const authStore = useAuthStore();
      const dashboardReportsStore = useDashboardReportsStore();
      const identity = authStore.identity;

      dashboardReportsStore.updateReportDate({
        newReportDateRange: reportDateRange,
        dashboardId,
        currentDashboard: this.currentDashboard,
        timeZone: identity?.time_zone_name,
      });

      dashboardReportsStore.updateContextDate({
        newContextDateRange: contextDateRange,
        dashboardId,
        currentDashboard: this.currentDashboard,
        timeZone: identity?.time_zone_name,
      });

      dashboardReportsStore.updateGraphScale({
        scale: graphScale,
        dashboardId,
        currentDashboard: this.currentDashboard,
      });

      dashboardReportsStore.updateReportingPeriodMode({
        mode: reportingPeriodMode,
        dashboardId,
        currentDashboard: this.currentDashboard,
      });

      dashboardReportsStore.updateContextPeriodMode({
        mode: contextPeriodMode,
        dashboardId,
        currentDashboard: this.currentDashboard,
      });
    },
    async initCurrentDashboard({
      id,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      graphScale,
      reportingPeriodMode,
      contextPeriodMode,
      force = true,
    }) {
      if (id) {
        this.loadLocalDashboardState({
          dashboardId: id,
          reportDateRange: [startDate, endDate],
          contextDateRange: [contextStartDate, contextEndDate],
          graphScale,
          reportingPeriodMode,
          contextPeriodMode,
        });
      }
      return this.setCurrentDashboard({
        id,
        force,
      });
    },
    async setCurrentDashboard({ id, force = true } = {}) {
      const authStore = useAuthStore();
      const identity = authStore.identity;

      let dashboard;
      let users;
      let autoExports;
      const dashboardIdsToRemove = [];
      try {
        this.currentDashboardLoading = true;

        let dashboardIdsToTry = [id];
        if (force) {
          // add last dashboardId from local storage
          const localStorageDashboardId = browserStorageGetItem(
            `currentDashboardId:${identity?.id}`,
          );
          dashboardIdsToTry.push([localStorageDashboardId]);

          // Add all other dashboardIds in order of the list api call
          const dashboards = this._dashboards || [];
          const dashboardIds = dashboards.map((dashboardObj) => dashboardObj.id);
          dashboardIdsToTry = dashboardIdsToTry.concat(dashboardIds);
        }

        // convert any strings in the list to numbers
        dashboardIdsToTry = dashboardIdsToTry
          .map((dashboardId) => parseInt(dashboardId, 10))
          .filter((dashboardId) => isFinite(dashboardId));

        for (let i = 0; i < dashboardIdsToTry.length; i += 1) {
          const dashboardId = dashboardIdsToTry[i];
          if (dashboardId) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const [dashboardResponse, usersResponse, autoExportsResponse] = await Promise.all([
                DashboardAPI.getDashboard({ dashboardId }),
                DashboardAPI.getDashboardUsers({ dashboardId }),
                DashboardAPI.getDashboardAutomatedExports({ dashboardId }),
              ]);
              dashboard = dashboardResponse?.data;
              users = usersResponse?.data;
              autoExports = autoExportsResponse?.data;
              break;
            } catch (e) {
              if (e.response?.status >= 400 && e.response?.status < 499) {
                logger.warn(`Failed to load dashboard ${dashboardId}`);
                dashboardIdsToRemove.push(dashboardId);
              } else {
                throw e;
              }
            }
          }
        }
      } finally {
        // remove any dashboards which are no longer accessible since the last refresh
        this.removeDashboardsFromList({ dashboardIds: dashboardIdsToRemove, identity });
        if (dashboard && users) {
          this.updateCurrentDashboard({ dashboard, users, identity, autoExports });
        } else {
          this.clearCurrentDashboard({ identity });
        }
        this.loadLocalDashboardState({
          dashboardId: dashboard?.id,
        });
        this.currentDashboardLoading = false;
      }
      return dashboard;
    },
    async refreshDashboard() {
      return this.setCurrentDashboard({ id: this.currentDashboardId });
    },
    async internalUpdateDashboard(updateDashboard) {
      const authStore = useAuthStore();
      const identity = authStore.identity;
      this.updatingDashboard = true;
      try {
        const response = await DashboardAPI.updateDashboard({
          dashboardId: updateDashboard.id,
          data: omit(updateDashboard, ['id', 'label']),
        });
        const updatedDashboard = response?.data;
        if (this.currentDashboard?.id === updatedDashboard.id) {
          this.updateCurrentDashboard({
            dashboard: updatedDashboard,
            users: this._currentDashboardUsers,
            autoExports: this._currentDashboardAutoExports,
            identity,
          });
        }
        this.addDashboardToList(updatedDashboard);
        return response;
      } catch (error) {
        if (error.response?.status !== 404) {
          throw error;
        }
      } finally {
        this.updatingDashboard = false;
      }
      return null;
    },
    async internalUpdateLayout({ layout }) {
      const originalLayout = overrideLayoutItemHeightFromConstant(layout);
      return this.internalUpdateDashboard({
        ...this.currentDashboard,
        layout: originalLayout,
      });
    },
    async saveCustomizedLayout({ layout }) {
      await this.internalUpdateLayout({ layout });
      useTrackingStore().track('Dashboard Customize Layout', {
        dashboardId: this.currentDashboardId,
        dashboardName: this.currentDashboardName,
      });
    },
    async updateDashboard(updateDashboard) {
      const previousDashboard =
        this.dashboardFormTarget?.id === updateDashboard?.id ? this.dashboardFormTarget : undefined;
      await this.internalUpdateDashboard(updateDashboard);
      useTrackingStore().track('Dashboard Edit Dashboard', {
        dashboardId: updateDashboard.id,
        dashboardName: updateDashboard.name,
        previoudDashboardName: previousDashboard?.name,
      });
    },
    async duplicateDashboard({ newName }) {
      const response = await DashboardAPI.createDashboard({
        data: {
          name: newName,
        },
        params: {
          source_id: this.dashboardFormTarget?.id,
        },
      });
      const createdDashboard = response?.data;
      useTrackingStore().track('Dashboard Create Dashboard', {
        dashboardId: createdDashboard?.id,
        dashboardName: createdDashboard?.name,
        sourceDashboardId: this.dashboardFormTarget?.id,
        duplicate: true,
      });
      await this.setCurrentDashboard({ id: createdDashboard.id });
      this.addDashboardToList(createdDashboard);
    },
    async deleteDashboard(dashboardToDelete) {
      const authStore = useAuthStore();
      const identity = authStore.identity;
      await DashboardAPI.deleteDashboard({
        dashboardId: dashboardToDelete.id,
      });
      useTrackingStore().track('Dashboard Delete Dashboard', {
        dashboardId: this.dashboardFormTarget?.id,
        dashboardName: this.dashboardFormTarget?.name,
      });
      this.removeDashboardsFromList({ dashboardIds: [dashboardToDelete.id], identity });
      if (this.currentDashboardId === dashboardToDelete.id) {
        this.clearCurrentDashboard({ identity });
      }
      await this.refreshDashboard();
    },
    async addDashboardLayoutItem({ reportId, type }) {
      const position = gridUtils.findPosition(
        this.currentDashboardLayout,
        REPORTS[type].width,
        REPORTS[type].height,
      );
      const newLayoutItem = { ...position, report_id: reportId, type };
      const newLayout = [...this.currentDashboardLayout, newLayoutItem];
      await this.internalUpdateDashboard({ ...this.currentDashboard, layout: newLayout });
    },
    async addMultipleDashboardLayoutItems({ reports }) {
      const layout = [...this.currentDashboardLayout];

      reports.forEach((report) => {
        const position = gridUtils.findPosition(
          layout,
          REPORTS[report.type].width,
          REPORTS[report.type].height,
        );
        const newLayoutItem = { ...position, report_id: report.id, type: report.type };
        layout.push(newLayoutItem);
      });
      await this.internalUpdateDashboard({ ...this.currentDashboard, layout });
    },
    async removeDashboardLayoutItem({ reportId }) {
      const newLayout = this.currentDashboardLayout.filter(
        (layoutItem) => layoutItem?.report_id !== reportId,
      );
      await this.internalUpdateDashboard({ ...this.currentDashboard, layout: newLayout });
    },
    async createDashboard(newDashboard) {
      const payload = {
        name: newDashboard.name,
      };
      if (newDashboard?.layout) {
        payload.layout = newDashboard.layout;
      }
      const response = await DashboardAPI.createDashboard({
        data: payload,
      });

      const createdDashboard = response?.data;
      const mixpanelProperties = {
        dashboardId: createdDashboard?.id,
        dashboardName: createdDashboard?.name,
        duplicate: false,
      };
      if (newDashboard?.templateName) {
        mixpanelProperties.templateType = newDashboard.templateName;
        mixpanelProperties.templateBrandIds = newDashboard?.templateBrandIds || [];
        mixpanelProperties.templateBrandNames = newDashboard?.templateBrandNames || [];
      }
      useTrackingStore().track('Dashboard Create Dashboard', mixpanelProperties);
      await this.setCurrentDashboard({ id: createdDashboard.id });
      this.addDashboardToList(createdDashboard);
    },

    // Reports
    addReport({ report }) {
      if (report?.id) {
        this.reports = {
          ...this.reports,
          [report.id]: report,
        };
      }
    },
    setReportLoading({ reportId, loading }) {
      this.reportLoading = {
        ...this.reportLoading,
        [reportId]: loading,
      };
    },
    setReportError({ reportId, error }) {
      this.reportError = {
        ...this.reportError,
        [reportId]: error,
      };
    },
    clearReports() {
      this.reports = {};
    },
    removeReport(reportToRemove) {
      if (reportToRemove?.id) {
        const reports = { ...this.reports };
        delete reports[reportToRemove.id];

        this.reports = reports;
      }
    },
    async fetchMetricTypes() {
      const response = await DashboardAPI.getMetricTypes();
      this.metricTypes = response?.data;
    },
    async getReportData({ reportId, refresh }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const cachedReport = this.reports?.[reportId];
      if (cachedReport && !refresh) {
        return cachedReport;
      }

      this.setReportLoading({ reportId, loading: true });
      try {
        const response = await DashboardAPI.getReport({
          dashboardId: this.currentDashboardId,
          reportId,
          params: {
            include_data: true,
            start_date: dashboardReportsStore.reportDateRangeInUserTimezone?.[0],
            end_date: dashboardReportsStore.reportDateRangeInUserTimezone?.[1],
            context_start_date: dashboardReportsStore.contextDateRangeInUserTimezone?.[0],
            context_end_date: dashboardReportsStore.contextDateRangeInUserTimezone?.[1],
            time_scale: dashboardReportsStore.graphScale,
          },
        });
        const report = response?.data;
        this.addReport({ report });
        return report;
      } catch (error) {
        return this.setReportError({ reportId, error });
      } finally {
        this.setReportLoading({ reportId, loading: false });
      }
    },
    async createReport(reportPayload) {
      const searchStore = useSearchStore();
      const contentTagsStore = useContentTagsStore();

      const response = await DashboardAPI.createReport({
        dashboardId: this.currentDashboardId,
        data: reportPayload,
      });
      const reportId = response?.data?.id;
      const reportMeta = reportPayload?.meta ?? {};
      const properties = getBaseReportProperties(
        reportId,
        reportPayload?.type,
        reportMeta,
        searchStore.competitors,
        contentTagsStore.allOrganizationsContentTags,
        this,
      );
      properties.duplicate = false;
      if (CONTENT_REPORT_TYPES.includes(reportPayload?.type)) {
        properties.contentCreator = contentCreator(reportPayload?.meta?.channel);
      }
      useTrackingStore().track('Dashboard Create Report', properties);

      await this.getReportData({ reportId });

      this.addDashboardLayoutItem({
        reportId,
        type: reportPayload.type,
      });
    },
    async createMultipleReports(reportsPayload) {
      const searchStore = useSearchStore();
      const contentTagsStore = useContentTagsStore();

      const createRequests = reportsPayload.map((report) => {
        return DashboardAPI.createReport({
          dashboardId: this.currentDashboardId,
          data: report,
        });
      });

      const responses = await Promise.all(createRequests);

      responses.forEach((response) => {
        const report = response?.data;
        const reportMeta = report?.meta ?? {};
        const properties = getBaseReportProperties(
          report?.id,
          report?.type,
          reportMeta,
          searchStore.competitors,
          contentTagsStore.allOrganizationsContentTags,
          this,
        );
        properties.duplicate = false;

        if (CONTENT_REPORT_TYPES.includes(report?.type)) {
          properties.contentCreator = contentCreator(report?.meta?.channel);
        }

        useTrackingStore().track('Dashboard Create Report', properties);
      });

      const getRequests = responses.map((response) =>
        this.getReportData({ reportId: response?.data?.id }),
      );
      await Promise.all(getRequests);

      const reports = responses.map((response) => response?.data);
      this.addMultipleDashboardLayoutItems({ reports });
    },
    refreshReportData() {
      // dump the cache of report data so that ALL reports will be fetched
      // fresh from the API the next time they are loaded
      this.clearReports();
      return Promise.all(
        this.currentDashboardLayoutReportIds.map((reportId) => {
          return this.getReportData({ reportId, refresh: true });
        }),
      );
    },
    updateDateRanges({ reportDateRange, contextDateRange }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const updateReportDateRange =
        !!reportDateRange && !isEqual(reportDateRange, dashboardReportsStore.reportDateRange);
      const updateContextDateRange =
        !!contextDateRange && !isEqual(contextDateRange, dashboardReportsStore.contextDateRange);

      if (updateReportDateRange) {
        dashboardReportsStore.updateReportDate({
          newReportDateRange: reportDateRange,
          currentDashboard: this.currentDashboard,
        });
        useTrackingStore().track('Dashboard Sort Reporting Period', {
          dashboardId: this.currentDashboardId,
          dashboardName: this.currentDashboardName,
          dashboardStartDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[0],
          dashboardEndDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[1],
          dashboardContextStartDate:
            dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[0],
          dashboardContextEndDate:
            dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[1],
        });
      }

      if (updateContextDateRange) {
        dashboardReportsStore.updateContextDate({
          newContextDateRange: contextDateRange,
          currentDashboard: this.currentDashboard,
        });
        useTrackingStore().track('Dashboard Sort Comparison Period', {
          dashboardId: this.currentDashboardId,
          dashboardName: this.currentDashboardName,
          dashboardStartDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[0],
          dashboardEndDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[1],
          dashboardContextStartDate:
            dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[0],
          dashboardContextEndDate:
            dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[1],
        });
      }

      if (updateReportDateRange || updateContextDateRange) {
        this.refreshReportData();
      }
    },
    async deleteReport(reportToDelete) {
      const reportId = reportToDelete?.id;
      const searchStore = useSearchStore();
      const contentTagsStore = useContentTagsStore();

      try {
        await DashboardAPI.deleteReport({
          dashboardId: this.currentDashboardId,
          reportId,
        });

        const reportMeta = reportToDelete?.meta ?? {};
        const properties = getBaseReportProperties(
          reportId,
          reportToDelete?.type,
          reportMeta,
          searchStore.competitors,
          contentTagsStore.allOrganizationsContentTags,
          this,
        );

        if (CONTENT_REPORT_TYPES.includes(reportToDelete?.type)) {
          properties.contentCreator = contentCreator(reportToDelete?.meta?.channel);
        }

        useTrackingStore().track('Dashboard Delete Report', properties);
      } catch (e) {
        if (e.response?.status !== 404) {
          throw e;
        }
      }

      await this.removeDashboardLayoutItem({
        reportId,
      });

      this.removeReport(reportToDelete);
    },
    async getGenericReportData({ type, meta }) {
      const dashboardReportsStore = useDashboardReportsStore();
      this.previewReportData = null;
      const brandIds = Array.isArray(meta?.brand_ids) ? meta.brand_ids.join(',') : meta?.brand_ids;
      const metric = meta?.metric || meta?.metrics?.[0];
      const channel = metric?.startsWith('UGC_') ? CHANNELS.INSTAGRAM_UGC.value : meta?.channel;

      if ([REPORTS.SINGLE_METRIC.value, REPORTS.MULTI_BRAND_METRIC.value].includes(type)) {
        if (brandIds && channel && metric) {
          const response = await DashboardAPI.getGenericReportData({
            brandIds,
            channels: channel,
            metrics: metric,
            startDate: dashboardReportsStore.reportDateRangeInUserTimezone?.[0],
            endDate: dashboardReportsStore.reportDateRangeInUserTimezone?.[1],
            contextStartDate: dashboardReportsStore.contextDateRangeInUserTimezone?.[0],
            contextEndDate: dashboardReportsStore.contextDateRangeInUserTimezone?.[1],
          });
          this.previewReportData = response?.data;
        }
      }
    },
    async getReportsData({
      type,
      brandIds,
      competitorSourceAccountIds,
      channels,
      metrics,
      aggregateBy,
      sourceAdAccountIds,
      sourceCampaignIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
    }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const request = {
        reportType: type,
        brandIds,
        competitorSourceAccountIds,
        channels,
        metrics,
        aggregateBy,
        startDate: startDate ?? dashboardReportsStore.reportDateRangeInUserTimezone?.[0],
        endDate: endDate ?? dashboardReportsStore.reportDateRangeInUserTimezone?.[1],
        contextStartDate:
          contextStartDate ?? dashboardReportsStore.contextDateRangeInUserTimezone?.[0],
        contextEndDate: contextEndDate ?? dashboardReportsStore.contextDateRangeInUserTimezone?.[1],
        sourceAdAccountIds,
        sourceCampaignIds,
      };

      const cachedReportsData = this.reportsDataCache.find((cacheValue) =>
        isEqual(cacheValue.request, request),
      );
      if (cachedReportsData) {
        return cachedReportsData?.payload;
      }

      const response = await DashboardAPI.getGenericReportData(request);
      const payload = response?.data;
      this.reportsDataCache.push({
        request,
        payload,
      });
      return payload;
    },
    async updateReport({ id, type, meta, inlineEdit }) {
      const searchStore = useSearchStore();
      const contentTagsStore = useContentTagsStore();

      const competitors = searchStore.competitors;
      const previousReportMeta = this.reports[id]?.meta;
      await DashboardAPI.updateReport({
        dashboardId: this.currentDashboardId,
        reportId: id,
        data: {
          meta,
          type,
        },
      });

      const currentProperties = getBaseReportProperties(
        id,
        type,
        meta,
        competitors,
        contentTagsStore.allOrganizationsContentTags,
        this,
      );
      const prevProperties = getBaseReportProperties(
        id,
        type,
        previousReportMeta,
        competitors,
        contentTagsStore.allOrganizationsContentTags,
        this,
        true,
      );

      const properties = {
        ...prevProperties,
        ...currentProperties,
        ...prefixObjectProperties(objectChanges(meta, previousReportMeta), 'changedReport'),
        inlineEdit,
      };

      if (CONTENT_REPORT_TYPES.includes(type)) {
        properties.contentCreator = contentCreator(meta?.channel);
      }

      useTrackingStore().track('Dashboard Edit Report', properties);

      this.getReportData({ reportId: id, refresh: true });
    },
    async duplicateReport({ type, meta }) {
      const searchStore = useSearchStore();
      const response = await DashboardAPI.createReport({
        dashboardId: this.currentDashboardId,
        data: {
          type,
          meta,
        },
      });
      const reportId = response?.data?.id;
      const channels = meta?.channels;
      const properties = {
        dashboardId: this.currentDashboardId,
        dashboardName: this.currentDashboardName,
        reportId,
        reportType: type,
        sourceReportId: this.reportFormTarget?.id,
        ...prefixObjectProperties(meta, 'report'),
        reportMetricText: getMetricText(meta?.channel, meta?.metric, channels),
        reportBrandNames: extractBrandNames(this.identityBrands, meta?.brand_ids),
        reportCompetitorNames: extractCompetitorNames(
          searchStore.competitors[CHANNELS.INSTAGRAM.value],
          meta?.competitor_source_account_ids,
        ),
        duplicate: true,
      };

      if (CONTENT_REPORT_TYPES.includes(type)) {
        properties.contentCreator = contentCreator(meta?.channel);
      }

      useTrackingStore().track('Dashboard Create Report', properties);

      await this.getReportData({ reportId });

      this.addDashboardLayoutItem({
        reportId,
        type,
      });
    },
    openReportStaticDates({ reportId }) {
      this.showStaticDatesDropdownReportId = reportId;
    },
    closeReportStaticStates() {
      this.showStaticDatesDropdownReportId = null;
    },
    async saveReportStaticDates({
      reportId,
      reportDateRange,
      contextDateRange,
      dateRemovedLocation = null,
    }) {
      const dashboardReportsStore = useDashboardReportsStore();
      let mixpanelPayload = {};
      if (!this.reports[reportId]) {
        return null;
      }

      const report = this.reports[reportId];
      const id = report?.id;
      const type = report?.type;
      const meta = cloneDeep(report?.meta) ?? {};

      if (reportDateRange?.length > 1) {
        if (!meta?.static_dates) {
          meta.static_dates = {};
        }
        meta.static_dates.start_date = reportDateRange[0];
        meta.static_dates.end_date = reportDateRange[1];
        mixpanelPayload.reportStartDate = meta.static_dates.start_date;
        mixpanelPayload.reportEndDate = meta.static_dates.end_date;
        if (contextDateRange?.length > 1) {
          meta.static_dates.context_start_date = contextDateRange[0];
          meta.static_dates.context_end_date = contextDateRange[1];
          mixpanelPayload.reportContextStartDate = meta.static_dates.context_start_date;
          mixpanelPayload.reportContextEndDate = meta.static_dates.context_end_date;
        }
      } else {
        meta.static_dates = undefined;
      }

      const response = await this.updateReport({ id, type, meta });
      const payload = response?.data;

      mixpanelPayload = {
        ...mixpanelPayload,
        customDateRemoved: meta.static_dates === undefined,
        customDateRemovedMethod: dateRemovedLocation,
        dashboardId: this.currentDashboard?.id,
        dashboardName: this.currentDashboard?.name,
        reportID: id,
        reportMetrics: meta?.metrics || [].concat(meta?.metric || []),
        reportType: type,
        dashboardStartDate: dashboardReportsStore.reportDateRange[0],
        dashboardEndDate: dashboardReportsStore.reportDateRange[1],
        dashboardContextStartDate: dashboardReportsStore.contextDateRange[0],
        dashboardContextEndDate: dashboardReportsStore.contextDateRange[1],
      };

      useTrackingStore().track('Dashboard Report Date Changed', mixpanelPayload);

      return payload;
    },
    async deleteReportStaticDates({ reportId, removedByDashboard = false }) {
      try {
        return this.saveReportStaticDates({
          reportId,
          reportDateRange: null,
          contextDateRange: null,
          dateRemovedLocation: removedByDashboard ? 'DASHBOARD' : 'REPORT',
        });
      } catch (error) {
        logger.error(
          'Failed to delete report static dates',
          {
            reportId,
          },
          error,
        );
        throw error;
      }
    },

    async deleteDashboardStaticDates() {
      const ids = this.reportsWithStaticDates.map((report) => report.id);
      if (ids?.length > 0) {
        await Promise.all(
          ids.map((reportId) =>
            this.deleteReportStaticDates({ reportId, removedByDashboard: true }),
          ),
        );
      }
    },

    // Exports
    launchScheduleAutoExportForm(autoExport = null) {
      this.autoExportListShow = false;
      this.autoExportFormShow = true;
      this.autoExportFormMode = autoExport ? 'EDIT' : 'CREATE';
      this.autoExportFormTarget = autoExport;
    },
    closeScheduleAutoExportForm() {
      this.autoExportFormShow = false;
      this.autoExportFormMode = null;
      this.autoExportFormTarget = null;
    },
    launchAutoExportList() {
      this.autoExportListShow = true;
      this.autoExportFormShow = false;
      this.autoExportFormMode = null;
      this.autoExportFormTarget = null;
    },
    closeAutoExportList() {
      this.autoExportListShow = false;
    },
    setDownloadingSpreadsheetExport(downloadingSpreadsheetExport) {
      this.spreadsheetExportDownloading = downloadingSpreadsheetExport;
    },
    updateAllDashboardAutoExports(autoExports) {
      this.allDashboardAutoExports = Array.isArray(autoExports) ? autoExports : [];
    },
    updateCurrentDashboardAutoExports(autoExports) {
      this._currentDashboardAutoExports = Array.isArray(autoExports) ? autoExports : [];
    },
    downloadingReportDataCsv(downloadingReportDataCsv) {
      this.reportDataCsvLoading = downloadingReportDataCsv;
    },
    async downloadDashboardPDF({ path, query, socketId, useWaitFor }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const authStore = useAuthStore();
      const userId = authStore.identity?.id || 'unknown';
      const currentBrand = authStore.currentBrand;
      const dashboardId = this.currentDashboard?.id || 'unknown';
      const dashboardName = this.currentDashboard?.name || 'dashboard';
      const dashboardLabel = this.currentDashboard?.label || 'dashboard';
      const startDate = dashboardReportsStore.reportDateRange?.[0];
      const endDate = dashboardReportsStore.reportDateRange?.[1];
      const filename = `${dashboardLabel}-${startDate}-to-${endDate}`;
      const { reports } = this;

      const reportsIdAndType = Object.entries(reports).map((report) => ({
        report_id: report[1].id,
        report_type: report[1].type,
      }));
      const type = 'dashboard';
      const url = `${originForReport()}${path}/report?${stringifyQuery({
        ...query,
        no_cover_page: true,
        brand: currentBrand?.label,
      })}`;

      const pdfStore = usePdfStore();
      pdfStore.getPdfV2({
        filename,
        notificationSocketId: socketId,
        idleMaxInflightRequest: 2,
        idleTimeout: 10,
        notificationMeta: JSON.stringify({
          type,
          dashboardId,
          dashboardName,
          userId,
        }),
        height: '1150px',
        width: '1550px',
        url,
        landscape: true,
        useWaitFor: useWaitFor || false,
        margin: 20,
      });

      const { dashboardTrackingProps } = useDashboardTrackingProps({
        dashboard: this.currentDashboard,
        reportDateRange: dashboardReportsStore.reportDateRange,
        contextDateRange: dashboardReportsStore.contextDateRange,
        reports: this.reports,
        industries: this.industries,
        brands: this.dashboardIdentityBrands,
      });

      useTrackingStore().track('Report Downloaded', {
        ...dashboardTrackingProps.value,
        type: capitalize(type),
        fileName: filename,
        reports: reportsIdAndType,
        format: 'PDF',
      });
    },
    async downloadDashboardSpreadsheet({ socketId }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const dashboardId = this.currentDashboardId;
      const startDate = dashboardReportsStore.reportDateRangeInUserTimezone?.[0];
      const endDate = dashboardReportsStore.reportDateRangeInUserTimezone?.[1];
      const contextStartDate = dashboardReportsStore.contextDateRangeInUserTimezone?.[0];
      const contextEndDate = dashboardReportsStore.contextDateRangeInUserTimezone?.[1];
      const formatType = 'xlsx';
      const type = 'dashboard';
      const timeScale = dashboardReportsStore.graphScale;
      const { reports } = this;

      const reportsIdAndType = Object.entries(reports).map((report) => ({
        report_id: report[1].id,
        report_type: report[1].type,
      }));

      const response = await DashboardAPI.getDashboardSpreadsheet({
        dashboardId,
        startDate,
        endDate,
        contextStartDate,
        contextEndDate,
        format: formatType,
        socketId,
        timeScale,
      });

      if (response.status === 202) {
        this.setDownloadingSpreadsheetExport(true);
      }

      const { dashboardTrackingProps } = useDashboardTrackingProps({
        dashboard: this.currentDashboard,
        reportDateRange: dashboardReportsStore.reportDateRangeInUserTimezone,
        contextDateRange: dashboardReportsStore.contextDateRangeInUserTimezone,
        reports: this.reports,
        industries: this.industries,
        brands: this.dashboardIdentityBrands,
      });

      useTrackingStore().track('Report Downloaded', {
        ...dashboardTrackingProps.value,
        type: capitalize(type),
        reports: reportsIdAndType,
        format: 'XLSX',
      });
    },
    async downloadReportCSV(reportCSVDownload) {
      const dashboardReportsStore = useDashboardReportsStore();
      const reportId = reportCSVDownload?.id;
      const dashboardId = this.currentDashboardId;
      const startDate = dashboardReportsStore.reportDateRangeInUserTimezone?.[0];
      const endDate = dashboardReportsStore.reportDateRangeInUserTimezone?.[1];
      const contextStartDate = dashboardReportsStore.contextDateRangeInUserTimezone?.[0];
      const contextEndDate = dashboardReportsStore.contextDateRangeInUserTimezone?.[1];
      const timeScale = dashboardReportsStore.graphScale;
      const response = await DashboardAPI.getReportCsv({
        dashboardId,
        reportId,
        startDate,
        endDate,
        contextStartDate,
        contextEndDate,
        timeScale,
      });
      const fileName = response?.headers?.['x-filename'] || 'dashboard-report.csv';
      downloadFileFromMemory(response?.data, fileName);

      const meta = reportCSVDownload?.meta;
      useTrackingStore().track('CSV Downloaded', {
        fileName,
        reportId,
        reportType: reportCSVDownload?.type,
        reportBrandNames: extractBrandNames(this.identityBrands, meta?.brand_ids),
        ...prefixObjectProperties(meta, 'report'),
        reportMetricText: getMetricText(meta?.channel, meta?.metric, meta?.channels),
        dashboardId: this.currentDashboardId,
        dashboardName: this.currentDashboard?.name,
        format: 'CSV',
        dashboardStartDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[0],
        dashboardEndDate: dashboardReportsStore.reportDateRangeISOStringInUserTimezone?.[1],
        dashboardContextStartDate:
          dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[0],
        dashboardContextEndDate: dashboardReportsStore.contextDateRangeISOStringInUserTimezone?.[1],
      });
    },
    async getAllDashboardAutoExports() {
      const response = await DashboardAPI.getAllDashboardAutomatedExports();
      const autoExports = response?.data;
      this.updateAllDashboardAutoExports(autoExports);
    },
    async refreshDashboardAutoExports() {
      if (this.allDashboardAutoExports?.length > 0) {
        this.getAllDashboardAutoExports();
      }
      const dashboardId = this.currentDashboardId;
      if (dashboardId) {
        const response = await DashboardAPI.getDashboardAutomatedExports({ dashboardId });
        const autoExports = response?.data;
        this.updateCurrentDashboardAutoExports(autoExports);
      }
    },
    async createAutoExport({ dashboard, autoExport }) {
      await DashboardAPI.createDashboardAutomatedExports({
        dashboardId: dashboard?.id,
        autoExport,
      });
      useTrackingStore().track('Dashboard Create Auto Export', {
        dashboardId: dashboard?.id,
        dashboardName: dashboard?.name,
        recipientUserIds: autoExport?.recipient_user_ids,
        frequency: autoExport?.frequency,
        dayOfWeek: autoExport?.day_of_week,
        formatList: autoExport?.format_list,
      });
      await this.refreshDashboardAutoExports();
    },
    async updateAutoExport({ dashboard, autoExport }) {
      await DashboardAPI.updateDashboardAutomatedExports({
        dashboardId: dashboard?.id,
        autoExportId: autoExport?.id,
        autoExport,
      });
      const previousAutoExport = this.autoExportFormTarget;
      useTrackingStore().track('Dashboard Edit Auto Export', {
        dashboardId: dashboard?.id,
        dashboardName: dashboard?.name,
        recipientUserIds: autoExport?.recipient_user_ids,
        frequency: autoExport?.frequency,
        dayOfWeek: autoExport?.day_of_week,
        formatList: autoExport?.format_list,
        previousRecipientUserIds: previousAutoExport?.recipient_user_ids,
        previousFrequency: previousAutoExport?.frequency,
        previousDayOfWeek: previousAutoExport?.dayOfWeek,
        previousFormatList: previousAutoExport?.formatList,
      });
      await this.refreshDashboardAutoExports();
    },
    async unsubscribeAutoExport({ dashboard, autoExport, userId }) {
      await DashboardAPI.deleteDashboardAutomatedExportUser({
        dashboardId: dashboard?.id,
        autoExportId: autoExport?.id,
        userId,
      });
      useTrackingStore().track('Dashboard Unsubscribe Auto Export', {
        dashboardId: dashboard?.id,
        dashboardName: dashboard?.name,
        autoExportId: autoExport?.id,
        recipientUserIdRemoved: userId,
      });
      await this.refreshDashboardAutoExports();
    },
    async removeAutomatedExport({ dashboard, autoExport }) {
      await DashboardAPI.deleteAutomatedExport({
        dashboardId: dashboard?.id,
        autoExportId: autoExport?.id,
      });
      useTrackingStore().track('Dashboard Delete Auto Export', {
        dashboardId: dashboard?.id,
        dashboardName: dashboard?.name,
        frequency: autoExport?.frequency,
        dayOfWeek: autoExport?.day_of_week,
        formatList: autoExport?.format_list,
      });
      await this.refreshDashboardAutoExports();
    },
    async downloadReportDataCSV({
      brandIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      channels,
      metrics,
      reportType,
      sortOrder,
      limit,
      timeScale,
      filenamePrefix,
      csvLayout,
    }) {
      this.downloadingReportDataCsv(true);
      const response = await DashboardAPI.getReportDataCsv({
        brandIds,
        startDate,
        endDate,
        contextStartDate,
        contextEndDate,
        channels,
        metrics,
        reportType,
        sortOrder,
        limit,
        timeScale,
        filenamePrefix,
        csvLayout,
      });
      const fileName = response?.headers?.['x-filename'] || 'dashboard-report.csv';
      downloadFileFromMemory(response?.data, fileName);
      this.downloadingReportDataCsv(false);
    },
    // Sharing
    launchShareForm() {
      this.shareFormShow = true;
    },
    closeShareForm() {
      this.shareFormShow = false;
    },
    setDashboardShareableUsers(dashboardSharableUsers) {
      this.dashboardSharableUsers = dashboardSharableUsers;
    },
    updateCurrentDashboardUsers(users) {
      this._currentDashboardUsers = Array.isArray(users) ? users : [];
    },
    async removeDashboardUser({ user }) {
      const authStore = useAuthStore();
      const identity = authStore.identity;
      const currentDashboardId = this.currentDashboard?.id;
      const removingOwnAccess = identity?.id === user?.id;
      await DashboardAPI.deleteDashboardUser({
        dashboardId: this.currentDashboard?.id,
        userId: user?.id,
      });
      useTrackingStore().track('Dashboard Remove User', {
        dashboardId: currentDashboardId,
        dashboardName: this.currentDashboard?.name,
        userId: user?.id,
        removeOwnAccess: removingOwnAccess,
      });
      if (removingOwnAccess) {
        this.clearCurrentDashboard({ identity });
      }
      await this.refreshDashboard();
    },
    async leaveDashboard() {
      const authStore = useAuthStore();
      const identity = authStore.identity;
      const leavingDashboardId = this.currentDashboard?.id;
      await this.removeDashboardUser({
        user: identity,
        removeOwnAccess: true,
      });
      this.removeDashboardsFromList({ dashboardIds: [leavingDashboardId], identity });
    },
    async getAllAccessibleBrandsUsers({ search = null }) {
      const authStore = useAuthStore();
      const identity = authStore.identity;
      const accessibleBrands = identity?.accessible_brands || [];
      const responses = await Promise.all(
        accessibleBrands.map((brandId) => {
          return AuthAPI.getBrandUsers({ brandId, limit: 1000, search });
        }),
      );
      return flatMap(responses, (response) => response?.data?.data || []);
    },
    async getDashboardSharableUsers({ search = null } = {}) {
      const brandUsers = await this.getAllAccessibleBrandsUsers({ search });

      const uniqueUsers = {};
      const uniqueBrandUsers = [];
      brandUsers.forEach((brandUser) => {
        if (!(brandUser.email in uniqueUsers)) {
          uniqueBrandUsers.push(brandUser);
          uniqueUsers[brandUser.email] = true;
        }
      });

      this.setDashboardShareableUsers(uniqueBrandUsers);
      return uniqueBrandUsers;
    },
    async refreshDashboardUsers() {
      const dashboardId = this.currentDashboardId;
      const response = await DashboardAPI.getDashboardUsers({ dashboardId });
      const users = response?.data;
      this.updateCurrentDashboardUsers(users);
    },
    async shareDashboard({ user, role }) {
      const dashboardReportsStore = useDashboardReportsStore();
      const previousRole = this.currentDashboardUsers?.find((u) => u.id === user?.id)?.user_role;
      await DashboardAPI.shareDashboard({
        dashboardId: this.currentDashboard?.id,
        userId: user?.id,
        userRole: role,
      });

      const { dashboardTrackingProps } = useDashboardTrackingProps({
        dashboard: this.currentDashboard,
        reportDateRange: dashboardReportsStore.reportDateRange,
        contextDateRange: dashboardReportsStore.contextDateRange,
        reports: this.reports,
        industries: this.industries,
        brands: this.dashboardIdentityBrands,
      });

      useTrackingStore().track('Dashboard Share User', {
        ...dashboardTrackingProps.value,
        userId: user?.id,
        role,
        previousRole,
      });
      await this.refreshDashboard();
    },
    async transferOwnership({ user }) {
      const currentDashboardId = this.currentDashboard?.id;
      const response = await DashboardAPI.updateDashboardOwner({
        dashboardId: this.currentDashboard?.id,
        userId: user?.id,
      });
      useTrackingStore().track('Dashboard Transfer Ownership', {
        dashboardId: currentDashboardId,
        dashboardName: this.currentDashboard?.name,
        userId: user?.id,
        previousUserId: this.currentDashboard?.user_id,
      });
      await this.refreshDashboard();
      await this.listDashboards();
      return response?.data;
    },
    // Industries
    async fetchIndustries() {
      const response = await DashboardAPI.getIndustries();
      this.industries = response?.data;
    },
  },
});
