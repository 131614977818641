import { computed, unref } from 'vue';
import dayjs from 'dayjs';
import {
  postStatus,
  EXPIRED_PUBLISHING_DATES_SCHEDULED_TIME_ERROR,
  INCOMPATIBLE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR,
  OUTSIDE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR,
} from '@/app/scheduler/constants';
import {
  findDateRangeOverlap,
  validatePublishDates,
  getPublishDateWarning,
} from '@/app/scheduler/utils/publish-dates';
import { areIntervalsOverlapping, getFutureAsInterval, isWithinInterval } from '@/utils/dateUtils';
import { formatDateRangeLabel } from '@/utils/formatters';

export function useApprovedPublishingDates({ scheduledTime, post, mediaList } = {}) {
  const canPublishWithin = computed(() => {
    return findDateRangeOverlap(unref(mediaList));
  });

  const approvedPublishingDatesExpired = computed(() => {
    return !areIntervalsOverlapping(unref(canPublishWithin), getFutureAsInterval());
  });

  const publishDateRangeWarning = computed(() => {
    return getPublishDateWarning(unref(canPublishWithin), unref(scheduledTime));
  });

  const publishDateError = computed(() => {
    return validatePublishDates(unref(canPublishWithin), unref(scheduledTime));
  });

  const scheduledTimeError = computed(() => {
    if ([postStatus.POSTED, postStatus.IMPORTED].includes(post?.status)) {
      return null;
    }

    if (!unref(canPublishWithin)) {
      return INCOMPATIBLE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR;
    }
    if (unref(approvedPublishingDatesExpired)) {
      return EXPIRED_PUBLISHING_DATES_SCHEDULED_TIME_ERROR;
    }
    if (unref(publishDateError)) {
      return OUTSIDE_PUBLISHING_DATES_SCHEDULED_TIME_ERROR;
    }
    return null;
  });

  const canAssetPublishToday = computed(() => {
    return isWithinInterval(dayjs(Date.now()).toDate(), unref(canPublishWithin));
  });

  const publishNowWarning = computed(() => {
    const publishingDatesDescription = formatDateRangeLabel(
      unref(canPublishWithin).start,
      unref(canPublishWithin).end,
      {
        forceYearDisplay: false,
        withTime: true,
      },
    );
    return !unref(canAssetPublishToday)
      ? `This asset can only be published between ${publishingDatesDescription}`
      : null;
  });

  return {
    canPublishWithin,
    canAssetPublishToday,
    publishNowWarning,
    approvedPublishingDatesExpired,
    publishDateRangeWarning,
    publishDateError,
    scheduledTimeError,
  };
}
