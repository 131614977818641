import { watch } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

export default function resetStore({ pinia, store, options }) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => store.$patch(cloneDeep(initialState));

  if (options?.resetOnBrandChange) {
    watch(
      () => pinia.state.value.identity?.currentBrandLabel,
      () => store.$reset(),
    );
  }
}
