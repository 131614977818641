import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useFlagStore } from '@/stores/flag';
import { useAuthStore } from '@/stores/auth';
import schedulerOutageNotificationHandler from '@/utils/schedulerOutageNotifications';

const NOTIFICATION_DELAY = 5000;

export const useNotificationStore = defineStore('notification', () => {
  const flagStore = useFlagStore();
  const authStore = useAuthStore();

  let maxToastId = 0;
  const toasts = ref([]);
  const platformIsDown = ref(null);
  const schedulerOutageMessage = ref(null);
  const messagePopupProps = ref({});
  const confirmMessage = ref({
    show: false,
  });

  function clearToasts({ id } = {}) {
    toasts.value = toasts.value.filter((n) => n.id !== id);
  }

  function setToast(payload) {
    const {
      htmlMessage,
      message,
      subtext,
      type,
      delay = NOTIFICATION_DELAY,
      actionText,
      actionTo,
      sameLine,
      buttonText,
      buttonAction,
      onDismiss,
      autoClear = true,
    } = payload ?? {};

    maxToastId += 1;
    const id = maxToastId;

    toasts.value.push({
      id,
      htmlMessage,
      message,
      subtext,
      type,
      actionText,
      actionTo,
      sameLine,
      buttonText,
      buttonAction,
      onDismiss,
    });

    if (autoClear) {
      setTimeout(() => {
        clearToasts({ id });
      }, delay);
    }
    return id;
  }

  function setMessagePopup({
    showMessagePopup,
    title = '',
    message = '',
    buttonText = '',
    mediaPostUrl = '',
    clickHandle = null,
  }) {
    messagePopupProps.value = {
      showMessagePopup,
      title,
      message,
      buttonText,
      mediaPostUrl,
      clickHandle,
    };
  }

  function confirm(title, message, options) {
    let confirmMessageResolve;
    let confirmMessageReject;

    const promise = new Promise((resolve, reject) => {
      confirmMessageResolve = resolve;
      confirmMessageReject = reject;
    });

    confirmMessage.value = {
      show: true,
      title,
      message,
      confirmAlias: options?.confirmAlias,
      confirmType: options?.confirmType,
      confirmCallback: options?.onConfirm || (() => {}),
      cancelAlias: options?.cancelAlias,
      cancelCallback: options?.onCancel || (() => {}),
      uncancellable: !!options?.uncancellable,
      largerText: options?.largerText,
      platform: options?.platform,
      resolve: confirmMessageResolve,
      reject: confirmMessageReject,
    };

    return promise;
  }

  watch(
    () => flagStore.flags?.platformOutage,
    (status) => {
      platformIsDown.value = status;
    },
  );
  watch(
    () => flagStore.flags?.notification,
    (notif) => {
      let notification;
      if (notif?.topic === 'scheduler-outage') {
        notification = schedulerOutageNotificationHandler(notif, (message) => {
          schedulerOutageMessage.value = message;
        });
      }
      if (authStore.identity && notification) {
        setToast(notification);
      }
    },
  );

  return {
    toasts,
    platformIsDown,
    schedulerOutageMessage,
    setToast,
    clearToasts,
    messagePopupProps,
    setMessagePopup,
    confirm,
    confirmMessage,
  };
});
