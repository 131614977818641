/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filter': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<g fill-rule="nonzero" _fill="#adadad"><path pid="0" d="M17 5.999c1.654 0 3-1.346 3-3s-1.346-3-3-3a2.993 2.993 0 00-2.815 2H0v2h14.185a2.993 2.993 0 002.815 2zm0-4a1 1 0 110 2 1 1 0 010-2zM7 5.999a2.993 2.993 0 00-2.815 2H0v2h4.185a2.994 2.994 0 002.815 2 2.994 2.994 0 002.815-2H20v-2H9.815a2.993 2.993 0 00-2.815-2zm0 4a1 1 0 110-2 1 1 0 010 2zM17 11.999a2.993 2.993 0 00-2.815 2H0v2h14.185a2.994 2.994 0 002.815 2c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0 4a1 1 0 110-2 1 1 0 010 2z"/></g>'
  }
})
