import {
  visualSources,
  visionPredictedSources,
  adsSources,
  sourceTypeEnum,
  invalidVisionUgcSources,
} from '@/app/library/constants';
import { useAuthStore } from '@/stores/auth';
import { PREDICTION_MODEL } from '@/config';
import dayjs from 'dayjs';

export const EXCLUDED_ROUTES = ['instagram.galleries'];

export const engagementRating = {
  good: 0.5,
  excellent: 0.75,
};

export const day = {
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last 7 Days',
  last30Days: 'Last 30 Days',
};

export function getRating(value) {
  if (value === null || value === undefined) {
    return null;
  }
  if (value > engagementRating.excellent) {
    return 'excellent';
  }
  if (value > engagementRating.good) {
    return 'good';
  }
  return 'bad';
}

export function getPredictionRating(
  predictions,
  { currentModel = PREDICTION_MODEL.ENGAGEMENT, mediaV2 = false } = {},
) {
  if (!predictions || !currentModel) return null;
  const field = mediaV2 ? currentModel.v2field : currentModel.field;
  if (predictions === 'processing' || predictions[field] === 'processing') {
    return 'processing';
  }
  return getRating(predictions[field]);
}

export function getPerformanceParameters(value) {
  if (typeof value === 'undefined' || value === null) {
    return { from: null, to: null };
  }
  if (value === 'top') {
    // For this iteration, "excellent" and "good" are grouped together
    // so selecting "excellent" actually means ranging from good to best
    return { from: engagementRating.good, to: 1 };
  }
  return { from: 0, to: engagementRating.good };
}

export function showPredictions(media, router) {
  const authStore = useAuthStore();
  // Check existence of required properties
  if (!media) return false;
  let mediaSource = media.sourceType?.split(':');
  mediaSource = mediaSource ? mediaSource[mediaSource.length - 1] : media.source;
  if (!mediaSource) return false;

  // Check base permissions
  if (!authStore.user_can('vision', 'can_access_vision')) return false;

  // Source exclusions
  const isVisionPredictedSource =
    visionPredictedSources.includes(mediaSource) ||
    (mediaSource === sourceTypeEnum.UGC && !invalidVisionUgcSources.includes(media.source));
  if (!isVisionPredictedSource && !Object.values(visualSources).includes(mediaSource)) {
    return false;
  }
  if (Object.values(adsSources).includes(mediaSource)) return false;

  // Ads users should have vision predictions for all non-text media
  if (authStore.user_can('ads', 'can_access_ad_predictions')) return true;

  // Route exclusions
  const routeName = router?.currentRoute?.value?.name;
  if (routeName && EXCLUDED_ROUTES.find((r) => routeName.startsWith(r))) {
    return false;
  }
  // Normal users show predictions on vision prediction sources
  return isVisionPredictedSource;
}

export function formatDate(date) {
  const formatType = 'dddd, MMMM Do';
  const currDate = dayjs().format(formatType);
  const yesterdaysDate = dayjs().subtract(1, 'day').format(formatType);
  let formattedDate = dayjs(new Date(date)).format(formatType);
  if (formattedDate === currDate) {
    formattedDate = day.today;
  } else if (formattedDate === yesterdaysDate) {
    formattedDate = day.yesterday;
  }
  return formattedDate;
}

export function formatTimestamp(timeCreated) {
  return dayjs(new Date(timeCreated)).format('h:mm a');
}
