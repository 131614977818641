import { env } from '@/env';

export function browserStorageNamespacedKey(key) {
  return `${env.browserStorageNamespace}-${key}`;
}

export function browserStorageSetItem(key, val) {
  localStorage.setItem(browserStorageNamespacedKey(key), val);
}

export function browserStorageGetItem(key) {
  return localStorage.getItem(browserStorageNamespacedKey(key));
}

export function browserStorageRemoveItem(key) {
  return localStorage.removeItem(browserStorageNamespacedKey(key));
}

export function browserStorageSetSessionItem(key, val) {
  sessionStorage.setItem(browserStorageNamespacedKey(key), val);
}

export function browserStorageGetSessionItem(key) {
  return sessionStorage.getItem(browserStorageNamespacedKey(key));
}

export function browserStorageRemoveSessionItem(key) {
  return sessionStorage.removeItem(browserStorageNamespacedKey(key));
}
