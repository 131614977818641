import {
  browserStorageSetItem,
  browserStorageGetItem,
  browserStorageRemoveItem,
} from '@/utils/browserStorage';

const schedulerOutageNotificationHandler = (notification, updateMessageAction) => {
  const cacheKey = 'scheduler-outage-notification';
  const ongoingCacheKey = `${cacheKey}-ongoing`;
  const resolvedCacheKey = `${cacheKey}-resolved`;
  const ongoingMsgRead = browserStorageGetItem(ongoingCacheKey);
  const resolvedMsgRead = browserStorageGetItem(resolvedCacheKey);

  if (notification.type === 'error' || notification.type === 'warning') {
    browserStorageRemoveItem(resolvedCacheKey);
    if (!ongoingMsgRead) {
      return {
        autoClear: false,
        type: notification.type,
        message: notification.title,
        subtext: notification.description,
        buttonText: 'OK',
        buttonAction: () => {
          updateMessageAction(notification.bannerMessage);
          browserStorageSetItem(ongoingCacheKey, 'read');
        },
      };
    }
    // If the notification is confirmed by the user, show a banner instead.
    updateMessageAction(notification.bannerMessage);
    return null;
  }
  if (notification.type === 'info') {
    // Clear the error banner and storage.
    updateMessageAction(null);
    browserStorageRemoveItem(ongoingCacheKey);
    if (!resolvedMsgRead) {
      return {
        autoClear: false,
        type: 'info',
        message: notification.title,
        subtext: notification.description,
        buttonText: 'OK',
        buttonAction: () => {
          browserStorageSetItem(resolvedCacheKey, 'read');
        },
      };
    }

    return null;
  }

  return null;
};

export default schedulerOutageNotificationHandler;
