import { useRoute, useRouter } from 'vue-router';
import {
  convertPostFrom,
  convertPostTo,
  DUPLICATION_POST_TYPES,
  DUPLICATION_TYPES,
  duplicationOptions,
  postTypeByPlatform,
} from '@/utils/postDuplication';
import { useAuthStore } from '@/stores/auth';
import { PLATFORMS, mixpanelActions, postStatus } from '@/app/scheduler/constants';
import { useTrackingStore } from '@/stores/tracking';
import enumTypes from '@/app/library/constants';
import { toolTips } from '@/config';
import { useNotificationStore } from '@/stores/notification';
import { useFlagStore } from '@/stores/flag';

export function usePostDuplication() {
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  const notificationStore = useNotificationStore();
  const flagStore = useFlagStore();

  function pdPostTypeByPlatform(platform, post) {
    return postTypeByPlatform(platform, post);
  }
  function pdDuplicationOptions(action) {
    return duplicationOptions(flagStore, action);
  }

  async function pdDuplicatePostToBrand({ post, toBrand }) {
    const postDuplicationData = {
      duplicatedFrom: post.brand_id,
      duplicationType: DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_BRAND,
      duplicateBrandID: toBrand.id,
      duplicateBrandName: toBrand.name,
    };
    trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
    await router.push({
      name: route.name,
      params: { brandLabel: toBrand.label },
    });
    await router.push({
      name: `scheduler.${post.platform}.posts`,
      params: {
        brandLabel: toBrand.label,
        id: 'new',
      },
      query: {
        duplicatePostId: post.id,
        duplicatePostPlatform: post.platform,
      },
    });
  }

  function duplicateToBrandSubList(post) {
    return Object.values(authStore.identity?.brands || {})
      .map((toBrand) => {
        return toBrand.id !== post.brand_id
          ? {
              text: toBrand.name,
              action: pdDuplicatePostToBrand,
              actionParams: { post, toBrand },
            }
          : null;
      })
      .filter(Boolean);
  }

  function pdCanCrossBrandDuplicateMedia(media) {
    let canCrossBrand = true;
    media.forEach((mediaItem) => {
      if (![enumTypes.UPLOAD, enumTypes.EDITOR].includes(mediaItem.source)) {
        canCrossBrand = false;
      }
    });
    return canCrossBrand;
  }

  function pdCrossBrandDuplicationDisabled(post, media) {
    const isThreadedTweet = post?.platform === PLATFORMS.TWITTER && post?.replies?.length > 0;
    if (![postStatus.IMPORTED, postStatus.POSTED].includes(post?.status)) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledPostStatus };
    }
    if (isThreadedTweet) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledTweetThread };
    }
    if (!pdCanCrossBrandDuplicateMedia(media)) {
      return { isDisabled: true, tip: toolTips.crossBrandDisabledMedia };
    }

    return { isDisabled: false, tip: '' };
  }
  function pdDuplicatePostToChannel({ post, toType, fromType, replaceModal = true }) {
    const validTypes = Object.values(DUPLICATION_POST_TYPES);
    if (!validTypes.includes(toType) || !validTypes.includes(fromType)) {
      notificationStore.setToast({
        message: 'The specified platform does not yet have post duplication implemented.',
        type: 'error',
      });
    } else {
      const basePost = convertPostFrom(post, fromType);
      const newPost = convertPostTo(basePost, toType);
      const isPinterestBulk = newPost.type === 'pinterestBulk';
      const routeName = isPinterestBulk
        ? 'scheduler.pinterest.posts.new.bulk'
        : `scheduler.${newPost.type}.posts`;
      const params = isPinterestBulk ? {} : { id: 'new' };
      router.push({
        name: routeName,
        params,
        query: {
          duplicatePostId: post.id,
          duplicatePostPlatform: post.platform,
          toType,
          replaceModal,
        },
      });
    }
  }
  return {
    pdDuplicatePostToBrand,
    pdDuplicatePostToChannel,
    pdCrossBrandDuplicationDisabled,
    duplicateToBrandSubList,
    pdPostTypeByPlatform,
    pdDuplicationOptions,
  };
}
