import { defineStore } from 'pinia';
import isEqual from 'lodash/isEqual';
import { formatMediaListData } from '@/utils';
import { LibraryAPI } from '@/apis';

export const useSearchStore = defineStore('search', {
  resetOnBrandChange: true,
  state: () => ({
    pending: {
      mediaSearchList: false,
      similarMediaList: false,
      mediaSourceList: false,
    },
    error: {
      mediaSearchList: null,
    },
    carouselState: false,
    searchPage: 1,
    searchLimit: 100,
    nextToken: null,
    mediaSearch: false,
    imageFile: null,
    mediaSearchList: [],
    brandId: null,
    requestMediaSearchList: false,
    multipleQuery: 0,
    searchListFullyLoaded: false,
    similarMediaList: [],
    mediaSourceList: [],
    uniqueImageGroupIds: {},
    uniqueSimilarImageGroupIds: [],
    competitors: {},
    competitorsRequestStatus: null,
    hasNewUploadedMedia: false,
  }),
  getters: {
    carousel() {
      return this.carouselState;
    },
    searchImageFile() {
      return this.imageFile;
    },
    formattedMediaSearchList() {
      return formatMediaListData(this.mediaSearchList);
    },
    formattedSimilarMediaList() {
      return formatMediaListData(this.similarMediaList);
    },
    filterCompetitors() {
      return ({ sourceAccountIds = [], source = '' } = {}) => {
        return (
          sourceAccountIds?.map((sourceAccountId) => {
            const competitorData = this.competitors[source]?.find((competitor) =>
              isEqual(competitor.sourceAccountId, sourceAccountId),
            );
            return competitorData ?? { sourceAccountId, missing: true };
          }) ?? []
        );
      };
    },
  },
  actions: {
    async getMediaSearchList({
      brandId,
      sort,
      page,
      limit,
      nextToken,
      sources,
      query,
      aspectRatio,
      size,
      type,
      color,
      rightsReqd,
      showDuplicateMedia,
      dateFrom,
      dateTo,
      imageDataIncluded,
      mediaId,
      performanceFrom,
      performanceTo,
      predictionModel,
      contentTags,
      imageData,
    }) {
      this.error.mediaSearchList = null;
      this.pending.mediaSearchList = true;
      this.requestMediaSearchList = true;
      // Sometimes when the user changes sort method or filters before the previous request is returned,
      // multiple requests are out and pending, but we actually want to show the result of the last query.
      // state.multipleQuery helps with that.
      this.multipleQuery += 1;
      try {
        const response = await LibraryAPI.getMediaSearchList({
          brandId,
          sort,
          page,
          limit,
          nextToken,
          sources,
          query,
          aspectRatio,
          size,
          type,
          color,
          rightsReqd,
          dateFrom,
          dateTo,
          imageDataIncluded,
          mediaId,
          performanceFrom,
          performanceTo,
          predictionModel,
          contentTags,
          imageData,
        });
        // This ensures that we don't merge data from different brands when switching brands
        if (brandId !== this.brandId) {
          this.brandId = brandId;
          this.searchListFullyLoaded = false;
          this.searchPage = 1;
          this.mediaSearchList = [];
          this.uniqueImageGroupIds = {};
        }
        // The backend is stripping out the searched image in the first page
        // TODO Remove this change after the fix in backend is implemented
        const maxSearchNumber = mediaId && page === 1 ? this.searchLimit - 1 : this.searchLimit;
        if (response.data.length < maxSearchNumber) {
          this.searchListFullyLoaded = true;
        }
        this.multipleQuery -= 1;
        if (this.multipleQuery) {
          return;
        }
        this.requestMediaSearchList = false;
        this.searchPage += 1;

        const nextTokenFromResponse = response.data.slice(-1)[0]?.sort;

        if (nextTokenFromResponse) {
          this.nextToken = nextTokenFromResponse;
        }

        if (showDuplicateMedia) {
          this.mediaSearchList = [...this.mediaSearchList, ...response.data];
          return;
        }

        const uniqueMediaList = response.data.reduce((acc, media) => {
          // Media that belong to an ads carousel (but are not the first item) have --00x appended to the source_id.
          // Remove ads carousel items except the first one
          if (media.source === 'FACEBOOK' && media.sourceId.includes('--')) {
            return acc;
          }

          if (media.mediaGroup != null) {
            if (!Object.prototype.hasOwnProperty.call(this.uniqueImageGroupIds, media.brandType)) {
              this.uniqueImageGroupIds[media.brandType] = [];
            }
            if (this.uniqueImageGroupIds[media.brandType].includes(media.mediaGroup)) {
              return acc;
            }
            this.uniqueImageGroupIds[media.brandType].push(media.mediaGroup);
          }

          acc.push(media);
          return acc;
        }, []);

        this.mediaSearchList = [...this.mediaSearchList, ...uniqueMediaList];
      } catch (error) {
        this.error.mediaSearchList = error;
        this.mediaSearchList = [];
        this.multipleQuery -= 1;
      } finally {
        this.pending.mediaSearchList = false;
      }
    },
    async getSimilarMediaList({ brandId, mediaId, groupId, sources, limit }) {
      this.pending.similarMediaList = true;
      try {
        const response = await LibraryAPI.getSimilarMediaList({
          brandId,
          mediaId,
          groupId,
          sources,
          limit,
        });

        // no paging, so reset on every request.
        this.uniqueSimilarImageGroupIds = [];
        // exclude self
        if (groupId) {
          this.uniqueSimilarImageGroupIds = [groupId.toString()];
        }
        // make sure only one media is displayed for each image group
        this.similarMediaList = response.data
          .map((media) => {
            const mediaGroup = media?.mediaGroup?.toString();
            if (!!mediaGroup && this.uniqueSimilarImageGroupIds.indexOf(mediaGroup) < 0) {
              this.uniqueSimilarImageGroupIds.push(mediaGroup);
              return media;
            }
            return null;
          })
          .filter((item) => item !== null);
      } catch (e) {
        this.uniqueSimilarImageGroupIds = [];
        this.similarMediaList = [];
      } finally {
        this.pending.similarMediaList = false;
      }
    },
    async getMediaSourceList({ brandId, groupId, globalIds }) {
      this.pending.mediaSourceList = true;
      try {
        const response = await LibraryAPI.getMediaSourceList({ brandId, groupId, globalIds });
        this.mediaSourceList = response;
      } finally {
        this.pending.mediaSourceList = false;
      }
    },
    setCarouselState({ newCarouselState }) {
      this.carouselState = newCarouselState;
    },
    setMediaSearchStatus({ status }) {
      this.mediaSearch = status;
    },
    clearMediaSearchList() {
      this.searchListFullyLoaded = false;
      this.searchPage = 1;
      this.mediaSearchList = [];
      this.uniqueImageGroupIds = {};
    },
    clearMediaSourceList() {
      this.mediaSourceList = [];
    },
    setHasNewUploadedMedia({ status }) {
      this.hasNewUploadedMedia = status;
    },
    async getCompetitors(source, brandIds) {
      this.competitorsRequestStatus = 'pending';

      const res = await LibraryAPI.apiGetCompetitors({ payload: { source, brandIds } });
      const competitors = res?.data || [];
      const cleanedCompetitors = competitors.filter(
        (competitor) => competitor.handle && competitor.avatarUrl && competitor.sourceAccountId,
      );
      this.competitors = { ...this.competitors, [source]: cleanedCompetitors };
      this.competitorsRequestStatus = 'success';
    },
    getCompetitorNames(sourceAccountIds, source) {
      return this.filterCompetitors({
        sourceAccountIds,
        source,
      })
        .filter((competitor) => {
          return !competitor.missing;
        })
        .map((competitor) => {
          return competitor.handle;
        });
    },
  },
});
