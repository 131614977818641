import { _adapters } from 'chart.js';
import dayjs from 'dayjs';

const FORMATS = {
  datetime: 'MMM D, YYYY, h:mm:ss a',
  millisecond: 'h:mm:ss.SSS a',
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'hA',
  day: 'MMM D',
  week: 'll',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY',
};

_adapters._date.override(
  typeof dayjs === 'function'
    ? {
        _id: 'dayjs',
        formats() {
          return FORMATS;
        },
        parse(value, format) {
          let dayValue = JSON.parse(JSON.stringify(value)); // Fixes an error when value is a Proxy object
          if (typeof dayValue === 'string' && typeof format === 'string') {
            dayValue = dayjs(dayValue, format);
          } else if (!(dayValue instanceof dayjs)) {
            dayValue = dayjs(dayValue);
          }
          return dayValue.isValid() ? dayValue.valueOf() : null;
        },
        format(time, format) {
          return dayjs(time).format(format);
        },
        add(time, amount, unit) {
          return dayjs(time).add(amount, unit).valueOf();
        },
        diff(max, min, unit) {
          return dayjs(max).diff(dayjs(min), unit);
        },
        startOf(time, unit, weekday) {
          if (unit === 'isoWeek') {
            const validatedWeekday = Math.trunc(Math.min(Math.max(0, weekday), 6));
            return dayjs(time).isoWeekday(validatedWeekday).startOf('day').valueOf();
          }
          return dayjs(time).startOf(unit).valueOf();
        },
        endOf(time, unit) {
          return dayjs(time).endOf(unit).valueOf();
        },
      }
    : {},
);
