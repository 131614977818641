import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVisionAiMessagesStore = defineStore('visionAiMessagesStore', () => {
  const pending = ref({
    messages: false,
  });
  const messages = ref([]);
  const regeneratingMessageId = ref(null);

  function setRegenerateMessageId(RegenerateMessageId) {
    regeneratingMessageId.value = RegenerateMessageId;
  }

  return {
    pending,
    messages,
    regeneratingMessageId,
    setRegenerateMessageId,
  };
});
