<template>
  <div data-cy="tweet-status" class="tweet-status" :class="{ 'top-divider': threadPosition > 1 }">
    <ConfirmDialog
      v-if="showDeleteConfirm"
      confirm-alias="Delete"
      title="Delete this Tweet?"
      @confirm="$emit('delete')"
      @cancel="showDeleteConfirm = false"
    />
    <div class="header" @click="$emit('expand')">
      <div class="title">
        Post
        <span v-if="showNumber">({{ threadPosition }}/{{ threadLength }})</span>
      </div>
      <PostStatusIcon
        v-if="threadLength > 1"
        :post="originalTweet"
        :additional-error="!disableEditing && error?.message"
        :additional-warning="!disableEditing && warning"
        :as-thread="false"
        show-success
        show-warning
        in-editor
      />
      <div class="spacer"></div>
      <Icon v-if="!isExpanded" name="caret" dir="up" xxsmall />
      <TextLimitProgressCircle
        v-else-if="!disableEditing"
        :remaining-length="remainingLength"
        :text-limit="maxTweetLength"
      />
    </div>
    <template v-if="isExpanded">
      <div class="edit-container">
        <div class="text-area">
          <RichTextarea
            ref="richTextarea"
            v-model="tweetHTML"
            :resize-char-limit="maxTweetLength"
            enable-twitter-mentions
            autofocus="end"
            :on-mention-add="addMention"
            :on-mention-lookup="twitterStore.getUser"
            :on-mention-search="searchTwitterUsers"
            :mention-details-component="mentionDetailsComponent"
            :disabled="disableEditing"
            :limit="maxTweetLength"
            platform="twitter"
            placeholder="Compose post"
            resizable
          />
          <slot name="text-controls" :tweet="tweet"></slot>
        </div>
        <div v-if="showAdd || showDelete" class="button-group">
          <div
            v-if="showDelete"
            data-cy="delete-thread-button"
            class="delete-button"
            @click="showDeleteConfirm = true"
          >
            <Icon
              v-tooltip="deleteThreadTooltip"
              name="bin"
              medium
              :color="colours.ICON.ICON_PRIMARY"
            />
          </div>
          <div v-if="showAdd" data-cy="add-thread-button" class="add-button" @click="addTweet">
            <Icon
              v-tooltip="addThreadTooltip"
              :class="{ disabled: addDisabled }"
              name="addCircle"
              medium
              :color="colours.ACTION.ACTION_500"
            />
          </div>
        </div>
      </div>
      <MediaManager
        :disable-editing="disableEditing"
        :display-error-message="(message) => $emit('media-upload-error', message)"
        :download-media-clicked="downloadMediaClicked"
        :media-list="mediaList"
        :media-selected="mediaSelected"
        :viewer-component="viewerComponent"
        :viewer-component-props="{
          platform: 'twitter',
          altTextMediaMap,
          validationError: error,
          mediaValidationErrorList: mediaValidationErrorList,
          mediaWarningIds,
        }"
        :on-upload-status-change="uploadStatusChanged"
        :save-alt-text="saveAltText"
        :can-be-published-at="tweet.timestamp"
        disallow-past-publish-dates
        disallow-incompatible-publish-dates
        :publish-dates-must-overlap-with="canPublishWithin"
      />
      <slot name="edit-utm-controls" :tweet="tweet"></slot>
      <Banner
        v-if="!mediaStore.pending.createMediaV2 && (error || warning)"
        :alert-type="error ? 'error' : 'warning'"
        :custom-icon="error?.icon"
        class="validation-banner"
        hide-default-icon
      >
        {{ error?.message || warning }}
      </Banner>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import twitter from 'twitter-text';
import debounce from 'lodash/debounce';
import {
  minRecommendedImageWidth,
  minRecommendedVideoWidth,
  ORIGINAL,
} from '@/app/scheduler/constants';
import { validateRecommendedMediaSize } from '@/app/scheduler/utils';
import { toolTips, debounceInputDelay } from '@/config';
import { colours } from '@/ux/colours';
import { decodeFromTiptap, encodeForTiptapFromTweetStatus } from '@/app/scheduler/utils/tiptap';
import RichTextarea from '@/app/scheduler/components/EditPost/RichTextarea/RichTextarea.vue';
import TwitterMentionDetails from '@/app/scheduler/components/EditPost/RichTextarea/TwitterMentionDetails.vue';
import { useTwitterStore } from '@/stores/twitter';
import { useMediaStore } from '@/stores/media';
import Icon from '@/components/foundation/Icon.vue';
import ConfirmDialog from '@/components/core/dialogs/ConfirmDialog.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { insertMentionIntoText } from '@/app/scheduler/utils/mentions';
import { getConflictingMediaIds } from '@/app/scheduler/utils/publish-dates';
import { useAuthStore } from '@/stores/auth';
import PostStatusIcon from '@/app/scheduler/components/PostStatusIcon.vue';
import {
  mixpanelPublishTypes,
  postTypeLabelMap,
  SchedulerUserEventTracker,
} from '@/app/scheduler/mixpanel';
import { isValidInterval } from '@/utils/dateUtils';
import MediaManager from '../MediaViewer/MediaManager.vue';
import ListViewer from '../MediaViewer/ListViewer.vue';
import TextLimitProgressCircle from '../TextLimitProgressCircle.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'TweetStatus',
  components: {
    PostStatusIcon,
    Icon,
    RichTextarea,
    MediaManager,
    TextLimitProgressCircle,
    ConfirmDialog,
    Banner,
  },
  props: {
    isExpanded: { type: Boolean, required: true },
    tweet: { type: Object, required: true },
    showNumber: { type: Boolean, required: true },
    threadLength: { type: Number, required: true },
    threadPosition: { type: Number, required: true },
    error: { type: [Object, Boolean], default: null },
    disableEditing: { type: Boolean, required: true },
    referencedObjects: { type: Object, required: true },
    autoPublish: { type: Boolean, required: true },
    maxTweetLength: { type: Number, required: true },
    altTextMediaMap: { type: Object, default: null },
    saveAltText: { type: Function, default: () => {} },
    canPublishWithin: {
      type: Object,
      required: true,
      validator: (value) => value === false || isValidInterval(value),
    },
  },
  emits: [
    'update:status',
    'update:media',
    'delete',
    'add',
    'expand',
    'media-upload-error',
    'upload-status-changed',
    'showUtmPanel',
  ],
  data() {
    return {
      showDeleteConfirm: false,
    };
  },
  computed: {
    ...mapStores(useTwitterStore, useMediaStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    originalTweet() {
      return (
        this.tweet[ORIGINAL] && {
          ...this.tweet[ORIGINAL],
          replies: null,
        }
      );
    },
    mediaList() {
      return this.tweet.mediaIds.map((id) => this.referencedObjects.media[id]);
    },
    colours() {
      return colours;
    },
    viewerComponent() {
      return ListViewer;
    },
    mentionDetailsComponent() {
      return TwitterMentionDetails;
    },
    deleteThreadTooltip() {
      return toolTips.twitter.deleteThreadToolTip;
    },
    addThreadTooltip() {
      return this.addDisabled || toolTips.twitter.addThreadToolTip;
    },
    addDisabled() {
      return (
        (!this.autoPublish && toolTips.twitter.addThreadAutoPublishDisabled) ||
        (this.threadLength >= 25 && toolTips.twitter.addThreadThreadLimitReached)
      );
    },
    showAdd() {
      return !this.disableEditing;
    },
    showDelete() {
      return this.threadPosition > 1 && !this.disableEditing;
    },
    tweetStatus: {
      get() {
        return this.tweet.tweetStatus;
      },
      set(val) {
        this.$emit('update:status', val);
      },
    },
    tweetHTML: {
      get() {
        return encodeForTiptapFromTweetStatus(this.tweetStatus);
      },
      set(val) {
        this.tweetStatus = decodeFromTiptap(val);
        this.debounceShowUtmPanel();
      },
    },
    weightedTweetLength() {
      return parseInt(twitter.parseTweet(this.tweetStatus).weightedLength, 10);
    },
    remainingLength() {
      return this.maxTweetLength - this.weightedTweetLength;
    },
    mediaWarningById() {
      return Object.fromEntries(
        this.tweet.mediaIds.map((id) => [
          id,
          validateRecommendedMediaSize(this.referencedObjects.media[id])?.message,
        ]),
      );
    },
    mediaWarningIds() {
      if (this.error?.code === 'OUTSIDE_OF_APPROVED_PUBLISHING_DATES') {
        return getConflictingMediaIds(this.mediaList, this.tweet.timestamp);
      }
      return this.tweet.mediaIds.filter((id) => this.mediaWarningById[id]);
    },
    warning() {
      const mediaWarnings = this.tweet.mediaIds
        .map((id) => this.mediaWarningById[id])
        .filter((warning) => warning);
      return (
        (mediaWarnings.length > 1 &&
          `One or more of your media are low resolution, which may compromise their
        quality when published. We recommend using images with a minimum width of
        ${minRecommendedImageWidth}px and videos with a minimum width of
        ${minRecommendedVideoWidth}px.`) ||
        mediaWarnings[0]
      );
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
    mediaValidationErrorList() {
      return this.tweet.mediaIds.map((mediaId) =>
        this.error?.mediaIds?.[mediaId] ? this.error : null,
      );
    },
  },
  methods: {
    debounceShowUtmPanel: debounce(function showUtmPanel() {
      this.$emit('showUtmPanel', this.tweet);
    }, debounceInputDelay),
    addTweet() {
      if (!this.addDisabled) {
        this.$emit('add');
      }
    },
    addMention({ username }) {
      const { cursorPosition, text } = insertMentionIntoText({
        text: this.tweetStatus,
        cursorPosition: this.$refs.richTextarea.cursorPosition,
        username,
      });

      /* Note: adding, then removing a zero-width space (\u200b) is a hack to get around
         a bug with tiptap. */
      this.tweetStatus = `${text}\u200b`;
      this.$refs.richTextarea.setCursorPosition(cursorPosition);
      setTimeout(() => {
        this.tweetStatus = this.tweetStatus.replace(/\u200b/, '');
      }, 100);
    },
    searchTwitterUsers(query) {
      return this.twitterStore.searchUsers(query, this.currentBrand.id);
    },
    mediaSelected(media) {
      this.$emit('update:media', media);
    },
    uploadStatusChanged(status) {
      this.$emit('upload-status-changed', status);
    },
    downloadMediaClicked(media) {
      const postTrackingData = {
        postId: this.originalTweet?.id ?? null,
        postPlatform: postTypeLabelMap.twitter,
        postPlatformType: null,
        postStatus: this.originalTweet?.status ?? null,
        publishType: this.tweet.autoPublish
          ? mixpanelPublishTypes.AUTO_PUBLISH
          : mixpanelPublishTypes.SEND_NOTIFICATION,
        timestamp: this.tweet.timestamp,
      };
      this.trackMixpanel.downloadMediaFromScheduler(postTrackingData, media);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.tweet-status {
  display: flex;
  flex-direction: column;
  font-size: var(--x14);

  .header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-bottom: var(--space-10);
    align-items: center;
    font-weight: var(--font-medium);

    .title {
      margin-right: var(--space-12);
    }

    .spacer {
      flex: 1;
    }
  }

  &.top-divider {
    margin-top: var(--space-16);
    border-top: 1px solid var(--border);

    .header {
      margin-top: var(--space-24);
    }
  }

  .edit-container {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--space-12);

    .text-area {
      overflow-x: auto;
      flex: 15;
    }

    .button-group {
      display: flex;
      flex-direction: column;
      flex: 0.5;
      margin-left: var(--space-10);
      justify-content: center;

      * {
        cursor: pointer;
      }
    }
  }

  .validation-banner {
    margin: var(--space-16) 0 0 0;
  }
}
</style>
