/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'key': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.088 7.457l-1.179 1.179-2.356-2.356-1.179 1.178 2.357 2.359-1.18 1.177-2.355-2.357-3.04 3.04c.523.696.843 1.552.843 2.49A4.167 4.167 0 115.833 10c.759 0 1.461.218 2.074.574l-.002-.002 7.826-7.827 1.178 1.178-1.177 1.178 2.356 2.356zm-14.755 6.71a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0z" _fill="#686a7a"/><mask id="svgicon_key_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="17"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.088 7.457l-1.179 1.179-2.356-2.356-1.179 1.178 2.357 2.359-1.18 1.177-2.355-2.357-3.04 3.04c.523.696.843 1.552.843 2.49A4.167 4.167 0 115.833 10c.759 0 1.461.218 2.074.574l-.002-.002 7.826-7.827 1.178 1.178-1.177 1.178 2.356 2.356zm-14.755 6.71a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0z" _fill="#fff"/></mask><g mask="url(#svgicon_key_a)"><path pid="2" _fill="#686a7a" d="M0 0h20v20H0z"/></g>'
  }
})
