// Vue
import { computed } from 'vue';
import { defineStore } from 'pinia';
// Stores
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
// Utils
import { loadScript } from '@/utils/dom';
// Config
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';
import { useTrackingStore } from '@/stores/tracking';
// Libraries

export const useChiliPiperStore = defineStore('chiliPiper', () => {
  const authStore = useAuthStore();
  const notificationStore = useNotificationStore();
  const trackingStore = useTrackingStore();

  const enabled = env.chiliPiperEnabled && !isDatadogSyntheticsBrowser;

  const identity = computed(() => authStore.identity);
  const firstName = computed(() => identity.value?.first_name);
  const lastName = computed(() => identity.value?.last_name);
  const email = computed(() => identity.value?.email);

  const errorMessage = computed(() =>
    enabled
      ? 'There was an error processing your request. Please contact a customer service representative.'
      : 'ChiliPiper is not enabled on this environment.',
  );

  function init() {
    if (!window.ChiliPiper && enabled) {
      loadScript('https://js.chilipiper.com/marketing.js');
    }
  }

  function chiliPiperError() {
    notificationStore.setToast({
      message: errorMessage,
      type: 'error',
    });
  }

  function sendMixpanelEvent(payload) {
    trackingStore.track('Chili Piper', payload);
  }

  /**
   * @param {string} router Hubspot identifier
   * @param {string} formName Form name
   * @param {string} buttonText Button text for Mixpanel tracking
   * @param {string} openedFrom For mixpanel tracking, values: profile dropdown, page
   */
  function submit(router, formName, buttonText, openedFrom = 'page') {
    if (typeof window.ChiliPiper?.submit === 'function') {
      sendMixpanelEvent({ action: 'opened', router, formName, buttonText, openedFrom });
      window.ChiliPiper.submit('dashhudson', router, {
        closeOnOutside: true,
        map: true,
        onSuccess() {
          sendMixpanelEvent({ action: 'submitted', router, formName, buttonText, openedFrom });
        },
        onClose() {
          sendMixpanelEvent({ action: 'closed', router, formName, buttonText, openedFrom });
        },
        lead: {
          FirstName: firstName.value,
          LastName: lastName.value,
          Email: email.value,
          InPlatformFormName: formName,
        },
      });
    } else {
      chiliPiperError();
    }
  }
  function openChiliPiperTab(formURL, router, formName, buttonText, openedFrom = 'page') {
    window.open(formURL, '_blank');
    sendMixpanelEvent({
      action: 'opened',
      router,
      formName,
      buttonText,
      openedFrom,
    });
  }

  return {
    init,
    submit,
    openChiliPiperTab,
  };
});
