import { extractEnumValue } from '@/models/enum.utils';

export const PROSPECT_JOURNEY = Object.freeze({
  PRODUCT_LED: {
    value: 'product_led',
  },
  SALES_LED: {
    value: 'sales_led',
  },
  SALES_ASSIST: {
    value: 'sales_assist',
  },
});

export function isSameProspectJourney(left, right) {
  const leftType = extractEnumValue(left);
  const rightType = extractEnumValue(right);
  return leftType === rightType;
}
