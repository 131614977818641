import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { BRAND, USER } from '@/models/auth/permissions.enum';

export default {
  computed: {
    ...mapStores(useAuthStore),
    hasAccessToLibrary() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    hasAccessToCampaigns() {
      return (
        this.authStore.guard(BRAND.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS) &&
        this.authStore.guard(USER.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS)
      );
    },
    addToAfterLabel() {
      if (this.hasAccessToLibrary && this.hasAccessToCampaigns) {
        return 'Boards, Campaigns & Galleries';
      }
      if (!this.hasAccessToLibrary && this.hasAccessToCampaigns) {
        return 'Boards & Campaigns';
      }
      if (this.hasAccessToLibrary && !this.hasAccessToCampaigns) {
        return 'Boards & Galleries';
      }
      return 'Boards';
    },
  },
};
