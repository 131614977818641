import { computed, ref, toRef } from 'vue';
import { defineStore } from 'pinia';
import { DashboardAPI, LibraryAPI } from '@/apis';
import { useIdentityStore } from '@/stores/identity';
import { CHANNEL_TO_CHANNELS_CONVERSION_REPORT_TYPES } from '@/app/dashboards/utils/reports.enum';
import {
  convertDateToUtcDateTimeByZoneISOString,
  convertDateToUtcDateTimeByZoneString,
  convertUtcDateTimeToLocalDateTimeByZone,
} from '@/utils';
import { formatDateRangeLabel, formatDatePattern } from '@/utils/formatters';
import { useDashboardsReportFilters } from '@/app/dashboards/composables/useDashboardsReportFilters';

export const useDashboardReportsStore = defineStore('dashboardReports', () => {
  // external store modules
  const identityStore = useIdentityStore();
  const {
    dashboardsFilters,
    updateReportDate,
    updateContextDate,
    updateReportingPeriodMode,
    updateContextPeriodMode,
    updateGraphScale,
  } = useDashboardsReportFilters();

  // states
  const reportDateRange = toRef(dashboardsFilters, 'reportDateRange');
  const contextDateRange = toRef(dashboardsFilters, 'contextDateRange');
  const reportingPeriodMode = toRef(dashboardsFilters, 'reportingPeriodMode');
  const contextPeriodMode = toRef(dashboardsFilters, 'contextPeriodMode');
  const graphScale = toRef(dashboardsFilters, 'graphScale');
  const reportFormShow = ref(false);
  const reportFormMode = ref(null);
  const reportFormTarget = ref(null);
  const widgetFormShow = ref(false);
  const widgetFormMode = ref(null);
  const widgetFormTarget = ref(null);
  const storyBoardPopupShow = ref(false);
  const comparableMetrics = ref({});
  const competitorTags = ref([]);

  const timeZone = computed(() => identityStore.identity?.time_zone_name ?? '');

  const identityBrands = computed(() => {
    const brands = identityStore.identity?.brands || {};
    return Object.keys(brands).map((key) => brands[key]);
  });

  const reportFormTargetMeta = computed(() => {
    const reportType = reportFormTarget.value?.type;
    if (
      CHANNEL_TO_CHANNELS_CONVERSION_REPORT_TYPES.includes(reportType) &&
      reportFormTarget.value?.meta?.channel
    ) {
      const cleanedReportFormTargetMeta = {
        ...reportFormTarget.value?.meta,
        channels: [reportFormTarget.value?.meta?.channel],
      };
      delete cleanedReportFormTargetMeta.channel;
      return cleanedReportFormTargetMeta;
    }
    return reportFormTarget.value?.meta;
  });

  const reportStartDate = computed(() => reportDateRange.value?.[0]);

  const reportEndDate = computed(() => reportDateRange.value?.[1]);

  const contextStartDate = computed(() => contextDateRange.value?.[0]);

  const contextEndDate = computed(() => contextDateRange.value?.[1]);

  const reportDateRangeInUserTimezone = computed(() => {
    return reportDateRange.value.map((date) =>
      convertDateToUtcDateTimeByZoneString(date, timeZone.value),
    );
  });

  const contextDateRangeInUserTimezone = computed(() => {
    return contextDateRange.value.map((date) =>
      convertDateToUtcDateTimeByZoneString(date, timeZone.value),
    );
  });

  const reportDateRangeISOStringInUserTimezone = computed(() => {
    return reportDateRange.value.map((value) =>
      convertDateToUtcDateTimeByZoneISOString(value, timeZone.value),
    );
  });

  const contextDateRangeISOStringInUserTimezone = computed(() => {
    return contextDateRange.value.map((value) =>
      convertDateToUtcDateTimeByZoneISOString(value, timeZone.value),
    );
  });

  const reportDateRangeDisplay = computed(() => {
    return formatDateRangeLabel(reportStartDate.value, reportEndDate.value, {
      forceYearDisplay: true,
    });
  });

  const contextDateRangeDisplay = computed(() => {
    const rangeLabel = formatDateRangeLabel(contextStartDate.value, contextEndDate.value, {
      forceYearDisplay: true,
    });
    return rangeLabel ? `Compared to ${rangeLabel}` : '';
  });

  function launchAddReportForm() {
    reportFormMode.value = 'ADD';
    reportFormShow.value = true;
    reportFormTarget.value = null;
  }

  function launchEditReportForm(reportToEdit) {
    reportFormMode.value = 'EDIT';
    reportFormShow.value = true;
    reportFormTarget.value = reportToEdit;
  }

  function launchDuplicateReportForm(reportToDuplicate) {
    reportFormMode.value = 'DUPLICATE';
    reportFormShow.value = true;
    reportFormTarget.value = reportToDuplicate;
  }

  function closeReportForm() {
    reportFormMode.value = null;
    reportFormShow.value = false;
    reportFormTarget.value = null;
  }

  function launchAddWidgetForm(reportToAdd) {
    widgetFormMode.value = 'ADD';
    widgetFormShow.value = true;
    widgetFormTarget.value = reportToAdd;
  }

  function launchEditWidgetForm(reportToEdit) {
    widgetFormMode.value = 'EDIT';
    widgetFormShow.value = true;
    widgetFormTarget.value = reportToEdit;
  }

  function closeWidgetForm() {
    widgetFormMode.value = null;
    widgetFormShow.value = false;
    widgetFormTarget.value = null;
  }

  function launchStoryBoardPopup() {
    storyBoardPopupShow.value = true;
  }

  function closeStoryBoardPopup() {
    storyBoardPopupShow.value = false;
  }

  async function fetchComparableMetrics() {
    const response = await DashboardAPI.getComparableMetrics();
    comparableMetrics.value = response?.data;
  }

  function getComparableMetricBasedOnChannel(channel, metric) {
    return comparableMetrics.value?.[metric]?.[channel];
  }

  async function fetchCompetitiveTags({ brandIds }) {
    const response = await LibraryAPI.getCompetitorTagsByBrand({
      brandIds,
      includeCompetitors: true,
    });
    competitorTags.value = response?.data?.data;
  }

  function convertUtcDateTimeToLocalTimeByTimeZone(utcDateTime) {
    const UtcToLocalTimeByTimeZone = convertUtcDateTimeToLocalDateTimeByZone(
      utcDateTime,
      timeZone.value,
    );

    return formatDatePattern(UtcToLocalTimeByTimeZone, 'YYYY-MM-DD');
  }

  return {
    reportDateRange,
    contextDateRange,
    reportingPeriodMode,
    contextPeriodMode,
    graphScale,
    reportStartDate,
    reportEndDate,
    contextStartDate,
    contextEndDate,
    reportDateRangeInUserTimezone,
    contextDateRangeInUserTimezone,
    reportDateRangeISOStringInUserTimezone,
    contextDateRangeISOStringInUserTimezone,
    reportDateRangeDisplay,
    contextDateRangeDisplay,
    reportFormShow,
    reportFormMode,
    reportFormTarget,
    widgetFormShow,
    widgetFormMode,
    widgetFormTarget,
    storyBoardPopupShow,
    comparableMetrics,
    competitorTags,
    reportFormTargetMeta,
    identityBrands,
    launchAddReportForm,
    launchEditReportForm,
    launchDuplicateReportForm,
    closeReportForm,
    launchAddWidgetForm,
    launchEditWidgetForm,
    closeWidgetForm,
    launchStoryBoardPopup,
    closeStoryBoardPopup,
    fetchComparableMetrics,
    fetchCompetitiveTags,
    getComparableMetricBasedOnChannel,
    convertUtcDateTimeToLocalTimeByTimeZone,
    updateReportDate,
    updateContextDate,
    updateReportingPeriodMode,
    updateContextPeriodMode,
    updateGraphScale,
  };
});
