/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<defs><path pid="0" d="M6.667 13.333A6.674 6.674 0 010 6.667 6.675 6.675 0 016.667 0a6.674 6.674 0 016.666 6.667 6.674 6.674 0 01-6.666 6.666zm0-12a5.34 5.34 0 00-5.334 5.334A5.34 5.34 0 006.667 12 5.34 5.34 0 0012 6.667a5.34 5.34 0 00-5.333-5.334zm.666 7.334V6a.666.666 0 00-.666-.667H5.333v1.334H6v2H4.667V10h4V8.667H7.333zm-.666-3.834a.833.833 0 100-1.666.833.833 0 000 1.666z" id="svgicon_info_a"/></defs><use _fill="#686a7a" fill-rule="nonzero" xlink:href="#svgicon_info_a" transform="translate(.333 .333)"/>'
  }
})
