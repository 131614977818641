/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chat-bubble': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_335_47119)" _fill="#686a7a"><path pid="0" d="M5.998 22v-4h-2c-1.103 0-2-.897-2-2V4c0-1.104.897-2 2-2h16c1.103 0 2 .896 2 2v12c0 1.103-.897 2-2 2h-8.667l-5.333 4zm-2-18v12h4v2l2.667-2h9.333V4h-16z"/><path pid="1" d="M17.998 6h-12v2h12V6zM17.998 9h-12v2h12V9zM13.998 12h-8v2h8v-2z"/></g><defs><clipPath id="clip0_335_47119"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
