/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'galleries': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<g _fill="#adadad" fill-rule="nonzero"><path pid="0" d="M7.94 11.778l-1.461-1.755L4 13h12l-4.472-6.173z"/><path pid="1" d="M18 3H9.75L8.1.8A2.009 2.009 0 006.5 0H2C.897 0 0 .898 0 2v13c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.102-.897-2-2-2zM2 15V2h4.5l1.65 2.2c.375.501.974.8 1.6.8H18l.001 10H2z"/></g>'
  }
})
