<template>
  <div v-if="showHeader" class="text-primary flex items-center py-5 pl-6 pr-0 text-base leading-5">
    <span>Comments ({{ commentCount }})</span>
  </div>
  <div ref="container" class="data-container">
    <SkeletonLoader
      :loading="!commentsAreVisible"
      :type="'comment'"
      :number-of-repeating-component="3"
    >
      <transition-group name="fade" tag="span">
        <div
          v-for="comment in commentingStore.comments"
          :key="comment.id"
          class="comment-container"
        >
          <Comment
            :ref="`comment-${comment.id}`"
            :comment="comment"
            :highlight="isCommentHighlighted(comment)"
            :is-visible="panelOpen"
            @delete-clicked="handleDelete"
            @edit-clicked="scrollToComment(comment.id)"
            @reply-clicked="scrollToReplyComposer(comment.id)"
          />
          <transition-group name="fade" tag="span">
            <div v-for="reply in comment.replies" :key="reply.id">
              <Comment
                :ref="`comment-${reply.id}`"
                :comment="reply"
                :highlight="isCommentHighlighted(reply)"
                :is-visible="panelOpen"
                @delete-clicked="handleDelete"
                @edit-clicked="scrollToComment(reply.id)"
              />
            </div>
          </transition-group>

          <Composer
            v-if="comment._composing_reply"
            :id="`reply-composer-${comment.id}`"
            :ref="`reply-composer-${comment.id}`"
            v-on-click-outside="(e) => handleClickOutsideReply(e, comment.id)"
            class="reply-composer"
            :composer-type="COMPOSER_TYPES.reply.label"
            focus-on-mount
            :value="comment._reply_text"
            :submit-disabled="disableReplySubmit(comment)"
            @cancel="commentingStore.cancelReply({ id: comment.id })"
            @input="commentingStore.setReplyText({ id: comment.id, value: $event })"
            @submit="saveComment(comment._reply_text, comment.id)"
          />
        </div>
      </transition-group>
    </SkeletonLoader>
  </div>
  <Composer
    v-if="commentsAreVisible"
    id="comment-composer"
    ref="comment-composer"
    class="composer-footer"
    :value="commentingStore.newCommentText"
    :composer-type="COMPOSER_TYPES.comment.label"
    :submit-disabled="commentSubmitDisabled"
    @input="commentingStore.setCommentText({ value: $event })"
    @cancel="commentingStore.cancelComment"
    @submit="saveComment(commentingStore.newCommentText, null)"
  />
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { commentAndNotificationTypes, COMPOSER_TYPES } from '@/config';
import { extractUrlsFromText } from '@/utils';
import Comment from '@/components/CommentingModule/Comment.vue';
import Composer from '@/components/CommentingModule/Composer.vue';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';

import { containsMention } from '@/utils/commenting';
import { useCommentingStore } from '@/stores/commenting';
import SocketsMixin from '@/mixins/socketsMixin';
import { useNotificationStore } from '@/stores/notification';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  name: 'CommentPanel',
  components: {
    Comment,
    Composer,
    SkeletonLoader,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  mixins: [SocketsMixin],
  props: {
    panelOpen: { type: Boolean, required: true },
    resourceId: { type: Number, required: true },
    resourceType: { type: String, required: true },
    linkedCommentId: { type: Number, default: null },
    showHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      unreadCommentIds: new Set(),
    };
  },
  computed: {
    ...mapStores(useCommentingStore, useNotificationStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    commentsAreVisible() {
      return this.panelOpen && !this.commentingStore?.pending?.comments;
    },
    COMPOSER_TYPES() {
      return COMPOSER_TYPES;
    },
    commentAndNotificationTypes() {
      return commentAndNotificationTypes;
    },
    unreadNotificationIds() {
      const commentIds = this.commentingStore?.comments.flatMap((comment) => [
        comment.id,
        ...(comment.replies ?? []).map((reply) => reply.id),
      ]);
      return this.commentingStore?.notifications
        ?.filter(
          (notification) => !notification.readAt && commentIds.includes(notification.comment.id),
        )
        ?.map((notification) => notification.id);
    },
    unreadNotificationsAndPanelOpen() {
      return this.panelOpen && this.unreadNotificationIds.length > 0;
    },
    commentCount() {
      if (this.commentingStore?.comments?.length) {
        const comments = this.commentingStore?.comments?.filter((c) => !c.deletedAt)?.length;
        const replies = this.commentingStore.comments
          .map((comment) => {
            return comment.replies?.length || 0;
          })
          .reduce((acc, current) => acc + current);
        return comments + replies;
      }
      return 0;
    },
    commentSubmitDisabled() {
      return (
        this.commentingStore?.pending?.newComment || !this.commentingStore?.newCommentText?.trim()
      );
    },
  },
  watch: {
    panelOpen: {
      handler(newVal) {
        if (
          !newVal &&
          this.commentingStore?.notifications?.filter((notification) => !notification.readAt)
        ) {
          this.unreadCommentIds = new Set();
        } else if (newVal) {
          this.unreadCommentIds = new Set(
            this.commentingStore?.notifications
              .filter((notification) => !notification.readAt)
              .map((notification) => notification.comment.id),
          );
          this.sendPanelExpandedEvent();
          if (this.unreadNotificationIds.length > 0) {
            this.commentingStore.markNotificationsRead({
              ids: this.unreadNotificationIds.toString(),
            });
          }
        }
      },
      immediate: true,
    },
    commentsAreVisible: {
      immediate: true,
      handler(to) {
        if (to) {
          nextTick(() => {
            if (this.linkedCommentId) {
              // eslint-disable-next-line no-unused-expressions
              this.$refs[`comment-${this.linkedCommentId}`]?.[0]?.$el?.scrollIntoView?.();
            } else {
              this.scrollToBottom();
            }
          });
        }
      },
    },
    unreadNotificationsAndPanelOpen: {
      immediate: true,
      handler(to) {
        if (to) {
          this.unreadCommentIds = new Set(
            this.commentingStore?.notifications
              ?.filter((notification) => !notification.readAt)
              ?.map((notification) => notification.comment.id),
          );
          this.commentingStore.markNotificationsRead({
            ids: this.unreadNotificationIds.toString(),
          });
        }
      },
    },
  },
  created() {
    this.commentingStore.getComments({
      brandId: this.currentBrand.id,
      resourceId: this.resourceId,
      resourceType: this.resourceType,
    });
  },
  beforeUnmount() {
    this.commentingStore.clearComments();
  },
  methods: {
    isCommentHighlighted(comment) {
      return (
        (this.unreadCommentIds.has(comment.id) && !this.linkedCommentId) ||
        (this.linkedCommentId && this.linkedCommentId === comment.id)
      );
    },
    sendPanelExpandedEvent() {
      this.trackingStore.track('Commenting Panel Expanded', {
        'Resource ID': this.resourceId,
        'Resource Type': this.resourceType,
        'Unread Notifications Count': this.unreadNotificationIds.length,
      });
    },
    sendCommentAddingEvent(parentId, commentText) {
      this.trackingStore.track('Commenting Adding Comment', {
        'Resource ID': this.resourceId,
        'Resource Type': this.resourceType,
        Reply: parentId !== undefined && parentId !== null,
        Mention: containsMention(commentText),
        Hyperlink: extractUrlsFromText(commentText).length > 0,
      });
    },
    sendCommentDeletingEvent(parentId, commentText) {
      this.trackingStore.track('Commenting Deleting Comment', {
        'Resource ID': this.resourceId,
        'Resource Type': this.resourceType,
        Reply: parentId !== undefined && parentId !== null,
        Mention: containsMention(commentText),
        Hyperlink: extractUrlsFromText(commentText).length > 0,
      });
    },
    async saveComment(text, parentId) {
      if (!this.commentingStore?.pending?.newComment) {
        const data = {
          brandId: this.currentBrand.id,
          resourceId: this.resourceId.toString(),
          resourceType: this.resourceType,
          text,
        };
        if (parentId) {
          data.parentId = parentId;
        }
        await this.commentingStore.createComment(data);
        this.sendCommentAddingEvent(parentId, text);
      }
      if (parentId) {
        this.commentingStore.cancelReply({ id: parentId });
      } else {
        nextTick(() => this.scrollToBottom());
      }
    },
    async handleDelete({ commentId, parentId, commentText } = {}) {
      if (
        await this.notificationStore.confirm('Delete Comment?', null, { confirmAlias: 'Delete' })
      ) {
        this.sendCommentDeletingEvent(parentId, commentText);
        this.commentingStore.deleteComment({ id: commentId });
      }
    },
    handleClickOutsideReply(e, comment) {
      // Reply composer should be removed when clicking outside if the user has not entered any text
      const replyButton = document.querySelector(`[data-id=comment-${comment.id}] .reply-button a`);
      const isEmojiPicker = e.target.className.toString().includes('emoji');
      if (
        e.target !== replyButton &&
        comment._composing_reply &&
        !comment._reply_text &&
        !isEmojiPicker
      ) {
        this.commentingStore.cancelReply({ id: comment.id });
      }
    },
    scrollToRef(ref) {
      nextTick(() => {
        const targetElement = this.$refs[ref]?.[0]?.$el;
        if (targetElement) {
          const targetRect = targetElement.getBoundingClientRect();
          const containerRect = this.$refs.container.getBoundingClientRect();
          const isHidden = targetRect.bottom > containerRect.bottom - 5;

          if (isHidden) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }
        }
      });
    },
    scrollToBottom() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.container?.scrollTo?.({ top: this.$refs.container.scrollHeight });
    },
    scrollToComment(commentId) {
      this.scrollToRef(`comment-${commentId}`);
    },
    scrollToReplyComposer(commentId) {
      this.scrollToRef(`reply-composer-${commentId}`);
    },
    disableReplySubmit(comment) {
      return this.commentingStore?.pending?.newComment || !comment._reply_text?.trim();
    },
  },
  sockets: {
    NEW_MENTIONED_COMMENT_NOTIFICATION(payload) {
      // Listen for websocket updates to the new mentioned comment notification
      this.commentingStore.createCommentNotificationSocket({ notification: payload });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.data-container {
  border-bottom-left-radius: var(--round-corner);
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  width: 20rem;
}

.composer-footer {
  display: flex;
  margin: var(--space-24);
}

.comment-container {
  height: fit-content;

  .reply-composer {
    margin-bottom: var(--space-16);
  }
}

.comment-container:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
