<template>
  <div class="video-prediction-thumbnails">
    <KeyframeSliderPrecise
      :media="media"
      :predictions="videoPredictions"
      :suggested-thumbnails="suggestedThumbnails"
      :prediction-interval="predictionInterval"
      :is-processing="isProcessing"
      @offset-update="handleOffset"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import KeyframeSliderPrecise from '@/app/scheduler/components/EditPost/MediaViewer/KeyframeSliderPrecise.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: true,
    COMPONENT_V_MODEL: true,
    WATCH_ARRAY: true,
  },
  components: {
    KeyframeSliderPrecise,
  },
  props: {
    suggestedThumbnails: { type: Array, default: null },
    videoPredictions: { type: Object, default: null },
    predictionInterval: { type: Number, default: null },
    media: { type: Object, default: null },
    isProcessing: { type: Boolean, default: null },
  },
  emits: ['offset-update'],
  methods: {
    handleOffset(e) {
      this.$emit('offset-update', Math.round((e + Number.EPSILON) * 1000) / 1000);
    },
  },
});
export default comp;
</script>
