import { defineAsyncComponent } from 'vue';
import LDRouteGuard from '@/components/core/LDRouteGuard';
import { campaignRouteNames } from '@/app/campaigns/constants';

const App = () => import('@/app/campaigns/App.vue');
const Campaigns = () => import('@/app/campaigns/pages/Campaigns.vue');
const CampaignDetail = () => import('@/app/campaigns/pages/CampaignDetail.vue');
const CampaignEdit = () => import('@/app/campaigns/pages/CampaignEdit.vue');
const Comparisons = defineAsyncComponent(() => import('@/app/campaigns/pages/Comparisons.vue'));
const ComparisonDetail = defineAsyncComponent(
  () => import('@/app/campaigns/pages/ComparisonDetail.vue'),
);
const ComparisonEdit = () => import('@/app/campaigns/pages/ComparisonEdit.vue');

const PAGE_LEVEL_TOP = `Campaigns`;

export default {
  path: 'campaigns',
  component: App,
  redirect: { name: campaignRouteNames.CAMPAIGNS },
  meta: {
    requiresAuth: true,
    hideSecondaryNav: true,
  },
  children: [
    {
      name: campaignRouteNames.CAMPAIGNS_COMPARISONS,
      path: 'comparisons',
      component: LDRouteGuard,
      props: {
        component: Comparisons,
        requiredFeatureFlag: 'campaignsComparison',
        to: { name: campaignRouteNames.CAMPAIGNS_COMPARISONS },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} Comparison`,
      },
      children: [
        {
          name: campaignRouteNames.CAMPAIGNS_COMPARISONS_CREATE,
          path: 'new',
          component: ComparisonEdit,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} Comparisons - New`,
          },
        },
      ],
    },
    {
      name: campaignRouteNames.CAMPAIGNS_COMPARISON_DETAIL,
      path: 'comparisons/:id',
      component: LDRouteGuard,
      props: {
        component: ComparisonDetail,
        requiredFeatureFlag: 'campaignsComparison',
        to: { name: campaignRouteNames.CAMPAIGNS_COMPARISON_DETAIL },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} Comparison - Detail`,
      },
    },
    {
      name: campaignRouteNames.CAMPAIGNS,
      path: '',
      component: Campaigns,
      props: {
        to: { name: campaignRouteNames.CAMPAIGNS },
      },
      meta: {
        analytics: PAGE_LEVEL_TOP,
      },
      children: [
        {
          name: campaignRouteNames.CAMPAIGNS_CREATE,
          path: 'new',
          component: CampaignEdit,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - New`,
          },
        },
      ],
    },
    {
      name: campaignRouteNames.CAMPAIGN_DETAIL,
      path: ':id',
      redirect: { name: campaignRouteNames.CAMPAIGN_DETAIL_OVERVIEW },
      component: CampaignDetail,
      props: {
        to: { name: campaignRouteNames.CAMPAIGN_DETAIL },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Detail`,
      },
      children: [
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_EDIT,
          path: 'edit',
          component: CampaignEdit,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Edit`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_DELETE,
          path: 'delete',
          analytics: `${PAGE_LEVEL_TOP} - Detail - Delete`,
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_OVERVIEW,
          path: 'overview',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Overview`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_TIKTOK,
          path: 'tiktok',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - TikTok`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_INSTAGRAM,
          path: 'instagram',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Instagram`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_INSTAGRAM_STORIES,
          path: 'instagram-stories',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Instagram Stories`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_FACEBOOK,
          path: 'facebook',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Facebook`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_PINTEREST,
          path: 'pinterest',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Pinterest`,
          },
        },
        {
          path: 'twitter:pathMatch(.*)*',
          redirect: (to) => ({ ...to, path: to.path.replace('/twitter', '/x') }),
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_TWITTER,
          path: 'x',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Twitter`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_YOUTUBE,
          path: 'youtube',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - YouTube`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_RELATIONSHIPS,
          path: 'relationships',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Relationships`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_UGC,
          path: 'ugc',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - UGC`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_CREATOR_INSIGHTS,
          path: 'creator-insights',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Creator Insights`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_CREATOR_DELIVERABLES,
          path: 'creator-deliverables',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Creator Deliverables`,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_ECOMM,
          path: 'ecomm',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Ecomm`,
          },
        },
      ],
    },
    {
      name: campaignRouteNames.CAMPAIGN_DETAIL_REPORT,
      path: ':id',
      redirect: { name: campaignRouteNames.CAMPAIGN_DETAIL_OVERVIEW_REPORT },
      component: CampaignDetail,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Detail - Report`,
        hideNav: true,
        hideVisionAi: true,
      },
      children: [
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_OVERVIEW_REPORT,
          path: 'overview/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Overview - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_TIKTOK_REPORT,
          path: 'tiktok/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - TikTok - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_INSTAGRAM_REPORT,
          path: 'instagram/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Instagram - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_INSTAGRAM_STORIES_REPORT,
          path: 'instagram-stories/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Instagram Stories - Reports`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_FACEBOOK_REPORT,
          path: 'facebook/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Facebook - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_PINTEREST_REPORT,
          path: 'pinterest/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Pinterest - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_TWITTER_REPORT,
          path: 'twitter/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Twitter - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_YOUTUBE_REPORT,
          path: 'youtube/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - YouTube - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_RELATIONSHIPS_REPORT,
          path: 'relationships/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Relationships - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_UGC_REPORT,
          path: 'ugc/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - UGC - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_CREATOR_INSIGHTS_REPORT,
          path: 'creator-insights/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Creator Insights - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_CREATOR_DELIVERABLES_REPORT,
          path: 'creator-deliverables/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Creator Deliverables - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_ECOMM_REPORT,
          path: 'ecomm/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - Ecomm - Report`,
            report: true,
          },
        },
        {
          name: campaignRouteNames.CAMPAIGN_DETAIL_ALL_TABS_REPORT,
          path: 'all/report',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Detail - ALL Tabs - Report`,
            report: true,
          },
        },
      ],
    },
  ],
};
