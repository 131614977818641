import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { ref, computed, watch, unref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { autoCompleteDebounceDelay } from '@/config';

// export const SELECTION_TYPES = {
//   LEAF: {
//     value: 'LEAF',
//   },
//   INDEPENDENT: {
//     value: 'INDEPENDENT',
//   },
// };

export function useSelectOptions({
  value,
  options,
  suggestedOptions,
  returnObject,
  minOptions,
  defaultValue,
  optionValue = ref('value'),
  optionLabel = ref('label'),
  // selectionType = SELECTION_TYPES.LEAF,
}) {
  const DISABLED_KEY = '_disabled';
  const DIVIDER_BEFORE_KEY = '_dividerBefore';
  const TOOLTIP_KEY = 'tooltip';
  const CHILDREN_KEY = 'children';

  const selectedValueOptions = ref([]);
  const internalSearch = ref(null);
  const expandedOptions = ref([]);
  const search = computed({
    get() {
      return internalSearch.value;
    },
    set: debounce(function updateInternalSearch(newValue) {
      internalSearch.value = newValue;
    }, autoCompleteDebounceDelay),
  });

  function cleanOption(option) {
    return omit(option, [DISABLED_KEY, DIVIDER_BEFORE_KEY]);
  }

  function isOptionEqual(left, right) {
    return isEqual(cleanOption(left), cleanOption(right));
  }

  function getOptionValue(option) {
    return unref(returnObject) ? cleanOption(option) : option[unref(optionValue)];
  }

  const selectedValues = computed(() => {
    return selectedValueOptions.value.map((option) => getOptionValue(option));
  });

  const atMinOptions = computed(() => {
    return unref(minOptions) > 0 && selectedValueOptions.value.length <= unref(minOptions);
  });

  const hasSearch = computed(() => internalSearch.value?.length > 0);

  function hasChildren(option) {
    return option?.[CHILDREN_KEY]?.length > 0;
  }

  function findOptionsByValue(valuesToFind, optionsToCheck) {
    return (optionsToCheck ?? []).flatMap((option) => {
      const selectedOption = [];
      if (valuesToFind?.some((valueToFind) => isEqual(valueToFind, getOptionValue(option)))) {
        selectedOption.push(option);
      }

      let selectedChildren = [];
      if (hasChildren(option)) {
        selectedChildren = findOptionsByValue(valuesToFind, option[CHILDREN_KEY]);
      }

      return [...selectedOption, ...selectedChildren];
    });
  }

  function findOriginalOption(optionToFind) {
    const originalOptions = findOptionsByValue([optionToFind?.value], unref(options));
    return originalOptions?.length > 0 ? originalOptions[0] : optionToFind;
  }

  function isOptionSelected(incomingOption) {
    const optionToCheck = findOriginalOption(incomingOption);
    if (hasChildren(optionToCheck)) {
      return optionToCheck[CHILDREN_KEY].every((childOption) => isOptionSelected(childOption));
    }
    return selectedValueOptions.value.some((option) => isOptionEqual(option, optionToCheck));
  }

  function canOptionExpand(option) {
    return hasChildren(option);
  }

  function canOptionCollapse(option) {
    return !internalSearch.value && hasChildren(option);
  }

  function isOptionExpanded(item) {
    return (
      !!internalSearch.value || expandedOptions.value.some((option) => isOptionEqual(option, item))
    );
  }

  function closeAllExpandedOptions() {
    expandedOptions.value = [];
  }

  function hasDividerBefore(option) {
    return option?.[DIVIDER_BEFORE_KEY];
  }

  function findRecursiveOption(
    optionToFind,
    optionsToSearch,
    results = { ancestors: [], option: null, index: -1 },
  ) {
    for (let i = 0; i < optionsToSearch.length; i += 1) {
      const option = optionsToSearch[i];

      results.index += 1;

      if (isOptionEqual(optionToFind, option)) {
        results.option = option;
        return results;
      }

      if (hasChildren(option)) {
        results.ancestors.push(option);
        const childrenResults = findRecursiveOption(optionToFind, option[CHILDREN_KEY], results);
        if (childrenResults.option !== null) {
          return childrenResults;
        }
        results.ancestors.pop();
      }
    }
    return results;
  }

  function findOption(optionToFind) {
    return findRecursiveOption(optionToFind, unref(options));
  }

  function getOptionLevel(optionToFind) {
    const results = findOption(optionToFind);
    if (results.option !== null) {
      return results.ancestors.length + 1;
    }
    return -1;
  }

  function isOptionBranchNode(option) {
    // Check incoming option for children
    if (hasChildren(option)) {
      return true;
    }

    // Find for option by value to check for children
    const valueToFind = option?.value ?? option;
    const foundOptions = findOptionsByValue([valueToFind], unref(options));
    return hasChildren(foundOptions?.[0]);
  }

  function optionMatchesSearch(option) {
    if (hasSearch.value) {
      return option[unref(optionLabel)].toLowerCase().includes(internalSearch.value.toLowerCase());
    }
    return true;
  }

  function filterOptions(optionsToFilter) {
    return (optionsToFilter ?? []).filter((option) => {
      if (hasChildren(option)) {
        const filteredChildOptions = filterOptions(option?.[CHILDREN_KEY] ?? []);
        option[CHILDREN_KEY] = filteredChildOptions;
      }
      return hasChildren(option) || optionMatchesSearch(option);
    });
  }

  const displayOptions = computed(() => {
    const clonedOptions = cloneDeep(unref(options) ?? []);
    let allDisplayOptions = [...clonedOptions];
    if (!hasSearch.value) {
      const cloneSuggestedOptions = cloneDeep(unref(suggestedOptions) ?? []);
      if (cloneSuggestedOptions.length > 0 && allDisplayOptions.length > 0) {
        allDisplayOptions[0][DIVIDER_BEFORE_KEY] = true;
      }
      allDisplayOptions = [...cloneSuggestedOptions, ...allDisplayOptions];
    }
    const optionsToFilter = allDisplayOptions.map((option) => {
      const disabled = atMinOptions.value && isOptionSelected(option);
      return { ...option, [DISABLED_KEY]: disabled };
    });
    return filterOptions(optionsToFilter);
  });

  function findOptionIndex(optionToFind) {
    const results = findOption(optionToFind);
    return results.index;
  }

  function isOptionDisabled(option) {
    return option?.[DISABLED_KEY] || option?.disabled;
  }

  function isChildOptionSelected(optionToCheck) {
    const originalOption = findOriginalOption(optionToCheck);
    const filteredChildOptions = originalOption[CHILDREN_KEY] ?? [];
    return filteredChildOptions.some((childOption) => {
      return isOptionSelected(childOption) || isChildOptionSelected(childOption);
    });
  }

  function isOptionPartiallySelected(optionToCheck) {
    return !isOptionSelected(optionToCheck) && isChildOptionSelected(optionToCheck);
  }

  function getOptionTooltip(option) {
    return option?.[TOOLTIP_KEY];
  }

  function findSelectedValueOptionIndex(optionToCheck) {
    return selectedValueOptions.value.findIndex((valueOption) =>
      isOptionEqual(valueOption, optionToCheck),
    );
  }

  function addOptionToSelectedValueOptions(optionToAdd) {
    if (isOptionDisabled(optionToAdd) || hasChildren(optionToAdd)) {
      return;
    }

    const index = findSelectedValueOptionIndex(optionToAdd);
    if (index < 0) {
      selectedValueOptions.value.push(optionToAdd);
    }
  }

  function removeOptionToSelectedValueOptions(optionToRemove) {
    if (isOptionDisabled(optionToRemove) || hasChildren(optionToRemove)) {
      return;
    }

    const index = findSelectedValueOptionIndex(optionToRemove);
    if (index > -1) {
      selectedValueOptions.value.splice(index, 1);
    }
  }

  function getOptionDescendants(option) {
    return (option?.[CHILDREN_KEY] ?? []).flatMap((childOption) => {
      return [childOption, ...getOptionDescendants(childOption)];
    });
  }

  function onOptionClick(clickedOption) {
    const isBranchNode = isOptionBranchNode(clickedOption);

    if (isOptionSelected(clickedOption) || isOptionPartiallySelected(clickedOption)) {
      if (isBranchNode) {
        const optionToCheck = findOriginalOption(clickedOption);
        getOptionDescendants(optionToCheck).forEach((descendantOption) =>
          removeOptionToSelectedValueOptions(descendantOption),
        );
      } else {
        removeOptionToSelectedValueOptions(clickedOption);
      }
    } else if (isBranchNode) {
      const optionToCheck = findOriginalOption(clickedOption);
      getOptionDescendants(optionToCheck).forEach((descendantOption) =>
        addOptionToSelectedValueOptions(descendantOption),
      );
    } else {
      addOptionToSelectedValueOptions(clickedOption);
    }

    selectedValueOptions.value.sort((a, b) => {
      return findOptionIndex(a) - findOptionIndex(b);
    });
  }

  function onOptionExpandClick(option) {
    if (isOptionDisabled(option) || !canOptionExpand(option) || !canOptionCollapse(option)) {
      return;
    }

    const index = expandedOptions.value.findIndex((valueOption) =>
      isOptionEqual(valueOption, option),
    );
    if (index > -1) {
      expandedOptions.value.splice(index, 1);
    } else {
      expandedOptions.value.push(option);
    }
  }

  const valueOptions = computed(() => {
    return findOptionsByValue(unref(value), unref(options));
  });

  function cancelChanges() {
    selectedValueOptions.value = [...valueOptions.value];
  }

  const isDefaultValue = computed(() => {
    return isEqual(selectedValues.value, unref(defaultValue));
  });

  const hasValueChanged = computed(() => {
    return !isEqual(selectedValues.value, value);
  });

  const selectableOptions = computed(() => {
    return displayOptions.value.filter((displayOption) => !isOptionDisabled(displayOption));
  });

  const isAllSelected = computed(() => {
    if (Array.isArray(selectedValueOptions.value) && Array.isArray(displayOptions.value)) {
      return selectableOptions.value.every((displayOption) => {
        return selectedValueOptions.value.some((selectedValueOption) =>
          isOptionEqual(selectedValueOption, displayOption),
        );
      });
    }
    return false;
  });

  const visibleSelectedOptions = computed(() => {
    return (
      selectableOptions.value.filter((displayOption) => {
        return selectedValueOptions.value.some((selectedValueOption) => {
          return isOptionEqual(selectedValueOption, displayOption);
        });
      }) ?? []
    );
  });

  const visibleUnselectedOptions = computed(() => {
    return (
      selectableOptions.value.filter((displayOption) => {
        return !selectedValueOptions.value.some((selectedValueOption) => {
          return isOptionEqual(selectedValueOption, displayOption);
        });
      }) ?? []
    );
  });

  const isPartiallySelected = computed(() => {
    return !isAllSelected.value && visibleSelectedOptions.value.length > 0;
  });

  function unselectAllVisibleOptions() {
    selectedValueOptions.value = selectedValueOptions.value.filter((selectedValueOption) => {
      return !visibleSelectedOptions.value.some((visibleSelectedOption) =>
        isOptionEqual(visibleSelectedOption, selectedValueOption),
      );
    });
  }

  function selectAllVisibleOptions() {
    selectedValueOptions.value = selectedValueOptions.value.concat(visibleUnselectedOptions.value);
  }

  const selectAllInput = computed(() => {
    return isAllSelected.value || isPartiallySelected.value;
  });

  function toggleSelectAll() {
    if (selectAllInput.value === false) {
      selectAllVisibleOptions();
    } else {
      unselectAllVisibleOptions();
    }
  }

  function clearAllSelections() {
    selectedValueOptions.value = [];
  }

  watch(
    () => ({
      value: unref(value),
      options: unref(options),
    }),
    () => {
      if (Array.isArray(unref(value)) && Array.isArray(unref(options))) {
        selectedValueOptions.value = [...valueOptions.value];
      } else {
        selectedValueOptions.value = [];
      }
    },
    { immediate: true },
  );

  return {
    displayOptions,
    onOptionClick,
    isOptionDisabled,
    isOptionSelected,
    cancelChanges,
    selectedValues,
    valueOptions,
    selectedValueOptions,
    isDefaultValue,
    hasValueChanged,
    selectAllInput,
    toggleSelectAll,
    search,
    getOptionTooltip,
    isPartiallySelected,
    isOptionExpanded,
    onOptionExpandClick,
    canOptionExpand,
    canOptionCollapse,
    closeAllExpandedOptions,
    getOptionLevel,
    isOptionPartiallySelected,
    hasDividerBefore,
    clearAllSelections,
  };
}
