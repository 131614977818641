import { ref } from 'vue';
import defaults from 'lodash/defaults';
import { useNotificationStore } from '@/stores/notification';
import { SERVER_ERROR } from '@/config';

const DEFAULT_OPTIONS = {
  prefix: SERVER_ERROR,
  ignoreFieldErrors: false,
  notification: true,
};

export function useServerErrors() {
  const notificationStore = useNotificationStore();

  const serverErrors = ref({});

  function onServerError(error, options) {
    const { prefix, notification } = defaults(options, DEFAULT_OPTIONS);

    const data = error?.response?.data;
    const message = `${prefix || ''} ${data?.message || data?.description || ''}`.trim();
    const hasFieldErrors = Object.keys(data?.errors || {}).length > 0;

    if (hasFieldErrors && !options?.ignoreFieldErrors) {
      if (data.errors.json) {
        serverErrors.value = { ...data.errors.json };
      } else {
        serverErrors.value = { ...data.errors };
      }
    } else if (notification) {
      notificationStore.setToast({
        message,
        type: 'error',
      });
    }

    return message;
  }

  function clearServerErrors() {
    serverErrors.value = {};
  }

  return {
    serverErrors,
    onServerError,
    clearServerErrors,
  };
}
